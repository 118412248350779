import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    height: '100%',
    width: '100%',
    '& .ck.ck-editor__main': {
      flexDirection: 'column !important',
    },
  },
}));
