import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '@web/dropdown';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  formControl: {
    marginTop: 15,
  },

  horizontalFormControl: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.appBorderInput}`,
  },

  sortOrderContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    borderLeft: `1px solid ${theme.palette.primary.appBorderInput}`,
    minWidth: 86,
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));
