import dataProvider from '../core/dataProvider';
import { isEmptyObject } from '../helper/data-helper';
import { GET_REPORT, RUN_SERVICE } from '../core/data-Provider.helper';
import { objectToLowerCaseProperties } from '../helper/data-helper';
import { actorOnDispatch } from '../type/actor-setup';

export interface ChatReportResponseInterface<T extends unknown = unknown> {
  data: T[];
  totalCount: number;
}

actorOnDispatch(
  'getChatReport',
  async action => {
    const { params, successCallback, failureCallback } = action;
    try {
      const response = await dataProvider(GET_REPORT, '', params);

      const result: ChatReportResponseInterface = {
        data: [],
        totalCount: 0,
      };

      if (!isEmptyObject(response)) {
        result.data = response.data;
        result.totalCount = response.totalCount;
      }

      successCallback?.(result);
    } catch (error) {
      failureCallback?.(error);
    }
  },
  {
    preserve: false,
  },
);

export type ChatServiceResponseInterface<T extends unknown = unknown> = T;

actorOnDispatch(
  'runChatService',
  async action => {
    const { params, successCallback, failureCallback } = action;
    try {
      const response = await dataProvider(RUN_SERVICE, '', params);

      const compatibleData = objectToLowerCaseProperties(response?.data);
      successCallback?.(compatibleData as ChatServiceResponseInterface);
    } catch (error) {
      failureCallback?.(error);
    }
  },
  {
    preserve: false,
  },
);
