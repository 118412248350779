import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    background: 'unset',
  },
}));
