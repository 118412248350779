import { ReactElement } from 'react';
import { MailFormToolbarViewInterface } from './mail-form-toolbar.type';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import CloseFullScreenIcon from '@material-ui/icons/FullscreenExit';

import MinimizeIcon from '@material-ui/icons/Minimize';

const MailFormToolbarView = (props: MailFormToolbarViewInterface): ReactElement => {
  const { title, onClose, handleToggleFullScreen, isFullScreen, onMinimize } = props;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box component="span">{title}</Box>
      <Box>
        {isFullScreen !== undefined &&
          (isFullScreen ? (
            <IconButton
              aria-label="closeFullScreen"
              onClick={handleToggleFullScreen}
            >
              <CloseFullScreenIcon
                style={{
                  fontSize: '1.2rem',
                }}
              />
            </IconButton>
          ) : (
            <IconButton aria-label="OpenFullScreen" onClick={handleToggleFullScreen}>
              <FullScreenIcon
                style={{
                  fontSize: '1.2rem',
                }}
              />
            </IconButton>
          ))}
        <IconButton
          data-test="mail-form-toolbar-minimize-button"
          aria-label="close"
          onClick={onMinimize}
          onTouchEnd={onMinimize}
        >
          <MinimizeIcon
            style={{
              marginBottom: '5px',
            }}
          />
        </IconButton>
        <IconButton aria-label="close" onClick={onClose} onTouchEnd={onClose}>
          <CloseIcon
            style={{
              fontSize: '1.2rem',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default MailFormToolbarView;
