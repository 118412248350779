import { FC, useState, useEffect, memo, useCallback, useRef } from 'react';
import {
  useLocale,
  useTranslate,
  userLogout as userLogoutAction,
} from 'react-admin';
import lodashGet from 'lodash/get';
import lodashSortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  DashboardInSidebarInterface,
  MenuItemParams,
  SidebarMenuInterface,
} from './sidebar-menu.type';
import SidebarMenuView from './sidebar-menu.view';
import { clone } from '../../../helper/data-helper';

import {
  MenuData,
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  waitForAction,
} from '../../../type/actor-setup';
import LoadingBox from '../../LoadingBox';
import {
  CONFIG_CACHED_MENU,
  localStorageGetItem,
  parseJSON,
} from '../../../core/configProvider';
import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';
import { getUsersDashboards } from '../../dashboard/dashboard-page.helper';
import { handleGetMenuItemsForSidebar } from '../menu.helper';
import { customDebounce } from '../../../helper/general-function-helper';

const SidebarMenuController: FC<SidebarMenuInterface> = memo(props => {
  const { onSelect, userLogout, isDrawerOpen } = props;

  const locale = useLocale();
  const translate = useTranslate();

  const [selectedList, setSelectedList] = useState<any>([]);
  const [favoriteItems, setFavoriteItems] = useState<MenuItemParams[]>([]);
  const [recentItems, setRecentItems] = useState<MenuItemParams[]>([]);
  const [isMenuScrolling, setIsMenuScrolling] = useState<boolean>(false);
  const [dashboards, setDashboards] = useState<DashboardInSidebarInterface[]>([]);

  const [menuData, setMenuData] = useState<MenuData | null>(null);
  const [menuList, setMenuList] = useState<any>(null);
  const [searchMenuWord, setSearchMenuWord] = useState<string>('');
  const [isModuleItemSelected, setIsModuleItemSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const menuDataLoadedFromStorageRef = useRef(false);

  useEffect(() => {
    // prettier-ignore
    waitForAction('showSettingsLoading', showSettingsLoading => showSettingsLoading === false).then(didMountHandler);
  }, []);

  useEffect(() => {
    isModuleItemSelected ? onOpenChildMenuHandler() : onCloseChildMenuHandler();
  }, [isModuleItemSelected]);

  useEffect(() => {
    if (menuData && !menuData.isLoading) {
      const sortedList = lodashSortBy(menuData.items, [
        'currentLevel',
        'priority',
      ]) as MenuItemParams[];

      const preparedMenu = prepareMenu(clone(sortedList));

      setMenuList(preparedMenu);
      setIsLoading(false);
    }
  }, [menuData]);

  const didMountHandler = useCallback(() => {
    const recentItemsJson = localStorage.getItem('recentMenuData');
    if (recentItemsJson) {
      actorDispatch('recentItemsData', parseJSON(recentItemsJson));
    }

    const allMenuDataInLocalStorage =
      localStorageGetItem<MenuItemParams[]>(CONFIG_CACHED_MENU);

    actorOnDispatch('recentItemsData', data => {
      if (data) {
        setRecentItems(data);
        localStorage.setItem('recentMenuData', JSON.stringify(data));
      }
    });
    actorDispatch(
      'favoriteItemsData',
      getAppSettings('fav_menu_data_for_user', true)?.value,
    );

    actorOnDispatch('favoriteItemsData', data => {
      data && setFavoriteItems(data);
    });

    actorOnDispatch('isModuleItemSelected', isModuleItemSelected => {
      setIsModuleItemSelected(isModuleItemSelected);
    });

    actorOnDispatch('isChildMenuOpen', isChildMenuOpen => {
      if (isChildMenuOpen === false) {
        setSelectedList([]);
        actorDispatch('isModuleItemSelected', false);
      }
    });

    actorOnDispatch('menuData', setMenuData);

    if (allMenuDataInLocalStorage == null) {
      handleGetMenuItemsForSidebar();
    } else {
      const preparedMenuList = prepareMenu(clone(allMenuDataInLocalStorage));
      setMenuList(preparedMenuList);

      setIsLoading(false);
      menuDataLoadedFromStorageRef.current = true;
    }
  }, []);

  /**
   * to define whether menu is scrolling or not
   * @function handleMenuScroll
   * @returns {void}
   */
  const handleMenuScroll = () => {
    setTimeout(() => {
      setIsMenuScrolling(true);
    }, 500);
  };

  /**
   * to define whether menu is scrolling or not
   * @function handleMenuLeaveScroll
   * @returns {void}
   */
  const handleMenuLeaveScroll = () => {
    setTimeout(() => {
      setIsMenuScrolling(false);
    }, 500);
  };

  /**
   * @function isActiveItem
   * @param {number} id
   * @returns {boolean}
   */
  const isActiveItem = (id: number): boolean => selectedList[0] === id;

  /**
   * @function isInfavoriteItems
   * @param {number} id
   * @returns {boolean}
   */
  const isInfavoriteItems = (id: number): boolean => {
    const currentFavoriteItemsData = actorGetActionValue('favoriteItemsData')!;
    return currentFavoriteItemsData?.some(item => item.id === id);
  };

  /**
   * @function onAddToFavoriteItemsHandler
   * @param {MenuItemParams} item
   * @returns {void}
   */
  const onAddToFavoriteItemsHandler = (item: MenuItemParams): void => {
    const currentFavoriteItemsData = actorGetActionValue('favoriteItemsData') || [];
    actorDispatch('favoriteItemsData', [...currentFavoriteItemsData, item]);
    setAppSettings({
      key: 'fav_menu_data_for_user',
      value: [...currentFavoriteItemsData, item],
      forUser: true,
    });
  };

  /**
   * @function onToggleChildMenuHandler
   * @returns {void}
   */
  const onToggleChildMenuHandler = () => {
    const isChildMenuOpen = actorGetActionValue('isChildMenuOpen');
    isChildMenuOpen ? onCloseChildMenuHandler() : onOpenChildMenuHandler();
  };

  /**
   * @function onOpenChildMenuHandler
   * @returns {void}
   */
  const onOpenChildMenuHandler = () => {
    actorDispatch('isChildMenuOpen', true);
  };

  /**
   * @function onCloseChildMenuHandler
   * @returns {void}
   */
  const onCloseChildMenuHandler = () => {
    actorDispatch('isChildMenuOpen', false);
  };

  /**
   * @function onToggleFavoriteItemsHandler
   * @param {React.MouseEvent<HTMLElement>} event
   * @param {MenuItemParams} item
   * @returns {void}
   */
  const onToggleFavoriteItemsHandler = (
    event: React.MouseEvent<HTMLElement> | undefined,
    item: MenuItemParams,
  ): void => {
    event?.stopPropagation();
    isInfavoriteItems(item.id)
      ? onDeleteFromFavoriteItemsHandler(item.id)
      : onAddToFavoriteItemsHandler(item);
  };

  /**
   * @function onDeleteFromFavoriteItemsHandler
   * @param {number} id
   * @returns {void}
   */
  const onDeleteFromFavoriteItemsHandler = (id: number): void => {
    const currentFavoriteItemsData = actorGetActionValue('favoriteItemsData') || [];
    const updateFavoriteItemsData = currentFavoriteItemsData.filter(
      item => item.id !== id,
    );
    actorDispatch('favoriteItemsData', updateFavoriteItemsData);
    setAppSettings({
      key: 'fav_menu_data_for_user',
      value: updateFavoriteItemsData,
      forUser: true,
    });
  };

  /**
   * @function prepareMenu
   * @param sortedList
   * @param {number | null} parentId
   */
  const prepareMenu = useCallback((sortedList, parentId = null) => {
    const result = sortedList?.filter(menu => menu.parentId === parentId);

    for (const i in result) {
      result[i].children = prepareMenu(sortedList, result[i].id);
      result[i].isOk = true;
    }

    return result;
  }, []);

  /**
   * @function parentMenuSelectHandler
   * @param {number} id
   * @returns {void}
   */
  const parentMenuSelectHandler = useCallback(
    id => {
      setSelectedList([id]);
      selectedList.length === 0 && onToggleChildMenuHandler();
      if (isActiveItem(id)) {
        if (isDrawerOpen) {
          onCloseChildMenuHandler();
          setSelectedList([]);
        } else {
          onToggleChildMenuHandler();
        }
      }
    },
    [isActiveItem, selectedList],
  );

  /**
   * @function childMenuSelectHandler
   * @param {number } id
   * @return {void}
   */
  const childMenuSelectHandler = useCallback(
    id => {
      const lastItemIndex = selectedList.length - 1;
      if (selectedList[lastItemIndex] && selectedList[lastItemIndex] === id) {
        return;
      }

      setSelectedList([...selectedList, id]);
    },
    [selectedList],
  );

  /**
   * @function backClickHandler
   * @returns { void }
   */
  const backClickHandler = useCallback(() => {
    const newSelectedList = [...selectedList];
    newSelectedList.pop();

    setSelectedList(newSelectedList);
  }, [setSelectedList, selectedList]);

  /**
   * @function getSelectedMenuLabel
   * @returns {string}
   */
  const getSelectedMenuLabel = useCallback(() => {
    const home = translate('menu.home');
    if (!selectedList.length) {
      return home;
    }

    let lastParent;
    let lastChildren = menuList;
    for (const id of selectedList) {
      lastParent = lastChildren.find(menu => menu.id === id);
      lastChildren = lastParent && lastParent.children ? lastParent.children : [];
    }

    if (lastParent) {
      return lodashGet(lastParent, ['translatedTitle', locale], lastParent.title);
    }

    return home;
  }, [selectedList]);

  /**
   * @function getSelectedMenuList
   */
  const getSelectedMenuList = useCallback(() => {
    if (!selectedList.length) {
      return [];
    }

    let lastChildren = menuList;
    for (const id of selectedList) {
      const lastParent = lastChildren.find(menu => {
        return parseInt(menu.id, 10) === parseInt(id, 10) && menu.isOk;
      });
      if (!lastParent) {
        break;
      }
      lastChildren = lastParent.children;
    }

    return lastChildren;
  }, [selectedList]);

  /**
   * @function closeHandleSelect
   * @returns {void}
   */
  const closeHandleSelect = useCallback(() => {
    if (typeof onSelect === 'function') {
      onSelect();
    }
    onCloseChildMenuHandler();
  }, [onSelect]);

  /**
   * @function searchInMenu
   * @param {string} word
   * @returns {void}
   */
  const searchInMenu = useCallback(
    customDebounce((word: string): void => {
      setSearchMenuWord(word);
    }, 300),
    [setSearchMenuWord],
  );

  const selectedMenuChildren = getSelectedMenuList();

  useEffect(() => {
    actorDispatch('childMenuData', selectedMenuChildren);
  }, [selectedMenuChildren]);

  /**
   * @function onSelectParentHandler
   * @param {MenuItemParams} menu
   * @returns {void}
   */
  const onSelectParentHandler = (menu: MenuItemParams): void => {
    actorDispatch('isModuleItemSelected', false);
    actorDispatch('selectedParentMenuId', menu.id);
    parentMenuSelectHandler(menu.id);
  };

  /**
   * @function onSelectModuleItemHandler
   * @returns {void}
   */
  const onSelectModuleItemHandler = () => {
    selectedList.length > 0 && setSelectedList([]);
    isModuleItemSelected
      ? actorDispatch('isModuleItemSelected', false)
      : actorDispatch('isModuleItemSelected', true);
  };

  useEffect(() => {
    const recentItemsJson = localStorage.getItem('recentMenuData');
    if (recentItemsJson) {
      actorDispatch('recentItemsData', JSON.parse(recentItemsJson));
    }

    const allMenuDataInLocalStorage =
      localStorageGetItem<MenuItemParams[]>(CONFIG_CACHED_MENU);

    actorOnDispatch('recentItemsData', data => {
      if (data) {
        setRecentItems(data);
        localStorage.setItem('recentMenuData', JSON.stringify(data));
      }
    });
    if (allMenuDataInLocalStorage != null) {
      const preparedMenuList = prepareMenu(clone(allMenuDataInLocalStorage));
      setMenuList(preparedMenuList);
      actorDispatch('menuData', allMenuDataInLocalStorage, { path: 'items' });

      setIsLoading(false);
      menuDataLoadedFromStorageRef.current = true;

      return;
    }

    actorOnDispatch('showSettingsLoading', setIsLoading);
    actorOnDispatch('menuData', _menuData => {
      if (Array.isArray(_menuData?.items)) {
        try {
          const compatibleMenuItems = prepareMenu(clone(_menuData.items));
          setMenuList(compatibleMenuItems);
          setMenuData(compatibleMenuItems);
        } catch (error) {
          console.error('error: ', error);
        }
      }
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('showSettingsLoading', isLoading => {
      if (isLoading) return;

      actorDispatch(
        'favoriteItemsData',
        getAppSettings('fav_menu_data_for_user', true)?.value,
      );
    });

    actorOnDispatch('favoriteItemsData', data => {
      data && setFavoriteItems(data);
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('isModuleItemSelected', isModuleItemSelected => {
      setIsModuleItemSelected(isModuleItemSelected);
    });
    handleGetUsersDashboards();
  }, []);

  useEffect(() => {
    isModuleItemSelected ? onOpenChildMenuHandler() : onCloseChildMenuHandler();
  }, [isModuleItemSelected]);

  // when child menu closes we should deSelect item from parent menu
  useEffect(() => {
    actorOnDispatch('isChildMenuOpen', isChildMenuOpen => {
      if (isChildMenuOpen === false) {
        setSelectedList([]);
        actorDispatch('isModuleItemSelected', false);
      }
    });
  }, []);

  /**
   * @function handleGetUsersDashboards
   * @returns {void}
   */
  const handleGetUsersDashboards = (): void => {
    getUsersDashboards({
      successCallback: dashboardData => {
        dashboardData.push({
          dashinfo_id: 1,
          dashname: translate('dashboard.manageDashboards'),
          id: 1,
          isfav: true,
          priority: null,
          uniqueid: 'string',
          url: 'dashboardlist',
        });
        const filteredFavData = dashboardData.filter(item => item.isfav == true);
        setDashboards(filteredFavData);
        const findPinnedDashboard = dashboardData.find(item => item.ispin == true);

        actorDispatch('pinedDashboard', findPinnedDashboard ?? { hasError: false });
      },
      failureCallback: () => {
        actorDispatch('pinedDashboard', { hasError: true });
      },
    });
  };

  useEffect(() => {
    actorOnDispatch('refreshView', refreshResource => {
      if (refreshResource == 'dashboardMenu') {
        handleGetUsersDashboards();
      }
    });
  }, []);

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <SidebarMenuView
      isDrawerOpen={isDrawerOpen}
      isActiveItem={isActiveItem}
      getSelectedMenuLabel={getSelectedMenuLabel}
      backClickHandler={backClickHandler}
      selectedList={selectedList}
      searchInMenu={searchInMenu}
      menuIsLoading={!!menuData?.isLoading}
      isInfavoriteItems={isInfavoriteItems}
      menuList={menuList}
      searchMenuWord={searchMenuWord}
      childMenuSelectHandler={childMenuSelectHandler}
      favoriteItems={favoriteItems}
      recents={recentItems}
      parentMenuSelectHandler={parentMenuSelectHandler}
      onDeleteFromFavoriteItemsHandler={onDeleteFromFavoriteItemsHandler}
      isModuleSelected={isModuleItemSelected}
      onSelectModuleItemHandler={onSelectModuleItemHandler}
      onSelectParentHandler={onSelectParentHandler}
      onToggleFavoriteItemsHandler={onToggleFavoriteItemsHandler}
      selectedMenuChildren={selectedMenuChildren}
      closeHandleSelect={closeHandleSelect}
      userLogout={userLogout}
      isMenuScrolling={isMenuScrolling}
      handleMenuScroll={handleMenuScroll}
      handleMenuLeaveScroll={handleMenuLeaveScroll}
      dashboards={dashboards}
    />
  );
});

const mapDispatchToProps = (dispatch, { redirectTo }) => ({
  userLogout: () => dispatch(userLogoutAction(redirectTo)),
});

export default compose(connect(null, mapDispatchToProps))(SidebarMenuController);
