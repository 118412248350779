import { ReactElement } from 'react';
import { Box, Hidden } from '@material-ui/core';
import clx from 'classnames';

import { useStyles } from './mail-list.style';
import { AppHeader } from '../../app-container/app-header';
import { AppFooter } from '../../app-container/app-footer';
import { AppContent } from '../../app-container/app-content';
import { AppList } from '../../app-list';
import { ListEmptyResult } from '../../app-list/list-empty-result';
import MailListSkeleton from '../../skeleton/mail-list-skeleton.view';
import { MailListItem } from './mail-list-item';
import { MailContentHeader } from './mail-content-header';
import Pagination from '../../Pagination';
import { MailInterface } from '..';
import { isEmptyObject } from '../../../helper/data-helper';
import { MailListItemHeader } from './mail-list-item-header';
import { getTotalCountByPagination } from '../mail-section.helper';

import type { MailListViewInterface } from './mail-list.type';

const MailListView = (props: MailListViewInterface): ReactElement => {
  const {
    mailActionsHandler,
    mailData,
    checkedMails,
    loading,
    pagination,
    onChangePageHandler,
    onStarClick,
    onImportantClick,
    visibleClassName,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={clx(visibleClassName, classes.appContainer)}
      data-test="mail-list-container"
    >
      <AppHeader>
        <MailContentHeader
          mailActionsHandler={mailActionsHandler}
          mailData={mailData}
          checkedMails={checkedMails}
          onChangePageHandler={onChangePageHandler}
          pagination={pagination}
          loading={loading}
          onStarClick={onStarClick}
          onImportantClick={onImportantClick}
        />
      </AppHeader>
      <AppContent>
        <Box>
          <MailListItemHeader />
        </Box>
        <AppList
          className={classes.mailListContainer}
          data={mailData}
          loading={loading}
          ListEmptyComponent={
            <ListEmptyResult loading={loading} placeholder={<MailListSkeleton />} />
          }
          renderRow={(mail, index) => {
            const { uniqueid } = mail as MailInterface;
            return (
              <MailListItem
                key={uniqueid}
                isEven={index === 0 || !!(index && !(index % 2))} // check if index is even
                mailActionsHandler={mailActionsHandler}
                mail={mail as MailInterface}
                onStarClick={onStarClick}
                onImportantClick={onImportantClick}
                checkedMails={checkedMails}
              />
            );
          }}
        />
      </AppContent>
      {/* @ts-ignore */}
      <Hidden smUp>
        {!isEmptyObject(mailData) ? (
          <AppFooter>
            <Pagination
              total={getTotalCountByPagination(pagination)}
              page={pagination.currentPage}
              perPage={pagination.perPage}
              rowsPerPageOptions={[10]}
              setPage={onChangePageHandler}
              setPerPage={() => {}}
              isLoading={loading}
              isCompactMode={false}
            />
          </AppFooter>
        ) : null}
      </Hidden>
    </div>
  );
};

export default MailListView;
