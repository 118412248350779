import { ProfileAvatarViewProps } from './profile-avatar.type';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';
import { useStyles } from './profile-avatar.style';

const ProfileAvatarView: React.FC<ProfileAvatarViewProps> = props => {
  const { getUrl, alt, bigAvatar = false } = props;

  const classes = useStyles({ bigAvatar });

  return (
    <Avatar
      data-test="avatarChange"
      className={classNames(classes.avatar, classes.avatarImage)}
      alt={alt}
      src={getUrl()}
    />
  );
};

export default ProfileAvatarView;
