import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  FilterFormFieldInterface,
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import { GET_LIST, useLocale } from 'react-admin';
import lodashDebounce from 'lodash/debounce';
import lodashForeach from 'lodash/forEach';
import lodashFind from 'lodash/find';

import ListChartView from './list-chart.view';
import { getMetaDataFromActorOrNetwork } from '../../../helper/meta-helper';
import LoadingBox from '../../loading-box';
import { PaginationControllerInterface } from '../../list-toolbar/pagination';
import { FilterValueStructureEnum } from '../../filter-form/filter-form.helper';
import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';
import { CONFIG_LIST_COLUMN_CHOICE, DEFAULT } from '../../../core/configProvider';
import { clone, isEmptyObject } from '../../../helper/data-helper';
import { getFieldsForDisplay, prepareSettingBarProps } from '../../list';
import { getConfigColumnWidthKey } from '../dynamic-chart.helper';
import type { GeneralMetaData } from '../../../helper/Types';
import type { ListChartInterface, SortType } from './list-chart.type';
import type { OnOptionChangeType } from '../../show-permissions/simple-grid';
import type { SettingBarControllerProps } from '../../list-toolbar/setting-bar';
import { prepareColumnsForGridFromMeta } from '../../grid/grid.helper';
const ListChartController: FC<ListChartInterface> = memo(props => {
  const {
    chartOptions,
    width,
    height,
    editPropertyOfDashboardCard,
    handleChartItemClick,
  } = props;

  const [page, setPage] = useState(1);
  const [metaData, setMetaData] = useState<GeneralMetaData | null>(null);
  const [gridData, setGridData] = useState<{
    data: Record<string, unknown>[];
    totalCount: number;
  } | null>(null);

  const { reportAddress, filterValues, perPage = 25, sort } = chartOptions;
  const resource = `dashboard/report/${reportAddress}`;

  const sortOptionRef = useRef<SortType>(null);
  const locale = useLocale();
  const [columnWidthSetting, setColumnWidthSetting] = useState<Record<
    string,
    number
  > | null>(null);

  useEffect(() => {
    //get new data when page and perPage changed
    updateReportData();
  }, [page, perPage, sort, reportAddress]);

  useEffect(() => {
    //get meta in first load
    getMetaDataFromActorOrNetwork(`report/${reportAddress}`).then(metaData => {
      setMetaData(metaData[`report/${reportAddress}`] ?? []);
    });

    const id = actorOnDispatch('refreshView', refreshResource => {
      if (refreshResource == resource) {
        updateReportData();
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: id,
      });
    };
  }, [reportAddress]);

  useEffect(() => {
    //show loading
    const columnWidthSetting =
      getAppSettings<Record<string, number>>(getConfigColumnWidthKey(resource), true)
        .value ?? {};

    setColumnWidthSetting(columnWidthSetting);
  }, [reportAddress]);

  const defaultSelected = useMemo(() => {
    return (
      getAppSettings<number[]>(
        DEFAULT + '_' + CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
      ).value ?? null
    );
  }, [reportAddress, resource]);

  const userSelected = getAppSettings<number[]>(
    CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
    true,
  ).value;

  const fields = useMemo(() => {
    return getFieldsForDisplay(
      defaultSelected,
      userSelected,
      metaData as GeneralMetaData,
      null, // <= disabledFieldList
      true, // <= isListMode
    );
  }, [defaultSelected, metaData, userSelected]);

  /**
   * setGridSetting
   * @param {Record<string, number>} columnWidthList
   * @returns {void}
   */
  const setGridSetting = (columnWidthList: Record<string, number>): void => {
    const key = getConfigColumnWidthKey(resource);
    setAppSettings({
      key,
      value: columnWidthList,
      forUser: true,
    });
  };

  /**
   * onOptionChanged
   * @param {OnOptionChangeType} _event
   * @returns {void} void
   */
  const onOptionChanged = lodashDebounce((_event: OnOptionChangeType): void => {
    if (!_event || !_event.fullName) return;

    const visibleColumns = _event.component?.getVisibleColumns();
    const columns = prepareColumnsForGridFromMeta({
      fields,
      metaData: metaData as GeneralMetaData,
      locale,
      columnWidthSetting,
    });

    const columnWidthList: Record<string, number> = {};
    lodashForeach(visibleColumns, gridColumn => {
      const columnInfo = lodashFind(columns, ['name', gridColumn.name]);
      if (!isEmptyObject(columnInfo)) {
        const columnKey = columnInfo.field?.id
          ? columnInfo.field.id
          : gridColumn.name;
        columnWidthList[columnKey] = gridColumn.width > 500 ? 500 : gridColumn.width;
      }
    });

    setGridSetting(columnWidthList);
  }, 500);

  const settingBarProps: SettingBarControllerProps = useMemo(() => {
    return prepareSettingBarProps(
      resource,
      metaData as GeneralMetaData,
      clone(fields),
    );
  }, [resource, metaData, fields, reportAddress]);

  /**
   * get new report data
   * @function updateReportData
   * @returns {void} void
   */
  const updateReportData = useCallback((): void => {
    setGridData(null);
    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        resource: `report/${reportAddress}`,
        requestParameters: {
          pagination: {
            page: page,
            perPage: perPage,
          },
          filter: filterValues,
          sort: sort,
        },
        onSuccess: reportData => {
          //if reportData unlike [GET_LIST]: {[entity]: {
          if (typeof reportData.data !== 'undefined') {
            //we need totalCount,code,data
            setGridData(reportData);
          }
        },
        onFailure: () => {
          setGridData({ data: [], totalCount: 0 });
        },
      },
      { disableDebounce: true },
    );
  }, [page, perPage, sort, reportAddress]);

  if (!metaData || gridData == null) {
    return <LoadingBox />;
  }

  /**
   * @function handleSetPerPage
   * @param {number} perPage
   * @returns {void}
   */
  const handleSetPerPage = (perPage: number): void => {
    if (typeof editPropertyOfDashboardCard == 'function') {
      editPropertyOfDashboardCard({ listChartPerPage: perPage });
    }
  };

  /**
   * @function handleSetSort
   * @param {any} _event
   * @returns {void}
   */
  const handleSetSort = (_event: any): void => {
    if (
      typeof editPropertyOfDashboardCard == 'function' &&
      _event.rowType == 'header' &&
      _event.column?.command !== 'select'
    ) {
      let direction = sortOptionRef.current?.order;

      //change order direction
      switch (direction) {
        case 'asc':
          direction = 'desc';
          break;
        case 'desc':
        default:
          direction = 'asc';
      }

      sortOptionRef.current = {
        field: _event.column.name,
        order: direction,
      };

      editPropertyOfDashboardCard?.({
        listChartSort: sortOptionRef.current,
      });
    }
  };

  /**
   * gridColumns
   */
  const gridColumns = prepareColumnsForGridFromMeta({
    fields: clone(fields),
    metaData: metaData as GeneralMetaData,
    locale,
    columnWidthSetting,
  });

  /**
   * default props for pagination
   */
  const paginationProps: PaginationControllerInterface = {
    total: gridData?.totalCount ?? 0,
    page: page,
    perPage: perPage,
    setPage: setPage,
    setPerPage: handleSetPerPage,
    isLoading: false,
    isCompactMode: false,
    isRelation: false,
    rowsPerPageOptions: [10, 25, 100],
  };

  /**
   * @function handleClickFilterIcon
   * @param {Record<string, FilterFormFieldInterface>} data
   * @returns {void} void
   */
  const handleClickFilterIcon = (
    data: Record<string, FilterFormFieldInterface>,
  ): void => {
    const value = Object.values(data)?.[0].value;

    if (Array.isArray(value) && value.length > 0) {
      handleChartItemClick?.(
        value[FilterValueStructureEnum.VALUE] as string,
        value[FilterValueStructureEnum.KEY],
        value[FilterValueStructureEnum.OPERATOR],
      );
    }
  };

  if (!metaData || gridData == null) {
    return <LoadingBox />;
  }

  return (
    <ListChartView
      onOptionChanged={onOptionChanged}
      gridData={gridData.data}
      metaData={metaData}
      columns={gridColumns}
      width={width}
      height={height}
      chartOptions={chartOptions}
      paginationProps={paginationProps}
      onCellClick={handleSetSort}
      sort={sortOptionRef.current}
      settingBarProps={settingBarProps}
      handleClickFilterIcon={handleClickFilterIcon}
    />
  );
});

export default ListChartController;
