import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& > .dx-widget': {
      height: 1,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    ' & .dx-loadpanel-content': {
      width: '260px !important',
    },
  },
  containerMap:{ flexGrow: 1,
    height:'100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    '& > .dx-widget': {
      height: 1,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    ' & .dx-loadpanel-content': {
      width: '260px !important',
    },},
  totalSummary: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#575757',
  },

  grid: {
    minWidth: '100%',
    height: '95%',

    '& .MuiIconButton-root': {
      padding: 0,
      minHeight: 15,
      paddingBottom: 8,
    },
  },

  relationGrid: {
    minWidth: '100%',
    minHeight: '500px',
    maxHeight: '500px',
    //edit icon
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  relationGridFullScreen: {
    minWidth: '100%',
    minHeight: '500px',
    height: '80vh !important',
    //edit icon
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  emptyRelationGrid: {
    minWidth: '100%',
    minHeight: '250px',
    maxHeight: '250px',
  },

  gridInputContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
    padding: '3px',
    margin: 0,
    height: '100%',
    position: 'relative',
    minHeight: '40px',
    maxHeight: '40px',

    '& input': {
      border: 'none',
      flexGrow: 1,
      width: '100%',
      outline: 'none',
      backgroundColor: 'transparent',
    },
  },
}));
