import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  messagesScreenRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  containerSearch: { cursor: 'pointer' },
}));
