import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    margin: 5,
    overflow: 'auto',

    '@media (max-width: 1024px)': {
      maxHeight: 500,
    },
  },
  divider: {
    borderBottom: '1px solid gray',
    margin: '10px 0px',
    width: '100%',
  },
  title: {
    textAlign: 'start',
    width: '100%',
    padding: '0px 10px',
  },
  formControl: {
    width: '100%',
    padding: '0px 10px',
  },
  formGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingRight: 15,
  },
  formControlLabel: {
    flex: '1 0 32%',
    '& span': {
      fontSize: 'small',
    },

    '@media (max-width: 950px)': {
      flexBasis: '49%',
    },
  },
  selectAll: {
    marginBottom: 5,
  },
}));
