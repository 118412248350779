import { useTranslate, useLocale } from 'react-admin';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TodayIcon from '@material-ui/icons/Today';
import momentJalaali from 'moment-jalaali';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { useStyles } from './chat-user-list.style';

import type { ReactElement } from 'react';
import type { ChatUsersInterface } from '../chat-dialogs/add-members-dialog/add-members-dialog.type';
import type { ChatUserListViewPropsInterface } from './chat-user-list.type';
import type { UsersWhoSeenMessageReportInterface } from '../chat-dialogs/message-info-dialog/message-info-dialog.type';

const ChatUserListView = (props: ChatUserListViewPropsInterface): ReactElement => {
  const {
    shouldShowRemoveIcon,
    getPersonAvatarURL,
    handleRemoveUser,
    openContextMenu,
    isUserSelected,
    handleClick,
    handleClose,
    anchorEl,
    users = [],
    usersWithSeenDate = [],
  } = props;

  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();

  if (users.length === 0 && usersWithSeenDate.length === 0) return <></>;

  return (
    <Box className={classes.listContainer}>
      <List>
        {users.map((user: ChatUsersInterface): JSX.Element => {
          return (
            <>
              <ListItem
                button
                onClick={handleClick?.(user)}
                className={classes.listItem}
                onContextMenu={openContextMenu}
              >
                <Box className={classes.rightSection}>
                  <Box>
                    <ListItemAvatar className={classes.avatarRoot}>
                      <Avatar
                        className={
                          isUserSelected(user)
                            ? classes.activeAvatar
                            : classes.avatar
                        }
                        src={getPersonAvatarURL(user)}
                        imgProps={{
                          loading: 'lazy',
                        }}
                      />
                    </ListItemAvatar>
                  </Box>
                  <Box>{user.personname ?? user.fullname}</Box>
                </Box>
                {shouldShowRemoveIcon(user) && (
                  <Box
                    onClick={handleRemoveUser?.(user)}
                    className={classes.removeText}
                  >
                    {translate('chat.remove')}
                  </Box>
                )}
              </ListItem>
              {/** TODO: check in all form and uncomment the code */}
              {/* {!user.isadmin && (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleRemoveUser?.(user)}>
                    {translate('chat.remove')}
                  </MenuItem>
                </Menu>
              )} */}
            </>
          );
        })}

        {usersWithSeenDate.map(
          (user: UsersWhoSeenMessageReportInterface): JSX.Element => {
            return (
              <>
                <ListItem
                  button
                  className={classes.listItem}
                  onContextMenu={openContextMenu}
                >
                  <Box className={classes.rightSection}>
                    <Box>
                      <ListItemAvatar className={classes.avatarRoot}>
                        <Avatar
                          className={classes.avatar}
                          imgProps={{
                            loading: 'lazy',
                          }}
                        />
                      </ListItemAvatar>
                    </Box>
                    <Box>{user.fullname}</Box>
                  </Box>

                  <div className={classes.seenDateContainer}>
                    <div className={classes.seenDateRow}>
                      <Typography className={classes.seenDateTypography}>
                        {momentJalaali(user.seendate).format(
                          locale === 'fa' ? 'jYYYY/jMM/jDD' : 'YYYY/MM/DD',
                        )}
                      </Typography>

                      <TodayIcon className={classes.seenDateIcon} />
                    </div>

                    <div className={classes.seenDateRow}>
                      <Typography className={classes.seenDateTypography}>
                        {momentJalaali(user.seendate).format('HH:mm:ss')}
                      </Typography>

                      <AccessAlarmIcon className={classes.seenDateIcon} />
                    </div>
                  </div>
                </ListItem>
              </>
            );
          },
        )}
      </List>
    </Box>
  );
};

export default ChatUserListView;
