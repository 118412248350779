import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  dayOfWeekNamesGregorian,
  dayOfWeekNamesGregorianPersian,
  dayOfWeekNamesJalaali,
  dayOfWeekNamesJalaaliEnglish
} from '../constants/constants';

export default class DaysOfWeek extends Component {
  static propTypes = {
    styles: PropTypes.object,
    isGregorian: PropTypes.bool,
    locale: PropTypes.string
  };

  render() {
    const { styles, isGregorian, locale } = this.props;

    const dayOfWeekNames =
      isGregorian && locale === 'en'
        ? dayOfWeekNamesGregorian
        : isGregorian && locale === 'fa'
        ? dayOfWeekNamesGregorianPersian
        : !isGregorian && locale === 'en'
        ? dayOfWeekNamesJalaaliEnglish
        : !isGregorian && locale === 'fa'
        ? dayOfWeekNamesJalaali
        : dayOfWeekNamesJalaali;
    return (
      <div className={styles.daysOfWeek}>
        {dayOfWeekNames.map((name, key) => (
          <div key={key}>{name}</div>
        ))}
      </div>
    );
  }
}
