import { FC, ReactElement } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { FieldTitle } from 'react-admin';
import { FilterButtonMenuItemInterface } from './filter-button.type';

const FilterButtonMenuItem: FC<FilterButtonMenuItemInterface> = (
  props,
): ReactElement => {
  const { fieldToFilter, resource, menuItemClickHandler } = props;

  return (
    <MenuItem
      className="new-filter-item"
      data-test-filter-menu-item={fieldToFilter.source}
      data-default-value={fieldToFilter.defaultValue}
      key={`FilterButton_MenuItem_${fieldToFilter.source}`}
      onClick={menuItemClickHandler?.({
        fieldData: fieldToFilter,
        value: fieldToFilter.defaultValue,
      })}
    >
      <FieldTitle
        label={fieldToFilter.label}
        source={fieldToFilter.source}
        resource={resource}
        key={`FilterButton_FieldTitle_${fieldToFilter.source}`}
      />
    </MenuItem>
  );
};

export default FilterButtonMenuItem;
