import { FC } from 'react';
import { useTheme } from '@material-ui/core';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { DevExpressGrid } from '../../grid';
import Pagination from '../../Pagination';

import type { MultiTabTableRelationViewInterface } from './multi-tab-table-relation.type';
import { clone } from '../../../helper/data-helper';

const MultiTabTableRelationView: FC<MultiTabTableRelationViewInterface> = props => {
  const {
    addToFilterRequestList,
    quickEditRowCallback,
    childResourceList,
    onSelectCheckbox,
    parentRecord,
    onTabChange,
    currentUrl,
    onRowClick,
    parentInfo,
    setPerPage,
    setFilters,
    relation,
    setSort,
    setPage,
    sort,
  } = props;

  const theme = useTheme();

  return (
    <Tabs defaultActiveKey={'0'} direction={theme.direction} onChange={onTabChange}>
      <>
        {childResourceList.map((report, index) => {
          return (
            <TabPane key={index} tab={<p>{report.title}</p>}>
              <DevExpressGrid
                data={report.data}
                sort={sort}
                fields={clone(report.fields)}
                redirect={currentUrl}
                hasShow={false}
                hasEdit={false}
                hasCreate={false}
                quickEditRowCallback={quickEditRowCallback?.(report.resource)}
                onSelectCheckbox={onSelectCheckbox(report.resource)}
                onRowClick={onRowClick(report.resource)}
                parentInfo={parentInfo}
                isReport={true}
                parentRecord={parentRecord}
                setSort={setSort(report.resource)}
                setFilters={setFilters}
                metaData={report.metaData}
                addToFilterRequestList={addToFilterRequestList}
                quickEditButton={false}
                // TODO: check here
                relationMode={true}
                relation={relation} // why? 😑
                // TODO: check here
                resource={report.resource}
                relationResource={report.resource} // why? 😑
                basePath={`/${report.resource}`} // why? 😑
              />

              <Pagination
                page={report.currentPage}
                perPage={report.currentPerPage}
                setPage={setPage(report.resource)}
                setPerPage={setPerPage(report.resource)}
                total={report.dataTotalCount}
                isRelation
              />
            </TabPane>
          );
        })}
      </>
    </Tabs>
  );
};

export default MultiTabTableRelationView;
