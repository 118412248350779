import { useContext } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth/withWidth';
import { withStyles } from '@material-ui/core';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';

import NoteStreamSidebar from '../component/NoteStreamSidebar';
import { hasNote, isTreeHasDelete } from '../helper/MetaHelper';
import { closeNoteStreamAction } from '../redux/listPage/action';
import LoadingBox from '../component/LoadingBox';
import { NewMetaContext } from '../container/NewMetaContext';
import TreeController from '../container/tree/TreeController';
import HeaderTitleController from '../component/header-title/header-title.controller';

const TreeListPage = props => {
  const {
    staticContext,
    classes,
    showNoteStream,
    closeNoteStream,
    showDevExtremeTopFilter,
    showDevExtremeGrouping,
    resource,
    ...rest
  } = props;

  const locale = useLocale();

  const { getMeta } = useContext(NewMetaContext);
  const metaData = getMeta(resource);
  if (!metaData) {
    return <LoadingBox />;
  }

  const isNoteStreamEnabled = hasNote(metaData);

  const isSlideOpen = () => {
    const { showNoteStream } = props;
    const isNoteStreamEnabled = hasNote(metaData);

    return isNoteStreamEnabled && showNoteStream;
  };

  const onRootClick = () => {
    const { theme, width, closeNoteStream } = props;
    if (theme.breakpoints.width(width) < theme.breakpoints.width('md')) {
      if (isSlideOpen()) {
        closeNoteStream();
      }
    }
  };

  const handleRowClick = row =>
    console.log('TreeListPage.js:30 handleRowClick', row);

  return (
    <>
      <HeaderTitleController
        title={
          <p>
            {metaData.config.translatedCaption[locale] ?? metaData.config.caption}
          </p>
        }
      />
      <TreeController
        {...props}
        locale={locale}
        metaData={metaData}
        resource={resource}
        hasList={true}
        hasCreate={true}
        hasDelete={isTreeHasDelete(metaData)}
        hasEdit={true}
        hasShow={false}
        enableSelection={true}
        basePath={`/${resource}`}
        slideComponent={isNoteStreamEnabled ? <NoteStreamSidebar /> : null}
        isSlideOpen={isSlideOpen()}
        onRootClick={onRootClick}
        onRowClick={handleRowClick}
        isTopFilterOpen={showDevExtremeTopFilter}
        isGroupingOpen={showDevExtremeGrouping}
        pagination={null}
        isFilterEnable={true}
        isGroupingEnable={false}
        isColumnChoiceEnable={true}
        perPage={9999999}
        treeLevel={lodashGet(metaData, ['config', 'treeLevel'])}
      />
    </>
  );
};

TreeListPage.propTypes = {
  resource: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { match }) => {
  const { moduleName, moduleTableName } = match.params;
  const resource = `${moduleName}/${moduleTableName}`;

  return {
    resource,
    showNoteStream: state.listPage.showNoteStream,
    showDevExtremeTopFilter: state.listPage.showDevExtremeTopFilter,
    showDevExtremeGrouping: state.listPage.showDevExtremeGrouping,
  };
};

const mapDispatchToProps = {
  closeNoteStream: closeNoteStreamAction,
};

export default compose(
  withWidth(),
  withStyles({}, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(TreeListPage);
