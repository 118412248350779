import { ReactElement } from 'react';
import { ModuleItemViewInterface } from './module-item.type';
import StarIcon from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

import { useStyles } from './module-item.style';

const ModuleItemView = (props: ModuleItemViewInterface): ReactElement => {
  const { title, isFavorite, onStarClick, dataTest, onTitleClick } = props;
  const classes = useStyles();

  return (
    <div data-test={dataTest} className={classes.container}>
      <span onClick={onTitleClick} className={classes.title}>
        {title}
      </span>
      {isFavorite ? (
        <StarIcon onClick={onStarClick} className={classes.starIcon} />
      ) : (
        <StarBorder onClick={onStarClick} className={classes.starIcon} />
      )}
    </div>
  );
};

export default ModuleItemView;
