import { type ReactElement, useRef, useEffect } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { useLocale } from 'react-admin';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { CustomAccordion } from '../custom-accordion';
import { Table as CustomFormTable } from '../table';
import { DynamicInput } from '../dynamic-input';
import { useStyles } from './record-edit-details.style';

import type { RecordDetailsPropsInterface } from './record-edit-details.type';
import {
  getInitialGroupsForEachTab,
  handleHiddenGroups,
} from '../form/form-with-tabs/form-with-tabs.helper';
import type { HandleHiddenGroups } from '../form';

export const RecordShowDetailsView = (
  props: RecordDetailsPropsInterface,
): ReactElement => {
  const {
    selectedTab,
    tabList,
    tabChangeHandler,
    tabsTitlesRef,
    relationListCustomFunctions,
  } = props;

  const inputsChangeFunctionsRef = useRef<Record<string, HandleHiddenGroups>>({});
  const inputsChangeFunctionsInitialized = useRef(false);
  const groupContainersRef = useRef<
    Record<string, Record<string, HTMLDivElement | null>>
  >(getInitialGroupsForEachTab(tabList));

  const classes = useStyles();
  const theme = useTheme();
  const locale = useLocale();

  useEffect(() => {
    // handle initial (hidden/visible) of group's container
    for (const tabId in inputsChangeFunctionsRef.current) {
      const groupsStatus =
        inputsChangeFunctionsRef.current[tabId].getTabGroupsState();

      onHiddenGroupsChange(groupsStatus, tabId);
    }
  }, []);

  // for each tab this function will be invoked to handle (hidden/visible) of group's container
  const onHiddenGroupsChange = (newHiddenSubpanels, tabId) => {
    for (const groupId in newHiddenSubpanels) {
      const groupElement =
        groupContainersRef.current[String(tabId)][String(groupId)];

      if (groupElement) {
        groupElement.hidden = newHiddenSubpanels[groupId];
      }
    }
  };

  // implement singleton design pattern for assign onHiddenGroupsChange to each tab's groups
  if (!inputsChangeFunctionsInitialized.current) {
    inputsChangeFunctionsInitialized.current = true;

    inputsChangeFunctionsRef.current = Object.fromEntries(
      tabList.map(tab => {
        return [String(tab.id), handleHiddenGroups(tab, onHiddenGroupsChange)];
      }),
    );
  }

  return (
    <Tabs
      defaultActiveKey={selectedTab ?? '0'}
      direction={theme.direction}
      onChange={index => {
        tabChangeHandler(index);
        relationListCustomFunctions?.current?.onActiveTabChange?.(+index);
      }}
      data-style-tabs="tabs"
    >
      {tabList.map((tab, index) => {
        return (
          <TabPane
            key={tab.id}
            tab={
              <p
                ref={tabRef => {
                  tabsTitlesRef.current[index] = tabRef;
                }}
              >
                {tab.translatedTitle?.[locale] ?? tab.title ?? ''}
              </p>
            }
          >
            <div className={classes.tabGroupsContainer}>
              {tab.groupList &&
                tab.groupList.length &&
                tab?.groupList.map((group, index: number) => {
                  const { translatedTitle = {}, id } = group;
                  const title: string =
                    translatedTitle?.[locale] ?? String(id) ?? '';

                  return (
                    <div
                      key={index}
                      className={classes.groupContainer}
                      ref={ref => {
                        groupContainersRef.current[String(tab.id)][
                          String(group.id)
                        ] = ref;
                      }}
                    >
                      <CustomAccordion
                        id={`formWithTabAccordion_${id}`}
                        enableChild
                        defaultExpanded
                        summary={
                          <div className={classes.titleSummary}>
                            <Typography
                              variant="body2"
                              data-style-accordion-text="accordionText"
                              data-tag="accordion-summuary-title"
                            >
                              {title}
                            </Typography>
                            {group.specialFields?.map(field => (
                              //TODO: use field like code below
                              // getTypeByField(field) === ICON_FIELD ? (
                              //   <IconField field={field} data={record} />
                              // ) : (
                              //   <ColorField field={field} data={record} />
                              // )
                              <DynamicInput key={field.id} field={field} />
                            ))}
                          </div>
                        }
                      >
                        <CustomFormTable
                          className={classes.table}
                          group={group}
                          type="form"
                          onInputStateChange={
                            inputsChangeFunctionsRef.current[String(tab.id)]
                              .onInputStateChange
                          }
                        />
                      </CustomAccordion>
                    </div>
                  );
                })}
            </div>
          </TabPane>
        );
      })}
    </Tabs>
  );
};
