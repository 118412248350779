import type { CompatibleTreeData, RawTreeData } from './permission-assigned.type';

/**
 * prepare permission tree data
 * @function preparePermissionTreeData
 * @param {RawTreeData[]} data
 * @param {number | null} parentId
 * @returns {CompatibleTreeData[]}
 */
export const preparePermissionTreeData = (
  data: RawTreeData[],
  parentId: number | null = null,
): CompatibleTreeData[] => {
  const result: RawTreeData[] | CompatibleTreeData[] = data.filter(
    menu => menu.parentid === parentId,
  );

  for (const i in result) {
    const item = result[i];
    const id = item.id;

    (item as CompatibleTreeData).items = preparePermissionTreeData(data, id);
    (item as CompatibleTreeData).isOk = true;
  }

  return result as CompatibleTreeData[];
};
