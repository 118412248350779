import { actorGetActionValue } from '../../../type/actor-setup';

import type { MenuItemParams } from '../../menu/sidebar-menu';
import { SelectedItemForPermissionAdvance } from '../../show-permissions/simple-grid';
import type { PermissionsMenuItem } from './permission-menu.type';

/**
 * convert menu data to readable format for devExpress tree
 * @function prepareCompatibleTreeDataForDevExpressTree
 * @param {MenuItemParams[]} menuData
 * @returns {MenuItemParams[]}
 */
export const prepareCompatibleTreeDataForDevExpressTree = (
  menuData: MenuItemParams[],
  selectItems?: SelectedItemForPermissionAdvance,
): PermissionsMenuItem[] => {
  const preparedItem: Array<PermissionsMenuItem> = [];
  const locale = actorGetActionValue('reactAdminHelpers')?.locale ?? 'fa';

  menuData.forEach(menu => {
    const preparedMenu = {
      label: menu.translatedTitle?.[locale] ?? menu.title,
      id: menu.id ?? menu.menu_id ?? menu.menu_Id ?? 0,
      paramKey: menu?.allowViewPermKey ?? '',
      allowView: menu?.allowView,
      selected: selectItems?.[menu?.allowViewPermKey ?? '']?.checked ?? false,
    };

    if (menu.children && menu.children.length > 0) {
      preparedMenu['items'] = prepareCompatibleTreeDataForDevExpressTree(
        menu.children,
      );
    }

    preparedItem.push(preparedMenu);
  });

  return preparedItem;
};
