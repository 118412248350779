import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import lodashGet from 'lodash/get';
import { ReactElement, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { createPortal } from 'react-dom';
import { isEmptyObject } from '../../helper/data-helper';
import { useStyles } from './notification-app.style';
import {
  CustomSnackbarInterface,
  NotificationAppViewInterface,
} from './notification-app.type';

const NotificationAppView = (props: NotificationAppViewInterface): ReactElement => {
  const {
    notification,
    handleExited,
    handleTooltipClose,
    tooltipOpen,
    copyToClipboard,
    handleRequestCloseSnackbar,
    typeSnackbar,
    classNameSnackbar,
    undoClickSnackbar,
  } = props;
  const {
    fromQuickCreateDialog = false,
    forceSnackbar = false,
    requestId = '',
    messageArgs = {},
  } = notification?.options ?? {};
  const classes = useStyles();
  const translate = useTranslate();

  const shouldConfirm =
    notification &&
    (notification.type === 'error' || notification.type === 'warning') &&
    (!forceSnackbar || forceSnackbar !== true);

  const _CustomSnackbar = (
    <CustomSnackbar
      notification={notification}
      handleRequestClose={handleRequestCloseSnackbar}
      type={typeSnackbar}
      className={classNameSnackbar}
      undoClick={undoClickSnackbar}
    />
  );

  if (shouldConfirm || !!notification?.options?.forceShowInDialog) {
    return (
      <Dialog open={true} data-test-notification-dialog>
        <DialogContent>
          <DialogContentText
            className={classes.contentText}
            data-test-notification="content"
          >
            {translate(lodashGet(notification, 'message'), messageArgs)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className={classes[notification?.type ?? '']}
            onClick={handleExited}
            data-test-notification="confirm"
          >
            {translate('ra.action.confirm')}
          </Button>
        </DialogActions>
        {requestId !== '' && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={translate('general.copied')}
            >
              <div className={classes.requestId} onClick={copyToClipboard}>
                {translate('general.requestId')}: <span>{requestId}</span>
              </div>
            </Tooltip>
          </ClickAwayListener>
        )}
      </Dialog>
    );
  }
  if (notification && !isEmptyObject(notification)) {
    if (fromQuickCreateDialog === true) {
      let SnackbarContainer: HTMLElement | null = null;

      if (typeof document !== 'undefined') {
        SnackbarContainer = document.getElementById('customSnackContainer');
      }

      if (!SnackbarContainer) {
        return _CustomSnackbar;
      } else {
        return createPortal(_CustomSnackbar, SnackbarContainer);
      }
    } else {
      return _CustomSnackbar;
    }
  } else {
    return <></>;
  }
};

export default NotificationAppView;

const CustomSnackbar = (props: CustomSnackbarInterface): ReactElement => {
  const { notification, handleRequestClose, type, className, undoClick, ...rest } =
    props;
  const translate = useTranslate();
  const classes = useStyles();
  const { confirm, warning, error } = classes;
  const snackbarClasses: Partial<ClassNameMap<string>> = {
    confirm,
    warning,
    error,
  };
  const {
    messageArgs = {},
    undoable = false,
    autoHideDuration = 4000,
  } = notification?.options ?? {};

  useEffect(() => {
    //when the focus is lost,autoHideDuration doesn't work. we had to force close snack bar
    setTimeout(() => {
      handleRequestClose();
    }, 4000);
  }, [notification]);
  return (
    <Snackbar
      open={!isEmptyObject(notification)}
      onClose={handleRequestClose}
      message={
        notification &&
        notification.message &&
        translate(lodashGet(notification, 'message'), messageArgs)
      }
      autoHideDuration={notification && autoHideDuration}
      disableWindowBlurListener={true}
      ContentProps={{
        className: classNames(
          classes[(notification && notification.type) || type],
          className,
        ),
      }}
      action={
        notification && undoable ? (
          <Button
            color="primary"
            className={classes.undo}
            size="small"
            onClick={undoClick}
          >
            {translate('ra.action.undo')}
          </Button>
        ) : null
      }
      classes={snackbarClasses}
      {...rest}
    />
  );
};
