import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    margin: 5,
    overflow: 'auto',

    '@media (max-width: 1024px)': {
      maxHeight: 700,
    },
  },
  typography: {
    textAlign: 'center',
    flex: 1,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '2px 0px',
    padding: '0px 10px',
    alignItems: 'center',
  },
  divider: {
    width: '95%',
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
  },
  title: {
    textAlign: 'start',
    width: '100%',
    padding: '0px 10px',
  },
  searchInput: {
    width: '95%',
    margin: '10px 0px',

    '& > div:nth-of-type(1)': {
      height: 40,
    },
  },
  tree: {
    flex: 1,
    width: '100%',
  },
  treeContainer: {
    overflow: 'auto',
    width: '100%',
  },
  spaceBetween: {
    justifyContent: 'space-around',
  },
}));
