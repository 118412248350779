import { ReactElement } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import FileStreamCustomChip from '../../dynamic-input/multi-file-stream-input/multi-file-stream-custom-chip';
import { useStyles } from './todo-file.style';

import type { CustomFileStreamInputViewInterface } from './todo-file.type';

const TodoFileView = (props: CustomFileStreamInputViewInterface): ReactElement => {
  const {
    onDeleteFile,
    onChangeFileStreamValue,
    handleOpenFile,
    handleClickInputFile,
    inputFileRef,
    fileStreamValue,
    name,
    required,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <fieldset>
        <input
          className={classes.inputTodoFile}
          name={name}
          id={name}
          onChange={onChangeFileStreamValue}
          type="file"
          required={required}
          ref={inputFileRef}
          multiple
        />
        <div onClick={handleClickInputFile}>
          <IconButton onClick={handleClickInputFile}>
            <AttachFileIcon fontSize="small" />
          </IconButton>
          <Typography noWrap variant="caption">
            {translate('ra.input.selectFile')}
          </Typography>
        </div>
      </fieldset>
      <div>
        {Array.isArray(fileStreamValue) && fileStreamValue.length
          ? fileStreamValue.map((file, index) => {
              return (
                <FileStreamCustomChip
                  onItemClick={handleOpenFile(file.filePath)}
                  realFileName={file.realFileName}
                  filePath={file.filePath}
                  index={index}
                  size={file.size}
                  onDeleteButtonClick={onDeleteFile}
                />
              );
            })
          : null}
      </div>
    </>
  );
};

export default TodoFileView;
