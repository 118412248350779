import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '98%',
    marginTop: '1rem',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'unset',
      alignItems: 'unset',
      flexDirection: 'column',
    },
  },
  btnRoot: {
    margin: '5px auto',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },

  splitBtnRoot: {
    fontSize: 12,
    fontWeight: 300,
    color: '#fff',
  },
  btn: {
    background: '#1D85D6 !important',
    borderColor: '#fff !important',
  },

  toggleBtn: {
    minWidth: '0 !important',
    background: '#1D85D6 !important',
  },

  formContainer: {
    minWidth: '500px',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '0 1rem',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      minHeight: 'auto',
    },
  },

  inputContainer: {
    flexGrow: 1,
    margin: '.25rem 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0',
    },
  },
}));
