import { ReactElement } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { useTranslate } from 'react-admin';

import { BooleanInputViewProps } from './boolean-input.type';
import { useStyles } from './boolean-input.style';
import InputBase from '../../input-base';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { Tooltip, useTheme } from '@material-ui/core';
import { castToBoolean } from '../../../helper/validation-helper';
import { sanitizeRestProps } from '../dynamic-input.helper';

const BooleanInputView = (props: BooleanInputViewProps): ReactElement => {
  const {
    visibleClass,
    className,
    id,
    disabled,
    value,
    getRef,
    resource,
    label,
    hint,
    source,
    handleChange,
    handleFocus,
    handleBlur,
    field,
    isFocused,
    isBlurred,
    options,
    inputMessage,
    ...rest
  } = props;

  const { name, customOption } = field;

  const classes = useStyles({ messageType: inputMessage?.messageType });
  const theme = useTheme();
  const translate = useTranslate();

  let tooltipTitle = '';
  if (!isEmpty(customOption.additionalDataFieldAlt)) {
    tooltipTitle = castToBoolean(customOption.additionalDataFieldAlt)
      ? translate('general.enable')
      : translate('general.disable');
  }

  return (
    <InputBase
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      required={field.required}
      field={field}
    >
      <Tooltip
        title={tooltipTitle}
        placement={theme.direction === 'rtl' ? 'left' : 'right'}
      >
        <div
          className={`${classes.booleanInputViewContainer} ${
            !isEmptyObject(inputMessage)
              ? classes.booleanInputViewContainerError
              : ''
          } ${disabled ? classes.disabled : ''}`}
          style={customOption.customStyleAdditionalDataField}
        >
          <FormGroup
            className={className}
            data-focus={isFocused}
            data-blur={isBlurred}
            {...sanitizeRestProps(rest as any)}
            id="switchButton"
          >
            <Switch
              ref={getRef}
              data-test-bool-input-value={!!value}
              {...options}
              id={id}
              color="primary"
              checked={!!value}
              name={name}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={disabled}
            />
          </FormGroup>
        </div>
      </Tooltip>
    </InputBase>
  );
};

export default BooleanInputView;
