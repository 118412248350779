import type { ReactElement } from 'react';
import { useTranslate } from 'react-admin';

import { AppContainer } from '../app-container';
import { AppDrawer } from '../app-drawer';
import CustomCssContentView from './custom-css-content-view';
import getCssSidebarRoutesConfig from './custom-css.helper';
import type { CustomCssViewType } from './custom-css.type';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const CustomCssView = (props: CustomCssViewType): ReactElement => {
  const { onChangeDataStyle, value, sendData } = props;

  const translate = useTranslate();

  return (
    <AppContainer
      sidebarContent={
        <div data-style-cssSidebar="cssSidebarCss">
          <DndProvider backend={HTML5Backend}>
            <AppDrawer
              routesConfig={getCssSidebarRoutesConfig(translate)}
              baseUrl="/customcss"
              data-test="css-sidebar-drawer"
              parentComponentName={'customcss'}
              canDragDrop={false}
            />
          </DndProvider>
        </div>
      }
    >
      <CustomCssContentView
        onChangeDataStyle={onChangeDataStyle}
        value={value}
        sendData={sendData}
      />
    </AppContainer>
  );
};

export default CustomCssView;
