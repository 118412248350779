import type { FC } from 'react';
import { useTranslate } from 'react-admin';

import { FilterSaveDialogView } from './filter-save-dialog.view';
import type { FilterSaveDialogPropsInterface } from './filter-save-dialog.type';

const FilterSaveDialogController: FC<FilterSaveDialogPropsInterface> = props => {
  const { dialogData, closeDialogHandler } = props;
  const { filterNameInputChangeHandler, onSave } = dialogData;

  const translate = useTranslate();
  return (
    <FilterSaveDialogView
      translate={translate}
      closeDialogHandler={closeDialogHandler}
      handleNameChange={filterNameInputChangeHandler}
      saveFilterHandler={onSave}
    />
  );
};

export default FilterSaveDialogController;
