import {
  memo,
  FC,
  useImperativeHandle,
  useRef,
  useEffect,
  SetStateAction,
} from 'react';
import { getTypeByField, inputTypes } from '../../../helper/InputHelper';
import { GridBooleanInput } from './grid-boolean-input';
import { GridDateInput } from './grid-date-input';
import { GridDropDownInput } from './grid-dropdown-input';
import { GridTextInput } from './grid-text-input';

import type { GridCustomInputInterface } from './grid-custom-input.type';
import { InputAppearanceCharacteristics } from '../../../helper/meta-helper.type';

const GridCustomInputController: FC<GridCustomInputInterface> = props => {
  const {
    focusOnLastInput,
    onChangeInput,
    isDisabled = false,
    resource,
    field,
    inputsCustomFunctionRef,
  } = props;

  const customInputRef = useRef<{
    updateInputCharacteristics: (value: InputAppearanceCharacteristics) => void;
  }>();

  useEffect(() => {
    updateFormDataCharacteristics({ enable: !isDisabled, visible: !isDisabled });
  }, []);

  const updateFormDataCharacteristics = (
    newValue: InputAppearanceCharacteristics,
  ) => {
    if (
      customInputRef.current &&
      typeof customInputRef.current.updateInputCharacteristics === 'function'
    ) {
      customInputRef.current.updateInputCharacteristics(newValue);
    }
  };

  useImperativeHandle(inputsCustomFunctionRef, () => ({
    ...inputsCustomFunctionRef?.current,
    [field?.name ?? '']: {
      updateFormDataCharacteristics,
    },
  }));

  if (!field) return <></>;
  const fieldType = getTypeByField(field);

  switch (fieldType) {
    case inputTypes.DATE_FIELD:
      return (
        <GridDateInput
          field={field}
          focusOnLastInput={focusOnLastInput}
          onChangeInput={onChangeInput}
          customInputRef={customInputRef}
        />
      );
    case inputTypes.DROPDOWN_FIELD:
      return (
        <GridDropDownInput
          field={field}
          onChangeInput={onChangeInput}
          resource={resource}
          customInputRef={customInputRef}
        />
      );
    case inputTypes.BOOLEAN_FIELD:
      return (
        <GridBooleanInput
          field={field}
          onChangeInput={onChangeInput}
          customInputRef={customInputRef}
        />
      );
    default:
      return (
        <GridTextInput
          field={field}
          onChangeInput={onChangeInput}
          customInputRef={customInputRef}
        />
      );
  }
};
export default memo(GridCustomInputController, (prevProps, nextProps) => {
  if (
    prevProps.field?.name !== nextProps.field?.name ||
    prevProps.resource !== nextProps.resource ||
    prevProps.isDisabled !== nextProps.isDisabled
  ) {
    return false;
  }
  return true;
});
