import { memo, ReactElement, useState } from 'react';

import PermissionTypeView from './permission-type.view';
import { actorSetActionValue } from '../../../type/actor-setup';
import { staticData } from './permissions.helper';

import type { OnTypeSelect } from './permission-type.type';

const PermissionTypeController = (): ReactElement => {
  const [formState, setFormState] = useState<Record<string, boolean>>({});
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  /**
   * handle type select
   * @function onTypeSelect
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @returns void
   */
  const onTypeSelect: OnTypeSelect = event => {
    try {
      let newValues;

      if (event.target.name === 'selectAll') {
        if (
          Object.values(formState).filter(item => item === true).length !==
          staticData.length
        ) {
          // select all
          newValues = Object.fromEntries(
            staticData.map(row => {
              return [row.value, true];
            }),
          );
          setIsSelectAll(true);
        } else {
          // deselect all
          newValues = Object.fromEntries(
            staticData.map(row => {
              return [row.value, false];
            }),
          );
          setIsSelectAll(false);
        }
      } else {
        // single checkbox normal treat
        newValues = { ...formState, [event.target.name]: event.target.checked };

        // deselect select all if all of items didn't select
        if (
          Object.values(newValues).filter(item => item === true).length !==
          staticData.length
        ) {
          setIsSelectAll(false);
        }
      }

      setFormState(newValues);

      const trueIds = Object.keys(newValues)
        .filter(id => newValues[id] === true)
        .map(Number);

      actorSetActionValue('userPermissionValue', trueIds, {
        path: 'selectedPermissionTypes',
        disableDebounce: true,
      });
    } catch {
      console.error('onTypeSelect failed');
    }
  };

  return (
    <PermissionTypeView
      permissionTypes={staticData}
      onTypeSelect={onTypeSelect}
      formState={formState}
      isSelectAll={isSelectAll}
    />
  );
};

export default memo(PermissionTypeController, () => true);
