import { type ChangeEvent, type FC, memo, useState, useRef } from 'react';
import { actorGetActionValue, actorSetActionValue } from '../../../type/actor-setup';
import UserDescriptionDialogView from './user-description-dialog.view';

import type { UserDescriptionDialogInterface } from './user-description-dialog.type';

/*
!!!IMPORTANT

this component was using simple form before if you want to change it back for any reason i did not delete anything
simply just get field from props
uncomment simpleform component

*/
const UserDescriptionDialogController: FC<UserDescriptionDialogInterface> = memo(
  props => {
    const { onSubmit } = props;
    const [isLoading, setIsLoading] = useState(false);
    const descriptionFieldValueRef = useRef<string>('');
    const currentResource = actorGetActionValue('resources')!.current;
    const formData = actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    )! as Record<string, unknown>;

    /**
     * @function onSubmitForm
     * @param {React.FormEvent<Element>} event
     * @return {Promise<void>}
     */
    const onSubmitForm = async (event: React.FormEvent<Element>): Promise<void> => {
      event.preventDefault();
      event.persist();
      const finalCorrectFormData = {
        ...formData,
        __userDescription: descriptionFieldValueRef.current,
      };
      actorSetActionValue('formData', finalCorrectFormData, {
        path: `${currentResource.value}.${currentResource.type}`,
        callerScopeName: 'form/simple-form-dialog/simple-form-dialog.controller.tsx',
      });

      setIsLoading(true);
      await onSubmit(finalCorrectFormData);
      setIsLoading(false);
    };

    /**
     * @function onSubmitForm
     * @param {React.FormEvent<Element>} event
     * @return {Promise<void>}
     */
    const onChange = (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
      descriptionFieldValueRef.current = event.target.value;
    };

    return (
      <UserDescriptionDialogView
        {...props}
        onSubmitForm={onSubmitForm}
        isLoading={isLoading}
        defaultTextFieldValue={(formData.__userDescription as string | null) ?? ''}
        onChange={onChange}
      />
    );
  },
);

export default UserDescriptionDialogController;
