import { FC, useEffect, useState } from 'react';
import { useTranslate, refreshView as refreshViewAction } from 'react-admin';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core';
import compose from 'recompose/compose';

import {
  actorDispatch,
  actorOnDispatch,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { apiRequestResultHandler } from '../../../../helper/crud-api.helper';
import ConfirmDialogHOC from '../../../../container/ConfirmDialogHOC';
import NoteStreamCardView from './note-stream-card.view';
import { requestListData } from '../../../list/list.helper';
import { isEmpty } from '../../../../helper/data-helper';
import { showNotification } from '../../../../helper/general-function-helper';
import { getRootTableId } from '../../../../helper/meta-helper';
import { getPrimaryField } from '../../../../helper/MetaHelper';
import { resourceForNote } from '../create-edit-note/constants';
import { NoteDataInterface } from '../create-edit-note';

import type { NoteAttachment, NoteStreamCardProps } from './note-stream-card.type';

const CHAR_LIMIT = 120;

const NoteStreamCard: FC<NoteStreamCardProps> = props => {
  const {
    isCardInNoteStream,
    forceShowFullText,
    openConfirmDialog, // TODO: refactor and remove ConfirmDialogHOC in another card
    relationResource,
    relationMetaData,
    parentResource,
    parentRecordId,
    childFieldName,
    parentFieldName,
    relationData,
    isDetailView,
  } = props;

  const [attachments, setAttachments] = useState<Array<NoteAttachment> | null>(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [showFullText, setShowFullText] = useState<boolean>(false);
  const [shortNote, setShortNote] = useState<string | null>(null);
  const [fullNote, setFullNote] = useState<string | null>(null);
  const [isPinNote, setIsPinNote] = useState<boolean>(
    relationData?.ispin as boolean,
  );

  const reduxDispatch = useDispatch();
  const translate = useTranslate();
  const theme = useTheme();

  const relationPrimaryKey: string = getPrimaryField(relationMetaData)?.name;

  useEffect(() => {
    getDataFromNote();

    // TODO: its a temporary actor state and should implement in dialog stack in another card
    actorOnDispatch(
      'isNoteCreateEditDialogOpen',
      isOpen => {
        setIsEditDialogOpen(isOpen);
      },
      { preserve: false },
    );
  }, []);

  /**
   * extract data from notes
   * @function getDataFromNote
   * @returns {void} void
   */
  const getDataFromNote = (): void => {
    let decoded;
    try {
      decoded = JSON.parse(
        (relationData.note as string) ?? (relationData.NoteText as string),
      );
    } catch (error) {
      decoded = { text: relationData.note };
    }

    // The value of 'showFullText' specifies the full or short view of the message based on 'forceShowFullText'.
    const _showFullText = forceShowFullText
      ? forceShowFullText
      : (decoded.text?.length ?? decoded.NoteText?.length) <= CHAR_LIMIT;

    setShowFullText(_showFullText);
    setFullNote(decoded.text ?? decoded.NoteText);
    setShortNote(showFullText ? null : substringForLink(decoded.text));
    setAttachments(decoded.attachmentArr);
    setIsPinNote(Boolean(relationData?.ispin));
  };

  useEffect(() => {
    getDataFromNote();
  }, [relationData.note]);

  /**
   * show full data of note
   * @function toggleShowFullText
   * @returns {void} void
   */
  const toggleShowFullText = (): void => {
    setShowFullText(prevState => !prevState);
  };

  /**
   * cut the text
   * @function substringForLink
   * @param {string} text
   * @returns {string}
   */
  const substringForLink = (text: string): string => {
    if (isEmpty(text)) return text;

    let tempText = text.trim();

    if (tempText.length && tempText.length > CHAR_LIMIT) {
      tempText = tempText.substr(0, CHAR_LIMIT) + '...';
    }

    return tempText;
  };

  /**
   * open edit dialog
   * @function openEditDialog
   * @returns {void} void
   */
  const openEditDialog = (): void => {
    setIsEditDialogOpen(true);
  };

  /**
   * close edit dialog
   * @function closeEditDialog
   * @returns {void} void
   */
  const closeEditDialog = (): void => {
    setIsEditDialogOpen(false);
  };

  /**
   * call refresh view action and should refactor to actor
   * @function refreshView
   * @returns {void} void
   */
  const refreshView = (): void => {
    reduxDispatch(refreshViewAction());
  };

  const noteData: NoteDataInterface = {
    IsPin: isPinNote ? 0 : 1,
    tableid: getRootTableId(),
    KeyID: parentRecordId,
    NoteText: String(relationData.NoteText ?? relationData.note),
  };

  /**
   * get new data for changed relation and its parent
   * @function updateRelationData
   * @returns {void} void
   */
  const updateRelationData = (): void => {
    refreshView();

    requestListData(
      relationResource,
      'note',
      parentRecordId,
      childFieldName,
      parentFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultHandler,
    );
  };

  /**
   * delete a note
   * @function deleteNote
   * @param {number} id
   * @returns {void} void
   */
  const deleteNote = (id: number): void => {
    actorDispatch(
      'crudAction',
      {
        type: 'RUN_SERVICE',
        data: {
          params: { ...noteData, isdeleted: 1, NoteID: id },
        },
        actionUniqueId: resourceForNote,
        onSuccess: () => {
          updateRelationData();
          actorDispatch('refreshView', 'newDataNotePin');
        },
        onFailure: (error: unknown): void => showNotification(error, 'error'),
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'NoteStreamCard => deleteNote',
      },
    );
  };

  /**
   * reset states,and request new relation data
   * @function onEditSuccess
   * @returns {void} void
   */
  const onEditSuccess = (): void => {
    actorDispatch('refreshView', 'newDataNotePin');
    updateRelationData();
    setIsPinNote(!isPinNote);
    actorSetActionValue('scroll', {
      uniqIdToScroll: '',
    });
  };
  /**
   * pin or unpin a note
   * @function deleteNote
   * @param {number} id
   * @returns {void} void
   */
  const pinNote = (id: number): void => {
    actorDispatch(
      'crudAction',
      {
        type: 'RUN_SERVICE',
        data: {
          params: { ...noteData, NoteID: id },
        },
        actionUniqueId: resourceForNote,
        onSuccess: onEditSuccess,
        onFailure: (error: unknown): void => {
          if (typeof error === 'string') {
            showNotification(error, 'error');
          }
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'NoteStreamCard => pinNote',
      },
    );
  };

  /**
   * open confirm dialog for status pin note
   * @function openStatusNoteDialog
   * @param {number} uniqId
   * @returns {void} void
   */
  const openStatusNoteDialog = (uniqId: number) => (): void => {
    openConfirmDialog({
      content: isPinNote
        ? translate('confirm.areYouSureYouWantToUnPinWholeTable')
        : translate('confirm.areYouSureYouWantToPinWholeTable'),
      onConfirm: (): void => pinNote(uniqId),
      color: theme.palette.primary.main,
    });
  };

  /**
   * open confirm dialog for delete note
   * @function openDeleteDialog
   * @param {number} id
   * @returns {void} void
   */
  const openDeleteDialog = (id: number) => (): void => {
    // TODO: refactor and remove ConfirmDialogHOC in another card
    openConfirmDialog({
      content: translate('confirm.areYouSureYouWantToDeleteThisNote'),
      onConfirm: (): void => deleteNote(id),
      color: theme.palette.error.main,
    });
  };

  /**
   * close edit note dialog and refresh relation data
   * @function onSubmitNote
   * @returns {void} void
   */
  const onSubmitNote = (): void => {
    closeEditDialog();
    requestListData(
      relationResource,
      'note',
      parentRecordId,
      childFieldName,
      parentFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultHandler,
    );
  };

  return (
    <div>
      <NoteStreamCardView
        parentRecordId={parentRecordId}
        isCardInNoteStream={isCardInNoteStream}
        toggleShowFullText={toggleShowFullText}
        openDeleteDialog={openDeleteDialog}
        openStatusNoteDialog={openStatusNoteDialog}
        relationResource={relationResource}
        isEditDialogOpen={isEditDialogOpen}
        parentFieldName={parentFieldName}
        closeEditDialog={closeEditDialog}
        openEditDialog={openEditDialog}
        parentResource={parentResource}
        onSubmitNote={onSubmitNote}
        relationData={relationData}
        showFullText={showFullText}
        isDetailView={isDetailView}
        refreshView={refreshView}
        attachments={attachments}
        shortNote={shortNote}
        fullNote={fullNote}
        isPinNote={isPinNote}
        relationPrimaryKey={relationPrimaryKey}
      />
    </div>
  );
};

// TODO: refactor and remove ConfirmDialogHOC in another card
export default compose(ConfirmDialogHOC)(NoteStreamCard);
