import GadgetDefaultValuesTabView from './gadget-default-values-tab.view';

import type { FC } from 'react';
import type { GadgetDefaultValuesTabControllerProps } from './gadget-default-values-tab.type';

const GadgetDefaultValuesTabController: FC<
  GadgetDefaultValuesTabControllerProps
> = props => {
  const { gadgetInternalChangeHandler, gadgetValues } = props;

  return (
    <GadgetDefaultValuesTabView
      gadgetInternalChangeHandler={gadgetInternalChangeHandler}
      gadgetValues={gadgetValues}
    />
  );
};

export default GadgetDefaultValuesTabController;
