import ChatPinsView from './chat-pins.view';

import { useEffect, useState, memo, type FC, type MouseEvent } from 'react';
import { highlightMessageAndScrollTo } from '../messages-list/message-item/message-item.helper';
import { chatStatelessActions } from '../chat-section-helper/chat-section-stateless-actions';
import { useTranslate } from 'react-admin';

import { checkJsonStringify } from '../../../helper/data-helper';
import { ChatTextJsonFormatInterface } from '../new-message';
import { getSummeryMessageText } from '../chat-sidebar/user-list/chat-item/chat-item.helper';
import { actorDispatch } from '../../../type/actor-setup';
import { pinOrUnpinMessage } from '../chat-section.helper';

import type { MessageItemInterface } from '../chat-section.type';
import type { ChatPinsPropsInterface } from './chat-pins.type';

const ChatPinsController: FC<ChatPinsPropsInterface> = memo(props => {
  const { pinMessagesList } = props;

  const [pinMessageIndex, setPinMessageIndex] = useState<number>(-1);

  const translate = useTranslate();

  useEffect(() => {
    if (pinMessagesList.length > 0) {
      setPinMessageIndex(pinMessagesList.length - 1);
    }
  }, [pinMessagesList.length]);

  const handleOnPinSectionClick = (pinMessage: MessageItemInterface) => (): void => {
    const targetElement = highlightMessageAndScrollTo(pinMessage.chat_id);

    if (!targetElement) {
      // FIXME: @honarvar @alasti: We need a loading state for requests that take time to response
      chatStatelessActions.fetchSpecificMessages({
        olderMessages: true,
        targetMessageId: pinMessage.chat_id,
        successCallback: () => {
          // FIXME: @honarvar: Check it to find a better solution (if possible)
          setTimeout(() => {
            highlightMessageAndScrollTo(pinMessage.chat_id);
          }, 100);
        },
      });
    }

    setPinMessageIndex(prev => {
      if (prev === 0) {
        return pinMessagesList.length - 1;
      }
      return prev - 1;
    });
  };

  const getPinMessageText = (): string => {
    if (pinMessagesList[pinMessageIndex] == null) return '';

    const parseMessageResult = checkJsonStringify<ChatTextJsonFormatInterface>(
      pinMessagesList[pinMessageIndex].chattext,
    );
    if (parseMessageResult === false) {
      return pinMessagesList[pinMessageIndex].chattext;
    }

    return getSummeryMessageText(
      pinMessagesList[pinMessageIndex].chattext,
      translate,
    );
  };

  const clearHandler = (event: MouseEvent<HTMLButtonElement>) => {
    // The parent element shouldn't be clicked
    event.stopPropagation();

    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate('ra.message.delete_content'),
        onCancel: (): void => {
          actorDispatch('closeCurrentDialog', true);
        },
        onConfirm: (): void => {
          actorDispatch('closeCurrentDialog', true);
          pinOrUnpinMessage(pinMessagesList[pinMessageIndex]);
        },
      },
    });
  };

  return (
    <ChatPinsView
      pinMessages={pinMessagesList}
      pinMessageIndex={pinMessageIndex}
      pinMessageText={getPinMessageText()}
      handleOnPinSectionClick={handleOnPinSectionClick}
      onClear={clearHandler}
    />
  );
});

export default ChatPinsController;
