import { FC, memo, useRef, useState, useEffect, MouseEvent } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useLocation, useHistory } from 'react-router-dom';

import { isEmpty } from '../../../../helper/data-helper';
import { actorDispatch } from '../../../../type/actor-setup';
import NavCollapseView from './nav-collapse.view';

import type { NavCollapseInterface } from './nav-collapse.type';

const NavCollapseController: FC<NavCollapseInterface> = memo(props => {
  const {
    item,
    parents,
    level,
    baseUrl,
    canDragDrop,
    parentComponentName,
    open,
    lastExpandedItems,
    setParentOpen,
    collapseChangingCallback,
  } = props;
  const location = useLocation();
  const history = useHistory();

  const openChangedAfterFirstRenderRef = useRef(true);
  const [isOpen, setIsOpen] = useState<boolean>(
    location.hash.includes(`open${item?.id}`),
  );

  const [isContextMenuOpen, setContextMenuIsOpen] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const dragDropRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (open != null) {
      setIsOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (!openChangedAfterFirstRenderRef.current) {
      if (isOpen) setParentOpen?.();
    }

    return () => {
      if (isOpen != null) {
        openChangedAfterFirstRenderRef.current = false;
      }
    };
  }, [isOpen]);

  /**
   * @function handleContextMenu
   * @param { React.MouseEvent } event
   * @returns { void }
   */
  const handleContextMenu = (event: React.MouseEvent): void => {
    if (item?.hasContextMenu) {
      event.preventDefault();
      setContextMenuIsOpen(
        isContextMenuOpen === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    }
  };

  /**
   * @function handleCloseContextMenu
   * @returns { void }
   */
  const handleCloseContextMenu = (): void => {
    setContextMenuIsOpen(null);
  };

  /**
   * @function openMenu
   * @returns { void }
   */
  const openMenu = (): void => {
    setIsOpen(true);
  };

  /**
   * @function onToggle
   * @returns { void }
   */
  const onToggle = (event: MouseEvent): void => {
    event.stopPropagation();

    if (!isOpen && typeof item?.onClick === 'function') {
      item.onClick();
    }

    if (isEmpty(item.url)) {
      collapsingToggle();
    } else {
      setIsOpen(isOpen => !isOpen);
      history.push(item.url);
    }
  };

  /**
   * @function collapsingToggle
   * @returns { void }
   */
  const collapsingToggle = (): void => {
    let currentOpened = false;
    setIsOpen(prevOpen => {
      currentOpened = !prevOpen;
      return currentOpened;
    });

    collapseChangingCallback?.(
      currentOpened,
      { self: item, parents: parents ?? [] },
      parentComponentName,
    );
  };

  /**
   * @function handleDroppedItem
   * @param {Record<string,unknown>} draggedItem
   * @returns { void }
   */
  const handleDroppedItem = (draggedItem: Record<string, unknown>): void => {
    actorDispatch(
      'dragDropAction',
      {
        draggedItem,
        droppedItem: item,
        parentComponentName,
      },
      {
        disableDebounce: true,
      },
    );
  };

  //@ts-ignore
  const [, connectDragSource] = useDrag(() => ({
    type: 'navCollapse',
    item,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  const [{ isOver }, connectDropSource] = useDrop({
    accept: ['navCollapse', 'navItem'],
    drop: draggedItemProperty =>
      handleDroppedItem(draggedItemProperty as unknown as Record<string, unknown>),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  connectDragSource(connectDropSource(dragDropRef));

  return (
    <NavCollapseView
      openMenu={openMenu}
      collapsingToggle={collapsingToggle}
      onToggle={onToggle}
      onContextMenu={handleContextMenu}
      handleCloseContextMenu={handleCloseContextMenu}
      item={item}
      parents={parents}
      level={level}
      baseUrl={baseUrl}
      open={isOpen}
      isContextMenuOpen={isContextMenuOpen}
      contextMenuNode={item?.contextMenuNode}
      folderId={item?.folderId}
      canDragDrop={canDragDrop}
      parentComponentName={parentComponentName}
      dragDropRef={dragDropRef}
      isOver={isOver}
      collapseChangingCallback={collapseChangingCallback}
      lastExpandedItems={lastExpandedItems}
    />
  );
});

export default NavCollapseController;
