import { DOMAttributes } from 'react';
import Cleave from 'cleave.js/react';
import { Moment } from 'moment';

import { FormActionsHandler, InputMessage } from '../../form/form.type';
import { FieldType, ValidationErrorMessageType } from '../../../helper/Types';

export interface StyleProps {
  disabled?: boolean;
  messageType?: ValidationErrorMessageType;
}

export interface JalaliDateInputInterface {
  value: string;
  formActionsHandler: FormActionsHandler;
  resource?: string;
  field: FieldType;
  label?: string;
  hint?: string;
  inputMessage?: InputMessage;
  disabled?: boolean;
  visibleClass?: string;
  DateInputInPuzzleForm?: boolean;
  DateInputInGrid?: boolean;
  options?: object;
  getRef?: (...args) => void;
  defaultValue?: Moment;
}

export interface JalaliDateInputViewInterface {
  visibleClass: string;
  resource: string;
  inputMessage?: InputMessage;
  disabled: boolean;
  DateInputInPuzzleForm?: boolean;
  label: string;
  hint: string;
  required: boolean;
  name: string;
  internalValue: string;
  maskInputValue: string;
  calendarConfig: string;
  handleChange: (date: Moment) => void;
  handleInputMaskChange: (event: React.ChangeEvent<typeof Cleave>) => void;
  handleInputMaskBlur: (event: React.ChangeEvent<typeof Cleave>) => void;
  inputFormat?: string | undefined;
  getRef?: (...args) => void;
  options?: object;
  onFocus?: DOMAttributes<HTMLInputElement>['onFocus'];
  onBlur?: DOMAttributes<HTMLInputElement>['onBlur'];
  datePickerType: DatePickerType;
}

export type DatePickerType = 'simple' | 'dateTime';

export enum CleaveRawValueLength {
  dateTime = 12,
  simple = 8,
}

export enum DatePickerFormat {
  dateTime = 'YYYY/MM/DD HH:mm',
  simple = 'YYYY/MM/DD',
}
