import { ReactElement } from 'react';
import { TextField } from '@material-ui/core';

import { useStyles } from './number-text-field.style';

import type { NumberTextFieldViewInterface } from './number-text-field.type';

const NumberTextFieldWMSView = (
  props: NumberTextFieldViewInterface,
): ReactElement => {
  const {
    getRef,
    field,
    resource,
    inputMessage,
    label,
    customOnChange,
    onFocus,
    onBlur,
    value,
    visibleClass,
    disabled,
    customTestAttribute,
  } = props;
  const classes = useStyles({ messageType: inputMessage?.messageType });
  const { required, precision, name, maxLength } = field;

  return (
    <TextField
      {...customTestAttribute}
      type="number"
      ref={getRef}
      value={value ?? ''}
      name={name}
      label={label}
      required={required!}
      margin="normal"
      className={`${visibleClass} ${classes.wmsInput} ${classes.inputStylePuzzleForm}`}
      style={{ width: `${field.widthPercent ?? 100}%` }}
      inputMode={'numeric'}
      variant="outlined"
      onChange={customOnChange}
      onFocus={onFocus}
      onBlur={onBlur}
      InputProps={{
        classes: {
          disabled: classes.disabled,
        },
      }}
      inputProps={{
        inputMode: 'numeric',
        enterkeyhint: 'go',
        'data-test-input-name': `${resource}/${name}`,
        'data-test-precision': precision,
        maxLength,
        autoComplete: 'off',
      }}
      disabled={disabled}
      data-test="inputContainerTextField"
      data-test-has-error={inputMessage?.messageType === 'error'}
      data-test-has-warning={inputMessage?.messageType === 'warning'}
      size={'small'}
    />
  );
};

export default NumberTextFieldWMSView;
