import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  logout: {
    '&:hover': {
      backgroundColor: theme.palette.primary.profileMenuItemBackgroundColor,
      color: theme.palette.secondary.contrastText,
    },
  },
}));
