import { Dropdown } from 'semantic-ui-react';
import { useTranslate } from 'react-admin';

import { useStyles } from './single-select-filter-input.style';

import type { FC } from 'react';
import type { SingleSelectFilterInputViewProps } from './single-select-filter-input.type';

const SingleSelectFilterInputView: FC<SingleSelectFilterInputViewProps> = props => {
  const {
    getSelectRef,
    dropValue,
    preparedOptions,
    fieldName,
    disabled,
    handleChange,
    handleFocus,
  } = props;

  const translate = useTranslate();
  const classes = useStyles(props);

  return (
    <div className={classes.root} data-test-field-name={fieldName}>
      <div className={classes.dropdownContainer}>
        <Dropdown
          data-test-dropdown-search-input
          fluid
          selection
          clearable
          closeOnBlur
          selectOnNavigation={false}
          ref={getSelectRef as (dropManager: unknown) => void}
          noResultsMessage={translate('ra.navigation.no_results')}
          text={dropValue}
          open={undefined}
          value={dropValue}
          options={preparedOptions}
          onChange={handleChange}
          selectOnBlur={false}
          onFocus={handleFocus}
          search={preparedOptions => preparedOptions}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SingleSelectFilterInputView;
