import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  tabParent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },

  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 5,
    border: `1px solid ${theme.palette.divider}`,
    contain: 'content',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
  },

  table: {
    borderCollapse: 'unset',
    borderSpacing: '15px',
    tableLayout: 'fixed',
  },

  titleSummary: {
    display: 'flex',
    alignItems: 'center',
  },

  tabGroupsContainer: {
    overflow: 'auto',
    height: '100% !important',
  },

  '@global': {
    '.ant-tabs': {
      overflow: 'unset',
    },

    '.ant-tabs-content-holder': {
      padding: '0 15px 5px 15px',

      '& > div:first-child ': {
        flexDirection: 'column',
      },
    },

    '.quickFormWithTabContainer .ant-tabs-content-holder': {
      height: 1,
    },

    '.quickFormWithTabContainer > .ant-tabs.ant-tabs-top.ant-tabs-rtl': {
      height: '100%',
    },

    '.ant-tabs-nav': {
      padding: '5px 15px 0 15px',
    },

    '.ant-tabs-rtl .ant-tabs-tab-active': {
      backgroundColor: theme.palette.secondary['withOpacity'],
    },

    '.ant-tabs-rtl .ant-tabs-tab-btn': {
      textTransform: 'uppercase',
      fontSize: '13px',
    },

    '.ant-tabs-rtl .ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.palette.secondary.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-error]': {
      backgroundColor: theme.palette.error['withOpacity'],
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-error] .ant-tabs-tab-btn > p': {
      color: theme.palette.error.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab-active[has-error] ~ .ant-tabs-ink-bar': {
      backgroundColor: theme.palette.error.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-warning]': {
      backgroundColor: theme.palette.warning['withOpacity'],
    },

    '.ant-tabs-rtl .ant-tabs-tab[has-warning] .ant-tabs-tab-btn > p': {
      color: theme.palette.warning.main,
    },

    '.ant-tabs-rtl .ant-tabs-tab-active[has-warning] ~ .ant-tabs-ink-bar': {
      backgroundColor: theme.palette.warning.main,
    },

    '.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab': {
      margin: '0',
      padding: '10px 18px',
    },

    '.ant-tabs-nav-list .ant-tabs-ink-bar': {
      backgroundColor: theme.palette.secondary.main,
    },

    '.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav':
      {
        margin: '0',
      },

    '.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before':
      {
        border: 'none',
      },
  },
}));
