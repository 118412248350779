import { type FC, useState, useCallback } from 'react';
import { actorGetActionValue } from '../../../type/actor-setup';
import AssignTaskDialogView from './assign-task-dialog.view';
import { editTodoTask } from '../../todo-section/todo-section.helper';
import type { AssignTaskDialogControllerInterface } from './assign-task-dialog.type';

const AssignTaskDialogController: FC<
  AssignTaskDialogControllerInterface
> = props => {
  const { closeDialogHandler, dialogData } = props;
  const taskUniqueId = actorGetActionValue('selectedTodoTask')?.uniqueid;

  const [selectedUserId, setSelectedUserId] = useState<number>(
    dialogData.selectedUser?.personinfo_id,
  );

  /**
   * @function handleListItemClick
   * @returns {void} void
   */
  const handleListItemClick = useCallback((userId: number): void => {
    setSelectedUserId(userId);
  }, []);

  /**
   * @function handleAssign
   * @returns {void} void
   */
  const handleAssign = useCallback((): void => {
    editTodoTask({
      taskUID: String(taskUniqueId),
      newValues: {
        agentuser: selectedUserId,
      },
      successCallback: closeDialogHandler,
    });
  }, [taskUniqueId, selectedUserId]);

  const todoShareList = actorGetActionValue('todoShareList');
  return (
    <AssignTaskDialogView
      handleAssign={handleAssign}
      sharingList={todoShareList ?? []}
      closeDialogHandler={closeDialogHandler}
      handleListItemClick={handleListItemClick}
      selectedUserId={selectedUserId}
    />
  );
};
export default AssignTaskDialogController;
