import { DynamicChart } from '../../dynamic-chart';
import useStyles from './gadget-field.style';

import type { ReactElement } from 'react';
import type { GadgetFieldViewProps } from './gadget-field.type';
import LoadingBox from '../../loading-box';

const GadgetFieldView = (props: GadgetFieldViewProps): ReactElement => {
  const { dynamicChartPreparedProps, parsedValue } = props;
  const classes = useStyles();

  return (
    <div className={classes.previewSection}>
      {parsedValue && dynamicChartPreparedProps ? (
        <DynamicChart {...dynamicChartPreparedProps} />
      ) : (
        <LoadingBox />
      )}
      {/* it can be a default shape 👆 */}
    </div>
  );
};

export default GadgetFieldView;
