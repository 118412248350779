import { FC, memo } from 'react';

import { useStyles } from './todo-section.style';
import { TodoTasks } from './todo-tasks';

const TodoSectionView: FC = memo(() => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TodoTasks />
    </div>
  );
});
export default TodoSectionView;
