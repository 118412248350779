import { getChats } from '../chat-section.helper';
import { clearChatsData } from './chat-section-common';

import type {
  ChatStateInterface,
  UploadedFileInterface,
} from '../chat-section.type';

const fetchMoreChats = (state: ChatStateInterface): ChatStateInterface => {
  const updatedChatPage = state.chatPage + 1;

  getChats({
    shouldLoading: false,
    page: updatedChatPage,
  });

  return {
    ...state,
    chatPage: updatedChatPage,
  };
};

const fetchMoreContacts = (state: ChatStateInterface): ChatStateInterface => {
  const updatedContactPage = state.contactPage + 1;

  getChats({
    shouldLoading: false,
    page: updatedContactPage,
  });

  return {
    ...state,
    contactPage: updatedContactPage,
  };
};

const refreshChats = (state: ChatStateInterface): ChatStateInterface => {
  clearChatsData();

  getChats({
    shouldLoading: true,
    page: 1,
  });

  return {
    ...state,
    chatPage: 1,
  };
};

const openUploadFileDialog = (
  state: ChatStateInterface,
  show: boolean,
  uploadedFiles: UploadedFileInterface[],
): ChatStateInterface => {
  return {
    ...state,
    isFileUploadDialogOpen: show,
    uploadedFiles,
  };
};

export const chatStatefulActions = {
  fetchMoreChats,
  fetchMoreContacts,
  refreshChats,
  openUploadFileDialog,
};
