import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { useTranslate } from 'react-admin';

import { useStyles } from './change-zoom-scale.style';

import type { FC } from 'react';
import type { ChangeZoomScaleViewProps } from './change-zoom-scale.type';

const ChangeZoomScaleView: FC<ChangeZoomScaleViewProps> = props => {
  const { onZoomIn, onZoomOut, defaultZoom, scale } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      <div className={classes.child}>{translate('formBuilder.zoomScaling')}</div>
      <div className={classes.child}>
        <button className={classes.zoomBtn} onClick={onZoomIn}>
          +
        </button>
        <div> {Math.floor(scale * 100)}% </div>
        <button className={classes.zoomBtn} onClick={onZoomOut}>
          -
        </button>
      </div>
      <div className={classes.child}>
        <FullscreenIcon onClick={defaultZoom} />
      </div>
    </div>
  );
};

export default ChangeZoomScaleView;
