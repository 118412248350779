import type { FC } from 'react';
import Highcharts from 'highcharts';
import { useLocale } from 'react-admin';
import momentJalaali from 'moment-jalaali';

import HighchartsReact from 'highcharts-react-official';
import { TimeSeriesChartViewProps } from './time-series-chart.type';

const TimeSeriesChartView: FC<TimeSeriesChartViewProps> = props => {
  const { highChartOptions, width, height } = props;

  const locale = useLocale();
  momentJalaali.locale(locale);

  if (locale == 'fa') {
    Highcharts.dateFormats = {
      a: function (ts) {
        return momentJalaali(ts).format('jdddd');
      },
      A: function (ts) {
        return momentJalaali(ts).format('dddd');
      },
      d: function (ts) {
        return momentJalaali(ts).format('jDD');
      },
      e: function (ts) {
        return momentJalaali(ts).format('jD');
      },
      b: function (ts) {
        return momentJalaali(ts).format('jMMMM');
      },
      B: function (ts) {
        return momentJalaali(ts).format('jMMMM');
      },
      m: function (ts) {
        return momentJalaali(ts).format('jMM');
      },
      y: function (ts) {
        return momentJalaali(ts).format('jYY');
      },
      Y: function (ts) {
        return momentJalaali(ts).format('jYYYY');
      },
      W: function (ts) {
        return momentJalaali(ts).format('jww');
      },
    };
  }
  Highcharts.setOptions({
    lang: {
      decimalPoint: '.',
      thousandsSep: ',',
    },
  });

  return (
    <div style={{ width, height, overflow: 'hidden' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={highChartOptions}
        width={width}
        height={height}
        key={Math.random()}
      />
    </div>
  );
};

export default TimeSeriesChartView;
