import type { HubConnection } from '@microsoft/signalr';
import {
  dispatchRunner,
  onDispatchRunner,
  setActionValueRunner,
  getActionValueRunner,
  resetActionList,
  waitForActionRunner,
  removeActionRunner,
} from '@web/actor';
import {
  CodingInputData,
  CustomChangeHandler,
  FormActionsHandler,
  FormData,
  InitialData,
  InputRefContent,
  SelectedService,
  tagInputData,
  ValidationErrors,
} from '../component/form';

import type {
  FieldType,
  MetaData,
  MetaDataBase,
  NotificationOptions,
  GlobalParametersInterface,
} from '../helper/Types';
import type { ValidationError } from '../helper/Types';
import type {
  FetchDropdownDataPayload,
  FileInputData,
  CheckValidationClientSide,
} from '../component/form/form.type';
import type { DialogData } from '../component/dialogs-stack';
import type { SearchPopupDialogData } from '../component/search-popup-dialog';
import type { MenuItemParams } from '../component/menu/sidebar-menu';
import type { GridParametersInterface } from '../component/search-popup-dialog';
import type {
  ChatDetailInterface,
  ChatListInterface,
  ChatReducerActionInterface,
  ChatStateInterface,
  SelectedChatType,
  UploadedFileInterface,
} from '../component/chat-section';
import { EventTypeRemote } from '../component/big-calendar';
import {
  MailFolderInterface,
  MailInterface,
  SelectedMailInterface,
} from '../component/mail-section';
import type { PaginationParams } from '../component/mail-section/mail-list';
import type { SaveType } from '../component/QuickCreateButtonToolbar';
import type { QuickAccessMenuDataInterface } from '../component/quick-access-menu';
import type {
  CrudCreateApiPayload,
  CrudDeleteOneApiPayload,
  CrudGetListApiPayload,
  CrudGetManyReferenceApiPayload,
  CrudGetOneApiPayload,
  CrudResultInterface,
  CrudRunServiceApiPayload,
  CrudUpdateApiPayload,
  CustomCssPostApiPayload,
  CustomCssGetApiPayload,
} from './data-provider';

import type { LogConfigInterface } from '@web/actor/types';
import type { GeneralMetaData, Translate } from './../helper/Types';
import type { Locale } from './global-types';
import type { SavedFilterItemInterface } from '../component/filter-form/saved-filters-button';
import type { QuestionApiResponseInterface } from '../component/dialogs-stack/dynamic-dialog';
import type { TodoTaskItemInterface } from '../component/todo-section/todo-tasks/todo-task-item/todo-task-item.type';
import type { GadgetInformation } from '../component/dynamic-input/gadget-input';
import type { InputAppearanceCharacteristics } from '../helper/meta-helper.type';
import type {
  ChatReportResponseInterface,
  ChatServiceResponseInterface,
} from '../api/chat-api';
import type { FilterItemBaseType, FinalFiltersType } from '../component/filter-form';
import type { SelectedMessageInterface } from '../component/chat-section/new-message';
import type { ShowImageDialogControllerInterface } from '../component/show-image-dialog';
import type { SuccessResponse } from '../component/dynamic-input/multi-file-stream-input/multi-file-stream-input.type';
import type { UserAssignedItemInterface } from '../component/dialogs-stack/assign-task-dialog/assign-task-dialog.type';
import type { PermissionAssignType } from '../component/permissions/permission-assigned/permission-assigned.type';
import type { TodoFolderType } from '../component/todo-section/todo-sidebar/todo-sidebar.type';
import { SelectedItemForPermissionAdvance } from '../component/show-permissions/simple-grid';
import { SharingListInterface } from '../component/dialogs-stack/todo-share-dialog/todo-share-dialog.type';

// export type Resource = 'root' | 'relation' | 'dropdown';
export enum FormKeyMode {
  ROOT = 'ROOT', // The first form that will display when a page loaded
  RELATION = 'RELATION',
  DROPDOWN = 'DROPDOWN',
  SERVICE = 'SERVICE', // The service dialog form
  PROFILE = 'PROFILE', // The profile form
  VISITOR = 'VISITOR',
  WMS = 'WMS',
  PERMISSION = 'PERMISSION',
  ADVANCE_PERMISSION = 'ADVANCE_PERMISSION',
}

export enum RecordKeyMode {
  FORM = 'FORM',
  PARENT_RECORD = 'PARENT_RECORD',
  FULL = 'FULL',
}

export interface ResourceInterface {
  type: FormKeyMode;
  value: string;
}

export interface ResourcesInterface {
  stack: ResourceInterface[];
  current: ResourceInterface;
}

export enum DropdownDataKey {
  ALL = 'ALL',
  DATA = 'DATA',
  TOTAL = 'TOTAL',
}

export interface GetServiceDefaultValueType {
  service: Record<string, unknown>;
  params: Record<string, unknown>;
  parentResource?: string;
  onSuccess: (
    params: Record<string, unknown>,
    service: Record<string, unknown>,
  ) => void;
  onFailure: (error: unknown, service: Record<string, unknown>) => void;
}

export interface LocationParamsInterface {
  id: string;
  moduleName: string;
  moduleTableName: string;
  module?: string;
  url?: string;
}
export interface URLInfo {
  params: LocationParamsInterface;
  location: {
    hash: string;
    pathname: string;
    search: string;
    state: string;
    href: string;
    origin: string;
    hostname: string;
  };
}

export interface FocusManagementInterface {
  focusOnInput: (...args) => void;
  focusOnNextInput: (
    inputsRef: Record<string, InputRefContent>,
    currentName: string | undefined,
    formData: FormData | null,
    submitFormCallback?: (...args) => void,
  ) => string | undefined;
  focusOnFirstInput: (inputsRef: Record<string, InputRefContent>) => void;
  focusOnFirstInputAfterSubmit: () => void;
}

export interface DropdownData {
  [DropdownDataKey.ALL]?: Record<string, unknown>[];
  [DropdownDataKey.DATA]?: Record<string, unknown>[];
  [DropdownDataKey.TOTAL]?: number;
}

export type SortOrderType = 'desc' | 'asc' | null;

export interface RequestParametersInterface {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: string | null;
    order: SortOrderType;
  };
  filter: FinalFiltersType;
  parentModule?: string;
  parentTable?: string;
  customRecordNotePin?: string;
  searchFilter?: (newFilters: Record<string, unknown>) => void;
}

export interface NoteRequestParametersInterface {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: string;
    order: 'desc' | 'asc';
  };
  filter: (string | string[])[];
  parentModule?: string;
  parentTable?: string;
  customRecordNotePin: string;
  IsPin: number;
  KeyID: string | undefined;
  tableid: number | null;
}

export interface GetListRequestParametersInterface {
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: string | null;
    order: SortOrderType;
  };
  filter?: FinalFiltersType; // ex: [['A', '=', '123'], 'and', ['B', '=', '456']]
  searchFilters?: Record<string, unknown>;
}

export interface NotificationInterface {
  message: string | unknown;
  type?: 'info' | 'error' | 'success' | 'warning' | 'confirm';
  options?: NotificationOptions;
}

export interface GridDataInterface {
  data?: Array<Record<string, unknown>>;
  totalCount?: number;
  requestParameters?: RequestParametersInterface | GetListRequestParametersInterface;
  selectedIds?: Array<string>;
  lastRequestId?: string;
  error?: string;
}

export interface ApiRequestResultInterface {
  [actionType: string]: {
    [entity: string]: CrudResultInterface;
  };
}

export interface FilterFormFieldInterface {
  fieldData: FieldType | Record<string, unknown>;
  value: FilterItemBaseType;
  fullDropdownItem?: Record<string, unknown>;
}

export interface ScrollParamsInterface {
  uniqIdToScroll: string;
}

export interface MenuData {
  items: MenuItemParams[];
  isLoadedOnce: boolean;
  isLoading: boolean;
  error: string | null;
}

export type offlineRefreshViewEntities = 'todoTasks';
type ActorSignals =
  | 'formDefaultValuesSet'
  | 'isInputClearedOnce'
  | 'reloadMap'
  | 'closeAppSidebar'
  | 'isChangedEmbedParam'
  | 'showReasonPermission'
  | 'reloadTopToolbarRelations';

// you can add more signals separated by `|`

export interface FullProfileInterface {
  isLoading?: boolean;
  error?: string | null;
  additionalData?: Record<string, unknown> | null;
  warehouseTitle?: string;
  globalParameters?: GlobalParametersInterface; // its edited version
  profileData?:
    | ({
        globalParameters: GlobalParametersInterface;
        globalparameters: GlobalParametersInterface;
      } & Record<string, unknown>)
    | null; // a lot of bullshits
}

export type SearchModeInChatType = 'allUser' | 'oneByOne';
export interface UserPermissionValueInterface {
  selectedAssignType: PermissionAssignType; // 1 = user , 2 = role , 3 = chart
  selectedAssignees: number[];
  selectedPermissionTypes: number[];
  selectedBranches?: number[];
  selectedMenus?: number[];
  selectedTab?: number;
  selectedName?: string;
}

export interface ChatIsTypingInterface {
  time: Date;
  personName: string;
  groupId: string | undefined;
}

declare global {
  // Because our lint doesn't understand a definition in a global scope, its temporary
  // eslint-disable-next-line no-unused-vars
  interface ActorActionList {
    // common
    loading: { [resource: string]: boolean };
    resources: ResourcesInterface;
    setDocumentTitle: {
      metaData: GeneralMetaData;
      recordTitle: string;
      locale: string;
    };

    urlInfo: URLInfo;

    remove: { resource: string; type: FormKeyMode };

    // dialogs
    quickAccessOpenDialog: boolean;
    quickDialog: DialogData;
    closeDialogs: boolean;
    closeCurrentDialog: boolean;
    searchDialog: SearchPopupDialogData;

    // data
    record: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: {
          [RecordKeyMode.FORM]?: Record<string, unknown>;
          [RecordKeyMode.PARENT_RECORD]?: Record<string, unknown>;
          [RecordKeyMode.FULL]?: Record<string, unknown>;
        };
        [FormKeyMode.DROPDOWN]?: {
          [RecordKeyMode.FORM]?: Record<string, unknown>;
          [RecordKeyMode.PARENT_RECORD]?: Record<string, unknown>;
          [RecordKeyMode.FULL]?: Record<string, unknown>;
        };
      };
    };

    initialData: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: InitialData;
        [FormKeyMode.RELATION]?: InitialData;
        [FormKeyMode.DROPDOWN]?: InitialData;
      };
    };

    formData: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: FormData;
        [FormKeyMode.RELATION]?: FormData;
        [FormKeyMode.DROPDOWN]?: FormData;
      };
    };

    quickCreateSupplementaryData: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: FormData;
        [FormKeyMode.RELATION]?: FormData;
        [FormKeyMode.DROPDOWN]?: FormData;
      };
    };

    dropDownData: {
      [uniqueId: string]: DropdownData;
    };

    metaData: {
      [resource: string]: MetaData;
    };

    getQuickAccessMenuData: boolean;

    quickAccessMenuData: QuickAccessMenuDataInterface[];

    /**
     * Pay attention:
     * If we needed to type of save(e.g. `saveAndNew`) we can use `SaveType` keys,
     *  otherwise we have to set `null`
     */
    resetForm: { saveType?: SaveType | null; resource?: ResourceInterface };

    // tabs
    checkTabErrors: (formErrors?: Record<string, ValidationError> | null) => void;

    quickCreateButtonToolbar: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: Record<string, HTMLButtonElement | undefined>;
        [FormKeyMode.RELATION]?: Record<string, HTMLButtonElement | undefined>;
        [FormKeyMode.DROPDOWN]?: Record<string, HTMLButtonElement | undefined>;
      };
    };

    // form
    inputsRef: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: Record<string, InputRefContent>;
        [FormKeyMode.RELATION]?: Record<string, InputRefContent>;
        [FormKeyMode.DROPDOWN]?: Record<string, InputRefContent>;
      };
    };

    allFields: { [resource: string]: Array<FieldType> };
    formMessages: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: ValidationErrors;
        [FormKeyMode.RELATION]?: ValidationErrors;
        [FormKeyMode.DROPDOWN]?: ValidationErrors;
      };
    };

    formGlobalProps: {
      formActionsHandler: FormActionsHandler;
      customChangeHandler: CustomChangeHandler;
      formFocusManagementFunctions: FocusManagementInterface;
      dropdownCreateSuccess: (newRecord: Record<string, unknown>) => void;
      checkValidationClientSide: CheckValidationClientSide;
      metaData: MetaDataBase;
      isCreateMode: boolean;
      keepValueAfterSubmitFieldNameList: string[];
      keepFocusAfterSubmitFieldNameList: string[];
      ignoreToFocusFieldNameList: string[];
      inputNameListSortedByPriority: string[];
    };

    gridIDs: {
      [resource: string]: {
        allIDs: Array<number>;
        selectedIDs: Array<number>;
      };
    };

    selectedService: {
      service: SelectedService | null;
    };
    runServiceDirectly: {
      service: SelectedService | null;
      serviceParams?: Record<string, unknown>;
      targetSelectedId?: number;
    };

    handleFinishServiceDialog: {
      handleFinishServiceDialog: () => void;
    };

    handleCloseProfileFormDialog: {
      handleCloseProfileFormDialog: () => void;
    };

    showLoading: boolean;
    showSettingsLoading: boolean;
    profileSetting: Record<string, unknown>;

    // inputs
    codingInputData: CodingInputData;
    tagInputData: tagInputData;
    fileInputData: { [resource: string]: FileInputData };
    uploadStreamFile: {
      param: {
        resource: string;
        file: Record<string, unknown>;
      };
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: string) => void;
    };

    uploadStreamMultipleFile: {
      param: {
        resource: string;
        files: Array<File>;
      };
      successCallback: (response: Record<string, unknown>) => void;
      successAllFilesUploadedCallback?: (
        response: SuccessResponse['data'][],
      ) => void;
      failureCallback: (errors: Array<unknown>) => void;
    };

    isSearchingOnDropDown: { [uniqueId: string]: boolean };
    isSearchPopUpDialogOpen: boolean;
    notification: NotificationInterface | null;
    notificationClosed: null;

    crudAction:
      | CrudCreateApiPayload
      | CrudUpdateApiPayload
      | CrudGetListApiPayload
      | CrudDeleteOneApiPayload
      | CrudGetOneApiPayload
      | CrudRunServiceApiPayload
      | CrudGetManyReferenceApiPayload
      | CustomCssPostApiPayload
      | CustomCssGetApiPayload;

    // side-bar-menu
    menuData: MenuData;

    childMenuData: MenuItemParams[];
    favoriteItemsData: MenuItemParams[];
    settingItem: MenuItemParams[];
    recentItemsData: MenuItemParams[];
    selectedParentMenuId: number;
    drawerWidth: number;
    isModuleItemSelected: boolean;
    isChildMenuOpen: boolean;
    shouldAllItemsExpand: { [menuId: string]: boolean };
    expandedItemsId: { [menuId: string]: number[] };
    profile: FullProfileInterface;

    /**
     * current session id will use in mobile / tablet devices, it will use for authentication without token
     * if `sessionid` key exist in url , we fill it on this state in actor and all of request url,s to server
     * will be changed. if this value change in url ( or first time of existence in url ) should get profile
     * data and set new global parameters of user in that will handle in data provider component.
     */
    currentSessionIdInUrl: string;

    fetchDropdownData: FetchDropdownDataPayload; // Fetch dropdown data from API.
    getDropdownData: Record<string, unknown>; // Get dropdown data from store.

    gridParameters: GridParametersInterface;

    changePassword: {
      successCallback: (props) => void;
      failureCallback: (error) => void;
      data: Record<string, unknown>;
    };
    changeProfile: { data: Record<string, unknown>; successCallback: () => void };
    delegationData: { caption: string; path: string; selected: boolean }[];
    getDelegationData: null;
    putPathDelegation: { resource: string; successCallback: () => void };
    getServiceDefaultValue: GetServiceDefaultValueType;
    viewVersion: number;

    //visitor calendar
    getCalendarData: {
      filters: FinalFiltersType[];
      reportId: string;
      successCallback: (props) => void;
    };
    runActionsService: {
      params: EventTypeRemote;
      actionUniqueId: string;
      __questionsResponse?: QuestionApiResponseInterface[];
      successCallback?: (props) => void;
      failureCallback?: (props) => void;
    };

    // SignalR
    notificationHubConnection: HubConnection;
    chatHubConnection: HubConnection;
    signalRNotification: unknown;

    // chat
    chatReducer: {
      state: ChatStateInterface;
      dispatch: React.Dispatch<ChatReducerActionInterface>;
    };

    chatSearchDetails: {
      selectedUser?: SelectedChatType;
      searchModeInChat?: SearchModeInChatType;
    };

    chatList: ChatListInterface; // @ChatDescription
    selectedChat: ChatDetailInterface | null;
    onDemandSelectedChat: ChatDetailInterface;
    selectedMessage: SelectedMessageInterface | null;
    unseenMessagesCount: Record<string | number, number>; // { [personinfo_id]: 2, ... }
    mainChatInput: HTMLInputElement;
    uploadedFile: UploadedFileInterface;
    isNewContentSent: boolean;
    usersLastSeen: Record<string, string>; // { [ person info id ] : [ 'online' | '1401/02/03' ] }
    isTypingUsers: Record<string, ChatIsTypingInterface>;
    updateTotalUnSeen: boolean;

    getChatReport: {
      params: Record<string, unknown>;
      successCallback: <T extends unknown>(
        response: ChatReportResponseInterface<T>,
      ) => void;
      failureCallback: (error: unknown) => void;
    };
    runChatService: {
      params: Record<string, unknown>;
      successCallback?: <T extends unknown>(
        response: ChatServiceResponseInterface<T>,
      ) => void;
      failureCallback?: (error: unknown) => void;
    };

    //map
    getMetaForMapReport;
    getMapReport: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: string) => void;
    };

    //pivot
    getPivotReport: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: string) => void;
    };

    //mail
    getMailReport: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: unknown) => void;
    };

    mailSelected: MailInterface;
    //print report
    getPrintReportData: {
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback?: (error: unknown) => void;
      metaData: GeneralMetaData;
      reportId: string;
      filterValues: FinalFiltersType;
    };

    runMailService: {
      params: Record<string, unknown>;
      successCallback: (prop: Record<string, unknown>) => void;
      failureCallback: (error: unknown) => void;
    };
    mailData: {
      data: MailInterface[];
      pagination: PaginationParams;
    };
    selectedMail: SelectedMailInterface[] | null;
    selectedDoc: Pick<
      SelectedMailInterface,
      'doc_id' | 'doctype_id' | 'refrences_id'
    > | null;
    mailReplyData: string;
    mailInboxUnreadCount: number;
    mailFolders: MailFolderInterface[];
    newLabelFormData: FormData;

    appSettings: {
      original: Array<Record<string, unknown>> | null;
      objectList: Record<string, Record<string, unknown>>;
    };

    isDrawerOpen: boolean;
    isChatDrawerOpen: boolean;

    // grid
    gridData: {
      [resource: string]: GridDataInterface;
    };
    gridFormData: Record<string, unknown>;

    apiRequestResult: ApiRequestResultInterface;

    // TODO: its a temporary actor state and should implement in dialog stack in another card
    isNoteCreateEditDialogOpen: boolean;

    isTopFilterOpen: boolean;

    refreshView: string;

    activeTab: {
      [resource: string]: number;
    };

    relationFieldsForDisplay: {
      [resource: string]: string;
    };

    wmsAsyncActionList: Promise<unknown>[];

    additionalData: {
      summary: Record<string, unknown>;
    };

    reactAdminHelpers: {
      translate: Translate;
      locale: Locale;
      reduxDispatch: (...args) => void;
    };

    filterFormFields: {
      [resource: string]: Record<string, FilterFormFieldInterface>;
    };
    filterFormFieldHiddenChanged: {
      [resource: string]: FieldType;
    };
    filterDataIsChanged: {
      [resource: string]: FinalFiltersType;
    };
    showAllFieldsToFilter: {
      [resource: string]: boolean;
    };
    savedFilters: {
      [resource: string]: Record<string | number, SavedFilterItemInterface>;
    };
    resetOriginalFieldsToFilter: {
      [resource: string]: boolean;
    };
    clearAllFilterValues: {
      [resource: string]: boolean;
    };

    changeScreenZoom: number;
    toggleAccordion: {
      accordionId: string;
    };

    scroll: ScrollParamsInterface;
    isGridGroupingEnable: boolean;
    signal: ActorSignals;

    mailActionsHandler: (type: string, payload?: unknown) => Promise<void>;
    todoFolders: TodoFolderType[];

    dragDropAction: {
      draggedItem: Record<string, unknown>;
      droppedItem: Record<string, unknown>;
      parentComponentName: string;
    };
    taskFormData: Record<string, unknown>;
    todoShareList: SharingListInterface[];
    todoTaskList: TodoTaskItemInterface[];
    selectedTodoTask: TodoTaskItemInterface;

    offlineRefreshView: {
      entity: offlineRefreshViewEntities;
      newData: TodoTaskItemInterface; // you can add your types here //TODO: use XOR to relate new data type to entity
      mode: 'create' | 'update';
    };
    selectedOaFolder: Record<string, unknown>;

    currentGadgetInformation: GadgetInformation;
    inputsInitialAppearanceCharacteristics: {
      [resource: string]: {
        [FormKeyMode.ROOT]?: {
          [id: number]: Record<string, InputAppearanceCharacteristics>;
        };
        [FormKeyMode.RELATION]?: {
          [id: number]: Record<string, InputAppearanceCharacteristics>;
        };
        [FormKeyMode.DROPDOWN]?: {
          [id: number]: Record<string, InputAppearanceCharacteristics>;
        };
      };
    };

    gridHiddenFields: {
      [resource: string]: string[];
    };

    imageDialog: ShowImageDialogControllerInterface;

    userPermissionValue: UserPermissionValueInterface;

    userPermissionValueInDialog: {
      selectedAssignees: number[];
      selectedAssignType: PermissionAssignType; // 1 = user , 2 = role , 3 = chart
      selectedPermissionTypes: number[];
      selectedBranches: number[];
    };
    pinedDashboard: {
      uniqueid: string;
      ispin: boolean;
      dashname: string;
      isfav: boolean;
      dashinfo_id: number;
      allowedit: number;
      hasError?: boolean;
    };

    redirectToLoginWithMessage: string;
    personFilterFormField: string;
    showColumnChooser: Record<string, boolean>;
    saveCellInPermissionsAdvance: SelectedItemForPermissionAdvance;
    fullScreenItem: () => JSX.Element | undefined;
  }
}

let logConfig: LogConfigInterface<ActorActionList> | undefined;
if (process.env.NODE_ENV === 'development') {
  logConfig = {
    actionNames: [],
    showActionHistory: true,
  };

  // To show logs in console
  localStorage.setItem('ALWATR_DEBUG', '1');
} else {
  localStorage.removeItem('ALWATR_DEBUG');
}

export const actorDispatch = dispatchRunner<ActorActionList>(logConfig);
export const actorOnDispatch = onDispatchRunner<ActorActionList>();
export const actorGetActionValue = getActionValueRunner<ActorActionList>();
export const actorSetActionValue = setActionValueRunner<ActorActionList>(logConfig);
export const waitForAction = waitForActionRunner<ActorActionList>();
export const actorRemoveAction = removeActionRunner<ActorActionList>();
export const actorResetActionList = resetActionList;
