import {
  Checkbox,
  Chip,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { Fragment, ReactElement } from 'react';
import { useTranslate } from 'react-admin';

import { SearchPopupButton } from '../../search-popup-button';
import InputBase from '../../input-base';
import { useStyles } from './auto-complete-input.style';
import { AutoCompleteInputViewInterface } from './auto-complete-input.type';

const AutoCompleteInputView = (
  props: AutoCompleteInputViewInterface,
): ReactElement => {
  const {
    inputContainerClass,
    customTestAttribute,
    visibleClass,
    label,
    hint,
    source,
    dropdownMeta,
    formData,
    loading,
    disabled,
    relationResource,
    relationSource,
    relationInfo,
    wrapperRef,
    preparedValue,
    preparedOptions,
    renderOptions,
    handleChangeInput,
    inputValue,
    handleChange,
    onFocus,
    onBlur,
    handleClickSearchDialog,
    handleSelectionWithSearchDialog,
    buttonRefDialog,
    inputMessage,
    required,
    refTags,
    limitTagCount,
    gridRowClickHandler,
    formActionsHandler,
    field,
    parentResource,
  } = props;
  const { customOption } = field;

  const classes = useStyles({ customOption });
  const translate = useTranslate();

  return (
    <InputBase
      className={`${visibleClass} ${inputContainerClass}`}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      required={required}
      data-test-field-name={source}
    >
      <div ref={refTags} className={classes.rootRef}>
        <div
          ref={wrapperRef}
          className={classes.rootDropdown}
          data-test-field-name={source}
        >
          <Autocomplete
            multiple
            openOnFocus
            disableListWrap
            disableCloseOnSelect
            classes={{
              root: classes.rootAutocomplete,
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
              listbox: classes.listboxAuto,
            }}
            limitTags={limitTagCount}
            disabled={disabled}
            loading={loading}
            options={renderOptions}
            onChange={handleChange}
            onFocus={onFocus}
            filterOptions={options => options} // This function should run to handle filtered items by searching, however affect the performance
            onBlur={onBlur}
            loadingText={translate('dropdown.loading')}
            noOptionsText={translate('dropdown.noOptionsMessage')}
            getOptionLabel={options => options.text}
            value={preparedOptions}
            defaultValue={preparedOptions}
            getOptionSelected={(option, selectedItem) =>
              option.value == selectedItem.value
            }
            renderOption={(option, { selected }) => (
              <div
                data-test-input-name={'auto-complete-options-typo-' + option['text']}
                data-test-grid-row={option['value']}
                className={classes.ulBox}
              >
                <Checkbox
                  checked={selected}
                  className={classes.checkBox}
                  icon={
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkBoxOutlineBlank}
                      fontSize="small"
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon
                      className={classes.checkBoxOutline}
                      fontSize="small"
                    />
                  }
                />
                <span>{option.text}</span>
              </div>
            )}
            renderInput={params => {
              const {
                InputProps: { endAdornment: EndAdornment },
              } = params;
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={translate('dropdown.placeholder')}
                  onChange={handleChangeInput}
                  defaultValue={inputValue}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment
                        className={classes.endAdornment}
                        position="end"
                        data-test="auto-complete-input-adornment"
                      >
                        <SearchPopupButton
                          clickHandler={handleClickSearchDialog}
                          dropdownInPuzzleForm={false}
                          dropdownMultipleSelection={true}
                          formActionsHandler={formActionsHandler}
                          resource={`${dropdownMeta.moduleName}/${dropdownMeta.tableName}`}
                          parentResource={parentResource.value}
                          source={field.name}
                          dropdownMeta={dropdownMeta}
                          relationResource={relationResource}
                          relationSource={relationSource}
                          relationInfo={relationInfo}
                          disabled={disabled}
                          changeFormValue={handleSelectionWithSearchDialog}
                          changeFormValueByClickingOnRow={gridRowClickHandler}
                          formData={formData ?? {}}
                          fieldName={field.name}
                          isTodo={false}
                          fieldId={field.id}
                          parentResourceType={parentResource.type}
                          label="label"
                          field={field}
                          buttonRef={buttonRefDialog}
                          dropBasePreparedOptions={preparedOptions}
                          dropBaseValue={Array.from(preparedValue, item =>
                            Number(item),
                          )}
                          className={`${classes.searchIcon} ${classes.padding}`}
                        />
                        {EndAdornment}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    ...params.inputProps,
                    ...customTestAttribute,
                    className:
                      classes.paddingInput +
                      ' ' +
                      (params.inputProps as Record<string, string>)?.className,
                  }}
                />
              );
            }}
            renderTags={(value, props) =>
              value.map((item, index) => {
                return (
                  <Fragment key={`fragment${item.key}_${index}`}>
                    <Tooltip
                      {...props({ index })}
                      classes={{
                        tooltip: classes.rootTooltip,
                        arrow: classes.arrowTooltip,
                      }}
                      arrow
                      placement="bottom-start"
                      title={item.text}
                    >
                      <Chip
                        style={customOption.customStyleAdditionalDataField}
                        disabled={disabled}
                        {...props({ index })}
                        className={classes.button}
                        label={
                          <Typography
                            component="span"
                            noWrap
                            className={classes.spanItemButton}
                          >
                            {item.text}
                          </Typography>
                        }
                      />
                    </Tooltip>
                  </Fragment>
                );
              })
            }
          />
        </div>
      </div>
    </InputBase>
  );
};

export default AutoCompleteInputView;
