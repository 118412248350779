import { type FC, memo, useCallback, useEffect, useState, ChangeEvent } from 'react';

import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import UploadFilesFormView from './upload-files-form.view';
import {
  CHAT_VERSION,
  ChatMessageTypeEnum,
  messageSendManager,
} from '../chat-section.helper';

import type {
  MessageItemInterface,
  UploadedFileInterface,
} from '../chat-section.type';
import type { UploadFilesFormPropsInterface } from './upload-files-form.type';
import type {
  AlbumMessageMetaInterface,
  ChatTextJsonFormatInterface,
  FileMessageMetaInterface,
} from '../new-message';
import { isEmpty } from '../../../helper/data-helper';

const UploadFilesFormController: FC<UploadFilesFormPropsInterface> = memo(props => {
  const { files, submitFormCallback } = props;

  const [sendAsAlbum, setSendAsAlbum] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFileInterface[]>([
    ...files,
  ]);

  useEffect(() => {
    const listenerId = actorOnDispatch(
      'uploadedFile',
      uploadedFile => {
        setUploadedFiles(prevFiles => [...prevFiles, uploadedFile]);
      },
      {
        preserve: false, // Should get new files when it is open, previous uploaded files get as prob named `files`
      },
    );

    return () => {
      actorRemoveAction({
        actionName: 'uploadedFile',
        listenerId,
      });
    };
  }, []);

  const onSendAsAlbumClick = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSendAsAlbum(isChecked);
  };

  /**
   * @function handleSendFiles
   * @param {string} messageText
   * @returns { void } void
   */
  const handleSendFiles = <
    TMessageType extends ChatMessageTypeEnum,
    TMetaType extends Record<string, any> = Record<string, any>,
  >(
    message: ChatTextJsonFormatInterface<TMessageType, TMetaType>,
  ): void => {
    if (uploadedFiles.length === 0) return;

    const _message: Partial<ChatTextJsonFormatInterface> = {
      rawText: message.rawText,
      formattedText: message.formattedText,
      version: CHAT_VERSION,
      meta: {},
    };

    if (sendAsAlbum) {
      _message.messageType = ChatMessageTypeEnum.ALBUM;
      _message.meta = {
        imageUrls: uploadedFiles,
      };

      messageSendManager({
        messageType: 'album',
        content: _message as ChatTextJsonFormatInterface<
          ChatMessageTypeEnum.ALBUM,
          AlbumMessageMetaInterface
        >,
      });
    } else {
      _message.messageType = ChatMessageTypeEnum.FILE;
      _message.meta = {
        files: uploadedFiles,
      };

      messageSendManager({
        messageType: 'file',
        content: _message as ChatTextJsonFormatInterface<
          ChatMessageTypeEnum.FILE,
          FileMessageMetaInterface
        >,
      });
    }

    submitFormCallback?.();
  };

  /**
   * @function removeUploadedFileHandler
   * @param {number} index
   * @returns { void } void
   */
  const removeUploadedFileHandler = useCallback(
    (index: number) => (): void => {
      setUploadedFiles(prevFiles => {
        prevFiles.splice(index, 1);
        return [...prevFiles];
      });
    },
    [],
  );

  return (
    <UploadFilesFormView
      uploadedFiles={uploadedFiles}
      handleSendFiles={handleSendFiles}
      onDeleteFile={removeUploadedFileHandler}
      onSendAsAlbumClick={onSendAsAlbumClick}
      sendAsAlbum={sendAsAlbum}
    />
  );
});

export default UploadFilesFormController;
