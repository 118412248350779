import { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import moment from 'jalali-moment';

import AddTodoTaskInputView from './add-todo-task-input.view';

import { isCtrlEnterPressed, isEnterPressed } from '../../../../helper/FormHelper';
import { actorDispatch, actorSetActionValue } from '../../../../type/actor-setup';
import { showNotification } from '../../../../helper/general-function-helper';
import { createTodoTask } from '../../todo-section.helper';
import { useLocation } from 'react-router-dom';

import type { CreateTaskResponseInterface } from './add-todo-task-input.type';
import type { CustomOptionsInterface } from '../../todo-section.type';

const AddTodoTaskInputController: FC = () => {
  const [noteTitle, setNoteTitle] = useState<string>('');
  const location = useLocation();

  const showAddMessageInput = !location.search.includes('assignedToMe');

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current?.focus();
      }
    }, 100);
  }, [noteTitle]);

  /**
   * @function handleSuccess
   * @param { CreateTaskResponseInterface } response
   * @returns { void } void
   */
  const handleSuccess = (response: CreateTaskResponseInterface) => {
    actorSetActionValue('taskFormData', null);
    setNoteTitle('');
    showNotification(response.userMessage, 'success');
    actorDispatch('offlineRefreshView', {
      entity: 'todoTasks',
      newData: response.data,
      mode: 'create',
    });
  };

  /**
   * fill custom option for new task
   * @function prepareCustomOptions
   * @param { Record<string, string> } location
   * @returns {CustomOptionsInterface} CustomOptionsInterface
   */
  const prepareCustomOptions = (
    location: Record<string, string>,
  ): CustomOptionsInterface => {
    if (location.search.includes('isAddedToMyDay')) {
      return { isaddedtomyday: true };
    } else if (location.search.includes('isImportant')) {
      return { isimportant: true };
    } else if (location.search.includes('dueDate')) {
      return { dueDate: moment() };
    } else {
      return {};
    }
  };

  /**
   * call a service to create a new task
   * @function createNewTask
   * @returns {void} void
   */
  const createNewTask = (): void => {
    if (!noteTitle) return;

    createTodoTask({
      title: noteTitle,
      customOptions: prepareCustomOptions(
        location as unknown as Record<string, string>, // FIXME: fix `location` types based on `react-router-dom`
      ),
      successCallback: handleSuccess,
      failureCallback: (error: unknown): void => {
        showNotification(error, 'error', { forceSnackbar: true });
      },
    });
  };

  /**
   * change local state
   * @function onChangeNoteTitle
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const onChangeNoteTitle = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setNoteTitle(event.target.value);
    },
    [],
  );

  /**
   * trigger send if ctrl + enter press
   * @function handleKeyDown
   * @param {KeyboardEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (isEnterPressed(event) || isCtrlEnterPressed(event)) {
      createNewTask();
    }
  };

  return (
    <AddTodoTaskInputView
      inputRef={inputRef}
      onChangeNoteTitle={onChangeNoteTitle}
      handleKeyDown={handleKeyDown}
      createNewTask={createNewTask}
      noteTitle={noteTitle}
      showAddMessageInput={showAddMessageInput}
    />
  );
};

export default memo(AddTodoTaskInputController);
