import { useState, type FC, useEffect, useRef } from 'react';
import { useLocale } from 'react-admin';

import { isEmptyObject } from '../../helper/data-helper';
import { getMetaDataFromActorOrNetwork } from '../../helper/meta-helper';
import DynamicChartView from './dynamic-chart.view';
import type { DynamicChartControllerProps } from './dynamic-chart.type';
import type { GeneralMetaData } from '../../helper/Types';
const DynamicChartController: FC<DynamicChartControllerProps> = props => {
  const [metaData, setMetaData] = useState<GeneralMetaData>();
  const reportColumns = useRef();
  const { resource, chartType } = props;

  const locale = useLocale();
  const uniqueReportColumns = new Map();

  useEffect(() => {
    if (resource) {
      getMetaDataFromActorOrNetwork(`report/${props?.resource}`).then(response => {
        setMetaData(response[`report/${props?.resource}` ?? '']);
      });
    }
  }, [resource, chartType]);
  useEffect(() => {
    if (!isEmptyObject(metaData)) {
      for (const column of metaData.columns ?? []) {
        uniqueReportColumns.set(
          column.name,
          column.translatedCaption?.[locale] ?? column.caption,
        );
      }
      reportColumns.current = Object.fromEntries([...uniqueReportColumns]);
    }
  }, [metaData?.reportId]);

  return (
    <DynamicChartView
      {...props}
      metaData={metaData}
      reportColumns={reportColumns.current}
      resource={`report/${props?.resource}`}
    />
  );
};

export default DynamicChartController;
