import { type FC, useEffect, useState } from 'react';

import { getUsersDashboards } from '../dashboard/dashboard-page.helper';
import { actorDispatch } from '../../type/actor-setup';
import LoadingBox from '../LoadingBox';
import ListDashboardView from './list-dashboard.view';
import { showNotification } from '../../helper/general-function-helper';

import type { DashboardInSidebarInterface } from '../menu/sidebar-menu';

const ListDashboardController: FC = () => {
  const [rowsDashboard, setRowsDashboard] = useState<
    DashboardInSidebarInterface[] | null
  >(null);

  const resource = 'report/dashinfo';

  useEffect(() => {
    getUsersDashboards({ successCallback });
  }, []);

  /** the purpose of this function is to handle the removal of a row of dashboards from the list of dashboards by making a request.
   * @function deleteDashboard
   * @param {number} dashboardId
   * @returns {void} void
   */
  const deleteDashboard = (dashboardId: number) => (): void => {
    actorDispatch(
      'crudAction',
      {
        type: 'DELETE',
        resource,
        id: dashboardId,
        onSuccess: () => getUsersDashboards({ successCallback }),
        onFailure: error => showNotification(error, 'error'),
      },
      { disableDebounce: true, replaceAll: true },
    );
  };

  /** Getting a list of dashboards
   * @function successCallback
   * @param {DashboardInSidebarInterface[]} response
   * @returns {void} void
   */
  const successCallback = (response: DashboardInSidebarInterface[]) => {
    setRowsDashboard(response);
  };

  if (!rowsDashboard) {
    return <LoadingBox />;
  }

  return (
    <ListDashboardView
      rowsDashboard={rowsDashboard}
      deleteDashboard={deleteDashboard}
      resource={resource}
    />
  );
};

export default ListDashboardController;
