import { ReactElement } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FieldTitle } from 'ra-core';
import classnames from 'classnames';

import { useStyles } from './boolean-input.style';

import type { BooleanInputViewProps } from './boolean-input.type';

const BooleanInputWMSView = (props: BooleanInputViewProps): ReactElement => {
  const {
    visibleClass,
    className,
    id,
    disabled,
    value,
    getRef,
    resource,
    label,
    hint,
    source,
    handleChange,
    handleFocus,
    handleBlur,
    field,
    isFocused,
    isBlurred,
    options,
    inputMessage,
    ...rest
  } = props;

  const { name } = field;

  const classes = useStyles({ messageType: inputMessage?.messageType, disabled });

  return (
    <FormGroup
      className={classnames(visibleClass, classes.wmsBooleanStyle, className)}
      data-focus={!!isFocused}
      data-blur={!!isBlurred}
      id="switchButton"
      style={{ width: `${field.widthPercent}%` }}
    >
      <FormControlLabel
        htmlFor={id}
        disabled={disabled}
        control={
          <Switch
            data-test-bool-input-value={!!value}
            {...options}
            id={id}
            color="primary"
            checked={!!value}
            name={name}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            ref={getRef}
          />
        }
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={field.required}
          />
        }
      />

      {inputMessage?.message ? (
        <FormHelperText className={classes.error}>
          {inputMessage.message}
        </FormHelperText>
      ) : null}
    </FormGroup>
  );
};

export default BooleanInputWMSView;
