import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { MailFormActionInterface } from './mail-form-action.type';
import { useStyles } from './mail-form-action.style';
import { Box, Button } from '@material-ui/core';

const MailFormActionView = (props: MailFormActionInterface): ReactElement => {
  const { onCancel, onSubmit } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.root}>
      <Button
        onClick={onCancel}
        className={classes.btnCancel}
        variant="outlined"
        color="primary"
        data-test="mail-form-decline-button"
      >
        {translate('general.decline')}
      </Button>
      <Button
        className={classes.btnRoot}
        onClick={onSubmit}
        variant="contained"
        color="secondary"
        data-test="mail-form-apply-button"
      >
        {translate('general.apply')}
      </Button>
    </Box>
  );
};

export default MailFormActionView;
