import { type ReactElement, useRef, memo, useEffect, useState } from 'react';

import { RecordShowDetailsView } from './record-show-details.view';

import type { RecordDetailsPropsInterface } from './record-show-details.type';
import { removeOnDispatches } from '../../helper/general-function-helper';
import { FormKeyMode, RecordKeyMode, actorOnDispatch } from '../../type/actor-setup';

const RecordShowDetailsController = (
  props: RecordDetailsPropsInterface,
): ReactElement => {
  const [recordInfo, setRecordInfo] = useState(props.record);
  const tabsTitlesRef = useRef<Record<number, HTMLParagraphElement | null>>({});

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    const listenerId = actorOnDispatch(
      'record',
      async records => {
        const targetRecord =
          records[props.resource]?.[FormKeyMode.ROOT]?.[RecordKeyMode.FULL];
        if (targetRecord) setRecordInfo(targetRecord);
      },
      { preserve: false },
    );

    onDispatches.push({
      actionName: 'record',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, []);

  return (
    <RecordShowDetailsView
      {...props}
      record={recordInfo}
      tabsTitlesRef={tabsTitlesRef}
    />
  );
};

export default memo(RecordShowDetailsController, (prevProps, nextProps) => {
  return (
    prevProps.record === nextProps.record &&
    prevProps.recordIsEditable === nextProps.recordIsEditable &&
    prevProps.tabList === nextProps.tabList &&
    prevProps.resource === nextProps.resource
  );
});
