import { ReactElement, useCallback, useEffect, useState } from 'react';
import { exportLatLngFromGeography } from '../../dynamic-input/location-input/location-input.helper';

import {
  LocationFieldControllerPropsInterface,
  LocationInterface,
} from './location-field.type';
import LocationFieldView from './location-field.view';

const LocationFieldController = (
  props: LocationFieldControllerPropsInterface,
): ReactElement => {
  const { field, record } = props;
  const [location, setLocation] = useState<LocationInterface | null | undefined>(
    undefined,
  );

  useEffect(() => {
    const value = (record?.[field.name] as string) ?? null;
    setLocation(exportLatLngFromGeography(value));
  }, [record]);

  if (location == undefined) {
    return <></>;
  }

  /**
   * @function onChangeLocation
   * @params {LocationInterface} changedLocation
   * @returns {void} void
   */
  const onChangeLocation = (changedLocation: LocationInterface): void => {
    setLocation(changedLocation);
  };

  /**
   * Handles the click event on the map.
   * @param {Object} event - The event object containing information about the click.
   * @param {LatLng} event.latlng - The geographical coordinates of the point that was clicked.
   */
  const handleMapClick = (event): void => {
    onChangeLocation(event.latlng); // Assuming e.latlng is the new location
  };
  return (
    <LocationFieldView
      location={location}
      field={field}
      handleMapClick={handleMapClick}
    />
  );
};

export default LocationFieldController;
