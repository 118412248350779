import { ReactElement, useCallback, useState, useContext } from 'react';

import { DynamicDialogView } from './dynamic-dialog.view';
import {
  AnsewrsInterface,
  DynamicDialogPropsInterface,
  QuestionInterface,
  QuestionApiResponseInterface,
  DialogDataInterface,
} from './dynamic-dialog.type';
import { actorDispatch } from '../../../type/actor-setup';
import { NewMetaContext } from '../../../container/NewMetaContext';

const DynamicDialogController = (
  props: DynamicDialogPropsInterface,
): ReactElement => {
  const {
    dialogData,
    closeDialogHandler,
    setDialogs,
    openDynamicDialog,
    gatherDialogData,
  } = props;
  const [answers, setAnswers] = useState<AnsewrsInterface[]>([]);

  const Question: QuestionInterface =
    dialogData?.response.actionOutput.additionalData.Question;

  const __questionsResponse: Array<QuestionApiResponseInterface> =
    dialogData.response.actionOutput.additionalData.__questionsResponse ?? [];

  const { getMeta } = useContext(NewMetaContext);

  /**
   * @function responseHandler
   * @description Handle the response from the dialog
   * @param {React.MouseEvent<HTMLButtonElement>} event
   * @returns {void}
   */
  const responseHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
    __questionsResponse.push({
      ID: Question.ID as string,
      Result: event?.currentTarget.dataset.value,
      InputValue: answers as AnsewrsInterface[],
    });

    setDialogs([]);

    runQuestionService(__questionsResponse);
  };

  /**
   * @function runQuestionService
   * @description sends question response to the server and if the service was successful, it goes to success callback
   * @param {ServiceDialogData} __questionResponse
   * @returns {void}
   */
  const runQuestionService = (
    __questionsResponse: Array<QuestionApiResponseInterface>,
  ): void => {
    actorDispatch(
      'runActionsService',
      {
        params: {},
        __questionsResponse,
        actionUniqueId: dialogData.serviceParameter.actionUniqueId,
        successCallback,
      },
      { disableDebounce: true },
    );
  };

  /**
   * @function successCallback
   * @description success callback for the service if the service response had additional data it opens another dynamic dialog
   * @param {DialogDataInterface} response
   * @returns {void}
   */
  const successCallback = (response: DialogDataInterface): void => {
    if (response.actionOutput.additionalData) {
      gatherDialogData(
        {
          data: {
            response,
            serviceParameter: dialogData.serviceParameter,
          },
        },
        getMeta,
      );
      openDynamicDialog();
    }
  };

  /**
   * @function handleInputChange
   * @description Handle the input change
   * @param {React.MouseEvent<HTMLTextAreaElement>} event
   * @param {number} key
   */
  const handleInputChange = useCallback(
    (key: number) =>
      (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
      ) => {
        setAnswers(answers => {
          const newAnswers = [...answers];
          newAnswers[key] = { Value: event?.target.value, ID: key + 1 };
          return newAnswers;
        });
      },
    [],
  );

  return (
    <DynamicDialogView
      Question={Question}
      handleInputChange={handleInputChange}
      closeDialogHandler={closeDialogHandler}
      responseHandler={responseHandler}
    />
  );
};

export default DynamicDialogController;
