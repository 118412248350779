import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import type {
  MailFilterName,
  MailInterface,
  OnArchiveParams,
  OnHandleMasterCheckboxParams,
  OnMarkUnReadParams,
} from '../../mail-section.type';
import { FolderTypeEnum, MailActions } from '../../mail-section.type';
import lodashDebounce from 'lodash/debounce';
import { isEmptyObject } from '../../../../helper/data-helper';
import { MailContentHeaderInterface } from './mail-content-header.type';
import MailContentHeaderView from './mail-content-header.view';
import { actorDispatch, actorGetActionValue } from '../../../../type/actor-setup';
import { getMailFolderID } from '../../mail-section.helper';
import type { MenuOptionInterface } from '../../../button-menu';

const MailContentHeaderController: FC<MailContentHeaderInterface> = memo(props => {
  const {
    mailActionsHandler,
    mailData,
    checkedMails,
    loading,
    pagination,
    onChangePageHandler,
    onStarClick,
    onImportantClick,
  } = props;

  const [shouldMarkAsRead, setShouldMarkAsRead] = useState(false);

  const urlInfo = actorGetActionValue('urlInfo')!;
  // folderId < 0 => delegationTypeIcon / folderId != null && folderId >= 0 => messageTypeIcon
  const folderId = useMemo(
    () => getMailFolderID(urlInfo.location.hash),
    [urlInfo.location.hash],
  );

  // folderType === 1  => messageTypeIcon
  const folderType = useMemo(() => {
    const searchParam = urlInfo.location.hash.split('?')?.[1]; // #mail?archived => [#mail], [archived]
    if (searchParam) {
      return FolderTypeEnum[searchParam];
    }
    return null;
  }, [urlInfo.location.hash]);

  /**
   * @function handleRefreshMailData
   * @returns { void }
   */
  const handleRefreshMailData = (): void => {
    mailActionsHandler(MailActions.OnRefreshMailData);
  };

  /**
   * @function onHandleMasterCheckbox
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const onHandleMasterCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    mailActionsHandler(MailActions.onHandleMasterCheckbox, {
      event,
    } as OnHandleMasterCheckboxParams);
  };

  /**
   * @function onMarkUnReadClick
   * @param { MailInterface[] | null } mailsData
   * @param { boolean } IsUnRead
   * @returns { void }
   */
  const onMarkUnReadClick = (
    mailsData: MailInterface[] | null,
    IsUnRead: boolean,
  ): void => {
    mailActionsHandler(MailActions.onMarkUnRead, {
      mailsData,
      IsUnRead,
    } as OnMarkUnReadParams);
  };
  /**
   * @function onArchiveClick
   * @returns { void }
   */
  const onArchiveClick = (): void => {
    mailActionsHandler(MailActions.onArchive, {
      mailsData: null,
    } as OnArchiveParams);
  };
  /**
   * @function onUnArchiveClick
   * @returns { void }
   */
  const onUnArchiveClick = (): void => {
    mailActionsHandler(MailActions.onUnArchive, {
      mailsData: null,
    } as OnArchiveParams);
  };
  /**
   * @function handleDebouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const handleDebouncedSearch = useCallback(
    lodashDebounce(() => {
      mailActionsHandler(MailActions.onSearchMailData);
    }, 1000),
    [],
  );

  /**
   * @function handleSearch
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentMailData = actorGetActionValue('mailData')!;

    // should not miss any data when we want to send search request
    // so we should assure to start from first page
    actorDispatch('mailData', {
      ...currentMailData,
      pagination: { ...currentMailData.pagination, currentPage: 1 },
    });
    handleDebouncedSearch(event.target.value);
  };

  useEffect(() => {
    if (!isEmptyObject(checkedMails)) {
      const isUnReadItemExist = checkedMails.some((item: MailInterface) =>
        Boolean(item.isunread),
      );

      setShouldMarkAsRead(isUnReadItemExist);
    }
  }, [checkedMails]);

  /**
   * @function filterChangeHandler
   * @param { { name: 'messageType' | 'delegationType'; value: unknown } } filterData
   * @returns { (filterName: string) => void } a function
   */
  const filterChangeHandler = useCallback(
    (filterName: MailFilterName) =>
      (selectedOption: MenuOptionInterface): void => {
        mailActionsHandler(MailActions.onChangeFilters, {
          name: filterName,
          value: selectedOption.value,
        });
      },
    [],
  );

  return (
    <MailContentHeaderView
      mailActionsHandler={mailActionsHandler}
      mailData={mailData}
      loading={loading}
      handleSearch={handleSearch}
      checkedMails={checkedMails}
      pagination={pagination}
      onChangePageHandler={onChangePageHandler}
      onRefreshMailData={handleRefreshMailData}
      onHandleMasterCheckbox={onHandleMasterCheckbox}
      onStarClick={onStarClick}
      onImportantClick={onImportantClick}
      onMarkUnReadClick={onMarkUnReadClick}
      onArchiveClick={onArchiveClick}
      onUnArchiveClick={onUnArchiveClick}
      shouldMarkAsRead={shouldMarkAsRead}
      filterChangeHandler={filterChangeHandler}
      showDelegationType={folderId != null && folderId < 0}
      showMessageTypeIcon={
        folderType === 1 || (folderType == null && folderId != null && folderId >= 0)
      }
    />
  );
});

export default MailContentHeaderController;
