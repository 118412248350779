import { useEffect, useState, useRef, type FC, MouseEvent } from 'react';
import { useTranslate } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import { actorDispatch, actorGetActionValue } from '../../type/actor-setup';
import DashboardPageView from './dashboard-page.view';
import { showNotification } from '../../helper/general-function-helper';
import LoadingBoxView from '../loading-box/loading-box.view';
import { parseJSON } from '../../core/configProvider';
import { isEscapePressed } from '../../helper/FormHelper';
import { RUN_SERVICE } from '../../core/data-Provider.helper';
import { areTwoObjectsShallowEqual, clone, isEmpty } from '../../helper/data-helper';
import NotFound from '../NotFound';
import { getDashboardFilterFromUrl } from './dashboard-card/dashboard-card.helper';
import { customizeMergingRequestParameters } from '../filter-form/filter-form.helper';
import { addParamToUrl } from '../../helper/UrlHelper';
import {
  CONFIRMED,
  DASHBOARD_FILTER,
  DashboardDefaultValue,
  getDashboard,
  getUsersDashboards,
  NOT_CONFIRMED,
  saveDashboard,
  updateDashboardPinAndFav,
} from './dashboard-page.helper';

import type { ValueFieldName } from '../dynamic-chart';
import type { FinalFiltersType } from '../filter-form';
import type {
  DashboardCardsCoordinatesInterface,
  DashboardCardsDataInterface,
  DashBoardFormDataInterface,
  DashboardInformationParsedJson,
  DashboardPageControllerProps,
  EditDashboardCard,
  PreviousValuesRefInterface,
} from './dashboard-page.type';

const initialPreviousValue = {
  dashboardCardsCoordinates: [],
  dashboardCardsData: [],
  stringifiedDashboardCardsCoordinates: '',
  stringifiedDashboardCardsData: '',
};

const DashboardPageController: FC<DashboardPageControllerProps> = props => {
  const { dashboardInfo, match } = props;

  const location = useLocation();

  const [dashboardId, setDashboardId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isPin, setIsPin] = useState(dashboardInfo?.ispin ?? false);
  const [rangePickerAnchorEl, setRangePickerAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  /**
   * @function openRangePicker
   * @param {MouseEvent<HTMLButtonElement>} event
   * @returns {void} void
   */
  const openRangePicker = (event: MouseEvent<HTMLButtonElement>): void => {
    setRangePickerAnchorEl(event.currentTarget);
  };

  /**
   * @function closeRangePicker
   * @returns {void} void
   */
  const closeRangePicker = (): void => {
    setRangePickerAnchorEl(null);
  };

  const [dashboardCardsData, setDashboardCardsData] = useState<
    DashboardCardsDataInterface[]
  >([]);

  const [dashboardCardsCoordinates, setDashboardCardsCoordinates] = useState<
    DashboardCardsCoordinatesInterface[]
  >([]);

  const previousValuesRef = useRef<PreviousValuesRefInterface>(initialPreviousValue);
  const dashboardEditRef = useRef<string>('');
  const dashboardNameRef = useRef<string>('');

  const urlHasEmbed = actorGetActionValue('urlInfo')?.location.search;

  const translate = useTranslate();
  const history = useHistory();

  useEffect(() => {
    if (match?.params) {
      setDashboardId(match?.params.dashboardId ?? '');
    } else {
      setDashboardId(dashboardInfo?.uniqueid ?? '');
      setIsPin(dashboardInfo?.ispin ?? false);
    }
  }, [dashboardInfo?.uniqueid, match?.params.dashboardId]);

  /**
   * @function updateDashboardCardsCoordinates
   * @param {DashboardCardsCoordinatesInterface[]} newValues
   * @returns {void} void
   */
  const updateDashboardCardsCoordinates = (
    newValues: DashboardCardsCoordinatesInterface[],
  ): void => {
    // stringify new values to compare
    const stringifiedNewValues = JSON.stringify(newValues);

    if (
      previousValuesRef.current.stringifiedDashboardCardsCoordinates !==
      stringifiedNewValues
    ) {
      // update cash
      previousValuesRef.current.stringifiedDashboardCardsCoordinates =
        stringifiedNewValues;
      previousValuesRef.current.dashboardCardsCoordinates = newValues;

      // update state
      setDashboardCardsCoordinates(newValues);
    } // else prevent unnecessary update
  };

  /**
   * @function updateDashboardCardsData
   * @param {DashboardCardsDataInterface[]} newValues
   * @returns {void} void
   */
  const updateDashboardCardsData = (
    newValues: DashboardCardsDataInterface[],
    shouldSaveDashboard = false,
  ): void => {
    // stringify new values to compare
    const stringifiedNewValues = JSON.stringify(newValues);

    if (
      previousValuesRef.current.stringifiedDashboardCardsData !==
      stringifiedNewValues
    ) {
      // update cash
      previousValuesRef.current.stringifiedDashboardCardsData = stringifiedNewValues;
      previousValuesRef.current.dashboardCardsData = newValues;
      setDashboardCardsData(newValues);

      // update state
      if (shouldSaveDashboard) {
        handleSaveDashboard(newValues);
      }
    }
  };

  useEffect(() => {
    if (dashboardId) {
      getDashboard({
        dashboardId: dashboardId,

        successCallback: response => {
          if (!response?.['data']) return; // because its not its related response

          const dashboard = response?.['data']?.[0];
          if (dashboard) {
            setErrorMessage('');

            const dashboardData = parseJSON<DashboardInformationParsedJson>(
              dashboard?.dashjson ?? DashboardDefaultValue,
            )!;

            updateDashboardCardsData(dashboardData.dashboardCardsData ?? []);

            updateDashboardCardsCoordinates(
              dashboardData.dashboardCardsCoordinates ?? [],
            );

            dashboardNameRef.current = dashboard.dashname;
            dashboardEditRef.current = dashboard.allowedit;

            setIsFavorite(dashboard.isfav);
            if (typeof dashboard === 'object' && 'ispin' in dashboard) {
              setIsPin(dashboard.ispin);
            }

            setIsLoading(false);
          } else {
            setErrorMessage(translate('gadget.dashboardNotFound'));
            setIsLoading(false);
          }
        },
        failureCallback: () => {
          setErrorMessage(translate('gadget.dashboardNotFound'));
          setIsLoading(false);
        },
      });
    } else {
      // setErrorMessage(translate('gadget.dashboardNotFound'));
      setIsLoading(false);
    }
    document.onkeydown = function (event) {
      event = event || window.event;
      if (isEscapePressed(event)) {
        handleExitFullScreen();
      }
    };

    return () => {
      resetInitialValue();
      document.onkeydown = null;
    };
  }, [dashboardId]);

  /**
   * @function resetInitialValue
   * @returns {void} void
   */
  const resetInitialValue = (): void => {
    previousValuesRef.current = clone(initialPreviousValue);
    setDashboardCardsData([]);
    setDashboardCardsCoordinates([]);

    setIsFavorite(false);
    setIsPin(false);
    setIsEditing(false);

    dashboardNameRef.current = '';
  };

  /**
   * @function handleFullScreen
   * @returns {void} void
   */
  const handleFullScreen = (): void => {
    history.replace({
      search: `embed=true`,
    });
    actorDispatch('signal', 'isChangedEmbedParam');
  };

  /**
   * @function handleExitFullScreen
   * @returns {void} void
   */
  const handleExitFullScreen = (): void => {
    history.replace({
      search: '',
    });
    actorDispatch('signal', 'isChangedEmbedParam');
  };

  /**
   * compute last vertical position
   * @function getLastVerticalPosition
   * @returns {number} last position
   */
  const getLastVerticalPosition = (): number => {
    let max = 0;

    dashboardCardsCoordinates.forEach(item => {
      if (item.y > max) max = item.y;
    });

    return max;
  };

  /**
   * create new dashboard card
   * @function createNewDashboardCard
   * @param {DashBoardFormDataInterface} newCardData
   * @returns {void} void
   */
  const createNewDashboardCard = (newCardData: DashBoardFormDataInterface): void => {
    const newId = new Date().getTime().toString();

    const newCoordinates = [
      ...previousValuesRef.current.dashboardCardsCoordinates,
      {
        i: newId,
        x: 0,
        y: getLastVerticalPosition() + 5,
        w: 40,
        h: 20,
      },
    ];

    updateDashboardCardsCoordinates(newCoordinates);

    const newCardsData = [
      ...previousValuesRef.current.dashboardCardsData,
      {
        id: newId,
        title: newCardData.title,
        color: newCardData.color,
        gadgetUniqueId: newCardData.gadgetUniqueId,
        customFilters: newCardData.customFilters,
        autoRefreshReportData: newCardData.autoRefreshReportData,
        autoRefreshReportDataTime: newCardData.autoRefreshReportDataTime,
        reportDataRowNumbers: newCardData.reportDataRowNumbers,
        isSortEnabled: newCardData.isSortEnabled,
        sortOrder: newCardData.sortOrder,
        sortField: newCardData.sortField,
        isFiltersConfirmed: true,
      },
    ];

    updateDashboardCardsData(newCardsData);
  };

  /**
   * edit dashboard card
   * @function editDashboardCard :EditDashboardCard
   */
  const editDashboardCard: EditDashboardCard = (editingId, newCardData) => {
    const shouldSaveDashboard = !areTwoObjectsShallowEqual(
      previousValuesRef.current.dashboardCardsData.find(
        dashboard => dashboard.id === editingId,
      )?.listChartSort,
      newCardData?.listChartSort,
    );

    const newCardsData = previousValuesRef.current.dashboardCardsData.map(
      dashboardCardData => {
        if (dashboardCardData.id === editingId) {
          return newCardData as DashboardCardsDataInterface;
        }
        return dashboardCardData;
      },
    );

    updateDashboardCardsData(newCardsData, shouldSaveDashboard);
  };

  /**
   * delete dashboard card
   * @function deleteDashboardCard
   * @param {string} id
   * @returns {void} void
   */
  const deleteDashboardCard = (id: string): void => {
    const newCardsData = previousValuesRef.current.dashboardCardsData.filter(
      dashboardCardData => dashboardCardData.id !== id,
    );

    updateDashboardCardsData(newCardsData);

    const newCoordinates =
      previousValuesRef.current.dashboardCardsCoordinates.filter(
        dashboardCardData => dashboardCardData.i !== id,
      );
    updateDashboardCardsCoordinates(newCoordinates);
  };

  /**
   * open create new dashboard card dialog
   * @function openCreateNewDashboardCardDialog
   * @returns {void} void
   */
  const openCreateNewDashboardCardDialog = (): void => {
    actorDispatch(
      'quickDialog',
      {
        isDashboardCardFormDialogOpen: true,
        data: {
          createNewDashboardCard,
        },
      },
      { replaceAll: true },
    );
  };

  /**
   * open edit dashboard card dialog
   * @function openEditDashboardCardDialog
   * @param { DashboardCardsDataInterface } dashboardCardData
   * @returns { void } void
   */
  const openEditDashboardCardDialog = (
    dashboardCardData: DashboardCardsDataInterface,
    valueFieldName?: ValueFieldName,
  ): void => {
    actorDispatch(
      'quickDialog',
      {
        isDashboardCardFormDialogOpen: true,
        data: {
          dashboardCardData,
          editDashboardCard,
          valueFieldName, // we need this to fill the `sortField` default value
        },
      },
      { replaceAll: true },
    );
  };

  /**
   * @function refreshDashboardMenu
   * @returns { void } void
   */
  const refreshDashboardMenu = (): void => {
    actorDispatch('refreshView', 'dashboardMenu');
  };

  /**
   * @function updatePinOrFavorite
   * @param {'pin' | 'favorite'} key
   * @param {boolean} newValue
   * @returns { void } void
   */
  const updatePinOrFavorite = (key: 'pin' | 'favorite', newValue: boolean): void => {
    const params = {
      DashUID: dashboardId,
      IsFav: key === 'favorite' ? newValue : isFavorite,
      IsPin: key === 'pin' ? newValue : isPin,
    };

    actorDispatch('crudAction', {
      type: RUN_SERVICE,
      entity: 'dashboard',
      actionUniqueId: updateDashboardPinAndFav,
      data: { params },
      onSuccess: () => {
        showNotification(translate('css.success'), 'success');
        getUsersDashboards({ successCallback: refreshDashboardMenu });
      },
      onFailure: error => {
        if (typeof error === 'string') {
          showNotification(error, 'error');
        }
      },
    });
  };
  /**
   * @function changeImportantDashboard
   * @returns { void } void
   */
  const onFavoriteChange = (): void => {
    setIsFavorite(prevState => {
      const newValue = !prevState;

      updatePinOrFavorite('favorite', newValue);
      return newValue; // do the set state
    });
  };

  /**
   * @function onPinChange
   * @returns {void}
   */
  const onPinChange = (): void => {
    setIsPin(prevState => {
      const newValue = !prevState;

      updatePinOrFavorite('pin', newValue);
      return newValue; // do the set state
    });
  };

  /**
   * set new coordinates of dashboard cards with prev `id` value
   *
   * tip:
   *    GridLayout in responsive mode will trigger this function twice with same properties
   *    so we should compare fake new values with previous values then do the set state to
   *    prevent unnecessary renders
   *
   * @function onDashboardCardsCoordinatesChange
   * @param {DashboardCardsCoordinatesInterface[]} newCoordinates
   * @returns {void} void
   */
  const onDashboardCardsCoordinatesChange = (
    newCoordinates: DashboardCardsCoordinatesInterface[],
  ): void => {
    const newValues = newCoordinates.map(eventItemCoordinates => ({
      // [+eventItemCoordinates.i] is index of changed array
      // so prevDashboardCardsCoordinates[+eventItemCoordinates.i].i refers to prev `i` value
      i: previousValuesRef.current.dashboardCardsCoordinates[+eventItemCoordinates.i]
        .i,
      h: eventItemCoordinates.h,
      w: eventItemCoordinates.w,
      x: eventItemCoordinates.x,
      y: eventItemCoordinates.y,
    }));

    updateDashboardCardsCoordinates(newValues);
  };

  /**
   * handle save dashboard
   * @function handleSaveDashboard
   * @returns {void} void
   */
  const handleSaveDashboard = (
    customDashboardCardsData?: DashboardCardsDataInterface[],
    customIsFavoriteValue?: boolean,
  ): void => {
    if (dashboardId) {
      saveDashboard({
        dashboardId: dashboardId,
        dashboardCardsCoordinates,
        dashboardCardsData: customDashboardCardsData ?? dashboardCardsData,
        dashboardName: dashboardNameRef.current,
        IsFav: customIsFavoriteValue ?? isFavorite,
        isPin: isPin,
        successCallback: () => {
          showNotification(translate('css.success'), 'success');
          getUsersDashboards({ successCallback: refreshDashboardMenu });
        },
        failureCallback: (error: unknown) => {
          showNotification(error as string, 'error');
        },
      });
    }
  };

  /**
   * handle save dashboard
   * @function onClickSaveDashboard
   * @returns {void} void
   */
  const onClickSaveDashboard = (): void => {
    handleSaveDashboard();
    setIsEditing(false);
  };

  const dashboardGadgetsFilterStatus = dashboardCardsData.reduce<
    Record<string, string>
  >((acc, current) => {
    const newAcc = {
      ...acc,
      [current.id]: current.isFiltersConfirmed ? CONFIRMED : NOT_CONFIRMED,
    };

    return newAcc;
  }, {});

  if (isLoading) {
    return <LoadingBoxView absolute />;
  }

  if (!isLoading && errorMessage) {
    return <NotFound title={errorMessage} />;
  }

  /**
   * @function toggleIsEditing
   * @returns {void} void
   */
  const toggleIsEditing = (): void => {
    setIsEditing(prev => !prev);
  };

  /**
   * @function onRangePickerChange
   * @param {Range} range
   * @returns {void}
   */
  const onRangePickerChange = (newFilter: FinalFiltersType): void => {
    const currentFilter = getDashboardFilterFromUrl();
    const mergedFilter = customizeMergingRequestParameters(currentFilter, [
      newFilter,
    ]);

    const newPathAddress = addParamToUrl(
      location.pathname,
      DASHBOARD_FILTER,
      JSON.stringify(mergedFilter),
    );

    if (!isEmpty(newPathAddress)) history.push(newPathAddress);
  };

  return (
    <DashboardPageView
      deleteDashboardCard={deleteDashboardCard}
      openEditDashboardCardDialog={openEditDashboardCardDialog}
      openCreateNewDashboardCardDialog={openCreateNewDashboardCardDialog}
      dashboardCardsCoordinates={dashboardCardsCoordinates}
      dashboardCardsData={dashboardCardsData}
      editDashboardCard={editDashboardCard}
      onDashboardCardsCoordinatesChange={onDashboardCardsCoordinatesChange}
      handleSaveDashboard={onClickSaveDashboard}
      dashboardGadgetsFilterStatus={dashboardGadgetsFilterStatus}
      handleFullScreen={handleFullScreen}
      handleExitFullScreen={handleExitFullScreen}
      url={urlHasEmbed}
      dashboardNameRef={dashboardNameRef}
      changeImportantDashboard={onFavoriteChange}
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      handlePinDashboard={onPinChange}
      dashboardEditRef={dashboardEditRef}
      isFav={isFavorite}
      pinDashboard={isPin}
      rangePickerAnchorEl={rangePickerAnchorEl}
      openRangePicker={openRangePicker}
      closeRangePicker={closeRangePicker}
      onRangePickerChange={onRangePickerChange}
    />
  );
};

export default DashboardPageController;
