import { ReactElement, Children, cloneElement } from 'react';

import { WMSFormViewProps } from './wms-form.type';
import { useStyles } from './wms-form.style';

import { DynamicInput } from '../../../dynamic-input';

import { computeWmsMaxHeight } from './wms-form.helper'; //fixme: it should remove

const FormRow = ({ children, classes, style, ...props }) => (
  <div className={classes.formRow} style={style}>
    {Children.map(children, child => cloneElement(child, props))}
  </div>
);

export const WMSFormView = (props: WMSFormViewProps): ReactElement => {
  const { rowList, formRef } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.groupContainer} ref={formRef}>
      <div className={classes.simpleForm}>
        {rowList.map((row, index) => {
          if (!row) {
            return null;
          }

          const rowMaximumHight = computeWmsMaxHeight(row);

          return (
            <FormRow
              key={index}
              classes={classes}
              style={rowMaximumHight && { height: rowMaximumHight, marginTop: 12 }}
            >
              {row.map(field => {
                if (field == null || field == 'empty') return <></>;

                return (
                  <DynamicInput
                    key={field.id}
                    source={field.name}
                    field={field}
                    inputInPuzzleForm
                  />
                );
              })}
            </FormRow>
          );
        })}
      </div>
    </div>
  );
};
