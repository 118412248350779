import { clone, isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { actorGetActionValue } from '../../../type/actor-setup';
import {
  HightChartDrillDownSeries,
  Series,
  SeriesDataGroupByValueField,
  SeriesItem,
  prepareAxisChartDataInterface,
} from './bar-chart.type';
import { groupDataByAxisName } from '../line-chart/line-chart.helper';
import { ValueFieldName } from '../dynamic-chart.type';

/**
 * @function createSeriesDataGroupByValueField
 * @param {SeriesDataGroupByValueField} entries
 * @returns {Series[]}
 */
export const createSeriesDataGroupByValueField = (
  entries: SeriesDataGroupByValueField,
): Series[] => {
  const {
    data,
    axisFieldName,
    valueFieldName,
    columns,
    locale,
    parentValue = '',
    rowCountToShow,
    hasDrillDown,
  } = entries;
  const series: Series[] = [];

  let collectedData = groupDataByAxisName(data, axisFieldName, valueFieldName);
  collectedData = createOtherData(
    collectedData,
    valueFieldName,
    axisFieldName,
    rowCountToShow,
  );

  valueFieldName.map((valueField: string, colorIndex: number) => {
    const label =
      columns?.find(column => column.name == valueField)?.translatedCaption?.[
        locale
      ] ?? '';

    const seriesRow: SeriesItem[] = [];
    collectedData.forEach((collectedRow): void => {
      const axisValue = (collectedRow[axisFieldName] as string) ?? '';

      seriesRow.push({
        name: axisValue,
        y: Number(collectedRow[valueField]),
        drilldown:
          hasDrillDown && axisValue !== 'other'
            ? axisValue + '_' + valueField
            : false,
        parentAxisFieldName: axisFieldName,
        parentValue: axisValue,
      });
    });
    series.push({
      name: label,
      data: seriesRow,
      colorIndex: colorIndex,
      id: parentValue + '_' + valueField,
    });
  });

  return series;
};

/**
 * @function getFirstLevelSeries
 * @param {prepareAxisChartDataInterface} entries
 * @returns
 */
export const getFirstLevelSeries = (
  entries: prepareAxisChartDataInterface,
): Series[] => {
  const { data, axisFieldName, valueFieldName, columns, rowCountToShow } = entries;

  const locale = actorGetActionValue('reactAdminHelpers')?.locale ?? 'fa';

  if (!axisFieldName) return [];
  let finalAxisFieldName = '';
  if (Array.isArray(axisFieldName)) {
    finalAxisFieldName = axisFieldName[0]?.toString();
  } else {
    finalAxisFieldName = axisFieldName.toString();
  }

  return createSeriesDataGroupByValueField({
    data,
    axisFieldName: finalAxisFieldName,
    valueFieldName,
    columns,
    locale,
    rowCountToShow,
    hasDrillDown: axisFieldName.length > 1,
  });
};

/**
 * @function getHightChartDrillDownSeries
 * @param {HightChartDrillDownSeries} entries
 * @returns {Series[]}
 */
export const getHightChartDrillDownSeries = (
  entries: HightChartDrillDownSeries,
): Series[] => {
  const {
    allRawData,
    firstLevelSeries,
    axisFieldName,
    valueFieldName,
    columns,
    rowCountToShow,
  } = entries;

  if (!axisFieldName || !Array.isArray(axisFieldName) || axisFieldName.length === 0)
    return [];

  const locale = actorGetActionValue('reactAdminHelpers')?.locale ?? 'fa';

  const processedData: Series[] = [];
  let lastSeriesData: Series[] = clone(firstLevelSeries);

  axisFieldName.map((axisField: string, index: number) => {
    const tempLastSeries: Series[] = [];
    lastSeriesData?.map(({ data }) => {
      data.map(seriesData => {
        if (isEmpty(axisField)) return;

        const filteredData = allRawData.filter(
          item => item[seriesData.parentAxisFieldName] == seriesData.parentValue,
        );

        const series = createSeriesDataGroupByValueField({
          data: filteredData,
          axisFieldName: axisField,
          valueFieldName,
          columns,
          locale,
          parentValue: seriesData.parentValue,
          rowCountToShow,
          hasDrillDown: axisFieldName.length - 1 != index,
        });
        processedData.push(...series);
        tempLastSeries.push(...series);
      });
    });
    lastSeriesData = clone(tempLastSeries);
  });

  return processedData;
};

/**
 * @function createOtherData
 * @param allData
 * @param valueFieldName
 * @param axisFieldName
 * @param count
 * @returns {Record<string, unknown>[]}
 */
export const createOtherData = (
  allData: Record<string, unknown>[],
  valueFieldName: ValueFieldName,
  axisFieldName: string,
  count: number,
): Record<string, unknown>[] => {
  let newData: Record<string, unknown>[] = [];
  newData = allData.slice(0, count);

  const otherData: Record<string, unknown>[] = allData.slice(
    count,
    allData.length - 1,
  );

  const seriesRow = {};
  if (!isEmptyObject(otherData)) {
    valueFieldName.forEach((fieldName: string) => {
      const sumValue = otherData?.reduce((acc, item) => {
        return acc + (item[fieldName] as number);
      }, 0);
      seriesRow[fieldName] = sumValue;
      seriesRow[axisFieldName] = 'other';
    });
    newData.push(seriesRow);
  }

  return newData;
};
