import { PureComponent } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  PersianCalendar,
  Calendar,
  momentLocalizer,
} from 'react-big-calendar-forked-persian';
import 'react-big-calendar-forked-persian/lib/css/react-big-calendar.css';
import moment from 'moment-jalaali';

import {
  getCalendarGridInfo,
  getServerDateFormat,
} from '../helper/CalendarMetaHelper';

const styles = theme => ({
  root: {
    padding: 10,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

class CalendarListView extends PureComponent {
  constructor(params) {
    super(params);

    const { isJalali } = this.props;
    moment.locale(isJalali ? 'fa' : 'en'); // texts on calendar
  }

  onRangeChange = ({ start, end }) => {
    const { resource } = this.props;
    const { start: column } = getCalendarGridInfo(resource);

    const format = getServerDateFormat();

    const from = moment(start)
      .locale('en') // make sure server gets valid english numbers
      .format(format);
    const until = moment(end).locale('en').format(format);

    this.props.setFilters({
      [column]: [column, 'between', from, until],
    });
  };

  render() {
    const { classes, resource, ids, data, onRowClick, isJalali } = this.props;

    const config = getCalendarGridInfo(resource);
    const Component = isJalali ? PersianCalendar : Calendar;

    return (
      <div className={classes.root}>
        <Component
          events={ids.map(id => {
            const row = data[id];

            return {
              ...row,
              title: row[config.title],
              start: new Date(row[config.start]),
              end: new Date(row[config.end]),
              allDay: config.allDay,
              // resource?: any,
            };
          })}
          views={['month', 'day']}
          localizer={momentLocalizer(moment)}
          onRangeChange={this.onRangeChange}
          onSelectEvent={onRowClick}
          style={{ height: 'unset', flexGrow: 1 }}
        />
      </div>
    );
  }
}

CalendarListView.propTypes = {
  ids: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  isJalali: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles, { withTheme: true }))(CalendarListView);
