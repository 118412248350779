import { SplitButton } from '../../../split-button';

import { useStyles } from './mail-split-button.style';

import type { SplitButtonViewInterface } from './mail-split-button.type';

const MailSplitButtonView = (props: SplitButtonViewInterface) => {
  const { splitButtonOptions, onClickItem } = props;
  const classes = useStyles();

  return (
    <SplitButton
      options={splitButtonOptions}
      disableButton={false}
      className={classes.btnContainer}
      buttonClassName={classes.btn}
      toggleButtonClassName={classes.toggleBtn}
      onClickItem={onClickItem}
      data-test="mail-sidebar-split-button"
    />
  );
};

export default MailSplitButtonView;
