import { useState, useEffect, ReactElement, useRef } from 'react';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import debounce from 'lodash/debounce';

import {
  SearchPopupDialogData,
  GridParametersInterface,
  DropdownData,
} from './search-popup-dialog.type';
import SearchPopupDialogView from './search-popup-dialog.view';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../type/actor-setup';
import { FormActions } from '../form';
import {
  prepareDropdownData,
  prepareGridColumns,
} from './search-popup-dialog.helper';
import { openLinkNewTab } from '../../helper/UrlHelper';
import { clone, isEmptyObject, mergeAndClone } from '../../helper/data-helper';
import {
  getFormDefaultValue,
  getGridColumns,
  getShowSummaryColumnList,
} from '../../helper/MetaHelper';
import { getLabelForDropdownOption } from '../../helper/DropdownHelper';
import { DropdownOption } from '../dynamic-input/auto-complete-input';

import type { FilterItemFinalFormatType } from '../filter-form';

let disabledGridParametersRequest = false;

const SearchPopupDialogContainer = (): ReactElement => {
  const dialogDataRef = useRef<SearchPopupDialogData | null>();
  const selectedIdsRef = useRef<Array<number>>([]);
  const finalSelectedIdsRef = useRef<number[][]>([]);
  const selectedItemsData = useRef<DropdownOption[][]>([]);
  const remoteSearchInputText = useRef<string | null>(null);
  const bulkDialogDefaultData = useRef<Record<string, unknown> | null>();
  const gridColumnsRef = useRef<Array<Record<string, unknown>>>([]);
  const actionAddButtonRef = useRef<HTMLButtonElement | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTopFilterOpen, setIsTopFilterOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isBulkDialogOpen, setIsBulkDialogOpen] = useState<boolean>(false);
  const [dropdownData, setDropdownData] = useState<DropdownData | null>(null);
  const [forceTreeLevel, setForceTreeLevel] = useState<boolean>(false);
  const [gridParameters, setGridParameters] = useState<GridParametersInterface>({
    currentPage: 1,
    perPage: 15,
    filterValues: [],
    sort: { field: '', order: '' },
  });

  useEffect(() => {
    actorOnDispatch('searchDialog', details => {
      dialogDataRef.current = details;

      if (
        Array.isArray(dialogDataRef.current.dropBaseValue) &&
        dialogDataRef.current.dropBaseValue.length > 0
      ) {
        selectedIdsRef.current = dialogDataRef.current.dropBaseValue.map(item =>
          Number(item.value),
        );

        selectedItemsData.current[gridParameters.currentPage - 1] =
          dialogDataRef.current.dropBaseValue;

        finalSelectedIdsRef.current[gridParameters.currentPage - 1] =
          selectedIdsRef.current;
      }

      if (details.dropdownMultipleSelection) {
        setIsBulkDialogOpen(true);
      } else {
        setIsDialogOpen(details.isOpen);
      }
    });
  }, []);

  useEffect(() => {
    if (isDialogOpen || isBulkDialogOpen) {
      setIsLoading(true);
      makeRequest();
    }
  }, [gridParameters, isDialogOpen, isBulkDialogOpen]);

  // because it may be in first render of `AppLayout.js` without any data
  if (!isDialogOpen && !isBulkDialogOpen) return <></>;

  const {
    resource,
    dropdownMeta,
    relationResource,
    relationSource,
    metaData,
    relationInfo,
    relationMetaData,
    addToRelationArray,
    dropdownMultipleSelection,
    source,
    changeFormValue,
    record,
    parentResource,
    dropdownId,
    dropBaseValue = [],
    formActionsHandler,
    dropdownInPuzzleForm,
    isTodo,
    isProfile,
    isService,
    isFilter,
    field,
    parentResourceType,
    changeFormValueByClickingOnRow,
    isFilterInput,
    maxSelection,
  } = dialogDataRef.current!;

  const emptyDropdownData: DropdownData = {
    preparedIds: [],
    preparedData: {},
    preparedDataLastLevel: {},
    preparedLastLevelIds: [],
    totalDataCount: 0,
  };

  /**
   * In the `searchPopupDialogContainer`, by clicking on the title of each column,
   * the field title and sort type are entered into this function as a parameter,
   * and it sorts the clicked column and sends a request.
   * @function sortPopupDialog
   * @param {string} titleField
   * @param {string} sortType
   * @returns {void}
   */
  const sortPopupDialog = (titleField, sortType): void => {
    setGridParameters(prevGridParameters => {
      return {
        ...prevGridParameters,
        sort: {
          field: titleField,
          order: sortType,
        },
      };
    });
  };

  /**
   * it will reset states
   * @function resetStates
   * @returns {void} void
   */
  const resetStates = () => {
    dialogDataRef.current = null;
    selectedIdsRef.current = [];
    finalSelectedIdsRef.current = [];
    selectedItemsData.current = [];
    remoteSearchInputText.current = '';
    bulkDialogDefaultData.current = {};
    gridColumnsRef.current = [];

    setIsDialogOpen(false);
    setIsBulkDialogOpen(false);
    setDropdownData(null);
    setGridParameters({
      currentPage: 1,
      perPage: 15,
      filterValues: [],
      sort: { field: '', order: '' },
    });
  };

  /**
   * it will change form value and update edited formData then close dialog
   * @function handleRowClick
   * @param {Record<string, unknown>} row
   * @returns {void}
   */
  const handleRowClick = (row: Record<string, unknown>): void => {
    if (row) {
      changeFormValueByClickingOnRow?.(row);
    }
    resetStates();
  };

  /**
   * update dropdown data state
   * @param {object} data
   * @returns {void} void
   */
  const updateDropdownData = (data: {
    ALL?: Record<string, unknown>[] | undefined;
    DATA?: Record<string, string>[] | undefined;
    TOTAL?: number | undefined;
  }) => {
    if (!(data && dialogDataRef.current)) return;
    const { dropdownMeta } = dialogDataRef.current;
    const { DATA = [], TOTAL = 0 } = data ?? {};

    gridColumnsRef.current = prepareGridColumns(dropdownMeta);

    setDropdownData(
      prepareDropdownData(
        dropdownMeta,
        DATA as Array<Record<string, unknown> & { id: number }>,
        dropdownId,
        TOTAL,
      ),
    );

    setIsLoading(false);
    disabledGridParametersRequest = false;
  };

  /**
   * it should compute request params and call findDropdownData function with computed parameters.
   * @function makeRequest
   * @returns {void}
   */
  const makeRequest = () => {
    if (disabledGridParametersRequest) return;

    const { currentPage, filterValues, perPage, sort } = gridParameters;

    const option = {
      dropdownInPuzzleForm,
      isTodo,
      isProfile,
      isService,
      isFilter,
      perPage,
      page: currentPage,
      forceTreeLevel,
      filterValues,
      sort,
      fieldName: isFilterInput ? null : field.name,
      fieldId: isFilterInput ? null : field.id,
    };

    const details = {
      option,
      dropdownMeta,
      resource: parentResource,
      resourceType: parentResourceType,
      successCallback: updateDropdownData,
      searchValue: remoteSearchInputText.current,
      failureCallback: () => {
        setIsLoading(false);
        setDropdownData(emptyDropdownData);
      },
    };

    if (typeof formActionsHandler === 'function') {
      formActionsHandler(FormActions.FetchDropdownData, details);
      return;
    }

    // else
    actorDispatch('fetchDropdownData', details, {
      replaceAll: true,
    });
  };

  /**
   * set per page with new value
   * @function changePerPage
   * @param {number} perPage
   * @returns {void}
   */
  const changePerPage = perPage => {
    setGridParameters(prevGridParameters => {
      return {
        ...prevGridParameters,
        perPage,
      };
    });
  };

  /**
   * set page with new value
   * @function changePage
   * @param {number} pageNo
   * @returns {void}
   */
  const changePage = pageNo => {
    finalSelectedIdsRef.current[gridParameters.currentPage - 1] = clone(
      selectedIdsRef.current,
    );

    setGridParameters(prevGridParameters => {
      return {
        ...prevGridParameters,
        currentPage: pageNo,
      };
    });
  };

  /**
   * set state for get `dropdownData` and call `makeRequest`.
   * @returns {void}
   */
  const loadDataForGrid = () => {
    setGridParameters(prevGridParameters => {
      return {
        ...prevGridParameters,
        currentPage: 1,
        perPage: 15,
      };
    });

    setForceTreeLevel(lodashGet(dropdownMeta, 'forceTreeLevel', false));
  };

  const loadDataForTree = () => {
    setGridParameters(prevGridParameters => {
      return {
        ...prevGridParameters,
        currentPage: 1,
        perPage: 100000,
      };
    });

    setForceTreeLevel(false);
  };

  /**
   * et new filters
   * @function setFilters
   * @param {Array<string>} newFilters
   * @returns {void}
   */
  const setFilters = debounce(newFilters => {
    // merge clone into new variable
    const mergedFilterValues = mergeAndClone(
      gridParameters.filterValues,
      newFilters,
    );

    // only apply filters with value
    Object.keys(mergedFilterValues).forEach(key => {
      if (!newFilters[key]) {
        delete mergedFilterValues[key];
      }
    });

    const filterValues: FilterItemFinalFormatType[] = [];
    const filters: FilterItemFinalFormatType[] = Object.values(mergedFilterValues);

    for (const item of filters) {
      filterValues.push(item);
      filterValues.push('and');
    }
    filterValues.pop(); // remove last extra `and`

    setGridParameters(prevGridParameters => {
      return {
        ...prevGridParameters,
        filterValues,
        currentPage: 1,
      };
    });
  }, 700);

  /**
   * open quick create for selected ids.
   * @returns {void}
   */
  const openQuickCreateWithForSelectedIds = () => {
    const summaryFields = getShowSummaryColumnList(relationMetaData);
    const fields = getGridColumns({ metaData: relationMetaData, isRelation: true });
    const globalParameters = actorGetActionValue(
      'profile',
      'profileData.globalparameters',
    );
    // sometimes, summary fields are not part of grid fields, su get them both and merge them
    const defaultRowData = getFormDefaultValue(
      [...fields, ...summaryFields],
      globalParameters,
    );
    const sourceParts = source.split('.');
    const fieldName = sourceParts[sourceParts.length - 1];
    const defaultData = clone(record);
    lodashSet(
      defaultData,
      relationSource,
      selectedIdsRef.current.map(dropdownId => ({
        ...defaultRowData,
        [fieldName]: dropdownId,
        id: Date.now() + '-' + Math.random(), // give fake id to track errors in relation form
        isNewRecord: true,
      })),
    );
    selectedIdsRef.current = [];
    bulkDialogDefaultData.current = defaultData;
    setIsBulkDialogOpen(true);
  };

  /**
   * should separate item values and join them with ',' and call onChange
   * then close the dialog;
   * @function handleMultipleSelect
   * @returns {void}
   */
  const handleMultipleSelect = () => {
    const selectedIds = Array.from(
      new Set(finalSelectedIdsRef?.current?.flat()),
    )?.join();

    const selectedItemsData = Object.values(dropdownData?.preparedData ?? {}).filter(
      data =>
        selectedIds.split(',').includes(String((data as { id: number })?.['id'])),
    );

    changeFormValue({
      selectedIds,
      selectedItemsData,
    });

    closeBulkDialog();
  };

  const closeBulkDialog = () => {
    setIsBulkDialogOpen(false);
    resetStates();
  };

  /**
   * Takes the searched value and sends it to the DropdownHelpers to perform the search operation.
   * @function handleDebounceTrigger
   * @returns {void}
   */
  const handleDebounceTrigger = value => {
    const option = {
      dropdownInPuzzleForm,
      isTodo,
      isProfile,
      isService,
      forceTreeLevel,
      fieldName: field.name,
      fieldId: field.id,
      isFilter,
      page: gridParameters.currentPage,
      perPage: gridParameters.perPage,
    };

    const details = {
      option,
      dropdownMeta,
      resource: parentResource,
      resourceType: parentResourceType,
      searchValue: value,

      successCallback: updateDropdownData,
    };

    if (typeof formActionsHandler === 'function') {
      formActionsHandler(FormActions.FetchDropdownData, details);
      return;
    }

    // else
    actorDispatch('fetchDropdownData', details, {
      replaceAll: true,
    });
  };

  /**
   * Sets the search entry in the state and using the handleDebounceTrigger, it takes the entered value into the DropdownHelpers
   * and finally changes the page number in the state to one.
   * @function handleChange
   * @returns {void}
   */
  const handleChange = debounce(event => {
    remoteSearchInputText.current = event.target.value;
    handleDebounceTrigger(event.target.value);

    disabledGridParametersRequest = true;
    setGridParameters(prevGridParameters => {
      return {
        ...prevGridParameters,
        currentPage: 1,
      };
    });
  }, 700);

  /**
   * it will filter `dropdownData` to find selected items and return it
   * @function getInitialSelection
   * @returns {Array<object>}
   */
  const getInitialSelection = () => {
    const _data =
      dropForceTreeLevel && dropdownMeta.type === 'Tree'
        ? dropdownData?.preparedDataLastLevel
        : dropdownData?.preparedData;

    if (!_data) return [];

    const selectedItemsInDropdownData = Object.values(_data).filter(
      item =>
        dropBaseValue.findIndex(
          dropValue =>
            dropValue.value ===
            (item as Record<string, unknown>)[dropdownMeta.valueMember],
        ) > -1,
    );
    return selectedItemsInDropdownData;
  };

  const dropForceTreeLevel = lodashGet(dropdownMeta, 'forceTreeLevel', false);
  const dropTreeLevel = lodashGet(dropdownMeta, 'treeLevel');

  const isSelectionEnabled =
    !!(relationResource && relationSource && relationMetaData) ||
    !!dropdownMultipleSelection;

  /**
   * it will clear grid filters if any filters exist
   * @function clearFilters
   * @returns {void} void
   */
  const clearFilters = () => {
    if (!isEmptyObject(gridParameters.filterValues)) {
      setGridParameters(prevGridParameters => {
        return {
          ...prevGridParameters,
          filterValues: [],
        };
      });
    }
  };

  /**
   * it will toggle `IsTopFilterOpen` state to decide show or dont show filters in each column in grid
   * and also it should call `clearFilters` function if it going to set false in state
   * @function toggleShowFilters
   * @returns {void} void
   */
  const toggleShowFilters = (): void => {
    setIsTopFilterOpen(prevState => {
      if (prevState) {
        // it means filter bar is open and should be close, so should clear grid filters from parameters
        clearFilters();
      }
      return !prevState;
    });
  };

  /**
   * forward user to dropdown crate page in new tab
   * @function openDropdownCreatePageInNewTab
   * @returns {void} void
   */
  const openDropdownCreatePageInNewTab = (): void => {
    const prefix = actorGetActionValue('urlInfo')?.location.origin;
    const url = `${prefix}/#/${dropdownMeta.moduleName}/${dropdownMeta.tableName}/create?fromDropdown=true`;
    openLinkNewTab(url, true);
  };

  const onSelectedItemChange = (
    selectedRowKeys: number[],
    currentDeselectedRowKeys: number[],
  ): void => {
    if (finalSelectedIdsRef.current[gridParameters.currentPage - 1] == null) {
      finalSelectedIdsRef.current[gridParameters.currentPage - 1] = [];
    }

    if (selectedItemsData.current[gridParameters.currentPage - 1] == null) {
      selectedItemsData.current[gridParameters.currentPage - 1] = [];
    }

    finalSelectedIdsRef.current[gridParameters.currentPage - 1] = Array.from(
      new Set(
        [
          ...finalSelectedIdsRef.current[gridParameters.currentPage - 1],
          ...selectedRowKeys,
        ]?.filter(item => {
          if (typeof item === 'number' && Number.isNaN(item)) {
            return false;
          }
          return true;
        }),
      ),
    );

    selectedIdsRef.current = selectedRowKeys;

    const { valueMember } = dropdownMeta;

    for (const id of selectedRowKeys) {
      if (
        selectedItemsData.current[gridParameters.currentPage - 1].findIndex(
          item => item?.[valueMember] === id,
        ) > -1
      ) {
        continue;
      }

      const selectedDropItem = dropdownData?.preparedData[id] as Record<
        string,
        unknown
      >;

      const formattedItem = {
        text: getLabelForDropdownOption(field.dropdown, selectedDropItem),
        value: selectedDropItem?.[valueMember] + '',
        key: `${selectedDropItem?.[valueMember]}-${id}`,
      };

      selectedItemsData.current[gridParameters.currentPage - 1].push(formattedItem);
    }

    if (Array.isArray(currentDeselectedRowKeys)) {
      for (const id of currentDeselectedRowKeys) {
        let index = selectedItemsData.current[
          gridParameters.currentPage - 1
        ].findIndex(item => item?.[valueMember] === id);

        if (index > -1) {
          selectedItemsData.current[gridParameters.currentPage - 1].splice(index, 1);
        }

        index = dropBaseValue.findIndex(item => Number(item.value) == id);
        if (index > -1) {
          dropBaseValue.splice(index, 1);
        }

        index =
          finalSelectedIdsRef.current[gridParameters.currentPage - 1].indexOf(id);
        if (index > -1) {
          finalSelectedIdsRef.current[gridParameters.currentPage - 1].splice(
            index,
            1,
          );
        }
      }
    }

    // prevent to select more than `maxSelection`
    if (maxSelection && finalSelectedIdsRef.current.flat().length > maxSelection) {
      actionAddButtonRef.current!.setAttribute('disabled', '');
    } else {
      actionAddButtonRef.current!.removeAttribute('disabled');
    }
  };

  const preparedSelectedIds = Array.from(
    new Set([
      ...dropBaseValue.map(item => {
        return typeof item.value === 'object' ? item.value : item;
      }),
      ...finalSelectedIdsRef.current.flat(),
    ]),
  )?.filter(item => {
    if (typeof item === 'number' && Number.isNaN(item)) {
      return false;
    }
    return true;
  });

  return (
    <SearchPopupDialogView
      isOpen={isDialogOpen || isBulkDialogOpen}
      closeDialog={resetStates}
      isLoading={isLoading}
      resource={resource}
      dropdownMeta={dropdownMeta}
      handleChange={handleChange}
      dropForceTreeLevel={dropForceTreeLevel}
      loadDataForGrid={loadDataForGrid}
      sortPopupDialog={sortPopupDialog}
      sort={gridParameters.sort}
      setFilters={setFilters}
      filterValues={gridParameters.filterValues}
      handleRowClick={handleRowClick}
      metaData={metaData}
      isSelectionEnabled={isSelectionEnabled}
      selectedIds={preparedSelectedIds}
      getInitialSelection={getInitialSelection}
      currentPage={gridParameters.currentPage}
      perPage={gridParameters.perPage}
      changePerPage={changePerPage}
      changePage={changePage}
      loadDataForTree={loadDataForTree}
      dropTreeLevel={dropTreeLevel}
      dropdownMultipleSelection={dropdownMultipleSelection}
      handleMultipleSelect={handleMultipleSelect}
      openQuickCreateWithForSelectedIds={openQuickCreateWithForSelectedIds}
      dropdownData={dropdownData ?? emptyDropdownData}
      gridColumns={gridColumnsRef.current}
      isTopFilterOpen={isTopFilterOpen}
      toggleShowFilters={toggleShowFilters}
      refreshGridData={makeRequest}
      openDropdownCreatePageInNewTab={openDropdownCreatePageInNewTab}
      onSelectedItemChange={onSelectedItemChange}
      actionAddButtonRef={actionAddButtonRef}
    />
  );
};

export default SearchPopupDialogContainer;
