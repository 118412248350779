import { GET_LIST } from 'react-admin';

import { actorDispatch, actorGetActionValue } from '../../type/actor-setup';
import { RUN_SERVICE } from '../../core/data-Provider.helper';
import {
  createOrEditTaskServiceId,
  getTasksReportUrl,
} from './todo-section-static-data.helper';
import { getTargetParamFromSearchParams } from '../../helper/general-function-helper';

import type { TodoTaskItemInterface } from './todo-tasks/todo-task-item/todo-task-item.type';
import type { FinalFiltersType } from '../filter-form';
import type {
  CreateTodoTask,
  EditTodoTask,
  GetTasksFromApi,
  GetTasksFromApiVariants,
} from './todo-section.type';

/**
 * prepare request filters
 * @function prepareGetTasksFromApiFilters
 * @param {GetTasksFromApiVariants} variant
 * @param {string} search
 * @returns {FinalFiltersType} preparedFilters
 */
const prepareGetTasksFromApiFilters = (
  variant?: GetTasksFromApiVariants,
  search?: string,
): FinalFiltersType => {
  const preparedFilters: FinalFiltersType = [];

  const selectedTodoFolderId = getSelectedTodoFolderId();

  if (selectedTodoFolderId) {
    preparedFilters.push(['folders_ID', 'equal', selectedTodoFolderId]);
  }

  if (variant) {
    preparedFilters.push('and');

    if (variant === 'isImportant' || variant === 'isAddedToMyDay') {
      preparedFilters.push([variant, 'equal', 'true']);
    } else if (variant === 'dueDate') {
      preparedFilters.push([variant, 'notequal', '']);
    } else if (variant === 'assignedToMe') {
      preparedFilters.push(['agentuser', 'notequal', '']);
    }
  }

  if (search) {
    preparedFilters.push('and');
    preparedFilters.push(['detailtitle', 'equal', search]);
  }

  return preparedFilters;
};

/**
 * request and get tasks from API
 * @function getTasksFromApi
 * @param {{
 *  successCallback: (data: TodoTaskItemInterface[]) => void,
 *  failureCallback: (error: unknown) => void,
 *  variant?: GetTasksFromApiVariants,
 *  search?: string,
 * }} props
 * @returns {void} void
 */
export const getTasksFromApi: GetTasksFromApi = (props): void => {
  const { successCallback, failureCallback, variant, search } = props;

  actorDispatch(
    'crudAction',
    {
      type: GET_LIST,
      entity: 'todo',
      resource: getTasksReportUrl,
      requestParameters: {
        pagination: { page: 0, perPage: 9999 },
        sort: { field: 'id', order: 'DESC' },
        filter: prepareGetTasksFromApiFilters(variant, search),
      },
      onSuccess: (response: { data: TodoTaskItemInterface[] }): void => {
        actorDispatch('todoTaskList', response.data);
        successCallback?.(response.data);
      },
      onFailure: (error: unknown): void => {
        failureCallback(error);
      },
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'getTasksFromApi',
    },
  );
};

/**
 * send a request to create new task
 * @function createTodoTask
 * @param {{
 *  successCallback: (data: TodoTaskItemInterface, userMessage: string) => void,
 *  failureCallback: (error: unknown) => void,
 *  title: string
 * }} props
 * @returns {void} void
 */
export const createTodoTask: CreateTodoTask = props => {
  const { successCallback, failureCallback, title, customOptions } = props;

  const taskFormData = actorGetActionValue('taskFormData');

  actorDispatch(
    'crudAction',
    {
      type: RUN_SERVICE,
      entity: 'todo',
      data: {
        params: {
          DetailTitle: title,
          ...taskFormData,
          ...customOptions,
          folders_id: getSelectedTodoFolderId(),
        },
      },
      actionUniqueId: createOrEditTaskServiceId,
      onSuccess: (response: {
        data: TodoTaskItemInterface;
        userMessage: string;
      }): void => {
        successCallback(response);
      },
      onFailure: (error: unknown): void => {
        failureCallback(error);
      },
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'createTodoTask',
    },
  );
};

/**
 * @function getTodoTaskFolderId
 * @param {string} searchParam
 * @returns {number | null} folderId
 */
export const getTodoTaskFolderId = (searchParam: string): number | null => {
  const uniqueId = getTargetParamFromSearchParams(searchParam, 'id');

  const todoFolders = actorGetActionValue('todoFolders');
  const folderId = todoFolders?.find(
    mailFolder => mailFolder.uniqueid === uniqueId,
  )?.folders_id;

  return folderId ?? null;
};

/**
 * send a request to edit a task
 * @function editTodoTask
 * @param {{
 *  successCallback: (data: TodoTaskItemInterface, userMessage: string) => void,
 *  failureCallback: (error: unknown) => void,
 *  taskUID: string,
 *  newValues: Partial<Omit<TodoTaskItemInterface, 'TaskUID' | 'folders_id'>>
 * }} params
 * @returns {void} void
 */
export const editTodoTask: EditTodoTask = params => {
  const { successCallback, failureCallback, taskUID, newValues } = params;
  const tasks = actorGetActionValue('todoTaskList')!;

  let taskRecordForEdit =
    tasks?.filter(data => data.uniqueid === taskUID)?.[0] ?? {};

  taskRecordForEdit = { ...taskRecordForEdit, ...newValues };

  const urlInfo = actorGetActionValue('urlInfo');
  const folderId = getTodoTaskFolderId(urlInfo?.location.search ?? '');

  actorDispatch(
    'crudAction',
    {
      type: RUN_SERVICE,
      entity: 'todo',
      data: {
        params: {
          TaskUID: taskUID,
          ...taskRecordForEdit,
        },
      },
      actionUniqueId: createOrEditTaskServiceId,

      onSuccess: (response: {
        data: TodoTaskItemInterface;
        userMessage: string;
      }): void => {
        if (folderId) {
          actorDispatch('offlineRefreshView', {
            entity: 'todoTasks',
            newData: response.data,
            mode: 'update',
          });
        } else {
          actorDispatch('refreshView', 'todo');
        }
        successCallback?.(response);
      },
      onFailure: (error: unknown): void => {
        failureCallback?.(error);
      },
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * extract folder id from URL
 * @function getSelectedTodoFolderId
 * @returns {number | null} folder id
 */
export const getSelectedTodoFolderId = (): number | null => {
  const urlParams = window.location.href;

  // because its possible that `#open` exists in url
  let uniqueId = urlParams?.split('id:')?.[1]?.split('#')?.[0];

  const targetIndex = uniqueId?.indexOf('&');
  const targetIndexBySharp = uniqueId?.indexOf('#');

  if (targetIndex > -1 || targetIndexBySharp > -1) {
    uniqueId = uniqueId?.substring(0, targetIndex);
  }

  const todoFolders = actorGetActionValue('todoFolders');

  if (!todoFolders) return null;
  const targetFolderID = todoFolders.find(
    todoFolder => todoFolder.uniqueid === uniqueId,
  )?.folders_id;

  return targetFolderID ?? null;
};

/**
 * extract folder id from URL
 * @function getUniqIdFromUrl
 * @param {string} url
 * @returns {string | null} uniqueid
 */
export const getUniqueIdFromUrl = (url: string) => {
  return url?.split('uniqueid:');
};
