import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';
import { StyleProps } from '../confirm-dialog';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  confirmButton: {
    color: theme.palette.primary.appLightBackgroundColor,
    background: ({ confirmBgColor }) =>
      confirmBgColor
        ? confirmBgColor
        : theme.palette.primary.automationBackgroundConfirmButton,
  },
}));
