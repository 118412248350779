import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { MailFormAction } from '../../mail-section/mail-form/mail-form-action';
import { useStyles } from './todo-sidebar.style';
import { AppDrawer } from '../../app-drawer';
import { AppDialog } from '../../app-dialog';
import { NewFolderForm } from '../../mail-section/mail-form';
import {
  todoStaticNewFolderFields,
  todoStaticShareFormFields,
} from '../todo-section-static-data.helper';

import type { TodoSidebarViewInterface } from './todo-sidebar.type';

const TodoSidebarView = (props: TodoSidebarViewInterface): ReactElement => {
  const {
    todoSidebarRoutesConfig,
    isNewFolderFormOpen,
    handleCloseNewFolderForm,
    onCreateNewFolder,
    isShareFormOpen,
    handleCloseShareForm,
    handleShareFolder,
  } = props;

  const translate = useTranslate();

  const classes = useStyles();

  return (
    <div data-test="todo-sidebar-container" data-style-mailSidebar="todoSidebar">
      <DndProvider backend={HTML5Backend}>
        <AppDrawer
          routesConfig={todoSidebarRoutesConfig}
          baseUrl="/oa"
          data-test="todo-sidebar-drawer"
          parentComponentName={'todo'}
          canDragDrop={true}
        />
      </DndProvider>
      {isNewFolderFormOpen && (
        <AppDialog
          open={isNewFolderFormOpen}
          handleClose={handleCloseNewFolderForm}
          dialogTitle={translate('todo.createNewList')}
          dialogContent={<NewFolderForm fields={todoStaticNewFolderFields} />}
          dialogActions={
            <MailFormAction
              onCancel={handleCloseNewFolderForm}
              onSubmit={onCreateNewFolder}
            />
          }
          maxWidth="sm"
          disableBackdropClick={true}
        />
      )}
      {isShareFormOpen && (
        <AppDialog
          open={isShareFormOpen}
          contentClassName={classes.shareFormContainer}
          handleClose={handleCloseShareForm}
          dialogTitle={translate('todo.shareList')}
          dialogContent={<NewFolderForm fields={todoStaticShareFormFields} />}
          dialogActions={
            <MailFormAction
              onCancel={handleCloseShareForm}
              onSubmit={handleShareFolder}
            />
          }
          maxWidth="md"
          disableBackdropClick={true}
        />
      )}
    </div>
  );
};

export default TodoSidebarView;
