import { useEffect, useMemo, useState, useRef, type FC } from 'react';
import { useTranslate } from 'react-admin';

import { USER_ID, API_NAME, getValue } from '../../core/configProvider';
import { setResource, removeLastResource } from '../../helper/resource-helper';
import { changePasswordFields } from '../form/change-password-form/change-password-form.helper';
import HeaderProfileView from './header-profile.view';
import { profileSettingFields } from './header-profile.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  FormKeyMode,
  FullProfileInterface,
} from '../../type/actor-setup';

const HeaderProfileContainer: FC<any> = ({ isDrawerOpen: _isDrawerOpen }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(_isDrawerOpen);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [profile, setProfile] = useState<FullProfileInterface>(
    actorGetActionValue('profile') ?? {},
  );

  const { isLoading, profileData } = profile;

  const activeTab = useRef<string | unknown>('profile');

  const translate = useTranslate();

  useEffect(() => {
    actorOnDispatch('profile', _profile => {
      setProfile(_profile);
    });

    actorSetActionValue('handleCloseProfileFormDialog', {
      handleCloseProfileFormDialog: closeDialog,
    });
  }, []);

  const handleNewProfileCreate = () => {
    setIsDialogOpen(false);
  };

  const currentUserId = getValue(USER_ID);
  const apiName = getValue(API_NAME);

  const resource = useMemo(
    () => (isDialogOpen ? actorGetActionValue('resources')!.current.value : ''),
    [isDialogOpen],
  );

  const resetPasswordFields = useMemo(() => changePasswordFields(translate), []);

  /**
   * open profile dialog
   * @function openDialog
   * @returns {void}
   */
  const openDialog = (): void => {
    setResource(`account/${apiName}/${activeTab.current}`, FormKeyMode.PROFILE);
    setIsDialogOpen(true);
  };

  /**
   * close profile dialog
   * @function closeDialog
   * @returns {void}
   */
  const closeDialog = (): void => {
    actorDispatch('remove', {
      resource: `account/${apiName}/${activeTab.current}`,
      type: FormKeyMode.PROFILE,
    });
    activeTab.current = 'profile';
    setIsDialogOpen(false);
  };

  /**
   * @function toggleDrawer
   * @returns {void}
   */
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    actorDispatch('isDrawerOpen', !isDrawerOpen);
  };

  /**
   * change resource when change tabs
   * @function onTabChange
   * @param {Record<string, Record<string, unknown>>} tab
   * @returns {void}
   */
  const onTabChange = (tab: Record<string, Record<string, unknown>>): void => {
    const name = tab.props?.name ?? '';
    removeLastResource(FormKeyMode.PROFILE);
    setResource(`account/${apiName}/${name}`, FormKeyMode.PROFILE);
    activeTab.current = name;
  };

  return (
    <HeaderProfileView
      isDrawerOpen={isDrawerOpen}
      currentUserId={currentUserId}
      profileIsLoading={isLoading}
      profileUpdateLoading={isLoading}
      profileData={profileData}
      isDialogOpen={isDialogOpen}
      onTabChange={onTabChange}
      profileSettingFields={profileSettingFields}
      handleNewProfileCreate={handleNewProfileCreate}
      apiName={apiName}
      fileUploadLimitMB={profileData?.globalParameters?.fileUploadLimitMB}
      closeDialog={closeDialog}
      resource={resource}
      isLoading={isLoading}
      resetPasswordFields={resetPasswordFields}
      toggleDrawer={toggleDrawer}
      openDialog={openDialog}
    />
  );
};

export default HeaderProfileContainer;
