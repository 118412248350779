import { FC, useMemo } from 'react';
import { useTranslate } from 'react-admin';

import type { SettingBarControllerProps } from './setting-bar.type';
import SettingBarView from './setting-bar.view';
import { GeneralMetaData } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  GridDataInterface,
} from '../../../type/actor-setup';
import NotFound from '../../NotFound';

const SettingBarController: FC<SettingBarControllerProps> = props => {
  const {
    resource,
    isTree = false,
    metaData,
    isGroupingEnable = true,
    isColumnChoiceEnable = true,
    fields,
    rootResource,
    isExportButtonEnable = true,
  } = props;

  const translate = useTranslate();

  const isExportButtonDisabled = useMemo(() => {
    const isReport = resource.indexOf('report') === 0;
    return (!isReport && !metaData?.['config']?.['allowExport']) ?? null;
  }, [
    resource,
    (metaData as GeneralMetaData)?.config?.moduleName,
    (metaData as GeneralMetaData)?.config?.moduleTableName,
    (metaData as GeneralMetaData)?.id, // for reports
  ]);

  /**
   * it toggle devExpress grid grouping feature
   * @function toggleDevExGrouping
   * @returns {void}
   */
  const toggleDevExGrouping = (): void => {
    const isGroupingEnable = actorGetActionValue('isGridGroupingEnable')?.[
      `${resource}`
    ];
    actorDispatch('isGridGroupingEnable', !isGroupingEnable, { path: resource });
  };

  if (!resource || !metaData) {
    return <NotFound title={translate('ra.navigation.no_results')} />;
  }

  // It's so important, we need main source to find filters in root resource
  const finalResource = rootResource ?? resource;
  const filterExcel = (
    actorGetActionValue('gridData', finalResource) as GridDataInterface | null
  )?.requestParameters?.filter;

  const sortExcel = (
    actorGetActionValue('gridData', finalResource) as GridDataInterface | null
  )?.requestParameters?.sort;

  return (
    <SettingBarView
      resource={resource}
      isGroupingEnable={isGroupingEnable}
      metaData={metaData as GeneralMetaData}
      toggleDevExGrouping={toggleDevExGrouping}
      isColumnChoiceEnable={isColumnChoiceEnable}
      fields={fields}
      isTree={isTree}
      isExportButtonDisabled={isExportButtonDisabled}
      filterExcel={filterExcel}
      sortExcel={sortExcel}
      isExportButtonEnable={isExportButtonEnable}
    />
  );
};

export default SettingBarController;
