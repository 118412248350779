import { useRef } from 'react';
import ManageGroupDialogView from './manage-group-dialog.view';

import { actorDispatch } from '../../../../type/actor-setup';
import {
  prepareSelectedUsersBeforeCreateGroup,
  updateGroup,
} from '../../group-chats.helper';
import { isEmptyObject } from '../../../../helper/data-helper';
import { EDIT_MODE } from '../add-members-dialog/add-members-dialog.helper';
import { getChats } from '../../chat-section.helper';

import type { ChangeEvent, ReactElement } from 'react';
import type { ManageGroupDialogPropsInterface } from './manage-group-dialog.type';
import type { FileStreamInterface } from '../create-group-dialog/create-group-dialog.type';
import type { ChatItemInterface } from '../../chat-section.type';

const ManageGroupDialogController = (
  props: ManageGroupDialogPropsInterface,
): ReactElement => {
  const { closeDialogHandler, dialogData } = props;
  const { selectedUser, groupUsers } = dialogData;

  const newImageFileStream = useRef<string>('');

  const inputValueRef = useRef<string>(selectedUser.personname);

  /**
   * @function handleInputChange
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    inputValueRef.current = event.target.value;
  };

  /**
   * @function handleSaveSuccess
   * @param {ApiRequestResultInterface} _response
   * @returns {void} void
   */
  const handleSaveSuccess = (response: ChatItemInterface): void => {
    actorDispatch('selectedChat', response, {
      path: 'info',
    });

    getChats({
      dataShouldBeReplaced: true,
      shouldLoading: true,
      page: 1,
    });

    actorDispatch('closeDialogs', true);
  };

  /**
   * @function handleSave
   * @returns {void} void
   * @description handle save button click
   */
  const handleSave = (): void => {
    if (!selectedUser.groupuid) return;
    updateGroup(
      {
        GroupUID: selectedUser.groupuid,
        GroupName: inputValueRef.current,
        GroupProfileImage: newImageFileStream.current,
        IsUseInChat: 1,
        MemberPersonInfoIDs: prepareSelectedUsersBeforeCreateGroup(groupUsers),
        IsChannel: Number(selectedUser.ischannel),
      },
      handleSaveSuccess,
    );
  };

  /**
   * @function openAddMembersDialog
   * @returns {void} void
   * @description open add members dialog
   */
  const openAddMembersDialog = (): void => {
    actorDispatch('quickDialog', {
      addMembersDialogIsOpen: true,
      chatCreateGroupDialogIsOpen: false,
      chatInfoDialogIsOpen: false,
      manageGroupDialogIsOpen: false,
      data: {
        GroupUID: selectedUser.groupuid,
        GroupProfileImage: selectedUser.personimage,
        GroupName: selectedUser.personname,
        IsUseInChat: 1,
        groupUsers,
        IsChannel: Number(selectedUser.ischannel),
        mode: EDIT_MODE,
      },
    });
  };

  /**
   * @function openAddMembersDialog
   * @returns {void} void
   * @description open add members dialog
   */
  const openAdminsDialog = (): void => {
    actorDispatch('quickDialog', {
      adminsDialogIsOpen: true,
      chatCreateGroupDialogIsOpen: false,
      chatInfoDialogIsOpen: false,
      manageGroupDialogIsOpen: false,
      data: {
        selectedUser,
      },
    });
  };

  /**
   * @function successFileCallback
   * @param {{filepath: string, realFileName:string}} response
   * @returns
   */
  const successFileCallback = (response: FileStreamInterface) => {
    if (!selectedUser.groupuid || isEmptyObject(response)) return;
    newImageFileStream.current = JSON.stringify(response);
  };

  return (
    <ManageGroupDialogView
      openAddMembersDialog={openAddMembersDialog}
      closeDialogHandler={closeDialogHandler}
      successFileCallback={successFileCallback}
      handleInputChange={handleInputChange}
      openAdminsDialog={openAdminsDialog}
      handleSave={handleSave}
      selectedUser={selectedUser}
    />
  );
};

export default ManageGroupDialogController;
