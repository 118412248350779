import { RUN_SERVICE } from '../../../../core/data-Provider.helper';
import { showNotification } from '../../../../helper/general-function-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../../type/actor-setup';

import type { ChatDetailInterface } from '../../chat-section.type';
import type { GroupCreationResultInterface } from './create-group-dialog.type';

const createGroupUniqueId = '3e15abf7-14a2-449f-8ec3-6259c89a5369';

/**
 * @function createGroup
 * @param {GroupName: string, MemberPersonInfoIDs: string, IsUseInChat: number, GroupProfileImage: string | null} params
 * @returns {void} void
 */
export const createGroup = (params: {
  GroupName: string;
  MemberPersonInfoIDs: string;
  IsUseInChat: number;
  GroupProfileImage: string | null;
  IsChannel: number;
}): void => {
  actorDispatch('crudAction', {
    type: RUN_SERVICE,
    actionUniqueId: createGroupUniqueId,
    data: {
      params,
    },
    onSuccess: (response: Record<string, unknown>) => {
      const result = response.data as GroupCreationResultInterface;

      showNotification(response.userMessage, 'success');

      const chatList = actorGetActionValue('chatList')!;
      /**
       * Id `-1` points to the latest created `group` or `chanel`
       */
      if (chatList.data[-1] != null) {
        chatList.data[result.personinfo_id] = { ...chatList.data[-1] };
        delete chatList.data[-1];

        actorSetActionValue('chatList', chatList, { replaceAll: true });
      }

      actorDispatch(
        'onDemandSelectedChat',
        {
          info: {
            chatdate: 'now',
            chattext: '',
            ischannel: result.ischannel,
            isgroupadmin: result.isgroupadmin,
            isowner: result.isowner,
            personimage: result.personimage,
            personname: result.personname,
            personinfo_id: result.personinfo_id ?? -1,
            sumnotseen: result.sumnotseen,
            mentionchatid: -1,
            groupuid: result.groupuid,
            frompersoninfoname: '',
          },

          messagesDetail: {
            data: [],
            hasMore: true,
          },
        } as ChatDetailInterface,
        {
          replaceAll: true,
          callerScopeName: 'createGroup',
        },
      );

      actorDispatch('closeDialogs', true);
    },
    onFailure: error => {
      /**
       * When there is an error, we have to remove the `onDemand` chat from chat list
       */
      const chatList = actorGetActionValue('chatList')!;
      delete chatList.data[-1];
      actorSetActionValue('chatList', chatList, { replaceAll: true });

      actorDispatch('selectedChat', null);

      if (typeof error === 'string') {
        showNotification(error, 'error');
      }
    },
  });
};
