export default {
  ra: {
    action: {
      date:'الرجاء إدخال تنسيق التاريخ الصحيح',
      license:'رخصة',
      table:' طاولة ',
      field:' مجال ',
      module:" وحدة ",
      add_filter: 'إضافة مرشح',
      add: 'إضافة',
      back: 'عد',
      bulk_actions: 'تم تحديد عنصر واحد |||| %{smart_count} العناصر المحددة',
      cancel: 'إلغاء',
      clear_input_value: 'قيمة واضحة',
      clone: 'استنساخ',
      confirm: 'تؤكد',
      create: 'خلق',
      pin: 'احفظ ودبوس',
      delete: 'حذف',
      edit: 'تصحيح',
      export: 'تصدير',
      list: 'قائمة',
      refresh: 'تحديث',
      remove_filter: 'إزالة هذا الفلتر',
      remove: 'إزالة',
      save: 'حفظ',
      search: 'بحث',
      show: 'تبين',
      sort: 'فرز',
      undo: 'فك',
      unselect: 'إلغاء الاختيار',
      reset: 'إعادة تعيين',
      downloadExcelFile: 'تحميل ملف Excel',
      downloadEncodingTextFile: 'تحميل ملف اکسل (encoding)',
      downloadAsciiTextFile: 'تحميل ملف نصي (ascii)',
    },
    boolean: {
      true: 'نعم فعلا',
      false: 'لا',
    },
    page: {
      create: 'خلق %{name}',
      dashboard: 'لوحة القيادة',
      edit: '%{name} #%{id}',
      error: 'هناك خطأ ما',
      list: '%{name}',
      loading: 'جار التحميل',
      not_found: 'غير معثور عليه',
      show: '%{name} #%{id}',
    },
    input: {
      file: {
        upload_several: 'إسقاط بعض الملفات للتحميل ، أو انقر لتحديد واحد.',
        upload_single: 'إسقاط ملف للتحميل ، أو انقر لتحديده.',
      },
      image: {
        upload_several: 'قم بإسقاط بعض الصور للتحميل ، أو انقر لتحديد واحدة.',
        upload_single: 'إسقاط صورة للتحميل ، أو انقر لتحديدها.',
      },
      references: {
        all_missing: 'غير قادر على العثور على بيانات المراجع.',
        many_missing: 'واحد على الأقل من المراجع المرتبطة لم تعد متوفرة.',
        single_missing: 'المرجع المرتبط لم يعد يبدو متاحًا.',
      },
      map: {
        open_map: 'افتح الخريطة',
      },
      upload: 'رفع',
      noFileSelected: 'لم يتم اختيار اي ملف',
      selectFile: 'حدد الملف',
    },
    message: {
      about: 'حول',
      are_you_sure: 'هل أنت واثق؟',
      bulk_delete_content:
        'هل أنت متأكد أنك تريد حذف هذا %{name}? |||| هل أنت متأكد من أنك تريد حذف هذه العناصر%{smart_count}?',
      bulk_delete_title: 'حذف %{name} |||| احذف عناصر%{smart_count}%{name}',
      delete_content: 'هل أنت متأكد أنك تريد حذف هذا البند؟',
      delete_title: 'حذف %{name} #%{id}',
      details: 'تفاصيل',
      error: 'حدث خطأ في العميل ولم يمكن إكمال طلبك.',
      invalid_form: 'النموذج غير صالح. يرجى التحقق من وجود أخطاء',
      loading: 'يتم تحميل الصفحة ، فقط لحظة من فضلك',
      no: 'لا',
      not_found: 'إما أن تكتب عنوان URL خاطئًا ، أو اتبعت رابطًا سيئًا.',
      yes: 'نعم فعلا',
    },
    navigation: {
      no_results: 'لا توجد نتائج',
      no_more_results: 'رقم الصفحة%{page} خارج الحدود. جرب الصفحة السابقة.',
      page_out_of_boundaries: 'رقم الصفحة%{page} خارج الحدود',
      page_out_from_end: 'لا يمكن الذهاب بعد الصفحة الأخيرة',
      page_out_from_begin: 'لا يمكن الذهاب قبل الصفحة 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd}',
      page_rows_per_page: 'الصفوف لكل صفحة:',
      next: 'التالى',
      prev: 'السابق',
    },
    auth: {
      user_menu: 'الملف الشخصي',
      username: 'اسم المستخدم',
      password: 'كلمه السر',
      sign_in: 'تسجيل الدخول',
      sign_in_error: 'أخفقت المصادقة ، يرجى إعادة المحاولة',
      logout: 'الخروج',
      authError: 'وصول غير قانوني',
      showPassword: 'عرض كلمة المرور',
    },
    notification: {
      updated: 'تم تحديث العنصر |||| تم تحديث%{smart_count} من العناصر',
      created: 'تم إنشاء العنصر',
      successfully_executed:'تم التنفيذ بنجاح' ,
      deleted: 'تم حذف العنصر |||| تم حذف%{smart_count} من العناصر',
      bad_item: 'عنصر غير صحيح',
      item_doesnt_exist: 'العنصر غير موجود',
      http_error: 'خطأ في اتصال الخادم',
      code_error: 'خطأ التطبيق',
      data_provider_error:
        'خطأ في مزود البيانات. تحقق من وحدة التحكم للحصول على التفاصيل.',
      canceled: 'تم إلغاء الإجراء',
      logged_out: 'انتهت جلستك ، يرجى إعادة الاتصال.',
      notifications: 'إشعارات',
      thereAreNoNewNotifications: 'لا توجد إخطارات جديدة.',
    },
    validation: {
      required: 'مطلوب',
      minLength: 'يجب أن يكون%{min} حرفًا على الأقل',
      maxLength: 'يجب أن يكون%{max} حرفًا أو أقل',
      minValue: 'يجب أن يكون%{min} على الأقل',
      maxValue: 'يجب أن يكون%{max} أو أقل',
      number: 'يجب أن يكون رقما',
      email: 'يجب أن يكون بريدًا إلكترونيًا صالحًا',
      oneOf: 'يجب أن يكون واحدًا من:%{options}',
      regex: 'يجب أن يتطابق مع تنسيق محدد (regex):%{pattern}',
      correctUrl: 'لم يتم إدخال حقل العنوان بشكل صحيح',
    },
    noItemFound: 'لم يتم العثور على أي عنصر',
    filterWasDeletedSuccessfully: 'تم حذف الترشيح بنجاح',
    filterWasAddedSuccessfully: 'تم إضافة الترشيح بنجاح',
    updatingSettingsFailed: 'فشلت عملية تحديث الإعدادات',
  },
};
