import {
  type MessagesListStatefulActionsType,
  type MessagesListStateInterface,
} from '../messages-list.type';
import { messagesListStatefulActions } from './messages-list-stateful-actions';

export const messagesListInitialState: MessagesListStateInterface = {
  loading: false,
  showScrollDownBtn: false,
  chatData: null,
  selectedMessage: null,
  scrollBoundaryReached: {
    bottom: true,
    top: false,
  },
  updatedMessagesDetail: {
    data: [],
    hasMore: true,
  },
  isUserMentioned: false,
  groupUsers: [],
  pinMessages: [],
  sumNotSeen: -1,
};

export const messagesListReducer = (
  state: typeof messagesListInitialState,
  action: MessagesListStatefulActionsType,
): MessagesListStateInterface => {
  let updatedState: MessagesListStateInterface;

  switch (action.type) {
    case 'setSelectedMessage': {
      updatedState = messagesListStatefulActions.setSelectedMessage(
        state,
        action.payload,
      );
      break;
    }

    case 'setUpdatedMessagesDetail': {
      updatedState = messagesListStatefulActions.setUpdatedUserMessages(
        state,
        action.payload,
      );
      break;
    }
    case 'setShowScrollButton': {
      updatedState = messagesListStatefulActions.setShowScrollButton(
        state,
        action.payload,
      );
      break;
    }

    case 'setLoading': {
      updatedState = messagesListStatefulActions.setLoading(state, action.payload);
      break;
    }

    case 'setSelectedChat': {
      updatedState = messagesListStatefulActions.setSelectedChat(
        state,
        action.payload,
      );
      break;
    }

    case 'scrollToBottomOfMessages': {
      updatedState = messagesListStatefulActions.scrollToBottomOfMessages(state);
      break;
    }

    case 'scrollToTopOfMessages': {
      updatedState = messagesListStatefulActions.scrollToTopOfMessages(state);
      break;
    }

    case 'setScrollBoundary': {
      updatedState = messagesListStatefulActions.setScrollBoundary(
        state,
        action.payload,
      );
      break;
    }

    case 'setIsUserMentioned': {
      updatedState = messagesListStatefulActions.setIsUserMentioned(
        state,
        action.payload,
      );
      break;
    }

    case 'setGroupUsers': {
      updatedState = messagesListStatefulActions.setGroupUsers(
        state,
        action.payload,
      );
      break;
    }

    case 'setPinMessages': {
      updatedState = messagesListStatefulActions.setPinMessages(
        state,
        action.payload,
      );
      break;
    }

    case 'setNewPinMessage': {
      updatedState = messagesListStatefulActions.setNewPinMessage(
        state,
        action.payload,
      );
      break;
    }

    case 'setSumNotSeen': {
      updatedState = messagesListStatefulActions.setSumNotSeen(
        state,
        action.payload,
      );
      break;
    }

    default:
      updatedState = state;
      console.warn('`messagesListReducer` => Invalid action name: %o', {
        state,
        action,
      });
      break;
  }

  return updatedState;
};
