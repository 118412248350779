import { type FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import moment from 'moment';

import ChatItemView from './chat-item.view';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../../../type/actor-setup';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../../core/configProvider';
import { isEmpty } from '../../../../../helper/data-helper';
import { onDeleteChatConfirm } from '../user-list.helper';
import { getMessageSenderUser } from '../../../chat-section.helper';

import type { ChatItemPropsInterface } from './chat-item.type';

const ChatItemController: FC<ChatItemPropsInterface> = props => {
  // prettier-ignore
  const { selectedUser, item, searchValue, onChatItemSelect, isUserInfoDialog } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [unseenMessagesCount, setUnseenMessagesCount] = useState<number>(
    item.sumnotseen,
  );
  const [isOnline, setIsOnline] = useState(false);

  const chatTextContainerElementRef = useRef<HTMLSpanElement>(null);

  const translate = useTranslate();
  const searchModeInChat =
    actorGetActionValue('chatSearchDetails')?.searchModeInChat ?? 'allUser';

  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  useEffect(() => {
    const onDispatches: { actionName: keyof ActorActionList; id: symbol }[] = [];

    let id = actorOnDispatch('usersLastSeen', usersLastSeen => {
      setIsOnline(usersLastSeen?.[String(item.personinfo_id)] === 'online');
    });

    onDispatches.push({
      actionName: 'usersLastSeen',
      id,
    });

    id = actorOnDispatch('unseenMessagesCount', unseenMessagesCountRecord => {
      if (unseenMessagesCountRecord[item.personinfo_id] == null) {
        return;
      }

      setUnseenMessagesCount(unseenMessagesCountRecord[item.personinfo_id]);
    });

    onDispatches.push({
      actionName: 'unseenMessagesCount',
      id,
    });

    return () => {
      for (const { actionName, id } of onDispatches) {
        actorRemoveAction({
          actionName,
          listenerId: id,
        });
      }
    };
  }, []);

  useEffect(() => {
    setUnseenMessagesCount(item.sumnotseen);
  }, [item.sumnotseen]);

  useEffect(() => {
    if (isEmpty(searchValue) || !searchModeInChat) return;

    const _toLowerCaseSearchText = searchValue!.toLowerCase();
    chatTextContainerElementRef.current!.innerHTML = item.chattext.replace(
      new RegExp(_toLowerCaseSearchText, 'g'), // search for all instances,
      `<mark style="background-color: yellow;">${_toLowerCaseSearchText}</mark>`,
    );
  }, [searchValue]);

  /**
   * @function openContextMenu
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
   * @returns { void } void
   */
  const openContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  /**
   * @function getAvatarURL
   * @returns { string } string
   */
  const getAvatarURL = useCallback((): string => {
    return `${apiUrl}/oauth2/${sessionId}/${apiVersion}${item.personimage}`;
  }, [item.personimage]);

  /**
   * @function handleClose
   * @returns { void } void
   */
  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  /**
   * @function handleOnUserSelect
   * @returns { void }
   */
  const chatItemSelectHandler = useCallback((): void => {
    onChatItemSelect(item);
  }, [item]);

  /**
   * @function handleDeleteChat
   * @returns { void }
   */
  const handleDeleteChat = useCallback((): void => {
    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate('ra.message.are_you_sure'),
        onConfirm: () => onDeleteChatConfirm(item),
      },
    });

    handleClose();
  }, []);

  /**
   * @function handleRemoveSearch
   * @returns { void }
   */
  const handleRemoveSearch = useCallback((): void => {
    actorDispatch('chatSearchDetails', {
      searchModeInChat: 'allUser',
    });
  }, []);

  const isActiveChat = useMemo(
    () => selectedUser?.personinfo_id === item.personinfo_id,
    [item, selectedUser],
  );

  const getFinalItem = () => {
    const _item = isActiveChat ? { ...item, ...selectedUser } : item;
    if (!isEmpty(item.groupuid)) {
      _item.fromgroup = true;
    }

    return _item;
  };

  const messageTime = useMemo(() => {
    const formattedTime = moment(
      item.chatdate === 'now' ? new Date() : item.chatdate,
    );
    if (!formattedTime.isValid()) return null;

    return formattedTime.startOf('second').fromNow();
  }, [item.chatdate]);

  return (
    <ChatItemView
      handleRemoveSearch={handleRemoveSearch}
      selectedUser={selectedUser}
      anchorEl={anchorEl}
      item={getFinalItem()}
      unseenMessagesCount={unseenMessagesCount}
      onChatItemSelect={chatItemSelectHandler}
      handleDeleteChat={handleDeleteChat}
      openContextMenu={openContextMenu}
      getAvatarURL={getAvatarURL}
      handleClose={handleClose}
      ref={chatTextContainerElementRef}
      isOnline={isOnline}
      searchModeInChat={searchModeInChat}
      isUserInfoDialog={isUserInfoDialog}
      senderUser={getMessageSenderUser()}
      isActiveChat={isActiveChat}
      messageTime={messageTime}
    />
  );
};

export default ChatItemController;
