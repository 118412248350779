import { type FC } from 'react';
import { Button } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { useStyles } from './permission-footer.style';

import type { PermissionFooter } from './permission-footer.type';

const PermissionFooterView: FC<PermissionFooter> = props => {
  const { onApplyPermissionClick, onRemovePermissionClick } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        className={classes.applyPermissionButton}
        onClick={onApplyPermissionClick}
      >
        {translate('permissions.applyPermission')}
      </Button>
      <Button onClick={onRemovePermissionClick} variant="outlined">
        {translate('permissions.removePermission')}
      </Button>
    </div>
  );
};

export default PermissionFooterView;
