import { useState, useMemo, type FC } from 'react';
import { useTranslate, useLocale } from 'react-admin';

import ShowRecordWithRelationView from './show-record-with-relation.view';
import { prepareRelationList } from './show-record-with-relation.helper';
import { prepareViewFields } from './show-record-with-relation.helper';
import { actorDispatch, actorGetActionValue } from '../../type/actor-setup';
import { clone } from '../../helper/data-helper';

import type { GeneralMetaData, MetaData } from '../../helper/Types';
import type {
  ShowRecordWithRelationInterface,
  TabInterface,
} from './show-record-with-relation.type';

import { getAppSettings, setAppSettings } from '../../helper/settings-helper';
import ViewButtonDialog from './view-button-dialog';
import { DynamicData } from '../sortable-list/sortable-list.type';
import { getSubPanelSortKeySetting } from '../relation-panel/relation-panel-sort.helper';
import { RelationPanelControllerInterface } from '../relation-panel';

const ShowRecordWithRelation: FC<ShowRecordWithRelationInterface> = props => {
  const { isReport, match, record, resource, refreshData, loading } = props;
  const sortSetting =
    getAppSettings<string[]>(getSubPanelSortKeySetting(resource), true).value ?? [];
  const locale = useLocale();

  const [relationSortInActor, setRelationSortInActor] =
    useState<string[]>(sortSetting);

  const translate = useTranslate();

  const metaData = actorGetActionValue('metaData', resource) as unknown as MetaData;

  const defaultTitle = translate('ra.page.show', {
    name: `${resource}`, // resource name
    recordId: record.id,
    record,
  });

  const processInfo = {
    processuniqueid: record?.__processuniqueid as string | null,
    positionid: record?.positionid as string | null,
    stateid: record?.stateid as string | null,
  };

  const tabList = useMemo((): TabInterface[] => {
    if (record == null || metaData == null) return [];
    return prepareViewFields(record, metaData as GeneralMetaData);
  }, [record, metaData, resource]);

  const relations = useMemo(
    () => prepareRelationList(tabList, resource, record.id as string, processInfo),
    [record, metaData, tabList, resource, relationSortInActor],
  );

  const hasRelationNote = useMemo(
    () =>
      relations.isCheckedRelations.filter(rel => rel.relationType === 'note')
        .length > 0,
    [relations.isCheckedRelations],
  );

  if (tabList.length === 0) {
    // TODO: show correct error message
    return null;
  }

  /**
   * saveChange
   * @returns {void} void
   */
  const saveChange = (sortedData: DynamicData[]) => (): void => {
    const sortKeyInConfig = getSubPanelSortKeySetting(resource);

    const filteredRelation = sortedData
      .filter(item => item.isChecked)
      .map(item => item.id);

    setAppSettings({
      key: sortKeyInConfig,
      value: filteredRelation,
      forUser: true,
      onSuccess: () => {
        actorDispatch('signal', 'reloadTopToolbarRelations');
        setRelationSortInActor(clone(filteredRelation));
      },
    });
  };

  /**
   * handleChangeSortMode
   * @returns {void} void
   */
  const handleChangeSortMode = (): void => {
    const allRelations = [
      ...relations.isCheckedRelations,
      ...relations.unCheckedRelations,
    ];

    // the following code block processes relations data to generate a sorted array 'data'.
    // it maps the relations array, generates unique IDs, and sets isChecked property based on the presence of IDs in relationSortInActor.
    // then, it sorts the 'data' array based on the isChecked status, prioritizing checked items.
    // finally, it further sorts the 'data' array based on the order defined in relationSortInActor, ensuring that checked items come first and maintaining the order of other elements.
    const data: { id: string; title: string; isChecked: boolean }[] = [];
    allRelations.forEach(rel => {
      data.push({
        id: rel.id,
        title:
          rel.relationItemInMetaData.moduleTableTitle ??
          rel.relationItemInMetaData.translatedTitle[locale],
        isChecked: relationSortInActor.includes(rel.id),
      });
    });

    actorDispatch('quickDialog', {
      listSortDialog: true,
      data: {
        data,
        resource,
        title: translate('listColumnChoice.relation'),
        ViewButtonDialog: <ViewButtonDialog saveChange={saveChange} />,
      },
    });
  };

  return (
    <ShowRecordWithRelationView
      record={record!} // record exist in this step because tablist could not have length without record
      tabList={tabList}
      resource={resource}
      isReport={isReport}
      customRefresh={refreshData}
      defaultTitle={defaultTitle}
      metaData={metaData}
      relations={relations.isCheckedRelations}
      match={match}
      hasRelationNote={hasRelationNote}
      handleChangeSortMode={handleChangeSortMode}
      loading={loading}
    />
  );
};

export default ShowRecordWithRelation;
