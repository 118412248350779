import { ForwardedRef, memo, useEffect, useRef, type FC } from 'react';

import TextWithEffectView from './ text-with-effect.view';

import type { TextWithEffectControllerInterface } from './text-with-effect.type';

const DOT = '.';

const TextWithEffectController: FC<TextWithEffectControllerInterface> = memo(
  props => {
    const { effect, style, text } = props;

    const typographyRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
      const effectChecker = setInterval(() => {
        if (!typographyRef.current) return;

        if (effect === 'pending') {
          typographyRef.current.innerText = preparePendingText(typographyRef);
        }
      }, 400);

      return () => {
        effectChecker && clearInterval(effectChecker);
      };
    }, []);

    /**
     * apply pending effect to text
     * Ex:
     *
     * preparePendingText('hi')
     * 'hi .'
     * preparePendingText('hi .')
     * 'hi ..'
     * preparePendingText('hi ..')
     * 'hi ...'
     * preparePendingText('hi ...')
     * 'hi'
     *
     * @function preparePendingText
     * @param {React.RefObject<HTMLSpanElement>} targetRef
     * @returns {string} manipulated text
     */
    const preparePendingText = (
      targetRef: React.RefObject<HTMLSpanElement>,
    ): string => {
      if (!targetRef.current) return '';

      let finalText = `${text} ${DOT}`;

      const prevText = targetRef.current.innerText;
      const [last, oneLeftToLast, twoLeftToLast] = Array.from(prevText).reverse();

      if (last === DOT && oneLeftToLast === DOT && twoLeftToLast === DOT) {
        finalText = text;
      } else if (last === DOT) {
        finalText = `${prevText}${DOT}`;
      }

      return finalText;
    };

    return (
      <TextWithEffectView
        style={style}
        ref={
          typographyRef as ForwardedRef<React.MutableRefObject<HTMLSpanElement> | null>
        }
      />
    );
  },
);

export default TextWithEffectController;
