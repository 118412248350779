import { FC, useCallback, useEffect, useState } from 'react';
import { GET_LIST } from 'react-admin';

import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import { shareListResource } from '../../dialogs-stack/todo-share-dialog/todo-share-dialog.helper';
import { getTodoTaskFolderId } from '../todo-section.helper';

import TodoAssignTaskView from './todo-assign-task.view';
import { isEmpty } from '../../../helper/data-helper';

import type { UserAssignedItemInterface } from '../../dialogs-stack/assign-task-dialog/assign-task-dialog.type';
import type { TodoAssignTaskControllerInterface } from './todo-assign-task.type';
import { SharingListInterface } from '../../dialogs-stack/todo-share-dialog/todo-share-dialog.type';

const TodoAssignTaskController: FC<TodoAssignTaskControllerInterface> = props => {
  const { todoSelectedTaskInfo } = props;

  const [selectedUser, setSelectedUser] = useState<
    SharingListInterface | undefined
  >();

  useEffect(() => {
    const todoShareList = actorGetActionValue('todoShareList');
    const findUser = todoShareList?.find(
      user => user.personinfo_id === todoSelectedTaskInfo?.agentuser,
    );

    setSelectedUser(findUser);
  }, [todoSelectedTaskInfo]);

  /**
   * @function openAssignTaskDialog
   * @returns { void } void
   */
  const openAssignTaskDialog = useCallback((): void => {
    actorDispatch('quickDialog', {
      todoAssignTask: true,
      data: { selectedUser },
    });
  }, [selectedUser]);

  return (
    <TodoAssignTaskView
      openAssignTaskDialog={openAssignTaskDialog}
      selectedUser={selectedUser}
    />
  );
};

export default TodoAssignTaskController;
