import { getTranslatedName } from '../../helper/meta-helper';
import { hasReportEditable } from '../../helper/MetaHelper';
import { GeneralMetaData } from '../../helper/Types';
import { actorGetActionValue } from '../../type/actor-setup';
import { isReportExecutable } from '../relation-panel/relation-panel.helper';
import {
  ChildResourceInterface,
  UpdateReport,
  GetChildList,
} from './multi-tab-list.type';

export const getChildList: GetChildList = (
  metaData,
  locale,
  resource,
  updateReport,
  reportChildren,
  childrenMetaDataList,
): ChildResourceInterface[] => {
  const childResourceList: ChildResourceInterface[] = [];

  // if parent report is executable
  if (isReportExecutable(metaData)) {
    const isParentReportEditable = hasReportEditable(metaData);

    childResourceList.push({
      title: getTranslatedName(metaData, locale) ?? '',
      resource,
      metaData,
      quickEditButton: isParentReportEditable,
      quickEditRowCallback: isParentReportEditable ? updateReport : null,
    });
  }

  reportChildren.forEach(({ childResource, title }, index) => {
    const childMetaData =
      (actorGetActionValue(
        'metaData',
        childResource as string,
      ) as unknown as GeneralMetaData) ?? childrenMetaDataList?.[childResource];

    const isReportEditable = hasReportEditable(childMetaData);

    childResourceList.push({
      title:
        (title as string) ||
        (getTranslatedName(childMetaData, locale) ?? '') ||
        String(index),
      resource: childResource as string,
      metaData: childMetaData as GeneralMetaData,
      quickEditButton: isReportEditable,
      quickEditRowCallback: isReportEditable ? updateReport : null,
    });
  });

  return childResourceList;
};

export const updateReport: UpdateReport = ({ resource, params, callback }) => {
  // this feature used to exist and work but was removed
  // so this function is no longer used, but codes below are kept for future reference
  //
  // try {
  //   const data = dataProvider(UPDATE_REPORT, resource, params);
  //   put(refreshView());
  //   if (typeof callback === 'function') {
  //     callback(null, data);
  //   }
  // } catch (error) {
  //   showNotification(error, 'error');
  //   if (typeof callback === 'function') {
  //     callback(error, null);
  //   }
  // }
};
