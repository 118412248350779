import { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import { NavItemInterface } from './nav-item.type';
import NavItemView from './nav-item.view';
import { useHistory, useLocation } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import { actorDispatch } from '../../../../type/actor-setup';

import type { RoutesConfigInterface } from '../navigation-container.type';
import { navCollapsingChangeHandler } from '../navigation-container.helper';

const NavItemController: FC<NavItemInterface> = memo(props => {
  const {
    item,
    level,
    baseUrl,
    setParentOpen,
    parentId,
    canDragDrop,
    parentComponentName,
    isPreSelected,
    parents,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [isActive, setIsActive] = useState<boolean>(false);

  const [isContextMenuOpen, setContextMenuIsOpen] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const dragDropRef = useRef<HTMLDivElement>(null);

  /**
   * @function handleSetActive
   * @param { boolean } isActive
   * @returns { void }
   */
  const handleSetActive = (isActive: boolean): void => {
    setIsActive(isActive);
  };

  /**
   * @function handleContextMenu
   * @param { React.MouseEvent } event
   * @returns { void }
   */
  const handleContextMenu = (event: React.MouseEvent): void => {
    if (item?.hasContextMenu) {
      event.preventDefault();
      setContextMenuIsOpen(
        isContextMenuOpen === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    }
  };

  /**
   * @function handleCloseContextMenu
   * @returns { void }
   */
  const handleCloseContextMenu = (): void => {
    setContextMenuIsOpen(null);
  };

  /**
   * add badge number to title of web app
   */
  useEffect(() => {
    if (isActive) {
      document.title = item?.count ? `(${item?.count}) iMaster` : 'iMaster';
      if (location.search.includes('id')) {
        history.push({
          hash: `#open${parentId ?? item?.id}`,
          pathname: location.pathname,
          search: location.search,
        });
      }
      setParentOpen?.();
    }
  }, [isActive]);

  /**
   * @function handleDroppedItem
   * @param {Record<string,unknown>} draggedItem
   * @returns { void }
   */
  const handleDroppedItem = (draggedItem: Record<string, unknown>): void => {
    actorDispatch(
      'dragDropAction',
      {
        draggedItem,
        droppedItem: item,
        parentComponentName,
      },
      {
        disableDebounce: true,
      },
    );
  };

  /**
   * @function isActiveItem
   * @param {string} _match
   * @param {string} location
   * @returns { boolean } a boolean
   */
  const isActiveItem = useCallback(
    (_match: string, location: string): boolean => {
      if (item.exact && !location?.search) {
        handleSetActive(true);
        return true;
      }
      if (!location || !location.search) {
        handleSetActive(false);
        return false;
      }
      const search = location.search;
      if (search) {
        const isActiveBasedOnURL = `${baseUrl}${search}`.includes(
          item.url as string,
        );
        handleSetActive(isActiveBasedOnURL);
        return isActiveBasedOnURL;
      }
      const isActive = isPreSelected ?? false;
      handleSetActive(isActive);

      return isActive;
    },
    [isActive, isPreSelected],
  );

  /**
   * @function onClickMenu
   * @param {React.MouseEvent<HTMLElement>} event
   * @returns { boolean }
   */
  const onClickMenu = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
  };

  /**
   * @function handleOnItemClick
   * @param {RoutesConfigInterface} selectedItem
   * @returns { () => void } a function
   */
  const handleOnItemClick = (selectedItem: RoutesConfigInterface) => (): void => {
    navCollapsingChangeHandler(
      true,
      { self: selectedItem, parents: parents ?? [] },
      parentComponentName,
    );

    if (typeof item?.onClick === 'function') {
      item.onClick();
    }
  };

  //@ts-ignore
  const [, connectDragSource] = useDrag(() => ({
    type: 'navItem',
    item,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  const [{ isOver }, connectDropSource] = useDrop({
    accept: ['navCollapse', 'navItem'],
    drop: draggedItemProperty =>
      handleDroppedItem(draggedItemProperty as unknown as Record<string, unknown>),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  connectDragSource(connectDropSource(dragDropRef));

  /**
   * @function setSelectedOAFolderInActor
   * @param {RoutesConfigInterface} item
   * @returns { void }
   */
  const setSelectedOAFolderInActor = (item: RoutesConfigInterface) => (): void => {
    actorDispatch('selectedOaFolder', item);
  };

  return (
    <NavItemView
      setSelectedOAFolderInActor={setSelectedOAFolderInActor}
      item={item}
      level={level}
      isContextMenuOpen={isContextMenuOpen}
      onContextMenu={handleContextMenu}
      handleCloseContextMenu={handleCloseContextMenu}
      contextMenuNode={item?.contextMenuNode}
      isActive={isActive ?? isPreSelected ?? false}
      parentComponentName={parentComponentName}
      dragDropRef={dragDropRef}
      canDragDrop={canDragDrop}
      isOver={isOver}
      isActiveItem={isActiveItem}
      onClickMenu={onClickMenu}
      onItemClick={handleOnItemClick}
    />
  );
});

export default NavItemController;
