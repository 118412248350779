import { FC, useState, memo } from 'react';

import { BooleanInputProps } from './boolean-input.type';
import BooleanInputView from './boolean-input.view';
import BooleanInputWMSView from './boolean-input-wms.view';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
} from '../../form/form.type';

const BooleanInputController: FC<BooleanInputProps> = memo(props => {
  const {
    formActionsHandler,
    visibleClass,
    className,
    id,
    disabled,
    value,
    getRef,
    inputMessage,
    resource,
    label,
    hint,
    source,
    field,
    options,
    inputInPuzzleForm,
  } = props;

  const [state, setState] = useState({ isFocused: false, isBlurred: false });

  const { name } = field;

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    setState({ isFocused: false, isBlurred: true });
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    setState({ isFocused: true, isBlurred: false });
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as ChangeFormValueParams);
  };

  /**
   * Handle Change event
   * @function handleChange
   * @param {ChangeEvent} event
   * @returns void
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: checked,
      isHidden: visibleClass.includes('displayNone'),
    } as ChangeFormValueParams);
  };

  const ViewComponent = inputInPuzzleForm ? BooleanInputWMSView : BooleanInputView;

  return (
    <ViewComponent
      visibleClass={visibleClass}
      className={className}
      id={id}
      disabled={disabled}
      isFocused={state.isFocused}
      isBlurred={state.isBlurred}
      inputMessage={inputMessage}
      resource={resource}
      label={label}
      hint={hint}
      source={source}
      field={field}
      value={value}
      getRef={getRef}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleChange={handleChange}
      options={options}
    />
  );
});

export default BooleanInputController;
