import React from 'react';
import { useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

import TodoNewLabelController from './todo-new-label/todo-new-label.controller';
import CustomChip from './custom-chip.view';
import CustomMenuListView from './custom-menu-list.view';
import { useStyles } from './todo-label.style';

import type { ReactElement } from 'react';
import type { TodoLabelViewInterface } from './todo-label.type';

const TodoLabelView = (props: TodoLabelViewInterface): ReactElement => {
  const {
    getLabelListAndOpenMenuLabel,
    handleDeleteLabel,
    handleCloseMenuLabel,
    onItemSelect,
    handleToggleNewLabelDialog,
    getUserLabels,
    checkedLabels,
    isNewLabelDialogOpen,
    anchorEl,
    labelList,
  } = props;

  const classes = useStyles({ color: '' });
  const translate = useTranslate();

  const ITEM_HEIGHT = 48;

  return (
    <>
      <div className={classes.itemContainer} onClick={getLabelListAndOpenMenuLabel}>
        <div className={classes.body}>
          <LocalOfferOutlinedIcon fontSize="small" />
          <div className={classes.titleAndValue}>
            <Typography variant="caption" className={classes.todoItemText}>
              {translate('todo.label')}
            </Typography>
          </div>
        </div>

        <div>
          <div className={classes.chipContainer}>
            {Array.isArray(checkedLabels) &&
              checkedLabels?.map(chipItem => (
                <CustomChip
                  handleDeleteLabel={handleDeleteLabel}
                  chipItem={chipItem}
                />
              ))}
          </div>
        </div>
      </div>
      <Menu
        className={classes.menuContainer}
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '35ch',
            marginTop: 30,
          },
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuLabel}
      >
        {labelList.map(item => (
          <CustomMenuListView
            onItemSelect={onItemSelect}
            checkedLabels={checkedLabels}
            data={item}
          />
        ))}

        <Button
          onClick={handleToggleNewLabelDialog}
          className={classes.btnNewLabel}
          size="small"
          variant="text"
          color="secondary"
        >
          {translate('mail.newLabel')}
        </Button>
        {isNewLabelDialogOpen && (
          <TodoNewLabelController
            getUserLabels={getUserLabels}
            handleToggleNewLabelDialog={handleToggleNewLabelDialog}
            isNewLabelDialogOpen={isNewLabelDialogOpen}
          />
        )}
      </Menu>
    </>
  );
};

export default TodoLabelView;
