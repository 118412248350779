import { type FC } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import { Column, Scrolling, LoadPanel } from 'devextreme-react/data-grid';
import { DataGrid } from 'devextreme-react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import { PushpinFilled } from '@ant-design/icons';
import { Button, Typography, IconButton } from '@material-ui/core';

import { useStyles } from './list-dashboard.style';
import {
  type DevExpressCellDataDashboard,
  type ListDashboardController,
} from './list-dashboard.type';

const ListDashboardView: FC<ListDashboardController> = props => {
  const { deleteDashboard, rowsDashboard, resource } = props;

  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.addIConContainer}>
        <IconButton
          href={`#/${resource}/create`}
          color="primary"
          target="_parent"
          className={classes.addIconStyle}
        >
          <AddIcon />
        </IconButton>
      </div>

      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <DataGrid
        allowColumnResizing={true}
        noDataText={translate('imageDialog.dataNotFound')}
        dataSource={rowsDashboard}
        rowAlternationEnabled={true}
        rtlEnabled={locale !== 'en'}
        paging={{ enabled: false }}
      >
        <Column
          width={'130px'}
          cellRender={({ data }) => (
            <div className={classes.actionContainer}>
              <IconButton
                href={`#/${resource}/${data.dashinfo_id}/show`}
                color="primary"
                target="_parent"
                className={classes.iconInGrid}
              >
                <VisibilityIcon />
              </IconButton>

              <IconButton
                href={`#/dashboard/${data.uniqueid}`}
                color="primary"
                target="_parent"
                className={classes.iconInGrid}
              >
                <ShowChartIcon />
              </IconButton>

              <IconButton color="primary" className={classes.iconInGrid}>
                <DeleteIcon
                  className={classes.iconInGrid}
                  fontSize="small"
                  onClick={deleteDashboard?.(data.dashinfo_id)}
                />
              </IconButton>
            </div>
          )}
        />

        <Column
          key={`column_dashboard`}
          alignment={'right'}
          dataField={`dashname`}
          caption={translate('dashboard.nameDashboard')}
          width={'80%'}
          cellRender={(cell: DevExpressCellDataDashboard) => {
            return (
              <div className={classes.containerCell}>
                {cell.value as string}
                {cell.data.isfav && (
                  <StarRateRoundedIcon
                    className={classes.iconInGrid}
                    fontSize="small"
                  />
                )}
                {cell.data.ispin && <PushpinFilled className={classes.pinIcon} />}
              </div>
            );
          }}
        />
      </DataGrid>
    </div>
  );
};

export default ListDashboardView;
