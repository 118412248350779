import { ReactElement, useEffect, useState } from 'react';

import { GadgetInputView } from './gadget-input.view';
import { actorDispatch } from '../../../type/actor-setup';
import { parseJSON } from '../../../core/configProvider';
import { ChangeFormValueParams, FormActions } from '../../form';
import { prepareDynamicChartPropsForGadgets } from '../../dynamic-chart/dynamic-chart.helper';
import { CUSTOM_GET } from '../../../core/data-Provider.helper';

import type {
  GadgetInformation,
  GadgetInputControllerProps,
} from './gadget-input.type';
import { getGadgetDefaultSort } from '../../dashboard/dashboard-card-form-dialog/dashboard-card-form-dialog.helper';
const GadgetInputController = (props: GadgetInputControllerProps): ReactElement => {
  const {
    field,
    label,
    hint,
    className,
    disabled,
    value, // its a json string
    inputMessage,
    formActionsHandler,
  } = props;

  const [reportData, setReportData] = useState<Record<string, unknown>[] | null>(
    null,
  );
  const [parsedValue, setParsedValue] = useState<null | GadgetInformation>(
    parseJSON<GadgetInformation>(value),
  );

  useEffect(() => {
    if (!parsedValue) return;

    if (parsedValue?.reportAddress) {
      actorDispatch(
        'crudAction',
        {
          type: CUSTOM_GET,
          entity: 'gadget',
          resource: `v2/report/${parsedValue.reportAddress}?skip=0&takeCount=999999&sort=id&sortType=DESC`,
          onSuccess: reportData => {
            if (reportData?.data?.length) {
              setReportData(reportData.data);
            } else {
              console.error('report data has not length'); // // show notification later if needed
              setReportData([]);
            }
          },
          onFailure: reportDataError => {
            console.error('reportDataError: ', reportDataError); // // show notification later if needed
            setReportData([]);
          },
        },
        { disableDebounce: true },
      );
    } else {
      setReportData([]);
    }
  }, [parsedValue?.reportAddress]);

  useEffect(() => {
    setParsedValue(parseJSON<GadgetInformation>(value));
  }, [value]);

  /**
   * Handle Change event
   * @function handleChange
   * @param {ChangeEvent} event
   * @returns }
   */
  const handleChange = (newData: GadgetInformation | null): void => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: field.name,
      value: JSON.stringify(newData),
    } as ChangeFormValueParams);
  };

  /**
   * open design dialog
   * @function onBrushIconClick
   * @returns {void}
   */
  const onBrushIconClick = (): void => {
    actorDispatch('currentGadgetInformation', parsedValue, {
      replaceAll: true,
    });

    actorDispatch(
      'quickDialog',
      {
        gadgetDesignDialogIsOpen: true,
        data: { field, handleChange },
      },
      {
        replaceAll: true,
      },
    );
  };

  const gadgetDefaultSort = getGadgetDefaultSort(parsedValue?.sortObject);
  const dynamicChartPreparedProps =
    reportData && parsedValue
      ? prepareDynamicChartPropsForGadgets({
          gadgetValues: parsedValue,
          reportData,
          ...(!!gadgetDefaultSort?.isSortEnabled && {
            sortField: gadgetDefaultSort.sortField,
            sortOrder: gadgetDefaultSort.sortOrder,
          }),
          displayNumber: parsedValue.displayNumber,
        })
      : null;

  return (
    <GadgetInputView
      field={field}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      className={className}
      disabled={disabled}
      onBrushIconClick={onBrushIconClick}
      parsedValue={parsedValue}
      dynamicChartPreparedProps={dynamicChartPreparedProps}
    />
  );
};

export default GadgetInputController;
