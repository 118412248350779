import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  accordionContainer: {
    transition: 'all 0.3s linear !important',
    margin: '.7rem 0',
    boxShadow: 'none !important',
    borderBottom: '2px solid rgba(100,121,143,0.122)',

    '& .MuiAccordionSummary-expandIcon': {
      display: 'none',
    },
  },

  accordionDetailContainer: {
    transition: 'all 0.3s linear !important',
    border: 'none',
  },

  summaryContainer: {
    borderRadius: 4,
    cursor: 'pointer',
    margin: '1rem 0',
  },
  iconImportant: { color: '#fff', stroke: '#000', fontSize: '20px' },
  iconImportantChecked: { color: '#D4AF37', fontSize: '20px' },
  detailContainer: { overflow: 'hidden' },
  showMoreButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  },
}));
