import { type FC, useEffect, useMemo, useState } from 'react';
import lodashGet from 'lodash/get';
import Button from '@material-ui/core/Button';
import { useUpdate, useTranslate, useLocale } from 'react-admin';
import ProcessIcon from '@material-ui/icons/CallMade';

import { separateRecordAndRelationRecord } from '../helper/data-helper';
import { handleServerSideValidationErrors } from '../helper/validation-helper';
import { MetaData, MetaDataBase } from '../helper/Types';
import { actorDispatch, actorOnDispatch } from '../type/actor-setup';
import { prepareOverrideParams } from './form/form.helper';
import {
  deviceCallbackFunction,
  removeOnDispatches,
  showNotification,
} from '../helper/general-function-helper';
import { descriptionFields } from './dialogs-stack/user-description-dialog/user-description-dialog.helper';
import { FormController } from './form';

interface ChangeProcessButtonInterface {
  locale: string;
  line: {
    id: number;
    title: string;
    color?: string;
    isNecessaryDescription?: boolean;
  };
  record: { __processuniqueid: number };
  resource: string;
  metaData: MetaData;
}

const ChangeProcessButton: FC<ChangeProcessButtonInterface> = props => {
  const { line, record, resource, metaData } = props;
  const translate = useTranslate();
  const locale = useLocale();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  //TODO: We have to use `record` from actor and other necessary form data, we don't need to any re-calculation
  const computedOverrideParams = useMemo(prepareOverrideParams, []);
  const { recordWithoutRelationData } = separateRecordAndRelationRecord(
    record,
    metaData,
    computedOverrideParams,
  );

  const [updateProcess] = useUpdate();

  /**
   * prepare data and change process sep
   * @function updateProcessAction
   * @params {Record<string, unknown>} formData
   * @returns {void}
   */
  const updateProcessAction = (formData: Record<string, unknown> = {}): void => {
    updateProcess(
      resource,
      lodashGet(recordWithoutRelationData, 'id'),
      {
        __processuniqueid: record.__processuniqueid,
        __lineid: line.id,
        ...formData,
      },
      recordWithoutRelationData, //previousData
      {
        undoable: false,
        onSuccess: (response: {
          data: Record<string, unknown>;
          userMessage: string;
        }) => {
          deviceCallbackFunction('successUpdateProcessLine');
          showNotification(
            response?.userMessage ?? 'ra.notification.updated',
            'info',
          );

          actorDispatch('closeCurrentDialog', true, {
            callerScopeName: 'changeProcessButton => updateProcessAction',
          });

          actorDispatch('loading', false, { path: 'processChangeLineButtons' });
          actorDispatch('refreshView', 'all', { disableDebounce: true });
        },
        onFailure: error => {
          actorDispatch('loading', false, { path: 'processChangeLineButtons' });
          actorDispatch('refreshView', 'all', { disableDebounce: true });
          if (typeof error === 'string') {
            showNotification(error, 'error');
          } else {
            const { data, requestId } = error;
            try {
              handleServerSideValidationErrors(
                metaData as MetaDataBase,
                [],
                { apiErrors: data, requestId },
                formData,
                showNotification,
                translate,
                locale,
              );
            } catch (error) {
              console.log('ChangeProcessButton.tsx:60 >> error in catch', {
                error,
              });
            }
          }
        },
      },
    );
  };

  /**
   * open confirm's dialog for the processes that need description
   * @function openConfirmDialog
   * @returns {void}
   */
  const openConfirmDialog = (): void => {
    actorDispatch('quickDialog', {
      userDescriptionDialogIsOpen: true,
      data: {
        onSubmit: updateProcessAction,
        onClose: () => {
          actorDispatch('loading', false, { path: 'processChangeLineButtons' });
        },
        fields: descriptionFields,
        dialogTitle: translate('form.pleaseFillTheDescription'),
      },
    });
  };

  const handleClick = () => {
    actorDispatch('loading', true, { path: 'processChangeLineButtons' });

    if (line.isNecessaryDescription) {
      openConfirmDialog();
      return;
    }

    updateProcessAction();
  };

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    const listenerId = actorOnDispatch('loading', loadingRecord => {
      setIsLoading(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          loadingRecord[resource] ||
          false,
      );
    });

    onDispatches.push({
      actionName: 'loading',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, []);

  return (
    <>
      <Button
        color="primary"
        onClick={handleClick}
        disabled={isLoading}
        data-test-process-name={line.title}
        style={{
          marginTop: 6,
          marginBottom: 6,
          marginLeft: 4,
          marginRight: 4,
          background: 'rgba(38,57,72,0.04)',
        }}
      >
        <ProcessIcon htmlColor={line?.color} />
        {lodashGet(line, ['translatedTitle', locale], line.title)}
      </Button>
    </>
  );
};

export default ChangeProcessButton;
