import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    minWidth: 400,
    minHeight: 500,
  },
  filesArea: {
    height: 450,
    padding: 20,
    margin: '2rem 0',
    border: 'none !important',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 3,
    },
  },
  sendAsAlbumContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px dotted ${theme.palette.todoDarkGray}`,
    borderTop: `1px dotted ${theme.palette.todoDarkGray}`,
    marginBottom: 5,
  },
}));
