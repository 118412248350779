import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflow: 'hidden',

    '@media (max-width: 1024px)': {
      overflow: 'auto',
      flexDirection: 'column',
    },
  },

  verticalContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    '@media (max-width: 1024px)': {
      overflow: 'unset',
    },
  },

  moreWidth: {
    flex: 1.65,
  },

  permissionAssignTypeContainer: {
    display: 'flex',
    flex: 1,
  },

  assignedContainer: {
    display: 'block',
    maxHeight: '78vh',
    overflow: 'auto',
  },

  permissionTypeContainer: {
    display: 'flex',
    flex: 2,
  },

  permissionBranchesContainer: {
    display: 'flex',
    flex: 2,
  },

  menuContainer: {
    display: 'flex',
    flex: 1,
  },

  footerContainer: {
    display: 'flex',
    width: '100%',
    height: '50px',
  },
}));
