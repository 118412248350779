import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  addUserButton: {
    color: 'green',
  },
  containerAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabParent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },
}));
