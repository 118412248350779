import { ReactElement, useState } from 'react';

import DynamicList from '../../../../container/DynamicList';
import DevExGrid from '../../../DevExGrid';
import { CustomForm, FormController } from '../../../form';
import WMSForm from '../wms-form';
import { useStyles } from './wms-table.style';
import { WMSTableViewProps } from './wms-table.type';

const PER_PAGE = 50;

const WMSTableView = (props: WMSTableViewProps): ReactElement | null => {
  const {
    tableMetaData,
    resource,
    urlInfo,
    formTabIndex,
    onSubmit,
    activeTabIndex,
    prevTabIndex,
  } = props;

  const [gridFooterRef, setGridFooterRef] = useState(null);

  const classes = useStyles();

  const getFooterRef = footerRef => {
    // sometimes footer ref is undefined
    if (footerRef) {
      setGridFooterRef(footerRef);
    }
  };

  return (
    <div className={classes.container}>
      <FormController formName="wms" key={activeTabIndex}>
        <CustomForm>
          <WMSForm
            formTabIndex={formTabIndex}
            onSubmit={onSubmit}
            activeTabIndex={activeTabIndex}
            prevTabIndex={prevTabIndex}
          />
        </CustomForm>
      </FormController>

      <DynamicList
        classes={{
          container: classes.dynamicList,
        }}
        resource={resource}
        hasList={true}
        hasCreate={false}
        hasEdit={false}
        hasShow={false}
        enableSelection={true}
        metaData={tableMetaData}
        location={urlInfo.location}
        basePath={`/${resource}`} // FIXME: Is its value correct?
        isFilterEnable={false}
        isGroupingEnable={false}
        listActionComponent={undefined}
        listFilterComponent={null}
        perPage={PER_PAGE}
        viewComponent={<DevExGrid getFooterRef={getFooterRef} resource={resource} />}
        isWMS={true}
        ignoreWebsettingFilters={true}
      />
    </div>
  );
};

export default WMSTableView;
