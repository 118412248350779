import { Badge, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import { FC } from 'react';
import { useTranslate } from 'react-admin';
import { animation, Menu } from 'react-contexify';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getNotificationItemPatternInfo,
  getNotificationPatternInfo,
} from '../../helper/PatternMetaHelper';
import { NotificationItemPatternType } from '../../helper/Types';
import LoadingBox from '../loading-box';
import WebNotification from './web-notification/web-notification.view';
import { useStyles } from './notification-panel.style';
import { NotificationPanelViewInterface } from './notification-panel.type';
import { NotificationItem } from './notification-item';

const NotificationPanelView: FC<NotificationPanelViewInterface> = props => {
  const {
    handleNotificationIconClick,
    notificationIconTriggerRef,
    unSeenCounter,
    notificationList,
    fetchMoreData,
    hasMore,
    webNotificationItem,
    showAllNotification,
    toggleShowAllNotification,
    seenAllMessages,
    handleClickNotificationItem,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const { notificationItemPatternName } = getNotificationPatternInfo('notification');
  const notificationItemPatternInfo: NotificationItemPatternType =
    getNotificationItemPatternInfo(notificationItemPatternName);

  return (
    <>
      <Tooltip title={translate('ra.notification.notifications')}>
        <div>
          <IconButton
            color="inherit"
            className={classes.IconButton}
            onClick={handleNotificationIconClick}
            ref={notificationIconTriggerRef}
            data-test-notification-icon
          >
            <Badge
              badgeContent={unSeenCounter}
              max={1000}
              color="error"
              showZero={false}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              data-test-badge-notification={`${unSeenCounter}`}
            >
              <Icon>notifications_none</Icon>
            </Badge>
          </IconButton>
        </div>
      </Tooltip>

      <Menu
        animation={animation.fade}
        id={'notificationPanel'}
        className={classes.menuContainer}
      >
        <div className={classes.itemContainer}>
          <div className={classes.linkContainer}>
            <a
              color="secondary"
              onClick={toggleShowAllNotification}
              // variant="text"
              className={classes.showAllMessagesButton}
            >
              {showAllNotification
                ? translate('notification.showUnreadMessage')
                : translate('notification.showAllMessages')}
            </a>
            <a
              color="secondary"
              onClick={seenAllMessages}
              // variant="text"
              className={classes.showAllMessagesButton}
            >
              {translate('notification.seenAllMessages')}
            </a>
          </div>
          <InfiniteScroll
            dataLength={
              notificationList && notificationList.length
                ? notificationList.length
                : 10
            }
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<LoadingBox size={25} />}
            endMessage={
              <div className={classes.notificationNotFound}>
                <Typography variant="body2" color="textSecondary">
                  {translate('ra.notification.thereAreNoNewNotifications')}
                </Typography>
              </div>
            }
            className={classes.infiniteScroll}
            height={500}
          >
            {notificationList?.length &&
              notificationList.map(record => {
                return (
                  <NotificationItem
                    itemInfo={record}
                    pattern={notificationItemPatternInfo}
                    handleClickNotificationItem={handleClickNotificationItem}
                  />
                );
              })}
          </InfiniteScroll>
        </div>
      </Menu>

      <WebNotification webNotificationItem={webNotificationItem} />
    </>
  );
};

export default NotificationPanelView;
