import { FC, memo } from 'react';
import { Checkbox, Icon, IconButton } from '@material-ui/core';

import { useStyles } from './todo-step-items.style';

import type { TodoStepItemsViewInterface } from './todo-step-items.type';

const TodoStepItemsView: FC<TodoStepItemsViewInterface> = memo(props => {
  const {
    handleChangeDoneStatus,
    handleChangeTitle,
    handleRemoveStep,
    stepTitleInputRef,
    dragDropRef,
    isLoading,
    stepItem,
    isOver,
  } = props;

  const classes = useStyles({ isOver });

  return (
    <div className={classes.stepList} ref={dragDropRef}>
      <Checkbox
        value={stepItem.IsDone}
        checked={stepItem.IsDone}
        onChange={handleChangeDoneStatus}
        color="secondary"
        icon={<Icon className={classes.stepDoneIcon}>radio_button_unchecked</Icon>}
        checkedIcon={<Icon className={classes.stepDoneIcon}>check_circle</Icon>}
        disabled={isLoading}
      />
      <input
        className={classes.taskTitle}
        defaultValue={stepItem.StepTitle}
        onKeyUp={handleChangeTitle}
        disabled={isLoading}
        ref={stepTitleInputRef}
      />
      <IconButton
        color="primary"
        className={classes.iconButton}
        onClick={handleRemoveStep}
        disabled={isLoading}
      >
        <Icon fontSize="small" className={classes.icon}>
          close
        </Icon>
      </IconButton>
    </div>
  );
});
export default TodoStepItemsView;
