import { useCallback, useEffect, useRef, useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import { useTranslate } from 'react-admin';

import {
  clone,
  generateRandomString,
  isEmpty,
  isEmptyObject,
} from '../../../helper/data-helper';
import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';
import { showNotification } from '../../../helper/general-function-helper';
import {
  CONFIG_LAST_CHOSEN_COLUMNS_BEFORE_SAVING_A_CUSTOM_FILTER,
  CONFIG_LIST_COLUMN_CHOICE,
  CONFIG_SAVED_FILTER,
} from '../../../core/configProvider';
import FilterSaveButtonView from './filter-save-button.view';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  FilterFormFieldInterface,
} from '../../../type/actor-setup';

import type { FilterSaveButtonPropsInterface } from './filter-save-button.type';
import type { FilterSaveDialogDataItem } from '../../dialogs-stack';
import type { SavedFilterItemInterface } from '../saved-filters-button';
import { generateFilterKeyForAppSetting } from '../filter-form.helper';

const FilterSaveButtonController: FC<FilterSaveButtonPropsInterface> = props => {
  const { resource } = props;

  const translate = useTranslate();

  const [disabled, setDisabled] = useState(true);

  const filterNameRef = useRef('');

  useEffect(() => {
    const onDispatchId = actorOnDispatch(
      'filterDataIsChanged',
      newFilters => {
        const _newFilters = newFilters?.[resource];
        // fixme: should check if there is at least one filter with value
        let disabled = true;
        if (_newFilters) {
          for (const filter of _newFilters) {
            if (Array.isArray(filter) && !isEmpty(filter[2])) {
              disabled = false;
              break;
            }
          }
          setDisabled(disabled);
        }
      },
      {
        callerScopeName: 'FilterSaveButtonController => useEffect',
      },
    );

    return () => {
      actorRemoveAction({
        actionName: 'filterDataIsChanged',
        listenerId: onDispatchId,
      });
    };
  }, []);

  /**
   * @function handleSave
   * @returns { void } void
   */
  const handleSave = useCallback((): void => {
    const filterFormFields = actorGetActionValue('filterFormFields')?.[resource];
    if (isEmptyObject(filterFormFields)) {
      console.warn(
        'FilterSaveButtonController => handleSave: `filterFormFields` is Empty',
      );
      return;
    }

    const gridSavedColumns =
      getAppSettings<number[]>(CONFIG_LIST_COLUMN_CHOICE + '_' + resource, true)
        .value ?? [];

    const savedFiltersFromTheAppSettings =
      getAppSettings<Record<string | number, SavedFilterItemInterface>>(
        generateFilterKeyForAppSetting(resource, CONFIG_SAVED_FILTER),
        true,
      ).value ?? {};

    const settingKey =
      CONFIG_LAST_CHOSEN_COLUMNS_BEFORE_SAVING_A_CUSTOM_FILTER + '_' + resource;
    const lastChosenColumnsBeforeSavingACustomFilter = getAppSettings<number[]>(
      settingKey,
      true,
    ).value;

    if (lastChosenColumnsBeforeSavingACustomFilter == null) {
      setAppSettings({
        key: settingKey,
        value: gridSavedColumns,
        forUser: true,
        onFailure: (): void => {
          showNotification(translate('ra.updatingSettingsFailed'), 'error');
        },
      });
    }

    let newFilterSettingId = generateRandomString();
    if (newFilterSettingId in savedFiltersFromTheAppSettings) {
      newFilterSettingId = generateRandomString();
    }

    const formattedFilterRecord: Record<string, FilterFormFieldInterface> = {};
    for (const fieldKey of Object.keys(filterFormFields)) {
      formattedFilterRecord[fieldKey] = {
        value: filterFormFields[fieldKey].value,
        fieldData: {
          name: filterFormFields[fieldKey].fieldData.name,
          id: filterFormFields[fieldKey].fieldData.id,
        },
      };
    }
    const newFilterData = {
      id: newFilterSettingId,
      name: filterNameRef.current,
      data: clone(formattedFilterRecord),
      gridColumns: gridSavedColumns,
      tabIndex: actorGetActionValue('activeTab')?.[resource],
    };

    const filterDataToSave = {
      [newFilterData.id]: newFilterData,
      ...savedFiltersFromTheAppSettings,
    };

    setAppSettings({
      key: generateFilterKeyForAppSetting(resource, CONFIG_SAVED_FILTER),
      value: filterDataToSave,
      forUser: true,
      onSuccess: (): void => {
        setDisabled(true);
        filterNameRef.current = '';
        actorDispatch('closeCurrentDialog', true, {
          callerScopeName: 'FilterSaveButtonController => handleSave',
        });

        actorDispatch(
          'savedFilters',
          { [resource]: filterDataToSave },
          {
            replaceAll: true,
            callerScopeName:
              'FilterSaveButtonController => handleSave (onSuccess In setAppSettings)',
          },
        );

        requestAnimationFrame(() => {
          showNotification(translate('ra.filterWasAddedSuccessfully'), 'info');
        });
      },
      onFailure: (): void => {
        showNotification(translate('ra.updatingSettingsFailed'), 'error');
      },
    });
  }, [resource]);

  /**
   * @function filterNameInputChangeHandler
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void } void
   */
  const filterNameInputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      filterNameRef.current = event.target.value;
    },
    [],
  );

  /**
   * @function openDialogHandler
   * @returns { void } void
   */
  const openDialogHandler = useCallback((): void => {
    actorDispatch(
      'quickDialog',
      {
        filterSaveDialogIsOpen: true,
        data: {
          resource,
          filterNameInputChangeHandler,
          onSave: handleSave,
        } as FilterSaveDialogDataItem,
      },
      {
        callerScopeName: 'FilterSaveButtonController => openDialogHandler',
      },
    );
  }, [resource]);

  return (
    <FilterSaveButtonView
      disabled={disabled}
      resource={resource}
      translate={translate}
      openDialogHandler={openDialogHandler}
    />
  );
};

export default FilterSaveButtonController;
