import { useTranslate } from 'react-admin';
import { Box, Button } from '@material-ui/core';

import { AppDialog } from '../../../app-dialog';
import { NewLabelForm } from '../../../mail-section/mail-list/mail-content-header/checked-mail-action/label-action/new-label-form';
import { useStyles } from '../todo-label.style';

import type { ReactElement } from 'react';
import type { TodoNewLabelViewInterface } from '../todo-label.type';

const TodoNewLabelView = (props: TodoNewLabelViewInterface): ReactElement => {
  const { onCreateNewLabel, handleToggleNewLabelDialog, isNewLabelDialogOpen } =
    props;

  const translate = useTranslate();
  const classes = useStyles({ color: '' });

  return (
    <AppDialog
      open={isNewLabelDialogOpen}
      handleClose={handleToggleNewLabelDialog}
      dialogTitle={translate('mail.newLabel')}
      dialogContent={<NewLabelForm />}
      contentClassName={classes.newLabelFormContainer}
      dialogActions={
        <Box className={classes.root}>
          <Button
            onClick={handleToggleNewLabelDialog}
            variant="text"
            color="primary"
            data-test="todo-form-decline-button"
          >
            {translate('general.decline')}
          </Button>
          <Button
            className={classes.btnRoot}
            onClick={onCreateNewLabel}
            variant="contained"
            color="secondary"
            data-test="todo-form-apply-button"
          >
            {translate('general.apply')}
          </Button>
        </Box>
      }
    />
  );
};

export default TodoNewLabelView;
