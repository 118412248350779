import { type FC, useEffect, useMemo, useRef } from 'react';
import { GET_MANY_REFERENCE } from 'react-admin';

import TableRelationView from './table-relation.view';
import Pagination from '../../Pagination';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  ApiRequestResultInterface,
  FormKeyMode,
  RecordKeyMode,
  RequestParametersInterface,
} from '../../../type/actor-setup';
import { PrintReport } from '../../print-report';
import { apiRequestResultHandler } from '../../../helper/crud-api.helper';
import { getAppSettings } from '../../../helper/settings-helper';
import { CONFIG_LIST_PER_PAGE } from '../../../core/configProvider';
import { getHiddenGridColumns } from '../../../helper/meta-helper';

import {
  getFieldsForDisplay,
  requestListData,
  setListPage,
  setListPerPage,
  setListSort,
} from '../../list/list.helper';

import type {
  ApiDataInterface,
  GridSetPageCallback,
  GridSetPerPageCallback,
  GridSetSortCallback,
} from '../../grid/grid.type';

import type { TableRelationControllerInterface } from './table-relation.type';
import type { GeneralMetaData, MetaData } from '../../../helper/Types';
import { PivotTable } from '../../pivot-table';
import FilterForm, { type FinalFiltersType } from '../../filter-form';
import { clone, isEmptyObject } from '../../../helper/data-helper';

const TableRelationController: FC<TableRelationControllerInterface> = props => {
  const { relationPanelBaseProps } = props;

  const summaryDataRef = useRef<Record<string, unknown>>({});

  const {
    relationData,
    relationDataCount,
    relationPermission,
    relationMetaData,
    settings,
    relationResource,
    childFieldName,
    parentFieldName,
    currentUrl,
    parentInfo,
    relationType,
  } = relationPanelBaseProps;

  const { hasCreate, hasEdit, disabledFieldList } = relationPermission;
  const { defaultSelected, userSelected } = settings;
  const { parentResource, parentId } = parentInfo;
  const defaultView = relationMetaData?.defaultView;
  const isPrintView = defaultView === 'Print';
  const isPivotReport = defaultView === 'Pivot';

  useEffect(() => {
    actorOnDispatch(
      'filterDataIsChanged',
      (finalFilters: Record<string, FinalFiltersType>) => {
        const filters = finalFilters[relationResource!];
        if (filters) {
          actorSetActionValue('gridData', filters, {
            path: `${relationResource}.requestParameters.filter`,
            replaceAll: true,
            callerScopeName: 'FilterFormController => updateGridFilters',
          });
          refreshRelation();
        }
      },
      { preserve: false },
    );
  }, []);

  const parentRecord = actorGetActionValue(
    'record',
    `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown>; // when code arrive here , 100% parent record exists!

  /**
   * prepare necessary parameters and call `getHiddenGridColumns` with them
   * @function getRelationHiddenFields
   * @returns {Array<string>} hidden field names
   */
  const getRelationHiddenFields = (): string[] => {
    const parentMetaData = actorGetActionValue(
      'metaData',
      parentResource,
    ) as MetaData | null;

    const parentRecord = actorGetActionValue(
      'record',
      `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
    ) as Record<string, unknown>; // when code arrive here , 100% parent record exists!

    return getHiddenGridColumns({
      parentMetaData: parentMetaData as GeneralMetaData,
      currentMetaData: relationMetaData,
      parentRecord: parentRecord,
    });
  };

  /**
   * prepare exact resource and set hidden fields in correct path of actor
   * @function setGridHiddenFieldsInActor
   * @param {Array<string>} hiddenFields
   * @returns {void} void
   */
  const setGridHiddenFieldsInActor = (hiddenFields: string[]): void => {
    const { moduleName, moduleTableName } = relationMetaData.config ?? {};

    const currentResource = relationMetaData.reportId
      ? `report/${relationMetaData.reportId}`
      : `${moduleName}/${moduleTableName}`;

    actorSetActionValue('gridHiddenFields', hiddenFields, {
      path: currentResource,
    });
  };

  const fields = useMemo(() => {
    /**
     * @const hiddenGridColumns
     * it check only hidden field in parent process step in deactive subpanels object and simple field.hidden.
     * current process hidden fields will handle in API side
     *  */
    const hiddenGridColumns = getRelationHiddenFields();
    setGridHiddenFieldsInActor(hiddenGridColumns);

    /**
     * @const relationFieldsForDisplay
     * it will filter hidden fields after execute `getFieldsForDisplay` function
     */
    const relationFieldsForDisplay = getFieldsForDisplay(
      defaultSelected,
      userSelected,
      relationMetaData,
      disabledFieldList,
    );

    actorSetActionValue(
      'relationFieldsForDisplay',
      relationFieldsForDisplay?.map(field => field.name).join(','),
      { path: relationResource },
    );

    return relationFieldsForDisplay;
  }, [
    relationResource,
    relationMetaData?.['config']?.moduleName,
    relationMetaData?.['config']?.moduleTableName,
    userSelected,
  ]);

  /**
   * refresh relation data
   * @function refreshRelation
   * @returns {void}
   */
  const refreshRelation = () => {
    requestListData(
      relationResource,
      relationType,
      (parentRecord[parentFieldName] as string) ?? parentId, // FIXME: It's temporary, but, When we have to use `parentId` exactly?
      childFieldName,
      parentFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      (response: ApiRequestResultInterface) => {
        const summaryInfo = response[GET_MANY_REFERENCE]?.relation
          ?.data as ApiDataInterface;

        if (!isEmptyObject(summaryInfo)) {
          summaryDataRef.current = summaryInfo.additionalData.summary;
        }

        apiRequestResultHandler(response);
      },
    );
  };

  /**
   * change selected ids in grid data object in actor a
   * @function onSelectCheckbox
   * @param {Array<string|number>} selectedIds
   * @returns {void} void
   */
  const onSelectCheckbox = (selectedIds: Array<string | number>): void => {
    const castedValues = selectedIds.map(id => id?.toString());
    actorDispatch('gridData', castedValues, {
      path: `${relationResource}.selectedIds`,
    });
  };

  // --------------- pagination ---------------

  /**
   * it will call global function to change page
   * @function setSortAdopter
   * @param {string} fieldName
   * @returns {void}
   */
  const setPageAdopter: GridSetPageCallback = page => {
    setListPage(page, relationResource, refreshRelation);
  };

  /**
   * it will call global function to change sort
   * @function setSortAdopter
   * @param {string} fieldName
   * @returns {void}
   */
  const setPerPageAdopter: GridSetPerPageCallback = perPage => {
    setListPerPage(perPage, relationResource, refreshRelation);
  };

  // --------------- table handlers ---------------
  /**
   * //TODO should complete
   * @function onRowClick
   */
  const onRowClick = row => {
    console.log('row: ', row);
  };

  /**
   * //TODO should complete
   * @function addToFilterRequestList
   */
  const addToFilterRequestList = <T extends unknown>(data: T): void => {
    console.log('field: ', data);
  };

  const minimizedRelation = { parentFieldName, childFieldName };

  /**
   * it will call global function to change sort
   * @function setSortAdopter
   * @param {string} fieldName
   * @returns {void}
   */
  const setSortAdopter: GridSetSortCallback = fieldName => {
    setListSort(fieldName, relationResource, refreshRelation);
  };

  /**
   * change filter in grid data object in actor and request data with new parameters
   * @function setFilters
   * @param {Record<string, unknown>} newFilters
   * @returns {function}
   */
  const setFilters = (newFilters: Record<string, unknown>): void => {
    actorSetActionValue('gridData', newFilters, {
      path: `${relationResource}.requestParameters.searchFilters`,
      replaceAll: true,
    });
    refreshRelation();
  };

  const currentRequestParams = actorGetActionValue(
    'gridData',
    `${relationResource}.requestParameters`,
  ) as unknown as RequestParametersInterface;

  const perPagePagination =
    (getAppSettings(`${CONFIG_LIST_PER_PAGE}_${relationResource}`, true)
      .value as number) || null;

  if (isPrintView) {
    return (
      <PrintReport
        metaData={relationMetaData}
        listType="Print"
        resource={relationResource}
        isRelation={true}
      />
    );
  }
  if (isPivotReport) {
    return (
      <PivotTable
        metaData={relationMetaData}
        listType="Pivot"
        resource={relationResource}
      />
    );
  }

  return (
    <div>
      <FilterForm
        resource={relationResource}
        checkFiltersIsChangedBeforeSubmitting
      />
      <TableRelationView
        data={relationData}
        setFilters={setFilters}
        fields={clone(fields)}
        currentUrl={currentUrl}
        hasEdit={hasEdit}
        hasCreate={hasCreate}
        quickEditRowCallback={refreshRelation}
        onSelectCheckbox={onSelectCheckbox}
        onRowClick={onRowClick}
        parentInfo={parentInfo}
        parentRecord={parentRecord}
        setSort={setSortAdopter}
        sort={settings.userSort}
        metaData={relationMetaData}
        addToFilterRequestList={addToFilterRequestList}
        quickEditButton={hasEdit} // TODO: check carefully
        resource={relationResource}
        relation={minimizedRelation}
        refreshRelation={refreshRelation}
        isReport={relationType == 'report'}
        summaryData={summaryDataRef.current}
      />

      {fields.length > 0 && (
        <Pagination
          page={currentRequestParams?.pagination.page ?? 1}
          perPage={perPagePagination ?? 100}
          setPage={setPageAdopter}
          setPerPage={setPerPageAdopter}
          total={relationDataCount}
          isRelation
        />
      )}
    </div>
  );
};

export default TableRelationController;
