import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    justifyItems: 'center',
  },
  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'rtl',
  },
  enDialogACtionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    direction: 'ltr',
  },
  input: {
    marginBottom: '1rem',
  },
  cameraIcon: {
    width: '20px',
    height: '20px',
  },
  fileInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    width: '40px',
    height: '40px',
    marginLeft: '3rem',
    cursor: 'pointer',
  },
}));
