import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { getValue, SESSION_ID, WEB_SOCKET_API_URL } from '../../core/configProvider';
import { isEmpty } from '../../helper/data-helper';
import { CHAT_SIGNAL, getEventValue } from '../../hooks/useSignalRConnection';
import { actorDispatch, actorOnDispatch } from '../../type/actor-setup';
import { totalUnseenMessagesReportId } from './message-notification.helper';
import MessageNotificationView from './message-notification.view';
import { chatSocketAddress } from '../chat-section/chat-section.helper';

const MessageNotificationController = memo(() => {
  const webSocketApiUrl = getValue(WEB_SOCKET_API_URL);
  const sessionId = getValue(SESSION_ID);

  const history = useHistory();

  const [totalUnseen, setTotalUnseen] = useState<number | null>(null);

  /**
   * @function handleNotificationIconClick
   * @returns { void }
   */
  const handleNotificationIconClick = (): void => {
    history.push('/chat');
  };

  /**
   * @function totalUnseenMessagesCallback
   * @param response
   * @returns { void }
   */
  const totalUnseenMessagesCallback = (response: unknown): void => {
    setTotalUnseen(response as number);
  };

  /**
   * @function getSignalRTotalUnseenMessages
   * @returns { Promise<void> }
   *
   */
  const getSignalRTotalUnseenMessages = async (): Promise<void> => {
    await getEventValue({
      connectionType: CHAT_SIGNAL,
      connectionUrl: chatSocketAddress,
      userId: sessionId,
      signalREvent: 'OnTotalNotSeenReceived',
      onEventCallback: totalUnseenMessagesCallback,
    });
  };

  /**
   * @function getInitialTotalUnseenMessages
   * @returns { void }
   */
  const getInitialTotalUnseenMessages = (): void => {
    actorDispatch(
      'getChatReport',
      {
        successCallback: ({ data }) =>
          totalUnseenMessagesCallback(data?.[0]?.totalnotseen),
        params: {
          reportId: totalUnseenMessagesReportId,
          pagination: { perPage: 9999999 },
        },
        failureCallback: () => {},
      },
      {
        disableDebounce: true,
      },
    );
  };

  useEffect(() => {
    if (isEmpty(sessionId)) return;

    actorOnDispatch('updateTotalUnSeen', getInitialTotalUnseenMessages);

    getInitialTotalUnseenMessages();
    getSignalRTotalUnseenMessages();
  }, []);

  return (
    <MessageNotificationView
      totalUnseen={totalUnseen}
      handleNotificationIconClick={handleNotificationIconClick}
    />
  );
});

export default MessageNotificationController;
