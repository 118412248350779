import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  addNote: {
    display: 'flex',
    background: theme.palette.primary.appSecondaryBackgroundColor,
  },
  textField: {
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },
  noBorder: {
    border: 'none',
  },
  noPadding: { padding: 0 },
  addNoteWithIcon: {
    cursor: 'pointer',
  },
}));
