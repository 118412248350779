import { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import lodashDebounce from 'lodash/debounce';

import TodoStepsView from './todo-steps.view';

import { editTodoTask } from '../todo-section.helper';
import { showNotificationForUnknownError } from '../../../helper/general-function-helper';
import { findMaxNumberInArrayByKey, isEmpty } from '../../../helper/data-helper';
import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';

import type { TodoTaskItemInterface } from '../todo-tasks/todo-task-item/todo-task-item.type';
import type { stepItemInterface } from './todo-steps.type';

const TodoStepsController: FC = memo(() => {
  const [todoSelectedTaskInfo, setTodoSelectedTaskInfo] =
    useState<TodoTaskItemInterface | null>(null);
  const [stepList, setStepList] = useState<stepItemInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const newStepInputRef = useRef<HTMLInputElement>(null);

  const translate = useTranslate();

  useEffect(() => {
    const listenerId = actorOnDispatch('selectedTodoTask', taskInfo => {
      setTodoSelectedTaskInfo(taskInfo);
      setStepList(JSON.parse(taskInfo?.taskstep ?? '[]'));
    });

    return () => {
      actorRemoveAction({
        actionName: 'selectedTodoTask',
        listenerId: listenerId,
      });
    };
  }, []);

  useEffect(() => {
    saveChanges();
  }, [stepList]);

  //force focus in new step text input
  useEffect(() => {
    newStepInputRef.current?.focus();
  });
  /**
   * @function saveChanges
   * @returns void
   */
  const saveChanges = (): void => {
    const stringifyStepList = JSON.stringify(stepList);
    if (
      isEmpty(todoSelectedTaskInfo) ||
      stringifyStepList == todoSelectedTaskInfo?.taskstep
    ) {
      return;
    }

    editTodoTask({
      taskUID: String(todoSelectedTaskInfo?.uniqueid),
      newValues: {
        taskstep: stringifyStepList,
      },
      failureCallback: (error: unknown) => {
        showNotificationForUnknownError(error, translate);
      },
    });
  };

  /**
   * @function updateStep
   * @param {stepItemInterface} updatedStepInfo
   * @returns void
   */
  const updateStep = (updatedStepInfo: stepItemInterface): void => {
    const updatedStepList = stepList.map(step =>
      step.id === updatedStepInfo.id ? updatedStepInfo : step,
    );
    setStepList(updatedStepList);
  };

  /**
   * @function removeStep
   * @param {number} id
   * @returns void
   */
  const removeStep = (id: number): void => {
    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate('ra.message.are_you_sure'),
        onConfirm: (): void => {
          const updatedStepList = stepList.filter(item => item.id != id);
          setStepList(updatedStepList);
        },
      },
    });
  };

  /**
   * @function addStep
   * @param {React.KeyboardEvent<HTMLElement>} event
   * @returns void
   */
  const addStep = (event: React.KeyboardEvent<HTMLElement>): void => {
    //save step by Enter
    if (event.key != 'Enter') return;

    const maxId = findMaxNumberInArrayByKey(stepList, 'id');
    const maxRowOrder = findMaxNumberInArrayByKey(stepList, 'RowOrder');

    if (newStepInputRef.current?.value) {
      const newStep: stepItemInterface = {
        id: Number(maxId) + 1,
        IsDone: false,
        StepTitle: newStepInputRef.current.value,
        RowOrder: Number(maxRowOrder) + 1,
      };
      setStepList(prev => [...prev, newStep]);
      newStepInputRef.current.value = '';
    }
  };

  /**
   * @function handleChangeIsImportantTask
   * @returns void
   */
  const handleChangeIsImportantTask = () => {
    editTodoTask({
      taskUID: String(todoSelectedTaskInfo?.uniqueid),
      newValues: {
        isimportant: !todoSelectedTaskInfo?.isimportant,
      },
      failureCallback: (error: unknown) => {
        showNotificationForUnknownError(error, translate);
      },
    });
  };

  /**
   * @function handleChangeDoneStatusTask
   * @returns void
   */
  const handleChangeDoneStatusTask = () => {
    editTodoTask({
      taskUID: String(todoSelectedTaskInfo?.uniqueid),
      newValues: {
        isdone: !todoSelectedTaskInfo?.isdone,
      },
      failureCallback: (error: unknown) => {
        showNotificationForUnknownError(error, translate);
      },
    });
  };

  /**
   * @function handleChangeTaskTitle
   * @returns void
   */
  const handleChangeTaskTitle = lodashDebounce(
    (event: ChangeEvent<HTMLInputElement>) => {
      editTodoTask({
        taskUID: String(todoSelectedTaskInfo?.uniqueid),
        newValues: {
          detailtitle: event.target.value,
        },
        failureCallback: (error: unknown) => {
          showNotificationForUnknownError(error, translate);
        },
      });
    },
    500,
  );

  const sortedStepList: stepItemInterface[] = stepList.sort(function (a, b) {
    return a.RowOrder - b.RowOrder;
  });

  return (
    <TodoStepsView
      handleChangeDoneStatusTask={handleChangeDoneStatusTask}
      handleChangeIsImportantTask={handleChangeIsImportantTask}
      updateStep={updateStep}
      removeStep={removeStep}
      addStep={addStep}
      isImportantTask={!!todoSelectedTaskInfo?.isimportant}
      isDoneTaskImportant={!!todoSelectedTaskInfo?.isdone}
      taskTitle={todoSelectedTaskInfo?.detailtitle ?? ''}
      handleChangeTaskTitle={handleChangeTaskTitle}
      newStepInputRef={newStepInputRef}
      stepList={sortedStepList}
      isLoading={isLoading}
    />
  );
});
export default TodoStepsController;
