import { useCallback, useEffect, useState } from 'react';

import { currentUserLabelsReportId } from '../../mail-section/mail-section.helper';
import { editTodoTask } from '../todo-section.helper';
import TodoLabelView from './todo-label.view';

import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import type { ChangeEvent, ReactElement } from 'react';
import type { LabelListInterface } from './todo-label.type';
import { TodoTaskItemInterface } from '../todo-tasks/todo-task-item/todo-task-item.type';
import { isEmpty } from '../../../helper/data-helper';

const TodoLabelController = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isNewLabelDialogOpen, setIsNewLabelDialogOpen] = useState<boolean>(false);
  const [labelList, setLabelList] = useState<LabelListInterface[]>([]);
  const [checkedLabels, setCheckedLabels] = useState<LabelListInterface[]>([]);
  const [todoSelectedTaskInfo, setTodoSelectedTaskInfo] =
    useState<TodoTaskItemInterface | null>(null);

  useEffect(() => {
    const listenerId = actorOnDispatch('selectedTodoTask', taskInfo => {
      setTodoSelectedTaskInfo(taskInfo);
      setCheckedLabels(JSON.parse(taskInfo?.labelsarray ?? '[]'));
    });

    return () => {
      actorRemoveAction({
        actionName: 'selectedTodoTask',
        listenerId: listenerId,
      });
    };
  }, []);

  useEffect(() => {
    saveChanges();
  }, [checkedLabels]);

  /**
   * @function saveChanges
   * @returns void
   */
  const saveChanges = (): void => {
    const stringifyLabelsArray = JSON.stringify(checkedLabels);
    if (
      isEmpty(todoSelectedTaskInfo) ||
      stringifyLabelsArray == todoSelectedTaskInfo?.labelsarray
    ) {
      return;
    }
    editTodoTask({
      taskUID: String(todoSelectedTaskInfo?.uniqueid),
      newValues: {
        labelsarray: stringifyLabelsArray,
      },
    });
  };

  /**
   * @function onItemSelect
   * @param {LabelListInterface} item
   * @returns { void }
   */
  const onItemSelect = (item: LabelListInterface) => (): void => {
    const CheckedIds = checkedLabels.map(item => item.lables_id);

    if (CheckedIds?.includes(item.lables_id)) {
      const newCheckLabelList = checkedLabels?.filter(
        labelObject => labelObject.lables_id !== item.lables_id,
      );
      setCheckedLabels(newCheckLabelList);
    } else {
      setCheckedLabels(prev => [...prev, item]);
    }
  };

  /**
   * @function handleDeleteLabel
   * @param {LabelListInterface} value
   * @returns { void }
   */
  const handleDeleteLabel = (value: LabelListInterface) => (): void => {
    const filterItem = checkedLabels?.filter(item => item !== value);
    setCheckedLabels(filterItem);
  };

  /**
   * @function getUserLabels
   * @returns { void }
   */
  const getUserLabels = useCallback((): void => {
    actorDispatch(
      'getMailReport',
      {
        successCallback: result => {
          setLabelList(result.data);
        },
        params: {
          reportId: currentUserLabelsReportId,
          pagination: { perPage: 9999 },
        },
      },
      {
        disableDebounce: true,
        callerScopeName: 'TodoLabelController => getUserLabels',
      },
    );
  }, []);

  /**
   * @function getLabelListAndOpenMenuLabel
   * @param { ChangeEvent<HTMLInputElement>} event
   * @returns { void }
   */
  const getLabelListAndOpenMenuLabel = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setAnchorEl(event.currentTarget as HTMLInputElement);
    if (!labelList.length) {
      getUserLabels();
    }
  };

  /**
   * @function handleCloseMenuLabel
   * @returns { void }
   */
  const handleCloseMenuLabel = (): void => {
    setAnchorEl(null);
  };

  /**
   * @function handleOpenNewLabelDialog
   * @returns { void }
   */
  const handleToggleNewLabelDialog = useCallback((): void => {
    setIsNewLabelDialogOpen(prev => !prev);
  }, []);

  return (
    <TodoLabelView
      handleDeleteLabel={handleDeleteLabel}
      onItemSelect={onItemSelect}
      handleCloseMenuLabel={handleCloseMenuLabel}
      getLabelListAndOpenMenuLabel={getLabelListAndOpenMenuLabel}
      handleToggleNewLabelDialog={handleToggleNewLabelDialog}
      getUserLabels={getUserLabels}
      isNewLabelDialogOpen={isNewLabelDialogOpen}
      checkedLabels={checkedLabels}
      anchorEl={anchorEl}
      labelList={labelList}
    />
  );
};

export default TodoLabelController;
