import { type FC, useEffect } from 'react';
import L from 'leaflet';

import { useLeaflet } from 'react-leaflet';

import { parseJSON } from '../../../core/configProvider';
import { type MarkerLegendProps } from './leaflet-map.type';
import { isJsonEncodedString } from '../../../helper/data-helper';

const LeafletMapLegend: FC<MarkerLegendProps> = props => {
  const legend = L.control({ position: 'bottomright' });
  const { map } = useLeaflet();

  useEffect(() => {
    const mapLegendList: any = isJsonEncodedString(props.headerMetaData)
      ? parseJSON(props.headerMetaData ?? '')
      : props.headerMetaData;

    if (!mapLegendList || !mapLegendList.MapLegend) {
      return;
    }

    legend.onAdd = () => {
      if (!mapLegendList?.MapLegend) return;
      const length = mapLegendList?.MapLegend.length;

      const divElement = L.DomUtil.create('div', 'info legend');
      for (let i = 0; i < length; i++) {
        if (
          !mapLegendList.MapLegend[i].Icon &&
          !mapLegendList.MapLegend[i].Description
        )
          return;

        const greenIcon = L.icon({
          iconUrl: getIconFromLocal(mapLegendList.MapLegend[i].Icon),
          shadowUrl: 'leaf-shadow.png',
          iconSize: [20, 20],
          iconAnchor: [0, 0],
          popupAnchor: [15, 0],
        });

        const newDivElement = document.createElement('div');
        newDivElement.classList.add('legend-icon');
        newDivElement.textContent = mapLegendList.MapLegend[i].Description;
        newDivElement.appendChild(greenIcon.createIcon());
        divElement.appendChild(newDivElement);
      }
      return divElement;
    };

    legend?.addTo(map);
  }, []);

  /**
   * getIconFromLocal
   * @param {string} fileName
   * @returns
   */
  const getIconFromLocal = (fileName: string) => {
    try {
      return require(`../../../images/icon/map/${fileName}.svg`).default;
    } catch {
      return require(`../../../images/icon/map/defaultMarkerMap.svg`).default;
    }
  };

  return null;
};
export default LeafletMapLegend;
