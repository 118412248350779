import makeStyles from '@material-ui/core/styles/makeStyles';
import { StyleProps } from './header-profile.type';
import { CustomTheme } from '../../core/themeProvider';
import grey from '@material-ui/core/colors/grey';

export const useStyles = makeStyles<CustomTheme, StyleProps>(() => ({
  toolbarContainer: {
    padding: ({ isDrawerOpen }) => (isDrawerOpen ? '5px 15px 0' : '5px 0'),
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  avatarContainer: {
    margin: '0 0.5rem',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    '&:hover div:nth-child(2)': {
      transform: 'translate3d(0, 0, 0)',
    },
  },

  imageContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  avatarIcon: {
    fontSize: 20,
    color: '#fafafa',
  },

  uploadImage: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    top: 'auto',
    width: 48,
    height: 48,
    backgroundColor: 'hsla(0, 0%, 0%, 0.28)',
    textAlign: 'center',
    cursor: 'pointer',
    transform: 'translate3d(0, 60px, 0)',
    transition: 'transform 0.28s cubic-bezier(0.4, 0, 0.2, 1)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  profile: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    flexBasis: '50%',
  },

  profileDisplayName: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  warehouseDisplayName: {
    fontSize: 10,
    fontWeight: 'bold',
  },

  profileUserCompanies: {
    fontSize: 10,
  },
  topBurgerMenu: {
    cursor: 'pointer',
  },

  burgerMenu: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0 0 0',
    cursor: 'pointer',
    padding: '1rem',
    '&:hover': {
      backgroundColor: grey[200],
    },
  },
}));
