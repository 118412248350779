import { ReactElement } from 'react';
import { IconButton, InputAdornment, TextField, useTheme } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { useStyles } from './text-input.style';
import { TextInputViewProps } from './text-input.type';
import InputBase from '../input-base';

export const TextInputView = (props: TextInputViewProps): ReactElement => {
  const {
    getRef,
    field,
    inputMessage,
    label,
    hint,
    disabled,
    resource,
    handleBlur,
    handleFocus,
    handleChange,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
    passwordShown,
    changeShowPassword,
    inputValueIsAValidUrl,
    handleUrlForOpenNewTab,
    value,
  } = props;

  const classes = useStyles(props);
  const { name, required, customOption } = field;

  return (
    <InputBase
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      required={required}
      field={field}
      className={`${visibleClass} ${inputContainerClass}`}
    >
      <TextField
        value={value}
        {...customTestAttribute}
        ref={getRef}
        type={field.isHashed ? (!passwordShown ? 'password' : 'text') : 'text'}
        name={name}
        required={required}
        classes={{
          root: classes.root,
        }}
        variant="outlined"
        InputProps={{
          disabled,
          classes: {
            root: classes.outlineRoot,
            notchedOutline: classes.notchedOutline,
            disabled: classes.disabled,
          },

          endAdornment: (
            <>
              {inputValueIsAValidUrl && (
                <InputAdornment position="end">
                  <OpenInNewIcon
                    onClick={handleUrlForOpenNewTab}
                    fontSize="small"
                    className={classes.iconUrl}
                  />
                </InputAdornment>
              )}

              {field.isHashed ? (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.icon}
                    aria-label="toggle password visibility"
                    onClick={changeShowPassword}
                  >
                    {!passwordShown ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ) : null}
            </>
          ),
        }}
        inputProps={{
          'data-test-input-name': `${resource}/${name}`,
          'data-test-input-field-priority': field.priority,
          maxLength: field.maxLength,
          autoComplete: field.isHashed ? 'new-password' : 'off',
          style: customOption.customStyleAdditionalDataField,
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        disabled={disabled}
        data-test="inputContainerTextField"
        data-test-has-error={inputMessage?.messageType === 'error'}
        data-test-has-warning={inputMessage?.messageType === 'warning'}
        size="small"
      />
    </InputBase>
  );
};
