import { FC } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';

import { BreadcrumbViewProps } from './breadcrumb.type';
import LoadingBox from '../LoadingBox';
import { useStyles } from './breadcrumb.style';
import { Link } from '@material-ui/core';

const BreadcrumbView: FC<BreadcrumbViewProps> = props => {
  const { isSimpleBreadcrumb, isReady, title, link } = props;
  const classes = useStyles();

  return (
    <>
      {isSimpleBreadcrumb && isReady && link && (
        <Link
          className={classes.link}
          underline="none"
          component={RouterLink}
          to={link}
        >
          {title}
        </Link>
      )}

      {!isSimpleBreadcrumb && link && (
        <MuiButton component={RouterLink} color="secondary" to={link}>
          {title ?? <LoadingBox size={25} />}
        </MuiButton>
      )}

      {!isSimpleBreadcrumb && !link && (
        <MuiButton disabled className={classes.parentButton}>
          {title ?? <LoadingBox size={25} />}
        </MuiButton>
      )}
    </>
  );
};

export default BreadcrumbView;
