import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Icon, Typography } from '@material-ui/core';

import { useStyles } from './add-in-my-day-button.style';

import type { AddInMyDay } from './add-in-my-day-button.type';

const AddInMyDayView = (props: AddInMyDay): ReactElement => {
  const { onChangeIsAddedToMyDay, isAddedToMyDayLocal } = props;
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div data-test="inMyDay" className={classes.itemContainer}>
      <div onClick={onChangeIsAddedToMyDay} className={classes.body}>
        <Icon
          fontSize="small"
          className={
            isAddedToMyDayLocal
              ? classes.sidebarTodoIcon
              : classes.todoSidebarSelectedIcon
          }
        >
          wb_sunny_out_lined
        </Icon>
        <div className={classes.titleAndValue}>
          <Typography
            variant="caption"
            className={
              isAddedToMyDayLocal ? classes.todoItemText : classes.todoSelectedItem
            }
          >
            {isAddedToMyDayLocal
              ? translate('todo.addedToMyDay')
              : translate('todo.addToMyDay')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AddInMyDayView;
