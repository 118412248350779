import { FC, memo } from 'react';

import type {
  contextMenuOptionInterface,
  TodoDateOptionsMenuInterface,
} from './todo-date-options-menu.type';
import TodoDateOptionsMenuView from './todo-date-options-menu.view';

const TodoDateOptionsMenu: FC<TodoDateOptionsMenuInterface> = memo(props => {
  const { isContextMenuOpen, items, handleClickItem } = props;

  /**
   * @function handleOnClickMenuItem
   * @param { contextMenuOptionInterface } clickedItem
   * @param { React.MouseEvent<HTMLElement> } event
   * @returns { void }
   */
  const handleOnClickMenuItem =
    (clickedItem: contextMenuOptionInterface) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      handleClickItem(clickedItem.value);
    };

  return (
    <TodoDateOptionsMenuView
      onClickItem={handleOnClickMenuItem}
      isContextMenuOpen={isContextMenuOpen}
      items={items}
    />
  );
});

export default TodoDateOptionsMenu;
