import { Button, DialogActions } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { ViewButtonDialogInterface } from '../sortable-list/sortable-list.type';
import { FC } from 'react';

const ViewButtonDialog: FC<ViewButtonDialogInterface> = props => {
  const { dynamicData, closeDialogHandler, saveChange } = props;
  const translate = useTranslate();

  /**
   * saveButton
   * @returns {void} void
   */
  const saveButton = (): void => {
    saveChange?.(dynamicData ?? [])();
    closeDialogHandler?.();
  };

  return (
    <DialogActions data-style-dialog-sortable="dialogSortable">
      <Button
        variant="contained"
        color="primary"
        onClick={saveButton}
        id="SaveButton"
      >
        {translate('ra.action.save')}
      </Button>
      <Button onClick={closeDialogHandler}>{translate('ra.action.cancel')}</Button>
    </DialogActions>
  );
};

export default ViewButtonDialog;
