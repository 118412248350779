import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: '55px !important',
    flex: 1,
    '& .user-info': {
      width: '100%',
    },
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  pointer: {
    cursor: 'pointer',
  },

  headerContainer: {
    backgroundColor: theme.palette.primary.appLightBackgroundColor,
  },
}));
