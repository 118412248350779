import { ReactElement } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import momentJalaali from 'moment-jalaali';
import { PostCardViewInterface } from './post-card.type';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './post-card.style';
import {
  removePageBreakFromHtmlString,
  truncateHtmlStringPageBreak,
} from '../../rich-text-editor-field/rich-text-editor-field.helper';
import {
  gregorianDateTimeFormat,
  jalaliDateTimeFormat,
} from '../../../helper/CalendarMetaHelper';
import { RichTextEditorField } from '../../rich-text-editor-field';
import { isEmpty } from '../../../helper/data-helper';

const PostCardView = (props: PostCardViewInterface): ReactElement => {
  const { post, isLoading, imageUrl, fullContentMode, onContinueReadingHandler } =
    props;
  const { annsubject, anntexthtml, createdate } = post;
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  momentJalaali.locale(locale);

  return (
    <Grid item xs={12} className={classes.container}>
      <div>
        <Card style={{ display: 'flex', flexDirection: 'column' }}>
          {isLoading ? (
            <Skeleton>
              <CardMedia
                component="img"
                className={classes.image}
                image={imageUrl}
                alt={annsubject}
              />
            </Skeleton>
          ) : (
            !isEmpty(imageUrl) && (
              <CardMedia
                component="img"
                className={classes.image}
                image={imageUrl}
                alt={annsubject}
              />
            )
          )}

          <CardContent style={{ flex: 1 }}>
            {isLoading ? (
              <Skeleton>
                <Typography component="h2" variant="h5">
                  {annsubject}
                </Typography>
              </Skeleton>
            ) : (
              <Typography component="h2" variant="h5">
                {annsubject}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton>
                <Typography
                  style={{ marginBottom: '1rem' }}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {createdate}
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                style={{ marginBottom: '1rem' }}
                variant="subtitle1"
                color="textSecondary"
              >
                {locale === 'fa'
                  ? momentJalaali(createdate).format(jalaliDateTimeFormat)
                  : momentJalaali(createdate).format(gregorianDateTimeFormat)}
              </Typography>
            )}
            {isLoading ? (
              <Skeleton>
                <Typography variant="subtitle1" paragraph>
                  {anntexthtml}
                </Typography>
              </Skeleton>
            ) : (
              <>
                {' '}
                <Typography variant="subtitle1" paragraph>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: fullContentMode
                        ? removePageBreakFromHtmlString(anntexthtml)
                        : truncateHtmlStringPageBreak(anntexthtml),
                    }}
                  />
                </Typography>
                {!fullContentMode && (
                  <Typography
                    component="a"
                    onClick={onContinueReadingHandler}
                    target="_blank"
                    variant="subtitle1"
                    color="primary"
                  >
                    {translate('blog.continueReading')}
                  </Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
};

export default PostCardView;
