import { FC } from 'react';

import { useStyles } from './todo-repeat-task.style';
import { useTranslate } from 'react-admin';
import { Box, Button, Icon, Typography } from '@material-ui/core';
import { MenuItem, Grid, FormControl, TextField, Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

import {
  repeatTypeDropDown,
  todoDateRepeatContextMenuOption,
  todoWeekDayList,
} from '../todo-date-options.helper';

import { TodoDateOptionsMenu } from '../todo-date-options-menu';
import { AppDialog } from '../../../app-dialog';

import type {
  WeekdaysListInterface,
  TodoRepeatTaskViewInterface,
} from './todo-repeat-task.type';

const TodoRepeatTaskView: FC<TodoRepeatTaskViewInterface> = props => {
  const {
    handleOpenContextMenu,
    handleClickMenuItem,
    handleRemoveRepeatOptions,
    handleChangeRepeatType,
    handleCloseOptionsDialog,
    handleChangeRepeatEveryCount,
    handleSaveRepeatOptions,
    isOpenOptionsDialog,
    contextMenuDetails,
    repeatEveryCount,
    repeatTypeLabel,
    repeatWeekDays,
    isMinimized,
    repeatType,
    handleChangeWeekDays,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.itemContainer}>
      <div onClick={handleOpenContextMenu} className={classes.body}>
        <Tooltip title={translate('todo.repeat')}>
          <Icon
            className={
              repeatType ? classes.sidebarTodoIcon : classes.todoSidebarSelectedIcon
            }
            fontSize="small"
          >
            repeat
          </Icon>
        </Tooltip>

        <div className={classes.titleAndValue}>
          {!isMinimized && !repeatTypeLabel && (
            <Typography
              className={classes.todoItemText}
              variant="caption"
              color={repeatType ? 'secondary' : 'textPrimary'}
            >
              {translate('todo.repeat')}
            </Typography>
          )}
          {repeatType && (
            <span color="textSecondary" className={classes.value}>
              {repeatTypeLabel}
            </span>
          )}
        </div>

        <TodoDateOptionsMenu
          isContextMenuOpen={contextMenuDetails}
          items={todoDateRepeatContextMenuOption(translate)}
          handleClickItem={handleClickMenuItem}
        />
      </div>

      <AppDialog
        maxWidth="xs"
        open={isOpenOptionsDialog}
        handleClose={handleCloseOptionsDialog}
        dialogTitle={translate('todo.CustomRepeat')}
        dialogContent={
          <FormControl size="small">
            <Grid container spacing={0}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-between',
                  paddingLeft: 5,
                }}
              >
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    type={'number'}
                    variant="outlined"
                    value={repeatEveryCount}
                    onChange={handleChangeRepeatEveryCount}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    data-test="occurrence-choose"
                    value={repeatType}
                    onChange={handleChangeRepeatType}
                    className={classes.dayPicker}
                    variant="outlined"
                  >
                    <MenuItem value={repeatTypeDropDown.daily}>
                      {translate('todo.daily')}
                    </MenuItem>
                    <MenuItem value={repeatTypeDropDown.weekly}>
                      {translate('todo.weekly')}
                    </MenuItem>
                    <MenuItem value={repeatTypeDropDown.monthly}>
                      {translate('todo.monthly')}
                    </MenuItem>
                    <MenuItem value={repeatTypeDropDown.yearly}>
                      {translate('todo.yearly')}
                    </MenuItem>
                  </Select>
                </Grid>
              </div>

              {repeatType == Number(repeatTypeDropDown.weekly) && (
                <div className={classes.inputCustomWeek}>
                  {todoWeekDayList(translate).map((item: WeekdaysListInterface) => (
                    <Button
                      size="small"
                      onClick={handleChangeWeekDays(item.value)}
                      className={
                        repeatWeekDays.includes(item.value)
                          ? classes.active
                          : classes.notActive
                      }
                    >
                      {item.title}
                    </Button>
                  ))}
                </div>
              )}
            </Grid>
          </FormControl>
        }
        dialogActions={
          <Box className={classes.formActionContainer}>
            <Button
              onClick={handleSaveRepeatOptions}
              className={classes.confirmButton}
              variant="contained"
              color="secondary"
            >
              {translate('general.apply')}
            </Button>
          </Box>
        }
      />
      {repeatType && (
        <CloseIcon onClick={handleRemoveRepeatOptions} fontSize="small" />
      )}
    </div>
  );
};
export default TodoRepeatTaskView;
