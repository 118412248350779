import { RUN_SERVICE } from '../../../../core/data-Provider.helper';
import { showNotification } from '../../../../helper/general-function-helper';
import { actorDispatch, actorGetActionValue } from '../../../../type/actor-setup';
import { getChats } from '../../chat-section.helper';

import type { ChatItemInterface, ContactInterface } from '../../chat-section.type';

const deleteGroupUniqueId = 'e5a5f129-1dad-41c4-98f9-7fe958b4cea5';
const deleteChatUniqueId = 'c0e69d65-5155-4cc1-aa40-0ea58508f5b7';

/**
 * @function deleteChat
 * @param {Object} Obj delete chat parameters
 * @param {string} Obj.ChatUID chatid for deleting a chat with a single person
 * @param {string} Obj.GroupUID groupid for deleting a group chat
 * @param {number} Obj.successFunction do for deleting
 * @returns {void} void
 */
const deleteChat = (params: {
  personInfoId: number;
  GroupUID?: string;
  onSuccess: () => void;
  ChatID?: string;
}): void => {
  const { personInfoId, GroupUID, onSuccess } = params;
  actorDispatch('crudAction', {
    type: RUN_SERVICE,
    actionUniqueId: GroupUID ? deleteGroupUniqueId : deleteChatUniqueId,
    data: {
      params: {
        GroupUID,
        otherpersoninfo_id: personInfoId,
      },
    },
    onSuccess,
    onFailure: error => {
      if (typeof error === 'string') {
        showNotification(error, 'error');
      }
    },
  });
};

/**
 * @function onDeleteChatConfirm
 * @param {ChatItemInterface | ContactInterface } item
 * @returns {void} void
 */
export const onDeleteChatConfirm = (
  item: ChatItemInterface | ContactInterface,
): void => {
  deleteChat({
    personInfoId: item.personinfo_id,
    GroupUID: item.groupuid,
    onSuccess: () => {
      const currentChat = actorGetActionValue('selectedChat');
      if (currentChat?.info.personinfo_id === item.personinfo_id) {
        actorDispatch('selectedChat', null, {
          callerScopeName: 'onDeleteChatConfirm',
        });
      }

      const currentChatList = actorGetActionValue('chatList')!;
      delete currentChatList.data[item.personinfo_id];

      actorDispatch(
        'chatList',
        { ...currentChatList },
        {
          replaceAll: true,
          callerScopeName: 'onDeleteChatConfirm',
        },
      );

      actorDispatch('closeCurrentDialog', true);
    },
  });
};
