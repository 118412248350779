import { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import type { FC } from 'react';

import { isEmptyObject } from '../../../helper/data-helper';
import { getFilterColumns } from '../../../helper/MetaHelper';
import ActionBarView from './action-bar.view';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  type GridDataInterface,
} from '../../../type/actor-setup';

import { addColorFilterToFilters } from '../../table-color-filter';
import { ROW_STATE_COLOR_FILTER } from '../../../core/configProvider';
import type { MenuOptionInterface } from '../../button-menu';
import type { RequestParametersInterface } from '../../../type/actor-setup';
import type { ActionBarControllerProps } from './action-bar.type';
import type {
  FilterItemBaseType,
  FilterItemFinalFormatType,
} from '../../filter-form';

const ActionBarController: FC<ActionBarControllerProps> = props => {
  const {
    getListDataAndRefresh,
    toggleShowFilters, // in relations and search popup
    isRelationMode = false,
    hasAccessPath = false,
    resource,
    quickCreateMustRefresh = false, // TODO: its disable in dropdown but check if its necessary or could be deleted
    hasCreate,
    hasDelete,
    metaData,
    processList,
    listColumnChoiceMustRefresh = true, // only list
    isColumnChoice = true, // in relations should compute //todo but should double check
    treeCondition = false,
    isSelectedItemsCount = true,
    initialData,
    activeTabIndex, // only in multi-tab
    disableDelete = false, // only in tree
    relationToolbarProps,
    tableRowColors,
    onShowGridToggleClick,
    onChangeClusterModeClick,
    rootResource,
    hideGrid,
    clusterMode,
  } = props;

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const selectedIdsRef = useRef<string[]>([]);

  const fieldListToFilter = useMemo(() => {
    return getFilterColumns(metaData);
  }, []);

  useEffect(() => {
    const id = actorOnDispatch('gridIDs', gridIds => {
      let finalResource: string = resource;
      if (metaData?.reportType === 'MultiResult') {
        const activeTab = actorGetActionValue('activeTab', rootResource) ?? 0;
        finalResource = `${rootResource}/${activeTab}`;
      }
      const selectedIdsInActor = gridIds?.[finalResource]?.selectedIDs ?? [];

      const areSelectedIdsChanged =
        selectedIdsInActor.length !== selectedIdsRef.current.length ||
        selectedIdsInActor.some(
          idInActor => !selectedIdsRef.current.includes(String(idInActor)),
        );

      if (areSelectedIdsChanged) {
        setSelectedIds(selectedIdsInActor.map(String));
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'gridIDs',
        listenerId: id,
      });
    };
  }, [resource]);

  useEffect(() => {
    selectedIdsRef.current = selectedIds;
  }, [selectedIds]);

  const getFilterValuesFromActor = (): (
    | FilterItemFinalFormatType
    | FilterItemFinalFormatType[]
  )[] => {
    const filterExcel =
      (
        actorGetActionValue(
          'gridData',
          rootResource ?? resource, //in multi-rab reports,filters sets on root resource
        ) as GridDataInterface | null
      )?.requestParameters?.filter ?? [];

    return filterExcel;
  };

  /**
   * @function colorFilterChangeHandler
   * @param { MenuOptionInterface } selectedItem
   * @returns { void } void
   */
  const colorFilterChangeHandler = useCallback(
    (selectedItem: MenuOptionInterface) => {
      const gridData = actorGetActionValue(
        'gridData',
        resource,
      )! as GridDataInterface;

      if (isEmptyObject(gridData.requestParameters)) {
        (gridData.requestParameters as unknown as Pick<
          RequestParametersInterface,
          'filter'
        >) = {
          filter: [],
        };
      }

      if (gridData.requestParameters!.filter == null) {
        gridData.requestParameters!.filter = [];
      }

      if (selectedItem.value == null) {
        const targetIndex = gridData.requestParameters!.filter.findIndex(
          item => Array.isArray(item) && item[0] === ROW_STATE_COLOR_FILTER.KEY,
        );
        if (targetIndex > -1) {
          gridData.requestParameters!.filter.splice(targetIndex, 1);
        }
      } else {
        gridData.requestParameters!.filter = addColorFilterToFilters(
          gridData.requestParameters!.filter as (string | FilterItemBaseType)[],
          selectedItem.value + '',
        );
      }

      actorSetActionValue('gridData', gridData.requestParameters, {
        path: `${resource}.requestParameters`,
        callerScopeName: 'ActionBarController => colorFilterChangeHandler',
      });

      getListDataAndRefresh?.(true);
    },
    [],
  );

  return (
    <ActionBarView
      toggleShowFilters={toggleShowFilters}
      onDeleteSuccessCallback={getListDataAndRefresh}
      hasAccessPath={hasAccessPath}
      isRelationMode={isRelationMode}
      isRefreshEnabled={isRelationMode}
      resource={resource}
      quickCreateMustRefresh={quickCreateMustRefresh}
      hasCreate={hasCreate}
      processList={processList}
      metaData={metaData}
      listColumnChoiceMustRefresh={listColumnChoiceMustRefresh}
      isColumnChoice={isColumnChoice}
      hasDelete={hasDelete}
      selectedIds={selectedIds}
      treeCondition={treeCondition}
      disableDelete={disableDelete}
      initialData={initialData}
      activeTabIndex={activeTabIndex}
      isSelectedItemsCount={isSelectedItemsCount}
      relationToolbarProps={relationToolbarProps}
      fieldListToFilter={fieldListToFilter}
      colorFilterChangeHandler={colorFilterChangeHandler}
      tableRowColors={tableRowColors}
      filterValues={getFilterValuesFromActor()}
      onShowGridToggleClick={onShowGridToggleClick}
      onChangeClusterModeClick={onChangeClusterModeClick}
      rootResource={rootResource}
      hideGrid={hideGrid}
    />
  );
};

export default ActionBarController;
