import { FC, memo } from 'react';
import { AppDrawerInterface } from './app-drawer.type';
import AppDrawerView from './app-drawer.view';

const AppDrawerController: FC<AppDrawerInterface> = memo(props => {
  const {
    routesConfig,
    baseUrl,
    sidebarClasses,
    backgroundColor,
    parentComponentName = '',
    canDragDrop = false,
    ...rest
  } = props;

  return (
    <AppDrawerView
      {...rest}
      routesConfig={routesConfig}
      sidebarClasses={sidebarClasses}
      backgroundColor={backgroundColor}
      baseUrl={baseUrl}
      parentComponentName={parentComponentName}
      canDragDrop={canDragDrop}
    />
  );
});

export default AppDrawerController;
