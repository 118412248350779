import { ReactElement, useContext } from 'react';
import { actorDispatch } from '../../type/actor-setup';
import { NewMetaContext } from '../../container/NewMetaContext';
import { SearchPopupButtonProps } from './search-popup-button.type';
import SearchPopupButtonView from './search-popup-button.view';

const SearchPopupButtonController = (
  props: SearchPopupButtonProps,
): ReactElement => {
  const {
    label,
    resource,
    source,
    dropdownMeta,
    relationResource,
    relationSource,
    relationInfo,
    disabled,
    changeFormValue,
    addToRelationArray,
    formData,
    dropdownMultipleSelection,
    parentResource,
    dropBaseValue = [],
    fieldName, //TODO: replace with field.name
    dropdownInPuzzleForm,
    buttonRef,
    buttonCustomStyle,
    formActionsHandler,
    field,
    parentResourceType,
    isTodo,
    isProfile,
    isService,
    isFilter,
    className,
    clickHandler,
    changeFormValueByClickingOnRow,
    dropBasePreparedOptions,
    customMakeRequest,
    isFilterInput,
    maxSelection,
  } = props;

  const { getMeta } = useContext(NewMetaContext);

  /**
   * it should gather the search dialog required data and dispatch an actor event to open it
   * @function openSearchDialog
   * @params { MouseEvent } event
   * @returns {void} void
   */
  const openSearchDialog = event => {
    if (disabled) return;

    clickHandler?.(event);

    const searchDialogRequireParameters = {
      isOpen: true,
      record: formData,
      formActionsHandler,
      resource,
      dropdownId: dropdownMeta.id,
      dropdownMeta,
      source,
      relationResource,
      relationSource,
      relationInfo,
      metaData: field.dropdown ?? {},
      relationMetaData,
      changeFormValue,
      addToRelationArray,
      dropdownMultipleSelection,
      parentResource,
      dropBaseValue,
      fieldName,
      dropdownInPuzzleForm,
      isTodo,
      isProfile,
      isService,
      isFilter,
      field,
      parentResourceType,
      changeFormValueByClickingOnRow,
      dropBasePreparedOptions,
      customMakeRequest,
      isFilterInput,
      maxSelection,
    };

    actorDispatch('searchDialog', searchDialogRequireParameters);
  };

  const relationMetaData = relationResource ? getMeta(relationResource) : null;

  return (
    <SearchPopupButtonView
      ref={buttonRef}
      style={buttonCustomStyle}
      label={label}
      openSearchDialog={openSearchDialog}
      disabled={disabled}
      className={className}
    />
  );
};

SearchPopupButtonController.defaultProps = {
  buttonCustomStyle: {},
};

export default SearchPopupButtonController;
