import {
  FILE_MANAGER,
  CHAT,
  POS,
  CART,
  TREE,
  MENU,
  WMS,
  CALENDAR,
  MULTI_REPORT,
  SINGLE_RECORD,
  VISITOR_CALENDAR,
  PIVOT_TABLE,
  DASHBOARD,
  FORM_VIEW,
  FORM_BUILDER,
} from '../core/configRouteConstant';
import PrivateRoute from '../container/PrivateRoute';
import MenuPage from './MenuPage';
import TreeListPage from './TreeListPage';
import CalendarPage from './CalendarPage';
import ListPage from './ListPage';
import ShowRecordPage from './ShowRecordPage';
import SingleRecordPage from './SingleRecordPage';
import CreateEditRecordPage from './CreateEditRecordPage';
import PointOfSaleView from '../component/pos/PointOfSaleView';
import WMSPage from './wms-page';
import FileManagerPage from './FileManagerPage';
import VisitorCalendarPage from './VisitorCalendarPage';
import ChatPage from './ChatPage';
import { PivotTable } from '../component/pivot-table';
import CustomCssController from '../component/custom-css/custom-css-controller';
import Automation from '../component/automation/automation.controller';
import { ShowPermissions } from '../component/show-permissions';
import { Permissions } from '../component/permissions';
import { DashboardPage } from '../component/dashboard';
import { FormBuilder } from '../component/form-builder';
import {ListDashboard} from '../component/list-dashboard';
import { RangePicker } from '../component/range-picker-jira';
import { ShowPermissionsAdvance } from '../component/show-permissions-advance';

// const FormLayoutPage = require('./FormLayoutPage').default;

// NOTE: first pattern that matches will be used
// prettier-ignore
const list = [
  <PrivateRoute
    exact
    path={`/${DASHBOARD}`}
    component={DashboardPage}
  />,
  <PrivateRoute
    exact
    path={`/${DASHBOARD}/:dashboardId`}
    component={DashboardPage}
  />,
  <PrivateRoute
    exact
    path={`/${FORM_BUILDER}`}
    component={FormBuilder}
  />,
  <PrivateRoute
    exact
    path={`/${FORM_VIEW}`}
    component={FormBuilder}
  />,
  <PrivateRoute
    exact
    path={`/${POS}/:moduleName/:moduleTableName`}
    component={PointOfSaleView}
  />,
  <PrivateRoute
    exact
    path={`/${SINGLE_RECORD}/:moduleName/:moduleTableName`}
    component={SingleRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/${MULTI_REPORT}/:reportId/:tabIndex?`}
    component={ListPage}
  />,
  <PrivateRoute
    exact
    path={`/${CALENDAR}/:moduleName/:moduleTableName`}
    component={CalendarPage}
  />,
  <PrivateRoute
    exact
    path={`/${TREE}/:moduleName/:moduleTableName`}
    component={TreeListPage}
  />,
  <PrivateRoute exact path={`/${MENU}/:id`} component={MenuPage} />,
  <PrivateRoute exact path={`/${WMS}`} component={MenuPage} />,
  <PrivateRoute exact path={`/${WMS}/:id`} component={WMSPage} />,
  <PrivateRoute exact path={`/${FILE_MANAGER}`} component={FileManagerPage} />,
  <PrivateRoute exact path={`/oa`} component={Automation} />,
  <PrivateRoute exact path={`/mail`} component={Automation} />,
  <PrivateRoute exact path={`/${CHAT}`} component={ChatPage} />,
  <PrivateRoute
    exact
    path={`/${VISITOR_CALENDAR}`}
    component={VisitorCalendarPage}
  />,
  <PrivateRoute exact path={`/${PIVOT_TABLE}/:id`} component={PivotTable} />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName/create`}
    component={CreateEditRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName/:id/show`}
    component={ShowRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName/:id`}
    component={CreateEditRecordPage}
  />,
  <PrivateRoute
    exact
    path={`/:moduleName/:moduleTableName`}
    component={ListPage}
  />,
  <PrivateRoute
    exact
    path={`/permissions`}
    component={Permissions}
    />,
  <PrivateRoute
    exact
    path={`/permissions`}
    component={Permissions}
  />,
   <PrivateRoute
    exact
    path={`/dashboardList`}
    component={ListDashboard}
  />,
  <PrivateRoute exact path={'/customcss/'} component={CustomCssController} />,
  <PrivateRoute exact path={'/showpermissions'} component={ShowPermissions} />,
  <PrivateRoute exact path={'/rangepicker'} component={RangePicker} />,
  <PrivateRoute exact path={'/advancepermission'} component={ShowPermissionsAdvance} />,


];

export default list;
