import { actorDispatch } from '../../../type/actor-setup';
import SearchInfoView from './search-info.view';

import { type FC } from 'react';
import type { SearchInfoControllerInterface } from './search-info.type';

const SearchInfoController: FC<SearchInfoControllerInterface> = props => {
  const { selectedUserInfo } = props;

  /**
   * @function handleSearchUser
   * @returns { void } void
   */
  const handleSearchUser = (): void => {
    actorDispatch('chatSearchDetails', {
      selectedUser: selectedUserInfo,
      searchModeInChat: 'oneByOne',
    });
  };

  return <SearchInfoView handleSearchUser={handleSearchUser} />;
};

export default SearchInfoController;
