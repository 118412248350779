import { makeStyles } from '@material-ui/core/styles';
import type { CustomTheme } from '../../../core/themeProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  pinMessageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    height: '40px',
    width: '100%',
    padding: '1rem 3rem',
    backgroundColor: theme.palette.grey[100],
  },

  pinMessageTitle: {
    color: theme.palette.primary.blueTextColor,
    fontWeight: 'bold',
  },

  pinMessageTextContainer: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },

  pinMessageText: {
    color: theme.palette.text.secondary,
  },
}));
export default useStyles;
