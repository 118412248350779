import {
  memo,
  useEffect,
  useRef,
  useState,
  type ReactElement,
  type ChangeEvent,
  type MutableRefObject,
} from 'react';
import { GET_LIST } from 'react-admin';

import PermissionAssignedView from './permission-assigned.view';
import { PERMISSION_SECTIONS_REPORT_ID } from '../permissions.helper';
import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../type/actor-setup';
import { clone } from '../../../helper/data-helper';
import { preparePermissionTreeData } from './permission-assigned.helper';

import { PermissionAssignType, type RawTreeData } from './permission-assigned.type';
import type {
  AssignedTableData,
  CompatibleTreeData,
} from './permission-assigned.type';
import { replaceArabicCharacters } from '../../../helper/TextHelper';

const PermissionAssignedController = (props): ReactElement => {
  const { className } = props;

  const [permissionAssignType, setPermissionAssignType] =
    useState<PermissionAssignType>(PermissionAssignType.User);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchedData, setSearchedData] = useState<
    AssignedTableData[] | CompatibleTreeData[]
  >([]);

  const fullData = useRef<AssignedTableData[] | CompatibleTreeData[]>([]);
  const searchInputRef = useRef<HTMLInputElement>();
  const scopeAccessSearchedDataRef = useRef<
    AssignedTableData[] | CompatibleTreeData[]
  >([]);

  const isTreeView = permissionAssignType === PermissionAssignType.Chart;

  useEffect(() => {
    actorSetActionValue('userPermissionValue', selectedIds, {
      path: 'selectedAssignees',
      replaceAll: true,
    });
  }, [selectedIds]);

  useEffect(() => {
    scopeAccessSearchedDataRef.current = searchedData;
  }, [searchedData]);

  useEffect(() => {
    setSelectedIds([]);
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
    }
    getReportData(permissionAssignType);
  }, [permissionAssignType]);

  useEffect(() => {
    const id = actorOnDispatch('userPermissionValue', newValues => {
      setPermissionAssignType(newValues.selectedAssignType);
    });

    return () => {
      actorRemoveAction({
        actionName: 'userPermissionValue',
        listenerId: id,
      });
    };
  }, []);

  /**
   * get permissions
   * @function getReportData
   * @param {number} PermAssignType
   * @returns {void}
   */
  const getReportData = (PermAssignType: PermissionAssignType): void => {
    setIsLoading(true);

    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        resource: `report/${PERMISSION_SECTIONS_REPORT_ID}`,
        requestParameters: {
          pagination: { page: 1, perPage: 999999 },
          filter: [['PermAssignType', 'equal', PermAssignType]],
        },
        onSuccess: (response: {
          data: AssignedTableData[] | RawTreeData[];
        }): void => {
          if (isTreeView) {
            const treeData = preparePermissionTreeData(
              response.data as RawTreeData[],
            );

            fullData.current = treeData;
            setIsLoading(false);
            setSearchedData(treeData);
          } else {
            fullData.current = response.data as AssignedTableData[];
            setIsLoading(false);
            setSearchedData(response.data as AssignedTableData[]);
          }
        },
        onFailure: (error: unknown): void => {
          console.log('error: ', error);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'PermissionAssignedController => getReportData',
      },
    );
  };

  /**
   * handle locally search
   * @function onSearch
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void}
   */
  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    try {
      const inputText = event.target.value;

      const filteredValue = fullData.current.filter(item => {
        return (
          replaceArabicCharacters(item.title)?.includes(
            replaceArabicCharacters(inputText),
          ) || String(item.id).includes(String(inputText))
        );
      });

      setSearchedData(filteredValue);
    } catch {
      console.error('onSearch failed');
    }
  };

  /**
   * compute and set selected ids in state
   * @function onSelectItem
   * @param {number} selectedRowId
   * @returns {void}
   */
  const onSelectItem = (selectedRowId: number): void => {
    if (selectedIds.includes(selectedRowId)) {
      setSelectedIds(prevIds => prevIds.filter(id => id !== selectedRowId));
    } else {
      setSelectedIds(prevIds => [...prevIds, selectedRowId]);
    }
  };

  /**
   * add ill ids to selected ids state
   * @function handleSelectAllClick
   * @returns {void}
   */
  const handleSelectAllClick = (): void => {
    if (
      scopeAccessSearchedDataRef.current.some(row => !selectedIds.includes(row.id))
    ) {
      const newValues: number[] = clone(selectedIds);

      for (const item of scopeAccessSearchedDataRef.current) {
        if (!newValues.includes(item.id)) {
          newValues.push(item.id);
        }
      }

      setSelectedIds(newValues);
    } else {
      setSelectedIds(prevValues => {
        return prevValues.filter(
          item =>
            !scopeAccessSearchedDataRef.current.map(item => item.id).includes(item),
        );
      });
    }
  };

  return (
    <PermissionAssignedView
      data={searchedData}
      onSearch={onSearch}
      treeView={isTreeView}
      onSelectItem={onSelectItem}
      selectedIds={selectedIds}
      handleSelectAllClick={handleSelectAllClick}
      searchInputRef={searchInputRef as MutableRefObject<HTMLInputElement>}
      isLoading={isLoading}
      className={className}
    />
  );
};

export default memo(PermissionAssignedController);
