export const getTasksReportUrl = 'report/77c9235b-bc16-4425-adeb-133c0bc50dc4';
export const createOrEditTaskServiceId = '4774ad34-fc24-4b88-a810-7aca96462d71';
export const deleteTaskServiceId = 'a4c0838c-fbb2-4d17-88dd-b42e803007b0';

export const todoStaticNewFolderFields: Record<string, unknown>[] = [
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 200,
    allowSort: true,
    hasValidationActions: false,
    comment: 'عنوان لیست',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'string',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 167,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 62528,
    caption: 'عنوان لیست',
    translatedCaption: {
      fa: 'عنوان لیست',
      en: 'FoldersTitle',
      ar: 'FoldersTitle',
    },
    translatedComment: {
      fa: 'عنوان لیست',
      en: 'عنوان لیست',
      ar: 'عنوان لیست',
    },
    relatedName: 'FoldersTitle',
    linkName: null,
    name: 'FoldersTitle',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: 100,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },

  {
    caption: 'لیست والد',
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    javaScriptFormula: '',
    dropdown: {
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'folders_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'Folders_ID',
            en: 'Folders_ID',
            ar: 'Folders_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 1,
          title: 'folderstitle',
          format: null,
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'FoldersTitle',
            en: 'FoldersTitle',
            ar: 'العنوان',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'issystemfolder',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'IsSystemFolder',
            en: 'issystemfolder',
            ar: 'issystemfolder',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 5,
          title: 'levelid',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'LevelID',
            en: 'levelid',
            ar: 'levelid',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 6,
          title: 'isdeleted',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'حذف شده',
            en: 'IsDeleted',
            ar: 'IsDeleted',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'parentfolders_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'ParentFolders_ID',
            en: 'ParentFolders_ID',
            ar: 'ParentFolders_ID',
          },
        },
        {
          isHidden: false,
          width: 100,
          priority: 8,
          title: 'parentfolders_id',
          format: null,
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'ParentFolders_ID',
            en: 'ParentFolders_ID',
            ar: 'ParentFolders_ID',
          },
        },
      ],
      displayMember: 'folderstitle',
      displayMember2: null,
      forceTreeLevel: false,
      id: '5456',
      moduleName: 'task',
      parameters: [
        {
          from: 'isfortask',
          to: 'isfortask',
          defaultValue: true,
        },
      ],
      remoteSearch: false,
      translatedComment: null,
      translatedTitle: {
        fa: 'لیست والد',
        en: 'ParentFolders_ID',
        ar: 'ParentFolders_ID',
      },
      type: 'Simple',
      uniqueId: 'f771c530-2d96-4702-bebe-6652570a0f4f',
      valueMember: 'folders_id',
      name: 'folders_id',
      title: 'folderstitle',
    },
    hidden: false,
    id: 0,
    name: 'ParentFolders_ID',
    translatedCaption: {
      fa: 'لیست والد',
      en: 'ParentFolders_ID',
      ar: 'ParentFolders_ID',
    },
    relatedName: '__ParentFolders_ID_value',
    required: false,
    values: null,
    resource: '',
  },
];

export const todoStaticShareFormFields: Record<string, unknown>[] = [
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 500,
    allowSort: true,
    hasValidationActions: false,
    comment: 'اشخاص',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'stringMultiSelectDropBase',
      sql: 'nvarchar(max)',
      simple: 'string',
      id: 194,
      defaultOperator: 'Contains',
    },
    disabled: false,
    defaultValue: null,
    parentField: null,
    moduleName: 'automation',
    id: 63957,
    caption: 'اشخاص',
    translatedCaption: {
      fa: 'اشخاص',
      en: 'ToPersonInfo_ID',
      ar: 'ToPersonInfo_ID',
    },
    translatedComment: {
      fa: 'اشخاص',
      en: 'اشخاص',
      ar: 'اشخاص',
    },
    relatedName: 'ShareWithPersonInfo_IDs',
    linkName: 'ShareWithPersonInfo_IDs',
    name: 'ShareWithPersonInfo_IDs',
    required: false,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: {
      id: 6497,
      uniqueId: '746ac586-51e9-4bba-813d-9f5c2501743a',
      comment: '',
      displayMember: 'fullname',
      displayMember2: null,
      valueMember: 'personinfo_id',
      moduleName: 'person',
      tableName: 'personinfo',
      type: 'SearchDialog',
      title: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      translatedTitle: {
        fa: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        en: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
        ar: 'اشخاص- نام خانوادگي و نام(ليست فعال)_محدود به شعبه',
      },
      translatedComment: null,
      treeLevel: 0,
      forceTreeLevel: false,
      remoteSearch: true,
      parameters: [
        {
          from: 'departmentinfo_id',
          to: 'departmentinfo_id',
          defaultValue: null,
          moduleName: 'automation',
          moduleTableName: 'messages',
        },
      ],
      maps: [],
      columns: [],
      table: null,
    },
    report: null,
    minValue: null,
    maxValue: null,
    values: null,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
  },
];
