import FileSystemItem from 'devextreme/file_management/file_system_item';
import { FileManagerDataType } from '.';
import { API_URL, getValue } from '../../core/configProvider';

export const reportId = `43d926b0-a6e6-458f-b4ce-33c484894991`;
export const createDirectoryId = '3a20742d-a896-4adc-b9bd-3856647089c5';
export const copyDirectoryId = '3f55401b-8c9e-4685-89a3-4bfce210b438';
export const copyFileId = '443be3c4-6d6a-4557-9536-ff9eac76b3d5';
export const moveItemId = 'f74fe4d7-2fc2-40a0-bf29-d91f534e8636';
export const renameDirectoryId = '2a962757-da5b-4b71-92f0-7fdf793f2ce8';
export const deleteDirectoryId = '8c3f2fa1-228b-4e65-8dc9-cbaef1973404';
export const uploadStreamResource = 'archive/archivefile/filestream/fileaddress';
export const uploadFileChunkResource = 'archive/archivefile';
export const archiveQuickCreateUrl = '#/archive/mainarchive/create';

/**
 * generate custom icons for specific file types
 * @function customizeIcon
 * @param { FileSystemItem } fileSystemItem
 * @returns { string }
 */
export const customizeIcon = (fileSystemItem: FileSystemItem): string => {
  if (fileSystemItem.isDirectory) {
    return 'folder';
  }

  const getFileExtension = fileSystemItem.getFileExtension();
  switch (getFileExtension) {
    case '.txt':
      return 'txtfile';
    case '.doc':
      return 'docfile';
    case '.docx':
      return 'docxfile';
    case '.pdf':
      return 'pdffile';
    case '.ppt':
      return 'pptfile';
    case '.pptx':
      return 'pptxfile';
    case '.rtf':
      return 'rtffile';
    case '.xls':
      return 'xlsfile';
    case '.xlsx':
      return 'xlsxfile';
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
      return 'image';
    default:
      return 'file';
  }
};

/**
 * @function findType
 * @param { FileSystemItem } data
 * @returns { FileManagerDataType }
 */
export const findType = (data: FileSystemItem): FileManagerDataType => {
  if (data?.dataItem?.['doctype_id'] == 1) {
    return 'archive';
  } else if (data?.dataItem?.['doctype_id'] == 2) {
    return `folder`;
  } else return 'file';
};

/**
 * @function createFileLink
 * @param { string } fileAddress
 * @returns { string }
 */
export const createFileLink = (fileAddress: string): string => {
  const apiUrl = getValue(API_URL);
  const filePath = JSON.parse(fileAddress)?.['filePath'];

  return `${apiUrl}/${filePath}`;
};
