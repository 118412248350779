import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 10,
    margin: 5,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    margin: '10px 0px',
  },
  title: {
    textAlign: 'start',
    width: '100%',
    padding: '0px 10px',
  },
  treeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: '0px 10px',
    overflow: 'auto',
  },
  tree: {
    flex: 1,
  },
  skeletonContainer: {
    width: '100%',
  },

  skeleton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemTree: {
    alignItems: 'center',
  },
  labelTree: {
    marginLeft: 2,
    marginRight: 2,
  },
  advanceText: {
    fontWeight: 700,
    fontSize: 12,
  },
}));
