import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';
import { ValidationErrorMessageType } from '../../../../helper/Types';
import { getColorBaseOfStatus } from '../../../input-base/input-message/input-message.helper';

export const useStyles = makeStyles<
  CustomTheme,
  { messageType: ValidationErrorMessageType | undefined }
>(theme => ({
  '@global': {
    '.MuiOutlinedInput-adornedEnd': {
      padding: '0',
    },
  },

  root: {
    flexGrow: 1,
    margin: 0,
    height: '100%',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,

    '& legend': {
      display: 'none',
    },
  },

  notchedOutline: {
    top: 0,
    borderColor: props => getColorBaseOfStatus(props.messageType, theme),
  },

  outlineRoot: {
    height: '100%',
  },

  input: {
    zIndex: 1,
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
    },
  },

  wmsInput: {
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    [theme.breakpoints.down('md')]: {
      '& label': {
        transform: 'scale(1) translate(14px, 12px)',
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      '& input': {
        lineHeight: 0,
      },
      '& label': {
        transform: 'scale(1) translate(14px, 10px)',
        fontSize: 10,
      },
      '& div': {
        fontSize: 10,
      },
    },
  },

  disabled: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.disabledFieldColor,
  },

  inputStylePuzzleForm: {
    margin: '7px 3px 0',
    '&[data-disabled-for-style=true]': {
      backgroundColor: theme.palette.grey[300],
    },
    height: 'auto',
  },
}));
