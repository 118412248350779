import { type FC, useMemo, useState } from 'react';
import { useCallback, useRef } from 'react';
import { arrayMove } from 'react-sortable-hoc';
import lodashDebounce from 'lodash/debounce';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import lodashFindLastIndex from 'lodash/findLastIndex';

import { actorGetActionValue } from '../../type/actor-setup';
import { getAppSettings } from '../../helper/settings-helper';
import { isEmpty } from '../../helper/data-helper';

import SortableView from './sortable.view';

import type { SortableControllerType } from './sortable-list.type';
import type { DynamicData } from './sortable-list.type';
import { getSubPanelSortKeySetting } from '../relation-panel/relation-panel-sort.helper';

const SortableController: FC<SortableControllerType> = props => {
  const { closeDialogHandler, dialogData } = props;
  const { data, resource, ViewButtonDialog, title } = dialogData;

  const translate = useTranslate();
  const [searchColumnWord, setSearchColumnWord] = useState<string>('');
  const [dynamicData, setDynamicData] = useState<DynamicData[]>(data);
  const checkedFieldsRef = useRef<DynamicData[]>([]);
  const checkedFieldsInSearchRef = useRef<DynamicData[]>([]);

  /**
   * onSortEnd
   * @param { number } oldIndex
   * @param {  number  } newIndex
   * @returns {void} void
   */
  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }): void => {
    setDynamicData(prevFieldList => {
      const newFieldList: DynamicData[] = arrayMove(
        prevFieldList,
        oldIndex,
        newIndex,
      );

      const lastChecked = lodashFindLastIndex(prevFieldList, {
        isChecked: true,
      });
      if (lastChecked && lastChecked >= newIndex) {
        for (let i = 0; i <= lastChecked; i++) {
          newFieldList[i].isChecked = true;
        }
      }

      if (isEmpty(searchColumnWord)) {
        checkedFieldsRef.current = newFieldList.filter(item => item.isChecked);
      } else {
        checkedFieldsInSearchRef.current = newFieldList.filter(
          item => item.isChecked,
        );
      }

      return newFieldList;
    });
  };

  const sortKeyInConfig = getSubPanelSortKeySetting(resource ?? '');
  const userSelected = getAppSettings<string[]>(sortKeyInConfig, true).value ?? [];

  /**
   * handleChangeAll
   * @param { boolean } isChecked
   * @param { DynamicData[] } finalFieldList
   * @returns {void} void
   */
  const handleChangeAll = useCallback(
    (isChecked: boolean, finalFieldList: DynamicData[]): void => {
      if (isEmpty(searchColumnWord)) {
        const updatedFieldList = dynamicData.map(item => ({
          ...item,
          isChecked,
        }));

        setDynamicData(updatedFieldList);

        if (isChecked) {
          checkedFieldsRef.current = updatedFieldList;
        } else {
          checkedFieldsRef.current = [];
        }
      } else {
        finalFieldList.forEach(item => {
          item.isChecked = isChecked;
        });

        if (!isChecked) {
          checkedFieldsRef.current = checkedFieldsRef.current.filter(
            item => !finalFieldList.some(finalItem => finalItem.id === item.id),
          );
        } else {
          checkedFieldsRef.current.push(...finalFieldList);
        }

        setDynamicData(Array.from(new Set([...dynamicData, ...finalFieldList])));
      }
    },
    [dynamicData, searchColumnWord],
  );
  useMemo(() => {
    if (userSelected.length > 0) {
      for (const selectedItem of userSelected) {
        const targeIndex = dynamicData.findIndex(
          field => field.id === selectedItem || field.id === selectedItem,
        );

        if (targeIndex > -1) {
          checkedFieldsRef.current.push(dynamicData[targeIndex]);
        }
      }
    }
  }, []);

  /**
   * changeHandler
   * @param { DynamicData } field
   * @param {  target: { checked: boolean }  } event
   * @returns {void} void
   */
  const changeHandler =
    (field: DynamicData) =>
    (event: { target: { checked: boolean } }): void => {
      const fieldIndex = dynamicData.findIndex(item => item.id === field.id);
      dynamicData[fieldIndex].isChecked = event.target.checked;

      checkedFieldsRef.current = dynamicData.filter(item => item && item.isChecked);
      const notCheckedFields = dynamicData.filter(item => item && !item.isChecked);

      setDynamicData([...checkedFieldsRef.current, ...notCheckedFields]);

      const lastScrollPosition =
        document.getElementById('dialog_content')?.scrollTop ?? 0;

      requestAnimationFrame(() => {
        document
          .getElementById('dialog_content')
          ?.scrollTo({ top: lastScrollPosition });
      });
    };

  /**
   * finalFieldList
   * @returns {DynamicData[]} Field[]
   */
  const finalFieldList = useMemo((): DynamicData[] => {
    const hiddenFields = actorGetActionValue('gridHiddenFields')?.[resource ?? ''];

    if (isEmpty(searchColumnWord)) {
      if (!hiddenFields) return dynamicData;

      return dynamicData.filter(field => !hiddenFields.includes(field.id));
    }

    const filteredFieldList = dynamicData.filter(fieldElement => {
      if (!fieldElement) {
        return false;
      }
      const title = lodashGet(fieldElement, 'title');
      if (!title) {
        return false;
      }

      const isMatch1 = title.toLowerCase().includes(searchColumnWord.toLowerCase());
      return isMatch1;
    });

    checkedFieldsInSearchRef.current = filteredFieldList.filter(
      item => item.isChecked,
    );

    if (!hiddenFields) return filteredFieldList;

    return filteredFieldList.filter(field => !hiddenFields.includes(field.id));
  }, [dynamicData, searchColumnWord]);

  /**
   * handleChangeSearch
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns {void} void
   */
  const handleChangeSearch = lodashDebounce(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchColumnWord(event.target.value);
    },
    700,
  );

  return (
    <SortableView
      checkedFieldsInSearchRef={checkedFieldsInSearchRef}
      handleChangeSearch={handleChangeSearch}
      searchColumnWord={searchColumnWord}
      checkedFieldsRef={checkedFieldsRef}
      finalFieldList={finalFieldList}
      dynamicData={dynamicData}
      translate={translate}
      onSortEnd={onSortEnd}
      changeHandler={changeHandler}
      handleChangeAll={handleChangeAll}
      closeDialogHandler={closeDialogHandler}
      ViewButtonDialog={ViewButtonDialog}
      title={title ?? ''}
    />
  );
};

export default SortableController;
