import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, { isRtl: boolean }>(
  (theme: CustomTheme) => ({
    RangePickerInputViewContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
    },
    inputContainer: {
      display: 'flex',
      flexDirection: props => (props.isRtl ? 'row-reverse' : 'row'),
    },
    textField: {
      flex: 1,
      width: 150,
    },
  }),
);
