import { PermissionAssignType } from '../../permissions/permission-assigned/permission-assigned.type';
import { SelectedRowData } from '../permission-show-reason-dialog/permission-show-reason-dialog.type';

import {
  actorGetActionValue,
  type UserPermissionValueInterface,
} from '../../../type/actor-setup';
import {
  DevExpressCellData,
  SelectedItemForPermissionAdvance,
} from '../../show-permissions/simple-grid';

/**
 * handle permission click
 * @function setSelectedPermissions
 * @param {UserPermissionValueInterface} permissionsInActor
 * @param {Record<string, unknown>} dialogData
 * @returns {selectedPermissionsInterface} selectedPermissionsInterface
 */
export const setSelectedPermissions = (
  permissionsInActor: UserPermissionValueInterface | null,
  dialogData: {
    permissionKey?: string;
    isAdvanced?: boolean;
    selectedItemForPermission?: SelectedItemForPermissionAdvance;
    cellData?: DevExpressCellData;
  },
  permDescs: {
    [cellName: string]: string;
  },
  permKeyForServer?: string[],
): SelectedRowData => {
  const titleDialog = actorGetActionValue('userPermissionValue')?.selectedName ?? '';

  const {
    selectedAssignType = PermissionAssignType.User,
    selectedBranches = [],
    selectedAssignees = [],
  } = permissionsInActor ?? {};

  const permKey = dialogData?.permissionKey ? [dialogData.permissionKey] : [];

  const selectedPermissions = {
    AttributionType: selectedAssignType,
    AttributionIds: selectedAssignees,
    PermKeys: dialogData.isAdvanced ? Object.keys(permDescs) : permKey,
    Departments: selectedBranches,
    PermDescs: dialogData.isAdvanced
      ? permDescs
      : {
          [dialogData?.permissionKey as string]: titleDialog,
        },
  };

  return selectedPermissions;
};
