import momentJalaali from 'moment-jalaali';

import type { ReactElement } from 'react';

/**
 * @function formatTextBreakLines
 * @param { string } string
 * @returns { ReactElement[]  }
 */
export const formatTextBreakLines = (string: string): ReactElement => {
  return <span dangerouslySetInnerHTML={{ __html: string }} />;
};

/**
 * @function highlightMessageAndScrollTo
 * @param { number } messageId
 * @returns { void }
 */
export const highlightMessageAndScrollTo = (
  messageId: number,
): HTMLElement | null => {
  const targetMessageElement = document.getElementById(`messageId-${messageId}`);

  if (!targetMessageElement) return null;

  if (targetMessageElement.classList.contains('chat-message-highlight')) {
    targetMessageElement.classList.remove('chat-message-highlight');
  }

  requestAnimationFrame(() => {
    targetMessageElement.classList.add('chat-message-highlight');
    targetMessageElement.scrollIntoView({ behavior: 'smooth' });
  });

  targetMessageElement.addEventListener(
    'animationend',
    function () {
      this.classList.remove('chat-message-highlight');
    },
    { once: true },
  );

  return targetMessageElement;
};

/**
 * it checks if date is equal to today then just shows time
 * else it shows date-time
 * @function createDateAndTimeForChatMessage
 * @param { string } date
 * @returns { string }
 */
export const createDateAndTimeForChatMessage = (
  date: string,
  locale = 'fa',
): string => {
  momentJalaali.locale(locale);

  let _date = date;
  if (_date === 'now') {
    _date = momentJalaali().format('YYYY-MM-DD HH:mm');
  }

  const startOfToday = momentJalaali().startOf('day');
  const startOfDate = momentJalaali(_date).startOf('day');
  const daysDiff = startOfDate.diff(startOfToday, 'days');

  // its Today
  if (Math.abs(daysDiff) === 0) {
    return momentJalaali(_date).format('HH:mm');
  } else {
    {
      if (locale === 'fa') {
        return momentJalaali(_date).format('HH:mm,  jYY-jMM-jDD');
      }

      return momentJalaali(_date).format('YYYY-MM-DD HH:mm');
    }
  }
};
