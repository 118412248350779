import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    background: grey[100],
    padding: '5px 10px',
    justifyContent: 'end',
    borderTop: `1px solid ${grey[200]}`,
  },
  applyPermissionButton: {
    marginRight: 10,
  },
}));
