import { memo, useEffect, useState, type FC } from 'react';
import { useHistory } from 'react-router-dom';

import NavigationContainerView from './navigation-container.view';
import { getAppSettings } from '../../../helper/settings-helper';
import { CARTABLE_COLLAPSE_STATE } from '../../../core/configProvider';
import { actorOnDispatch } from '../../../type/actor-setup';
import { navCollapsingChangeHandler } from './navigation-container.helper';
import { isEmptyObject } from '../../../helper/data-helper';

import type { NavigationContainerInterface } from './navigation-container.type';
import type { CartableCollapsingStateInSettingsType } from '../../../helper/Types';

const NavigationContainerController: FC<NavigationContainerInterface> = memo(
  props => {
    const { routesConfig, baseUrl, parentComponentName, canDragDrop } = props;
    const history = useHistory();

    const [expandedItemsKeys, setExpandedItemsKeys] =
      useState<CartableCollapsingStateInSettingsType>({
        [parentComponentName]: {
          parents: [],
        },
      });

    useEffect(() => {
      let runningCodeCounter = 0;
      actorOnDispatch('showSettingsLoading', async () => {
        if (runningCodeCounter === 1) return;
        runningCodeCounter++;

        let lastCollapseState =
          getAppSettings<CartableCollapsingStateInSettingsType>(
            CARTABLE_COLLAPSE_STATE,
            true,
          ).value ?? {};

        if (isEmptyObject(lastCollapseState)) {
          return;
        }

        if (isEmptyObject(lastCollapseState![parentComponentName])) {
          lastCollapseState = {
            [parentComponentName]: {
              parents: [],
            },
            ...lastCollapseState,
          };
        }

        setExpandedItemsKeys(lastCollapseState!);
      });
    }, []);

    return (
      <NavigationContainerView
        onCollapseChange={navCollapsingChangeHandler}
        routesConfig={routesConfig}
        baseUrl={baseUrl}
        parentComponentName={parentComponentName}
        canDragDrop={canDragDrop}
        lastExpandedItems={expandedItemsKeys[parentComponentName]}
      />
    );
  },
);

export default NavigationContainerController;
