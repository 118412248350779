import { FC, memo, useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';
import { actorDispatch } from '../../../../type/actor-setup';

import { ConfirmDialog } from '../../../confirm-dialog';
import { OnDeleteFolderParams } from '../../../mail-section';
import { TodoSidebarContextMenuInterface } from './todo-sidebar-context-menu.type';
import TodoSidebarContextMenuView from './todo-sidebar-context-menu.view';

const TodoSidebarContextMenuController: FC<TodoSidebarContextMenuInterface> = memo(
  props => {
    const { actionsHandler, folderId, title } = props;
    const translate = useTranslate();

    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
      useState<boolean>(false);

    /**
     * @function handleDeleteFolder
     * @param { React.MouseEvent<HTMLElement> } event
     * @returns { void }
     */
    const handleDeleteFolder = useCallback(
      (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();
        setIsConfirmDeleteDialogOpen(true);
      },
      [],
    );

    /**
     * @function handleCloseDeleteConfirmDialog
     * @returns { void }
     */
    const handleCloseDeleteConfirmDialog = useCallback((): void => {
      setIsConfirmDeleteDialogOpen(false);
    }, []);

    /**
     * @function successDeleteFolderCallback
     * @returns { void }
     */
    const successDeleteFolderCallback = useCallback((): void => {
      actionsHandler.refreshTodoFolders();
      handleCloseDeleteConfirmDialog();
    }, []);

    /**
     * @function onConfirmDeleteFolder
     * @returns { void }
     */
    const onConfirmDeleteFolder = useCallback((): void => {
      actionsHandler.deleteFolder({
        Folders_ID: folderId,
        successCallback: successDeleteFolderCallback,
      } as OnDeleteFolderParams);
    }, []);

    /**
     * @function onConfirmDeleteFolder
     * @parsm {React.MouseEvent<HTMLElement>}event
     * @returns { void }
     */
    const handleToggleShareForm = useCallback(
      (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();
        folderId &&
          actorDispatch('quickDialog', {
            todoShareDialogIsOpen: true,
            data: {
              folderId: folderId,
            },
          });
      },
      [],
    );

    return (
      <>
        <TodoSidebarContextMenuView
          toggleShareForm={handleToggleShareForm}
          handleDeleteFolder={handleDeleteFolder}
        />
        {isConfirmDeleteDialogOpen && (
          <ConfirmDialog
            open={isConfirmDeleteDialogOpen}
            handleClose={handleCloseDeleteConfirmDialog}
            onConfirm={onConfirmDeleteFolder}
            title={translate('mail.confirmDeleteFolderMessage', {
              folderName: title,
            })}
            content=""
          />
        )}
      </>
    );
  },
);

export default TodoSidebarContextMenuController;
