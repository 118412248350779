import type { ReactElement } from 'react';
import type { MessagesHeaderViewInterface } from './messages-header.type';
import { useStyles } from './messages-header.style';
import Box from '@material-ui/core/Box';
import { UserInfo } from '../chat-sidebar/user-info';
import { AppHeader } from '../../app-container/app-header';
import { SearchInfo } from '../search-info';

const MessagesHeaderView = (props: MessagesHeaderViewInterface): ReactElement => {
  const { selectedUser, openChatInfoDialog } = props;
  const classes = useStyles();

  return (
    <AppHeader className={classes.headerContainer}>
      <Box
        width={1}
        display="flex"
        alignItems="center"
        className={classes.root}
        id="messageHeader"
        onClick={openChatInfoDialog}
      >
        <UserInfo user={selectedUser} showStatus={false} />
      </Box>
      <SearchInfo selectedUserInfo={selectedUser} />
    </AppHeader>
  );
};

export default MessagesHeaderView;
