import type { PermissionTypeStaticData } from './permission-type.type';

export const permissionTypesResource = 'permission-by-menu/permission-types';

export const staticData: PermissionTypeStaticData[] = [
  { title: 'جدول - نمایش', value: '3' },
  { title: 'جدول - ویرایش', value: '2' },
  { title: 'جدول - ایجاد', value: '1' },
  { title: 'جدول - حذف', value: '4' },
  { title: 'جدول - لیست نمایشی', value: '5' },
  { title: 'جدول - خروجی گرفتن', value: '6' },
  { title: 'جدول - نمایش کامل', value: '7' },
  { title: 'جدول - انتقال فرایند', value: '8' },
  { title: 'جدول - نمایش فیلد ها', value: '12' },
  { title: 'جدول - خطوط فرایند', value: '14' },
  { title: 'جدول - ماکرو ها', value: '16' },
  { title: 'امکان مشاهده کلیه ردیف های جدول بدون محدودیت شرط', value: '10' },
  { title: 'امکان مشاهده گزارش', value: '17' },
  { title: 'گزارش - نمایش', value: '18' },
  { title: 'امکان مشاهده سرویس در گزارش', value: '19' },
  { title: 'امکان ویرایش فیلد', value: '13' },
  { title: 'امکان مشاهده مرحله فرآیند', value: '15' },
  { title: 'امکان مشاهده رکوردهای همه کاربران', value: '9' },
  { title: 'امکان مشاهده منو', value: '0' },
  { title: 'امکان مشاهده سرویس', value: '11' },
  { title: 'پرینت ها', value: '20' },
];
