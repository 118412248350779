import { FC, memo, ReactElement } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import { saveAs } from 'save-files';
import {
  MailActions,
  MailInterface,
  OnNewMessageParams,
  OnImportantMailParams,
  OnOpenReferenceFormParams,
  OnStarMailParams,
} from '../../mail-section.type';
import { isEmpty } from '../../../../helper/data-helper';
import { showNotification } from '../../../../helper/general-function-helper';
import { callQuickAccess, openNewTab } from '../../../../helper/QuickAccessHelper';
import { actorDispatch } from '../../../../type/actor-setup';
import {
  cleanSubject,
  defaultMailFormValue,
  getSubjectTranslate,
} from '../../mail-section.helper';

import { AttachedFile, MailDetailBodyInterface } from './mail-detail-body.type';
import MailDetailBodyView from './mail-detail-body.view';
import MultipleMailBodyView from './multiple-mail-body.view';
import momentJalaali from 'moment-jalaali';
import {
  jalaliDateTimeFormat,
  gregorianDateTimeFormat,
} from '../../../../helper/CalendarMetaHelper';
import { API_URL, getValue } from '../../../../core/configProvider';
import { downloadAll } from '../../../dynamic-input/multi-file-stream-input/multi-file-stream-input.helper';

const MailDetailBodyController: FC<MailDetailBodyInterface> = memo(props => {
  const {
    mailActionsHandler,
    selectedMail,
    selectedMailLoading,
    isFromMultipleMailList = false,
    isMainMail,
    onRecallClick,
    onForwardMailClick,
  } = props;
  const translate = useTranslate();

  const apiUrl = getValue(API_URL);

  const locale = useLocale();
  momentJalaali.locale(locale);
  const { toReferenceText, replySubjectText } = getSubjectTranslate(translate);

  /**
   * @function onReferenceMail
   * @returns { void }
   */
  const onReferenceMail = (): void => {
    if (selectedMail) {
      let subject = !isEmpty(selectedMail?.messageparaph)
        ? selectedMail?.messageparaph
        : selectedMail.subject;
      subject = toReferenceText + ' ' + cleanSubject(subject, translate);

      actorDispatch('selectedDoc', {
        doc_id: selectedMail.doc_id,
        doctype_id: selectedMail.doctype_id,
        refrences_id: selectedMail.refrences_id,
      });

      mailActionsHandler(MailActions.onOpenReferenceForm, {
        formData: { ...defaultMailFormValue, LetterParaph: subject },
      } as OnOpenReferenceFormParams);
    }
  };

  /**
   * to reply mail
   * @function onReplyToMail
   * @returns { void }
   */
  const onReplyToMail = (): void => {
    if (selectedMail) {
      let subject = !isEmpty(selectedMail?.messageparaph)
        ? selectedMail?.messageparaph
        : selectedMail.subject;

      subject = replySubjectText + ' ' + cleanSubject(subject, translate);

      const replyFormData = {
        LetterParaph: subject,
        ccinputisactive: false,
        bccinputisactive: false,
        topersoninfo_id: selectedMail.frompersoninfo_id,
        __topersoninfo_id_value: selectedMail.fromperson,
        ccpersoninfo_id: selectedMail.ccpersoninfo_id,
        __ccpersoninfo_id_value: selectedMail.__ccpersoninfo_id_value,
      } as Record<string, unknown>;

      actorDispatch('selectedDoc', {
        doc_id: selectedMail.doc_id,
        doctype_id: selectedMail.doctype_id,
        refrences_id: selectedMail.refrences_id,
      });

      mailActionsHandler(MailActions.onOpenReferenceForm, {
        formData: replyFormData,
        formTitle: translate('mail.reply'),
      } as OnOpenReferenceFormParams);
    }
  };

  /**
   * to reply all mail
   * @function onReplyAllToMail
   * @returns { void }
   */
  const onReplyAllMail = (): void => {
    if (selectedMail) {
      let subject = !isEmpty(selectedMail?.messageparaph)
        ? selectedMail?.messageparaph
        : selectedMail.subject;

      subject = replySubjectText + ' ' + cleanSubject(subject, translate);

      const replyAllFormData = {
        ccinputisactive: false,
        bccinputisactive: false,
        topersoninfo_id: selectedMail.replyall_to_id,
        ccpersoninfo_id: selectedMail.replyall_cc_id,
        __ccpersoninfo_id_value: null,
        __topersoninfo_id_value: null,
        LetterParaph: subject,
      } as Record<string, unknown>;

      actorDispatch('selectedDoc', {
        doc_id: selectedMail.doc_id,
        doctype_id: selectedMail.doctype_id,
        refrences_id: selectedMail.refrences_id,
      });

      mailActionsHandler(MailActions.onOpenReferenceForm, {
        formData: replyAllFormData,
        formTitle: translate('mail.replyAll'),
      } as OnOpenReferenceFormParams);
    }
  };

  /**
   * forward mail
   * @function onForwardMail
   * @returns { void }
   */
  const onForwardMail = (): void => {
    if (selectedMail) {
      const subject = `${translate('mail.send')} : ${
        !isEmpty(selectedMail?.messageparaph)
          ? selectedMail?.messageparaph
          : selectedMail.subject
      }`;

      const date =
        locale === 'fa'
          ? momentJalaali(selectedMail.createdate).format(jalaliDateTimeFormat)
          : momentJalaali(selectedMail.createdate).format(gregorianDateTimeFormat);

      const messageBody = `
      -------- ${translate('mail.forward')} --------<br/>
      ${translate('mail.from')}:  ${selectedMail?.fromcontactinfo} <br/>
      ${translate('mail.to')}:  ${selectedMail?.__topersoninfo_id_value} <br/>
      ${translate('mail.referenceListHeader.date')}:  ${date} <br/><br/><br/>
      ${
        !isEmpty(selectedMail.messagebody)
          ? selectedMail.messagebody
          : selectedMail.messageparaph
      }`;

      const forwardFormData = {
        attachfiles: JSON.parse(selectedMail.attachfiles),
        subject: subject,
        messagebody: messageBody,
      } as Record<string, unknown>;

      actorDispatch('selectedDoc', {
        doc_id: selectedMail.doc_id,
        doctype_id: selectedMail.doctype_id,
        refrences_id: selectedMail.refrences_id,
      });

      mailActionsHandler(MailActions.onOpenNewMessageForm, {
        formData: forwardFormData,
        formTitle: translate('mail.newMessage'),
      } as OnNewMessageParams);
    }
  };

  /**
   * @function onStarClick
   * @param {  MailInterface[] | null } mailsData
   * @param { boolean } isStarred
   * @returns { void }
   */
  const onStarClick = (
    mailsData: MailInterface[] | null,
    isStarred: boolean,
  ): void => {
    mailActionsHandler(MailActions.onStarMail, {
      mailsData,
      isStarred,
    } as OnStarMailParams);
  };

  /**
   * @function onImportantClick
   * @param {  MailInterface[] | null } mailsData
   * @param { boolean } isStarred
   * @returns { void }
   */
  const onImportantClick = (
    mailsData: MailInterface[] | null,
    isImportant: boolean,
  ): void => {
    mailActionsHandler(MailActions.onImportantMail, {
      mailsData,
      isImportant,
    } as OnImportantMailParams);
  };

  /**
   * @function onShowDelegationHandler
   * @returns { Promise<void> }
   */
  const onShowDelegationHandler = async (): Promise<void> => {
    if (selectedMail?.delegationlink) {
      try {
        const url = await callQuickAccess(selectedMail.delegationlink.toString());
        openNewTab(url);
      } catch (error) {
        showNotification(error as string, 'warning');
      }
    }
  };

  /**
   * @function onRecallHandler
   * @returns { void }
   */
  const onRecallHandler = (): void => {
    if (selectedMail) {
      onRecallClick &&
        onRecallClick({
          doctype_id: selectedMail.doctype_id,
          doc_id: selectedMail.doc_id,
          refrences_id: selectedMail.refrences_id,
        } as MailInterface);
    }
  };

  /**
   * It creates a link to download a file with real and clear name
   * @param { AttachedFile } file
   * @returns { (event: React.MouseEvent) => void } function
   */
  const downloadIconClickHandler =
    (file: AttachedFile) => (event: React.MouseEvent) => {
      event.preventDefault();
      saveAs(`${apiUrl}/${file.filePath}`, file.realFileName, {
        autoBom: false,
        cors: false,
      });
    };

  /**
   * It creates a link to download a file with real and clear name
   * @function downloadAllAttachmentsHandler
   * @param { AttachedFile[] } files
   * @returns { (event: React.MouseEvent) => void } function
   */
  const downloadAllAttachmentsHandler =
    (files: AttachedFile[]) => (event: React.MouseEvent) => {
      downloadAll(files, apiUrl);
    };

  /**
   * @function onRecallHandler
   * @returns { void }
   */
  const onForwardMailHandler = (): void => {
    if (selectedMail) {
      onForwardMailClick && onForwardMailClick(selectedMail);
    }
  };

  /**
   * @function getView
   * @returns { ReactElement }
   */
  const getView = (): ReactElement => {
    return isFromMultipleMailList ? (
      <MultipleMailBodyView
        mailActionsHandler={mailActionsHandler}
        selectedMail={selectedMail}
        selectedMailLoading={selectedMailLoading}
        onReplyToMail={onReplyToMail}
        onReplyAllMail={onReplyAllMail}
        onImportantClick={onImportantClick}
        onStarClick={onStarClick}
        onReferenceMail={onReferenceMail}
        isMainMail={isMainMail}
        onShowDelegationHandler={onShowDelegationHandler}
        onRecallHandler={onRecallHandler}
        onForwardMailHandler={onForwardMailHandler}
        apiUrl={apiUrl}
        downloadIconClickHandler={downloadIconClickHandler}
        downloadAllAttachmentsHandler={downloadAllAttachmentsHandler}
      />
    ) : (
      <MailDetailBodyView
        mailActionsHandler={mailActionsHandler}
        selectedMail={selectedMail}
        selectedMailLoading={selectedMailLoading}
        onReplyToMail={onReplyToMail}
        onReplyAllMail={onReplyAllMail}
        onImportantClick={onImportantClick}
        onStarClick={onStarClick}
        onReferenceMail={onReferenceMail}
        onForwardMailHandler={onForwardMailHandler}
      />
    );
  };

  return getView();
});

export default MailDetailBodyController;
