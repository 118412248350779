import { FC } from 'react';
import { useStyles } from './show-image-dialog.style';
import { ShowImageDialogViewProps } from './show-image-dialog.type';
import useWidth from '../useWidth';
import LoadingBox from '../LoadingBox';
import { Dialog, Typography } from '@material-ui/core';

const ShowImageDialogView: FC<ShowImageDialogViewProps> = props => {
  const {
    isLoading,
    isOpenImageDialog,
    imageUrl,
    imageTitle,
    imageInfo,
    handleCloseDialog,
  } = props;

  const classes = useStyles();
  const width = useWidth();

  if (isLoading && isOpenImageDialog) {
    return <LoadingBox absolute />;
  }

  if (!isOpenImageDialog || !imageUrl) {
    return null;
  }

  return (
    <Dialog
      open={isOpenImageDialog}
      onClose={handleCloseDialog}
      maxWidth={width}
      aria-labelledby="show-image-dialog-title"
    >
      <div className={classes.imageContainer}>
        <img className={classes.image} src={imageUrl} alt={imageTitle} />
      </div>
      <div>
        <Typography>{imageInfo}</Typography>
      </div>
    </Dialog>
  );
};

export default ShowImageDialogView;
