import { FC, memo, useEffect, useState } from 'react';
import { complete, undo, userLogout } from 'react-admin';
import { useDispatch } from 'react-redux';

import { hiddenNotifications } from '../../helper/general-function-helper';
import { actorOnDispatch, NotificationInterface } from '../../type/actor-setup';
import NotificationAppView from './notification-app.view';

import type { NotificationAppInterface } from './notification-app.type';

const NotificationAppController: FC<NotificationAppInterface> = memo(props => {
  const { classNameSnackbar, typeSnackbar } = props;
  const dispatch = useDispatch();
  const [notification, setNotification] = useState<NotificationInterface | null>(
    null,
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {
    requestId = '',
    logout = false,
    undoable = false,
    codeNumber,
  } = notification?.options ?? {};

  useEffect(() => {
    actorOnDispatch(
      'notification',
      notify => {
        setNotification(notify);
      },
      {
        preserve: false,
      },
    );
  }, []);

  /**I
   * Change `tooltipOpen` state to false
   * @function handleTooltipOpen
   * @returns {void}
   */
  const handleTooltipClose = (): void => {
    setTooltipOpen(false);
  };

  /**
   * Change `tooltipOpen` state to true
   * @function handleTooltipOpen
   * @returns {void}
   */
  const handleTooltipOpen = (): void => {
    setTooltipOpen(true);
  };

  /**
   * @function isInvalidUser
   * @param { number } codeNumber
   * @returns { boolean }
   */
  const isInvalidUser = (codeNumber?: number): boolean => {
    if (codeNumber) {
      if (codeNumber == 401) {
        return true;
      }
    }
    return false;
  };

  const handleExited = () => {
    window.addEventListener(
      'keyup',
      event => {
        event.stopImmediatePropagation();
      },
      {
        capture: true,
        once: true,
      },
    );

    if ((notification && logout) || isInvalidUser(codeNumber)) {
      dispatch(userLogout());
    }
    if (notification && undoable) {
      dispatch(complete());
    }
    hiddenNotifications();
  };

  /**
   * Copy Request Id to Clipboard
   * @function copyToClipboard
   * @returns {void}
   */
  const copyToClipboard = (): void => {
    navigator.clipboard
      .writeText(requestId)
      .then(() => {
        console.log('copied');
        handleTooltipOpen();
      })
      .catch(() => {
        console.log('failed');
      });
  };

  return (
    <NotificationAppView
      notification={notification}
      handleExited={handleExited}
      handleTooltipClose={handleTooltipClose}
      tooltipOpen={tooltipOpen}
      copyToClipboard={copyToClipboard}
      handleRequestCloseSnackbar={hiddenNotifications}
      typeSnackbar={typeSnackbar}
      classNameSnackbar={classNameSnackbar}
      undoClickSnackbar={dispatch(undo())}
    />
  );
});

export default NotificationAppController;
