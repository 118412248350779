import { MenuItemParams } from './sidebar-menu.type';

/**
 * @function prepareMenu
 * @param menuArray
 * @param {number | null} parentId
 */
export const prepareMenu = (
  menuArray: MenuItemParams[],
  parentId: number | null = null,
): MenuItemParams[] => {
  const result = menuArray.filter(
    menu =>
      menu.parentId === parentId ||
      menu.parentmenu_id === parentId ||
      menu?.['parentMenuId'] === parentId ||
      menu?.['parentMenuId'] === String(parentId),
  );

  for (const i in result) {
    const item = result[i];
    const altTitle =
      item.translatedtitlefa ??
      item?.['translatedTitleFa'] ??
      item.caption ??
      item.displayvalue ??
      item?.['displayValue'];

    let id;
    if (typeof item.menu_id !== 'undefined') {
      id = item.menu_id;
    } else if (typeof item.menuId !== 'undefined') {
      id = item.menuId;
    } else if (typeof item.menu_Id !== 'undefined') {
      id = item.menu_Id;
    } else {
      id = item.id;
    }

    if (!item.title) {
      item.title = altTitle;
    }

    item.children = prepareMenu(menuArray, id);
    item.isOk = true;
  }

  return result;
};
