import { checkJsonStringify } from '../../../../../helper/data-helper';

import type { Translate } from '../../../../../helper/Types';
import { ChatMessageTypeEnum } from '../../../chat-section.helper';
import { ChatTextJsonFormatInterface } from '../../../new-message';

export const getSummeryMessageText = (
  message: string,
  translate: Translate,
): string => {
  // prettier-ignore
  const parseMessageTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(message);
  // It means there isn't a valid json
  if (parseMessageTextResult === false) {
    return message.replaceAll(/<p>|<\/p>/g, '').replaceAll(/<br>/g, ' ');
  }

  let summary = parseMessageTextResult.rawText ?? '';
  switch (parseMessageTextResult.messageType) {
    case ChatMessageTypeEnum.LOCATION:
      summary = summary.replaceAll(/<br>/g, ' ') ?? translate('chat.location');
      break;

    case ChatMessageTypeEnum.ALBUM:
    case ChatMessageTypeEnum.FILE:
    case ChatMessageTypeEnum.VIDEO:
      summary = summary.replaceAll(/<br>/g, ' ') ?? translate('chat.album');
      break;

    case ChatMessageTypeEnum.FORWARD:
      summary = summary.replaceAll(/<br>/g, ' ') ?? translate('chat.forward');
      break;

    case ChatMessageTypeEnum.TEXT:
      summary = summary.replaceAll(/<p>|<\/p>/g, '').replaceAll(/<br>/g, ' ');
      break;

    default:
      summary = message.replaceAll(/<p>|<\/p>/g, '').replaceAll(/<br>/g, ' ');
  }

  return summary;
};
