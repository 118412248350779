/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import lodashFilter from 'lodash/filter';
import lodashDiffBy from 'lodash/differenceBy';
import lodashOrderBy from 'lodash/orderBy';
import moment from 'moment-jalaali';

import { clone, mergeAndClone, isEmptyObject, isEmpty } from './data-helper';
import { COLOR_FIELD, getTypeByField, ICON_FIELD } from './InputHelper';
import { SERVER_DATE_FORMAT } from '../core/configProvider';
import { getDefaultValuesFromApi } from '../component/form/form.helper';
import { logErrorToGraylog } from './data-helper';
import { getRootMetaData } from './ActorHelper';
import { arrayWithAnd } from './general-function-helper';
import { sanitizeInRangeFilters } from '../component/filter-form/filter-form.helper';

const mockField = {
  dataType: { erp: 'string', sql: 'varchar(max)', simple: 'string' },
  disabled: null,
  hidden: null,
  required: null,
  id: null,
};

/**
 * Handle current things for defaultValue.
 * @function handleCurrentThingsDefaultValue
 * @param {string} globalParam
 * @param {string} simpleType
 * @returns {null | string | date obj}
 */
const handleCurrentThingsDefaultValue = (globalParam, simpleType) => {
  const isDateReturn = simpleType === 'date' || simpleType === 'datetime';
  let value = null;

  if (typeof globalParam === 'string') {
    const globalParamLowerCase = globalParam.toLowerCase();
    if (
      globalParamLowerCase.includes('getdate') ||
      globalParamLowerCase.includes('currentdate') ||
      globalParamLowerCase.includes('currentpersiandate')
    ) {
      value = moment().format(SERVER_DATE_FORMAT);
    } else if (globalParamLowerCase.includes('currenttime')) {
      value = moment().format('h:mm:ss');
    } else if (globalParamLowerCase.includes('currentyear')) {
      value = moment().format('YYYY');
    } else if (globalParamLowerCase.includes('currentmonth')) {
      value = moment().format('MM');
    } else if (globalParamLowerCase.includes('currentday')) {
      value = moment().format('DD');
    }
  }

  if (!isEmpty(value)) {
    return isDateReturn ? moment(value) : value;
  } else {
    return value;
  }
};

export async function getFormDefaultValue(
  fields,
  globalParams,
  additionalData,
  isCreateMode = false,
) {
  if (!fields || !fields.length) {
    return null;
  }

  let data = {};

  if (isCreateMode) {
    const {
      resource,
      processUniqueId,
      stringUrlParameters,
      parentResource,
      parentId,
    } = additionalData;

    data = await getDefaultValuesFromApi(resource, {
      processUniqueId,
      parentResource,
      parentId,
      stringUrlParameters,
    }).catch(error => {
      if (typeof error === 'string') {
        throw error;
      }

      if (typeof error?.message === 'string') {
        throw error.message;
      }

      logErrorToGraylog(JSON.stringify(error), {});
    });

    return data;
  }

  const fieldsCount = fields.length;
  for (let index = 0; index < fieldsCount; index++) {
    const currentField = fields[index];
    const name = currentField.name;
    // don't set defaults for process fields, they always must be set from server
    if (name === 'stateid' || name === 'positionid') {
      continue;
    }

    // const erpType = lodashGet(currentField, 'dataType.erp');
    const simpleType = lodashGet(currentField, 'dataType.simple');
    const defaultValue = lodashGet(currentField, 'defaultValue', null);
    const fieldDefaultGlobalParam = lodashGet(
      currentField,
      'defaultValueGlobalParameter',
    );
    const fieldGlobalParam = lodashGet(currentField, 'globalParameter');

    // if (isCreateMode && erpType === 'dropdown' && currentField.dropdown) {
    //   return;
    // }

    if (fieldGlobalParam) {
      const value = handleCurrentThingsDefaultValue(fieldGlobalParam, simpleType);

      data[name] = value
        ? value
        : lodashGet(
            globalParams,
            fieldGlobalParam,
            lodashGet(globalParams, String(fieldGlobalParam).toLowerCase()),
          );
    } else if (defaultValue) {
      const value = handleCurrentThingsDefaultValue(defaultValue, simpleType);

      data[name] = value ? value : defaultValue;
    } else if (fieldDefaultGlobalParam) {
      const value = handleCurrentThingsDefaultValue(
        fieldDefaultGlobalParam,
        simpleType,
      );
      data[name] = value
        ? value
        : lodashGet(
            globalParams,
            fieldDefaultGlobalParam,
            lodashGet(globalParams, String(fieldDefaultGlobalParam).toLowerCase()),
          );
    } else {
      data[name] = defaultValue;
    }

    if (simpleType === 'boolean') {
      data[name] = data[name] ? Boolean(+data[name]) : false;
    }
  }

  return data;
}

export function getTreeParentFieldName(list) {
  if (!list || isEmptyObject(list)) {
    return null;
  }

  if (list.config.treeParentField) {
    return list.fields[list.config.treeParentField].name;
  }

  return null;
}

/**
 * By default, we should first 20 cols to increase page render and remove null/undefined items
 * @param {object} list metaData
 * @param {boolean} filterFirstFive show first five columns?
 * @returns {array} filtered column list
 */
export function getGridColumns(params) {
  const {
    metaData,
    resource,
    filterFirstFive,
    isRelation,
    defaultSelected,
    userSelected,
  } = params;

  if (isEmptyObject(metaData) || !isEmpty(metaData.error)) {
    return [];
  }

  const columns = [];
  // in report grids
  if (metaData.reportId) {
    metaData.columns.forEach((column, index) => {
      if (
        !column.visible ||
        column.relatedName?.toString()?.includes('additionaldata')
      ) {
        return;
      }

      const fieldType = getTypeByField(column);
      const tempColumn = mergeAndClone(mockField, column);

      if (fieldType === COLOR_FIELD || fieldType === ICON_FIELD) {
        columns.push(tempColumn);
        return;
      }
      if (filterFirstFive && index < 20) {
        // show first 20 item
        tempColumn.id = column.relatedName;
        tempColumn.relatedName = `${tempColumn.relatedName}`;
        columns.push(tempColumn);
      } else if (!filterFirstFive) {
        tempColumn.id = column.relatedName;
        tempColumn.relatedName = `${tempColumn.relatedName}`;
        columns.push(tempColumn);
      }
    });
  } else {
    const columnsRelation = isRelation
      ? metaData.dashletGridColumns
      : metaData.gridColumns;

    // We delete invalid columns data by reference from the user settings
    if (Array.isArray(defaultSelected)) {
      // fixme: Object.keys ?!
      Object.keys(defaultSelected).map(id => {
        if (columnsRelation.includes(+id)) {
          delete defaultSelected[id];
        }
      });
    }

    // We delete invalid columns data by reference from the user settings
    if (Array.isArray(userSelected)) {
      // fixme: Object.keys ?!
      Object.keys(userSelected).map(id => {
        if (columnsRelation.includes(+id)) {
          delete userSelected[id];
        }
      });
    }

    // in normal table grids
    columnsRelation.forEach((fieldId, index) => {
      const fieldType = getTypeByField(metaData?.fields[fieldId]);
      if (typeof metaData?.fields[fieldId] === 'undefined') {
        console.log(`fieldId ${fieldId} is not in fields!`);
        columns.push(null);
      }

      if (fieldType === COLOR_FIELD || fieldType === ICON_FIELD) {
        columns.push(metaData.fields[fieldId]);
        return;
      }
      if (filterFirstFive && index < 10) {
        // show first five item
        columns.push(metaData.fields[fieldId]);
      } else if (!filterFirstFive) {
        columns.push(metaData.fields[fieldId]);
      }
    });
  }

  return columns;
}

export function getFields(list) {
  if (!list || isEmptyObject(list)) {
    return null;
  }

  // in report grids
  if (list.reportId) {
    console.log('getFields in report');
    return null;
  }

  const clonedFields = clone(list.fields);

  // in normal table grids
  return clonedFields;
}

export function getSpecialFields(list) {
  if (!list || isEmptyObject(list) || isEmptyObject(list.fields)) {
    return [];
  }

  const fields = clone(list.fields);
  const colorFields = [];

  for (const id in fields) {
    const fieldType = getTypeByField(fields[id]);
    if (fieldType === COLOR_FIELD || fieldType === ICON_FIELD) {
      colorFields.push(fields[id]);
    }
  }

  return colorFields;
}

/**
 * it computes the filters of simple and report grids and return them as an array to grid
 * for render also it should ignore filters that their not exist on metadata fields
 * @function getFilterColumns
 * @param {Object} list metaData
 * @returns {Array} list of filters
 */
export function getFilterColumns(list) {
  if (!list || isEmptyObject(list)) {
    return null;
  }

  // in report grids
  if (list.reportId) {
    const parameters = lodashOrderBy(list.parameters, ['priority'], ['asc']);
    const primaryField = getPrimaryField(getRootMetaData());
    const visibleFields = parameters?.filter(
      parameter => !parameter.isHidden && primaryField?.name != parameter.field.name,
    );

    return visibleFields?.map(parameter => ({
      ...parameter.field,
      defaultOperator: parameter.defaultOperator,
      onlyEqualCondition: parameter.onlyEqualCondition,
      key: parameter.key,
    }));
  }

  // in normal table grids
  return lodashGet(list, 'filterColumns', [])
    .map(fieldId => {
      if (typeof list.fields[fieldId] === 'undefined') {
        console.log(`fieldId ${fieldId} is not in fields!`);
        return null;
      }

      return list.fields[fieldId];
    })
    .filter(id => id);
}

export function getFieldsById(metaData, fieldList = []) {
  const tempFields = [];
  if (isEmptyObject(metaData)) {
    return tempFields;
  }

  const _fieldList = Object.keys(metaData?.fields ?? {});

  if (metaData.reportId) {
    fieldList.forEach(relatedName => {
      const column = lodashFind(metaData.columns, columns => {
        if (String(columns.relatedName) === String(relatedName)) {
          return true;
        }
      });

      if (typeof column !== 'undefined') {
        const tempColumn = mergeAndClone(mockField, column);
        tempColumn.id = column.relatedName;
        tempColumn.relatedName = `${tempColumn.relatedName}`;
        tempFields.push(tempColumn);
      } else {
        console.warn(`fieldName ${relatedName} is not in columns!`);
      }
    });
  } else {
    fieldList.forEach(item => {
      if (typeof metaData.fields?.[item] !== 'undefined') {
        tempFields.push(metaData.fields[item]);
        return;
      }

      /**
       * PAY ATTENTION
       * In some cases in `grid`s,
       * some `field`s does not have `id` and instead of it we will save `name` in its `field` to settings
       * then here we have to find those `name`s in `field`s
       */
      const targetIndex = _fieldList.findIndex(
        option => metaData.fields[option].name === item,
      );
      if (targetIndex > -1) {
        tempFields.push(metaData.fields[targetIndex]);
      } else {
        console.warn(`fieldId ${item} is not in fields!`);
      }
    });
  }

  return tempFields;
}

export function getTitleFieldNames(list) {
  if (!list || !list.captionColumns || !list.captionColumns.length) {
    return [];
  }

  // in normal table grids
  return list.captionColumns.map(fieldId => {
    if (typeof list.fields[fieldId] === 'undefined') {
      console.log(`fieldId ${fieldId} is not in fields!`);
      return null;
    }

    return list.fields[fieldId].name;
  });
}

export function getPermanentFilterFieldNames(list) {
  if (!list) {
    return [];
  }

  const titleFields = getTitleFieldNames(list);

  const reportFilterList = [];
  // if (list.parameters) {
  //   list.parameters.
  // }

  return [...titleFields, ...reportFilterList];
}

export function getTranslatedName(list, locale) {
  if (!list || isEmptyObject(list) || !locale) {
    return null;
  }

  const translatedTitleLocale = lodashGet(
    list,
    ['translatedTitle', locale],
    lodashGet(list, 'title'),
  );

  if (translatedTitleLocale) {
    return translatedTitleLocale;
  }

  // prettier-ignore
  const translatedCaptionLocale = lodashGet(list, ['config', 'translatedCaption', locale], lodashGet(list, ['config', 'caption']));
  if (translatedCaptionLocale) {
    return translatedCaptionLocale;
  }

  return lodashGet(
    list,
    ['config', 'caption'],
    lodashGet(list, ['config', 'title']),
  );
}

export function getShowSummaryColumnList(list) {
  if (!list || !list.quickColumns) {
    return null;
  }

  return list.quickColumns.map(fieldId => {
    if (typeof list.fields[fieldId] === 'undefined') {
      console.log(`fieldId ${fieldId} is not in fields!`);
      return null;
    }

    return list.fields[fieldId];
  });
}

/**
 * extract all fields from meta data
 * @function getAllFieldList
 * @param {Object} list metaData
 * @returns {Array<Object> | null}
 */
export function getAllFieldList(list) {
  if (!list || isEmptyObject(list)) {
    return null;
  }
  const primaryField = getPrimaryField(list);
  const metaDataFields = list.fields ?? {};

  return Object.keys(metaDataFields).map(fieldId => {
    if (typeof metaDataFields[fieldId] === 'undefined') {
      console.log(`fieldId ${fieldId} is not in fields!`);
      return null;
    }

    if (primaryField && fieldId == primaryField.id) {
      metaDataFields[fieldId].disabled = true;
    }

    return metaDataFields[fieldId];
  });
}

/**
 * Return relations list
 * @function getRelationList
 * @param {object} list Metadata
 * @param {object} record Record
 * @returns {undefined | Array} array of relations
 */
export function getRelationList(list, record) {
  if (!list || isEmptyObject(list)) {
    return null;
  }

  return removeHiddenRelations(list, record, list.relations, 'deactiveSubpanels');
}

/**
 * Remove relations which have isHidden:true in current process task
 * @function removeHiddenRelations
 * @param {object} list Metadata
 * @param {object} record Record
 * @param {object[]} relations Relations array
 * @param {string} field the field which should be checked: deactivReports/deactiveSubpanel
 * @returns {Array} array of relations/filteredRelations
 */
export function removeHiddenRelations(list, record, relations, field) {
  const { processuniqueid, positionid, stateid } = record;
  const currentProcess = getProcessInfo(list, processuniqueid);
  const currentTask = getProcessTaskInfo(list, processuniqueid, positionid, stateid);
  const hiddenRelations = findHiddenRelations(currentTask, field);

  if (!currentProcess || !currentTask || !hiddenRelations) {
    return relations;
  }

  return filteredRelations(relations, hiddenRelations, field);
}

/**
 * Returns a list of hidden relations
 * @param {object} task Current Task
 * @param {string} field deactiveReports/deactiveSubpanel
 * @returns {undefined|Array} If there is not a deactiveReports/deactiveSubpanel field returns undefiend, else returns  an array of relations to be removed
 */
export function findHiddenRelations(task, field) {
  if (!task || !task[field] || !task[field].length) {
    return;
  }

  return task[field].filter(item => {
    if (field === 'deactiveReports') {
      item.id = item.reportId;
    }
    if (item.isHidden) {
      return item;
    }
  });
}

/**
 * Remove hidden relations from relations array
 * @param {object[]} relations list of all relations from metadata
 * @param {*} hiddenRelations list of relatiosn which should be removed
 * @returns {Array} An array of filtered relations
 */
export function filteredRelations(relations, hiddenRelations, field) {
  return lodashDiffBy(relations, hiddenRelations, sub => {
    if (field === 'deactiveReports') {
      return sub.id;
    }
    return sub.moduleName && sub.moduleTableName;
  });
}

/**
 * Return report relations list for a record
 * @param {object} list Metadata
 * @param {object} record Record
 */
export function getSingleRecordReportRelationList(list, record) {
  if (!list || isEmptyObject(list)) {
    return null;
  }

  const relationReportList = [];
  if (list.reports?.length) {
    list.reports.forEach(report => {
      if (report.related === 'SingleRecord') {
        relationReportList.push(report);
      }
    });
  }

  return removeHiddenRelations(list, record, relationReportList, 'deactiveReports');
}

export function getReportInfo(list, reportId) {
  if (!list || !list.reports || !list.reports.length || isEmptyObject(list)) {
    return null;
  }

  return lodashFind(list.reports, { id: reportId });
}

export function isModuleTable(list) {
  if (!list || isEmptyObject(list)) {
    return false;
  }

  return !list.reportId;
}

export function hasNote(list) {
  return !!lodashGet(list, ['config', 'hasNote']);
}

export function hasReportEditable(list) {
  return !!lodashGet(list, 'editable');
}

export function getNoteInfo(list) {
  const note = lodashGet(list, ['config', 'hasNote']);
  if (note && note.moduleName && note.moduleTableName) {
    return note;
  }

  return null;
}

/**
 * check meta and return relation list with relationResource
 * @param {Object} list
 * @returns {Object}
 */
export function getRelationsInForm(list, processInfo) {
  if (!list || isEmptyObject(list)) {
    return [];
  }

  const relationList = [];

  const reportRelationList = getSingleRecordReportRelationList(list, {
    processuniqueid: processInfo?.__processuniqueid||processInfo?.processuniqueid,
    positionid: processInfo?.positionid,
    stateid: processInfo?.stateid,
  });  

  const notes = list.config.hasNote?[{moduleTableTitle:list.config.hasNote.moduleTableTitle, id:'notes'}]: []

  // if a process is available, try to find hidden relations and remove them
  if (processInfo && list.processes) {
    const filteredRelationList = removeHiddenRelations(
      list,
      {
        processuniqueid: processInfo.processUniqueId,
        positionid: processInfo.positionId,
        stateid: processInfo.stateid,
      },
      list.relations,
      'deactiveSubpanels',
    );

    if (filteredRelationList && filteredRelationList.length > 0) {
      return [...filteredRelationList, ...reportRelationList, ...notes];
    }
  } else {
    list?.relations?.forEach(relation => {
      relationList.push({
        ...relation,
        relationResource: `${relation.moduleName}/${relation.moduleTableName}`,
      });
    });
  }
  return [...relationList, ...reportRelationList, ...notes];
}

export function getFileInfo(list) {
  const fileInfo = lodashGet(list, ['config', 'hasFile']);

  if (fileInfo && fileInfo.moduleName && fileInfo.moduleTableName) {
    return fileInfo;
  }
  return null;
}

export function getProcessLines(list, processuniqueid, positionid, stateid) {
  if (
    isEmptyObject(list) ||
    !list.processes ||
    !list.processes.length ||
    !processuniqueid ||
    !positionid ||
    !stateid
  ) {
    return null;
  }

  const taskInfo = getProcessTaskInfo(list, processuniqueid, positionid, stateid);
  if (!taskInfo) {
    return [];
  }

  return taskInfo.lines;
}

export function getProcessTaskInfo(list, processuniqueid, positionid, stateid) {
  const processInfo = getProcessInfo(list, processuniqueid);

  if (!processInfo) {
    return null;
  }

  return lodashFind(processInfo.tasks, {
    positionId: parseInt(positionid),
    stateId: parseInt(stateid),
  });
}

export function getProcessInfo(list, processuniqueid) {
  if (
    isEmptyObject(list) ||
    !list ||
    !list.processes ||
    !list.processes.length ||
    !processuniqueid
  ) {
    return null;
  }

  return lodashFind(list.processes, { uniqueId: processuniqueid });
}

export function getServices(list) {
  if (isEmptyObject(list) || !list.actions || !list.actions.length) {
    return null;
  }

  return list.actions;
}

export function getServerValidationFieldList(list) {
  if (!list || isEmptyObject(list)) {
    return null;
  }

  // validationActions
  return Object.keys(list.validationActions).map(fieldId => {
    if (typeof list.fields[fieldId] === 'undefined') {
      console.log(`fieldId ${fieldId} is not in fields!`);
      return null;
    }

    return {
      service: list.validationActions[fieldId],
      field: list.fields[fieldId],
    };
  });
}

export function getAsyncValidationInfoForField(meta, fieldId) {
  if (!meta || !fieldId) {
    return undefined;
  }

  return lodashGet(meta, ['validationActions', fieldId, '0']);
}

export function getOneToOneRelationList(list) {
  if (!list || !list.oneToOneRelations || !list.oneToOneRelations.length) {
    return [];
  }

  const tabList = [];
  list.oneToOneRelations.forEach(oneToOneTab => {
    for (const tab of oneToOneTab['tabPages']) {
      tabList.push({
        ...tab,
        isOneToOne: true,
      });
    }
  });
  return tabList;
}

/**
 * Merge tab data with setting from meta.
 * @param {object} mtaData
 * @param {object | null} processList
 * @param {object} rawTabs
 * @param {array} [tableRelationList]
 * @param {array} [reportRelationList]
 * @param {array} [noteRelation]
 * @returns {TabInterFace[]}
 */
export function mergeTabDataWithSetting(
  metaData,
  rawTabs,
  tableRelationList = [],
  reportRelationList = [],
  noteRelation = [],
) {
  const fields = getFields(metaData);
  const specialFields = getSpecialFields(metaData);

  // work on a copy of original data
  const clonedTabList = clone(rawTabs);

  // prettier-ignore
  for (const tabIndex in clonedTabList) {
    for (const groupIndex in clonedTabList[tabIndex].groupList) {
      // add special fields (icon/color) to first group of first tab
      if(clonedTabList[0] && clonedTabList[0]?.groupList?.length){
        clonedTabList[0].groupList[0].specialFields = [...specialFields];
      }
      for (const layoutRowIndex in lodashGet(clonedTabList, [tabIndex, 'groupList', groupIndex, 'layout'], [])) {
        for (const fieldIndex in clonedTabList[tabIndex].groupList[groupIndex].layout[layoutRowIndex]) {
          const clonedField = clonedTabList[tabIndex].groupList[groupIndex].layout[layoutRowIndex][fieldIndex];

          // if this position has no field in it
          if (!clonedField || clonedField === 'empty') {
            continue;
          }

          const originalField = fields[clonedField.id];

          if (!originalField) {
            console.log(`id ${clonedField.id} not found in original fields`);
            clonedTabList[tabIndex].groupList[groupIndex].layout[layoutRowIndex][fieldIndex] = clonedField === 'empty' ? clonedField : null;
            continue;
          }

          // push the tab title and tab id to each field of each groupList
          clonedTabList[tabIndex].groupList[groupIndex].layout[layoutRowIndex][fieldIndex]['tabTitle'] = clonedTabList[tabIndex]['title'];
          clonedTabList[tabIndex].groupList[groupIndex].layout[layoutRowIndex][fieldIndex]['tabId'] = clonedTabList[tabIndex]['id'];
          clonedTabList[tabIndex].groupList[groupIndex].layout[layoutRowIndex][fieldIndex]['translatedTabTitle'] = clonedTabList[tabIndex]['translatedTitle'];
          clonedTabList[tabIndex].groupList[groupIndex].layout[layoutRowIndex][fieldIndex] = mergeAndClone(originalField, clonedField);
        }
      }
    }

    clonedTabList[0].tableRelationList = tableRelationList;
    clonedTabList[0].reportRelationList = reportRelationList;
    clonedTabList[0].noteRelation = noteRelation;
  }

  return clonedTabList;
}

export function getPrimaryField(list) {
  if (!list || !list.fields || !list.config) {
    return null;
  }

  // config.primaryField contains id of pk
  const pkId = list.config.primaryField;
  return list.fields[pkId];
}

export function isRecordEditable(list, record) {
  if (isEmptyObject(record)) {
    return false;
  }

  if (typeof record.iseditable !== 'undefined' && !record.iseditable) {
    return false;
  }

  const processTaskInfo = getProcessTaskInfo(
    list,
    record.__processuniqueid,
    record.positionid,
    record.stateid,
  );

  return !(processTaskInfo && !processTaskInfo.allowEdit);
}

export function isReportExecutable(list) {
  return !!lodashGet(list, 'executable');
}

export function getGroupingColumns(fieldList) {
  if (!fieldList || !Array.isArray(fieldList)) {
    return [];
  }

  return fieldList
    .filter(field => (field ? !!field.groupingPriority : false))
    .sort((a, b) => a.groupingPriority - b.groupingPriority);
}

export function getDefaultSort(meta) {
  if (!meta || isEmptyObject(meta)) {
    return undefined;
  }

  const { config, reportId, columns, fields, gridColumns } = meta;

  if (reportId) {
    const columnFound = columns.find(column => column.sortType);
    if (columnFound) {
      return {
        field: `${columnFound.relatedName}`,
        order: columnFound.sortType,
      };
    } else if (columns[0]) {
      // because reports must get a sort, give back first column that we have
      return {
        field: null,
        order: null,
      };
    }
  } else if (config && !isEmptyObject(config.sort)) {
    return {
      field: config.sort.fieldName,
      order: config.sort.type,
    };
  } else {
    return {
      field: lodashGet(
        lodashGet(fields, lodashGet(gridColumns, ['0'])),
        'relatedName',
      ),
      order: 'DESC',
    };
  }

  return undefined;
}

export function isSingleRecordTable(list) {
  return !!lodashGet(list, 'config.hasOneRow');
}

export function getIsRowReOrderEnabled(list) {
  if (isEmptyObject(list)) {
    return null;
  }
  return !!lodashGet(list, 'config.sort.allowChangingRowOrder');
}

export function getFieldByName(metaData, fieldName) {
  if (isEmptyObject(metaData)) {
    return null;
  }

  return lodashFind(metaData.fields, { name: fieldName });
}

export function getReportParameterFieldByKey(reportMetaData, fieldName) {
  if (isEmptyObject(reportMetaData)) {
    return null;
  }

  return lodashFind(reportMetaData.parameters, { key: fieldName })?.['field'];
}

export function getDefaultReportSort(meta, reportId) {
  if (!meta && !reportId) {
    return undefined;
  }

  const report = lodashFind(meta.reports, { id: reportId });

  if (report) {
    const columnFound = report.columns.find(column => column.sortType);
    if (columnFound) {
      return {
        field: `${columnFound.relatedName}`,
        order: columnFound.sortType,
      };
    } else if (report.columns[0]) {
      return {
        field: `${report.columns[0].relatedName}`,
        order: 'DESC',
      };
    }
  }

  return undefined;
}

export function prepareReportFilter({
  meta,
  record,
  parentFilters = null,
  formatByAnd = false,
}) {
  let reportFilters = [];
  if (parentFilters) {
    reportFilters = Object.values(parentFilters);
  }

  if (isEmptyObject(meta) || isEmptyObject(record)) {
    return reportFilters.length ? reportFilters : undefined;
  }

  let reportParameters = [];
  for (const parameter of  meta.parameters ?? []) {
    let value = record[parameter.field.name];

    let operator = parameter.defaultOperator.toLowerCase();
    if (parameter.onlyEqualCondition || parameter.defaultOperator === 'empty') {
      operator = 'equal';
    }

    if (operator === 'between' || operator === 'notbetween') {
      reportParameters.push(...sanitizeInRangeFilters({...parameter.field, key: parameter.key}, operator, value));
      continue;
    }

    reportParameters.push([
      parameter.key,
      operator,
      value,
    ]);
  }

  const finalReportFilters = [...reportParameters, ...reportFilters];
  if (formatByAnd) {
    return arrayWithAnd(finalReportFilters);
  }

  return finalReportFilters;
}

export function getRelationPermissionFromProcessTask(
  meta,
  record,
  moduleName,
  moduleTableName,
) {
  if (!meta || !record || !moduleName || !moduleTableName) {
    return null;
  }

  const processTaskInfo = getProcessTaskInfo(
    meta,
    record.__processuniqueid,
    record.positionid,
    record.stateid,
  );

  if (!processTaskInfo) {
    return null;
  }

  return lodashFind(processTaskInfo.deactiveSubpanels, {
    moduleName,
    moduleTableName,
  });
}

// TODO: delete this function . its duplicate of relation helper function
export function isRelationCreateEnabled(meta, record, moduleName, moduleTableName) {
  const relInfo = getRelationPermissionFromProcessTask(
    meta,
    record,
    moduleName,
    moduleTableName,
  );

  if (relInfo) {
    return !relInfo.disableAdd;
  } else {
    return null;
  }
}

export function isRelationEditEnabled(meta, record, moduleName, moduleTableName) {
  const relInfo = getRelationPermissionFromProcessTask(
    meta,
    record,
    moduleName,
    moduleTableName,
  );
  if (relInfo) {
    return !relInfo.disableEdit;
  } else {
    return null;
  }
}

export function isRelationDeleteEnabled(meta, record, moduleName, moduleTableName) {
  const relInfo = getRelationPermissionFromProcessTask(
    meta,
    record,
    moduleName,
    moduleTableName,
  );

  if (relInfo) {
    return !relInfo.disableDelete;
  } else {
    return null;
  }
}

export function getRelationDisabledFields(
  meta,
  record,
  moduleName,
  moduleTableName,
) {
  const relInfo = getRelationPermissionFromProcessTask(
    meta,
    record,
    moduleName,
    moduleTableName,
  );
  if (!relInfo || !relInfo.deActiveFields || !relInfo.deActiveFields.length) {
    return null;
  }

  const result = {};
  for (const fieldId of relInfo.deActiveFields) {
    result[fieldId] = true;
  }

  return result;
}

/**
 * it will return dropdown list from state
 * @function getDropDownListFromState
 * @param {object} field
 * @param {string} type
 * @param {object} state
 * @param {object} metaData
 * @returns {Array | null}
 */
export function getDropDownListFromState(field, type, state, metaData) {
  const fieldName = field[type][0][0];
  const dropId = lodashGet(
    lodashFind(metaData.fields, { name: fieldName }),
    ['dropdown', 'uniqueId'],
    null,
  );
  return lodashGet(state, [`dropdown`, dropId], null);
}

export function fieldFileList(fields) {
  if (!fields || isEmptyObject(fields)) {
    return undefined;
  }

  const list = [];
  Object.values(fields).map(field => {
    if (field.dataType.simple === 'file') {
      list.push(field);
    }
  });

  return list;
}

export function prepareShiftProcess(metaData, uniqueId) {
  if (!metaData) {
    return null;
  }

  const process = getProcessInfo(metaData, uniqueId);
  if (!process || !process.tasks || !process.tasks.length) {
    return null;
  }

  return lodashFilter(process.tasks, 'isTransferable');
}

/**
 * check `allowDelete` in config.
 * @function isTreeHasDelete
 * @param {Object} meta
 * @returns {Boolean}
 */
export function isTreeHasDelete(meta) {
  return lodashGet(meta, ['config', 'allowDelete'], false);
}

/**
 * extract all of fields from a custom group list
 * @param {Object} GroupList
 * @returns {Array} fields
 */
export function extractAllFieldsFromCustomGroupList(GroupList) {
  const fields = [];

  if (GroupList && GroupList.length) {
    GroupList.forEach(group => {
      group.groupList.forEach(groupList => {
        groupList.layout.forEach(layout => {
          layout.forEach(field => {
            if (field) {
              fields.push(field);
            }
          });
        });
      });
    });
  }

  return fields;
}

/**
 * Check add, edit and delete relation permission.
 * @function checkPermission
 * @param {boolean | null} permissionType
 * @param {object} relationMetaData
 * @param {string} relationPermissionType
 * @param {boolean} parentRecordIsEditable
 * @returns {boolean}
 */
export const checkPermission = (
  permissionType,
  relationMetaData,
  relationPermissionType,
  parentRecordIsEditable,
) => {
  if (!parentRecordIsEditable) return false;

  if (permissionType !== null) {
    return permissionType;
  }

  if (!isEmptyObject(relationMetaData)) {
    return lodashGet(relationMetaData, ['config', relationPermissionType]);
  }

  return true;
};

// TODO: delete this function . its duplicate of relation helper function
/**
 * Prepare add, edit, delete and disabled field for relation.
 * @function preparedRelationPermission
 * @param {object} metaData
 * @param {object} record
 * @param {object} relation
 * @param {object} relationMetaData
 * @param {boolean} parentRecordIsEditable
 * @returns {object} permission
 */
export const preparedRelationPermission = (
  metaData,
  record,
  relation,
  relationMetaData,
  parentRecordIsEditable,
) => {
  if (isEmptyObject(relation)) {
    return {
      hasCreate: true,
      hasEdit: true,
      hasDelete: true,
      disabledFieldList: null,
    };
  }

  const createEnabled = isRelationCreateEnabled(
    metaData,
    record,
    relation.moduleName,
    relation.moduleTableName,
  );

  const editEnabled = isRelationEditEnabled(
    metaData,
    record,
    relation.moduleName,
    relation.moduleTableName,
  );

  const deleteEnabled = isRelationDeleteEnabled(
    metaData,
    record,
    relation.moduleName,
    relation.moduleTableName,
  );

  const permission = {
    hasCreate: checkPermission(
      createEnabled,
      relationMetaData,
      'allowAdd',
      parentRecordIsEditable,
    ),
    hasEdit: checkPermission(
      editEnabled,
      relationMetaData,
      'allowEdit',
      parentRecordIsEditable,
    ),
    hasDelete: checkPermission(
      deleteEnabled,
      relationMetaData,
      'allowDelete',
      parentRecordIsEditable,
    ),
    disabledFieldList: getRelationDisabledFields(
      metaData,
      record,
      relation.moduleName,
      relation.moduleTableName,
    ),
  };

  return permission;
};

/**
 * Get name for `parameter` from `field`.
 * @function getParameterName
 * @param {Object} field
 * @returns {String}
 */
export const getParameterName = field => {
  return lodashGet(
    field,
    'relatedParameterName',
    lodashGet(field, 'relatedName', lodashGet(field, 'name')),
  );
};

/**
 * Returns the allowEdit value from the meta
 * @function isMetaEditable
 * @param {object} metaData
 * @returns {boolean}
 */
export const isMetaEditable = metaData => {
  return lodashGet(metaData, ['config', 'allowEdit']);
};

/**
 * Returns the allowEditInGrid value from the meta
 * @function isGridInlineEditable
 * @param {object} metaData
 * @returns {boolean}
 */
export const isGridInlineEditable = metaData => {
  return lodashGet(metaData, ['config', 'allowEditInGrid']);
};
