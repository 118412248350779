import { memo, useRef, useEffect, useState, useImperativeHandle } from 'react';
import type { FC, ChangeEvent } from 'react';

import GridTextInputView from './grid-text-input.view';
import { actorOnDispatch, actorRemoveAction } from '../../../../type/actor-setup';
import { useStyles } from '../../grid.style';

import type { GridTextInputInterface } from './grid-text-input.type';
import { InputAppearanceCharacteristics } from '../../../../helper/meta-helper.type';

const GridTextInputController: FC<GridTextInputInterface> = props => {
  const { field, onChangeInput, customInputRef } = props;
  const classes = useStyles();

  const [internalValue, setInternalValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const internalValueRef = useRef('');

  const [appearanceCharacteristics, setAppearanceCharacteristics] =
    useState<InputAppearanceCharacteristics>();

  const updateInputCharacteristics = (
    value: InputAppearanceCharacteristics,
  ): void => {
    setAppearanceCharacteristics(value);
  };

  useImperativeHandle(customInputRef, () => ({
    updateInputCharacteristics,
  }));

  useEffect(() => {
    const onDispatchId = actorOnDispatch('gridFormData', gridFormData => {
      const value = gridFormData[field.name] ?? '';
      (isFocused || value) && !internalValueRef.current
        ? setInternalValue(value as string)
        : setIsFocused(true);
    });

    return () => {
      actorRemoveAction({
        actionName: 'gridFormData',
        listenerId: onDispatchId,
      });
    };
  }, []);

  /**
   * handle change date
   * @function handleChangeValue
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void } void
   */
  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>): void => {
    setInternalValue(event.target.value);
    internalValueRef.current = event.target.value;
    onChangeInput({
      fieldName: field.name,
      value: internalValue ?? event.target.value,
      submitValue: false,
    });
  };

  /**
   * handle blur
   * @function handleBlur
   * @returns { void } void
   */
  const handleBlur = (): void => {
    if (isFocused) {
      onChangeInput({ fieldName: field.name, value: internalValue });
    }
  };

  /**
   * handle focus
   * @function handleFocus
   * @param { React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element> } event
   * @returns { void } void
   */
  const handleFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ): void => {
    setIsFocused(true);
    event.target.select();
  };

  return (
    <div className={classes.gridInputContainer}>
      <GridTextInputView
        handleChangeValue={handleChangeValue}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        isDisabled={
          appearanceCharacteristics?.enable === false ||
          appearanceCharacteristics?.visible === false
        }
        value={internalValue}
      />
    </div>
  );
};

export default memo(GridTextInputController);
