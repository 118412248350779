import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { LabelActionViewInterface } from './label-action.type';
import { useStyles } from './label-action.style';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import LabelSharpIcon from '@material-ui/icons/LabelSharp';
import Menu from '@material-ui/core/Menu';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { MailLabelType } from '../../../../mail-section.type';
import { AppDialog } from '../../../../../app-dialog';
import { NewLabelForm } from './new-label-form';
import { MailFormAction } from '../../../../mail-form/mail-form-action';

const LabelActionView = (props: LabelActionViewInterface): ReactElement => {
  const {
    onLabelOpen,
    onLabelClose,
    isLabelOpen,
    labels,
    handleChangeLabel,
    handleSubmitLabels,
    isNewLabelDialogOpen,
    handleOpenNewLabelDialog,
    handleCloseNewLabelDialog,
    onCreateNewLabel,
    checkIsSelectedLabel
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <Tooltip title={translate('mail.labels')}>
        <Box
          style={{ cursor: 'pointer' }}
          mt={1}
          mx={2}
          component="span"
          color="text.disabled"
        >
          <LabelSharpIcon onClick={onLabelOpen} />
        </Box>
      </Tooltip>
      <Menu
        anchorEl={isLabelOpen}
        open={Boolean(isLabelOpen)}
        onClose={onLabelClose}
        className={classes.labelsMenuContainer}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <FormGroup className={classes.labelsContainer}>
          {labels?.map((label: MailLabelType) => {
            return (
              <FormControlLabel
                key={label.uniqueid}
                control={
                  <Checkbox
                    checked={checkIsSelectedLabel(label)}
                    onChange={() => handleChangeLabel(label)}
                  />
                }
                label={label.lablestitle}
              />
            );
          })}
        </FormGroup>
        <Divider />

        <Button
          onClick={handleSubmitLabels}
          className={classes.btnRoot}
          size="small"
          variant="outlined"
          color="primary"
        >
          {translate('mail.applyLabel')}
        </Button>
        <Button
          onClick={handleOpenNewLabelDialog}
          className={classes.btnNewLabel}
          size="small"
          variant="text"
          color="secondary"
        >
          {translate('mail.newLabel')}
        </Button>
      </Menu>
      {isNewLabelDialogOpen && (
        <AppDialog
          open={isNewLabelDialogOpen}
          handleClose={handleCloseNewLabelDialog}
          dialogTitle={translate('mail.newLabel')}
          dialogContent={<NewLabelForm />}
          contentClassName={classes.newLabelFormContainer}
          dialogActions={
            <MailFormAction
              onSubmit={onCreateNewLabel}
              onCancel={handleCloseNewLabelDialog}
            />
          }
        />
      )}
    </>
  );
};

export default LabelActionView;
