import { useRef, type FC, ForwardedRef } from 'react';
import { TitleForRecord, useLocale } from 'react-admin';
import classnames from 'classnames';

import { useStyles } from './show-record-with-relation.style';
import ShowActions from '../ShowActions';
import ShowTitle from '../ShowTitle';
import { PinnedNotesController } from '../relation-panel/note-relation/pinned-note';
import { isGadgetList } from '../list/list.helper';

import { RelationList, RelationListCustomFunctions } from './relation-list';
import { RecordShowDetails } from '../record-show-details';
import { TransparentLoadingBox } from '../LoadingBox';

import type { ShowRecordWithRelationViewInterface } from './show-record-with-relation.type';

const ShowRecordWithRelationView: FC<
  ShowRecordWithRelationViewInterface
> = props => {
  const {
    record,
    tabList = [],
    resource,
    isReport,
    metaData,
    defaultTitle,
    relations,
    match,
    hasRelationNote,
    handleChangeSortMode,
    loading,
    customRefresh,
  } = props;

  const classes = useStyles();
  const locale = useLocale();

  const relationLitsRef = useRef<RelationListCustomFunctions>();

  return (
    <>
      <TitleForRecord
        title={<ShowTitle metaData={metaData} />}
        record={record}
        defaultTitle={defaultTitle}
      />

      <ShowActions
        metaData={metaData}
        locale={locale}
        hasEdit={!isReport || isGadgetList(resource)}
        data={record}
        basePath={`/${resource}`}
        resource={resource}
        dashboardUniqueId={record?.uniqueid}
        handleChangeSortMode={handleChangeSortMode}
        customRefresh={customRefresh}
        relations={relations}
      />

      <div className={classnames(classes.viewContainer)}>
        {hasRelationNote && <PinnedNotesController match={match} />}
        <RecordShowDetails
          record={record}
          tabList={tabList}
          resource={resource}
          relationListCustomFunctions={
            relationLitsRef as React.MutableRefObject<RelationListCustomFunctions>
          }
        />
        {
          <RelationList
            relations={relations}
            ref={relationLitsRef as ForwardedRef<RelationListCustomFunctions>}
            parentMetaData={metaData}
          />
        }
        {loading && <TransparentLoadingBox />}
      </div>
    </>
  );
};

export default ShowRecordWithRelationView;
