import { useEffect, useRef, useState } from 'react';

import {
  getGroupUsers,
  prepareSelectedUsersBeforeCreateGroup,
  updateGroup,
} from '../../group-chats.helper';
import ChatInfoDialogView from './chat-info-dialog.view';
import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../../type/actor-setup';
import { EDIT_MODE } from '../add-members-dialog/add-members-dialog.helper';
import { getChats } from '../../chat-section.helper';

import type { ReactElement, MouseEvent } from 'react';
import type { ChatInfoDialogPropsInterface } from './chat-info-dialog.type';
import type { ChatUsersInterface } from '../add-members-dialog/add-members-dialog.type';

const ChatInfoDialogController = (
  props: ChatInfoDialogPropsInterface,
): ReactElement => {
  const { closeDialogHandler, dialogData } = props;
  const { selectedUser } = dialogData;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [groupUsers, setGroupUsers] = useState<ChatUsersInterface[]>([]);
  const groupUsersRef = useRef<ChatUsersInterface[]>([]);

  useEffect(() => {
    if (selectedUser.groupuid && groupUsers.length === 0) {
      getGroupUsers(String(selectedUser.groupuid), handleSuccessGetGroupUsers);
    }

    const id = actorOnDispatch('refreshView', resource => {
      if (resource === 'refreshChatInfoDialog' && selectedUser.groupuid) {
        getGroupUsers(String(selectedUser.groupuid), handleSuccessGetGroupUsers);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: id,
      });
    };
  }, []);

  /**
   * @function handleSuccessGetGroupUsers
   * @param {ChatUsersInterface[]} data
   * @returns {void} void
   */
  const handleSuccessGetGroupUsers = (data: ChatUsersInterface[]) => {
    setGroupUsers(data);
    groupUsersRef.current = data;
  };

  /**
   * @function handleClose
   * @param { MouseEvent<HTMLElement> } event
   * @returns { void } void
   */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  /**
   * @function successFileCallback
   * @param {{filepath: string, realFileName:string}} response
   * @returns
   */
  const successFileCallback = (response: {
    filepath: string;
    realFileName: string;
  }) => {
    if (!selectedUser.groupuid) return;
    updateGroup(
      {
        GroupUID: selectedUser.groupuid,
        GroupProfileImage: JSON.stringify(response ?? ''),
        GroupName: selectedUser.personname,
        IsUseInChat: 1,
        MemberPersonInfoIDs: prepareSelectedUsersBeforeCreateGroup(
          groupUsersRef.current,
        ),
        IsChannel: Number(selectedUser.ischannel),
      },
      () =>
        getChats({
          dataShouldBeReplaced: true,
          shouldLoading: true,
          page: 1,
        }),
    );
  };

  /**
   * @function showUserInfo
   * @param {ChatUsersInterface} user
   * @returns {(event: MouseEvent<HTMLInputElement>) =>  void}
   */
  const showUserInfo =
    (user: ChatUsersInterface) =>
    (_event: MouseEvent<HTMLDivElement>): void => {
      console.log('showUserInfo');
    };

  /**
   * @function openMenu
   * @param {MouseEvent<HTMLElement>} event
   * @returns {void} void
   */
  const openMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @function openManageGroupDialog
   * @returns {void} void
   * @description open manage group dialog
   */
  const openManageGroupDialog = (): void => {
    setAnchorEl(null);
    actorDispatch('quickDialog', {
      chatInfoDialogIsOpen: false,
      manageGroupDialogIsOpen: true,
      addMembersDialogIsOpen: false,
      data: {
        selectedUser,
        groupUsers,
      },
    });
  };

  /**
   * @function openAddMembersDialog
   * @param {string} mode
   * @returns { () => void } void
   * @description open add members dialog it has two modes edit and show
   */
  const openAddMembersDialog =
    (mode: string = EDIT_MODE) =>
    (): void => {
      setAnchorEl(null);
      actorDispatch('quickDialog', {
        addMembersDialogIsOpen: true,
        chatCreateGroupDialogIsOpen: false,
        chatInfoDialogIsOpen: false,
        data: {
          GroupUID: selectedUser.groupuid,
          GroupProfileImage: selectedUser.personimage,
          GroupName: selectedUser.personname,
          IsUseInChat: 1,
          IsChannel: selectedUser.ischannel,
          groupUsers,
          mode,
        },
      });
    };

  return (
    <ChatInfoDialogView
      openManageGroupDialog={openManageGroupDialog}
      openAddMembersDialog={openAddMembersDialog}
      successFileCallback={successFileCallback}
      closeDialogHandler={closeDialogHandler}
      showUserInfo={showUserInfo}
      handleClose={handleClose}
      openMenu={openMenu}
      selectedUser={selectedUser}
      groupUsers={groupUsers}
      anchorEl={anchorEl}
      open={open}
    />
  );
};

export default ChatInfoDialogController;
