import { FC, useState, useEffect } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import {
  MenuItem,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import lodashGet from 'lodash/get';

import { crudCustomUpdate } from '../redux/crud/action';
import { prepareShiftProcess } from '../helper/MetaHelper';
import { actorDispatch, actorOnDispatch } from '../type/actor-setup';
import { removeOnDispatches } from '../helper/general-function-helper';

export interface ProcessTaskInterface {
  allowEdit: boolean;
  deactiveSubpanels: [];
  description: string;
  fields: object;
  lines: [];
  positionId: number;
  stateId: number;
  title: string;
  translatedTitle: object;
}

interface ProcessInterface {
  title: string;
  uniqueId: string;
  isActive?: boolean;
}

interface ShiftProcessButtonInterfaceProps {
  resource: string;
  recordId: number;
  crudUpdate: Function;
  metaData: object;
  processList: ProcessInterface[];
  defaultProcessUniqueId: string;
}

const useStyles = makeStyles(theme => ({
  iconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  icon: {
    fontSize: 20,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },

  selectInput: {
    padding: 10,
    fontSize: 11,
  },
  menuitem: {
    fontSize: '0.8rem',
  },
  selected: {
    fontSize: '0.8rem',
  },
}));

const ShiftProcessButton: FC<ShiftProcessButtonInterfaceProps> = props => {
  const {
    recordId,
    resource,
    crudUpdate,
    metaData,
    defaultProcessUniqueId,
    processList,
  } = props;
  const locale = useLocale();
  const classes = useStyles();
  const translate = useTranslate();

  const [processUniqueId, setProcessUniqueId] = useState(defaultProcessUniqueId);
  const [preparedShiftProcess, setPreparedShiftProcess] =
    useState<ProcessTaskInterface[]>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    const listenerId = actorOnDispatch('loading', loadingRecord => {
      setLoading(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          loadingRecord[resource] ||
          false,
      );
    });

    onDispatches.push({
      actionName: 'loading',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, []);

  useEffect(() => {
    setPreparedShiftProcess(prepareShiftProcess(metaData, processUniqueId));
  }, [processUniqueId]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShiftProcess = (task: ProcessTaskInterface) => () => {
    const data = [
      `process/${processUniqueId}/`,
      `toState/${task.stateId}/`,
      `toPosition/${task.positionId}/`,
    ];

    crudUpdate(resource, recordId, data, false, () => {
      actorDispatch('refreshView', 'all', { disableDebounce: true });
      // TODO: maybe need to refresh all relations
      // refreshRelation();
    });
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProcessUniqueId(event.target.value as string);
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{translate('form.processSelection')}</InputLabel>
        <Select
          value={processUniqueId}
          onChange={handleChange}
          label={translate('form.processSelection')}
          classes={{ select: classes.selectInput }}
          id="selectProcessDropdown"
          disabled={loading}
        >
          {processList?.map((process: ProcessInterface) => {
            return (
              process.isActive && (
                <MenuItem
                  className={classes.selected}
                  value={process.uniqueId}
                  data-test-process-id={process.uniqueId.slice(0, 4)}
                  key={`process-MenuItem-${process.uniqueId}`}
                >
                  {lodashGet(
                    process,
                    ['translatedTitle', locale],
                    lodashGet(process, 'title'),
                  )}
                </MenuItem>
              )
            );
          })}
        </Select>
      </FormControl>

      {preparedShiftProcess?.map((task: ProcessTaskInterface) => (
        <MenuItem
          className={classes.menuitem}
          key={`${task.positionId}${task.stateId}${task.title}`}
          data-test-prepared-shift-process={`${task.positionId}${task.stateId}${task.title}`}
          onClick={handleShiftProcess(task)}
        >
          {lodashGet(task, ['translatedTitle', locale], lodashGet(task, 'title'))}
        </MenuItem>
      ))}
    </>
  );
};

const mapDispatchToProps = {
  crudUpdate: crudCustomUpdate,
};
export default connect(null, mapDispatchToProps)(ShiftProcessButton);
