import { memo } from 'react';
import PropTypes from 'prop-types';
import { create } from 'jss';
import rtl from 'jss-rtl';
import grey from '@material-ui/core/colors/grey';
import {
  StylesProvider,
  jssPreset,
  createTheme,
  Theme,
} from '@material-ui/core/styles';

import { getValue, CONFIG_THEME_DIR, CONFIG_PALETTE_COLORS } from './configProvider';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const direction = getValue(CONFIG_THEME_DIR);
const palette = getValue(CONFIG_PALETTE_COLORS);

// must change dir on <body> tag
document.body.setAttribute('dir', direction);

export interface CustomTheme extends Theme {
  palette: CustomPaletteInterface;
  typography: Theme['typography'] & { fontWeightRegular: number };
}

export interface CustomPaletteInterface extends Palette {
  primary: CustomPrimaryInterface;
  todoTaskBackground: string;
  todoLightGray: string;
  todoHoveredItem: string;
  todoDarkGray: string;
  todoCustomShadow: Record<string, string>;
}

export interface CustomPrimaryInterface extends PaletteColor {
  appPrimaryBackgroundColor: string;
  appPrimaryLightBackgroundColor: string;
  appSecondaryBackgroundColor: string;
  appPrimaryTextColor: string;
  appSecondaryTextColor: string;
  appPrimaryDisableTextColor: string;
  appPrimaryDividerColor: string;
  appSecondaryDividerColor: string;
  appPrimaryIconColor: string;
  appPrimaryDisableIconColor: string;
  appPrimaryBorderInputColor: string;
  appPrimaryToolbarBackgroundColor: string;
  appBorderInput: string;
  appNotificationItem: string;
  eventColorPrimary: string;
  eventColorSecondary: string;
  appLightBackgroundColor: string;
  automationBackgroundConfirmButton: string;
  lightBlueBackgroundButton: string;
  borderSelectMarker: string;
  backgroundMarker: string;
  blueTextColor: string;
  border: string;
  caption: string;
  activeButton: string;
  noneActiveButton: string;
  backgroundMainTree: string;
  blueLink: string;
  customBoxShadow: string;
  disabledFieldColor: string;
  gray: string;
  dividerColor: string;
  profileMenuItemBackgroundColor: string;
  MenuButtonBackgroundColor: string;
  loginButton: string;
  loginButtonHover: string;
  loginPageBackgroundColor: string;
  zoomingBorderColor: string;
}

export const themeParams = {
  direction: direction, // Both here and <body dir="rtl">
  palette: {
    // type: 'dark',
    primary: {
      main: '#263948',
      appPrimaryBackgroundColor: '#eef1f5',
      appPrimaryLightBackgroundColor: grey[200],
      appSecondaryBackgroundColor: 'rgba(255, 255, 255, 1)',
      appLightBackgroundColor: '#ffffff',
      appPrimaryTextColor: 'rgba(0, 0, 0, 0.87)' /* hsl(240, 5%, 28%), */,
      appSecondaryTextColor: 'rgba(0, 0, 0, 0.75)',
      appPrimaryDisableTextColor: 'rgba(0, 0, 0, 0.38)',
      appSecondaryDividerColor: '#C7E1F2',
      appPrimaryDividerColor: 'rgba(0, 0, 0, 0.12)',
      appPrimaryIconColor:
        'rgba(0, 0, 0, 0.7)' /* original alpha in material: 54% */,
      appPrimaryDisableIconColor: 'rgba(0, 0, 0, 0.38)',
      appPrimaryBorderInputColor: 'rgba(0, 0, 0, 0.23)',
      gradientAppBar: 'linear-gradient(180deg, #26394B 0%, #495D70 99.99%)',
      appPrimaryToolbarBackgroundColor: '#FFFFFF',
      appBorderInput: '#bdbdbd',
      appNotificationItem: '#1d85d64f',
      eventColorPrimary: '#359a5c',
      eventColorSecondary: '#985a5d',
      automationBackgroundConfirmButton: '#1D85D6',
      lightBlueBackgroundButton: '#1D85D6',
      borderSelectMarker: 'red',
      backgroundMarker: '#247dc9',
      blueTextColor: '#007bff',
      border: '#e0e0e0',
      caption: 'rgba(0, 0, 0, 0.54)',
      activeButton: '#2564CF',
      noneActiveButton: '#818180',
      backgroundMainTree: '#eee',
      blueLink: '#3f51b5',
      customBoxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      loginButton: '#54AE4E',
      loginButtonHover: '#438640',
      loginPageBackgroundColor: '#f5f5f5',
      disabledFieldColor: '#00000099',
      gray: '#00000099',
      LanguageContainerBackgroundColor: 'transparent',
      LanguageContainerTextShadow: '#000000',
      dividerColor: '#737373',
      profileMenuItemBackgroundColor: '#475B6E',
      MenuButtonBackgroundColor: 'gray',
      zoomingBorderColor: '#d3d3d3de',
    },
    secondary: {
      main: '#2763cf',
      contrastText: '#ffffff',
      withOpacity: 'rgba(0, 170, 236, 0.05)',
    },
    info: {
      main: '#0078D4',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#f3a200',
    },
    success: {
      main: '#34A853',
    },
    error: {
      main: '#ff5350',
      withOpacity: 'rgba(255, 83, 80, 0.05)',
    },
    mail: {
      primaryTextColor: 'rgba(0, 0, 0)',
      secondaryTextColor: 'rgba(0, 0, 0)',
    },
    todoTaskBackground: 'rgb(238, 238, 238)',
    todoLightGray: '#ababab',
    todoDarkGray: '#323130',
    todoHoveredItem: '#edebe9',
    todoCustomShadow: {
      shadowOne: 'rgb(0 0 0 / 10%)',
      shadowTow: 'rgb(0 0 0 / 14%)',
      shadowTree: 'rgb(0 0 0 / 5%)',
    },
    ...palette,
  },
  typography: {
    useNextVariants: true,
    // https://material-ui.com/customization/themes/#typography
    fontFamily: [
      'WebErpFont',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightRegular: 400,
  },
};
export type ThemeParams = typeof themeParams;
const theme = createTheme(themeParams);

function RawAppLayoutDirection(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

RawAppLayoutDirection.propTypes = {
  children: PropTypes.any.isRequired,
};

export const AppLayoutDirection = memo(RawAppLayoutDirection);

export default theme;
