import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  mainContainer: {
    width: '100%',
    minHeight: '20rem',
    minWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
  },

  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  icon: {
    cursor: 'pointer',
    padding: 6,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '20rem',
  },

  titleBox: {
    marginRight: '5rem',
  },

  dialogContent: {
    height: '100%',
  },

  dialogContentBox: {
    height: '100%',
  },

  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `1px dotted ${theme.palette.todoDarkGray}`,
  },
}));
