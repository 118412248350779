import { SyntheticEvent, useCallback, useEffect, useState, type FC } from 'react';

import SingleSelectFilterInputView from './single-select-filter-input.view';
import { convertDigitsToEnglish } from '../../../helper/NumberHelper';
import { findSelectedItemFromDropdownData } from '../../../helper/DropdownHelper';
import { isEmpty } from '../../../helper/data-helper';

import { FilterValueStructureEnum } from '../../filter-form/filter-form.helper';
import type {
  DropdownMenuOption,
  SingleSelectFilterInputControllerProps,
  SingleSelectFilterInputViewProps,
} from './single-select-filter-input.type';

const SingleSelectFilterInputController: FC<
  SingleSelectFilterInputControllerProps
> = props => {
  const {
    dropdownMeta,
    customChangeHandler,
    record,
    field,
    formData,
    value,
    onChange,
    onFocus,
    disabled,
    options,
  } = props;

  const dropdownItems: string[] = (field.values as string[]) ?? [];

  const [dropValue, setDropValue] = useState('');

  useEffect(() => {
    setDropValue(value);
    onChange(value);
  }, [value]);

  useEffect(() => {
    const selectedItem = findSelectedItemFromDropdownData({
      dropdownMeta,
      dataArray: dropdownItems,
      record,
      dropValue,
      field,
      isSingleSelect: true,
    });

    // if we have a selected value, and no item can be found with this data
    if (!isEmpty(dropValue) && selectedItem) {
      customChangeHandler(selectedItem?.value ?? null, selectedItem);
    }
  }, [dropValue]);

  /**
   * @function handleFocus
   * @param { SyntheticEvent } event
   * @returns { void } void
   */
  const handleFocus = useCallback(
    (event: SyntheticEvent) => {
      onFocus?.(event);
    },
    [formData],
  );

  /**
   * @function handleChange
   * @param { SyntheticEvent } event
   * @param { Object } params
   * @returns { void } void
   */
  const handleChange = (event, { value: newValue }) => {
    const userInput = convertDigitsToEnglish(newValue);

    setDropValue(userInput);

    onChange(userInput);
  };

  const preparedOptions: DropdownMenuOption[] = dropdownItems.map(item => ({
    text: item,
    value: item,
    key: item,
  }));

  /**
   * @function getSelectRef
   * @param { unknown } dropManager
   * @returns { void } void
   */
  const getSelectRef = (dropManager: { searchRef: { current: unknown } }): void => {
    const parentGetRef = options?.inputRef;

    if (!dropManager || !parentGetRef || !dropManager?.searchRef?.current) {
      return;
    }

    parentGetRef?.(dropManager?.searchRef?.current);
  };

  const formattedDropdownValue = Array.isArray(dropValue)
    ? dropValue[FilterValueStructureEnum.VALUE]
    : dropValue;

  return (
    <SingleSelectFilterInputView
      getSelectRef={getSelectRef}
      dropValue={formattedDropdownValue}
      preparedOptions={preparedOptions}
      fieldName={field.name}
      disabled={disabled}
      handleChange={handleChange as SingleSelectFilterInputViewProps['handleChange']}
      handleFocus={handleFocus}
    />
  );
};

export default SingleSelectFilterInputController;
