import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: { display: 'inline-block' },

  searchFilter: {
    display: 'flex',
    padding: '10px 16px',
    '&:focus': {
      outline: 'none',
    },
  },

  icon: {
    fontSize: 18,
  },

  IconButton: {
    padding: 7,
    margin: '2px 5px',
  },

  notItemFoundText: {
    padding: '5px',
    marginInlineStart: '10px',
  },
}));
