import lodashGet from 'lodash/get';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import { get as axiosGet, post as axiosPost } from 'axios';

import {
  API_URL,
  API_NAME,
  API_VERSION,
  USER_TOKEN,
  USER_ID,
  USER_COMPANY_ID,
  IS_ADMIN_USER,
  getValue,
  setValue,
  clearAllConfig,
  CONFIG_FIXED_HEADER_PARAMS,
  CONFIG_CURRENCY_NAME,
  CONFIG_CURRENCY_SYMBOL,
  USER_WAREHOUSE_ID,
  USER_WAREHOUSE_TITLE,
  SESSION_ID,
  USER_LOG_IN,
  CONFIG_CALENDAR,
  SUB_DOMAIN,
  USER_LOG_OUT,
} from './configProvider';
import { showNotification } from '../helper/general-function-helper';
import {  actorGetActionValue } from '../type/actor-setup';
import { getSessionIdInUrl } from '../helper/UrlHelper';

export const AUTH_SEND_MOBILE = 'AUTH_SEND_MOBILE';
export const AUTH_SEND_CONFIRMATION = 'AUTH_SEND_CONFIRMATION';

export const broadcastChannel = new BroadcastChannel('reactERP');

export default async (type, params) => {
  const apiUrl = localStorage.getItem('customApiUrl')
    ? localStorage.getItem('customApiUrl')
    : getValue(API_URL);

  const apiVersion = getValue(API_VERSION);
  const apiName = getValue(API_NAME);
  const fixedHeaderParams = getValue(CONFIG_FIXED_HEADER_PARAMS) || {};
  const calendarLocale = getValue(CONFIG_CALENDAR);
  const company = getValue(SUB_DOMAIN);

  const translate = actorGetActionValue('reactAdminHelpers')?.translate;

  const requestConfig = {
    headers: {
      calendarLocale,
      ...fixedHeaderParams,
      ...(!!company && {company})
    },
  };

  switch (type) {
    case AUTH_SEND_MOBILE:
      return await axiosGet(
        `${apiUrl}/${apiVersion}/account/${apiName}/register?mobilenumber=${params.mobile}`,
        requestConfig,
      );

    case AUTH_SEND_CONFIRMATION:
      try {
        const confirmationResponse = await axiosPost(
          `${apiUrl}/${apiVersion}/account/${apiName}/register`,
          {
            mobilenumber: params.mobile,
            code: params.confirmation,
          },
          requestConfig,
        );

        if (!confirmationResponse.data.data && !confirmationResponse.data.status) {
          throw confirmationResponse.data.userMessage;
        }

        setValue(USER_TOKEN, confirmationResponse.data.data.token);
        setValue(SESSION_ID, confirmationResponse.data.data.token);
        setValue(
          CONFIG_CURRENCY_SYMBOL,
          lodashGet(confirmationResponse, 'data.data.extra.currencySymbol'),
        );
        setValue(
          CONFIG_CURRENCY_NAME,
          lodashGet(confirmationResponse, 'data.data.extra.currencyTypeTitle'),
        );
        setValue(USER_WAREHOUSE_ID, null);
        setValue(USER_WAREHOUSE_TITLE, null);

        return confirmationResponse;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.userMessage
        ) {
          throw new Error(error.response.data.userMessage);
        }
        throw error;
      }

    case AUTH_LOGIN:
      try {
        const response = await axiosPost(
          `${apiUrl}/${apiVersion}/account/${apiName}/login`,
          {
            username: params.username,
            password: params.password,
          },
          requestConfig,
        );

        if (response.data && response.data.code === 200) {
          setValue(USER_TOKEN, response.data.data.token);
          setValue(SESSION_ID, response.data.data.sessionId);
          setValue(USER_WAREHOUSE_ID, null);
          setValue(USER_WAREHOUSE_TITLE, null);

          if (response.data.data.companyId) {
            setValue(USER_COMPANY_ID, response.data.data.companyId);
          }

          if (response.data.data.userId || response.data.data.userId === 0) {
            setValue(USER_ID, response.data.data.userId);
          }

          if (response.data.data.extra) {
            setValue(
              IS_ADMIN_USER,
              lodashGet(response, ['data', 'data', 'extra', 'isAdmin'], false),
            );
          }

          broadcastChannel.postMessage({ type: USER_LOG_IN });

          const redirectUrl = sessionStorage.getItem('redirectUrl') ?? '';
          sessionStorage.removeItem('redirectUrl');

          if (redirectUrl.includes('login')) {
            location.href = location.origin + '#/';
          } else {
            location.href = location.origin + '#/' + redirectUrl;
          }

          // Prevent to reload `window` in the first login
          if (redirectUrl) {
            location.reload();
          }

          return;
        }

        throw new Error(response.data.userMessage);
      } catch (error) {
        if (error instanceof Error) {
          showNotification(error.message, 'error');
          throw error.message;
        }

        throw error;
      }

    case AUTH_LOGOUT:
      clearAllConfig();
      broadcastChannel.postMessage({ type: USER_LOG_OUT }); // For other tabs
      return;

    case AUTH_ERROR:
      const { status } = params;
      if (status === 401 || status === 403) {
        clearAllConfig();
        showNotification(translate('ra.auth.authError'), 'error');
        throw new Error('ra.auth.authError');
      }
      return;

    case AUTH_GET_PERMISSIONS:
    case AUTH_CHECK:
      if (!getValue(USER_TOKEN) && !getSessionIdInUrl()) {
        showNotification(translate('ra.auth.authError'), 'error');
        throw new Error('ra.auth.authError');
      }
      return;

    default:
      throw 'Unknown method';
  }
};
