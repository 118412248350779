import { FC, memo } from 'react';
import { useTranslate } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { Box, Button, Divider, InputAdornment, TextField } from '@material-ui/core';
import { TodoDateOptions } from '../../todo-date-options';
import { useStyles } from './add-todo-task-input.style';

import type { AddTodoTaskInputViewInterface } from './add-todo-task-input.type';

const AddTodoTaskInputView: FC<AddTodoTaskInputViewInterface> = props => {
  const {
    noteTitle,
    onChangeNoteTitle,
    handleKeyDown,
    createNewTask,
    showAddMessageInput,
    inputRef,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      {showAddMessageInput && (
        <div className={classes.mainContainer}>
          <div className={classes.inputContainer}>
            <TextField
              inputRef={inputRef}
              autoFocus={true}
              id="add-task"
              placeholder={translate('todo.newTask')}
              className={classes.input}
              onChange={onChangeNoteTitle}
              onKeyDown={handleKeyDown}
              value={noteTitle}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <AddIcon
                      className={classes.inputIcon}
                      onClick={createNewTask}
                      id="add-task-with-btn"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Divider />
          <Box className={classes.inputFooterContainer}>
            <div>
              <TodoDateOptions isMinimized={true} />
            </div>
            <div className={classes.inputFooterButtonContainer}>
              <Button
                disabled={noteTitle ? false : true}
                variant={'outlined'}
                onClick={createNewTask}
              >
                {translate('todo.add')}
              </Button>
            </div>
          </Box>
        </div>
      )}
    </>
  );
};

export default memo(AddTodoTaskInputView);
