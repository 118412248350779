//fixme: should delete after replace with new one in relations and tree

import React from 'react';
import { TopToolbar, useTranslate, useLocale } from 'react-admin';
import { Tooltip, IconButton, Icon } from '@material-ui/core';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import SaveFilterButton from '../container/SaveFilterButton';
import BulkDeleteButton from '../container/BulkDeleteButton';
import QuickCreateButton from '../container/QuickCreateButton';
import PrintButtonContainer from '../container/PrintButtonContainer';
import ListSelectSavedFilter from '../container/ListSelectSavedFilter';
import ServiceButtonContainer from '../container/ServiceButtonContainer';
import { toggleNoteStreamAction } from '../redux/listPage/action';
import ListColumnSelectDialogButton from '../container/ListColumnSelectDialogButton';
import CreateWithProcessButtonContainer from '../container/CreateWithProcessButtonContainer';
import { getFilterColumns, hasNote, isModuleTable } from '../helper/MetaHelper';
import { createPortal } from 'react-dom';
import ImportButton from './ImportButton';
import { AccessPath } from './access-path';
import ExportButton from './ExportButton';
import { getProcessList } from '../helper/meta-helper';
import { TableColorFilter } from './table-color-filter';
import { FilterButton } from './filter-form/filter-button';

const useStyles = makeStyles(theme => ({
  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  topToolbar: {
    alignItems: 'center',
    padding: 0,
    zIndex: 2,
    display: 'flex',
    justifyContent: props =>
      props.hasSelectedItems || props.isAccessPath ? 'space-between' : 'flex-end',
    flexWrap: 'wrap',
  },

  actionsContainer: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
  },

  selectedItemsContainer: {
    display: 'flex',
    alignSelf: 'center',
  },

  selectedItemsText: {
    alignSelf: 'center',
  },

  clearIcon: {
    color: theme.palette.error.main,
  },

  refreshButton: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ListActions = props => {
  const {
    bulkActions,
    basePath,
    currentSort = null,
    displayedFilters,
    filter,
    filters,
    filterValues = null,
    showFilterByList,
    showFilter,
    setFilters,
    onUnselectItems,
    resource,
    selectedIds,
    toggleNoteStream,
    metaData,
    quickCreateData,
    quickCreateMustRefresh,
    redirect,
    hasCreate,
    hasDelete,
    columnChoiceKey,
    listColumnChoiceMustRefresh,
    disabledFieldList,
    parentInfo,
    treeLevel,
    disableDelete,
    initialData,
    additionalProps,
    relationMode,
    childFieldName,
    hidePrint,
    selectionRef,
    accordionPortalId,
    isColumnChoice = true,
    isRefreshEnabled,
    toggleRefreshButton,
    isSelectedItemsCount = true,
    isAccessPath = false,
    toggleShowFilters,
    clearAllSelectedCheckboxes,
    onDeleteSuccessCallback,
    allowExport,
    tableRowColors,
    onColorFilterChange,
    isFullScreen,  
    toggleFullScreen
  } = props;
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles({
    hasSelectedItems: !!(isSelectedItemsCount && selectedIds && selectedIds.length),
    isAccessPath,
  });

  const processList = getProcessList(metaData);
  const treeCondition =
    treeLevel && typeof treeLevel === 'number' && selectedIds.length > 1;

  /**
   * this function should clear all selected items from selection
   * by calling clearSelection function from selectionRef
   * @function clearSelection
   * @returns {void}
   */
  const clearSelection = event => {
    if (typeof clearAllSelectedCheckboxes === 'function') {
      clearAllSelectedCheckboxes();
    } else if (
      selectionRef &&
      selectionRef.current &&
      selectionRef.current.clearSelection
    ) {
      selectionRef.current.clearSelection();
    }
  };

  if (!resource || !metaData) {
    return <div />;
  }

  const isReport = Boolean(resource?.indexOf('report') === 0);

  const result = (

    <TopToolbar
      className={classes.topToolbar}
      data-test-has-delete={hasDelete}
      data-test-has-create={hasCreate}
      data-style-top-toolbar="topToolbar"
    >
      {isFullScreen?<FullscreenExitIcon onClick={toggleFullScreen?.(false)}/>:<FullscreenIcon onClick={toggleFullScreen?.(true)}/>}
      {isAccessPath && <AccessPath />}
      {relationMode && isReport && (
        <FilterButton
          className={classes.filterButton}
          resource={resource}
          locale={locale}
          fieldListToFilter={getFilterColumns(metaData)}
          relationMode={relationMode}
        />
      )}


      <div className={classes.actionsContainer}>
        {isRefreshEnabled && toggleRefreshButton && (
          <Tooltip
            className={classes.refreshButton}
            title={translate('grid.refresh')}
          >
            <div>
              <IconButton
                color="primary"
                className={classes.IconButton}
                onClick={toggleRefreshButton}
                disabled={!isRefreshEnabled}
                id="refreshButton"
              >
                <Icon fontSize="small">refresh</Icon>
              </IconButton>
            </div>
          </Tooltip>
        )}

        {hasCreate && !processList.all.length && (
          <QuickCreateButton
            data={quickCreateData}
            resource={resource}
            label={translate('grid.quickCreate')}
            mustRefresh={quickCreateMustRefresh}
            redirect={redirect}
            disabledFieldList={disabledFieldList}
            parentInfo={parentInfo}
            additionalProps={additionalProps}
            relationMode={relationMode}
            childFieldName={childFieldName}
            // refreshRelation={toggleRefreshButton}
          />
        )}

        {hasCreate && processList.all.length ? (
          <CreateWithProcessButtonContainer
            locale={locale}
            metaData={metaData}
            resource={resource}
            relationMode={relationMode}
            data={quickCreateData}
            activeProcessList={processList.actives}
            parentInfo={parentInfo}
          />
        ) : null}

        {relationMode && (
          <ExportButton
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            relationMode={relationMode}
            disabled={!allowExport}
            metaData={metaData}
          />
        )}

        {relationMode && (
          <ImportButton
            resource={resource}
            metaData={metaData}
            parentResource={parentInfo?.parentResource}
            parentId={parentInfo?.parentId}
          />
        )}

        {isColumnChoice && columnChoiceKey && metaData && resource && (
          <ListColumnSelectDialogButton
            metaData={metaData}
            locale={locale}
            resource={columnChoiceKey}
            mustRefresh={listColumnChoiceMustRefresh}
            relationMode={relationMode}
          />
        )}

        {filters && setFilters && <SaveFilterButton resource={resource} />}

        {filters && setFilters && (
          <ListSelectSavedFilter
            metaData={metaData}
            locale={locale}
            resource={resource}
            setFilters={setFilters}
            filter={filter}
            showFilterByList={showFilterByList}
            displayedFilters={displayedFilters}
            filterValues={filterValues}
          />
        )}

        {typeof toggleShowFilters == 'function' && (

          <Tooltip title={translate('grid.tableFilter')}>
            <IconButton
              id={'searchPopUpFilterButton'}
              color="primary"
              onClick={toggleShowFilters}
            >
              <Icon
                fontSize="small"
                className={classNames(classes.icon, 'fa fa-filter')}
              />
            </IconButton>
          </Tooltip>
        )}

        {Array.isArray(tableRowColors) && tableRowColors.length > 0 && (
          <TableColorFilter
            tableRowColors={tableRowColors}
            tooltipText={translate('general.tableRowColorFilter')}
            defaultText={translate('general.defaultTableRowColorFilterText')}
            colorChangeHandler={onColorFilterChange}
          />
        )}

        {filters &&
          setFilters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}

        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
          })}

        <ServiceButtonContainer
          locale={locale}
          resource={resource}
          metaData={metaData}
          selectedIds={selectedIds}
          initialData={initialData}
          relationMode={relationMode}
        />

        {!hidePrint && (
          <PrintButtonContainer
            isForceEnabled={!isModuleTable(metaData)}
            locale={locale}
            resource={resource}
            selectedIds={selectedIds}
            filterValues={filterValues && filterValues}
            currentSort={currentSort && currentSort}
            metaData={metaData}
            relationMode={relationMode}
          />
        )}

        {hasDelete && (
          <BulkDeleteButton
            undoable={false}
            basePath={basePath}
            resource={resource}
            selectedIds={selectedIds}
            bulkDeleteButtonOnViewPage
            disabled={!selectedIds.length || treeCondition || disableDelete}
            additionalProps={additionalProps}
            clearSelection={clearSelection}
            onDeleteSuccessCallback={onDeleteSuccessCallback}
          />
        )}
      </div>
    </TopToolbar>
  );
  if (accordionPortalId) {
    return createPortal(result, document.getElementById(accordionPortalId));
  } else {
    return result;
  }
};

const mapDispatchToProps = {
  toggleNoteStream: toggleNoteStreamAction,
};

export default connect(null, mapDispatchToProps)(ListActions);
