import { type FC, memo, useEffect, useRef, useState } from 'react';

import HomeTitleView from './home-title.view';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../type/actor-setup';

const HomeTitleController: FC = memo(() => {
  const [customText, setCustomText] = useState('');
  const [resource, setResource] = useState('');

  const onDispatchRefs = useRef<{ [name: string]: symbol }>({});

  const menuPagePattern = /\/menu\/[0-9]*/;

  useEffect(() => {
    onDispatchRefs.current['resources'] = actorOnDispatch('resources', details => {
      setResource(details?.current.value);
    });

    onDispatchRefs.current['urlInfo'] = actorOnDispatch('urlInfo', urlInfo => {
      if (menuPagePattern.test(urlInfo?.location?.hash ?? '')) {
        const pageTitle = actorGetActionValue('setDocumentTitle')?.recordTitle ?? '';
        setCustomText(pageTitle);
      } else {
        setCustomText('');
      }
    });

    return () => {
      Object.keys(onDispatchRefs.current).forEach(key => {
        actorRemoveAction({
          actionName: key as keyof ActorActionList,
          listenerId: onDispatchRefs.current[key],
        });
      });
    };
  }, []);

  return <HomeTitleView resource={resource} customText={customText} />;
});

export default HomeTitleController;
