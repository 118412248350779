import { makeStyles } from '@material-ui/core';
import type { CustomTheme, themeParams} from '../../core/themeProvider';

export const useStyles = makeStyles(
  (theme: CustomTheme) => ({
    form: {
      padding: '0 1em 1em 1em',
      margin: '10px',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      margin: '10px',
    },

    // styles for login with Google button
    // loginText: {
    //   display: 'inline-block',
    //   background: '#fff',
    //   padding: '0 1em',
    //   color: '#bab7b7',
    //   fontSize: '1em',
    // },
    // loginHeader: {
    //   texAlign: 'center',
    //   borderBottom: '1px solid #e1dfdf',
    //   height: '0.5em',
    //   textAlign: 'center',
    //   marginBottom: '20px',
    // },
    // loginWithGoogle: {
    //   margin: '15px 10px',
    // },
    // googleBtn: {
    //   width: '100%',
    //   display: 'flex',
    //   flexWrap: 'nowrap',
    //   flexDirection: 'row',
    //   border: '2px solid #d4d4d5',
    //   borderRadius: '30px',
    // },
    // GoogleText: {
    //   fontSize: '1em',
    //   fontWeight: 900,
    //   justifyContent: 'space-between',
    //   margin: '0px 5px',
    //   marginTop: '3px',
    //   textTransform: 'capitalize',
    // },
    // img2: {
    //   width: '30px',
    //   height: '30px',
    //   justifyContent: 'space-between',
    //   margin: '0px 5px',
    // },
    root: {
      color: 'white',
      background: `${theme.palette.primary.loginButton}`,
      borderRadius: '20px',
      marginBottom: '5px',
      '&:hover': {
        background: `${theme.palette.primary.loginButtonHover}`,
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    formCheckBox: { display: 'none', justifyContent: 'start' },
  }),
  { name: 'RaLoginForm' },
);
