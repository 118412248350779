import { useTranslate } from 'react-admin';

import { useState, type FC, useEffect } from 'react';
import { useLocale } from 'react-admin';

import type {
  DynamicChartSettingsSelectorControllerProps,
  OptionsType,
} from './dynamic-chart-settings-selector.type';
import { getReportColumns } from '../../../gadget-design-dialog.helper';
import { getMetaDataFromActorOrNetwork } from '../../../../../../../helper/meta-helper';
import DynamicChartSettingsSelectorView from './dynamic-chart-settings-selector.view';
import { MainCharts } from '../../../../../../dynamic-chart';

import type { ColumnsInterface } from '../../../../../../grid/grid.type';

const DynamicChartSettingsSelectorController: FC<
  DynamicChartSettingsSelectorControllerProps
> = props => {
  const { gadgetInternalChangeHandler, gadgetValues } = props;
  const locale = useLocale();

  const [reportColumns, setReportColumns] = useState<ColumnsInterface[]>([]);

  useEffect(() => {
    if (gadgetValues.reportAddress) {
      getMetaDataFromActorOrNetwork(
        `report/${gadgetValues.reportAddress}` ?? '',
      ).then(response => {
        const metaData = response[`report/${gadgetValues?.reportAddress}` ?? ''];
        setReportColumns(getReportColumns(metaData, locale));
      });
    }
  }, [gadgetValues.reportAddress]);

  const translate = useTranslate();

  let options: Array<OptionsType>;

  /**
   * Notice 📣👇 :
   * in each object without `inputType` key, it would render a ( simple dropdown ) as default
   */
  switch (gadgetValues.chartType) {
    case MainCharts.Pie: {
      options = [
        {
          label: translate('gadget.idField'),
          keyInReport: 'idFieldName',
          keyInGadgetData: 'idFieldName',
        },
        {
          label: translate('gadget.valueField'),
          keyInReport: 'valueFieldName',
          keyInGadgetData: 'valueFieldName',
        },
        {
          label: translate('gadget.labelField'),
          keyInReport: 'labelFieldName',
          keyInGadgetData: 'labelFieldName',
        },
        {
          label: translate('gadget.colorField'),
          keyInReport: 'colorFieldName',
          keyInGadgetData: 'colorFieldName',
        },
        {
          label: translate('gadget.sortReportData'),
          keyInReport: 'sortObject',
          keyInGadgetData: 'sortObject',
          inputType: 'sortField',
        },
        {
          label: translate('gadget.displayNumber'),
          keyInReport: 'displayNumber',
          keyInGadgetData: 'displayNumber',
          inputType: 'displayNumber',
        },
      ];

      break;
    }
    case MainCharts.Bar: {
      options = [
        {
          label: translate('gadget.horizontal'),
          keyInReport: 'axisFieldName',
          keyInGadgetData: 'axisFieldName',
          inputType: 'multiSelect',
        },
        {
          label: translate('gadget.valueField'),
          keyInReport: 'valueFieldName',
          keyInGadgetData: 'valueFieldName',
          inputType: 'multiSelect',
        },
        {
          label: translate('gadget.axisBottomLabelField'),
          keyInReport: 'axisBottomLabelFieldName',
          keyInGadgetData: 'axisBottomLabelFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.axisLeftLabelField'),
          keyInReport: 'axisLeftLabelFieldName',
          keyInGadgetData: 'axisLeftLabelFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.sortReportData'),
          keyInReport: 'sortObject',
          keyInGadgetData: 'sortObject',
          inputType: 'sortField',
        },
        {
          label: translate('gadget.displayNumber'),
          keyInReport: 'displayNumber',
          keyInGadgetData: 'displayNumber',
          inputType: 'displayNumber',
        },
      ];
      break;
    }
    case MainCharts.Line: {
      options = [
        {
          label: translate('gadget.horizontal'),
          keyInReport: 'axisFieldName',
          keyInGadgetData: 'axisFieldName',
        },
        {
          label: translate('gadget.vertical'),
          keyInReport: 'valueFieldName',
          keyInGadgetData: 'valueFieldName',
          // inputType: 'multiSelect',
        },
        {
          label: translate('gadget.axisBottomLabelField'),
          keyInReport: 'axisBottomLabelFieldName',
          keyInGadgetData: 'axisBottomLabelFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.axisLeftLabelField'),
          keyInReport: 'axisLeftLabelFieldName',
          keyInGadgetData: 'axisLeftLabelFieldName',
          inputType: 'textField',
        },
      ];
      break;
    }
    case MainCharts.TimeSeriesChart: {
      options = [
        {
          label: translate('gadget.horizontal'),
          keyInReport: 'axisFieldName',
          keyInGadgetData: 'axisFieldName',
        },
        {
          label: translate('gadget.vertical'),
          keyInReport: 'valueFieldName',
          keyInGadgetData: 'valueFieldName',
        },
        {
          label: translate('gadget.titleField'),
          keyInReport: 'legendFieldName',
          keyInGadgetData: 'legendFieldName',
        },
        {
          label: translate('gadget.axisBottomLabelField'),
          keyInReport: 'axisBottomLabelFieldName',
          keyInGadgetData: 'axisBottomLabelFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.axisLeftLabelField'),
          keyInReport: 'axisLeftLabelFieldName',
          keyInGadgetData: 'axisLeftLabelFieldName',
          inputType: 'textField',
        },
      ];
      break;
    }
    case MainCharts.Area: {
      options = [
        {
          label: 'axisFieldName',
          keyInReport: 'axisFieldName',
          keyInGadgetData: 'axisFieldName',
        },
        {
          label: 'series',
          keyInReport: 'valueFieldName',
          keyInGadgetData: 'valueFieldName',
          // inputType: 'multiSelect',
        },
        {
          label: 'legendFieldName',
          keyInReport: 'legendFieldName',
          keyInGadgetData: 'legendFieldName',
        },
        {
          label: translate('gadget.colorField'),
          keyInReport: 'colorFieldName',
          keyInGadgetData: 'colorFieldName',
        },
        {
          label: translate('gadget.axisBottomLabelField'),
          keyInReport: 'axisBottomLabelFieldName',
          keyInGadgetData: 'axisBottomLabelFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.axisTopLabelField'),
          keyInReport: 'axisTopLabelFieldName',
          keyInGadgetData: 'axisTopLabelFieldName',
          inputType: 'textField',
        },
      ];
      break;
    }
    case MainCharts.WordCloud:
      {
        options = [
          {
            label: translate('gadget.idField'),
            keyInReport: 'idFieldName',
            keyInGadgetData: 'idFieldName',
          },
          {
            label: translate('gadget.labelField'),
            keyInReport: 'wordFieldName',
            keyInGadgetData: 'wordFieldName',
          },
          {
            label: translate('gadget.valueField'),
            keyInReport: 'valueFieldName',
            keyInGadgetData: 'valueFieldName',
          },
          {
            label: translate('gadget.titleField'),
            keyInReport: 'titleFieldName',
            keyInGadgetData: 'titleFieldName',
            inputType: 'textField',
          },
        ];
      }

      break;

    case MainCharts.Stat: {
      options = [
        {
          label: translate('gadget.valueField'),
          keyInReport: 'valueFieldName',
          keyInGadgetData: 'valueFieldName',
          inputType: 'singleSelect',
        },
        {
          label: translate('gadget.titleField'),
          keyInReport: 'titleFieldName',
          keyInGadgetData: 'titleFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.iconField'),
          keyInReport: 'iconFieldName',
          keyInGadgetData: 'iconFieldName',
          inputType: 'singleSelect',
        },
      ];
      break;
    }
    case MainCharts.Gauge: {
      options = [
        {
          label: translate('gadget.valueField'),
          keyInReport: 'valueFieldName',
          keyInGadgetData: 'valueFieldName',
          inputType: 'singleSelect',
        },
        {
          label: translate('gadget.labelField'),
          keyInReport: 'labelFieldName',
          keyInGadgetData: 'labelFieldName',
          inputType: 'singleSelect',
        },
        {
          label: translate('gadget.titleField'),
          keyInReport: 'titleFieldName',
          keyInGadgetData: 'titleFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.thresholdColorRange'),
          keyInReport: 'thresholdColorRange',
          keyInGadgetData: 'thresholdColorRange',
          inputType: 'thresholdColorRange',
        },
      ];
      break;
    }
    case MainCharts.List: {
      options = [
        {
          label: translate('gadget.titleField'),
          keyInReport: 'titleFieldName',
          keyInGadgetData: 'titleFieldName',
          inputType: 'textField',
        },
        {
          label: translate('gadget.sortReportData'),
          keyInReport: 'sortObject',
          keyInGadgetData: 'sortObject',
          inputType: 'sortField',
        },
      ];
      break;
    }
    case MainCharts.Announcements: {
      options = [
        {
          label: translate('gadget.announcementsPerPage'),
          keyInReport: 'perPage',
          keyInGadgetData: 'perPage',
          inputType: 'textField',
        },
      ];
      break;
    }

    case MainCharts.Iframe: {
      options = [
        {
          label: translate('gadget.url'),
          keyInReport: 'url',
          keyInGadgetData: 'url',
          inputType: 'textField',
        },
        {
          label: translate('gadget.attachSessionToUrl'),
          keyInReport: 'attachSessionToUrl',
          keyInGadgetData: 'attachSessionToUrl',
          inputType: 'boolean',
        },
      ];
      break;
    }
    default:
      options = [];
      break;
  }

  /**
   * trigger `gadgetInternalChangeHandler` function with new value
   * @function handleSelect
   * @param {OptionsType} field
   * @param {string} value
   * @returns {void}
   */
  const handleSelect = (
    field: OptionsType,
    value: string | number | boolean,
  ): void => {
    try {
      if (field.inputType === 'multiSelect') {
        gadgetInternalChangeHandler({
          [field.keyInGadgetData]: value?.toString().split(','),
        });
      } else {
        gadgetInternalChangeHandler({
          [field.keyInGadgetData]: value,
        });
      }
    } catch {
      console.error('handleSelect failed');
    }
  };

  const menuItems = reportColumns.map(item => ({
    name: item.name as string,
    title: item.title as string,
  }));

  return (
    <DynamicChartSettingsSelectorView
      handleSelect={handleSelect}
      gadgetValues={gadgetValues}
      menuItems={menuItems}
      options={options}
    />
  );
};

export default DynamicChartSettingsSelectorController;
