import { type FC, useCallback, useEffect, useState } from 'react';

import { showNotification } from '../../../helper/general-function-helper';
import { getPermissionTabData } from './show-permission-tab.helper';
import NotFound from '../../NotFound';
import LoadingSkeleton from './loading-skeleton';
import { SimpleGrid } from '../simple-grid';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';

import type { DynamicPermissionDataType } from '../show-permissions.type';
import type { PermissionResponseType } from './dynamic-permission-data.type';

const DynamicPermissionData: FC<DynamicPermissionDataType> = props => {
  const { tabId, columns, isAdvanced, selectItems } = props;
  const resource = 'permission/show';

  const [data, setData] = useState<PermissionResponseType[]>([]);

  const filter = actorGetActionValue('filterDataIsChanged')?.[resource];

  const [loading, setLoading] = useState(!!filter);
  const [error, setError] = useState(false);

  /**
   * @function onSuccess
   * @param { PermissionResponseType[] } response
   * @returns {void} void
   */
  const onSuccess = useCallback((response: PermissionResponseType[]): void => {
    setData(response);
    setLoading(false);
  }, []);

  /**
   * @function onFailure
   * @param { string } error
   * @returns {void} void
   */
  const onFailure = useCallback((error: string): void => {
    showNotification(error, 'error');
    setError(true);
    setLoading(false);
  }, []);

  /**
   * @function refreshTabData
   * @param { string } error
   * @returns {void} void
   */
  const refreshTabData = (customFilter: any = undefined): void => {
    const defaultFilter = actorGetActionValue('filterDataIsChanged')?.[resource];

    getPermissionTabData(tabId, onSuccess, onFailure, {
      filter: customFilter ?? defaultFilter,
    });
  };

  useEffect(() => {
    const id = actorOnDispatch('filterDataIsChanged', filter => {
      refreshTabData(filter[resource]);
    });

    return () => {
      actorRemoveAction({
        actionName: 'filterDataIsChanged',
        listenerId: id,
      });
    };
  }, []);

  if (loading) {
    return <LoadingSkeleton />;
  }
  if (error) {
    return <NotFound />;
  }

  return (
    <SimpleGrid
      filterLocally={true}
      columns={columns}
      rows={data}
      refreshTabData={refreshTabData}
      isAdvanced={isAdvanced}
      selectItems={selectItems}
    />
  );
};

export default DynamicPermissionData;
