import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { useStyles } from './user-description-dialog.style';
import type { UserDescriptionDialogViewInterface } from './user-description-dialog.type';

const UserDescriptionDialogView = (
  props: UserDescriptionDialogViewInterface,
): ReactElement => {
  const {
    onClose,
    onSubmitForm,
    defaultTextFieldValue,
    isLoading,
    dialogTitle,
    onChange,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <form onSubmit={onSubmitForm}>
      <DialogTitle className={classes.dialogTitle}>
        {dialogTitle ?? translate('form.completedFrom')}
      </DialogTitle>
      <DialogContent className={classes.dialogTitle}>
        <TextField
          fullWidth
          variant="outlined"
          label={translate('sellin.description')}
          defaultValue={defaultTextFieldValue}
          onChange={onChange}
        />
        {/* <SimpleForm fields={fields} className={classes.field} /> */}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          color="secondary"
        >
          {translate('confirm.confirm')}
        </Button>
        <Button onClick={onClose} variant="outlined" color="primary">
          {translate('confirm.cancel')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default UserDescriptionDialogView;
