import { type FC } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { CircularProgress, Typography } from '@material-ui/core';
import ProfileSettingItemView from '../profile/ProfileSettingItemView';
import { useStyles } from './header-profile.style';
import { getProfileImageUrl } from '../profile-avatar/profile-avatar.helper';

const HeaderProfileView: FC<any> = props => {
  const {
    isDrawerOpen,
    profileIsLoading,
    profileUpdateLoading,
    profileData,
    toggleDrawer,
  } = props;

  const classes = useStyles({ isDrawerOpen });

  return (
    <>
      <div className={classes.toolbarContainer}>
        <div className={classes.avatarContainer} id="profileAvatar">
          <img
            className={classes.imageContainer}
            src={getProfileImageUrl(profileData)}
          />
        </div>

        {(profileIsLoading || profileUpdateLoading) && <CircularProgress />}

        {isDrawerOpen && profileData && (
          <div className={classes.profile} id="profileSidebar">
            <Typography
              variant="caption"
              noWrap
              className={classes.profileDisplayName}
            >
              {profileData.displayName ||
                (profileData.firstName || profileData.lastName
                  ? `${profileData.firstName} ${profileData.lastName}`
                  : profileData.mobileNumber)}
            </Typography>
            <ProfileSettingItemView className={classes.warehouseDisplayName} />
          </div>
        )}

        {isDrawerOpen && (
          <div
            onClick={toggleDrawer}
            className={classes.topBurgerMenu}
            id="toggleMenuIcon"
          >
            <MenuIcon fontSize="large" />
          </div>
        )}
      </div>
      {!isDrawerOpen && (
        <div
          data-test="toggleDrawer"
          onClick={toggleDrawer}
          className={classes.burgerMenu}
        >
          <MenuIcon />
        </div>
      )}
    </>
  );
};

export default HeaderProfileView;
