import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}));
