import { type FC, type ReactElement, useCallback, useRef, useState } from 'react';
import { removeLastResource, setResource } from '../../../helper/resource-helper';
import {
  actorGetActionValue,
  actorSetActionValue,
  FormKeyMode,
} from '../../../type/actor-setup';
import { WMSLayoutWithTabsView } from './wms-layout-with-tabs.view';
import { WMSLayoutWithTabsPropsInterface } from './wms-layout-with-tabs.type';

const WMSLayoutWithTabsController: FC<WMSLayoutWithTabsPropsInterface> = (
  props,
): ReactElement => {
  const { wmsMetaData } = props;

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const prevTabIndexRef = useRef<number>(0);

  /**
   * It handles all stuffs about changing a tab, e.g save the current data in this tab if there is a form and etc...
   * @function handleTabChange
   * @param { string } index
   * @returns { void }
   */
  const handleTabChange = useCallback(
    (index: string): void => {
      removeLastResource();

      // Remove `inputsRef` of previous tab to increase memory performance
      const currentResource = actorGetActionValue('resources')!.current;
      actorSetActionValue('inputsRef', null, {
        path: `${currentResource.value}.${currentResource.type}`,
        replaceAll: true,
        callerScopeName: 'WMSLayoutWithTab => handleTabChange',
      });

      const rootResource = actorGetActionValue('resources')!.stack[0];
      const newResource = `${rootResource.value}/tab-${index}`;
      const metaData = actorGetActionValue('metaData', rootResource.value);
      actorSetActionValue('metaData', metaData, {
        path: newResource,
        callerScopeName: 'WMSLayoutWithTab => handleTabChange',
      });

      setResource(newResource, FormKeyMode.WMS);

      prevTabIndexRef.current = currentTabIndex;
      setCurrentTabIndex(Number(index));
    },
    [currentTabIndex],
  );

  return (
    <WMSLayoutWithTabsView
      wmsMetaData={wmsMetaData}
      currentTabIndex={currentTabIndex}
      prevTabIndex={prevTabIndexRef.current}
      handleTabChange={handleTabChange}
    />
  );
};

export default WMSLayoutWithTabsController;
