import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  icon: {
    margin: '0 8px',
    fontSize: 17,
    cursor: 'pointer',
  },
  selectToolbar: {
    color: 'white',
    border: 'none',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    top: '2px',
  },
  selectBoxIcon: {
    margin: '3px 3px',
    fontSize: 17,
    cursor: 'pointer',
    fill: 'white',
  },
}));
