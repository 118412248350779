import { isEmpty } from '../../helper/data-helper';
import { showNotification } from '../../helper/general-function-helper';
import {
  getNotificationItemPatternInfo,
  getNotificationPatternInfo,
} from '../../helper/PatternMetaHelper';
import { callQuickAccess, openNewTab } from '../../helper/QuickAccessHelper';
import { NotificationItemInterface } from '../../helper/Types';

/**
 * Call `openNewTab` if `link` exist.
 * @function handleClickOnNotificationItem
 * @param {NotificationItemInterface} record
 * @returns {Promise<void>}
 */
export const onClickNotificationItem = async (
  record: NotificationItemInterface,
): Promise<void> => {
  const { notificationItemPatternName } = getNotificationPatternInfo('notification');
  const { link } = getNotificationItemPatternInfo(notificationItemPatternName);
  try {
    if (!isEmpty(record[link])) {
      if (/([0-9]\.[0-9])/g.test(record[link])) {
        const url: string = await callQuickAccess(record[link]);
        openNewTab(url);
      } else {
        openNewTab(record[link]);
      }
    }
  } catch (error) {
    showNotification(error, 'warning');
  }
};
