import { FC, memo, useCallback, useEffect } from 'react';
import { isEmptyObject } from '../../../helper/data-helper';
import { getFormDefaultValue } from '../../../helper/MetaHelper';
import ServiceDialogFormView from './service-dialog-form.view';
import { RecordKeyMode } from '../../../type/actor-setup';
import { getServiceInputsInitialAppearanceCharacteristics } from '../../../helper/meta-helper';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../../../helper/settings-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
} from '../../../type/actor-setup';

import type { ServiceDialogFormInterface } from './service-dialog-form.type';
import type { ValidationError } from '../../../helper/Types';
import type { InputRefContent } from '../form.type';

import type { FormData } from '../form.type';

const ServiceDialogFormController: FC<ServiceDialogFormInterface> = memo(props => {
  const { fields, isServiceMode, serviceParams } = props;

  const { current: currentResource, stack } = actorGetActionValue('resources')!;
  const rootResource = stack[stack.length - 2];

  /**
   * Finding input values from `formData`, if corresponding input name found in `formErrors`, replace it
   * @function updateInputsState
   * @param {object} inputsRef
   * @param {object} formErrors
   * @param {object} formData
   * @returns {void} void
   */
  const updateInputsState = (
    inputsRef: Record<string, InputRefContent> | null | undefined,
    formErrors: Record<string, ValidationError> | undefined,
    formData: FormData,
  ): void => {
    if (isEmptyObject(inputsRef)) return;

    //TODO: move to main parent component
    for (const inputName in inputsRef) {
      if (fields.findIndex(field => field.name === inputName) === -1) continue;

      if (formErrors?.[inputName]) {
        inputsRef[inputName].setInputMessage?.({
          message: formErrors?.[inputName].message ?? '',
          messageType: 'error',
        });

        // FIXME: Because we have some many re-renders, without this `delay`, the correct value will be removed
        setTimeout(() => {
          inputsRef[inputName].setInputValue?.(formErrors?.[inputName].value ?? '');
        }, 0);

        continue;
      }

      inputsRef[inputName]?.setInputMessage?.(undefined);
      inputsRef[inputName]?.setInputValue?.(formData?.[inputName] ?? '');
    }
  };

  const getDefaultValues = useCallback(async () => {
    const globalParameters = actorGetActionValue(
      'profile',
      'profileData.globalparameters',
    );
    const response = await getFormDefaultValue(fields, globalParameters);

    return (response ?? null) as Record<string, unknown> | null;
  }, []);

  useEffect(() => {
    actorOnDispatch('formMessages', formMessages => {
      const formData = actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null;
      const inputsRef = actorGetActionValue('inputsRef');
      if (formData) {
        updateInputsState(
          inputsRef?.[currentResource.value]?.[currentResource.type],
          formMessages?.[currentResource.value]?.[currentResource.type],
          formData,
        );
      }
    });

    actorSetActionValue('allFields', fields, {
      path: `${currentResource.value}.${currentResource.type}`,
      replaceAll: true,
      callerScopeName: 'ServiceDialogFormController => useEffect',
    });
  }, []);

  useEffect(() => {
    getDefaultValues()
      .then((defaultValues: Record<string, unknown> | null) => {
        const recordData = {};
        const record = actorGetActionValue(
          'record',
          `${rootResource?.value}.${rootResource?.type}.${RecordKeyMode.FORM}`,
        )!;

        for (const item of fields) {
          recordData[item.name] = record?.[item.name] ?? defaultValues?.[item.name];
        }

        actorDispatch('initialData', defaultValues, {
          path: `${currentResource.value}.${currentResource.type}`,
          replaceAll: true,
        });

        const formData = { ...defaultValues, ...recordData, ...serviceParams };

        const inputsRef = actorGetActionValue(
          'inputsRef',
          `${currentResource.value}.${currentResource.type}`,
        ) as Record<string, InputRefContent> | undefined;

        actorSetActionValue('formData', formData, {
          path: `${currentResource.value}.${currentResource.type}`,
          replaceAll: true,
        });

        // it will execute and set in actor when ever service dialog get open
        actorSetActionValue(
          'inputsInitialAppearanceCharacteristics',
          getServiceInputsInitialAppearanceCharacteristics(fields, serviceParams),
          {
            path: `${currentResource.value}.${currentResource.type}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}`,
          },
        );

        updateInputsState(inputsRef, {}, formData);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return <ServiceDialogFormView fields={fields} isServiceMode={isServiceMode} />;
});

export default ServiceDialogFormController;
