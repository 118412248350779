import { type FC } from 'react';
import Polyline from 'react-leaflet-arrowheads';
import { PolylinePropsInterface } from './leaflet-map.type';

const PolylineController: FC<PolylinePropsInterface> = props => {
  const { polyLines } = props;

  if (!polyLines || polyLines.length === 0) return <></>;
  return (
    <>
      {polyLines.map((startPoint, index) => {
        const endPoint = polyLines[index + 1];
        if (startPoint && endPoint) {
          const linePositions = [
            [startPoint.lat, startPoint.lng],
            [endPoint.lat, endPoint.lng],
          ];
          return (
            <Polyline
              positions={linePositions}
              arrowheads={{ size: '8px' }}
              smoothFactor={10}
              color={startPoint?.lineColor ?? `blue`}
              key={index}
            />
          );
        }
        return null;
      })}
    </>
  );
};

export default PolylineController;
