import React from 'react';
import { Chip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './todo-label.style';

import type { CustomChipInterface } from './todo-label.type';
import type { ReactElement } from 'react';

const CustomChip = (props: CustomChipInterface): ReactElement => {
  const { handleDeleteLabel, chipItem } = props;
  const color = chipItem.colorcode;

  const classes = useStyles({ color: color });

  return (
    <div className={classes.chipContainerItem}>
      <Chip
        className={classes.chipItem}
        label={chipItem.lablestitle}
        onDelete={handleDeleteLabel(chipItem)}
        deleteIcon={
          <CloseIcon fontSize="small" className={classes.closeIconColor} />
        }
      />
    </div>
  );
};

export default CustomChip;
