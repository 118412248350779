import { clone } from './data-helper';
import {
  actorGetActionValue,
  actorSetActionValue,
  FormKeyMode,
} from './../type/actor-setup';

/**
 * Set resource to stack and current.
 * @function setResource
 * @param {string} resource
 * @param {FormKeyMode} type
 * @returns {void}
 */
export const setResource = (resource: string, type: FormKeyMode): void => {
  const resourceStack = clone(actorGetActionValue('resources')?.stack ?? []);

  const newResourcesStack = [
    ...resourceStack,
    {
      value: resource,
      type,
    },
  ];

  actorSetActionValue(
    'resources',
    {
      stack: newResourcesStack,
      current: {
        value: resource,
        type,
      },
    },
    {
      callerScopeName: 'setResource',
    },
  );
};

/**
 * Remove last resource from stack and current.
 * @function removeLastResource
 * @param {string} resourceName
 * @param {FormKeyMode} type
 * @returns {void}
 */
export const removeLastResource = (type?: FormKeyMode): void => {
  const resourceStack = clone(actorGetActionValue('resources')?.stack ?? []);
  if (resourceStack.length === 0) return;

  if (type) {
    const filteredResourceStack = resourceStack.filter(
      resource => resource.type !== type,
    );

    actorSetActionValue(
      'resources',
      {
        stack: filteredResourceStack,
        current: filteredResourceStack[filteredResourceStack.length - 1],
      },
      {
        callerScopeName: 'removeLastResource 1',
      },
    );
  } else {
    resourceStack.pop();
    actorSetActionValue(
      'resources',
      {
        stack: resourceStack,
        current: resourceStack[resourceStack.length - 1],
      },
      {
        callerScopeName: 'removeLastResource 2',
      },
    );
  }
};

/**
 * returns module name and module table name or root resource as an array
 * @function getRootResource
 * @returns {[string | null, string | null]} [parentModule, parentTable]
 */
export const getRootResource = (): [string | null, string | null] => {
  const resources = actorGetActionValue('resources');
  if (!resources) return [null, null];

  const rootResource = resources.stack[0].value;
  const [parentModule, parentTable] = rootResource.split('/');
  return [parentModule, parentTable];
};
