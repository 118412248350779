import { type FC, useState, memo, useCallback, useEffect } from 'react';
import {
  MailActions,
  MailLabelType,
  OnCreateNewLabelParams,
  OnGetLabelsParams,
  OnSubmitLabelsParams,
} from '../../../../mail-section.type';
import { isEmptyObject } from '../../../../../../helper/data-helper';
import {
  actorOnDispatch,
  actorRemoveAction,
  FormKeyMode,
} from '../../../../../../type/actor-setup';
import { parseJSON } from '../../../../../../core/configProvider';

import { LabelActionInterface } from './label-action.type';
import LabelActionView from './label-action.view';

const LabelActionController: FC<LabelActionInterface> = memo(props => {
  const { mailActionsHandler } = props;

  const [labels, setLabels] = useState<MailLabelType[]>([]);
  const [isLabelOpen, onOpenLabel] = useState<(EventTarget & HTMLElement) | null>(
    null,
  );
  const [checkedLabels, setCheckedLabels] = useState<MailLabelType[]>([]);
  const [isNewLabelDialogOpen, setIsNewLabelDialogOpen] = useState<boolean>(false);

  /**
   * @function successGetLabelsCallback
   * @param { Record<string, unknown> } response
   * @returns { void }
   */
  const successGetLabelsCallback = (response: Record<string, unknown>): void => {
    setLabels(response?.data as MailLabelType[]);
  };

  useEffect(() => {
    const id = actorOnDispatch('mailSelected', item => {
      if (item.labelsarray) {
        const currentLabels = parseJSON<MailLabelType[]>(
          item.labelsarray as string,
        )!;
        setCheckedLabels(currentLabels);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'mailSelected',
        listenerId: id,
      });
    };
  }, []);

  /**
   * @function onLabelOpen
   * @param event
   * @returns { void }
   */
  const onLabelOpen = (event: any): void => {
    isEmptyObject(labels) &&
      mailActionsHandler &&
      mailActionsHandler(MailActions.onGetLabels, {
        successCallback: successGetLabelsCallback,
      } as OnGetLabelsParams);
    onOpenLabel(event.currentTarget);
  };

  /**
   * @function onLabelClose
   * @returns { void }
   */
  const onLabelClose = (): void => {
    onOpenLabel(null);
  };

  /**
   * @function checkIsSelectedLabel
   * @param { MailLabelType } label
   * @returns { boolean } boolean
   */
  const checkIsSelectedLabel = (label: MailLabelType): boolean => {
    return checkedLabels.some(item => item.lablestitle === label.lablestitle);
  };

  /**
   * @function handleChangeLabel
   * @param { MailLabelType } label
   * @returns { void }
   */
  const handleChangeLabel = (label: MailLabelType): void => {
    if (checkIsSelectedLabel(label)) {
      setCheckedLabels((prev: MailLabelType[]) => {
        return prev.filter(
          (item: MailLabelType) => item.lables_id !== label.lables_id,
        );
      });
    } else {
      setCheckedLabels((prev: MailLabelType[]) => [...prev, label]);
    }
  };

  /**
   * to submit labels
   * @function handleSubmitLabels
   * @returns { void }
   */
  const handleSubmitLabels = (): void => {
    mailActionsHandler &&
      mailActionsHandler(MailActions.onSubmitLabels, {
        LabelsArray: checkedLabels,
      } as OnSubmitLabelsParams);
  };

  /**
   * @function handleOpenNewLabelDialog
   * @returns { void }
   */
  const handleOpenNewLabelDialog = useCallback(() => {
    onOpenLabel(null);
    setIsNewLabelDialogOpen(true);
  }, [setIsNewLabelDialogOpen]);

  /**
   * @function handleCloseNewLabelDialog
   * @returns { void }
   */
  const handleCloseNewLabelDialog = useCallback(() => {
    setIsNewLabelDialogOpen(false);
    actorRemoveAction({
      actionName: 'formData',
      path: `automation/messages.${FormKeyMode.ROOT}`,
    });
  }, [setIsNewLabelDialogOpen]);

  /**
   * @function onSuccessNewLabelCallback
   * @returns { void }
   */
  const onSuccessNewLabelCallback = (): void => {
    handleCloseNewLabelDialog();
    mailActionsHandler &&
      mailActionsHandler(MailActions.onGetLabels, {
        successCallback: successGetLabelsCallback,
      } as OnGetLabelsParams);
  };

  /**
   * @function onCreateNewLabel
   * @returns { void }
   */
  const onCreateNewLabel = (): void => {
    mailActionsHandler &&
      mailActionsHandler(MailActions.onCreateNewLabel, {
        onSuccessNewLabelCallback: onSuccessNewLabelCallback,
      } as OnCreateNewLabelParams);
  };

  return (
    <LabelActionView
      mailActionsHandler={mailActionsHandler}
      labels={labels}
      isLabelOpen={isLabelOpen}
      onLabelClose={onLabelClose}
      onLabelOpen={onLabelOpen}
      handleChangeLabel={handleChangeLabel}
      handleSubmitLabels={handleSubmitLabels}
      isNewLabelDialogOpen={isNewLabelDialogOpen}
      handleOpenNewLabelDialog={handleOpenNewLabelDialog}
      handleCloseNewLabelDialog={handleCloseNewLabelDialog}
      checkIsSelectedLabel={checkIsSelectedLabel}
      onCreateNewLabel={onCreateNewLabel}
    />
  );
});

export default LabelActionController;
