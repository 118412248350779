import { API_URL, getValue } from '../../../core/configProvider';
import { actorDispatch } from '../../../type/actor-setup';

export default function UploadAdapterPlugin(resource) {
  return editor => {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new UploadAdapter(loader, resource);
    };
  };
}

class UploadAdapter {
  constructor(props, resource) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    this.resource = resource;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      const apiUrl = getValue(API_URL);
      this.loader.file.then(result => {
        actorDispatch('uploadStreamMultipleFile', {
          param: {
            resource: this.resource,
            files: [result],
          },
          successCallback: data => {
            resolve({
              default: `${apiUrl}/${data?.data?.filePath}`,
            });
          },
          failureCallback: (error)=>{ 
            console.log('error',error); 
            reject();
          },
        },
          {
            disableDebounce:true
          }
        );
      });
    });
  }

  abort() {
    //TODO Aborts the upload process.
  }
}
