import {
  HttpTransportType,
  HubConnectionBuilder,
  type HubConnection,
} from '@microsoft/signalr';

import { WEB_SOCKET_API_URL, getValue, USER_TOKEN } from '../core/configProvider';
import { isEmpty } from '../helper/data-helper';

export const NOTIFICATION_SIGNAL = 'notificationHubConnection';
export const CHAT_SIGNAL = 'chatHubConnection';

type SignalRConnectionType = typeof NOTIFICATION_SIGNAL | typeof CHAT_SIGNAL;

interface SignalRConnectionInterfaceProps<T = Record<string, unknown>> {
  connectionType: SignalRConnectionType;
  connectionUrl: string;
  userId: string;
  signalREvent: string;
  onEventCallback?: (...args: any[]) => void;
  args?: unknown[];
}

interface SignalRConnectionCache {
  [key: string]: HubConnection;
}

const isConnecting = {};
const pendingRequests: Array<
  [(value: HubConnection) => void, (reason?: any) => void]
> = [];

const signalRConnectionCache: SignalRConnectionCache = {};
const webSocketApiUrl = getValue(WEB_SOCKET_API_URL);

/**
 * Connects to a SignalR hub using the specified URL and user ID, and returns a Promise that resolves to a HubConnection object.
 *
 * @function connect
 * @param {string} connectionUrl The URL of the SignalR hub to connect to.
 * @param {string} userId The ID of the user connecting to the SignalR hub.
 * @param {SignalRConnectionType} connectionType The type of connection to establish.
 * @returns {Promise<HubConnection>} A Promise that resolves to a HubConnection object.
 * @throws {Error} If the connection could not be established.
 */
const connect = async (
  connectionUrl: string,
  userId: string,
  connectionType: SignalRConnectionType,
): Promise<HubConnection> => {
  let hubConnection = signalRConnectionCache[connectionType];
  if (hubConnection) {
    return hubConnection;
  }

  hubConnection = new HubConnectionBuilder()
    .withUrl(`${connectionUrl}`, {
      accessTokenFactory: () => getValue(USER_TOKEN),
      transport: HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .build();

  try {
    await hubConnection.start();
    signalRConnectionCache[connectionType] = hubConnection;
  } catch (err) {
    console.warn('signalR connection:', err);
  }

  isConnecting[connectionUrl] = false;
  while (pendingRequests.length > 0) {
    const request = pendingRequests.shift();

    if (request) {
      const [resolve, reject] = request;
      try {
        const connection = await connect(connectionUrl, userId, connectionType);
        resolve(connection);
      } catch (error) {
        reject(error);
      }
    }
  }
  return hubConnection;
};

/**
 * Connects to a SignalR hub using the specified URL and user ID, and returns a Promise that resolves to a HubConnection object.
 * If the specified connection type is already connected, returns the existing HubConnection object instead.
 *
 * @function handleConnectToSignalR
 * @param {string} connectionUrl The URL of the SignalR hub to connect to.
 * @param {string} userId The ID of the user connecting to the SignalR hub.
 * @param {SignalRConnectionType} connectionType The type of connection to establish.
 * @returns {Promise<HubConnection|null>} A Promise that resolves to a HubConnection object, or null if the connection could not be established.
 */
const handleConnectToSignalR = async (
  connectionUrl: string,
  userId: string,
  connectionType: SignalRConnectionType,
): Promise<HubConnection> => {
  const hubConnection = signalRConnectionCache[connectionType];

  if (hubConnection) {
    return hubConnection;
  }

  if (isConnecting[connectionUrl]) {
    return new Promise<HubConnection>((resolve, reject) => {
      pendingRequests.push([resolve, reject]);
    });
  }

  isConnecting[connectionUrl] = true;
  return connect(connectionUrl, userId, connectionType);
};

/**
 * to receive an event, connect to signalR if needed then
 * listen to that event and trigger the callback
 * @function getEventValue
 * @param { SignalRConnectionInterfaceProps } param0
 * @returns { Promise<void> }
 */
export async function getEventValue<T>(
  entires: SignalRConnectionInterfaceProps<T>,
): Promise<void> {
  // prettier-ignore
  const { connectionType, connectionUrl, userId, signalREvent, onEventCallback } = entires;

  if (isEmpty(webSocketApiUrl)) {
    console.warn('`getEventValue`: invalid `webSocketApiUrl` address ', {
      webSocketApiUrl,
      signalREvent,
    });
    return;
  }

  try {
    const signalRConnection = await handleConnectToSignalR(
      `${webSocketApiUrl}/${connectionUrl}`,
      userId,
      connectionType,
    );

    signalRConnection.on(signalREvent, (...args) => {
      console.info('signalREvent: message ', { signalREvent, args });
      onEventCallback?.(...args);
    });
  } catch (error) {
    console.error('Connection failed: ', error);
  }
}

/**
 * fire signal with signalR
 * @function fireSignal
 * @param {SignalRConnectionInterfaceProps} entires
 * @returns {Promise<void>}
 */
export async function fireSignal(
  entires: SignalRConnectionInterfaceProps,
): Promise<void> {
  const { connectionType, connectionUrl, userId, signalREvent, args = [] } = entires;

  console.log('fireSignal ', entires);

  if (isEmpty(webSocketApiUrl)) {
    console.warn('`fireSignal`: invalid `webSocketApiUrl` address ', {
      webSocketApiUrl,
      signalREvent,
    });
    return;
  }

  try {
    const signalRConnection = await handleConnectToSignalR(
      `${webSocketApiUrl}/${connectionUrl}`,
      userId,
      connectionType,
    );

    if (signalRConnection?.state !== 'Disconnected') {
      signalRConnection?.send(signalREvent, ...args);
    }
  } catch (fireSignalError) {
    console.error('fireSignalError: ', fireSignalError);
  }
}
