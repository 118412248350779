import BrushIcon from '@material-ui/icons/Brush';
import { IconButton } from '@material-ui/core';

import InputBase from '../../input-base';
import { DynamicChart } from '../../dynamic-chart';
import { useStyles } from './gadget-input.style';

import type { GadgetInputViewInterface } from './gadget-input.type';
import type { FC } from 'react';

export const GadgetInputView: FC<GadgetInputViewInterface> = props => {
  const {
    field,
    label,
    hint,
    inputMessage,
    onBrushIconClick,
    parsedValue,
    dynamicChartPreparedProps,
  } = props;

  const classes = useStyles({ messageType: inputMessage?.messageType });

  return (
    <InputBase
      required={field?.required}
      field={field}
      inputMessage={inputMessage}
      label={label}
      hint={hint}
    >
      <div className={classes.gadgetContainer}>
        <div className={classes.informationSection}>
          <IconButton onClick={onBrushIconClick} color="inherit">
            <BrushIcon />
          </IconButton>
        </div>

        <div className={classes.previewSection}>
          {parsedValue && dynamicChartPreparedProps ? (
            <DynamicChart {...dynamicChartPreparedProps} />
          ) : null}
          {/* it can be a default shape 👆 */}
        </div>
      </div>
    </InputBase>
  );
};
