import { useState, useEffect, memo, type FC } from 'react';
import { useTranslate } from 'react-admin';

import { clone } from '../../../helper/data-helper';
import { FormActions } from '../../form';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import ComputedInputView from './computed-input.view';
import { convertNumberWithCommas } from '../../../helper/InputHelper';

import type { ComputedInputProps } from './computed-input.type';
import type { ValidationErrorMessageType } from '../../../helper/Types';

const ComputedInputController: FC<ComputedInputProps> = memo(props => {
  const {
    formula,
    visibleClass,
    className,
    label,
    hint,
    formActionsHandler,
    field,
    getRef,
  } = props;

  const { name } = field;

  const [formData, setFormData] = useState({});
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  const translate = useTranslate();

  useEffect(() => {
    const currentResource = actorGetActionValue('resources')?.current;

    const onDispatchId = actorOnDispatch('formData', formData => {
      if (currentResource == null) return;

      if (formData?.[currentResource.value]?.[currentResource.type] != null) {
        setFormData(formData[currentResource.value][currentResource.type]);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'formData',
        listenerId: onDispatchId,
      });
    };
  }, []);

  useEffect(() => {
    if (!formData) {
      setError(translate('form.recordNotExist'));
    }
    // every formula must have a return statement
    else if (!formula || formula.indexOf('return') === -1) {
      setError(translate('form.formulaIsNotValid'));
    } else {
      try {
        const computed = new Function('formdata', `${formula}`);
        setValue(computed(clone(formData)));
      } catch (error) {
        setError(translate('form.errorInComputing'));
      }
    }
  }, [formData]);

  useEffect(() => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value,
    });
  }, [value]);

  const inputMessage = {
    message: error,
    messageType: 'error' as ValidationErrorMessageType,
  };

  return (
    <ComputedInputView
      visibleClass={visibleClass}
      className={className}
      value={convertNumberWithCommas('number', value)}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      name={name}
      getRef={getRef}
    />
  );
});

export default ComputedInputController;
