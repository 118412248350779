import { actorGetActionValue } from '../../../type/actor-setup';

import type {
  DataHighChartInterface,
  prepareAxisChartDataInterface,
} from './line-chart.type';

/**
 * @function { prepareAxisChartData } prepareAxisChartData
 * @param { entries } prepareAxisChartDataInterface
 * @returns { DataHighChartInterface } DataHighChartInterface
 */
export const prepareAxisChartData = (
  entries: prepareAxisChartDataInterface,
): DataHighChartInterface => {
  const { data, axisFieldName, valueFieldName, columns } = entries;

  const locale = actorGetActionValue('reactAdminHelpers')?.locale ?? 'fa';

  const label = columns?.find(column => column.name == valueFieldName?.[0])
    ?.translatedCaption?.[locale];

  const y: { name: string; data: number[] }[] = [{ name: label ?? '', data: [] }];
  const x: string[] = [];

  if (!axisFieldName) return { y: [], x: [] };
  const mergedData = groupDataByAxisName(data, axisFieldName, valueFieldName);
  const valueAxisFieldName = axisFieldName.toString();

  mergedData.forEach((reportRowInfo): void => {
    y[0].data.push(reportRowInfo[valueFieldName?.[0]] as number);

    x.push(String(reportRowInfo[valueAxisFieldName]));
  });

  return { y, x };
};

/**
 * @function {groupDataByAxisName} groupDataByAxisName
 * @param { Record<string, unknown>[] } data
 * @param { string } axisName
 * @param { string } valueFieldName
 * @returns {Record<string, unknown>[]}Record<string, unknown>[]
 */
export const groupDataByAxisName = (
  data: Record<string, unknown>[],
  axisName: string,
  valueFieldName: string[],
): Record<string, unknown>[] => {
  const finalData = {};

  data.forEach(row => {
    const axisValue = ('index_' + row[axisName]) as string;
    valueFieldName.map(valueField => {
      if (axisValue in finalData) {
        if (!isNaN(Number(row[valueField]))) {
          finalData[axisValue][valueField] =
            finalData[axisValue][valueField] + row[valueField];
        }
      } else {
        finalData[axisValue] = { ...row };
      }
    });
  });

  return Object.values(finalData);
};
