import { GET_ONE } from 'react-admin';

import { prepareOverrideParams } from '../component/form/form.helper';
import { getMetaFromApi } from '../container/NewMetaContext';
import { ADVANCE_PERMISSION, PERMISSION, WMS } from '../core/configRouteConstant';
import dataProvider from '../core/dataProvider';
import {
  isEmpty,
  isEmptyObject,
  isNetworkError,
  objectToLowerCaseProperties,
  separateRecordAndRelationRecord,
} from '../helper/data-helper';
import { showNotification } from '../helper/general-function-helper';
import { getParamFromUrl } from '../helper/UrlHelper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
  ResourcesInterface,
  actorDispatch,
} from '../type/actor-setup';

import type { MetaData } from '../helper/Types';

/**
 * check parent record and parent meta data exist in actor or not
 * and if there weren't one of them, request and set it to actor store
 * @function prepareParentMetaAndData
 * @param {Record<string,unknown>} record
 * @returns {Promise<void>}
 */
const prepareParentMetaAndData = async (
  record: Record<string, unknown>,
): Promise<void> => {
  const parentRoute = (record?.['__parentInfo'] as Record<string, unknown>)?.[
    'route'
  ] as string | undefined;

  if (parentRoute) {
    const [, moduleName, moduleTableName, id] = parentRoute.split('/');
    const parentResource = `${moduleName}/${moduleTableName}`;

    const parentMetaData = actorGetActionValue('metaData')?.[parentResource];

    const parentRecord =
      actorGetActionValue('record')?.[parentResource]?.[FormKeyMode.ROOT]?.[
        RecordKeyMode.FULL
      ];

    if (!parentMetaData || !parentRecord) {
      await getMetaDataAndRecordForSpecificResource({
        resource: parentResource,
        id,
      });
    }
  }
};

const changeResourcesHandler = async (
  resources: ResourcesInterface,
): Promise<void> => {
  if (
    resources?.current == null ||
    resources.current.value === '/' ||
    resources.current.value.includes(WMS) ||
    resources.current.value == PERMISSION ||
    resources.current.value == ADVANCE_PERMISSION
  ) {
    return;
  }

  actorDispatch('showLoading', true);

  // FIXME: We have to use this `setTimeout` because of `PrivateRoute` behavior
  const currentResource = resources.current;

  const urlInfo = actorGetActionValue('urlInfo')!;

  if (currentResource.type === FormKeyMode.DROPDOWN) {
    //TODO: Complete this section;
    actorDispatch('loading', false, {
      path: currentResource.value,
    });
    return;
  }

  let metaData = actorGetActionValue(
    'metaData',
    currentResource.value,
  ) as MetaData | null;

  let hasNetworkError = false;

  if (metaData == null) {
    try {
      const allMetaData = await getMetaFromApi(currentResource.value);
      metaData = allMetaData.find(
        item => item.name === currentResource.value.toLowerCase(),
      )?.meta;

      if (hasNetworkError) hasNetworkError = false;
    } catch (error) {
      console.log('getMetaFromApi Error: %o', error);
      showNotification(error, 'error');

      if (isNetworkError(error) && !hasNetworkError) hasNetworkError = true;
    }
  }

  actorDispatch('metaData', hasNetworkError ? { error: 'networkError' } : metaData, {
    path: currentResource.value,
  });

  actorDispatch('showLoading', false);
};

actorOnDispatch('resources', changeResourcesHandler);

actorOnDispatch('urlInfo', async urlInfo => {
  if (isEmptyObject(urlInfo)) return;

  let prevParentResource = '';
  let prevParentId = '';

  const { location } = urlInfo;
  const splitLocation = location?.hash.split('?');
  const search = splitLocation[1] ?? '';
  const parentResource = getParamFromUrl(search, 'parentResource');
  const parentId = getParamFromUrl(search, 'parentId');

  if (
    !isEmpty(parentResource) &&
    !isEmpty(parentId) &&
    parentResource !== prevParentResource &&
    parentId !== prevParentId
  ) {
    const { data: responseData } = await dataProvider(GET_ONE, parentResource, {
      id: parentId,
    });

    const _data = objectToLowerCaseProperties(responseData, parentId);

    prevParentResource = parentResource!;
    prevParentId = parentId!;

    actorSetActionValue('record', _data, {
      path: `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FORM}`,
    });
  }
});

// trigger when edit button in relation panel clicked

actorOnDispatch('quickDialog', async details => {
  if (isEmptyObject(details)) return;
  if (details.relationEditIsOpen) {
    const { resource, parentId, id } = details?.data?.['data'];

    actorDispatch('loading', true, {
      path: `${resource}-edit`,
    });
    if (!isEmpty(resource) && !isEmpty(id)) {
      const responseData = await dataProvider(GET_ONE, resource, {
        id,
      });
      const _data = objectToLowerCaseProperties(responseData.data, parentId);

      actorDispatch('record', {
        [resource]: {
          [FormKeyMode.RELATION]: {
            [RecordKeyMode.FULL]: _data,
            [RecordKeyMode.FORM]: _data,
            [RecordKeyMode.PARENT_RECORD]: { parentRecord: parentId },
          },
        },
      });
      _data &&
        actorDispatch('loading', false, {
          path: `${resource}-edit`,
        });
    }
  }
});

/**
 * @function setDocumentTitle
 * @param {MetaData} metaData
 * @param {string} prepareRecordName
 */
actorOnDispatch('setDocumentTitle', ({ metaData, recordTitle, locale }) => {
  if (isEmpty(metaData) && isEmpty(recordTitle)) return;
  let title = '';

  if (!isEmptyObject(metaData?.translatedTitle)) {
    title = metaData?.translatedTitle?.[locale];
  } else if (!isEmptyObject(metaData?.config?.translatedCaption)) {
    title = metaData.config?.translatedCaption?.[locale];
  }

  if (!isEmpty(recordTitle)) {
    title += ' ' + recordTitle;
  }

  if (!isEmpty(title)) {
    document.title = title;
  }
});

/**
 * request meta data record for an specific resource
 * @function getMetaDataAndRecordForSpecificResource
 * @param entries {
 *   @param {string} resource
 *   @param {string | number} id
 *   @param {() => void} successCallback
 *   @param {() => void} failureCallback
 * }
 * @returns {Promise<void>}
 */
export const getMetaDataAndRecordForSpecificResource = async (entries: {
  resource: string;
  id: string | number;
  successCallback?: () => void;
  failureCallback?: () => void;
}): Promise<void> => {
  const { resource, id, successCallback, failureCallback } = entries;
  let hasNetworkError = false;

  try {
    let metaData = actorGetActionValue('metaData', resource) as MetaData | null;

    if (metaData == null) {
      try {
        const allMetaData = await getMetaFromApi(resource);
        metaData = allMetaData.find(
          item => item.name === resource.toLowerCase(),
        )?.meta;

        if (hasNetworkError) hasNetworkError = false;
      } catch (error) {
        console.log('getMetaFromApi Error: %o', error);
        showNotification(error, 'error');

        if (isNetworkError(error) && !hasNetworkError) hasNetworkError = true;
        throw { error, hasNetworkError };
      }
    }

    actorSetActionValue(
      'metaData',
      hasNetworkError ? { error: 'networkError' } : metaData,
      {
        path: resource,
      },
    );

    const record = actorGetActionValue(
      'record',
      `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
    );

    if (!record) {
      const responseData = await dataProvider(GET_ONE, resource, {
        id,
      });
      const _data = objectToLowerCaseProperties(responseData.data, id);

      actorSetActionValue('record', _data, {
        path: `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
      });
    }

    successCallback?.();
  } catch (error) {
    console.error('error: ', error);
    failureCallback?.();
  }
};
