import { parseJSON } from '../../../core/configProvider';
import { getParamFromUrl } from '../../../helper/UrlHelper';
import { FilterItemFinalFormatType } from '../../filter-form';
import { DASHBOARD_FILTER } from '../dashboard-page.helper';

/**
 * @function getDashboardFilterFromUrl
 * @returns {FilterItemFinalFormatType}
 */
export const getDashboardFilterFromUrl = (): FilterItemFinalFormatType[] => {
  const currentFilter = getParamFromUrl(location.href, DASHBOARD_FILTER) ?? '[]';
  return parseJSON(currentFilter) ?? [];
};
