import {
  RelationPanelControllerInterface,
  RelationInMetaData,
  RelationPanelControllerAllRelationInterface,
} from '../relation-panel';
import { GET_ONE } from 'react-admin';

import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
  ApiRequestResultInterface,
  FormKeyMode,
  RecordKeyMode,
  ResourceInterface,
} from '../../type/actor-setup';
import { isEmptyObject } from '../../helper/data-helper';
import type { GeneralMetaData, MetaData } from '../../helper/Types';
import {
  getFileInfo,
  getNoteInfo,
  getRelationList,
  mergeTabDataWithSetting,
  getSingleRecordReportRelationList,
} from '../../helper/MetaHelper';

import type {
  CustomRefreshParamsInterface,
  TabInterface,
} from './show-record-with-relation.type';
import {
  getInputsInitialAppearanceCharacteristics,
  getMetaDataFromActorOrNetwork,
  getShowTabList,
} from '../../helper/meta-helper';
import { ProcessInformation } from '../form';
import {
  apiRequestFailureResultHandler,
  apiRequestResultHandler,
} from '../../helper/crud-api.helper';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../../helper/settings-helper';
import { applyUserSortOnSubPanels } from '../relation-panel/relation-panel-sort.helper';

/**
 * it change som properties of one to one tab by reference
 * @param tab
 * @returns
 */
const prepareOneToOneTabData = (
  tab: TabInterface,
  record: Record<string, unknown>,
): void => {
  const oneToOneRelationResource = `${tab.moduleName}/${tab.tableName}`;

  const oneToOneRelationMeta = actorGetActionValue(
    'metaData',
    oneToOneRelationResource,
  )! as unknown as MetaData;

  if (!oneToOneRelationMeta) {
    tab['tableRelationList'] = [];
    tab['reportRelationList'] = [];
    tab['fileRelation'] = [];
    tab['noteRelation'] = null;
    return;
  }

  tab['tableRelationList'] = getRelationList(oneToOneRelationMeta, {
    processuniqueid: record.__processuniqueid,
    positionid: record.positionid,
    stateid: record.stateid,
  });

  tab['reportRelationList'] = getSingleRecordReportRelationList(
    oneToOneRelationMeta,
    {
      processuniqueid: record.__processuniqueid,
      positionid: record.positionid,
      stateid: record.stateid,
    },
  );
  tab['fileRelation'] = getFileInfo(oneToOneRelationMeta);
  tab['noteRelation'] = getNoteInfo(oneToOneRelationMeta);
};

/**
 * prepare fields list based on metadata, setting, current state
 * @function prepareViewFields
 * @returns {void}
 */
export const prepareViewFields = (
  record: Record<string, unknown>,
  metaData: GeneralMetaData,
): Array<TabInterface> => {
  const { __processuniqueid: processuniqueid, positionid, stateid } = record;

  const columnCount = metaData.config?.columnCount ?? 0;
  const relationList = getRelationList(metaData, {
    processuniqueid,
    positionid,
    stateid,
  });

  const reportRelationList: Array<Record<string, unknown>> | null =
    getSingleRecordReportRelationList(metaData, {
      processuniqueid: record.__processuniqueid,
      positionid: record.positionid,
      stateid: record.stateid,
    });

  const noteRelation = getNoteInfo(metaData);

  const processInfo = {
    processuniqueid: record.__processuniqueid as string | null,
    positionid: record.positionid as string | null,
    stateid: record.stateid as string | null,
  };

  const rawTabs = getShowTabList({
    metaData: metaData as GeneralMetaData,
    defaultColumnCount: columnCount,
    processInfo,
  });

  const tempPreparedGroupList: Array<TabInterface> = mergeTabDataWithSetting(
    metaData,
    rawTabs,
    relationList,
    reportRelationList as Array<Record<string, unknown>> | undefined,
    noteRelation,
  );

  tempPreparedGroupList?.forEach(tab => {
    if (tab.isOneToOne) {
      prepareOneToOneTabData(tab, record);
    }
  });

  return tempPreparedGroupList;
};

const extractRelationsFromTabList = (tabList: Array<TabInterface>) => {
  const tableRelations: Array<RelationInMetaData> = [];
  const reportRelations: Array<RelationInMetaData> = [];
  const noteRelations: Array<RelationInMetaData> = [];

  tabList?.forEach((tab: TabInterface) => {
    const { tableRelationList, reportRelationList, noteRelation } = tab;
    if (Array.isArray(tableRelationList)) {
      tableRelationList.forEach(tableRelation => {
        tableRelations.push({
          ...tableRelation,
          isOneToOneRelation: tab.isOneToOne,
        });
      });
    }

    if (Array.isArray(reportRelationList)) {
      reportRelationList.forEach(reportRelation => {
        reportRelations.push({
          ...reportRelation,
          isOneToOneRelation: tab.isOneToOne,
        });
      });
    }

    if (!isEmptyObject(noteRelation)) {
      noteRelations.push({
        ...noteRelation!,
        isOneToOneRelation: tab.isOneToOne,
      });
    }
  });

  return [tableRelations, reportRelations, noteRelations];
};

export const prepareRelationList = (
  tabList: Array<TabInterface>,
  parentResource: string,
  parentRecordId: string | undefined,
  processInfo: ProcessInformation,
): RelationPanelControllerAllRelationInterface => {
  const preparedRelations: Array<RelationPanelControllerInterface> = [];

  const [tableRelations, reportRelations, noteRelations] =
    extractRelationsFromTabList(tabList);

  tableRelations.forEach(tableRelation => {
    const relationProps: RelationPanelControllerInterface = {
      relationItemInMetaData: tableRelation,
      relationType: tableRelation.isOneToOneRelation ? 'oneToOneTable' : 'table',
      parentResource,
      parentRecordId,
      processInfo,
      id: `${tableRelation.moduleName}/${tableRelation.moduleTableName}`,
    };
    preparedRelations.push(relationProps);
  });

  reportRelations.forEach(reportRelation => {
    const isMultiReport =
      reportRelation?.['reportType'] === 'MultiResult' ||
      reportRelation?.['reportType'] === 'ParentChild';

    const relationProps: RelationPanelControllerInterface = {
      relationItemInMetaData: reportRelation,
      relationType: isMultiReport ? 'multiReport' : 'report',
      parentResource,
      parentRecordId,
      processInfo,
      id: `report/${reportRelation.id}`,
    };
    preparedRelations.push(relationProps);
  });

  noteRelations.forEach(noteRelation => {
    const relationProps: RelationPanelControllerInterface = {
      relationItemInMetaData: noteRelation,
      relationType: 'note',
      parentResource,
      parentRecordId,
      processInfo,
      id: `${noteRelation.moduleName}/${noteRelation.moduleTableName}`,
    };
    preparedRelations.push(relationProps);
  });

  return applyUserSortOnSubPanels(parentResource, preparedRelations);
};

const customRefreshCallback = async (
  response,
  successCallback: ((response: Record<string, unknown>) => void) | undefined,
  failureCallback: (() => void) | undefined,
): Promise<void> => {
  // check for parent information :
  const parentRout =
    response?.GET_ONE?.showRecordPage?.data?.data?.__parentInfo?.route;

  if (typeof parentRout === 'string') {
    const [, module, moduleTable, recordId] = parentRout.split('/');
    const resource = `${module}/${moduleTable}`;

    await getMetaDataFromActorOrNetwork(resource);

    const parentRecord = actorGetActionValue('record');

    if (
      isEmptyObject(
        parentRecord?.[resource]?.[FormKeyMode.ROOT]?.[RecordKeyMode.FULL],
      )
    ) {
      actorDispatch(
        'crudAction',
        {
          type: GET_ONE,
          resource,
          recordId,
          entity: 'parentRecord',
          onSuccess: (record): void => {
            const parentRecord = record?.GET_ONE?.parentRecord?.data?.data;

            actorSetActionValue('record', parentRecord, {
              path: `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
            });

            successCallback?.(response[GET_ONE]['showRecordPage'].data);
            apiRequestResultHandler(response);
          },
          onFailure: error => {
            apiRequestFailureResultHandler(error, GET_ONE, 'showRecordPage');
            failureCallback?.();
          },
        },
        {
          disableDebounce: true,
          replaceAll: true,
          callerScopeName: 'customRefreshCallback',
        },
      );
    } else {
      successCallback?.(response[GET_ONE]['showRecordPage'].data);
      apiRequestResultHandler(response);
    }
  } else {
    successCallback?.(response[GET_ONE]['showRecordPage'].data);
    apiRequestResultHandler(response);
  }
};

/**
 * @function customRefresh
 * @param {CustomRefreshParamsInterface} params
 * @returns {void} void
 */
export const customRefresh = (params: CustomRefreshParamsInterface): void => {
  const {
    resource,
    recordId,
    disableNotification,
    successCallback,
    failureCallback,
  } = params;
  actorDispatch(
    'crudAction',
    {
      type: GET_ONE,
      entity: 'showRecordPage',
      disableNotification,
      resource,
      recordId,
      onSuccess: (response): void => {
        customRefreshCallback(response, successCallback, failureCallback);
      },
      onFailure: (error: ApiRequestResultInterface): void => {
        apiRequestFailureResultHandler(error, GET_ONE, 'showRecordPage');
        failureCallback?.();
      },
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'customRefresh',
    },
  );
};

/**
 * @function prepareShowFieldsCharacteristics
 * @param { MetaData } metaData
 * @param { ResourceInterface } currentResource
 * @param { Record<string, unknown> } currentRecord
 * @returns {void} void
 */
export const prepareShowFieldsCharacteristics = (
  metaData: MetaData,
  currentResource: ResourceInterface | undefined,
  currentRecord: Record<string, unknown>,
): void => {
  let parentMetaData;
  let parentRecord;

  if (!currentResource) return;

  const parentRoute = (currentRecord?.['__parentInfo'] as Record<string, unknown>)?.[
    'route'
  ] as string | undefined;

  if (parentRoute) {
    const [, moduleName, moduleTableName] = parentRoute.split('/');
    const parentResource = `${moduleName}/${moduleTableName}`;

    parentMetaData = actorGetActionValue('metaData', parentResource);
    parentRecord = actorGetActionValue(
      'record',
      `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
    );
  }

  const inputsInitialAppearanceCharacteristics =
    getInputsInitialAppearanceCharacteristics({
      currentMetaData: metaData as GeneralMetaData,
      currentRecord,
      parentMetaData,
      parentRecord,
    }) ?? {};

  actorSetActionValue(
    'inputsInitialAppearanceCharacteristics',
    inputsInitialAppearanceCharacteristics,
    {
      path: `${currentResource.value}.${currentResource.type}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}`,
    },
  );
};
