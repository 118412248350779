import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from './../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  mainContainer: {
    width: '100%',
    maxHeight: '100%',
  },

  /**
   * header style
   */
  headerContainer: {
    width: '100%',
    height: '35px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
    },
  },
  taskTitle: {
    flexGrow: 1,
    fontWeight: 400,
    outline: 'none',
    border: 'none',
    padding: 5,
    height: 30,
    borderRadius: theme.shape.borderRadius,
    fontSize: '1rem',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    fontFamily: theme.typography.fontFamily,
  },
  taskTitleInput: {
    flexGrow: 1,
    fontWeight: 600,
    outline: 'none',
    border: 'none',
    padding: 5,
    height: 30,
    borderRadius: theme.shape.borderRadius,
    fontSize: '1rem',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    fontFamily: theme.typography.fontFamily,
  },
  /**
   * list style
   */
  stepListContainer: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'none',
  },

  /**
   * add form style
   */
  inputContainer: {
    width: '100%',
    height: '35px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
    },
  },
  addIcon: {
    margin: '0 10px 0 10px',
  },
  defaultIcon: {
    color: theme.palette.secondary.main,
    marginLeft: '0px',
  },
}));
