import lodashMerge from 'lodash/merge';

import {
  actorGetActionValue,
  RecordKeyMode,
  actorSetActionValue,
  actorDispatch,
} from './../type/actor-setup';
import {
  arrayResultToObjectWithLowerCaseForDropDown,
  clone,
  isEmpty,
} from './../helper/data-helper';
import { GET_DROPDOWN } from '../core/data-Provider.helper';
import { isEmptyObject } from '../helper/data-helper';
import { actorOnDispatch } from '../type/actor-setup';
import { getDropdownRequestParams } from '../helper/dropdown-api.helper';
import { FormData } from '../component/form';
import { showNotification } from '../helper/general-function-helper';
import dataProvider from '../core/dataProvider';
import { formatFilterFormData } from '../component/filter-form/filter-form.helper';

actorOnDispatch('fetchDropdownData', async toFetchDropdownData => {
  const {
    option = {},
    dropdownMeta,
    resource,
    resourceType,
    searchValue,
    hasMore,
    successCallback,
    failureCallback,
    exactRequestParams,
  } = toFetchDropdownData;

  if (exactRequestParams) {
    await requestDropdownData({
      dropdownMeta,
      hasMore,
      finalParams: exactRequestParams,
      option,
      successCallback,
      failureCallback,
    });
    return;
  }

  const {
    dropdownInPuzzleForm = false,
    isTodo = false,
    isProfile = false,
    isService = false,
    isFilter = false,
    perPage,
    page,
    forceTreeLevel,
    filterValues,
    sort,
    fieldName,
  } = option;

  const record = clone(
    actorGetActionValue('record', [resource, resourceType, RecordKeyMode.FULL]),
  ) as Record<string, unknown>;

  let formData: FormData | null = clone(
    actorGetActionValue('formData', `${resource}.${resourceType}`),
  );

  if (dropdownInPuzzleForm && formData == null) {
    const rootResource = actorGetActionValue('resources')!.stack[0];
    formData = actorGetActionValue(
      'formData',
      `${rootResource.value}.${rootResource.type}`,
    );
  }
  if (isFilter) {
    formData = formatFilterFormData(
      clone(actorGetActionValue('filterFormFields')?.[resource] ?? {}),
    );
  }

  const myDynamicCondition =
    dropdownInPuzzleForm || isTodo || isProfile || isService || isFilter;

  const checker = (result, condition = myDynamicCondition) =>
    condition ? undefined : result;

  const finalDropId = checker(dropdownMeta.uniqueId ?? dropdownMeta.id);

  const params = {
    dropdownId: finalDropId,
    dropdownResource: checker(resource),
    fieldName: checker(fieldName),
    dropdownValueMember: dropdownMeta?.valueMember,
  };

  let finalRecord: FormData | undefined;
  if (dropdownInPuzzleForm) {
    finalRecord = formData ?? {};
  } else {
    if (!isEmptyObject(formData)) {
      finalRecord = formData!;
    } else {
      finalRecord = record;
    }
  }

  const finalParams = lodashMerge(
    getDropdownRequestParams({
      dropdownMeta,
      record: finalRecord ?? {},
      perPage: !isEmpty(perPage) ? (perPage as number) : null,
      page: !isEmpty(page) ? (page as number) : null,
      forceTreeLevel: !isEmpty(forceTreeLevel) ? (forceTreeLevel as boolean) : null,
      filterValues: Array.isArray(filterValues)
        ? (filterValues as (string | string[])[])
        : [],
      sort: !isEmptyObject(sort) ? (sort as Record<string, unknown>) : {},
      search: searchValue ?? '',
      isWMS: dropdownInPuzzleForm as boolean,
      resource,
      isFilter: (option.isFilter as boolean) ?? false,
    }),
    params,
  );

  requestDropdownData({
    dropdownMeta,
    hasMore,
    finalParams,
    option,
    successCallback,
    failureCallback,
  });
});

/**
 * Calls data provider and fetch data, then calls `actorDispatch`
 * @function requestDropdownData
 * @param entries
 */
const requestDropdownData = async (entries): Promise<void> => {
  const {
    dropdownMeta,
    finalParams,
    hasMore,
    option,
    successCallback,
    failureCallback,
  } = entries;

  try {
    const {
      data,
      totalCount: total,
      userMessage,
      additionalData,
    } = await dataProvider(GET_DROPDOWN, dropdownMeta.uniqueId ?? dropdownMeta.id, {
      ...(finalParams as Record<string, unknown>),
    });

    const _data = arrayResultToObjectWithLowerCaseForDropDown(
      data,
      dropdownMeta.valueMember ?? finalParams.fieldName,
    );

    if (option.dropdownInPuzzleForm && !isEmpty(userMessage)) {
      showNotification(userMessage, 'info');
    }

    if (
      option.dropdownInPuzzleForm
      // `type` equals `SearchDialog` in some cases that is different with `field.dataType.erp` value
      // && dropdownMeta?.type?.toLowerCase() === 'searchdialog'
    ) {
      const currentResource = actorGetActionValue('resources')!.current;

      actorDispatch(
        'dropDownData',
        { data: _data[0], wmsSubmit: additionalData?.WmsSubmit },
        {
          path: `${currentResource.value}.${currentResource.type}`,
          replaceAll: true,
        },
      );

      return;
    }

    const dropDownKeyInActor = `${dropdownMeta.uniqueId}-${option.fieldId}`;
    const allDropDownData = actorGetActionValue('dropDownData') ?? {};

    if (hasMore === true) {
      //prettier-ignore
      const currentDropDownData = allDropDownData[dropDownKeyInActor]?.DATA ?? [];
      allDropDownData[dropDownKeyInActor] = {
        ALL: [...currentDropDownData, ..._data],
        DATA: [...currentDropDownData, ..._data],
        TOTAL: total,
      };
    } else {
      allDropDownData[dropDownKeyInActor] = {
        ALL: _data,
        DATA: _data,
        TOTAL: total,
      };
    }
    actorDispatch('dropDownData', allDropDownData, {
      disableDebounce: true,
      replaceAll: true,
    });

    successCallback?.(allDropDownData[dropDownKeyInActor]);
  } catch (error) {
    console.error(error);
    failureCallback?.({ result: null, error });
  }
};
