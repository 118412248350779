import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, { isRtl: boolean }>(
  (theme: CustomTheme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: 645,
      height: 300,
      padding: 5,
      border: `1px dotted #1a85fe`,
      borderRadius: 7,
      background: '#56305a14',
    },
    FormControl: {
      height: '100%',
    },
    RadioGroup: {
      height: '100%',
    },
    row: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    label: {
      marginInline: 10,
      color: 'gray',
    },
    numberInput: {
      width: 50,
      marginInline: 5,
      textAlign: 'center',
    },
    activeText: {
      color: 'black',
    },
  }),
);
