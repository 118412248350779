import { ReactElement } from 'react';
import { MultiValueViewInterface } from './multi-value.type';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';

const MultiValueView = (props: MultiValueViewInterface): ReactElement => {
  const { children, removeProps, isFocused, selectProps } = props;
  const { classes } = selectProps;

  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(classes.chip, {
        [classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={
        <Icon {...removeProps} fontSize="small" className={classes.chipIcon}>
          close
        </Icon>
      }
    />
  );
};

export default MultiValueView;
