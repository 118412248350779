import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CustomTheme } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  userInfoRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 16,
    width: '100%',
  },

  customBadge: {
    backgroundColor: blue[300],
    color: '#fff',
  },
  avatar: {
    width: 40,
    height: 40,
    '& .MuiAvatar-img': {
      objectFit: 'unset',
      borderRadius: '50%',
      width: '150%',
      height: '120%',
      filter: 'saturate(140%)',
    },
  },
  avatarRoot: {
    minWidth: 0,
    position: 'relative',
  },
  userStatus: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 1,
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid white`,
  },
  listItemRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 24,
    paddingRight: 24,
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'all .2s ease',
    transform: 'scale(1)',
    '&.active': {
      backgroundColor: alpha(theme.palette.primary.main, 0.07),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      transform: 'translateY(-2px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '75%',
  },
  groupTitle: {
    width: '100%',
    paddingInlineEnd: 10,
  },
  onlineIcon: {
    fontSize: 15,
    color: '#08a318',
    border: '2px solid #0db81e',
    borderRadius: 10,
  },
}));
