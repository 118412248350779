import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

/**
 * source => https://devtrium.com/posts/how-keyboard-shortcut
 * @function useKeyPress
 * @param { string[] } keys
 * @param { (event: KeyboardEvent) => void } callback
 * @param { null } node
 * @returns { void } void
 */
const useKeyPress = (
  keys: string[],
  callback: (event: KeyboardEvent) => void,
  isWithAltKey = false,
): void => {
  // implement the callback ref pattern
  const callBackRef = useRef(callback);
  useLayoutEffect(() => {
    callBackRef.current = callback;
  });
  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want to listen to
      if (keys.some(key => event.key === key)) {
        if (isWithAltKey && event.altKey) {
          callBackRef.current(event);
        } else if (!isWithAltKey) {
          callBackRef.current(event);
        }
      }
    },
    [keys],
  );

  useEffect(() => {
    // target is either the provided node or the document
    // attach the event listener
    window.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);
};

export default useKeyPress;
