import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

import { MailActions } from '../mail-section.type';
import ArchivedIcon from '../../../icon/ArchivedIcon';
import DraftIcon from '../../../icon/DraftIcon';
import ImportantIcon from '../../../icon/ImportantIcon';
import InboxIcon from '../../../icon/InboxIcon';
import OutboxIcon from '../../../icon/OutboxIcon';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import StartIcon from '../../../icon/StartIcon';
import { RoutesConfigInterface } from '../../app-drawer/navigation-container';
import { generatePersonalFolders } from './mail-sidebar.helper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import { FolderName } from '../mail-section.helper';
import {
  DISABLE_MAIL_AND_TODO_MODULE,
  getValue,
} from '../../../core/configProvider';
import MailSidebarView from './mail-sidebar.view';

const MailSidebarController: FC = memo(props => {
  const translate = useTranslate();

  const inboxFolderCountId = 1;
  const draftFolderCountId = 3;

  const [dynamicMailFolders, setDynamicMailFolders] = useState<
    RoutesConfigInterface[]
  >([]);
  const [userCreatedFolders, setUserCreatedFolders] = useState<
    RoutesConfigInterface[]
  >([]);

  const [inputBoxUnreadCount, setInputBoxUnreadCount] = useState<number | undefined>(
    undefined,
  );
  const [draftBoxUnreadCount, setDraftBoxUnreadCount] = useState<number | undefined>(
    undefined,
  );

  /**
   * @function onOpenNewFolderOpen
   * @returns { void }
   */
  const onOpenNewFolderForm = (): void => {
    const mailActionsHandler = actorGetActionValue('mailActionsHandler')!;
    mailActionsHandler && mailActionsHandler(MailActions.onOpenNewFolderForm);
  };

  useEffect(() => {
    const mailActionsHandler = actorGetActionValue('mailActionsHandler')!;
    const id = actorOnDispatch('mailFolders', mailFolders => {
      if (!Array.isArray(mailFolders)) return;

      const inboxFolder = mailFolders.find(
        folder => folder.folders_id === inboxFolderCountId,
      );

      setInputBoxUnreadCount(inboxFolder?.itemcount);

      const DraftFolder = mailFolders.find(
        folder => folder.folders_id === draftFolderCountId,
      );

      setDraftBoxUnreadCount(DraftFolder?.itemcount);

      const filteredMailFolders = mailFolders.filter(
        folder => folder.folders_id !== inboxFolderCountId && folder.folders_id < 0,
      );

      //mailActionsHandler already exist
      setDynamicMailFolders(
        generatePersonalFolders(filteredMailFolders, mailActionsHandler),
      );

      const userNewFolders = mailFolders.filter(
        folder =>
          folder.folders_id !== draftFolderCountId &&
          folder.folders_id !== inboxFolderCountId &&
          folder.folders_id > 0,
      );

      //mailActionsHandler already exist
      setUserCreatedFolders(
        generatePersonalFolders(userNewFolders, mailActionsHandler),
      );
    });

    return () => {
      actorRemoveAction({
        actionName: 'mailFolders',
        listenerId: id,
      });
    };
  }, []);

  const isMailDisabled = getValue(DISABLE_MAIL_AND_TODO_MODULE);
  // sidebar menu config
  const mailSidebarRoutesConfig: RoutesConfigInterface[] = isMailDisabled
    ? [...dynamicMailFolders]
    : [
        ...dynamicMailFolders,
        {
          id: 'folders',
          title: `${translate('mail.message')}`,
          type: 'collapse',
          dataTest: 'static-folders-foldername-messages',
          children: [
            {
              id: 'inbox',
              title: `${translate('mail.docType.inbox')}`,
              type: 'item',
              icon: <InboxIcon color="#fff" />,
              url: `/oa?module=mail&${FolderName}=inbox`,
              dataTest: 'static-folder-inbox',
              count: inputBoxUnreadCount,
            },
            {
              id: 'outbox',
              title: `${translate('mail.docType.outbox')}`,
              type: 'item',
              icon: <OutboxIcon />,
              url: `/oa?module=mail&${FolderName}=outbox`,
              dataTest: 'static-folder-outbox',
            },
            {
              id: 'unread',
              title: `${translate('mail.docType.unread')}`,
              type: 'item',
              icon: <MailOutlineIcon color="action" />,
              url: `/oa?module=mail&${FolderName}=unread`,
              dataTest: 'static-folder-unread',
            },
            {
              id: 'archived',
              title: `${translate('mail.docType.archived')}`,
              type: 'item',
              icon: <ArchivedIcon />,
              url: `/oa?module=mail&${FolderName}=archived`,
              dataTest: 'static-folder-archived',
            },
            {
              id: 'starred',
              title: `${translate('mail.docType.starred')}`,
              type: 'item',
              icon: <StartIcon />,
              url: `/oa?module=mail&${FolderName}=starred`,
              dataTest: 'static-folder-starred',
            },
            {
              id: 'important',
              title: `${translate('mail.docType.important')}`,
              type: 'item',
              icon: <ImportantIcon />,
              url: `/oa?module=mail&${FolderName}=important`,
              dataTest: 'static-folder-important',
            },
            {
              id: 'draft',
              title: `${translate('mail.docType.draft')}`,
              type: 'item',
              icon: <DraftIcon />,
              url: `/oa?module=mail&${FolderName}=draft`,
              dataTest: 'static-folder-drafts',
              count: draftBoxUnreadCount,
            },
            {
              id: 'create-new-folder',
              title: translate('mail.createFolder'),
              type: 'item',
              onClick: onOpenNewFolderForm,
              icon: <AddIcon />,
              url: '#',
              dataTest: 'static-folder-drafts',
            },
            ...userCreatedFolders,
          ],
        },
      ];

  return (
    <MailSidebarView
      mailSidebarRoutesConfig={mailSidebarRoutesConfig}
      isMailDisabled={isMailDisabled}
    />
  );
});

export default MailSidebarController;
