import { useRef, type ForwardedRef, type ReactElement } from 'react';
import { useLocale } from 'react-admin';

import { useStyles } from './form-with-tab.style';
import { FormWithTabsViewProps } from '../form-with-tabs';

import { RelationActionTopToolbar } from '../../relation-action-top-toolbar';
import { getRelationsInForm } from '../../../helper/MetaHelper';
import { PinnedNotesController } from '../../relation-panel/note-relation/pinned-note';
import {
  RelationList,
  RelationListCustomFunctions,
} from '../../show-record-with-relation/relation-list';
import { RecordEditDetails } from '../../record-edit-details';
import { TransparentLoadingBox } from '../../LoadingBox';

const FormWithTabsView = (props: FormWithTabsViewProps): ReactElement | null => {
  const {
    tabList = [],
    showRelations = false,
    selectedTab,
    metaData,
    relations,
    tabsTitlesRef,
    tabChangeHandler,
    match,
    hasRelationNote,
    processInfo,
    loading,
    resource,
  } = props;

  const classes = useStyles();
  const locale = useLocale();

  const relationLitsRef = useRef<RelationListCustomFunctions>();

  return (
    <div className={classes.formWithTabContainer} data-style-form-tab="mainForm">
      {hasRelationNote && <PinnedNotesController match={match} />}
      {showRelations && (
        <RelationActionTopToolbar
          list={getRelationsInForm(
            metaData,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            processInfo,
          ).map(item => {
            return {
              title: item.translatedTitle?.[locale] ?? item.moduleTableTitle,
              id: item.moduleTableName || item.id,
            };
          })}
          resource={resource ?? ''}
        />
      )}
      <RecordEditDetails
        selectedTab={selectedTab ?? '0'}
        tabList={tabList}
        tabsTitlesRef={tabsTitlesRef}
        tabChangeHandler={tabChangeHandler}
        relationListCustomFunctions={
          relationLitsRef as React.MutableRefObject<RelationListCustomFunctions>
        }
      />

      {showRelations && (
        <RelationList
          relations={relations}
          parentMetaData={metaData}
          ref={relationLitsRef as ForwardedRef<RelationListCustomFunctions>}
        />
      )}
      {loading && <TransparentLoadingBox />}
    </div>
  );
};

export { FormWithTabsView };
