import { FC, memo, useMemo } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import { API_URL, getValue } from '../../../core/configProvider';
import { isEmpty, clone } from '../../../helper/data-helper';
import { showNotification } from '../../../helper/general-function-helper';
import { useHistory } from 'react-router-dom';
import {
  callQuickAccess,
  linkOpenHandler,
  openNewTab,
} from '../../../helper/QuickAccessHelper';
import { FieldType } from '../../../helper/Types';
import { actorDispatch } from '../../../type/actor-setup';
import { downloadFile } from '../../field/multi-file-stream-field/multi-file-stream-field.helper';
import { GridCellControllerInterface } from './grid-cell.type';
import GridCellView from './grid-cell.view';
import { getFieldAdditionalData } from '../../../helper/InputHelper';
import { showImageDialog } from '../../list';
import { handleInlineEdit } from '../../../api/grid-api';
import {
  getReportEditableColumnServices,
  runReportEditService,
} from '../grid.helper';

const GridCellViewController: FC<GridCellControllerInterface> = props => {
  const {
    row,
    value,
    column,
    basePath,
    redirect,
    metaData,
    hasShow,
    relationMode,
    addToFilterRequestList,
    resource,
    hasEdit,
    onFailureSaveForm,
  } = props;

  const { name, field } = column;
  const locale = useLocale();
  const history = useHistory();

  const reportEditableColumnServices = getReportEditableColumnServices(metaData);

  if (!isEmpty(row[`__${field?.name}_additionaldata`] as string)) {
    const fieldAdditionalData = getFieldAdditionalData(
      row[`__${field?.name}_additionaldata`] as string,
    );

    setTimeout(() => {
      const targetCellElement = document.querySelector(
        `#cell-field-${field?.name}-${row.id}`,
      ) as HTMLDivElement;
      if (targetCellElement?.parentElement) {
        targetCellElement.parentElement.style.backgroundColor =
          fieldAdditionalData.customStyleAdditionalDataField.backgroundColor;

        targetCellElement.parentElement.title =
          fieldAdditionalData?.additionalDataFieldAlt;
      }
    }, 100);
  }

  /**
   * It applies the field to filter when onClick triggered.
   * @function setCurrentValueToFilter
   * @returns {void}
   */
  const setCurrentValueToFilter = (): void => {
    if (!field) return;

    const clonedField = clone(field);

    clonedField.source = clonedField.resource ?? clonedField.name;
    clonedField.disabled = false;
    clonedField.readOnly = false;
    clonedField.required = false; // To avoid error when field is not a required filter
    clonedField.label =
      clonedField['translatedCaption']?.[locale] ?? clonedField.caption;
    clonedField.defaultOperator = clonedField.dataType.defaultOperator.toLowerCase();

    const { parameterKey, relatedName, defaultOperator } = clonedField;
    const value = lodashGet(row, relatedName);

    clonedField.key = parameterKey; // In `report`s we need to `key`

    if (typeof addToFilterRequestList == 'function') {
      const filterKey = parameterKey ?? clonedField.name;
      addToFilterRequestList({
        [filterKey]: {
          fieldData: clonedField,
          value: [filterKey, defaultOperator, value],
        },
      });
    }
  };

  /**
   * call showImageDialog for show file or image when onClick triggered.
   * @function handleFileFieldClick
   * @returns {void}
   */
  const handleFileFieldClick = (event: React.MouseEvent): void => {
    event.stopPropagation(); // to prevent trigger grid row click

    showImageDialog({
      url: lodashGet(row, lodashGet(column, ['field', 'relatedName'])),
      isGetFileMode: true,
      fileName: lodashGet(row, lodashGet(column, ['field', 'name'])),
    });
  };

  /**
   * this function trigger callQuickAccess to open quickAccessUrl in new tab
   * @function handleTableQuickAccessFieldClick
   * @returns {Promise<void>} Promise<void>
   */
  const handleTableQuickAccessFieldClick = async (): Promise<void> => {
    try {
      // Based on last changes (backend), in tables we have to check `__FieldName_AdditionalData` and process it if there is
      // https://doc.samiansoft.com/pages/viewpage.action?pageId=13403866
      let additionalDataLinkInfo = row?.[
        `__${field?.relatedName}_additionaldata`
      ] as string;
      if (!isEmpty(additionalDataLinkInfo)) {
        additionalDataLinkInfo = additionalDataLinkInfo.replaceAll('\r\n  ', ''); //remove extra space
        const parsedLinkData = JSON.parse(additionalDataLinkInfo);
        linkOpenHandler(parsedLinkData.link, parsedLinkData.linktype);
      } else {
        const linkNameValueUrl = lodashGet(
          row,
          lodashGet(column, ['field', 'linkName']),
          value,
        );

        const linkNameValue = linkNameValueUrl.substring(
          linkNameValueUrl.lastIndexOf('/') + 1,
        );

        const url = await callQuickAccess(linkNameValue);
        openNewTab(url);
      }
    } catch (error) {
      showNotification(error, 'warning');
    }
  };

  /**
   * onClick boolean field to inline edit it
   * @function handleBooleanFieldInlineEdit
   * @returns { void }
   */
  const handleBooleanFieldInlineEdit = (): void => {
    const changedData = { [name]: !value };
    if (reportEditableColumnServices[name ?? '']) {
      runReportEditService({
        fieldName: name,
        metaData,
        resource,
        gridFormData: { ...row, ...changedData },
        onSuccess: () => {},
      });
      return;
    }
    handleInlineEdit(changedData, row, resource, onFailureSaveForm, undefined, true);
  };

  /**
   * download an element with simulation <a/> tag
   * @function saveFile
   * @returns { void } void
   */
  const handleSaveFile = (): void => {
    const filePath = lodashGet(
      row,
      lodashGet(column, ['field', 'relatedName']),
      value,
    );
    const fullUrl = getValue(API_URL);

    downloadFile(filePath, fullUrl);
  };

  /**
   * redirect to dashboard
   * @function redirectToDashboard
   * @returns {void} void
   */
  const redirectToDashboard = () => {
    row?.uniqueid && history.push('/dashboard/' + row.uniqueid);
  };

  return (
    <GridCellView
      handleSaveFile={handleSaveFile}
      handleBooleanFieldInlineEdit={handleBooleanFieldInlineEdit}
      handleTableQuickAccessFieldClick={handleTableQuickAccessFieldClick}
      handleFileFieldClick={handleFileFieldClick}
      setCurrentValueToFilter={setCurrentValueToFilter}
      redirectToDashboard={redirectToDashboard}
      row={row}
      value={value}
      column={column}
      basePath={basePath}
      redirect={redirect}
      metaData={metaData}
      hasShow={hasShow}
      relationMode={relationMode}
      resource={resource}
      hasEdit={hasEdit}
    />
  );
};

export default memo(GridCellViewController);
