import { useTheme } from '@material-ui/core';
import lodashGet from 'lodash/get';
import moment from 'moment';
import { FC, memo, useEffect, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import compose from 'recompose/compose';
import ConfirmDialogHOC from '../../container/ConfirmDialogHOC';
import { SERVER_DATE_FORMAT, SERVER_TIME_FORMAT } from '../../core/configProvider';
import jalaliLocalizer from '../../helper/localizers-jalaali-calendar/jalali-moment';
import { getServices } from '../../helper/MetaHelper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../type/actor-setup';
import { CalendarEventInterface, CurrentRange } from '../big-calendar';
import {
  convertToEventObject,
  sanitizerEventRemote,
} from '../big-calendar/big-calendar.helper';
import { ServiceDialogDataItem } from '../dialogs-stack';
import { DraggedCalendarEventInterface } from '../events-calendar';
import { actionUniqueId } from './visitor-calendar.helper';
import { VisitorCalendarInterface } from './visitor-calendar.type';
import VisitorCalendarView from './visitor-calendar.view';

const VisitorCalendarController: FC<VisitorCalendarInterface> = memo(props => {
  const { metaData, openConfirmDialog } = props;
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventInterface[]>([]);
  const { current: currentResource } = actorGetActionValue('resources')!;
  const [visitorPersonInfoId, setVisitorPersonInfoId] = useState<number>();
  const [draggedCalendarEvent, setDraggedCalendarEvent] =
    useState<DraggedCalendarEventInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const visibleDays: Date[] = jalaliLocalizer.visibleDays(moment());
  const [currentRange, setCurrentRange] = useState<CurrentRange>({
    startDate: moment(visibleDays[0]).format(SERVER_DATE_FORMAT),
    endDate: moment(visibleDays[visibleDays.length - 1]).format(SERVER_DATE_FORMAT),
  });

  const translate = useTranslate();
  const theme = useTheme();
  const locale = useLocale();

  useEffect(() => {
    actorOnDispatch('formData', detail => {
      const _visitorPersonInfoId = lodashGet(
        detail,
        `${currentResource.value}.${currentResource.type}.visitorpersoninfo_id`,
        null,
      );

      if (_visitorPersonInfoId) {
        setVisitorPersonInfoId(_visitorPersonInfoId);
        getCalendarData(_visitorPersonInfoId);
      }
    });
    actorOnDispatch('loading', detail => {
      setIsLoading(Boolean(detail?.[currentResource.value as string]));
    });
  }, []);

  useEffect(() => {
    getCalendarData(visitorPersonInfoId);
  }, [currentRange]);

  /**this function get calendar data form server
   * @function getCalendarData
   * @param {number} visitorPersonId
   * @returns {void}
   */
  const getCalendarData = (visitorPersonId?: number): void => {
    const { startDate, endDate } = currentRange;
    visitorPersonId &&
      actorDispatch('getCalendarData', {
        successCallback: response => {
          setCalendarEvents(convertToEventObject(response.data));
        },
        reportId: '75038bdb-efae-47d2-8daa-06d4f41dd8c4',
        params: {
          VisitorPersonInfo_ID: visitorPersonId,
          FromDate: startDate,
          ToDate: endDate,
        },
      });
  };

  /**
   * this function create event
   *@function createNewCalendarEvent
   * @param {CalendarEventInterface} params
   * @param {void} failureCallback
   * @returns {void}
   */
  const createNewCalendarEvent = (
    params: CalendarEventInterface,
    failureCallback: () => void,
  ): void => {
    actorDispatch('runActionsService', {
      actionUniqueId: actionUniqueId['create'],
      params: draggedCalendarEvent
        ? sanitizerEventRemote(
            params,
            visitorPersonInfoId,
            draggedCalendarEvent?.VisitCalendarType_ID,
          )
        : params,
      failureCallback,
      successCallback: () => {
        actorDispatch('closeCurrentDialog', true);
        getCalendarData(visitorPersonInfoId);
      },
    });
    setDraggedCalendarEvent(undefined);
  };

  /**
   * this function handle when select on events
   * @function onSelectCalendarEventCallback
   * @param {CalendarEventInterface} event
   * @returns {void}
   */
  const onSelectCalendarEventCallback = (event: CalendarEventInterface): void => {
    openConfirmDialog({
      content: translate('confirm.areYouSureYouWantToDeleteThisItem'),
      onConfirm: handleClickDeleteCalendarEvent(event),
      color: theme.palette.error.main,
    });
  };

  /**
   * this function handle delete event
   *@function handleClickDeleteCalendarEvent
   * @param {CalendarEventInterface} event
   * @returns {Function}
   */
  const handleClickDeleteCalendarEvent =
    (event: CalendarEventInterface): Function =>
    () => {
      actorDispatch('runActionsService', {
        actionUniqueId: actionUniqueId['delete'],
        params: {
          visitcalendar_id: event.id,
        },
        successCallback: () => {
          getCalendarData(visitorPersonInfoId);
        },
      });
    };

  /**
   * this function handle update event
   *@function updateCalendarEventCallback
   * @param {CalendarEventInterface} updatedEvent
   * @param {void} failureCallback
   * @returns {void}
   */
  const updateCalendarEventCallback = (
    updatedEvent: CalendarEventInterface,
    failureCallback: () => void,
  ): void => {
    actorDispatch('runActionsService', {
      actionUniqueId: actionUniqueId['update'],
      params: {
        ...sanitizerEventRemote(updatedEvent, visitorPersonInfoId),
      },
      failureCallback: () => {
        failureCallback?.();
      },
    });
  };

  /**
   * this function handle click on events
   *@function onSelectSlotCallback
   * @param {Omit<CalendarEventInterface, 'allDay'>} calendarEvent
   * @returns {void}
   */
  const onSelectSlotCallback = (
    calendarEvent: Omit<CalendarEventInterface, 'allDay'>,
  ): void => {
    if (!visitorPersonInfoId) {
      actorDispatch(
        'formMessages',
        {
          message: translate('calendar.require'),
          name: 'visitorpersoninfo_id',
        },
        {
          path: `${currentResource.value}.${
            currentResource.type
          }.${'visitorpersoninfo_id'}`,
        },
      );
    } else {
      const services = getServices(metaData);
      actorDispatch(
        'quickDialog',
        {
          serviceIsOpen: true,
          onCloseDialogCallback: () => {},
          data: {
            data: {
              locale: locale,
              service: services[0],
              isSending: false,
              serviceParams: {
                customSubmit: _ => {
                  const formData = actorGetActionValue('formData')!;
                  createNewCalendarEvent(
                    formData[currentResource.value][
                      currentResource.type
                    ] as CalendarEventInterface,
                    () => {},
                  );
                },
                startdate: moment(calendarEvent.start).format(SERVER_DATE_FORMAT),
                enddate: moment(calendarEvent.end).format(SERVER_DATE_FORMAT),
                starttime: moment(calendarEvent.start).format(SERVER_TIME_FORMAT),
                endtime: moment(calendarEvent.end).format(SERVER_TIME_FORMAT),
                visitorpersoninfo_id: visitorPersonInfoId,
                isalldayevent: false,
              },
              runService: () => {},
              parentResource: currentResource.value,
            } as ServiceDialogDataItem,
          },
        },
        {
          replaceAll: true,
        },
      );
    }
  };

  /**
   * this function handle change range in calendar
   * @function onRangeChangeCallback
   * @param {Date} date
   * @param {string} type
   * @param {string} position
   * @returns {void}
   */
  const onRangeChangeCallback = (
    date: Date,
    type: string,
    position: string,
  ): void => {
    if (type === 'month') {
      const _visibleDays: Date[] = jalaliLocalizer.visibleDays(date);
      setCurrentRange({
        startDate: moment(_visibleDays[0]).format(SERVER_DATE_FORMAT),
        endDate: moment(_visibleDays[_visibleDays.length - 1]).format(
          SERVER_DATE_FORMAT,
        ),
      });
    }
  };

  return (
    <VisitorCalendarView
      setDraggedEvent={setDraggedCalendarEvent}
      draggedEvent={draggedCalendarEvent}
      calendarEvents={calendarEvents}
      isLoading={isLoading}
      createNewCalendarEvent={createNewCalendarEvent}
      onSelectCalendarEventCallback={onSelectCalendarEventCallback}
      updateCalendarEventCallback={updateCalendarEventCallback}
      onSelectSlotCallback={onSelectSlotCallback}
      onRangeChangeCallback={onRangeChangeCallback}
      setCalendarEvents={setCalendarEvents}
    />
  );
});

export default compose(ConfirmDialogHOC)(VisitorCalendarController);
