import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  profilePic: {
    height: 30,
    width: 30,
    fontSize: 24,
    backgroundColor: blue[300],
    [theme.breakpoints.up('xl')]: {
      height: 40,
      width: 40,
    },
    '& .MuiAvatar-img': {
      objectFit: 'unset',
      borderRadius: '50%',
      width: '150%',
      height: '120%',
      filter: 'saturate(140%)',
    },
  },
  userInfo: {
    width: 'calc(100% - 75px)',
  },
  userName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  pointer: {
    cursor: 'pointer',
  },
  adminRoot: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  avatarRoot: {
    position: 'relative',
  },
  userStatus: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 1,
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid white`,
    [theme.breakpoints.up('xl')]: {
      width: 16,
      height: 16,
    },
  },
  lastSeenText: {
    color: theme.palette.grey[600],
  },
}));
