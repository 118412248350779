import { Dialog, Divider, Icon } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { FC, memo } from 'react';
import { useTranslate } from 'react-admin';
import DatePicker from '../../../date-picker';

import { TodoDateOptionsMenu } from '../todo-date-options-menu';
import { todoDateChooserContextMenuOption } from '../todo-date-options.helper';
import { useStyles } from '../todo-date-options.style';
import { TodoDateChooserViewInterface } from './todo-date-chooser.type';

const TodoDateChooserView: FC<TodoDateChooserViewInterface> = memo(props => {
  const {
    handleOpenContextMenu,
    handleClickMenuItem,
    handleChangeCalendar,
    handleRemoveDate,
    handleCloseCalendar,
    handleBlurCalendar,
    contextMenuDetails,
    value,
    isOpenCalendar,
    isMinimized,
    iconName,
    title,
    datePickerProps,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.itemContainer}>
      <div onClick={handleOpenContextMenu} className={classes.body}>
        <Tooltip title={title}>
          <Icon
            className={
              value ? classes.sidebarTodoIcon : classes.todoSidebarSelectedIcon
            }
            fontSize="small"
          >
            {iconName}
          </Icon>
        </Tooltip>
        <div className={classes.titleAndValue}>
          {!isMinimized && (
            <span
              color={value ? 'secondary' : 'textPrimary'}
              className={classes.todoItemText}
            >
              {title}
            </span>
          )}
          {value && (
            <span color="textSecondary" className={classes.value}>
              {value}
            </span>
          )}
        </div>

        <TodoDateOptionsMenu
          isContextMenuOpen={contextMenuDetails}
          items={todoDateChooserContextMenuOption(translate)}
          handleClickItem={handleClickMenuItem}
        />
      </div>
      <Dialog
        open={isOpenCalendar}
        maxWidth="xs"
        onBackdropClick={handleCloseCalendar}
        fullWidth={false}
        hideBackdrop
      >
        <DatePicker
          {...datePickerProps}
          showDefaultInput={false}
          onChange={handleChangeCalendar}
          onBlur={handleBlurCalendar}
        />
      </Dialog>

      {value && <CloseIcon onClick={handleRemoveDate} fontSize="small" />}
      <Divider />
    </div>
  );
});
export default TodoDateChooserView;
