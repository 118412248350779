import { useTranslate } from 'react-admin';
import { FC, memo, useCallback, useState } from 'react';

import { actorDispatch } from '../../../../../type/actor-setup';
import UserItemView from './user-item.view';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../../core/configProvider';
import { onDeleteChatConfirm } from '../user-list.helper';

import type { UserItemInterface } from './user-item.type';
import type { ChatItemInterface } from '../../../chat-section.type';

const UserItemController: FC<UserItemInterface> = memo(props => {
  const { selectedUser, item, onUserSelect } = props;

  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const cacheKey = Math.random();

  /**
   * @function openContextMenu
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
   * @returns { void } void
   */
  const openContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  /**
   * @function getAvatarURL
   * @returns { string } string
   */
  const getAvatarURL = useCallback((): string => {
    return `${apiUrl}/oauth2/${sessionId}/${apiVersion}${item.personimage}`;
  }, [item.personimage, cacheKey]);

  /**
   * @function handleClose
   * @returns { void } void
   */
  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  /**
   * @function handleDeleteGroup
   * @returns { void }
   */
  const handleDeleteChat = useCallback((): void => {
    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate('ra.message.are_you_sure'),
        onConfirm: () => onDeleteChatConfirm(item),
      },
    });
    handleClose();
  }, []);

  /**
   * @function handleOnUserSelect
   * @returns { void }
   */
  const handleOnUserSelect = useCallback((): void => {
    // FIXME: Check the type of `item`
    onUserSelect(item as ChatItemInterface);
  }, []);

  return (
    <UserItemView
      handleOnUserSelect={handleOnUserSelect}
      handleDeleteChat={handleDeleteChat}
      openContextMenu={openContextMenu}
      getAvatarURL={getAvatarURL}
      handleClose={handleClose}
      selectedUser={selectedUser}
      anchorEl={anchorEl}
      item={item}
    />
  );
});

export default UserItemController;
