import { DialogData, DialogType } from './dialogs-stack.type';

/**
 * this function checks type of dialog
 * @function checkDialogType
 * @param { DialogData } detail
 * @returns { DialogType | undefined }
 */
export const checkDialogType = (detail: DialogData): DialogType | undefined => {
  if (detail == null) return undefined;

  if (detail.dropdownIsOpen) {
    return 'dropdownQuickCreateForm';
  }

  if (detail.createIsOpen) {
    return 'quickCreateDialog';
  }

  if (detail.serviceIsOpen) {
    return 'serviceDialogForm';
  }

  if (detail.relationEditIsOpen) {
    return 'relationEditDialogForm';
  }

  if (detail.gridColumnsSelectionDialogIsOpen) {
    return 'gridColumnSelectionDialog';
  }

  return undefined;
};
