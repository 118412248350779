import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { useStyles } from './todo-label.style';

import type { ReactElement } from 'react';
import type { CustomMenuListViewInterface } from './todo-label.type';

const CustomMenuListView = (props: CustomMenuListViewInterface): ReactElement => {
  const { onItemSelect: handleToggle, data, checkedLabels } = props;
  const classes = useStyles({ color: data.colorcode });

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={handleToggle(data)}>
        <RadioButtonUncheckedIcon fontSize="small" className={classes.CheckIcon} />
        <ListItemText primary={data.lablestitle} className={classes.itemText} />
        {checkedLabels.map(label => {
          if (label.lables_id == data.lables_id) {
            return (
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="checked">
                  <CheckIcon className={classes.CheckIcon} fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            );
          }
          return null;
        })}
      </ListItem>
    </List>
  );
};

export default CustomMenuListView;
