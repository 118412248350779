import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  listContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '-webkit-fill-available',
    maxWidth: '-moz-available', // firefox will ignore webkit, so it can be used for firefox
    backgroundColor: '#FFFFFF',
  },

  bottomToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  loadingOrErrorBoxContainer: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },
}));
