import { Button, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import FilterForm from '../../filter-form';
import { DynamicChart } from '../../dynamic-chart';
import ColorPickerController from '../../color-picker/color-picker.controller';
import useStyles from './dashboard-card.style';

import type { FC } from 'react';
import type { DashboardCardViewProps } from './dashboard-card.type';
import LoadingBox from '../../loading-box';

const defaultColorList = [
  '#00a3bf',
  '#0747a6',
  '#00875a',
  '#FF8B00',
  '#de350b',
  '#6554c0',
  '#5e6c84',
  'transparent',
];

const DashboardCardView: FC<DashboardCardViewProps> = props => {
  const {
    dashboardCardData,
    dynamicChartPreparedProps,
    isFilterFormOpenInDashboard,
    anchorEl,
    filterFields,
    onChooseColor,
    goToFilterMode,
    openOptionsMenu,
    closeOptionsMenu,
    onEditButtonClick,
    onDeleteButtonClick,
    onFiltersChange,
    reportResource,
    handleShowMore,
    showMore,
    isEditing,
    hasGadgetsError,
  } = props;

  const { title, color, isFiltersConfirmed } = dashboardCardData;

  const open = Boolean(anchorEl);

  const classes = useStyles({ headerColor: color });
  const translate = useTranslate();
  const theme = useTheme();

  return (
    <>
      <div dir={theme.direction} className={classes.container}>
        <div
          id="header-content"
          className={classes.header}
          style={{ background: color }}
          onClick={handleShowMore}
        >
          <Typography className={classes.title}>{title}</Typography>

          {((filterFields && filterFields.length > 0) || isEditing) && (
            <Button
              className={classes.moreButton}
              size="small"
              onClick={openOptionsMenu}
            >
              {showMore && <MoreHorizIcon />}
            </Button>
          )}
        </div>

        <div className={classes.content}>
          {(() => {
            if (hasGadgetsError)
              return <div>{translate('gadget.getReportInformationFailed')}</div>;
            if (!dynamicChartPreparedProps) return <LoadingBox absolute />;
            if (isFiltersConfirmed)
              return <DynamicChart {...dynamicChartPreparedProps} />;
            else if (filterFields)
              return (
                <FilterForm
                  resource={reportResource}
                  selectedFilterFields={filterFields}
                  customSaveButtonText={translate('dashboard.confirmFilters')}
                  onFiltersChange={onFiltersChange}
                />
              );

            return <></>;
          })()}
        </div>
      </div>

      <Menu anchorEl={anchorEl} open={open} onClose={closeOptionsMenu}>
        {isEditing && (
          <ColorPickerController
            selectedColor={color}
            onSelectColor={onChooseColor}
            defaultColorList={defaultColorList}
          />
        )}

        {isEditing && (
          <MenuItem onClick={onEditButtonClick}>
            {translate('general.edit')}
          </MenuItem>
        )}
        {!!filterFields && filterFields.length > 0 && (
          <MenuItem onClick={goToFilterMode} disabled={isFilterFormOpenInDashboard}>
            {translate('grid.filter')}
          </MenuItem>
        )}

        {isEditing && (
          <MenuItem onClick={onDeleteButtonClick}>
            {translate('general.delete')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DashboardCardView;
