import { ReactElement, Fragment } from 'react';
import { NavigationContainerViewPropsInterface } from './navigation-container.type';
import { RoutesConfigInterface } from '.';
import { NavGroup } from './nav-group';
import { NavCollapse } from './nav-collapse';
import { NavItem } from './nav-item';

const NavigationContainerView = (
  props: NavigationContainerViewPropsInterface,
): ReactElement => {
  const {
    onCollapseChange,
    routesConfig,
    baseUrl,
    canDragDrop,
    parentComponentName,
    lastExpandedItems,
  } = props;

  return (
    <div data-style-navigation="navigation">
      {routesConfig.map((item: RoutesConfigInterface) => (
        <Fragment key={item.id}>
          {item.type === 'group' && (
            <NavGroup
              item={item}
              level={0}
              baseUrl={baseUrl}
              canDragDrop={canDragDrop}
              parentComponentName={parentComponentName}
            />
          )}

          {item.type === 'collapse' && (
            <NavCollapse
              item={item}
              level={0}
              baseUrl={baseUrl}
              canDragDrop={canDragDrop}
              parentComponentName={parentComponentName}
              collapseChangingCallback={onCollapseChange}
              open={
                lastExpandedItems!.parents.findIndex(
                  option => option.id === item.id,
                ) > -1
              }
              lastExpandedItems={lastExpandedItems}
            />
          )}

          {item.type === 'item' && (
            <NavItem
              item={item}
              level={0}
              baseUrl={baseUrl}
              canDragDrop={canDragDrop}
              parentComponentName={parentComponentName}
            />
          )}
          {item.type === 'action' && <>{item?.actionNode}</>}
        </Fragment>
      ))}
    </div>
  );
};

export default NavigationContainerView;
