import { FC, useEffect, useState, useRef } from 'react';
import PivotGridDataSource, {
  PivotGridDataSourceField,
} from 'devextreme/ui/pivot_grid/data_source';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { showNotification } from '../../helper/general-function-helper';
import { isEmptyObject } from '../../helper/data-helper';
import { FieldType } from '../../helper/Types';
import { stringify } from 'query-string'; // TODO: replace "query-string" with "qs"
import {
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
} from '../../type/actor-setup';
import PivotTableView from './pivot-table.view';
import { getGridColumns } from '../../helper/MetaHelper';
import lodashMap from 'lodash/map';
import LoadingBox from '../LoadingBox';
import {
  DefaultSettingInterface,
  PivotInterface,
  PivotTableControllerPropsInterface,
} from './pivot-table.type';
import lodashGet from 'lodash/get';
import lodashFilter from 'lodash/filter';
import { useTranslate } from 'react-admin';
import { useStyles } from './pivot-table.style';
import { useLocale } from 'react-admin';
import {
  CONFIG_PIVOT_FIELD_CHOOSER_SETTING,
  getValue,
  USER_ID,
} from '../../core/configProvider';
import { getAppSettings, setAppSettings } from '../../helper/settings-helper';
import { getTypeByField } from '../../helper/InputHelper';
import { ListToolbar } from '../list-toolbar';
import { prepareActionBarProps } from '../list/list.helper';
import { GeneralMetaData } from '../../helper/Types';
import { getBaseUrl } from '../../core/data-Provider.helper';

import type { FinalFiltersType } from '../filter-form';

//fixme: remove partial from interface
const PivotTableController: FC<PivotTableControllerPropsInterface> = props => {
  const { metaData, resource, listType } = props;

  const [gridFieldsList, setGridFieldsList] = useState<Array<Partial<FieldType>>>(
    [],
  );
  const pivotRef = useRef<PivotInterface>(null);
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();
  const [applyChangesMode, setApplyChangesMode] = useState<string>('onDemand');
  const [savedDataSource, setSavedDataSource] = useState<PivotGridDataSource>(
    new PivotGridDataSource(),
  );
  const baseUrl = getBaseUrl();

  useEffect(() => {
    getFields();

    actorOnDispatch(
      'filterDataIsChanged',
      (finalFilters: Record<string, FinalFiltersType>) => {
        const filter = finalFilters?.[resource!];
        if (filter) {
          actorSetActionValue('gridData', filter, {
            path: `${resource}.requestParameters.filter`,
            replaceAll: true,
            callerScopeName: 'FilterFormController => updateGridFilters',
          });

          pivotRef.current?.props.dataSource.reload();
        }
      },
      { preserve: false },
    );
  }, []);

  const actionBarProps = prepareActionBarProps(listType, resource, metaData);

  /**
   * @function getFields
   * @returns {Promise<void>}
   */
  const getFields = async (): Promise<void> => {
    if (!isEmptyObject(metaData)) {
      const columns = getGridColumns({ metaData });

      const defaultFieldListFromSetting = getAppSettings(getKeySetting()).value;

      const fields: Array<Partial<FieldType>> = [];
      let defaultSetting: DefaultSettingInterface;

      lodashMap(columns, row => {
        if (!isEmptyObject(row) && row.relatedName != '' && row.caption != '') {
          defaultSetting = {};
          if (
            Array.isArray(defaultFieldListFromSetting) &&
            defaultFieldListFromSetting.length > 0
          ) {
            const { area, areaIndex } = lodashFilter(
              defaultFieldListFromSetting,
              setting => setting.dataField == row.relatedName,
            )[0];

            if (area) {
              defaultSetting = {
                area: area,
                areaIndex: areaIndex,
              };
            }
          }
          const fieldType = getTypeByField(row);
          fields.push({
            caption: lodashGet(row, ['translatedCaption', locale], row.name),
            dataField: row.relatedName,
            summaryType: 'sum',
            format: ',##0.###', // 123,456.789
            ...defaultSetting,
          });
          if (fieldType === 'NUMBER_FIELD') {
            fields.push({
              caption:
                lodashGet(row, ['translatedCaption', locale], row.name) +
                `(${translate('pivot.max')})`,
              dataField: row.relatedName,
              summaryType: 'max',
              format: ',##0.###', // 123,456.789
              ...defaultSetting,
            });
            fields.push({
              caption:
                lodashGet(row, ['translatedCaption', locale], row.name) +
                `(${translate('pivot.min')})`,
              dataField: row.relatedName,
              summaryType: 'min',
              format: ',##0.###', // 123,456.789
              ...defaultSetting,
            });
            fields.push({
              caption:
                lodashGet(row, ['translatedCaption', locale], row.name) +
                `(${translate('pivot.average')})`,
              dataField: row.relatedName,
              summaryType: 'avg',
              format: ',##0.###', // 123,456.789
              ...defaultSetting,
            });
          }
        }
      });
      const dataSource = new PivotGridDataSource({
        fields: fields as PivotGridDataSourceField[],
        remoteOperations: true,
        retrieveFields: false,
        store: createStore({
          loadUrl:
            `${baseUrl}/${resource}/pivot?` +
            `&${stringify({
              filters: JSON.stringify(
                actorGetActionValue(
                  'gridData',
                  `${resource}.requestParameters.filter`,
                ),
              ),
            })}`,
          onBeforeSend: function (e, ajaxOptions) {
            ajaxOptions.headers = {
              authorization: `Bearer ${localStorage
                .getItem('USER_TOKEN')
                ?.replaceAll('"', '')}`,
            };
          },
        }),
      });
      setSavedDataSource(dataSource);
      setGridFieldsList(fields);
    }
  };

  /**
   * @function getData
   * @returns {Record<string, unknown> | Array<Record<string, unknown>>}
   */
  /**
   * get key for chooser config
   * @function getKeySetting
   * @returns {void}
   */
  const getKeySetting = (): string => {
    const userId = getValue(USER_ID);
    return userId + '_' + CONFIG_PIVOT_FIELD_CHOOSER_SETTING + '_' + resource;
  };

  /**
   * show notification callback
   * @function showSucceedMessage
   * @returns {void}
   */
  const showSucceedMessage = (): void => {
    showNotification(translate('pivot.settingSavedSuccessfully'), 'success');
  };

  /**
   * save grid setting in webSetting
   * @function onSaveSetting
   * @param {FieldType} fields
   * @returns {void}
   */
  const onSaveSetting = (
    fields: Partial<FieldType>[] | PivotGridDataSource[],
  ): void => {
    setAppSettings({
      key: getKeySetting(),
      value: fields,
      onSuccess: showSucceedMessage,
    });
  };

  /**
   * reset grid setting
   * @function onResetClick
   * @returns {void}
   */

  const onResetClick = (): void => {
    savedDataSource.state({});
  };

  /**
   * get fields from setting and pass to props
   * @function onSaveClick
   * @returns {void}
   */

  const onSaveClick = (): void => {
    onSaveSetting(gridFieldsList);
  };

  if (isEmptyObject(metaData) || gridFieldsList === null) {
    return <LoadingBox />;
  }

  return (
    <div className={classes.mainContainer}>
      <ListToolbar actionBarProps={actionBarProps} />
      <PivotTableView
        fields={gridFieldsList}
        resource={resource}
        dataSource={savedDataSource}
        applyChangesMode={applyChangesMode}
        onSaveClick={onSaveClick}
        onResetClick={onResetClick}
        pivotRef={pivotRef}
        metaData={metaData}
      />
    </div>
  );
};

export default PivotTableController;
