import { FC, memo } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { Avatar, Box, Tooltip } from '@material-ui/core';
import { isEmpty } from '../../../../helper/data-helper';

import { useStyles } from './todo-task-item.style';

import type {
  TodoTaskItemIconsInterface,
  TodoTaskItemViewProps,
} from './todo-task-item.type';
import { apiUrl, apiVersion } from '../../../../core/data-Provider.helper';

const TodoTaskItemView: FC<TodoTaskItemViewProps> = props => {
  const {
    onCompleteIconClick,
    onStarIconClick,
    onTaskClick,
    isTaskSelected,
    dragDropRef,
    isOver,
    task,
    taskLabelList,
    todoTaskItemIcons,
    imageUrl,
  } = props;

  const classes = useStyles({ isIndexOdd: isTaskSelected, isOver });

  return (
    <div
      className={classes.container}
      onClick={onTaskClick}
      ref={dragDropRef}
      data-test-todo-task-item={task.id}
      data-test="todo-item"
    >
      <div className={classes.textAndStarIconContainer}>
        {task.isdone ? (
          <CheckCircleIcon
            id="is_done_task"
            className={classes.CheckCircleIcon}
            onClick={onCompleteIconClick}
          />
        ) : (
          <RadioButtonUncheckedIcon
            className={classes.RadioButtonUncheckedIcon}
            onClick={onCompleteIconClick}
            id="is-not_done_task"
          />
        )}

        <div className={classes.listItem}>
          <div className={classes.taskItemContainer}>
            <div>
              <span className={classes.tskDetail} id="title-task-todo">
                {task?.detailtitle}
              </span>

              <div className={classes.metaDataInfo}>
                {todoTaskItemIcons.map((item: TodoTaskItemIconsInterface) => (
                  <>
                    {!!task[item.name] && !!item.title && (
                      <>
                        {item.icon}
                        <span className={classes[item.titleClassName]}>
                          {item.title}
                        </span>
                      </>
                    )}
                  </>
                ))}
                <span>
                  {!isEmpty(taskLabelList) ? (
                    <Box display="flex" alignItems="center" component="span">
                      {taskLabelList?.map(label => (
                        <Box key={label.lables_id} component="span" ml={1}>
                          <Tooltip title={label.lablestitle} placement="top">
                            <Box
                              height={12}
                              width={12}
                              component="span"
                              borderRadius="50%"
                              display="block"
                              bgcolor={label.colorcode}
                            />
                          </Tooltip>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.containerTodo}>
        <div>
          {imageUrl ? (
            <Avatar
              src={`${apiUrl}/${apiVersion}${imageUrl}`}
              className={classes.small}
            />
          ) : null}
        </div>
        {task.isimportant ? (
          <StarIcon
            className={classes.StarIcon}
            onClick={onStarIconClick}
            id="is-important-task"
          />
        ) : (
          <StarBorderIcon
            className={classes.StarBorderIcon}
            onClick={onStarIconClick}
            id="is-not-important-task"
          />
        )}
      </div>
    </div>
  );
};
export default memo(TodoTaskItemView);
