import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, { isSender?: boolean }>(theme => ({
  messageItemRoot: {
    '&.right $messageChatRoot': {
      '& .message-time': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    '&.right $messageChat': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 2px 1px 0 rgba(0,0,0,.2)',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 8,
      '& .MuiAvatar-root': {
        marginLeft: -30,
        marginTop: -30,
      },
      '& .message-time': {
        display: 'flex',
        justifyContent: 'flex-end',
        color: '#7F8387',
      },
      '& .message-info': {
        width: '100%',
        fontSize: 14,
        [theme.breakpoints.up('lg')]: {
          fontSize: 16,
        },
      },
    },
    [theme.breakpoints.up('md')]: {
      '&.right $messageChat': {
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 0,
      },
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: ({ isSender }) => (isSender ? 'flex-start' : 'unset'),
      flexDirection: ({ isSender }) => (isSender ? 'row' : 'row-reverse'),
    },
  },
  messageChatRoot: {
    position: 'relative',
  },
  messageTime: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  messageChat: {
    display: 'flex',
    boxShadow: '0 2px 1px 0 rgba(93,196,82,.5)',
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    padding: '5px 15px',
    minWidth: '120px',
    position: 'relative',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 10,
    backgroundColor: '#effdde',
    '& .message-time': {
      display: 'flex',
      justifyContent: 'flex-end',
      color: '#70BF6C',
    },
    '& .MuiAvatar-root': {
      marginLeft: -30,
      marginTop: -30,
    },
    '& .message-info': {
      width: '100%',
      fontSize: 14,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
      },
    },
  },
  textPointer: {
    cursor: 'pointer',
  },
  arrowIcon: {
    marginTop: 20,
  },
  profilePic: {
    height: 30,
    width: 30,
    fontSize: 12,
    backgroundColor: blue[300],
    '& .MuiAvatar-img': {
      objectFit: 'unset',
      borderRadius: '50%',
      width: '150%',
      height: '120%',
      filter: 'saturate(140%)',
    },
  },
  editRoot: {
    paddingLeft: 10,
    color: theme.palette.grey[200],
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },

  mediaWrapper: {
    position: 'relative',
    maxWidth: 360,
    maxHeight: 250,
    display: 'inline-block',
    verticalAlign: 'top',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.todoLightGray}`,

    '& img': {
      objectFit: 'cover',
      width: 'fit-content',
      borderRadius: 10,
      maxHeight: 226,
    },
  },

  albumMediaWrapper: {
    position: 'relative',
    maxWidth: 360,
    maxHeight: 250,
    display: 'inline-block',
    verticalAlign: 'top',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.todoLightGray}`,
    margin: 5,
    cursor: 'pointer',

    '& img': {
      objectFit: 'cover',
      width: 'fit-content',
      borderRadius: 10,
      maxHeight: 226,
    },
  },

  repliedContent: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '2px solid #70BF6C',
    paddingLeft: '.5rem',
    marginBottom: '1rem',
  },

  repliedMediaWrapper: {
    position: 'relative',
    maxWidth: 100,
    maxHeight: 200,
    display: 'inline-block',
    verticalAlign: 'top',
    overflow: 'hidden',

    '& img': {
      objectFit: 'cover',
      width: 35,
      height: 35,
      borderRadius: 4,
    },
  },

  docIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#70BF6C',
    height: 35,
    width: 35,
    borderRadius: '1000%',
  },

  chip: {
    marginTop: '0.5rem',
    marginLeft: '0.2rem',
  },

  faForwardIcon: { transform: 'rotate(-180deg)' },

  forwardItem: {
    display: 'flex',
  },

  itemForward: {
    marginLeft: 2,
    marginRight: 2,
  },
  videoContentContainer: {
    maxWidth: '300px',
  },

  locationText: {
    marginTop: 10,
  },

  input: {
    height: '20px',
  },
  chatSearch: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    border: 'none',
    outline: 'none',

    '& .MuiOutlinedInput-input': {
      padding: '6px 14px',
      paddingLeft: 0,
      [theme.breakpoints.up('xl')]: {
        padding: '10px 14px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.secondary,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },

  textFieldContainer: {
    width: '100%',
  },
}));
