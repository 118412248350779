import { Fragment, memo } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslate } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from '../../grid.style';

import type { FC } from 'react';
import type { GridDropDownInputViewInterface } from './grid-dropdown-input.type';

const GridDropDownInputView: FC<GridDropDownInputViewInterface> = props => {
  const {
    handleChangeValue,
    handleChangeInput,
    toggleDropdown,
    onFocus,
    onBlur,
    isOpen,
    items,
    loading,
    isDisabled,
    dropdownValue,
    fieldMeta,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  return (
    <div className={classes.gridInputContainer}>
      <Autocomplete
        disabled={isDisabled}
        filterOptions={options => options}
        disableListWrap
        open={isOpen && !isDisabled}
        onChange={handleChangeValue}
        onFocus={onFocus}
        onClick={onFocus}
        onBlur={onBlur}
        onClose={toggleDropdown}
        onOpen={toggleDropdown}
        getOptionLabel={option => option.label}
        options={items}
        loading={loading}
        loadingText={translate('dropdown.loading')}
        noOptionsText={translate('dropdown.noOptionsMessage')}
        getOptionSelected={(option, selectedItem) =>
          option.value?.[fieldMeta.valueMember] ==
          selectedItem.value?.[fieldMeta.valueMember]
        }
        renderInput={params => (
          <TextField
            {...params}
            onChange={handleChangeInput}
            value={dropdownValue}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default memo(GridDropDownInputView);
