import { memo, type FC } from 'react';
import type { PieChartViewProps } from './pie-chart.type';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const PieChartView: FC<PieChartViewProps> = props => {
  const { compatibleData, height, width, handleChartItemClick } = props;

  /**
   * prepare chart options
   */
  const highChartOptions = {
    chart: {
      type: 'pie',
      width: width,
      height: height,
    },
    title: {
      text: '',
    },
    tooltip: {
      format:
        '<span style="font-size: 0.8em">{key}:</span><br/><b>{point.y:,.0f}</b>',
      shared: true,
    },
    series: [
      {
        data: compatibleData,
      },
    ],
    plotOptions: {
      series: {
        cursor: 'pointer',
        events: {
          click: handleChartItemClick,
        },
      },
    },
  };

  Highcharts.setOptions({
    lang: {
      decimalPoint: '.',
      thousandsSep: ',',
    },
  });
  return (
    <div style={{ width, height, overflow: 'hidden' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={highChartOptions}
        width={width}
        height={height}
        key={Math.random()}
      />
    </div>
  );
};

export default memo(PieChartView);
