import { memo, useEffect, useRef, useState, type FC, useMemo } from 'react';

import type { UserInfoInterface, UserSummaryInfoInterface } from './user-info.type';
import { actorOnDispatch, actorRemoveAction } from '../../../../type/actor-setup';
import UserInfoView from './user-info.view';
import { ChatItemInterface } from '../../chat-section.type';
import { isEmpty, isEmptyObject } from '../../../../helper/data-helper';

const UserInfoController: FC<UserInfoInterface> = memo(props => {
  const { user, showStatus = false, isOnline = false } = props;

  const [userLastSeen, setUserLastSeen] = useState('');
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const _user: UserSummaryInfoInterface = useMemo(() => {
    const userInGroup =
      (user as ChatItemInterface).fromgroup ?? !isEmpty(user.groupuid);

    return {
      currentUserID: user.personinfo_id + '',
      displayName: user.personname,
      photoURL: user.personimage,
      inGroup: userInGroup,
      groupId: userInGroup ? user.personinfo_id + '' : null,
    };
  }, [user]);

  const usersIsTypingInGroup = useRef<string[]>([]);

  const onDispatchRefs = useRef<{ [name: string]: symbol }>({});

  useEffect(() => {
    onDispatchRefs.current['usersLastSeen'] = actorOnDispatch(
      'usersLastSeen',
      usersLastSeen => {
        setUserLastSeen(usersLastSeen?.[String(_user.currentUserID)] ?? '');
      },
    );

    onDispatchRefs.current['isTypingUsers'] = actorOnDispatch(
      'isTypingUsers',
      isTypingData => {
        if (isEmptyObject(isTypingData)) {
          setIsTyping(false);
          usersIsTypingInGroup.current = [];
          return;
        }

        if (!_user.inGroup) {
          usersIsTypingInGroup.current = [];

          setIsTyping(
            Object.keys(isTypingData).includes(String(_user.currentUserID)!),
          );

          return;
        }

        if (!isEmpty(isTypingData[_user.groupId!]?.personName)) {
          const targetIndex = usersIsTypingInGroup.current.indexOf(
            isTypingData[_user.groupId!].personName,
          );
          if (targetIndex === -1) {
            usersIsTypingInGroup.current.push(
              isTypingData[_user.groupId!].personName,
            );
          }
        }

        setIsTyping(true);
      },
    );

    return () => {
      setUserLastSeen('');
      setIsTyping(false);

      Object.keys(onDispatchRefs.current).forEach(key => {
        actorRemoveAction({
          actionName: key as keyof ActorActionList,
          listenerId: onDispatchRefs.current[key],
        });
      });
    };
  }, [_user]);

  return (
    <UserInfoView
      user={_user}
      showStatus={showStatus}
      isOnline={isOnline}
      userLastSeen={userLastSeen}
      isTyping={isTyping}
      usersIsTypingInGroup={usersIsTypingInGroup.current}
    />
  );
});

export default UserInfoController;
