import { useTranslate } from 'react-admin';
import { forwardRef, type ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import { Menu, MenuItem } from '@material-ui/core';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Badge from '@material-ui/core/Badge';
import LensIcon from '@material-ui/icons/Lens';

import CloseIcon from '@material-ui/icons/Close';
import clsx from 'classnames';

import { useStyles } from './chat-item.style';
import { getSummeryMessageText } from './chat-item.helper';

import type { ChatItemViewPropsInterface } from './chat-item.type';

const ChatItemView = forwardRef<HTMLSpanElement, ChatItemViewPropsInterface>(
  (props, ref): ReactElement => {
    const {
      onChatItemSelect,
      handleDeleteChat,
      openContextMenu,
      getAvatarURL,
      handleClose,
      anchorEl,
      item,
      unseenMessagesCount,
      isOnline,
      handleRemoveSearch,
      searchModeInChat,
      isUserInfoDialog,
      senderUser,
      isActiveChat,
      messageTime,
    } = props;

    const translate = useTranslate();
    const classes = useStyles();

    return (
      <div>
        <ListItem
          button
          className={clsx(classes.listItemRoot, 'item-hover', {
            active: isActiveChat,
          })}
          onClick={onChatItemSelect}
          onContextMenu={openContextMenu}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={1}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              id="boxChat"
            >
              <Box>
                <ListItemAvatar
                  className={classes.avatarRoot}
                  data-style-avatar-chat="avatarChat"
                >
                  <>
                    <Badge
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      badgeContent={
                        isOnline && <LensIcon className={classes.onlineIcon} />
                      }
                    >
                      <Avatar className={classes.avatar} src={getAvatarURL()} />
                    </Badge>
                  </>
                </ListItemAvatar>
              </Box>
              <Box
                component="span"
                fontWeight={300}
                display="block"
                ml={1}
                fontSize={13}
                data-style-person-chat="personChat"
              >
                {item.personname}
              </Box>
            </Box>
            {searchModeInChat === 'oneByOne' && (
              <Box
                component="span"
                fontWeight={300}
                display="block"
                fontSize={12}
                data-style-date-chat="dateChat"
              >
                <CloseIcon onClick={handleRemoveSearch} />
              </Box>
            )}
            {searchModeInChat === 'allUser' &&
              !isUserInfoDialog &&
              messageTime != null && (
                <Box
                  component="span"
                  fontWeight={300}
                  display="block"
                  fontSize={12}
                  data-style-date-chat="dateChat"
                >
                  {messageTime}
                </Box>
              )}
            {isUserInfoDialog && (
              <p className={classes.groupTitle}>{item['contactgrouptitle']}</p>
            )}
          </Box>

          {!isUserInfoDialog && (
            <Box className={classes.userInfoRoot} mt={1}>
              <Box
                id={`chatId-${item.personinfo_id}`}
                className={classes.truncate}
                component="p"
                color="text.secondary"
                display="block"
                fontSize={12}
                data-style-desc-chat="descChat"
                {...{ ref }}
              >
                {item.fromgroup && (
                  <Box component="span" color="text.primary">
                    {item.frompersoninfoname || item.personname}: &nbsp;
                  </Box>
                )}
                {getSummeryMessageText(item.chattext, translate)}
              </Box>

              {senderUser?.personinfo_id !== item.personinfo_id && (
                <Box component="span" color="text.secondary" display="block">
                  <Badge
                    classes={{ badge: classes.customBadge }}
                    badgeContent={unseenMessagesCount}
                    max={10000}
                  ></Badge>
                </Box>
              )}
            </Box>
          )}
        </ListItem>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleDeleteChat}>
            {translate('chat.deleteChat')}
          </MenuItem>
        </Menu>
      </div>
    );
  },
);

export default ChatItemView;
