import { FC, memo } from 'react';

import { ListHeaderFooterInterface } from './list-header-footer.type';
import { useStyles } from './list-header-footer.style';
import { isEmpty } from '../../helper/data-helper';

const ListHeaderFooterController: FC<ListHeaderFooterInterface> = memo(props => {
  const { message } = props;
  const classes = useStyles();

  if (isEmpty(message)) return null;
  return <span className={classes.messageBox}>{message}</span>;
});

export default ListHeaderFooterController;
