import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    overflow: 'scroll',
  },

  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '-webkit-fill-available',
    minHeight: 500,
    maxWidth: '-moz-available', // firefox will ignore webkit, so it can be used for firefox
  },
}));
