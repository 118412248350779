import HighChartsReact from 'highcharts-react-official';
import HighCharts from 'highcharts';

import type { FC } from 'react';
import type { BarChartViewProps } from './bar-chart.type';
import { addThousandSeparator } from '../dynamic-chart.helper';
import drilldown from 'highcharts/modules/drilldown.js';

drilldown(HighCharts);

const BarChartView: FC<BarChartViewProps> = props => {
  const {
    compatibleData,
    width,
    height,
    handleClickBarChartItem,
    axisBottomLegend = '',
    axisLeftLegend = '',
    drillDownSeries,
  } = props;

  const highChartOptions = {
    chart: {
      type: 'column',
      zoomType: 'x',
      style: { width, height },
      width: width,
      height: height,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      title: {
        text: axisBottomLegend,
      },
    },
    yAxis: {
      title: {
        text: axisLeftLegend,
      },
      labels: {
        formatter: ({ value }) => addThousandSeparator(value),
      },
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        // events: { click: handleClickBarChartItem },
      },
    },
    series: compatibleData,
    drilldown: {
      allowPointDrilldown: false,
      series: drillDownSeries,
    },
  };

  HighCharts.setOptions({
    lang: {
      decimalPoint: '.',
      thousandsSep: ',',
    },
  });

  return (
    <div
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height,
      }}
    >
      <HighChartsReact
        highcharts={HighCharts}
        options={highChartOptions}
        width={width}
        height={height}
        key={Math.random()}
      />
    </div>
  );
};

export default BarChartView;
