import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import 'react-contexify/dist/ReactContexify.min.css';
import { LogoutButtonInterface } from './logout-button.type';
import { NewMetaContext } from '../../../../container/NewMetaContext';
import { connect } from 'react-redux';
import { userLogout as userLogoutAction } from 'ra-core';
import {
  CONFIG_LOCALE,
  CONFIG_THEME_DIR,
  getValue,
  IN_ACTIVE_LOGIN_USER_TIMEOUT,
  setValue,
  USER_MOUSE_MOVE,
} from '../../../../core/configProvider';
import {
  CHAT_SIGNAL,
  NOTIFICATION_SIGNAL,
} from '../../../../hooks/useSignalRConnection';
import {
  actorGetActionValue,
  actorResetActionList,
} from '../../../../type/actor-setup';
import LogoutButtonView from './logout-button.view';
import lodashDebounce from 'lodash/debounce';
import { broadcastChannel } from '../../../../core/authProvider';

const LogoutButton: FC<LogoutButtonInterface> = props => {
  const { userLogout } = props;
  const timerRef = useRef<any>(null);
  const currentLocale = getValue(CONFIG_LOCALE);

  const { resetContextData } = useContext(NewMetaContext);

  const inTime = getValue(IN_ACTIVE_LOGIN_USER_TIMEOUT) ?? 604800000;

  useEffect(() => {
    // FIXME: handle with actor, remove mousemoveHandler function
    window.addEventListener('mousemove', mousemoveHandler);

    setTimers();

    broadcastChannel.addEventListener('message', (event: MessageEvent) => {
      if (event.data.type === USER_MOUSE_MOVE) {
        updateActiveTime();
        return;
      }
    });

    return () => {
      window.removeEventListener('mousemove', mousemoveHandler);
    };
  }, []);

  const mousemoveHandler = useCallback(() => {
    updateActiveTime();
    broadcastChannel.postMessage({ type: USER_MOUSE_MOVE });
  }, []);

  /**
   * @description: update timers
   */
  const setTimers = useCallback((): void => {
    timerRef.current = setTimeout(() => userLogoutHandler(), inTime); //7 days
  }, []);

  /**
   * @description: clear old timers and set new timers
   */
  const updateActiveTime = lodashDebounce(() => {
    clearTimeout(timerRef.current);
    setTimers();
  }, 10000);

  /**
   * @description: user logout process
   */
  const userLogoutHandler = () => {
    //debug on customer site
    console.log('>>>>user logout<<<<');
    resetContextData?.();
    userLogout();
    setValue(CONFIG_LOCALE, currentLocale ?? 'fa');
    setValue(CONFIG_THEME_DIR, currentLocale !== 'en' ? 'rtl' : 'ltr');

    const hubConnection = actorGetActionValue(NOTIFICATION_SIGNAL);
    const hubConnectionChat = actorGetActionValue(CHAT_SIGNAL);

    hubConnection?.stop();
    hubConnectionChat?.stop();

    setTimeout(() => {
      actorResetActionList();
    }, 0);
  };

  return <LogoutButtonView userLogoutHandler={userLogoutHandler} />;
};

const mapDispatchToProps = dispatch => ({
  userLogout: () => dispatch(userLogoutAction()),
});

export default connect(null, mapDispatchToProps)(LogoutButton);
