import { alpha, makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  avatar: {
    width: 40,
    height: 40,
  },

  avatarRoot: {
    minWidth: 0,
    position: 'relative',
    marginRight: '2rem',
  },

  activeAvatar: {
    //blue border for active user
    border: `2px solid ${theme.palette.primary.main}`,
  },

  listContainer: {
    maxHeight: '20rem',
    marginTop: '1rem',
    overflow: 'auto',
    width: '100%',
    //hide scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  listItem: {
    cursor: 'pointer',
    //hover
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },

  rightSection: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },

  removeText: {
    color: theme.palette.primary.blueTextColor,
    '&:hover': {
      textDecoration: 'underline',
    },
    marginLeft: '1rem',
  },

  seenDateContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  seenDateRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  seenDateTypography: {
    fontSize: 12,
  },

  seenDateIcon: {
    fontSize: 14,
  },
}));
