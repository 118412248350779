import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  addIconStyle: {
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  container: {
    padding: 10,
  },
  addIConContainer: {
    background: theme.palette.primary.appPrimaryLightBackgroundColor,
    marginRight: 3,
    marginLeft: 3,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerCell: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  actionContainer: {
    width: 'auto',
  },
  iconInGrid: {
    cursor: 'pointer',
    margin: '0 5px',
    padding: 0,
  },
  pinIcon: {
    fontSize: 10,
    marginTop: -1,
  },
}));
