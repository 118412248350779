import { Avatar } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { useStyles } from './chat-avatar-input.style';
import { isEmpty } from '../../../helper/data-helper';

import type { ChatAvatarInputViewPropsInterface } from './chat-avatar-input.type';
import { forwardRef, ReactElement } from 'react';

const ChatAvatarInputView = forwardRef<
  HTMLInputElement,
  ChatAvatarInputViewPropsInterface
>((props, ref): ReactElement => {
  const { handleClick, onSelectFile, avatarURL } = props;

  const classes = useStyles();
  return (
    <>
      <input
        onChange={onSelectFile}
        className={classes.input}
        type="file"
        multiple={false}
        ref={ref}
        accept="image/*"
      ></input>
      <Avatar
        onClick={handleClick}
        src={avatarURL ? avatarURL : ''}
        className={classes.largeFileInputContainer}
      >
        {isEmpty(avatarURL) && <CameraAltIcon className={classes.cameraIcon} />}
      </Avatar>
    </>
  );
});

export default ChatAvatarInputView;
