import { ReactElement } from 'react';

import { CustomForm, FormController } from '../../../form';
import WMSForm from '../wms-form';
import { useStyles } from './wms-action.style';
import { WMSActionViewProps } from './wms-action.type';

export const WMSActionView = (props: WMSActionViewProps): ReactElement => {
  const { onSubmit, activeTabIndex, formTabIndex, prevTabIndex } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormController formName="wms" key={`wms-${activeTabIndex ?? 0}`}>
        <CustomForm>
          <WMSForm
            onSubmit={onSubmit}
            activeTabIndex={activeTabIndex}
            formTabIndex={formTabIndex}
            prevTabIndex={prevTabIndex}
          />
        </CustomForm>
      </FormController>
    </div>
  );
};
