import { FormBuilder as FormBuilderIo, Components } from 'react-formio';
import { useTranslate } from 'react-admin';
import { Button, TextField } from '@material-ui/core';
import 'formiojs/dist/formio.builder.css';

import useStyles from './form-builder.style';
import components from './custom-components/custom-components';

import type { FC } from 'react';
import type { FormBuilderViewInterface } from './form-builder.type';

import './bootstrap.min.css';
Components.setComponents(components);

const FormBuilderView: FC<FormBuilderViewInterface> = props => {
  const {
    createOrUpdateCustomForm,
    handleInputChange,
    onEditButtonClick,
    onCancelEditForm,
    customFormOptions,
    formIOComponents,
    componentMode,
    isEditable,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      {componentMode === 'view' ? (
        <div className={classes.formViewContainer}>
          <div className={classes.headerContainer}>
            {isEditable && (
              <Button
                className={classes.headerButton}
                onClick={onEditButtonClick}
                variant="contained"
                color="primary"
              >
                {translate('chat.edit')}
              </Button>
            )}
          </div>
          <div id="formio-result"></div>
        </div>
      ) : (
        <div className={classes.root}>
          <TextField
            label={translate('formBuilder.formName')}
            onChange={handleInputChange}
            variant="outlined"
            required
          ></TextField>
          <div className={classes.formBuilderContainer} id="formBuilder">
            <FormBuilderIo
              form={formIOComponents}
              options={customFormOptions}
              className={classes.formBuilder}
            />
          </div>
          <Button
            variant="outlined"
            className={classes.saveFormButton}
            onClick={createOrUpdateCustomForm}
          >
            {translate('css.save')}
          </Button>
          {componentMode === 'edit' && (
            <Button
              variant="outlined"
              className={classes.saveFormButton}
              onClick={onCancelEditForm}
            >
              {translate('confirm.cancel')}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default FormBuilderView;
