import { type FC } from 'react';
import { useTranslate } from 'react-admin';
import { IconButton, Popover, Tooltip } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { Responsive, WidthProvider } from 'react-grid-layout';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EditIcon from '@material-ui/icons/Edit';
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons';

import { RangePicker } from '../range-picker-jira';
import { NOT_CONFIRMED } from './dashboard-page.helper';
import { limitSizeCard } from './dashboard-page.helper';
import { DashboardCard } from './dashboard-card';
import { useStyles } from './dashboard-page.style';
import { findFormDataBaseOfFilter } from '../range-picker-jira/range-picker.helper';

import './dashboard-page.style.css';

import type { DashboardPageViewPropsInterface } from './dashboard-page.type';

const GridLayout = WidthProvider(Responsive);

const DashboardPageView: FC<DashboardPageViewPropsInterface> = props => {
  const {
    deleteDashboardCard,
    openEditDashboardCardDialog,
    openCreateNewDashboardCardDialog,
    dashboardCardsCoordinates,
    dashboardCardsData,
    editDashboardCard,
    onDashboardCardsCoordinatesChange,
    handleSaveDashboard,
    dashboardGadgetsFilterStatus,
    handleFullScreen,
    handleExitFullScreen,
    dashboardNameRef,
    changeImportantDashboard,
    isFav,
    url,
    isEditing,
    toggleIsEditing,
    handlePinDashboard,
    pinDashboard,
    dashboardEditRef,
    rangePickerAnchorEl,
    openRangePicker,
    closeRangePicker,
    onRangePickerChange,
  } = props;

  const isRangePickerOpen = Boolean(rangePickerAnchorEl);

  const classes = useStyles();
  const translate = useTranslate();

  const responsiveLayouts = {
    lg: limitSizeCard(dashboardCardsCoordinates),
    md: limitSizeCard(dashboardCardsCoordinates),
    sm: limitSizeCard(dashboardCardsCoordinates),
    xs: limitSizeCard(dashboardCardsCoordinates),
    xxs: limitSizeCard(dashboardCardsCoordinates),
  };

  const isFilterFormOpenInDashboard = Object.values(
    dashboardGadgetsFilterStatus,
  ).some(item => item === NOT_CONFIRMED);

  return (
    <div className={classes.root}>
      <div className={classes.dashboardHeader}>
        <span className={classes.titleDashboard}>
          <div className={classes.contentInfoDashboard}>
            {dashboardNameRef.current}
            <div className={classes.iconDashboard}>
              {isFav ? (
                <StarRateRoundedIcon
                  onClick={changeImportantDashboard}
                  className={classes.ClickedStartIcon}
                />
              ) : (
                <StarBorderIcon
                  onClick={changeImportantDashboard}
                  fontSize="small"
                  className={classes.startIcon}
                />
              )}
              {pinDashboard ? (
                <PushpinFilled
                  className={classes.iconPinDashboard}
                  onClick={handlePinDashboard}
                />
              ) : (
                <PushpinOutlined
                  className={classes.iconPinDashboard}
                  onClick={handlePinDashboard}
                />
              )}
            </div>
          </div>
        </span>

        {/* <IconButton color="primary" size="small" onClick={openRangePicker}>
          <DateRangeIcon />
        </IconButton> */}

        <Popover
          id={isRangePickerOpen ? 'rangePicker-popover' : undefined}
          open={isRangePickerOpen}
          anchorEl={rangePickerAnchorEl}
          onClose={closeRangePicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <RangePicker
            fieldName="myDateFieldName"
            onChange={onRangePickerChange}
            defaultValue={findFormDataBaseOfFilter([
              'myDateFieldName',
              'between',
              '1402/07/02 10:20:28',
              '1402/07/26 10:20:29',
            ])}
          />
        </Popover>

        <div className={classes.toolbarEmptySpace}></div>

        <div className={classes.content}>
          {url?.includes('embed') ? (
            <Tooltip title={translate('general.exitFullScreen')}>
              <p
                className={classes.dashboardButton}
                onClick={handleExitFullScreen}
                color="primary"
              >
                <FullscreenExitIcon />
              </p>
            </Tooltip>
          ) : (
            <Tooltip title={translate('general.fullScreen')}>
              <p
                className={classes.dashboardButton}
                onClick={handleFullScreen}
                color="primary"
              >
                <FullscreenIcon />
              </p>
            </Tooltip>
          )}
          {isEditing && (
            <Tooltip title={translate('general.add')}>
              <p
                className={
                  isFilterFormOpenInDashboard
                    ? classes.dashboardButtonDisable
                    : classes.dashboardButton
                }
                onClick={openCreateNewDashboardCardDialog}
                color="primary"
              >
                <AddIcon />
              </p>
            </Tooltip>
          )}
          {isEditing && (
            <Tooltip title={translate('css.save')}>
              <p
                className={classes.dashboardButton}
                onClick={handleSaveDashboard}
                color="primary"
              >
                <SaveIcon />
              </p>
            </Tooltip>
          )}
          {!isEditing && (
            <Tooltip title={translate('general.edit')}>
              <p
                className={classes.dashboardButton}
                onClick={toggleIsEditing}
                color="primary"
              >
                {dashboardEditRef.current ? (
                  <EditIcon />
                ) : (
                  <EditIcon color="disabled" />
                )}
              </p>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.responsiveGridLayoutContainer}>
        <GridLayout
          rowHeight={5}
          autoSize
          isDraggable={isEditing}
          useCSSTransforms
          isResizable={isEditing}
          layouts={responsiveLayouts}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 100, md: 100, sm: 100, xs: 100, xxs: 100 }}
          onLayoutChange={onDashboardCardsCoordinatesChange}
          draggableHandle={'#header-content'}
          compactType={'vertical'}
        >
          {dashboardCardsData.map((dashboardCardData, index) => (
            <div
              key={index}
              data-grid={limitSizeCard(dashboardCardsCoordinates)[index]}
            >
              <DashboardCard
                deleteDashboardCard={deleteDashboardCard}
                openEditDashboardCardDialog={openEditDashboardCardDialog}
                dashboardCardData={dashboardCardData}
                editDashboardCard={editDashboardCard}
                dashboardGadgetsFilterStatus={dashboardGadgetsFilterStatus}
                isEditing={isEditing}
              />
            </div>
          ))}
        </GridLayout>
      </div>
    </div>
  );
};

export default DashboardPageView;
