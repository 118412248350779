import { MailInterface } from '.';
import {
  areTwoObjectsShallowEqual,
  clone,
  isEmpty,
  isEmptyObject,
} from '../../helper/data-helper';
import { actorDispatch, actorGetActionValue } from '../../type/actor-setup';
import { PaginationParams } from './mail-list';
import { getParamFromUrl } from '../../helper/UrlHelper';
import { getTargetParamFromSearchParams } from '../../helper/general-function-helper';

// hardCode resource
export const MailPageResource = 'automation/messages';
export const FolderName = 'folder-name';

// service & report IDs
export const mailReportId = '4692e817-8f63-495e-aa5d-f4740fff8775';
export const currentUserFoldersReportId = 'b346e6f0-f89e-4001-9067-b9e5d967280b';
export const currentUserLabelsReportId = '0a8106e5-c6f0-457c-a5fc-d6f7bcc6baf9';
export const onStarMailId = '72def8f3-4e1f-484c-baff-99b7bf1b1a52';
export const onImportantMailId = '2b8d86f7-b486-4f23-8640-bf907697c7a4';
export const onUnReadMailId = '49df227c-fcb8-4e85-a52e-759e3cf29ddd';
export const submitLabelsId = '8ab2de42-891b-4a30-843d-2a7e9f7928a0';
export const newLabelId = 'f29fbc75-4b64-478e-bfe1-1903339e65de';
export const newFolderId = 'b2c06a3c-81c3-4dcc-a3a4-ff8d7dee08af';
export const deleteFolderServiceId = 'f6506b4e-c9b9-45ad-b808-1034b7901c99';
export const newMessageId = '2163c86b-01ed-4b1b-a1f5-ba0ec4ff6770';
export const archiveMailId = 'de20093a-7b06-4d21-9124-b82532515b06';
export const getMailDetailReportId = '5aec8a0a-94c2-4090-8546-eb23d80fd0c5';
export const getReferenceReportDataId = '8fc2516e-22a3-4040-9e21-78e1cf00f0a3';
export const recallServiceId = '86f40123-d14d-48c7-999f-89c9a49ad916';
export const onArchiveMailId = 'd9a260b9-41aa-42db-baf5-ebc8f5030e88';
export const onMoveToFolderServiceId = '6085fb25-f37a-4fef-a5ea-6e7550a30d83';
export const updateFolderInfoServiceId = 'df98023f-0509-4790-a7f2-96eeaaf521b8';
export const shareFolderServiceId = '417a12d4-2844-4077-a0da-f2f636222c70';
export const newLetterQuickAccessLink = 'automation/letters';
export const mailActionListReportId = '98e97df5-d575-467e-8f38-4e325207982d';

// default value for formData
export const defaultMailFormValue = {
  flagreminderactive: false,
  isflag: false,
  ccinputisactive: false,
  bccinputisactive: false,
};

/**
 * @function removeQuestionSignFromParam
 * @param { string } param
 * @returns { string }
 */
export const removeQuestionSignFromParam = (param: string): string => {
  return param.split('?')[1];
};

/**
 * @function getVariantFromUrl
 * @param { string } param
 * @returns { string }
 */
export const getVariantFromUrl = (param: string): string => {
  return getParamFromUrl(param, 'folder-name') ?? '';
};

/**
 * it returns first part of searchParams if exist before '&'
 * @function generateMainSearchParameter
 * @param { string } searchParams
 * @returns { string }
 */
export const generateMainSearchParameter = (searchParams: string): string => {
  if (isEmpty(searchParams?.substring(0, searchParams.indexOf('&uniqueid')))) {
    return searchParams;
  }
  return searchParams?.substring(0, searchParams.indexOf('&uniqueid'));
};

/**
 * @function getMailFolderID
 * @param {string} searchParam
 * @returns {number | null} folderId
 */
export const getMailFolderID = (searchParam: string): number | null => {
  const uniqueId = getTargetParamFromSearchParams(searchParam, 'id');

  const mailFolders = actorGetActionValue('mailFolders');
  const folderId = mailFolders?.find(
    mailFolder => mailFolder.uniqueid === uniqueId,
  )?.folders_id;

  return folderId ?? null;
};

/**
 * @function handleUpdateIsReadMailsData
 * @param  {string }  mailId
 * @returns { void }
 */
export const handleUpdateIsReadMailsData = (mailId: number): void => {
  handleUpdateMailsData(mailId, { isunread: 0 });
};

/**
 * @function handleUpdateIsReadMailsData
 * @param  {string }  mailId
 * @returns { void }
 */
export const handleUpdateMailsData = (
  mailId: number,
  valuesForUpdate: Record<string, unknown>,
): void => {
  const currentMailsData = actorGetActionValue('mailData')!;
  if (currentMailsData && !isEmptyObject(currentMailsData)) {
    actorDispatch('mailData', {
      ...currentMailsData,
      data: currentMailsData?.data.map((item: MailInterface) =>
        item.doc_id === mailId ? { ...item, ...valuesForUpdate } : item,
      ),
    });
  }
};

/**
 * getSubjectTranslate
 * @param {(string: string) => string} translate
 * @returns
 */
export const getSubjectTranslate = (
  translate: (string: string) => string,
): Record<string, string> => {
  const toReferenceText = `${translate('mail.toReference')} :`;
  const replySubjectText = `${translate('mail.replySubject')} :`;
  const sendText = `${translate('mail.send')} :`;

  return {
    toReferenceText,
    replySubjectText,
    sendText,
  };
};

/**
 * if formData has changed return true and else return false
 * @function checkMailDataDifference
 * @param {FormData} customFormData
 * @returns {boolean}
 */

export const isMailFormDataChanged = (
  customFormData: Record<string, unknown> | null,
): boolean => {
  const currentResource = actorGetActionValue('resources')!.current;
  const currentFormData = clone(
    actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as Record<string, unknown>,
  );

  if (!currentFormData) return false;

  delete customFormData?.id;
  delete currentFormData?.id;

  if (
    !areTwoObjectsShallowEqual(customFormData, currentFormData) ||
    currentFormData.isMailFormChanged == true
  ) {
    return true;
  }
  return false;
};

/**
 *
 * @param {string | null} subject
 * @param { (string: string) => string} translate
 * @returns string
 */
export const cleanSubject = (
  subject: string | null,
  translate: (string: string) => string,
): string => {
  if (!subject) return '';

  const { toReferenceText, replySubjectText, sendText } =
    getSubjectTranslate(translate);

  const regex = new RegExp(
    `${toReferenceText}|${replySubjectText}|${sendText}`,
    'g',
  );

  return subject.replace(regex, '').trim();
};

/**
 * set mail sys urls into grid ids
 * @function setGridIdsForHandlePrevNextButton
 * @param {MailInterface[]} mailData
 * @returns {void}
 */
export const setGridIdsForHandlePrevNextButton = (
  mailData: MailInterface[],
): void => {
  try {
    const allIdsInMailTargetResource: number[] = [];
    let mailTargetResource = '';

    for (const mail of mailData) {
      const [moduleName, moduleTableName, id] = String(mail?.['sysurl'])
        .toLowerCase()
        .split('/');

      allIdsInMailTargetResource.push(+id);

      if (!mailTargetResource && moduleName && moduleTableName) {
        mailTargetResource = `${moduleName}/${moduleTableName}`;
      }
    }

    mailTargetResource &&
      actorDispatch('gridIDs', allIdsInMailTargetResource, {
        path: `${mailTargetResource}.allIDs`,
        replaceAll: true,
      });
  } catch (error) {
    console.log('error: ', error);
  }
};

/**
 * api return static number as total count(25 or 26) and we should compute total count from this number
 * @function getTotalCountByPagination
 * @param {PaginationParams} pagination
 * @returns number
 */
export const getTotalCountByPagination = (pagination: PaginationParams) => {
  return (pagination.currentPage - 1) * pagination.perPage + pagination.total;
};
