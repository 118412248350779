import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  buttonHasChildren: {
    position: 'relative',
    width: '250px',
    height: '150px',
    '& > span': {
      flexFlow: 'column',
    },

    background: theme.palette.primary.appPrimaryTextColor,
    color: theme.palette.primary.appLightBackgroundColor,
  },

  buttonNotChildren: {
    position: 'relative',
    width: '250px',
    height: '150px',
    background: theme.palette.primary.appLightBackgroundColor,
    color: 'black',
    '& > span': {
      flexFlow: 'column',
    },
    '&:hover': {
      background: theme.palette.primary.appLightBackgroundColor,
    },
  },
  cardBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  mailInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '238px', //250px-12px
    height: '150px',
    padding: '0 10px',
  },
  name: {
    fontSize: '16px',
  },
  attributes: {
    position: 'absolute',
    bottom: '5px',
    right: '10px',
  },
  containerStyles: {
    width: '100%',
    height: '100%',
    background: theme.palette.primary.backgroundMainTree,
  },
  cardColorBox: {
    width: 12,
  },
}));
