import { useEffect, useState } from 'react';

import { showNotification } from '../../../helper/general-function-helper';
import { UseGetFormDefaultValues } from '../form-with-tabs';
import { setDefaultFormValues } from '../form-with-tabs/form-with-tabs.helper';
import { getFormDefaultValues } from '../form.helper';

export const useGetFormDefaultValues = (
  params: UseGetFormDefaultValues,
): {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
} => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    allFields,
    globalParameters,
    isCreateMode,
    resource,
    urlInfo,
    parentInfo,
    record,
  } = params;

  useEffect(() => {
    if (!isLoading) return;

    getFormDefaultValues(
      urlInfo,
      parentInfo,
      allFields,
      globalParameters,
      resource,
      isCreateMode,
    )
      .then(data => {
        setDefaultFormValues(record, data ?? {}, resource);
        setIsLoading(false);
      })
      .catch(error => {
        showNotification(error, 'error');
      });
  }, [isLoading]);

  return { isLoading, setIsLoading };
};
