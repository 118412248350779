import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { InputAdornment, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { useStyles } from './add-note.style';
import type { NoteAddViewProps } from './add-todo-note.type';

const AddNoteView = (props: NoteAddViewProps): ReactElement => {
  const {
    onNewNoteChange,
    onNewNoteKeyPress,
    noteText,
    invalidNote,
    handleClickAddIcon,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <div data-test="add-note-section" className={classes.addNote}>
        <TextField
          multiline
          maxRows={2}
          placeholder={translate('noteStream.newNote')}
          fullWidth
          variant="outlined"
          className={classes.textField}
          value={noteText}
          onChange={onNewNoteChange}
          onKeyPress={onNewNoteKeyPress}
          error={invalidNote}
          size="small"
          helperText={invalidNote ? translate('noteStream.invalidNote') : undefined}
          InputProps={{
            classes: { notchedOutline: classes.noBorder },
            startAdornment: (
              <InputAdornment position="start">
                <AddIcon
                  fontSize="small"
                  className={classes.addNoteWithIcon}
                  onClick={handleClickAddIcon}
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};
export default AddNoteView;
