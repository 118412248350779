import React from 'react';
import type { FC } from 'react';
import {
  IconButton,
  MenuItem,
  Menu,
  Icon,
  Tooltip,
  Checkbox,
} from '@material-ui/core';
import { useStyles } from './saved-filters-button.style';
import IconSaveFilter from '../../../component/IconSaveFilter';
import type { SavedFiltersButtonViewPropsInterface } from './saved-filters-button.type';
import CheckIcon from '@material-ui/icons/Check';
import { FilterSaveButton } from '../filter-save-button';

const SavedFiltersButtonView: FC<SavedFiltersButtonViewPropsInterface> = props => {
  const {
    anchorEl,
    disableItem,
    savedFilters,
    clearAllFilters,
    closeSelectMenu,
    handleClick,
    removeFilterHandler,
    translate,
    setNewListFilter,
    showAllFilters,
    clearAllFilterValues,
    isShowAllFiltersButtonDisabled,
    toggleShowFilters,
    isTopOfFilter,
    resource,
  } = props;

  const classes = useStyles();

  return (
    <div data-saved-filters>
      <Tooltip title={translate('filter.list')}>
        <IconButton
          color="primary"
          className={classes.IconButton}
          onClick={handleClick}
          data-test-saved-filter-button
        >
          <IconSaveFilter className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Menu
        id="saved-filter-list-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeSelectMenu}
        anchorReference="anchorEl"
        style={{ marginTop: 48 }}
      >
        <MenuItem className={classes.menuItem} data-saved-filters-click-fire>
          <div className={classes.mainContainerTableGrid}>
            {isTopOfFilter ? <CheckIcon fontSize="small" /> : null}
            <span className={classes.textTableFilter} onClick={toggleShowFilters}>
              {translate('grid.tableFilter')}
            </span>
          </div>
        </MenuItem>
        <MenuItem>
          <FilterSaveButton resource={resource} />
        </MenuItem>
        <MenuItem
          onClick={clearAllFilters}
          className={classes.menuItem}
          data-saved-filters-click-fire
          disabled={disableItem}
        >
          {translate('filter.clearList')}
        </MenuItem>

        {isShowAllFiltersButtonDisabled ? (
          <Tooltip title={translate('filter.disabledBecauseOfLength')} interactive>
            <span>
              <MenuItem
                onClick={showAllFilters}
                className={classes.menuItem}
                disabled
              >
                {translate('filter.showEveryFilterInList')}
              </MenuItem>
            </span>
          </Tooltip>
        ) : (
          <MenuItem onClick={showAllFilters} className={classes.menuItem}>
            {translate('filter.showEveryFilterInList')}
          </MenuItem>
        )}

        <MenuItem
          onClick={clearAllFilterValues}
          className={classes.menuItem}
          disabled={disableItem}
          data-saved-clear-all-filters
        >
          {translate('filter.clearOnlyFilterValuesFromList')}
        </MenuItem>
        {Object.keys(savedFilters).map(id => {
          const item = savedFilters[id];

          return (
            <MenuItem
              key={item.id}
              className={classes.menuItem}
              data-test-filter-item={item.id}
            >
              <div
                className={classes.filterName}
                onClick={setNewListFilter(item)}
                data-test-saved-filter-item={item.id}
              >
                {item.name}
              </div>
              <IconButton
                color="primary"
                onClick={removeFilterHandler(item.id)}
                data-test-delete-saved-filter-item
              >
                <Icon fontSize="small">delete</Icon>
              </IconButton>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default SavedFiltersButtonView;
