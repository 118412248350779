import { isEmptyObject } from './data-helper';
import {
  BOOLEAN_FIELD,
  DATE_FIELD,
  DATETIME_FIELD,
  DECIMAL_FIELD,
  DROP_BASE_MULTI_SELECT_FIELD,
  DROPDOWN_FIELD,
  getTypeByField,
  NUMBER_FIELD,
} from './InputHelper';
import { FieldType, ModeItemInterface } from './Types';

export const HIDE_FILTER = 'HIDE_FILTER';
export const CLEAR_FILTER_VALUE = 'CLEAR_FILTER_VALUE';
export const NO_FILTER_OPERATOR = {
  name: 'noValue',
  operator: 'noValue',
  both: false,
};

export const onlyEqualModeList = [
  {
    name: 'equals',
    operator: 'equals',
    both: false,
  },
];

const dateModeList = [
  {
    name: 'onDate',
    operator: '=',
    both: false,
  },
  {
    name: 'fromDate',
    operator: '>=',
    both: false,
  },
  {
    name: 'untilDate',
    operator: '<=',
    both: false,
  },
  {
    name: 'between',
    operator: 'between',
    both: true,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
  NO_FILTER_OPERATOR,
];

const textModeList = [
  {
    name: 'contains',
    operator: 'contains',
    both: false,
  },
  {
    name: 'equals',
    operator: 'equals',
    both: false,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
  {
    name: 'startsWith',
    operator: 'startsWith',
    both: false,
  },
  NO_FILTER_OPERATOR,
];

const strictModeList = [
  {
    name: 'equals',
    operator: 'equals',
    both: false,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
  NO_FILTER_OPERATOR,
];

const dropMultiselectModeList = [
  {
    name: 'contains',
    operator: 'in',
    both: false,
  },
  NO_FILTER_OPERATOR,
];

const dropModeList = [
  {
    name: 'contains',
    operator: 'contains',
    both: false,
  },
  {
    name: 'notContains',
    operator: 'notContain',
    both: false,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
  {
    name: 'equals',
    operator: 'equals',
    both: false,
  },
  NO_FILTER_OPERATOR,
];

const numberModeList = [
  {
    name: 'contains',
    operator: 'contains',
    both: false,
  },
  {
    name: 'equals',
    operator: 'equals',
    both: false,
  },
  {
    name: 'greaterThanOrEquals',
    operator: '>=',
    both: false,
  },
  {
    name: 'lessThanOrEquals',
    operator: '<=',
    both: false,
  },
  {
    name: 'between',
    operator: 'between',
    both: true,
  },
  {
    name: 'opposite',
    operator: 'notequal',
    both: false,
  },
  NO_FILTER_OPERATOR,
];

/**
 * get `modeList` object base on the field type.
 * @function getModeList
 * @param {object} field
 * @returns {ModeItemInterface[]}
 */
export const getModeList = (field: Partial<FieldType>): ModeItemInterface[] => {
  if (isEmptyObject(field)) {
    return textModeList;
  }

  if (Boolean(field.dropdown?.filterMultiSelect)) {
    return dropMultiselectModeList;
  }

  switch (getTypeByField(field)) {
    case DATE_FIELD:
    case DATETIME_FIELD:
      return dateModeList;

    case BOOLEAN_FIELD:
      return strictModeList;

    case DROPDOWN_FIELD:
      return dropModeList;

    case DROP_BASE_MULTI_SELECT_FIELD:
      return dropMultiselectModeList;

    case NUMBER_FIELD:
    case DECIMAL_FIELD:
      return numberModeList;

    default:
      return textModeList;
  }
};

// prettier-ignore
export const dateTimeRegexString = `\\d{4}-\\d{2}-\\d{2}\\s(\\d{2}:\\d{2}(?::\\d{2}(\\.\\d{3})?)?)`; // 2023-08-10 10:10/10:10:28
// prettier-ignore
export const dateWithOptionalTimeRegexString = `^\\d{4}-\\d{2}-\\d{2}\\s*(?:\\d{2}:\\d{2}(?::\\d{2}(\\.\\d{3})?)?)?$`; // 2023-08-10( 10:10/10:10:28)?

/**
 * add fake (maximum or minimum) time to an string date
 * @function addFakeTimeToDate
 * @param {string} date expect format: YYYY-MM-DD
 * @param {string} time just `min` or `max`
 * @returns {string} string date as format: YYYY-MM-DD HH:mm:ss
 */
const addFakeTimeToDate = (date: string | null, time: 'min' | 'max'): string => {
  if (!date) return '';
  if (new RegExp(dateTimeRegexString, 'g').test(date)) return date;

  return `${date} ${time === 'min' ? '00:00:00' : '23:59:59'}`;
};

/**
 * handle simple date filter without time and add fake time to it base of its operation
 * @function prepareDateTimeFilterValue
 * @param {Array<string>} simpleValue date filter without time
 * @returns {{Array<string>} date filter with time
 */
export const prepareDateTimeFilterValue = (simpleValue: string[]): string[] => {
  const [filterName, operator, filterValue, secondFilterValue] = simpleValue;
  const preparedValue = [filterName];

  switch (operator) {
    case '=': {
      preparedValue.push('between');
      if (filterValue.length > 10) {
        preparedValue.push(addFakeTimeToDate(filterValue.split(' ')[0], 'min'));
        preparedValue.push(filterValue);
      } else {
        preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
        preparedValue.push(addFakeTimeToDate(filterValue, 'max'));
      }

      break;
    }

    case 'notequal': {
      preparedValue.push('notbetween');
      if (filterValue.length > 10) {
        preparedValue.push(addFakeTimeToDate(filterValue.split(' ')[0], 'min'));
        preparedValue.push(filterValue);
      } else {
        preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
        preparedValue.push(addFakeTimeToDate(filterValue, 'max'));
      }
      break;
    }

    case 'between': {
      preparedValue.push(operator);
      preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
      preparedValue.push(addFakeTimeToDate(secondFilterValue, 'max'));
      break;
    }

    case '>=': {
      preparedValue.push(operator);
      preparedValue.push(addFakeTimeToDate(filterValue, 'min'));
      break;
    }

    case '<=': {
      preparedValue.push(operator);
      preparedValue.push(addFakeTimeToDate(filterValue, 'max'));
      break;
    }

    default: {
      return simpleValue;
    }
  }

  return preparedValue;
};
