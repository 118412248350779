import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import lodashGet from 'lodash/get';
import { MailActions, OnSendNewMessageParams } from '..';
import {
  clone,
  isEmpty,
  isEmptyObject,
  isJsonEncodedString,
  megaByteToByte,
} from '../../../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  FormKeyMode,
} from '../../../type/actor-setup';
import { useStyles } from './mail-form-dialog-actions.style';
import { MailFormDialogActionsInterface } from './mail-form-dialog-actions.type';
import MailFormDialogActionsView from './mail-form-dialog-actions.view';
import {
  MultiFileStreamItem,
  SuccessResponse,
} from '../../dynamic-input/multi-file-stream-input/multi-file-stream-input.type';
import { ChangeFormValueParams, FormActions } from '../../form';
import { generateErrorMessage } from '../../dynamic-input/multi-file-stream-input/multi-file-stream-input.helper';
import { AppDialog } from '../../app-dialog';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { MailForm } from '../mail-form';
import { staticFollowUpFormFields } from '../mail-form/mail-form.helper';
import { showNotification } from '../../../helper/general-function-helper';
import { isMailFormDataChanged } from '../mail-section.helper';

const MailFormDialogActionsController: FC<MailFormDialogActionsInterface> = memo(
  props => {
    const { mailActionsHandler, handleCloseNewMessageForm, formName } = props;
    const translate = useTranslate();
    const classes = useStyles();

    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
    const currentResource = actorGetActionValue('resources')!.current;
    const globalParameters = actorGetActionValue(
      'profile',
      'profileData.globalparameters',
    );

    const fileUploadLimitMB = lodashGet(globalParameters, 'fileUploadLimitMB', null);

    const [isFollowUpOpen, setIsFollowUpOpen] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const defaultMailFormDataRef = useRef<Record<string, unknown> | null>({});

    useEffect(() => {
      setTimeout(() => {
        defaultMailFormDataRef.current = clone(
          actorGetActionValue('formData', `automation/messages.${FormKeyMode.ROOT}`),
        );
      }, 500);
    }, []);

    /**
     * show confirm dialog when form data changed
     * @function handleCloseNewDialog
     * @return {void}
     */

    const handleCloseNewDialog = (): void => {
      if (isMailFormDataChanged(defaultMailFormDataRef.current)) {
        actorDispatch('quickDialog', {
          confirmationIsOpen: true,
          data: {
            content: translate?.('ra.message.are_you_sure'),
            onConfirm: (confirmationCallback?: () => void) => {
              handleCloseNewMessageForm();
              confirmationCallback?.();
            },
          },
        });
      } else {
        handleCloseNewMessageForm();
      }
    };

    /**
     * add new value to form data
     * @function onFileSent
     * @param {object} successResponse
     * @return {void}
     */
    const onFileSent = (successResponse: SuccessResponse): void => {
      if (isEmptyObject(successResponse.data)) return;
      const { filePath, realFileName } = successResponse.data;

      const formData = actorGetActionValue(
        'formData',
        `${currentResource.value}.${currentResource.type}`,
      ) as FormData | null;

      const prevValue: Array<MultiFileStreamItem> = formData
        ? isJsonEncodedString(formData['attachfiles'] as Array<MultiFileStreamItem>)
          ? JSON.parse(formData['attachfiles'] as string)
          : formData['attachfiles'] ?? []
        : [];

      formActionsHandler(FormActions.InputChange, {
        fieldName: 'attachfiles',
        value: [...prevValue, { filePath, realFileName }],
      } as ChangeFormValueParams);
    };
    /**
     * it should show error messages
     * @function handleSendFilesErrors
     * @param {string} error
     * @returns {void} void
     */
    const handleSendFilesErrors =
      (largerThanAllowedFiles: Array<File>) =>
      (apiErrors: Array<{ fileName: string; message: string }>) => {
        //  setLoading(false);

        showNotification(
          generateErrorMessage(largerThanAllowedFiles, apiErrors, translate),
          'error',
        );
      };

    /**
     * check the size of coming files, separate the valid and invalid files
     * put invalid files to errors array and make request with valid files
     * @function onFileChange
     * @param {Event} event selected file in input
     * @returns {void} void
     */
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) return;

      const validFiles: Array<File> = [];
      const largerThanAllowedFiles: Array<File> = [];

      for (const file of event.target.files) {
        if (!file) continue;

        // limit files size
        if (
          fileUploadLimitMB &&
          !isEmpty(fileUploadLimitMB) &&
          megaByteToByte(+fileUploadLimitMB) < file.size
        ) {
          largerThanAllowedFiles.push(file);
          continue;
        }

        validFiles.push(file);
      }

      if (validFiles.length) {
        actorDispatch('uploadStreamMultipleFile', {
          param: {
            resource: `automation/messages/multifilestream/attachfiles`,
            files: validFiles,
          },
          successCallback: onFileSent,
          failureCallback: handleSendFilesErrors(largerThanAllowedFiles),
        });
      } else if (largerThanAllowedFiles.length) {
        handleSendFilesErrors(largerThanAllowedFiles)([]);
      }
    };

    /**
     * @function handleOpenFollowUp
     * @returns { void }
     */
    const handleOpenFollowUp = (): void => {
      setIsFollowUpOpen(true);
    };

    /**
     * @function handleCloseFollowUp
     * @returns { void }
     */
    const handleCloseFollowUp = (): void => {
      setIsFollowUpOpen(false);
    };

    /**
     * @function handleSuccessCallback
     * @returns { void }
     */
    const handleSuccessCallback = (): void => {
      handleCloseNewMessageForm();
      setDisableButton(false);
    };

    /**
     * @function handleFailureCallback
     * @returns { void }
     */
    const handleFailureCallback = (error: any): void => {
      showNotification(error, 'error');
      setDisableButton(false);
    };
    /**
     * @function onSendMessage
     * @param { boolean } saveAsDraft
     * @returns { void }
     */
    const onSendMessage = (saveAsDraft: boolean): void => {
      setDisableButton(true);
      mailActionsHandler(MailActions.onSendNewMessage, {
        successCallback: handleSuccessCallback,
        failureCallback: handleFailureCallback,
        isSaveAsDraft: saveAsDraft,
      } as OnSendNewMessageParams);
    };

    /**
     * @function handleClickSplitButton
     * @param { number } selectedIndex
     * @returns { void }
     */
    const handleSubmitForm = (selectedIndex?: number): void => {
      if (formName == 'newMessage') {
        // send message
        if (selectedIndex === 0) {
          onSendMessage(false);
        }
        // save as draft
        else if (selectedIndex === 1) {
          onSendMessage(true);
        }
      } else {
        mailActionsHandler(MailActions.onReference);
      }
    };

    return (
      <>
        <MailFormDialogActionsView
          handleCloseNewMessageForm={handleCloseNewDialog}
          onFileChange={onFileChange}
          handleOpenFollowUp={handleOpenFollowUp}
          handleSubmitForm={handleSubmitForm}
          disableButton={disableButton}
          formName={formName}
        />
        <AppDialog
          open={isFollowUpOpen}
          handleClose={handleCloseFollowUp}
          dialogTitle={translate('mail.followUp')}
          dialogContent={
            <MailForm
              fields={staticFollowUpFormFields}
              formContainerClass={classes.formContainer}
              inputContainerClass={classes.inputContainer}
              showBCCTextButton={false}
            />
          }
          dialogActions={
            <Box>
              <Button
                onClick={handleCloseFollowUp}
                variant="contained"
                color="secondary"
                style={{ backgroundColor: '#1D85D6', color: '#fff' }}
              >
                {translate('general.apply')}
              </Button>
            </Box>
          }
          maxWidth="sm"
        />
      </>
    );
  },
);

export default MailFormDialogActionsController;
