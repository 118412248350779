import { ReactElement, useEffect, useState } from 'react';

import WMS from '../component/wms';
import LoadingBox from '../component/LoadingBox';
import { actorOnDispatch, actorRemoveAction } from '../type/actor-setup';

const WMSPage = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onDispatchId = actorOnDispatch('showLoading', setIsLoading, {
      preserve: false,
    });

    return () => {
      actorRemoveAction({
        actionName: 'showLoading',
        listenerId: onDispatchId,
      });
    };
  }, []);

  if (isLoading) {
    return <LoadingBox />;
  }

  return <WMS />;
};

export default WMSPage;
