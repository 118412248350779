import { RUN_SERVICE } from '../core/data-Provider.helper';
import dataProvider from '../core/dataProvider';
import { isEmptyObject, objectToLowerCaseProperties } from '../helper/data-helper';
import { showNotification } from '../helper/general-function-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../type/actor-setup';

actorOnDispatch('getServiceDefaultValue', async details => {
  if (isEmptyObject(details)) return;
  const { service, params, parentResource, onSuccess, onFailure } = details;

  const { stack } = actorGetActionValue('resources')!;

  // We need parentResource for this reason We get previous resource from stack resources
  const resource = parentResource ?? stack[stack.length - 2].value;

  const isReport = resource.indexOf('report') === 0;
  const [serviceModuleName, serviceModuleTableName] = resource.split('/');

  let dataProviderParam = {};

  if (isReport) {
    dataProviderParam = {
      reportId: serviceModuleTableName,
    };
  } else {
    dataProviderParam = {
      serviceModuleName,
      serviceModuleTableName,
    };
  }

  try {
    const response = await dataProvider(RUN_SERVICE, null, {
      actionUniqueId: `${service?.uniqueId}/defaultvalue`,
      data: {
        params,
      },
      ...dataProviderParam,
      rawResponse: true,
    });

    const compatibleData = objectToLowerCaseProperties(
      response?.data,
      params.id as string | number | undefined,
    );

    if (typeof onSuccess === 'function') {
      onSuccess(compatibleData, service);
    }
  } catch (error) {
    if (typeof onSuccess === 'function') {
      onFailure(error, service);
    }
  }
});

actorOnDispatch(
  'runActionsService',
  detail => {
    const {
      params,
      __questionsResponse,
      actionUniqueId,
      successCallback,
      failureCallback,
    } = detail;
    const { current: currentResource } = actorGetActionValue('resources')!;

    actorDispatch('loading', true, {
      path: `${currentResource.value}`,
    });

    dataProvider(RUN_SERVICE, '', {
      actionUniqueId,
      data: { params, __questionsResponse },
    })
      .then(response => {
        successCallback?.(response);
        actorDispatch('loading', false, {
          path: `${currentResource.value}`,
        });
      })
      .catch(error => {
        failureCallback?.(error);
        showNotification(error, 'error');
        actorDispatch('loading', false, {
          path: `${currentResource.value}`,
        });
      });
  },
  {
    preserve: false,
  },
);
