import { ReactElement } from 'react';

import { AppContainer } from '../app-container';
import { ChatSidebar } from './chat-sidebar';
import { AppDialog } from '../app-dialog';
import { UploadFilesForm } from './upload-files-form';
import { useStyles } from './chat-section.style';
import { MessagesList } from './messages-list';
import { Box } from '@material-ui/core';
import { MessagesHeader } from './messages-header';

import type { ChatSectionViewInterface } from './chat-section.type';

const ChatSectionView = (props: ChatSectionViewInterface): ReactElement => {
  const {
    uploadedFiles,
    isFileUploadDialogOpen,
    handleCloseFileUploadDialog,
    handlePasteFromClipboard,
  } = props;

  const classes = useStyles();

  return (
    <>
      <AppContainer
        onPaste={handlePasteFromClipboard}
        sidebarContent={<ChatSidebar />}
        cardStyle={{
          overflow: 'hidden',
        }}
      >
        <Box className={classes.messagesScreenRoot} id="chatContent">
          <MessagesHeader />
          <MessagesList />
        </Box>
      </AppContainer>
      {isFileUploadDialogOpen && (
        <AppDialog
          open={isFileUploadDialogOpen}
          handleClose={handleCloseFileUploadDialog}
          dialogTitle=""
          dialogContent={
            <UploadFilesForm
              files={uploadedFiles}
              submitFormCallback={handleCloseFileUploadDialog}
            />
          }
          dialogActions={null}
          maxWidth="md"
        />
      )}
    </>
  );
};

export default ChatSectionView;
