import { ReactElement } from 'react';
import { AppSidebarInterface } from './app-sidebar.type';
import { useStyles } from './app-sidebar.style';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { Box } from '@material-ui/core';
import clsx from 'classnames';

const AppSidebarView = (props: AppSidebarInterface): ReactElement => {
  // prettier-ignore
  const { isAppDrawerOpen, onCloseDrawer, fullView, sidebarContent, cardStyle } = props;
  const classes = useStyles({ fullView });

  return (
    <Box className={classes.appsSidebar}>
      {/* @ts-ignore */}
      <Hidden lgUp>
        <Drawer
          open={isAppDrawerOpen}
          onClose={onCloseDrawer}
          classes={{
            paper: clsx(classes.appSidebarDrawer),
          }}
          style={{ position: 'absolute' }}
        >
          {sidebarContent}
        </Drawer>
      </Hidden>
      {/* @ts-ignore */}
      <Hidden mdDown>
        <Card className={classes.sideBarContent} style={cardStyle}>
          {sidebarContent}
        </Card>
      </Hidden>
    </Box>
  );
};

export default AppSidebarView;
