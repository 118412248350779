import { isEmpty, isEmptyObject } from '../../../../helper/data-helper';
import {
  FILE_FIELD,
  getTypeByField,
  SEARCH_FIELD,
} from '../../../../helper/InputHelper';
import {
  actorGetActionValue,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { FormData, InputRefContent } from '../../../form';
import { getFocusedInputName } from '../../../form/form.helper';
import { updateValuesOfInputsAndFormDataAfterSubmit } from '../../wms.helper';
import { WmsFieldsInRowType } from './wms-form.type';

import type { FieldType } from '../../../../helper/Types';

/**
 * It handles all necessary process of form's behaviors(e.g. focus to the next input, submitting the form on the last input under different situations)
 * @function formHandlerOnPressingEnterKey
 * @param {Record<string, InputRefContent> | null} inputsRef
 * @param {() => void} onSubmit
 * @returns {Promise<void>} a promise of void
 */
export const formHandlerOnPressingEnterKey = async (
  inputsRef: Record<string, InputRefContent> | null,
  onSubmit?: (formData?: FormData) => void,
): Promise<void> => {
  if (inputsRef == null) return;

  try {
    const wmsAsyncActionList = actorGetActionValue('wmsAsyncActionList') ?? [];
    await Promise.all(wmsAsyncActionList);
  } catch (error) {
    console.error('`formActionsHandler`: saving error => ', error);
  }

  const currentResource = actorGetActionValue('resources')!.current;
  const formData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  ) as FormData | null;

  const currentFocusedInputName = getFocusedInputName(inputsRef);
  if (!currentFocusedInputName) {
    console.error('formHandlerOnPressingEnterKey: any focused input not found');
    return;
  }

  actorGetActionValue(
    'formGlobalProps',
  )!.formFocusManagementFunctions.focusOnNextInput(
    inputsRef,
    currentFocusedInputName,
    formData,
    () => {
      onSubmit?.();
    },
  );
};

/**
 * It resets the current form inputs and changes focus to the first input
 * @function resetWMSForm
 * @param {
 *   fieldNameList: string[];
 *   forceEmptyFields?: boolean;
 *   dataToReplace?: Record<string, unknown> | null;
 * } options
 * @returns {void} void
 */
export const resetWMSForm = (options: {
  fieldNameList: string[];
  forceEmptyFields?: boolean;
  dataToReplace?: Record<string, unknown> | null;
}): void => {
  const { fieldNameList, dataToReplace, forceEmptyFields } = options;
  const currentResource = actorGetActionValue('resources')!.current;
  const inputsRef = actorGetActionValue(
    'inputsRef',
    `${currentResource.value}.${currentResource.type}`,
  )! as Record<string, InputRefContent> | undefined;

  if (inputsRef == null) return;

  const formFocusManagementFunctions =
    actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;

  updateValuesOfInputsAndFormDataAfterSubmit({
    inputsRef,
    fieldNameList,
    newFormData: dataToReplace,
    forceEmptyFields,
  });

  formFocusManagementFunctions.focusOnFirstInputAfterSubmit();
};

/**
 * @function submitHandler
 * @param {(formData?: FormData) => void} onSubmit
 * @returns {(): void} a function
 */
export const submitHandler =
  (onSubmit?: (formData?: FormData) => void) => (): void => {
    {
      const currentResource = actorGetActionValue('resources')!.current;

      const currentFormData = actorGetActionValue(
        'formData',
        `${currentResource.value}.${currentResource.type}`,
      ) as FormData | undefined;

      onSubmit?.(currentFormData);

      const { focusOnFirstInputAfterSubmit } =
        actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;

      focusOnFirstInputAfterSubmit();
    }
  };

/**
 * @function checkCurrentFocusedFieldIsSearchField
 * @param { Record<string, InputRefContent> | null } inputsRef
 * @returns { boolean } a boolean
 */
export const checkCurrentFocusedFieldIsSearchField = (
  inputsRef: Record<string, InputRefContent> | null,
): boolean => {
  if (inputsRef == null) {
    console.error('`currentFocusedFieldIsSearchField`: inputsRef is null');
    return false;
  }

  const focusedInputName = getFocusedInputName(inputsRef);

  if (
    focusedInputName &&
    inputsRef![focusedInputName]?.field &&
    getTypeByField(inputsRef![focusedInputName]!.field) === SEARCH_FIELD
  ) {
    return true;
  }

  return false;
};

/**
 * @function updateFormDataAndInputs
 * @param {boolean} prevTabIsAction
 * @returns {void} void
 */
export const updateFormDataAndInputs = (prevTabIndex: number): void => {
  const currentResource = actorGetActionValue('resources')!.current;
  const rootResource = actorGetActionValue('resources')!.stack[0];

  const inputsRef = actorGetActionValue(
    'inputsRef',
    `${currentResource.value}.${currentResource.type}`,
  ) as Record<string, InputRefContent> | null;

  if (inputsRef == null) return;

  const allFormData = actorGetActionValue('formData') ?? {};
  // prettier-ignore
  const currentFormData = allFormData[currentResource.value]?.[currentResource.type] ?? {};
  // prettier-ignore
  const prevTabFormData = allFormData[`${rootResource.value}/tab-${prevTabIndex}`]?.[currentResource.type] ?? {};

  const finalFormData: Record<string, unknown> = { ...currentFormData };
  if (isEmptyObject(currentFormData)) {
    for (const inputName in inputsRef) {
      finalFormData[inputName] =
        prevTabFormData[inputName] ??
        inputsRef[inputName].field.defaultValue ??
        null;
    }
  } else {
    for (const inputName in inputsRef) {
      if (!isEmpty(finalFormData[inputName])) continue;
      finalFormData[inputName] = prevTabFormData[inputName] ?? null;
    }
  }

  actorSetActionValue('formData', finalFormData, {
    path: `${currentResource.value}.${currentResource.type}`,
    callerScopeName: 'wms-form.helper => updateFormDataAndInputs',
  });
};

export const computeWmsMaxHeight = (
  fieldsInRow: WmsFieldsInRowType,
): number | undefined => {
  const DEFAULT_IMAGE_HEIGHT = 200;
  let isImageExistInRow = false;

  const rowMaximumHight = fieldsInRow.reduce<number | undefined>(
    (acc, currentField): number | undefined => {
      if (currentField && typeof currentField === 'object') {
        if (getTypeByField(currentField) === FILE_FIELD) {
          isImageExistInRow = true;
        }

        if (currentField?.height) {
          if (acc) {
            return acc > currentField.height ? acc : currentField.height;
          } else {
            return currentField.height;
          }
        }
      }

      return acc;
    },
    undefined,
  );

  return isImageExistInRow
    ? rowMaximumHight ?? DEFAULT_IMAGE_HEIGHT
    : rowMaximumHight;
};

/**
 * Combines the current form data with the new data that incoming from the `api`, based on `maps` key in `meta` of the `field`
 * @function mapSearchFieldDataToFormData;
 * @param { FieldType } field
 * @param { Record<string, unknown> } currentFormData
 * @param { Record<string, unknown> } newData
 * @returns { Record<string, unknown> } - An object
 */
export const mapSearchFieldDataToFormData = (
  field: FieldType | null | undefined,
  currentFormData: Record<string, unknown>,
  newData: Record<string, unknown>,
): Record<string, unknown> => {
  if (field == null) return newData;

  const searchInputMaps = field.searchDialog?.maps;
  if (!Array.isArray(searchInputMaps) || searchInputMaps.length === 0) {
    return newData;
  }

  const _currentFormData = { ...currentFormData, ...newData };
  for (const item of searchInputMaps) {
    _currentFormData[item.to] = newData[item.from];
  }

  return _currentFormData;
};
