import { useTranslate } from 'react-admin';
import { TextField, Icon, IconButton, Tooltip, Popover } from '@material-ui/core';
import classNames from 'classnames';

import { useStyles } from './filter-button.style';
import FilterButtonMenuItem from './filter-button-menu-item';

import type { FC } from 'react';
import type { FilterButtonViewInterface } from './filter-button.type';

const FilterButtonView: FC<FilterButtonViewInterface> = props => {
  const {
    fieldListToFilter,
    anchorEl,
    searchFilterWord,
    resource,
    className,
    locale,
    searchInputRef,
    handleSearchInputChange,
    getButtonRef,
    handleClickButton,
    handleCloseFilterMenu,
    menuItemClickHandler,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classNames(classes.root, className)} id="addFilterToGridTestId">
      <Tooltip title={translate('ra.action.add_filter')}>
        <IconButton
          className={classes.IconButton}
          ref={getButtonRef}
          onClick={handleClickButton}
          color="primary"
          size="small"
        >
          <Icon className={classNames(classes.icon, 'fa fa-filter')} />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseFilterMenu}
        anchorReference="anchorEl"
        style={{ marginTop: 32 }}
      >
        <TextField
          className={classes.searchFilter}
          placeholder={translate('ra.action.search')}
          onChange={handleSearchInputChange}
          defaultValue={searchFilterWord}
          id="search-filter-input"
          inputRef={searchInputRef}
        />
        {fieldListToFilter.length > 0 ? (
          fieldListToFilter.map(filterField => (
            <FilterButtonMenuItem
              key={`FilterButtonMenuItem_${filterField.source}`}
              fieldToFilter={filterField}
              resource={resource}
              locale={locale}
              menuItemClickHandler={menuItemClickHandler}
            />
          ))
        ) : (
          <p className={classes.notItemFoundText}>{translate('ra.noItemFound')}</p>
        )}
      </Popover>
    </div>
  );
};

export default FilterButtonView;
