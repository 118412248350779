import { type FC } from 'react';
import Button from '@material-ui/core/Button';
import { useStyles } from './avatar-settings.style';
import { ProfileAvatar } from '../profile-avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { LogoutButton } from '../app-layout/custom-app-bar/logout-button';
import useWidth from '../useWidth';
import TabParent from '../form-component-old/TabParent';
import TabChild from '../form-component-old/TabChild';
import { CustomForm, FormController } from '../form';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import { ProfileForm } from '../form/profile-form';
import UploadFileContainer from '../../container/UploadFileContainer';
import { ChangePasswordForm } from '../form/change-password-form';
import { profileSettingFields } from './avatar-settings.helper';
import {
  USER_ID,
  USER_WAREHOUSE_TITLE,
  getValue,
  localStorageGetItem,
} from '../../core/configProvider';
import { AvatarSettingsViewInterface } from './avatar-settings.type';
import { ChangeZoomScale } from '../change-zoom-scale';

const AvatarSettingsView: FC<AvatarSettingsViewInterface> = props => {
  const {
    handleProfileFormClick,
    openDialog,
    anchorEl,
    handleCloseProfileMenu,
    profileData,
    getProfileDisplayName,
    profileSettingHandler,
    translate,
    isProfileFormOpen,
    handleCloseProfileForm,
    onTabChange,
    resource,
    handleNewProfileCreate,
    apiName,
    fileUploadLimitMB,
    resetPasswordFields,
    rest,
  } = props;

  const currentUserId: string = getValue(USER_ID);
  const classes = useStyles();
  const width = useWidth();
  const userData = localStorageGetItem<string | null>(USER_WAREHOUSE_TITLE) ?? '';
  return (
    <>
      <div className={classes.container}>
        <Button
          className={classes.menuButton}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleProfileFormClick}
        >
          <div className={classes.avatarContainer} id="profileAvatar">
            <ProfileAvatar
              userId={currentUserId}
              bigAvatar={true}
              onClick={openDialog}
            />
          </div>
          <ArrowDropDownIcon className={classes.arrowDropDown} />
        </Button>
      </div>

      <Menu
        classes={{ paper: classes.menu }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseProfileMenu}
      >
        <div className={classes.userImage}>
          <ProfileAvatar userId={currentUserId} bigAvatar={true} />
        </div>
        {profileData && (
          <div className={classes.mainContainer}>
            <div className={classes.userName}>
              <Typography
                variant="caption"
                noWrap
                className={classes.profileDisplayName}
              >
                {getProfileDisplayName(profileData)}
              </Typography>
            </div>

            <div className={classes.userName}>
              <Typography
                variant="caption"
                noWrap
                className={classes.profileDisplayName}
              >
                {userData}
              </Typography>
            </div>
          </div>
        )}
        <ChangeZoomScale />
        <MenuItem className={classes.setting} onClick={profileSettingHandler}>
          {translate?.('general.setting')}
        </MenuItem>
        <LogoutButton />
      </Menu>
      <Dialog
        open={isProfileFormOpen}
        onClose={handleCloseProfileForm}
        maxWidth={width}
        aria-labelledby="simple-dialog-title"
        classes={{ paper: classes.dialogVisible }}
      >
        <DialogContent classes={{ root: classes.dialogVisible }}>
          <TabParent onChange={onTabChange}>
            <TabChild label={translate('profile.profileSettings')} name={'profile'}>
              <FormController formName="profileForm" {...rest}>
                <CustomForm
                  Toolbar={
                    <QuickCreateButtonToolbar
                      closeDialog={handleCloseProfileForm}
                      disableFullFormButton={true}
                      justSaveAndClose={true}
                      isFromDropdown={false}
                      resource={resource}
                    />
                  }
                  isBottomToolbar
                >
                  <ProfileForm fields={profileSettingFields} />
                </CustomForm>
              </FormController>
            </TabChild>
            <TabChild
              label={translate('menu.uploadProfilePicture')}
              name={'profile'}
            >
              <div>
                <UploadFileContainer
                  onChange={handleNewProfileCreate}
                  resource={`account/${apiName}/profile`}
                  fileUploadLimitMB={fileUploadLimitMB}
                />
              </div>
            </TabChild>
            <TabChild
              label={translate('menu.changePassword')}
              name={'changepassword'}
            >
              <FormController formName="changePassword">
                <CustomForm
                  Toolbar={
                    <QuickCreateButtonToolbar
                      closeDialog={handleCloseProfileForm}
                      disableFullFormButton={true}
                      justSaveAndClose={true}
                      isFromDropdown={false}
                      resource={resource}
                    />
                  }
                  isBottomToolbar
                >
                  <ChangePasswordForm fields={resetPasswordFields} />
                </CustomForm>
              </FormController>
            </TabChild>
          </TabParent>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AvatarSettingsView;
