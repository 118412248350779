import {
  actorDispatch,
  actorSetActionValue,
  ApiRequestResultInterface,
  FormKeyMode,
  RecordKeyMode,
} from '../type/actor-setup';
import { GET_ONE, GET_MANY_REFERENCE, GET_LIST } from 'react-admin';
import { CRUDActionsType, CrudResultInterface } from '../type/data-provider';
import { addFakeIdsToEachRecordOfDataArrayByReference } from '../component/relation-panel/relation-panel.helper';
import { showNotification } from '../helper/general-function-helper';
import { arrayResultToObjectWithLowerCase } from './data-helper';
import { isEmptyObject } from './data-helper';

/**
 * it get signal from crud request and handle its status to fill data in actor or show error notification
 * @function apiRequestResultHandler
 * @param {object} apiSignal
 * @returns {void} void
 */
export const apiRequestResultHandler = (
  apiSignal: ApiRequestResultInterface,
): void => {
  let relatedSignal: CrudResultInterface | undefined;
  let requestType: string | undefined;

  const getManyReferenceSignal: CrudResultInterface | undefined =
    apiSignal[GET_MANY_REFERENCE]?.relation;

  const getListRelationSignal: CrudResultInterface | undefined =
    apiSignal[GET_LIST]?.relation;

  const getListSignal: CrudResultInterface | undefined = apiSignal[GET_LIST]?.list;

  //We have different entities (showRecordPage,createEditRecordPage) therefore if requestType was GET_ONE, We should get the first value in the object
  //prettier-ignore
  const getOneSignal: CrudResultInterface | undefined = !isEmptyObject(apiSignal[GET_ONE]) ? Object.values(apiSignal[GET_ONE])[0] : undefined;

  if (getManyReferenceSignal) {
    requestType = GET_MANY_REFERENCE;

    relatedSignal = getManyReferenceSignal;
  } else if (getListRelationSignal) {
    requestType = GET_LIST;
    relatedSignal = getListRelationSignal;
  } else if (getListSignal) {
    requestType = GET_LIST;
    relatedSignal = getListSignal;
  } else if (getOneSignal) {
    requestType = GET_ONE;
    relatedSignal = getOneSignal;
  }

  if (!relatedSignal) return;

  const { status, data, payload, resource } = relatedSignal;

  if (status === 'success') {
    const {
      data: serverData,
      total,
      requestId,
    } = data as {
      data: Array<Record<string, unknown>>;
      total: number;
      requestId: string;
    };

    if (requestType === 'GET_ONE') {
      const _data = (data as Record<string, unknown> | undefined) ?? {};

      actorDispatch('formData', _data.data, {
        path: `${resource}.${FormKeyMode.ROOT}`,
        replaceAll: true,
        callerScopeName: 'apiRequestResultHandler => (requestType === GET_ONE)',
      });
    } else {
      actorSetActionValue('gridData', total ?? 0, {
        path: `${resource}.totalCount`,
      });

      actorSetActionValue('gridData', requestId, {
        path: `${resource}.lastRequestId`,
      });

      addFakeIdsToEachRecordOfDataArrayByReference(serverData);

      actorDispatch('gridData', serverData, {
        path: `${resource}.data`,
        disableDebounce: true,
      });
    }
  } else {
    const { disableNotification } = payload as {
      disableNotification: boolean;
      recordId: string;
    };

    if (!disableNotification) {
      showNotification('ra.notification.http_error', 'error');
    }
  }

  // remove result from actor state
  actorSetActionValue('apiRequestResult', null, {
    path: `${requestType}.relation`,
  });
  actorSetActionValue('apiRequestResult', null, {
    path: `${GET_ONE}.showRecordPage`,
  });
};

/**
 * @function apiRequestFailureResultHandler
 * @param { ApiRequestResultInterface } error
 * @param { CRUDActionsType } type
 * @param { string } entity
 * @returns { void }
 */
export const apiRequestFailureResultHandler = (
  apiErrorResult: ApiRequestResultInterface,
  type: CRUDActionsType,
  entity: string,
): void => {
  if (!apiErrorResult) {
    return;
  }

  const relatedApiErrorResult = apiErrorResult?.[type]?.[entity];

  showNotification(
    (relatedApiErrorResult as Record<string, any>)?.['data']?.['userMessage'],
    'error',
  );
};
