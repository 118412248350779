import { Avatar, Box, Typography } from '@material-ui/core';
import momentJalaali from 'moment-jalaali';
import { FC } from 'react';

import { onClickNotificationItem } from '../notification-panel.helper';
import { useStyles } from './notification-item.style';
import { NotificationItemViewInterface } from './notification-item.type';
import clsx from 'classnames';
import { CONFIG_CALENDAR, getValue } from '../../../core/configProvider';
import { apiUrl } from '../../../core/data-Provider.helper';
import { Item } from 'react-contexify';
import { isJalali } from '../../../helper/CalendarMetaHelper';

const NotificationItemView: FC<NotificationItemViewInterface> = props => {
  const { itemInfo, pattern, handleClickNotificationItem } = props;
  const classes = useStyles();

  const { notifyText, isSeen, id, imageUrl } = pattern;

  const currentCalendar: string = getValue(CONFIG_CALENDAR);
  momentJalaali.locale(isJalali(currentCalendar) ? 'fa' : 'en');
  return (
    <Item
      className={clsx(classes.notifyItem, !itemInfo[isSeen] ? classes.isSeen : null)}
      onClick={handleClickNotificationItem(itemInfo)}
    >
      <div
        id={itemInfo[id]}
        className={classes.notificationCard}
        data-test-notification-item={`${itemInfo[id]}`}
        data-style-card-notify="cardNotify"
      >
        <div
          className={classes.notificationCardBody}
          data-style-row-notify="rowNotify"
        >
          <Avatar
            data-test="avatarChange"
            className={classes.avatar}
            alt={'notification'}
            src={itemInfo[imageUrl] ? `${apiUrl}/${itemInfo[imageUrl]}` : ''}
            data-style-avatar-notify="avatarNotify"
          />
          <Typography
            variant="body1"
            className={clsx(
              classes.notifytext,
              !itemInfo[isSeen] ? classes.seenNotifyText : null,
            )}
            data-style-text-notify="textNotify"
          >
            {itemInfo[notifyText]}
          </Typography>
        </div>
      </div>
    </Item>
  );
};

export default NotificationItemView;
