/**
 * Get days of a month that should be shown on a month page
 *
 * @param month A moment object
 * @returns {Array}
 */
export function getDaysOfMonth(month, isGregorian, locale) {
  const days = [];

  const monthFormat = isGregorian ? 'Month' : 'jMonth';
  const dayOffset = isGregorian ? 0 : 1;

  const current = month.clone().startOf(monthFormat);
  const end = month.clone().endOf(monthFormat);

  // Set start to the first day of week in the last month
  current.subtract((current.day() + dayOffset) % 7, 'days');

  // Set end to the last day of week in the next month
  end.add(6 - ((end.day() + dayOffset) % 7), 'days');

  while (current.isBefore(end)) {
    days.push(current.clone());
    current.add(1, 'days');
  }

  return days;
}

export function addZero(val) {
  val = Number(val);
  if (val < 10) return `0${val}`;
  return val;
}

export function checkToday(compare) {
  const today = new Date();
  const todayString =
    String(today.getFullYear()) +
    addZero(String(today.getMonth() + 1)) +
    addZero(String(today.getDate()));

  return compare === todayString;
}

export function toEnglishDigits(str) {
  if (!str) return str;
  const regex1 = /[\u0660-\u0669]/g;
  const regex2 = /[\u06f0-\u06f9]/g;
  const regex3 = /ق.ظ/g;
  const regex4 = /ب.ظ/g;
  return str
    .replace(regex1, function(c) {
      return c.charCodeAt(0) - 0x0660;
    })
    .replace(regex2, function(c) {
      return c.charCodeAt(0) - 0x06f0;
    })
    .replace(regex3, 'AM')
    .replace(regex4, 'PM');
}

export function toPersianDigits(str) {
  if (!str) return str;
  const regex = /[0-9]/g;
  const regex1 = /AM/g;
  const regex2 = /PM/g;
  const id = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  return str
    .replace(regex, function(w) {
      return id[+w];
    })
    .replace(regex1, 'ق.ظ')
    .replace(regex2, 'ب.ظ');
}

export function toPascalCase(s) {
  s.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
}

export const convertToGregorianPersian = s => {
  return `${s.split(' ')[0]} ${toPersianDigits(s.split(' ')[1])}`;
};
