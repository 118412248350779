import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import RefreshIcon from '@material-ui/icons/Refresh';
import AppBar from '@material-ui/core/AppBar';
import { Divider, Typography, useTheme } from '@material-ui/core';
import lodashGet from 'lodash/get';
import Link from '@material-ui/core/Link';
import { useTranslate } from 'react-admin';

import {
  CustomAppBarViewInterface,
  ParentInformationInRecordInterface,
} from './custom-app-bar.type';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  RecordKeyMode,
} from '../../../type/actor-setup';
import { getValue, DISABLE_CHAT_MODULE } from '../../../core/configProvider';
import { Breadcrumb } from '../../../component/breadcrumb';
import { getDrawerWidth } from '../drawer/drawer.helper';
import { isEmpty } from '../../../helper/data-helper';
import { useStyles } from './custom-app-bar.style';
import { HomeTitle } from '../../home-title';
import useWidth from '../../useWidth';
import { MessageNotification } from '../../message-notification';
import { QuickAccessMenu } from '../../quick-access-menu';
import AppBarBackButton from '../../AppBarBackButton';
import { LogoutButton } from './logout-button';
import { NotificationPanel } from '../../notification-panel';
import { WMS } from '../../../core/configRouteConstant';
import { WMSFetchMetaResult } from '../../wms';
import { AvatarSettings } from '../../avatar-settings';

// FIXME: refactor to separate the logics from its `view`
const CustomAppBar: FC<CustomAppBarViewInterface> = props => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [parentInformationInRecord, setParentInformationInRecord] =
    useState<ParentInformationInRecordInterface | null>(null);

  const locale = actorGetActionValue('reactAdminHelpers')?.locale;

  const [urlInfo, setUrlInfo] = useState(
    actorGetActionValue('urlInfo') ?? {
      params: { moduleName: '', moduleTableName: '', id: '' },
    },
  );

  const isChatModuleDisabled = getValue(DISABLE_CHAT_MODULE);

  const [showWMSTitle, setWMSTitle] = useState(false);
  const wmsAppBarInfo = useRef<{
    title: string;
    link: string;
  }>({
    title: '',
    link: '',
  });

  useEffect(() => {
    actorOnDispatch('record', record => {
      const currentResource = actorGetActionValue('resources')?.current;
      if (!currentResource) return;

      const _record = lodashGet(
        record,
        [currentResource.value, currentResource.type, RecordKeyMode.FULL],
        {},
      );

      const newParentInformationInRecord: ParentInformationInRecordInterface =
        (_record?.['__parentInfo'] as ParentInformationInRecordInterface) ?? null;

      setParentInformationInRecord(newParentInformationInRecord);
    });

    actorOnDispatch('urlInfo', _urlInfo => {
      if (
        _urlInfo.params.id !== urlInfo.params?.id ||
        _urlInfo.params.moduleName !== urlInfo.params.moduleName ||
        _urlInfo.params.moduleTableName !== urlInfo.params.moduleTableName
      ) {
        setWMSTitle(false);
        setUrlInfo(_urlInfo);

        // its necessary to check parent information in this scope again
        // because its possible to already have been got the record in prevues step
        // and wanna back to that page . so url info will be dispatch and record dose not.
        // it can cause to have double breadcrumb if dont check here.
        const currentResource = actorGetActionValue('resources')?.current;

        if (!currentResource) return;

        const record = actorGetActionValue(
          'record',
          `${currentResource.value}.${currentResource.type}.${RecordKeyMode.FULL}`,
        ) as unknown as Record<string, unknown>;

        const newParentInformationInRecord: ParentInformationInRecordInterface =
          (record?.['__parentInfo'] as ParentInformationInRecordInterface) ?? null;

        setParentInformationInRecord(newParentInformationInRecord);
      }
    });

    // TODO: @honarvar check below code and remove on dispatch
    actorOnDispatch('metaData', metaData => {
      const rootResource = actorGetActionValue('resources')?.stack[0];
      if (!rootResource?.value.includes('wms')) return;

      const wmsMetaData = metaData[rootResource.value] as WMSFetchMetaResult;
      if (!isEmpty(wmsMetaData?.data?.title)) {
        wmsAppBarInfo.current.title = wmsMetaData.data!.title;

        actorDispatch(
          'setDocumentTitle',
          {
            metaData: null,
            recordTitle: wmsAppBarInfo.current.title,
            locale: locale,
          },
          { replaceAll: true },
        );
      }

      const menuList = actorGetActionValue('menuData')?.items;
      const wmsMenu = menuList?.filter(
        item => (item?.title as string)?.toLowerCase() === WMS,
      )[0];

      if (!isEmpty(wmsMenu?.id)) {
        wmsAppBarInfo.current.link = `menu/${wmsMenu!.id}`;
      }

      setWMSTitle(true);
    });
  }, []);

  const parsedParentInfo = useMemo(() => {
    if (!parentInformationInRecord) {
      return {
        parentModuleName: null,
        parentModuleTableName: null,
        parentId: null,
      };
    }

    const data = parentInformationInRecord.route?.split('/') ?? [];

    return {
      parentModuleName: data[1],
      parentModuleTableName: data[2],
      parentId: data[3],
    };
  }, [parentInformationInRecord]);

  const drawerWidth = useMemo(() => {
    return getDrawerWidth(isDrawerOpen);
  }, [isDrawerOpen]);

  const width = useWidth();
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();
  const translate = useTranslate();

  const { parentModuleName, parentModuleTableName, parentId } =
    parsedParentInfo ?? {};
  const { moduleName, moduleTableName } = urlInfo.params;

  /**
   * toggle drawer
   * @function toggleMenuDrawer
   * @returns {void}
   */
  const toggleMenuDrawer = useCallback(() => {
    const isDrawerOpen = actorGetActionValue('isDrawerOpen');
    actorDispatch('isDrawerOpen', !isDrawerOpen);
  }, []);

  useEffect(() => {
    actorOnDispatch(
      'isDrawerOpen',
      isDrawerOpen => {
        setIsDrawerOpen(isDrawerOpen);
      },
      {
        preserve: false,
      },
    );
  }, []);

  useEffect(() => {
    if (window.location?.hash.includes('menu'))
      actorDispatch(
        'setDocumentTitle',
        {
          metaData: null,
          recordTitle: 'WMS',
          locale: locale,
        },
        { replaceAll: true },
      );
    if (window.location?.hash.includes('advancepermission'))
      actorDispatch(
        'setDocumentTitle',
        {
          metaData: null,
          recordTitle: translate('permissions.advancePermission'),
          locale: locale,
        },
        { replaceAll: true },
      );
    if (window.location?.hash.includes('showpermissions'))
      actorDispatch(
        'setDocumentTitle',
        {
          metaData: null,
          recordTitle: translate('permissions.permission'),
          locale: locale,
        },
        { replaceAll: true },
      );
  }, []);

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={`${
        isDrawerOpen
          ? theme.direction === 'rtl'
            ? classes.appBarRight
            : classes.appBarLeft
          : theme.direction === 'rtl'
          ? classes.appBarRight
          : classes.appBarLeft
      } ${classes.appBar}`}
      id="header"
    >
      <div id="app-bar-portal">
        {/* @ts-ignore */}
        <Toolbar className={classes.mainHeader}>
          {width === 'xs' && (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleMenuDrawer}
              className={classes.menuButton}
              id="menuIcon"
            >
              <MenuIcon />
            </IconButton>
          )}
          <HomeTitle />

          {showWMSTitle && (
            <div>
              {!isEmpty(wmsAppBarInfo.current.link) && (
                <>
                  <Link
                    className={classes.wmsLink}
                    underline="none"
                    component={RouterLink}
                    to={`/${wmsAppBarInfo.current.link}`}
                  >
                    WMS
                  </Link>
                  &nbsp;/&nbsp;
                </>
              )}

              <Typography
                component="span"
                variant="button"
                color="inherit"
                id="headerTitle"
              >
                {wmsAppBarInfo.current.title}
              </Typography>
            </div>
          )}

          {parentInformationInRecord && (
            <>
              <Breadcrumb
                isHeaderPage
                moduleName={moduleName}
                moduleTableName={moduleTableName}
                parentInformation={{
                  parentResource: `${parentModuleName}/${parentModuleTableName}`,
                  parentTitle: parentInformationInRecord.caption ?? '',
                }}
              />

              <RouterLink
                color="inherit"
                className={classes.appBarTitleParent}
                to={`/${parentModuleName}/${parentModuleTableName}/${parentId}/show`}
              >
                #{parentInformationInRecord.id}
              </RouterLink>
            </>
          )}

          {!isEmpty(urlInfo.params.id) && (
            <Breadcrumb
              isHeaderPage={false}
              moduleName={moduleName}
              moduleTableName={moduleTableName}
              parentInformation={parentInformationInRecord}
            />
          )}
          <div
            color="inherit"
            className={classes.appBarTitle}
            id="react-admin-title"
          />
          <div color="inherit" id="reactAdminHeaderButtonContainer" />

          <QuickAccessMenu />
          <Divider
            orientation="vertical"
            flexItem
            light
            className={classes.divider}
          />
          {!isChatModuleDisabled && <MessageNotification />}
          {!isChatModuleDisabled && <NotificationPanel />}
          <IconButton
            onClick={() => location.reload()}
            color="inherit"
            data-test="container"
            id="refreshIcon"
          >
            <RefreshIcon />
          </IconButton>
          <AppBarBackButton />
          <div>
            <AvatarSettings />
          </div>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default CustomAppBar;
