import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactElement,
} from 'react';
import { useLocale } from 'react-admin';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate } from 'react-admin';

import {
  isEmpty,
  isEmptyObject,
  separateRecordAndRelationRecord,
} from '../helper/data-helper';
import { FormController } from '../component/form';
import { CustomForm } from '../component/form/form.view';
import { FormWithTabs } from '../component/form/form-with-tabs';
import FormActionButtonList from '../component/FormActionButtonList';
import { getParamFromUrl } from '../helper/UrlHelper';
import LoadingBox from '../component/loading-box';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  ApiRequestResultInterface,
  FormKeyMode,
  RecordKeyMode,
  waitForAction,
} from '../type/actor-setup';
import HeaderTitleController from '../component/header-title/header-title.controller';
import ShowTitle from '../component/ShowTitle';
import { getTranslatedName } from '../helper/MetaHelper';
import { GET_ONE } from 'react-admin';
import type { GeneralMetaData } from '../helper/Types';
import NotFound from '../component/NotFound';
import {
  apiRequestFailureResultHandler,
  apiRequestResultHandler,
} from '../helper/crud-api.helper';
import { removeOnDispatches } from '../helper/general-function-helper';
import { getRootResource } from '../helper/ActorHelper';
import { prepareOverrideParams } from '../component/form/form.helper';
import { getMetaDataFromActorOrNetwork } from '../helper/meta-helper';

const useStyles = makeStyles(() => ({
  createEditRecordPageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 1,
  },

  viewRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  viewMain: {
    height: '100%',
    margin: 0,
  },

  viewCard: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxShadow: 'none',
  },
  loadingOrErrorBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    height: '100vh',
  },
  iconButton: {
    padding: 5,
    margin: '0 6px',
  },
}));

const CreateEditRecordPage = (props): ReactElement => {
  const { metaData, record, isSingleRecord, match, ...rest } = props;

  const [firstLoading, setFirstLoading] = useState(
    metaData == null && record == null,
  );
  const [processing, setProcessing] = useState(false);
  const currentRecordRef = useRef<Record<string, unknown> | null>(record ?? null);

  const currentMetaDataRef = useRef<GeneralMetaData | null>(metaData ?? null);
  const isCreateModeRef = useRef(false);
  /**
   * There is a possible to get `loading` & `record` actions in different times
   * To get latest `record` & prevent to perform extra re-renders, we use the following `ref`
   */
  const updateRecordPendingRef = useRef(true);

  const translate = useTranslate();
  const history = useHistory();
  const locale = useLocale();
  const classes = useStyles();

  const { current: currentResource } = actorGetActionValue('resources')!;
  const urlInfo = actorGetActionValue('urlInfo');

  useEffect(() => {
    const rootResource = getRootResource();
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    let listenerId = actorOnDispatch('loading', loadingRecord => {
      /**
       * When a `refreshData` is running, we set this `ref` to `true`
       * So after getting the updated `record` we have to change the state if `processing`
       */
      if (updateRecordPendingRef.current) return;

      setProcessing(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          loadingRecord[currentResource.value] ||
          false,
      );
    });

    onDispatches.push({
      actionName: 'loading',
      listenerId,
    });

    listenerId = actorOnDispatch(
      'record',
      async records => {
        if (!rootResource) return;

        await waitForAction('metaData', metaData => {
          currentMetaDataRef.current =
            (metaData[rootResource] as GeneralMetaData | undefined) ?? null;

          return currentMetaDataRef.current != null;
        });

        await waitForAction(
          'showSettingsLoading',
          showSettingsLoading => showSettingsLoading === false,
        );
        const targetRecord =
          records[rootResource]?.[FormKeyMode.ROOT]?.[RecordKeyMode.FULL];

        if (isCreateModeRef.current) {
          disableLoading();
          updateRecordPendingRef.current = false;
        }

        if (isEmptyObject(targetRecord)) return;
        currentRecordRef.current = targetRecord ?? null;

        const parentRout = targetRecord?.__parentInfo?.['route'];

        if (typeof parentRout === 'string') {
          const [, module, moduleTable, recordId] = parentRout.split('/');
          const resource = `${module}/${moduleTable}`;

          await getMetaDataFromActorOrNetwork(resource);

          const parentRecord = actorGetActionValue('record');

          if (
            isEmptyObject(
              parentRecord?.[resource]?.[FormKeyMode.ROOT]?.[RecordKeyMode.FULL],
            )
          ) {
            actorDispatch(
              'crudAction',
              {
                type: GET_ONE,
                resource,
                recordId,
                entity: 'parentRecord',
                onSuccess: (record): void => {
                  const parentRecord = record?.GET_ONE?.parentRecord?.data?.data;

                  actorSetActionValue('record', parentRecord, {
                    path: `${resource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
                  });

                  // We got the updated `record`, so we have to change the `processing` state
                  if (updateRecordPendingRef.current) {
                    disableLoading();
                    updateRecordPendingRef.current = false;
                  }
                },
                onFailure: error => {
                  console.log('error: ', error);
                },
              },
              {
                disableDebounce: true,
                replaceAll: true,
                callerScopeName: 'customRefreshCallback',
              },
            );
          } else {
            if (updateRecordPendingRef.current) {
              disableLoading();
              updateRecordPendingRef.current = false;
            }
          }
        } else {
          // We got the updated `record`, so we have to change the `processing` state
          if (updateRecordPendingRef.current) {
            disableLoading();
            updateRecordPendingRef.current = false;
          }
        }
      },
      { preserve: false },
    );

    onDispatches.push({
      actionName: 'record',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
      resetComponentData();
    };
  }, []);

  const resetComponentData = useCallback(() => {
    actorSetActionValue('gridData', {}, { replaceAll: true });

    currentRecordRef.current = null;
    currentMetaDataRef.current = null;
    updateRecordPendingRef.current = false;
    isCreateModeRef.current = false;

    setFirstLoading(true);
    setProcessing(false);
  }, []);

  const matchParams = useMemo(() => {
    if (match == null) return null;

    if (!isSingleRecord) {
      resetComponentData();
      isCreateModeRef.current = isEmpty(match.params?.id);
    } else {
      isCreateModeRef.current = isEmptyObject(record);
    }

    return match.params;
  }, [match, isSingleRecord]);

  const finalResource = useMemo(() => {
    if (!isSingleRecord) return currentResource;

    const { moduleName, moduleTableName } = match.params;
    return {
      type: FormKeyMode.ROOT,
      value: `${moduleName}/${moduleTableName}`,
    };
  }, [isSingleRecord, match]);

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    if (isCreateModeRef.current) {
      updateRecordPendingRef.current = true;

      actorDispatch(
        'record',
        {
          [currentResource.value]: {
            [FormKeyMode.ROOT]: {
              [RecordKeyMode.FULL]: prepareOverrideParams(),
            },
          },
        },
        {
          replaceAll: true,
          callerScopeName:
            'CreateEditRecordPage => useEffect(..., [matchParams, isSingleRecord])',
        },
      );
    } else {
      actorSetActionValue('gridData', {}, { replaceAll: true });

      !isSingleRecord && customRefresh();

      const listenerId = actorOnDispatch(
        'refreshView',
        viewName => {
          if (viewName === 'record' || viewName === 'all') {
            customRefresh();
          }
        },
        {
          preserve: false,
        },
      );

      onDispatches.push({
        actionName: 'refreshView',
        listenerId,
      });
    }

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, [matchParams, isSingleRecord]);

  const disableLoading = useCallback(() => {
    setFirstLoading(false);
    setProcessing(false);
  }, []);

  /**
   * @function customRefresh
   * @returns {void} void
   */
  const customRefresh = useCallback((): void => {
    updateRecordPendingRef.current = true;
    setProcessing(true);

    actorDispatch(
      'crudAction',
      {
        type: GET_ONE,
        entity: 'createEditRecordPage',
        resource: currentResource.value,
        recordId: matchParams.id,
        onSuccess: response => {
          const _response = response[GET_ONE]['createEditRecordPage'].data;

          const { recordWithoutRelationData = {}, relationRecord = {} } =
            separateRecordAndRelationRecord(
              _response.data ?? {},
              currentMetaDataRef.current,
              prepareOverrideParams(),
            );

          actorSetActionValue('additionalData', _response.additionalData, {
            path: currentResource.value,
          });

          apiRequestResultHandler(response);

          actorDispatch(
            'record',
            {
              [currentResource.value]: {
                [FormKeyMode.ROOT]: {
                  [RecordKeyMode.FULL]: {
                    ...(_response.data ?? {}),
                    ...prepareOverrideParams(),
                  },
                  [RecordKeyMode.FORM]: recordWithoutRelationData,
                  [RecordKeyMode.PARENT_RECORD]: relationRecord,
                },
              },
            },
            {
              replaceAll: true,
              callerScopeName: 'CreateEditRecordPage => customRefresh',
            },
          );
        },
        onFailure: (error: ApiRequestResultInterface): void => {
          apiRequestFailureResultHandler(error, GET_ONE, 'createEditRecordPage');
          disableLoading();
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'CreateEditRecordPage => customRefresh',
      },
    );
  }, [matchParams]);

  if (firstLoading) {
    return <LoadingBox />;
  }

  const isReport = currentResource.value.indexOf('report') === 0;
  const redirect = getParamFromUrl(location.search, 'redirect') || 'show';
  const hashUrl = urlInfo?.location?.hash ?? window.location.href;

  if (
    Array.isArray(currentMetaDataRef.current?.processes) &&
    currentMetaDataRef.current!.processes.length > 0 &&
    hashUrl.includes('/create') && // in create mode
    (!hashUrl.includes('__processuniqueid') ||
      !hashUrl.includes('positionid') ||
      !hashUrl.includes('stateid'))
  ) {
    history.replace(`/${currentResource.value}`);
  }

  if (!isCreateModeRef.current && isEmptyObject(currentRecordRef.current)) {
    return (
      <div className={classes.loadingOrErrorBoxContainer}>
        <NotFound title={translate('ra.navigation.no_results')} />
      </div>
    );
  }

  // going to a wrong resource
  if (isEmptyObject(currentMetaDataRef.current)) {
    return (
      <div className={classes.loadingOrErrorBoxContainer}>
        <NotFound title={translate('meta.resourceIsNotDefined')} />
      </div>
    );
  }

  return (
    <>
      <div className={classes.createEditRecordPageContainer}>
        <HeaderTitleController
          title={
            <ShowTitle
              metaData={currentMetaDataRef.current!}
              record={currentRecordRef.current!}
            />
          }
          record={currentRecordRef.current!}
          defaultTitle={getTranslatedName(currentMetaDataRef.current, locale)}
        />
        <FormController
          {...rest}
          formName="createEditRecordPage"
          classes={{
            root: classes.viewRoot,
            main: classes.viewMain,
            card: classes.viewCard,
          }}
          actions={null}
          hasList={true}
          hasCreate={!isReport}
          hasEdit={!isReport}
          hasShow={!isReport}
          loading={processing}
          rootResource={finalResource}
        >
          <CustomForm
            Toolbar={
              <FormActionButtonList
                submitOnEnter={false}
                redirect={redirect}
                locale={locale}
                isCreateMode={isCreateModeRef.current}
                isDefaultMode={false}
                formName="createEditRecordPage"
                {...rest}
              />
            }
          >
            <FormWithTabs />
          </CustomForm>
        </FormController>
      </div>
    </>
  );
};

export default CreateEditRecordPage;
