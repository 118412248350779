import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Button, IconButton, useTheme } from '@material-ui/core';
import clsx from 'classnames';
import { MailDetailHeaderViewInterface } from './mail-detail-header.type';
import { useStyles } from './mail-detail-header.style';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Tooltip from '@material-ui/core/Tooltip';
import MarkUnReadIcon from '@material-ui/icons/MarkunreadOutlined';
import MarkReadIcon from '@material-ui/icons/DraftsOutlined';
import Box from '@material-ui/core/Box';
import CycleIcon from '../../../../icon/CycleIcon';
import ReferencesIcon from '../../../../icon/ReferencesIcon';
import ArchiveIcon from '../../../../icon/ArchiveIcon';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
const MailDetailHeaderView = (
  props: MailDetailHeaderViewInterface,
): ReactElement => {
  const {
    selectedMail,
    onMarkUnReadClick,
    onArchiveClick,
    onUnArchiveClick,
    onGetReferencesDataClick,
    onGetDiagramDataClick,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();

  if (!selectedMail) return <Box />;

  return (
    <Box width={1} data-test="mail-detail-header-container">
      <Box
        display="flex"
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent="space-between"
        flexDirection={{ xs: 'column', md: 'row' }}
        width={1}
      >
        <Tooltip title={translate('general.back')}>
          <Box
            my={0.5}
            component="span"
            color="text.disabled"
            data-test="mail-detail-header-back-icon"
          >
            {theme.direction === 'ltr' ? (
              <KeyboardBackspaceIcon
                onClick={() => window.history.back()}
                className={classes.pointer}
              />
            ) : (
              <KeyboardBackspaceIcon
                onClick={() => window.history.back()}
                className={clsx(classes.pointer, classes.rotate180)}
              />
            )}
          </Box>
        </Tooltip>

        <Box ml={{ xs: 0, md: 'auto' }}>
          <Tooltip title={translate('mail.references')}>
            <Button
              onClick={() => onGetReferencesDataClick(1)}
              variant="text"
              startIcon={<ReferencesIcon />}
              data-test="mail-detail-header-references-icon"
            >
              {translate('mail.references')}
            </Button>
          </Tooltip>

          <Tooltip title={translate('mail.cycle')}>
            <Button
              onClick={onGetDiagramDataClick}
              variant="text"
              startIcon={<CycleIcon />}
              data-test="mail-detail-header-cycle-icon"
            >
              {translate('mail.cycle')}
            </Button>
          </Tooltip>
          {selectedMail.isarchived ? (
            <Tooltip title={translate('mail.toUnArchive')}>
              <Box component="span" color="text.disabled">
                <IconButton
                  onClick={onUnArchiveClick}
                  data-test="mail-detail-header-archive-icon"
                >
                  <UnarchiveIcon className={classes.pointer} />
                </IconButton>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title={translate('mail.toArchive')}>
              <Box component="span" color="text.disabled">
                <IconButton
                  onClick={onArchiveClick}
                  data-test="mail-detail-header-archive-icon"
                >
                  <ArchiveIcon className={classes.pointer} />
                </IconButton>
              </Box>
            </Tooltip>
          )}

          {!selectedMail.isunread ? (
            <Tooltip title={translate('mail.markAsUnRead')}>
              <Box component="span" color="text.disabled">
                <IconButton
                  onClick={() => onMarkUnReadClick(true)}
                  data-test="mail-detail-header-markUnRead-icon"
                >
                  <MarkUnReadIcon className={classes.pointer} />
                </IconButton>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title={translate('mail.markAsRead')}>
              <Box component="span" color="text.disabled">
                <IconButton
                  onClick={() => onMarkUnReadClick(false)}
                  data-test="mail-detail-header-markRead-icon"
                >
                  <MarkReadIcon className={classes.pointer} />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MailDetailHeaderView;
