import { ReactElement } from 'react';

import { CustomForm, FormController } from '../../../form';
import DynamicList from '../../../../container/DynamicList';
import MultipleReportListContainer from '../../../../container/list/MultipleReportListContainer';
import WMSForm from '../wms-form';
import { useStyles } from './wms-report.style';
import { WMSReportViewProps } from './wms-report.type';
import { actorGetActionValue } from '../../../../type/actor-setup';

const PER_PAGE = 50;

const WMSReportView = (props: WMSReportViewProps): ReactElement | null => {
  const {
    resource,
    reportId,
    reportType,
    showGrid,
    reportMetaData,
    filterValues,
    onSubmit,
    activeTabIndex,
    formTabIndex,
    prevTabIndex,
  } = props;

  const classes = useStyles();

  const urlInfo = actorGetActionValue('urlInfo')!;
  return (
    <div className={classes.container}>
      <FormController formName="wms">
        <CustomForm>
          <WMSForm
            onSubmit={onSubmit}
            activeTabIndex={activeTabIndex}
            formTabIndex={formTabIndex}
            prevTabIndex={prevTabIndex}
          />
        </CustomForm>
      </FormController>

      {showGrid[reportId] &&
        (reportType === 'MultiResult' || reportType === 'ParentChild' ? (
          <MultipleReportListContainer
            resource={resource}
            defaultTabIndex={0}
            showDevExtremeTopFilter={false}
            showDevExtremeGrouping={false}
            metaData={reportMetaData}
            filter={filterValues}
            relationMode
            listFilterComponent={null}
            slideComponent={null}
            isSlideOpen={false}
            quickEditRowCallback={null}
            actionEditColumnCount={0}
            enableSelection={false}
            listActionComponent={null}
            perPage={PER_PAGE}
            isWMS={true}
            isFilterEnable={false}
            isGroupingEnable={false}
            basePath={`/${resource}`}
            location={urlInfo.location}
          />
        ) : (
          <DynamicList
            classes={{
              container: classes.dynamicList,
            }}
            defaultTabIndex={0}
            resource={resource}
            metaData={reportMetaData}
            hasList={true}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            enableSelection={false}
            listActionComponent={undefined}
            listFilterComponent={null}
            filter={filterValues as Record<string, unknown>}
            perPage={PER_PAGE}
            isWMS={true}
            isFilterEnable={false}
            isGroupingEnable={false}
            basePath={`/${resource}`}
            location={urlInfo.location}
          />
        ))}
    </div>
  );
};

export default WMSReportView;
