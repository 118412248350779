/**
 * check file is image or not
 * @function isImage
 * @param { string | null } path
 * @returns { boolean } boolean
 */
export function isImage(path: string | null): boolean {
  return path?.match(/\.(jpeg|jpg|gif|png|svg|JPG|PNG|JPEG|GIF|SVG)$/) != null;
}

/**
 * get file's type
 * @function getFileExtension
 * @param { string | null} fileName
 * @returns { string } string
 */
export const getFileExtension = (fileName: string | null): string | null => {
  return fileName?.split('.').at(-1) ?? null;
};
