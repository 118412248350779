import { ChangeEvent, useCallback, useRef, useState } from 'react';

import ChatAvatarInputView from './chat-avatar-input.view';

import type { ReactElement } from 'react';
import type { ChatAvatarInputPropsInterface } from './chat-avatar-input.type';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../core/configProvider';
import { fileUploadResource } from '../chat-section.helper';
import { actorDispatch } from '../../../type/actor-setup';

const ChatAvatarInputController = (
  props: ChatAvatarInputPropsInterface,
): ReactElement => {
  const { successFileCallback, personImage } = props;

  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);
  const cacheKey = Math.random();

  const [avatarURL, setAvatarURL] = useState<string>(
    personImage ? `${apiUrl}/oauth2/${sessionId}/${apiVersion}${personImage}` : '',
  );

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  /**
   * @function handleClick
   * @description handle click event on avatar
   * @returns {void} void
   */
  const handleClick = useCallback((): void => {
    // By using `forwardRef` we sure that it's exists
    hiddenFileInput.current!.click();
  }, []);

  /**
   * @function onSelectFile
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const onSelectFile = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    const fileInfo = event.target.files?.[0];
    if (fileInfo == null) {
      console.warn('Invalid file structure');
      return;
    }

    setAvatarURL(URL.createObjectURL(fileInfo));
    actorDispatch('uploadStreamFile', {
      successCallback: successFileCallback,
      param: {
        resource: fileUploadResource,
        file: fileInfo,
      },
    });
  }, []);

  return (
    <ChatAvatarInputView
      onSelectFile={onSelectFile}
      handleClick={handleClick}
      avatarURL={avatarURL}
      ref={hiddenFileInput}
    />
  );
};

export default ChatAvatarInputController;
