import { useCallback, useEffect, useState, type FC } from 'react';
import { GET_LIST } from 'react-admin';

import ManageInfoDialogView from './message-info-dialog.view';
import { actorDispatch } from '../../../../type/actor-setup';
import {
  defaultRequestStatus,
  getPageStatus,
  getUsersWhoSeenMessageReport,
} from './message-info-dialog.helper';

import type {
  ManageInfoDialogControllerProps,
  RequestStatusInterface,
  UsersWhoSeenMessageReportInterface,
} from './message-info-dialog.type';

const ManageInfoDialogController: FC<ManageInfoDialogControllerProps> = props => {
  const { closeDialogHandler, dialogData } = props;

  const [requestStatus, setRequestStatus] =
    useState<RequestStatusInterface>(defaultRequestStatus);
  const [usersWhoSeenMessage, setUsersWhoSeenMessage] = useState<
    UsersWhoSeenMessageReportInterface[]
  >([]);

  /**
   * set data to state and handle page status
   * @function getReportSuccessCallback
   * @param {{data: UsersWhoSeenMessageReportInterface[]}} response
   * @returns {void} void
   */
  const getReportSuccessCallback = useCallback(
    (response: { data: UsersWhoSeenMessageReportInterface[] }): void => {
      if (Array.isArray(response.data)) {
        setUsersWhoSeenMessage(response.data);
      }

      setRequestStatus({
        isLoading: false,
        isErrorAccrued: false,
      });
    },
    [],
  );

  /**
   * handle page status
   * @function getReportFailureCallback
   * @returns {void} void
   */
  const getReportFailureCallback = useCallback(() => {
    setRequestStatus({
      isLoading: false,
      isErrorAccrued: true,
    });
  }, []);

  useEffect(() => {
    actorDispatch('crudAction', {
      resource: getUsersWhoSeenMessageReport,
      requestParameters: {
        filter: [['chatid', 'equal', dialogData?.chatID]],
      },
      type: GET_LIST,
      entity: 'chat',
      onSuccess: getReportSuccessCallback,
      onFailure: getReportFailureCallback,
    });
  }, []);

  const pageStatus = getPageStatus(requestStatus, usersWhoSeenMessage);

  return (
    <ManageInfoDialogView
      closeDialogHandler={closeDialogHandler}
      usersWhoSeenMessage={usersWhoSeenMessage}
      pageStatus={pageStatus}
    />
  );
};

export default ManageInfoDialogController;
