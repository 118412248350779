import { FC, useEffect, memo, createRef } from 'react';
import { inputTypes } from '../../helper/InputHelper';

import { HiddenFieldsHandlerInterface } from './hidden-fields-handler.type';

let internalClassName = '';
const HiddenFieldsHandlerController: FC<HiddenFieldsHandlerInterface> = memo(
  props => {
    // @ts-ignore
    const { visibleClass, fieldType, children } = props;
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
      if (ref.current?.parentElement == null) return;

      const { classList, tagName, parentElement, childElementCount } =
        ref.current.parentElement;
      if (tagName === 'TD' || childElementCount === 1) {
        if (visibleClass) {
          internalClassName = visibleClass;
          ref.current.parentElement.className =
            (`${visibleClass} ` ?? '') + classList;
        } else {
          ref.current.parentElement.className =
            ref.current.parentElement.className.replaceAll(internalClassName, '');
        }
      }

      if (parentElement?.children) {
        const { children, classList, tagName } = parentElement;
        if (
          tagName === 'TR' &&
          [...children]?.filter(item => item.className.includes(visibleClass))
            .length === children.length &&
          !parentElement.className.includes(internalClassName)
        ) {
          parentElement.className = (`${visibleClass} ` ?? '') + classList;
        } else {
          parentElement.className = parentElement.className.replaceAll(
            internalClassName,
            '',
          );
        }
      }
    }, [ref.current, visibleClass]);

    return (
      <div
        ref={ref}
        style={{
          height: '100%',
          textAlign: fieldType === inputTypes.BUTTON_FIELD ? 'center' : 'start',
          width: '100%',
        }}
      >
        {children}
      </div>
    );
  },
);

export default HiddenFieldsHandlerController;
