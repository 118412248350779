import Settings from '@material-ui/icons/Settings';
import type { Translate } from '../../helper/Types';

import { RoutesConfigInterface } from '../app-drawer/navigation-container';
import { API_URL, getValue } from '../../core/configProvider';

/**
 * sidebar config setting by item dynamic css
 * @function getCssSidebarRoutesConfig
 * @param {Translate} translate
 * @returns {void} void
 */
const getCssSidebarRoutesConfig = (
  translate: Translate,
): RoutesConfigInterface[] => {
  return [
    {
      id: 'css',
      title: `${translate('css.drawer')}`,
      type: 'item',
      dataTest: 'static-css',
      url: '/customcss',
      icon: <Settings />,
    },
  ];
};
export default getCssSidebarRoutesConfig;

/**
 * get custom css file name base on apiUrl
 * @function getCssFileName
 * return {string} string
 */
export const getCssFileName = (): string => {
  const apiUrl = getValue(API_URL);

  //just keep number and letters from api url
  const regExp = /[^A-za-z0-9]/g;
  return apiUrl.toLowerCase().replace(regExp, '');
};
