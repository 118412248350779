import { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import lodashFilter from 'lodash/filter';

import {
  getGroupUsers,
  prepareSelectedUsersBeforeCreateGroup,
  updateGroup,
} from '../../group-chats.helper';
import AdminsDialogView from './admins-dialog.view';
import { replaceArabicCharacters } from '../../../../helper/TextHelper';
import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../../type/actor-setup';
import { ADMINS_MODE } from '../add-members-dialog/add-members-dialog.helper';
import { isEmpty } from '../../../../helper/data-helper';

import type { ReactElement, MouseEvent } from 'react';
import type { AdminsDialogControllerPropsInterface } from './admins-dialog.type';
import type { ChatUsersInterface } from '../add-members-dialog/add-members-dialog.type';

const AdminsDialogController = (
  props: AdminsDialogControllerPropsInterface,
): ReactElement => {
  const { closeDialogHandler, dialogData } = props;
  const { selectedUser } = dialogData;

  const translate = useTranslate();
  const [, render] = useReducer(p => !p, false);

  const [groupAdmins, setGroupAdmins] = useState<ChatUsersInterface[]>([]);
  const [isFound, setIsFound] = useState<boolean>(true);
  const allGroupAdminsRef = useRef<ChatUsersInterface[]>([]);
  const groupUsersRef = useRef<ChatUsersInterface[]>([]);

  useEffect(() => {
    const id = actorOnDispatch('refreshView', resource => {
      if (resource === 'refreshChatInfoDialog' && selectedUser.groupuid) {
        getGroupUsers(String(selectedUser.groupuid), handleSuccessGetGroupUsers);
      }
    });

    if (selectedUser.groupuid) {
      getGroupUsers(String(selectedUser.groupuid), handleSuccessGetGroupUsers);
    }

    return () => {
      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: id,
      });
    };
  }, []);

  /**
   * @function handleSearch
   * @param {React.ChangeEvent<HTMLInputElement>}event
   * @returns {void} void
   */
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (!isEmpty(value)) {
      const result = lodashFilter(allGroupAdminsRef.current, user => {
        if (
          replaceArabicCharacters(user.personname ?? user.fullname).includes(
            replaceArabicCharacters(value),
          )
        ) {
          return user;
        }
      });

      if (result.length === 0) {
        setGroupAdmins([]);
        setIsFound(false);
        return;
      }

      setIsFound(true);
      setGroupAdmins(result);
      return;
    }

    setIsFound(true);
    //if value is empty than show all users
    setGroupAdmins(allGroupAdminsRef.current);
  };

  /**
   * @function handleSuccessGetGroupUsers
   * @param {ChatUsersInterface[]} data
   * @returns {void} void
   */
  const handleSuccessGetGroupUsers = (data: ChatUsersInterface[]): void => {
    groupUsersRef.current = data;
    const groupAdmins = data.filter(user => user.isadmin);
    allGroupAdminsRef.current = groupAdmins;
    setGroupAdmins(groupAdmins);
  };

  /**
   * @function openAddMembersDialog
   * @returns {void} void
   * @description open add members dialog
   */
  const openAddMembersDialog = (): void => {
    actorDispatch('quickDialog', {
      addMembersDialogIsOpen: true,
      chatCreateGroupDialogIsOpen: false,
      chatInfoDialogIsOpen: false,
      manageGroupDialogIsOpen: false,
      adminsDialogIsOpen: false,
      data: {
        GroupUID: selectedUser.groupuid,
        GroupProfileImage: selectedUser.personimage,
        GroupName: selectedUser.personname,
        IsUseInChat: 1,
        groupUsers: groupUsersRef.current,
        mode: ADMINS_MODE,
        IsChannel: Number(selectedUser.ischannel),
      },
    });
  };

  const handleSuccessRemoveAdmin = (): void => {
    actorDispatch('refreshView', 'refreshChatInfoDialog');
    render();
  };

  /**
   * @function onRemoveAdminConfirmation
   * @param { ChatUsersInterface } targetAdmin
   * @returns { void } void
   */
  const onRemoveAdminConfirmation = (targetAdmin: ChatUsersInterface): void => {
    if (!selectedUser.groupuid) return;

    const adminThatShouldBeRemoved = groupAdmins.find(
      (admin: ChatUsersInterface) =>
        admin.personinfo_id === targetAdmin.personinfo_id,
    );
    if (!adminThatShouldBeRemoved) return;
    const index = groupAdmins.indexOf(adminThatShouldBeRemoved);
    if (index > -1) {
      groupAdmins.splice(index, 1);
    }
    setGroupAdmins(groupAdmins);

    updateGroup(
      {
        GroupUID: selectedUser.groupuid,
        GroupName: selectedUser.personname,
        GroupProfileImage: JSON.stringify(selectedUser.personimage ?? ''),
        IsUseInChat: 1,
        IsChannel: Number(selectedUser.ischannel),
        AdminPersonInfo_IDs: prepareSelectedUsersBeforeCreateGroup(groupAdmins),
        MemberPersonInfoIDs: prepareSelectedUsersBeforeCreateGroup(
          groupUsersRef.current,
        ),
      },
      handleSuccessRemoveAdmin,
    );
    actorDispatch('closeCurrentDialog', true);
  };

  /**
   * @function handleRemoveAdmin
   * @param { ChatUsersInterface } admin
   * @returns { (event: MouseEvent<HTMLElement>) => void } void
   */
  const handleRemoveAdmin =
    (admin: ChatUsersInterface) =>
    (event: MouseEvent<HTMLElement>): void => {
      actorDispatch('quickDialog', {
        confirmationIsOpen: true,
        data: {
          content: translate('ra.message.are_you_sure'),
          onConfirm: () => onRemoveAdminConfirmation(admin),
        },
      });
    };

  return (
    <AdminsDialogView
      openAddMembersDialog={openAddMembersDialog}
      closeDialogHandler={closeDialogHandler}
      handleRemoveAdmin={handleRemoveAdmin}
      handleSearch={handleSearch}
      groupAdmins={groupAdmins}
      isFound={isFound}
    />
  );
};

export default AdminsDialogController;
