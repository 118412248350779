import { ReactElement, useEffect, useRef, useState } from 'react';
import lodashGet from 'lodash/get';
import { CodingInputInterface } from './coding-input.type';
import { CodingInputView } from './coding-input.view';
import { getTreeParentFieldName } from '../../../helper/MetaHelper';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import { getCodingPattern } from '../../../helper/InputHelper';
import { ChangeFormValueParams, FormActions, OnBlurParams } from '../../form';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  RecordKeyMode,
} from '../../../type/actor-setup';

const CodingInput = (props: CodingInputInterface): ReactElement => {
  // --------------------------------------- destruct props ----------------------------------------------
  const {
    metaData,
    field,
    label,
    hint,
    className,
    disabled,
    value,
    inputMessage,
    visibleClass,
    customTestAttribute,
    getRef,
  } = props;

  const { name } = field;

  // --------------------------------------- Actor -----------------------------------------------
  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

  const { current: currentResource } = actorGetActionValue('resources')!;

  const record = actorGetActionValue('record', [
    currentResource.value,
    currentResource.type,
    RecordKeyMode.FULL,
  ]);
  const formData =
    (actorGetActionValue('formData', [
      currentResource.value,
      currentResource.type,
    ]) as FormData | null) ?? {};

  // --------------------------------------- states ----------------------------------------------

  const [codingValues, setCodingValues] = useState([]);
  const [maxLength, setMaxLength] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const prevIdTreeParent = useRef(
    lodashGet(formData, getTreeParentFieldName(metaData)),
  );

  useEffect(() => {
    const newIdTreeParent = lodashGet(formData, getTreeParentFieldName(metaData));

    if (prevIdTreeParent.current != newIdTreeParent) {
      prevIdTreeParent.current = newIdTreeParent;
    }
  }, [formData]);

  // --------------------------------------- to fetch coding default value ----------------------------------------------

  /**
   * Handle get coding data
   * @function getCodingDefaultValueHandler
   * @returns {void}
   */
  const getCodingDefaultValueHandler = (): void => {
    const _recordId = record?.id;
    if (isEmpty(_recordId) && isEmptyObject(prevIdTreeParent.current)) {
      setIsLoading(false);
      return;
    }

    let rowId: string | null = null;
    if (isEmpty(_recordId) && prevIdTreeParent.current) {
      rowId = prevIdTreeParent.current;
    }

    formActionsHandler(FormActions.GetCodingDefaultValue, {
      rowId,
      field,
      record,
    });
  };

  useEffect(() => {
    getCodingDefaultValueHandler();
  }, [prevIdTreeParent.current]);

  useEffect(() => {
    const codingInputDataOnDispatchId = actorOnDispatch(
      'codingInputData',
      codingInputData => {
        setIsLoading(codingInputData.isLoading);
        if (!codingInputData.isLoading) {
          const resMaxLength = getCodingPattern(
            field.codingPattern ?? '',
            codingInputData
              ? codingInputData.data && codingInputData.data['currentlevel']
              : lodashGet(formData, 'currentLevel'),
          );
          resMaxLength && setMaxLength(resMaxLength);
          setCodingValues(lodashGet(codingInputData.data, '__codingvalues'));
        }
      },
      { preserve: false },
    );

    const resetFormOnDispatchId = actorOnDispatch(
      'resetForm',
      () => {
        getCodingDefaultValueHandler();
      },
      { preserve: false },
    );

    return () => {
      actorRemoveAction({
        actionName: 'codingInputData',
        listenerId: codingInputDataOnDispatchId,
      });

      actorRemoveAction({
        actionName: 'resetForm',
        listenerId: resetFormOnDispatchId,
      });
    };
  }, []);

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as ChangeFormValueParams);
  };

  /**
   * Handle Change event
   * @function handleChange
   * @param {ChangeEvent} event
   * @returns void
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: event.target.value,
      isHidden: visibleClass.includes('displayNone'),
    } as ChangeFormValueParams);
  };

  return (
    <CodingInputView
      getRef={getRef}
      field={field}
      isLoading={isLoading}
      label={label}
      hint={hint}
      value={value}
      inputMessage={inputMessage}
      maxLength={maxLength}
      className={className}
      disabled={disabled}
      codingValues={codingValues}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleChange={handleChange}
      visibleClass={visibleClass}
      customTestAttribute={customTestAttribute}
    />
  );
};

export default CodingInput;
