import type { FieldType } from '../../../../../../helper/Types';
import type { FilterFormFieldInterface } from '../../../../../../type/actor-setup';
import type { Locale } from '../../../../../../type/global-types';
import type { FinalFiltersType } from '../../../../../filter-form';

/**
 * merge filters and make it compatible to gadget
 * @function gatherAllFilters
 * @param {FinalFiltersType | null} preDesignedGadgetFilters
 * @param {FinalFiltersType | null} customFilters
 * @returns Record<string, unknown>
 */
const gatherAllFiltersForGadget = (
  preDesignedGadgetFilters?: FinalFiltersType | null,
  customFilters?: FinalFiltersType | null,
): Record<string, unknown> => {
  const filters = {};

  if (Array.isArray(preDesignedGadgetFilters)) {
    preDesignedGadgetFilters.forEach(filter => {
      if (Array.isArray(filter)) {
        filters[filter[0] as string] = filter;
      }
    });
  }

  if (Array.isArray(customFilters)) {
    customFilters.forEach(filter => {
      if (Array.isArray(filter)) {
        filters[filter[0] as string] = filter;
      }
    });
  }

  return filters;
};

/**
 * make report parameters compatible with filter form
 * @function getFilterFieldsFromGadgetValues
 * @param {GadgetReportParameters[]} reportParameters
 * @param {Locale} locale
 * @returns {FilterFormFieldInterface[]}
 */
export const getFilterFieldsFromGadgetValues = (
  reportParameters: FieldType[],
  locale: Locale,
  preDesignedGadgetFilters?: FinalFiltersType | null,
  customFilters?: FinalFiltersType | null,
): FilterFormFieldInterface[] => {
  const filters = gatherAllFiltersForGadget(preDesignedGadgetFilters, customFilters);

  const preparedFields = reportParameters.map(parameter => {
    parameter.defaultOperator =
      parameter.defaultOperator?.toLowerCase() ??
      parameter.dataType.defaultOperator.toLowerCase();

    if (parameter.defaultOperator === 'empty') {
      parameter.defaultOperator = 'equals';
    } else if (parameter.defaultOperator === 'like') {
      parameter.defaultOperator = 'contains';
    }

    return {
      fieldData: {
        ...parameter,
        label: parameter.translatedComment?.[locale] || parameter.comment,
      },
      value: filters[(parameter.key ?? parameter.name) as string] ?? [],
    };
  }) as unknown as FilterFormFieldInterface[];
  return preparedFields;
};
