import {
  type ChatSidebarReducerActionInterface,
  type ChatSidebarStateInterface,
} from '../chat-sidebar.type';
import { chatSidebarStatefulActions } from './chat-sidebar-stateful-actions';

export const chatSidebarInitialState: ChatSidebarStateInterface = {
  loading: true,
  searchValue: '',
  selectedUser: null,
  contactsFoundBySearch: [],
  messagesFoundBySearch: [],
  chatsData: {
    list: [],
    hasMore: false,
  },
};

export const chatSidebarReducer = (
  state: ChatSidebarStateInterface,
  action: ChatSidebarReducerActionInterface,
): ChatSidebarStateInterface => {
  let updatedState: typeof state;

  switch (action.type) {
    case 'setLoading': {
      updatedState = chatSidebarStatefulActions.setLoading(state, action.payload);
      break;
    }

    case 'setSearchValue': {
      updatedState = chatSidebarStatefulActions.setSearchValue(
        state,
        action.payload,
      );
      break;
    }

    case 'setSelectedUser': {
      updatedState = chatSidebarStatefulActions.setSelectedUser(
        state,
        action.payload,
      );
      break;
    }

    case 'setContactsFoundBySearch': {
      updatedState = chatSidebarStatefulActions.setContactsFoundBySearch(
        state,
        action.payload,
      );
      break;
    }

    case 'setMessagesFoundBySearch': {
      updatedState = chatSidebarStatefulActions.setMessagesFoundBySearch(
        state,
        action.payload,
      );
      break;
    }

    case 'setChatsData': {
      updatedState = chatSidebarStatefulActions.setChatsData(state, action.payload);
      break;
    }

    default: {
      updatedState = state;
      console.warn('`chatSidebarReducer` => Invalid action name: %o', {
        state,
        action,
      });
      break;
    }
  }

  return updatedState;
};
