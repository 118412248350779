import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';
import classNames from 'classnames';
import grey from '@material-ui/core/colors/grey';
import {
  IconButton,
  DialogContent,
  Dialog,
  Icon,
  CardMedia,
  withWidth,
  withStyles,
} from '@material-ui/core';
import {
  translate,
  refreshView,
  startUndoable as startUndoableAction,
} from 'react-admin';

import { ProfileAvatar } from '../component/profile-avatar';
import textImage from '../images/text.png';
import { getValue, API_URL } from '../core/configProvider';
import DisplayDate from './DisplayDate';
import NoteCreateContainer from '../container/NoteCreateContainer';
import ConfirmDialogHOC from '../container/ConfirmDialogHOC';
import { isFileTypeImage } from '../helper/FileHelper';
import { crudDeleteWithCallbackAction } from '../redux/crud/action';
import { showNotification } from '../helper/general-function-helper';
import { showImageDialog } from './list';

const CHAR_LIMIT = 120;

const styles = theme => ({
  noteItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: 12,
    transition: 'all 200ms',
    borderBottom: `1px solid ${grey[300]}`,
    '&:hover #noteAction': {
      opacity: 1,
      transition: 'all 200ms',
    },
    '&:hover': {
      backgroundColor: grey[50],
      boxShadow: `inset 6px 0px 0px -1px ${theme.palette.primary.main}`,
      transition: 'all 200ms',
    },
  },

  noteItemInSidebar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    wordBreak: 'break-word',
    '&:hover #noteAction': {
      opacity: 1,
      transition: 'all 200ms',
    },
  },

  noteAction: {
    alignSelf: 'flex-start',
    opacity: 0,
    transition: 'all 200ms',
  },

  noteContent: {
    marginLeft: '0.5em',
    flexGrow: 1,
  },

  noteContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5em',
  },

  author: {
    fontSize: 13,
    fontWeight: 400,
    paddingBottom: '0.3em',
  },

  noteTime: {
    fontSize: 12,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  note: {
    fontSize: 11,
    borderRadius: 2,
    margin: '5px 0',
    whiteSpace: 'pre-line',
  },

  noteInStream: {
    fontSize: 12,
    padding: 6,
    marginBottom: '0.5em',
    backgroundColor: grey[50],
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.23)',
  },

  showMoreButton: {
    marginTop: '0.5em',
    cursor: 'pointer',
    fontSize: 11,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.38)',
  },

  attachmentContainer: {
    display: 'flex',
    marginTop: 10,
  },

  media: {
    width: 25,
    height: 25,
    margin: '0 5px',
    cursor: 'pointer',
    fontSize: 10,
  },

  parentLink: {
    color: '#212121',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

class NoteStreamCard extends PureComponent {
  state = {
    showFullText: false,
    currentNote: null,
    shortNote: null,
    fullNote: null,
    attachment: null,
    isOpen: false,
    dialogOpen: false,
  };

  componentDidMount() {
    this.getDataFromNote(this.props);
  }

  getDataFromNote = props => {
    const { model, forceShowFullText } = props;

    let decoded;
    try {
      decoded = JSON.parse(model.note);
    } catch (error) {
      decoded = { text: model.note };
    }

    // The value of 'showFullText' specifies the full or short view of the message based on 'forceShowFullText'.
    const showFullText = forceShowFullText
      ? forceShowFullText
      : decoded.text.length <= CHAR_LIMIT;
    this.setState({
      showFullText,
      currentNote: model.note,
      fullNote: decoded.text,
      shortNote: showFullText ? null : this.substringForLink(decoded.text),
      attachmentArr: decoded.attachmentArr,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.model.note !== this.state.currentNote) {
      this.getDataFromNote(nextProps);
    }
  }

  toggleShowFullText = () => {
    this.setState({ showFullText: !this.state.showFullText });
  };

  substringForLink(text) {
    let tempText = text.trim();

    if (tempText.length && tempText.length > CHAR_LIMIT) {
      tempText = tempText.substr(0, CHAR_LIMIT) + '...';
    }

    return tempText;
  }

  handelDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  ShowConfirm = () => {
    const { openConfirmDialog, translate, theme } = this.props;

    openConfirmDialog({
      content: translate('confirm.areYouSureYouWantToDeleteThisNote'),
      onConfirm: this.handleClickDelete,
      color: theme.palette.error.main,
    });
  };

  handleClickDelete = () => {
    const { model = {}, resource, crudDelete, refreshView } = this.props;
    try {
      crudDelete(
        resource,
        model.id,
        model,
        () => {
          // give a little timeout for delete request to complete, and then refresh
          refreshView();
          // TODO: maybe need to refresh all relations
          // refreshRelation();
        },
        false,
      );
    } catch (error) {
      showNotification(error.message, 'error');
    }
  };

  render() {
    const {
      classes,
      model = {},
      translate,
      parentResource,
      parentFK,
      detailView,
      relation,
      noteStreamInSidebar,
      id,
      MetaData,
      ...rest
    } = this.props;

    const { showFullText, fullNote, shortNote, attachmentArr, dialogOpen } =
      this.state;

    const apiUrl = getValue(API_URL);

    const creatorName = model.__createuserid_value;

    return (
      <div
        className={
          noteStreamInSidebar ? classes.noteItemInSidebar : classes.noteItem
        }
        data-test-note-id={id}
      >
        <ProfileAvatar userId={model.createuserid} />

        <div className={classes.noteContent}>
          <div className={classes.noteContentHeader}>
            <div className={classes.author}>
              {creatorName} -{' '}
              <Link
                to={linkToRecord('/' + parentResource, model[parentFK], 'show')}
                className={classes.parentLink}
              >
                {model[`__${parentFK}_value`]}
              </Link>
            </div>

            <div className={classes.noteTime}>
              <DisplayDate value={model.createdate} />
            </div>
          </div>

          {fullNote && showFullText && (
            <div
              className={classNames(
                classes.note,
                !detailView && classes.noteInStream,
              )}
            >
              <span id="fullNote">{fullNote}</span>
            </div>
          )}

          {fullNote && !showFullText && (
            <div
              className={classNames(
                classes.note,
                !detailView && classes.noteInStream,
              )}
            >
              <div>{shortNote}</div>
              <div
                className={classes.showMoreButton}
                onClick={this.toggleShowFullText}
              >
                {translate('form.showFull')}
              </div>
            </div>
          )}

          <div className={classes.attachmentContainer}>
            {attachmentArr &&
              attachmentArr.map(attachment => {
                const fileUrl = `${apiUrl}/${attachment.folderPath}/${
                  attachment.uploadedFileName
                    ? attachment.uploadedFileName
                    : attachment.fileName
                }`;

                const isImage = isFileTypeImage(attachment.originalFileName);
                if (isImage) {
                  return (
                    <CardMedia
                      image={fileUrl}
                      key={fileUrl}
                      component="img"
                      alt={attachment.originalFileName}
                      title={attachment.originalFileName}
                      className={classes.media}
                      onClick={() => {
                        showImageDialog({
                          fullUrl: fileUrl,
                          title: attachment.originalFileName,
                        });
                      }}
                      data-test-note-image
                    />
                  );
                }

                return (
                  <a
                    key={fileUrl}
                    href={fileUrl}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CardMedia
                      image={textImage}
                      component="img"
                      alt={attachment.originalFileName}
                      title={attachment.originalFileName}
                      className={classes.media}
                    />
                  </a>
                );
              })}
          </div>
        </div>
        {detailView && (
          <div id="noteAction" className={classes.noteAction}>
            <IconButton
              color="primary"
              onClick={this.handelDialog}
              id="EditButoon"
              data-test-is-enable={MetaData.allowEdit}
              disabled={!MetaData.allowEdit}
            >
              <Icon fontSize="small">edit</Icon>
            </IconButton>
            <IconButton
              color="primary"
              onClick={this.ShowConfirm}
              id="deleteButoon"
              data-test-is-enable={MetaData.allowDelete}
              disabled={!MetaData.allowDelete}
            >
              <Icon fontSize="small">delete</Icon>
            </IconButton>
          </div>
        )}

        <Dialog
          open={dialogOpen}
          maxWidth={this.props.width}
          fullWidth
          onClose={this.closeDialog}
          aria-labelledby="show-image-dialog-title"
        >
          <DialogContent>
            <NoteCreateContainer
              {...rest}
              model={model}
              relation={relation}
              parentId={model[relation.parentFieldName]}
              parentResource={parentResource}
              onSubmitNote={() => {
                this.closeDialog();
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

NoteStreamCard.propTypes = {
  model: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  parentResource: PropTypes.string.isRequired,
  parentFK: PropTypes.string.isRequired,
  relation: PropTypes.object.isRequired,
  noteStreamInSidebar: PropTypes.bool,
};

const mapDispatchToProps = {
  refreshView,
  startUndoable: startUndoableAction,
  crudDelete: crudDeleteWithCallbackAction,
};

export default compose(
  ConfirmDialogHOC,
  translate,
  withWidth(),
  withStyles(styles, { withTheme: true }),
  connect(null, mapDispatchToProps),
)(NoteStreamCard);
