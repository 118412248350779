import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    height: '100%',
    width: '100%',
    padding: '1rem 0',
    background: theme.palette.primary.appPrimaryBackgroundColor,
  },

  contentContainer: {
    padding: '0 1rem 7rem 1rem',
    overflowY: 'auto',
    height: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0 0 4rem 0',
    },
  },

  toolbarContainer: {
    padding: '0 1rem',
  },

  footerContainer: {
    position: 'sticky',
    bottom: 0,
  },
}));
