import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btnCancel: {
    margin: '0 5px',
  },
  btnRoot: {
    margin: '5px 0',
    background: '#1D85D6',
    color: '#fff',
  },
}));
