import {
  actorOnDispatch,
  actorDispatch,
  actorGetActionValue,
} from '../type/actor-setup';
import { GET_REPORT } from '../core/data-Provider.helper';
import { showNotification } from '../helper/general-function-helper';
import dataProvider from '../core/dataProvider';
import { isEmpty } from '../helper/data-helper';

actorOnDispatch(
  'getCalendarData',
  detail => {
    const { reportId, filters, successCallback } = detail;
    const actorResource = actorGetActionValue('resources')!;

    const resource = !isEmpty(actorResource)
      ? actorResource?.current?.value
      : `report/${reportId}`;

    actorDispatch('loading', true, {
      path: `${resource}`,
    });
    dataProvider(GET_REPORT, '', {
      reportId,
      filters,
      pagination: {
        page: 0,
        perPage: 999999,
      },
      sort: {
        field: 'id',
        order: 'DESC',
      },
    })
      .then(response => {
        successCallback?.(response);
        actorDispatch('loading', false, {
          path: `${resource}`,
        });
      })
      .catch(error => {
        showNotification(error, 'error');
        actorDispatch('loading', false, {
          path: `${resource}`,
        });
      });
  },
  {
    preserve: false,
  },
);
