import lodashIsEqual from 'lodash/isEqual';
import lodashFind from 'lodash/find';

import type {
  ChatItemInterface,
  ChatTotalUnseenInterface,
  GetChatsFunction,
  GetContactsParams,
  GetChatContentsParams,
  SignalRMessageType,
  MessageItemInterface,
} from '../chat-section.type';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../type/actor-setup';
import { showNotification } from '../../../helper/general-function-helper';
import { generateErrorMessage } from '../../dynamic-input/multi-file-stream-input/multi-file-stream-input.helper';
import { ChatReportResponseInterface } from '../../../api/chat-api';
import { chatStatelessActions } from './chat-section-stateless-actions';

export const contactsReportId = '6ed80f00-76b9-4365-a004-a601e580f71b';
export const chatsReportId = '5d466dd3-8cf2-4e22-b99b-f8e480f7846f';
export const userContentsReportId = '1f9b97cd-5180-48d6-9a43-8b32d3f92f88';
export const sendMessageReportId = 'aa774db4-8a78-469f-8786-811215482f84';
export const deleteMessageServiceId = 'c0e69d65-5155-4cc1-aa40-0ea58508f5b7';
export const editMessageServiceId = '80e931ef-8fdc-4f38-8402-071a6a665971';
export const searchInMessagesReportId = '59bbbd72-3c94-4296-b530-57273164d8e4';
export const fileUploadResource = 'General/Chat/filestream/fileurl';
export const seenMessageServiceId = 'e6bae5b0-9f41-4445-a4a9-268dd90fe3a1';

export enum ChatStatefulActionName {
  fetchMoreChats = 'FETCH_MORE_CHATS',
  fetchMoreContacts = 'FETCH_MORE_CONTACTS',
  refreshChats = 'REFRESH_CHATS',
  openUploadFileDialog = 'OPEN_UPLOAD_FILE_DIALOG',
  closeUploadFileDialog = 'CLOSE_UPLOAD_FILE_DIALOG',
}

export enum ChatStatelessActionName {
  sendContent = 'SEND_CONTENT',
  sendMessage = 'SEND_MESSAGE',
  sendFile = 'SEND_FILE',
  refreshContacts = 'REFRESH_CONTACTS',
  fetchMoreMessages = 'FETCH_MORE_MESSAGES',
  selectUser = 'SELECT_USER',
  moveLast = 'MOVE_LAST',
  moveToRepliedMessage = 'MOVE_TO_REPLIED_MESSAGE',
  deleteMessage = 'DELETE_MESSAGE',
  fetchSpecificMessages = 'FETCH_SPECIFIC_MESSAGES',
}

/**
 * set messagesData reaches end
 * by setting hasMore: false
 * @function setReachEndOfMessages
 * @returns { void }
 */
// export const setReachEndOfMessages = (): void => {
//   const currentMessagesData = actorGetActionValue('messagesData');
//   actorDispatch('messagesData', { ...currentMessagesData, hasMore: false });
// };

/**
 * to determine how to insert contents
 * on messagesList view
 * @function handleInsertContentOnView
 * @param { MessageItemInterface[] }newData
 * @param { boolean } isUp
 * @returns { void }
 */
// export const handleInsertContentOnView = (
//   newMessages: MessageItemInterface[],
//   isUp: boolean,
// ): void => {
//   const currentMessagesData = actorGetActionValue('messagesData');
//   if (currentMessagesData) {
//     !isUp
//       ? actorDispatch('messagesData', {
//           ...currentMessagesData,
//           hasMore: true,
//           data: [...newData, ...currentMessagesData?.data],
//         })
//       : actorDispatch('messagesData', {
//           ...currentMessagesData,
//           hasMore: true,
//           data: [...currentMessagesData?.data, ...newData],
//         });
//   }
// };

/**
 * to create new chat
 * @function handleCreateNewChat
 * @param { ChatItemInterface } newChat
 * @returns { void }
 */
export const handleCreateNewChat = (newChat: ChatItemInterface): void => {
  const currentChatList = actorGetActionValue('chatList')!;

  // Add `newChat` to the current chats and update them by a `dispatch`
  currentChatList.data[newChat.personinfo_id] = {
    info: newChat,
    messagesDetail: {
      data: [],
      hasMore: false,
    },
  };

  actorDispatch('chatList', currentChatList, {
    replaceAll: true,
    callerScopeName: 'handleCreateNewChat',
  });
};

/**
 * to update existed chat
 * @function handleUpdateExistedChat
 * @param { ChatItemInterface } updatedChat
 * @returns { void }
 */
export const handleUpdateExistedChat = (updatedChat: ChatItemInterface): void => {
  const currentChatList = actorGetActionValue('chatList')!;

  currentChatList.data[updatedChat.personinfo_id] = {
    info: updatedChat,
    messagesDetail: currentChatList.data[updatedChat.personinfo_id].messagesDetail,
  };

  actorDispatch('chatList', currentChatList, {
    replaceAll: true,
    callerScopeName: 'handleUpdateExistedChat',
  });
};

/**
 * handle update unseen messages on sidebar
 * @function handleUpdateUnseenMessages
 * @param { ChatTotalUnseenInterface } newMessage
 * @returns { void }
 */
export const handleUpdateUnseenMessages = (
  newMessage: ChatTotalUnseenInterface,
): void => {
  const currentSelectedUser = actorGetActionValue('selectedChat')!.info;
  const currentChatList = actorGetActionValue('chatList')!;

  /**
   * we don't want to update unseen messages of active chat on sidebar
   */
  if (
    !lodashIsEqual(currentSelectedUser.personinfo_id, newMessage.otherpersoninfo_id)
  ) {
    currentChatList.data[newMessage.otherpersoninfo_id].info.sumnotseen =
      newMessage.sumnotseen;

    /**
     * but we should  update unseen messages of active chat on sidebar if it has more than 0 unseen messages
     */
  } else {
    if (currentSelectedUser.sumnotseen > 0) {
      currentChatList.data[newMessage.otherpersoninfo_id].info.sumnotseen =
        newMessage.sumnotseen;

      currentSelectedUser.sumnotseen = newMessage.sumnotseen;
      actorDispatch('selectedChat', currentSelectedUser);
    }
  }

  actorDispatch('chatList', currentChatList, {
    callerScopeName: 'handleUpdateUnseenMessages',
  });
};

/**
 * @function handleAfterSuccessSendMessage
 * @param { boolean } shouldNotScrollDown
 * @returns { void }
 */
export const handleAfterSuccessSendMessage = (
  shouldNotScrollDown: boolean,
): void => {
  !shouldNotScrollDown && actorDispatch('isNewContentSent', true);

  actorDispatch('uploadedFile', null, {
    replaceAll: true,
  });
};

/**
 * to insert new message
 * in messageData
 * @function handleCreateNewMessage
 * @param { MessageItemInterface } newChat
 * @returns { void }
 */
export const handleCreateNewMessage = (newMessage: MessageItemInterface): void => {
  const currentChatList = actorGetActionValue('chatList')!;

  currentChatList.data[newMessage.frompersoninfo_id].messagesDetail!.data.push(
    newMessage,
  );
  actorDispatch('chatList', currentChatList, {
    callerScopeName: 'handleCreateNewMessage',
  });
};

/**
 * to update messagesData when
 * we edit a specific message
 * @function handleEditMessage
 * @param { MessageItemInterface } updatedMessage
 * @returns { void }
 */
export const handleEditMessage = (updatedMessage: MessageItemInterface): void => {
  const currentChatList = actorGetActionValue('chatList')!;
  const messageList =
    currentChatList.data[updatedMessage.frompersoninfo_id].messagesDetail!.data;

  const targetMessageIndex = messageList.findIndex(
    message => message.chat_id === updatedMessage.chat_id,
  );
  if (targetMessageIndex > -1) {
    messageList[targetMessageIndex] = updatedMessage;
  }

  actorDispatch('chatList', currentChatList, {
    callerScopeName: 'handleEditMessage',
  });
};

/**
 * to update messagesData when
 * we delete a specific message
 * @function handleDeleteMessage
 * @param { MessageItemInterface } message
 * @returns { void }
 */
export const handleDeleteMessage = (message: MessageItemInterface): void => {
  const currentChat = actorGetActionValue('selectedChat')!;
  const messageList = currentChat.messagesDetail!.data;

  const targetMessageIndex = messageList.findIndex(
    _message => _message.chat_id === message.chat_id,
  );
  if (targetMessageIndex > -1) {
    messageList.splice(targetMessageIndex, 1);
  }

  actorDispatch('selectedChat', currentChat);
};

/**
 * @function handleUpdateSignalRChat
 * @param { MessageItemInterface } signalRMessage
 * @returns { void }
 */
export const handleUpdateSignalRChat = (
  signalRMessage: MessageItemInterface,
): void => {
  const currentChatList = actorGetActionValue('chatList')!;
  const newChat: ChatItemInterface = {
    chatdate: signalRMessage.chatdate,
    chattext: signalRMessage.chattext,
    personimage: signalRMessage.personimage,
    personinfo_id: signalRMessage.frompersoninfo_id,
    personname: signalRMessage.personname,
    sumnotseen: 1,
    ischannel: false,
    isgroupadmin: 0,
    isowner: 0,
    mentionchatid: 0,
    frompersoninfoname: '',
  };

  const existedChat = lodashFind(currentChatList.data, [
    'personinfo_id',
    newChat.personinfo_id,
  ]);
  if (existedChat && !isEmptyObject(existedChat)) {
    handleUpdateExistedChat(newChat);
  } else {
    handleCreateNewChat(newChat);
  }
};

/**
 * @function handleInsertNewSignalRMessage
 * @param { MessageItemInterface } signalRMessage
 * @returns { void }
 */
export const handleInsertNewSignalRMessage = (
  signalRMessage: MessageItemInterface,
): void => {
  const currentSelectedUser = actorGetActionValue('selectedChat')!.info;

  if (currentSelectedUser) {
    if (
      lodashIsEqual(
        currentSelectedUser.personinfo_id,
        signalRMessage.frompersoninfo_id,
      )
    ) {
      // insert new message to this active chat
      handleCreateNewMessage(signalRMessage);
      // seen that message
      chatStatelessActions.updateSeenMessages({
        otherpersoninfo_id: signalRMessage?.frompersoninfo_id,
        FromChatID: signalRMessage?.chat_id,
        ToChatID: signalRMessage?.chat_id,
      });
    } else {
      // update sidebar chats
      !signalRMessage?.isfrommyself && handleUpdateSignalRChat(signalRMessage);
    }
  } else {
    // update sidebar chats
    !signalRMessage?.isfrommyself && handleUpdateSignalRChat(signalRMessage);
  }
};

/**
 * @function getSignalRMessageType
 * @param { MessageItemInterface } signalRMessage
 * @returns { SignalRMessageType }
 */
export const getSignalRMessageType = (
  signalRMessage: MessageItemInterface,
): SignalRMessageType => {
  if (signalRMessage.isdeleted) {
    return 'DELETE';
  } else if (signalRMessage.isedited || signalRMessage.isseen) {
    return 'UPDATE';
  } else {
    return 'INSERT';
  }
};

/**
 * to get all chats
 * @function getChats
 * @param {GetChatFunctionParamsInterface} params
 * @returns {void} void
 */
export const getChats: GetChatsFunction = ({
  page,
  shouldLoading,
  successCallback,
  failureCallback,
}) => {
  // FIXME:
  actorDispatch(
    'getChatReport',
    {
      params: {
        reportId: chatsReportId,
        pagination: { page, perPage: 10 },
      },
      successCallback: successCallback ?? successGetChatsCallback,
      failureCallback: failureCallback ?? getChatsFailureCallback,
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * @function successGetChatsCallback
 * @param { ChatItemInterface[] } chatData
 * @returns { void } void
 */
export const successGetChatsCallback = (chatData: ChatItemInterface[]): void => {
  const currentChatList = actorGetActionValue('chatList')!;
  // if (!(Array.isArray(chatData) && chatData.length > 0)) {
  //   actorDispatch('chatsData', {
  //     ...currentChatsData,
  //     hasMore: false,
  //   });
  //   actorDispatch('loading', { chatsLoading: false });
  //   return;
  // }

  // actorDispatch('chatsData', {
  //   data: [...currentChatsData.data, ...chatData],
  //   hasMore: true,
  // });

  actorDispatch('loading', { chatsLoading: false });
};

/**
 * @function failureCallback
 * @param error
 * @returns { void } void
 */
export const getChatsFailureCallback = (error: unknown): void => {
  showNotification(error, 'error');
};
/**
 * @function handleAfterSuccessGetUserContents
 * @param { MessageItemInterface[] } data
 * @param { number } total
 * @returns { void }
 */
const handleAfterSuccessGetUserContents = (
  data: MessageItemInterface[],
  total: number,
): void => {
  const currentSelectedUser = actorGetActionValue('selectedChat')!.info;

  if (isEmptyObject(currentSelectedUser)) {
    // FIXME: Handle this situation if it's necessary
    return;
  }

  /**
   * the situation we get just unseen messages
   * and they are less than 10 items
   * so we need more items to active scroll
   * so we fetch prev items
   */
  getChatContents({
    userId: currentSelectedUser.personinfo_id,
    chatdate: data[data.length - 1].chatdate,
    IsUp: 1,
    countToGet: 10,
    stopToGetMore: true,
  });
};

/**
 * @function successGetUserContentsCallback
 * @param {number} userId
 * @param {ChatReportResponseInterface<MessageItemInterface>} reportResponse
 * @param {boolean} olderMessages
 * @returns {void} void
 */
const successGetChatContentsCallback = (
  userId: number,
  reportResponse: ChatReportResponseInterface<MessageItemInterface>,
  olderMessages: boolean,
  stopToGetMore = false,
  customSuccessCallback?: () => void,
): void => {
  const { data, totalCount } = reportResponse;

  // prettier-ignore
  const currentSelectedChat = actorGetActionValue('selectedChat')!;
  let updatedMessagesDetail = currentSelectedChat.messagesDetail;
  if (updatedMessagesDetail == null) {
    updatedMessagesDetail = {
      data: [],
      hasMore: false,
    };
  }

  if (totalCount === 0) {
    if (olderMessages) {
      updatedMessagesDetail.hasMore = false;
    }

    actorDispatch(
      'selectedChat',
      { ...currentSelectedChat, messagesDetail: updatedMessagesDetail },
      {
        replaceAll: true,
        callerScopeName: 'successGetChatContentsCallback',
      },
    );

    actorSetActionValue(
      'chatList',
      { info: currentSelectedChat.info, messagesDetail: updatedMessagesDetail },
      {
        replaceAll: true,
        path: `data.${userId}`,
        callerScopeName: 'successGetChatContentsCallback',
      },
    );

    customSuccessCallback?.();
    return;
  }

  let updatedChatMessages = updatedMessagesDetail.data;
  if (olderMessages) {
    updatedChatMessages = updatedChatMessages.concat(data);
  } else {
    updatedChatMessages.unshift(...data);
  }

  const updatedChatData = {
    ...currentSelectedChat,
    info: {
      ...currentSelectedChat.info,
      chatdate: updatedChatMessages[0].chatdate,
      chattext: updatedChatMessages[0].chattext,
      frompersonname: updatedChatMessages[0].personname,
    },
    messagesDetail: { data: updatedChatMessages, hasMore: true },
  };

  actorSetActionValue('chatList', updatedChatData, {
    replaceAll: true,
    path: `data.${userId}`,
    callerScopeName: 'successGetChatContentsCallback 2',
  });

  actorDispatch('selectedChat', updatedChatData, {
    replaceAll: true,
    callerScopeName: 'successGetChatContentsCallback 2',
  });

  if (!stopToGetMore) {
    handleAfterSuccessGetUserContents(updatedChatMessages, totalCount);
  }

  customSuccessCallback?.();
};

/**
 * to get all contents of a specific user
 * @function getUserContents
 * @param { GetChatContentsParams } params
 * @returns { void } void
 */
export const getChatContents = (params: GetChatContentsParams): void => {
  const {
    userId,
    countToGet,
    chatdate,
    IsUp,
    MoveLast,
    ChatID,
    AutoSeen,
    stopToGetMore,
    customSuccessCallback,
  } = params;

  actorDispatch('getChatReport', {
    params: {
      reportId: userContentsReportId,
      pagination: { perPage: countToGet ?? 999999 },
      filters: [
        ['otherpersoninfo_id', 'equal', userId],
        ['chatdate', 'equal', chatdate],
        ['IsUp', 'equal', IsUp],
        ['MoveLast', 'equal', MoveLast],
        ['ChatID', 'equal', ChatID],
        ['AutoSeen', 'equal', AutoSeen],
      ],
      sort: {
        field: 'chatdate',
        order: 'DESC',
      },
    },
    successCallback: (response: ChatReportResponseInterface<MessageItemInterface>) =>
      successGetChatContentsCallback(
        userId,
        response,
        Boolean(IsUp),
        stopToGetMore,
        customSuccessCallback,
      ),
  });
};

/**
 * @function successGetContactsCallback
 * @param { ContactsInterface[] } data
 * @returns { void }
 */
const successGetContactsCallback = ({ data }): void => {
  if (isEmpty(data) || isEmptyObject(data)) {
    actorDispatch('loading', { contactsLoading: false });
    return;
  }
  actorDispatch('loading', { contactsLoading: false });
};

/**
 * to get all contacts
 * @function getContacts
 * @param { GetContactsParams } params
 * @returns { void } void
 */
export const getContacts = (params: GetContactsParams): void => {
  //TODO default => shouldLoading = false, page = contactPage
  const { shouldLoading, page } = params;
  if (shouldLoading) {
    actorDispatch('loading', { contactsLoading: true });
  }
  actorDispatch(
    'getChatReport',
    {
      successCallback: successGetContactsCallback,
      params: {
        reportId: contactsReportId,
        pagination: { page, perPage: 10 },
      },
      getChatsFailureCallback,
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * @function clearChatsData
 * @returns { void } void
 */
export const clearChatsData = (): void => {
  // actorDispatch('chatsData', null, { replaceAll: true });
  // actorDispatch('selectedUser', null, { replaceAll: true });
};

/**
 * @function handleMoveToRepliedMessage
 * @param { number } chatId
 * @returns { void }
 */
export const handleMoveToRepliedMessage = (chatId: number, successCallback) => {
  const currentSelectedUser = actorGetActionValue('selectedChat')!.info;
  if (currentSelectedUser) {
    getChatContents({
      userId: currentSelectedUser.personinfo_id,
      chatdate: null,
      IsUp: 0,
      MoveLast: 0,
      ChatID: chatId,
      customSuccessCallback: successCallback,
    });
  }
};

/**
 * it should show error messages
 * @function handleSendFilesErrors
 * @param {string} error
 * @returns {void} void
 */
export const handleSendFilesErrors =
  (largerThanAllowedFiles: Array<File>) =>
  (apiErrors: Array<{ fileName: string; message: string }>): void => {
    const { translate } = actorGetActionValue('reactAdminHelpers')!;

    showNotification(
      generateErrorMessage(largerThanAllowedFiles, apiErrors, translate),
      'error',
    );
  };

/**
 * @function postScrolling
 * @param {number[]} seenMessagesIds
 * @returns {void} void
 */
export const syncSeenMessages = (seenMessagesIds: number[]): void => {
  const selectedUser = actorGetActionValue('selectedChat')?.info;
  if (seenMessagesIds.length === 0 || selectedUser == null) return;

  const sortedAscSeenMessagesIds = seenMessagesIds.sort((a, b) => a - b);
  chatStatelessActions.updateSeenMessages({
    otherpersoninfo_id: selectedUser.personinfo_id,
    FromChatID: sortedAscSeenMessagesIds[0],
    ToChatID: sortedAscSeenMessagesIds.at(-1)!, // We sure to have an item at this position
  });
};
