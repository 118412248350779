import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import momentJalaali from 'moment-jalaali';
import { useStyles } from './mail-list-item.style';
import ListItem from '@material-ui/core/ListItem';

import { Checkbox } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ImportantIcon from '@material-ui/icons/LabelImportant';
import ImportantBorderIcon from '@material-ui/icons/LabelImportantOutlined';
import ReminderIcon from '@material-ui/icons/Notifications';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ReplyIcon from '@material-ui/icons/Reply';

import clsx from 'classnames';
import {
  gregorianDateFormat,
  jalaliDateFormat,
} from '../../../../helper/CalendarMetaHelper';
import { MailLabelType } from '../..';
import type { MailListItemViewInterface } from './mail-list-item.type';

const MailListItemView = (props: MailListItemViewInterface): ReactElement => {
  const {
    mail,
    isRead,
    isEven,
    onStarClick,
    onImportantClick,
    onShowDetail,
    checkedMails,
    removeCurrentLabel,
    onChangeCheckedMails,
    labelList,
  } = props;
  const classes = useStyles({ isRead, isEven });
  const locale = useLocale();
  momentJalaali.locale(locale);

  return (
    <ListItem
      dense
      button
      key={mail.uniqueid}
      className={classes.root}
      data-test="mail-list-item"
      onClick={event => onShowDetail(mail, event)}
      data-style-mail-list-item="mailListItem"
    >
      <Box
        display="flex"
        alignItems="center"
        width={{ xs: '100%', sm: 'auto' }}
        data-style-mail-item-right="mailItemRight"
      >
        <Box
          component="span"
          pr={2}
          display="inline-block"
          onClick={event => event.stopPropagation()}
        >
          <Checkbox
            checked={checkedMails.includes(mail)}
            onChange={() => onChangeCheckedMails(mail)}
            color="primary"
          />
        </Box>

        <Box
          component="span"
          className={classes.appsStarredRoot}
          onClick={event => event.stopPropagation()}
        >
          <Box component="span" color="text.disabled">
            <Checkbox
              checked={Boolean(mail.isstared)}
              onChange={() => onStarClick([mail], !mail.isstared)}
              data-test="mail-list-item-star-checkbox"
              icon={<StarBorderIcon data-test="mail-list-item-star-border-icon" />}
              checkedIcon={
                <StarIcon
                  data-test="mail-list-item-star-icon"
                  style={{ color: '#D4AF37' }}
                />
              }
            />
          </Box>
        </Box>
        <Box
          component="span"
          mr={10}
          className={classes.appsStarredRoot}
          onClick={event => event.stopPropagation()}
        >
          <Box component="span" color="text.disabled">
            <Checkbox
              checked={Boolean(mail.isimportant)}
              onChange={() => onImportantClick([mail], !mail.isimportant)}
              data-test="mail-list-item-important-checkbox"
              icon={
                <ImportantBorderIcon
                  data-test="mail-list-item-important-border-icon"
                  style={{ color: '#fff', stroke: '#000', fontSize: '1.3rem' }}
                />
              }
              checkedIcon={
                <ImportantIcon
                  data-test="mail-list-item-important-icon"
                  style={{ color: '#D4AF37' }}
                />
              }
            />
          </Box>
        </Box>

        <Tooltip title={mail?.contactinfo}>
          <Box
            component="p"
            className={clsx(
              classes.crMailTitle,
              classes.truncate,
              mail.isunread ? classes.fontBold : '',
            )}
          >
            {mail?.contactinfo}
          </Box>
        </Tooltip>
      </Box>

      <Box className={classes.crMailInfo} data-style-mail-item-left="mailItemLeft">
        <Box width={1} className={classes.subjectContainer}>
          <Tooltip title={mail.abstract}>
            <Box
              mb={0}
              component="p"
              className={clsx(
                classes.truncate,
                mail.isunread ? classes.fontBold : '',
              )}
            >
              <div className={classes.containerReply}>
                <div className={classes.styleIConReplay}>
                  <Box component="span" color="text.disabled">
                    {!!mail.replied && <ReplyIcon />}
                  </Box>
                </div>
                {mail.abstract}
              </div>
            </Box>
          </Tooltip>

          {labelList && (
            <Box
              display="flex"
              alignItems="center"
              ml={1}
              mt={{ xs: 1, sm: 0 }}
              component="span"
              onClick={event => event.stopPropagation()}
            >
              {labelList.map((label: MailLabelType) => (
                <Box ml={1} key={label.lables_id} component="span">
                  <Tooltip title={label.lablestitle} placement="top">
                    <Box
                      onClick={() => removeCurrentLabel(label)}
                      height={12}
                      width={12}
                      component="span"
                      borderRadius="50%"
                      display="block"
                      bgcolor={label.colorcode ?? '#000'}
                    />
                  </Tooltip>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box component="span" className={classes.crMailTime}>
          <Box component="span" className={classes.whitespacePre} pl={2}>
            {locale === 'fa'
              ? momentJalaali(mail.docdate).format(jalaliDateFormat)
              : momentJalaali(mail.docdate).format(gregorianDateFormat)}
          </Box>
          <Box component="span" display="flex" alignItems="center" mt={1}>
            <Box component="span">
              {mail.hasattachment ? <AttachFileIcon /> : null}
            </Box>
            <Box component="span">
              {mail.isremider ? <ReminderIcon style={{ color: '#D4AF37' }} /> : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

export default MailListItemView;
