import { ReactElement } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { actorDispatch, actorSetActionValue } from '../../../type/actor-setup';
import type {
  DevExpressCellData,
  SelectedItemForPermissionAdvance,
  SimpleGridColumn,
} from './simple-grid.type';
import { clone } from '../../../helper/data-helper';

/**
 * @function handleSaveClickPermission
 * @param {cellData} DevExpressCellData
 * @param {column} SimpleGridColumn
 * @returns {void} void
 */
export const handleSaveClickPermission = ({
  permKey,
  ColumnName,
  checked,
  cellData,
}: {
  permKey: string;
  ColumnName: string;
  checked: boolean;
  cellData?: DevExpressCellData;
}): void => {
  actorDispatch('saveCellInPermissionsAdvance', {
    [ColumnName]: { permissionKey: permKey, checked, cellData },
  });
};

/**
 * it will find grid column index from `fullName` property in change filter event
 * @function findColumnIndexByName
 * @param {string} name Ex. "...[1]..."
 * @returns {number} 1
 */
export const findColumnIndexByName = (name: string): number => {
  const colIndex = name
    .match(/\[\d+\]/)?.[0]
    .replace('[', '')
    .replace(']', '');

  return parseInt(colIndex ?? '');
};

/**
 * @function openPermissionsDialog
 * @param {cellData} DevExpressCellData
 * @param {column} SimpleGridColumn
 * @returns {void} void
 */
export const openPermissionsDialog = (
  cellData: DevExpressCellData,
  refreshTabData?: () => void,
  column?: SimpleGridColumn,
): void => {
  if (column?.click === 'false') return;
  actorDispatch('quickDialog', {
    reasonPermissionIsOpen: true,
    data: {
      cellData,
      column,
      refreshTabData,
    },
  });
};

/**
 * @function openPermissionsDialog
 * @param {boolean ,DevExpressCellData,SimpleGridColumn} {value,cellData,column}
 * @returns {ReactElement} ReactElement
 */
const BooleanCell = ({
  value,
  cellData,
  column,
  refreshTabData,
}: {
  value: boolean;
  cellData: DevExpressCellData;
  column: SimpleGridColumn;
  refreshTabData?: () => void;
}): ReactElement => {
  const name = column.permKey || `${column.name}PermKey` || `${column.name}permKey`;
  //@ts-ignore
  const checkNameInData = name in cellData?.data;
  //@ts-ignore
  const checkValue = cellData?.data[name];

  if ((!checkNameInData && !column?.permKey) || !checkValue) {
    return <></>;
  } else {
    return value === true ? (
      <CheckBoxIcon
        onClick={() => openPermissionsDialog(cellData, refreshTabData, column)}
      />
    ) : (
      <CheckBoxOutlineBlankIcon
        onClick={() => openPermissionsDialog(cellData, refreshTabData, column)}
      />
    );
  }
};

/**
 * @function AdvancedBooleanCell
 * @param {boolean ,DevExpressCellData,SimpleGridColumn} {value,cellData,column}
 * @returns {ReactElement} ReactElement
 */
export const AdvancedBooleanCell = ({
  cellData,
  column,
  selectItems,
}: {
  cellData?: DevExpressCellData;
  column?: SimpleGridColumn;
  selectItems?: SelectedItemForPermissionAdvance;
}): ReactElement => {
  const name: string =
    (column?.permKey as string) ||
    `${column?.name}PermKey` ||
    `${column?.name}permKey`;
  //@ts-ignore
  const checkNameInData = name ?? '' in cellData?.data;
  //@ts-ignore
  const checkValue = cellData?.data[name];
  if ((!checkNameInData && !column?.permKey) || !checkValue) {
    return <></>;
  } else {
    return (
      <Checkbox
        checked={selectItems?.[checkValue]?.checked ?? false}
        onChange={e =>
          handleSaveClickPermission({
            permKey: name,
            ColumnName: checkValue,
            checked: e.target?.checked,
            cellData,
          })
        }
      />
    );
  }
};

/**
 * @function TextCell
 * @param {value} value
 * @returns {ReactElement} ReactElement
 */
const TextCell = ({ value }: { value: string }): ReactElement => {
  if (value == 'null') {
    return <></>;
  } else {
    return <Typography align="center">{value}</Typography>;
  }
};

/**
 * @function prepareCustomCell
 * @param {SimpleGridColumn} column
 * @param {DevExpressCellData} cellData
 * @returns {ReactElement} ReactElement
 */
export const prepareCustomCell =
  (column: SimpleGridColumn, refreshTabData?: () => void) =>
  (cellData: DevExpressCellData): ReactElement => {
    if (column.type === 'text') {
      return <TextCell value={String(cellData.value)} />;
    }

    if (column.type === 'checkbox') {
      return (
        <BooleanCell
          value={Boolean(cellData.value)}
          cellData={cellData}
          column={column}
          refreshTabData={refreshTabData}
        />
      );
    }

    return <></>;
  };

/**
 * @function prepareCustomCellForAdvancedPermissions
 * @param {SimpleGridColumn} column
 * @param {DevExpressCellData} cellData
 * @returns {ReactElement} ReactElement
 */
export const prepareCustomCellForAdvancedPermissions =
  (
    column: SimpleGridColumn,
    refreshTabData?: () => void,
    selectItems?: SelectedItemForPermissionAdvance,
  ) =>
  (cellData: DevExpressCellData): ReactElement => {
    if (column.type === 'text') {
      return <TextCell value={String(cellData.value)} />;
    }

    if (column.type === 'checkbox') {
      return (
        <AdvancedBooleanCell
          cellData={cellData}
          column={column}
          selectItems={selectItems}
        />
      );
    }

    return <></>;
  };
