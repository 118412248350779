import ReactDOM from 'react-dom';
import { ReactComponent } from 'react-formio';
import settingsForm from './Button.settingsForm';

const CustomButton = () => {
  return (
    <div>
      <button>Custom Button</button>
    </div>
  );
};

// https://help.form.io/developers/custom-components
export default class Button extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: 'samian button',
      icon: 'square',
      group: 'Data',
      documentation: '',
      weight: -10,
      schema: {
        type: 'buttonCustomComponent',
        label: 'Default Label',
      },
    };
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(<CustomButton />, element);
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
