import { useTranslate } from 'react-admin';
import { useTheme } from '@material-ui/core';
import { Box, IconButton, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { useStyles } from './user-info-dialog.style';
import { ChatAvatarInput } from '../../chat-avatar-input';
import { ChatUserList } from '../../chat-user-list';
import { UserList } from '../../chat-sidebar/user-list';
import { emptyFunction } from '../../../form/form.helper';

import type { ReactElement } from 'react';
import type { UserInfoDialogViewPropsInterface } from './user-info-dialog.type';

const UserInfoDialogView = (
  props: UserInfoDialogViewPropsInterface,
): ReactElement => {
  const {
    closeDialogHandler,
    successFileCallback,
    tabChangeHandler,
    showUserInfo,
    onGroupSelect,
    selectedUser,
    commonGroups,
    isFetchingCommonGroups,
    groupUsers,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.mainContainer}>
      <DialogTitle>
        <Box className={classes.dialogTitleContainer}>
          <Box>{translate('chat.contactInformation')}</Box>
          <Box style={{ marginRight: '5rem' }}>
            <IconButton className={classes.icon} onClick={closeDialogHandler}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box className={classes.contentHeaderContainer}>
            <Box className={classes.groupImageContainer}>
              <ChatAvatarInput
                successFileCallback={successFileCallback}
                personImage={selectedUser.personimage}
              />
            </Box>
            <Box className={classes.groupNameContainer}>
              {selectedUser.personname}
            </Box>
          </Box>

          <Box>
            <Tabs
              defaultActiveKey={'0'}
              direction={theme.direction}
              onChange={tabChangeHandler}
              data-style-tabs="tabs"
            >
              <TabPane key={'0'} tab={<p>{translate('chat.commonGroups')}</p>}>
                <div className={classes.tabGroupsContainer}>
                  <div>
                    <UserList
                      data={{
                        list: commonGroups,
                        hasMore: false,
                      }}
                      selectedUser={selectedUser}
                      loading={isFetchingCommonGroups}
                      fetchMore={emptyFunction}
                      refreshFunction={emptyFunction}
                      onUserSelect={onGroupSelect}
                      isUserInfoDialog
                    />
                  </div>
                </div>
              </TabPane>
            </Tabs>
            <ChatUserList handleClick={showUserInfo} users={groupUsers} />
          </Box>
        </Box>
      </DialogContent>
    </Box>
  );
};

export default UserInfoDialogView;
