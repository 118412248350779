import { memo, ReactElement } from 'react';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import { useTranslate, useLocale } from 'react-admin';
import { Typography } from '@material-ui/core';
import lodashMap from 'lodash/map';

import { GridCell } from '../../grid/grid-cell';
import { Pagination } from '../../list-toolbar/pagination';
import { isEmpty } from '../../../helper/data-helper';
import { useStyles } from './list-chart.style';
import { ListChartViewInterface } from './list-chart.type';
import { SettingBar } from '../../list-toolbar/setting-bar';

const ListChartView = (props: ListChartViewInterface): ReactElement => {
  const {
    onCellClick,
    gridData,
    columns,
    metaData,
    width,
    height,
    chartOptions,
    paginationProps,
    sort,
    handleClickFilterIcon,
    onOptionChanged,
    settingBarProps,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <div className={classes.root} style={{ width, height }}>
      {!isEmpty(chartOptions?.titleFieldName) && (
        <Typography className={classes.title} variant="h5">
          {chartOptions?.titleFieldName}
        </Typography>
      )}
      <div className={classes.gridChart}>
        {
          //@ts-ignore
          <DataGrid
            className={classes.dataGrid}
            height={'100%'}
            width={'100%'}
            dataSource={gridData}
            showBorders={true}
            rowAlternationEnabled={true}
            rtlEnabled={locale !== 'en'}
            allowColumnResizing={true}
            columnResizingMode={'widget'}
            paging={{ enabled: false }}
            remoteOperations={{ sorting: true, filtering: true, paging: true }}
            noDataText={translate('imageDialog.dataNotFound')}
            repaintChangesOnly={true}
            onCellClick={onCellClick}
            onOptionChanged={onOptionChanged}
          >
            <Scrolling
              useNative={false}
              showScrollbar={'onHover'}
              preloadEnabled={true}
              mode={'standard'}
              scrollByThumb={true}
            />
            {lodashMap(columns, columnInfo => (
              // @ts-ignore
              <Column
                key={columnInfo.name}
                dataField={columnInfo.name}
                caption={columnInfo.title}
                sortIndex={columnInfo.name === sort?.field ? 1 : undefined}
                sortOrder={columnInfo.name === sort?.field ? sort?.order : undefined}
                sortingMethod={() => {
                  //this function should be here to prevent sorting
                  return;
                }}
                allowSorting={true}
                cellRender={cellData => {
                  columnInfo.field.customOption = {
                    additionalDataFieldAlt: '',
                    customStyleAdditionalDataField: {},
                  };

                  return (
                    <GridCell
                      key={`gridCell${columnInfo.name}`}
                      column={columnInfo}
                      row={cellData.data}
                      basePath={''}
                      hasShow={false}
                      resource={''}
                      relationMode={false}
                      metaData={metaData}
                      value={cellData?.data[columnInfo?.field?.name]}
                      hasEdit={false}
                      addToFilterRequestList={handleClickFilterIcon}
                    />
                  );
                }}
                width={columnInfo.field?.width}
              />
            ))}
          </DataGrid>
        }
      </div>

      <div className={classes.containerPagination}>
        <Pagination {...paginationProps} />
        <SettingBar
          {...settingBarProps}
          isGroupingEnable={false}
          isExportButtonEnable={false}
        />
      </div>
    </div>
  );
};

export default memo(ListChartView);
