import { Grid } from '@material-ui/core';
import { ReactElement } from 'react';
import { Button as ReactAdminButton } from 'react-admin';
import ReactDOM from 'react-dom';
import { isEmpty } from '../../helper/data-helper';
import { useStyles } from './relation-action-top-toolbar.style';
import { RelationActionTopToolbarViewInterface } from './relation-action-top-toolbar.type';

const RelationActionTopToolbarView = (
  props: RelationActionTopToolbarViewInterface,
): ReactElement => {
  const { list, stopPropagation, ...rest } = props;
  const classes = useStyles();

  if (
    isEmpty(list) ||
    list.length === 0 ||
    !document.getElementById('app-bar-portal')
  ) {
    return <></>;
  }
  return ReactDOM.createPortal(
    <Grid container>
      <Grid item xs={11}>
        <Grid
          container
          className={classes.container}
          direction="row"
          alignItems="center"
        >
          {list &&
            list.map(item => (
              <Grid
                item
                className={classes.item}
                key={`relation-action-top-toolbar-grid-${item['id']}`}
              >
                <ReactAdminButton
                  className={classes.buttonLink}
                  id={item['id']}
                  onClick={stopPropagation}
                  label={item['title']}
                  {...rest}
                  key={`relation-action-top-toolbar-button-${item['id']}`}
                ></ReactAdminButton>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>,
    document.getElementById('app-bar-portal') as Element,
  );
};

export default RelationActionTopToolbarView;
