export const MENUS_REPORT_ID = 'ee4d59ed-dd10-4338-86f9-6381bf7ff812';
export const BRANCHES_REPORT_ID = 'a327971b-f1fa-4832-bcfb-b3694ab0ecc2';

/**
 * @const {PERMISSION_SECTIONS_REPORT_ID}
 * parameter: `PermAssignType` => `1` for users, `2` for roles, `3` for charts,
 */
export const PERMISSION_SECTIONS_REPORT_ID = 'de342fb2-cc6f-46ce-8a90-740e181ee5ae';

export const APPLY_PERMISSION_URL = 'permission/bulkapplypermission';
export const REMOVE_PERMISSION_URL = 'permission/bulkremovepermission';
