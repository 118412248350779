import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  IconButton: {
    padding: 5,
  },
  menuContainer: {
    overflow: 'visible',
    width: 310,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    '&::after': {
      content: '""',
      display: 'block',
      padding: 10,
      position: 'absolute',
      top: '-3px',
      right: 25,
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
      transform: 'rotate(45deg)',
      zIndex: -1,
    },
  },

  itemContainer: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  infiniteScroll: {
    minHeight: 110,
  },

  notificationNotFound: {
    padding: 0,
    margin: 10,
    textAlign: 'center',
  },
  showAllMessagesButton: {
    alignSelf: 'flex-start',
    margin: '10px',
    border: 0,
    color: theme.palette.primary.appPrimaryTextColor,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.appPrimaryTextColor,
      textDecoration: 'underline',
    },
  },

  notificationListDialogContent: {
    padding: '0 !important',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },
    minHeight: '500px',
    width: '700px',
  },
  linkContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));
