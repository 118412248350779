// https://reactjs.org/docs/error-boundaries.html

import { ReactNode, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { translate as withTranslate } from 'react-admin';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';

import NotFound from '../component/NotFound';
import { Translate } from '../helper/Types';
import { logErrorToGraylog } from '../helper/data-helper';

interface ErrorBoundaryProps {
  classes: Partial<ClassNameMap<string>>;
  translate: Translate;
  children: ReactNode;
}
interface ErrorBoundaryStates {
  hasError: boolean;
}

const styles = theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
  },
});

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryStates> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToGraylog(error, errorInfo, 'crash');
    console.log(error, errorInfo);
  }

  render() {
    const { classes, translate, children } = this.props;

    if (this.state.hasError) {
      return (
        <div className={classes.container}>
          {/* //fixme : translate doesn't work when app crashes */}
          {/* <NotFound title={translate('ra.notification.code_error')} /> */}

          <NotFound
            hideBackButton
            withRefreshButton
            refreshCallBack={() => this.setState({ hasError: false })}
            title={
              'خطایی در برنامه رخ داده است. این خطا به زودی بررسی و رفع خواهد شد.'
            }
          />
        </div>
      );
    }

    return children;
  }
}

export default compose(
  withTranslate,
  withStyles(styles, { withTheme: true }),
)(ErrorBoundary);
