import { Typography } from '@material-ui/core';
import { FC, memo, MouseEvent, useCallback, useMemo } from 'react';
import { useStyles } from './word-cloud.style';

import { Word, WordCloudInterface } from './word-cloud.type';
import WordCloudView from './word-cloud.view';
import { isEmpty } from '../../../helper/data-helper';

const WordCloudController: FC<WordCloudInterface> = memo(props => {
  const { chartOptions, reportData, handleChartItemClick, height, width } = props;
  const classes = useStyles();

  //in old dashboard version valueFieldName was array and maybe we have crash
  const finalValueFieldName = Array.isArray(chartOptions.valueFieldName)
    ? chartOptions.valueFieldName[0]
    : chartOptions.valueFieldName;

  const words: Word[] = useMemo(
    () =>
      chartOptions?.wordFieldName && finalValueFieldName
        ? reportData?.map(item => ({
            value: item[finalValueFieldName] as number,
            text: item[chartOptions?.wordFieldName] as string,
            id: (item[chartOptions?.idFieldName] as string) ?? '',
          }))
        : [],
    [chartOptions?.wordFieldName, finalValueFieldName, reportData],
  );

  /**
   * @function onClick
   * @param {React.MouseEvent<SVGTextElement, MouseEvent>} event
   * @param {Word} word
   * @return {void}
   */
  const onClick = useCallback(
    (_event: MouseEvent<SVGTextElement, MouseEvent>, word: Word) => {
      if (word?.id && !isEmpty(chartOptions?.idFieldName)) {
        handleChartItemClick?.(word.id);
      }
    },
    [],
  );

  return (
    <WordCloudView
      width={width}
      height={height}
      data={words}
      onWordClick={onClick}
      title={chartOptions?.titleFieldName}
    />
  );
});

export default WordCloudController;
