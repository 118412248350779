import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { IconButton } from '@material-ui/core';
import momentJalaali from 'moment-jalaali';
import TetherComponent from 'react-tether';
import classnames from 'classnames';
import Calendar from './Calendar';
import MyTimePicker from './CustomTimePicker';
import { toEnglishDigits, toPersianDigits } from '../utils/moment-helper';

const outsideClickIgnoreClass = 'ignore--click--outside';

export default class DatePicker extends Component {
  static propTypes = {
    value: PropTypes.object,
    defaultValue: PropTypes.object,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    children: PropTypes.node,
    min: PropTypes.object,
    max: PropTypes.object,
    defaultYear: PropTypes.object,
    defaultMonth: PropTypes.object,
    inputFormat: PropTypes.string,
    inputJalaaliFormat: PropTypes.string,
    removable: PropTypes.bool,
    styles: PropTypes.object,
    calendarStyles: PropTypes.object,
    calendarContainerProps: PropTypes.object,
    isGregorian: PropTypes.bool, // jalaali or gregorian
    timePicker: PropTypes.bool,
    calendarClass: PropTypes.string,
    datePickerClass: PropTypes.string,
    tetherAttachment: PropTypes.string,
    inputReadOnly: PropTypes.bool,
    ranges: PropTypes.array,
    showToggleButton: PropTypes.bool,
    toggleButtonText: PropTypes.any,
    showTodayButton: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    setTodayOnBlur: PropTypes.bool,
    disableYearSelector: PropTypes.bool,
    locale: PropTypes.oneOf(['en', 'fa', 'ar']),
    showDefaultInput: PropTypes.bool,
    disabled: PropTypes.bool,
    hiddenInput: PropTypes.bool
  };

  static defaultProps = {
    styles: undefined,
    calendarContainerProps: {},
    isGregorian: true,
    timePicker: true,
    showTodayButton: true,
    showCloseButton: false,
    placeholder: '',
    name: '',
    setTodayOnBlur: true,
    disableYearSelector: false,
    locale: 'fa',
    showDefaultInput: true,
    disabled: false,
    hiddenInput: false
  };

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef();
    this.prevMomentValue = React.createRef();

    this.state = {
      isOpen: false,
      momentValue: this.props.defaultValue || null,
      inputValue: this.getValue(
        this.props.defaultValue,
        this.props.isGregorian,
        this.props.timePicker,
      ),
      inputJalaaliFormat:
        this.props.inputJalaaliFormat ||
        this.getInputFormat(false, this.props.timePicker),
      inputFormat:
        this.props.inputFormat || this.getInputFormat(true, this.props.timePicker),
      isGregorian: this.props.isGregorian,
      timePicker: this.props.timePicker,
      timePickerComponent: this.props.timePicker ? MyTimePicker : undefined,
      setTodayOnBlur: this.props.setTodayOnBlur,
    };
  }

  getInputFormat(isGregorian, timePicker) {
    if (timePicker) return isGregorian ? 'YYYY/M/D HH:mm' : 'jYYYY/jM/jD HH:mm';
    return isGregorian ? 'YYYY/M/D' : 'jYYYY/jM/jD';
  }

  getValue(inputValue, isGregorian, timePicker) {
    const { locale } = this.props;
    if (!inputValue) return '';
    let { inputFormat, inputJalaaliFormat } = this.state ?? {};
    if (!inputFormat) inputFormat = this.getInputFormat(isGregorian, timePicker);
    if (!inputJalaaliFormat)
      inputJalaaliFormat = this.getInputFormat(isGregorian, timePicker);

    inputValue.locale(locale);

    return isGregorian
      ? inputValue.format(inputFormat)
      : inputValue.format(inputJalaaliFormat);
  }

  setOpen = isOpen => {
    this.setState({ isOpen });
    this.props.onOpen?.(isOpen);
  };

  componentDidUpdate() {
    if (this.state.momentValue && this.state.momentValue !== this.prevMomentValue.current) {
      this.props.onChange?.(this.state.momentValue);
      this.prevMomentValue.current = this.state.momentValue;
    }
  }

  UNSAFE_componentWillMount() {
      if (this.props.value) {
      this.setMomentValue(this.props.value);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ('value' in nextProps && nextProps.value === null) {
      this.setState({
        input: '',
        inputValue: '',
        momentValue: null,
      });
    } else if (
        nextProps.value != null &&
        this.props.value != null &&
        !nextProps.value.isSame(this.props.value)
    ) {
      this.setMomentValue(nextProps.value);
    }

    if (
      'isGregorian' in nextProps &&
      nextProps.isGregorian !== this.props.isGregorian
    ) {
      const { inputFormat: nextPropsInputFormat } = nextProps;
      const { inputJalaaliFormat: nextPropsInputJalaaliFormat } = nextProps;

      this.setState({
        isGregorian: nextProps.isGregorian,
        inputValue: this.getValue(
          nextProps.value,
          nextProps.isGregorian,
          nextProps.timePicker,
        ),
        inputFormat: nextPropsInputFormat || this.state.inputFormat,
        inputJalaaliFormat:
          nextPropsInputJalaaliFormat || this.state.inputJalaaliFormat,
      });
    }

    if (
      'timePicker' in nextProps &&
      nextProps.timePicker !== this.props.timePicker
    ) {
      this.setState({
        timePicker: nextProps.timePicker,
        timePickerComponent: this.props.timePicker ? MyTimePicker : undefined,
      });
    }

    if (
      'setTodayOnBlur' in nextProps &&
      nextProps.setTodayOnBlur !== this.props.setTodayOnBlur
    ) {
      this.setState({
        setTodayOnBlur: nextProps.setTodayOnBlur,
      });
    }
  }

  toggleMode = () => {
    const isGregorian = !this.state.isGregorian;
    const { inputFormat: nextPropsInputFormat } = this.props;
    const { inputJalaaliFormat: nextPropsInputJalaaliFormat } = this.props;
    this.setState({
      isGregorian: isGregorian,
      inputValue: this.getValue(
        this.props.value,
        isGregorian,
        this.props.timePicker,
      ),
    });
  };

  setMomentValue(momentValue) {
    const { isGregorian, timePicker } = this.state;
    const inputValue = this.getValue(momentValue, isGregorian, timePicker);
    this.setState({ momentValue, inputValue });
  }

  // handleFocus = () => {
  //   this.setOpen(true);
  // };

  handleClickOutsideCalendar(event) {
    this.setOpen(false);
    if (!event.target.value) return;
    this.props.onBlur?.(event.target.value);
  }

  handleSelectDay(selectedDay) {
    const { momentValue: oldValue } = this.state;
    let momentValue = selectedDay.clone();

    if (oldValue) {
      momentValue = momentValue.set({
        hour: oldValue.hours(),
        minute: oldValue.minutes(),
        second: oldValue.seconds(),
      });
    } else if (this.props.timePicker) {
      momentValue = momentValue.set({
        hour: momentJalaali().hours(),
        minute: momentJalaali().minutes(),
        second: momentJalaali().seconds(),
      });
    }

    this.setMomentValue(momentValue);

    if (this.props?.showDefaultInput) this.input.focus();

    if (!this.props.timePicker) {
      this.setOpen(false);
    }
  }

  handleInputChange(event) {
    const { inputFormat, inputJalaaliFormat, isGregorian } = this.state;
    const inputValue = toEnglishDigits(event.target.value);
    const currentInputFormat = isGregorian ? inputFormat : inputJalaaliFormat;

    const momentValue = momentJalaali(inputValue, currentInputFormat);

    const cursor = event.target.selectionStart;

    if (momentValue.isValid()) {
      this.setState({ momentValue });
    }

    this.props.onInputChange?.(event);
  }

  handleBlur(event) {
    if (!event.target.value && this.state.setTodayOnBlur === false) return;

    const { inputFormat, inputJalaaliFormat, isGregorian } = this.state;
    const inputValue = toEnglishDigits(event.target.value);
    const currentInputFormat = isGregorian ? inputFormat : inputJalaaliFormat;
    const momentValue = momentJalaali(inputValue, currentInputFormat);

    let _value = null;
    if (event.target.value && momentValue.isValid()) {
      _value = this.state.momentValue
    } else if (this.state.setTodayOnBlur === true) {
      _value = momentJalaali();
    }

    this.props.onChange?.(_value);
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === '\n' || event.key === 'Tab') {
      this.setOpen(false);
    }
    if (this.props.onKeyDown) this.props.onKeyDown(event);
  }

  handleInputClick() {
    if (!this.props.disabled) {
      this.setOpen(true);
    }
  }

  removeDate = () => {
    const { onChange } = this.props;
    this.setState({
      input: '',
      inputValue: '',
      momentValue: null,
    });
    if (onChange) {
      onChange(null);
    }
  }
  renderInput = ref => {
    const { isOpen, inputValue, isGregorian } = this.state;
    const { locale, showDefaultInput } = this.props;
    const className = classnames(this.props.className, {
      [outsideClickIgnoreClass]: isOpen,
    });
    return (
      <div ref={ref}>
        <input
          placeholder={this.props.placeholder}
          name={this.props.name}
          className={`datepicker-input ${className}`}
          type="search"
          ref={inst => {
            this.input = inst;
          }}
          // onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onChange={this.handleInputChange.bind(this)}
          onClick={this.props.hiddenInput ? undefined : this.handleInputClick.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
          value={
          locale === 'en'|| locale ==='ar'
              ? toEnglishDigits(inputValue)
              : toPersianDigits(inputValue)
          }
          readOnly={this.props.inputReadOnly === true}
          disabled={this.props.disabled}
          hidden={this.props.hiddenInput}
        />

        {this.props.hiddenInput && <IconButton
          color={!this.props.disabled ? 'primary' : 'default'}
          size="small"
          onClick={this.handleInputClick.bind(this)}
        >
          <DateRangeIcon />
        </IconButton>
        }
      </div>
    );
  };

  renderCalendar = ref => {
    const { momentValue, isGregorian, timePickerComponent: TimePicker } = this.state;
    const {
      onChange,
      min,
      max,
      defaultYear,
      defaultMonth,
      styles,
      calendarContainerProps,
      ranges,
      disableYearSelector,
      locale,
    } = this.props;

    return (
      <div ref={ref}>
        <Calendar
          toggleMode={this.toggleMode}
          ranges={ranges}
          min={min}
          max={max}
          selectedDay={momentValue}
          defaultYear={defaultYear}
          defaultMonth={defaultMonth}
          onSelect={this.handleSelectDay.bind(this)}
          onClickOutside={this.handleClickOutsideCalendar.bind(this)}
          outsideClickIgnoreClass={outsideClickIgnoreClass}
          styles={styles}
          containerProps={calendarContainerProps}
          isGregorian={isGregorian}
          calendarClass={this.props.calendarClass ? this.props.calendarClass : ''}
          showToggleButton={this.props.showToggleButton}
          toggleButtonText={this.props.toggleButtonText}
          showTodayButton={this.props.showTodayButton}
          showCloseButton={this.props.showCloseButton}
          disableYearSelector={disableYearSelector}
          locale={locale}
          timePicker={
            TimePicker ? (
              <TimePicker
                outsideClickIgnoreClass={outsideClickIgnoreClass}
                isGregorian={isGregorian}
                min={min}
                max={max}
                momentValue={momentValue}
                setMomentValue={this.setMomentValue.bind(this)}
                locale={locale}
              />
            ) : null
          }
        />
      </div>
    );
  };


  render() {
    const { isOpen } = this.state;
    const { showDefaultInput } = this.props;

    if (!showDefaultInput) {
      return this.renderCalendar();
    }

    return (
      <TetherComponent
        ref={tether => (this.tether = tether)}
        attachment={
          this.props.tetherAttachment ? this.props.tetherAttachment : 'top center'
        }
        constraints={[
          {
            to: 'scrollParent',
            pin: true,
          },
          {
            to: 'window',
            attachment: 'together',
            pin: true,
          },
        ]}
        offset="-10px -10px"
        onResize={() => this.tether && this.tether.position()}
        /* renderTarget: This is what the item will be tethered to, make sure to attach the ref */
        renderTarget={ref => this.renderInput(ref)}
        /* renderElement: If present, this item will be tethered to the the component returned by renderTarget */
        renderElement={ref => isOpen && this.renderCalendar(ref)}
      />
    );
  }
}
