import { useStyles } from './area-chart.style';
import { ResponsiveAreaBump } from '@nivo/bump';

import type { FC } from 'react';
import type { AreaChartViewProps } from './area-chart.type';

const AreaChartView: FC<AreaChartViewProps> = props => {
  const { compatibleData, width, height, bottomLegend, topLegend } = props;

  const classes = useStyles();

  return (
    <div className={classes.container} style={{ width, height }}>
      <ResponsiveAreaBump
        data={compatibleData as any}
        margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
        spacing={8}
        colors={{ scheme: 'paired' }}
        blendMode="multiply"
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: 'CoffeeScript',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'TypeScript',
            },
            id: 'lines',
          },
        ]}
        // startLabel="id"
        // endLabel="id"
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: topLegend,
          legendPosition: 'middle',
          legendOffset: -36,
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: bottomLegend,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
      />
    </div>
  );
};

export default AreaChartView;
