import { FC, memo } from 'react';

import { FileCardInterface } from './file-card.type';
import FileCardView from './file-card.view';

const FileCardController: FC<FileCardInterface> = memo(props => {
  const { file, onDeleteCard } = props;

  return <FileCardView file={file} onDeleteCard={onDeleteCard} />;
});

export default FileCardController;
