import type { GeneralMetaData } from '../../helper/Types';
import type { SortOrderType } from '../../type/actor-setup';
import type { PaginationParams } from '../announcement-posts/announcement-posts.type';
import type { EditPropertyOfDashboardCardType } from '../dashboard/dashboard-card/dashboard-card.type';
import type { DashboardCardsDataInterface } from '../dashboard/dashboard-page.type';
import type { GadgetInformation } from '../dynamic-input/gadget-input';
import type { SortReportDataInterface } from '../dynamic-input/gadget-input/gadget-design-dialog/setting-section/gadget-settings-tab/dynamic-chart-settings-selector/sort-picker/sort-picker.type';
import type { FinalFiltersType } from '../filter-form';

// it will use as a constant to pass correct type with correct spelling value
export enum MainCharts {
  Line = 'LineChart',
  Bar = 'BarChart',
  Pie = 'PieChart',
  Area = 'AreaChart',
  WordCloud = 'WordCloud',
  Stat = 'StatChart',
  Gauge = 'GaugeChart',
  List = 'List',
  Announcements = 'Announcements',
  Calendar = 'Calendar',
  TimeSeriesChart = 'TimeSeriesChart',
  Iframe = 'Iframe',
}

// it will use to create a loop between all chart types from MainCharts
export const ChartTypes = [
  MainCharts.Line,
  MainCharts.Bar,
  MainCharts.Pie,
  MainCharts.Area,
  MainCharts.WordCloud,
  MainCharts.Stat,
  MainCharts.Gauge,
  MainCharts.List,
  MainCharts.Announcements,
  MainCharts.Calendar,
  MainCharts.TimeSeriesChart,
  MainCharts.Iframe,
] as const;

// it will use for component interfaces that want to accept only one of the chart typesF
export type ChartType = (typeof ChartTypes)[number];

export type ValueFieldName = string[];

export interface PieChartOptions {
  idFieldName: string | null;
  valueFieldName: ValueFieldName;
  labelFieldName: string | null;
  colorFieldName?: string;
  sortObject?: string;
  displayNumber?: number;
}
export interface IframeChartOptions {
  url: string | null;
  attachSessionToUrl?: boolean;
}

export interface AnnouncementsChartOptions {
  paginationParams: PaginationParams;
  reportAddress: string | null;
}

export interface ListChartOptions {
  titleFieldName: string | null;
  reportAddress: string | null;
  filterValues: FinalFiltersType;
  perPage: number;
  sort: {
    field: string | null;
    order: SortOrderType;
  };
}

export interface BarChartOptions {
  axisFieldName: string[];
  valueFieldName: ValueFieldName;
  axisBottomLabelFieldName: string;
  axisLeftLabelFieldName: string;
  sortObject?: string;
  displayNumber?: number;
}
export interface LineChartOptions {
  idFieldName: string;
  axisFieldName: string;
  valueFieldName: ValueFieldName;
  colorFieldName: string;
  axisBottomLabelFieldName: string;
  axisLeftLabelFieldName: string;
  legendFieldName: string;
}

export interface TimeSeriesChartOptions {
  axisFieldName: string;
  valueFieldName: ValueFieldName;
  axisBottomLabelFieldName: string;
  axisLeftLabelFieldName: string;
  legendFieldName: string;
}
export interface AreaChartOptions {
  axisFieldName: string;
  valueFieldName: ValueFieldName;
  colorFieldName: string;
  axisBottomLabelFieldName: string;
  axisTopLabelFieldName: string;
  legendFieldName: string;
}

export interface WordCloudOptions {
  wordFieldName: string;
  valueFieldName: ValueFieldName;
  titleFieldName: string;
  idFieldName: string;
}

export interface StatChartOptions {
  valueFieldName: ValueFieldName;
  titleFieldName: string;
  iconFieldName: string;
}

export interface GaugeChartOptions {
  valueFieldName: ValueFieldName;
  labelFieldName?: string;
  titleFieldName: string;
  thresholdColorRange: string;
}
export interface DynamicInputPieChartCustomizedProps {
  chartType: MainCharts.Pie;
  reportData: Array<Record<string, unknown>>;
  chartOptions: PieChartOptions;
  resource: string;
}
export interface DynamicInputIframeChartCustomizedProps {
  chartType: MainCharts.Iframe;
  chartOptions: IframeChartOptions;
}
export interface DynamicAnnouncementsChartCustomizedProps {
  chartType: MainCharts.Announcements;
  reportData: Array<Record<string, unknown>>;
  chartOptions: AnnouncementsChartOptions;
}
export interface DynamicInputLineChartCustomizedProps {
  chartType: MainCharts.Line;
  reportData: Array<Record<string, unknown>>;
  chartOptions: LineChartOptions;
}
export interface DynamicInputTimeSeriesChartCustomizedProps {
  chartType: MainCharts.TimeSeriesChart;
  reportData: Array<Record<string, unknown>>;
  chartOptions: TimeSeriesChartOptions;
}
export interface DynamicInputBarChartCustomizedProps {
  chartType: MainCharts.Bar;
  reportData: Array<Record<string, unknown>>;
  chartOptions: BarChartOptions;
}
export interface DynamicInputAreaChartCustomizedProps {
  chartType: MainCharts.Area;
  reportData: Array<Record<string, unknown>>;
  chartOptions: AreaChartOptions;
}
export interface DynamicInputStatChartCustomizedProps {
  chartType: MainCharts.Stat;
  reportData: Array<Record<string, unknown>>;
  chartOptions: StatChartOptions;
}
export interface DynamicInputGaugeChartCustomizedProps {
  chartType: MainCharts.Gauge;
  reportData: Array<Record<string, unknown>>;
  chartOptions: GaugeChartOptions;
}
export interface DynamicInputListChartCustomizedProps {
  chartType: MainCharts.List;
  chartOptions: ListChartOptions;
}

export interface DynamicInputWordCloudCustomizedProps {
  chartType: MainCharts.WordCloud;
  reportData: Array<Record<string, unknown>>;
  chartOptions: WordCloudOptions;
}

export interface DynamicInputCalendarCustomizedProps {
  chartType: MainCharts.Calendar;
  reportData: Array<Record<string, unknown>>;
  chartOptions: [];
}

export type DynamicChartControllerProps = (
  | DynamicInputPieChartCustomizedProps
  | DynamicInputLineChartCustomizedProps
  | DynamicInputBarChartCustomizedProps
  | DynamicInputGaugeChartCustomizedProps
  | DynamicInputAreaChartCustomizedProps
  | DynamicInputStatChartCustomizedProps
  | DynamicInputListChartCustomizedProps
  | DynamicInputWordCloudCustomizedProps
  | DynamicInputCalendarCustomizedProps
  | DynamicAnnouncementsChartCustomizedProps
  | DynamicInputTimeSeriesChartCustomizedProps
  | DynamicInputIframeChartCustomizedProps
) & {
  editPropertyOfDashboardCard?: EditPropertyOfDashboardCardType;
  handleChartItemClick?: HandleChartItemClickType;
  resource?: string;
};

export type HandleChartItemClickType = (
  value: string | number,
  fieldName?: string,
  operator?: string,
) => void;

export interface DynamicChartViewProps
  extends Pick<DynamicChartControllerProps, 'chartOptions'> {
  chartType: ChartType;
  reportData?: Array<Record<string, unknown>>;
  height: number;
  width: number;
  editPropertyOfDashboardCard?: EditPropertyOfDashboardCardType;
  handleChartItemClick?: HandleChartItemClickType;
  reportColumns?: { [key: string]: string };
  metaData?: GeneralMetaData;
  resource: string;
}

export interface PrepareDynamicChartPropsInterface {
  gadgetValues: GadgetInformation;
  reportData: Array<Record<string, unknown>>;
  filterValues?: FinalFiltersType;
  dashboardCardData?: DashboardCardsDataInterface;
  editPropertyOfDashboardCard?: EditPropertyOfDashboardCardType;
  handleChartItemClick?: HandleChartItemClickType;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  currentGadgetMetaData?: Partial<GeneralMetaData>;
  displayNumber?: number;
}
