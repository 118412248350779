import { FC, useCallback, useEffect, useState } from 'react';
import { parseJSON } from '../../../../core/configProvider';
import {
  actorDispatch,
  actorOnDispatch,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { RelationNoteDataInterface } from '../../../show-record-with-relation';
import { customScrollTo } from './pinned-notes-helper';
import { GET_LIST } from 'react-admin';

import { PinnedNotesPropsController } from './pinned-notes-type';
import PinnedNotesView from './pinned-notes-view';
import { getRootTableId } from '../../../../helper/meta-helper';
import { customRecordNotePin } from '../create-edit-note/constants';

const PinnedNotesController: FC<PinnedNotesPropsController> = props => {
  const { match } = props;

  const [pinnedNotes, setPinnedNotes] = useState<RelationNoteDataInterface[]>([]);

  useEffect(() => {
    getPinOfNote();

    actorOnDispatch(
      'refreshView',
      resource => {
        if (resource === 'newDataNotePin') {
          setTimeout(() => {
            getPinOfNote();
          }, 100);
        }
      },
      {
        preserve: true,
      },
    );
  }, []);

  /**
   * get data for pin note
   * @function toggleAccordion
   * @param {data: RelationNoteData[]} result
   * @returns {void} void
   */
  const getDataPinNote = useCallback(
    (result: { data: RelationNoteDataInterface[] }): void => {
      setPinnedNotes(result?.data);
    },
    [],
  );

  /**
   * get pin Note
   * @function getPinOfNote
   * @returns {void} void
   */
  const getPinOfNote = useCallback((): void => {
    const parentId = match.params.id;
    if (!parentId) return;

    const filter = [
      ['KeyID', '=', parentId ?? ''],
      'and',
      ['IsPin', '=', 1],
      'and',
      ['tableid', '=', String(getRootTableId() ?? '')],
    ];

    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        entity: 'pinnedNotes',
        disableNotification: true,
        resource: `report/${customRecordNotePin}`,
        requestParameters: {
          filter,
          IsPin: 1,
          KeyID: parentId,
          tableid: getRootTableId(),
        },
        parentId,
        onSuccess: getDataPinNote,
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'PinnedNotesController => getPinOfNote',
      },
    );
  }, [match.params.id]);

  /**
   * hide pinned note on top page with click on close button
   * @function hidePinnedNote
   * @param {number} id
   * @returns {void} void
   */
  const hidePinnedNote = useCallback(
    (id: number) => (): void => {
      if (Array.isArray(pinnedNotes)) {
        setPinnedNotes(pinnedNotes.filter(note => note.id !== id));
      } else {
        setPinnedNotes([]);
      }
    },
    [pinnedNotes],
  );

  /**
   *  find id pinned not and click on note the page scroll down
   * @function handlePinNoteScroll
   * @returns {void} void
   */
  const handlePinNoteScroll = useCallback((event): void => {
    event.stopPropagation();

    actorDispatch(
      'toggleAccordion',
      {
        accordionId: 'notes',
      },
      { disableDebounce: true },
    );

    actorSetActionValue('scroll', {
      uniqIdToScroll: event?.currentTarget.id,
    });

    const target = event?.currentTarget.id;
    if (target) {
      customScrollTo(String(target));
    }
  }, []);

  /**
   *  get json note and convert to string
   * @function parseJSONNote
   * @param {string} str
   * @returns {string | null} string | null
   */
  const parsedJSONNote = useCallback((str: string): string | null => {
    const parsedNote = parseJSON<{ NoteText?: string; text: string }>(str);
    if (parsedNote == null) {
      console.warn('parsedJSONNote: parsedNote is null');
      return '';
    }

    return parsedNote.NoteText ?? parsedNote.text ?? '';
  }, []);

  return (
    <PinnedNotesView
      pinnedNotes={pinnedNotes}
      handlePinNoteScroll={handlePinNoteScroll}
      hidePinnedNote={hidePinnedNote}
      parsedJSONNote={parsedJSONNote}
    />
  );
};

export default PinnedNotesController;
