import { FC } from 'react';

import { DevExpressGrid } from '../grid';
import type { ListViewPropsInterface } from './list.type';
import { Map } from '../map';

const ListView: FC<ListViewPropsInterface> = props => {
  const {
    quickEditRowCallback,
    onSelectCheckbox,
    quickEditButton,
    parentInfo,
    onRowClick,
    hasCreate,
    resource,
    metaData,
    setSort,
    hasEdit,
    fields,
    data,
    addToFilterRequestList,
    setFilters,
    hasShow = true,
  } = props;

  if (metaData?.defaultView == 'Map') {
    return (
      <Map metaData={metaData} resource={resource} data={data} isMultiTab={true} />
    );
  }

  return (
    <DevExpressGrid
      quickEditRowCallback={quickEditRowCallback}
      addToFilterRequestList={addToFilterRequestList}
      onSelectCheckbox={onSelectCheckbox}
      quickEditButton={quickEditButton}
      basePath={`/${resource}`}
      onRowClick={onRowClick}
      parentInfo={parentInfo}
      hasCreate={hasCreate}
      metaData={metaData}
      resource={resource}
      hasEdit={hasEdit}
      setSort={setSort}
      isReport={false}
      fields={fields}
      hasShow={hasShow}
      data={data}
      setFilters={setFilters}
    />
  );
};

export default ListView;
