import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  tablePaginationSmall: {
    color: 'rgba(0, 0, 0, 0.54)',
    height: 30,
    padding: 0,
    '& div': {
      height: 'auto',
      minHeight: 'auto',
    },
    '& button': {
      padding: 0,
    },
    '& p': {
      fontSize: 12,
    },
  },

  tablePaginationMedium: {
    overflow: 'unset',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    height: 40,
    '& div': {
      height: 'auto',
      minHeight: 'auto',
    },
    '& p': {
      fontSize: 12,
    },
  },
}));
