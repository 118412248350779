import { type ReactElement } from 'react';
import { useTranslate } from 'react-admin';

import { Map as MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet-gesture-handling';

import MarkerCluster from './leaflet-map-marker-cluster';
import LoadingBox from '../../LoadingBox';
import { useStyles } from './leaflet-map.style';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';

import type { LatLngExpression } from '../map.type';
import type { LeafletMapViewInterface } from './leaflet-map.type';
import './styles.css';
import LeafletMapLegend from './leaflet-map-legend';
import PolylineController from './leaflet-map-polyline';

const LeafletMapView = (props: LeafletMapViewInterface): ReactElement => {
  const {
    getMarkerIcon,
    mapCenter,
    zoom,
    polylinePositions,
    markerList,
    hasTracing,
    headerMetaData,
    clusterMode,
    mapKey,
  } = props;
  const classes = useStyles({ isFullScreen: props.isFullScreen });
  const translate = useTranslate();

  if (isEmptyObject(mapCenter)) return <LoadingBox />;

  return (
    <MapContainer
      scrollWheelZoom={true}
      className={classes.mapContainer}
      id="mapContent"
      gestureHandling={true}
      gestureHandlingOptions={{
        text: {
          scroll: translate('map.zoomMap'),
          touch: translate('map.zoomMap'),
          scrollMac: translate('map.zoomMap'),
        },
        duration: 1000,
      }}
      center={mapCenter as LatLngExpression}
      zoom={zoom}
      key={mapKey} //force render map when cluster mode changed
    >
      <TileLayer
        key="mapComponent"
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxNativeZoom={25}
        maxZoom={25}
      />

      <MarkerCluster
        getMarkerIcon={getMarkerIcon}
        markerList={markerList}
        mapCenter={mapCenter}
        clusterMode={clusterMode}
      />
      {!isEmpty(headerMetaData) && (
        <LeafletMapLegend headerMetaData={headerMetaData} />
      )}

      {hasTracing && <PolylineController polyLines={polylinePositions} />}
    </MapContainer>
  );
};

export default LeafletMapView;
