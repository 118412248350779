import domtoimage from 'dom-to-image';

export const findLocationOptions = {
  timeout: 30000, // call failure if couldn't find in this time
  enableHighAccuracy: true, // A boolean value that indicates the application would like to receive the best possible results
  maximumAge: 75000, // A positive long value indicating the maximum age in milliseconds of a possible cached position that is acceptable to return
};

/**
 * take screen shot of specific element in dom
 * @function getSnapShotOfElement
 * @param customMapId
 * @param {string} customMapId
 * @returns {Promise<string>}
 */
export const getSnapShotOfElement = async (customMapId: string): Promise<string> => {
  const node = document.getElementById(customMapId);
  return domtoimage.toPng(node);
};
