import { type FC } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import { useStyles } from './range-picker.style';
import { Locale } from '../../type/global-types';
import { RangePickerInput } from './range-picker-input';
import { UnitPicker } from './range-unit-picker';

import type { RangePickerViewProps } from './range-picker.type';
import type { Translate } from '../../helper/Types';

const RangePickerView: FC<RangePickerViewProps> = props => {
  const {
    onUnitSelect,
    onChangeFormMode,
    onInputChange,
    onApplyClick,
    formMode,
    fromDateInputRef,
    toDateInputRef,
    fromRangeInputRef,
    toRangeInputRef,
    defaultValue,
    errors,
  } = props;

  const {
    fromDateInput: defaultFromDateInputRef,
    toDateInput: defaultToDateInputRef,
    fromRangeInput: defaultFromRangeInputRef,
    toRangeInput: defaultToRangeInputRef,
    selectedNumbers: defaultSelectedNumbers,
    selectedUnits: defaultSelectedUnits,
  } = defaultValue ?? {};

  const translate: Translate = useTranslate();
  const locale: Locale = useLocale();

  const classes = useStyles({ isRtl: locale !== Locale.EN });

  return (
    <div className={classes.container}>
      <FormControl className={classes.FormControl} component="fieldset">
        <RadioGroup
          onChange={onChangeFormMode}
          className={classes.RadioGroup}
          value={formMode}
        >
          <div id="allPast_container" className={classes.row}>
            <FormControlLabel control={<Radio />} value="allPast" label="" />
            <Typography
              className={`${classes.label} ${
                formMode === 'allPast' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.to')} {translate('rangePickerJira.now')}
            </Typography>
          </div>

          <div id="lastPeriod_container" className={classes.row}>
            <FormControlLabel control={<Radio />} value="lastPeriod" label="" />
            <Typography
              className={`${classes.label} ${
                formMode === 'lastPeriod' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.from')}
            </Typography>
            <TextField
              disabled={formMode !== 'lastPeriod'}
              inputProps={{ style: { textAlign: 'center' } }}
              className={classes.numberInput}
              placeholder="15"
              defaultValue={defaultSelectedNumbers}
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={e => {
                e.preventDefault();
              }}
              onChange={onInputChange}
              name={'lastPeriod'}
              error={errors.lastPeriod}
            />
            <UnitPicker
              disabled={formMode !== 'lastPeriod'}
              onUnitSelect={onUnitSelect}
              defaultValue={defaultSelectedUnits}
            />
            <Typography
              className={`${classes.label} ${
                formMode === 'lastPeriod' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.past')} {translate('rangePickerJira.to')}{' '}
              {translate('rangePickerJira.now')}
            </Typography>
          </div>

          <div id="nextPeriod_container" className={classes.row}>
            <FormControlLabel control={<Radio />} value="nextPeriod" label="" />
            <Typography
              className={`${classes.label} ${
                formMode === 'nextPeriod' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.from')} {translate('rangePickerJira.now')}{' '}
              {translate('rangePickerJira.to')}
            </Typography>
            <TextField
              disabled={formMode !== 'nextPeriod'}
              inputProps={{ style: { textAlign: 'center' } }}
              className={classes.numberInput}
              placeholder="15"
              defaultValue={defaultSelectedNumbers}
              onKeyPress={e => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
              onPaste={e => {
                e.preventDefault();
              }}
              onChange={onInputChange}
              name={'nextPeriod'}
              error={errors.nextPeriod}
            />
            <UnitPicker
              disabled={formMode !== 'nextPeriod'}
              onUnitSelect={onUnitSelect}
              defaultValue={defaultSelectedUnits}
            />
            <Typography
              className={`${classes.label} ${
                formMode === 'nextPeriod' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.future')}
            </Typography>
          </div>

          <div id="between_container" className={classes.row}>
            <FormControlLabel control={<Radio />} value="between" label="" />
            <Typography
              className={`${classes.label} ${
                formMode === 'between' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.between')}
            </Typography>

            <RangePickerInput
              ref={fromDateInputRef}
              isActive={formMode === 'between'}
              placeholder="1401/05/12 12:30:40"
              showDatePicker
              defaultValue={defaultFromDateInputRef}
            />

            <Typography
              className={`${classes.label} ${
                formMode === 'between' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.to')}
            </Typography>

            <RangePickerInput
              ref={toDateInputRef}
              isActive={formMode === 'between'}
              placeholder="1401/05/12 15:12:21"
              showDatePicker
              defaultValue={defaultToDateInputRef}
            />
          </div>

          <div id="inRange_container" className={classes.row}>
            <FormControlLabel control={<Radio />} value="inRange" label="" />
            <Typography
              className={`${classes.label} ${
                formMode === 'inRange' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.range')}
            </Typography>

            <RangePickerInput
              ref={fromRangeInputRef}
              isActive={formMode === 'inRange'}
              placeholder="-4w 2d 4h"
              onlyRange
              defaultValue={defaultFromRangeInputRef}
            />

            <Typography
              className={`${classes.label} ${
                formMode === 'inRange' && classes.activeText
              }`}
            >
              {translate('rangePickerJira.to')}
            </Typography>

            <RangePickerInput
              ref={toRangeInputRef}
              isActive={formMode === 'inRange'}
              placeholder="3w 1d 5h"
              onlyRange
              defaultValue={defaultToRangeInputRef}
            />
          </div>
        </RadioGroup>
      </FormControl>

      <Button onClick={onApplyClick} className={classes.applyButton}>
        <Typography className={classes.applyButtonText}>
          {translate('rangePickerJira.applyRange')}
        </Typography>
      </Button>
    </div>
  );
};

export default RangePickerView;
