import { type FC, memo } from 'react';
import type { UserListInterface } from './user-list.type';
import UserListView from './user-list.view';

const UserListController: FC<UserListInterface> = memo(props => {
  const {
    data,
    selectedUser,
    loading,
    fetchMore,
    refreshFunction,
    isChatMode,
    onUserSelect,
    isUserInfoDialog,
  } = props;

  return (
    <UserListView
      data={data}
      fetchMore={fetchMore}
      refreshFunction={refreshFunction}
      selectedUser={selectedUser}
      loading={loading}
      isChatMode={isChatMode}
      onUserSelect={onUserSelect}
      isUserInfoDialog={isUserInfoDialog}
    />
  );
});

export default UserListController;
