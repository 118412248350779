import { AppContainer } from '../app-container';
import { MailSidebar } from '../mail-section/mail-sidebar';
import { TodoSidebar } from '../todo-section/todo-sidebar';
import { AutomationListPanel } from './automation-list-panel';

import type { FC } from 'react';

const AutomationView: FC = () => {
  return (
    <AppContainer
      cardStyle={{
        overflow: 'auto',
      }}
      sidebarContent={
        <>
          <MailSidebar />
          <TodoSidebar />
        </>
      }
    >
      <AutomationListPanel />
    </AppContainer>
  );
};

export default AutomationView;
