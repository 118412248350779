import { GET_REPORT, RUN_SERVICE } from '../core/data-Provider.helper';
import dataProvider from '../core/dataProvider';
import { objectToLowerCaseProperties } from '../helper/data-helper';
import { actorOnDispatch } from '../type/actor-setup';

actorOnDispatch(
  'getMailReport',
  async action => {
    const { params, successCallback, failureCallback } = action;

    try {
      const response = await dataProvider(GET_REPORT, '', params);
      successCallback({ data: response?.data ?? {}, total: response.totalCount });
    } catch (error) {
      failureCallback?.(error);
    }
  },
  {
    preserve: false,
  },
);

actorOnDispatch(
  'runMailService',
  async action => {
    const { params, successCallback, failureCallback } = action;
    try {
      const response = await dataProvider(RUN_SERVICE, '', params);
      const compatibleData = objectToLowerCaseProperties(response?.data);

      successCallback((compatibleData as Record<string, unknown>) ?? {});
    } catch (error) {
      failureCallback(error);
    }
  },
  {
    preserve: false,
  },
);
