import { FC, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import { initializeIcons } from '@uifabric/icons';
import 'devextreme/dist/css/dx.light.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

//RCT-4194 we should import UrlHelper,configProvider at the top of other files
import { getUrlSubdomain } from './helper/UrlHelper';
import {
  SUB_DOMAIN,
  setValue,
  USER_LOG_IN,
  USER_LOG_OUT,
} from './core/configProvider';
import dataProvider from './core/dataProvider';
import authProvider, { broadcastChannel } from './core/authProvider';
import i18nProvider from './core/i18n/i18nProvider';
import themeProvider, { AppLayoutDirection } from './core/themeProvider';
import customReducers from './redux/customReducers';
import customSagas from './redux/customSagas';
import customRoutes from './page/customRoutes';
import { AppLayout } from './component/app-layout';
import DashboardPage from './page/DashboardPage';
import LoginPage from './page/LoginPage';
import { NewMetaStore } from './container/NewMetaContext';
import { loadMessages } from 'devextreme/localization';
import faMessages from './core/devExpressTranslations/fa.json';
import ErrorBoundary from './core/ErrorBoundary';
import './api/settings-api';

/**
 * to load devExpress custom localization
 * @function initDevExpressMessages
 */
function initDevExpressMessages() {
  loadMessages(faMessages);
}

initializeIcons();
initDevExpressMessages();

const App: FC = () => {
  useEffect(() => {
    broadcastChannel.addEventListener('message', event => {
      const { type: eventType } = event.data;
      if (eventType === USER_LOG_IN) {
        const redirectUrl = sessionStorage.getItem('redirectUrl') ?? '';
        sessionStorage.removeItem('redirectUrl');

        if (redirectUrl.includes('login')) {
          location.href = location.origin + '#/';
        } else {
          location.href = location.origin + '#/' + redirectUrl;
          location.reload();
        }

        return;
      }

      if (eventType === USER_LOG_OUT) {
        location.href = location.origin + '#/login';
      }
    });

    setValue(SUB_DOMAIN, getUrlSubdomain());
  }, []);

  return (
    <ErrorBoundary>
      <AppLayoutDirection>
        <NewMetaStore>
          <Admin
            title="iMaster Web"
            dashboard={DashboardPage}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            theme={themeProvider}
            customReducers={customReducers}
            customSagas={customSagas}
            customRoutes={customRoutes}
            layout={AppLayout}
            loginPage={LoginPage}
            disableTelemetry
          >
            <Resource key="appcore/websetting" name="appcore/websetting" />
          </Admin>
        </NewMetaStore>
      </AppLayoutDirection>
    </ErrorBoundary>
  );
};

export default App;
