import { ReactElement } from 'react';
import { SplitButtonViewInterface } from './split-button.type';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const SplitButtonView = (props: SplitButtonViewInterface): ReactElement => {
  const {
    anchorRef,
    options,
    defaultIndex,
    handleClick,
    handleToggle,
    open,
    handleClose,
    handleMenuItemClick,
    className,
    buttonClassName,
    toggleButtonClassName,
    disableButton,
    ...rest
  } = props;

  return (
    <div {...rest}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        color="secondary"
        className={className}
        data-style-button-group="buttonGroup"
      >
        <Button
          style={{ color: '#fff' }}
          className={buttonClassName}
          onClick={handleClick}
          disabled={disableButton}
        >
          {!!options && options[defaultIndex]}
        </Button>
        <Button
          size="small"
          className={toggleButtonClassName}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label=""
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon style={{ color: '#fff' }} />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {!!options &&
                    options.map((option, index) => (
                      <MenuItem
                        id={`menuItem${index}`}
                        key={option}
                        selected={index === defaultIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default SplitButtonView;
