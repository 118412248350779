import { useTranslate, useLocale } from 'react-admin';
import { Button, DialogActions, DialogContent, TextField } from '@material-ui/core';

import { useStyles } from './create-group-dialog.style';
import { ChatAvatarInput } from '../../chat-avatar-input';

import type { ReactElement } from 'react';
import type { CreateGroupDialogViewPropsInterface } from './create-group-dialog.type';

const CreateGroupDialogView = (
  props: CreateGroupDialogViewPropsInterface,
): ReactElement => {
  const {
    handleOpenAddMembersDialog,
    successFileCallback,
    closeDialogHandler,
    handleChange,
    IsChannel,
    hasError,
    groupNameInputRef,
  } = props;

  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.dialogContentContainer}>
        <TextField
          error={hasError}
          className={classes.input}
          label={
            IsChannel ? translate('chat.channelName') : translate('chat.groupName')
          }
          onChange={handleChange}
          variant="standard"
          inputRef={groupNameInputRef}
        ></TextField>
        <ChatAvatarInput successFileCallback={successFileCallback} />
      </DialogContent>
      <DialogActions
        className={
          locale === 'en'
            ? classes.enDialogACtionsContainer
            : classes.dialogActionsContainer
        }
      >
        <Button onClick={closeDialogHandler}> {translate('confirm.cancel')} </Button>
        <Button onClick={handleOpenAddMembersDialog}>
          {translate('chat.next')}
        </Button>
      </DialogActions>
    </>
  );
};

export default CreateGroupDialogView;
