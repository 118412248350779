import { type FC, memo, useCallback, useEffect, useState, useRef } from 'react';
import { saveAs } from 'save-files';
import { useTranslate } from 'react-admin';

import MessageItemView from './message-item.view';
import { actorDispatch } from '../../../../type/actor-setup';
import { ConfirmDialog } from '../../../confirm-dialog';
import { chatStatelessActions } from '../../chat-section-helper/chat-section-stateless-actions';
import {
  ChatMessageTypeEnum,
  handleDeleteMessage,
  pinOrUnpinMessage,
} from '../../chat-section.helper';
import { isEmpty } from '../../../../helper/data-helper';
import { highlightMessageAndScrollTo } from './message-item.helper';
import { customDebounce } from '../../../../helper/general-function-helper';
import { replaceArabicCharacters } from '../../../../helper/TextHelper';
import { getMembersResourceId } from '../../chat-dialogs/add-members-dialog/add-members-dialog.helper';

import type { MessageItemPropsInterface } from './message-item.type';
import type {
  ChatItemInterface,
  MessageItemInterface,
} from '../../chat-section.type';
import type { ChatReportResponseInterface } from '../../../../api/chat-api';
import type { SelectedMessageInterface } from '../../new-message/new-message.type';
import type { ChatUsersInterface } from '../../chat-dialogs/add-members-dialog/add-members-dialog.type';
import { LightBoxDialogInterface } from '../../../dialogs-stack/light-box-dialog/light-box-dialog.type';
import { apiUrl } from '../../../../core/data-Provider.helper';

const MessageItemController: FC<MessageItemPropsInterface> = memo(
  props => {
    const {
      item,
      message,
      user,
      isSender,
      replyMessageText,
      isSeen,
      messageId,
      onScrollTop,
      moveToRepliedMessage,
    } = props;
    const translate = useTranslate();
    const [chatUsers, setChatUsers] = useState<ChatUsersInterface[]>([]);

    const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
      useState<boolean>(false);
    const [isForwardDialogOpen, setIsForwardDialogOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [isPinConfirmDialogOpen, setIsPinConfirmDialogOpen] =
      useState<boolean>(false);

    const allUsersRef = useRef<ChatUsersInterface[]>([]);

    const messageTypeRef = useRef<ChatMessageTypeEnum>(ChatMessageTypeEnum.TEXT);

    /**
     * @function onConfirmPinOrUnpinMessage
     * @returns {void} void
     */
    const onConfirmPinOrUnpinMessage = useCallback((): void => {
      handleClosePinConfirmDialog();
      pinOrUnpinMessage(item);
    }, [item]);

    const [contextMenu, setContextMenu] = useState<{
      mouseX: number;
      mouseY: number;
    } | null>(null);

    const isInGroup = !isEmpty(user.groupuid);

    /**
     * @function handleContextMenu
     * @param {  React.MouseEvent } event
     * @returns { void }
     */
    const handleContextMenu = (event: React.MouseEvent): void => {
      event.preventDefault();
      if (!contextMenu) {
        setContextMenu({
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        });
      } else {
        setContextMenu(null);
      }
    };

    useEffect(() => {
      isForwardDialogOpen && getMembers();
    }, [isForwardDialogOpen]);

    /**
     * @description get members for list of
     * @function getMembers
     * @returns { void }
     */
    const getMembers = (): void => {
      setLoading(true);
      actorDispatch('crudAction', {
        type: 'GET_LIST',
        resource: getMembersResourceId,
        requestParameters: {
          filter: [['membersonly', '=', 1]],
          pagination: {
            page: 1,
            perPage: 999999,
          },
        },
        onSuccess: (response: { data: ChatUsersInterface[] }) => {
          if (response.data) {
            allUsersRef.current = response.data;
            setChatUsers(response.data);
          }
          setLoading(false);
        },
      });
    };

    /**
     * @description this function will get the user and set in chats data in the selected message
     * @function onUserClick
     * @param { ChatUsersInterface } user
     * @returns { void }
     */
    const onUserClick = (user: ChatUsersInterface) => (): void => {
      const newChat: ChatItemInterface = {
        chatdate: 'now',
        chattext: '',
        ischannel: false,
        isgroupadmin: user.isadmin ? 1 : 0,
        isowner: user.isowner ? 1 : 0,
        personimage: user.personimage,
        personname: user.personname,
        personinfo_id: user.personinfo_id,
        sumnotseen: 0,
        mentionchatid: user.personinfo_id, // FIXME: What is and what the value it should have?
        frompersoninfoname: '',
      };

      actorDispatch(
        'onDemandSelectedChat',
        {
          info: newChat,
        },
        {
          replaceAll: true,
          callerScopeName: 'onUserClick',
        },
      );

      onForwardClick();
    };

    /**
     * @function handleCloseForwardMenu
     * @returns { void }
     */
    const handleCloseForwardMenu = (): void => {
      setIsForwardDialogOpen(false);
    };

    /**
     * @function handleCloseContextMenu
     * @returns { void }
     */
    const handleCloseContextMenu = (): void => {
      setContextMenu(null);
    };

    /**
     * to reply a message
     * @function onReplyMessage
     * @param { MessageItemInterface } message
     * @returns { void }
     */
    const onReplyMessage = useCallback((message: MessageItemInterface): void => {
      actorDispatch('selectedMessage', {
        data: message,
        mode: 'reply',
      });
    }, []);

    /**
     * to pin a message
     * @function onPinMessage
     * @param { MessageItemInterface } message
     * @returns { void }
     */
    const onPinMessage = useCallback((): void => {
      handleCloseContextMenu();
      setIsPinConfirmDialogOpen(true);
    }, []);

    /**
     * @function onReplyClick
     * @returns { void }
     */
    const onReplyClick = useCallback((): void => {
      handleCloseContextMenu();
      onReplyMessage(item);
    }, [item]);

    /**
     * @function successMoveToRepliedMessageCallback
     * @param { MessagesDataInterface } response
     * @returns { void }
     */
    const successMoveToRepliedMessageCallback = (
      response: ChatReportResponseInterface<MessageItemInterface>,
    ): void => {
      const { data } = response;
      if (data.length === 0) return;
      actorDispatch('loading', { messagesLoading: false });

      // to highlight the replied message
      const toBeHighlightedMessageId =
        response?.data?.[response?.data?.length - 1]?.chat_id;
      toBeHighlightedMessageId &&
        highlightMessageAndScrollTo(toBeHighlightedMessageId);

      if (response?.data?.length <= 10) {
        chatStatelessActions.fetchMoreMessages({
          olderMessages: true,
          countToGet: 20,
        });
      } else {
        setTimeout(() => {
          onScrollTop();

          toBeHighlightedMessageId &&
            highlightMessageAndScrollTo(toBeHighlightedMessageId);
        }, 500);
      }
    };

    /**
     * @function onMoveToRepliedMessage
     * @returns { void }
     */
    const onMoveToRepliedMessage = useCallback((): void => {
      if (!item.replyofchat_id) return;
      moveToRepliedMessage(item.replyofchat_id);
    }, []);

    /**
     * @function handleOpenDeleteConfirmDialog
     * @returns { void }
     */
    const handleOpenDeleteConfirmDialog = (): void => {
      handleCloseContextMenu();
      setIsDeleteConfirmDialogOpen(true);
    };

    /**
     * @function handleCloseDeleteConfirmDialog
     * @returns { void }
     */
    const handleCloseDeleteConfirmDialog = (): void => {
      setIsDeleteConfirmDialogOpen(false);
    };

    /**
     * @function handleClosePinConfirmDialog
     * @returns { void }
     */
    const handleClosePinConfirmDialog = (): void => {
      setIsPinConfirmDialogOpen(false);
    };

    /**
     * @function successDeleteMessageCallback
     * @returns { void }
     */
    const successDeleteMessageCallback = (): void => {
      handleDeleteMessage([item]);
      handleCloseDeleteConfirmDialog();
    };

    /**
     * @function onConfirmDeleteMessage
     * @returns { void }
     */
    const onConfirmDeleteMessage = (): void => {
      chatStatelessActions.deleteMessage({
        successCallback: successDeleteMessageCallback,
        _params: {
          OtherPersonInfo_ID: isSender
            ? item.topersoninfo_id
            : item.frompersoninfo_id,
          ChatID: item.chat_id,
        },
      });
    };

    /**
     * @function onEditClick
     * @returns { void }
     */
    const onEditClick = (): void => {
      handleCloseContextMenu();
      actorDispatch('selectedMessage', {
        data: item,
        mode: 'edit',
      });
    };

    /**
     * @function onForwardClick
     * @returns { void }
     */
    const onForwardClick = useCallback((): void => {
      actorDispatch('selectedMessage', {
        data: item,
        mode: 'forward',
      } as SelectedMessageInterface);
    }, [item]);

    /**
     * @function handleForward
     * @returns { void }
     */
    const handleForward = (): void => {
      setIsForwardDialogOpen(true);
    };

    /**
     * open message info dialog
     * @function onMessageInfoClick
     * @returns {void} void
     */
    const onMessageInfoClick = useCallback((): void => {
      actorDispatch('quickDialog', {
        messageInfoDialogIsOpen: true,
        data: {
          chatID: item.chat_id,
          personInfoID: item.frompersoninfo_id,
          isInGroup,
        },
      });
    }, [item, isInGroup]);

    /**
     * Finds users by searching `value`
     * @function handleSearch
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @returns {void} void
     */
    const handleSearch = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        if (isEmpty(value)) {
          setChatUsers(allUsersRef.current);
          return;
        }

        const filteredUsers = allUsersRef.current.filter(user => {
          return replaceArabicCharacters(user.personname ?? user.fullname).includes(
            replaceArabicCharacters(value),
          );
        });

        setChatUsers(filteredUsers);
      },
      [user],
    );

    /**
     * @function downloadFile
     * @returns {void}
     */
    const downloadFile = useCallback((): void => {
      const fileInfo = JSON.parse(item.fileurl ?? '') ?? {};
      saveAs(`${apiUrl}/${fileInfo.filePath}`, fileInfo.realFileName, {
        autoBom: false,
        cors: false,
      });
    }, [item]);

    /**
     * set images on refs and open light box
     * @function onImageClick
     * @param {string[]} allImages
     * @param {number} selectedImageIndex
     * @returns {void} void
     */
    const onImageClick = useCallback(
      (allImages: string[], selectedImageIndex: number): void => {
        actorDispatch('quickDialog', {
          lightBoxPreviewIsOpen: true,
          data: {
            imageData: allImages.map(url => ({ url })),
            startIndex: selectedImageIndex,
            onDownloadClick: downloadFile,
          } as LightBoxDialogInterface,
        });
      },
      [],
    );

    /**
     * @function debouncedSearch
     */
    const debouncedSearch = useCallback(customDebounce(handleSearch, 500), []);

    return (
      <>
        <MessageItemView
          item={item}
          handleForward={handleForward}
          user={user}
          message={message}
          messageId={messageId}
          isSeen={isSeen}
          replyMessageText={replyMessageText}
          isSender={isSender}
          onReplyMessage={onReplyMessage}
          onReplyClick={onReplyClick}
          contextMenu={contextMenu}
          handleCloseContextMenu={handleCloseContextMenu}
          handleContextMenu={handleContextMenu}
          onMoveToRepliedMessage={onMoveToRepliedMessage}
          handleOpenDeleteConfirmDialog={handleOpenDeleteConfirmDialog}
          onEditClick={onEditClick}
          isForwardDialogOpen={isForwardDialogOpen}
          setForwardMember={setIsForwardDialogOpen}
          onUserClick={onUserClick}
          closeDialogHandler={handleCloseForwardMenu}
          chatUsers={chatUsers}
          loading={loading}
          onImageClick={onImageClick}
          onMessageInfoClick={onMessageInfoClick}
          isInGroup={!!isInGroup}
          messageTypeRef={messageTypeRef}
          onPinMessage={onPinMessage}
          handleSearch={debouncedSearch}
          downloadFile={downloadFile}
        />
        {isDeleteConfirmDialogOpen && (
          <ConfirmDialog
            open={isDeleteConfirmDialogOpen}
            handleClose={handleCloseDeleteConfirmDialog}
            title={translate('chat.deleteConfirmMessage')}
            content=""
            onConfirm={onConfirmDeleteMessage}
          />
        )}
        {isPinConfirmDialogOpen && (
          <ConfirmDialog
            open={isPinConfirmDialogOpen}
            handleClose={handleClosePinConfirmDialog}
            title={translate('ra.message.are_you_sure')}
            content=""
            onConfirm={onConfirmPinOrUnpinMessage}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.message === nextProps.message &&
      prevProps.replyMessageText === nextProps.replyMessageText &&
      prevProps.messageId === nextProps.messageId &&
      prevProps.isSeen === nextProps.isSeen
    );
  },
);

export default MessageItemController;
