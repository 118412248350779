import { FC, memo, useEffect, useState } from 'react';
import { actorDispatch, actorOnDispatch } from '../../../type/actor-setup';
import TodoActionSideBarView from './task-action-side-bar.view';

import { RUN_SERVICE } from '../../../core/data-Provider.helper';
import { showNotification } from '../../../helper/general-function-helper';
import { deleteTaskServiceId } from '../todo-section-static-data.helper';

import type { TodoTaskItemInterface } from '../todo-tasks/todo-task-item/todo-task-item.type';
import type { ValidationErrorMessageType } from '../../../helper/Types';

const TodoActionSideBarController: FC = memo(() => {
  const [todoSelectedTaskInfo, setTodoSelectedTaskInfo] =
    useState<TodoTaskItemInterface | null>(null);

  useEffect(() => {
    actorOnDispatch('selectedTodoTask', selectedTask => {
      setTodoSelectedTaskInfo(selectedTask ?? null);
    });
    //fixme: use effect with on dispatch should not have dependency
  }, [todoSelectedTaskInfo]);

  const isOpen = !!todoSelectedTaskInfo;

  /**
   * close side bar todo
   * @function handleCloseSideBar
   * @returns {void} void
   */
  const handleCloseSideBar = (): void => {
    actorDispatch('selectedTodoTask', '', { replaceAll: true });
  };

  const createDate = todoSelectedTaskInfo?.createdate ?? '';

  /**
   * @function handleDeleteTask
   * @returns {void} void
   */
  const handleDeleteTask = (): void => {
    actorDispatch(
      'crudAction',
      {
        type: RUN_SERVICE,
        entity: 'todo_delete',
        actionUniqueId: deleteTaskServiceId,
        data: {
          params: {
            TaskUID: todoSelectedTaskInfo?.uniqueid,
          },
        },
        onSuccess: (response: {
          userMessage: string;
          messageType: ValidationErrorMessageType;
        }): void => {
          showNotification(response.userMessage, response.messageType);
          actorDispatch('refreshView', 'todo');
          actorDispatch('selectedTodoTask', '', { replaceAll: true });
        },
        onFailure: error => {
          if (typeof error === 'string') {
            showNotification(error, 'error');
          }
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'TodoActionSideBarController => handleDeleteTask',
      },
    );
  };

  return (
    <TodoActionSideBarView
      isOpen={isOpen}
      handleCloseSideBar={handleCloseSideBar}
      createDate={createDate}
      todoSelectedTaskInfo={todoSelectedTaskInfo}
      handleDeleteTask={handleDeleteTask}
    />
  );
});
export default TodoActionSideBarController;
