import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    height: 'calc(100% - 70px)', //bottom action bar
    overflow: 'auto',
    '& fieldset': {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 1rem',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        flexWrap: 'noWrap',
      },
    },
  },

  inputContainer: {
    flexGrow: 1,
    maxHeight: '40px',
    width: 500,
    margin: '1rem 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
