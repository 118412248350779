import type { FC } from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, IconButton, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useStyles } from './sort-picker.style';

import type { SortPickerViewInterface } from './sort-picker.type';

const SortPickerView: FC<SortPickerViewInterface> = props => {
  const { sortReportData, onDashboardSortDataChange, menuItems } = props;
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.horizontalFormControl} fullWidth>
        <Typography>{translate('gadget.sortReportData')}</Typography>
        <Checkbox
          checked={Boolean(sortReportData?.isSortEnabled)}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          onChange={onDashboardSortDataChange('isSortEnabled')}
          name="isSortEnabled"
        />
      </FormControl>

      {Boolean(sortReportData?.isSortEnabled) && (
        <div className={classes.sortContainer}>
          <FormControl fullWidth className={classes.formControl}>
            <Autocomplete
              onChange={onDashboardSortDataChange('sortField')}
              options={menuItems}
              renderInput={params => {
                return (
                  <TextField {...params} label={translate('gadget.baseOfField')} />
                );
              }}
              value={
                menuItems.find(
                  column => column.name === sortReportData?.sortField,
                ) ?? null
              }
              getOptionLabel={option => option.title}
            />
          </FormControl>

          <div className={classes.sortOrderContainer}>
            <IconButton onClick={onDashboardSortDataChange('sortOrder')}>
              {sortReportData.sortOrder === 'asc' ? (
                <ArrowUpwardIcon fontSize="small" />
              ) : (
                <ArrowDownwardIcon fontSize="small" />
              )}
            </IconButton>

            <Typography>
              {sortReportData.sortOrder === 'asc'
                ? translate('gadget.ascending')
                : translate('gadget.descending')}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default SortPickerView;
