import { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import lodashGet from 'lodash/get';

import { getBase64File } from '../../helper/FileHelper';
import { showImageDialog } from '../list';

interface FileFieldType {
  record: object;
  source: string;
  field: {
    rowSpan: null | number;
    name: string;
    relatedName: string;
  };
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const useStyles = makeStyles(() => {});

const FileField = (props: FileFieldType) => {
  const { record, source, field } = props;

  const [isImage, setIsImage] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>('');

  const rowSpan = lodashGet(field, 'rowSpan') || 1; // Don't set `1` to the third `lodashGet` parameter.
  const calcHeight = `${rowSpan * 20}px`;

  const classes: { [x: string]: string } = useStyles(props);
  const url: string = lodashGet(record, lodashGet(field, 'relatedName'));
  const fileName: string = lodashGet(record, lodashGet(field, 'name'));

  /**
   * Get File from API and set data to specified variables
   * @function getFile
   * @return {void}
   */
  const getFile = async () => {
    const { src, isImage } = await getBase64File({ link: url });

    setIsImage(isImage);
    setImageSrc(src);
  };

  /**
   * Open `showImageDialogContainer` with specified data
   * @function openDialog
   * @returns {void}
   */
  const openDialog = () => {
    showImageDialog({
      url,
      isGetFileMode: true,
      fileName,
    });
  };

  useEffect(() => {
    if (!record || !url) {
      return;
    }
    getFile();
  }, [record]);

  if (!record || !record[source]) {
    return <div data-test-date-field-name="source" />;
  }

  return (
    <div className={(classes.fieldValue, classes.fieldLink)} onClick={openDialog}>
      {isImage ? (
        <img
          data-test-has-image
          src={imageSrc}
          style={{ height: calcHeight, cursor: 'pointer' }}
        />
      ) : (
        <span data-test-file-name={fileName}>{fileName}</span>
      )}
    </div>
  );
};

export default FileField;
