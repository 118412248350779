import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  noteTodoMain: { background: theme.palette.primary.appSecondaryBackgroundColor },
  noteItemInSidebar: {
    flexDirection: 'column',
    overflowY: 'auto',
    display: 'flex',
    wordBreak: 'break-word',
    padding: 5,
    marginBottom: 30,
    background: theme.palette.primary.appSecondaryBackgroundColor,
  },
}));
