import { useTranslate } from 'react-admin';

import { getRangeUnits } from '../range-picker.helper';
import RangeUnitPickerView from './range-unit-picker.view';

import type { ChangeEvent, FC } from 'react';
import type { RangeUnit } from '../range-picker.type';
import type { RangeUnitPickerControllerProps } from './range-unit-picker.type';

const RangeUnitPickerController: FC<RangeUnitPickerControllerProps> = props => {
  const { onUnitSelect, disabled, defaultValue = 'day' } = props;

  const translate = useTranslate();
  const rangeUnits = getRangeUnits(translate);

  /**
   * set local state
   * @function onUnitChange
   * @param {ChangeEvent} event
   * @returns {void}
   */
  const onUnitChange = (
    event: ChangeEvent<{
      value: unknown;
    }>,
  ): void => {
    const value = event.target.value as RangeUnit;
    onUnitSelect(value);
  };

  return (
    <RangeUnitPickerView
      onChange={onUnitChange}
      defaultValue={defaultValue}
      disabled={disabled}
      rangeUnits={rangeUnits}
    />
  );
};

export default RangeUnitPickerController;
