import { type FC } from 'react';
import { Button, DialogActions, useTheme } from '@material-ui/core';
import { Tabs } from 'antd';

import MenuPermissions from './permission-tabs/menu-permissions';
import { useStyles } from './show-permissions.style';
import FilterForm from '../../component/filter-form/index';
import DynamicPermissionData from './permission-tabs/dynamic-permission-data';
import { SimpleGridColumn } from './simple-grid';
import {
  customSelectedFilterFields,
  customSelectedFilterFieldsForAdvancedPermissions,
  permissionsTab,
} from './show-permissions.helper';
import { useTranslate } from 'react-admin';

import type {
  ShowPermissionsViewProps,
  PermissionTabId,
} from './show-permissions.type';
import { Conditions } from '../condition/condition';

const { TabPane } = Tabs;

const ShowPermissionsView: FC<ShowPermissionsViewProps> = props => {
  const {
    handleTabChange,
    rootResource,
    isAdvanced,
    removeAdvancePermission,
    openAdvancePermissions,
    selectItemsForAddPermissionsAdvance,
    selectItems,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      <div className={classes.filterContainer}>
        <FilterForm
          resource={rootResource}
          selectedFilterFields={
            isAdvanced
              ? customSelectedFilterFieldsForAdvancedPermissions
              : customSelectedFilterFields
          }
          className={classes.filter}
        />

        <Tabs
          defaultActiveKey={'0'}
          direction={theme.direction}
          className={classes.tabParent}
          data-style-tab-relation="tabsRelation"
          onChange={handleTabChange}
        >
          <>
            {permissionsTab.map((tab, index) => {
              return (
                <TabPane
                  data-style-tab-item="tabItem"
                  key={index}
                  tab={<p>{tab.title}</p>}
                >
                  {tab.viewType === 'tree' ? (
                    <>
                      <MenuPermissions
                        tabId={tab.id}
                        isAdvanced={isAdvanced}
                        selectItems={selectItems}
                      />
                    </>
                  ) : (
                    <>
                      <DynamicPermissionData
                        tabId={tab.id as PermissionTabId}
                        columns={tab.columns as SimpleGridColumn[]}
                        isAdvanced={isAdvanced}
                        selectItems={selectItems}
                      />
                    </>
                  )}
                </TabPane>
              );
            })}
          </>
        </Tabs>
      </div>
      <div className={classes.actionPermissions}>
        <Conditions condition={isAdvanced}>
          <div className={classes.styleButtonAdvancePermission}>
            <span className={classes.advanceText}>
              {translate('permissions.advance')} :
              {selectItemsForAddPermissionsAdvance}
            </span>
            <Button
              style={{ marginLeft: 5, marginRight: 5 }}
              color="primary"
              variant="contained"
              onClick={openAdvancePermissions}
            >
              {translate('permissions.applyPermission')}
            </Button>
            <Button variant="outlined" onClick={removeAdvancePermission}>
              {translate('permissions.removePermission')}
            </Button>
          </div>
        </Conditions>
      </div>
    </div>
  );
};

export default ShowPermissionsView;
