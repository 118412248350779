import {
  type SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import lodashGet from 'lodash/get';
import lodashDebounce from 'lodash/debounce';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslate } from 'react-admin';
import { Dropdown } from 'semantic-ui-react';

import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import {
  getLabelForDropdownOption,
  triggerDropdownFetchData,
} from '../../helper/DropdownHelper';
import { isEmpty } from '../../helper/data-helper';
import { CustomTheme } from '../../core/themeProvider';
import { dummyFunc } from '../../helper/InputHelper';
import { actorOnDispatch, actorRemoveAction } from '../../type/actor-setup';
import { formatFilterFormData } from '../filter-form/filter-form.helper';
import { FilterFormFieldInterface } from '../../type/actor-setup';
import { DropdownOption } from './autocomplete-filter-input';
import { SearchPopupButton } from '../search-popup-button';

type OnChangeType = (
  event: SyntheticEvent<HTMLElement>,
  data: {
    value?: any;
  },
) => void;

type OnSearchChangeType = (
  event: SyntheticEvent<HTMLElement>,
  data: {
    searchQuery: string;
  },
) => void;

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    height: 20,

    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    '& .ui.fluid.dropdown': {
      border: 'none',
      borderRadius: 0,
      background: 'none',
      padding: '2px 0',
      minHeight: 'auto',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flexBasis: '100%',
      height: 20,
    },

    '& .ui.selection.active.dropdown:hover': {
      border: 'none',
      boxShadow: 'none',
    },

    '& input': {
      fontFamily: theme.typography.fontFamily,
      padding: '2px 0 !important',
    },

    '& .dropdown.icon': {
      color: theme.palette.primary.appPrimaryDisableIconColor,
      padding: '2px 5px !important',
    },

    '& .ui.loading.selection.dropdown>i.icon': {
      padding: '8px 10px !important',
    },

    '& .ui.dropdown .menu': {
      top: '133%',
      [theme.breakpoints.down('sm')]: {
        top: '112%',
      },
    },

    '& .ui.selection.active.dropdown .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.active.dropdown:hover .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.dropdown .menu>.item': {
      fontSize: 13,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.search.dropdown>.text': {
      padding: '2px 0',
      overflow: 'hidden',
      fontSize: 13,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexBasis: '90%',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.selection.dropdown .menu': {
      width: 'max-content',
      minWidth: 'auto',
    },

    '& .ui.upward.dropdown>.menu': {
      bottom: 'auto',
    },

    '& .ui.fluid.dropdown>.dropdown.icon': {
      float: 'none',
    },
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legendError: {
    color: theme.palette.error.main,
  },

  dropdownContainer: {
    display: 'flex',
    height: 17,
    alignItems: 'center',
    '& span': {
      cursor: 'pointer',
    },

    margin: 0,
    justifyContent: 'space-between',
    flexBasis: '100%',

    position: 'absolute',
    width: '100%',
  },

  errorText: {
    position: 'absolute',
    right: 90,
    top: 10,
    width: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 8,
    [theme.breakpoints.down('lg')]: {
      right: 18,
    },
    [theme.breakpoints.down('sm')]: {
      top: 3,
    },
  },

  label: {
    fontSize: 13,
    color: theme.palette.primary.appSecondaryTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  dropdownInPuzzleForm: {
    margin: '7px 3px 0',
  },
}));

const DropdownInput = props => {
  const { dropdownMeta, field, formData, disabled, value, onChange, filterData } =
    props;

  //prettier-ignore
  const { valueMember, filterValueMember, displayMember, displayMember2 } = dropdownMeta;

  const translate = useTranslate();
  const classes = useStyles(props);

  const [dropdownData, setDropdownData] = useState<Record<string, unknown>[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<Record<
    string,
    unknown
  > | null>(null);

  const searchStringRef = useRef('');
  const buttonRefDialog = useRef<HTMLSpanElement>();

  /**
   * We set the raw value as a label as default,
   * If there is a `selectedDropdownItem`, the label will set by a correct value
   */
  let selectedOptionLabel = value ?? '';
  let dropRawValue = '';
  if (selectedDropdownItem) {
    // prettier-ignore
    selectedOptionLabel = selectedDropdownItem[displayMember] as string;
    if (!isEmpty(selectedDropdownItem[displayMember2])) {
      selectedOptionLabel += ` - ${selectedDropdownItem[displayMember2]}`;
    }
    dropRawValue = selectedDropdownItem[valueMember] as string;
  }

  useEffect(() => {
    const onDispatchId = actorOnDispatch('dropDownData', dropdownData => {
      const { DATA } = dropdownData?.[`${dropdownMeta.uniqueId}-${field.id}`] ?? {};

      setDropdownData(DATA ?? []);
    });

    if (isEmpty(value)) {
      setSelectedDropdownItem(null);
    }

    return () => {
      actorRemoveAction({
        actionName: 'dropDownData',
        listenerId: onDispatchId,
      });
    };
  }, []);

  useEffect(() => {
    setIsLoading(false);

    //prettier-ignore
    const stringValue = value?.[field.dropdown?.filterValueMember as string] ?? value;
    changeHandler(String(stringValue));
  }, [dropdownData]);

  useEffect(() => {
    //prettier-ignore
    const stringValue = value?.[field.dropdown?.filterValueMember as string] ?? value;
    if (stringValue == null) return;

    const targetItem = dropdownData.find(
      // prettier-ignore
      dropdownItem => dropdownItem[valueMember] == stringValue || dropdownItem[filterValueMember] == stringValue,
    );
    if (targetItem == null) {
      searchStringRef.current = stringValue;

      fetchDropdownData();
    } else {
      changeHandler(String(stringValue), targetItem);
    }
  }, [value]);

  /**
   * @function fetchDropdownData
   * @returns {void} void
   */
  const fetchDropdownData = useCallback((): void => {
    setIsLoading(true);

    triggerDropdownFetchData(
      { ...props, formData: formatFilterFormData(filterData) },
      searchStringRef.current,
      {
        failureCallback: () => {
          setIsLoading(false);
        },
        isFilter: true,
      },
    );
  }, [field, filterData]);

  const handleFocus = useCallback(
    (event: SyntheticEvent) => {
      props.onFocus(event);
      searchStringRef.current = '';
      fetchDropdownData();
    },
    [props.onFocus, field, filterData],
  );

  const changeHandler = useCallback(
    (value: string, targetItemBasedOnValue?: Record<string, unknown>) => {
      if (isEmpty(value)) {
        setSelectedDropdownItem(null);
        onChange(null);
        return;
      }

      const formattedValue = convertDigitsToEnglish(value);

      let selectedItem = targetItemBasedOnValue;
      if (selectedItem == null) {
        /**
         * Some `settings` has been saved by `valueMember`
         * To support those filters we have to check `dropdownItem[valueMember] == formattedValue` too
         */
        selectedItem = dropdownData.find(
          // prettier-ignore
          dropdownItem => dropdownItem[valueMember] == formattedValue || dropdownItem[filterValueMember] == formattedValue,
        );
      }

      if (selectedItem) {
        setSelectedDropdownItem(selectedItem);
        onChange(selectedItem);
      }
    },
    [dropdownData],
  );

  const handleChange: OnChangeType = useCallback(
    (_, { value }) => {
      changeHandler(value);
    },
    [dropdownData],
  );

  const handleSearchChange: OnSearchChangeType = useCallback(
    lodashDebounce((ـ, { searchQuery }) => {
      searchStringRef.current = searchQuery;
      fetchDropdownData();
    }, 500),
    [props.formData, dropRawValue],
  );

  const getSelectRef = dropManager => {
    const parentGetRef = lodashGet(props, 'options.inputRef');
    if (
      !dropManager ||
      !parentGetRef ||
      !lodashGet(dropManager, 'searchRef.current')
    ) {
      return;
    }

    parentGetRef(lodashGet(dropManager, 'searchRef.current'));
  };

  const onKeyDown = lodashGet(props, 'options.onKeyDown');
  const searchInputParams = useMemo(
    () => ({
      onKeyDown,
    }),
    [onKeyDown],
  );

  /**
   * @function handleClickSearchDialog
   * @param {React.MouseEvent} event
   * @return {void}
   */
  const handleClickSearchDialog = useCallback(
    (event): void => {
      event.stopPropagation();
      if (disabled) return;

      if (buttonRefDialog.current) {
        buttonRefDialog.current.click();
        buttonRefDialog.current.style.display = 'none';
      }
    },
    [disabled],
  );

  /**
   * @function gridRowClickHandler
   * @returns {void} void
   */
  const gridRowClickHandler = useCallback(
    (newValue: Record<string, unknown>): void => {
      changeHandler(newValue[filterValueMember] as string, newValue);
    },
    [filterValueMember],
  );

  const preparedOptions = useMemo(() => {
    return dropdownData.map((item, index) => ({
      text: getLabelForDropdownOption(dropdownMeta, item),
      value: item[filterValueMember] + '',
      key: item[valueMember] + '' + index,
    }));
  }, [dropdownData]);

  return (
    <div className={classes.root} data-test-field-name={field.name}>
      <div className={classes.dropdownContainer}>
        <Dropdown
          data-test-dropdown-search-input
          fluid
          selection
          clearable
          closeOnBlur
          selectOnNavigation={false}
          ref={getSelectRef}
          noResultsMessage={translate('ra.navigation.no_results')}
          text={selectedOptionLabel}
          loading={isLoading}
          open={undefined}
          value={dropRawValue}
          options={isLoading ? [] : preparedOptions}
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          selectOnBlur={false}
          onFocus={handleFocus}
          searchInput={searchInputParams}
          search={preparedOptions => preparedOptions}
          disabled={disabled}
        />
        {!disabled && (
          <SearchPopupButton
            clickHandler={handleClickSearchDialog}
            resource={`${dropdownMeta.moduleName}/${dropdownMeta.tableName}`}
            parentResource={''}
            source={field.name}
            dropdownMeta={dropdownMeta}
            relationResource={''}
            relationSource={''}
            relationInfo={{}}
            disabled={disabled}
            changeFormValueByClickingOnRow={gridRowClickHandler}
            formData={formData ?? {}}
            fieldName={field.name}
            fieldId={field.id}
            parentResourceType={''}
            label="label"
            field={field}
            buttonRef={buttonRefDialog}
            dropBasePreparedOptions={preparedOptions}
            dropBaseValue={[]}
            // buttonCustomStyle={{ display: 'none' }}
            isFilter
          />
        )}
      </div>
    </div>
  );
};

DropdownInput.defaultProps = {
  onFocus: dummyFunc,
  onBlur: dummyFunc,
  onChange: dummyFunc,
  customChangeHandler: dummyFunc,
  options: {},
  step: 'any',
  textAlign: 'right',
};

export default DropdownInput;
