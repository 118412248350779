import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './chat-sidebar.style';
import { SearchList } from './search-list';
import { isEmpty } from '../../../helper/data-helper';
import { SideBarSettings } from './sidebar-settings';
import { UserList } from './user-list';

import type { ChatSidebarViewInterface } from './chat-sidebar.type';
import type { ContactInterface } from '../chat-section.type';
import LoadingBox from '../../LoadingBox';
import { IconButton } from '@material-ui/core';

const ChatSidebarView = (props: ChatSidebarViewInterface): ReactElement => {
  const {
    chatsData,
    chatsLoading,
    fetchMoreChats,
    refreshChats,
    onChatSelect,
    selectedUser,
    searchValue,
    onSearch,
    foundedContacts,
    foundedMessages,
    clearSearchValue,
    searchInputRef,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.chatContent} id="boxChat">
      <Box className={classes.sidebarHeader} mr={2} px={3} mb={1} width="100%">
        <SideBarSettings />
        <TextField
          autoFocus={true}
          variant="outlined"
          className={classes.chatSearch}
          placeholder={translate('chat.search')}
          value={searchValue}
          inputRef={searchInputRef}
          InputProps={{
            classes: {
              input: classes.input,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: !isEmpty(searchValue) ? (
              <InputAdornment position="end">
                <IconButton
                  className={classes.clearIcon}
                  onClick={clearSearchValue}
                  disableRipple
                  disableFocusRipple
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={onSearch}
          id="inputChat"
        />
      </Box>
      {chatsLoading ? (
        <LoadingBox />
      ) : !isEmpty(searchValue) ? (
        <SearchList
          contacts={foundedContacts}
          messages={foundedMessages}
          onChatSelect={onChatSelect}
          selectedUser={selectedUser as ContactInterface}
          searchValue={searchValue}
        />
      ) : (
        <Box mt={2} width={1}>
          <UserList
            data={chatsData}
            selectedUser={selectedUser}
            loading={chatsLoading}
            fetchMore={fetchMoreChats}
            refreshFunction={refreshChats}
            onUserSelect={onChatSelect}
            isChatMode
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatSidebarView;
