import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { Field } from 'react-final-form';

import { clone, mergeAndClone } from '../helper/data-helper';
import { getFilterColumns } from '../helper/MetaHelper';
import Filter from './Filter';
import DynamicFilterInput from './filter/DynamicFilterInput';

const ListFilter = props => {
  const { metaData, resource, locale, ...rest } = props;

  const fieldList = getFilterColumns(clone(metaData));

  fieldList.map(field => {
    const clonedField = mergeAndClone(field, {
      disabled: false,
      readonly: false,
    });

    const params = {
      key: clonedField.id,
      source: clonedField.name,
      name: clonedField.name,
      field: clonedField,
      label: lodashGet(
        clonedField,
        ['translatedCaption', locale],
        clonedField.caption,
      ),
      //alwaysOn: permanentFilterNameList.indexOf(field.name) !== -1,
      defaultOperator: clonedField.defaultOperator,
      onlyEqualCondition: clonedField.onlyEqualCondition,
    };

    field.params = params;
  });

  const sortByLabel = fieldList.sort((a, b) =>
    a.params.label.localeCompare(b.params.label),
  );

  return (
    <Filter {...rest} resource={resource}>
      {sortByLabel.map(field => {
        return (
          <Field
            {...field.params}
            parse={value => value}
            component={DynamicFilterInput}
          />
        );
      })}
    </Filter>
  );
};

ListFilter.propTypes = {
  resource: PropTypes.string,
  locale: PropTypes.string,
  metaData: PropTypes.object,
};

export default ListFilter;
