import { type FC, useEffect, useState } from 'react';
import { GET_LIST } from 'react-admin';

import {
  actorDispatch,
  actorOnDispatch,
  actorSetActionValue,
} from '../../../type/actor-setup';
import PermissionMenuView from './permission-menu.view';
import { prepareMenu } from '../../menu/sidebar-menu/sidebar-menu.helper';
import { prepareCompatibleTreeDataForDevExpressTree } from './permission-menu.helper';
import { MENUS_REPORT_ID } from '../permissions.helper';

import type {
  PermissionsMenuItem,
  PermissionMenuControllerProps,
} from './permission-menu.type';
import type { MenuItemParams } from '../../menu/sidebar-menu';

const PermissionMenuController: FC<PermissionMenuControllerProps> = props => {
  const { onRowClick, data, isAdvanced, handleClickPermissionAdvance, selectItems } =
    props;
  const [treeMenuItems, setTreeMenuItems] = useState<Array<PermissionsMenuItem>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      const CompatibleTree = prepareCompatibleTreeDataForDevExpressTree(
        data,
        selectItems,
      );
      setIsLoading(false);
      setTreeMenuItems(CompatibleTree);
      return;
    }

    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        resource: `report/${MENUS_REPORT_ID}`,
        requestParameters: {
          pagination: { page: 1, perPage: 999999 },
        },
        onSuccess: (response: { data: MenuItemParams[] }): void => {
          const menuData = prepareMenu([...response.data]);
          const CompatibleTree =
            prepareCompatibleTreeDataForDevExpressTree(menuData);
          setIsLoading(false);
          setTreeMenuItems(CompatibleTree);
        },
        onFailure: (): void => {
          setIsLoading(false);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'PermissionMenuController => useEffect(..., [data])',
      },
    );
  }, [data]);

  const findAllParents = (item, acc: number[] = []): number[] => {
    if (!item.parent) {
      return [...acc, item.itemData.id as number];
    }

    return findAllParents(item.parent, [...acc, item.itemData.id]);
  };

  /**
   * merge two tree's data and pass to parent onMenuSelect function
   * @function onCheckboxSelect
   * @param {DevExTreeChangeSelectionEvent} event
   * @returns {void} void
   */
  const onCheckboxSelect = (event: any): void => {
    const rawSelectedMenus = event.component.getSelectedNodes() ?? [];
    const preparedSelectedMenus: number[] = [];

    for (const menu of rawSelectedMenus) {
      preparedSelectedMenus.push(menu.itemData.id);
      if (!menu.parent) {
        continue;
      }

      const parentIds: number[] = findAllParents(menu.parent);

      parentIds.forEach(id => {
        if (!preparedSelectedMenus.includes(id)) {
          preparedSelectedMenus.push(id);
        }
      });
    }

    actorSetActionValue('userPermissionValue', preparedSelectedMenus, {
      path: 'selectedMenus',
      disableDebounce: true,
    });
  };

  return (
    <PermissionMenuView
      onRowClick={onRowClick}
      firstTreeMenuItems={treeMenuItems}
      onCheckboxSelect={onCheckboxSelect}
      isLoading={isLoading}
      data={data}
      isAdvanced={isAdvanced}
      handleClickPermissionAdvance={handleClickPermissionAdvance}
    />
  );
};

export default PermissionMenuController;
