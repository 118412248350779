import { Icon } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { type FC, memo } from 'react';
import { isEmpty } from '../../../helper/data-helper';

import { useStyles } from './stat-chart.style';
import type { StatChartViewProps } from './stat-chart.type';

const StatChartView: FC<StatChartViewProps> = props => {
  const { data, height, width } = props;
  const classes = useStyles();
  const translate = useTranslate();

  if (isEmpty(data.value))
    return (
      <div className={classes.container} style={{ height, width }}>
        {translate('general.noData')}
      </div>
    );

  return (
    <div className={classes.container} style={{ height, width }}>
      {!isEmpty(data.title) && <div className={classes.title}>{data.title}</div>}
      <div className={classes.body}>
        <p style={{ fontSize: `${width / 10}px` }}>
          {!isEmpty(data.icon) && <Icon>{data.icon}</Icon>} {data.value}
        </p>
      </div>
    </div>
  );
};

export default memo(StatChartView);
