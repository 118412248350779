import { FC, memo, useEffect, useState } from 'react';
import { isEmpty } from '../../../helper/data-helper';

import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
  OnFocusParams,
} from '../../form/form.type';
import { NumberInputInterface } from './number-input.type';
import NumberInputView from './number-input.view';
import { NumberTextField } from './number-text-field';
import { convertToFloat } from './number-input.helper';

const NumberInputController: FC<NumberInputInterface> = memo(props => {
  const {
    value,
    formActionsHandler,
    inputMessage,
    resource,
    field,
    label,
    getRef,
    hint,
    className,
    onClick,
    visibleClass,
    disabled,
    customTestAttribute,
    inputInPuzzleForm,
  } = props;
  const { name } = field;

  const [numberInputValue, setNumberInputValue] = useState<string | null>(null);

  useEffect(() => {
    if (value != null && !Number.isNaN(value)) {
      setNumberInputValue(String(value));
    }
  }, [value]);

  /**
   * Handle Blur event
   * @function onBlur
   * @returns {void} void
   */
  const onBlur = (): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function onFocus
   * @returns {void} void
   */
  const onFocus = (): void => {
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as OnFocusParams);
  };

  /**
   * Handle Change event
   * @function onChange
   * @param {NumberFormatValues} values
   * @returns void
   */
  const onChange = (values: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value: floatValue },
    } = values;

    const value = isEmpty(convertToFloat(floatValue))
      ? null
      : convertToFloat(floatValue);

    setNumberInputValue(String(value));

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: value,
      isHidden: visibleClass.includes('displayNone'),
    } as ChangeFormValueParams);
  };

  /**
   * Handle Change event
   * @function onKeyDown
   * @param {ChangeEvent} event
   * @returns void
   */
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    formActionsHandler(FormActions.InputKeyDown, {
      fieldName: name,
      value: (event.target as HTMLInputElement).value,
      event: event,
    } as ChangeFormValueParams);
  };

  return (
    <NumberInputView
      inputMessage={inputMessage}
      value={numberInputValue ?? ''}
      className={className}
      onChange={onChange}
      CustomInput={NumberTextField}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      resource={resource}
      onFocusForTest={onFocus}
      label={label}
      getRef={getRef}
      hint={hint}
      field={field}
      onClick={onClick}
      visibleClass={visibleClass}
      disabled={disabled}
      customTestAttribute={customTestAttribute}
      inputInPuzzleForm={inputInPuzzleForm}
    />
  );
});

export default NumberInputController;
