import {
  useState,
  forwardRef,
  useImperativeHandle,
  type FC,
  type ChangeEvent,
  type FocusEvent,
} from 'react';
import type { Moment } from 'moment';
import { useLocale } from 'react-admin';

import RangePickerInputView from './range-picker-input.view';
import { getRangeInputMode } from './range-picker-input.helper';

import type {
  RangeInputMode,
  RangePickerInputControllerProps,
} from './range-picker-input.type';

const RangePickerInputController: FC<RangePickerInputControllerProps> = forwardRef(
  (props, forwardedRef) => {
    const {
      placeholder,
      isActive,
      showDatePicker,
      onlyRange,
      defaultValue = '',
    } = props;

    const locale = useLocale();

    const [inputValue, setInputValue] = useState<string>(defaultValue);
    const [inputMode, setInputMode] = useState<RangeInputMode>(
      getRangeInputMode(defaultValue) ?? 'empty',
    );

    useImperativeHandle(forwardedRef, () => ({
      changeInputValueFromParent,
      inputValue,
      inputMode,
    }));

    /**
     * set incoming event value to local state
     * @function onInputChange
     * @param {ChangeEvent<HTMLInputElement>} event
     * @returns {void}
     */
    const onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const value = event.target.value;
      setInputMode('empty');
      setInputValue(value);
    };

    /**
     * it will validate input
     * @param {FocusEvent<HTMLInputElement>} event
     * @returns {void}
     */
    const onInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      const value = event.target.value;
      const mode = getRangeInputMode(value);
      if (
        showDatePicker &&
        mode !== 'empty' &&
        mode !== 'date' &&
        mode !== 'dateTime'
      ) {
        setInputMode('invalid');
        return;
      }

      if (onlyRange && mode !== 'empty' && mode !== 'range') {
        setInputMode('invalid');
        return;
      }

      setInputMode(mode);
    };

    /**
     * apply external changes to internal state
     * @function changeInputValueFromParent
     * @param {string} value
     * @returns {void}
     */
    const changeInputValueFromParent = (value: string): void => {
      const mode = getRangeInputMode(value);
      setInputMode(mode);
      setInputValue(value);
    };

    /**
     * set date picker moment value to local input value
     * @function onChooseDate
     * @param {Moment} moment
     * @returns {void}
     */
    const onChooseDate = (moment: Moment): void => {
      const newDate = moment.format(
        locale === 'en' ? 'YYYY/MM/DD HH:mm:ss' : 'jYYYY/jMM/jDD HH:mm:ss',
      );

      setInputValue(newDate);
      const mode = getRangeInputMode(newDate);
      setInputMode(mode);
    };

    return (
      <RangePickerInputView
        inputValue={inputValue}
        inputMode={inputMode}
        placeholder={placeholder}
        showDatePicker={showDatePicker}
        isActive={isActive}
        onInputChange={onInputChange}
        onChooseDate={onChooseDate}
        onInputBlur={onInputBlur}
      />
    );
  },
);

export default RangePickerInputController;
