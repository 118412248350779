import { useTranslate } from 'react-admin';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import { Menu, MenuItem } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import TodoTasksContainer from './todo-tasks-container/todo-tasks-container.view';
import { AddTodoTaskInput } from './add-todo-task-input';
import { useStyles } from './todo-tasks.style';
import { isEmptyObject } from '../../../helper/data-helper';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import type { FC } from 'react';
import type {
  LocalSortTaskListInterface,
  TodoTasksViewProps,
} from './todo-tasks.type';

const TodoTasksView: FC<TodoTasksViewProps> = props => {
  const {
    handleOpenMenuListNameSort,
    handleMenuListSortClose,
    handleTodoTasksSort,
    handleRemoveSort,
    openShareDialog,
    toggleOrder,
    localSortTaskList,
    isAscendingOrder,
    displayNameRef,
    selectedTask,
    anchorEl,
    tasks,
    shareList,
    openAssignTaskDialog,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div data-test="todoheader" className={classes.headerContainer}>
        <div className={classes.headerRightSideContainer}>
          <span className={classes.headerIcon}>
            <FormatListBulletedIcon />
          </span>
          <span>{selectedTask?.title ?? ''}</span>
        </div>

        <div className={classes.sortItemContainer}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuListSortClose}
          >
            <MenuItem>
              <b>{translate('todo.sortBy')}</b>
            </MenuItem>

            <hr />
            {localSortTaskList.map((task: LocalSortTaskListInterface) => {
              return (
                <MenuItem onClick={handleTodoTasksSort(task.name, task.displayName)}>
                  <div className={classes.listSortName}>
                    {task.displayName} {task.icon}
                  </div>
                </MenuItem>
              );
            })}
          </Menu>
          <div
            onClick={handleOpenMenuListNameSort}
            className={classes.sortSectionContainer}
          >
            <SwapVertIcon className={classes.iconMenuSort} />
            {translate('todo.sort')}
          </div>
          {selectedTask && selectedTask.id !== 'inbox' && (
            <div className={classes.shareSectionContainer} onClick={openShareDialog}>
              {shareList.length == 0 ? (
                <PersonAddIcon className={classes.iconShare} />
              ) : shareList.length == 1 ? (
                <GroupAddIcon className={classes.iconShare} />
              ) : (
                <AccountCircleIcon className={classes.shareIcon} />
              )}
              {translate('todo.share')}
            </div>
          )}
        </div>
      </div>
      <div className={classes.sortContainer}>
        {!!displayNameRef.current && (
          <CloseIcon onClick={handleRemoveSort} className={classes.iconSort} />
        )}
        {displayNameRef.current}
        {isAscendingOrder && !!displayNameRef.current ? (
          <ExpandLess onClick={toggleOrder} className={classes.iconSort} />
        ) : (
          !!displayNameRef.current && (
            <ExpandMoreIcon onClick={toggleOrder} className={classes.iconSort} />
          )
        )}
      </div>
      <div className={classes.addTaskInput}>
        <AddTodoTaskInput />
      </div>

      <div className={classes.tasksContainer}>
        <TodoTasksContainer tasks={tasks.unCompletedTasks} />
        {!isEmptyObject(tasks.completedTasks) && (
          <TodoTasksContainer
            tasks={tasks.completedTasks}
            accordionSummary={translate('todo.completedTasks')}
          />
        )}
      </div>
    </div>
  );
};

export default TodoTasksView;
