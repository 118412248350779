import { createRandomHexColor } from '../dynamic-chart.helper';

import type { CreatePieChartDataEntries, PieChartValidData } from './pie-chart.type';

/**
 * validate colors in any format (HEX, RGBA , ...)
 * @function isValidColor
 * @param {string} color
 * @returns {boolean}
 */
export const isValidColor = (color: string): boolean => {
  try {
    return (
      /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})/.test(color) || // check HexaDecimal
      /^(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/.test(color) // check RGB & RGBA
    );
  } catch {
    return false;
  }
};

/**
 * it will convert report data to pie chart valid data format
 * @function createPieChartData
 * @param entries {
 *  @param { Array<Record<string, unknown>> } data
 *  @param { string | null } idFieldName
 *  @param { string | null } valueFieldName
 *  @param { string | null } labelFieldName
 *  @param { string | undefined } colorFieldName
 * }
 * @returns {Array<PieChartValidData>}
 */
export const createPieChartData = (
  entries: CreatePieChartDataEntries,
): Array<PieChartValidData> => {
  const {
    data,
    idFieldName,
    valueFieldName,
    labelFieldName,
    colorFieldName,
    displayNumber = 5,
  } = entries;
  //in old dashboard version valueFieldName was array and maybe we have crash
  const finalValueFieldName = Array.isArray(valueFieldName)
    ? valueFieldName[0]
    : valueFieldName;

  const compatibleData: PieChartValidData[] = [];
  let newData: PieChartValidData[] = [];

  const thereIsAtLeastOneRowWithValidColor = data.some(
    row => colorFieldName && isValidColor(row[colorFieldName] as string),
  );

  data.forEach((row, rowIndex) => {
    const value = {};

    if (idFieldName) {
      value['id'] = row[idFieldName] as string;
    } else {
      value['id'] = String(rowIndex);
    }

    if (labelFieldName) {
      value['name'] = row[labelFieldName] as string;
    } else {
      value['name'] = idFieldName ? (row[idFieldName] as string) : '';
    }

    if (valueFieldName) {
      value['y'] = !isNaN(Number(row[finalValueFieldName]))
        ? Number(row[finalValueFieldName])
        : 0;
    } else {
      value['y'] = 0;
    }

    if (colorFieldName && thereIsAtLeastOneRowWithValidColor) {
      if (isValidColor(row[colorFieldName] as string)) {
        value['color'] = row[colorFieldName] as string;
      } else {
        value['color'] = createRandomHexColor();
      }
    }

    const indexInPrevArray = compatibleData.findIndex(
      row => row.id === (value as PieChartValidData).id,
    );

    if (indexInPrevArray > -1) {
      compatibleData[indexInPrevArray].y += (value as PieChartValidData).y;
    } else {
      compatibleData.push(value as PieChartValidData);
    }
    let sum = 0;
    newData = compatibleData.slice(0, displayNumber);
    for (let i = displayNumber; i < compatibleData.length; i++) {
      if (compatibleData[i]?.y) {
        sum += compatibleData[i].y;
      }
    }
    if (sum > 0) {
      newData[displayNumber] = {
        name: 'other',
        id: '6',
        y: sum,
        color: '',
      };
    }
  });

  //remove undefined items
  return newData.filter(item => item.name && item.id);
};
