import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  contentSectionContainer: {
    display: 'flex',
    height: '100%',
    overflow: 'auto',
    flexDirection: 'row',
    border: `3px dotted ${theme.palette.primary.border}`,
    borderRadius: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  previewSection: {
    flex: 1,
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  settingSection: {
    width: '30%',
    borderRight: `3px dotted ${theme.palette.primary.border}`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 'none',
      borderBottom: `3px dotted ${theme.palette.primary.border}`,
    },
  },

  messageSection: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    padding: '16px',
  },
}));
