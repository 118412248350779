import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import { localStorageGetItem } from '../../core/configProvider';
import {
  showNotification,
  showNotificationForUnknownError,
} from '../../helper/general-function-helper';
import { actorDispatch } from '../../type/actor-setup';
import { CUSTOM_GET, CUSTOM_POST } from '../../core/data-Provider.helper';
import CustomCssView from './custom-css-view';
import { isEmpty } from '../../helper/data-helper';
import { getDomain } from '../../helper/UrlHelper';
import { getCssFileName } from './custom-css.helper';

const CustomCssController = (): ReactElement => {
  const useCompanyId = localStorageGetItem('USER_COMPANY_ID');
  const translate = useTranslate();

  const [dataStyle, setDataStyle] = useState<string>('');
  const cssFileName = getCssFileName();

  /**
   * set received css styles in local state
   * @function getCssSuccessCallback
   * @param {string} result
   * @returns {void} void
   */
  const getCssSuccessCallback = (result: string): void => {
    setDataStyle(result);
  };

  /**
   * get style for custom css
   * @function updateData
   * @returns {void} void
   */
  const updateData = (): void => {
    actorDispatch(
      'crudAction',
      {
        type: CUSTOM_GET,
        entity: 'changeCssLocal',
        resource: `filesuploaded/company_${useCompanyId}/${cssFileName}.css?randomKey=${Math.random()}`,
        onSuccess: getCssSuccessCallback,
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'CustomCssController => updateData',
      },
    );
  };

  useEffect(() => {
    if (!isEmpty(useCompanyId)) {
      updateData();
    }
  }, [useCompanyId]);

  /**
   * send style for custom css
   * @function sendData
   * @returns {void} void
   */
  const sendData = (): void => {
    actorDispatch(
      'crudAction',
      {
        type: CUSTOM_POST,
        entity: 'changeCssLocal',
        resource: `client-file/css`,
        data: { fileName: cssFileName, content: dataStyle },
        onSuccess: () => {
          updateData();
          showNotification(`${translate('css.success')}`, 'success');
          //if you want to reload page after send style, you can use this code
          window.location.reload();
        },
        onFailure: (error: unknown) => {
          showNotificationForUnknownError(error, translate);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'CustomCssController => sendData',
      },
    );
  };

  /**
   * get value and change value textArea
   * @function onChangeDataStyle
   * @param {ChangeEvent<HTMLTextAreaElement>} event
   * @returns {void} void
   */
  const onChangeDataStyle = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    setDataStyle(event.target?.value ?? '');
  };

  return (
    <CustomCssView
      onChangeDataStyle={onChangeDataStyle}
      value={dataStyle}
      sendData={sendData}
    />
  );
};

export default CustomCssController;
