import { useEffect, useState } from 'react';
import { useLocale } from 'react-admin';

import FilterForm from '../../../../../filter-form';
import { getFilterFieldsFromGadgetValues } from './gadget-default-values-tab.helper';
import {
  actorOnDispatch,
  actorRemoveAction,
} from '../../../../../../type/actor-setup';
import { useStyles } from './gadget-default-values-tab.style';

import { getFilterColumns } from '../../../../../../helper/MetaHelper';
import { getMetaDataFromActorOrNetwork } from '../../../../../../helper/meta-helper';
import type { FieldType } from '../../../../../../helper/Types';
import type { FC } from 'react';
import type { GadgetDefaultValuesTabViewProps } from './gadget-default-values-tab.type';

const GadgetDefaultValuesTabView: FC<GadgetDefaultValuesTabViewProps> = props => {
  const { gadgetInternalChangeHandler, gadgetValues } = props;

  const [reportParameters, setReportParameters] = useState<FieldType[]>([]);
  const classes = useStyles();
  const locale = useLocale();
  const reportResource = `report/${gadgetValues?.reportAddress}`;

  useEffect(() => {
    if (gadgetValues.reportAddress) {
      getMetaDataFromActorOrNetwork(
        `report/${gadgetValues.reportAddress}` ?? '',
      ).then(response => {
        const metaData = response[`report/${gadgetValues?.reportAddress}` ?? ''];
        setReportParameters(getFilterColumns(metaData));
      });
    }
  }, [gadgetValues.reportAddress]);

  useEffect(() => {
    const id = actorOnDispatch('filterDataIsChanged', newFilterValues => {
      const filter = newFilterValues[reportResource];
      if (filter) {
        gadgetInternalChangeHandler({
          filterValues: filter,
        });
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'filterDataIsChanged',
        listenerId: id,
      });
    };
  }, []);

  return (
    <div className={classes.container}>
      <FilterForm
        resource={reportResource}
        selectedFilterFields={getFilterFieldsFromGadgetValues(
          (reportParameters as FieldType[]) ?? [],
          locale,
          gadgetValues.filterValues,
        )}
      />
    </div>
  );
};

export default GadgetDefaultValuesTabView;
