import { FC, useCallback, useEffect, useState, useRef } from 'react';
import { useLocale, useTranslate } from 'react-admin';

import { CONFIG_LIST_COLUMN_CHOICE, DEFAULT } from '../../core/configProvider';
import MultiTabListView from './multi-tab-list.view';
import { getCurrentResource } from '../../helper/ActorHelper';
import { getAppSettings } from '../../helper/settings-helper';
import { getChildList, updateReport } from './multi-tab-list.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FilterFormFieldInterface,
  GridDataInterface,
} from '../../type/actor-setup';
import { apiRequestResultHandler } from '../../helper/crud-api.helper';
import { useStyles } from './multi-tab-list.style';
import { getFieldsForDisplay, showImageDialog } from '../list/list.helper';
import NotFound from '../NotFound';
import { ListToolbar } from '../list-toolbar';
import { SettingBar } from '../list-toolbar/setting-bar';
import { Pagination } from '../list-toolbar/pagination';
import useMultiReportMetaHandler from '../relation-panel/multi-tab-table-relation/use-multi-report-meta-handler';
import { getReportChildren, getTranslatedName } from '../../helper/meta-helper';
import {
  prepareActionBarProps,
  preparePaginationProps,
  prepareSettingBarProps,
  requestListData,
  setListPage,
  setListPerPage,
  setListSort,
} from '../list/list.helper';

import type { MultiTabListControllerProps } from './multi-tab-list.type';
import type { GeneralMetaData, MetaDataViewTypes } from '../../helper/Types';
import type {
  MultiTabGridSetSortCallback,
  MultiTabGridSetPageCallback,
  MultiTabGridSetPerPageCallback,
} from '../grid/grid.type';
import type { ReportMetaDataInterface } from '../relation-panel/multi-tab-table-relation/multi-tab-table-relation.type';
import type { FinalFiltersType } from '../filter-form';
import {
  arrayWithAnd,
  showNotification,
  updateUrlQueryParams,
} from '../../helper/general-function-helper';
import { isReportExecutable } from '../relation-panel/relation-panel.helper';
import { ListViewPropsInterface } from '../list';
import { FilterValueStructureEnum } from '../filter-form/filter-form.helper';

const MultiTabListController: FC<MultiTabListControllerProps> = props => {
  const { metaData, listType } = props;

  const tabListWithLoadedData = useRef<Record<number, boolean>>({});
  const activeResourceRef = useRef<string>('');
  const onDispatchData = useRef<{ actionName: keyof ActorActionList; id: symbol }[]>(
    [],
  );

  const [tabActiveKey, setTabActiveKey] = useState<string>('0');
  const [tabsData, setTabsData] = useState<{
    [resource: string]: { data: Array<Record<string, unknown>>; totalCount: number };
  }>({});

  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();

  const resource = getCurrentResource()?.value;
  const rootResource = actorGetActionValue('resources')?.stack[0].value ?? '';
  const isMap = metaData?.defaultView == 'Map';

  const reportChildren = getReportChildren(
    metaData,
    locale,
  ) as unknown as Array<ReportMetaDataInterface>;

  //------ child resource list ----------//
  let childResourceList: ReportMetaDataInterface[] = [];
  if (isReportExecutable(metaData)) {
    childResourceList.push({
      title: getTranslatedName(metaData, locale) ?? '',
      childResource: resource as string,
    });
  }
  childResourceList = [...childResourceList, ...reportChildren];

  // --------------- handle children meta data ---------------
  const childrenMetaDataList = useMultiReportMetaHandler(
    reportChildren,
    resource ?? '',
    metaData,
    true, // ignore multiResults
  );

  const isMultiResult = metaData?.['reportType'] === 'MultiResult';

  activeResourceRef.current = isMultiResult
    ? resource + `/${tabActiveKey}`
    : childResourceList[tabActiveKey]?.childResource;

  const getTabListDataAndRefresh = useCallback(() => {
    if (!resource || !activeResourceRef.current) return;

    const isMap = metaData.defaultView == 'Map';
    if (!isMap) {
      requestListData(
        activeResourceRef.current,
        listType,
        null, // <= parentIdentifier
        null, // <= childFieldName
        null, // <= parentFieldName
        metaData as GeneralMetaData,
        undefined, // <= params
        apiSignal => {
          apiRequestResultHandler(apiSignal);
          tabListWithLoadedData.current[tabActiveKey] = true;
        },
        error => {
          showNotification(error.GET_LIST?.list.data, 'error');
        }, // <= onFailure
        true, // <= isListMode
        true,
        rootResource,
      );
    }
  }, [tabActiveKey, resource]);

  useEffect(() => {
    if (!resource || !activeResourceRef.current) return;

    let id = actorOnDispatch(
      'gridData',
      gridData => {
        const preparedData = {};

        if (isMultiResult) {
          // because all of multi result relations have index at ent of their resource and its not necessary to
          // add main resource to prepared data

          preparedData[activeResourceRef.current] = {
            data: gridData[activeResourceRef.current]?.data,
            totalCount: gridData[activeResourceRef.current]?.totalCount ?? 0,
          };
        } else {
          childResourceList.forEach(child => {
            preparedData[child.childResource] = {
              data: gridData[child.childResource]?.data,
              totalCount: gridData[child.childResource]?.totalCount ?? 0,
            };
          });
        }

        setTabsData(prev => ({ ...prev, ...preparedData }));
      },
      { callerScopeName: 'MultiTabListController' },
    );

    onDispatchData.current.push({
      id,
      actionName: 'gridData',
    });

    id = actorOnDispatch(
      'filterDataIsChanged',
      (finalFilters: Record<string, FinalFiltersType>): void => {
        const filter = finalFilters?.[resource!];
        if (filter) {
          actorSetActionValue('gridData', filter, {
            path: `${rootResource}.requestParameters.filter`,
            replaceAll: true,
            callerScopeName: 'FilterFormController => updateGridFilters',
          });

          if (finalFilters?.[resource!].length === 0) {
            updateUrlQueryParams(null);
          }

          // it will trigger when filters change
          getTabListDataAndRefresh();
        }
      },
      {
        preserve: false,
        callerScopeName: 'MultiTabListController => useEffect',
      },
    );

    onDispatchData.current.push({
      id,
      actionName: 'filterDataIsChanged',
    });

    actorSetActionValue('activeTab', 0, { path: resource });

    id = actorOnDispatch(
      'refreshView',
      refreshResource => {
        if (refreshResource === activeResourceRef.current)
          getTabListDataAndRefresh();
      },
      {
        preserve: false,
      },
    );

    onDispatchData.current.push({
      id,
      actionName: 'refreshView',
    });

    id = actorOnDispatch(
      'filterFormFieldHiddenChanged',
      filterField => {
        const field = filterField[resource];
        const currentFilters = (actorGetActionValue(
          'gridData',
          `${isMultiResult ? rootResource : resource}.requestParameters.filter`,
        ) ?? []) as FinalFiltersType;

        for (let index = 0; index < currentFilters.length; index++) {
          if (!Array.isArray(currentFilters[index])) continue;
          // prettier-ignore
          if (currentFilters[index][FilterValueStructureEnum.KEY] === (field.key ?? field.name)) {
            currentFilters.splice(index, 1);
            break;
          }
        }

        if (currentFilters.at(-1) === 'and') {
          currentFilters.pop();
        }

        actorSetActionValue('gridData', currentFilters, {
          path: `${
            isMultiResult ? rootResource : resource
          }.requestParameters.filter`,
          callerScopeName: 'actorOnDispatch(filterFormFieldHiddenChanged)',
        });
      },
      {
        preserve: false,
      },
    );

    onDispatchData.current.push({
      id,
      actionName: 'filterFormFieldHiddenChanged',
    });

    return () => {
      for (const item of onDispatchData.current) {
        const { actionName, id } = item;
        actorRemoveAction({
          actionName,
          listenerId: id,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (tabListWithLoadedData.current[tabActiveKey]) return;
    getTabListDataAndRefresh();
  }, [tabActiveKey]);

  /**
   * Gets new data when a tab double-click event occurred
   * @function tabDoubleClickHandler
   * @returns { void } void
   */
  const tabDoubleClickHandler = useCallback((): void => {
    getTabListDataAndRefresh();
  }, [tabActiveKey]);

  /**
   * @function addToFilterRequestList
   * @param { Record<string, FilterFormFieldInterface> } data
   * @returns { void } void
   */
  const addToFilterRequestList = useCallback(
    (data: Record<string, FilterFormFieldInterface>): void => {
      /**
       * In `multiReport`s we get `parameters` in `metaData` of the parent
       * And there isn't The key of `dropdown` in `column` field
       * We need it and we should get it from as the following:
       */
      const rootMetaData = actorGetActionValue(
        'metaData',
        rootResource,
      ) as GeneralMetaData | null;

      const toAddFilterKey = Object.keys(data)[0];
      if (rootMetaData != null) {
        const fieldInParameters = rootMetaData.parameters?.find(
          parameter => parameter.key === toAddFilterKey,
        );

        data[toAddFilterKey].fieldData.dropdown = fieldInParameters?.field.dropdown; // Now we have `dropdown` key of `parameters`
      }

      // prettier-ignore
      const currentFilterFormFields = actorGetActionValue('filterFormFields')?.[resource!] ?? {};
      actorDispatch(
        'filterFormFields',
        { [resource!]: { ...currentFilterFormFields, ...data } },
        {
          replaceAll: true,
          callerScopeName: 'MultiTabListController => addToFilterRequestList',
        },
      );

      const gridData = actorGetActionValue(
        'gridData',
        resource,
      ) as GridDataInterface | null;
      if (gridData == null) return;

      const filterDetails = Object.values(data)[0]; // { fieldData: {...}, value: [...] }

      const currentFilters = gridData.requestParameters?.filter ?? [];
      /**
       * We have to check if the key of new added filter exists in the current gird filters
       *  that means we have to check `filterDetails.value[0]` to find it
       * if we found it, we have to replace with the new value otherwise have to push to the current filters
       */
      const targetIndex = currentFilters.findIndex(
        item =>
          Array.isArray(item) &&
          ((Array.isArray(item[0]) && // [[[], '', ...], ...]
            filterDetails.value.indexOf(item[0][0]) > -1) ||
            // [..., ... , ...]
            (!Array.isArray(item[0]) && filterDetails.value.indexOf(item[0]) > -1)),
      );

      if (targetIndex > -1) {
        currentFilters[targetIndex] = Object.values(data)[0].value;
      } else {
        if (currentFilters.length > 0) {
          currentFilters.push('and');
        }
        currentFilters.push(Object.values(data)[0].value);
      }

      actorSetActionValue('gridData', currentFilters, {
        path: `${isMultiResult ? rootResource : resource}.requestParameters.filter`,
        callerScopeName: 'MultiTabListController => addToFilterRequestList',
      });
    },
    [resource, rootResource],
  );

  if (!resource || !activeResourceRef.current) {
    return (
      <div className={classes.loadingOrErrorBoxContainer}>
        <NotFound
          title={
            !resource
              ? translate('ra.navigation.no_results')
              : translate('meta.errorInParentChildsMeta')
          }
          style={{ width: '100%' }}
        />
      </div>
    );
  }

  const rawTabs = getChildList(
    metaData,
    locale,
    resource,
    updateReport,
    reportChildren,
    childrenMetaDataList,
  );

  /**
   * it will call global function to change sort
   * @function setSortAdopter
   * @param {string} fieldName
   * @returns {void} void
   */
  const setSortAdopter: MultiTabGridSetSortCallback = _resource => fieldName => {
    setListSort(fieldName, _resource, getTabListDataAndRefresh);
  };

  /**
   * it will call global function to change page
   * @function setPageAdopter
   * @param {number} page
   * @returns {void} void
   */
  const setPageAdopter: MultiTabGridSetPageCallback = _resource => page => {
    setListPage(page, _resource, getTabListDataAndRefresh);
  };

  /**
   * it will call global function to change sort
   * @function setPerPageAdopter
   * @param {number} perPage
   * @returns {void} void
   */
  const setPerPageAdopter: MultiTabGridSetPerPageCallback = _resource => perPage => {
    setListPerPage(perPage, _resource, getTabListDataAndRefresh);
  };

  //TODO :CHECK THIS
  const preparedTabs: ListViewPropsInterface[] = rawTabs.map(tabData => {
    const { resource: tabResource, metaData: tabMetaData, title } = tabData;

    const defaultSelected =
      getAppSettings<number[]>(
        DEFAULT + '_' + CONFIG_LIST_COLUMN_CHOICE + '_' + tabResource,
      ).value ?? null;

    const userSelected = getAppSettings<number[]>(
      CONFIG_LIST_COLUMN_CHOICE + '_' + tabResource,
      true,
    ).value;

    const fields = getFieldsForDisplay(
      defaultSelected,
      userSelected,
      tabMetaData as GeneralMetaData,
      null, // <= disabledFieldList
      true, // <= isListMode
    );

    return {
      metaData: tabMetaData as GeneralMetaData,
      resource: tabResource,
      data: tabsData[tabResource]?.data ?? [],
      fields,
      setSort: setSortAdopter(tabResource),
      title,
      addToFilterRequestList,
    };
  });

  /**
   * set current active tab index in actor
   * @function onTabChange
   * @param {number} tabIndex
   * @returns {void} void
   */
  const onTabChange = (tabIndex: string) => {
    actorSetActionValue('activeTab', +tabIndex, { path: resource });
    setTabActiveKey(tabIndex);
  };

  const actionBarProps = prepareActionBarProps(
    listType as MetaDataViewTypes,
    activeResourceRef.current,
    preparedTabs[tabActiveKey]?.metaData ?? metaData, //get columns from tab
    getTabListDataAndRefresh,
    undefined, //customFunctions
    rootResource, // It's so important, we need main source to find filters in `action-bar`s
  );

  const settingBarProps = prepareSettingBarProps(
    activeResourceRef.current,
    preparedTabs[tabActiveKey]?.metaData ?? metaData, //get columns from tab
    preparedTabs[tabActiveKey]?.fields,
    rootResource,
  );

  const paginationProps = preparePaginationProps(
    activeResourceRef.current,
    setPageAdopter(activeResourceRef.current),
    setPerPageAdopter(activeResourceRef.current),
    false, // isLoading
    false, // isCompactMode
    false, // isRelation
  );

  /**
   * change filter in grid data object in actor and request data with new parameters
   * @function setFilters
   * @param {Record<string, unknown>} newFilters
   * @returns {function}
   */
  const setFilters = (newFilters: Record<string, unknown>): void => {
    const filter = Object.values(newFilters);
    const filterArray = arrayWithAnd(filter);
    actorSetActionValue('gridData', filterArray, {
      path: `${resource}.requestParameters.searchFilters`,
      replaceAll: true,
    });

    requestListData(
      activeResourceRef.current,
      listType,
      null, // <= parentIdentifier
      null, // <= childFieldName
      null, // <= parentFieldName
      metaData as GeneralMetaData,
      undefined, // <= params
      apiSignal => {
        apiRequestResultHandler(apiSignal);
        tabListWithLoadedData.current[tabActiveKey] = true;
      },
      error => {
        showNotification(error.GET_LIST?.list.data, 'error');
      }, // <= onFailure
      true, // <= isListMode
      true,
      rootResource,
    );
  };

  return (
    <div className={classes.mainContainer}>
      {<ListToolbar actionBarProps={actionBarProps} />}

      <MultiTabListView
        tabs={preparedTabs}
        onTabChange={onTabChange}
        tabActiveKey={tabActiveKey}
        tabDoubleClickHandler={tabDoubleClickHandler}
        setFilters={setFilters}
      />

      {!isMap && (
        <div className={classes.bottomToolbar}>
          <SettingBar {...settingBarProps} />
          <Pagination {...paginationProps} />
        </div>
      )}
    </div>
  );
};

export default MultiTabListController;
