import { ReactElement, useEffect, useState } from 'react';

import { actorGetActionValue, actorOnDispatch } from '../../../type/actor-setup';
import PrevNextRecordView from './prev-next-record.view';
import lodashGet from 'lodash/get';
import { isEmpty } from '../../../helper/data-helper';
import { getCurrentRecord, getCurrentResource } from '../../../helper/ActorHelper';
import { openNewTab } from '../../../helper/QuickAccessHelper';
import { removeOnDispatches } from '../../../helper/general-function-helper';

const PrevNextRecordController = (): ReactElement | null => {
  const [loading, setLoading] = useState(false);

  const currentResource = getCurrentResource()!;
  const currentRecord = getCurrentRecord();
  const gridIDs = actorGetActionValue('gridIDs', `${currentResource.value}`)
    ?.allIDs as unknown as number[];

  const currentID = lodashGet(currentRecord, 'id', null);
  const indexInGridIds: number | undefined = gridIDs?.indexOf(currentID);

  useEffect(() => {
    const listenerId = actorOnDispatch('loading', loadingRecord => {
      setLoading(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          loadingRecord[currentResource.value] ||
          false,
      );
    });

    return () => {
      removeOnDispatches([
        {
          actionName: 'loading',
          listenerId,
        },
      ]);
    };
  }, []);

  /**
   * redirect to next record
   * @function onNextButtonClick
   * @returns {void}
   */
  const onNextButtonClick = () => {
    openNewTab(`${currentResource?.value}/${gridIDs?.[indexInGridIds! + 1]}/show`);
  };

  /**
   * redirect to prev record
   * @function onPrevButtonClick
   * @returns {void}
   */
  const onPrevButtonClick = () => {
    openNewTab(`${currentResource?.value}/${gridIDs?.[indexInGridIds! - 1]}/show`);
  };

  return (
    <PrevNextRecordView
      onNextButtonClick={onNextButtonClick}
      onPrevButtonClick={onPrevButtonClick}
      isNextButtonDisabled={
        loading ||
        isEmpty(indexInGridIds) ||
        indexInGridIds === -1 ||
        !gridIDs?.[indexInGridIds! + 1]
      }
      isPrevButtonDisabled={
        loading ||
        isEmpty(indexInGridIds) ||
        indexInGridIds === 0 ||
        indexInGridIds === -1
      }
    />
  );
};

export default PrevNextRecordController;
