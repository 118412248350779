import { useTranslate } from 'react-admin';

import type { FC } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import NotFound from '../NotFound';
import { AreaChart } from './area-chart';
import { BarChart } from './bar-chart';
import { useStyles } from './dynamic-chart.style';
import type {
  AreaChartOptions,
  BarChartOptions,
  DynamicChartViewProps,
  GaugeChartOptions,
  LineChartOptions,
  PieChartOptions,
  StatChartOptions,
  ListChartOptions,
  AnnouncementsChartOptions,
  TimeSeriesChartOptions,
  IframeChartOptions,
} from './dynamic-chart.type';

import { MainCharts, WordCloudOptions } from './dynamic-chart.type';
import { LineChart } from './line-chart';
import { PieChart } from './pie-chart';
import { WordCloud } from './word-cloud';
import { StatChart } from './stat-chart';
import { GaugeChart } from './gauge-chart';
import { ListChart } from './list-chart';
import { AnnoucementPost } from '../announcement-posts';
import { AnnouncementsChart } from './announcment-chart';
import { TimeSeriesChart } from './time-series-chart';
import { isEmpty } from '../../helper/data-helper';
import { getPreparedUrlForIframeGadget } from './dynamic-chart.helper';

const DynamicChartView: FC<DynamicChartViewProps> = props => {
  const {
    chartType,
    reportData = [],
    chartOptions,
    height,
    width,
    editPropertyOfDashboardCard,
    handleChartItemClick,
    reportColumns,
    metaData,
    resource,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  if (chartType === MainCharts.Pie) {
    return (
      <div className={classes.container}>
        <PieChart
          reportData={reportData}
          chartOptions={chartOptions as PieChartOptions}
          height={height ?? 300}
          width={width}
          handleChartItemClick={handleChartItemClick}
          metaData={metaData}
        />
      </div>
    );
  }

  if (chartType === MainCharts.Bar) {
    return (
      <div className={classes.container}>
        <BarChart
          reportData={reportData}
          chartOptions={chartOptions as BarChartOptions}
          height={height ?? 300}
          width={width}
          handleChartItemClick={handleChartItemClick}
          reportColumns={reportColumns}
          metaData={metaData}
        />
      </div>
    );
  }

  if (chartType === MainCharts.Line) {
    return (
      <div className={classes.container}>
        <LineChart
          reportData={reportData}
          chartOptions={chartOptions as LineChartOptions}
          height={height ?? 300}
          width={width}
          handleChartItemClick={handleChartItemClick}
          reportColumns={reportColumns}
          metaData={metaData}
        />
      </div>
    );
  }

  if (chartType === MainCharts.Area) {
    return (
      <div className={classes.container}>
        <AreaChart
          reportData={reportData}
          chartOptions={chartOptions as AreaChartOptions}
          height={height ?? 300}
          width={width}
          reportColumns={reportColumns}
        />
      </div>
    );
  }
  if (chartType === MainCharts.WordCloud) {
    return (
      <WordCloud
        reportData={reportData}
        chartOptions={chartOptions as WordCloudOptions}
        handleChartItemClick={handleChartItemClick}
        height={height ?? 300}
        width={width}
      />
    );
  }

  if (chartType === MainCharts.Stat) {
    return (
      <div className={classes.container}>
        <StatChart
          reportData={reportData}
          chartOptions={chartOptions as StatChartOptions}
          height={height ?? 300}
          width={width}
        />
      </div>
    );
  }

  if (chartType === MainCharts.Gauge) {
    return (
      <div className={classes.container}>
        <GaugeChart
          reportData={reportData}
          chartOptions={chartOptions as GaugeChartOptions}
          height={height ?? 300}
          width={width}
        />
      </div>
    );
  }

  if (chartType === MainCharts.List) {
    return (
      <div className={classes.container}>
        <ListChart
          chartOptions={chartOptions as ListChartOptions}
          height={height ?? 300}
          width={width}
          editPropertyOfDashboardCard={editPropertyOfDashboardCard}
          handleChartItemClick={handleChartItemClick}
        />
      </div>
    );
  }

  if (chartType === MainCharts.Announcements) {
    return (
      <div className={classes.container}>
        <AnnouncementsChart
          chartOptions={chartOptions as AnnouncementsChartOptions}
          height={height ?? 300}
          width={width}
          announcementsData={reportData as unknown as AnnoucementPost[]}
        />
      </div>
    );
  }

  if (chartType === MainCharts.TimeSeriesChart) {
    return (
      <div className={classes.container}>
        <TimeSeriesChart
          reportData={reportData}
          chartOptions={chartOptions as TimeSeriesChartOptions}
          height={height ?? 300}
          width={width}
        />
      </div>
    );
  }

  if (chartType === MainCharts.Iframe) {
    const { url, attachSessionToUrl } = chartOptions as IframeChartOptions;

    const preparedUrl = getPreparedUrlForIframeGadget(url, attachSessionToUrl);
    if (isEmpty(preparedUrl)) {
      return <></>;
    }

    return (
      <div className={classes.container}>
        <iframe
          src={preparedUrl}
          className={classes.iframeContainer}
          frameBorder="0"
        />
      </div>
    );
  }

  if (chartType === MainCharts.Calendar) {
    if (!resource) {
      return (
        <NotFound
          hideBackButton={true}
          title={translate('gadget.invalidCalender')}
        />
      );
    }

    return (
      <div className={classes.container}>
        <iframe
          src={`./#/${resource}?embed=true`}
          className={classes.iframeContainer}
          frameBorder="0"
        />
      </div>
    );
  }

  return <NotFound title={translate('gadget.invalidChart')} />;
};

export default withResizeDetector(DynamicChartView);
