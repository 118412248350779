import { type FC, memo } from 'react';

import PermissionFooterView from './permission-footer.view';
import { PermissionFooter } from './permission-footer.type';

const PermissionFooterController: FC<PermissionFooter> = props => {
  const { onApplyPermissionClick, onRemovePermissionClick } = props;

  return (
    <PermissionFooterView
      onApplyPermissionClick={onApplyPermissionClick}
      onRemovePermissionClick={onRemovePermissionClick}
    />
  );
};

export default memo(PermissionFooterController, () => true);
