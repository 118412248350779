import querystring from 'qs';
import lodashMap from 'lodash/map';

import { isEmpty } from './data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorRemoveAction,
  actorSetActionValue,
} from '../type/actor-setup';
import {
  API_NAME,
  getValue,
  USER_COMPANY_ID,
  USER_ID,
} from '../core/configProvider';

import type { ShowNotification, Translate } from './Types';

/**
 * @function showNotification
 * @return {void}
 */
export const showNotification: ShowNotification = (
  message,
  type = 'info',
  options,
): void => {
  if (isEmpty(message)) return;

  let _message = message;
  let requestId;
  let codeNumber;
  if (String(message).includes('^') && !options?.forceSnackbar) {
    const msgArr = String(message).split('^');
    _message = msgArr[0];
    if (String(message).includes('CODE')) {
      requestId = msgArr[1]?.split('CODE')[0];
      codeNumber = msgArr[1]?.split('CODE')[1];
    } else {
      requestId = msgArr[1];
    }
  }

  const _options = { ...options, requestId, codeNumber };
  let changeType = '';

  switch (type) {
    case 'error':
      changeType = 'error';
      break;
    case 'ok':
      changeType = 'success';
      break;
    case 'warning':
      changeType = 'warning';
      break;
    case 'info':
      changeType = 'success';
      break;
    default:
      changeType = 'info';
  }

  actorDispatch('notification', {
    message: _message,
    type: changeType,
    options: _options,
  });
};

/**
 * @function hiddenNotifications
 * @returns {void}
 */
export const hiddenNotifications = (): void => {
  actorDispatch('notification', null, { replaceAll: true });
};

/**
 * @function setLoading
 * @param { string } path
 * @param { boolean } loading
 * @returns { void }
 */
export const setLoading = (path: string, loading: boolean): void => {
  actorDispatch('loading', loading, {
    path,
  });
};

/**
 * handle unknown error
 * @function showNotificationForUnknownError
 * @param {unknown} error
 * @param {Translate} translate
 * @param {boolean} showInDialog
 * @returns {void} void
 */
export const showNotificationForUnknownError = (
  error: unknown,
  translate?: Translate,
  showInDialog?: boolean,
): void => {
  if (typeof error === 'string') {
    showNotification(error, 'error', { forceSnackbar: showInDialog ? false : true });
  } else if (typeof error === 'object' && error?.['message']) {
    showNotification(error['message'], 'error', {
      forceSnackbar: showInDialog ? false : true,
    });
  } else if (translate) {
    showNotification(translate('ra.notification.data_provider_error'), 'error', {
      forceSnackbar: showInDialog ? false : true,
    });
  }
};

/**
 * A while for a browser's paint(more than a animation frame)
 * @function waitForBrowserPaint
 * @returns { Promise<void> } a promise of void
 */
export const waitForBrowserPaint = (): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(resolve, 0);
  });
};

/**
 * @function updateUrlQueryParams
 * @param { unknown } queryParams
 * @returns { void } void
 */
export const updateUrlQueryParams = (queryParams: unknown): void => {
  const urlInfo = actorGetActionValue('urlInfo');
  if (urlInfo == null) return;

  const { location: actorLocation } = urlInfo;

  /**
   * If `queryParams` equals `null` means we want to empty query parameters
   */
  if (queryParams == null) {
    const finalLocationHref = actorLocation.href.replace(actorLocation.search, '');

    history.pushState({ updateQueryParamsManually: true }, '', finalLocationHref);
    actorSetActionValue('urlInfo', {
      href: finalLocationHref,
      search: '',
    });

    return;
  }

  // Split the URL into base and query string
  const [, actorSearchParams] = actorLocation.href.split('?');

  // Parse the query string into an object
  const oldQueryParams = {};
  if (actorSearchParams) {
    const pairs = actorSearchParams.split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      oldQueryParams[key] = decodeURIComponent(value);
    }
  }

  let queryString = '';
  /**
   * When `queryParams` is an object we have to check nested key/value,
   * to convert them to a string before adding them to url params
   */
  if (!Array.isArray(queryParams) && typeof queryParams === 'object') {
    // const finalQueryParams = {};
    for (const key in queryParams) {
      if (Array.isArray(queryParams[key]) || typeof queryParams[key] === 'object') {
        oldQueryParams[key] = JSON.stringify(queryParams[key]); // Converts nested values to string
      } else if (!isEmpty(queryParams[key])) {
        // We want to remove empty values
        oldQueryParams[key] = queryParams[key];
      }
    }

    queryString = querystring.stringify(oldQueryParams, {
      encode: false,
      arrayFormat: 'comma',
    });
  } else {
    queryString = querystring.stringify(queryParams, {
      encode: false,
      arrayFormat: 'comma',
    });
  }

  let finalLocationHref = actorLocation.href;
  if (!isEmpty(queryString)) {
    // We just want to add final `queryString` to href query params if it doesn't empty
    if (isEmpty(actorLocation.search)) {
      finalLocationHref = `${actorLocation.href}?${queryString}`;
    } else {
      // Otherwise we have to replace it
      finalLocationHref = actorLocation.href.replace(
        actorLocation.search,
        '?' + queryString,
      );
    }
  }

  history.pushState({ updateQueryParamsManually: true }, '', finalLocationHref);
  actorSetActionValue('urlInfo', { href: finalLocationHref, search: queryString });
};

/**
 * @function getUserAvatarUrl
 * @returns {string} user avatar relative url
 * @example Output sample: 'account/crmweb/profile/image?userId=8296&companyId=1'
 */
export const getUserAvatarRelativeUrl = (): string => {
  const apiName = getValue(API_NAME);
  const companyId = getValue(USER_COMPANY_ID);
  const userId = getValue(USER_ID);

  // account/crmweb/profile/image?userId=8296&companyId=1
  return `account/${apiName}/profile/image?userId=${userId}&companyId=${companyId}`;
};

/**
 * @function getFileSize
 * @param { number } bytes
 * @returns { string }
 */
export const getFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * @function customDebounce
 * @param fn
 * @param ms
 * @returns {(args: A) => Promise<R>} - a debounced function
 */
export function customDebounce<R = void>(
  fn: (...args: any) => R,
  ms: number,
): (...args: any) => Promise<R> {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (...args: any): Promise<R> =>
    new Promise(resolve => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(...args));
      }, ms);
    });

  return debouncedFunc;
}

/**
 * deviceCallbackFunction : run function in android OS
 * @param {string} actionName
 * @returns {void} void
 */
export const deviceCallbackFunction = (actionName: string): void => {
  //@ts-ignore
  window?.flutter_inappwebview?.callHandler('deviceCallbackFunction', actionName);
};

/**
 * @function arrayWithAnd
 * @param {unknown[]} array
 * @returns {(string | unknown)[]} An array
 */
export const arrayWithAnd = (array: unknown[]): (string | unknown)[] => {
  const formattedFilters: (string | unknown)[] = [];
  for (const _value of array) {
    formattedFilters.push(_value, 'and');
  }
  formattedFilters.pop(); // Remove last extra `and`

  return formattedFilters;
};

/**
 * @function decodeHtml
 * @param {string} htmlString
 * @returns {string} string
 */
export const decodeHtml = (htmlString: string): string => {
  const stringWithNewlines = htmlString.replace(/<br\s*\/?>/gi, '\n');
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(stringWithNewlines, 'text/html').body
    .textContent;

  return decodedString ? decodedString : '';
};

/**
 * it will resolve after a custom delay
 * @function delay
 * @param {number} time
 * @returns {Promise<void>}
 */
export const delay = async (time: number): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

/**
 * @function getTargetParamFromSearchParams
 * @param {string} searchParams
 * @param {string} targetParamKey
 * @returns {string | null} targetParam
 */
export const getTargetParamFromSearchParams = (
  searchParams: string,
  targetParamKey: string,
): string | null => {
  if (!searchParams?.includes(targetParamKey)) {
    return null;
  }

  const listOfParams = searchParams?.split('&');

  let targetParam = '';
  //id:041d2e1c-581c-4ced-af87-aa0133b2ab46&uniqueid:259de1bf-55eb-40df-a587-6efee455a29b
  //target = id
  lodashMap(listOfParams, param => {
    if (param?.startsWith(`${targetParamKey}:`)) {
      targetParam = param?.split(':')[1]?.split('#')[0];
    }
  });

  return targetParam;
};

/**
 * @function removeOnDispatches
 * @description Remove a set of `onDispatch` footprints
 * @param {{ actionName: keyof ActorActionList; listenerId: symbol; prune?: boolean; }[]} onDispatches
 * @returns {void} void
 */
export const removeOnDispatches = (
  onDispatches: {
    actionName: keyof ActorActionList;
    listenerId: symbol;
    prune?: boolean;
  }[],
): void => {
  for (const { actionName, listenerId, prune } of onDispatches) {
    const option = { actionName };
    if (prune === true) {
      option['prune'] = prune;
    } else {
      option['listenerId'] = listenerId;
    }

    actorRemoveAction(option);
  }
};
