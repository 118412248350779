import { useEffect, useState } from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, Switch } from '@material-ui/core';

const styles = theme => ({});

const BooleanFilterInput = props => {
  const { value, classes, onChange } = props;

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(!!value);
    onChange(!!value);
  }, [value]);

  const handleChange = event => {
    const checked = event.target.checked;
    setChecked(checked);
    onChange(checked);
  };

  return (
    <Switch
      data-test-switch-input
      checked={checked}
      onChange={handleChange}
      className={classes.switch}
      size="small"
    />
  );
};

BooleanFilterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default compose(withStyles(styles, { withTheme: true }))(BooleanFilterInput);
