import { Typography } from '@material-ui/core';
import { AssignmentInd } from '@material-ui/icons';
import { useTranslate } from 'react-admin';

import { useStyles } from './todo-assign-task.style';

import type { FC } from 'react';
import type { TodoAssignTaskViewInterface } from './todo-assign-task.type';

const TodoAssignTaskView: FC<TodoAssignTaskViewInterface> = props => {
  const { openAssignTaskDialog, selectedUser } = props;
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <>
      <div onClick={openAssignTaskDialog}></div>
      <div data-test="assignTask" className={classes.itemContainer}>
        <div onClick={openAssignTaskDialog} className={classes.body}>
          <AssignmentInd color="disabled" />
          <div className={classes.titleAndValue}>
            <Typography variant="caption" className={classes.todoItemText}>
              {selectedUser?.fullname ?? translate('todo.assign')}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodoAssignTaskView;
