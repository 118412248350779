import { makeStyles } from '@material-ui/core';

import type { TodoTaskItemThemeInterface } from './todo-task-item.type';
import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, TodoTaskItemThemeInterface>(
  theme => ({
    container: {
      borderRadius: 4,
      justifyContent: 'space-between',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      padding: 10,
      margin: '1px 5px 0px 5px',
      boxShadow: `0px 0.3px 0.9px ${theme.palette.todoCustomShadow.shadowOne}, 0px 0px 1.6px ${theme.palette.todoCustomShadow.shadowTow}, 0px 1px 1px 0px ${theme.palette.todoCustomShadow.shadowTree}`,
      // boxShadow: `0px 0.3px 0.9px ${theme.palette.todoCustomShadow.shadowOne}, 0px 1.6px 3.6px ${theme.palette.todoCustomShadow.shadowTow}, 0px 1px 3px 0px ${theme.palette.todoCustomShadow.shadowTree}`,
      background: props =>
        props.isIndexOdd
          ? theme.palette.todoTaskBackground
          : theme.palette.primary.appLightBackgroundColor,
      '&:hover': {
        background: theme.palette.todoHoveredItem,
      },
    },
    CheckCircleIcon: {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    RadioButtonUncheckedIcon: {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    StarIcon: {
      color: theme.palette.secondary.main,
      marginRight: 10,
      cursor: 'pointer',
    },
    StarBorderIcon: {
      color: theme.palette.secondary.main,
      marginRight: 10,
      cursor: 'pointer',
    },
    taskId: {
      margin: '0px 15px',
    },
    isOver: {
      border: ({ isOver }) =>
        isOver ? `1px solid ${theme.palette.primary.appPrimaryTextColor}` : 'unset',
    },
    textAndStarIconContainer: {
      display: 'flex',
      alignItems: 'center',
    },

    listItem: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5px',
      marginRight: '5px',
    },

    doneTask: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1rem',
      color: theme.palette.todoDarkGray,
      textDecoration: 'line-through',
    },
    undoneTask: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '1rem',
      color: theme.palette.todoDarkGray,
      alignItems: 'center',
    },
    taskItemContainer: { display: 'flex', width: '100%' },

    metaDataInfo: { display: 'flex', alignItems: 'center', margin: '1px 1px' },

    metaDataInfoGroup: {
      color: theme.palette.todoLightGray,
      marginRight: 4,
      display: 'flex',
      alignItems: 'center',
      margin: '1px 1px',
      '&::after': {
        content: `'•'`,
        marginLeft: '4px',
        marginTop: 2,
      },
    },
    metaDataInfoGroupBlue: {
      color: theme.palette.secondary.main,
      marginRight: 4,
      display: 'flex',
      alignItems: 'center',
      margin: '1px 1px',
      '&::after': {
        content: `'•'`,
        marginLeft: '4px',
        marginTop: 2,
      },
    },
    metaDataInfoGroupIcon: {
      marginRight: 4,
      color: theme.palette.todoLightGray,
      display: 'flex',
      alignItems: 'center',
      margin: '1px 1px',
    },
    metaDataInfoGroupIconBlue: {
      marginRight: 4,
      color: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      margin: '1px 1px',
    },

    containerTodo: {
      display: 'flex',
      alignItems: 'center',
    },
    small: { width: theme.spacing(3), height: theme.spacing(3) },
  }),
);
