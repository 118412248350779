import { useEffect, type FC, useState, useCallback } from 'react';
import ShowPermissionsView from '../show-permissions/show-permissions.view';
import { useTranslate } from 'react-admin';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
} from '../../type/actor-setup';

import { SelectedItemForPermissionAdvance } from '../show-permissions/simple-grid';
import { IS_ADMIN_USER, getValue } from '../../core/configProvider';
import { showNotificationForUnknownError } from '../../helper/general-function-helper';
import NotFound from '../NotFound';
import { useLocale } from 'react-admin';

const ShowPermissionsAdvance: FC = () => {
  const rootResource = actorGetActionValue('resources')?.current?.value;

  const [selectItems, setSelectItems] = useState<SelectedItemForPermissionAdvance>(
    {},
  );
  const [items, setItems] = useState<number>(0);
  const isAdmin = getValue(IS_ADMIN_USER);
  const translate = useTranslate();
  const locale = useLocale();

  useEffect(() => {
    actorOnDispatch('saveCellInPermissionsAdvance', permission => {
      setSelectItems(permission);
    });
    actorDispatch('filterDataIsChanged', '');
  }, []);

  useEffect(() => {
    const computedItems = Object.values(
      selectItems as SelectedItemForPermissionAdvance,
    ).filter(item => item.checked)?.length;

    setItems(computedItems);
  }, [selectItems]);

  /**
   * @function openAdvancePermissions
   * @returns {void}
   */
  const openAdvancePermissions = useCallback((): void => {
    const selectedItemForPermission =
      actorGetActionValue('saveCellInPermissionsAdvance') ?? {};
    actorDispatch('quickDialog', {
      permissionAddUserIsOpen: true,
      data: {
        selectedItemForPermission,
        isAdvanced: true,
        idDelete: false,
      },
    });
  }, []);

  /**
   * @function openAdvancePermissions
   * @returns {void}
   */
  const removeAdvancePermission = useCallback((): void => {
    const selectedItemForPermission =
      actorGetActionValue('saveCellInPermissionsAdvance') ?? {};
    actorDispatch('quickDialog', {
      permissionAddUserIsOpen: true,
      data: {
        selectedItemForPermission,
        isAdvanced: true,
        isDelete: true,
      },
    });
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      showNotificationForUnknownError(
        translate('ra.auth.authError'),
        translate,
        true,
      );
    }
  }, []);

  /**
   * @function onTabChange
   * @param {string} activeKey
   * @returns {void} void
   */
  const onTabChange = (activeKey: string): void => {
    actorSetActionValue('userPermissionValue', { selectedTab: activeKey });
  };

  if (!isAdmin) {
    return <NotFound />;
  }

  return (
    <ShowPermissionsView
      handleTabChange={onTabChange}
      rootResource={rootResource ?? ''}
      isAdvanced={true}
      openAdvancePermissions={openAdvancePermissions}
      removeAdvancePermission={removeAdvancePermission}
      selectItemsForAddPermissionsAdvance={items}
      selectItems={selectItems}
    />
  );
};

export default ShowPermissionsAdvance;
