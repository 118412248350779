import { FC } from 'react';
import { useLocale } from 'react-admin';

import useWidth from '../../useWidth';
import { useStyles } from './table-row-grid.style';
import { TableRowGridProps } from './table-row-grid.type';
import { shouldHideField } from './table-row-grid.helper';
import { isEmpty } from '../../../helper/data-helper';
import { checkUiVisible } from '../../form/form-with-tabs/form-with-tabs.helper';
import DynamicField from '../../../container/DynamicField';

export const TableRowGrid: FC<TableRowGridProps> = props => {
  // prettier-ignore
  const { key, fields, dynamicFieldProps, inputsInitialAppearanceCharacteristics, onInputStateChange, groupId } = props;
  const { record, tab } = dynamicFieldProps;

  const classes = useStyles();
  const locale = useLocale();
  const width = useWidth();

  return (
    <tr key={key}>
      {fields.map((field, index) => {
        if (shouldHideField(field, width)) {
          onInputStateChange(groupId, field?.['name'], false);
          return (
            <td key={index} className={`${classes.emptyTableCell} displayNone`} />
          );
        } else if (
          field &&
          field !== 'empty' &&
          inputsInitialAppearanceCharacteristics?.[field.name]?.visible
        ) {
          if (
            !isEmpty(field.javaScriptUiVisible?.trim()) &&
            !checkUiVisible(field, record)
          ) {
            onInputStateChange(groupId, field?.['name'], false);
            return null;
          }

          onInputStateChange(groupId, field?.['name'], true);
          return (
            <td
              key={index}
              rowSpan={field.rowSpan ? field.rowSpan : 1}
              colSpan={field.colSpan ? field.colSpan : 1}
              className={classes.tableCell}
              data-test-td-name={field.name}
              style={{
                height:
                  field.rowSpan && field.rowSpan > 1 ? field.rowSpan * 40 : 'unset',
                ...field.customOption?.customStyleAdditionalDataField,
                overflow: 'auto',
              }}
            >
              <DynamicField
                customLabel
                key={field.id}
                source={field.relatedName ?? field.name}
                field={field}
                label={field['translatedCaption']?.[locale] ?? field.caption}
                record={record}
                resource={tab.resource}
                basePath={`/${tab.resource}`}
              />
            </td>
          );
        }

        onInputStateChange(groupId, field?.['name'], false);
        return null;
      })}
    </tr>
  );
};
