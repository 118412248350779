import { useEffect, type FC, useState } from 'react';
import { useLeaflet } from 'react-leaflet';
import L from 'leaflet';

import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import type { MarkerClusterProps } from './leaflet-map.type';

const MarkerCluster: FC<MarkerClusterProps> = props => {
  const { markerList, getMarkerIcon, clusterMode = false } = props;
  const mcg = clusterMode
    ? L.markerClusterGroup()
    : L.markerClusterGroup({ disableClusteringAtZoom: 2 });

  const { map } = useLeaflet();

  useEffect(() => {
    markerList?.forEach(({ lat, long, icon = '', markerTooltip, popupContent }) => {
      L.marker(new L.LatLng(lat, long), {
        icon: getMarkerIcon(icon, markerTooltip, String(lat), String(long)),
      })
        .bindPopup(popupContent)
        .addTo(mcg);
    });
    map?.addLayer(mcg);
  }, [markerList, map]);

  return null;
};

export default MarkerCluster;
