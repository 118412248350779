import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import moment from 'moment';
import {
  FC,
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GET_LIST } from 'react-admin';

import { parseJSON, SERVER_DATE_TIME_FORMAT } from '../../core/configProvider';
import {
  clone,
  convertDateToBigCalenderReadableFormat,
  isEmpty,
  isEmptyObject,
} from '../../helper/data-helper';
import jalaliLocalizer from '../../helper/localizers-jalaali-calendar/jalali-moment';
import { getServices } from '../../helper/MetaHelper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  FilterFormFieldInterface,
} from '../../type/actor-setup';

import { CalendarEventInterface } from '../big-calendar';
import { SelectedService } from '../form';
import ReportCalendarView from './report-calendar.view';
import { FilterValueStructureEnum } from '../filter-form/filter-form.helper';
import { updateUrlQueryParams } from '../../helper/general-function-helper';

import type { ReportCalendarInterface } from './report-calendar.type';
import type { FilterItemBaseType } from '../filter-form';
import type {
  ActionItemInterface,
  CalendarConfigFromMetaDataInterface,
  MetaDataParametersInterface,
} from '../../helper/Types';

const ReportCalendarController: FC<ReportCalendarInterface> = memo(props => {
  const { metaData, resource } = props;

  // prettier-ignore
  const [calendarFilters, setCalendarFilters] = useState<Record<string, FilterItemBaseType>>({
    date: ['date', 'equal', new Date().toISOString()],
    type: ['type', 'equal', 'month'],
  });

  const [listHolidays, setListHolidays] = useState<Record<string, unknown>[]>([]);
  const [anchorElForServiceMenu, setAnchorElForServiceMenu] =
    useState<EventTarget>();
  const [selectCalendarEvent, setSelectCalendarEvent] =
    useState<CalendarEventInterface>();
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [openMenuInResponsiveMode, setOpenMenuInResponsiveMode] =
    useState<boolean>(false);
  const [calendarEventsForFilterSection, setCalendarEventsForFilterSection] =
    useState<CalendarEventInterface[]>([]);

  let finalResource = '';
  const actorResource = actorGetActionValue('resources')!;
  if (!isEmpty(resource)) {
    finalResource = resource;
  } else if (!isEmptyObject(actorResource)) {
    finalResource = actorResource.current.value;
  } else {
    finalResource = `report/${metaData.reportId}`;
  }
  const reportId = finalResource.split('/')[1];
  const calendarFiltersRef = useRef<Record<string, FilterItemBaseType>>({});

  useEffect(() => {
    // prettier-ignore
    const onDispatchData: { actionName: keyof ActorActionList; id: symbol }[] = [];

    getOffDay();

    const _calendarFilters = { ...calendarFilters };
    const actorLocation = actorGetActionValue('urlInfo')!.location;
    const searchParamsFromUrl = new URLSearchParams(actorLocation.search ?? '');

    if (searchParamsFromUrl.has('filter')) {
      //prettier-ignore
      const urlFilters = parseJSON<FilterItemBaseType[]>(searchParamsFromUrl.get('filter') ?? '') ?? [];
      for (const filter of urlFilters) {
        _calendarFilters[filter[FilterValueStructureEnum.KEY]] = filter;
      }
    } else if (searchParamsFromUrl.has('type') && searchParamsFromUrl.has('date')) {
      // It's backward compatibility
      [...searchParamsFromUrl.entries()].map(urlParameters => {
        const urlParamValue = urlParameters[1].split(',');

        if (Array.isArray(urlParamValue)) {
          if (urlParamValue.length === 3) {
            _calendarFilters[urlParamValue[0]] = [
              urlParamValue[0],
              urlParamValue[1],
              urlParamValue[2],
            ];
          } else {
            _calendarFilters[urlParameters[0]] = [
              urlParameters[0],
              'equals',
              urlParamValue[0],
            ];
          }
        }
      });
    }

    setCalendarFilters({ ..._calendarFilters });

    let id = actorOnDispatch('loading', detail => {
      setIsLoading(Boolean(detail?.[finalResource]));
    });

    onDispatchData.push({
      id,
      actionName: 'loading',
    });

    id = actorOnDispatch(
      'filterFormFieldHiddenChanged',
      changedFilterRecord => {
        const field = changedFilterRecord[finalResource];
        setCalendarFilters(prev => {
          if (prev[field.key] != null) {
            delete prev[field.key];
          }

          return { ...prev };
        });
      },
      {
        preserve: false,
      },
    );

    onDispatchData.push({
      id,
      actionName: 'filterFormFieldHiddenChanged',
    });

    actorOnDispatch(
      'filterDataIsChanged',
      filterRecord => {
        const filters = filterRecord[finalResource];
        if (filters?.length === 0) {
          setCalendarFilters(prev => {
            for (const filterKey of Object.keys(prev)) {
              if (filterKey === 'date' || filterKey === 'type') continue;
              delete prev[filterKey];
            }

            return { ...prev };
          });

          return;
        }

        // else
        setCalendarFilters(prev => {
          for (const filter of filters) {
            // prettier-ignore
            prev[(filter as FilterItemBaseType)[FilterValueStructureEnum.KEY]] = filter as FilterItemBaseType;
          }

          return { ...prev };
        });
      },
      {
        preserve: false,
      },
    );

    onDispatchData.push({
      id,
      actionName: 'filterDataIsChanged',
    });

    return () => {
      for (const { id, actionName } of onDispatchData) {
        actorRemoveAction({
          actionName,
          listenerId: id,
        });
      }
    };
  }, []);

  useEffect(() => {
    calendarFiltersRef.current = calendarFilters;
    if (isEmptyObject(calendarFilters)) return;
    getCalendarData();
  }, [calendarFilters]);

  const calendarConfigForMetaData: Partial<CalendarConfigFromMetaDataInterface> =
    metaData.calendarConfig ?? {};
  const services: ActionItemInterface[] = getServices(metaData) ?? [];
  const visibleServices = services.filter(
    service =>
      service.uniqueId !== calendarConfigForMetaData.validationService &&
      service.uniqueId !== calendarConfigForMetaData.creationService,
  );

  const filterFieldList = useMemo(() => {
    return (
      metaData.parameters
        ?.filter(parameter => !parameter.isHidden)
        .reduce(
          (
            filterFieldList: FilterFormFieldInterface[],
            currentParameter: MetaDataParametersInterface,
          ) => {
            let filterValue = '';
            //prettier-ignore
            let filterOperator = currentParameter.defaultOperator.toLocaleLowerCase();
            if (calendarFilters[currentParameter.key] != null) {
              //prettier-ignore
              filterValue = calendarFilters[currentParameter.key][FilterValueStructureEnum.VALUE] as string;
              //prettier-ignore
              filterOperator = calendarFilters[currentParameter.key][FilterValueStructureEnum.OPERATOR]
            }

            /**
             * To get `key` later in the filtering process
             */
            currentParameter.field.key = currentParameter.key;

            filterFieldList.push({
              fieldData: currentParameter.field,
              value: [currentParameter.key, filterOperator, filterValue],
            });

            return filterFieldList;
          },
          [],
        ) ?? []
    );
  }, [metaData, calendarFilters]);

  /**
   *@function getOffDay
   *@returns {void} void
   */
  const getOffDay = useCallback((): void => {
    setIsLoading(true);
    actorDispatch(
      'crudAction',
      {
        entity: 'report',
        type: GET_LIST,
        resource: 'report/0c27d0e8-5de3-41bd-8289-6fcc29b26f16',
        requestParameters: {
          FromDate: null,
          ToDate: null,
          //per page -1 = total rows
          pagination: { perPage: -1 },
        },
        onSuccess: response => {
          setListHolidays([...response.data]);
          setIsLoading(false);
        },
        onFailure: (): void => {
          setIsLoading(false);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'ReportCalendarController => getOffDay',
      },
    );
  }, []);

  /**
   * this function add filters to state
   *@function addFilter
   * @param {Record<string, string | []>} newFilter
   * @returns {void}
   */
  const addFilter = useCallback(
    (newFilter: { date?: string; type?: string }): void => {
      const _calendarFilters = { ...calendarFilters };
      if (newFilter.date != null) {
        _calendarFilters.date = ['date', 'equal', newFilter.date];
      }

      if (newFilter.type != null) {
        _calendarFilters.type = ['type', 'equal', newFilter.type];
      }

      setCalendarFilters(prev => ({ ...prev, ..._calendarFilters }));
    },
    [calendarFilters],
  );

  /**
   * this function get calendar data form server
   * @function getCalendarData
   * @returns {void}
   */
  const getCalendarData = useCallback((): void => {
    const _filterRecord = clone(calendarFiltersRef.current);
    const dateFilterValue = _filterRecord['date'];
    const _visibleDays: Date[] = jalaliLocalizer.visibleDays(
      new Date(dateFilterValue[FilterValueStructureEnum.VALUE] as string),
    );
    const fromDate = moment(_visibleDays[0]).format(SERVER_DATE_TIME_FORMAT);
    const toDate = moment(_visibleDays[_visibleDays.length - 1]).format(
      SERVER_DATE_TIME_FORMAT,
    );

    updateUrlQueryParams({ filter: Object.values(_filterRecord) });

    delete _filterRecord['date'];
    delete _filterRecord['type'];

    const filters = [
      ['fromDate', 'equal', fromDate],
      'and',
      ['toDate', 'equal', toDate],
      'and',
      ...Object.values(_filterRecord),
    ];

    actorDispatch('getCalendarData', {
      reportId,
      filters: filters,
      successCallback: successCallbackGetCalendarData,
    });
  }, []);

  /**
   * this function set event form api
   *@function successCallbackGetCalendarData
   * @param {Record<string,unknown>} response
   * @returns {void}
   */
  const successCallbackGetCalendarData = (response: Record<string, unknown>) => {
    const { showEvent, fromDate, toDate, eventColor, link, image, primary } =
      calendarConfigForMetaData;
    const data = response.data as [];
    const newEvents = data?.map((item: Record<string, unknown>) => ({
      id: lodashGet(item, primary),
      title: lodashGet(item, showEvent),
      allDay: false,
      start: convertDateToBigCalenderReadableFormat(lodashGet(item, fromDate)),
      end: convertDateToBigCalenderReadableFormat(lodashGet(item, toDate)),
      hexColor: `#${lodashGet(item, eventColor)}`,
      link: lodashGet(item, link),
      image: lodashGet(item, image),
      ...item,
    }));
    setCalendarEvents(newEvents);
    setCalendarEventsForFilterSection(newEvents);
  };

  /**
   * this callback for change range date in calendar
   *@function onRangeChangeCallback
   * @param {Date} date
   * @param {string} type
   * @param {string}position
   * @returns {void}
   */
  const onRangeChangeCallback = (
    date: Date,
    type: string,
    position: string,
  ): void => {
    const stringDate = date?.toISOString();
    addFilter({ date: stringDate, type });
  };

  /**
   * this function handle type of big calendar view
   *@function onViewCallback
   * @param {string} type
   * @returns {void}
   */
  const onViewCallback = (type: string): void => {
    addFilter({ type });
  };

  /**
   * this function handle update event
   *@function updateCalendarEventCallback
   * @param {CalendarEventInterface} updatedEvent
   * @param {void} failureCallback
   * @returns {Function}
   */
  const updateCalendarEventCallback = (
    updatedEvent: CalendarEventInterface,
    failureCallback: () => void,
  ) => {
    const { validationService, primary } = calendarConfigForMetaData;
    actorDispatch('runActionsService', {
      actionUniqueId: validationService,
      params: {
        fromdate: moment(updatedEvent.start as Date).format(SERVER_DATE_TIME_FORMAT),
        todate: moment(updatedEvent.end as Date).format(SERVER_DATE_TIME_FORMAT),
        [primary ?? '']: updatedEvent?.[primary ?? ''],
      },
      failureCallback: () => {
        failureCallback?.();
      },
    });
  };

  /**
   * this function handle click in calendar slot
   *@function onSelectSlotCallback
   * @param { Omit<CalendarEventInterface, "allDay">} calendarEvent
   * @returns {void}
   */
  const onSelectSlotCallback = (calendarEvent: CalendarEventInterface): void => {
    const { creationService } = calendarConfigForMetaData;
    const service = metaData.actions?.find(
      action => (action as SelectedService).uniqueId === creationService,
    );

    //send filter form to service dialog as serviceParams
    const filterFormDataForServiceDialog = {};
    Object.keys(calendarFilters).map(key => {
      if (
        calendarFilters[key] &&
        Array.isArray(calendarFilters[key]) &&
        !isEmpty(calendarFilters[key][2])
      )
        filterFormDataForServiceDialog[key] = calendarFilters[key][2];
    });

    actorDispatch('runServiceDirectly', {
      service: service,
      serviceParams: {
        ...filterFormDataForServiceDialog,
        fromdate: moment(calendarEvent?.start).format(SERVER_DATE_TIME_FORMAT),
        todate: calendarEvent?.allDay
          ? moment(calendarEvent?.end)
              .add(-1, 'days')
              .format(SERVER_DATE_TIME_FORMAT)
          : moment(calendarEvent?.end).format(SERVER_DATE_TIME_FORMAT),
      },
    });
  };

  /**
   * this function handle right click in event on calendar
   *@function onContextMenuEvent
   * @param {MouseEvent<HTMLDivElement>} eventHtmlElement
   * @param {CalendarEventInterface} calendarEvent
   * @returns {void}
   */
  const onContextMenuEvent = (
    eventHtmlElement: MouseEvent<HTMLDivElement>,
    calendarEvent: CalendarEventInterface,
  ): void => {
    eventHtmlElement.preventDefault();
    setSelectCalendarEvent(calendarEvent);
    setAnchorElForServiceMenu(eventHtmlElement.currentTarget);
  };

  /**
   * empty state callback form service dialog
   * @function customEmptyState
   * @returns {void}
   */
  const customEmptyState = (): void => {
    setAnchorElForServiceMenu(undefined);
  };

  const summaryCalendarEvent = calendarConfigForMetaData?.summaryColumns?.map(
    item => {
      return lodashFind(metaData?.tabsColumns?.table, {
        name: item,
      });
    },
  );

  return (
    <ReportCalendarView
      openMenuInResponsiveMode={openMenuInResponsiveMode}
      metaData={metaData}
      setOpenMenuInResponsiveMode={setOpenMenuInResponsiveMode}
      addFilter={addFilter}
      filters={calendarFilters}
      calendarEvents={calendarEvents}
      setCalendarEvents={setCalendarEvents}
      isLoading={isLoading}
      resource={finalResource}
      onRangeChangeCallback={onRangeChangeCallback}
      updateCalendarEventCallback={updateCalendarEventCallback}
      onSelectSlotCallback={onSelectSlotCallback}
      anchorEl={anchorElForServiceMenu}
      selectedCalendarEvent={selectCalendarEvent}
      eventCustomProps={{
        summaryCalendarEvent,
        onContextMenuEvent,
      }}
      visibleServices={visibleServices}
      calendarEventsForFilterSection={calendarEventsForFilterSection}
      getCalendarData={getCalendarData}
      customEmptyState={customEmptyState}
      onViewCallback={onViewCallback}
      filterFieldList={filterFieldList}
      listHolidays={listHolidays}
    />
  );
});

export default ReportCalendarController;
