import { useEffect, useState, useRef, useCallback, useMemo, type FC } from 'react';
import { GET_LIST } from 'react-admin';

import CreateEditRecordPage from './CreateEditRecordPage';
import {
  FormKeyMode,
  RecordKeyMode,
  actorDispatch,
  actorOnDispatch,
  actorSetActionValue,
  waitForAction,
} from '../type/actor-setup';
import { CrudGetListApiPayload } from '../type/data-provider';
import LoadingBox from '../component/loading-box';

import type { GeneralMetaData } from '../helper/Types';
import { removeOnDispatches } from '../helper/general-function-helper';

interface SingleRecordPageInterface {
  match: {
    params: {
      moduleName: string;
      moduleTableName: string;
    };
  };
}

const SingleRecordPage: FC<SingleRecordPageInterface> = props => {
  const { match } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const currentMetaDataRef = useRef<GeneralMetaData | null>(null);
  const currentRecordRef = useRef<Record<string, unknown> | null>(null);
  const resourceRef = useRef<string | null>(null);

  useEffect(() => {
    const listenerId = actorOnDispatch(
      'metaData',
      async metaData => {
        if (!resourceRef.current) return;

        await waitForAction(
          'showSettingsLoading',
          showSettingsLoading => showSettingsLoading === false,
        );

        currentMetaDataRef.current =
          (metaData[resourceRef.current] as GeneralMetaData | undefined) ?? null;

        if (currentMetaDataRef.current != null) {
          getRecordInfo();
        }
      },
      { preserve: false },
    );

    return () => {
      removeOnDispatches([
        {
          actionName: 'metaData',
          listenerId,
        },
      ]);
    };
  }, []);

  /**
   * get record from API and check record id.
   * @returns {void}
   */
  const getRecordInfo = useCallback((): void => {
    if (!resourceRef.current) return;

    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        entity: 'singleRecordPage',
        resource: resourceRef.current,
        onSuccess: response => {
          //if we have many records,use first record for formController
          currentRecordRef.current = response?.data?.[0];
          const rowId = currentRecordRef.current?.id ?? null;

          actorSetActionValue('urlInfo', {
            params: { ...match.params, id: rowId },
          });

          actorSetActionValue('record', currentRecordRef.current, {
            path: `${resourceRef.current}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
            replaceAll: true,
          });

          setIsLoading(false);
        },
      } as CrudGetListApiPayload,
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'SingleRecordPage => getRecordInfo',
      },
    );
  }, []);

  const resetComponentData = useCallback(() => {
    currentMetaDataRef.current = null;
    currentRecordRef.current = null;

    setIsLoading(true);
  }, []);

  resourceRef.current = useMemo(() => {
    if (match.params == null) return null;

    resetComponentData();

    // I didn't use `destructing` because I didn't want two new variables(i.e. `moduleName` and `moduleTableName`)
    return `${match.params.moduleName}/${match.params.moduleTableName}`;
  }, [match]);

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <CreateEditRecordPage
      {...props}
      metaData={currentMetaDataRef.current}
      record={currentRecordRef.current ?? {}}
      isSingleRecord
    />
  );
};

export default SingleRecordPage;
