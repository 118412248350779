import React from 'react';
import type { FC } from 'react';
import {
  Typography,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from './filter-save-dialog.style';
import type { FilterSaveDialogViewPropsInterface } from './filter-save-dialog.type';

export const FilterSaveDialogView: FC<
  FilterSaveDialogViewPropsInterface
> = props => {
  const { translate, closeDialogHandler, handleNameChange, saveFilterHandler } =
    props;

  const classes = useStyles();

  return (
    <>
      <DialogTitle id="show-image-dialog-title">
        {translate('filter.saveFilter')}
      </DialogTitle>
      <DialogContent>
        <Typography>{translate('filter.pleaseGiveANameToYourNewFilter')}</Typography>
        <TextField
          label={translate('filter.name')}
          onChange={handleNameChange}
          fullWidth
          autoFocus
          id="saveFilterDialogTextField"
        />
        <Button
          color="primary"
          className={classes.button}
          variant="contained"
          onClick={saveFilterHandler}
          id="saveFilterDialogSaveButton"
        >
          {translate('ra.action.save')}
        </Button>
        <Button
          className={classNames(classes.button, classes.buttonCancel)}
          onClick={closeDialogHandler}
        >
          {translate('ra.action.cancel')}
        </Button>
      </DialogContent>
    </>
  );
};
