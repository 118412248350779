import { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import lodashFind from 'lodash/find';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Title, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Icon, InputBase } from '@material-ui/core';
import lodashGet from 'lodash/get';

import { isStringWthStarsMatch } from '../helper/TextHelper';
import LocaleHoc from '../component/LocaleHoc';
import { apiUrl, apiVersion } from '../core/data-Provider.helper';
import { getValue, SESSION_ID } from '../core/configProvider';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../type/actor-setup';
import { callQuickAccess, openNewTab } from '../helper/QuickAccessHelper';
import { handleGetMenuItemsForSidebar } from '../component/menu/menu.helper';

const styles = theme => ({
  menuPageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  iframeContainer: {
    height: '100%',
  },

  cardContent: {
    padding: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    overflow: 'auto',
  },

  card: {
    display: 'flex',
    margin: 16,
    textAlign: 'center',
    flexGrow: 1,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    [theme.breakpoints.up('xs')]: {
      width: '39%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '39%',
    },
    [theme.breakpoints.up('md')]: {
      width: '28%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '15%',
    },

    [theme.breakpoints.down('xs')]: {
      margin: 10,
    },
  },

  cardActionArea: {
    fontFamily: theme.typography.fontFamily,
    padding: '25px 0',
  },

  menuLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },

  containerSearch: {
    backgroundColor: '#ffffff',
    display: 'inline-flex',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    padding: '0 5px',
  },

  iconSearch: {
    color: theme.palette.text.hint,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },

  input: {
    marginLeft: 8,
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
});

class MenuPage extends PureComponent {
  state = { searchMenu: '', menu: [], menuData: actorGetActionValue('menuData') };

  componentDidMount() {
    
    actorOnDispatch('menuData', _menuData => {
      this.setState({ menuData: _menuData });
    });

    if(!this.state.menuData) return 
    const { items, isLoadedOnce, isLoading } = this.state.menuData;
    
    if (!isLoadedOnce && !isLoading && !items?.length) {
      handleGetMenuItemsForSidebar();
    } else {
      this.findPageFromMenu({ match: this.props.match, menuArray: items });
    }

  }

  componentDidUpdate() {
    const { items } = this.state.menuData;
    if (items?.length) {
      this.findPageFromMenu({ match: this.props.match, menuArray: items });
    }
  }

  findPageFromMenu = async ({ match, menuArray }) => {
    const menuList = menuArray || [];

    const menu = lodashFind(menuList, {
      id: parseInt(match.params.id, 10),
    });

    if (menu) {
      if (menu.url && menu.type && menu.type === 'tableQuickAccess') {
        const tableQuickAccessUrl = await callQuickAccess(menu.url.split('/')?.at(-1));
  
        openNewTab(tableQuickAccessUrl);
      }

      this.setState({ menu });
    }
  };
  render() {
    const { classes, translate, locale } = this.props;
    const { menu, searchMenu } = this.state;
    const { list, type, url, data } = menu;

    // In `wms` => menuItems are in `menu.data`
    const menuList = data ?? list;

    if (url && type && type === 'iFrame') {
      return <iframe className={classes.iframeContainer} src={url} />;
    } else if (url && type && type === 'dashboard') {
      const sessionId = getValue(SESSION_ID);
      const preparedUrl = `${apiUrl}/oauth2/${sessionId}/${apiVersion}/${url}`;

      return <iframe className={classes.iframeContainer} src={preparedUrl} />;
    }
    const pageTitle =
      actorGetActionValue('reactAdminHelpers')?.locale ?? menu.pageName;
      
    actorDispatch(
      'setDocumentTitle',
      {
        metaData: null,
        recordTitle: pageTitle,
        locale,
      },
      { replaceAll: true },
    );

    return (
      <>
        {!!menuList && (
          <Card className={classes.menuPageContainer}>
            <Title
              title={
                <Fragment>
                  <div className={classes.containerSearch}>
                    <Icon className={classes.iconSearch}>search</Icon>
                    <InputBase
                      className={classes.input}
                      placeholder={translate('ra.action.search')}
                      value={searchMenu}
                      onChange={event =>
                        this.setState({ searchMenu: event.target.value })
                      }
                      inputProps={{ 'aria-label': translate('ra.action.search') }}
                      autoFocus
                    />
                  </div>
                </Fragment>
              }
            />
            <CardContent className={classes.cardContent}>
              {menuList
                ?.filter(itemElement => {
                  if (!itemElement) {
                    return false;
                  }

                  // prettier-ignore
                  const isMatch1 = lodashGet(itemElement, ['translatedTitle', locale], false)  && isStringWthStarsMatch(lodashGet(itemElement, ['translatedTitle', locale]), searchMenu);
                  const isMatch2 = isStringWthStarsMatch(
                    itemElement.title,
                    searchMenu,
                  );

                  return isMatch1 || isMatch2;
                })
                ?.map(item => (
                  <Link key={item.id} className={classes.card} to={`/${item.route}`}>
                    <CardActionArea className={classes.cardActionArea}>
                      {lodashGet(
                        item,
                        ['translatedTitle', locale],
                        lodashGet(item, ['title']),
                      )}
                    </CardActionArea>
                  </Link>
                ))}
            </CardContent>
          </Card>
        )}
      </>
    );
  }
}

MenuPage.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default compose(
  translate,
  withStyles(styles, { withTheme: true }),
  LocaleHoc,
)(MenuPage);
