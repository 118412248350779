import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { clone } from '../../../../helper/data-helper';
import { TodoTaskItem } from '../todo-task-item';
import { TodoTasksCustomAccordion } from '../todo-tasks-custom-accordion';

import type { FC } from 'react';
import type { TodoTasksContainerProps } from './todo-tasks-container.type';

const TodoTasksContainer: FC<TodoTasksContainerProps> = props => {
  const { tasks, accordionSummary } = props;

  let lastItemRowOrder: number | null = null;

  if (accordionSummary) {
    return (
      <TodoTasksCustomAccordion accordionSummary={accordionSummary}>
        <DndProvider backend={HTML5Backend}>
          {tasks.map((task, index) => {
            const clonedLastItemRowOrder = clone(lastItemRowOrder);
            lastItemRowOrder = task.roworder; // for next round

            return (
              <TodoTaskItem
                task={task}
                index={index}
                lastItemRowOrder={clonedLastItemRowOrder}
              />
            );
          })}
        </DndProvider>
      </TodoTasksCustomAccordion>
    );
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        {tasks.map((task, index) => {
          const clonedLastItemRowOrder = clone(lastItemRowOrder);
          lastItemRowOrder = task.roworder; // for next round

          return (
            <TodoTaskItem
              task={task}
              index={index}
              lastItemRowOrder={clonedLastItemRowOrder}
            />
          );
        })}
      </DndProvider>
    );
  }
};

export default TodoTasksContainer;
