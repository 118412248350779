import React from 'react';
import { IconButton, Tooltip, Icon } from '@material-ui/core';

import { useStyles } from './filter-save-button.style';

import type { FC } from 'react';
import type { FilterSaveButtonViewPropsInterface } from './filter-save-button.type';

const FilterSaveButtonView: FC<FilterSaveButtonViewPropsInterface> = props => {
  const { disabled, translate, openDialogHandler } = props;

  const classes = useStyles();

  return (
    <div>
      {disabled ? (
        <span className={classes.disableSave}>{translate('filter.saveFilter')}</span>
      ) : (
        <span
          className={classes.saveText}
          id="saveFilterButton"
          onClick={openDialogHandler}
          style={{ fontSize: 12 }}
        >
          {translate('filter.saveFilter')}
        </span>
      )}
    </div>
  );
};

export default FilterSaveButtonView;
