import { FC, memo, useEffect, useImperativeHandle, useState } from 'react';

import { actorGetActionValue } from '../../../../type/actor-setup';
import JalaliDateInputController from '../../../dynamic-input/jalali-date-input/jalali-date-input.controller';
import { useStyles } from '../../grid.style';

import type { GridDateInputInterface } from './grid-date-input.type';
import { InputAppearanceCharacteristics } from '../../../../helper/meta-helper.type';

const GridDateInputController: FC<GridDateInputInterface> = props => {
  const { focusOnLastInput, onChangeInput, field, customInputRef } = props;
  const classes = useStyles();

  const [internalValue, setInternalValue] = useState('');

  const [appearanceCharacteristics, setAppearanceCharacteristics] =
    useState<InputAppearanceCharacteristics>();

  const updateInputCharacteristics = (
    value: InputAppearanceCharacteristics,
  ): void => {
    setAppearanceCharacteristics(value);
  };

  useImperativeHandle(customInputRef, () => ({
    updateInputCharacteristics,
  }));

  useEffect(() => {
    const gridFormData = actorGetActionValue('gridFormData')!;
    const value = gridFormData[field.name] ?? '';
    setInternalValue(value as string);
  }, []);

  /**
   * handle change date
   * @function handleChangeDate
   * @param { string } type
   * @param { unknown } payload
   * @returns { void } void
   */
  const handleChangeDate = (type, payload): void => {
    const { fieldName, value } = payload;
    onChangeInput({ fieldName, value });
    setInternalValue(value);
    focusOnLastInput();
  };

  return (
    <div className={classes.gridInputContainer}>
      <JalaliDateInputController
        formActionsHandler={handleChangeDate}
        value={internalValue}
        disabled={
          appearanceCharacteristics?.enable === false ||
          appearanceCharacteristics?.visible === false
        }
        field={field}
        DateInputInGrid
      />
    </div>
  );
};

export default memo(GridDateInputController);
