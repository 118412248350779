import { type ReactElement, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import lodashMap from 'lodash/map';
import momentJalaali from 'moment-jalaali';
import clsx from 'classnames';

import { Button, Chip, Divider, Icon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip/index';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ReplyIcon from '@material-ui/icons/Reply';
import ForwardIcon from '@material-ui/icons/CallMade';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { removePageBreakFromHtmlString } from '../../../rich-text-editor-field/rich-text-editor-field.helper';
import { isEmpty } from '../../../../helper/data-helper';
import { MailDetailSkeleton } from '../../../skeleton/mail-detail-skeleton.view';
import MoreDetailBodyView from '../multiple-mail-detail-body/mail-summary/more-detail/more-detail-body.view';

import { useStyles } from './mail-detail-body.style';
import './mail-detail-body-css-style.css';

import type {
  AttachedFile,
  MultipleMailBodyViewInterface,
} from './mail-detail-body.type';
import { parseJSON } from '../../../../core/configProvider';
import { LightBoxDialog } from '../../../dialogs-stack/light-box-dialog';

const MultipleMailBodyView = (
  props: MultipleMailBodyViewInterface,
): ReactElement => {
  const {
    selectedMail,
    selectedMailLoading,
    onReplyToMail,
    onReplyAllMail,
    onReferenceMail,
    onForwardMailHandler,
    apiUrl,
    downloadIconClickHandler,
    downloadAllAttachmentsHandler,
  } = props;
  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();

  const [isOpenLightBox, setIsOpenLightBox] = useState<boolean>(false);

  momentJalaali.locale(locale);

  if (!selectedMail || selectedMailLoading) {
    return <MailDetailSkeleton />;
  }

  const parsedAttachedFiles: AttachedFile[] | null = parseJSON(
    selectedMail.attachfiles,
  );

  const images = Array.isArray(parsedAttachedFiles)
    ? parsedAttachedFiles.map(img => {
        const imgUrl = `${apiUrl}/${img.filePath}`;
        return {
          url: imgUrl,
          title: (
            <a onClick={downloadIconClickHandler(img)}>
              {translate('general.download')}: {img.realFileName}
            </a>
          ),
        };
      })
    : [];

  return (
    <Box className={classes.container} data-test="mail-detail-body-container">
      {isOpenLightBox && (
        <LightBoxDialog
          dialogData={{ imageData: images ?? [], startIndex: 0 }}
          onClose={() => setIsOpenLightBox(false)}
        />
      )}

      {/* show CC and BCC in print mode */}
      <Box
        color="primary.main"
        fontWeight="light"
        fontSize={14}
        lineHeight={'30px'}
        className={'onlyShowInPrintMode'}
      >
        <MoreDetailBodyView mail={selectedMail} isMainMail={true} />
      </Box>

      {!isEmpty(selectedMail.messageparaph) && (
        <Box
          color="primary.main"
          fontWeight="light"
          fontSize={{ xs: 12, md: 14 }}
          lineHeight={'30px'}
        >
          {selectedMail.messageparaph}
        </Box>
      )}
      {!isEmpty(selectedMail.paraphtext) && (
        <Box
          color="primary.main"
          fontWeight="light"
          fontSize={{ xs: 12, md: 14 }}
          lineHeight={'30px'}
        >
          <div
            className={'ck-content'}
            dangerouslySetInnerHTML={{
              __html: removePageBreakFromHtmlString(selectedMail.paraphtext),
            }}
          />
        </Box>
      )}

      <Box
        className={classes.editorContainer}
        color="text.primary"
        fontSize={{ xs: 14, xl: 16 }}
        data-test="mail-detail-message-body-container"
      >
        <div className={classes.messageBodyContainer}>
          <div
            className={'ck-content'}
            dangerouslySetInnerHTML={{
              __html: removePageBreakFromHtmlString(selectedMail.messagebody),
            }}
          />
        </div>
      </Box>
      <div>
        {Boolean(selectedMail.hasattachment) &&
          Array.isArray(parsedAttachedFiles) && (
            <Box color="text.secondary">
              <Divider />
              <Box className={classes.attachmentContainer}>
                <Tooltip title={translate('mail.attachedFiles')}>
                  <AttachFileIcon />
                </Tooltip>
                {lodashMap(parsedAttachedFiles, (file: AttachedFile) => (
                  <Box>
                    <Chip
                      label={file.realFileName}
                      component="div"
                      className={classes.chip}
                      onClick={downloadIconClickHandler(file)}
                      icon={
                        <Icon fontSize="medium" style={{ marginLeft: '5px' }}>
                          cloud_download
                        </Icon>
                      }
                    />
                  </Box>
                ))}

                {parsedAttachedFiles.length > 1 && (
                  <Box>
                    <Chip
                      label={translate('mail.downloadAll')}
                      className={classes.chip}
                      onClick={downloadAllAttachmentsHandler(parsedAttachedFiles)}
                      icon={<ArrowDownwardIcon>download all</ArrowDownwardIcon>}
                    ></Chip>
                  </Box>
                )}
                <Box mx={'0.25rem'}>
                  <Chip
                    label={translate('mail.seeAll')}
                    className={classes.chip}
                    onClick={() => setIsOpenLightBox(true)}
                    icon={<RemoveRedEyeIcon />}
                  ></Chip>
                </Box>
              </Box>
            </Box>
          )}
      </div>

      <div>
        <div className={'dontShowInPrint'}>
          {!!selectedMail.isreplyactive && (
            <>
              <Button
                onClick={onReferenceMail}
                variant="contained"
                color="secondary"
                className={clsx(classes.btnRoot, classes.referenceBtn)}
                data-test="mail-detail-refer-button"
              >
                {translate('mail.toReference')}
              </Button>
              <Button
                onClick={onReplyToMail}
                variant="outlined"
                endIcon={<ReplyIcon />}
                className={classes.replayBtn}
                data-test="mail-detail-reply-button"
              >
                {translate('mail.reply')}
              </Button>
              <Button
                onClick={onReplyAllMail}
                variant="outlined"
                endIcon={<ReplyAllIcon />}
                className={classes.replayBtn}
                data-test="mail-detail-reply-all-button"
              >
                {translate('mail.replyAll')}
              </Button>
            </>
          )}

          <Tooltip title={translate('mail.forward')}>
            <Button
              onClick={onForwardMailHandler}
              variant="outlined"
              endIcon={<ForwardIcon />}
              className={classes.replayBtn}
              data-test="mail-detail-forward-button"
            >
              {translate('mail.forward')}
            </Button>
          </Tooltip>
        </div>
      </div>
    </Box>
  );
};

export default MultipleMailBodyView;
