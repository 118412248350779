import type { LocationInterface } from './location-input.type';

/**
 * @function latLngToString
 * @params {location} LocationInterface | null
 * @returns string POINT(35.312603835200434 46.99573516845704)
 */
export const latLngToString = (location: LocationInterface | null): string => {
  return location ? `POINT(${location?.lat} ${location?.lng})` : '';
};

/**
 * centerLocation: tehran
 */
export const centerLocation: LocationInterface = {
  lat: 35.6839961,
  lng: 51.3973511,
};

/**
 * @function exportLatLngFromGeography
 * @params {Geography} geography like POINT(35.312603835200434 46.99573516845704)
 * @returns {void} void
 */
export const exportLatLngFromGeography = (
  geography: string | null,
): LocationInterface | null => {
  try {
    if (geography) {
      const location = geography
        .replaceAll(/(point)/gi, '')
        .replaceAll(/\(|\)/g, '')
        .trim()
        .split(' ');

      if (!Array.isArray(location) || location.length !== 2) {
        throw new Error('Invalid location format');
      }

      return {
        lat: parseFloat(location[0]),
        lng: parseFloat(location[1]),
      };
    }

    return null;
  } catch (error) {
    console.error('Error: %o', error);
    return null;
  }
};
