import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  menuItem: {
    width: '100%',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },

  button: {
    marginTop: 20,
  },

  buttonCancel: {
    marginLeft: 10,
    marginRight: 10,
  },

  IconButton: {
    padding: 7,
    margin: '2px 5px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 7px',
      margin: 0,
    },
  },

  icon: {
    fontSize: 19,
  },

  filterName: {
    flexGrow: 1,
  },
  textTableFilter: {
    marginLeft: 2,
    marginRight: 2,
  },
  mainContainerTableGrid: {
    display: 'flex',
    alignItems: 'center',
  },
}));
