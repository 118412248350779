import { FC, memo, useCallback } from 'react';
import { SearchListInterface } from './search-list.type';
import SearchListView from './search-list.view';

import type { ChatItemInterface, SelectedChatType } from '../../chat-section.type';

const SearchListController: FC<SearchListInterface> = memo(props => {
  const { contacts, messages, onChatSelect, selectedUser, searchValue } = props;

  /**
   * @function handleOnUserSelect
   * @param { ContactInterface } user
   * @returns { void }
   */
  const chatSelectHandler = useCallback((chat: SelectedChatType): void => {
    onChatSelect(chat as ChatItemInterface);
  }, []);

  /**
   * @function onSelectMessage
   * @param { ChatItemInterface } message
   * @returns { void }
   */
  const onSelectMessage = useCallback((message: SelectedChatType): void => {
    onChatSelect(message as ChatItemInterface);
    // !isEmptyObject(user) && actorDispatch('foundedMessage', user);
  }, []);

  return (
    <SearchListView
      contacts={contacts}
      messages={messages}
      onChatSelect={chatSelectHandler}
      selectedUser={selectedUser}
      onSelectMessage={onSelectMessage}
      searchValue={searchValue}
    />
  );
});

export default SearchListController;
