import { ReactElement } from 'react';
import { LongtextInputViewInterface } from './longtext-input.type';
import { useStyles } from './longtext-input.style';
import { TextField, Tooltip, useTheme } from '@material-ui/core';
import InputBase from '../../input-base';

const LongtextInputView = (props: LongtextInputViewInterface): ReactElement => {
  const {
    getRef,
    field,
    value,
    label,
    hint,
    inputMessage,
    visibleClass,
    handleBlur,
    handleChange,
    handleFocus,
    disabled,
  } = props;
  const classes = useStyles({ messageType: inputMessage?.messageType });

  const theme = useTheme();
  const { name, required, numberOfLines, customOption } = field;

  return (
    <InputBase
      className={`${visibleClass}`}
      label={label}
      inputMessage={inputMessage}
      required={required}
      hint={hint}
      field={field}
    >
      <Tooltip
        title={customOption.additionalDataFieldAlt}
        placement={theme.direction === 'rtl' ? 'left' : 'right'}
      >
        <TextField
          data-test-field-name={name}
          ref={getRef}
          value={value ?? ''}
          name={name}
          required={required}
          margin="normal"
          multiline
          maxRows={numberOfLines}
          classes={{
            root: classes.root,
          }}
          InputProps={{
            classes: {
              root: classes.outlineRoot,
              notchedOutline: classes.notchedOutline,
              input: classes.input,
              multiline: classes.multiline,
              disabled: classes.disabled,
            },
            style: { height: '100% !important' },
          }}
          inputProps={{
            style: customOption.customStyleAdditionalDataField,
          }}
          variant="outlined"
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          disabled={disabled}
        />
      </Tooltip>
    </InputBase>
  );
};

export default LongtextInputView;
