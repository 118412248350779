import { actorDispatch } from '../../../type/actor-setup';

import type { LocationInterface } from '../../dynamic-input/location-input/location-input.type';

const ltrChars = `A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' +
'\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF`;

const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';

// eslint-disable-next-line no-misleading-character-class
const rtlDirCheck = new RegExp('^[^' + ltrChars + ']*[' + rtlChars + ']');

/**
 * it detects if user is typing in RTL language or not
 * @function checkIsRtlLanguage
 * @param { string } str
 * @returns { boolean }
 */
export const checkIsRtlLanguage = (str: string): boolean => {
  return rtlDirCheck.test(str);
};

/**
 * open location dialog
 * @function openLocationDialog
 * @param {(location: LocationInterface, imageUrl: string) => void} handleChooseLocation
 * @returns {void} void
 */
export const openLocationDialog = (
  handleChooseLocation: (location: LocationInterface, imageUrl: string) => void,
): void => {
  actorDispatch(
    'quickDialog',
    {
      sendLocationDialogIsOpen: true,
      data: {
        onSelectLocation: handleChooseLocation,
      },
    },
    {
      replaceAll: true,
    },
  );
};
