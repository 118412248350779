import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  scrollTo: {
    display: 'flex',
    backgroundColor: '#e3f2fd',
    opacity: 1,
    boxShadow: `inset 6px 0px 0px -1px ${theme.palette.primary.main}`,
    padding: 12,
    zIndex: 2,
    '&:hover #noteAction': {
      opacity: 1,
      transition: 'all 200ms',
    },
  },

  iconPin: { width: 20, height: 20, marginRight: 2 },
  iconPinDisable: { width: 20, height: 20, marginRight: 2, opacity: 0.3 },
  noteItem: {
    zIndex: 3,
    display: 'flex',
    flexDirection: 'row',
    padding: 12,
    transition: 'all 200ms',
    borderBottom: `1px solid ${grey[300]}`,
    '&:hover #noteAction': {
      opacity: 1,
      transition: 'all 200ms',
    },
    '&:hover': {
      backgroundColor: grey[50],
      boxShadow: `inset 6px 0px 0px -1px ${theme.palette.primary.main}`,
      transition: 'all 200ms',
    },
  },

  noteItemInSidebar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    wordBreak: 'break-word',
    '&:hover #noteAction': {
      opacity: 1,
      transition: 'all 200ms',
    },
  },

  noteAction: {
    alignSelf: 'flex-start',
    opacity: 0,
    transition: 'all 200ms',
    zIndex: 5,
  },
  noteActionPin: {
    alignSelf: 'flex-start',
    opacity: 1,
    transition: 'all 200ms',
    zIndex: 5,
    '&:hover #noteAction': {
      transition: 'all 200ms',
    },
  },
  noteContent: {
    marginLeft: '0.5em',
    flexGrow: 1,
  },

  noteContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5em',
  },

  author: {
    fontSize: 13,
    fontWeight: 400,
    paddingBottom: '0.3em',
  },

  noteTime: {
    fontSize: 12,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  note: {
    fontSize: 11,
    borderRadius: 2,
    margin: '5px 0',
    whiteSpace: 'pre-line',
  },

  noteInStream: {
    fontSize: 12,
    padding: 6,
    marginBottom: '0.5em',
    backgroundColor: grey[50],
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.23)',
  },

  showMoreButton: {
    marginTop: '0.5em',
    cursor: 'pointer',
    fontSize: 11,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.38)',
  },

  attachmentContainer: {
    display: 'flex',
    marginTop: 10,
  },

  media: {
    width: 25,
    height: 25,
    margin: '0 5px',
    cursor: 'pointer',
    fontSize: 10,
  },

  parentLink: {
    color: '#212121',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
