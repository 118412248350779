import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';

import { useStyles } from './service-dialog-form.style';
import { DynamicInput } from '../../dynamic-input';

import type { ServiceDialogFormViewInterface } from './service-dialog-form.type';
import type { FieldType } from '../../../helper/Types';

const ServiceDialogFormView = (
  props: ServiceDialogFormViewInterface,
): ReactElement => {
  const { fields, isServiceMode } = props;
  const locale = useLocale();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <fieldset>
        {fields?.map((field: FieldType | undefined) =>
          field ? (
            <div className={classes.inputContainer}>
              <DynamicInput
                key={field.id}
                field={field}
                currentTabIndex={0}
                label={lodashGet(field, ['translatedCaption', locale], field.name)}
                isServiceMode={isServiceMode}
              />
            </div>
          ) : (
            <></>
          ),
        )}
      </fieldset>
    </div>
  );
};

export default ServiceDialogFormView;
