import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { ReactElement } from 'react';
import { useStyles } from './color-select-filter-input.style';
import { RenderOptionViewInterface } from './color-select-filter-input.type';

const RenderOptionView = (props: RenderOptionViewInterface): ReactElement => {
  const { color, text } = props;
  const classes = useStyles();

  return (
    <Tooltip title={text} placement="top">
      <Grid container className={classes.itemMenu}>
        <Box
          height={12}
          width={12}
          component="span"
          borderRadius="50%"
          display="block"
          bgcolor={color}
        />
        <Typography variant="inherit">{text}</Typography>
      </Grid>
    </Tooltip>
  );
};
export default RenderOptionView;
