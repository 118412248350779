import { makeStyles } from '@material-ui/core';
import avatarPlaceholder from '../../images/avatar.jpg';
import { StyleProps } from '.';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, StyleProps>({
  avatar: {
    backgroundImage: `url(${avatarPlaceholder})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '& .MuiAvatar-img': {
      objectFit: 'fill',
    },
  },
  avatarImage: props => ({
    width: props.bigAvatar ? '64px' : '32px',
    height: props.bigAvatar ? '64px' : '32px',
    '& .MuiAvatar-img': {
      objectFit: 'unset',
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      filter: 'saturate(140%)',
    },
  }),
});
