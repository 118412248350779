/* eslint-disable no-unused-vars */
import { isEmpty, isEmptyObject } from './data-helper';
import {
  actorGetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../type/actor-setup';

import type { FormData } from '../component/form';

type ComputeParameterValueFunc = (
  data: FormData | undefined,
  fieldName: string,
  defaultValue?: string | number,
) => null | string | number;

interface DropRequestParams {
  pagination: {
    page: number | null;
    perPage?: number | null;
  };
  sort: { field?: string; order?: string };
  search: string | number;
  filter: (string | string[])[];
  parameters?: string;
  forceTreeLevel?: boolean | null;
  isFilter?: boolean;
}

type GetDropdownRequestParamsFunc = (params: {
  dropdownMeta: Partial<DropdownMeta>;
  page?: number | null;
  perPage?: number | null;
  record?: FormData;
  search?: string | number;
  filterValues?: (string | string[])[];
  forceTreeLevel?: boolean | null;
  resource?: string | undefined;
  sort?: { field?: string; order?: string };
  isWMS?: boolean;
  isGridDropdown?: boolean;
  isFilter?: boolean;
}) => DropRequestParams;

interface DropdownMeta {
  moduleName: string;
  tableName: string;
  id: string | number;
  valueMember: string;
  uniqueId: string;
  forceTreeLevel: boolean | null;
  displayMember: string;
  displayMember2: string;
  parameters: Array<{
    moduleTableName?: string;
    moduleName?: string;
    defaultValue?: string | number;
    to: string;
    from: string;
  }>;
}

/**
 * Get dropdown request parameter from form data.
 * @function getDropdownRequestParams
 * @param {object} -
 * {
 *   dropdownMeta: object,
 *   page: number,
 *   perPage: number,
 *   record: object,
 *   search: string,
 *   filterValues: object,
 *   forceTreeLevel: boolean,
 *   additionalProps: object,
 *   resource: string,
 *   sort:string
 * }
 * @returns {object}
 */
export const getDropdownRequestParams: GetDropdownRequestParamsFunc = ({
  dropdownMeta,
  page = 1,
  perPage = 10,
  record = {},
  search = '',
  filterValues = [],
  forceTreeLevel = dropdownMeta.forceTreeLevel,
  resource = '',
  sort = {},
  isWMS = false,
  isGridDropdown = false,
  isFilter = false,
}) => {
  const { parameters: dropdownRequiredParameters = [] } = dropdownMeta;

  const result: DropRequestParams = {
    pagination: {
      page,
      perPage,
    },
    sort,
    search,
    filter: filterValues,
    isFilter: isFilter,
  };

  const parameters = {};

  /**
   * in `wms` forms we have only `formData`
   * I hope we have `formData` everywhere in the future(and remove `record`)
   * */
  if (isWMS) {
    for (const item of dropdownRequiredParameters) {
      if (!item) continue;

      const { to, from, defaultValue } = item;
      const formFieldName = !isEmpty(from) ? from.toLowerCase() : '';
      const paramFieldName = !isEmpty(to) ? to.toLowerCase() : '';
      const currentResource = actorGetActionValue('resources')!.current;
      const formData =
        (actorGetActionValue('formData', [
          currentResource.value,
          currentResource.type,
        ]) as FormData | undefined) ?? {};

      const parameterValue = computeParameterValue(
        formData,
        formFieldName,
        defaultValue,
      );

      if (!isEmpty(parameterValue)) {
        parameters[paramFieldName] = parameterValue;
      }
    }

    if (Object.keys(parameters).length > 0) {
      result.parameters = JSON.stringify(parameters);
    }

    result.forceTreeLevel = forceTreeLevel;
    return result;
  }

  dropdownRequiredParameters.forEach(item => {
    if (!item) return;

    const { to, from, moduleName, moduleTableName, defaultValue } = item;

    let dropdownParameterResource = resource;
    if (moduleName && moduleTableName) {
      dropdownParameterResource = `${moduleName}/${moduleTableName}`;
    }

    const formFieldName = !isEmpty(from) ? from.toLowerCase() : '';
    const paramFieldName = !isEmpty(to) ? to.toLowerCase() : '';

    if (dropdownParameterResource === resource) {
      const parameterValue = computeParameterValue(
        isGridDropdown ? actorGetActionValue('gridFormData') ?? record : record,
        formFieldName,
        defaultValue,
      );

      if (!isEmpty(parameterValue)) {
        parameters[paramFieldName] = parameterValue;
      }
    } else {
      const formData =
        (actorGetActionValue('formData', [
          dropdownParameterResource,
          FormKeyMode.ROOT,
        ]) as FormData | undefined) ?? {};

      let parentRecord =
        (actorGetActionValue('record', [
          dropdownParameterResource,
          FormKeyMode.ROOT,
          RecordKeyMode.FULL,
        ]) as Record<string, unknown> | undefined) ?? {};

      /**
       * Dropdowns in GRID => If `parentRecord` based on `dropdownParameterResource` not found,
       *  we have to try to get `parameterValue` based on incoming record,
       * Why this `record` did not set before? I don't know, please ask @badry
       */
      if (isGridDropdown && isEmptyObject(parentRecord)) {
        parentRecord = record;
      }

      const finalData = !isEmptyObject(formData) ? formData : parentRecord;

      const parameterValue = computeParameterValue(
        finalData,
        formFieldName,
        defaultValue,
      );

      if (!isEmpty(parameterValue)) {
        parameters[paramFieldName] = parameterValue;
      }
    }
  });

  if (Object.keys(parameters).length > 0) {
    result.parameters = JSON.stringify(parameters);
  }

  result.forceTreeLevel = forceTreeLevel;
  return result;
};

/**
 * Check `fieldName` in `data` and return value.
 * @function computeParameterValue
 * @param {object} data
 * @param {string} fieldName
 * @param {number | string | null} defaultValue
 * @returns {number | string | null}
 */
const computeParameterValue: ComputeParameterValueFunc = (
  data,
  fieldName,
  defaultValue = '',
) => {
  return data && !isEmpty(data[fieldName])
    ? (data[fieldName] as number | string | null)
    : !isEmpty(defaultValue)
    ? defaultValue
    : null;
};
