import { ReactElement, Fragment } from 'react';
import { ListItem } from '@material-ui/core';
import { NavGroupInterface } from './nav-group.type';
import { useStyles } from './nav-group.style';
import { NavGroup } from '.';
import { NavCollapse } from '../nav-collapse';
import { NavItem } from '../nav-item';
import { RoutesConfigInterface } from '..';

const NavGroupView = (props: NavGroupInterface): ReactElement => {
  const { item, level, baseUrl, canDragDrop, parentComponentName } = props;
  const classes = useStyles({ level });

  return (
    <>
      <ListItem
        component="li"
        className={classes.navItem}
        data-test={item?.dataTest}
        data-style-list-group="listGroup"
      >
        {item.title}
      </ListItem>

      {item.children && Array.isArray(item.children) && (
        <>
          {item.children.map((item: RoutesConfigInterface) => (
            <Fragment key={item.id}>
              {item.type === 'group' && (
                <NavGroup
                  item={item}
                  level={level}
                  baseUrl={baseUrl}
                  canDragDrop={canDragDrop}
                  parentComponentName={parentComponentName}
                />
              )}

              {item.type === 'collapse' && (
                <NavCollapse
                  item={item}
                  level={level}
                  baseUrl={baseUrl}
                  canDragDrop={canDragDrop}
                  parentComponentName={parentComponentName}
                />
              )}

              {item.type === 'item' && (
                <NavItem
                  item={item}
                  level={level}
                  baseUrl={baseUrl}
                  canDragDrop={canDragDrop}
                  parentComponentName={parentComponentName}
                />
              )}

              {item.type === 'action' && <>{item?.actionNode}</>}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default NavGroupView;
