import React from 'react';
import { GET_LIST } from 'react-admin';
import * as Icons from '@material-ui/icons/';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { actorDispatch } from '../../../type/actor-setup';
import { showNotification } from '../../../helper/general-function-helper';
import { isEmptyObject } from '../../../helper/data-helper';
import { TodoSidebarContextMenu } from './todo-sidebar-context-menu';
import {
  currentUserFoldersReportId,
  updateFolderInfoServiceId,
} from '../../mail-section/mail-section.helper';

import type { RoutesConfigInterface } from '../../app-drawer/navigation-container';
import type {
  TodoFolderType,
  TodoSidebarActionHandlerInterface,
} from './todo-sidebar.type';
import type { MailFolderInterface } from '../../mail-section';
import type {
  GetTodoFoldersInterface,
  UpdateFolderInfoInterface,
} from './todo-sidebar.type';

/**
 * @function {GetTodoFoldersInterface} getTodoFolders
 * @returns { void }
 */
export const getTodoFolders: GetTodoFoldersInterface = (): void => {
  actorDispatch(
    'crudAction',
    {
      type: GET_LIST,
      entity: 'todo',
      resource: `report/${currentUserFoldersReportId}`,
      requestParameters: {
        pagination: { page: 0, perPage: 9999 },
        sort: { field: 'id', order: 'DESC' },
        filter: [['IsForTask', 'equal', 1]],
      },
      onSuccess: (response: { data: TodoFolderType[] }): void => {
        response.data && actorDispatch('todoFolders', response.data);
      },
      onFailure: (error: unknown): void =>
        error ? showNotification(error, 'error') : undefined,
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'getTodoFolders',
    },
  );
};

/**
 * @function {UpdateFolderInfoInterface} updateFolderInfo
 * @returns { void } void
 */
export const updateFolderInfo: UpdateFolderInfoInterface = (
  data,
  successCallback,
): void => {
  actorDispatch('crudAction', {
    type: 'RUN_SERVICE',
    data: {
      params: data,
    },
    actionUniqueId: updateFolderInfoServiceId,
    onSuccess: (): void => {
      successCallback?.();
    },
    onFailure: (error: unknown): void =>
      error ? showNotification(error, 'error') : undefined,
  });
};

/**
 * @function generateChildren
 * @param { MailFolderInterface[] } listItems
 * @param { MailFolderInterface } item
 * @param { TodoSidebarActionHandlerInterface } actionsHandler
 * @returns { RoutesConfigInterface[] }
 */
export const generateChildren = (
  listItems: MailFolderInterface[],
  item: MailFolderInterface,
  actionsHandler: TodoSidebarActionHandlerInterface,
): RoutesConfigInterface[] => {
  return listItems
    ?.filter(
      (listItem: MailFolderInterface) =>
        listItem.parentfolders_id === item.folders_id,
    )
    .map((item: MailFolderInterface) => {
      if (isParentItemHasChildren(listItems, item)) {
        return createSidebarCollapseItem(listItems, item, actionsHandler);
      } else {
        return createSidebarItem(item, actionsHandler);
      }
    }) as RoutesConfigInterface[];
};

/**
 * @function createSidebarCollapseItem
 * @param { MailFolderInterface[] } listItems
 * @param { MailFolderInterface } item
 * @param { TodoSidebarActionHandlerInterface } actionsHandler
 * @returns { RoutesConfigInterface }
 */
const createSidebarCollapseItem = (
  listItems: MailFolderInterface[],
  item: MailFolderInterface,
  actionsHandler: TodoSidebarActionHandlerInterface,
): RoutesConfigInterface => {
  return {
    id: item.uniqueid,
    folderId: item?.folders_id,
    title: item.folderstitle,
    type: 'collapse',
    isOpen: item?.folders_id === 1,
    children: generateChildren(listItems, item, actionsHandler),
    url: `/oa?module=todo&id:${item.uniqueid}`,
    count: item?.itemcount,
    hasContextMenu: true,
    contextMenuNode: <TodoSidebarContextMenu actionsHandler={actionsHandler} />,
    dataTest: item.folderstitle,
  };
};

/**
 * @function createSidebarItem
 * @param { MailFolderInterface } item
 * @param { TodoSidebarActionHandlerInterface } actionsHandler
 * @returns { RoutesConfigInterface }
 */
const createSidebarItem = (
  item: MailFolderInterface,
  actionsHandler: TodoSidebarActionHandlerInterface,
): RoutesConfigInterface => ({
  id: item.uniqueid,
  folderId: item?.folders_id,
  title: item.folderstitle,
  icon: item?.iconname ? (
    React.createElement(Icons[item?.iconname])
  ) : (
    <FolderOpenIcon />
  ),
  type: 'item',
  url: `/oa?module=todo&id:${item.uniqueid}`,
  count: item?.itemcount,
  hasContextMenu: true,
  contextMenuNode: <TodoSidebarContextMenu actionsHandler={actionsHandler} />,
  dataTest: item.folderstitle,
});

/**
 * @function isParentItemHasChildren
 * @param { MailFolderInterface[] } listItems
 * @param { MailFolderInterface } item
 * @returns { boolean }
 */
const isParentItemHasChildren = (
  listItems: MailFolderInterface[],
  item: MailFolderInterface,
): boolean =>
  listItems?.some(
    (listItem: MailFolderInterface) => listItem.parentfolders_id === item.folders_id,
  );

/**
 * @function generatePersonalFolders
 * @param { MailFolderInterface[] } currentFolders
 * @param { MailActionsHandler } mailActionsHandler
 * @returns { RoutesConfigInterface[] }
 */
export const generateTodoFolders = (
  currentFolders: MailFolderInterface[],
  actionsHandler: TodoSidebarActionHandlerInterface,
): RoutesConfigInterface[] => {
  if (isEmptyObject(currentFolders)) {
    return [];
  }

  return currentFolders
    ?.filter((folder: MailFolderInterface) => !folder.parentfolders_id)
    .map((item: MailFolderInterface) => {
      if (isParentItemHasChildren(currentFolders, item)) {
        return createSidebarCollapseItem(currentFolders, item, actionsHandler);
      } else {
        return createSidebarItem(item, actionsHandler);
      }
    }) as RoutesConfigInterface[];
};
