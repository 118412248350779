import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },

  iconPinDashboard: {
    cursor: 'pointer',
  },

  dashboardHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    position: 'relative',
    boxShadow: theme.palette.primary.customBoxShadow,
    padding: 20,
  },

  dashboardButton: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },

  dashboardCard: {},

  responsiveGridLayoutContainer: {
    width: '100%',
    height: '100%',
  },
  titleDashboard: { flex: 1, fontWeight: 600, fontSize: 14 },
  startIcon: { marginLeft: 2, marginRight: 2, cursor: 'pointer' },
  contentInfoDashboard: {
    display: 'flex',
  },
  iconDashboard: {
    marginLeft: 5,
    marginRight: 5,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center ',
  },
  ClickedStartIcon: {
    color: '#FFAB00',
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    marginTop: 10,
  },
  dashboardButtonDisable: {
    pointerEvents: 'none',
    color: theme.palette.todoLightGray,
    marginLeft: '1rem',
  },
  toolbarEmptySpace: {
    flex: 10,
  },
}));
