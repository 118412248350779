import { useTranslate, useLocale } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import { useStyles } from './sidebar-settings.style';

import type { ReactElement } from 'react';
import type { SideBarSettingsViewPropsInterface } from './sidebar-settings.type';

const SideBarSettingsView = (
  props: SideBarSettingsViewPropsInterface,
): ReactElement => {
  const { openCreateGroupDialog, handleClick, handleClose, anchorEl } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const locale = useLocale();
  const direction = locale === 'en' ? 'left' : 'right';

  return (
    <div>
      <IconButton
        color="primary"
        onClick={handleClick}
        className={classes.settingsIcon}
        disableRipple
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        className={classes.settingsMenu}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: direction,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: direction,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={openCreateGroupDialog(0)} disableRipple>
          <GroupIcon className={classes.menuItemIcon} />
          <Typography variant="button">
            {translate('chat.createNewGroup')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={openCreateGroupDialog(1)} disableRipple>
          <AnnouncementIcon className={classes.menuItemIcon} />
          <Typography variant="button">{translate('chat.createChannel')}</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SideBarSettingsView;
