import type { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';

import { useStyles } from './search-list.style';

import { UserItem } from '../user-list/user-item';
import { ChatItem } from '../user-list/chat-item';

import type { SearchListViewInterface } from './search-list.type';
import type { ChatItemInterface } from '../../chat-section.type';
import { getSummeryMessageText } from '../user-list/chat-item/chat-item.helper';

const SearchListView = (props: SearchListViewInterface): ReactElement => {
  const {
    contacts,
    messages,
    onChatSelect,
    selectedUser,
    onSelectMessage,
    searchValue,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.container} width={1}>
      <Box mt={2} mb={2} className={classes.titleContainer}>
        <span>{translate('chat.messages')}</span>
      </Box>

      {messages.length > 0 ? (
        messages.map(item => {
          return (
            <Box width={1}>
              <ChatItem
                key={item.chat_id}
                selectedUser={selectedUser}
                onChatItemSelect={item => onSelectMessage(item as ChatItemInterface)}
                searchValue={searchValue}
                item={{
                  chatdate: item.chatdate,
                  chattext: getSummeryMessageText(item.chattext, translate),
                  personimage: item.personimage,
                  personinfo_id: item.topersoninfo_id,
                  personname: item.personname,
                  sumnotseen: 0,
                  ischannel: false,
                  isgroupadmin: 0,
                  isowner: 0,
                  mentionchatid: -1,
                  frompersoninfoname: item.personname,
                }}
              />
            </Box>
          );
        })
      ) : (
        <span>{translate('chat.noMessagesFound')}</span>
      )}
      <Box mb={2} className={classes.titleContainer}>
        <span>{translate('chat.contacts')}</span>
      </Box>
      {contacts.length > 0 ? (
        contacts.map(item => {
          return (
            <UserItem
              key={item.personinfo_id}
              selectedUser={selectedUser}
              onUserSelect={item => onChatSelect(item as ChatItemInterface)}
              item={item}
            />
          );
        })
      ) : (
        <span>{translate('chat.noUserFound')}</span>
      )}
    </Box>
  );
};

export default SearchListView;
