export const sanitizeRestProps = (
  props: Record<string, unknown>,
): Record<string, unknown> => {
  const {
    alwaysOn,
    basePath,
    component,
    defaultValue,
    formClassName,
    initializeForm,
    input,
    isRequired,
    limitChoicesToValue,
    locale,
    meta,
    options,
    optionText,
    optionValue,
    record,
    resource,
    allowEmpty,
    source,
    textAlign,
    translate,
    translateChoice,
    formData,
    isFormModified,
    ...rest
  } = props;

  return rest;
};
