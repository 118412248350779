//TODO: this component should be refactored completely
import { useCallback, type FC, type ReactElement } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import clsx from 'classnames';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  CardMedia,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import DocIcon from '@material-ui/icons/Description';
import { Button } from 'semantic-ui-react';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ForwardIcon from '@material-ui/icons/Forward';

import { useStyles } from './message-item.style';
import { getFileAddress } from '../messages-list-helper/messages-list-common';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../core/configProvider';
import {
  isEmpty,
  checkJsonStringify,
  isEmptyObject,
} from '../../../../helper/data-helper';
import { isImageFile } from '../../../dynamic-input/file-input/file-input.helper';
import { getSummeryMessageText } from '../../chat-sidebar/user-list/chat-item/chat-item.helper';
import {
  createDateAndTimeForChatMessage,
  formatTextBreakLines,
} from './message-item.helper';
import SearchIcon from '@material-ui/icons/Search';
import LoadingBox from '../../../loading-box';
import { ChatUserList } from '../../chat-user-list';
import { ChatMessageTypeEnum } from '../../chat-section.helper';

import type {
  VideoMessageMetaInterface,
  AlbumMessageMetaInterface,
  LocationMessageMetaInterface,
  ChatTextJsonFormatInterface,
  ForwardMessageMetaInterface,
} from '../../new-message';
import type { MessageItemInterface } from '../../chat-section.type';
import type { MessageItemViewPropsInterface } from './message-item.type';

const MessageItemView: FC<MessageItemViewPropsInterface> = props => {
  const {
    item,
    isSender,
    user,
    contextMenu,
    isInGroup,
    onReplyClick,
    onReplyMessage,
    handleCloseContextMenu,
    handleContextMenu,
    onMoveToRepliedMessage,
    handleOpenDeleteConfirmDialog,
    onMessageInfoClick,
    onEditClick,
    handleForward,
    isForwardDialogOpen,
    onUserClick,
    closeDialogHandler,
    chatUsers,
    loading,
    onImageClick,
    onPinMessage,
    messageTypeRef,
    handleSearch,
    isSeen,
    downloadFile,
  } = props;

  const classes = useStyles({ isSender });
  const translate = useTranslate();
  const locale = useLocale();

  const apiUrl = getValue(API_URL);

  const sessionId = getValue(SESSION_ID);
  const apiVersion = getValue(API_VERSION);
  const fileAddress = getFileAddress(item.fileurl);

  /**
   * @function getRepliedMessageContent
   * @param {  MessageItemInterface | undefined } item
   * @returns  { ReactElement }
   */
  const getRepliedMessageContent = (
    item: MessageItemInterface | undefined,
  ): ReactElement => {
    if (item == null) return <></>;
    const fileAddress = getFileAddress(item.replyfileurl) ?? '';

    let replyChatText: string | null = null;
    // prettier-ignore
    const parseChatTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(item.replychattext);
    if (parseChatTextResult === false) {
      replyChatText = item.replychattext;
    } else {
      replyChatText = parseChatTextResult.rawText;
    }

    if (!isEmpty(replyChatText) && isEmptyObject(fileAddress)) {
      return (
        <Box
          onClick={onMoveToRepliedMessage}
          className={clsx(classes.repliedContent, 'pointer')}
          component="p"
          fontSize={13}
        >
          <Box mb={1}>{item.replypersonname}</Box>
          {getSummeryMessageText(replyChatText, translate)}
        </Box>
      );
    }

    if (
      !isEmpty(replyChatText) &&
      fileAddress &&
      !isImageFile(fileAddress.filePath)
    ) {
      return (
        <Box
          onClick={onMoveToRepliedMessage}
          className={clsx(classes.repliedContent, 'pointer')}
          display="flex"
          flexWrap="nowrap"
        >
          <Box
            component="span"
            className="pointer"
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
          >
            <a className={classes.docIcon} style={{ marginLeft: '1rem' }}>
              <DocIcon style={{ color: '#fff' }} />
            </a>
            <Box component="span" display="inline-block" mr={2} fontSize={13}>
              <Box mb={1}>{item.replypersonname}</Box>
              <Box>{replyChatText}</Box>
            </Box>
          </Box>
        </Box>
      );
    }
    if (
      !isEmpty(replyChatText) &&
      fileAddress &&
      isImageFile(fileAddress.filePath)
    ) {
      return (
        <Box
          onClick={onMoveToRepliedMessage}
          className={clsx(classes.repliedContent, 'pointer')}
          display="flex"
          flexWrap="nowrap"
        >
          <Box component="span" fontSize={12} mb={1} ml={1}>
            {item.replypersonname}
          </Box>
          <a className={clsx(classes.repliedMediaWrapper)}>
            <img alt="" src={`${apiUrl}/${fileAddress.filePath}`} />
          </a>
        </Box>
      );
    }
    return <Box />;
  };

  /**
   * @function getRepliedMessage
   * @param { MessageItemInterface } item
   * @returns { ReactElement }
   */
  const getRepliedMessage = (item?: MessageItemInterface): ReactElement => {
    if (item == null) return <></>;

    let chatText: string | null = null;
    // prettier-ignore
    const parseChatTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(item.chattext);
    if (parseChatTextResult === false) {
      chatText = item.chattext;
    } else {
      chatText = parseChatTextResult.formattedText;
    }

    if (!isEmpty(chatText) && isEmptyObject(fileAddress)) {
      return (
        <Box mb={1}>
          {getRepliedMessageContent(item)}
          <Box component="span" fontSize={13}>
            {formatTextBreakLines(chatText)}
          </Box>
        </Box>
      );
    }

    if (!isEmpty(chatText) && fileAddress && !isImageFile(fileAddress.filePath)) {
      return (
        <Box mb={1}>
          <Box style={{ fontStyle: 'italic' }} component="p" fontSize={13}>
            {getRepliedMessageContent(item)}
          </Box>
          <Box display="flex" flexWrap="nowrap" onClick={downloadFile}>
            <Box
              component="span"
              className="pointer"
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
            >
              <a
                href={`${apiUrl}/${fileAddress.filePath}`}
                download={`${apiUrl}/${fileAddress.filePath}`}
                target="_blank"
                className={classes.docIcon}
                style={{ marginLeft: '1rem' }}
              >
                <DocIcon style={{ color: '#fff' }} />
              </a>
              <Box component="span" display="inline-block" fontSize={13}>
                <Box>{formatTextBreakLines(chatText)}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    } else if (
      !isEmpty(chatText) &&
      fileAddress &&
      isImageFile(fileAddress.filePath)
    ) {
      return (
        <Box mb={1}>
          <Box style={{ fontStyle: 'italic' }} component="p" fontSize={13}>
            {getRepliedMessageContent(item)}
          </Box>

          <Box className={classes.mediaWrapper} onClick={downloadFile}>
            <img alt="" src={`${apiUrl}/${fileAddress.filePath}`} />
          </Box>
        </Box>
      );
    }
    return <Box />;
  };

  const getOldVersionMessage = (item: MessageItemInterface): ReactElement => {
    if (!isEmpty(item.chattext) && isEmptyObject(fileAddress)) {
      return (
        <>
          <Box component="p" fontSize={13} m={0}>
            {formatTextBreakLines(item.chattext)}
          </Box>
        </>
      );
    }

    if (
      !isEmpty(item.chattext) &&
      fileAddress &&
      !isImageFile(fileAddress.filePath)
    ) {
      return (
        <Box display="flex" flexWrap="nowrap">
          <Box
            component="span"
            className="pointer"
            display="flex"
            alignItems="center"
            onClick={downloadFile}
          >
            <Box
              className={classes.docIcon}
              style={{
                marginLeft: '1rem',
              }}
            >
              <DocIcon style={{ color: '#fff' }} />
            </Box>
            <Box component="span" display="inline-block" m={0} mr={2} fontSize={13}>
              <Box>{formatTextBreakLines(item.chattext)}</Box>
            </Box>
          </Box>
        </Box>
      );
    }

    if (
      !isEmpty(item.chattext) &&
      fileAddress &&
      isImageFile(fileAddress.filePath)
    ) {
      return (
        <>
          <img
            className={classes.mediaWrapper}
            alt=""
            src={`${apiUrl}/${fileAddress.filePath}`}
            onClick={() => {
              onImageClick([`${apiUrl}/${fileAddress.filePath}`], 0);
            }}
          />
          <Box mt={2} fontSize={13}>
            {formatTextBreakLines(item.chattext)}
          </Box>
        </>
      );
    }

    return <Box />;
  };

  const getForwardMessage = useCallback(
    (message: ChatTextJsonFormatInterface) => {
      messageTypeRef.current = ChatMessageTypeEnum.FORWARD;

      // prettier-ignore
      const { forwardName } = message.meta as ForwardMessageMetaInterface;
      return (
        <>
          <div className={classes.forwardItem}>
            <span>
              <ForwardIcon
                className={locale !== 'en' ? classes.faForwardIcon : ''}
              />
            </span>
            <span>{translate('chat.forwardFrom')}</span>
            <span className={classes.itemForward}>{forwardName}</span>
          </div>

          {!isEmptyObject(fileAddress) && getFileMessage(message)}

          <Box fontSize={13} mt={2}>
            {formatTextBreakLines(message.formattedText)}
          </Box>
        </>
      );
    },
    [fileAddress],
  );

  const getFileMessage = useCallback(
    (message: ChatTextJsonFormatInterface): ReactElement => {
      if (isEmptyObject(fileAddress)) return <></>;

      if (!isImageFile(fileAddress.filePath)) {
        return (
          <Box display="flex" flexWrap="nowrap">
            <Box
              component="span"
              className="pointer"
              display="flex"
              alignItems="center"
              onClick={downloadFile}
            >
              <Box
                className={classes.docIcon}
                style={{
                  marginLeft: '1rem',
                }}
              >
                <DocIcon style={{ color: '#fff' }} />
              </Box>
              <Box
                component="span"
                display="inline-block"
                m={0}
                mr={2}
                fontSize={13}
              >
                <Box>{fileAddress.realFileName}</Box>
              </Box>
            </Box>
          </Box>
        );
      }

      return (
        <>
          <img
            className={classes.mediaWrapper}
            alt=""
            src={`${apiUrl}/${fileAddress.filePath}`}
            onClick={() => {
              onImageClick([`${apiUrl}/${fileAddress.filePath}`], 0);
            }}
          />

          <a
            href={`${apiUrl}/${fileAddress.filePath}`}
            download={`${apiUrl}/${fileAddress.filePath}`}
            target="_blank"
          >
            <Box mt={2} fontSize={13}>
              {fileAddress.realFileName}
            </Box>
          </a>
        </>
      );
    },
    [fileAddress],
  );

  /**
   * TODO: this function should be changed completely it should be something like dynamic input
   * @function getMessage
   * @param { MessageItemInterface } item
   * @returns { ReactElement }
   */
  const getMessage = (item: MessageItemInterface): ReactElement => {
    if (item == null) return <></>;

    // prettier-ignore
    const parseChatTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(item.chattext);
    if (parseChatTextResult === false) {
      return getOldVersionMessage(item);
    }

    switch (parseChatTextResult.messageType) {
      case ChatMessageTypeEnum.LOCATION: {
        messageTypeRef.current = ChatMessageTypeEnum.LOCATION;

        const _meta = parseChatTextResult.meta as LocationMessageMetaInterface;
        if (!_meta) {
          return (
            <div>
              <p>invalid location</p>

              {!isEmpty(parseChatTextResult.formattedText) && (
                <Typography className={classes.locationText}>
                  {parseChatTextResult.formattedText}
                </Typography>
              )}
            </div>
          );
        }
        // prettier-ignore
        const { coordinates, imageUrl } = _meta;
        return (
          <div>
            <a
              className={classes.mediaWrapper}
              href={`https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lng}`}
              target="_blank"
            >
              <img alt="" src={`${apiUrl}/${imageUrl}`} />
            </a>

            {!isEmpty(parseChatTextResult.formattedText) && (
              <Typography className={classes.locationText}>
                {parseChatTextResult.formattedText}
              </Typography>
            )}
          </div>
        );
      }

      case ChatMessageTypeEnum.VIDEO: {
        // prettier-ignore
        const { link } = parseChatTextResult.meta as VideoMessageMetaInterface;
        messageTypeRef.current = ChatMessageTypeEnum.VIDEO;

        const a = document.createElement('a');
        a.setAttribute('download', link);
        a.setAttribute('href', link);
        document.body.appendChild(a);
        document.body.removeChild(a);

        return (
          <div>
            <CardMedia
              className={classes.videoContentContainer}
              component="video"
              image={link}
              controls
              loop
            />
          </div>
        );
      }

      case ChatMessageTypeEnum.ALBUM: {
        // prettier-ignore
        const { imageUrls } = parseChatTextResult.meta as AlbumMessageMetaInterface;
        messageTypeRef.current = ChatMessageTypeEnum.ALBUM;

        return (
          <div>
            {imageUrls.map((imageUrl, imageIndex) => (
              <img
                className={classes.albumMediaWrapper}
                alt=""
                src={`${apiUrl}/${imageUrl.filePath}`}
                onClick={() => {
                  onImageClick(
                    imageUrls.map(img => `${apiUrl}/${img.filePath}`),
                    imageIndex,
                  );
                }}
              />
            ))}

            {!isEmpty(parseChatTextResult.formattedText) && (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: parseChatTextResult.formattedText,
                }}
                className={classes.locationText}
              />
            )}
          </div>
        );
      }

      case ChatMessageTypeEnum.FILE: {
        messageTypeRef.current = ChatMessageTypeEnum.FILE;

        return (
          <div>
            {getFileMessage(parseChatTextResult)}
            {!isEmpty(parseChatTextResult.formattedText) && (
              <Typography
                dangerouslySetInnerHTML={{
                  __html: parseChatTextResult.formattedText,
                }}
                className={classes.locationText}
              />
            )}
          </div>
        );
      }

      default:
        break;
    }

    if (!isEmpty(item.replyofchat_id)) {
      return getRepliedMessage(item);
    }

    if (parseChatTextResult.messageType === ChatMessageTypeEnum.FORWARD) {
      return getForwardMessage(parseChatTextResult);
    }

    if (!isEmptyObject(fileAddress)) {
      return getFileMessage(parseChatTextResult);
    }

    messageTypeRef.current = ChatMessageTypeEnum.TEXT;
    return (
      <Box component="p" fontSize={13} m={0}>
        {formatTextBreakLines(parseChatTextResult.formattedText)}
      </Box>
    );
  };

  return (
    <Box
      id={`messageId-${item.chat_id}`}
      className={clsx(classes.messageItemRoot, isSender ? 'left' : 'right')}
      display="flex"
      alignItems="center"
      ml={1}
    >
      <Avatar
        className={classes.profilePic}
        src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${item.personimage}`}
        alt={item.personname}
      />
      <Box
        className={classes.messageChatRoot}
        onDoubleClick={() => onReplyMessage(item)}
        onContextMenu={handleContextMenu}
      >
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Box className={classes.messageChat} ml="auto">
            <Box className="message-info">
              {isInGroup && (
                <Box
                  component="p"
                  color="text.primary"
                  fontSize={'0.785rem'}
                  marginBottom={1}
                >
                  {item.personname}
                </Box>
              )}
              {getMessage(item)}
              <Box
                display="flex"
                justifyContent="flex-start"
                className={clsx(classes.messageTime, 'message-time')}
                component="span"
                mt={1.5}
              >
                {item.isedited && (
                  <EditIcon style={{ fontSize: '.9rem', marginLeft: '.4rem' }} />
                )}
                {createDateAndTimeForChatMessage(item.chatdate, locale)}
                {isSender &&
                  (isSeen ? (
                    <DoneAllIcon
                      style={{ fontSize: '1.1rem', marginRight: '.6rem' }}
                    />
                  ) : (
                    <DoneIcon style={{ fontSize: '1.1rem', marginRight: '.6rem' }} />
                  ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.arrowIcon}>
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={onReplyClick}>{translate('chat.reply')}</MenuItem>

          {isSender &&
            messageTypeRef.current !== ChatMessageTypeEnum.LOCATION &&
            messageTypeRef.current !== ChatMessageTypeEnum.FORWARD && (
              <MenuItem onClick={onEditClick}>{translate('chat.edit')}</MenuItem>
            )}

          {(item.isgroupsupervisor || !user.groupuid) && (
            <>
              <MenuItem onClick={handleOpenDeleteConfirmDialog}>
                {translate('chat.deleteMessage')}
              </MenuItem>
              <MenuItem onClick={onPinMessage}>
                {item.ispin
                  ? translate('chat.unpinMessage')
                  : translate('chat.pinMessage')}
              </MenuItem>
            </>
          )}
          {isSender && isInGroup && (
            <MenuItem onClick={onMessageInfoClick}>
              {translate('chat.messageInfo')}
            </MenuItem>
          )}
          <MenuItem onClick={handleForward}>{translate('chat.forward')}</MenuItem>
        </Menu>
      </Box>

      <Dialog open={isForwardDialogOpen}>
        <DialogContent dividers={true}>
          <Box className={classes.textFieldContainer}>
            <TextField
              focused
              fullWidth
              className={classes.chatSearch}
              placeholder={translate('chat.search')}
              onChange={handleSearch}
              InputProps={{
                classes: {
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {loading ? (
            <LoadingBox />
          ) : (
            <ChatUserList users={chatUsers} handleClick={onUserClick} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogHandler}>{translate('confirm.cancel')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MessageItemView;
