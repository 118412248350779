import { ReactElement } from 'react';
import classNames from 'classnames';
import { FileIcon } from 'react-file-icon';
import { Icon, IconButton, Typography } from '@material-ui/core';

import { useStyles } from './multi-file-stream-input.style';
import { FileStreamCustomChipInterface } from './multi-file-stream-input.type';

import { API_URL, getValue } from '../../../core/configProvider';
import defaultStyles from '../../react-file-icon-component/default.style';
import {
  getFileExtension,
  isImage,
} from '../../react-file-icon-component/preview-file.helper';
import { isEmpty } from '../../../helper/data-helper';
import { getFileSize } from '../../../helper/general-function-helper';
import { isFileTypeImage } from '../../../helper/FileHelper';

const FileStreamCustomChip = (
  props: FileStreamCustomChipInterface,
): ReactElement => {
  const {
    realFileName,
    filePath,
    onItemClick,
    onDeleteButtonClick,
    disabled,
    index,
    size,
    maximumHeight,
  } = props;

  const classes = useStyles({ disabled });
  const apiUrl = getValue(API_URL);

  const chipClickHandler = (event: React.MouseEvent): void => {
    event.stopPropagation();
    if (!disabled) {
      onItemClick?.(filePath);
    }
  };

  if (isEmpty(realFileName) || isEmpty(filePath)) return <></>;

  const previewFileType = getFileExtension(realFileName);

  return (
    <div
      onClick={chipClickHandler}
      className={classNames(
        classes.chipContainerImage,
        disabled ? classes.disabled : '',
      )}
    >
      <div className={classes.chipImage}>
        <div className={classes.containerText}>
          <Typography className={classes.chipText}>{realFileName}</Typography>
          {size && <span className={classes.chipTextSize}>{getFileSize(size)}</span>}
        </div>

        {isFileTypeImage(filePath) ? (
          <img
            src={`${apiUrl}/${filePath}`}
            className={classes.preiviewImg}
            height={maximumHeight ?? 40}
          />
        ) : previewFileType ? (
          <FileIcon
            extension={previewFileType}
            {...defaultStyles[previewFileType]}
          />
        ) : null}

        {onDeleteButtonClick && (
          <IconButton
            onClick={onDeleteButtonClick(index)}
            disabled={disabled}
            size="small"
          >
            <Icon fontSize="small">clear</Icon>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default FileStreamCustomChip;
