import { FC } from 'react';
import { useStyles } from './grid-cell.style';
import { GridCellMultiStreamProps } from './grid-cell.type';
import { isEmptyObject } from '../../../helper/data-helper';
import { isFileTypeImage } from '../../../helper/FileHelper';
import { openPreviewImage } from '../../dynamic-input/multi-file-stream-input/multi-file-stream-input.helper';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { apiUrl } from '../../../core/data-Provider.helper';

const GridCellMultiStreamView: FC<GridCellMultiStreamProps> = props => {
  const { files } = props;
  const classes = useStyles();

  if (!Array.isArray(files) || isEmptyObject(files)) {
    return <></>;
  }

  const firstImageAddress = files.find(file => isFileTypeImage(file.filePath));
  const firstFileExtension = files[0].realFileName?.split('.').at(-1);

  return (
    <>
      {!isEmptyObject(firstImageAddress) ? (
        <img
          src={`${apiUrl}/${firstImageAddress?.filePath}`}
          onClick={openPreviewImage(files)}
          className={classes.multiStreamCell}
        />
      ) : (
        <div className={classes.multiStreamCell} onClick={openPreviewImage(files)}>
          <FileIcon
            extension={firstFileExtension}
            {...defaultStyles[firstFileExtension]}
          />
        </div>
      )}
    </>
  );
};

export default GridCellMultiStreamView;
