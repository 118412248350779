import { FC, FormEvent } from 'react';
import Button from '@material-ui/core/Button/Button';
import { FilterFormViewPropsInterface } from './filter-form.type';
import { useStyles } from './filter-form.style';
import DynamicFilterInput from '../filter/DynamicFilterInput';
import classnames from 'classnames';
import { useTranslate } from 'react-admin';
import type { FieldType } from '../../helper/Types';

export const FilterFormView: FC<FilterFormViewPropsInterface> = props => {
  const {
    selectedFilterFields,
    resource,
    submitButtonDisabled,
    filterDataRecord,
    hideFilterFieldHandler,
    filterInputChangeHandler,
    submitHandler,
    className,
    customSaveButtonText,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <form
      className={classnames(className, classes.form)}
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
      }}
    >
      {selectedFilterFields!.map(field => {
        if (!field.fieldData) {
          return null;
        }

        return (
          <DynamicFilterInput
            key={field.fieldData.name as string}
            field={field.fieldData as FieldType}
            resource={resource}
            handleHide={hideFilterFieldHandler}
            value={field.value}
            onChange={filterInputChangeHandler}
            filterData={filterDataRecord}
          />
        );
      })}
      <Button
        type="submit"
        id="submitFilterButton"
        variant="contained"
        className={classes.submitButton}
        disabled={submitButtonDisabled}
        onClick={submitHandler}
      >
        {customSaveButtonText ?? translate('dashboard.confirmFilters')}
      </Button>
    </form>
  );
};
