import { PureComponent } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  translate as withTranslate,
  refreshView as refreshViewAction,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import grey from '@material-ui/core/colors/grey';
import UploadFileContainer from './UploadFileContainer';
import { ProfileAvatar } from '../component/profile-avatar';
import { getValue, USER_ID } from '../core/configProvider';
import Icon from '@material-ui/core/Icon';
import { isCtrlEnterPressed } from '../helper/FormHelper';
import PreviewFile from '../component/PreviewFile';
import {
  crudCreateWithCallbackAction,
  crudUpdateWithCallbackAction,
} from '../redux/crud/action';
import { isEmpty } from '../helper/data-helper';

const styles = theme => ({
  noteItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
  },

  noteContent: {
    flexGrow: 1,
    marginLeft: '0.5em',
    fontSize: 11,
    backgroundColor: grey[50],
    borderRadius: 2,
    padding: 15,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.23)',
    whiteSpace: 'pre-line',
  },

  textField: {
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  textFieldInput: {
    minHeight: 22,
  },

  saveBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 15,
  },

  icon: {
    margin: '0 5px',
  },

  previewFileContainer: {
    display: 'flex',
  },
});

const getNewState = () => ({
  noteText: '',
  currentNote: '', //todo: check this removed state and delete this component after replaced with `create-note` component
  attachmentArr: [],
  isSending: false,
  invalidNote: false,
});

class NoteCreateContainer extends PureComponent {
  state = getNewState();

  static getDerivedStateFromProps(props, prevState) {
    const { model } = props;

    if (model && (!prevState.currentNote || prevState.currentNote !== model.note)) {
      const note = JSON.parse(model.note);

      return {
        ...prevState,
        currentNote: model.note,
        noteText: note.text ? note.text : '',
        attachmentArr: note.attachmentArr ? note.attachmentArr : [],
      };
    }

    return prevState;
  }

  handleTextChange = event => {
    const text = event.target.value;
    if (!text.includes(' ') && text.length >= 50) {
      this.setState({ invalidNote: true });
    } else {
      this.setState({ invalidNote: false });
    }

    this.setState({ noteText: text });
  };

  saveNote = async () => {
    this.setState({ isSending: true });
    const {
      parentId,
      relation,
      model,
      onSubmitNote = () => {},
      crudCreate,
      crudUpdate,
      refreshView,
    } = this.props;
    const { parentFieldName, moduleName, moduleTableName } = relation;
    const { noteText, attachmentArr } = this.state;

    let childResource = '';
    if (!isEmpty(moduleName) && !isEmpty(moduleTableName)) {
      childResource = `${moduleName}/${moduleTableName}`;
    } else {
      // prettier-ignore
      console.warn('invalid `moduleName` or `moduleTableName`', { moduleName, moduleTableName });
    }

    const data = {
      note: JSON.stringify({
        text: noteText,
        attachmentArr: attachmentArr,
      }),
    };
    if (!model) {
      data[parentFieldName] = parentId;

      crudCreate(childResource, data, () => {
        this.setState(getNewState());
        onSubmitNote();
        refreshView();
      });
    } else {
      crudUpdate(childResource, model.id, data, model, () => {
        this.setState(getNewState());
        onSubmitNote();
        refreshView();
      });
    }
  };

  handleNewAttachment = fileInfo => {
    const { attachmentArr = [] } = this.state;

    attachmentArr.push(fileInfo);
    this.setState({ attachmentArr: [...attachmentArr] });
  };

  handleDeleteAttachment = index => {
    const { attachmentArr = [] } = this.state;
    attachmentArr.splice(index, 1);
    this.setState({ attachmentArr: [...attachmentArr] });
  };

  keyPress = event => {
    if (this.state.noteText) {
      if (isCtrlEnterPressed(event)) {
        event.preventDefault();
        this.saveNote();
      }
    }
  };

  render() {
    const { classes, translate, parentResource, parentId } = this.props;
    const { isSending, attachmentArr, invalidNote, noteText } = this.state;
    const currentUserId = getValue(USER_ID);

    return (
      <div className={classes.noteItem}>
        <ProfileAvatar userId={currentUserId} />
        <div className={classes.noteContent}>
          <TextField
            label={translate('noteStream.newNote')}
            fullWidth
            variant="outlined"
            rowsMax={7}
            className={classes.textField}
            InputProps={{
              classes: {
                error: classes.error,
                input: classes.textFieldInput,
              },
            }}
            multiline
            value={this.state.noteText}
            onChange={this.handleTextChange}
            onKeyPress={this.keyPress}
            error={invalidNote}
            helperText={
              invalidNote ? translate('noteStream.invalidNote') : undefined
            }
          />
          <div className={classes.noteAction}>
            <UploadFileContainer
              onChange={this.handleNewAttachment}
              resource={`${parentResource}/${parentId}`}
            />
            <div
              className={classes.previewFileContainer}
              data-test-previw-file-length={attachmentArr.length}
            >
              {attachmentArr &&
                attachmentArr.map((attachment, index) => (
                  <PreviewFile
                    index={index}
                    key={attachment.fileName}
                    file={attachment}
                    onDelete={this.handleDeleteAttachment}
                  />
                ))}
            </div>
            <div className={classes.saveBtnContainer}>
              <Button
                disabled={
                  isSending ||
                  invalidNote ||
                  (!noteText && attachmentArr.length === 0)
                }
                variant="contained"
                color="primary"
                size="small"
                onClick={this.saveNote}
              >
                <Icon className={classes.icon} fontSize="small">
                  save
                </Icon>
                {translate('ra.action.save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NoteCreateContainer.propTypes = {
  refreshView: PropTypes.func.isRequired,
  crudCreate: PropTypes.func.isRequired,
  crudUpdate: PropTypes.func.isRequired,
  relation: PropTypes.object.isRequired,
  parentId: PropTypes.any.isRequired,
  locale: PropTypes.string.isRequired,
  onSubmitNote: PropTypes.func,
  model: PropTypes.object,
  childResource: PropTypes.string,
};

const mapDispatchToProps = {
  refreshView: refreshViewAction,
  crudCreate: crudCreateWithCallbackAction,
  crudUpdate: crudUpdateWithCallbackAction,
};

export default compose(
  withTranslate,
  withStyles(styles, { withTheme: true }),
  connect(null, mapDispatchToProps),
)(NoteCreateContainer);
