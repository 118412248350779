import { ReactElement } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { useStyles } from './text-input.style';
import { TextInputViewProps, WMSObjectedValue } from './text-input.type';
import { checkJsonStringify, isEmpty } from '../../helper/data-helper';

export const TextInputWMSView = (props: TextInputViewProps): ReactElement => {
  const {
    getRef,
    field,
    value,
    inputMessage,
    label,
    disabled,
    resource,
    handleBlur,
    handleFocus,
    handleChange,
    visibleClass,
    customTestAttribute,
    passwordShown,
    changeShowPassword,
    inputValueIsAValidUrl,
    handleUrlForOpenNewTab,
    inputRef,
  } = props;

  const classes = useStyles(props);
  const parsedValue: WMSObjectedValue = checkJsonStringify(value);

  return (
    <TextField
      {...customTestAttribute}
      ref={getRef}
      inputRef={inputRef}
      type={field.isHashed ? (!passwordShown ? 'password' : 'text') : 'text'}
      value={parsedValue && !isEmpty(parsedValue.value) ? parsedValue.value : value}
      style={{ width: `${field.widthPercent ?? 100}%` }}
      name={field.name}
      label={label}
      required={field.required!}
      margin="normal"
      className={`${visibleClass} ${classes.wmsInput} ${classes.inputStylePuzzleForm}`}
      variant="outlined"
      InputProps={{
        disabled,
        classes: {
          disabled: classes.disabled,
        },
        endAdornment: (
          <>
            {inputValueIsAValidUrl && (
              <InputAdornment position="end">
                <OpenInNewIcon
                  onClick={handleUrlForOpenNewTab}
                  fontSize="small"
                  className={classes.iconUrl}
                />
              </InputAdornment>
            )}

            {field.isHashed ? (
              <InputAdornment position="end">
                <IconButton
                  className={classes.icon}
                  aria-label="toggle password visibility"
                  onClick={changeShowPassword}
                >
                  {!passwordShown ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : null}
          </>
        ),
      }}
      inputProps={{
        style: {
          ...(parsedValue && parsedValue.backColor
            ? { backgroundColor: parsedValue.backColor }
            : null),
        },
        enterkeyhint: 'go',
        autoComplete: 'off',
        'data-test-input-name': `${resource}/${name}`,
        'data-test-input-field-priority': field.priority,
        maxLength: field.maxLength,
        onSelect: event => {
          event.preventDefault();
        },
      }}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      disabled={disabled}
      data-test="inputContainerTextField"
      data-test-has-error={inputMessage?.messageType === 'error'}
      data-test-has-warning={inputMessage?.messageType === 'warning'}
      size={'small'}
    />
  );
};
