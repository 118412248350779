import { isEmpty } from '../../../helper/data-helper';
import { getCurrentUser } from '../chat-section.helper';
import { getGroupUsers } from '../group-chats.helper';

import type { ChatItemInterface, SelectedChatType } from '../chat-section.type';

/**
 * get a list of person info id's that are chatting now
 * @function getCommutatingUsers
 * @param {SelectedChatType} chat
 * @returns {Promise<string[]>}
 */
export const getCommutatingUsers = async (
  chat: SelectedChatType,
): Promise<string[]> => {
  const commutatingUsers: string[] = [];

  const isGroup = !isEmpty(chat.groupuid);
  const currentUserId = getCurrentUser()?.id;

  if (isGroup) {
    const groupUsers = await getGroupUsers(String(chat.groupuid));

    groupUsers?.forEach(user => {
      const selectedPersonInfoId = String(user.personinfo_id);

      if (currentUserId !== selectedPersonInfoId) {
        commutatingUsers.push(selectedPersonInfoId);
      }
    });
  } else {
    const selectedPersonInfoId = String(chat.personinfo_id);

    if (currentUserId !== selectedPersonInfoId) {
      commutatingUsers.push(selectedPersonInfoId);
    }
  }

  return commutatingUsers;
};

/**
 * get all non group users in chats
 * @function getAllCommutatingUsers
 * @param {ChatItemInterface[]} chats
 * @returns {string[]} commutatingUsers
 */
export const getAllCommutatingUsers = (chats: ChatItemInterface[]): string[] => {
  const commutatingUsers: string[] = [];

  chats.forEach(chat => {
    const isGroup = !isEmpty(chat.groupuid);
    const currentUserId = getCurrentUser()?.id;

    if (!isGroup) {
      const selectedPersonInfoId = String(chat.personinfo_id);

      if (currentUserId !== selectedPersonInfoId) {
        commutatingUsers.push(selectedPersonInfoId);
      }
    }
  });

  return commutatingUsers;
};
