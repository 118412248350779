import { makeStyles } from '@material-ui/core/styles';

import type { CustomTheme } from '../../../core/themeProvider';

const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogTitle: {
    borderBottom: `1px dotted ${theme.palette.todoDarkGray}`,
  },
  DialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    minWidth: 400,
  },

  colorPickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 30,
  },

  colorPickerLabel: {
    flex: 1,
    color: theme.palette.todoDarkGray,
  },

  input: {
    marginTop: theme.spacing(2),
  },

  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `1px dotted ${theme.palette.todoDarkGray}`,
    marginTop: 20,
  },

  formControl: {
    marginTop: 15,
    flex: 1,
  },

  horizontalFormControl: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.todoLightGray}`,
  },

  colorBox: {
    padding: 5,
    flex: 1,
  },

  sortOrderContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    borderLeft: `1px solid ${theme.palette.todoLightGray}`,
    minWidth: 86,
  },

  sortContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

export default useStyles;
