import { ReactElement } from 'react';
import { MailSidebarViewInterface } from './mail-sidebar.type';
import Box from '@material-ui/core/Box';
import { AppDrawer } from '../../app-drawer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MailSplitButton } from './mail-split-button';

const MailSidebarView = (props: MailSidebarViewInterface): ReactElement => {
  const { mailSidebarRoutesConfig, isMailDisabled } = props;

  return (
    <div data-test="mail-sidebar-container" data-style-mailSidebar="mailSidebar">
      {!isMailDisabled && (
        <Box display="flex" justifyContent="center" pt={{ xs: 4, md: 5 }} pb={2}>
          <MailSplitButton />
        </Box>
      )}

      <DndProvider backend={HTML5Backend}>
        <AppDrawer
          routesConfig={mailSidebarRoutesConfig}
          baseUrl="/oa"
          data-test="mail-sidebar-drawer"
          parentComponentName={'mail'}
          canDragDrop={false}
        />
      </DndProvider>
    </div>
  );
};

export default MailSidebarView;
