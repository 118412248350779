import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  actorGetActionValue,
  actorSetActionValue,
} from '../../../../type/actor-setup';

import WMSReportView from './wms-report.view';
import { showGridWithoutSearch } from './wms-report.helper';

import type { FormData } from '../../../form';
import type { WMSReportControllerProps } from './wms-report.type';

const WMSReportController = (props: WMSReportControllerProps): ReactElement => {
  const { reportMetaData, layoutRows, activeTabIndex, formTabIndex, prevTabIndex } =
    props;

  const reportId = reportMetaData.id;
  const reportType = reportMetaData.reportType;

  const [showGrid, setShowGrid] = useState({ [reportId]: false });
  const [filterValues, setFilterValues] = useState<FormData | null>(null);

  const resource = `report/${reportId}`;

  const dispatch = useDispatch();
  useEffect(() => {
    actorSetActionValue('metaData', reportMetaData, {
      path: resource,
      callerScopeName: 'WMSReportController',
    });

    dispatch({
      type: 'RA/REGISTER_RESOURCE',
      payload: {
        name: resource,
        options: {},
        hasList: true,
        hasEdit: false,
        hasShow: false,
        hasCreate: false,
      },
    });

    const rootResource = actorGetActionValue('resources')!.stack[0];
    if (rootResource) {
      const formData = actorGetActionValue(
        'formData',
        `${rootResource.value}.${rootResource.type}`,
      ) as FormData | null;

      setFilterValues(formData);
    }
  }, []);

  useEffect(() => {
    setShowGrid({ [reportId]: false });

    const rootResource = actorGetActionValue('resources')!.stack[0];

    const formData = actorGetActionValue(
      'formData',
      `${rootResource!.value}.${rootResource!.type}`,
    ) as FormData | null;

    requestAnimationFrame(() => {
      setFilterValues(formData);

      setShowGrid({
        [reportId]: showGridWithoutSearch(layoutRows, formData ?? {}),
      });
    });
  }, [reportId, activeTabIndex]);

  /**
   * @function onSubmit
   * @param {FormData} formData
   * @returns {void} void
   */
  const onSubmit = useCallback(
    (formData: FormData) => {
      setShowGrid({ [reportId]: false });

      // when `formData` doesn't change, I want just re-render the grid to make new request with the same data
      setTimeout(() => {
        setFilterValues(formData);
        setShowGrid({ [reportId]: showGridWithoutSearch(layoutRows, formData) });

        const formGlobalProps = actorGetActionValue('formGlobalProps')!;
        formGlobalProps.formFocusManagementFunctions.focusOnFirstInputAfterSubmit();
      }, 100);
    },
    [reportMetaData.id],
  );

  return (
    <WMSReportView
      resource={resource}
      reportId={reportId}
      reportType={reportType}
      showGrid={showGrid}
      reportMetaData={reportMetaData}
      filterValues={filterValues}
      onSubmit={onSubmit}
      activeTabIndex={activeTabIndex}
      formTabIndex={formTabIndex}
      prevTabIndex={prevTabIndex}
    />
  );
};

export default WMSReportController;
