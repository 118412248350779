import { FC, useEffect, useRef, useState } from 'react';

import LoadingBox from '../../../../LoadingBox';
import { actorDispatch } from '../../../../../type/actor-setup';
import { getReportsDropdownId } from '../gadget-design-dialog.helper';
import { prepareReportsForGadgetInput } from './setting-section.helper';
import SettingSectionView from './setting-section.view';
import {
  GadgetReport,
  SettingSectionControllerInterface,
} from './setting-section.type';
import { CUSTOM_GET } from '../../../../../core/data-Provider.helper';

const SettingSectionController: FC<SettingSectionControllerInterface> = props => {
  const { gadgetValues, gadgetInternalChangeHandler } = props;

  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<GadgetReport[]>([]);
  const reportsRef = useRef<GadgetReport[]>([]);

  useEffect(() => {
    actorDispatch(
      'crudAction',
      {
        type: CUSTOM_GET,
        entity: 'gadget',
        resource: `v2/dropdown/${getReportsDropdownId}?parameters=&skip=0&takeCount=999999`,
        onSuccess: reportList => {
          const preparedReports = Array.isArray(reportList?.data)
            ? prepareReportsForGadgetInput(reportList.data)
            : [];

          setReports(preparedReports);
          reportsRef.current = preparedReports;
          setLoading(false);
        },
        onFailure: () => {
          setLoading(false);
        },
      },
      { disableDebounce: true },
    );
  }, []);

  useEffect(() => {
    if (!Array.isArray(reports)) return;

    const filteredReports =
      gadgetValues.chartType === 'Calendar'
        ? reports.filter(report => report.isActiveCalendar)
        : reportsRef.current;

    setReports(filteredReports);
  }, [gadgetValues.chartType]);

  if (loading) return <LoadingBox />;

  return (
    <SettingSectionView
      reports={reports}
      gadgetValues={gadgetValues}
      gadgetInternalChangeHandler={gadgetInternalChangeHandler}
    />
  );
};

export default SettingSectionController;
