import type { FieldType } from '../../../helper/Types';

export const field: Partial<FieldType> = {
  resource: '',
  fixCalendar: '',
  regex: null,
  regexDescription: null,
  width: 100,
  allowSort: true,
  hasValidationActions: false,
  comment: 'ضمائم',
  tableName: 'messages',
  numberOfLines: 1,
  dataType: {
    erp: 'multiFileStream',
    sql: 'nvarchar(max)',
    simple: 'string',
    id: 204,
    defaultOperator: 'Equals',
  },
  disabled: false,
  defaultValue: null,
  parentField: null,
  moduleName: 'automation',
  id: 63975,
  caption: 'ضمائم',
  translatedCaption: {
    fa: 'ضمائم',
    en: 'AttachFiles',
    ar: 'AttachFiles',
  },
  translatedComment: {
    fa: 'ضمائم',
    en: 'ضمائم',
    ar: 'ضمائم',
  },
  relatedName: 'attachfiles',
  name: 'attachfiles',
  required: false,
  isHashed: false,
  hasSummary: false,
  miladiDisplay: false,
  filterDefaultValue: null,
  maxLength: null,
  report: null,
  minValue: null,
  maxValue: null,
  values: null,
  precision: 4,
  multiLanguage: false,
  relatedLocaleFieldId: null,
  relatedParameterName: null,
  hidden: false,
  hasViewPermission: false,
  hasEditPermission: false,
  format: 'G18',
  defaultValueGlobalParameter: null,
  javaScriptFormula: '',
  codingPattern: null,
  dropdownValueMemberVisibility: false,
  isUniqueInGrid: false,
  isMapped: false,
  groupingPriority: 0,
  treeParentField: null,
};
