import type {
  ChatReducerActionInterface,
  ChatStateInterface,
} from '../chat-section.type';
import { chatStatefulActions } from './chat-section-stateful-actions';

export const chatSectionInitialState: ChatStateInterface = {
  contactPage: 1,
  chatPage: 1,
  isFileUploadDialogOpen: false,
  uploadedFiles: [],
};

export const chatSectionReducer = (
  state: ChatStateInterface,
  action: ChatReducerActionInterface,
): ChatStateInterface => {
  let updatedState: typeof state;

  switch (action.type) {
    case 'fetchMoreChats': {
      updatedState = chatStatefulActions.fetchMoreChats(state);
      break;
    }

    case 'fetchMoreContacts': {
      updatedState = chatStatefulActions.fetchMoreContacts(state);
      break;
    }

    case 'refreshChats': {
      updatedState = chatStatefulActions.refreshChats(state);
      break;
    }

    case 'openUploadFileDialog': {
      updatedState = chatStatefulActions.openUploadFileDialog(
        state,
        action.payload.show,
        action.payload.uploadedFiles,
      );
      break;
    }

    default: {
      updatedState = state;
      console.warn('`chatSectionReducer` => Invalid action name: %o', {
        state,
        action,
      });
      break;
    }
  }

  return updatedState;
};
