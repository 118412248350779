import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 0',
    cursor: 'pointer',
  },
  iconPin: { width: 15, height: 15, marginRight: 2, marginLeft: 2 },
  containerDashboard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  iconDashboard: {
    display: 'flex',
    alignItems: 'center',
  },
  textMenu: {
    marginLeft: 27,
    marginRight: 27,
    fontSize: 12,
  },
}));
