import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    marginRight: '10px',
  },
  arrowDropDown: {
    color: theme.palette.secondary.contrastText,
  },
  avatarContainer: {
    margin: '0px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '50%',
    width: 25,
    height: 25,
    cursor: 'pointer',
    border: '1px solid white',
    '&:hover div:nth-child(2)': {
      transform: 'translate3d(0, 0, 0)',
    },
    '& [data-test="avatarChange"]': {
      width: '23px !important',
      height: '23px !important',
    },
  },
  menu: {
    marginTop: '35px',
    width: '230px',
  },
  userImage: {
    width: '4.5rem',
    height: '4.5rem',
    margin: 'auto',
  },
  userName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 10px 0',
    borderBottom: `1px solid ${theme.palette.primary.zoomingBorderColor}`,
  },
  profileDisplayName: {
    fontSize: '13px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  setting: {
    '&:hover': {
      backgroundColor: theme.palette.primary.profileMenuItemBackgroundColor,
      color: theme.palette.secondary.contrastText,
    },
  },

  menuButton: {
    border: 'none',
    padding: '3px 5px',
    fontSize: '6px',
    margin: '10px 0px',
    cursor: 'pointer',
    minWidth: '40px',
    borderRadius: '10%',
    '&:hover': {
      backgroundColor: theme.palette.primary.MenuButtonBackgroundColor,
      padding: '4px 5px',
    },
  },

  simpleForm: {
    height: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,

    '& > div.fieldContainer ': {
      overflowY: 'auto',
      flexGrow: 1,
      height: 1,
      '& > div': {
        height: '100%',
      },
    },
  },

  dialogVisible: {
    overflowY: 'visible',
    padding: 0,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
