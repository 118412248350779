import { useTranslate } from 'react-admin';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

import { useStyles } from './message-info-dialog.style';
import { ChatUserList } from '../../chat-user-list';
import LoadingBox from '../../../loading-box';

import type { FC } from 'react';
import type { ManageInfoDialogViewProps } from './message-info-dialog.type';

const ManageInfoDialogView: FC<ManageInfoDialogViewProps> = props => {
  const { closeDialogHandler, usersWhoSeenMessage, pageStatus } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.mainContainer}>
      <DialogTitle>
        <Box className={classes.dialogTitleContainer}>
          <Box>{translate('chat.peopleWhoHaveSeenYourMessage')}</Box>
        </Box>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box className={classes.dialogContentBox}>
          <div className={classes.container}>
            {pageStatus === 'loading' && <LoadingBox />}

            {pageStatus === 'error' && (
              <Typography>{translate('chat.getDataError')}</Typography>
            )}

            {pageStatus === 'empty' && (
              <Typography>{translate('chat.noOneSeenYourMessageYet')}</Typography>
            )}

            {pageStatus === 'data' && (
              <ChatUserList usersWithSeenDate={usersWhoSeenMessage} />
            )}
          </div>
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActionsContainer}>
        <Button onClick={closeDialogHandler}>{translate('confirm.ok')}</Button>
      </DialogActions>
    </Box>
  );
};

export default ManageInfoDialogView;
