import { type FC, useEffect, useState } from 'react';

import SortPickerView from './sort-picker.view';
import type { DashBoardFormDataInterface } from '../../../../../../../dashboard/dashboard-page.type';
import type { GadgetsDropdownResponseInterface } from '../../../../../../../dashboard/dashboard-card-form-dialog/dashboard-card-form-dialog.type';
import type {
  SortPickerControllerInterface,
  SortReportDataInterface,
} from './sort-picker.type';
import { isJsonEncodedString } from '../../../../../../../../helper/data-helper';
import { parseJSON } from '../../../../../../../../core/configProvider';

const SortPickerController: FC<SortPickerControllerInterface> = props => {
  const { onChange, menuItems, value } = props;

  const defaultSort: SortReportDataInterface = {
    isSortEnabled: false,
    sortOrder: 'desc',
    sortField: '',
  };

  const [sortReportData, setSortReportData] =
    useState<SortReportDataInterface>(defaultSort);

  useEffect(() => {
    onChange(JSON.stringify(sortReportData));
  }, [sortReportData]);

  useEffect(() => {
    if (isJsonEncodedString(value)) {
      setSortReportData(parseJSON<SortReportDataInterface>(value) ?? defaultSort);
    } else if (value) {
      setSortReportData({
        isSortEnabled: true,
        sortField: value,
        sortOrder: 'desc',
      });
    }
  }, []);

  /**
   * handle onChanges
   * @function onDashboardSortDataChange
   * @param { keyof DashBoardFormDataInterface } key
   * @returns { (event: unknown): void } change handler
   */
  const onDashboardSortDataChange =
    (key: keyof DashBoardFormDataInterface) =>
    (event: unknown, value?: GadgetsDropdownResponseInterface): void => {
      switch (key) {
        case 'isSortEnabled': {
          setSortReportData(prevSortReportData => ({
            ...prevSortReportData,
            isSortEnabled: !prevSortReportData.isSortEnabled,
          }));
          break;
        }
        case 'sortField': {
          setSortReportData(prevSortReportData => ({
            ...prevSortReportData,
            sortField: value?.name,
          }));
          break;
        }
        case 'sortOrder': {
          setSortReportData(prevSortReportData => ({
            ...prevSortReportData,
            sortOrder: prevSortReportData.sortOrder === 'asc' ? 'desc' : 'asc',
          }));
          break;
        }
        default:
          console.warn('unknown key changed !');
          break;
      }
    };

  return (
    <SortPickerView
      sortReportData={sortReportData}
      onDashboardSortDataChange={onDashboardSortDataChange}
      menuItems={menuItems}
    />
  );
};
export default SortPickerController;
