import { FC } from 'react';
import Lightbox from './light-box';
import { LightBoxDialogControllerInterface } from './light-box-dialog.type';

const LightBoxDialogController: FC<LightBoxDialogControllerInterface> = props => {
  const { onClose, dialogData } = props;

  return (
    <Lightbox
      images={dialogData.imageData}
      startIndex={dialogData.startIndex}
      onClose={onClose}
      onDownloadClick={dialogData.onDownloadClick}
    />
  );
};

export default LightBoxDialogController;
