import { FormControl, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { useStyles } from './threshold-color-range.style';
import { useTranslate } from 'react-admin';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';

import type { FC } from 'react';
import type { ThresholdColorRangeViewProps } from './threshold-color-range.type';
import { ColorPicker } from '../../../../../../../color-picker';

const ThresholdColorRangeView: FC<ThresholdColorRangeViewProps> = props => {
  const { value, onChange, handleDelete, handleAddNewRow } = props;

  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.gaugeSettingContainer}>
      <div className={classes.addButton} onClick={handleAddNewRow}>
        {translate('general.add')}
        <Add />
      </div>

      {value?.map(thresholdItem => (
        <TextField
          type={'number'}
          value={thresholdItem.number ?? ''}
          variant="outlined"
          onChange={event =>
            onChange('number', thresholdItem.id, event.target.value)
          }
          className={classes.textInput}
          InputProps={{
            classes: {
              root: classes.textInput,
            },
            endAdornment: (
              <InputAdornment position="end">
                <FiberManualRecordIcon style={{ color: thresholdItem.color }} />
                <ColorPicker
                  onSelectColor={event => onChange('color', thresholdItem.id, event)}
                  selectedColor={thresholdItem.color ?? ''}
                />
                <Delete
                  className={classes.deleteIcon}
                  onClick={handleDelete(thresholdItem.id)}
                />
              </InputAdornment>
            ),
          }}
        />
      ))}
    </div>
  );
};

export default ThresholdColorRangeView;
