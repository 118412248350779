import { type ReactElement, useRef, useEffect } from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { useLocale } from 'react-admin';
import lodashGet from 'lodash/get';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { getTypeByField, ICON_FIELD } from '../../helper/InputHelper';
import { CustomAccordion } from '../custom-accordion';
import { Table as CustomFormTable } from '../table';
import { ColorField } from '../field/color-field';
import { IconField } from '../field/icon-field';
import { useStyles } from './record-show-details.style';

import type { TabInterface } from '../show-record-with-relation';
import type { RecordDetailsViewPropsInterface } from './record-show-details.type';
import {
  getInitialGroupsForEachTab,
  handleHiddenGroups,
} from '../form/form-with-tabs/form-with-tabs.helper';
import type { HandleHiddenGroups } from '../form';

export const RecordShowDetailsView = (
  props: RecordDetailsViewPropsInterface,
): ReactElement => {
  const {
    record,
    tabList,
    recordIsEditable,
    tabsTitlesRef,
    relationListCustomFunctions,
  } = props;

  // eslint-disable-next-line prettier/prettier
  const groupContainersRef = useRef<Record<string, Record<string, HTMLDivElement | null>>>(getInitialGroupsForEachTab(tabList));
  const inputsChangeFunctionsInitialized = useRef(false);
  const inputsChangeFunctionsRef = useRef<Record<string, HandleHiddenGroups>>({});

  const classes = useStyles();
  const theme = useTheme();
  const locale = useLocale();

  useEffect(() => {
    // handle initial (hidden/visible) of group's container
    for (const tabId in inputsChangeFunctionsRef.current) {
      const groupsStatus =
        inputsChangeFunctionsRef.current[tabId].getTabGroupsState();

      onHiddenGroupsChange(groupsStatus, tabId);
    }
  }, []);

  // for each tab this function will be invoked to handle (hidden/visible) of group's container
  const onHiddenGroupsChange = (newHiddenSubpanels, tabId) => {
    for (const groupId in newHiddenSubpanels) {
      const groupElement =
        groupContainersRef.current[String(tabId)][String(groupId)];

      if (groupElement) {
        groupElement.hidden = newHiddenSubpanels[groupId];
      }
    }
  };

  // implement singleton design pattern for assign onHiddenGroupsChange to each tab's groups
  if (!inputsChangeFunctionsInitialized.current) {
    inputsChangeFunctionsInitialized.current = true;

    inputsChangeFunctionsRef.current = Object.fromEntries(
      tabList.map(tab => {
        return [String(tab.id), handleHiddenGroups(tab, onHiddenGroupsChange)];
      }),
    );
  }

  return (
    <Tabs
      defaultActiveKey={'0'}
      direction={theme.direction}
      className={classes.tabParent}
      data-style-tab-relation="tabsRelation"
      onChange={newTab => {
        relationListCustomFunctions.current?.onActiveTabChange?.(+newTab);
      }}
    >
      <>
        {tabList.map((tab: TabInterface, index) => {
          return (
            <TabPane
              data-style-tab-item="tabItem"
              key={index}
              tab={
                <p
                  ref={tabRef => {
                    tabsTitlesRef.current[index] = tabRef;
                  }}
                >
                  {lodashGet(tab, ['translatedTitle', locale]) ||
                    tab.title ||
                    tab.name ||
                    tab.id}
                </p>
              }
            >
              <div className={classes.tabGroupsContainer}>
                {tab.groupList?.map((group, index: number) => {
                  const { translatedTitle = {}, id } = group;
                  const title: string =
                    translatedTitle?.[locale] ?? String(id) ?? '';
                  return (
                    <div
                      key={index}
                      className={classes.groupContainer}
                      ref={ref => {
                        groupContainersRef.current[String(tab.id)][
                          String(group.id)
                        ] = ref;
                      }}
                    >
                      <CustomAccordion
                        id={`showRecord_${id}`}
                        defaultExpanded={true}
                        enableChild
                        summary={
                          <div className={classes.titleSummary}>
                            <Typography
                              data-tag="accordion-summuary-title"
                              variant="body2"
                            >
                              {title}
                            </Typography>
                            {group.specialFields?.map(field =>
                              getTypeByField(field) === ICON_FIELD ? (
                                <IconField field={field} data={record} />
                              ) : (
                                <ColorField field={field} data={record} />
                              ),
                            )}
                          </div>
                        }
                      >
                        <CustomFormTable
                          className={classes.table}
                          group={group}
                          type="grid"
                          record={record}
                          tab={tab}
                          recordIsEditable={recordIsEditable}
                          onInputStateChange={
                            inputsChangeFunctionsRef.current[String(tab.id)]
                              .onInputStateChange
                          }
                        />
                      </CustomAccordion>
                    </div>
                  );
                })}
              </div>
            </TabPane>
          );
        })}
      </>
    </Tabs>
  );
};
