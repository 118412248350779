import type { ReactElement } from 'react';

import { PermissionAssigned } from './permission-assigned';
import { PermissionType } from './permission-type';
import { PermissionMenu } from './permission-menu';
import { PermissionFooter } from './permission-footer';

import { useStyles } from './permissions.style';
import { PermissionsAssignType } from './permission-assign-type';
import type { PermissionsViewProps } from './permissions.type';

const PermissionsView = (props: PermissionsViewProps): ReactElement => {
  const { onChangePermissionClick } = props;
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.verticalContainer}>
          <PermissionsAssignType />
          <PermissionAssigned />
        </div>

        <div className={`${classes.verticalContainer} ${classes.moreWidth}`}>
          <PermissionType />
        </div>

        <div className={classes.verticalContainer}>
          <PermissionMenu />
        </div>
      </div>

      <div className={classes.footerContainer}>
        <PermissionFooter
          onApplyPermissionClick={onChangePermissionClick('apply')}
          onRemovePermissionClick={onChangePermissionClick('remove')}
        />
      </div>
    </>
  );
};

export default PermissionsView;
