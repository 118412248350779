import { memo, useEffect, useState } from 'react';
import { useLocale } from 'react-admin';
import { isEmptyObject } from '../../helper/data-helper';
import { actorOnDispatch, actorGetActionValue } from '../../type/actor-setup';
import lodashGet from 'lodash/get';
import AccessPathView from './access-path.view';

const AccessPathController = memo(props => {
  const [labelPath, setLabelPath] = useState<string>('');
  const locale = useLocale();
  useEffect(() => {
    actorOnDispatch('metaData', details => {
      const resource = actorGetActionValue('resources')!;
      const metaData = lodashGet(details, resource.current.value, {});
      let accessPath = lodashGet(
        metaData,
        `config.accessPath.translated.${locale}`,
        lodashGet(metaData, `accessPath.translated.${locale}`, []),
      ) as string[];
      if (!accessPath) {
        accessPath = lodashGet(
          metaData,
          `config.accessPath.default`,
          lodashGet(metaData, 'accessPath.default', []),
        ) as string[];
      }
      if (!isEmptyObject(metaData) && accessPath.length) {
        setLabelPath(accessPath.join('/'));
      } else {
        setLabelPath('');
      }
    });
  }, []);

  return <AccessPathView labelPath={labelPath} />;
});

export default AccessPathController;
