import type { GetPageStatus } from './message-info-dialog.type';

export const getUsersWhoSeenMessageReport =
  'report/517998e4-8b72-41b0-a1e2-119dab135ad8';

export const defaultRequestStatus = {
  isLoading: true,
  isErrorAccrued: false,
};

/**
 * @function getPageStatus
 * @param {RequestStatusInterface} requestStatus
 * @param {UsersWhoSeenMessageReportInterface[]} usersWhoSeenMessage
 * @returns {PageStatus} page status
 */
export const getPageStatus: GetPageStatus = (requestStatus, usersWhoSeenMessage) => {
  if (requestStatus.isLoading) return 'loading';
  if (requestStatus.isErrorAccrued) return 'error';
  if (!usersWhoSeenMessage.length) return 'empty';
  return 'data';
};
