import { useState, FC } from 'react';

import TodoTasksCustomAccordionView from './todo-tasks-custom-accordion.view';

import type { TodoTasksCustomAccordionProps } from './todo-tasks-custom-accordion.type';

const TodoTasksCustomAccordionController: FC<
  TodoTasksCustomAccordionProps
> = props => {
  const { children, accordionSummary } = props;

  const [expanded, setExpanded] = useState<boolean>(true);

  /**
   * @function handleExpand
   * @returns { void } void
   */
  const handleExpand = (): void => {
    setExpanded(!expanded);
  };

  return (
    <TodoTasksCustomAccordionView
      handleExpand={handleExpand}
      accordionSummary={accordionSummary}
      expanded={expanded}
      children={children}
    />
  );
};

export default TodoTasksCustomAccordionController;
