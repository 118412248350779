import { useCallback, useEffect, useMemo, useState } from 'react';

import UserInfoDialogView from './user-info-dialog.view';
import { getCommonGroupsReportId } from '../../chat-section.helper';
import {
  getGroupUsers,
  prepareSelectedUsersBeforeCreateGroup,
  updateGroup,
} from '../../group-chats.helper';
import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../../type/actor-setup';

import type { ReactElement, MouseEvent } from 'react';
import type { ChatUsersInterface } from '../add-members-dialog/add-members-dialog.type';
import type {
  CommonGroupItemInterface,
  UserInfoDialogPropsInterface,
} from './user-info-dialog.type';
import type {
  ChatDetailInterface,
  ChatItemInterface,
} from '../../chat-section.type';

const UserInfoDialogController = (
  props: UserInfoDialogPropsInterface,
): ReactElement => {
  const { closeDialogHandler, dialogData } = props;
  const { selectedUser } = dialogData;

  const [isFetchingCommonGroups, setIsFetchingCommonGroups] = useState(true);
  const [commonGroups, setCommonGroups] = useState<CommonGroupItemInterface[]>([]);
  const [groupUsers, setGroupUsers] = useState<ChatUsersInterface[]>([]);

  useEffect(() => {
    if (selectedUser.groupuid && groupUsers.length === 0) {
      getGroupUsers(String(selectedUser.groupuid), handleSuccessGetGroupUsers);
    }

    const id = actorOnDispatch('refreshView', resource => {
      if (resource === 'refreshChatInfoDialog' && selectedUser.groupuid) {
        getGroupUsers(String(selectedUser.groupuid), handleSuccessGetGroupUsers);
      }
    });

    getCommonUsers();

    return () => {
      setCommonGroups([]);

      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: id,
      });
    };
  }, []);

  /**
   * @function getCommonUsers
   * @returns {void}
   */
  const getCommonUsers = useCallback((): void => {
    actorDispatch(
      'crudAction',
      {
        type: 'GET_LIST',
        resource: `report/${getCommonGroupsReportId}`,
        requestParameters: {
          filter: [['PersonInfo_ID', '=', selectedUser.personinfo_id]],
          pagination: {
            page: 1,
            perPage: 999999,
          },
        },
        onSuccess: (response: { data: CommonGroupItemInterface[] }) => {
          setIsFetchingCommonGroups(false);
          setCommonGroups(response.data);
        },
        onFailure: () => {
          setIsFetchingCommonGroups(false);
        },
      },
      { disableDebounce: true },
    );
  }, [selectedUser.personinfo_id]);

  /**
   * @function handleSuccessGetGroupUsers
   * @param {ChatUsersInterface[]} data
   * @returns {void} void
   */
  const handleSuccessGetGroupUsers = useCallback((data: ChatUsersInterface[]) => {
    setGroupUsers(data);
  }, []);

  /**
   * @function successFileCallback
   * @param {{filepath: string, realFileName:string}} response
   * @returns
   */
  const successFileCallback = useCallback(
    (response: { filepath: string; realFileName: string }) => {
      if (!selectedUser.groupuid) return;
      updateGroup({
        GroupUID: selectedUser.groupuid,
        GroupProfileImage: JSON.stringify(response ?? ''),
        GroupName: selectedUser.personname,
        IsUseInChat: 1,
        MemberPersonInfoIDs: prepareSelectedUsersBeforeCreateGroup(groupUsers),
        IsChannel: Number(selectedUser.ischannel),
      });
    },
    [selectedUser.personinfo_id],
  );

  /**
   * @function showUserInfo
   * @param {ChatUsersInterface} user
   * @returns {(event: MouseEvent<HTMLInputElement>) =>  void}
   */
  const showUserInfo = useCallback(
    (user: ChatUsersInterface) =>
      (_event: MouseEvent<HTMLDivElement>): void => {
        // it can redirect you to group // will implement later
      },
    [],
  );

  const tabChangeHandler = useCallback(() => {
    // implement this function when ever need
  }, []);

  const onGroupSelect = useCallback((chat: ChatItemInterface) => {
    actorDispatch('onDemandSelectedChat', {
      info: chat,
      messagesDetail: {
        data: [],
        hasMore: true,
      },
    } as ChatDetailInterface);

    closeDialogHandler();
  }, []);

  const formattedCommonGroups = useMemo(() => {
    const _formattedCommonGroups: ChatItemInterface[] = [];
    for (const group of commonGroups) {
      _formattedCommonGroups.push({
        chatdate: '',
        chattext: '',
        ischannel: false,
        isgroupadmin: 0,
        isowner: 0,
        mentionchatid: -1,
        sumnotseen: 0,
        personname: group.contactgrouptitle,
        personimage: group.personimage,
        personinfo_id: group.personinfo_id,
        groupuid: group.uniqueid,
        frompersoninfoname: '',
      });
    }

    return _formattedCommonGroups;
  }, [commonGroups]);

  return (
    <UserInfoDialogView
      closeDialogHandler={closeDialogHandler}
      successFileCallback={successFileCallback}
      tabChangeHandler={tabChangeHandler}
      showUserInfo={showUserInfo}
      selectedUser={selectedUser}
      commonGroups={formattedCommonGroups}
      isFetchingCommonGroups={isFetchingCommonGroups}
      groupUsers={groupUsers}
      onGroupSelect={onGroupSelect}
    />
  );
};

export default UserInfoDialogController;
