import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useDrag, useDrop } from 'react-dnd';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import RepeatIcon from '@material-ui/icons/Repeat';
import { useLocation, useHistory } from 'react-router-dom';

import TodoTaskItemView from './todo-task-item.view';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../../type/actor-setup';

import { showNotificationForUnknownError } from '../../../../helper/general-function-helper';
import { editTodoTask, getUniqueIdFromUrl } from '../../todo-section.helper';
import { isJsonEncodedString } from '../../../../helper/data-helper';
import { parseJSON } from '../../../../core/configProvider';
import { getTranslateForRepeatType } from '../../todo-date-options/todo-date-options.helper';
import { calculateDaysDiff, parseDate } from '../../../../helper/DateHelper';

import { useStyles } from './todo-task-item.style';

import type { LabelListInterface } from '../../todo-label/todo-label.type';
import type {
  TodoTaskItemIconsInterface,
  TodoTaskItemControllerProps,
  TodoTaskItemInterface,
} from './todo-task-item.type';
import { UserAssignedItemInterface } from '../../../dialogs-stack/assign-task-dialog/assign-task-dialog.type';

const TodoTaskItemController: FC<TodoTaskItemControllerProps> = props => {
  const { task, index, lastItemRowOrder } = props;

  const [isTaskSelected, setIsTaskSelected] = useState<boolean>(false);

  const dragDropRef = useRef<HTMLDivElement>(null);
  const translate = useTranslate();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles({ isIndexOdd: isTaskSelected, isOver: false });

  const reportType = getTranslateForRepeatType(task.repeattype_id, translate);
  const repeatDate = parseDate(task.remindmedate, translate) ?? null;
  const dueDate = parseDate(task.enddate, translate) ?? null;

  const todoTaskItemIcons: TodoTaskItemIconsInterface[] = [
    {
      name: 'isaddedtomyday',
      icon: (
        <WbSunnyOutlinedIcon
          fontSize="small"
          className={classes.metaDataInfoGroupIcon}
        />
      ),
      title: translate('todo.IsAddedToMyDay'),
      titleClassName: 'metaDataInfoGroup',
    },
    {
      name: 'remindmedate',
      icon: (
        <NotificationsOutlinedIcon
          fontSize="small"
          className={
            calculateDaysDiff(task.remindmedate) === 0
              ? classes.metaDataInfoGroupIconBlue
              : classes.metaDataInfoGroupIcon
          }
        />
      ),
      title: repeatDate,
      titleClassName:
        calculateDaysDiff(task.remindmedate) === 0
          ? 'metaDataInfoGroupBlue'
          : 'metaDataInfoGroup',
    },
    {
      name: 'enddate',
      icon: (
        <DateRangeOutlinedIcon
          fontSize="small"
          className={classes.metaDataInfoGroupIcon}
        />
      ),

      title: dueDate,
      titleClassName: 'metaDataInfoGroup',
    },
    {
      name: 'repeateveryx',
      icon: (
        <RepeatIcon fontSize="small" className={classes.metaDataInfoGroupIcon} />
      ),
      title: reportType,
      titleClassName: 'metaDataInfoGroup',
    },
  ];

  useEffect(() => {
    const id = actorOnDispatch('selectedTodoTask', detailNote => {
      if (detailNote?.id === task.id) {
        setIsTaskSelected(true);
      } else {
        setIsTaskSelected(false);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'selectedTodoTask',
        listenerId: id,
      });
    };
  }, []);

  /**
   * @function checkTaskIsAssign
   * @returns { UserAssignedItemInterface  } AssignListInterface
   */
  const checkTaskIsAssign = useCallback(():
    | UserAssignedItemInterface
    | undefined => {
    const listAssign = actorGetActionValue('todoShareList');
    const checkTask = listAssign?.find(
      item => item.personinfo_id === task.agentuser,
    );
    return checkTask;
  }, []);

  /**
   * edit item with service and refresh view
   * @function handleDroppedItem
   * @param {TodoTaskItemInterface} draggedItem
   * @returns { void }
   */
  const handleDroppedItem = (draggedItem: TodoTaskItemInterface): void => {
    editTodoTask({
      taskUID: draggedItem.uniqueid,
      newValues: {
        roworder: (Number(task.roworder) + Number(lastItemRowOrder)) / 2,
      },
      failureCallback: onEditTaskFailure,
    });
  };

  const [, connectDragSource] = useDrag(() => ({
    type: 'todoItem',
    item: task,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  const [{ isOver }, connectDropSource] = useDrop({
    accept: ['todoItem'],
    drop: draggedItemProperty =>
      handleDroppedItem(draggedItemProperty as TodoTaskItemInterface),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  connectDragSource(connectDropSource(dragDropRef));

  /**
   * dispatch selected task to actor
   * @function onTaskClick
   * @returns {void} void
   */
  const onTaskClick = (): void => {
    actorDispatch('selectedTodoTask', task, { replaceAll: true });
    const { pathname, search } = location;

    if (!search.includes('uniqueid:')) {
      history.push({
        pathname,
        search: `${search}&uniqueid:${task.uniqueid}`,
      });
      return;
    }

    const uniqueIdSections = getUniqueIdFromUrl(search);

    if (Array.isArray(uniqueIdSections) && uniqueIdSections.length > 1) {
      const uniqueIdValue: string = uniqueIdSections[1];
      const firstOccurrenceOfAnd = uniqueIdValue?.indexOf('&');

      let uniqueId = '';
      if (firstOccurrenceOfAnd > -1) {
        uniqueId = uniqueIdValue?.substring(0, firstOccurrenceOfAnd);
      } else {
        uniqueId = uniqueIdValue?.substring(0);
      }

      const newSearch = search?.replace(uniqueId, task.uniqueid);
      history.push({
        pathname,
        search: newSearch,
      });
    }
  };

  /**
   * show error notification
   * @function onEditTaskFailure
   * @param {unknown} error
   * @returns {void} void
   */
  const onEditTaskFailure = useCallback((error: unknown): void => {
    showNotificationForUnknownError(error, translate);
  }, []);

  /**
   * call service
   * @function onCompleteIconClick
   * @param {MouseEvent<SVGSVGElement>} event
   * @returns {void} void
   */
  const onCompleteIconClick = useCallback(
    (event: MouseEvent<SVGSVGElement>): void => {
      event.stopPropagation();

      editTodoTask({
        taskUID: task.uniqueid,
        newValues: { isdone: !task.isdone },
        failureCallback: onEditTaskFailure,
      });
    },
    [task.uniqueid, task.isdone],
  );

  /**
   * call service
   * @function onStarIconClick
   * @param {MouseEvent<SVGSVGElement>} event
   * @returns {void} void
   */
  const onStarIconClick = useCallback(
    (event: MouseEvent<SVGSVGElement>): void => {
      event.stopPropagation();

      editTodoTask({
        taskUID: task.uniqueid,
        newValues: { isimportant: !task.isimportant },
        failureCallback: onEditTaskFailure,
      });
    },
    [task.uniqueid, task.isimportant],
  );

  const taskLabelList: LabelListInterface[] | null = isJsonEncodedString(
    task?.labelsarray,
  )
    ? parseJSON(task?.labelsarray)
    : [];

  return (
    <TodoTaskItemView
      onCompleteIconClick={onCompleteIconClick}
      onStarIconClick={onStarIconClick}
      onTaskClick={onTaskClick}
      isTaskSelected={isTaskSelected}
      dragDropRef={dragDropRef}
      isOver={isOver}
      index={index}
      task={task}
      taskLabelList={taskLabelList}
      todoTaskItemIcons={todoTaskItemIcons}
      imageUrl={checkTaskIsAssign()?.personprofile}
    />
  );
};

export default TodoTaskItemController;
