import { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslate, useLocale } from 'react-admin';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Icon,
} from '@material-ui/core';

import { useStyles } from './search-popup-dialog.style';
import { getTranslatedName } from '../../helper/MetaHelper';
import TreeController from '../../container/tree/TreeController';
import DevExGrid from '../../component/DevExGrid';
import TabParent from '../../component/form-component-old/TabParent';
import TabChild from '../../component/form-component-old/TabChild';
import Pagination from '../../component/Pagination';
import useWidth from '../useWidth';
import LoadingBox from '../LoadingBox';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';

import type { SearchPopupDialogContainerViewProps } from './search-popup-dialog.type';

const SearchPopupDialogView = (
  props: SearchPopupDialogContainerViewProps,
): ReactElement => {
  const {
    isOpen,
    closeDialog,
    isLoading,
    resource,
    dropdownMeta,
    handleChange,
    dropForceTreeLevel,
    loadDataForGrid,
    sortPopupDialog,
    sort,
    setFilters,
    filterValues,
    handleRowClick,
    metaData,
    isSelectionEnabled,
    selectedIds,
    getInitialSelection,
    currentPage,
    perPage,
    changePerPage,
    changePage,
    loadDataForTree,
    dropTreeLevel,
    dropdownMultipleSelection,
    handleMultipleSelect,
    openQuickCreateWithForSelectedIds,
    dropdownData,
    gridColumns,
    isTopFilterOpen,
    toggleShowFilters,
    refreshGridData,
    openDropdownCreatePageInNewTab,
    actionAddButtonRef,
    onSelectedItemChange,
  } = props;

  const {
    preparedData,
    preparedDataLastLevel,
    preparedLastLevelIds,
    totalDataCount,
  } = dropdownData;

  const classes = useStyles();
  const translate = useTranslate();
  const width = useWidth();
  const locale = useLocale();

  const showCreateButton =
    !isEmpty(dropdownMeta.moduleName) && !isEmpty(dropdownMeta.tableName);

  return (
    <>
      <Dialog
        id="search-popup-dialog-div"
        open={isOpen}
        onClose={closeDialog}
        maxWidth={width}
        classes={{
          paper: classes.dialog,
        }}
        aria-labelledby="show-image-dialog-title"
      >
        {isLoading && !gridColumns?.length && <LoadingBox absolute />}

        <div className={classes.topToolbar}>
          <DialogTitle className={classes.dialogTitle} id="show-image-dialog-title">
            {isOpen && resource ? getTranslatedName(dropdownMeta, locale) : ''}
          </DialogTitle>

          <div className={classes.toolbarIconsAndSearchContainer}>
            {showCreateButton && (
              <Tooltip title={translate('ra.action.create')}>
                <IconButton
                  id={'searchPopUpCreateButton'}
                  color="primary"
                  onClick={openDropdownCreatePageInNewTab}
                >
                  <Icon>add</Icon>
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title={translate('ra.action.refresh')}>
              <IconButton
                id={'searchPopUpRefreshButton'}
                color="primary"
                onClick={refreshGridData}
              >
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title={translate('grid.filter')}>
              <IconButton
                id={'searchPopUpFilterButton'}
                color="primary"
                onClick={toggleShowFilters}
              >
                <Icon className={classNames(classes.icon, 'fa fa-filter')} />
              </IconButton>
            </Tooltip>

            <Tooltip title={translate('menu.close')}>
              <IconButton
                id={'searchPopCloseButton'}
                color="primary"
                onClick={closeDialog}
              >
                <Icon>close</Icon>
              </IconButton>
            </Tooltip>

            <div className={classes.searchContainer}>
              <TextField
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.input,
                  },
                }}
                margin="dense"
                label={translate('ra.action.search')}
                onChange={handleChange}
                key="remoteSearch"
              />
            </div>
          </div>
        </div>

        {isOpen && (
          <TabParent className={classes.childrenContainer}>
            {gridColumns && resource && (
              <TabChild
                label={translate('ra.action.list')}
                onClick={loadDataForGrid}
              >
                <DevExGrid
                  idDropDown={dropdownMeta.id}
                  hasShow={false}
                  hasEdit={false}
                  resource={resource}
                  data={
                    isLoading
                      ? {}
                      : dropForceTreeLevel &&
                        dropdownMeta.type === 'Tree' &&
                        !isEmptyObject(preparedDataLastLevel)
                      ? preparedDataLastLevel
                      : preparedData
                  }
                  ids={
                    isLoading
                      ? []
                      : dropForceTreeLevel && dropdownMeta.type === 'Tree'
                      ? preparedLastLevelIds
                      : null
                  }
                  // we don't want to enable sort
                  setSort={sortPopupDialog}
                  currentSort={sort}
                  fields={gridColumns}
                  relationMode={false}
                  setFilters={setFilters}
                  filterValues={filterValues}
                  isTopFilterOpen={isTopFilterOpen}
                  isGroupingOpen={false}
                  onRowClick={handleRowClick}
                  metaData={metaData}
                  enableSelection={isSelectionEnabled}
                  selectedIds={selectedIds}
                  getInitialSelection={getInitialSelection}
                  isLoading={isLoading}
                  isRemoteFiltering
                  hideLoading
                  onSelect={onSelectedItemChange}
                />
                <Pagination
                  page={currentPage}
                  perPage={perPage}
                  total={totalDataCount}
                  setPerPage={changePerPage}
                  setPage={changePage}
                  isLoading={isLoading}
                  rowsPerPageOptions={[]} // Todo - check this // bug -
                  isCompactMode={false} // Todo - check this // bug -
                />
              </TabChild>
            )}

            {dropdownMeta.type === 'Tree' && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <TabChild label={translate('tree.tree')} onClick={loadDataForTree}>
                {/* /** * eslint-disable-next-line @typescript-eslint/ban-ts-comment *
                @ts-ignore */}
                <TreeController
                  data={preparedData}
                  onRowClick={handleRowClick}
                  metaData={dropdownMeta}
                  forceTreeLevel={dropForceTreeLevel}
                  treeLevel={dropTreeLevel}
                  isDropdown={true}
                />
              </TabChild>
            )}
          </TabParent>
        )}

        {isSelectionEnabled && (
          <DialogActions className={classes.dialogAction}>
            <Button
              ref={actionAddButtonRef}
              color="primary"
              variant="contained"
              size="small"
              id="searchAddButton"
              onClick={
                dropdownMultipleSelection
                  ? handleMultipleSelect
                  : openQuickCreateWithForSelectedIds
              }
            >
              {translate('ra.action.add')}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default SearchPopupDialogView;
