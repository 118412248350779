import { alpha, makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  mainContainer: {
    width: '100%',
    height: '36rem',
    minWidth: '20rem',
    overflow: 'hidden',
    marginBottom: 10,
  },

  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  contentHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem',
    marginInlineEnd: '1rem',
  },

  icon: {
    cursor: 'pointer',
    padding: 6,
  },

  groupNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginInlineStart: '0.5rem',
  },

  groupImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  menu: {
    marginTop: '2rem',
  },

  membersListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },

  membersTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    //hover shadow effect
  },

  membersText: {
    marginInlineEnd: '0.5rem',
  },

  menuIcon: {
    marginInlineEnd: '0.5rem',
  },

  dialogHeaderIcons: {
    marginInlineEnd: '5rem',
  },

  tabGroupsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}));
