import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@material-ui/core';

import { useStyles } from './todo-tasks-custom-accordion.style';

import type { FC } from 'react';
import type { TodoTasksCustomAccordionViewProps } from './todo-tasks-custom-accordion.type';

const TodoTasksCustomAccordionView: FC<
  TodoTasksCustomAccordionViewProps
> = props => {
  const { handleExpand, expanded, children, accordionSummary } = props;
  const classes = useStyles();
  return (
    <Accordion expanded={expanded} onChange={handleExpand}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        className={classes.accordionSummaryContainer}
        classes={{
          root: ` ${classes.accordionSummaryRoot}`,
        }}
      >
        <Typography>{accordionSummary}</Typography>
      </AccordionSummary>
      <AccordionDetails
        className={classes.accordionDetailsContainer}
        classes={{
          root: ` ${classes.accordionSummaryRoot}`,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default TodoTasksCustomAccordionView;
