import { FC, memo } from 'react';

import { actorDispatch } from '../../../type/actor-setup';
import PermissionsAssignTypeView from './permission-assign-type.view';
import { PermissionsAssignTypeControllerType } from './permission-assign-type.type';

const PermissionsAssignTypeController: FC<
  PermissionsAssignTypeControllerType
> = props => {
  const { className } = props;

  /**
   * set selected radio button value in actor (in form of number)
   * @function handleChangePermissionAssignType
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @param {string} value
   * @returns {void}
   */
  const handleChangePermissionAssignType = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ): void => {
    event.stopPropagation();

    actorDispatch('userPermissionValue', +value, {
      path: 'selectedAssignType',
      disableDebounce: true,
    });
  };

  return (
    <PermissionsAssignTypeView
      className={className}
      onChangePermissionAssignType={handleChangePermissionAssignType}
    />
  );
};

export default memo(PermissionsAssignTypeController);
