/**
 *  find id pinned not and click on note the page scroll down
 * @function customScrollTo
 * @param {string | undefined} target
 * @returns {void} void
 */
export const customScrollTo = (target: string | undefined): void => {
  const violation = document.getElementById('scrollTo_' + target);

  if (violation) {
    violation.scrollIntoView({
      behavior: 'smooth',
    });
  }
};
