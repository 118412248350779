import { type FC } from 'react';
import { useTranslate } from 'react-admin';
import {
  Card,
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { useStyles } from './permission-assign-type.style';

import type { PermissionsAssignTypeViewProps } from './permission-assign-type.type';
import { PermissionAssignType } from '../permission-assigned/permission-assigned.type';

const PermissionsAssignTypeView: FC<PermissionsAssignTypeViewProps> = props => {
  const { onChangePermissionAssignType, className } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card className={`${classes.container} ${className}`}>
      <Typography className={classes.title}>
        {translate('permissions.assignType')}
      </Typography>

      <div className={classes.divider} />

      <div className={classes.itemsContainer}>
        <FormControl>
          <RadioGroup
            row
            name="row-radio-buttons-group"
            defaultValue={PermissionAssignType.User.toString()}
            onChange={onChangePermissionAssignType}
          >
            <FormControlLabel
              value={PermissionAssignType.User.toString()}
              control={<Radio />}
              label={translate('permissions.user')}
              className={classes.formControlLabel}
            />
            <FormControlLabel
              value={PermissionAssignType.Role.toString()}
              control={<Radio />}
              label={translate('permissions.role')}
              className={classes.formControlLabel}
            />
            <FormControlLabel
              value={PermissionAssignType.Chart.toString()}
              control={<Radio />}
              label={translate('permissions.charts')}
              className={classes.formControlLabel}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </Card>
  );
};

export default PermissionsAssignTypeView;
