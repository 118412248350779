import { makeStyles } from '@material-ui/core/styles';
import type { CustomTheme } from '../../../core/themeProvider';

const useStyles = makeStyles<CustomTheme, { headerColor: string }>(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    border: `1px solid ${theme.palette.todoLightGray}`,
  },

  header: {
    display: 'flex',
    width: '100%',
    height: 30,
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 12px',
  },
  verticalDivider: {
    height: '100%',
    width: 1,
    backgroundColor: 'black',
  },
  title: {
    flex: 1,
    cursor: 'all-scroll',
    fontWeight: 'bold',
    color: ({ headerColor }) =>
      theme.palette.getContrastText(
        headerColor === 'transparent'
          ? theme.palette.primary.appLightBackgroundColor
          : headerColor,
      ),
  },
  content: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'auto',
    },
  },
  moreButton: {
    minWidth: 'auto',
    padding: 0,
    color: ({ headerColor }) =>
      theme.palette.getContrastText(
        headerColor === 'transparent'
          ? theme.palette.primary.appLightBackgroundColor
          : headerColor,
      ),
  },
  icon: {
    fontSize: 15,
  },
}));

export default useStyles;
