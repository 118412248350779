import { type FC, useCallback, useEffect, useState, useRef } from 'react';
import { useTranslate } from 'react-admin';

import { getPermissionReason } from '../../show-permissions/permission-tabs/show-permission-tab.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';

import SimpleDialogPermissionsView from './permission-show-reason-dialog.view';
import {
  computePermKey,
  getDialogTitle,
  removeSelectedPermissions,
} from './permission-show-reason-dialog.helper';
import {
  showNotification,
  showNotificationForUnknownError,
} from '../../../helper/general-function-helper';

import {
  type RowData,
  type PermissionShowReasonDialogControllerInterface,
  defaultTabKey,
} from './permission-show-reason-dialog.type';
import type { PermissionResponseType } from '../../show-permissions/permission-tabs/dynamic-permission-data.type';
import { CUSTOM_POST } from '../../../core/data-Provider.helper';
let isDeletePermission = false;

const PermissionShowReasonDialogController: FC<
  PermissionShowReasonDialogControllerInterface
> = props => {
  const { closeDialogHandler, dialogData } = props;
  const [permissionReason, setPermissionReason] = useState<PermissionResponseType[]>(
    [],
  );

  const [showPermissionReasonRefresh, setShowPermissionReasonRefresh] =
    useState(false);

  const activeTabKeyRef = useRef<string>(defaultTabKey.activeTabOne);

  const translate = useTranslate();
  const resource = 'permission/show';
  const activeTab = actorGetActionValue('userPermissionValue');
  const filterData = actorGetActionValue('filterDataIsChanged')!;
  const filterFormFields = actorGetActionValue('filterFormFields')!;
  const filterDetails = Object.values(filterFormFields[resource])?.[0];
  const selectPersonForFilter = filterDetails?.fullDropdownItem?.fullname;

  const {
    column: cellDataColumn,
    data: cellDataData,
    itemData: cellDataItemData,
    values: cellDataValues,
    node: cellDataNode,
  } = dialogData.cellData ?? {};

  const permKey = dialogData?.column?.permKey;
  const cellDataName = cellDataColumn?.name;
  const cellName = cellDataValues?.[0] ?? cellDataItemData?.label ?? '';
  const cellNameModule = cellDataValues?.[1] ?? cellDataItemData?.label ?? '';
  const columnTitle = cellDataColumn?.caption ?? cellDataNode?.itemData?.label ?? '';

  // compute permission key
  const permissionKey = computePermKey(
    permKey,
    cellDataData,
    cellDataName,
    cellDataItemData,
  );

  let fieldName = '';
  if (cellDataData?.fieldname) {
    fieldName = cellDataData?.fieldname as string;
  }

  const dialogTitle = getDialogTitle({
    fieldName,
    columnTitle,
    activeTab,
    cellData: dialogData.cellData ?? {},
    cellName,
    cellNameModule,
  });

  const filter = [
    ...filterData[resource],
    'and',
    ['permKey', 'equals', permissionKey ?? ' '],
  ];
  const filterWithoutPermKey = [['permKey', 'equals', permissionKey ?? ' ']];

  useEffect(() => {
    const id = actorOnDispatch('signal', detail => {
      if (detail === 'showReasonPermission') {
        setShowPermissionReasonRefresh(prev => !prev);
      }
    });
    return () => {
      actorRemoveAction({
        actionName: 'signal',
        listenerId: id,
      });
    };
  }, []);

  useEffect(() => {
    handleGetPermissionReason(activeTabKeyRef.current);
    actorDispatch('userPermissionValue', { selectedName: dialogTitle });
  }, [showPermissionReasonRefresh]);

  /**
   * @function handleGetPermissionReason
   * @param {string} key
   * @returns {void} void
   */
  const handleGetPermissionReason = (key: string): void => {
    if (key == defaultTabKey.activeTabOne) {
      getPermissionReason(
        onGetPermissionReasonSuccess,
        onGetPermissionReasonFailure,
        {
          filter,
        },
      );
    } else {
      getPermissionReason(
        onGetPermissionReasonSuccess,
        onGetPermissionReasonFailure,
        {
          filter: filterWithoutPermKey,
        },
      );
    }
  };

  /**
   * @function onTabChange
   * @param {string} activeKey
   * @returns {void} void
   */
  const onTabChange = (_activeKey: string): void => {
    activeTabKeyRef.current = _activeKey;
    handleGetPermissionReason(_activeKey);
  };

  /**
   * @function onGetPermissionReasonSuccess
   * @param { PermissionReasonInterface[] } response
   * @returns {void} void
   */
  const onGetPermissionReasonSuccess = useCallback(
    (response: PermissionResponseType[]): void => {
      setPermissionReason(response);
    },
    [activeTabKeyRef.current],
  );

  /**
   * @function onGetPermissionReasonFailure
   * @returns {void} void
   */
  const onGetPermissionReasonFailure = useCallback((error): void => {
    showNotificationForUnknownError(error, translate, true);
    setPermissionReason([]);
  }, []);

  /**
   * @function addUser
   * @returns {void} void
   */
  const addUser = useCallback((): void => {
    actorDispatch('quickDialog', {
      permissionAddUserIsOpen: true,
      data: { permissionKey },
    });
  }, []);

  /**
   * @function onSuccessDeleteUserPermission
   * @returns {void} void
   */
  const onSuccessDeleteUserPermission = useCallback(
    ({ data }): void => {
      if (data.code !== 200) {
        showNotification(data.userMessage, 'success', {
          fromQuickCreateDialog: true,
          forceSnackbar: true,
        });
      } else {
        showNotification(
          translate('ra.notification.successfully_executed'),
          'success',
          {
            fromQuickCreateDialog: true,
            forceSnackbar: true,
          },
        );

        handleGetPermissionReason(activeTabKeyRef.current);

        isDeletePermission = true;
      }
    },
    [activeTabKeyRef.current],
  );

  /**
   * @function deleteUserPermission
   * @param { RowData } row
   * @returns { void } void
   */
  const deleteUserPermission = (row: RowData) => {
    actorDispatch(
      'crudAction',
      {
        type: CUSTOM_POST,
        resource: 'permission/RemovePermission',
        data: removeSelectedPermissions(row, permissionKey, dialogTitle),
        onSuccess: onSuccessDeleteUserPermission,
        onFailure: (error: { data: { userMessage: string } }) => {
          showNotificationForUnknownError(error.data.userMessage, translate, true);
        },
      },
      { disableDebounce: true },
    );
  };

  /**
   * @function deleteUserConfirmation
   * @param { RowData } row
   * @returns { void } void
   */
  const deleteUserConfirmation = useCallback(
    (row: RowData) => (): void => {
      actorDispatch(
        'quickDialog',
        {
          confirmationIsOpen: true,
          data: {
            content: translate('ra.message.are_you_sure'),
            onConfirm: (confirmationCallback?: () => void) => {
              confirmationCallback?.();
              deleteUserPermission(row);
            },
          },
        },
        {
          disableDebounce: true,
          replaceAll: true,
          callerScopeName:
            'PermissionShowReasonDialogController => deleteUserPermission',
        },
      );
    },
    [],
  );

  /**
   * @function customCloseDialogHandler
   * @returns { void } void
   */
  const customCloseDialogHandler = (): void => {
    if (isDeletePermission) {
      dialogData.refreshTabData?.();
    }
    closeDialogHandler();
  };

  if (!permissionReason) {
    return <></>;
  }

  return (
    <SimpleDialogPermissionsView
      handleTabChange={onTabChange}
      closeDialogHandler={customCloseDialogHandler}
      deleteUserPermission={deleteUserConfirmation}
      addUser={addUser}
      dialogData={dialogData}
      permissionReason={permissionReason}
      dialogTitle={dialogTitle}
      selectPersonForFilter={selectPersonForFilter}
    />
  );
};

export default PermissionShowReasonDialogController;
