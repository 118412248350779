import { FC } from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Icon,
  CardMedia,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { linkToRecord } from 'ra-core';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import { getValue, API_URL } from '../../../../core/configProvider';
import { isFileTypeImage } from '../../../../helper/FileHelper';
import { CreateEditNote } from '../create-edit-note';
import { actorGetActionValue } from '../../../../type/actor-setup';
import { useStyles } from './note-stream-card.style';
import DisplayDate from '../../../DisplayDate';
import useWidth from '../../../useWidth';
import { ProfileAvatar } from '../../../profile-avatar';
import textImage from '../../../../images/text.png';
import Pin from '../../../../images/pin.png';
import Unpin from '../../../../images/unpin.png';

import { showImageDialog } from '../../../list';

import type { NoteStreamCardViewProps } from './note-stream-card.type';
import type { GlobalParametersInterface } from '../../../../helper/Types';

const NoteStreamCardView: FC<NoteStreamCardViewProps> = props => {
  const {
    openStatusNoteDialog,
    toggleShowFullText,
    openDeleteDialog,
    closeEditDialog,
    openEditDialog,
    onSubmitNote,
    refreshView,
    isCardInNoteStream,
    relationPrimaryKey,
    isEditDialogOpen,
    relationResource,
    parentFieldName,
    parentRecordId,
    parentResource,
    showFullText,
    relationData,
    isDetailView,
    attachments,
    shortNote,
    fullNote,
    isPinNote,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const width = useWidth();

  const creatorName =
    relationData.__createuserid_value ?? relationData.createusername;

  const globalParameters = actorGetActionValue(
    'profile',
    'profileData.globalparameters',
  ) as GlobalParametersInterface;
  const allowPermissionChangeNote = Boolean(
    relationData.createuserid === Number(globalParameters?.currentUserID),
  );

  const apiUrl = getValue(API_URL);

  const scrollToUniqAccordion = `${'scrollTo_' + relationData[relationPrimaryKey]}`;

  const getUniqNote = actorGetActionValue('scroll');

  return (
    <div
      id={scrollToUniqAccordion}
      className={
        getUniqNote?.uniqIdToScroll === String(relationData[relationPrimaryKey])
          ? classes.scrollTo
          : isCardInNoteStream
          ? classes.noteItemInSidebar
          : classes.noteItem
      }
      data-test-note-id={relationData.id}
    >
      <ProfileAvatar userId={relationData.createuserid as number} />

      <div className={classes.noteContent}>
        <div className={classes.noteContentHeader}>
          <div className={classes.author}>
            {creatorName as string} -{' '}
            <Link
              to={linkToRecord(
                '/' + parentResource,
                relationData[parentFieldName],
                'show',
              )}
              className={classes.parentLink}
            >
              {relationData[`__${parentFieldName}_value`] as string}
            </Link>
          </div>

          <div className={classes.noteTime}>
            <DisplayDate value={relationData.createdate as string} />
          </div>
        </div>

        {fullNote && showFullText && (
          <div
            className={classNames(
              classes.note,
              !isDetailView && classes.noteInStream,
            )}
          >
            <span id="fullNote">{fullNote}</span>
          </div>
        )}

        {fullNote && !showFullText && (
          <div
            className={classNames(
              classes.note,
              !isDetailView && classes.noteInStream,
            )}
          >
            <div>{shortNote}</div>
            <div className={classes.showMoreButton} onClick={toggleShowFullText}>
              {translate('form.showFull')}
            </div>
          </div>
        )}

        <div className={classes.attachmentContainer}>
          {attachments &&
            attachments.map(attachment => {
              const fileUrl = `${apiUrl}/${attachment.folderPath}/${
                attachment.uploadedFileName
                  ? attachment.uploadedFileName
                  : attachment.fileName
              }`;

              const isImage = isFileTypeImage(attachment.originalFileName);
              if (isImage) {
                return (
                  <CardMedia
                    image={fileUrl}
                    key={fileUrl}
                    component="img"
                    alt={attachment.originalFileName}
                    title={attachment.originalFileName}
                    className={classes.media}
                    onClick={() =>
                      showImageDialog({
                        fullUrl: fileUrl,
                        title: attachment.originalFileName,
                      })
                    }
                    data-test-note-image
                  />
                );
              }

              return (
                <a
                  key={fileUrl}
                  href={fileUrl}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardMedia
                    image={textImage}
                    component="img"
                    alt={attachment.originalFileName}
                    title={attachment.originalFileName}
                    className={classes.media}
                  />
                </a>
              );
            })}
        </div>
      </div>

      {isDetailView && (
        <div id="noteAction" className={classes.noteAction}>
          {!isPinNote && (
            <Tooltip title={translate('general.pin')}>
              <IconButton
                color="primary"
                id="StatusNoteButton"
                disabled={!allowPermissionChangeNote}
                onClick={openStatusNoteDialog(relationData[relationPrimaryKey])}
              >
                <img
                  src={Pin}
                  className={
                    allowPermissionChangeNote
                      ? classes.iconPin
                      : classes.iconPinDisable
                  }
                />
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            color="primary"
            onClick={openEditDialog}
            id="EditButton"
            data-test-is-enable={!allowPermissionChangeNote}
            disabled={!allowPermissionChangeNote}
          >
            <Icon fontSize="small">edit</Icon>
          </IconButton>
          <IconButton
            color="primary"
            onClick={openDeleteDialog(relationData[relationPrimaryKey])}
            id="deleteButton"
            data-test-is-enable={!allowPermissionChangeNote}
            disabled={!allowPermissionChangeNote}
          >
            <Icon fontSize="small">delete</Icon>
          </IconButton>
        </div>
      )}
      {isPinNote && (
        <div id="noteActionPin" className={classes.noteActionPin}>
          <Tooltip title={translate('general.unpin')}>
            <IconButton
              color="primary"
              id="StatusNoteButton"
              disabled={!allowPermissionChangeNote}
              onClick={openStatusNoteDialog(relationData[relationPrimaryKey])}
            >
              <img
                src={Unpin}
                className={
                  allowPermissionChangeNote
                    ? classes.iconPin
                    : classes.iconPinDisable
                }
              />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Dialog
        open={isEditDialogOpen}
        maxWidth={width}
        fullWidth
        onClose={closeEditDialog}
        aria-labelledby="show-image-dialog-title"
      >
        <DialogContent>
          <CreateEditNote
            relationData={relationData}
            parentFieldName={parentFieldName}
            parentId={parentRecordId}
            parentResource={parentResource}
            onSubmitNote={onSubmitNote}
            refreshView={refreshView}
            relationResource={relationResource}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NoteStreamCardView;
