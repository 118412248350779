import { makeStyles } from '@material-ui/core';
import { CustomTheme, themeParams } from '../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  avatar: {
    width: 50,
    height: 50,
    '& .MuiAvatar-img': {
      objectFit: 'unset',
      borderRadius: '50%',
      width: '150%',
      height: '120%',
      filter: 'saturate(140%)',
    },
  },
  dateBox: {
    color: theme.palette.primary.appSecondaryTextColor,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  contactInfo: {
    color: themeParams.palette.mail.primaryTextColor,
  },
  mailSummaryContainer: {
    marginTop: '15px', //production and build version is different, don't decrease the margin number
  },
}));
