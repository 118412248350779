export const descriptionFields = [
  {
    fixCalendar: '',
    regex: null,
    regexDescription: null,
    width: 100,
    allowSort: true,
    hasValidationActions: true,
    comment: 'توضیحات',
    tableName: 'messages',
    numberOfLines: 1,
    dataType: {
      erp: 'text',
      sql: 'bit',
      simple: 'text',
      id: 104,
      defaultOperator: 'Equals',
    },
    disabled: false,
    defaultValue: false,
    parentField: null,
    moduleName: 'automation',
    id: 639761,
    caption: 'توضیحات',
    translatedCaption: {
      fa: 'توضیحات',
      en: 'description',
      ar: 'وصف',
    },
    translatedComment: {
      fa: 'توضیحات',
      en: 'description',
      ar: 'وصف',
    },
    relatedName: null,
    linkName: null,
    name: '__userDescription',
    required: true,
    isHashed: false,
    hasSummary: false,
    miladiDisplay: false,
    filterDefaultValue: null,
    maxLength: null,
    dropdown: null,
    report: null,
    minValue: null,
    maxValue: null,
    values: true,
    precision: 4,
    multiLanguage: false,
    relatedLocaleFieldId: null,
    multiSelectDropdown: null,
    relatedParameterName: null,
    hidden: false,
    hasViewPermission: false,
    hasEditPermission: false,
    format: '',
    uiVisible: null,
    uiEnable: null,
    defaultValueGlobalParameter: null,
    javaScriptFormula: '',
    codingPattern: null,
    dropdownValueMemberVisibility: false,
    isUniqueInGrid: false,
    isMapped: false,
    groupingPriority: 0,
    treeParentField: null,
    javaScriptUiEnable: null,
    javaScriptUiVisible: null,
    customOption: { dontShowErrorIcon: true, noLabel: true },
  },
];
