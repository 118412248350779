import { type FC } from 'react';
import { useTranslate } from 'react-admin';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';

import useStyles from './dashboard-card-form-dialog.style';

import type { DashboardCardFormDialogViewPropsInterface } from './dashboard-card-form-dialog.type';

const DashboardCardFormDialogView: FC<
  DashboardCardFormDialogViewPropsInterface
> = props => {
  const {
    closeDialogHandler,
    addLayoutToGrid,
    editGridLayout,
    isInAddMode,
    preDesignedGadgets,
    onDashboardFormDataChange,
    formData,
    gadgetListInputValue,
    reportColumns,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div>
      <DialogTitle className={classes.dialogTitle}>
        {translate(isInAddMode ? 'dashboard.createGadget' : 'dashboard.editGadget')}
      </DialogTitle>

      <DialogContent className={classes.DialogContentContainer}>
        <FormControl fullWidth>
          <TextField
            label={translate('notification.title')}
            onChange={onDashboardFormDataChange('title')}
            value={formData.title}
          />
        </FormControl>

        <Autocomplete
          className={classes.formControl}
          fullWidth
          onChange={onDashboardFormDataChange('gadgetUniqueId')}
          options={preDesignedGadgets ?? []}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label={translate('gadget.chooseChart')}
                error={formData.gadgetUniqueId ? false : true}
                required
              />
            );
          }}
          value={gadgetListInputValue ?? null}
          getOptionLabel={option => option?.gadgetstitle}
        />

        <FormControl className={classes.horizontalFormControl} fullWidth>
          <Typography>{translate('gadget.autoRefreshReportData')}</Typography>
          <Checkbox
            checked={!!formData.autoRefreshReportData}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            onChange={onDashboardFormDataChange('autoRefreshReportData')}
            name="autoRefreshReportData"
          />
        </FormControl>

        {!!formData.autoRefreshReportData && (
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label={translate('gadget.autoRefreshReportDataTime')}
              type="number"
              value={Number(formData.autoRefreshReportDataTime)}
              onChange={onDashboardFormDataChange('autoRefreshReportDataTime')}
            />
          </FormControl>
        )}

        <FormControl className={classes.horizontalFormControl} fullWidth>
          <Typography>{translate('gadget.sortReportData')}</Typography>
          <Checkbox
            checked={!!formData.isSortEnabled}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            onChange={onDashboardFormDataChange('isSortEnabled')}
            name="isSortEnabled"
          />
        </FormControl>

        {!!formData.isSortEnabled && (
          <div className={classes.sortContainer}>
            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                onChange={onDashboardFormDataChange('sortField')}
                options={reportColumns}
                renderInput={params => {
                  return (
                    <TextField {...params} label={translate('gadget.baseOfField')} />
                  );
                }}
                value={
                  reportColumns.find(column => column.name === formData.sortField) ??
                  null
                }
                getOptionLabel={option => option.title}
              />
            </FormControl>

            <div className={classes.sortOrderContainer}>
              <IconButton onClick={onDashboardFormDataChange('sortOrder')}>
                {formData.sortOrder === 'asc' ? (
                  <ArrowUpwardIcon fontSize="small" />
                ) : (
                  <ArrowDownwardIcon fontSize="small" />
                )}
              </IconButton>

              <Typography>
                {formData.sortOrder === 'asc'
                  ? translate('gadget.ascending')
                  : translate('gadget.descending')}
              </Typography>
            </div>
          </div>
        )}
      </DialogContent>

      <DialogActions className={classes.dialogActionsContainer}>
        {isInAddMode ? (
          <Button onClick={addLayoutToGrid}>{translate('css.save')}</Button>
        ) : (
          <Button onClick={editGridLayout}>{translate('css.save')}</Button>
        )}
        <Button onClick={closeDialogHandler}>{translate('confirm.cancel')}</Button>
      </DialogActions>
    </div>
  );
};

export default DashboardCardFormDialogView;
