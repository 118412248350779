import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  accordionSummaryContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
  },

  accordionDetailsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.palette.primary.appPrimaryBackgroundColor,
  },
  accordionSummaryRoot: {
    padding: 0,
  },
}));
