import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';
import { MultiFileStreamItemStyleProps } from './multi-file-stream-input.type';

export const useStyles = makeStyles<CustomTheme, MultiFileStreamItemStyleProps>(
  theme => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #BDBDBD',
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.primary.appSecondaryBackgroundColor,
      padding: '8px 0',
      margin: 0,
      cursor: 'pointer',
      width: '100%',
    },

    disabled: {
      backgroundColor: '#e0e0e0',
      cursor: 'default',
      color: theme.palette.primary.disabledFieldColor,
    },

    fieldsetMessage: {
      border: props => `1px solid ${getColorBaseOfStatus(props.messageType, theme)}`,
    },

    customFileInput: {
      width: 0,
      opacity: 0,
      cursor: 'pointer',
      padding: 0,
      height: 35,
    },

    wrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },

    chipsSection: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
      width: '100%',
      maxHeight: 100,
      overflowY: 'auto',
      fontSize: 12,
    },
    chipsSectionTodo: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 5px',
      width: '100%',
      maxHeight: 100,
      overflowY: 'hidden',
    },
    fileIcon: {
      color: theme.palette.primary.appPrimaryBorderInputColor,
    },

    action: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 64px',
      justifyContent: 'center',
      alignItems: 'center',
    },

    chipContainerImage: {
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 5,
      padding: '0 8px',
      flexWrap: 'wrap',
      border: '1px solid gray',
      '&:hover': {
        backgroundColor: ({ disabled }) => (!disabled ? '#c1dbeb' : ''),
      },
    },

    chipImage: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: 5,
      alignItems: 'center',
      '& svg': {
        width: '40px',
        marginRight: 5,
      },
    },

    progressbar: {
      border: '1px solid green',
      width: 45,
      height: 10,
    },

    caption: {
      fontSize: 'inherit',
    },
    previewImg: {
      objectFit: 'cover',
      marginRight: 5,
    },
    containerText: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 3,
      marginLeft: 3,
    },
    chipText: {
      margin: '0 !important',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 200,
      paddingBottom: 0,
      fontSize: 11,
    },
    chipTextSize: {
      color: theme.palette.grey[600],
      direction: theme.direction,
      margin: 0,
      fontSize: 11,
    },
  }),
);
