import type { FC } from 'react';

import { TableColorFilterView } from './table-color-filter.view';

import type { TableColorFilterPropsInterface } from './table-color-filter.type';

const TableColorFilterController: FC<TableColorFilterPropsInterface> = props => {
  const { tableRowColors, tooltipText, defaultText, colorChangeHandler } = props;

  return (
    <TableColorFilterView
      colorChangeHandler={colorChangeHandler}
      tableRowColors={tableRowColors}
      tooltipText={tooltipText}
      defaultText={defaultText}
    />
  );
};

export default TableColorFilterController;
