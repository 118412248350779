import { FC, memo, useEffect, useState } from 'react';
import { getInputsInitialAppearanceCharacteristicsBaseOnFieldProperty } from '../../../helper/meta-helper';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../../../helper/settings-helper';
import { FieldType } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
  FormKeyMode,
} from '../../../type/actor-setup';
import { FormActions, InputRefContent, OnBlurParams } from '../../form';
import { updateInputsUiSpecs } from '../../form/form-with-tabs/form-with-tabs.helper';
import { updateInputsState } from './mail-form.helper';

import { MailFormInterface } from './mail-form.type';
import MailFormView from './mail-form.view';
import { defaultTemplateReportId } from './mail-form.helper';
import { field as templateIdField } from '../mail-form-dialog-actions/template-action/template-action.helper';
import { RUN_SERVICE } from '../../../core/data-Provider.helper';
const MailFormController: FC<MailFormInterface> = memo(props => {
  const {
    fields,
    formContainerClass,
    inputContainerClass,
    showBCCTextButton = true,
    ...rest
  } = props;
  const [isBCCInputActive, setIsBCCInputActive] = useState<boolean>(false);
  const { name, id: fieldId } = templateIdField;

  /**
   * @function onSelectTemplate
   * @returns { void }
   */
  const onSelectTemplate = (): void => {
    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
    actorDispatch('crudAction', {
      type: RUN_SERVICE,
      // payload: {
      actionUniqueId: defaultTemplateReportId,
      data: {},
      onSuccess: response => {
        formActionsHandler(FormActions.InputBlur, {
          fieldName: name,
          value: response.data.templates_id,
        } as OnBlurParams);
      },
    });
  };
  /**
   * @function handleActiveBCCInput
   * @returns { void }
   */
  const handleActiveBCCInput = (): void => {
    const currentFormData = actorGetActionValue(
      'formData',

      `automation/messages.${FormKeyMode.ROOT}`,
    );

    actorDispatch(
      'formData',
      { ...currentFormData, bccinputisactive: true },
      {
        path: `automation/messages.${FormKeyMode.ROOT}`,
        disableDebounce: true,
        callerScopeName:
          'mail-section/mail-form/mail-form.controller.tsx handleActiveBCCInput',
      },
    );
    setIsBCCInputActive(true);
  };

  useEffect(() => {
    const inputsRef = actorGetActionValue(
      'inputsRef',
      `automation/messages.${FormKeyMode.ROOT}`,
    ) as Record<string, InputRefContent> | undefined;

    const formData = actorGetActionValue('formData', [
      'automation/messages',
      FormKeyMode.ROOT,
    ]) as Record<string, unknown>;
    updateInputsState(inputsRef, formData);
    if (inputsRef) updateInputsUiSpecs(inputsRef, fields as FieldType[]);
    // it will execute and set in actor
    actorSetActionValue(
      'inputsInitialAppearanceCharacteristics',
      getInputsInitialAppearanceCharacteristicsBaseOnFieldProperty(
        fields as FieldType[],
      ),
      {
        path: `automation/messages.${FormKeyMode.ROOT}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}`,
      },
    );
    onSelectTemplate();
  }, []);

  return (
    <MailFormView
      {...rest}
      fields={fields}
      formContainerClass={formContainerClass}
      inputContainerClass={inputContainerClass}
      handleActiveBCCInput={handleActiveBCCInput}
      isBCCInputActive={isBCCInputActive}
      showBCCTextButton={showBCCTextButton}
    />
  );
});

export default MailFormController;
