import { memo } from 'react';
import { ChromePicker } from 'react-color';

import type { ColorPickerMenuPopupViewProps } from './color-picker-menu-popup.type';

const ColorPickerMenuPopupView = memo((props: ColorPickerMenuPopupViewProps) => {
  const { onColorSelect, color, setColor } = props;

  return (
    <ChromePicker
      color={color}
      onChange={event => setColor(event.hex)}
      onChangeComplete={onColorSelect}
    />
  );
});
export default ColorPickerMenuPopupView;
