export const profileSettingFields = [
  {
    caption: 'انبار کاربر',
    dataType: {
      erp: 'dropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    javaScriptFormula: '',
    dropdown: {
      columns: [
        {
          isHidden: false,
          width: 100,
          priority: 0,
          title: 'warehouse_id',
          format: '',
          dataType: {
            erp: 'bigint',
            sql: 'bigint',
            simple: 'number',
            id: 127,
            defaultOperator: 'Equals',
          },
          translatedTitle: {
            fa: 'انبار',
            en: 'WareHouse',
            ar: 'المخزن المتطلب',
          },
        },
        {
          isHidden: false,
          width: 250,
          priority: 1,
          title: 'warehousetitle',
          format: '',
          dataType: {
            erp: 'string',
            sql: 'nvarchar(max)',
            simple: 'string',
            id: 167,
            defaultOperator: 'Contains',
          },
          translatedTitle: {
            fa: 'نام انبار',
            en: 'نام انبار',
            ar: 'نام انبار',
          },
        },
      ],
      displayMember: 'warehousetitle',
      displayMember2: null,
      forceTreeLevel: false,
      id: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
      moduleName: 'task',
      parameters: [],
      remoteSearch: false,
      translatedComment: null,
      translatedTitle: {
        fa: 'انبار/شعبه کاربر جاری',
        en: 'Warehouse/Branch current user',
        ar: 'المستودع / فرع المستخدم الحالي',
      },
      type: 'Simple',
      uniqueId: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
      valueMember: 'warehouse_id',
      name: 'warehouse_id',
      title: 'warehousetitle',
    },
    hidden: false,
    id: 0,
    name: 'currentWareHouseID',
    translatedCaption: {
      fa: 'انبار/شعبه کاربر جاری',
      en: 'Warehouse/Branch current user',
      ar: 'المستودع / فرع المستخدم الحالي',
    },
    relatedName: '__currentWareHouseID_value',
    required: true,
    values: null,
    resource: '',
  },
  {
    caption: 'تفویض اختیار',
    dataType: {
      erp: 'delegationDropdown',
      sql: 'bigint',
      simple: 'number',
      id: 1,
      defaultOperator: 'Equals',
    },
    disabled: false,
    javaScriptFormula: '',
    dropdown: {
      columns: null,
      displayMember: 'caption',
      displayMember2: null,
      forceTreeLevel: false,
      id: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
      moduleName: 'task',
      parameters: [],
      remoteSearch: false,
      translatedComment: null,
      translatedTitle: {
        fa: 'تفویض اختیار',
        en: 'Delegation of authority',
        ar: 'تفويض السلطة',
      },
      type: 'Simple',
      uniqueId: '46a93036-0de8-4f4e-bc71-b8b9bab0f0e9',
      valueMember: 'path',
      name: 'authorityDelegation',
      title: 'caption',
    },
    hidden: false,
    id: 0,
    name: 'authorityDelegation',
    translatedCaption: {
      fa: 'تفویض اختیار',
      en: 'Delegation of authority',
      ar: 'تفويض السلطة',
    },
    relatedName: '__AuthorityDelegation_value',
    required: false,
    values: null,
    resource: '',
  },
];
