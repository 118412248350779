import type { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Checkbox, Typography } from '@material-ui/core';

import { useStyles } from './upload-files-form.style';
import { NewMessage } from '../new-message';
import { AppList } from '../../app-list';
import { FileCard } from './file-card';

import type { UploadFilesFormViewPropsInterface } from './upload-files-form.type';
import type { UploadedFileInterface } from '../chat-section.type';

const UploadFilesFormView = (
  props: UploadFilesFormViewPropsInterface,
): ReactElement => {
  const {
    uploadedFiles,
    sendAsAlbum,
    handleSendFiles,
    onDeleteFile,
    onSendAsAlbumClick,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classes.root}>
      <Box component="span" fontWeight="bold" fontSize={14}>
        {translate('chat.selectedFiles', {
          count: uploadedFiles.length,
        })}
      </Box>

      <AppList
        className={classes.filesArea}
        data={uploadedFiles}
        renderRow={(item, index) => (
          <FileCard
            file={item as UploadedFileInterface}
            onDeleteCard={onDeleteFile(index)}
          />
        )}
      />

      {uploadedFiles.length > 1 ? (
        <div className={classes.sendAsAlbumContainer}>
          <Typography>{translate('chat.sendAsAlbum')}</Typography>

          <Checkbox
            checked={sendAsAlbum}
            onChange={onSendAsAlbumClick}
            id="sendAsAlbumCheckbox"
          />
        </div>
      ) : null}

      <Box>
        <NewMessage sendMessageAction={handleSendFiles} embeded />
      </Box>
    </Box>
  );
};

export default UploadFilesFormView;
