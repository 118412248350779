import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';

import {
  getTranslateForRepeatType,
  repeatTypeDropDown,
} from '../todo-date-options.helper';
import TodoRepeatTaskView from './todo-repeat-task.view';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { editTodoTask } from '../../todo-section.helper';
import { showNotificationForUnknownError } from '../../../../helper/general-function-helper';

import type { TodoTaskItemInterface } from '../../todo-tasks/todo-task-item/todo-task-item.type';
import type {
  TodoContextMenuInterface,
  TodoRepeatTaskControllerPropsInterface,
} from './todo-repeat-task.type';

const TodoRepeatTaskController: FC<TodoRepeatTaskControllerPropsInterface> = memo(
  props => {
    const { isMinimized = false } = props;
    const [todoSelectedTaskInfo, setTodoSelectedTaskInfo] =
      useState<TodoTaskItemInterface | null>(null);
    const [repeatType, setRepeatType] = useState<number | null>(null);
    const [repeatEveryCount, setRepeatEveryCount] = useState<number | null>(null);
    const [repeatWeekDays, setRepeatWeekDays] = useState<string[]>([]);
    const [isOpenOptionsDialog, setIsOpenOptionsDialog] = useState<boolean>(false);
    const [contextMenuDetails, setContextMenuDetails] =
      useState<TodoContextMenuInterface>(null);

    const translate = useTranslate();

    const weekDaysName: string[] = [
      'repeatweekdayssa',
      'repeatweekdayssu',
      'repeatweekdaysmo',
      'repeatweekdaystu',
      'repeatweekdayswe',
      'repeatweekdaysth',
      'repeatweekdaysfr',
    ];

    useEffect(() => {
      actorOnDispatch('selectedTodoTask', taskInfo => {
        if (isMinimized) return;
        setTodoSelectedTaskInfo(taskInfo);
        setRepeatType(taskInfo?.repeattype_id);
        setRepeatEveryCount(Number(taskInfo?.repeateveryx));

        //set default for repeat week day
        const tempRepeatWeekDays: string[] = [];
        weekDaysName.map(dayName => {
          if (taskInfo?.[dayName]) {
            tempRepeatWeekDays.push(dayName);
          }
        });
        setRepeatWeekDays(
          tempRepeatWeekDays.length === 0
            ? ['repeatweekdayssu']
            : tempRepeatWeekDays,
        );
      });
      //for clearing refresh icon after create
      const offlineRefreshListenerId = actorOnDispatch(
        'offlineRefreshView',
        detail => {
          if (detail?.entity !== 'todoTasks') return;
          if (detail?.mode === 'create' && isMinimized) {
            setRepeatType(null);
          }
        },
      );

      return () => {
        actorRemoveAction({
          actionName: 'offlineRefreshView',
          listenerId: offlineRefreshListenerId,
        });
      };
    }, []);

    /**
     * @function handleContextMenu
     * @param { React.MouseEvent } event
     * @returns { void }
     */
    const handleOpenContextMenu = (event: React.MouseEvent): void => {
      event.preventDefault();
      setContextMenuDetails(
        contextMenuDetails === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    };

    /**
     * @function handleClickMenuItem
     * @param { string } selectedMenu
     * @returns { void }
     */
    const handleClickMenuItem = useCallback(
      (selectedMenu: string): void => {
        if (selectedMenu == 'options') {
          setIsOpenOptionsDialog(true);
        } else {
          setRepeatType(Number(selectedMenu));
          saveRepeatOptions(Number(selectedMenu), repeatEveryCount, repeatWeekDays);
        }
      },
      [repeatType, repeatEveryCount, repeatWeekDays],
    );

    /**
     * @function handleSaveRepeatOptions
     * @returns { void }
     */
    const handleSaveRepeatOptions = (): void => {
      saveRepeatOptions(repeatType, repeatEveryCount, repeatWeekDays);
    };

    /**
     * @function saveRepeatOptions
     * @param { number | null } repeatTypeID
     * @param { number | null } repeatCount
     * @param { string[] } weekDays
     * @returns { void }
     */
    const saveRepeatOptions = (
      repeatTypeID: number | null,
      repeatCount: number | null,
      weekDays: string[],
    ): void => {
      //prepare necessary data for send to api
      const prepareDataForApi = {
        repeattype_id: repeatTypeID,
        repeateveryx: Number(repeatCount) > 0 ? repeatCount : 1,
      };

      //first set default "false" for weekdays
      weekDaysName.map(dayName => {
        prepareDataForApi[dayName] = false;
      });

      //if user selected weekly days,we should set that and send to server
      if (repeatTypeID == Number(repeatTypeDropDown.weekly)) {
        weekDays?.map(day => {
          prepareDataForApi[day] = true;
        });
      }
      //if we are in isMinimized mode we should save changes in actor for sending it to api when user clicking on create button
      if (isMinimized) {
        const lastCreateData = actorGetActionValue('taskFormData');
        actorSetActionValue('taskFormData', {
          ...lastCreateData,
          ...prepareDataForApi,
        });
        setIsOpenOptionsDialog(false);
        return;
      }

      editTodoTask({
        taskUID: String(todoSelectedTaskInfo?.uniqueid),
        newValues: prepareDataForApi,
        successCallback: () => {
          setIsOpenOptionsDialog(false);
        },
        failureCallback: (error: unknown) => {
          showNotificationForUnknownError(error, translate);
        },
      });
    };

    /**
     * @function handleRemoveRepeatOptions
     * @returns { void }
     */
    const handleRemoveRepeatOptions = (): void => {
      setRepeatType(null);
      setRepeatEveryCount(null);
      setRepeatWeekDays([]);
      saveRepeatOptions(null, null, []);
    };

    /**
     * @function handleCloseOptionsDialog
     * @returns { void }
     */
    const handleCloseOptionsDialog = useCallback((): void => {
      setIsOpenOptionsDialog(false);
    }, []);

    /**
     * @function handleChangeRepeatType
     * @param { ChangeEvent<HTMLInputElement> } event
     * @returns { void }
     */
    const handleChangeRepeatType = useCallback(
      (event: ChangeEvent<{ name?: string | undefined; value: unknown }>): void => {
        setRepeatType(event.target.value as number);
      },
      [],
    );

    /**
     * @function handleChangeWeekDays
     * @param { WeekdaysListInterface[] } newValue
     * @returns { void }
     */
    const handleChangeWeekDays = (newValue: string) => (): void => {
      if (repeatWeekDays.includes(newValue)) {
        setRepeatWeekDays(prev => prev.filter(item => item !== newValue));
      } else {
        setRepeatWeekDays(prev => [...prev, newValue]);
      }
    };

    /**
     * @function handleChangeRepeatEveryCount
     * @param { ChangeEvent<HTMLInputElement> } event
     * @returns { void }
     */
    const handleChangeRepeatEveryCount = useCallback(
      (event: ChangeEvent<HTMLInputElement>): void => {
        setRepeatEveryCount(Number(event.target.value));
      },
      [repeatEveryCount],
    );

    const translateForRepeatType = useMemo((): string => {
      return getTranslateForRepeatType(repeatType, translate);
    }, [repeatType]);

    return (
      <TodoRepeatTaskView
        contextMenuDetails={contextMenuDetails}
        handleOpenContextMenu={handleOpenContextMenu}
        isOpenOptionsDialog={isOpenOptionsDialog}
        handleClickMenuItem={handleClickMenuItem}
        handleRemoveRepeatOptions={handleRemoveRepeatOptions}
        repeatType={repeatType}
        repeatTypeLabel={translateForRepeatType}
        repeatEveryCount={repeatEveryCount}
        isMinimized={isMinimized}
        repeatWeekDays={repeatWeekDays}
        handleChangeRepeatType={handleChangeRepeatType}
        handleCloseOptionsDialog={handleCloseOptionsDialog}
        handleChangeRepeatEveryCount={handleChangeRepeatEveryCount}
        handleSaveRepeatOptions={handleSaveRepeatOptions}
        handleChangeWeekDays={handleChangeWeekDays}
      />
    );
  },
);
export default TodoRepeatTaskController;
