import { isEmpty, isEmptyObject, megaByteToByte } from '../../../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../type/actor-setup';

import {
  deleteMessageServiceId,
  fileUploadResource,
  getChatsFailureCallback,
  getContacts,
  getChatContents,
  handleMoveToRepliedMessage,
  handleSendFilesErrors,
  sendMessageReportId,
  seenMessageServiceId,
} from './chat-section-common';

import type {
  ChatStatelessActionsType,
  DeleteMessageParamsInterface,
  FetchMoreMessagesParamsInterface,
  FetchSpecificMessagesParamsInterface,
  MessageReplySaveResultType,
  MessageSaveResultInterface,
  MoveLastParamsInterface,
  MoveToRepliedMessageParamsInterface,
  SeenMessageParamsInterface,
  SelectUserParamsInterface,
  SendContentParamsInterface,
  SendFileParamsInterface,
} from '../chat-section.type';
import type { SuccessResponse } from '../../dynamic-input/multi-file-stream-input/multi-file-stream-input.type';
import type { MessageModeType } from '../new-message';
import { GlobalParametersInterface } from '../../../helper/Types';

/**
 * @function  successUploadFilesCallback
 * @param { SuccessResponse } successResponse
 * @returns { void }
 */
const successUploadFilesCallback = (successResponse: SuccessResponse): void => {
  if (isEmptyObject(successResponse.data)) return;
  const { filePath, realFileName } = successResponse.data;
  actorDispatch(
    'uploadedFile',
    { filePath, realFileName },
    { disableDebounce: true },
  );
};

const fetchMoreMessages: ChatStatelessActionsType<FetchMoreMessagesParamsInterface>['fetchMoreMessages'] =
  params => {
    const { countToGet, olderMessages } = params;

    const currentSelectedUser = actorGetActionValue('selectedChat')!.info;

    const currentMessages =
      actorGetActionValue('chatList')!.data[currentSelectedUser.personinfo_id]
        .messagesDetail!.data;

    const chatdate = olderMessages
      ? currentMessages[currentMessages.length - 1].chatdate
      : currentMessages[0].chatdate;

    getChatContents({
      userId: currentSelectedUser.personinfo_id,
      chatdate,
      IsUp: olderMessages ? 1 : 0,
      countToGet,
      AutoSeen: 0,
    });
  };

export const fetchSpecificMessages: ChatStatelessActionsType<FetchSpecificMessagesParamsInterface>['fetchSpecificMessages'] =
  params => {
    const { olderMessages, targetMessageId, successCallback } = params;
    const currentSelectedUser = actorGetActionValue('selectedChat')!.info;

    getChatContents({
      userId: currentSelectedUser.personinfo_id,
      ChatID: targetMessageId,
      IsUp: olderMessages ? 1 : 0,

      AutoSeen: 0,
      customSuccessCallback: successCallback,
    });
  };

const refreshContacts: ChatStatelessActionsType['refreshContacts'] = () => {
  getContacts({ shouldLoading: true, page: 1 });
};

const selectUser: ChatStatelessActionsType<SelectUserParamsInterface>['selectUser'] =
  params => {
    const { chat } = params;
    getChatContents({
      userId: chat.personinfo_id,
      chatdate: null,
      IsUp: 0,
      AutoSeen: 0,
    });

    const chatInputRef = actorGetActionValue('mainChatInput');
    chatInputRef?.focus();
  };

const moveLast: ChatStatelessActionsType<MoveLastParamsInterface>['moveLast'] =
  params => {
    const { successCallback } = params;
    const currentSelectedUser = actorGetActionValue('selectedChat')?.info;

    if (currentSelectedUser) {
      getChatContents({
        userId: currentSelectedUser.personinfo_id,
        chatdate: null,
        IsUp: 0,
        MoveLast: 1,
        ChatID: null,
        customSuccessCallback: successCallback,
      });
    }
  };

const moveToRepliedMessage: ChatStatelessActionsType<MoveToRepliedMessageParamsInterface>['moveToRepliedMessage'] =
  params => {
    const { chatId, successCallback } = params;
    handleMoveToRepliedMessage(chatId, successCallback);
  };

const deleteMessage: ChatStatelessActionsType<DeleteMessageParamsInterface>['deleteMessage'] =
  params => {
    const { _params, successCallback } = params;
    actorDispatch(
      'runChatService',
      {
        params: {
          actionUniqueId: deleteMessageServiceId,
          data: {
            params: _params,
          },
        },
        successCallback,
        failureCallback: getChatsFailureCallback,
      },
      {
        replaceAll: true,
      },
    );
  };

const sendMessageSuccessCallback = (
  senderId: number,
  response: MessageSaveResultInterface | MessageReplySaveResultType,
  sentMessage: {
    id: number;
    mode: MessageModeType;
  },
): void => {
  const chatData = actorGetActionValue('selectedChat')!;

  const messages = chatData.messagesDetail?.data ?? [];
  const targetMessageIndex = messages.findIndex(
    _message => _message.chat_id === sentMessage.id,
  );

  if (targetMessageIndex === -1) return;

  messages[targetMessageIndex] = {
    ...messages[targetMessageIndex],
    chat_id: response.chat_id,
    chattext: response.chattext,
    fileurl: response.fileurl,
    isedited: response.isedited,
    personimage: response.personimage,
    tscode: response.tscode,
    topersoninfo_id: response.topersoninfo_id,
    isfrommyself: response.isfrommyself,
    frompersoninfo_id: senderId,
  };

  if (sentMessage.mode === 'new' || sentMessage.mode === 'forward') {
    messages[targetMessageIndex].chatdate = response.chatdate;
  } else if (sentMessage.mode === 'reply') {
    const _response = response as MessageReplySaveResultType;
    messages[targetMessageIndex].replychattext = _response.replychattext;
    messages[targetMessageIndex].replyofchat_id = _response.replyofchat_id;
    messages[targetMessageIndex].chatdate = _response.chatdate;
  } else if (sentMessage.mode === 'edit') {
    messages[targetMessageIndex].personname = (
      response as MessageSaveResultInterface
    ).personname;
  }

  const messagesDetail = { data: messages, hasMore: true };

  actorSetActionValue('chatList', messagesDetail, {
    path: `data.${chatData.info.personinfo_id}.messagesDetail`,
    callerScopeName: 'sendMessageSuccessCallback',
  });

  actorDispatch(
    'selectedChat',
    {
      info: {
        ...chatData.info,
      },
      messagesDetail,
    },
    {
      disableDebounce: true,
      callerScopeName: 'sendMessageSuccessCallback',
    },
  );
};

const sendContent: ChatStatelessActionsType<SendContentParamsInterface>['sendContent'] =
  params => {
    const { senderId, message, failureCallback } = params;

    const currentSelectedUser = actorGetActionValue('selectedChat')!.info;

    const serviceParams: {
      chattext: string;
      fileurl: string | null;
      chatid: number | null;
      replyofchat_id: number | null;
      topersoninfo_id: number;
    } = {
      chattext: message.data.chattext,
      fileurl: message.data.fileurl,
      chatid: null,
      replyofchat_id: null,
      topersoninfo_id: currentSelectedUser.personinfo_id,
    };

    if (message.mode === 'edit') {
      serviceParams.chatid = message.data.chat_id;
    }

    if (message.mode === 'reply') {
      serviceParams.replyofchat_id = message.data.replyofchat_id;
    }

    actorDispatch(
      'runChatService',
      {
        params: {
          actionUniqueId: sendMessageReportId,
          data: {
            params: serviceParams,
          },
        },
        failureCallback,
        successCallback: (response: MessageSaveResultInterface) =>
          sendMessageSuccessCallback(senderId, response, {
            id: message.data.chat_id,
            mode: message.mode,
          }),
      },
      {
        disableDebounce: true,
        replaceAll: true,
      },
    );
  };

/**
 * to send new message
 * @function sendTextMessage
 * @param {string} message
 * @returns {void} void
 */
const sendTextMessage = (senderId: number, message: string): void => {
  // sendContent({
  //   senderId,
  //   content: {
  //     chattext: message,
  //   },
  // });
};

/**
 * @function sendFiles
 * @param { SendFileParamsInterface } params
 * @returns {  Promise<void> } a promise of void
 */
const sendFiles: ChatStatelessActionsType<SendFileParamsInterface>['sendFile'] =
  async params => {
    const { files } = params;
    if (!files?.length) return;

    const validFiles: Array<File> = [];
    const largerThanAllowedFiles: Array<File> = [];
    const globalParameters = actorGetActionValue(
      'profile',
      'profileData.globalparameters',
    )! as GlobalParametersInterface;

    const fileUploadLimitMB = globalParameters?.fileUploadLimitMB ?? null;

    for (const file of files) {
      if (!file) continue;

      // limit files size
      if (
        !isEmpty(fileUploadLimitMB) &&
        megaByteToByte(+fileUploadLimitMB) < file.size
      ) {
        largerThanAllowedFiles.push(file);
        continue;
      }

      validFiles.push(file);
    }

    if (largerThanAllowedFiles.length > 0) {
      handleSendFilesErrors(largerThanAllowedFiles)([]);
      return;
    }

    if (validFiles.length > 0) {
      const { dispatch } = actorGetActionValue('chatReducer')!;
      actorDispatch('uploadStreamMultipleFile', {
        param: {
          resource: fileUploadResource,
          files: validFiles,
        },
        failureCallback: handleSendFilesErrors(largerThanAllowedFiles),
        successCallback: successUploadFilesCallback,
        successAllFilesUploadedCallback: (
          uploadedFiles: (
            | SuccessResponse['data']
            | { data: SuccessResponse['data'] }
          )[],
        ) => {
          const preparedFiled = uploadedFiles.map(
            file => (file as { data: SuccessResponse['data'] })?.data ?? file,
          );

          dispatch({
            type: 'openUploadFileDialog',
            payload: {
              show: true,
              uploadedFiles: preparedFiled,
            },
          });
        },
      });
    }
  };

/**
 * To convert `isseen` prop of some messages
 * @function updateSeenMessages
 * @param {SeenMessageParamsInterface} params- Params to update `isseen` prop of some messages
 * @returns {void} void
 */
const updateSeenMessages = (params: SeenMessageParamsInterface): void => {
  actorDispatch(
    'runChatService',
    {
      params: {
        actionUniqueId: seenMessageServiceId,
        data: {
          params,
        },
      },
      successCallback: () => {
        actorDispatch('updateTotalUnSeen', true);
      },
    },
    {
      disableDebounce: true,
      replaceAll: true,
    },
  );
};

export const chatStatelessActions = {
  fetchMoreMessages,
  refreshContacts,
  selectUser,
  moveLast,
  moveToRepliedMessage,
  deleteMessage,
  sendContent,
  sendMessage: sendTextMessage,
  sendFiles,
  updateSeenMessages,
  fetchSpecificMessages,
};
