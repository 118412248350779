import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import lodashGet from 'lodash/get';
import { useLocale, useTranslate } from 'react-admin';
import grey from '@material-ui/core/colors/grey';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { isStringWthStarsMatch } from '../../helper/TextHelper';
import { isEmptyObject,isEmpty } from '../../helper/data-helper';

const useStyles = makeStyles(theme => ({

  menulist: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    overflowY: 'auto',
    height: '100%',
    transition: 'all 300ms ease-in-out',
  },

  menulistLiContainer: {
    '&:hover': {
      backgroundColor: grey[200],
    },
    cursor: 'pointer',
    '& a': {
      padding: '12px 15px',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },

  menulistLiParentContainer: {
    padding: '10px 15px',
    backgroundColor: '#F5F5F5',
    fontSize: 11,
    color: '#616161',
  },

  menucart: {
    width: '100%',
    position: 'relative',
    flexGrow: 1,
    padding: '8px 16px 0',
  },

  noData: {
    textAlign: 'center',
    marginTop: 8,
    flexGrow: 1,
  }
}));

const MenuSearchList = props => {
  // prettier-ignore
  const { showMenuInList, childMenuSelectHandler, menuArray, searchMenuWord } = props;

  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles();

  /**
   * @example
   * searchResult = {
   *    'سفارش': [{...},  {...}, ...],
   *    'فاکتور': [{...},  {...}, ...],
   *    ...
   * }
   */
  const [searchResult, setSearchResult] = useState({});
  const searchResultRef = useRef({});

  useEffect(() => {
    findSearchWordInMenuArray(menuArray);
    setSearchResult(searchResultRef.current);

    return () => {
      searchResultRef.current = {};
    }
  }, [searchMenuWord]);
  
  
  const getParentTitle = (childMenu) => {
      if(isEmptyObject(childMenu)) return '' ;
      
      const parentId = !isEmpty(childMenu.fullPath)? parseInt(childMenu.fullPath?.split('#')[0]) : (childMenu.parentid ?? childMenu.parentId);
      const parentRow = menuArray.find(menu=> menu.id == parentId) ;
      return parentRow?.translatedTitle?.[locale] ?? parentRow?.title ?? childMenu.title;
  }

  const findSearchWordInMenuArray = useCallback((_menuArray) => {
    for (const menuItem of _menuArray) {
      let data = [] ;
      if (menuItem.children.length === 0){
        data = isStringWthStarsMatch(
          lodashGet(menuItem, ['translatedTitle', locale], menuItem.title),
          searchMenuWord,
        ) ? [menuItem]:[];
      } else {
        data = menuItem.children.filter(menuChild => {
          if (menuChild.children.length > 0) {
            findSearchWordInMenuArray(menuChild.children);
          };
          return isStringWthStarsMatch(
            lodashGet(menuChild, ['translatedTitle', locale], menuChild.title),
            searchMenuWord,
          );
        }) ?? [];
      }

      if (data.length > 0) {
        const menuItemKey = getParentTitle(menuItem);

        if (Array.isArray(searchResultRef.current[menuItemKey])) {
          searchResultRef.current[menuItemKey].push(...data);
        } else {
          searchResultRef.current[menuItemKey] = [...data]
        }
      }
    }
  }, [searchMenuWord]);

  const searchResultKeys = Object.keys(searchResult);

  if (searchResultKeys.length === 0) {
    return (<div className={classes.noData}><span>{translate('general.noRecordFound')}</span></div>)
  }

  return (
    <div className={classes.menucart}>
      <ul className={classes.menulist} id="menu-search-result-list">
        {searchResultKeys.map((key) => (
          <Fragment>
            <li className={classes.menulistLiParentContainer}>{key}</li>
            {searchResult[key].map(menu => (
              <li key={menu.id} className={classes.menulistLiContainer}>
                {showMenuInList(menu, childMenuSelectHandler)}
              </li>
            ))}
          </Fragment>
        ))
        }
      </ul>
    </div>
  );
};

export default MenuSearchList;
