import { MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';

import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import { field } from './custom-field';
import { editTodoTask } from '../todo-section.helper';
import TodoFileView from './todo-file.view';
import { parseJSON } from '../../../core/configProvider';

import { clone, isEmptyObject } from '../../../helper/data-helper';
import { apiUrl } from '../../../core/data-Provider.helper';

import type { MultiFileStreamItem } from '../../dynamic-input/multi-file-stream-input/multi-file-stream-input.type';
import type { SuccessResponse, TodoFileControllerInterface } from './todo-file.type';

const TodoFileController = (props: TodoFileControllerInterface): ReactElement => {
  const { todoSelectedTaskInfo } = props;

  const currentResource = actorGetActionValue('resources')!.current;
  const inputRef = useRef<HTMLElement>();

  const [fileStreamValue, setFileStreamValue] = useState<MultiFileStreamItem[]>([]);

  useEffect(() => {
    const listenerId = actorOnDispatch('selectedTodoTask', taskInfo => {
      const taskAttachments = taskInfo?.taskattachments
        ? parseJSON<MultiFileStreamItem[]>(taskInfo?.taskattachments) ?? []
        : [];
      setFileStreamValue(taskAttachments);
    });

    return () => {
      actorRemoveAction({
        actionName: 'selectedTodoTask',
        listenerId: listenerId,
      });
    };
  }, []);

  /**
   * @function handleChangeFileStreamValue
   * @param {  MultiFileStreamItem[] } value
   * @returns { void } void
   */
  const handleChangeFileStreamValue = (value: MultiFileStreamItem[]): void => {
    setFileStreamValue(value);

    editTodoTask({
      taskUID: String(todoSelectedTaskInfo?.uniqueid),
      newValues: {
        taskattachments: JSON.stringify(value),
      },
    });
  };

  /**
   * add new value to form data
   * @function onFileSent
   * @param { SuccessResponse } successResponse
   * @return { void } void
   */
  const onFileSent = (successResponse: SuccessResponse): void => {
    if (isEmptyObject(successResponse.data)) return;
    const { filePath, realFileName } = successResponse.data;
    const { size } = successResponse;

    handleChangeFileStreamValue([
      ...fileStreamValue,
      { filePath, realFileName, size },
    ]);
  };

  /**
   * check the size of coming files, separate the valid and invalid files
   * put invalid files to errors array and make request with valid files
   * @function onChangeFileStreamValue
   * @param { Event } event selected file in input
   * @returns { void } void
   */
  const onChangeFileStreamValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validFiles: Array<File> = [];
    if (!event.target.files?.length) return;

    for (const file of event.target.files) {
      if (!file) continue;
      validFiles.push(file);
    }

    if (validFiles?.length) {
      actorDispatch('uploadStreamMultipleFile', {
        param: {
          resource: `${currentResource.value}/multifilestream/${field?.name}`,
          files: validFiles,
        },
        successCallback: onFileSent,
      });
    }
  };

  /**
   * remove an item from files array in form data
   * @function onDeleteFile
   * @param { number } index
   * @param { MouseEvent } event
   * @returns { void } void
   */
  const onDeleteFile = (index: number) => (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!fileStreamValue) return;
    const newValue: MultiFileStreamItem[] = clone(fileStreamValue);
    newValue.splice(index, 1);
    handleChangeFileStreamValue(newValue);
  };

  /**
   * @function handleClickOpenNewUrl
   * @param { string } url
   * @returns { void } void
   */
  const handleOpenFile = (url: string) => (): void => {
    window.open(`${apiUrl}/${url}`, '_blank');
  };

  /**
   * trigger `onClick` input file
   * @function handleClickInputFile
   * @param { React.MouseEvent } event
   * @returns { void } void
   */
  const handleClickInputFile = (event: React.MouseEvent): void => {
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <TodoFileView
      onDeleteFile={onDeleteFile}
      onChangeFileStreamValue={onChangeFileStreamValue}
      handleOpenFile={handleOpenFile}
      handleClickInputFile={handleClickInputFile}
      inputFileRef={inputRef as MutableRefObject<HTMLInputElement> | undefined}
      fileStreamValue={fileStreamValue}
      name={field?.name}
      required={false}
    />
  );
};

export default TodoFileController;
