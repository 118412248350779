import { useEffect, useState } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';

import { getTranslatedName } from '../../helper/MetaHelper';
import { isCleanStringMatch } from '../../helper/TextHelper';
import { CustomForm, FormActionProps, FormController, FormWithTabs } from '../form';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import LoadingBox from '../LoadingBox';
import { actorOnDispatch, actorRemoveAction } from '../../type/actor-setup';
import { useStyles } from './dialogs-stack.style';

import type { DialogViewProps } from './dialogs-stack.type';
import type { MetaData } from '../../helper/Types';
import type { ReactElement } from 'react';

export const RelationEditDialogView = (props: DialogViewProps): ReactElement => {
  const { dialogData, locale, dialogType, currentResource, closeDialogHandler } =
    props;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const onDispatchId = actorOnDispatch('loading', response => {
      if (response && `${currentResource!.value}-edit` in response) {
        setIsLoading(response[`${currentResource!.value}-edit`]);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'loading',
        listenerId: onDispatchId,
      });
    };
  }, []);

  if (isLoading) {
    return <LoadingBox />;
  }

  return (
    <>
      <DialogTitle id="relationEditDialogTitle">
        {isCleanStringMatch(getTranslatedName(dialogData.metaData, locale), 'ي')}
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
        data-style-dialog-relation="dialogRelation"
      >
        <FormController formName={dialogType as keyof FormActionProps} isQuickForm>
          <CustomForm
            isBottomToolbar
            Toolbar={
              <QuickCreateButtonToolbar
                closeDialog={closeDialogHandler}
                redirect={''} // FIXME: What is it?!!!
                parentInfo={dialogData.parentInfo as Record<string, unknown>}
                metaData={dialogData.metaData as MetaData}
                disableFullFormButton={true}
                resource={currentResource!.value}
                relationMode={true}
                mustRefresh={dialogData.mustRefresh as boolean | undefined}
                isEdit={true}
              />
            }
          >
            <FormWithTabs />
          </CustomForm>
        </FormController>
      </DialogContent>
    </>
  );
};
