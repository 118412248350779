import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { MailFormDialogActionsViewInterface } from './mail-form-dialog-actions.type';
import { useStyles } from './mail-form-dialog-actions.style';
import { Box, Button, IconButton, Tooltip } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import { TemplateAction } from './template-action';
import { SplitButton } from '../../split-button';

const MailFormDialogActionsView = (
  props: MailFormDialogActionsViewInterface,
): ReactElement => {
  const {
    handleCloseNewMessageForm,
    onFileChange,
    handleOpenFollowUp,
    handleSubmitForm,
    formName,
    disableButton,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();

  const splitButtonOptions = [
    `${translate('mail.send')}`,
    `${translate('mail.saveAsDraft')}`,
  ];

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center">
        <TemplateAction />
        <Box>
          <input
            id="icon-button-file"
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={onFileChange}
          />
          <label htmlFor="icon-button-file">
            <IconButton color="primary" aria-label="upload file" component="span">
              <AttachFileIcon />
            </IconButton>
          </label>
        </Box>
        {formName == 'newMessage' && (
          <Box>
            <Tooltip title={translate('mail.followUp')}>
              <IconButton
                onClick={handleOpenFollowUp}
                color="primary"
                aria-label="upload file"
                component="span"
              >
                <AccessAlarmIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        marginTop="8px"
      >
        <Button
          className={classes.btnRoot}
          onClick={handleCloseNewMessageForm}
          variant="text"
          color="primary"
        >
          {translate('general.decline')}
        </Button>

        {formName == 'newMessage' ? (
          <SplitButton
            className={classes.splitBtnRoot}
            buttonClassName={classes.btn}
            toggleButtonClassName={classes.toggleBtn}
            options={splitButtonOptions}
            onClickItem={handleSubmitForm}
            data-test="new-message-split-button"
            disableButton={disableButton}
          />
        ) : (
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={() => handleSubmitForm()}
            style={{ background: '#1D85D6', color: '#fff' }}
            data-test="reference-form-apply-button"
          >
            {translate('mail.send')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MailFormDialogActionsView;
