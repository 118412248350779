import { useCallback, useState } from 'react';
import type { ReactElement, MouseEvent } from 'react';

import ButtonMenuView from './button-menu.view';
import type { ButtonMenuInterface } from './button-menu.type';
import { actorGetActionValue } from '../../type/actor-setup';

const ButtonMenuController = (props: ButtonMenuInterface): ReactElement => {
  const {
    options,
    defaultSelectedOptionIndex,
    tooltipTitle,
    defaultText,
    initialDisplayComponent,
    showAnchorDown,
    optionSelectionHandler,
  } = props;

  const { translate, locale } = actorGetActionValue('reactAdminHelpers')!;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    defaultSelectedOptionIndex ?? null,
  );

  /**
   * @function menuButtonClickHandler
   * @param { React.MouseEvent<HTMLButtonElement> } event
   * @returns { void } void
   */
  const menuButtonClickHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  /**
   * @function menuCloseHandler
   * @returns { void } void
   */
  const menuCloseHandler = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  /**
   * @function menuClickHandler
   * @param { number } index
   * @returns { () => void } a function
   */
  const menuClickHandler = useCallback(
    (index: number) => (): void => {
      setSelectedOptionIndex(index);
      optionSelectionHandler?.(options[index]);
      menuCloseHandler();
    },
    [],
  );

  /**
   * @function onContextMenu
   * @param {React.MouseEvent<HTMLDivElement>} event
   * @returns {void} void
   */
  const onContextMenu = useCallback((event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
  }, []);

  let preSelectedItem: JSX.Element;
  if (selectedOptionIndex == null || options[selectedOptionIndex].value == null) {
    preSelectedItem = initialDisplayComponent ?? (
      <span>
        {defaultText ?? translate('general.defaultTextInSelectableOptions')}
      </span>
    );
  } else {
    preSelectedItem = options[selectedOptionIndex].component;
  }

  return (
    <ButtonMenuView
      tooltipTitle={tooltipTitle}
      anchorEl={anchorEl}
      selectedOptionIndex={selectedOptionIndex}
      options={options}
      defaultText={defaultText}
      selectedItem={preSelectedItem}
      locale={locale}
      showAnchorDown={showAnchorDown}
      menuButtonClickHandler={menuButtonClickHandler}
      menuClickHandler={menuClickHandler}
      menuCloseHandler={menuCloseHandler}
      onContextMenu={onContextMenu}
    />
  );
};

export default ButtonMenuController;
