import { FC, memo } from 'react';

import { NavGroupInterface } from './nav-group.type';
import NavGroupView from './nav-group.view';

const NavGroupController: FC<NavGroupInterface> = memo(props => {
  const { item, level, baseUrl, canDragDrop, parentComponentName } = props;

  return (
    <NavGroupView
      item={item}
      level={level}
      baseUrl={baseUrl}
      canDragDrop={canDragDrop}
      parentComponentName={parentComponentName}
    />
  );
});

export default NavGroupController;
