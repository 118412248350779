import { FC } from 'react';
import BackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

const AppBarBackButton: FC = () => {
  const handleClick = () => {
    return window.history.back();
  };

  return (
    <IconButton
      onClick={handleClick}
      color="inherit"
      data-test="container"
      id="backIcon"
    >
      <BackIcon />
    </IconButton>
  );
};

export default AppBarBackButton;
