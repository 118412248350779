import Box from '@material-ui/core/Box';
import { useTranslate } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';

import useStyles from './chat-pins.style';

import type { FC } from 'react';
import type { ChatPinsViewPropsInterface } from './chat-pins.type';

const ChatPinsView: FC<ChatPinsViewPropsInterface> = props => {
  const {
    pinMessages,
    pinMessageIndex,
    pinMessageText,
    handleOnPinSectionClick,
    onClear,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  if (pinMessages.length === 0) return null;

  return (
    <Box
      className={classes.pinMessageContainer}
      onClick={handleOnPinSectionClick(pinMessages[pinMessageIndex])}
    >
      <div className={classes.pinMessageTextContainer}>
        <Box className={classes.pinMessageTitle}>
          {translate('chat.pinnedMessage')}
        </Box>
        <Box
          className={classes.pinMessageText}
          dangerouslySetInnerHTML={{
            __html: pinMessageText,
          }}
        />
      </div>
      <IconButton
        size="small"
        role={'clearable'}
        tabIndex={-1}
        onClick={onClear}
        disableRipple
      >
        <Close fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default ChatPinsView;
