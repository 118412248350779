import { CONFIG_LIST_COLUMN_WIDTH } from '../../core/configProvider';
import { SESSION_ID, getValue } from '../../core/configProvider';
import { apiUrl, apiVersion } from '../../core/data-Provider.helper';
import { GeneralMetaData } from '../../helper/Types';
import { isValidUrl } from '../../helper/UrlHelper';
import { customSort, isEmpty } from '../../helper/data-helper';
import lodashGroupBy from 'lodash/groupBy';
import lodashFindIndex from 'lodash/findIndex';
import {
  MainCharts,
  DynamicInputWordCloudCustomizedProps,
  DynamicInputStatChartCustomizedProps,
  DynamicInputGaugeChartCustomizedProps,
  DynamicInputListChartCustomizedProps,
  PrepareDynamicChartPropsInterface,
  DynamicInputTimeSeriesChartCustomizedProps,
} from './dynamic-chart.type';
import type {
  DynamicChartControllerProps,
  DynamicInputPieChartCustomizedProps,
  DynamicInputLineChartCustomizedProps,
  DynamicInputBarChartCustomizedProps,
  DynamicInputAreaChartCustomizedProps,
  ValueFieldName,
  DynamicInputCalendarCustomizedProps,
  DynamicInputIframeChartCustomizedProps,
} from './dynamic-chart.type';
import { PrepareHighChartDataInterface } from './line-chart';

/**
 * create random hex color
 * @function createRandomHexColor
 * @returns {string}
 */
export const createRandomHexColor = (): string => {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

/**
 * each case of dynamic gadget needs specific props and options
 * this function will make necessary props for them base of chart type and report data
 * @function prepareDynamicChartPropsForGadgets
 * @param {GadgetInformation} gadgetValues
 * @param {Array<Record<string, unknown>>} reportData
 * @returns {DynamicChartControllerProps}
 */
export const prepareDynamicChartPropsForGadgets = (
  entires: PrepareDynamicChartPropsInterface,
): DynamicChartControllerProps => {
  const {
    gadgetValues,
    reportData,
    filterValues = [],
    dashboardCardData,
    editPropertyOfDashboardCard,
    handleChartItemClick,
    sortField,
    sortOrder,
    currentGadgetMetaData,
  } = entires;

  const {
    chartType,
    idFieldName,
    valueFieldName,
    labelFieldName,
    colorFieldName,
    axisBottomLabelFieldName,
    axisLeftLabelFieldName,
    titleFieldName,
    thresholdColorRange,
    iconFieldName,
    reportAddress,
    wordFieldName,
    axisTopLabelFieldName,
    axisFieldName,
    legendFieldName,
    url,
    attachSessionToUrl,
    displayNumber,
  } = gadgetValues;

  const sortedData =
    sortField && sortOrder
      ? customSort(reportData, sortField, sortOrder)
      : reportData;

  const sharedData = {
    chartType,
    reportData: sortedData,
    handleChartItemClick,
    resource: reportAddress,
  };

  switch (chartType) {
    case MainCharts.Pie: {
      return {
        ...sharedData,
        chartOptions: {
          idFieldName: idFieldName ?? null,
          valueFieldName: valueFieldName ?? '',
          labelFieldName: labelFieldName ?? null,
          colorFieldName,
          displayNumber,
        },
      } as DynamicInputPieChartCustomizedProps;
    }
    case MainCharts.Bar:
      return {
        ...sharedData,
        chartOptions: {
          // idFieldName: idFieldName ?? '',
          axisFieldName: axisFieldName ?? [],
          valueFieldName: valueFieldName ?? [],
          axisBottomLabelFieldName: axisBottomLabelFieldName ?? '',
          axisLeftLabelFieldName: axisLeftLabelFieldName ?? '',
          displayNumber,
        },
      } as DynamicInputBarChartCustomizedProps;

    case MainCharts.Line: {
      return {
        ...sharedData,
        chartOptions: {
          idFieldName: idFieldName ?? '',
          valueFieldName: valueFieldName ?? '',
          axisBottomLabelFieldName: axisBottomLabelFieldName ?? '',
          axisLeftLabelFieldName: axisLeftLabelFieldName ?? '',
          colorFieldName: colorFieldName ?? null,
          axisFieldName: axisFieldName ?? '',
          legendFieldName,
        },
      } as DynamicInputLineChartCustomizedProps;
    }

    case MainCharts.TimeSeriesChart: {
      return {
        ...sharedData,
        chartType,
        reportData: sortedData ?? reportData,
        chartOptions: {
          idFieldName: idFieldName ?? '',
          valueFieldName: valueFieldName ?? '',
          axisBottomLabelFieldName: axisBottomLabelFieldName ?? '',
          axisLeftLabelFieldName: axisLeftLabelFieldName ?? '',
          axisFieldName: axisFieldName ?? '',
          legendFieldName,
        },
      } as DynamicInputTimeSeriesChartCustomizedProps;
    }

    case MainCharts.Area: {
      return {
        ...sharedData,
        chartOptions: {
          idFieldName: idFieldName ?? '',
          valueFieldName: valueFieldName ?? '',
          axisBottomLabelFieldName: axisBottomLabelFieldName ?? '',
          axisTopLabelFieldName: axisTopLabelFieldName ?? '',
          colorFieldName: colorFieldName ?? null,
          axisFieldName: axisFieldName ?? '',
          legendFieldName,
        },
      } as DynamicInputAreaChartCustomizedProps;
    }
    case MainCharts.Stat: {
      return {
        ...sharedData,
        chartOptions: {
          valueFieldName: valueFieldName ?? '',
          titleFieldName: titleFieldName ?? '',
          iconFieldName: iconFieldName ?? '',
        },
      } as DynamicInputStatChartCustomizedProps;
    }
    case MainCharts.Gauge: {
      return {
        ...sharedData,
        chartOptions: {
          valueFieldName: valueFieldName ?? '',
          titleFieldName: titleFieldName ?? '',
          thresholdColorRange: thresholdColorRange ?? '',
          labelFieldName: labelFieldName ?? '',
        },
      } as DynamicInputGaugeChartCustomizedProps;
    }
    case MainCharts.List: {
      return {
        ...sharedData,
        editPropertyOfDashboardCard: editPropertyOfDashboardCard,
        chartOptions: {
          titleFieldName: titleFieldName ?? '',
          filterValues: filterValues,
          reportAddress: reportAddress,
          perPage: dashboardCardData?.listChartPerPage,
          sort: {
            field: dashboardCardData?.listChartSort ?? sortField,
            order: dashboardCardData?.sortOrder ?? sortOrder,
          },
        },
      } as DynamicInputListChartCustomizedProps;
    }
    case MainCharts.WordCloud: {
      return {
        ...sharedData,
        chartOptions: {
          valueFieldName: valueFieldName ?? '',
          wordFieldName: wordFieldName ?? '',
          titleFieldName: titleFieldName ?? '',
          idFieldName: idFieldName ?? '',
        },
      } as unknown as DynamicInputWordCloudCustomizedProps;
    }

    case MainCharts.Calendar: {
      return {
        resource: reportAddress,
        chartType,
      } as unknown as DynamicInputCalendarCustomizedProps;
    }

    case MainCharts.Iframe: {
      return {
        chartType,
        chartOptions: {
          url: url ?? '',
          attachSessionToUrl: attachSessionToUrl ?? false,
        },
      } as unknown as DynamicInputIframeChartCustomizedProps;
    }

    case MainCharts.Announcements: {
      return {
        chartType,
        reportData,
        chartOptions: {
          reportAddress: gadgetValues.reportAddress,
          paginationParams: {
            total: gadgetValues.perPage ?? 10,
            currentPage: 0,
          },
        },
      };
    }

    default:
      throw new Error('prepareDynamicChartPropsForGadgets failed');
  }
};

/**
 * add comma separator to a number
 * @function addThousandSeparator
 * @param {number | string} number
 * @returns {string}
 */
export const addThousandSeparator = (number: number | string): string => {
  try {
    if (!Number.isNaN(+number)) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return String(number);
  } catch {
    return String(number);
  }
};

/**
 * add comma separator to a number
 * @function getNumericFormat
 * @param {metaData } GeneralMetaData
 * @param {valueFieldName } ValueFieldName
 * @returns {string}
 */
export const getNumericFormat = (
  metaData: GeneralMetaData,
  valueFieldName: ValueFieldName,
): string => {
  const isFormat = metaData?.columns?.find(
    column => column.relatedName == valueFieldName[0],
  )?.format as string;

  return isFormat;
};

/**
 * @function getConfigColumnWidthKey
 * @param { string } resource
 * @returns {string}
 */
export const getConfigColumnWidthKey = (resource: string): string => {
  let key = `${CONFIG_LIST_COLUMN_WIDTH}_`;
  key += resource;

  return key;
};
/**
 * @function getPreparedUrlForIframeGadget
 * @param {string | null} url
 * @param {boolean} attachSessionToUrl
 * @returns {string} string
 */
export const getPreparedUrlForIframeGadget = (
  url: string | null,
  attachSessionToUrl = false,
): string => {
  let preparedUrl = url ?? '';
  if (attachSessionToUrl) {
    const sessionId = getValue(SESSION_ID);
    preparedUrl = `${apiUrl}/oauth2/${sessionId}/${apiVersion}/${url}`;
  } else {
    if (!isEmpty(preparedUrl) && !isValidUrl(preparedUrl) && !url?.includes('#')) {
      preparedUrl = `#/${preparedUrl}`;
    }
  }

  return preparedUrl;
};

/**
 * @function {prepareAxisChartDataInterface} prepareAxisChartData
 * @returns
 */
export const prepareHighChartData: PrepareHighChartDataInterface = entries => {
  const {
    data,
    axisFieldName,
    valueFieldName,
    colorFieldName,
    legendFieldName,
    idFieldName,
  } = entries;

  const legends = Object.keys(
    lodashGroupBy(data, legendFieldName ?? Object.keys(data)),
  );

  const prepareData: { x: string; y: number; id: number | string }[] = [];

  if (!axisFieldName) return [];
  const sortedData = customSort(data, axisFieldName.toString());

  if (!legendFieldName) {
    sortedData.forEach((reportRowInfo): void => {
      const reportInfoAxis = String(reportRowInfo[axisFieldName.toString()]);
      const reportInfoId = idFieldName ? (reportRowInfo[idFieldName] as number) : '';
      const reportInfoSeries = reportRowInfo[valueFieldName?.[0]] as number;

      let axisValue = {
        x: reportInfoAxis,
        y: reportInfoSeries,
        id: reportInfoId,
      };

      const findExistIndex = lodashFindIndex(prepareData, {
        x: reportInfoAxis,
      });

      if (findExistIndex > -1) {
        axisValue = {
          x: reportInfoAxis,
          y: prepareData[findExistIndex].y + reportInfoSeries,
          id: reportInfoId,
        };

        prepareData[findExistIndex] = axisValue;
      } else {
        prepareData.push(axisValue);
      }
    });

    return [
      {
        id: '',
        data: prepareData,
      },
    ];
  }

  sortedData.forEach((reportRowInfo): void => {
    const reportInfoAxis = String(reportRowInfo[axisFieldName.toString()]);
    const reportInfoId = idFieldName ? (reportRowInfo[idFieldName] as number) : '';
    const reportInfoSeries = reportRowInfo[valueFieldName?.[0]] as number;

    legends.forEach(legend => {
      const legendData = prepareData[legend] ?? [];

      if (reportRowInfo[legendFieldName] === legend) {
        let axisValue = {
          x: reportInfoAxis,
          y: reportInfoSeries,
          id: reportInfoId,
        };

        const findExistIndex = lodashFindIndex(legendData, {
          x: reportInfoAxis,
        });

        if (findExistIndex > -1) {
          axisValue = {
            x: reportInfoAxis,
            y: legendData[findExistIndex].y + reportInfoSeries,
            id: reportInfoId,
          };

          legendData[findExistIndex] = axisValue;
        } else {
          legendData.push(axisValue);
        }
      }

      prepareData[legend] = legendData;
    });
  });

  const createData = Object.keys(prepareData).map(item => {
    return {
      id: legendFieldName ? item.toString() : '',
      data: prepareData[item],
      color: colorFieldName ?? '',
    };
  });

  return createData;
};
