import querystring from 'qs';
import { FC, memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push as redirectToPage } from 'connected-react-router';

import QuickEditButtonToolbarView from './quick-edit-button-toolbar.view';

import { FormActions, type ExtraParamsInterface } from '../../form/form.type';
import type { QuickEditButtonToolbarInterface } from './quick-edit-button-toolbar.type';

const QuickEditButtonToolbarController: FC<QuickEditButtonToolbarInterface> = memo(
  props => {
    const { closeDialog, resource, id, redirect, formActionsHandler, loading } =
      props;
    const reduxDispatch = useDispatch();

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      setDisabled(!!loading);
    }, [loading]);

    /**redirect to full form with data
     * @function redirectWithData
     * @return {void}
     */
    const redirectWithData: React.MouseEventHandler<
      HTMLButtonElement
    > = (): void => {
      closeDialog();
      reduxDispatch(
        redirectToPage(
          // TODO: fix querystring
          `/${resource}/${id}?${querystring.stringify({})}` +
            (redirect ? `&redirect=${redirect}` : ''),
        ),
      );
    };

    /** submit form
     * @function handleSubmit
     * @return {void}
     */
    const handleSubmit = (): void => {
      /**
       * After opening a new dialog, this component will reset, so we don't need to `setDisabled(false)`
       */
      setDisabled(true);

      formActionsHandler?.(FormActions.Save, {
        isSaveAndNew: false,
        isSaveAndView: false,
        closeDialog,
        onFailure: () => {
          setDisabled(false);
        },
      } as ExtraParamsInterface);
    };

    return (
      <QuickEditButtonToolbarView
        redirectWithData={redirectWithData}
        closeDialog={closeDialog}
        handleSubmit={handleSubmit}
        loading={disabled}
      />
    );
  },
);

export default QuickEditButtonToolbarController;
