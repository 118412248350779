import { ReactElement, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { useStyles } from './computed-input.style';
import { ComputedInputViewProps } from './computed-input.type';
import InputBase from '../../input-base';

const ComputedInputView = (props: ComputedInputViewProps): ReactElement => {
  const { visibleClass, className, value, label, hint, inputMessage, name, getRef } =
    props;
  const classes = useStyles(props);

  const inputPropsParams = useMemo(
    (): object => ({
      classes: {
        root: classes.outlineRoot,
        notchedOutline: classes.notchedOutline,
        error: classes.error,
        input: classes.input,
        disabled: classes.disabled,
      },
    }),
    [],
  );

  return (
    <InputBase
      className={`${visibleClass}`}
      inputMessage={inputMessage}
      required={false}
      label={label}
      hint={hint}
    >
      <TextField
        name={name}
        className={className}
        classes={{
          root: classes.root,
        }}
        variant="outlined"
        type={'text'}
        data-test-field-name={name}
        ref={getRef}
        value={value}
        InputProps={inputPropsParams}
        disabled={true}
        autoComplete="off"
      />
    </InputBase>
  );
};

export default ComputedInputView;
