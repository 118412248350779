import { FC } from 'react';
import classnames from 'classnames';

import {
  RelationPanel,
  RelationPanelControllerInterface,
} from '../../relation-panel';

import { RelationListViewInterface } from './relation-list.type';
import { useStyles } from './relation-list.style';
import { getRelationResource } from './relation-list-helper';

const RelationListView: FC<RelationListViewInterface> = props => {
  const { relations, hiddenSubPanels } = props;

  const classes = useStyles();
  return (
    <div className={classnames(classes.viewContainer)}>
      {relations &&
        relations.map(
          (relationProps: RelationPanelControllerInterface, relationIndex) => {
            // hide relations
            if (hiddenSubPanels.includes(getRelationResource(relationProps))) {
              return null;
            }

            return (
              <div className={classes.relationItem} key={relationIndex}>
                <RelationPanel
                  {...relationProps}
                  relationIndex={relationIndex}
                  key={`${relationIndex}_${relationProps.relationItemInMetaData.moduleName}__${relationProps.relationItemInMetaData.moduleTableName}_${relationProps.relationItemInMetaData.id}`}
                />
              </div>
            );
          },
        )}
    </div>
  );
};

export default RelationListView;
