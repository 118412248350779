import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';
import { createLogger } from '@web/logger';

import { ProcessInformation } from '../component/form/form-with-tabs';
import { getFieldByName, getFilterColumns, getPrimaryField } from './MetaHelper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../type/actor-setup';
import { clone, isEmpty, isEmptyObject } from './data-helper';
import {
  checkUiEnable,
  checkUiVisible,
} from '../component/form/form-with-tabs/form-with-tabs.helper';
import { computeDisableField } from '../component/form/service-dialog-form/service-dialog-form.helper';
import { getMetaFromApi } from '../container/NewMetaContext';

import type { ProcessTaskInterface } from '../component/ShiftProcessButton';
import type { Locale } from '../type/global-types';
import type {
  LayoutField,
  FieldType,
  MetaData,
  GeneralMetaData,
  MetaDataBase,
  MetaDataQuickTab,
  MetaDataTab,
  MetaDataProcessTask,
} from './Types';
import type {
  GetTabListInterface,
  RawShowTabs,
  FieldLayoutInterface,
  FullProcessList,
  GetInputsInitialAppearanceCharacteristics,
  GetHiddenGridColumns,
  InputAppearanceCharacteristics,
  GetServiceInputsInitialAppearanceCharacteristics,
  GetRequiredFieldsFromDeactivateSubPanel,
} from './meta-helper.type';
import { TabGroup } from '../component/form';

const logger = createLogger('samian-web-erp');

// ------------------- form related functions -------------------

/**
 * this function receives a name of field and found the related field with getFieldByName from MetaHelper.
 * then try to find similar field from fieldList array from props.
 * if field exist on field list return that else return original field from getFieldByName.
 * @function findFieldByName
 * @param {MetaData} metaData
 * @param {Array<FieldType>} fieldsWithHigherPriority
 * @param {string} fieldName
 * @returns {FieldType | null} a field if found it or null
 */
export const findFieldByName = (
  metaData: MetaDataBase,
  fieldsWithHigherPriority: Array<FieldType>,
  fieldName: string,
): FieldType | null => {
  // find field by name from meta
  const dirtyFieldFromMeta = getFieldByName(metaData, fieldName);

  if (!isEmptyObject(dirtyFieldFromMeta)) {
    // find similar field in fieldsWithHigherPriority
    const similarFieldInFieldsArray = fieldsWithHigherPriority?.filter(
      field => field['name'] === dirtyFieldFromMeta['name'],
    );

    if (similarFieldInFieldsArray && similarFieldInFieldsArray.length > 0) {
      return similarFieldInFieldsArray[0]; // field with tabId and tabTitle
    } else {
      return dirtyFieldFromMeta; // field without tabId and tabTitle
    }
  } else {
    return null;
  }
};

/**
 * get record and extract process information keys from it
 * @param {Record<string, unknown>} record
 * @returns {ProcessInformation} ProcessInformation
 */
export const getProcessInformationFromRecord = (
  record: Record<string, unknown> | undefined,
): ProcessInformation => {
  if (!record) {
    return {
      processuniqueid: null,
      positionid: null,
      stateid: null,
    };
  }

  return {
    processuniqueid: (record.__processuniqueid as string) ?? null,
    positionid: (record.positionid as string) ?? null,
    stateid: (record.stateid as string) ?? null,
  };
};

/**
 * it will return table id of root table
 * @returns { number|null } boolean or null
 */
export const getRootTableId = (): number | null => {
  const resources = actorGetActionValue('resources');
  if (!resources) return null;

  const rootMetaData = actorGetActionValue(
    'metaData',
    resources.stack[0].value,
  ) as unknown as MetaDataBase | null;
  if (!rootMetaData?.config) {
    return null;
  }

  return rootMetaData.config.moduleTableId;
};

/**
 * extract valid services from meta data
 * @function getVisibleServices
 * @param {MetaData} metaData
 * @param {Record<string, unknown>} Record
 * @returns {Array<Record<string, unknown>> | null }
 */
export const getVisibleServices = (
  metaData: MetaData,
  record: Record<string, unknown>,
): Array<Record<string, unknown>> | null => {
  let visibleServices: Array<Record<string, unknown>> | null = null;

  if (Array.isArray(metaData?.['actions'])) {
    if (record?.iseditable) {
      visibleServices = metaData['actions'];
    } else {
      const activeServices = metaData['actions'].filter(
        action => action?.runOnNonEditableRecords,
      );

      if (activeServices.length) {
        visibleServices = activeServices;
      }
    }
  }

  return visibleServices;
};

/**
 * // TODO: complete jsDoc
 * @param metaData
 * @param locale
 * @returns
 */
export const getTranslatedName = (
  metaData: GeneralMetaData,
  locale: Locale,
): string | null => {
  if (isEmptyObject(metaData) || !locale) {
    return null;
  }

  const translatedTitleLocale =
    metaData?.translatedTitle?.[locale] ?? metaData.title;

  if (translatedTitleLocale) {
    return translatedTitleLocale;
  }

  const translatedCaptionLocale =
    metaData.config?.translatedCaption?.[locale] ?? metaData.config?.caption;

  if (translatedCaptionLocale) {
    return translatedCaptionLocale;
  }

  return metaData.config?.title ?? null;
};

// ------------------- grid related functions -------------------

/**
 * creates an empty layout (matrix) according to `rowCount`, and `columnCount`.
 * @function createEmptyLayout
 * @param {Number} rowCount
 * @param {Number} columnCount
 * @returns {Array<Array<String>>}
 */
export const createEmptyLayout = (
  rowCount: number,
  columnCount: number,
): 'empty'[][] => {
  return [...Array(rowCount + 1)].map(() => Array(columnCount).fill('empty'));
};

/**
 * `getMaxMinOfRow` finds the last row-index and checks row-span and returns max of rows.
 * @function getMaxMinOfRow
 * @param {Array<FieldType | FieldLayoutInterface>} fieldsLayout
 * @returns {Number}
 */
export const getMaxMinOfRow = (
  fieldsLayout: Array<FieldType | FieldLayoutInterface>,
): number => {
  return fieldsLayout.reduce((accumulator, { rowIndex, rowSpan }) => {
    if (rowIndex >= accumulator) {
      accumulator = rowIndex;

      if (rowSpan) {
        accumulator = accumulator + rowSpan - 1;
      }
    }

    return accumulator;
  }, 0);
};

/**
 * `createLayout` with using `rowIndex`, `columnIndex`, `rowSpan` and `colSpan` make a layout for the form .
 * @function createLayout
 * @param {Array<FieldLayoutInterface>} fieldsLayout
 * @param {Number} columnCount
 * @param {Object} fieldSet
 * @returns {Array}
 */
export const createLayout = (
  fieldsLayout: Array<FieldLayoutInterface>,
  columnCount: number,
  fieldSet: Record<number, FieldType>,
): LayoutField[][] => {
  if (
    !(Array.isArray(fieldsLayout) && fieldsLayout.length > 0) ||
    isEmptyObject(fieldSet)
  ) {
    return [];
  }

  const maxRow = getMaxMinOfRow(fieldsLayout);
  const preparedGroup = clone(
    createEmptyLayout(maxRow, columnCount),
  ) as LayoutField[][];

  fieldsLayout.forEach(item => {
    const { fieldId, rowIndex, columnIndex, rowSpan = 0, colSpan = 0 } = item;

    // add `colSpan` and `rowSpan` from layout field to field
    fieldSet[fieldId] = {
      ...fieldSet[fieldId],
      colSpan: colSpan as number,
      rowSpan: rowSpan as number,
    };

    if (rowSpan) {
      for (let i = 0; i < rowSpan; i++) {
        if (preparedGroup![rowIndex + i] == null) continue;

        if (colSpan) {
          for (let j = columnIndex; j < columnIndex + colSpan; j++) {
            preparedGroup![rowIndex + i][j] = null;
          }
        } else {
          preparedGroup![rowIndex + i][columnIndex] = null;
        }
      }
    } else if (colSpan) {
      for (let j = columnIndex; j < columnIndex + colSpan; j++) {
        preparedGroup![rowIndex][j] = null;
      }
    } else {
      preparedGroup![rowIndex][columnIndex] = null;
    }

    preparedGroup![rowIndex].splice(columnIndex, 1, fieldSet[fieldId]);
  });

  return preparedGroup;
};

/**
 * disable field if exist in disabledFields object
 * @function findDisabledFields
 * @param {Object} prevFieldList
 * @param {Object} disabledFields
 * @returns {Object} new default values
 */
export const findDisabledFields = (
  prevFieldList: Record<number, FieldType>,
  disabledFields,
): Record<number, FieldType> => {
  const fields = clone(prevFieldList);

  Object.keys(disabledFields).forEach(fieldId => {
    if (fields[fieldId]) {
      fields[fieldId].disabled = true;
    }
  });

  return fields;
};

export const mergeHigherPriorityFieldsInProcessTask = (
  metaData: GeneralMetaData,
  clonedFieldsInGroupList: Record<number, FieldType>,
  processInfo: ProcessInformation,
): Record<number, FieldType> => {
  // fields in metaData have some properties, but it may a field has some higher priority properties in a specific state of a process.
  // in this situation higher priority properties will override on default field properties . `fieldsWithProcessNewProperties` is the result of this merge.
  const fieldsWithProcessNewProperties = clonedFieldsInGroupList;

  // if isShowMode true apply hidden and disabled form additional to field
  const { processuniqueid, positionid, stateid } = processInfo;

  // find process in metaData
  const processList = lodashFind(metaData?.processes, {
    uniqueId: processuniqueid,
  });

  if (processList) {
    let taskInfo: ProcessTaskInterface | null = null;

    if (!isEmpty(positionid) && !isEmpty(stateid)) {
      taskInfo = lodashFind(processList.tasks, {
        //@ts-ignore
        positionId: +positionid,
        //@ts-ignore
        stateId: +stateid,
      });

      if (!taskInfo) {
        console.error(
          `unable to find task with position "${positionid}" and state "${stateid}" in process "${processuniqueid}"`,
        );
      }
    } else {
      console.log('going with default task of process');
      taskInfo = processList.firstTask;
    }

    if (taskInfo) {
      Object.keys(taskInfo.fields).forEach(fieldId => {
        const taskField = taskInfo!.fields[fieldId];
        const metaField = metaData.fields[fieldId];
        if (typeof metaField === 'undefined') {
          console.log(`fieldId ${fieldId} is not in fields!`);
        } else {
          fieldsWithProcessNewProperties[fieldId] = {
            ...metaField,
            ...taskField,
          };
        }
      });
    }
  }

  return fieldsWithProcessNewProperties;
};

/**
 * find fields in meta data from an array of field ids
 * @function findAndCloneFieldsFromMetaData
 * @param {Object} metaDate
 * @param {Array<Number} fieldIds
 * @returns {object}
 */
export const findAndCloneFieldsFromMetaData = (
  metaDate: GeneralMetaData,
  fieldIds: number[] = [],
): Record<number, FieldType> => {
  const fields: Record<number, FieldType> = {};

  fieldIds.forEach(id => {
    if (typeof metaDate.fields[id] !== 'undefined') {
      fields[id] = clone(metaDate.fields[id]);
    }
  });

  return fields;
};

/**
 * it should update the default values of field in different conditions
 * @param {GeneralMetaData} metaData meta data
 * @param {Array<Number>} groupFields array of fields that exist in group lists in meta
 * @param {ProcessInformation} processInfo
 * @param {boolean} isShowMode
 * @param {string} resource
 * @returns {Record<number, FieldType>} fields with default values
 */
export const getGroupFields = (
  metaData: GeneralMetaData,
  groupFields: Array<number>,
  processInfo: ProcessInformation,
): Record<number, FieldType> => {
  let clonedFields = findAndCloneFieldsFromMetaData(metaData, groupFields);
  const hasProcess = processInfo.processuniqueid && metaData.processes;

  if (hasProcess) {
    clonedFields = mergeHigherPriorityFieldsInProcessTask(
      metaData,
      clonedFields,
      processInfo,
    );
  }

  return clonedFields;
};

const prepareGroups = (
  metaData: GeneralMetaData,
  defaultColumnCount: number,
  processInfo: ProcessInformation,
): Record<string, any> => {
  const groups = lodashGet(metaData, ['groups']);

  const preparedList: any = [];

  for (const groupId in groups) {
    const group = groups[groupId];

    const fieldSet = getGroupFields(metaData, group?.fields, processInfo);

    const createdLayout = createLayout(
      group.fieldsLayout,
      group.columnCount || defaultColumnCount,
      fieldSet,
    );

    preparedList.push({
      id: parseInt(groupId, 10),
      translatedTitle: group.translatedTitle, // always use translated strings
      columnCount: group.columnCount || defaultColumnCount,
      layout: createdLayout,
    });
  }

  return preparedList;
};

export const getTabsFromMetaData = (
  metaData: GeneralMetaData,
  quickMode = false,
): Array<MetaDataQuickTab> | Array<MetaDataTab> => {
  if (quickMode && metaData.quickTabPages && metaData.quickTabPages.length) {
    return lodashGet(metaData, ['quickTabPages']);
  }

  return lodashGet(metaData, ['tabPages']);
};

export const getShowTabList = ({
  metaData,
  defaultColumnCount,
  processInfo,
}: GetTabListInterface): RawShowTabs[] => {
  const tabList = getTabsFromMetaData(metaData, false);

  const allGroupList = prepareGroups(metaData, defaultColumnCount, processInfo);

  return tabList?.map((tab, index) => {
    const groupList = tab.groups.map(groupId =>
      lodashFind(allGroupList, { id: parseInt(groupId, 10) }),
    );

    return {
      ...tab,
      id: index, // it was tab.name previously
      groupList,
      resource: `${tab.moduleName}/${tab.tableName}`,
    };
  });
};

/**
 * get childs of multi report
 * @function getReportChildren
 * @param {Object} metaData
 * @param {string} locale
 * @returns {Array<Record<string, unknown>>}
 */
export const getReportChildren = (
  metaData: GeneralMetaData,
  locale: Locale,
): Array<Record<string, unknown>> => {
  if (!metaData || !Array.isArray(metaData.childs)) return [];

  const { reportType, tabsColumns, childs, id: parentId } = metaData;

  if (reportType === 'MultiResult' && tabsColumns) {
    return Object.keys(tabsColumns).map((tab, index) => ({
      title: childs[index]?.translatedTitle?.[locale] ?? childs[index]?.title,
      childResource: `report/${parentId}/${index}`,
    }));
  }

  // report type is parent child
  return childs.map(({ reportId }, index) => ({
    title: childs[index]?.translatedTitle?.[locale] ?? childs[index]?.title,
    childResource: `report/${reportId}`,
  }));
};

/**
 * @function getProcessList
 * @param {MetaData} metaData
 * @returns {Object} processList in two levels (actives and all)
 */
export const getProcessList = (metaData: GeneralMetaData): FullProcessList => {
  return {
    all: metaData?.processes ?? [],
    actives: metaData?.processes?.filter(process => process.isActive) ?? [],
  };
};

export const getProcessTaskByMetaDataAndRecord = (
  metaData: GeneralMetaData,
  record: Record<string, unknown>,
): MetaDataProcessTask | null => {
  const processInfoBaseOfRecord = getProcessInformationFromRecord(record);
  if (
    processInfoBaseOfRecord.processuniqueid &&
    !processInfoBaseOfRecord.positionid &&
    !processInfoBaseOfRecord.stateid
  ) {
    // its means here should select first task
    const targetProcess = metaData.processes.find(
      process => process.uniqueId === processInfoBaseOfRecord.processuniqueid,
    );

    const firstTaskStateId = (
      targetProcess?.['firstTask'] as Record<string, unknown>
    )?.['stateId'];

    const firstTaskPositionId = (
      targetProcess?.['firstTask'] as Record<string, unknown>
    )?.['positionId'];

    if (!isEmpty(firstTaskStateId)) {
      processInfoBaseOfRecord.stateid = firstTaskStateId as string;
    }

    if (!isEmpty(firstTaskPositionId)) {
      processInfoBaseOfRecord.positionid = firstTaskPositionId as string;
    }
  }

  for (const key in processInfoBaseOfRecord) {
    if (isEmpty(processInfoBaseOfRecord[key])) {
      return null;
    }

    if (!Array.isArray(metaData?.['processes'])) {
      return null;
    }

    const targetProcess = metaData.processes.find(
      process => process.uniqueId === processInfoBaseOfRecord.processuniqueid,
    );

    if (!targetProcess || !Array.isArray(targetProcess.tasks)) {
      return null;
    }

    const targetTask: MetaDataProcessTask | null =
      targetProcess.tasks.find(
        task =>
          // processInfoBaseOfRecord keys existent has been already checked in `for` loop
          task.positionId === parseInt(processInfoBaseOfRecord.positionid!) &&
          task.stateId === parseInt(processInfoBaseOfRecord.stateid!),
      ) ?? null;

    return targetTask;
  }
  return null;
};

/**
 * it check only hidden field in parent process step in deactive subpanels object and simple field.hidden.
 * current process hidden fields will handle in API side
 *
 * @function getHiddenGridColumns
 * @param entries {
 *   @param {GeneralMetaData} currentMetaData
 *   @param { GeneralMetaData} parentMetaData
 *   @param { Record<string, unknown>} parentRecord
 *  }
 * @returns {Array<string>}
 *
 */
export const getHiddenGridColumns: GetHiddenGridColumns = entries => {
  try {
    const { parentMetaData, currentMetaData, parentRecord } = entries;

    // --------------------- declare some variables ---------------------
    const fieldNamesReference: Record<number, string> = {};
    let parentProcessInfo;

    const { reportId, config } = currentMetaData;

    const currentResource = reportId
      ? `report/${reportId}`
      : config
      ? `${config.moduleName}/${config.moduleTableName}`
      : null;

    // find parent process status
    if (parentRecord && parentMetaData?.['processes']) {
      try {
        parentProcessInfo = getProcessTaskByMetaDataAndRecord(
          parentMetaData,
          parentRecord,
        );
      } catch (error) {
        console.error('Error 😱', error);
      }
    }

    // 1- hidden field in meta data (not related to process step)
    const hiddenFields = Object.values(currentMetaData.fields)
      .filter(field => {
        // its just a trick to find field names base of field ids later easier and without extra loops
        fieldNamesReference[field.id] = field.name;

        return field.hidden;
      })
      .map(field => field.name);

    if (!isEmptyObject(parentProcessInfo)) {
      const relatedDeactiveSubPanelObject = parentProcessInfo.deactiveSubpanels.find(
        deactiveSubPanelObject =>
          `${deactiveSubPanelObject.moduleName}/${deactiveSubPanelObject.moduleTableName}` ===
          currentResource,
      );

      if (relatedDeactiveSubPanelObject) {
        // check for hidden
        if (relatedDeactiveSubPanelObject.hiddenFields) {
          relatedDeactiveSubPanelObject.hiddenFields.forEach(fieldId => {
            if (!hiddenFields.includes(fieldNamesReference[fieldId])) {
              hiddenFields.push(fieldNamesReference[fieldId]);
            }
          });
        }
      }
    }

    return hiddenFields;
  } catch (error) {
    console.error('getHiddenGridColumns error: ', error);
    return [];
  }
};

/**
 * The following function is divided into several parts
 *  1- Getting the parent meta to split the DeactiveSubpanels field
 *  2- Check the From field isRequiredFields to DeactiveSubpanels
 * PAY ATTENTION: We can't use the following function in `WMS` forms, we perform `wms` specs in `dynamic-input` instead
 * @function getRequiredFieldsFromDeactivateSubPanel
 * @param entries {
 *    @param {parentMetaData, currentMetaData,parentRecord} entries
 *   }
 * @returns {GetRequiredFieldsFromDeactivateSubPanel} GetRequiredFieldsFromDeactivateSubPanel
 */
export const getRequiredFieldsFromDeactivateSubPanel: GetRequiredFieldsFromDeactivateSubPanel =
  entries => {
    const { parentMetaData, currentMetaData, parentRecord } = entries;

    // find parent process status
    if (!parentRecord || !parentMetaData?.['processes']) return {};

    const parentProcessInfo = getProcessTaskByMetaDataAndRecord(
      parentMetaData,
      parentRecord,
    );

    if (isEmptyObject(parentProcessInfo)) return {};

    const { reportId, config } = currentMetaData;

    const currentResource = reportId
      ? `report/${reportId}`
      : config
      ? `${config.moduleName}/${config.moduleTableName}`
      : null;

    const relatedDeactiveSubPanelObject = parentProcessInfo.deactiveSubpanels?.find(
      deactiveSubPanelObject =>
        `${deactiveSubPanelObject.moduleName}/${deactiveSubPanelObject.moduleTableName}` ===
        currentResource,
    );

    const requiredFieldsInDeactivateSubPanel: Record<string, FieldType> = {};
    if (relatedDeactiveSubPanelObject?.isRequiredFields) {
      // prettier-ignore
      for (const subPanelRequiredFieldId of relatedDeactiveSubPanelObject.isRequiredFields) {
        // if a field is required in the deactiveSubpanels, but is not present at all in the child's metadata, it will be ignored
        if (currentMetaData.fields[subPanelRequiredFieldId] == null) continue;
        requiredFieldsInDeactivateSubPanel[subPanelRequiredFieldId] = currentMetaData.fields[subPanelRequiredFieldId];
      }
    }
    return requiredFieldsInDeactivateSubPanel;
  };

/**
 * TODO: Break the following function to some smaller & specific sub-functions
 *
 * it has few parameters:
 *  1- isRecordEditable
 *  2- check is report
 *  3- isFieldIsNotDisabledByParentDeactiveSubpanels
 *  4- exist in metadata but not exist in process task and ui enable/visible
 *  5- disable table primary field
 *
 * PAY ATTENTION: We can't use the following function in `WMS` forms, we perform `wms` specs in `dynamic-input` instead
 *
 * @function getInputsInitialAppearanceCharacteristics
 * @param entries {
 *    @param {GeneralMetaData} currentMetaData
 *    @param {Record<string, unknown>} currentRecord
 *    @param { GeneralMetaData} parentMetaData
 *    @param { Record<string, unknown>} parentRecord
 *   }
 * @returns {Record<string, InputAppearanceCharacteristics> | null}
 */
export const getInputsInitialAppearanceCharacteristics: GetInputsInitialAppearanceCharacteristics =
  entries => {
    logger.logMethodArgs?.('getInputsInitialAppearanceCharacteristics', { entries });

    try {
      const {
        parentMetaData,
        currentMetaData,
        parentRecord,
        currentRecord,
        currentProcessInfo,
      } = entries;

      // --------------------- declare some variables ---------------------
      let parentProcessInfo;
      let _currentProcessInfo = currentProcessInfo;
      let disableAllFields = false;
      const fieldsStatus: Record<string, InputAppearanceCharacteristics> = {};
      const fieldNamesReference: Record<number, string> = {};
      const currentResource = currentMetaData.reportId
        ? `report/${currentMetaData.reportId}`
        : `${currentMetaData.config?.moduleName}/${currentMetaData.config?.moduleTableName}`;

      // --------------------- gather some general information about situation ---------------------
      // find parent process status
      if (parentRecord && parentMetaData?.['processes']) {
        try {
          parentProcessInfo = getProcessTaskByMetaDataAndRecord(
            parentMetaData,
            parentRecord,
          );

          logger.logMethodArgs?.(
            'getInputsInitialAppearanceCharacteristics => find parent process status(`getProcessTaskByMetaDataAndRecord` result)',
            clone({ parentRecord, parentMetaData, parentProcessInfo }),
          );
        } catch (error) {
          logger.error(
            'getInputsInitialAppearanceCharacteristics => find parent process status(`getProcessTaskByMetaDataAndRecord` result)',
            'getProcessTaskByMetaDataAndRecord_run_error',
            error,
          );
        }
      }

      // find self process status
      if (
        _currentProcessInfo == null &&
        currentRecord &&
        currentMetaData?.['processes']
      ) {
        try {
          _currentProcessInfo = getProcessTaskByMetaDataAndRecord(
            currentMetaData,
            currentRecord,
          );

          logger.logMethodArgs?.(
            'getInputsInitialAppearanceCharacteristics => find self process status(`getProcessTaskByMetaDataAndRecord` result)',
            clone({ currentRecord, currentMetaData, _currentProcessInfo }),
          );
        } catch (error) {
          logger.error(
            'getInputsInitialAppearanceCharacteristics => find self process status(`getProcessTaskByMetaDataAndRecord` result)',
            'getProcessTaskByMetaDataAndRecord_run_error',
            error,
          );
        }
      }

      // extract all field names
      Object.values(currentMetaData.fields).forEach(field => {
        fieldsStatus[field.name] = {
          enable: !field.disabled,
          visible: !field.hidden,
        };

        // its just a trick to find field names base of field ids later easier and without extra loops
        fieldNamesReference[field.id] = field.name;
      });

      logger.logMethodArgs?.(
        'getInputsInitialAppearanceCharacteristics => extract all field names',
        clone({ currentRecord, currentMetaData, fieldsStatus, fieldNamesReference }),
      );

      // 1- check record is editable
      if (String(currentRecord?.['iseditable']).toLowerCase() === 'false') {
        logger.logMethodArgs?.(
          'getInputsInitialAppearanceCharacteristics(1- check record is editable)',
          clone({ currentRecord, fieldsStatus, fieldNamesReference }),
        );
        disableAllFields = true;
      }

      // 2- check is report
      if (!isEmpty(currentMetaData.reportId)) {
        logger.logMethodArgs?.(
          'getInputsInitialAppearanceCharacteristics(2- check is report) report is empty',
          clone({ currentRecord, fieldsStatus, fieldNamesReference }),
        );

        disableAllFields = true;
      }

      // 3- check deactiveFields in deactiveSubpanels in current process state in parent metaData (in case of relation)
      if (parentProcessInfo) {
        const relatedDeactiveSubPanelObject =
          parentProcessInfo?.deactiveSubpanels.find(
            deactiveSubPanelObject =>
              `${deactiveSubPanelObject.moduleName}/${deactiveSubPanelObject.moduleTableName}` ===
              currentResource,
          );

        logger.logMethodArgs?.(
          'getInputsInitialAppearanceCharacteristics(3- check deactiveFields in deactiveSubpanels in current process state in parent metaData (in case of relation))',
          clone({
            parentProcessInfo,
            relatedDeactiveSubPanelObject,
            fieldsStatus,
            fieldNamesReference,
          }),
        );

        if (relatedDeactiveSubPanelObject) {
          if (relatedDeactiveSubPanelObject.disableEdit) {
            logger.logMethodArgs?.(
              'getInputsInitialAppearanceCharacteristics(3- check deactiveFields => `relatedDeactiveSubPanelObject.disableEdit` )',
              clone({
                relatedDeactiveSubPanelObject,
                fieldsStatus,
                fieldNamesReference,
              }),
            );
            disableAllFields = true;
          }

          // check for disable
          if (relatedDeactiveSubPanelObject.deActiveFields) {
            for (const fieldId of relatedDeactiveSubPanelObject.deActiveFields) {
              if (fieldsStatus[fieldNamesReference[fieldId]] == null) continue;
              fieldsStatus[fieldNamesReference[fieldId]].enable = false;
            }

            logger.logMethodArgs?.(
              'getInputsInitialAppearanceCharacteristics(3- check deactiveFields => `relatedDeactiveSubPanelObject.deActiveFields` )',
              clone({
                relatedDeactiveSubPanelObject,
                fieldsStatus,
                fieldNamesReference,
              }),
            );
          }

          // check for hidden
          if (relatedDeactiveSubPanelObject.hiddenFields) {
            for (const fieldId of relatedDeactiveSubPanelObject.hiddenFields) {
              if (fieldsStatus[fieldNamesReference[fieldId]] == null) continue;
              fieldsStatus[fieldNamesReference[fieldId]].visible = false;
            }

            logger.logMethodArgs?.(
              'getInputsInitialAppearanceCharacteristics(3- check deactiveFields => `relatedDeactiveSubPanelObject.hiddenFields` )',
              clone({
                relatedDeactiveSubPanelObject,
                fieldsStatus,
                fieldNamesReference,
              }),
            );
          }
        }
      }

      // 4- check ui enable/visible
      if (_currentProcessInfo) {
        // 4-1: in process tables
        if (!_currentProcessInfo.allowEdit) {
          logger.logMethodArgs?.(
            'getInputsInitialAppearanceCharacteristics(4-1 check field disable in current metadata process info => `!_currentProcessInfo.allowEdit`)',
            clone({ _currentProcessInfo, fieldsStatus, fieldNamesReference }),
          );
          disableAllFields = true;
        }

        /**
         * We have to check corresponding `fieldName` in the main fields(in `metaData`) that exists in the current step of a process,
         * If there isn't a field in the current step, we have to set it `disabled`,
         * Otherwise the incoming field status should be replaced
         */
        for (const fieldId of Object.keys(fieldNamesReference)) {
          const fieldName = fieldNamesReference[fieldId];

          /**
           * If there isn't any fields in `metadata`
           */
          if (_currentProcessInfo!.fields[fieldId] == null) {
            fieldsStatus[fieldName].enable = false;
            continue;
          }

          // else
          fieldsStatus[fieldName].enable = !(
            _currentProcessInfo.fields as Record<string, FieldType>
          )[fieldId].disabled;

          // handle hidden fields
          if (_currentProcessInfo.fields[fieldId].hidden) {
            fieldsStatus[fieldName].visible = false;
          }
        }

        logger.logMethodArgs?.(
          'getInputsInitialAppearanceCharacteristics(4-1 check field disable in current metadata process info => `_currentProcessInfo.allowEdit`)',
          clone({ _currentProcessInfo, fieldsStatus, fieldNamesReference }),
        );
      } else {
        // 4-2: in no process tables
        logger.logMethodArgs?.(
          'getInputsInitialAppearanceCharacteristics(4-2: in no process tables => `isEmptyObject(_currentProcessInfo)`)',
          { currentProcessInfo, fieldsStatus },
        );

        Object.values(currentMetaData.fields).forEach(field => {
          if (!isEmpty(field.javaScriptUiVisible?.trim())) {
            try {
              if (!checkUiVisible(field, currentRecord)) {
                fieldsStatus[field.name].visible = false;
              }
            } catch (error) {
              console.error(error);
            }
          }

          // check ui enable in higher priority field in process task
          if (!isEmpty(field.javaScriptUiEnable?.trim())) {
            try {
              if (!checkUiEnable(field, currentRecord)) {
                fieldsStatus[fieldNamesReference[field.id]].enable = false;
              }
            } catch (error) {
              console.error(error);
            }
          }
        });

        logger.logMethodArgs?.(
          'getInputsInitialAppearanceCharacteristics(4-2: in no process tables => `isEmptyObject(_currentProcessInfo)`)',
          clone({ currentProcessInfo, fieldsStatus }),
        );
      }

      // if in case 1 - 2 - 3 - 4 this variable got `true`, here should disable all fields
      if (disableAllFields) {
        for (const field in fieldsStatus) {
          fieldsStatus[field].enable = false;
        }

        logger.logMethodArgs?.(
          'getInputsInitialAppearanceCharacteristics(if in case 1 - 2 - 3 - 4 - 5 this variable got `true`, here should disable all fields)',
          clone({ disableAllFields, fieldsStatus }),
        );
      }

      // 5- disable table primary field
      const primaryField = getPrimaryField(currentMetaData);
      fieldsStatus[primaryField.name].enable = false;
      logger.logMethodArgs?.(
        'getInputsInitialAppearanceCharacteristics(5- disable table primary field)',
        clone({ primaryField, fieldsStatus }),
      );

      logger.logMethodArgs?.(
        'getInputsInitialAppearanceCharacteristics(6- read only fields in `WMS` should be disabled)',
        clone({ currentMetaData, fieldsStatus }),
      );

      return fieldsStatus;
    } catch (error) {
      logger.error(
        'getInputsInitialAppearanceCharacteristics',
        'getProcessTaskByMetaDataAndRecord_whole_running_error',
        error,
      );
      return {};
    }
  };

/**
 * prepare service dialog inputs appearance characteristics
 * @function getServiceInputsInitialAppearanceCharacteristics
 * @param {FieldType[]} fields
 * @param {Record<string, unknown>} serviceParameters
 * @returns {Record<string, InputAppearanceCharacteristics>}
 */
export const getServiceInputsInitialAppearanceCharacteristics: GetServiceInputsInitialAppearanceCharacteristics =
  (fields, serviceParameters) => {
    const preparedCharacteristics: Record<string, InputAppearanceCharacteristics> =
      {};

    fields.forEach(field => {
      preparedCharacteristics[field.name] = {
        enable: !computeDisableField(field, serviceParameters),
        visible: !field.hidden,
      };
    });

    return preparedCharacteristics;
  };

/**
 * prepare inputs appearance characteristics base on "disabled" and "hidden" property in meta
 * @function getInputsInitialAppearanceCharacteristicsBaseOnFieldProperty
 * @param {FieldType[]} fields
 * @returns {Record<string, InputAppearanceCharacteristics>}
 */
export const getInputsInitialAppearanceCharacteristicsBaseOnFieldProperty = (
  fields: FieldType[],
): Record<string, InputAppearanceCharacteristics> => {
  const preparedCharacteristics: Record<string, InputAppearanceCharacteristics> = {};

  fields.forEach(field => {
    preparedCharacteristics[field.name] = {
      enable: !field.disabled,
      visible: !field.hidden,
    };
  });

  return preparedCharacteristics;
};

/**
 * @function getRequiredFields
 * @param {GeneralMetaData} metaData
 * @returns {FieldType[]} a list of required fields in the meta
 */
export const getRequiredFields = (metaData: GeneralMetaData): FieldType[] => {
  const isMultiReportMeta =
    metaData.reportType === 'ParentChild' || metaData.reportType === 'MultiResult';

  // The simple table does not have any required filter
  if (!isMultiReportMeta) {
    return [];
  }

  const filterFields = getFilterColumns(metaData);

  const requiredFields: FieldType[] = [];
  for (const item of filterFields) {
    if (item.required) {
      requiredFields.push(item);
    }
  }

  return requiredFields;
};

const pendingMetaData = {};
/**
 * if meta data exist in actor return it
 * if meta data does not exist in actor return it from network
 * @function getMetaDataFromActorOrNetwork
 * @param {string} relationResource
 * @param {Array<string> | null = null} neededResources
 * @param {boolean} disableNotification
 * @returns {Promise<Record<string, GeneralMetaData>>}
 */
export const getMetaDataFromActorOrNetwork = async (
  relationResource: string,
  neededResources: Array<string> | null = null,
  disableNotification = false,
): Promise<Record<string, GeneralMetaData>> => {
  const metaData: Record<string, GeneralMetaData> = {};
  try {
    if (!(relationResource in pendingMetaData)) {
      pendingMetaData[relationResource] = getMetaFromApi(relationResource);
    }

    const allMetaData = await pendingMetaData[relationResource];
    delete pendingMetaData[relationResource];

    if (neededResources) {
      neededResources.forEach(resource => {
        metaData[resource] = allMetaData.find(item => item.name === resource)?.meta;
      });
    } else {
      metaData[relationResource] = allMetaData.find(
        item => item.name === relationResource,
      )?.meta;

      if (!actorGetActionValue('metaData', relationResource)) {
        actorSetActionValue('metaData', metaData[relationResource], {
          path: relationResource,
        });
      }
    }
  } catch (error) {
    if (!disableNotification) {
      actorDispatch('notification', { message: error, type: 'error' });
    }
    throw error;
  }
  return metaData;
};
