import { type FC, useState, useEffect, useCallback, useRef } from 'react';
import { GET_LIST } from 'react-admin';

import AnnouncementsChartView from './announcements-chart.view';
import { actorDispatch } from '../../../type/actor-setup';
import { areTwoObjectsShallowEqual, clone } from '../../../helper/data-helper';
import LoadingBox from '../../loading-box';

import type { AnnouncementsChartControllerProps } from './announcements-chart.type';
import type { AnnoucementPost, PaginationParams } from '../../announcement-posts';
import Typography from '@material-ui/core/Typography';

import { useTranslate } from 'react-admin';
const AnnouncementsChartController: FC<
  AnnouncementsChartControllerProps
> = props => {
  const { chartOptions, announcementsData, height, width } = props;
  const { paginationParams, reportAddress } = chartOptions;

  const [pagination, setPagination] = useState<PaginationParams>(paginationParams);
  const prevPaginationRef = useRef<PaginationParams>(paginationParams);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<AnnoucementPost[]>(announcementsData);
  const translate = useTranslate();

  useEffect(() => {
    if (!areTwoObjectsShallowEqual(pagination, prevPaginationRef))
      updateReportData();
  }, [pagination]);

  /**
   * get new report data
   * @function updateReportData
   * @returns {void} void
   */
  const updateReportData = useCallback((): void => {
    setIsLoading(true);
    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        resource: `report/${reportAddress}`,
        requestParameters: {
          pagination: {
            page: pagination.currentPage,
            perPage: pagination.total,
          },
        },
        onSuccess: reportData => {
          //if reportData unlike [GET_LIST]: {[entity]: {
          if (typeof reportData.data !== 'undefined') {
            setData(reportData.data ?? []);
            setIsLoading(false);
          }
        },
      },
      { disableDebounce: true },
    );
  }, [pagination]);

  /**
   * @function onChangePageHandler
   * @param {number} page
   * @returns { void }
   */
  const onChangePageHandler = (page: number): void => {
    setPagination(previous => {
      prevPaginationRef.current = clone(previous);
      return { ...pagination, currentPage: page };
    });
  };

  if (isLoading) {
    return <LoadingBox />;
  }

  if (!data.length) {
    return (
      <Typography variant="h5" color="secondary">
        {translate('general.noData')}
      </Typography>
    );
  }
  return (
    <AnnouncementsChartView
      onChangePageHandler={onChangePageHandler}
      announcementsData={data}
      paginationParams={pagination}
      height={height}
      width={width}
    />
  );
};

export default AnnouncementsChartController;
