/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import lodashMerge from 'lodash/merge';
import lodashGet from 'lodash/get';

import { actorDispatch } from '../type/actor-setup';
import { isEmpty, isEmptyObject } from './data-helper';

/**
 * Get dropdown request parameter from form data.
 * @function getDropdownRequestParams
 * @param {object} -
 * {
 *   dropdownMeta: object,
 *   page: number,
 *   perPage: number,
 *   record: object,
 *   search: string,
 *   filterValues: object,
 *   forceTreeLevel: boolean,
 *   additionalProps: object,
 *   resource: string,
 *   sort:string
 * }
 * @returns {object}
 */
export const getDropdownRequestParams = ({
  dropdownMeta,
  page = 1,
  perPage = 10,
  record = {},
  search = '',
  filterValues = {},
  forceTreeLevel = dropdownMeta.forceTreeLevel,
  additionalProps = {},
  resource = '',
  sort = '',
}) => {
  const { parameters: dropdownRequiredParameters } = dropdownMeta;

  const result = {
    pagination: {
      page,
      perPage,
    },
    sort,
    search,
    filter: filterValues,
  };

  const parameters = {};
  dropdownRequiredParameters.forEach(item => {
    if (!item) {
      return;
    }
    const { originalRecord } = additionalProps;
    const { to, from, moduleName, moduleTableName, defaultValue } = item;
    const formFieldName = !isEmpty(from) ? from.toLowerCase() : null;
    const paramFieldName = !isEmpty(to) ? to.toLowerCase() : null;

    let finalRecord = record;
    if (!isEmpty(moduleName) && !isEmpty(moduleTableName)) {
      const dropdownParameterResource = `${moduleName}/${moduleTableName}`;
      if (dropdownParameterResource !== resource) {
        finalRecord = originalRecord;
      }
    }

    const parameterValue = computeParameterValue(
      finalRecord,
      formFieldName,
      defaultValue,
    );

    if (!isEmpty(parameterValue)) {
      parameters[paramFieldName] = parameterValue;
    }
  });

  if (Object.keys(parameters).length > 0) {
    result.parameters = JSON.stringify(parameters);
  }

  result.forceTreeLevel = forceTreeLevel;

  return result;
};

/**
 * Check `fieldName` in `data` and return value.
 * @function computeParameterValue
 * @param {object} data
 * @param {string} fieldName
 * @param {number | string | null} defaultValue
 * @returns {number | string | null}
 */
const computeParameterValue = (data, fieldName, defaultValue) => {
  return data && !isEmpty(data[fieldName])
    ? data[fieldName]
    : !isEmpty(defaultValue)
      ? defaultValue
      : null;
};

export const getLabelForDropdownOption = (dropdownMeta, row) => {
  const { displayMember, displayMember2 } = dropdownMeta;


  let label = row?.[displayMember];

  if (typeof row?.[displayMember2] !== 'undefined') {
    label += ' - ' + row[displayMember2];
  }

  return label ? String(label) : '';
};

/**
 * it will compute the dropdown label that should be displayed and the dropdown value that
 * should send in requests and returns this info in an object to use in the dropdown field.
 * @function findSelectedItemFromDropdownData
 * @param {Object} includes dropdownMeta,dataArray,record,value,field,isSingleSelect
 * @returns {Object} dropdown lable and value
 */
export const findSelectedItemFromDropdownData = ({
  dropdownMeta,
  dataArray,
  record,
  value,
  field,
  isSingleSelect = false,
}) => {
  // if we have any data from api, use that first!
  if (dataArray && dataArray.length && !isEmpty(value)) {
    const dropdownItem = dataArray.find(item => {
      // keep loose comparison, because server might give number inside string
      // eslint-disable-next-line eqeqeq
      const computedValue = Array.isArray(value) ? lodashGet(value, 2) : value;
      return isSingleSelect
        ? item == computedValue
        : item[dropdownMeta?.filterValueMember] == computedValue;
    });

    if (dropdownItem) {
      return {
        ...dropdownItem,
        value: isSingleSelect
          ? dropdownItem
          : dropdownItem[dropdownMeta?.valueMember],
        label: getLabelForDropdownOption(dropdownMeta, dropdownItem),
      };
    }
  } else if (!isEmpty(value)) {
    return {
      value: value,
      label: value,
    }
  }

  const preFilledName = dropdownPreFilledName(field);
  if (!isEmpty(value) && record && record[preFilledName]) {
    return {
      value: value,
      label: record[preFilledName],
    };
  }

  return null;
};

export const dropdownPreFilledName = field => {
  return field.relatedName;
};

/**
 * this function will call the getDropdownRequestParams function to compute dropdown parameters
 * for HTTP requests then merge them with custom params that have been received from the third parameter.
 * then trigger the findDropdownData action that has been destructed from the first parameter (props)
 * @function triggerDropdownFetchData
 * @param {object} props
 * @param {string} value
 * @param {object} customParams constant parameters
 * @returns {void}
 */
export const triggerDropdownFetchData = (props, value, customParams = {}) => {
  const {
    formData,
    record = {},
    dropdownMeta,
    additionalProps,
    resource,
    perPage,
    forceTreeLevel,
    filterValues,
    page,
    dropdownInPuzzleForm,
    isTodo,
    isProfile,
    isService,
    sort,
    field,
  } = props;

  const finalParams =
    dropdownInPuzzleForm || isTodo || isProfile || isService
      ? { ...customParams, fieldName: undefined, dropId: undefined }
      : customParams;

  const searchValue = !isEmpty(value)
    ? Array.isArray(value) && !isEmpty(value[2])
      ? value[2]
      : value
    : '';

  const params = lodashMerge(
    getDropdownRequestParams({
      dropdownMeta,
      record: !isEmptyObject(formData) ? formData : record,
      search: searchValue,
      additionalProps,
      resource,
      perPage: !isEmpty(perPage) ? perPage : null,
      page: !isEmpty(page) ? page : null,
      forceTreeLevel: !isEmpty(forceTreeLevel) ? forceTreeLevel : null,
      filterValues: !isEmptyObject(filterValues) ? filterValues : null,
      sort: !isEmptyObject(sort) ? sort : '',
    }),
    finalParams,
  );

  actorDispatch(
    'fetchDropdownData',
    {
      exactRequestParams: params,
      dropdownMeta,
      failureCallback: customParams.failureCallback,
      option: {
        fieldId: field.id,
        isFilter: customParams.isFilter
      },
      failureCallback: customParams.failureCallback
    },
    { disableDebounce: true, replaceAll: true },
  );
};
