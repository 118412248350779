import { type FC } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { TextField, Typography } from '@material-ui/core';

import DatePicker from '../../date-picker';
import { useStyles } from './range-picker-input.style';
import { Locale } from '../../../type/global-types';

import type { RangePickerInputViewProps } from './range-picker-input.type';

const RangePickerInputView: FC<RangePickerInputViewProps> = props => {
  const {
    inputValue,
    inputMode,
    placeholder,
    isActive,
    showDatePicker,
    onInputChange,
    onInputBlur,
    onChooseDate,
  } = props;

  const locale: Locale = useLocale();
  const translate = useTranslate();

  const classes = useStyles({ isRtl: locale !== Locale.EN });

  return (
    <div className={classes.RangePickerInputViewContainer}>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.textField}
          value={inputValue}
          onChange={onInputChange}
          onBlur={onInputBlur}
          error={isActive && inputMode === 'invalid'}
          placeholder={placeholder}
          inputProps={{ style: { textAlign: 'center', direction: 'ltr' } }}
          disabled={!isActive}
        />
        {showDatePicker && (
          <DatePicker
            onChange={onChooseDate}
            isGregorian={locale === 'en'}
            disabled={!isActive}
            hiddenInput
          />
        )}
      </div>

      <Typography
        color={isActive ? 'error' : 'textSecondary'}
        align="right"
        style={{ height: 21 }}
      >
        {inputMode === 'invalid' &&
          (showDatePicker
            ? translate('rangePickerJira.invalidDate')
            : translate('rangePickerJira.invalidRange'))}
      </Typography>
    </div>
  );
};

export default RangePickerInputView;
