import { FC, useMemo, useEffect, ReactElement, useRef, RefObject } from 'react';
import compose from 'recompose/compose';
import { makeStyles } from '@material-ui/core';
import { TreeView } from 'devextreme-react';
import { useTranslate, setListSelectedIds } from 'react-admin';
import { crudDelete } from 'ra-core';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import dxTreeView from 'devextreme/ui/tree_view';
import { dxElement } from 'devextreme/core/element';

import ConfirmDialogHOC from '../ConfirmDialogHOC';
import { getTreeParentFieldName } from '../../helper/MetaHelper';
import ListToolbar from '../ListToolbar';
import { getListControllerProps } from '../ListContainerApiController';
import TreeRowItem from '../../component/tree/TreeRowItem';
import { TreeRow } from '../../helper/Types';
import { showNotification } from '../../helper/general-function-helper';
import LoadingBox from '../../component/loading-box';

type showCheckBoxesModeTypes = 'normal' | 'none' | 'selectAll';
type HandleClickOnItem = () => void;

interface AdditionalProps {
  selectNodesRecursive: boolean;
  selectByClick: boolean;
  showCheckBoxesMode?: showCheckBoxesModeTypes;
}

interface TreeViewContainerInterface {
  tree: TreeRow[];
  onRowClick: Function;
  forceTreeLevel: boolean;
  treeLevel: number;
  setSelectedId: Function;
  isDropdown: boolean;
  useInFilterInput: boolean;
  actions: object;
  bulkActions: object;
  metaData: object;
  basePath: string;
  contentCreate: ReactElement;
  settingToolbar: ReactElement;
  redirect: string | boolean;
  resource: string;
  setListSelectedIdsInRedux: Function;
  setDisableDelete: Function;
  displayField: string;
  displayField2: string;
  ids: (number | string)[];
  selectionRef: { current: object };
  loading: boolean;
}

interface SyncSelectionEvent {
  component?: dxTreeView | undefined;
  element?: dxElement | undefined;
  model?: object;
}

const useStyle = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    overflow: 'auto',
    padding: 7,
    display: 'flex',
    flexDirection: 'column',
  },

  treeView: {
    flexGrow: 1,
    height: 1,
    overflow: 'auto',

    '& .dx-treeview-item': {
      padding: 0,
      backgroundColor: 'unset !important',
      display: 'flex',
      alignItems: 'center',
    },

    '& .dx-treeview-item-content': {
      width: '100%',
    },

    '& .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item *': {
      color: theme.palette.text.primary,
    },
  },

  itemContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  editButton: {
    padding: '7px !important',
    marginLeft: 10,
  },

  item: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',

    '& a': {
      opacity: 0,
    },

    '& button': {
      opacity: 0,
    },

    '&:hover': {
      '& a': {
        opacity: 1,
        transition: 'opacity 200ms',
      },
      '& button': {
        opacity: 1,
        transition: 'opacity 200ms',
      },
    },
  },

  toolbarBottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const TreeViewContainer: FC<TreeViewContainerInterface> = props => {
  const {
    tree,
    onRowClick,
    forceTreeLevel,
    treeLevel,
    setSelectedId,
    isDropdown,
    actions,
    bulkActions,
    metaData,
    basePath,
    contentCreate,
    redirect,
    resource,
    setListSelectedIdsInRedux,
    setDisableDelete,
    displayField,
    displayField2,
    ids,
    selectionRef,
    settingToolbar,
    useInFilterInput,
  } = props;

  const classes = useStyle();
  const translate = useTranslate();
  const controllerProps: object = getListControllerProps(props);
  const treeParentFieldName: string = isDropdown
    ? ''
    : getTreeParentFieldName(metaData);

  const treeViewRef: RefObject<TreeView> = useRef(null);

  useEffect(() => {
    if (selectionRef) {
      selectionRef.current = { clearSelection };
    }
  }, [selectionRef]);

  useEffect(() => {
    clearSelection();
  }, [tree]);

  const additionalProps = useMemo(() => {
    const additionalProps: AdditionalProps = {
      selectNodesRecursive: false,
      selectByClick: false,
      showCheckBoxesMode: 'normal',
    };

    return !isDropdown && additionalProps;
  }, [isDropdown]);

  /**
   * Check `forceTreeLevel` if is `true` check `row` and `treeLevel` for select items on the tree, else show notification.
   * @function handleClickOnItem
   * @param {object} row
   * @returns {void}
   */
  const handleClickOnItem =
    (row: TreeRow): HandleClickOnItem =>
    () => {
      if (!useInFilterInput && forceTreeLevel) {
        if (treeLevel === 0 || row.currentlevel === treeLevel - 1) {
          onRowClick(row);
        } else {
          showNotification('tree.itIsNotPossibleToSelectFromThisLevel', 'error');
        }
      } else {
        onRowClick(row);
      }
      setSelectedId(row.id);
    };

  /**
   * this function will compute an object that includes all or tree rows that are selected with
   * checkboxes from an Event that came through props.
   * then extract ids from these objects and save them in redux with action as a number array.
   * then decide to disable the delete button if is there a row selected with items in it.
   * @function syncSelection
   * @param event
   * @returns {void}
   */
  const syncSelection = (event: SyncSelectionEvent) => {
    const selectedItems: TreeRow[] =
      event && event.component
        ? event.component.getSelectedNodes().map(node => node.itemData)
        : [];
    setListSelectedIdsInRedux(
      resource,
      selectedItems.map(row => +row.id),
    );

    // for disable delete button if it has child
    if (
      selectedItems.length === 1 &&
      lodashGet(selectedItems, [0, 'items'], []).length
    ) {
      setDisableDelete(true);
    } else {
      setDisableDelete(false);
    }
  };

  /**
   * this function should clear all selected items from treeview internal state by ref.
   * @function clearSelection
   * @returns {void}
   */
  const clearSelection = () => {
    setListSelectedIdsInRedux(resource, []);
    setDisableDelete(true);
    if (treeViewRef && treeViewRef.current) {
      treeViewRef.current.instance.unselectAll();
    }
  };

  return (
    <div
      className={classes.container}
      data-test-tree-container
      data-test-children-length={ids && ids.length ? ids : 'emptyTree'}
    >
      {actions && (
        <ListToolbar
          {...controllerProps}
          actions={actions}
          bulkActions={bulkActions}
        />
      )}

      <TreeView
        {...additionalProps}
        id="simpleTreeview"
        className={classes.treeView}
        items={tree}
        ref={treeViewRef}
        itemRender={row =>
          TreeRowItem(
            row,
            treeParentFieldName,
            classes,
            handleClickOnItem,
            isDropdown,
            treeLevel,
            basePath,
            contentCreate,
            redirect,
            metaData,
            resource,
            displayField,
            displayField2,
            translate,
          )
        }
        searchMode="contains"
        searchTimeout={500}
        virtualModeEnabled={true}
        searchEnabled={true}
        rtlEnabled={true}
        noDataText={translate('tree.noDataToDisplay')}
        selectionMode="multiple"
        searchEditorOptions={{ placeholder: translate('ra.action.search') }}
        onSelectionChanged={syncSelection}
      />

      {settingToolbar && (
        <div className={classes.toolbarBottomContainer}>
          <ListToolbar
            {...controllerProps}
            actions={settingToolbar}
            settingToolbar
            currentSort={{ field: displayField, order: 'ASC' }}
            isTree
          />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  dispatchCrudDelete: crudDelete,
  setListSelectedIdsInRedux: setListSelectedIds,
};

export default compose(
  ConfirmDialogHOC,
  connect(null, mapDispatchToProps),
)(TreeViewContainer);
