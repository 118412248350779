import { memo, useState, type FC } from 'react';

import ColorPickerView from './color-picker.view';

import type { ColorPickerInterface } from './color-picker.type';
import type { ColorResult } from 'react-color';

const ColorPickerController: FC<ColorPickerInterface> = memo(props => {
  const { onSelectColor, selectedColor, defaultColorList = [] } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /**
   * @function onCirclePickerChange
   * @param {ColorResult} event
   * @returns {void} void
   */
  const onCirclePickerChange = (event: ColorResult): void => {
    onSelectColor(event.hex);
  };

  return (
    <ColorPickerView
      colors={defaultColorList}
      onSelectColor={onSelectColor}
      selectedColor={selectedColor}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onCirclePickerChange={onCirclePickerChange}
    />
  );
});

export default ColorPickerController;
