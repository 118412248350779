import { useEffect, useState } from 'react';
import { GeneralMetaData } from '../../../helper/Types';
import { actorGetActionValue } from '../../../type/actor-setup';
import { getMetaDataFromActorOrNetwork } from '../../../helper/meta-helper';
import { ReportMetaDataInterface } from './multi-tab-table-relation.type';

interface MultiReportMetaDataList {
  [relationResource: string]: GeneralMetaData | null;
}

const useMultiReportMetaHandler = (
  reports: Array<ReportMetaDataInterface>,
  relationResource: string,
  relationMetaData: GeneralMetaData,
  ignoreMultiResults = false,
): MultiReportMetaDataList | null => {
  const [metaDataList, setMetaDataList] = useState<MultiReportMetaDataList | null>(
    null,
  );

  const isMultiResult = relationMetaData['reportType'] === 'MultiResult';

  const getChildrenMetaData = async () => {
    let _metaDataList: Record<string, GeneralMetaData | null> = {};

    if (isMultiResult) {
      _metaDataList = await getMetaDataFromActorOrNetwork(
        relationResource,
        reports?.map(report => report?.childResource),
      );
    } else {
      for (const report of reports) {
        const { childResource } = report;
        _metaDataList[childResource] = actorGetActionValue('metaData')?.[
          childResource
        ] as GeneralMetaData;

        if (!_metaDataList[childResource]) {
          await getMetaDataFromActorOrNetwork(childResource).then(res => {
            _metaDataList[childResource] = res[childResource];
          });
        }
      }
    }

    setMetaDataList(_metaDataList);
  };

  useEffect(() => {
    if (ignoreMultiResults && isMultiResult) return;

    getChildrenMetaData();
  }, []);

  return metaDataList;
};

export default useMultiReportMetaHandler;
