import './jalali-date-input.style.css';
import { useEffect, useRef } from 'react';
import { ReactElement } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useStyles } from './jalali-date-input.style';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import DatePicker from '../../date-picker';

import type { FieldType } from '../../../helper/Types';
// TODO: fix type DatePicker2-samian

const JalaliDateInputWMSView = (props: any): ReactElement => {
  const {
    visibleClass,
    resource,
    disabled,
    inputMessage,
    DateInputInPuzzleForm,
    label,
    inputFormat,
    handleChange,
    internalValue,
    calendarConfig,
    options,
    onFocus,
    onBlur,
    datePickerRef,
    simpleType,
    field,
  } = props;

  const locale = useLocale();
  const translate = useTranslate();
  const classes = useStyles({ disabled, messageType: inputMessage?.messageType });
  const showLabelRef = useRef<boolean>(false);

  useEffect(() => {
    const datePickerInternalInput = datePickerRef.current.input as HTMLInputElement;

    if (disabled && isEmptyObject(internalValue)) {
      datePickerInternalInput.placeholder = !isEmpty(label) ? label : '';
      showLabelRef.current = false;
    } else {
      datePickerInternalInput.placeholder = '';
      showLabelRef.current = true;
    }
  }, [internalValue]);

  return (
    <fieldset
      data-test-field-name={resource}
      className={`${visibleClass} datePickerContainer`}
      data-error={inputMessage?.messageType === 'error'}
      data-label={isEmpty(label)}
      data-puzzle-form={!!DateInputInPuzzleForm}
      disabled={disabled}
      style={{ width: `${(field as FieldType).widthPercent ?? 100}%` }}
    >
      {showLabelRef.current && !isEmpty(label) && (
        <legend
          className={`${classes.legend} ${
            inputMessage?.message ? classes.legendError : ''
          }`}
        >
          {label}
          {field.required ? '*' : ''}
        </legend>
      )}
      <DatePicker
        {...options}
        ref={datePickerRef}
        inputFormat={inputFormat}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={internalValue}
        disabled={disabled}
        isGregorian={calendarConfig === 'gregorian'}
        timePicker={simpleType === 'datetime'}
        showTodayButton={false}
        setTodayOnBlur={false}
        locale={locale}
      />
      {!!inputMessage?.message && (
        <FormHelperText error className={classes.error}>
          {translate(inputMessage.message)}
        </FormHelperText>
      )}
    </fieldset>
  );
};

export default JalaliDateInputWMSView;
