import lodashGet from 'lodash/get';
import { convert } from '@americanexpress/css-to-js';

import { RUN_VALIDATION } from '../core/data-Provider.helper';
import { getDropDownListFromState, getProcessTaskInfo } from './MetaHelper';
import { isEmpty, isEmptyObject, objectToLowerCaseProperties } from './data-helper';
import {
  FieldAdditionalDataInterface,
  FieldType,
  ValidationErrorMessageType,
} from './Types';
import { actorGetActionValue, ResourceInterface } from '../type/actor-setup';

import { addCommas } from '@persian-tools/persian-tools';
import { parseJSON } from '../core/configProvider';
import dataProvider from '../core/dataProvider';

// TODO: remove individual exports and just use these items in `inputTypes` object
export const URL_FIELD = 'URL_FIELD';
export const RESOURCE_LINK_FIELD = 'RESOURCE_LINK_FIELD';
export const POLYGON_FIELD = 'POLYGON_FIELD';
export const LOCATION_FIELD = 'LOCATION_FIELD';
export const LINK_FIELD = 'LINK_FIELD';
export const COMPUTED_FIELD = 'COMPUTED_FIELD';
export const CALCULATED_FIELD = 'CALCULATED_FIELD';
export const DROPDOWN_FIELD = 'DROPDOWN_FIELD';
export const SEARCH_FIELD = 'SEARCH_FIELD';
export const TAG_FIELD = 'TAG_FIELD';
export const BOOLEAN_FIELD = 'BOOLEAN_FIELD';
export const NUMBER_FIELD = 'NUMBER_FIELD';
export const DATE_FIELD = 'DATE_FIELD';
export const DATETIME_FIELD = 'DATETIME_FIELD';
export const LONG_TEXT_FIELD = 'LONG_TEXT_FIELD';
export const TEXT_FIELD = 'TEXT_FIELD';
export const CODING_FIELD = 'CODING_FIELD';
export const DECIMAL_FIELD = 'DECIMAL_FIELD';
export const FILE_FIELD = 'FILE_FIELD';
export const STRING_SELECT_FIELD = 'STRING_SELECT_FIELD';
export const COLOR_SELECT_FIELD = 'COLOR_SELECT_FIELD';
export const STRING_MULTI_SELECT_FIELD = 'STRING_MULTI_SELECT_FIELD';
export const DROP_BASE_MULTI_SELECT_FIELD = 'DROP_BASE_MULTI_SELECT_FIELD';
export const TIME_FIELD = 'TIME_FIELD';
export const TIMER_FIELD = 'TIMER_FIELD';
export const TYPE_DROPDOWN = 'dropdown';
export const TYPE_SEARCH_INPUT = 'searchDialog';
export const TYPE_CODING = 'coding';
export const COLOR_FIELD = 'COLOR_FIELD';
export const ICON_FIELD = 'ICON_FIELD';
export const TABLE_QUICK_ACCESS_FIELD = 'TABLE_QUICK_ACCESS_FIELD';
export const RICH_TEXT_EDITOR_FIELD = 'RICH_TEXT_EDITOR_FIELD';
export const DELEGATION_FIELD = 'DELEGATION_FIELD';
export const FILE_STREAM_FIELD = 'FILE_STREAM_FIELD';
export const MULTI_FILE_STREAM_FIELD = 'MULTI_FILE_STREAM_FIELD';
export const PARENT_FIELD = 'PARENT_FIELD';
export const FILE_DOWNLOAD_ICON = 'FILE_DOWNLOAD_ICON';
export const COLOR_PICKER_FIELD = 'COLOR_PICKER_FIELD';
export const BUTTON_FIELD = 'BUTTON_FIELD';
export const GADGET_FIELD = 'GADGET_FIELD';
export const MANUAL_ID = 'MANUAL_ID';

export const inputTypes = {
  URL_FIELD,
  RESOURCE_LINK_FIELD,
  POLYGON_FIELD,
  LOCATION_FIELD,
  COMPUTED_FIELD,
  CALCULATED_FIELD,
  DROPDOWN_FIELD,
  SEARCH_FIELD,
  TAG_FIELD,
  BOOLEAN_FIELD,
  NUMBER_FIELD,
  DATE_FIELD,
  DATETIME_FIELD,
  LONG_TEXT_FIELD,
  TEXT_FIELD,
  CODING_FIELD,
  DECIMAL_FIELD,
  FILE_FIELD,
  STRING_SELECT_FIELD,
  STRING_MULTI_SELECT_FIELD,
  DROP_BASE_MULTI_SELECT_FIELD,
  TIME_FIELD,
  TIMER_FIELD,
  TYPE_DROPDOWN,
  TYPE_SEARCH_INPUT,
  TYPE_CODING,
  COLOR_FIELD,
  ICON_FIELD,
  RICH_TEXT_EDITOR_FIELD,
  DELEGATION_FIELD,
  FILE_STREAM_FIELD,
  MULTI_FILE_STREAM_FIELD,
  COLOR_PICKER_FIELD,
  BUTTON_FIELD,
  GADGET_FIELD,
  MANUAL_ID,
};

/**
 * Find minimum field width or return a static value if it is a dropdown
 * @function getInputWidth
 * @param  {object} field
 * @returns {number}
 */
export const getInputWidth = (field?: {
  dropdown: object | null;
  width: number;
}): number => {
  if (!field) {
    return 150;
  }
  const { dropdown, width } = field;
  const minInputWidth = dropdown ? 250 : 150;

  return width <= minInputWidth ? minInputWidth : width;
};

/**
 * Find current level length based on provided pattern
 * @function getCodingPattern
 * @param {string} codingPattern A pattern separated by # like 'x#xx#xxxx'
 * @param {number} currentLevel A number which express the current level of coding input
 * @returns {undefined | number}
 */
export const getCodingPattern = (
  codingPattern: string,
  currentLevel: number,
): undefined | number => {
  const regExPattern = RegExp(/^\d+$/);
  if (!codingPattern || !regExPattern.exec(`${currentLevel}`)!.length) {
    return;
  }
  const splitted = codingPattern.split('#');
  return splitted[currentLevel].length;
};

/**
 * Find field type based on its data type
 * @function getTypeByField
 * @param {FieldType} field
 * @returns {string}
 */

export const simpleFieldType = {
  file: FILE_FIELD,
  url: URL_FIELD,
  boolean: BOOLEAN_FIELD,
  number: NUMBER_FIELD,
  datetime: DATETIME_FIELD,
  date: DATE_FIELD,
  html: RICH_TEXT_EDITOR_FIELD,
  file_download_icon: FILE_DOWNLOAD_ICON,
  geography: LOCATION_FIELD,
  link: LINK_FIELD,
};

const erpFieldType = {
  delegationDropdown: DELEGATION_FIELD,
  fileStream: FILE_STREAM_FIELD,
  multiFileStream: MULTI_FILE_STREAM_FIELD,
  coding: CODING_FIELD,
  polygon: POLYGON_FIELD,
  location: LOCATION_FIELD,
  computed: COMPUTED_FIELD,
  dropdown: DROPDOWN_FIELD,
  parentField: PARENT_FIELD,
  stringSingleSelect: STRING_SELECT_FIELD,
  colorSelectField: COLOR_SELECT_FIELD,
  stringMultiSelect: STRING_MULTI_SELECT_FIELD,
  stringMultiSelectDropBase: DROP_BASE_MULTI_SELECT_FIELD,
  searchDialog: SEARCH_FIELD,
  tag: TAG_FIELD,
  decimal: DECIMAL_FIELD,
  currency: DECIMAL_FIELD,
  time: TIME_FIELD,
  timer: TIMER_FIELD,
  button: BUTTON_FIELD,
  color: COLOR_FIELD,
  icon: ICON_FIELD,
  tableQuickAccess: TABLE_QUICK_ACCESS_FIELD,
  geography: LOCATION_FIELD,
  manualId: MANUAL_ID,
  colorPicker: {
    default: COLOR_PICKER_FIELD,
    ...simpleFieldType,
  },
  calculated: {
    default: TEXT_FIELD,
    ...simpleFieldType,
  },
};

// FIXME: Check if the `field` can be `undefined` here or not
export const getTypeByField = (field?: Partial<FieldType>): string => {
  if (field == null) return '';

  const simpleType = lodashGet(field, 'dataType.simple');
  const erpType = lodashGet(field, 'dataType.erp');

  if (field?.name?.toString()?.includes('gadgetdesign')) {
    // TODO: handle whenever API support it
    return GADGET_FIELD;
  }
  if (simpleType === 'multiFileStream') {
    return MULTI_FILE_STREAM_FIELD;
  }

  if (field.resource && field.resourceType) {
    return RESOURCE_LINK_FIELD;
  }

  if (erpType === 'colorPicker' || erpType === 'calculated') {
    //prettier-ignore
    return erpFieldType[erpType][simpleType] ?? erpFieldType[erpType].default;
  }

  if (field.numberOfLines && field.numberOfLines > 1) {
    return LONG_TEXT_FIELD;
  }

  return erpFieldType[erpType] ?? simpleFieldType[simpleType] ?? TEXT_FIELD;
};

/**
 * change form values base of service response
 * @function changeFormValuesBaseOfServiceResponse
 * @param { Record<string,unknown> } data
 * @param { number } rowNumber
 * @param { string } relationPath
 * @param { function } changeFormValue
 * @returns { void }
 */
const changeFormValuesBaseOfServiceResponse = (
  data,
  rowNumber,
  relationPath,
  changeFormValue,
) => {
  if (!isEmptyObject(data)) {
    Object.keys(data).forEach(name => {
      if (!isEmpty(rowNumber)) {
        changeFormValue({
          fieldName: `${relationPath}.${rowNumber}.${name.toLowerCase()}`,
          value: data[name],
        });
      } else {
        changeFormValue({ fieldName: name.toLowerCase(), value: data[name] });
      }
    });
  }
};

/**
 * get message type from request response
 * @function getMessageTypeFromResponse
 * @param {Record<string,unknown>} response
 * @returns {ValidationErrorMessageType} messageType
 */
const getMessageTypeFromResponse = (response: any): ValidationErrorMessageType => {
  if (!response?.data?.messageType) {
    return 'error';
  }

  switch (response.data.messageType) {
    case 'warning': {
      return 'warning';
    }
    case 'info': {
      return 'info';
    }
    case 'success': {
      return 'success';
    }

    default: {
      return 'error';
    }
  }
};

/**
 * prepare data and param and run service validation
 * @param {Number} fieldId
 * @param {String} resource
 * @param {Object} validationInfo
 * @param {Function} changeFormValue
 * @param {String} source
 * @param { Function | undefined } successRunValidationCallback
 * @returns {Function}
 */
export const runAsyncValidation =
  (
    fieldName: string,
    resource: string,
    validationInfo: {
      parameters: { field: Partial<FieldType> }[];
      uniqueId: string;
    },
    changeFormValue: Function,
    source: string,
    successRunValidationCallback: Function | undefined = undefined,
  ) =>
  async (
    currentValue: unknown,
    formData: Record<string, unknown>,
  ): Promise<{
    message: string;
    messageType: ValidationErrorMessageType;
  } | void> => {
    const [relationPath, rowNumber] = source.split('.');

    try {
      const { status, data, userMessage } = await dataProvider(
        RUN_VALIDATION,
        resource,
        {
          fieldName,
          data: {
            params: formData,
            items: [],
          },
        },
      );

      if (!status) {
        return { message: userMessage, messageType: 'error' };
      }
      const compatibleData = objectToLowerCaseProperties(data);

      changeFormValuesBaseOfServiceResponse(
        compatibleData,
        rowNumber,
        relationPath,
        changeFormValue,
      );

      // In `wms` we want to check `data` to submit the form after validation
      successRunValidationCallback?.(data);
    } catch (error: any) {
      const {
        response,
        shouldParseResponseError,
        shouldParseResponseAdditionalDataError,
        message,
      } = error;

      if (shouldParseResponseError) {
        changeFormValuesBaseOfServiceResponse(
          response.data,
          rowNumber,
          relationPath,
          changeFormValue,
        );
      }

      if (shouldParseResponseAdditionalDataError) {
        const preparedErrorObject = {
          requestId: response.data.requestId,
          data: response.data.additionalData[0].exception.data,
        };
      }

      return {
        message,
        messageType: getMessageTypeFromResponse(response),
      };
    }
  };

/**
 * prepare parameter for service validation with `relatedParameterName`.
 * @param {Array} parameter
 * @param {Object} data
 * @returns {Object}
 */
export const prepareParamForValidation = (
  parameter: { field: Partial<FieldType> }[],
  data: object,
): object => {
  if (!parameter || !parameter.length) {
    return {};
  }

  const tempParam = {};

  // TODO: sometimes we get null field from meta, it should be fixed in meta
  // but now i filter null items for assurance!
  parameter
    .filter(item => item?.['field'] !== null)
    .forEach(item => {
      const { name, id, relatedParameterName, defaultValue } = item.field;
      if (relatedParameterName != null) {
        if ((id as number)! > 0) {
          tempParam[relatedParameterName] = lodashGet(data, name, null);
        } else {
          tempParam[relatedParameterName] = !isEmpty(defaultValue)
            ? defaultValue
            : lodashGet(data, name, null);
        }
      }
    });

  return tempParam;
};
interface ExtraProps {
  globalParameters: object;
  dropdownState: [{ id?: string | number }] | null | any[];
  viewVersion: number;
}
interface StateType {
  admin: {
    ui: { viewVersion: number };
    profile: { globalParameters: object | null };
  };
}
export const mapStateToInputProps = (
  state: StateType,
  props: { field: Partial<FieldType>; metaData?: object },
): ExtraProps => {
  const extraProps: ExtraProps = {
    // fixme: read from actor or remove the file
    globalParameters: lodashGet(state, 'profile.globalParameters', {}),
    dropdownState: [],
    viewVersion: state.admin.ui.viewVersion,
  };

  const { field, metaData } = props;
  if (
    field.uiVisible &&
    field.uiVisible &&
    field.uiVisible.length &&
    field.uiVisible[0].length === 3 &&
    field.uiVisible[0][ui.column] !== ''
  ) {
    extraProps.dropdownState = getDropDownListFromState(
      field,
      'uiVisible',
      state,
      metaData,
    );
  } else if (
    field.uiEnable &&
    field.uiEnable &&
    field.uiEnable.length &&
    field.uiEnable[0].length === 3 &&
    field.uiEnable[0][ui.column] !== ''
  ) {
    extraProps.dropdownState = getDropDownListFromState(
      field,
      'uiEnable',
      state,
      metaData,
    );
  }

  return extraProps;
};

export const dummyFunc = () => {};

export enum ui { // Example: { field.uiVisible : [ ["customer_parentbarcodemain_id", "customer_id", "value=2991942"] ] }
  fieldName,
  column,
  value,
}

/**
 * Check field status (disabled/hidden) in current process to overwrite js formula
 * @param metaData
 * @param field
 * @param record
 * @param ui Calculated ui from formulas
 * @returns { { uiVisibled: boolean; uiEnabled: boolean } }
 */
export const processUiChecks = (
  field: Partial<FieldType>,
  record: Record<string, unknown>,
  resource: ResourceInterface,
  ui: { uiVisibled: boolean; uiEnabled: boolean },
): { uiVisibled: boolean; uiEnabled: boolean } => {
  const metaData = actorGetActionValue('metaData', resource.value)!;
  const { positionid, stateid, __processuniqueid } = record;
  const currentTask = getProcessTaskInfo(
    metaData,
    __processuniqueid,
    positionid,
    stateid,
  );

  const currentField = currentTask?.fields?.[field.id!];

  if (
    currentField &&
    currentField.disabled === true &&
    currentField.hidden === true
  ) {
    return { uiEnabled: !currentField?.disabled, uiVisibled: !currentField?.hidden };
  } else if (currentField && currentField.disabled === true) {
    return { uiEnabled: !currentField?.disabled, uiVisibled: ui.uiVisibled };
  } else if (currentField && currentField.hidden === true)
    return { uiEnabled: ui.uiEnabled, uiVisibled: !currentField?.hidden };
  else {
    return ui;
  }
};

/**
 * convert number computedinput with commas
 * @param simpleType
 * @param value
 * @returns {string}
 */
export const convertNumberWithCommas = (
  simpleType: string,
  value: string,
): string => {
  return simpleType === 'number' && value != null && !isNaN(+value)
    ? addCommas(value)
    : '';
};

/**
 * @function getFieldAdditionalData
 * @param { string } fieldAdditionalData
 * @returns { FieldAdditionalDataInterface } an object\
 *
 * fieldAdditionalData sample: { alt: "test" ,"background-color": "#00FFFF","style": "border: dashed;}
 */
export const getFieldAdditionalData = (
  fieldAdditionalData: string,
): FieldAdditionalDataInterface => {
  const fieldAdditionalDataObject =
    parseJSON<Record<string, unknown>>(fieldAdditionalData);

  let customStyleAdditionalDataField = {};
  let additionalDataFieldAlt = '';

  if (fieldAdditionalDataObject) {
    for (const key in fieldAdditionalDataObject) {
      const _splitKey = key.split('-') as string[];

      if (_splitKey?.length > 1) {
        let _key = _splitKey[0];
        for (const item of _splitKey.splice(1)) {
          _key += item.charAt(0).toUpperCase() + item.slice(1);
        }
        fieldAdditionalDataObject[_key] = fieldAdditionalDataObject[key]; // add 'backgroundColor' key
        delete fieldAdditionalDataObject[key]; // remove 'background-color' key
      }
    }

    customStyleAdditionalDataField = { ...fieldAdditionalDataObject };
    delete customStyleAdditionalDataField['alt'];
    delete customStyleAdditionalDataField['style'];

    if (!isEmptyObject(fieldAdditionalDataObject?.style)) {
      const css = `.fakeClass {${fieldAdditionalDataObject?.style}}`;
      const jsStyle = convert(css);

      customStyleAdditionalDataField = {
        ...customStyleAdditionalDataField,
        ...jsStyle['fakeClass'],
      };
    }

    additionalDataFieldAlt = (fieldAdditionalDataObject?.['alt'] as string) ?? '';
  }

  return {
    additionalDataFieldAlt,
    customStyleAdditionalDataField,
  };
};
