import { saveAs } from 'save-files';
import { checkJsonStringify } from '../../../helper/data-helper';
import { Translate } from '../../../helper/Types';

import type { AttachedFile } from '../../mail-section/mail-detail/mail-detail-body/mail-detail-body.type';
import type { MultiFileStreamItem } from './multi-file-stream-input.type';
import { actorDispatch } from '../../../type/actor-setup';
import { apiUrl } from '../../../core/data-Provider.helper';

/**
 * generate error message
 * @function generateErrorMessage
 * @param {Array} largerThanAllowedFiles
 * @param {Array} apiErrors
 * @param {function} translate
 * @returns {string} errorMessage
 */
export const generateErrorMessage = (
  largerThanAllowedFiles: Array<File>,
  apiErrors: Array<{ fileName: string; message: string }>,
  translate: Translate,
): string => {
  let errorMessage = '';

  if (largerThanAllowedFiles.length) {
    if (largerThanAllowedFiles.length === 1) {
      // has one large file
      errorMessage += `${translate('file.file')} ${
        largerThanAllowedFiles[0].name
      } ${translate('file.couldNotSendFileBecauseItsLargeSize')}`;
    } else {
      // has few large file
      errorMessage += `${translate('file.files')}‍ `;

      largerThanAllowedFiles.forEach((file, index) => {
        errorMessage += file.name;

        // `largerThanAllowedFiles.length - 1` is last item of errors
        if (index !== largerThanAllowedFiles.length - 1) {
          errorMessage += ` ${translate('file.and')}‍ `;
        }

        errorMessage += '\n';
      });

      errorMessage += `${translate('file.couldNotSendFilesBecauseItsLargeSize')}‍`;
    }
  }

  if (apiErrors.length) {
    if (errorMessage) {
      // add some space between errors
      errorMessage += '\n\n';
    }

    apiErrors.forEach(error => {
      const { fileName, message } = error;

      if (message.includes('^')) {
        // its not require to add request id in this case
        const errorArray = message.split('^');
        errorMessage += `${translate('file.file')} ${fileName} : ${errorArray[0]}`;
      } else {
        errorMessage += `${translate('file.file')} ${fileName} : ${message}`;
      }

      errorMessage += '\n';
    });
  }
  return errorMessage;
};

/**
 * @function delay
 * @param milliseconds
 * @returns {Promise<void>}
 */
export const delay = milliseconds =>
  new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });

/**
 * It creates a link to download a file with real and clear name
 * @function downloadAllAttachmentsHandler
 * @param { AttachedFile[] } files
 * @param { string } apiUrl
 * @returns { Promise<void> } function
 */
export const downloadAll = async (
  files: AttachedFile[],
  apiUrl: string,
): Promise<void> => {
  for (const [index, file] of files.entries()) {
    saveAs(`${apiUrl}/${file.filePath}`, file.realFileName);
    await delay(index * 1000);
  }
};

/**
 * @function sanitizeFileStreamValue
 * @param {string} value
 * @returns {MultiFileStreamItem[] | null } - An array of `file-stream` or `null`
 */
export const sanitizeFileStreamValue = (
  value: unknown,
): MultiFileStreamItem[] | null => {
  const checkJsonStringifyResult = checkJsonStringify<MultiFileStreamItem[]>(value);
  if (checkJsonStringifyResult !== false) {
    /**
     * In some cases, we have been sent invalid stringified value to the server
     * (i.e. { 'fieldName': '\'{\\\[\'\f\'\'i\'...\]\\\'}\' })
     * to sanitize those values, we have to do the following:
     */
    const sanitizedValue: MultiFileStreamItem[] = [];
    for (const _value of checkJsonStringifyResult) {
      if (typeof _value !== 'object') continue;
      sanitizedValue.push(_value);
    }

    return sanitizedValue;
  }

  if (Array.isArray(value)) return value as MultiFileStreamItem[];

  return null;
};

/**
 * @function openPreviewImage
 * @param { React.MouseEvent<HTMLButtonElement, MouseEvent>| undefined} event
 * @param {MultiFileStreamItem[]}  items
 * @returns {void} void
 */
export const openPreviewImage =
  (items: MultiFileStreamItem[]) =>
  (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined,
  ) => {
    //dont open add file form
    event?.stopPropagation();

    const images = Array.isArray(items)
      ? items.map(img => {
          return {
            url: `${apiUrl}/${img.filePath}`,
          };
        })
      : [];
    if (items && items.length !== 0) {
      actorDispatch('quickDialog', {
        lightBoxPreviewIsOpen: true,
        data: {
          imageData: images,
          startIndex: 0,
        },
      });
    }
  };
