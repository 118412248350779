import { RUN_SERVICE } from '../../core/data-Provider.helper';
import { showNotification } from '../../helper/general-function-helper';
import { actorDispatch } from '../../type/actor-setup';

import type { ChatUsersInterface } from './chat-dialogs/add-members-dialog/add-members-dialog.type';
import type { ChatItemInterface } from './chat-section.type';

const groupUsersReportId = 'report/45eaee51-4bcf-4314-af28-af31c7e74bfa';
const updateGroupUniqueId = '3e15abf7-14a2-449f-8ec3-6259c89a5369';

/**
 * @function getGroupUsers
 * @param {string} GroupUID
 * @param {(data: ChatUsersInterface[]) => void} handleSuccess
 * @returns {Promise<ChatUsersInterface[] | void>} groupUsers
 */
export const getGroupUsers = async (
  GroupUID: string,
  handleSuccess?: (data: ChatUsersInterface[]) => void,
): Promise<ChatUsersInterface[] | void> => {
  return new Promise((resolve, reject) => {
    actorDispatch('crudAction', {
      type: 'GET_LIST',
      resource: groupUsersReportId,
      requestParameters: {
        filter: [['GroupUID', '=', GroupUID]],
      },
      onSuccess: (response: { data: ChatUsersInterface[] }) => {
        if (!response.data) return;
        handleSuccess?.(response.data);
        resolve(response.data);
      },
      onFailure: () => {
        reject();
      },
    });
  });
};

/**
 * @function updateGroup
 * @param {GroupName: string, MemberPersonInfoIDs: string, IsUseInChat: number, GroupProfileImage: string | null, GroupUID: string, AdminPersonInfo_IDs?: string} params
 * @returns {void} void
 */
export const updateGroup = (
  params: {
    GroupName?: string;
    MemberPersonInfoIDs?: string;
    IsUseInChat?: number;
    GroupProfileImage: string | null;
    GroupUID: string;
    IsChannel?: number;
    AdminPersonInfo_IDs?: string;
  },
  handleSuccess?: (response: ChatItemInterface) => void,
  handleFailure?: () => void,
): void => {
  actorDispatch(
    'crudAction',
    {
      type: RUN_SERVICE,
      actionUniqueId: updateGroupUniqueId,
      data: {
        params,
      },
      onSuccess: (response: Record<string, unknown>) => {
        showNotification(response.userMessage, 'success', {
          fromQuickCreateDialog: true,
        });

        actorDispatch('refreshView', 'chat');
        handleSuccess?.(response.data as ChatItemInterface);
      },
      onFailure: error => {
        handleFailure?.();

        if (typeof error === 'string') {
          showNotification(error, 'error');
        }
      },
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'updateGroup',
    },
  );
};

/**
 * @function prepareSelectedUsersBeforeCreateGroup
 * @param {ChatUsersInterface[]} selectedUsers
 * @returns {string} string
 */
export const prepareSelectedUsersBeforeCreateGroup = (
  selectedUsers: ChatUsersInterface[],
): string => {
  let selectedUsersIds = '';
  for (const user of selectedUsers) {
    selectedUsersIds += `${user.personinfo_id},`;
  }
  return selectedUsersIds;
};
