import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import moment from 'moment-jalaali';

import { getValue, CONFIG_CALENDAR } from '../core/configProvider';

const JALAALI_FORMAT = 'jYYYY-jMM-jDD HH:mm';
const GREGORIAN_FORMAT = 'YYYY-MM-DD HH:mm';

const styles = {
  noteTime: {
    fontSize: 12,
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.54)',
  },
};

// TODO: refactor to (Typescript) and (Functional)
class DisplayDate extends PureComponent {
  render() {
    const currentCalendar = getValue(CONFIG_CALENDAR);
    const { classes, value } = this.props;
    if (currentCalendar === 'jalali') {
      // const options = { usePersianDigits: true, dialect: 'persian-modern' };
      // moment.loadPersian(options);
      return (
        <div className={classes.noteTime} data-test="jalaliDate">
          {moment(value).format(JALAALI_FORMAT)}
        </div>
      );
    } else {
      return (
        <div className={classes.noteTime} data-test="gregorianDate">
          {moment(value).format(GREGORIAN_FORMAT)}
        </div>
      );
    }
  }
}

DisplayDate.propTypes = {
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(DisplayDate);
