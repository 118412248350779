// FIXME : https://jira.samiansoft.com/browse/RCT-3092

import {
  FC,
  useEffect,
  useCallback,
  memo,
  ChangeEvent,
  ReactNode,
  MouseEvent,
} from 'react';
import { useTranslate } from 'react-admin';
import TablePagination, {
  LabelDisplayedRowsArgs,
} from '@material-ui/core/TablePagination';
import { sanitizeListRestProps } from 'ra-core';
import { makeStyles } from '@material-ui/core/styles';

import linebarLoader from '../images/linebarLoader.gif';

const emptyArray = [];

const useStyles = makeStyles(() => ({
  tablePaginationSmall: {
    color: 'rgba(0, 0, 0, 0.54)',
    height: 30,
    padding: 0,
    '& div': {
      height: 'auto',
      minHeight: 'auto',
    },
    '& button': {
      padding: 0,
    },
    '& p': {
      fontSize: 12,
    },
  },

  tablePaginationMedium: {
    overflow: 'unset',
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    height: 40,
    '& div': {
      height: 'auto',
      minHeight: 'auto',
    },
    '& p': {
      fontSize: 12,
    },
  },
}));

interface PaginationType {
  total: number;
  perPage: number;
  page: number;
  setPage: Function;
  setPerPage: Function;
  isLoading?: boolean;
  rowsPerPageOptions?: number[];
  isCompactMode?: boolean;
  isRelation?: boolean;
}

const Pagination: FC<PaginationType> = props => {
  const {
    total,
    perPage,
    page,
    setPage,
    setPerPage,
    isLoading = false,
    rowsPerPageOptions = [10, 25, 100, 1000],
    isCompactMode,
    isRelation,
    ...rest
  } = props;

  const translate = useTranslate();
  const classes = useStyles(props);

  /**
   * Calculate how many page exist
   * @function getNbPages
   * @returns {number}
   */
  const getNbPages = useCallback(
    (): number => Math.ceil(total / perPage) || 1,
    [total, perPage],
  );

  /**
   * `Warning`: material-ui's page is 0-based
   * @function handlePageChange
   * @param {Event} event
   * @param {number} page page number
   * @returns {void}
   */
  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => {
    event && event.stopPropagation();
    if (page < 0 || page > getNbPages() - 1) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', {
          page: page + 1,
        }),
      );
    }
    setPage(page + 1);
  };

  /**
   * Update how many rows are in each page
   * @function handlePerPageChange
   * @param {Event} event
   * @returns {void}
   */
  const handlePerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    if (event && event.target) {
      if (!isRelation) {
        setPage(1);
      }
      setPerPage(event.target.value);
    }
  };

  /**
   * prepare pagination labels
   * @function labelDisplayedRows
   * @param {object} param
   * @returns {object}
   */
  const labelDisplayedRows = ({ from, to }: LabelDisplayedRowsArgs): ReactNode => {
    return isLoading ? (
      <img src={linebarLoader} alt="loading..." />
    ) : (
      translate('ra.navigation.page_range_info', {
        offsetBegin: from,
        offsetEnd: to,
      })
    );
  };

  useEffect(() => {
    if (page < 1 || isNaN(page)) {
      setPage(1);
    }
  }, [page]);

  if (!isLoading && total === 0) {
    return null;
  }

  if (isCompactMode) {
    return (
      <TablePagination
        id="tablePagination"
        className={classes.tablePaginationSmall}
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={handlePageChange}
        rowsPerPageOptions={emptyArray}
        component="span"
        labelDisplayedRows={labelDisplayedRows}
        SelectProps={{ inputProps: { disabled: isLoading } }}
        {...sanitizeListRestProps(rest)}
      />
    );
  }
  // has been changed in commit: f6c770fcd
  return (
    <TablePagination
      id="tablePagination"
      className={classes.tablePaginationSmall}
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      onPageChange={handlePageChange}
      rowsPerPageOptions={rowsPerPageOptions}
      component="span"
      labelDisplayedRows={labelDisplayedRows}
      onChangeRowsPerPage={handlePerPageChange}
      SelectProps={{ inputProps: { disabled: isLoading } }}
      labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
      data-test-total-items={total}
      data-style-pagination="pagination"
      {...sanitizeListRestProps(rest)}
    />
  );
};
export default memo(Pagination);
