import { useCallback, useEffect, useRef, memo, useState, type FC } from 'react';
import { useTranslate } from 'react-admin';

import { actorDispatch } from '../../../../type/actor-setup';
import SendLocationDialogView from './send-location-dialog.view';
import { showNotification } from '../../../../helper/general-function-helper';
import { centerLocation } from '../../../dynamic-input/location-input/location-input.helper';
import { fileUploadResource } from '../../chat-section.helper';
import { base64ToBlob } from '../../../../helper/FileHelper';
import {
  findLocationOptions,
  getSnapShotOfElement,
} from './send-location-dialog.helper';

import type { LocationInterface } from '../../../dynamic-input/location-input/location-input.type';
import type {
  GeolocationStatus,
  OnFindLocationSuccessParamsInterface,
  SendLocationDialogControllerProps,
} from './send-location-dialog.type';
import { UploadedFileInterface } from '../..';

const SendLocationDialogController: FC<SendLocationDialogControllerProps> = memo(
  props => {
    const { dialogData } = props;
    const translate = useTranslate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentLocation, setCurrentLocation] = useState<LocationInterface | null>(
      null,
    );
    const [geolocationStatus, setGeolocationStatus] = useState<GeolocationStatus>({
      isFinding: true,
      errorMessage: null,
    });

    const selectedLocationRef = useRef<LocationInterface>(centerLocation);

    /**
     * update `selectedLocationRef` ref
     * @function onMoveMarker
     * @param {LocationInterface} newMarkerLocation
     * @returns {void} void
     */
    const onMoveMarker = useCallback(
      (newMarkerLocation: LocationInterface): void => {
        selectedLocationRef.current = newMarkerLocation;
      },
      [],
    );

    /**
     * update `setCurrentLocation` state and `selectedLocationRef` ref
     * @function onFindLocationSuccess
     * @param {{
     *   coords: {
     *     latitude: number;
     *     longitude: number;
     *   }
     * }}
     * @returns {void} void
     */
    const onFindLocationSuccess: OnFindLocationSuccessParamsInterface = position => {
      const { latitude, longitude } = position.coords;

      setCurrentLocation({ lat: latitude, lng: longitude });
      selectedLocationRef.current = { lat: latitude, lng: longitude };
    };

    /**
     * update `setCurrentLocation` and `setGeolocationStatus` state
     * and show error notification
     * @function onFindLocationFailure
     * @returns {void} void
     */
    const onFindLocationFailure = useCallback((): void => {
      setCurrentLocation(centerLocation);

      showNotification(translate('chat.findLocationFailed'), 'error');

      setGeolocationStatus({
        isFinding: false,
        errorMessage: translate('chat.chooseLocationManually'),
      });
    }, []);

    /**
     * handle geolocation permission in users browser
     * get user's current location and set it on state
     * or show error with notification if user denies location access
     * @function getUserLocation
     * @returns {void} void
     */
    const getUserLocation = useCallback((): void => {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(
            onFindLocationSuccess,
            onFindLocationFailure,
            findLocationOptions,
          );
        } else if (result.state === 'prompt') {
          showNotification(translate('chat.needLocationPermission'), 'warning');

          navigator.geolocation.getCurrentPosition(
            onFindLocationSuccess,
            onFindLocationFailure,
            findLocationOptions,
          );
        } else if (result.state === 'denied') {
          setGeolocationStatus({
            isFinding: false,
            errorMessage: translate('chat.chooseLocationManually'),
          });

          setCurrentLocation(centerLocation);
        }
      });
    }, []);

    useEffect(() => {
      if (navigator.geolocation) {
        // in this case user's browser support geolocation
        getUserLocation();
      } else {
        // in this case user's browser dose't support geolocation
        setGeolocationStatus({
          isFinding: false,
          errorMessage: translate('chat.chooseLocationManually'),
        });

        setCurrentLocation(centerLocation);
      }
    }, []);

    /**
     * get snapshot of map
     * send it to server
     * call `onSelectLocation` function with selected location and screenshot url
     * @function onConfirmButtonClick
     * @returns {void} void
     */
    const onConfirmButtonClick = useCallback((): void => {
      const { onSelectLocation } = dialogData;
      setIsLoading(true);

      getSnapShotOfElement('map_container')
        .then(imageWithBase64Format => {
          const fileBlob = base64ToBlob(imageWithBase64Format);
          if (!fileBlob) {
            console.warn(
              'SendLocationDialogController => onConfirmButtonClick: Invalid file data ',
              { imageWithBase64Format, base64ToBlobResult: fileBlob },
            );
            return;
          }

          actorDispatch('uploadStreamFile', {
            successCallback: (response: UploadedFileInterface): void => {
              setIsLoading(false);
              onSelectLocation(selectedLocationRef.current, response.filePath);
              actorDispatch('closeDialogs', true);
            },

            param: {
              resource: fileUploadResource,
              file: fileBlob,
            },
          });
        })
        .catch(error => {
          console.error('getSnapShotOfMap error: ', error);
          setIsLoading(false);
          showNotification(translate('chat.errorInSavingLocation'), 'error');
        });
    }, []);

    /**
     * close location dialog
     * @function onCancelButtonClick
     * @returns {void} void
     */
    const onCancelButtonClick = useCallback((): void => {
      actorDispatch('closeDialogs', true);
    }, []);

    return (
      <SendLocationDialogView
        onConfirmButtonClick={onConfirmButtonClick}
        onCancelButtonClick={onCancelButtonClick}
        onMoveMarker={onMoveMarker}
        selectedLocationRef={selectedLocationRef}
        currentLocation={currentLocation}
        geolocationStatus={geolocationStatus}
        isLoading={isLoading}
      />
    );
  },
);

export default SendLocationDialogController;
