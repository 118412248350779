import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  itemContainer: {
    width: '100%',
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    padding: 11,
    '&:hover': {
      background: theme.palette.todoHoveredItem,
    },
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexGrow: 1,
  },
  titleAndValue: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 10px 0 10px',
    fontWeight: 400,
    color: theme.palette.todoLightGray,
    fontSize: 14,
  },
  todoItemText: {
    fontSize: '1rem',
    fontWeight: 400,
  },
}));
