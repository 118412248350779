import { useEffect, useState, type FC } from 'react';

import { actorDispatch } from '../../type/actor-setup';
import ChangeZoomScaleView from './change-zoom-scale.view';

const ChangeZoomScaleController: FC = () => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    actorDispatch('changeScreenZoom', scale);
    //@ts-ignore
    document.body.style.zoom = scale;
  }, [scale]);

  const onZoomIn = () => {
    setScale(prev => prev + 0.1);
  };
  const onZoomOut = () => {
    setScale(prev => prev - 0.1);
  };
  const defaultZoom = () => {
    setScale(1);
  };

  return (
    <ChangeZoomScaleView
      onZoomIn={onZoomIn}
      onZoomOut={onZoomOut}
      defaultZoom={defaultZoom}
      scale={scale}
    />
  );
};

export default ChangeZoomScaleController;
