import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import lodashDebounce from 'lodash/debounce';

import { clone, isEmpty, isEmptyObject } from '../../../helper/data-helper';
import {
  SearchModeInChatType,
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
} from '../../../type/actor-setup';
import {
  contactsReportId,
  searchInMessagesReportId,
  setClientContacts,
  requestUsersStatus,
  ONLINE_STATUS_REFRESH_RATE,
  getChats,
} from '../chat-section.helper';
import ChatSidebarView from './chat-sidebar.view';
import { showNotification } from '../../../helper/general-function-helper';
import { chatStatelessActions } from '../chat-section-helper/chat-section-stateless-actions';
import { getAllCommutatingUsers, getCommutatingUsers } from './chat-sidebar.helper';
import {
  chatSidebarInitialState,
  chatSidebarReducer,
} from './chat-sidebar-helper/chat-sidebar-reducer';
import { actorRemoveAction } from '../../../type/actor-setup';

import type { ChatItemInterface, SelectedChatType } from '../chat-section.type';

const ChatSidebarController: FC = memo(() => {
  const [state, dispatch] = useReducer(chatSidebarReducer, chatSidebarInitialState);

  const {
    chatsData,
    loading,
    searchValue,
    selectedUser,
    contactsFoundBySearch,
    messagesFoundBySearch,
  } = state;

  const originalChatRef = useRef<ChatItemInterface[]>([]);
  const selectedGroupUserIds = useRef<string[]>([]);
  const searchModeInChatRef = useRef<SearchModeInChatType>('oneByOne');
  const lastContactSearchRef = useRef<string>('');
  const loadingStateInSearchRef = useRef<{
    searchInContactsDone: boolean;
    searchInMessagesDone: boolean;
  }>({
    searchInContactsDone: false,
    searchInMessagesDone: false,
  });
  const shouldUpdateChatListAfterSelectingAChatRef = useRef<boolean>(false);
  const searchInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const onDispatches: { actionName: keyof ActorActionList; id: symbol }[] = [];

    let id = actorOnDispatch('chatList', chatList => {
      const chats: ChatItemInterface[] = [];
      const localUpdatedChats: ChatItemInterface[] = [];

      const chatsKeys = Object.keys(chatList.data);
      const chatsLength = chatsKeys.length;
      for (let index = 0; index < chatsLength; index++) {
        const chat = chatList.data[chatsKeys[index]];
        /**
         * When we have an `onDemand` selected chat, based on given data from the `api`,
         * we have set an empty string or 'now' in that `chatdate`
         */
        if (chat.info.chatdate === 'now' || chat.info.chatdate === '') {
          localUpdatedChats.push(chat.info);
          continue;
        }

        chats.push(chatList.data[chatsKeys[index]].info);
      }

      const sortedDescByDateChats = chats.sort((a, b) => {
        const unixTimestampA = Math.floor(new Date(a.chatdate).getTime() / 1000);
        const unixTimestampB = Math.floor(new Date(b.chatdate).getTime() / 1000);
        return unixTimestampB - unixTimestampA;
      });

      sortedDescByDateChats.unshift(
        ...localUpdatedChats.sort((a, b) => +a.chatid! - +b.chatid!),
      );
      originalChatRef.current = [...sortedDescByDateChats];

      if (!isEmpty(lastContactSearchRef.current)) {
        searchContacts(lastContactSearchRef.current);
      }

      dispatch({
        type: 'setChatsData',
        payload: {
          list: sortedDescByDateChats,
          hasMore: chatList.totalCount > 10 && chatsLength < chatList.totalCount,
        },
      });

      dispatch({
        type: 'setLoading',
        payload: false,
      });
    });

    id = actorOnDispatch('chatSearchDetails', _selectedUser => {
      searchInputRef.current?.focus();

      dispatch({
        type: 'setChatsData',
        payload: {
          list: [_selectedUser.selectedUser as ChatItemInterface],
          hasMore: false,
        },
      });

      if (_selectedUser.searchModeInChat === 'allUser') {
        dispatch({
          type: 'setChatsData',
          payload: {
            list: originalChatRef.current,
            hasMore: false,
          },
        });
      }

      searchModeInChatRef.current = _selectedUser.searchModeInChat!;
    });

    onDispatches.push({
      id,
      actionName: 'chatSearchDetails',
    });

    id = actorOnDispatch(
      'onDemandSelectedChat',
      chat => {
        const chatList = actorGetActionValue('chatList')!;
        if (chatList.data[chat.info.personinfo_id] != null) {
          onChatSelect(chatList.data[chat.info.personinfo_id].info);
          return;
        }

        const newChat: ChatItemInterface = {
          chatdate: chat.info.chatdate ?? 'now',
          chattext: '',
          ischannel: chat.info.ischannel,
          isgroupadmin: chat.info.isgroupadmin,
          isowner: chat.info.isowner,
          personimage: chat.info.personimage,
          personname: chat.info.personname,
          personinfo_id: chat.info.personinfo_id ?? -1,
          sumnotseen: chat.info.sumnotseen,
          mentionchatid: -1, // FIXME: What is and what the value it should have?
          groupuid: chat.info.groupuid,
          frompersoninfoname: chat.info.frompersoninfoname,
        };

        shouldUpdateChatListAfterSelectingAChatRef.current = true;
        onChatSelect(newChat);
      },
      {
        preserve: false,
      },
    );

    onDispatches.push({
      id,
      actionName: 'onDemandSelectedChat',
    });

    actorOnDispatch('selectedChat', selectedChat => {
      dispatch({
        type: 'setSelectedUser',
        payload: {
          chat: selectedChat?.info ?? null,
          shouldUpdateChatsData: shouldUpdateChatListAfterSelectingAChatRef.current,
        },
      });
    });

    id = actorOnDispatch(
      'isChatDrawerOpen',
      isChatDrawerOpen => {
        if (!isChatDrawerOpen) return;

        dispatch({
          type: 'setLoading',
          payload: true,
        });

        getChats({
          shouldLoading: false,
          page: 1,
        });
      },
      {
        preserve: false,
      },
    );

    onDispatches.push({
      id,
      actionName: 'isChatDrawerOpen',
    });

    return () => {
      for (const { actionName } of onDispatches) {
        actorRemoveAction({
          prune: true,
          actionName,
        });
      }
    };
  }, []);

  useEffect(() => {
    const allCommutatingUsers = getAllCommutatingUsers(chatsData.list);

    requestUsersStatus(allCommutatingUsers); // this is for first load

    const interval = setInterval(() => {
      requestUsersStatus(allCommutatingUsers); // this is for get direct user

      if (selectedGroupUserIds.current.length) {
        requestUsersStatus(selectedGroupUserIds.current); // this is for get group members
      }
    }, ONLINE_STATUS_REFRESH_RATE);

    return () => {
      interval && clearInterval(interval);
    };
  }, [chatsData]);

  useEffect(() => {
    loadingStateInSearchRef.current = {
      searchInContactsDone: false,
      searchInMessagesDone: false,
    };
  }, [loading]);

  useEffect(() => {
    if (selectedUser == null) return;

    if (actorGetActionValue('selectedChat')?.messagesDetail != null) {
      shouldUpdateChatListAfterSelectingAChatRef.current = false;
    }
  }, [selectedUser]);

  /**
   * setClientContacts and fill or remove interval reference variable
   * @function refreshContactListInWebSocket
   * @param {SelectedChatType} chat
   * @function {Promise<void>}
   */
  const refreshContactListInWebSocket = async (
    chat: SelectedChatType,
  ): Promise<void> => {
    const commutatingUsers = await getCommutatingUsers(chat);

    actorSetActionValue(
      'selectedChat',
      {
        chatContacts: commutatingUsers,
      },
      {
        callerScopeName: 'refreshContactListInWebSocket',
      },
    );

    setClientContacts(commutatingUsers);

    if (isEmpty(chat.groupuid)) {
      // in this case we are in chat with a single person and shouldn't request any group persons online status
      // so clear the interval reference variable
      selectedGroupUserIds.current = [];
    } else {
      // here we are in chat with a group, so should request users online status and also save group user id's in
      // a variable to request it in an interval
      requestUsersStatus(selectedGroupUserIds.current);
      selectedGroupUserIds.current = commutatingUsers;
    }
  };

  /**
   * @function onUserSelect
   * @returns { void }
   */
  const onChatSelect = useCallback(
    (chat: ChatItemInterface | undefined | null): void => {
      if (chat == null) return;

      refreshContactListInWebSocket(chat);

      actorDispatch(
        'selectedChat',
        {
          info: clone(chat),
        },
        {
          replaceAll: true,
          callerScopeName: 'onChatSelect',
        },
      );

      chatStatelessActions.selectUser({
        chat,
      });
    },
    [],
  );

  /**
   * @function onFetchMoreChats
   * @returns { void }
   */
  const onFetchMoreChats = useCallback((): void => {
    const { dispatch } = actorGetActionValue('chatReducer')!;
    dispatch({
      type: 'fetchMoreChats',
    });
  }, []);

  /**
   * @function successSearchContactsCallback
   * @param { ContactsInterface[] } data
   * @returns { void }
   */
  const successSearchContactsCallback = useCallback(({ data }): void => {
    loadingStateInSearchRef.current.searchInContactsDone = true;

    if (loadingStateInSearchRef.current.searchInMessagesDone) {
      dispatch({
        type: 'setLoading',
        payload: false,
      });
    }

    data &&
      dispatch({
        type: 'setContactsFoundBySearch',
        payload: data,
      });
  }, []);

  /**
   * @function successSearchInMessagesCallback
   * @param { ContactsInterface[] } data
   * @returns { void }
   */
  const successSearchInMessagesCallback = useCallback(({ data }): void => {
    loadingStateInSearchRef.current.searchInMessagesDone = true;

    if (loadingStateInSearchRef.current.searchInContactsDone) {
      dispatch({
        type: 'setLoading',
        payload: false,
      });
    }

    data &&
      dispatch({
        type: 'setMessagesFoundBySearch',
        payload: data,
      });
  }, []);

  /**
   * @function failureCallback
   * @param error
   * @returns { void }
   */
  const failureCallback = useCallback((error: unknown): void => {
    showNotification(error, 'error');
  }, []);

  /**
   * to search in contacts
   * @function searchContacts
   * @param { Function } setLoading
   * @returns { void }
   */
  const searchContacts = useCallback((ddsearchcode: string) => {
    actorDispatch(
      'getChatReport',
      {
        params: {
          reportId: contactsReportId,
          pagination: { perPage: 9999 },
          filters: [['ddsearchcode', 'equal', ddsearchcode]],
        },
        successCallback: successSearchContactsCallback,
        failureCallback,
      },
      {
        disableDebounce: true,
      },
    );
  }, []);

  /**
   * to search in contacts
   * @function searchInMessages
   * @returns { void }
   */
  const searchInMessages = useCallback(
    (ddsearchcode: string) => {
      const reportFilters: (string | number)[][] = [
        ['ddsearchcode', 'equal', ddsearchcode],
      ];

      if (
        !isEmptyObject(selectedUser) &&
        searchModeInChatRef.current == 'oneByOne'
      ) {
        reportFilters.push([
          'OtherPersonInfo_ID',
          'equal',
          selectedUser.personinfo_id,
        ]);
      }
      actorDispatch(
        'getChatReport',
        {
          params: {
            reportId: searchInMessagesReportId,
            pagination: { perPage: 100 },
            filters: reportFilters,
          },
          successCallback: successSearchInMessagesCallback,
          failureCallback,
        },
        {
          disableDebounce: true,
        },
      );
    },
    [selectedUser],
  );

  /**
   * @function handleDebouncedSearch
   * @param { string } value
   * @returns { void }
   */
  const handleDebouncedSearch = useCallback(
    lodashDebounce((value: string) => {
      lastContactSearchRef.current = value;
      searchInMessages(value);
      searchContacts(value);
    }, 500),
    [selectedUser],
  );

  /**
   * @function onSearch
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void } void
   */
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'setSearchValue',
      payload: event.target.value,
    });

    if (!isEmpty(event.target.value)) {
      dispatch({
        type: 'setLoading',
        payload: true,
      });
      handleDebouncedSearch(event.target.value);
    }
  };

  /**
   * @function clearSearchValue
   * @returns {void} void
   */
  const clearSearchValue = useCallback(() => {
    dispatch({
      type: 'setSearchValue',
      payload: '',
    });
  }, []);

  return (
    <ChatSidebarView
      chatsData={chatsData} //order all the chats by date
      chatsLoading={loading}
      fetchMoreChats={onFetchMoreChats}
      onChatSelect={onChatSelect}
      selectedUser={selectedUser}
      searchValue={searchValue}
      onSearch={onSearch}
      foundedContacts={contactsFoundBySearch}
      foundedMessages={messagesFoundBySearch}
      refreshChats={chatStatelessActions.refreshContacts}
      clearSearchValue={clearSearchValue}
      searchInputRef={searchInputRef}
    />
  );
});

export default ChatSidebarController;
