import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  btnContainer: {
    width: '160px',
    fontSize: 12,
    fontWeight: 300,
    color: '#ffffff',
  },

  btn: {
    width: '95%',
    background: `${theme.palette.primary.lightBlueBackgroundButton} !important`,
    borderColor: `${theme.palette.primary.appPrimaryToolbarBackgroundColor} !important`,
  },
  toggleBtn: {
    minWidth: '0 !important',
    background: `${theme.palette.primary.lightBlueBackgroundButton} !important`,
  },
}));
