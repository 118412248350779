import { FC, memo, useEffect, useImperativeHandle, useState } from 'react';

import GridBooleanInputView from './grid-boolean-input.view';
import { actorOnDispatch, actorRemoveAction } from '../../../../type/actor-setup';
import { useStyles } from '../../grid.style';

import type { GridBooleanInputInterface } from './grid-boolean-input.type';
import { InputAppearanceCharacteristics } from '../../../../helper/meta-helper.type';

const GridBooleanInputController: FC<GridBooleanInputInterface> = props => {
  const { field, onChangeInput, customInputRef } = props;
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState<boolean>(false);

  const [appearanceCharacteristics, setAppearanceCharacteristics] =
    useState<InputAppearanceCharacteristics>();

  const updateInputCharacteristics = (
    value: InputAppearanceCharacteristics,
  ): void => {
    setAppearanceCharacteristics(value);
  };

  useImperativeHandle(customInputRef, () => ({
    updateInputCharacteristics,
  }));

  useEffect(() => {
    const onDispatchId = actorOnDispatch('gridFormData', gridFormData => {
      const value = gridFormData[field.name] ?? false;
      setInternalValue(!!value);
    });

    return () => {
      actorRemoveAction({
        actionName: 'gridFormData',
        listenerId: onDispatchId,
      });
    };
  }, []);

  /**
   * handle change date
   * @param { string } type
   * @param { unknown } payload
   * @returns void
   */
  const handleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setInternalValue(checked);
    onChangeInput({ fieldName: field.name, value: checked });
  };

  return (
    <div className={classes.gridInputContainer}>
      <GridBooleanInputView
        value={internalValue}
        handleChangeValue={handleChangeValue}
        isDisabled={
          appearanceCharacteristics?.enable === false ||
          appearanceCharacteristics?.visible === false
        }
      />
    </div>
  );
};

export default memo(GridBooleanInputController);
