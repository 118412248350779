import { useMemo, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import AvatarSettingsView from './avatar-settings.view';
import { removeLastResource, setResource } from '../../helper/resource-helper';
import { API_NAME, getValue } from '../../core/configProvider';
import { ProfileDataInterface } from './avatar-settings.type';
import { changePasswordFields } from '../form/change-password-form/change-password-form.helper';
import {
  FormKeyMode,
  actorDispatch,
  actorGetActionValue,
} from '../../type/actor-setup';

const AvatarSettingsController = props => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [isProfileFormOpen, setIsProfileFormOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const translate = useTranslate();

  const resetPasswordFields = useMemo(() => changePasswordFields(translate), []);

  const profile = actorGetActionValue('profile') ?? {};

  const { isLoading, profileData } = profile;

  const apiName = getValue(API_NAME);
  const activeTabRef = useRef<string | unknown>('profile');

  const activeTab = useRef<string | unknown>('profile');

  const resource = useMemo(
    () => (isDialogOpen ? actorGetActionValue('resources')!.current.value : ''),
    [isDialogOpen],
  );

  /**
   * when the user upload a new profile image
   * @function handleNewProfileCreate
   * @returns {void}
   */
  const handleNewProfileCreate = (): void => {
    setIsProfileFormOpen(false);
  };

  /**
   * when Profile Form button clicked
   * @function handleProfileFormClick
   * @param event
   * @returns {void}
   */
  const handleProfileFormClick = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * when click on out of the box Menu
   * @function handleCloseProfileMenu
   * @returns {void}
   */
  const handleCloseProfileMenu = (): void => {
    setAnchorEl(null);
  };

  /**
   * returns name or phoneNumber of user
   * @function getProfileDisplayName
   * @param {ProfileDataInterface} profileData
   * @returns {string}
   */
  const getProfileDisplayName = (profileData: ProfileDataInterface): string => {
    if (profileData.displayName) {
      return profileData.displayName;
    } else if (profileData.firstName || profileData.lastName) {
      return `${profileData.firstName} ${profileData.lastName}`.trim();
    }
    return profileData.mobileNumber ?? '';
  };

  /**
   * @function profileSettingHandler
   * @returns {void}
   */
  const profileSettingHandler = (): void => {
    setIsProfileFormOpen(true);
    setResource(`account/${apiName}/${activeTabRef.current}`, FormKeyMode.PROFILE);
    setIsProfileOpen(true);
    setAnchorEl(null);
  };

  /**
   * @function handleCloseProfileForm
   * @returns {void}
   */
  const handleCloseProfileForm = (): void => {
    actorDispatch('remove', {
      resource: `account/${apiName}/${activeTabRef.current}`,
      type: FormKeyMode.PROFILE,
    });
    activeTabRef.current = 'profile';
    setIsProfileFormOpen(false);
  };

  /**
   * @function onTabChange
   * @param {Record<string, Record<string, unknown>>} tab
   * @returns {void}
   */
  const onTabChange = (tab: Record<string, Record<string, unknown>>): void => {
    const name = tab.props?.name ?? '';
    removeLastResource(FormKeyMode.PROFILE);
    setResource(`account/${apiName}/${name}`, FormKeyMode.PROFILE);
    activeTabRef.current = name;
  };

  /**
   * close profile dialog
   * @function closeDialog
   * @returns {void}
   */
  const closeDialog = (): void => {
    actorDispatch('remove', {
      resource: `account/${apiName}/${activeTab.current}`,
      type: FormKeyMode.PROFILE,
    });
    activeTab.current = 'profile';
    setIsDialogOpen(false);
  };

  return (
    <AvatarSettingsView
      handleProfileFormClick={handleProfileFormClick}
      anchorEl={anchorEl}
      handleCloseProfileMenu={handleCloseProfileMenu}
      profileSettingHandler={profileSettingHandler}
      getProfileDisplayName={getProfileDisplayName}
      isProfileFormOpen={isProfileFormOpen}
      handleCloseProfileForm={handleCloseProfileForm}
      onTabChange={onTabChange}
      closeDialog={closeDialog}
      resource={resource}
      profileData={profileData}
      isLoading={isLoading}
      handleNewProfileCreate={handleNewProfileCreate}
      apiName={apiName}
      fileUploadLimitMB={profileData?.globalParameters?.fileUploadLimitMB}
      resetPasswordFields={resetPasswordFields}
      translate={translate}
    />
  );
};

export default AvatarSettingsController;
