import { GET_ONE } from 'react-admin';
import lodashFind from 'lodash/find';
import lodashGet from 'lodash/get';

import { getProfileSettingDropdownPatternInfo } from './PatternMetaHelper';
import {
  actorDispatch,
  actorGetActionValue,
  DropdownDataKey,
} from '../type/actor-setup';
import {
  apiUrl,
  apiVersion,
  GET_DROPDOWN,
  httpClient,
} from '../core/data-Provider.helper';
import { showNotification } from './general-function-helper';
import dataProvider from '../core/dataProvider';
import {
  arrayResultToObjectWithLowerCaseForDropDown,
  isEmpty,
  objectToLowerCaseProperties,
} from './data-helper';
import {
  API_NAME,
  CONFIG_PROFILE_SETTING,
  getValue,
  setValue,
  USER_TOKEN,
  USER_WAREHOUSE_ID,
  USER_WAREHOUSE_TITLE,
} from '../core/configProvider';
import { getDropdownRequestParams } from './DropdownHelper';
import { createNewUrlBaseOfSessionIdInUrl } from './UrlHelper';

import type { FieldType, GlobalParametersAndWarehouse } from './Types';

/**
 * it will compare current session id in url with session id in actor store
 * and return the equality compare result as reverse
 * @function shouldUpdateProfile
 * @param {string} sessionIdInUrl
 * @returns {boolean}
 */
export const shouldUpdateProfile = (sessionIdInUrl: string): boolean => {
  const sessionIdInActor = actorGetActionValue('currentSessionIdInUrl');

  return sessionIdInUrl == sessionIdInActor ? false : true;
};

/**
 * set new profile in actor
 * @function setNewProfile
 * @param {Record<string, unknown>} compatibleProfileData
 * @param {Record<string, unknown>} additionalData
 * @param {GlobalParametersAndWarehouse} globalParametersAndWarehouse
 * @returns {void}
 */
const setNewProfile = (
  compatibleProfileData: Record<string, unknown>,
  additionalData: Record<string, unknown>,
  globalParametersAndWarehouse?: GlobalParametersAndWarehouse,
): void => {
  actorDispatch(
    'profile',
    {
      error: null,
      isLoading: false,
      profileData: compatibleProfileData,
      additionalData: additionalData,
      warehouseTitle: globalParametersAndWarehouse?.warehousetitle,
      globalParameters: globalParametersAndWarehouse?.newGlobalParameters,
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'setNewProfile',
    },
  );
};

/**
 * get profile failure
 * @function onGetProfileFailure
 * @param { string | undefined } errorMessage
 * @returns {void}
 */
const onGetProfileFailure = (errorMessage: string | null = null): void => {
  actorDispatch(
    'profile',
    {
      error: errorMessage,
      isLoading: false,
      profileData: null,
      additionalData: null,
      warehouseTitle: null,
    },
    {
      replaceAll: true,
      callerScopeName: 'onGetProfileFailure',
    },
  );
};

/**
 * set profile loading
 * @function setProfileInLoadingMode
 * @returns {void}
 */
const setProfileInLoadingMode = (): void => {
  actorDispatch(
    'profile',
    {
      isLoading: true,
    },
    {
      callerScopeName: 'setProfileInLoadingMode',
    },
  );
};

/**
 * get profile and set in actor
 * @function getProfile
 * @param {string} translate
 * @param {string} sessionIdInUrl
 * @returns {Promise<void>}
 */
export const getProfile = async (
  translate: (key: string) => string,
  sessionIdInUrl?: string,
): Promise<void> => {
  if (!sessionIdInUrl && !getValue(USER_TOKEN)) {
    const errorText = translate?.('general.invalidToken') ?? 'invalid token';

    onGetProfileFailure(errorText);
    showNotification(errorText, 'error');
    return;
  }

  setProfileInLoadingMode();

  try {
    const apiName = getValue(API_NAME);
    const getOneUrl = `${apiUrl}/${apiVersion}/account/${apiName}/profile`;

    const rawProfileData = sessionIdInUrl
      ? await httpClient.get(
          createNewUrlBaseOfSessionIdInUrl(getOneUrl, sessionIdInUrl ?? '', apiUrl),
        )
      : await dataProvider(GET_ONE, `account/${apiName}`, {
          id: 'profile',
          skipPrefix: true,
        });

    const compatibleProfileData = objectToLowerCaseProperties(
      rawProfileData.data.data ?? rawProfileData.data,
    );

    const additionalData =
      rawProfileData?.data?.data?.additionalData ??
      rawProfileData?.data?.additionalData;
    const globalParametersAndWarehouse = await getGlobalParametersAndWarehouse(
      compatibleProfileData,
    );

    setNewProfile(
      compatibleProfileData,
      additionalData,
      globalParametersAndWarehouse,
    );
  } catch (error) {
    if (error && typeof error === 'object' && error['code'] === 401) {
      showNotification(`${error['userMessage']}`, 'error');
      // now should logout
      onGetProfileFailure(error['userMessage']);
    } else {
      onGetProfileFailure();
    }
  }
};

export const getDropDownItem = async (
  dropdownMeta: FieldType['dropdown'],
  userWarehouse?: string,
): Promise<Record<string, unknown> | undefined> => {
  try {
    const _userWarehouse = userWarehouse ?? getValue(USER_WAREHOUSE_ID);
    const dropdownId = dropdownMeta?.id;

    let dropdownData = actorGetActionValue('dropDownData')?.[dropdownId]?.DATA;

    if (!(Array.isArray(dropdownData) && dropdownData.length > 0)) {
      const params = getDropdownRequestParams({
        dropdownMeta,
      });

      const data = await dataProvider(GET_DROPDOWN, dropdownId, params);

      dropdownData = arrayResultToObjectWithLowerCaseForDropDown(
        data.data,
        dropdownMeta?.valueMember ?? params.fieldName,
      );
    }

    actorDispatch(
      'dropDownData',
      {
        [DropdownDataKey.DATA]: dropdownData,
        [DropdownDataKey.ALL]: dropdownData,
      },
      { path: `${dropdownId}`, disableDebounce: true },
    );

    if (!isEmpty(_userWarehouse)) {
      return lodashFind(dropdownData, {
        [dropdownMeta.valueMember]: +_userWarehouse,
      });
    } else {
      return lodashGet(dropdownData, '0');
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

export const getGlobalParametersAndWarehouse = async (
  profileData: Record<string, unknown>,
): Promise<GlobalParametersAndWarehouse | undefined> => {
  try {
    const dropdownMeta = getProfileSettingDropdownPatternInfo(
      'profileSettingDropdown',
    );

    const globalParameters = objectToLowerCaseProperties(
      profileData?.['globalParameters'] as Record<string, unknown>,
    );

    let userWarehouseId = getValue<string>(USER_WAREHOUSE_ID);

    if (!userWarehouseId) {
      userWarehouseId = globalParameters['currentWareHouseID'] as string;
      setValue(USER_WAREHOUSE_ID, globalParameters['currentWareHouseID']);
    }

    const dropdownItem = await getDropDownItem(
      dropdownMeta as unknown as FieldType['dropdown'],
      userWarehouseId,
    );

    const newGlobalParameters = {
      ...globalParameters,
    };

    if (dropdownItem) {
      newGlobalParameters['currentdepid'] = dropdownItem['departmentinfo_id'];
      newGlobalParameters['currentDepID'] = dropdownItem['departmentinfo_id'];
      newGlobalParameters['currentwarehouseid'] = dropdownItem['warehouse_id'];
      newGlobalParameters['currentWareHouseID'] = dropdownItem['warehouse_id'];

      setValue(USER_WAREHOUSE_TITLE, dropdownItem['warehousetitle']);
    }

    // set to actor and local storage
    setValue(CONFIG_PROFILE_SETTING, newGlobalParameters);

    return {
      warehousetitle: dropdownItem?.['warehousetitle'] as string,
      newGlobalParameters,
    };
  } catch (error) {
    return;
    console.log(error);
  }
};
