import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { GET_LIST, useSetLocale } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import dataProvider from '../core/dataProvider';
import {
  getValue,
  setValue,
  CONFIG_LOCALE,
  CONFIG_THEME_DIR,
  CONFIG_CALENDAR,
} from '../core/configProvider';
import { arrayResultToObjectWithLowerCase } from '../helper/data-helper';

const LANG_API = 'setting/systemlanguages';

const styles = theme => ({
  languageContainer: {
    paddingTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  IconButton: {
    padding: '8px 12px',
    margin: '0 5px',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.LanguageContainerBackgroundColor,
    }  
  },

  language: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textShadow: `2px 2px 2px ${theme.palette.primary.LanguageContainerTextShadow}`,
    borderBottom: `1px solid ${theme.palette.primary.LanguageContainerBackgroundColor}`,
    '&:hover': {
      borderBottomColor: theme.palette.secondary.contrastText,
    },
  },
});

const LanguageContainer = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [languageList, setLanguageList] = useState([]);
  const { classes } = props;

  const [currentLocale, setCurrentLocale] = useState(getValue(CONFIG_LOCALE));
  const setLocale = useSetLocale();

  useEffect(() => {
    setIsLoading(true);

    // use effect must only return function to cleanup, if async is used, it will give back a promise
    // so define a function here and call it immidiately
    async function fetchData() {
      try {
        const { data } = await dataProvider(GET_LIST, LANG_API, {
          pagination: {
            page: 1,
            perPage: 1000,
          },
          sort: {
            field: 'id',
            order: 'ASC',
          },
          skipPrefix: true,
        });

        setIsLoading(false);
        setLanguageList(data);
        const defaultLanguage = data.find((item)=>item.isdefault);

        if(defaultLanguage?.keyword){
          handleChangeLocale(defaultLanguage)
        }
         if(defaultLanguage?.calendar) {
          setValue(CONFIG_CALENDAR, defaultLanguage?.calendar);
         }
      } catch (error) {
        console.log('`LanguageContainer` Error: %o', error);
        setIsLoading(true);
      }
    }

    fetchData();
  }, []);

  const handleChangeLocale = row => {
    setValue(CONFIG_LOCALE, row.keyword);
    setCurrentLocale(row.keyword)
    setLocale(row.keyword)
    setValue(CONFIG_THEME_DIR, row.textDirection);
  };

  return (
    <div className={classes.languageContainer} id="lang">
      {isLoading && <CircularProgress color="secondary" />}
      {languageList &&
        languageList.map(row => (
          <IconButton
            className={classes.IconButton}
            onClick={() => handleChangeLocale(row)}
            data-test={`lang-${row.keyword}`}
          >
            <Typography
              className={classNames({
                [classes.language]: true,
                [classes.selectLang]: currentLocale === row.keyword,
                [`lang-${row.keyword}`]: true,
              })}
              variant="body2"
            >
              {row.title}
            </Typography>
          </IconButton>
        ))}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(LanguageContainer);
