import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  formControl: {
    marginTop: 15,
  },
  accordionView: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordionBackground: {
    background: theme.palette.primary.main,
    borderRadius: '10px 10px 0px 0px',

    '& span': {
      color: theme.palette.secondary.contrastText,
    },
  },
  accordionSummeryText: {
    color: theme.palette.secondary.contrastText,
  },
  autoCompleteStyle: {},
}));
