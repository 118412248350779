import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  cameraIcon: {
    width: '20px',
    height: '20px',
  },

  fileInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    width: '40px',
    height: '40px',
    marginLeft: '3rem',
    cursor: 'pointer',
  },

  largeFileInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    width: 60,
    height: 60,
    cursor: 'pointer',
  },

  input: {
    display: 'none',
  },
}));
