import TimeSeriesChartView from './time-series-chart.view';

import type { FC } from 'react';
import {
  HightChartSeries,
  TimeSeriesChartControllerProps,
} from './time-series-chart.type';
import { isFullDateFormat } from '../../../helper/DateHelper';
import { addThousandSeparator, prepareHighChartData } from '../dynamic-chart.helper';

const TimeSeriesChartController: FC<TimeSeriesChartControllerProps> = props => {
  const { reportData, chartOptions, width, height } = props;
  let xAxisType = 'datetime';

  //in old dashboard version valueFieldName was array and maybe we have crash
  const finalValueFieldName = Array.isArray(chartOptions.valueFieldName)
    ? chartOptions.valueFieldName[0]
    : chartOptions.valueFieldName;

  /**
   * @function getHightChartData
   * @returns {HightChartSeries}
   */
  const getHightChartData = (): HightChartSeries[] => {
    const chartData = prepareHighChartData({
      data: reportData,
      ...chartOptions,
      valueFieldName: [finalValueFieldName],
    });

    xAxisType = 'datetime';
    const highChartData: HightChartSeries[] = [];
    chartData.map(rowInfo => {
      const seriesData: [number | string, number][] = [];
      rowInfo.data.map(pointInfo => {
        if (isFullDateFormat(pointInfo.x)) {
          seriesData.push([new Date(pointInfo.x).getTime(), pointInfo.y]);
        } else {
          xAxisType = 'category';
          seriesData.push([pointInfo.x, pointInfo.y]);
        }
      });

      highChartData.push({
        type: 'line',
        name: rowInfo.id,
        data: seriesData,
      });
    });

    return highChartData;
  };

  /**
   * prepare chart options
   */
  const highChartOptions = {
    series: getHightChartData(),
    title: { text: '' },
    chart: {
      zoomType: 'x',
      style: { width, height },
      width: width,
      height: height,
    },
    xAxis: {
      type: xAxisType,
      title: {
        text: chartOptions.axisBottomLabelFieldName,
      },
    },
    yAxis: {
      title: {
        text: chartOptions.axisLeftLabelFieldName,
      },
      labels: {
        formatter: ({ value }) => addThousandSeparator(value),
      },
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <TimeSeriesChartView
      highChartOptions={highChartOptions}
      width={width}
      height={height}
    />
  );
};

export default TimeSeriesChartController;
