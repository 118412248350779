import { type FC, useEffect, useState } from 'react';
import GaugeChartView from './gauge-chart.view';
import { isEmpty } from '../../../helper/data-helper';
import { addThousandSeparator, getNumericFormat } from '../dynamic-chart.helper';
import { ThresholdColorRangeInterface } from '../../dynamic-input/gadget-input/gadget-design-dialog/setting-section/gadget-settings-tab/dynamic-chart-settings-selector/threshold-color-range';

import type { GaugeChartControllerProps } from './gauge-chart.type';
import { defaultThresholdRow } from '../../dynamic-input/gadget-input/gadget-design-dialog/setting-section/gadget-settings-tab/dynamic-chart-settings-selector/threshold-color-range/threshold-color-range.controller';
const GaugeChartController: FC<GaugeChartControllerProps> = props => {
  const { reportData, chartOptions, height, width, metaData } = props;
  const [numericFormat, setNumericFormat] = useState<string | null>(null);

  const arcOption: ThresholdColorRangeInterface[] = !isEmpty(
    chartOptions.thresholdColorRange,
  )
    ? JSON.parse(chartOptions.thresholdColorRange)
    : defaultThresholdRow;

  //in old dashboard version valueFieldName was array and maybe we have crash
  const finalValueFieldName = Array.isArray(chartOptions.valueFieldName)
    ? chartOptions.valueFieldName[0]
    : chartOptions.valueFieldName;

  useEffect(() => {
    if (metaData) {
      setNumericFormat(getNumericFormat(metaData, [finalValueFieldName]));
    }
  }, [finalValueFieldName, metaData?.reportId]);

  const value: number =
    Array.isArray(reportData) && reportData.length > 0
      ? Number(reportData[0][finalValueFieldName])
      : 0;

  /**
   * getArcsNumber: create Arcs number range list
   * @returns { number[]}
   */
  const getArcsNumber = (): number[] => {
    let prevNumber = 0;
    return arcOption?.map(option => {
      const arcNumber = option.number - prevNumber;
      prevNumber = option.number;
      return arcNumber;
    });
  };

  /**
   * getArcsColor: create Arcs color range list
   * @returns { string[]}
   */
  const getArcsColor = (): string[] => {
    return arcOption?.map(option => option.color);
  };

  /**
   * getFormatTextValue
   * @param {string} value
   * @returns { string}
   */
  const getFormatTextValue = (): string => {
    let label: string | number = value;

    if (typeof value === 'number') {
      if (numericFormat) {
        label = addThousandSeparator(label);
      }
    }

    label += '%';

    if (
      chartOptions.labelFieldName &&
      Array.isArray(reportData) &&
      reportData.length > 0
    ) {
      label = (reportData[0][chartOptions.labelFieldName] as string) ?? '';
    }
    return String(label);
  };

  /**
   * getTextColor
   * @returns {string}
   */
  const getTextColor = (): string => {
    let textColor: string | undefined = undefined;
    let lastColor = '#000';
    arcOption?.map(option => {
      if (value < option.number && !textColor) {
        textColor = option.color;
      }
      lastColor = option.color;
    });
    return textColor ?? lastColor;
  };

  return (
    <GaugeChartView
      title={chartOptions.titleFieldName ?? ''}
      getFormatTextValue={getFormatTextValue}
      getTextColor={getTextColor}
      value={value}
      height={height}
      width={width}
      arcNumberList={getArcsNumber()}
      arcColorList={getArcsColor()}
    />
  );
};

export default GaugeChartController;
