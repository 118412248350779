import { FC, memo, ReactElement } from 'react';
import lodashGet from 'lodash/get';

import ButtonInputView from './button-input.view';
import { ButtonInputValuesType, ButtonInputViewType } from '.';
import IconButtonInputView from './icon-button-input.view';
import { showNotification } from '../../../helper/general-function-helper';
import {
  isEmpty,
  isEmptyObject,
  objectToLowerCaseProperties,
} from '../../../helper/data-helper';
import { checkUiControl } from '../../form/form-with-tabs/form-with-tabs.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../type/actor-setup';

import type {
  ButtonInputInterface,
  ServiceResultInterface,
} from './button-input.type';
import type { ValidationAction } from '../../../helper/Types';
import type { InputRefContent } from '../../form';
import { openNewTab } from '../../../helper/QuickAccessHelper';
import { CreateLinkTarget } from '../../quick-access-menu';

const ButtonInputController: FC<ButtonInputInterface> = memo(props => {
  const { field, resource, disabled, visibleClass, customTestAttribute } = props;

  const { values, id } = field;

  const currentMetaData = actorGetActionValue('metaData', resource)!;

  const currentFieldValidationActions = lodashGet(
    currentMetaData,
    'validationActions',
  )?.[id] as ValidationAction[] | undefined;

  /**
   * Handle Run Service
   * @function handleRunService
   * @returns {void}
   */
  const handleRunService = (action: ValidationAction): void => {
    if (action && action?.uniqueId) {
      const currentResource = actorGetActionValue('resources')!.current;

      const formData = actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null;

      actorDispatch(
        'crudAction',
        {
          type: 'RUN_SERVICE',
          actionUniqueId: action.uniqueId,
          data: {
            params: formData,
          },
          onFailure: error => showNotification(error, 'error'),
          onSuccess: (response: ServiceResultInterface = {}) => {
            const { userMessage, data = {}, actionOutput } = response;
            showNotification(userMessage, 'info');
            actorDispatch('refreshView', 'all', {
              callerScopeName: 'ButtonInputController => handleRunService',
            });

            const currentResource = actorGetActionValue('resources')!.current;
            const inputsRef = actorGetActionValue('inputsRef')?.[
              currentResource.value
            ]?.[currentResource.type] as Record<string, InputRefContent> | null;

            const formData = {};
            for (const [fieldName, value] of Object.entries(data)) {
              formData[fieldName] = value;

              if (!isEmptyObject(inputsRef)) {
                inputsRef![fieldName]?.setInputValue?.(value);
                checkUiControl(fieldName, currentResource);
              }
            }

            actorSetActionValue('formData', formData, {
              path: `${currentResource.value}.${currentResource.type}`,
            });

            if (actionOutput && !isEmpty(actionOutput?.additionalData?.link)) {
              const { link, linktype } = actionOutput.additionalData;
              openNewTab(link, linktype ? CreateLinkTarget(linktype) : '_self');
            }
          },
        },
        {
          disableDebounce: true,
          replaceAll: true,
          callerScopeName: 'ButtonInputController => handleRunService',
        },
      );
    }
  };

  /**
   * @function renderView
   * @returns { ReactElement }
   */
  const renderView = (): ReactElement => {
    switch ((values as unknown as ButtonInputValuesType)?.viewType) {
      case ButtonInputViewType.ICON_AND_CAPTION:
      case ButtonInputViewType.CAPTION_ONLY:
        return (
          <ButtonInputView
            actions={currentFieldValidationActions}
            handleRunService={handleRunService}
            field={field}
            resource={resource}
            visibleClass={visibleClass}
            customTestAttribute={customTestAttribute}
            disabled={disabled}
          />
        );

      case ButtonInputViewType.ICON_ONLY:
        return (
          <IconButtonInputView
            actions={currentFieldValidationActions}
            handleRunService={handleRunService}
            field={field}
            resource={resource}
            visibleClass={visibleClass}
            customTestAttribute={customTestAttribute}
            disabled={disabled}
          />
        );

      default:
        return (
          <ButtonInputView
            actions={currentFieldValidationActions}
            handleRunService={handleRunService}
            field={field}
            resource={resource}
            visibleClass={visibleClass}
            customTestAttribute={customTestAttribute}
            disabled={disabled}
          />
        );
    }
  };

  return renderView();
});

export default ButtonInputController;
