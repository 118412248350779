/**
 * TODO: After completing refactor, have to delete
 */

import {
  checkJsonStringify,
  isEmpty,
  isEmptyObject,
  objectToLowerCaseProperties,
} from '../../helper/data-helper';
import {
  FullProfileInterface,
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
  ApiRequestResultInterface,
  ChatIsTypingInterface,
} from '../../type/actor-setup';
import {
  getUserAvatarRelativeUrl,
  showNotification,
} from '../../helper/general-function-helper';
import { ChatReportResponseInterface } from '../../api/chat-api';
import momentJalaali from 'moment-jalaali';
import { getValue, SESSION_ID, USER_ID } from '../../core/configProvider';
import lodashFilter from 'lodash/filter';
import { replaceArabicCharacters } from '../../helper/TextHelper';
import {
  CHAT_SIGNAL,
  fireSignal,
  getEventValue,
} from '../../hooks/useSignalRConnection';
import { chatStatelessActions } from './chat-section-helper/chat-section-stateless-actions';

import type { MessageSendManagerParamsType } from './messages-list';
import type {
  ChatDetailInterface,
  ChatItemInterface,
  ChatMessageContentInterface,
  ChatTotalUnseenInterface,
  GetChatsFunction,
  MessageInSignalRType,
  MessageItemInterface,
  SendMessageParamsBase,
  SendMessageParamsType,
  SignalRMessageType,
  UploadedFileInterface,
  UsersOnlineStatusResponseInterface,
} from './chat-section.type';
import type { ChatUsersInterface } from './chat-dialogs/add-members-dialog/add-members-dialog.type';
import type {
  AlbumMessageMetaInterface,
  ChatTextJsonFormatInterface,
  FileMessageMetaInterface,
  LocationMessageMetaInterface,
  SelectedMessageInterface,
  VideoMessageMetaInterface,
} from './new-message';
import { RUN_SERVICE } from '../../core/data-Provider.helper';
import { handleInsertNewSignalRMessage } from './handleInsertNewSignalRMessage';
import { seenMessageServiceId } from './chat-section-helper/chat-section-common';

export const contactsReportId = '6ed80f00-76b9-4365-a004-a601e580f71b';
export const chatsReportId = '5d466dd3-8cf2-4e22-b99b-f8e480f7846f';
export const userContentsReportId = '1f9b97cd-5180-48d6-9a43-8b32d3f92f88';
export const sendMessageReportId = 'aa774db4-8a78-469f-8786-811215482f84';
export const deleteMessageServiceId = 'c0e69d65-5155-4cc1-aa40-0ea58508f5b7';
export const editMessageServiceId = '80e931ef-8fdc-4f38-8402-071a6a665971';
export const searchInMessagesReportId = '59bbbd72-3c94-4296-b530-57273164d8e4';
export const fileUploadResource = 'General/Chat/filestream/fileurl';
export const getCommonGroupsReportId = 'b0eca386-8fde-43ea-a3db-41f6d91b0e9a';
export const chatSocketAddress = 'hub/chatv2?app=5';
export const pinMessageActionUniqueId = '8cd88cf0-67e8-463d-9edd-242ec130ddfa';

export const ONLINE_STATUS_REFRESH_RATE = 10000;
export const IS_TYPING_REFRESH_RATE = 1000;

export const CHAT_VERSION = '1.0.0-rc.1';
export enum ChatMessageTypeEnum {
  LOCATION = 'LOCATION',
  ALBUM = 'ALBUM',
  FORWARD = 'FORWARD',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  FILE = 'FILE',
}

export enum ChatActionName {
  sendContent = 'SEND_CONTENT',
  sendMessage = 'SEND_MESSAGE',
  sendFile = 'SEND_FILE',
  refreshContacts = 'REFRESH_CONTACTS',
  refreshChats = 'REFRESH_CHATS',
  fetchMoreContacts = 'FETCH_MORE_CONTACTS',
  fetchMoreChats = 'FETCH_MORE_CHATS',
  fetchMoreMessages = 'FETCH_MORE_MESSAGES',
  selectUser = 'SELECT_USER',
  moveLast = 'MOVE_LAST',
  moveToRepliedMessage = 'MOVE_TO_REPLIED_MESSAGE',
  deleteMessage = 'DELETE_MESSAGE',
}

/**
 * set messagesData reaches end
 * by setting hasMore: false
 * @function setReachEndOfMessages
 * @returns { void }
 */
// export const setReachEndOfMessages = (): void => {
//   const currentMessagesData = actorGetActionValue('messagesData');
//   actorDispatch('messagesData', { ...currentMessagesData, hasMore: false });
// };

/**
 * to seen messages
 * @function handleSeenMessage
 * @param { Record<string, unknown> } params
 * @returns { void }
 */
export const handleSeenMessage = (params: Record<string, unknown>): void => {
  actorDispatch(
    'runChatService',
    {
      params: {
        actionUniqueId: seenMessageServiceId,
        data: {
          params,
        },
      },
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * to determine how to insert contents
 * on messagesList view
 * @function handleInsertContentOnView
 * @param { MessageItemInterface[] }newData
 * @param { boolean } isUp
 * @returns { void }
 */
export const handleInsertContentOnView = (
  newData: MessageItemInterface[],
  isUp: boolean,
): void => {
  // const currentMessagesData = actorGetActionValue('messagesData');
  // if (currentMessagesData) {
  //   !isUp
  //     ? actorDispatch('messagesData', {
  //         ...currentMessagesData,
  //         hasMore: true,
  //         data: [...newData, ...currentMessagesData?.data],
  //       })
  //     : actorDispatch('messagesData', {
  //         ...currentMessagesData,
  //         hasMore: true,
  //         data: [...currentMessagesData?.data, ...newData],
  //       });
  // }
};

/**
 * to create new chat
 * @function handleCreateNewChat
 * @param { ChatItemInterface } newChat
 * @returns { void }
 */
export const handleCreateNewChat = (
  personInfoId: number,
  signalRMessage: MessageInSignalRType,
): void => {
  const message = createFormattedMessagesFromSignalRMessages([signalRMessage])[0];
  const chatInfo = createChatInfoFromSignalRMessage(signalRMessage);

  const currentChats = actorGetActionValue('chatList');
  if (!currentChats) {
    // ...
  } else {
    if (!isEmptyObject(currentChats.data[personInfoId])) {
      currentChats!.data[personInfoId].info.chattext = message.chattext;
      currentChats!.data[personInfoId].info.chatdate = message.chatdate;
      currentChats!.data[personInfoId].info.sumnotseen += 1;
      currentChats!.data[personInfoId].messagesDetail?.data.unshift(message);
    } else {
      chatInfo.sumnotseen = 1;
      // A brand new chat received
      currentChats.data[personInfoId] = {
        info: chatInfo,
        messagesDetail: null,
      };
    }

    actorDispatch('chatList', currentChats, {
      replaceAll: true,
      disableDebounce: true,
      callerScopeName: 'handleCreateNewChat',
    });
  }
  // const currentChatsData = actorGetActionValue('chatsData')!;
  // actorDispatch(
  //   'chatsData',
  //   currentChatsData
  //     ? {
  //         ...currentChatsData,
  //         data: [newChat, ...currentChatsData.data],
  //       }
  //     : {
  //         hasMore: true,
  //         data: [newChat],
  //       },
  // );
};

/**
 * to update existed chat
 * @function updateChatMessages
 * @param {ChatDetailInterface} chat
 * @param {message}
 * @returns {void} void
 */
export const updateChatMessages = (
  chat: ChatDetailInterface,
  signalRMessage: MessageInSignalRType,
  dispatchSelectedChat = false,
): void => {
  const message = createFormattedMessagesFromSignalRMessages([signalRMessage])[0];

  if (!chat.messagesDetail) {
    // TODO :
  } else {
    chat.messagesDetail.data.unshift(message);
    const parseMessageTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(
      message.chattext,
    );
    chat.info.chattext =
      parseMessageTextResult === false
        ? message.chattext
        : parseMessageTextResult.rawText;
    chat.info.chatdate = message.chatdate;

    if (dispatchSelectedChat) {
      actorDispatch('selectedChat', chat, {
        replaceAll: true,
        disableDebounce: true,
        callerScopeName: 'updateChatMessages',
      });
    } else {
      chat.info.sumnotseen += 1;
    }

    const currentChats = actorGetActionValue('chatList');

    const personInfoId = chat.info.personinfo_id;
    currentChats!.data[personInfoId].info.chattext = message.chattext;
    currentChats!.data[personInfoId].info.chatdate = message.chatdate;
    currentChats!.data[personInfoId].info.sumnotseen = chat.info.sumnotseen;

    if (signalRMessage.fromgroup) {
      // prettier-ignore
      currentChats!.data[personInfoId].info.frompersoninfoname = signalRMessage.personname;
    }

    actorDispatch('chatList', currentChats, {
      replaceAll: true,
      disableDebounce: true,
      callerScopeName: 'updateChatMessages',
    });
  }
};

/**
 * handle update unseen messages on sidebar
 * @function handleUpdateUnseenMessages
 * @param { ChatTotalUnseenInterface } newChatData
 * @returns { void } void
 */
export const handleUpdateUnseenMessages = (
  newChatData: ChatTotalUnseenInterface,
): void => {
  const currentChatData = actorGetActionValue('selectedChat');
  if (currentChatData?.info.personinfo_id === newChatData.otherpersoninfo_id) return;

  const chats = actorGetActionValue('chatList');

  if (chats) {
    chats.data[newChatData.otherpersoninfo_id].info.sumnotseen =
      newChatData.sumnotseen;

    actorDispatch('chatList', chats, {
      replaceAll: true,
      callerScopeName: 'handleUpdateUnseenMessages',
    });
  }
  /**
   * we don't want to update unseen messages of active chat on sidebar
   */
  // if (
  //   !lodashIsEqual(currentSelectedUser?.personinfo_id, newMessage.otherpersoninfo_id)
  // ) {
  //   actorDispatch('chatsData', {
  //     ...currentChatsData,
  //     data: currentChatsData.data.map((item: ChatItemInterface) =>
  //       lodashIsEqual(item.personinfo_id, newMessage.otherpersoninfo_id)
  //         ? {
  //             ...item,
  //             sumnotseen: newMessage?.sumnotseen,
  //           }
  //         : item,
  //     ),
  //   });
  //   /**
  //    * but we should  update unseen messages of active chat on sidebar if it has more than 0 unseen messages
  //    */
  // } else {
  //   if (currentSelectedUser?.sumnotseen > 0) {
  //     actorDispatch('chatsData', {
  //       ...currentChatsData,
  //       data: currentChatsData.data.map((item: ChatItemInterface) =>
  //         lodashIsEqual(item.personinfo_id, newMessage.otherpersoninfo_id)
  //           ? {
  //               ...item,
  //               sumnotseen: newMessage?.sumnotseen,
  //             }
  //           : item,
  //       ),
  //     });
  //     actorDispatch(
  //       'selectedUser',
  //       currentSelectedUser
  //         ? {
  //             ...currentSelectedUser,
  //             sumnotseen: newMessage?.sumnotseen,
  //           }
  //         : null,
  //     );
  //   }
  // }
};

/**
 * @function handleAfterSuccessSendMessage
 * @param { boolean } shouldNotScrollDown
 * @returns { void }
 */
export const handleAfterSuccessSendMessage = (
  shouldNotScrollDown: boolean,
): void => {
  // actorDispatch('chatText', '');
  // !shouldNotScrollDown && actorDispatch('isNewContentSent', true);

  actorDispatch('uploadedFile', null, {
    replaceAll: true,
  });
};

/**
 * to insert new message
 * in messageData
 * @function handleCreateNewMessage
 * @param { MessageItemInterface } newChat
 * @returns { void }
 */
export const handleCreateNewMessage = (newMessage: MessageItemInterface): void => {
  // const currentMessagesData = actorGetActionValue('messagesData')!;
  // actorDispatch('messagesData', {
  //   ...currentMessagesData,
  //   data: [newMessage, ...currentMessagesData?.data],
  // });
};

/**
 * to update messagesData when
 * we edit a specific message
 * @function handleEditMessage
 * @param { MessageItemInterface } updatedMessage
 * @returns { void }
 */
export const handleEditMessage = (updatedMessage: MessageItemInterface): void => {
  // const currentMessagesData = actorGetActionValue('messagesData')!;
  // actorDispatch('messagesData', {
  //   ...currentMessagesData,
  //   data: currentMessagesData.data.map((item: MessageItemInterface) =>
  //     item.chat_id === updatedMessage.chat_id
  //       ? {
  //           ...item,
  //           chattext: updatedMessage?.chattext,
  //           fileurl: updatedMessage?.fileurl,
  //           isedited: true,
  //         }
  //       : item,
  //   ),
  // });
};

/**
 * to update messagesData when
 * @function handleUpdateMessage
 * @param { MessageInSignalRType[] } updatedMessages
 * @returns { void }
 */
export const handleUpdateMessage = (
  updatedMessages: MessageInSignalRType[],
): void => {
  const currentChatData = actorGetActionValue('selectedChat');
  if (!currentChatData?.messagesDetail) {
    // TODO:
    return;
  }

  for (const updatedMessage of updatedMessages) {
    const targetMessageIndex = currentChatData.messagesDetail.data.findIndex(
      message => message.chat_id === updatedMessage.chat_id,
    );
    if (targetMessageIndex > -1) {
      const targetMessage = currentChatData.messagesDetail.data[targetMessageIndex];
      if (
        updatedMessage.isedited === targetMessage.isedited &&
        updatedMessage.isseen === targetMessage.isseen
      ) {
        continue;
      }

      currentChatData.messagesDetail.data[targetMessageIndex] =
        createFormattedMessagesFromSignalRMessages([updatedMessage])[0];
    }
  }

  if (updatedMessages[0].fromgroup) {
    currentChatData.info.frompersoninfoname = updatedMessages[0].personname;
  }

  actorDispatch('selectedChat', currentChatData, {
    replaceAll: true,
    disableDebounce: true,
    callerScopeName: 'handleUpdateMessage',
  });
};

/**
 * to update messagesData when
 * we delete a specific message
 * @function handleDeleteMessage
 * @param { MessageItemInterface } toBeDeletedMessage
 * @returns { void }
 */
export const handleDeleteMessage = (
  toBeDeletedMessages: MessageItemInterface[],
): void => {
  const selectedChat = actorGetActionValue('selectedChat');
  if (selectedChat == null) return;

  const { messagesDetail, info } = selectedChat;
  let updateInfo = { ...info };

  for (const toBeDeletedMessage of toBeDeletedMessages) {
    const targetMessageIndexToDelete = messagesDetail!.data.findIndex(
      message => message.chat_id === toBeDeletedMessage.chat_id,
    );
    if (targetMessageIndexToDelete === -1) continue;

    let lastMessageBeforeDelete = messagesDetail!.data[0];
    // prettier-ignore
    if ( messagesDetail!.data[targetMessageIndexToDelete].chat_id === lastMessageBeforeDelete.chat_id) {
      lastMessageBeforeDelete = messagesDetail!.data[targetMessageIndexToDelete + 1];
    }

    if (lastMessageBeforeDelete == null) {
      const currentChatList = actorGetActionValue('chatList')!;
      delete currentChatList.data[toBeDeletedMessage.topersoninfo_id];
      currentChatList.totalCount -= 1;

      actorDispatch('chatList', currentChatList, {
        replaceAll: true,
        callerScopeName: 'handleDeleteMessage',
      });

      actorDispatch('selectedChat', null, {
        replaceAll: true,
        callerScopeName: 'handleDeleteMessage',
      });

      return;
    }

    messagesDetail!.data.splice(targetMessageIndexToDelete, 1);

    updateInfo = {
      ...updateInfo,
      chattext: lastMessageBeforeDelete.chattext,
      chatdate: lastMessageBeforeDelete.chatdate,
    };
  }

  const chatId =
    toBeDeletedMessages[0].isfrommyself === 0
      ? toBeDeletedMessages[0].frompersoninfo_id
      : toBeDeletedMessages[0].topersoninfo_id;

  actorDispatch(
    'chatList',
    { info: updateInfo, messagesDetail },
    {
      path: `data.${chatId}`,
      replaceAll: true,
      callerScopeName: 'handleDeleteMessage2',
    },
  );

  actorDispatch(
    'selectedChat',
    {
      ...selectedChat,
      info: updateInfo,
      messagesDetail,
    },
    {
      replaceAll: true,
      callerScopeName: 'handleDeleteMessage2',
    },
  );
};

/**
 * @function handleUpdateSignalRChat
 * @param { MessageItemInterface } signalRMessage
 * @returns { void }
 */
export const handleUpdateSignalRChat = (
  signalRMessage: MessageItemInterface,
): void => {
  // const currentChatsData = actorGetActionValue('chatsData')!;
  const newChat = {
    chatdate: signalRMessage.chatdate,
    chattext: signalRMessage.chattext,
    personimage: signalRMessage?.personimage,
    personinfo_id: signalRMessage.frompersoninfo_id,
    personname: signalRMessage?.personname,
    sumnotseen: 1,
  } as ChatItemInterface;

  // const existedChat = lodashFind(currentChatsData.data, [
  //   'personinfo_id',
  //   newChat.personinfo_id,
  // ]);
  // if (existedChat && !isEmptyObject(existedChat)) {
  //   handleUpdateExistedChat(newChat);
  // } else {
  //   handleCreateNewChat(newChat);
  // }
};

export const createFormattedMessagesFromSignalRMessages = (
  signalRMessages: MessageInSignalRType[],
): MessageItemInterface[] => {
  const formattedSignalRMessages: MessageItemInterface[] = [];

  for (const signalRMessage of signalRMessages) {
    const message = {} as MessageItemInterface;
    for (const key in signalRMessage) {
      if (['tscodeid', 'updatedate', 'updateuserid'].indexOf(key) > -1) continue;
      message[key] = signalRMessage[key];
    }

    message.isseen = Boolean(signalRMessage.isseen);
    formattedSignalRMessages.push(message);
  }

  return formattedSignalRMessages;
};

const createChatInfoFromSignalRMessage = (
  signalRMessage: MessageInSignalRType,
): ChatItemInterface => {
  const chatInfo = {} as ChatItemInterface;
  for (const key in signalRMessage) {
    if (
      [
        'chatdate',
        'chattext',
        'personinfo_id',
        'personname',
        'topersonname',
        'sumnotseen',
        'groupuid',
        'isgroupadmin',
        'ischannel',
        'mentionchatid',
        'isowner',
        'fromgroup',
      ].indexOf(key) === -1
    ) {
      continue;
    }

    chatInfo[key] = signalRMessage[key];
  }

  if (signalRMessage.fromgroup) {
    chatInfo.frompersoninfoname = signalRMessage.personname;
    chatInfo.personname = signalRMessage.topersonname;
    chatInfo.personinfo_id = signalRMessage.topersoninfo_id;
  }

  return chatInfo;
};

/**
 * @function getSignalRMessageType
 * @param { MessageInSignalRType[] } signalRMessages
 * @returns { SignalRMessageType }
 */
export const getSignalRMessageType = (
  signalRMessages: MessageInSignalRType[],
): SignalRMessageType => {
  if (signalRMessages[0].isdeleted) {
    return 'DELETE';
  } else if (signalRMessages[0].isedited || signalRMessages[0].isseen) {
    return 'UPDATE';
  } else {
    return 'INSERT';
  }
};

/**
 * to get all chats
 * @function getChats
 * @param {GetChatFunctionParamsInterface} params
 * @returns {void} void
 */
export const getChats: GetChatsFunction = ({
  page,
  dataShouldBeReplaced,
  successCallback,
  failureCallback,
}) => {
  dataShouldBeReplaced && actorSetActionValue('chatList', [], { replaceAll: true });

  actorDispatch(
    'getChatReport',
    {
      params: {
        reportId: chatsReportId,
        pagination: { page, perPage: 10 },
      },
      successCallback: successCallback ?? successGetChatsCallback,
      failureCallback: failureCallback ?? getChatsFailureCallback,
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * @function successGetChatsCallback
 * @param { ChatItemInterface[] } chatData
 * @returns { void } void
 */
export const successGetChatsCallback = (
  reportResponse: ChatReportResponseInterface<ChatItemInterface>,
): void => {
  const { data: chats, totalCount } = reportResponse;

  let currentChatsList = actorGetActionValue('chatList');

  if (isEmptyObject(currentChatsList)) {
    currentChatsList = {
      data: {},
      totalCount: 0,
    };

    actorSetActionValue('chatList', currentChatsList, {
      callerScopeName: 'successGetChatsCallback',
    });
  }

  if (!(Array.isArray(chats) && chats.length > 0)) {
    return;
  }

  const formattedChats = { ...currentChatsList!.data };
  for (let index = 0; index < chats.length; index++) {
    formattedChats[chats[index].personinfo_id] = {
      info: chats[index],
      messagesDetail: {
        data: [],
        hasMore: false,
      },
    };
  }

  actorDispatch(
    'chatList',
    {
      data: formattedChats,
      totalCount,
    },
    {
      callerScopeName: 'successGetChatsCallback',
    },
  );

  const currentChatData = actorGetActionValue('selectedChat'); //this actually actives chat on the user screen
  if (currentChatData?.info.personinfo_id) {
    const chatDataInfo = formattedChats[currentChatData.info.personinfo_id].info;
    actorDispatch(
      'selectedChat',
      {
        info: chatDataInfo,
        messagesDetail: currentChatData.messagesDetail,
      },
      {
        callerScopeName: 'successGetChatsCallback',
      },
    );
  }
};

/**
 * @function failureCallback
 * @param error
 * @returns { void } void
 */
export const getChatsFailureCallback = (error: unknown): void => {
  showNotification(error, 'error');
};

/**
 * it checks if date is equal to today then just shows time
 * else it shows date-time
 * @function getDate
 * @param { string } date
 * @returns { string }
 */
export const getDate = (date: string, locale: string): string => {
  const momentJalaliWithInitialDate = momentJalaali(date);

  const startOfToday = momentJalaali().startOf('day');
  const startOfDate = momentJalaliWithInitialDate.startOf('day');
  const daysDiff = startOfDate.diff(startOfToday, 'days');

  // its Today
  if (Math.abs(daysDiff) === 0) {
    return momentJalaliWithInitialDate.format('hh:mm');
  } else {
    {
      if (locale === 'fa') {
        return momentJalaliWithInitialDate.format('HH:mm,  jYY-jMM-jDD ');
      }

      return momentJalaliWithInitialDate.format('YYYY-MM-DD HH:mm');
    }
  }
};

/**
 * @function getMessageSenderUser
 * @param {FullProfileInterface | null} profileData
 * @returns {ChatItemInterface | null} a chat info object or null
 */
export const getMessageSenderUser = (
  profileData?: FullProfileInterface | null,
): ChatItemInterface | null => {
  let _profileData = profileData;
  if (_profileData == null) {
    _profileData = actorGetActionValue('profile');
  }

  if (isEmptyObject(_profileData?.globalParameters)) return null;

  return {
    personinfo_id: +_profileData!.globalParameters!.currentUserID,
    personname: _profileData!.globalParameters!.currentUserName,
    personimage: '/' + getUserAvatarRelativeUrl(),
    frompersoninfoname: _profileData!.globalParameters!.currentUserName,
    chatdate: '',
    chattext: '',
    ischannel: false,
    isgroupadmin: 0,
    isowner: 0,
    mentionchatid: 0,
    sumnotseen: 0,
  };
};

let fakeMessageId = -1; // To create a local message and to sync it later by the server response
export const createNewMessage = (params: {
  senderUser: ChatItemInterface;
  receiverUser: ChatItemInterface;
  chatTextData: ChatTextJsonFormatInterface;
  fileUrl?: string;
}): MessageItemInterface & { chatTextData: ChatTextJsonFormatInterface } => {
  const { chatTextData, senderUser, receiverUser, fileUrl } = params;

  return {
    chattext: JSON.stringify(chatTextData),
    chatdate: 'now',
    frompersoninfo_id: senderUser.personinfo_id,
    personimage: senderUser.personimage,
    personname: senderUser.personname,
    topersoninfo_id: receiverUser.personinfo_id,
    fileurl: fileUrl ?? null,
    chat_id: fakeMessageId--,
    isdeleted: false,
    isedited: false,
    isfrommyself: 1,
    isgroupsupervisor: false,
    isseen: false,
    replyofchat_id: null,
    replychattext: null,
    replyfileurl: null,
    replypersonimage: '',
    replypersonname: '',
    tscode: -1,
    ispin: false,
    chatTextData,
    fromgroup: !isEmpty(receiverUser.groupuid),
  };
};

const editMessageHandler = (params: {
  newMessage: MessageItemInterface;
  toEditMessage: SelectedMessageInterface;
  messagesList: MessageItemInterface[];
}): MessageItemInterface[] => {
  const { newMessage, messagesList, toEditMessage } = params;

  const targetMessageIndexInChatMessages = messagesList.findIndex(
    _message => _message.chat_id === toEditMessage.data.chat_id,
  );
  if (targetMessageIndexInChatMessages === -1) return messagesList;

  messagesList[targetMessageIndexInChatMessages].chattext = newMessage.chattext;
  messagesList[targetMessageIndexInChatMessages].isedited = true;

  // Update messages that have been replied already(with current edited chat(message!!) id)
  for (const message of messagesList) {
    if (message.replyofchat_id !== toEditMessage.data.chat_id) continue;
    message.replychattext = newMessage.chattext;
  }

  return [...messagesList];
};

const replyMessageHandler = (params: {
  newMessage: MessageItemInterface;
  senderUser: ChatItemInterface;
  toReplyMessage: SelectedMessageInterface;
  messagesList: MessageItemInterface[];
}): {
  updatedNewMessage: MessageItemInterface;
  updatedMessagesList: MessageItemInterface[];
} => {
  const { newMessage, senderUser, messagesList, toReplyMessage } = params;

  const targetMessageIndexInChatMessages = messagesList.findIndex(
    _message => _message.chat_id === toReplyMessage.data.chat_id,
  );
  if (targetMessageIndexInChatMessages === -1)
    return {
      updatedNewMessage: newMessage,
      updatedMessagesList: messagesList,
    };

  newMessage.replychattext = toReplyMessage.data.chattext;
  newMessage.replypersonimage = senderUser.personimage;
  newMessage.replypersonname = senderUser.personname;
  newMessage.replyofchat_id = toReplyMessage.data.chat_id;
  newMessage.replyfileurl = toReplyMessage.data.fileurl;
  newMessage.chatdate = 'now';

  messagesList.unshift(newMessage);

  return {
    updatedNewMessage: { ...newMessage },
    updatedMessagesList: [...messagesList],
  };
};

let fakeChatId = -1; // To create a local chat
const sendMessage = (
  params: {
    newMessage: MessageItemInterface & {
      chatTextData?: ChatTextJsonFormatInterface;
    };
  } & Omit<SendMessageParamsBase, 'receiverUser'>,
): MessageItemInterface[] => {
  const { newMessage, senderUser, prevMessages, selectedMessage } = params;

  const finalMessage: SelectedMessageInterface = {
    data: { ...newMessage },
    mode: 'new',
  };

  let updatedMessagesList: MessageItemInterface[] = [];

  if (isEmptyObject(selectedMessage)) {
    // A new message
    updatedMessagesList = [finalMessage.data, ...prevMessages];
  } else {
    finalMessage.mode = selectedMessage.mode;

    if (selectedMessage.mode === 'forward') {
      finalMessage.data.fileurl = selectedMessage.data.fileurl;
      updatedMessagesList = [finalMessage.data, ...prevMessages];
    }

    if (selectedMessage.mode === 'edit') {
      finalMessage.data = { ...selectedMessage.data, chattext: newMessage.chattext };
      updatedMessagesList = editMessageHandler({
        newMessage: { ...finalMessage.data },
        toEditMessage: selectedMessage,
        messagesList: [...prevMessages],
      });
    }

    if (selectedMessage.mode === 'reply') {
      const { updatedNewMessage, updatedMessagesList: _updatedMessagesList } =
        replyMessageHandler({
          newMessage,
          senderUser,
          toReplyMessage: selectedMessage,
          messagesList: [...prevMessages],
        });

      updatedMessagesList = _updatedMessagesList;
      finalMessage.data = updatedNewMessage;
    }
  }

  const chats = actorGetActionValue('chatList')!;
  const sendToPersonId = newMessage.topersoninfo_id;
  if (
    selectedMessage?.mode !== 'edit' ||
    selectedMessage.data.chat_id === prevMessages[0].chat_id
  ) {
    /**
     * Re-order selected chat based on the user has been sent a new messages to it
     */
    // prettier-ignore
    chats.data[sendToPersonId].info.chattext = newMessage.chatTextData?.rawText ?? '';
    chats.data[sendToPersonId].info.chatdate = 'now';
    chats.data[sendToPersonId].info.chatid = fakeChatId-- + '';
  }

  if (newMessage.fromgroup) {
    chats.data[sendToPersonId].info.frompersoninfoname = newMessage.personname;
  }

  actorDispatch(
    'chatList',
    {
      data: { ...chats.data },
      totalCount: chats.totalCount,
    },
    {
      replaceAll: true,
      callerScopeName: 'sendMessage',
    },
  );

  // Should to set re-ordered messages
  actorSetActionValue(
    'selectedChat',
    {
      ...chats.data[sendToPersonId],
      info: chats.data[sendToPersonId].info,
      messagesDetail: { data: updatedMessagesList, hasMore: true },
    },
    {
      replaceAll: true,
      callerScopeName: 'sendMessage',
    },
  );

  chatStatelessActions.sendContent({
    senderId: senderUser.personinfo_id,
    message: finalMessage,
  });

  return updatedMessagesList;
};

const sendTextMessage: (typeof sendMessageFunctions)['text'] = params => {
  const { content, senderUser, receiverUser, prevMessages, selectedMessage } =
    params;

  const newMessage = createNewMessage({
    chatTextData: content,
    senderUser,
    receiverUser,
  });

  return sendMessage({
    newMessage,
    senderUser,
    prevMessages: [...prevMessages],
    selectedMessage,
  });
};

const sendMultiFileMessages = (
  params: { files: UploadedFileInterface[] } & SendMessageParamsBase,
): MessageItemInterface[] => {
  const { files, senderUser, receiverUser, prevMessages, selectedMessage } = params;

  let updatedMessages: MessageItemInterface[] = [];

  for (const file of files) {
    const newMessage = createNewMessage({
      senderUser,
      receiverUser,
      fileUrl: JSON.stringify(file),
      chatTextData: {
        rawText: file.realFileName ?? 'File',
        formattedText: '',
        version: CHAT_VERSION,
        messageType: ChatMessageTypeEnum.FILE,
        meta: {
          file,
        },
      },
    });

    updatedMessages = sendMessage({
      newMessage,
      senderUser,
      selectedMessage,
      prevMessages:
        updatedMessages.length > 0 ? [...updatedMessages] : [...prevMessages],
    });
  }

  return updatedMessages;
};

const sendAlbumMessage: (typeof sendMessageFunctions)['album'] = params => {
  const { content, senderUser, receiverUser, prevMessages, selectedMessage } =
    params;

  if (isEmpty(content.rawText)) {
    const { imageUrls } = content.meta as AlbumMessageMetaInterface;
    content.rawText = imageUrls.map(item => item.realFileName ?? '').join(', ');
  }

  const newMessage = createNewMessage({
    chatTextData: content,
    senderUser,
    receiverUser,
  });

  return sendMessage({
    newMessage,
    senderUser,
    selectedMessage,
    prevMessages,
  });
};

const sendFileMessage: (typeof sendMessageFunctions)['file'] = params => {
  const { content, senderUser, receiverUser, prevMessages, selectedMessage } =
    params;

  let updatedMessages: MessageItemInterface[] = [];

  const { files } = content.meta as FileMessageMetaInterface;

  // New file message processing
  if (files.length === 1) {
    if (isEmpty(content.rawText)) {
      content.rawText = files[0].realFileName ?? 'File';
    }

    const newMessage = createNewMessage({
      chatTextData: content,
      senderUser,
      receiverUser,
      fileUrl: JSON.stringify(files[0]),
    });

    updatedMessages = sendMessage({
      newMessage,
      senderUser,
      selectedMessage,
      prevMessages:
        updatedMessages.length > 0 ? [...updatedMessages] : [...prevMessages],
    });

    return updatedMessages;
  }

  updatedMessages = sendMultiFileMessages({
    files,
    senderUser,
    receiverUser,
    selectedMessage,
    prevMessages:
      updatedMessages.length > 0 ? [...updatedMessages] : [...prevMessages],
  });

  return updatedMessages;
};

const sendVideoMessage: (typeof sendMessageFunctions)['video'] = params => {
  const { content, senderUser, receiverUser, prevMessages, selectedMessage } =
    params;

  const { link } = content.meta as VideoMessageMetaInterface;

  const newMessage = createNewMessage({
    chatTextData: content,
    senderUser,
    receiverUser,
    fileUrl: JSON.stringify(link),
  });

  return sendMessage({
    newMessage,
    senderUser,
    selectedMessage,
    prevMessages,
  });
};

const sendLocationMessage: (typeof sendMessageFunctions)['location'] = params => {
  const { content, senderUser, receiverUser, prevMessages, selectedMessage } =
    params;

  const { coordinates, imageUrl } = content.meta as LocationMessageMetaInterface;

  const newMessage = createNewMessage({
    chatTextData: content,
    senderUser,
    receiverUser,
    fileUrl: JSON.stringify({ coordinates, imageUrl }),
  });

  return sendMessage({
    newMessage,
    senderUser,
    selectedMessage,
    prevMessages,
  });
};

const sendMessageFunctions: {
  [key in keyof ChatMessageContentInterface]: ChatMessageContentInterface[key];
} = {
  text: sendTextMessage,
  file: sendFileMessage,
  album: sendAlbumMessage,
  forward: sendTextMessage,
  video: sendVideoMessage,
  location: sendLocationMessage,
};

export const messageSendManager = <T extends keyof ChatMessageContentInterface>(
  params: MessageSendManagerParamsType<T>,
): MessageItemInterface[] => {
  const { messageType, content, selectedMessage } = params;

  const senderUser = getMessageSenderUser();
  if (!senderUser) {
    console.warn('messageSendManager: `senderUser` not found');
    return [];
  }

  const chatData = actorGetActionValue('selectedChat');
  if (isEmptyObject(chatData)) {
    console.warn('messageSendManager: `chatData` not found');
    return [];
  }

  let updatedMessages: MessageItemInterface[] = [];

  if (typeof sendMessageFunctions[messageType as string] === 'function') {
    updatedMessages = sendMessageFunctions[messageType as string]({
      content,
      senderUser,
      selectedMessage: selectedMessage ?? actorGetActionValue('selectedMessage'),
      receiverUser: chatData.info,
      prevMessages: chatData.messagesDetail?.data ?? [],
    } as SendMessageParamsType);
  }

  return updatedMessages;
};

/**
 * @function chatSectionSignalRManager
 * @param {} signalRMessage - ...
 * @returns {void} void
 */
export const chatSectionSignalRManager = (
  signalRMessages: MessageInSignalRType[],
  senderUser: ChatItemInterface,
): void => {
  if (!Array.isArray(signalRMessages) || signalRMessages.length === 0) return;

  const _signalRMessages: MessageInSignalRType[] = [];
  for (const signalRMessage of signalRMessages) {
    _signalRMessages.push(
      objectToLowerCaseProperties(signalRMessage) as MessageInSignalRType,
    );
  }

  const signalRMessageType = getSignalRMessageType(_signalRMessages);
  switch (signalRMessageType) {
    case 'INSERT':
      if (_signalRMessages[0].frompersoninfo_id === senderUser.personinfo_id) break;
      handleInsertNewSignalRMessage(_signalRMessages[0]);
      break;

    case 'DELETE': {
      const messages = createFormattedMessagesFromSignalRMessages(signalRMessages);
      handleDeleteMessage(messages);
      break;
    }

    case 'UPDATE':
      handleUpdateMessage(_signalRMessages);
      break;

    default:
      break;
  }

  actorDispatch('updateTotalUnSeen', true);
};

/**
 * get current user id from global parameters
 * @function getCurrentUser
 * @returns {{ id: string; personName: string } | null}
 */
export const getCurrentUser = (): { id: string; personName: string } | null => {
  const currentUser = actorGetActionValue('profile')?.globalParameters;
  return isEmpty(currentUser?.currentUserID)
    ? null
    : {
        id: String(currentUser!.currentUserID),
        personName: currentUser!.currentUserName,
      };
};

const sessionId = getValue(SESSION_ID);

/**
 * @function getSignalRMessages
 * @param { (signalRMessage: MessageInSignalRType) => void } callback - ...
 * @returns { void }
 */
export const getSignalRMessages = (
  callback: (signalRMessages: MessageInSignalRType[]) => void,
): void => {
  getEventValue({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'OnChatRecieved',
    onEventCallback: callback,
  });
};

/**
 * @function getUsersOnlineStatus
 * @param { (usersStatus: UsersOnlineStatusResponseInterface[]) => void } callback - ...
 * @returns { void }
 */
export const getUsersOnlineStatus = (
  callback: (usersStatus: UsersOnlineStatusResponseInterface[]) => void,
): void => {
  getEventValue({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'OnClientStatusQuery',
    onEventCallback: callback,
  });
};

/**
 * setup is typing listener
 * @function setUpUsersIsTypingListener
 * @param { (usersStatus: string[]) => void } callback
 * @returns {void}
 */
export const setUpUsersIsTypingListener = (
  callback: (
    isTypingUserId: string,
    groupId: string,
    isTypingPersonName: string,
  ) => void,
): void => {
  getEventValue({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'OnIsTypingReceived',
    onEventCallback: callback,
  });
};

/**
 * @function getSignalRMessages
 * @returns { Promise<void> }
 *
 */
export const getChatTotalUnSeen = (): void => {
  getEventValue({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'OnSumNotSeenReceived',
    onEventCallback: handleUpdateUnseenMessages,
  });
};

/**
 * set client contacts
 * @function setClientContacts
 * @param {string[]} userContacts
 * @returns {void} void
 */
export const setClientContacts = (userContacts: string[]): void => {
  fireSignal({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'SetClientContact',
    args: [getCurrentUser()?.id, userContacts],
  });
};

/**
 * broadcast user is online
 * @function broadcastUserIsOnline
 * @returns {void} void
 */
export const broadcastUserIsOnline = (): void => {
  fireSignal({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'SetClientOnline',
    args: [getCurrentUser()?.id],
  });
};

/**
 * remove old typing status
 * @function validateUsersIsTyping
 * @returns {void}
 */
export const validateUsersIsTyping = (): void => {
  const isTypingUsers = actorGetActionValue('isTypingUsers') ?? {};

  const now = new Date();
  const newValidValues: Record<string, ChatIsTypingInterface> = {};

  for (const userId in isTypingUsers) {
    const deferenceTime =
      (now as unknown as number) - (isTypingUsers[userId].time as unknown as number); // it got as number because dates will compare as numbers and returns milliseconds

    if (deferenceTime < IS_TYPING_REFRESH_RATE) {
      newValidValues[userId] = isTypingUsers[userId];
    }
  }

  actorDispatch('isTypingUsers', newValidValues, {
    replaceAll: true,
    disableDebounce: true,
  });
};

/**
 * request users status
 * @function requestUsersStatus
 * @param {string[]} userContacts
 * @returns {void} void
 */
export const requestUsersStatus = (userContacts: string[]): void => {
  fireSignal({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'GetClientStatus',
    args: [getCurrentUser()?.id, userContacts],
  });
};

/**
 * @function setUsersIsTyping
 * @param {string[]} userContacts
 * @returns {void}
 */
export const setUsersIsTyping = (userContacts: string[], groupId: string): void => {
  const { id, personName } = getCurrentUser()!;

  fireSignal({
    connectionType: CHAT_SIGNAL,
    connectionUrl: chatSocketAddress,
    userId: sessionId,
    signalREvent: 'SetClientIsTyping',
    args: [id, userContacts, groupId, personName],
  });
};

/**
 * @function searchForUsersInGroup
 * @param {ChatUsersInterface[]} groupUsers
 * @param {string} targetUserName
 * @returns {ChatUsersInterface[]} founded users
 */
export const searchForUsersInGroup = (
  groupUsers: ChatUsersInterface[],
  targetUserName: string,
): ChatUsersInterface[] => {
  return lodashFilter(groupUsers, user => {
    if (
      replaceArabicCharacters(user.fullname).includes(
        replaceArabicCharacters(targetUserName),
      )
    ) {
      return user;
    }
  });
};

/**
 * get difference in milliseconds
 * @function getDifferenceBetweenCustomTimeToNow
 * @param {string} customTime
 * @returns {number}
 */
export const getDifferenceBetweenCustomTimeToNow = (customTime: string): number => {
  const userLastSeen = new Date(customTime);
  const now = new Date();
  const deferenceTime =
    (now as unknown as number) - (userLastSeen as unknown as number); // it got as number because dates will compare as numbers and returns milliseconds

  return deferenceTime;
};

/**
 * @function pinOrUnpinMessage
 * @param {MessageItemInterface} message - data of a message
 * @returns {void} void
 */
export const pinOrUnpinMessage = (message: MessageItemInterface): void => {
  actorDispatch('crudAction', {
    type: RUN_SERVICE,
    actionUniqueId: pinMessageActionUniqueId,
    data: {
      params: {
        IsPin: !message.ispin,
        ChatID: message.chat_id,
        OtherPersonInfo_id: message.topersoninfo_id,
      },
    },
    onSuccess: (response: ApiRequestResultInterface) => {
      actorDispatch('selectedMessage', {
        data: { ...message, ispin: !message.ispin },
        mode: 'pin',
      } as SelectedMessageInterface);

      showNotification(response.userMessage, 'success');
    },
    onFailure: error => {
      // FIXME: @honarvar @alasti: We don't have to check type of `error` here
      if (typeof error === 'string') {
        showNotification(error, 'error');
      }
    },
  });
};

export const isAdminLoggedUser = (userList: ChatUsersInterface[] | undefined) => {
  const userId = getValue(USER_ID);
  const loggedUser = userList?.find(userInfo => userInfo.personinfo_id === userId);
  return loggedUser?.isadmin || loggedUser?.isowner;
};
