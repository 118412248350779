import { actorGetActionValue } from '../../type/actor-setup';
import type { MessageInSignalRType } from './chat-section.type';
import { updateChatMessages, handleCreateNewChat } from './chat-section.helper';

/**
 * @function handleInsertNewSignalRMessage
 * @param { MessageItemInterface } signalRMessage
 * @returns { void }
 */

export const handleInsertNewSignalRMessage = (
  signalRMessage: MessageInSignalRType,
): void => {
  const personInfoId = signalRMessage.fromgroup
    ? signalRMessage.topersoninfo_id
    : signalRMessage.frompersoninfo_id;

  const currentChatData = actorGetActionValue('selectedChat');
  if (currentChatData?.info.personinfo_id === personInfoId) {
    updateChatMessages(currentChatData, signalRMessage, true);
    return;
  }

  // prettier-ignore
  const targetChatData = actorGetActionValue('chatList')?.data[personInfoId];
  if (!targetChatData) {
    handleCreateNewChat(personInfoId, signalRMessage);
  } else {
    updateChatMessages(targetChatData, signalRMessage);
  }

  // if (currentSelectedUser) {
  //   if (
  //     lodashIsEqual(
  //       currentSelectedUser.personinfo_id,
  //       signalRMessage.frompersoninfo_id,
  //     )
  //   ) {
  //     // insert new message to this active chat
  //     handleCreateNewMessage(signalRMessage);
  //     // seen that message
  //     handleSeenMessage({
  //       otherpersoninfo_id: signalRMessage?.frompersoninfo_id,
  //       FromChatID: signalRMessage?.chat_id,
  //       ToChatID: signalRMessage?.chat_id,
  //     });
  //   } else {
  //     // update sidebar chats
  //     !signalRMessage?.isfrommyself && handleUpdateSignalRChat(signalRMessage);
  //   }
  // } else {
  //   // update sidebar chats
  //   !signalRMessage?.isfrommyself && handleUpdateSignalRChat(signalRMessage);
  // }
};
