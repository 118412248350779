import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '2rem',
    fontFamily: 'sans-serif',
    textAlign: 'justify',
    '& .MuiFormControl-root': {
      margin: theme.spacing(1),
      width: '236px',
    },
    '& input[type="checkbox"]': {
      //take the span in the checkbox and move it to the right
      '& + span': {
        marginTop: '0.1rem',
        marginLeft: '0.8rem',
      },
    },
    '& input[type="radio"]': {
      //take the span in the checkbox and move it to the right
      '& + span': {
        marginTop: '0.1rem',
        marginInlineEnd: '0.8rem',
        marginInlineStart: '1.5rem',
      },
    },
    width: '100%',
    scale: '0.96',
  },

  formBuilder: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#f5f5f5',
  },

  resultFormContainer: {
    display: 'none',
  },

  saveFormButton: {
    marginTop: '1rem',
    marginLeft: '6rem',
  },

  formViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    position: 'relative',
  },

  headerButton: {
    marginLeft: '1rem',
  },

  formBuilderContainer: {
    textAlign: 'center',
  },
}));
export default useStyles;
