import { forwardRef, type FC } from 'react';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core';

import type { TextWithEffectViewInterface } from './text-with-effect.type';

const TextWithEffectView: FC<TextWithEffectViewInterface> = forwardRef(
  (props, forwardedRef) => {
    const { style } = props;

    const theme = useTheme();

    /**
     * here there is no text in typography, because the effected text
     * will be append in it from controller using forwardedRef
     */
    return (
      <Typography
        style={{ color: theme.palette.grey[700], ...style }}
        ref={forwardedRef as React.MutableRefObject<HTMLSpanElement> | null}
        variant="subtitle2"
      ></Typography>
    );
  },
);

export default TextWithEffectView;
