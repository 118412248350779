import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 5,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.primary.gray}`,
    margin: '10px 0px',
  },
  title: {
    textAlign: 'start',
    width: '100%',
    padding: '0px 10px',
  },
  itemsContainer: {
    width: '100%',
    flex: 2,
    padding: '0px 10px',
  },
  formControlLabel: {
    height: '100%',
    '& span': {
      fontSize: 'small',
    },
  },
}));
