import { memo, ReactElement } from 'react';
import { useStyles } from './word-cloud.style';
import { WordCloudViewInterface } from './word-cloud.type';
import { Typography } from '@material-ui/core';
import ReactWordcloud from 'react-wordcloud';
import { isEmpty } from '../../../helper/data-helper';

const WordCloudView = (props: WordCloudViewInterface): ReactElement => {
  const { data, height, width, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ width, height }}>
      {!isEmpty(title) && (
        <Typography className={classes.title} variant="h5">
          {title}
        </Typography>
      )}
      <div className={classes.chart} style={{ width, height }}>
        <ReactWordcloud words={data} />
      </div>
    </div>
  );
};

export default memo(WordCloudView);
