import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    width: 600,
    height: 600,
  },

  mapContainerParent: {
    overflow: 'hidden',
  },

  mapContainer: {
    width: '100%',
    height: 500,
  },

  informationLayer: {
    padding: 16,
  },

  linearProgress: {
    paddingTop: 5,
  },

  message: {
    marginBottom: 12,
    color: theme.palette.primary.borderSelectMarker,
  },

  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },

  confirmButton: {
    marginInlineEnd: 8,
    background: theme.palette.primary.activeButton,
    color: theme.palette.secondary.contrastText,
  },

  loadingTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  loadingText: {
    paddingInlineStart: 10,
  },
}));
