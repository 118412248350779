import moment, { MomentInput } from 'moment';
import jMoment from 'moment-jalaali';

import { CONFIG_CALENDAR, CONFIG_LOCALE, getValue } from '../core/configProvider';
import { isEmpty } from './data-helper';

/**
 * Return a date format based on user preference and type calendar
 * @function getInputFormat
 * @param {string} fieldCalendarType
 * @returns {string}
 */
export const getInputFormat = (fieldCalendarType: string): string => {
  const localeConfig = getValue(CONFIG_LOCALE);
  const typeCalendar = getCalendarType(fieldCalendarType);
  return typeCalendar === 'gregorian' || localeConfig === 'en'
    ? 'YYYY-MM-DD'
    : 'jYYYY-jMM-jDD';
};

/**
 * @function calculateDaysDiff
 * @param {MomentInput} date
 * @returns {number}
 */
export const calculateDaysDiff = (date: MomentInput): number => {
  return moment(date)
    .startOf('days')
    .diff(moment().format('YYYY-MM-DD'), 'days', true);
};

/**
 * Convert date to a human readable string
 * @function parseDate
 * @param {MomentInput} date
 * @param {Function} translate
 * @param {boolean} withTime
 * @returns {string}
 */
export const parseDate = (
  date: MomentInput,
  translate: Function,
  withTime?: boolean,
): string | undefined => {
  if (!date) {
    return;
  }
  const diff = calculateDaysDiff(date);

  let output = '';

  if (diff < 0 || isNaN(diff)) {
    return translate('todo.invalidDate');
  } else if (diff == 0) {
    output = 'today';
  } else if (diff == 1) {
    output = 'tomorrow';
  } else if (diff == 2) {
    output = 'overmorrow';
  } else if (diff == 7) {
    output = 'nextWeek';
  }
  const translateText = translate(`todo.${output}`);
  const outputText = withTime
    ? translateText + ' ' + formatDate(date, 'time')
    : translateText;
  return isEmpty(output)
    ? formatDate(date, withTime ? 'dateTime' : 'date')
    : outputText;
};

/**
 * Format a moment date to system date format based on user selected calender
 * @function formatDate
 * @param {MomentInput} date
 * @param {string} type
 * @returns {string}
 */
const formatDate = (
  date: MomentInput,
  type: 'dateTime' | 'time' | 'date',
): string => {
  const DATE_FORMAT = {
    dateTime: {
      gregorian: 'YYYY-MM-DD HH:mm',
      jalali: 'jYYYY-jMM-jDD HH:mm',
    },
    date: {
      gregorian: 'YYYY-MM-DD',
      jalali: 'jYYYY-jMM-jDD',
    },
    time: {
      gregorian: 'HH:mm',
      jalali: 'HH:mm',
    },
  };
  const FORMAT = DATE_FORMAT[type];

  const localeConfig = getValue(CONFIG_LOCALE);
  const calendarConfig = getValue(CONFIG_CALENDAR);
  if (calendarConfig === 'gregorian') {
    return moment(date).locale(localeConfig).format(FORMAT.gregorian);
  }
  return jMoment(date).locale(localeConfig).format(FORMAT.jalali);
};

/**
 * Convert human formatted string to system formatted date
 * @function createDateWithType
 * @param {string} type
 * @param {boolean} withTime
 * @returns {string}
 */
export const createDateWithType = (type: string, withTime?: boolean): string => {
  const date = moment().locale('en');
  const format = withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';

  switch (type) {
    case 'tomorrow':
      return date.add(1, 'days').format(format);

    case 'nextWeek':
      return date.add(1, 'week').format(format);

    default:
      return date.format(format);
  }
};

/**
 * Specify the calendar type according to the value fixCalendar
 * @function getCalendarType
 * @param {string} fieldCalendarType
 * @returns {string}
 */
export const getCalendarType = (fieldCalendarType: string): string => {
  const calendarConfig = getValue(CONFIG_CALENDAR);
  return isEmpty(fieldCalendarType) ? calendarConfig : fieldCalendarType;
};

/**
 * sample :
 *    '2020-01-02 23:00:00' => true
 *    '2020-01-02' => false
 * @function isFullDate
 * @param {string} date
 * @returns {boolean}
 */
export const isFullDateFormat = (date: string): boolean => {
  //prettier-ignore
  /* eslint-disable-next-line */
  const dateRegex = new RegExp(/^(\d{4})[\/-](0?[1-9]|1[012])[\/-](0?[1-9]|[12][0-9]|3[01]) ([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])/,);
  return dateRegex.test(date);
};
