import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import PropTypes from 'prop-types';
import { cloneElement, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { isEmbeddedPage } from '../helper/UrlHelper';

export const FORM_NAME = 'RaFilterForm';

const useStyles = makeStyles(
  theme => ({
    form: {
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      margin: 0,
      marginBottom: 8,
    },
    submitButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.appPrimaryBackgroundColor,
      alignSelf: 'center',
      margin: '5px 7px',
      height: 40,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }),
  { name: FORM_NAME },
 { dropInput:{display:'flex'}}
);

const sanitizeRestProps = ({
  anyTouched,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  displayedFilters,
  errors,
  filters,
  filterValues,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  hideFilter,
  initialize,
  initialized,
  initialValues,
  invalid,
  modified,
  pristine,
  pure,
  reset,
  resetSection,
  save,
  setFilter,
  setFilters,
  submit,
  submitAsSideEffect,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  touched,
  triggerSubmit,
  untouch,
  valid,
  validate,
  validating,
  values,
  visited,
  __versions,
  ...props
}) => props;

export const FilterForm = ({
  classes = {},
  className,
  resource,
  margin,
  variant,
  filters,
  displayedFilters,
  hideFilter,
  initialValues,
  showFilterByList,
  handleSubmit,
  submitting,
  pristine,
  ...rest
}) => {
  const translate = useTranslate();
  useEffect(() => {
    filters.forEach(filter => {
      if (filter.props.alwaysOn && filter.props.defaultValue) {
        throw new Error(
          'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.',
        );
      }
    });
  }, [filters]);

  const getShownFilters = useCallback(() => {
    Object.keys(displayedFilters).map(key => {
      if (!displayedFilters[key]) {
        initialValues[key] = null;
      }
    });

    return filters.filter(
      filterElement =>
        filterElement.props.alwaysOn ||
        displayedFilters[filterElement.props.source] === true ||
        (initialValues[filterElement.props.source] &&
          typeof initialValues[filterElement.props.source] !== 'undefined'),
    );
  }, [initialValues, displayedFilters, filters]);

  const handleHide = useCallback(filterName => hideFilter(filterName), [hideFilter]);

  if (isEmbeddedPage()) {
    return null;
  }

  return (
    <form
      className={classnames(className, classes.form)}
      {...sanitizeRestProps(rest)}
      onSubmit={handleSubmit}
    >
      {getShownFilters().map(filterElement =>
        cloneElement(filterElement, {
          key: filterElement.props.source,
          name: filterElement.props.source,
          handleHide: handleHide,
          // classes: classes,
          resource: resource,
          allowEmpty: true,
          margin: margin,
          variant: variant,
        }),
      )}
      {getShownFilters()?.length ? (
        <Button
          type="submit"
          id="submitFilterButton"
          variant="contained"
          className={classes.submitButton}
          disabled={submitting || pristine}
          data-style-submit="submitButton"
        >
          {translate('filter.apply')}
        </Button>
      ) : null}
    </form>
  );
};

FilterForm.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.object.isRequired,
  hideFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export const mergeInitialValuesWithDefaultValues = ({ initialValues, filters }) => ({
  ...filters
    .filter(
      filterElement =>
        filterElement.props.alwaysOn && filterElement.props.defaultValue,
    )
    .reduce(
      (acc, filterElement) =>
        lodashSet(
          { ...acc },
          filterElement.props.source,
          filterElement.props.defaultValue,
        ),
      {},
    ),
  ...initialValues,
});

const EnhancedFilterForm = ({ classes: classesOverride, ...props }) => {
  const classes = useStyles({ classes: classesOverride });

  const mergedInitialValuesWithDefaultValues =
    mergeInitialValuesWithDefaultValues(props);

  const { initialValues, ...rest } = props;
  const handleFinalFormSubmit = values => {
    props && props.setFilters(values);
  };

  return (
    <Form
      onSubmit={handleFinalFormSubmit}
      initialValues={mergedInitialValuesWithDefaultValues}
      mutators={{ ...arrayMutators }}
      render={formProps => <FilterForm classes={classes} {...formProps} {...rest} />}
    />
  );
};

// Options to instruct the FormSpy that it should only listen to the values and pristine changes
const FormSpySubscription = { values: true, pristine: true };

export default EnhancedFilterForm;
