import { forwardRef, useCallback, type ReactElement } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import VideoRecorder from 'react-video-recorder';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  useTheme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';
import EditIcon from '@material-ui/icons/Edit';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import ForwardIcon from '@material-ui/icons/Forward';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { useStyles } from './new-message.style';
import { checkIsRtlLanguage } from './new-message.helper';
import { CustomTheme } from '../../../core/themeProvider';
import { ChatUserList } from '../chat-user-list';
import { getSummeryMessageText } from '../chat-sidebar/user-list/chat-item/chat-item.helper';
import { getFileAddress } from '../messages-list/messages-list-helper/messages-list-common';
import { isImageFile } from '../../dynamic-input/file-input/file-input.helper';
import { checkJsonStringify } from '../../../helper/data-helper';
import { ChatMessageTypeEnum } from '../chat-section.helper';

import type {
  AlbumMessageMetaInterface,
  ChatTextJsonFormatInterface,
  NewMessageViewInterface,
  SelectedMessageInterface,
} from './new-message.type';

const NewMessageView = forwardRef(
  (props: NewMessageViewInterface, ref): ReactElement => {
    const {
      emojiContainerRef,
      message,
      handleInputChange,
      onSendMessage,
      onKeyPress,
      selectedMessageData,
      onCancelSelectedMessage,
      showEmojis,
      toggleEmojisHandler,
      handleOnMentionClick,
      onAddEmoji,
      groupUsers,
      isMentionListOpen,
      openDialog,
      openVideoChatDialog,
      closeVideoChatDialog,
      onVideoRecorded,
      sendVideoMessage,
      speedActions,
      closeSpeedDialSelect,
      openSpeedDialSelect,
      speedDialItemSelect,
      isSpeedDialSelectOpen,
      embeded,
      selectedUser,
    } = props;

    const classes = useStyles();
    const theme: CustomTheme = useTheme();
    const translate = useTranslate();
    const locale = useLocale();

    const getReplyTemplate = useCallback(
      (selectedMessageData: SelectedMessageInterface) => {
        return (
          <>
            <ReplyIcon
              style={{
                color: theme.palette.secondary.main,
                transform: locale !== 'en' ? 'rotate(180deg)' : undefined,
              }}
            />
            <div className={classes.replayMessageContainer}>
              <Typography className={classes.styleTypography}>
                {selectedMessageData.data.personname}
              </Typography>
              <Typography className={classes.styleTypography} component="p">
                {getSummeryMessageText(selectedMessageData.data.chattext, translate)}
              </Typography>
            </div>
          </>
        );
      },
      [],
    );

    const getEditTemplate = useCallback(
      (selectedMessageData: SelectedMessageInterface) => {
        // prettier-ignore
        const parseChatTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(selectedMessageData.data.chattext);
        if (parseChatTextResult === false) {
          return <></>;
        }

        const fileAddress = getFileAddress(selectedMessageData.data.fileurl);

        if (fileAddress && isImageFile(fileAddress.filePath)) {
          return (
            <>
              <EditIcon
                style={{
                  color: theme.palette.secondary.main,
                  transform: locale !== 'en' ? 'rotate(180deg)' : undefined,
                }}
              />
              <div className={classes.replayMessageContainer}>
                <Typography className={classes.styleTypography} component="p">
                  {fileAddress.realFileName}
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: parseChatTextResult.formattedText,
                  }}
                />
              </div>
            </>
          );
        }

        if (parseChatTextResult.messageType === ChatMessageTypeEnum.ALBUM) {
          const { imageUrls } =
            parseChatTextResult.meta as AlbumMessageMetaInterface;
          return (
            <>
              <EditIcon
                style={{
                  color: theme.palette.secondary.main,
                  transform: locale !== 'en' ? 'rotate(180deg)' : undefined,
                }}
              />
              <div className={classes.replayMessageContainer}>
                <Typography className={classes.styleTypography} component="p">
                  {imageUrls.length > 1
                    ? imageUrls[0].realFileName + '...'
                    : imageUrls[0].realFileName}
                </Typography>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: parseChatTextResult.formattedText,
                  }}
                />
              </div>
            </>
          );
        }

        return (
          <>
            <EditIcon
              style={{
                color: theme.palette.secondary.main,
                transform: locale !== 'en' ? 'rotate(180deg)' : undefined,
              }}
            />
            <div className={classes.replayMessageContainer}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: parseChatTextResult.formattedText,
                }}
              />
            </div>
          </>
        );
      },
      [],
    );

    const getForwardTemplate = useCallback(
      (selectedMessageData: SelectedMessageInterface) => {
        return (
          <div className={classes.container}>
            <span>
              <ForwardIcon
                className={locale !== 'en' ? classes.rtlDIrectionIcon : ''}
              />
            </span>
            <div className={classes.replayMessageContainer}>
              <Typography className={classes.styleTypography}>
                {selectedUser?.personname}
              </Typography>
              <Typography className={classes.styleTypography} component="p">
                {getSummeryMessageText(selectedMessageData.data.chattext, translate)}
              </Typography>
            </div>
          </div>
        );
      },
      [selectedUser],
    );

    return (
      <>
        {isMentionListOpen && (
          <ChatUserList users={groupUsers} handleClick={handleOnMentionClick} />
        )}
        {showEmojis && (
          <div
            ref={emojiContainerRef}
            style={{ position: 'fixed', bottom: '10%', zIndex: 1 }}
          >
            <Picker onSelect={onAddEmoji} />
          </div>
        )}
        {selectedMessageData && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={0.2}
            className={classes.replySection}
          >
            <Box display="flex" alignItems="center">
              {selectedMessageData.mode === 'reply' ? (
                getReplyTemplate(selectedMessageData)
              ) : selectedMessageData.mode === 'edit' ? (
                getEditTemplate(selectedMessageData)
              ) : selectedMessageData.mode === 'forward' ? (
                getForwardTemplate(selectedMessageData)
              ) : (
                <EditIcon
                  style={{
                    color: theme.palette.secondary.main,
                  }}
                />
              )}
            </Box>
            <IconButton onClick={onCancelSelectedMessage}>
              <CancelIcon />
            </IconButton>
          </Box>
        )}

        <Box display="flex" style={{ flexDirection: 'column' }}>
          <Box display="flex">
            <IconButton onClick={toggleEmojisHandler}>
              <EmojiEmotionsIcon
                style={{ color: theme.palette.grey[400], fontSize: '1.8rem' }}
              />
            </IconButton>
            <TextField
              dir={checkIsRtlLanguage(message) ? 'rtl' : 'ltr'}
              autoFocus={true}
              focused
              inputProps={{
                autoFocus: true,
              }}
              multiline
              style={{ width: '100%' }}
              variant="outlined"
              value={message}
              onChange={handleInputChange}
              onKeyPress={onKeyPress}
              inputRef={ref}
              className={classes.chatInput}
              InputProps={{
                classes: {
                  input: classes.chatInput,
                },
              }}
            />
            <Box ml={2} display="flex" flexDirection="row" alignItems="center">
              <IconButton
                className={classes.btnRoot}
                onClick={onSendMessage}
                style={{ height: 40, width: 40, marginRight: 10 }}
              >
                <SendIcon />
              </IconButton>
              {selectedMessageData?.mode !== 'forward' && (
                <>
                  {!embeded && (
                    <SpeedDial
                      ariaLabel={translate('quickAccess.quickAccess')}
                      className={classes.speedDial}
                      hidden={false}
                      icon={<SpeedDialIcon />}
                      onClose={closeSpeedDialSelect}
                      onOpen={openSpeedDialSelect}
                      open={isSpeedDialSelectOpen}
                      direction={'up'}
                    >
                      {speedActions.map(action => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.ComponentAsIcon}
                          tooltipTitle={action.name}
                          onClick={speedDialItemSelect(action.name)}
                        />
                      ))}
                    </SpeedDial>
                  )}
                  <p onClick={openDialog}>
                    <IconButton onClick={openDialog}>
                      <CameraAltIcon />
                    </IconButton>
                  </p>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Dialog
          className={classes.videoDialog}
          open={openVideoChatDialog}
          fullWidth
          aria-labelledby="show-image-dialog-title"
        >
          <DialogContent className={classes.videoContainer}>
            <VideoRecorder onRecordingComplete={onVideoRecorded} />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" variant="contained" onClick={sendVideoMessage}>
              {translate('mail.send')}
            </Button>
            <Button onClick={closeVideoChatDialog} variant="contained">
              {translate('confirm.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  },
);

export default NewMessageView;
