import { useTranslate } from 'react-admin';
import { useTheme } from '@material-ui/core';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

import { GadgetDefaultValuesTab } from './gadget-default-values-tab';
import { useStyles } from './setting-section.style';
import { GadgetSettingsTab } from './gadget-settings-tab';

import type { FC } from 'react';
import type { SettingSectionViewInterface } from './setting-section.type';

const SettingSectionView: FC<SettingSectionViewInterface> = props => {
  const { reports, gadgetValues, gadgetInternalChangeHandler } = props;

  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <Tabs direction={theme.direction} data-style-tabs="tabs">
        <TabPane key={0} tab={<p>{translate('gadget.gadgetSettings')}</p>}>
          <div className={classes.tabGroupsContainer}>
            <GadgetSettingsTab
              reports={reports}
              gadgetValues={gadgetValues}
              gadgetInternalChangeHandler={gadgetInternalChangeHandler}
            />
          </div>
        </TabPane>

        <TabPane key={1} tab={<p>{translate('gadget.defaultValues')}</p>}>
          <div className={classes.tabGroupsContainer}>
            <GadgetDefaultValuesTab
              gadgetValues={gadgetValues}
              gadgetInternalChangeHandler={gadgetInternalChangeHandler}
            />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SettingSectionView;
