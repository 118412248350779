import { makeStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  viewContainer: {
    height: '100%',
  },

  relationItem: {
    contain: 'content',
    borderRadius: theme.shape.borderRadius,
    margin: '0 1rem',
  },
}));
