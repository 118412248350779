import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  calendar: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  rootToolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipArrow: {
    color: theme.palette.primary.appSecondaryBackgroundColor,
    '&::before': {
      backgroundColor: theme.palette.common.white,
      border: '0.5px solid #C4C4C4',
    },
  },
  rootCustomToolbar: {
    minWidth: theme.spacing(20),
    minHeight: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
  },
  headerToolbar: {
    padding: 4,
    width: '100%',
    color: theme.palette.primary.appSecondaryTextColor,
  },
  bodyToolbar: {
    borderTop: '1px solid #C4C4C4',
    borderBottom: '1px solid #C4C4C4',
    padding: 8,
  },
  dateToolbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start',
  },
  picAvatar: {
    width: 20,
    height: 20,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 4px',
  },
  fieldLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    fontSize: 'medium',
    '&:hover': {
      textDecoration: 'underline',
    },
    pointerEvents: 'painted',
    margin: '8px',
  },
  containerCalendar: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleCalendar: {
    fontSize: 10,
  },
  labelCalendar: {
    fontSize: 10,
    maxHeight: 5,
  },
}));
