import { isEmptyObject } from '../../../helper/data-helper';
import lodashFind from 'lodash/find';
import { getProfileSettingDropdownPatternInfo } from '../../../helper/PatternMetaHelper';
import { actorGetActionValue } from '../../../type/actor-setup';
import { updateInputsState } from '../form.helper';
import { GlobalParametersInterface, ValidationError } from '../../../helper/Types';
import { InitialData, InputRefContent } from '../form.type';

/**
 * Prepare global parameters from `dropdownMeta`.
 * @function prepareGlobalParameters
 * @param {number} dropId
 * @returns {object}
 */
export const prepareGlobalParameters = (
  dropId: number,
  field: Record<string, unknown>,
): object => {
  if (isNaN(dropId)) return {};

  const globalParameters = actorGetActionValue(
    'profile',
    'profileData.globalparameters',
  ) as unknown as GlobalParametersInterface;

  const dropdownMeta = getProfileSettingDropdownPatternInfo(
    'profileSettingDropdown',
  );
  if (isEmptyObject(dropdownMeta)) {
    return globalParameters;
  }

  // else
  // prettier-ignore
  const { valueMember, valueMember2, currentWareHouseId, currentDepId, uniqueId } = dropdownMeta;

  const dropDownData = actorGetActionValue('dropDownData');

  let allDropDownData: Record<string, unknown>[] = [];
  if (dropDownData != null) {
    // prettier-ignore
    allDropDownData = (dropDownData[`${uniqueId}-${field.id}`] ?? dropDownData[uniqueId])?.DATA ?? [];
  }

  const dropdownItem: object = lodashFind(allDropDownData, {
    [valueMember]: dropId,
  });

  return {
    ...globalParameters,
    [currentDepId.toLowerCase()]: dropdownItem[valueMember2],
    [currentDepId]: dropdownItem[valueMember2],
    [currentWareHouseId.toLowerCase()]: dropdownItem[valueMember],
    [currentWareHouseId]: dropdownItem[valueMember],
  };
};

/**
 * @functions updateInputValues
 * @param { boolean } withInitialData
 * @returns { void } void
 */
export const updateInputValues = (withInitialData = false): void => {
  // currentResource get again because need resources to be updated
  const currentResource = actorGetActionValue('resources')!.current;
  const inputsRef = actorGetActionValue(
    'inputsRef',
    `${currentResource.value}.${currentResource.type}`,
  ) as Record<string, InputRefContent> | undefined;

  let data = {};
  if (withInitialData) {
    data =
      (actorGetActionValue('initialData', [
        currentResource.value,
        currentResource.type,
      ]) as InitialData | null) ?? {};
  } else {
    data =
      (actorGetActionValue('formData', [
        currentResource.value,
        currentResource.type,
      ]) as FormData | null) ?? {};
  }

  const formErrors =
    (actorGetActionValue('formMessages', [
      currentResource.value,
      currentResource.type,
    ]) as ValidationError | null) ?? {};

  updateInputsState({
    inputsRef,
    formErrors,
    formData: data,
  });
};
