import { FC, useEffect, useRef, memo } from 'react';
import { getAppSettings } from '../../helper/settings-helper';
import RelationPanelView from './relation-panel.view';
import useRelationData from './use-relation-data';
import LoadingBox from '../LoadingBox';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../../type/actor-setup';
import {
  preparedRelationPermission,
  shouldRelationRefresh,
} from './relation-panel.helper';
import {
  CONFIG_LIST_COLUMN_CHOICE,
  CONFIG_LIST_COLUMN_WIDTH,
  CONFIG_LIST_SORT,
  DEFAULT,
} from '../../core/configProvider';

import type { GeneralMetaData, MetaData } from '../../helper/Types';
import type { fieldSort } from '../grid/grid.type';
import type {
  RelationPanelBasePropsInterface,
  RelationPanelControllerInterface,
  RelationPermissions,
} from './relation-panel.type';
import { requestListData } from '../list/list.helper';
import { customScrollTo } from './note-relation/pinned-note/pinned-notes-helper';
import { apiRequestResultHandler } from '../../helper/crud-api.helper';

const RelationPanel: FC<RelationPanelControllerInterface> = props => {
  const {
    relationItemInMetaData,
    relationType,
    parentResource,
    parentRecordId,
    relationIndex,
    key,
    id,
  } = props;

  const { moduleName, moduleTableName, childFieldName, parentFieldName } =
    relationItemInMetaData;

  const relationResource = props.id;
  const actorOnDispatchCallbackIdRef = useRef<symbol>();
  const dragDropRef = useRef<HTMLDivElement>(null);

  /**
   * `basePropsVersionRef` is a counter and created to notice whenever something change in relationPanelBaseProps.
   * in each render in this file, this variable will increase one with `++` operation in line 365
   * and `RelationPanelView` is watching this value to handle itself's memoization.
   */
  const basePropsVersionRef = useRef<number>(0);

  useEffect(() => {
    actorOnDispatchCallbackIdRef.current = actorOnDispatch(
      'refreshView',
      resource => {
        shouldRelationRefresh(resource, relationResource) && refreshRelation();
      },
      {
        preserve: false,
      },
    );

    return () => {
      actorRemoveAction({
        actionName: 'refreshView',
        listenerId: actorOnDispatchCallbackIdRef.current,
      });
    };
  }, [relationResource]);

  const relationPath = `${moduleName}/${moduleTableName}/${childFieldName}`;

  // --------------- Parent Information ---------------
  const parentMetaData = actorGetActionValue(
    'metaData',
    parentResource,
  ) as MetaData | null;

  const parentRecord = actorGetActionValue(
    'record',
    `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown>; // when code arrive here , 100% parent record exists!

  const clearAllSelectedCheckboxes = (): void => {
    console.log('clearAllSelectedCheckboxes');
  };

  // --------------- Handle Meta ---------------
  const [
    relationMetaData,
    relationData,
    isRelationDataReady,
    getNewRelationMetaAndRefreshData,
    metaDataError,
    handleGetRelationDataRequestFailure,
  ] = useRelationData({
    relationResource,
    childFieldName,
    parentFieldName,
    parentIdentifier: parentRecord?.[parentFieldName] as string,
    relationIndex: relationIndex!, // in map loop we always have index
    relationType,
  });

  /**
   * get relation meta data if not exist, then request again and set new data in relation data
   * @function refreshRelation
   * @returns {void} void
   */
  const refreshRelation = (): void => {
    const metaDataInActor = actorGetActionValue('metaData');
    if (!relationMetaData || !metaDataInActor?.[relationResource]) {
      getNewRelationMetaAndRefreshData(
        (parentRecord?.[parentFieldName] as string) ?? parentRecordId,
      );
      return;
    }

    const isMultiResult =
      metaDataInActor?.[relationResource]?.['reportType'] === 'MultiResult';

    requestListData(
      isMultiResult && !relationData ? relationResource + '/0' : relationResource,
      relationType,
      (parentRecord?.[parentFieldName] as string) ?? parentRecordId, // FIXME
      childFieldName,
      parentFieldName,
      metaDataInActor?.[relationResource] as GeneralMetaData,
      undefined,
      apiRequestResultHandler,
      handleGetRelationDataRequestFailure,
    );
  };

  useEffect(() => {
    if (relationData == null) return;
    const getUniqIdToScroll = actorGetActionValue('scroll');

    setTimeout(() => {
      customScrollTo(getUniqIdToScroll?.uniqIdToScroll);
      actorSetActionValue('scroll', { uniqIdToScroll: '' });
    }, 150);
  }, [relationData?.lastRequestId]);

  if (!isRelationDataReady) {
    return <LoadingBox />;
  }

  // --------------- Handle Permissions ---------------
  const relationPermission: RelationPermissions = preparedRelationPermission(
    parentMetaData!,
    parentRecord!,
    relationItemInMetaData,
    relationMetaData,
  );

  // --------------- Handle Setting ---------------
  const defaultSelected = getAppSettings(
    `${DEFAULT}_${CONFIG_LIST_COLUMN_CHOICE}_${relationResource}`,
  ).value;

  const userSelected = getAppSettings(
    `${CONFIG_LIST_COLUMN_CHOICE}_${relationResource}`,
    true,
  ).value;

  const defaultColumnWidth = getAppSettings(
    `${DEFAULT}_${CONFIG_LIST_COLUMN_WIDTH}_${relationResource}`,
  ).value;
  const userColumnWidth = getAppSettings(
    `${CONFIG_LIST_COLUMN_WIDTH}_${relationResource}`,
    true,
  ).value;
  const userSort = getAppSettings(
    `${CONFIG_LIST_SORT}_${relationResource}`,
    true,
  ).value;
  // --------------- prepare base props ---------------
  const currentUrl =
    actorGetActionValue('urlInfo')?.location?.href ?? window.location.href ?? '';

  const parentInfo = {
    parentResource,
    parentId: parentRecord?.id as string,
    parentProcessUniqueId: parentRecord?.__processuniqueid as string | null,
    parentPositionId: parentRecord?.positionid as string | null,
    parentStateId: parentRecord?.stateid as string | null,
  };

  const relationPanelBaseProps: RelationPanelBasePropsInterface = {
    relationData: relationData?.data ?? [],
    relationDataCount: relationData?.totalCount ?? 0,
    relationPermission,
    relationMetaData: relationMetaData as GeneralMetaData,
    relationType,
    currentUrl,
    parentInfo,
    settings: {
      defaultSelected: defaultSelected as number[] | null,
      userSelected: userSelected as number[] | null,
      defaultColumnWidth: defaultColumnWidth as number | null,
      userColumnWidth: userColumnWidth as number | null,
      userSort: userSort as fieldSort,
    },
    relationResource,
    childFieldName,
    parentFieldName,
    basePropsVersion: basePropsVersionRef.current++,
  };

  const idTarget =
    relationType === 'note'
      ? 'notes'
      : relationType === 'report' || relationType === 'multiReport'
      ? id
      : moduleTableName;

  return (
    <RelationPanelView
      refreshRelation={refreshRelation}
      clearAllSelectedCheckboxes={clearAllSelectedCheckboxes}
      relationPanelBaseProps={relationPanelBaseProps}
      idTarget={idTarget}
      relationIndex={relationIndex}
      relationData={relationData}
      relationItemInMetaData={relationItemInMetaData}
      parentResource={parentResource}
      relationPath={relationPath}
      parentRecord={parentRecord}
      metaDataError={metaDataError}
      dragDropRef={dragDropRef}
      key={key}
    />
  );
};

export default memo(RelationPanel, (prevProps, nextProps) => {
  return (
    prevProps.parentRecordId === nextProps.parentRecordId &&
    prevProps.parentResource === nextProps.parentResource &&
    prevProps.processInfo.positionid === nextProps.processInfo.positionid &&
    prevProps.processInfo.processuniqueid ===
      nextProps.processInfo.processuniqueid &&
    prevProps.processInfo.stateid === nextProps.processInfo.stateid &&
    prevProps.key === nextProps.key
  );
});
