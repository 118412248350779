import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Dropdown } from 'semantic-ui-react';
import { useStyles } from './color-select-filter-input.style';
import type { ColorSelectFilterInputViewInterface } from './color-select-filter-input.type';

const ColorSelectFilterInputView = (
  props: ColorSelectFilterInputViewInterface,
): ReactElement => {
  const { options, onChange, value, disabled, field, text } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.root} data-test-field-name={field.name}>
      <div className={classes.dropdownContainer}>
        <Dropdown
          data-test-dropdown-search-input
          fluid
          selection
          clearable
          closeOnBlur
          selectOnNavigation={false}
          noResultsMessage={translate('ra.navigation.no_results')}
          text={text}
          open={undefined}
          value={value}
          options={options}
          onChange={onChange}
          selectOnBlur={false}
          search={preparedOptions => preparedOptions}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default ColorSelectFilterInputView;
