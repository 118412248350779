import { type FC, memo, useCallback, useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';

import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { FormActions, FormData, InputRefContent, OnBlurParams } from '../../../form';
import WMSButtonToolbar from '../../wms-button-toolbar';
import { isEnterPressed } from '../../../../helper/FormHelper';
import {
  checkFormInputsSpecs,
  getFocusedInputName,
  updateInputsState,
} from '../../../form/form.helper';
import { WMSFormView } from './wms-form.view';
import {
  prepareRowListWMSForm,
  updateValuesOfInputsAfterSearchInputChanges,
} from '../../wms.helper';
import {
  checkCurrentFocusedFieldIsSearchField,
  formHandlerOnPressingEnterKey,
  mapSearchFieldDataToFormData,
  resetWMSForm,
  submitHandler,
  updateFormDataAndInputs,
} from './wms-form.helper';
import { showNotification } from '../../../../helper/general-function-helper';
import { getAsyncValidationInfoForField } from '../../../../helper/MetaHelper';
import { isEmpty, isEmptyObject } from '../../../../helper/data-helper';

import type { WMSFormHelperVariablesRef, WMSFormProps } from './wms-form.type';

const WMSFormController: FC<WMSFormProps> = memo(props => {
  // prettier-ignore
  const { wmsMetaData, formTabIndex, onSubmit, activeTabIndex, prevTabIndex } = props;

  const correctActiveTabIndex = activeTabIndex ?? 0;

  const translate = useTranslate();

  const wmsFormHelperVariablesRef = useRef<WMSFormHelperVariablesRef>({
    fieldList: [],
    fields: {},
    rowList: [],
    refs: null,
  });
  const serviceValidationExist = useRef(false);
  const serviceValidationDoneSuccessfully = useRef(false);
  const dropdownInProgress = useRef(false);
  const formContainerRef = useRef<HTMLDivElement>(null);
  const onDispatchDataRef = useRef<
    { actionName: keyof ActorActionList; id: symbol }[]
  >([]);

  useEffect(() => {
    const currentResource = actorGetActionValue('resources')!.current;

    let id = actorOnDispatch(
      'resetForm',
      (): void => {
        const fieldNameList =
          actorGetActionValue('formGlobalProps')!.inputNameListSortedByPriority;

        if (!isEmptyObject(wmsMetaData!.tabs[correctActiveTabIndex]?.item.table)) {
          // In `table` mode, we have to reset form data by ignoring incoming data
          resetWMSForm({
            fieldNameList,
            forceEmptyFields: true,
          });
        } else {
          // FIXME: Check print and action types
        }
      },
      {
        preserve: false,
      },
    );

    onDispatchDataRef.current.push({ actionName: 'resetForm', id });

    id = actorOnDispatch(
      'dropDownData',
      async dropDownData => {
        const currentResource = actorGetActionValue('resources')!.current;
        if (
          dropdownInProgress.current ||
          !checkCurrentFocusedFieldIsSearchField(
            wmsFormHelperVariablesRef.current.refs,
          ) // Prevent to process when dropdown is not a search field
        ) {
          return;
        }

        const focusedInputName = getFocusedInputName(
          wmsFormHelperVariablesRef.current.refs,
        );

        // prettier-ignore
        const { data, wmsSubmit, } = dropDownData?.[currentResource.value]?.[currentResource.type];

        // prettier-ignore
        let combinedFormDataWithDropdownData = {...data};

        if (!isEmpty(focusedInputName)) {
          if (combinedFormDataWithDropdownData == null) {
            wmsFormHelperVariablesRef.current.refs![
              focusedInputName!
            ].setInputValue?.('');

            return;
          }

          const currentFormData = (actorGetActionValue(
            'formData',
            `${currentResource.value}.${currentResource.type}`,
          ) ?? {}) as FormData;

          combinedFormDataWithDropdownData = mapSearchFieldDataToFormData(
            wmsFormHelperVariablesRef.current.refs?.[focusedInputName!].field,
            currentFormData,
            combinedFormDataWithDropdownData,
          );
        }

        dropdownInProgress.current = true;

        updateValuesOfInputsAfterSearchInputChanges(
          combinedFormDataWithDropdownData,
          wmsFormHelperVariablesRef.current.refs,
          wmsMetaData!.tabs[correctActiveTabIndex].item.action != null,
          wmsMetaData!.tabs[correctActiveTabIndex].item.table != null, // In `table` types that we have a grid in the current tab, we have to refresh it after searching
        );

        fieldServiceRunner();
        if (
          serviceValidationExist.current &&
          !serviceValidationDoneSuccessfully.current
        ) {
          dropdownInProgress.current = false;
          return;
        }

        fieldServiceRunner();
        if (
          serviceValidationExist.current &&
          !serviceValidationDoneSuccessfully.current
        ) {
          dropdownInProgress.current = false;
          return;
        }

        if (wmsSubmit) {
          submitHandler(onSubmit)();
          dropdownInProgress.current = false;

          return;
        }

        await formHandlerOnPressingEnterKey(
          wmsFormHelperVariablesRef.current.refs,
          onSubmit,
        );
        dropdownInProgress.current = false;
      },
      {
        preserve: false,
      },
    );

    onDispatchDataRef.current.push({ actionName: 'formData', id });

    id = actorOnDispatch(
      'formMessages',
      formMessages => {
        const currentResource = actorGetActionValue('resources')!.stack[0];
        const formData = actorGetActionValue('formData');

        if (
          formData?.[currentResource.value]?.[currentResource.type] == null ||
          formMessages?.[currentResource.value]?.[currentResource.type] == null
        ) {
          return;
        }

        updateInputsState({
          inputsRef: wmsFormHelperVariablesRef.current.refs,
          formErrors: formMessages[currentResource.value][currentResource.type],
          formData: formData[currentResource.value][currentResource.type],
        });
      },
      {
        preserve: false,
      },
    );

    onDispatchDataRef.current.push({ actionName: 'formMessages', id });

    document.addEventListener('keyup', onPressingEnterKey);

    actorSetActionValue('metaData', [], {
      path: `${currentResource.value}.processes`,
      callerScopeName: 'WMSFormController => useMemo',
    });

    return () => {
      document.removeEventListener('keyup', onPressingEnterKey);

      wmsFormHelperVariablesRef.current = {
        fieldList: [],
        fields: {},
        refs: null,
        rowList: [],
      };

      for (const actionData of onDispatchDataRef.current) {
        const { actionName, id } = actionData;
        actorRemoveAction({
          actionName,
          listenerId: id,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (
      activeTabIndex !== formTabIndex ||
      isEmptyObject(wmsFormHelperVariablesRef.current.fields)
    ) {
      return;
    }

    const rootResource = actorGetActionValue('resources')!.stack[0];
    const currentResource = actorGetActionValue('resources')!.current;

    const inputsRef = actorGetActionValue(
      'inputsRef',
      `${currentResource.value}.${currentResource.type}`,
    ) as Record<string, InputRefContent> | null;

    wmsFormHelperVariablesRef.current.refs = inputsRef;

    const formInputsSpecs = checkFormInputsSpecs(
      wmsFormHelperVariablesRef.current.refs!,
    );

    actorSetActionValue(
      'formGlobalProps',
      formInputsSpecs.ignoreToFocusFieldNameList,
      {
        path: 'ignoreToFocusFieldNameList',
        callerScopeName: 'WMSFormController 1 => useEffect',
      },
    );

    actorSetActionValue(
      'formGlobalProps',
      formInputsSpecs.keepValueAfterSubmitFieldNameList,
      {
        path: 'keepValueAfterSubmitFieldNameList',
        callerScopeName: 'WMSFormController 2 => useEffect',
      },
    );

    actorSetActionValue(
      'formGlobalProps',
      formInputsSpecs.keepFocusAfterSubmitFieldNameList,
      {
        path: 'keepFocusAfterSubmitFieldNameList',
        callerScopeName: 'WMSFormController 3 => useEffect',
      },
    );

    actorSetActionValue(
      'formGlobalProps',
      formInputsSpecs.inputNameListSortedByPriority,
      {
        path: 'inputNameListSortedByPriority',
        callerScopeName: 'WMSFormController 4 => useEffect',
      },
    );

    updateFormDataAndInputs(prevTabIndex ?? -1);

    updateInputsState({
      isWMSForm: true,
      inputsRef: wmsFormHelperVariablesRef.current.refs,
      formData: actorGetActionValue(
        'formData',
        `${currentResource.value}.${currentResource.type}`,
      ) as FormData | undefined,
    });

    actorGetActionValue(
      'formGlobalProps',
    )!.formFocusManagementFunctions.focusOnFirstInput(
      wmsFormHelperVariablesRef.current.refs!,
    );

    actorSetActionValue('metaData', wmsFormHelperVariablesRef.current.fields, {
      path: `${currentResource.value}.fields`,
      callerScopeName: 'WMSFormController => useMemo',
    });

    actorSetActionValue('allFields', wmsFormHelperVariablesRef.current.fieldList, {
      path: `${rootResource.value}.${rootResource.type}`,
      callerScopeName: 'WMSFormController => useEffect',
    });
  }, [correctActiveTabIndex]);

  const onPressingEnterKey = useCallback(
    async (event: KeyboardEvent): Promise<void> => {
      if (
        !isEnterPressed(event) ||
        checkCurrentFocusedFieldIsSearchField(wmsFormHelperVariablesRef.current.refs)
      ) {
        return;
      }

      fieldServiceRunner();
      if (
        serviceValidationExist.current &&
        !serviceValidationDoneSuccessfully.current
      ) {
        return;
      }

      await formHandlerOnPressingEnterKey(
        wmsFormHelperVariablesRef.current.refs,
        submitHandler(onSubmit),
      );

      actorSetActionValue('wmsAsyncActionList', [], {
        replaceAll: true,
        callerScopeName: 'WMSFormController => wmsFormHandlerOnPressingEnterKey',
      });
    },
    [activeTabIndex],
  );

  const fieldServiceRunner = useCallback(() => {
    // Only type of `table` may have ‍a `service`
    if (
      isEmptyObject(
        wmsMetaData!.tabs[correctActiveTabIndex].item.table?.validationActions,
      )
    ) {
      return;
    }

    const currentFocusedInputName = getFocusedInputName(
      wmsFormHelperVariablesRef.current.refs!,
    );
    if (!currentFocusedInputName) {
      return;
    }

    const fieldValidationAction = getAsyncValidationInfoForField(
      wmsMetaData!.tabs[correctActiveTabIndex].item.table,
      wmsFormHelperVariablesRef.current.refs![currentFocusedInputName].field.id,
    );

    if (!fieldValidationAction) {
      return;
    }

    serviceValidationExist.current = true;

    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
    formActionsHandler(FormActions.InputBlur, {
      fieldName: currentFocusedInputName,
      value:
        wmsFormHelperVariablesRef.current.refs![currentFocusedInputName].inputRef
          .current!.value,
      runValidationSuccessCallback: (
        validationResultData: {
          [key: string]: unknown;
          actionOutput: {
            additionalData: { [key: string]: unknown; WmsSubmit: boolean };
          };
        } | null,
      ) => {
        serviceValidationDoneSuccessfully.current = true;

        // TODO: By checking `validationResultData` we can run `submitHandler(onSubmit)`
        if (validationResultData?.actionOutput.additionalData?.WmsSubmit === true) {
          submitHandler(onSubmit);
        } else {
          showNotification(translate('service.success'), 'success');
        }
      },
    } as OnBlurParams);
  }, [correctActiveTabIndex, onSubmit]);

  if (activeTabIndex !== formTabIndex) return null;

  const rowData = prepareRowListWMSForm(wmsMetaData!.tabs[correctActiveTabIndex]);

  wmsFormHelperVariablesRef.current.fieldList = rowData.fieldList;
  wmsFormHelperVariablesRef.current.fields = rowData.fields;
  wmsFormHelperVariablesRef.current.rowList = rowData.rowList;

  return (
    <>
      <WMSButtonToolbar
        wmsMetaData={wmsMetaData!}
        selectedTabIndex={correctActiveTabIndex}
      />
      <WMSFormView
        fullHeight
        formRef={formContainerRef}
        rowList={wmsFormHelperVariablesRef.current.rowList}
        fieldList={wmsFormHelperVariablesRef.current.fieldList}
        formTabIndex={correctActiveTabIndex}
        onSubmit={submitHandler(onSubmit)}
      />
    </>
  );
});

export default WMSFormController;
