import { FC, memo, useRef, KeyboardEvent } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import TodoStepItemsView from './todo-step-items.view';

import type { stepItemInterface } from '../todo-steps.type';
import type { TodoStepItemsControllerInterface } from './todo-step-items.type';

const TodoStepItemsController: FC<TodoStepItemsControllerInterface> = memo(props => {
  const { updateStep, removeStep, isLoading, stepItem } = props;
  const dragDropRef = useRef<HTMLDivElement>(null);
  const stepTitleInputRef = useRef<HTMLInputElement>(null);

  /**
   * @function handleChangeSort
   * @param {stepItemInterface} draggedItem
   * @returns { void } void
   */
  const handleChangeSort = (draggedItem: stepItemInterface): void => {
    draggedItem.RowOrder = stepItem.RowOrder / 2;
    updateStep(draggedItem);
  };

  /**
   * @function handleChangeTitle
   * @param {React.ChangeEvent<HTMLInputElement>} event
   * @returns { void } void
   */
  const handleChangeTitle = (event: KeyboardEvent<HTMLElement>): void => {
    //save step by Enter
    if (event.key !== 'Enter') return;
    updateStep({ ...stepItem, StepTitle: stepTitleInputRef.current?.value ?? '' });
  };

  /**
   * @function handleChangeDoneStatus
   * @returns { void } void
   */
  const handleChangeDoneStatus = (): void => {
    updateStep({ ...stepItem, IsDone: !stepItem.IsDone });
  };

  /**
   * @function handleRemoveStep
   * @returns { void } void
   */
  const handleRemoveStep = (): void => {
    removeStep(stepItem.id);
  };

  const [, connectDragSource] = useDrag(() => ({
    type: 'todoStepItems',
    item: stepItem,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }));

  const [{ isOver }, connectDropSource] = useDrop({
    accept: ['todoStepItems'],
    drop: handleChangeSort,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  connectDragSource(connectDropSource(dragDropRef));

  return (
    <TodoStepItemsView
      handleChangeDoneStatus={handleChangeDoneStatus}
      handleChangeTitle={handleChangeTitle}
      handleRemoveStep={handleRemoveStep}
      stepTitleInputRef={stepTitleInputRef}
      dragDropRef={dragDropRef}
      isLoading={isLoading}
      stepItem={stepItem}
      isOver={isOver}
    />
  );
});
export default TodoStepItemsController;
