import { FC } from 'react';
import { useTheme } from '@material-ui/core';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import { useLocale } from 'react-admin';

import { useStyles } from './multi-tab-list.style';

import type { MultiTabListViewProps } from './multi-tab-list.type';
import ListView from '../list/list.view';

const MultiTabListView: FC<MultiTabListViewProps> = props => {
  const { tabs, onTabChange, tabActiveKey, tabDoubleClickHandler, setFilters } =
    props;

  const classes = useStyles();
  const locale = useLocale();
  const theme = useTheme();

  return (
    <div className={classes.tabListContainer}>
      <div className={classes.tabsContainer} onDoubleClick={tabDoubleClickHandler}>
        <Tabs
          defaultActiveKey={'0'}
          direction={theme.direction}
          className={classes.tabParent}
          onChange={onTabChange}
        >
          {tabs.map((tab, index) => {
            return (
              <TabPane
                key={index}
                className={classes.tabPane}
                style={{
                  display: String(index) === tabActiveKey ? 'flex' : 'none',
                }}
                tab={
                  <p>
                    {tab.translatedTitle?.[locale] ||
                      tab.title ||
                      tab.name ||
                      tab.id}
                  </p>
                }
              >
                <div className={classes.gridContainer}>
                  <ListView {...tab} hasShow={false} setFilters={setFilters} />
                </div>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default MultiTabListView;
