import lodashFind from 'lodash/find';

import { clone, isEmpty, isEmptyObject } from '../../helper/data-helper';
import {
  actorGetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../../type/actor-setup';
import {
  getGridColumns,
  getPrimaryField,
  isGridInlineEditable,
} from '../../helper/MetaHelper';

import type { RelationPermissions, RelationInMetaData } from './relation-panel.type';
import type {
  FieldType,
  GeneralMetaData,
  MetaData,
  MetaDataBase,
} from '../../helper/Types';

export const KEY_SCROLL_TO = 'scrollTo_';

/**
 * check permissions
 * @function checkPermission
 * @param {boolean | null} permissionType
 * @param {MetaData} relationMetaData
 * @param {string} relationPermissionType
 * @param {boolean} parentRecordIsEditable
 * @returns {boolean}
 */
export const checkPermission = (
  permissionType: boolean | null,
  relationMetaData: MetaData,
  relationPermissionType: 'allowAdd' | 'allowEdit' | 'allowDelete',
): boolean => {
  if (permissionType !== null) {
    return permissionType;
  }

  if (!isEmptyObject(relationMetaData)) {
    return !!(relationMetaData as GeneralMetaData)?.config?.[relationPermissionType];
  }

  return true;
};

/**
 * check relation permissions
 * @function preparedRelationPermission
 * @param {MetaData} parentMetaData
 * @param {Object} parentRecord
 * @param {Object} relationItemInMetaData
 * @param {Object} relationMetaData
 * @param {boolean} parentRecordIsEditable
 * @returns {Object}
 */
export const preparedRelationPermission = (
  parentMetaData: MetaData,
  parentRecord: Record<string, unknown>,
  relationItemInMetaData: RelationInMetaData,
  relationMetaData: MetaData | null,
): RelationPermissions => {
  if (!relationMetaData) {
    return {
      hasCreate: false,
      hasEdit: false,
      hasDelete: false,
      disabledFieldList: null,
    };
  }

  let allowAdd: boolean | null = null;
  let allowEdit: boolean | null = null;
  let allowDelete: boolean | null = null;
  const disabledFieldList: Record<string, boolean> = {};

  const parentProcessInformation = {
    processuniqueid: parentRecord?.__processuniqueid as string,
    positionid: parentRecord?.positionid as string,
    stateid: parentRecord?.stateid as string,
  };

  const processDetailsInParentMetaData = lodashFind(
    (parentMetaData as GeneralMetaData).processes,
    {
      uniqueId: parentProcessInformation.processuniqueid,
    },
  );

  const currentTaskDetailsInParentProcessDetails = lodashFind(
    processDetailsInParentMetaData?.tasks,
    {
      positionId: parseInt(parentProcessInformation.positionid),
      stateId: parseInt(parentProcessInformation.stateid),
    },
  );

  const deactivateSubPanelsInCurrentTask = lodashFind(
    currentTaskDetailsInParentProcessDetails?.deactiveSubpanels,
    {
      moduleName: relationItemInMetaData.moduleName,
      moduleTableName: relationItemInMetaData.moduleTableName,
    },
  );

  if (deactivateSubPanelsInCurrentTask) {
    allowAdd = !deactivateSubPanelsInCurrentTask.disableAdd;
    allowEdit = !deactivateSubPanelsInCurrentTask.disableEdit;
    allowDelete = !deactivateSubPanelsInCurrentTask.disableDelete;

    // prettier-ignore
    for (const fieldId of currentTaskDetailsInParentProcessDetails?.deActiveFields ?? []) {
      disabledFieldList[fieldId] = true;
    }
  }

  const isParentRecordEditable = parentRecord?.['iseditable'];
  // this permissions will use in relation panel toolbar
  let preparedPermissions;

  if (!isParentRecordEditable) {
    preparedPermissions = {
      allowAdd: false,
      allowEdit: false,
      allowDelete: false,
      disabledFieldList,
    };
  } else {
    preparedPermissions = {
      hasCreate: checkPermission(allowAdd, relationMetaData, 'allowAdd'),
      hasEdit: checkPermission(allowEdit, relationMetaData, 'allowEdit'),
      hasDelete: checkPermission(allowDelete, relationMetaData, 'allowDelete'),
      disabledFieldList,
    };
  }

  return preparedPermissions;
};

/**
 * Prepare relation grid columns
 * @function getRelationFieldsForDisplay
 * @param {GeneralMetaData} metaData
 * @param {number[] | null} disabledFieldList disabled columns
 * @returns {FieldType[]}
 */
export const getRelationFieldsForDisplay = (
  relationMetaData: GeneralMetaData,
  disabledFieldList?: { [x: number]: boolean } | null,
): Array<FieldType> => {
  let fieldList: FieldType[] = [];
  let gridColumns = getGridColumns({
    metaData: relationMetaData,
    filterFirstFive: false,
    isRelation: true,
  });

  if (relationMetaData?.reportId) {
    fieldList = getGridColumns({
      metaData: relationMetaData,
      filterFirstFive: true,
      isRelation: true,
    });
  }

  if (!fieldList.length) {
    const primaryField = getPrimaryField(relationMetaData);
    gridColumns = getGridColumns({
      metaData: relationMetaData,
      filterFirstFive: true,
      isRelation: true,
    });
    let allFields = gridColumns;
    // in relations, we don't need to show primary field
    if (primaryField) {
      allFields =
        gridColumns && gridColumns.length
          ? gridColumns.filter(
              field => !isEmptyObject(field) && field?.id !== primaryField.id,
            )
          : [];
    }

    fieldList = [...allFields];
  }

  if (isEmptyObject(disabledFieldList)) {
    return fieldList;
  }

  if (fieldList?.length) {
    return clone(fieldList).map(field => {
      if (disabledFieldList![field.id]) {
        field.disabled = true;
      }

      return field;
    });
  }

  return [];
};

/**
 * compute if metadata allows execute
 * @function isReportExecutable
 * @param {GeneralMetaData} metaData
 * @returns {boolean}
 */
export const isReportExecutable = (metaData: GeneralMetaData): boolean => {
  return !!metaData?.executable;
};

/**
 * compute if metadata allows dit
 * @function isReportEditable
 * @param {GeneralMetaData} metaData
 * @returns {boolean}
 */
export const isReportEditable = (metaData: GeneralMetaData): boolean => {
  return !!metaData?.editable;
};

/**
 * add fake ids to each record of an array by reference
 * @function addFakeIdsToEachRecordOfDataArrayByRefrence
 * @param {Array<Record<string, unknown>> | null}
 * @returns {void}
 */
export const addFakeIdsToEachRecordOfDataArrayByReference = (
  data: Array<Record<string, unknown>> | null,
): void => {
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
      if (typeof item === 'object' && isEmpty(item.id)) {
        item.id = index.toString();
      }
    });
  }
};

/**
 * check if relation should be to refresh
 * @function relationShouldRefresh
 * @param {string} resource
 * @param {relationResource} relationResource
 * @returns boolean
 */
export const shouldRelationRefresh = (
  resource: string | undefined | null,
  relationResource: string,
): boolean => {
  const lastRequestId = actorGetActionValue(
    'gridData',
    `${relationResource}.lastRequestId`,
  )!;

  return (
    resource?.includes(relationResource) ||
    (resource === 'all' && !isEmpty(lastRequestId))
  );
};

/**
 * @function isRelationCellEditableWithoutTypeChecking
 * @param {FieldType} field
 * @returns {boolean}
 */
export const isRelationCellEditableWithoutTypeChecking = (
  field: FieldType,
  metaData: MetaData,
  hasEdit: boolean,
): boolean => !!(isGridInlineEditable(metaData) && hasEdit && !field?.disabled);

/**
 * @function getRelationSelectedItem
 * @param {string} resource
 * @returns {Record<string,unknown>}
 */
export const getRelationSelectedItem = (
  resource: string,
): Record<string, unknown> => {
  const gridData = actorGetActionValue('gridData', resource);

  const selectedRowData =
    Array.isArray(gridData?.data) &&
    gridData?.data.find(item => item?.id == gridData?.selectedIds[0]);

  return selectedRowData;
};

/**
 * extract primary field value from root record
 * @function getRootPrimaryFieldValue
 * @returns {string | null}
 */
export const getRootPrimaryFieldValue = (): string | null => {
  const resources = actorGetActionValue('resources');
  if (!resources) return null;

  const rootMetaData = actorGetActionValue(
    'metaData',
    resources.stack[0].value,
  ) as unknown as MetaDataBase;

  const primaryField = getPrimaryField(rootMetaData);

  const rootRecord = actorGetActionValue(
    'record',
    `${resources.stack[0].value}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
  ) as Record<string, unknown>;

  const value = rootRecord?.[primaryField.name];

  return isEmpty(value) ? null : String(value);
};
