import { useMemo, useRef, useEffect, createRef, useState, useCallback } from 'react';
import { AutocompleteCloseReason } from '@material-ui/lab';
import { HandlePopupInterface } from '.';
import { areTwoObjectsShallowEqual } from '../../../helper/data-helper';
import { getLabelForDropdownOption } from '../../../helper/DropdownHelper';
import {
  AutoLimitTagsInterface,
  DropdownOption,
  OptionDataInterface,
  PreparedOptionsInterface,
} from './auto-complete-input.type';
import lodashDebounce from 'lodash/debounce';

export const useAutoLimitTags = (): AutoLimitTagsInterface => {
  const [limitTagCount, setLimitTagCount] = useState<number | undefined>(undefined);
  const refTags = createRef<HTMLInputElement>();

  /**
   * handle more item with flex (when item is more than one line in flex-box)
   * @function updateSize
   * @return {void}
   */
  const updateSize = useCallback((): void => {
    if (refTags.current == null) return;

    const parentRect = refTags.current.getBoundingClientRect();

    const maxTagsCountBasedOnInputContent = Math.round(parentRect.width / 100);

    if (maxTagsCountBasedOnInputContent - 1 <= 0) {
      setLimitTagCount(0);
    } else {
      setLimitTagCount(maxTagsCountBasedOnInputContent - 1);
    }
  }, [refTags.current]);

  /**
   * @function debouncedWindowResizeHandler
   * @returns { void }
   */
  const debouncedWindowResizeHandler = useCallback(
    lodashDebounce(updateSize, 1000),
    [],
  );

  useEffect(() => {
    /**
     * add listener when resize view port change
     */
    window.addEventListener('resize', debouncedWindowResizeHandler);

    /**
     * call update size when change refTags
     */
    updateSize();

    /**
     * clear listener when component destroy
     */
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return { limitTagCount, refTags };
};

export const useHandlePopup = (): HandlePopupInterface => {
  const [anchorEl, handleOpen] = useState<null | HTMLElement>(null);
  /**
   * this function close drop down popup
   * @function handleClose
   * @param _event
   * @param reason
   * @returns {void}
   */
  const handleClose = (
    _event: React.ChangeEvent<Record<string, unknown>>,
    reason: AutocompleteCloseReason,
  ): void => {
    if (reason === 'toggleInput' || reason === 'blur') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    handleOpen(null);
  };

  const open = Boolean(anchorEl);

  return { open, handleClose, handleOpen };
};

export const useOptionsData = (props: OptionDataInterface) => {
  const { valueMember, value, dropdownData, dropdownMeta } = props;
  const optionsStore = useRef<DropdownOption[]>([]);

  /**
   * it will look for an object with the same value as its entry from "optionsStore" ref.
   * return its complete object if found it, else return null.
   * @function getOptionWithValue
   * @param {string} _value
   * @returns {DropdownOption | null} from optionsStore ref
   */
  const getOptionWithValue = (_value: string): DropdownOption | null => {
    const preparedOption: DropdownOption[] = optionsStore.current.filter(
      (opt: DropdownOption) => opt.value === _value,
    );
    return preparedOption && preparedOption.length ? preparedOption[0] : null;
  };

  /**
   * it split dropdown values string to an array
   * @function dropdownValues
   * @returns {Array<string>} dropdown values as string array
   */
  const dropdownValues: string[] = useMemo(() => {
    return value && value.toString().split(',').length
      ? value.toString().split(',')
      : [];
  }, [value]);

  /**
   * prepare drop down options
   * @function preparedOptions
   * @returns {PreparedOptionsInterface}
   */
  const preparedOptions: PreparedOptionsInterface = useMemo(() => {
    const normalOptions: DropdownOption[] = dropdownData.map((item, index) => {
      const newItem: DropdownOption = {
        text: getLabelForDropdownOption(dropdownMeta, item),
        value: item[valueMember] + '',
        key: item[valueMember] + '' + index,
      };

      if (
        !optionsStore.current.some(storeItem =>
          areTwoObjectsShallowEqual<DropdownOption, DropdownOption>(
            storeItem,
            newItem,
          ),
        )
      ) {
        optionsStore.current.push(newItem);
      }

      return newItem;
    });
    const selectedOptions = normalOptions;
    dropdownValues.forEach((element: string) => {
      const optionInStore: DropdownOption | null = getOptionWithValue(element);

      if (optionInStore) {
        selectedOptions.push(optionInStore);
      }
    });

    return { selectedOptions, normalOptions };
  }, [dropdownData, value]);

  return { preparedOptions };
};

export const useOutsideAlerter = (
  ref: React.RefObject<HTMLElement>,
  callback: Function,
): void => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
