import { FC } from 'react';
import FileStreamCustomChip from '../../dynamic-input/multi-file-stream-input/multi-file-stream-custom-chip';
import { MultiFileStreamFieldViewInterface } from './multi-file-stream-field.type';
import { useStyles } from './multi-file-stream-field.style';

const MultiFileStreamFieldView: FC<MultiFileStreamFieldViewInterface> = props => {
  const { items, disabled, onItemClick } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {items.map((item, index) => (
        <FileStreamCustomChip
          realFileName={item.realFileName}
          filePath={item.filePath}
          size={item.size}
          index={index}
          disabled={disabled}
          onItemClick={onItemClick}
        />
      ))}
    </div>
  );
};

export default MultiFileStreamFieldView;
