import { PureComponent } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { getValue, setValue, CONFIG_CALENDAR } from '../core/configProvider';

const styles = theme => ({
  calendarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  IconButton: {
    padding: '8px 12px',
    margin: '0 5px',
  },


  IconButton: {
    padding: '18px 12px',
    margin: '0 5px',
    color: theme.palette.secondary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.LanguageContainerBackgroundColor,
    }  
  },


  calender: {
    fontSize: '1rem',
    fontWeight: 'bold',
    textShadow: `2px 2px 2px ${theme.palette.primary.LanguageContainerTextShadow}`,
    borderBottom: `1px solid ${theme.palette.primary.LanguageContainerBackgroundColor}`,
    '&:hover': {
      borderBottomColor: theme.palette.secondary.contrastText,
    },
  },
});

const calendarList = {
  jalali: 'جلالی',
  gregorian: 'Gregorain',
  // ghamari: 'قمری',
};

class ChangeCalendarContainer extends PureComponent {
  state = { currentCalendar: getValue(CONFIG_CALENDAR) };

  handleChangeLocale = value => {
    setValue(CONFIG_CALENDAR, value);
    this.setState({ currentCalendar: value });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.calendarContainer}>
        {Object.keys(calendarList).map(key => (
          <Tooltip key={key} title="" data-test={`cal-${key}`}>
            <IconButton key={key}
              className={classes.IconButton}
              onClick={() => this.handleChangeLocale(key)}
            >
              <Typography
                className={classNames({
                  [classes.calender] : true,
                  [classes.selectCalendar] : this.state.currentCalendar === key ? true : null
                })}
                variant="caption"
              >
                {calendarList[key]}
              </Typography>
            </IconButton>
          </Tooltip>
        ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChangeCalendarContainer);
