import { memo } from 'react';
import { ProfileAvatarControllerProps } from './profile-avatar.type';
import {
  API_NAME,
  API_URL,
  API_VERSION,
  USER_COMPANY_ID,
  getValue,
} from '../../core/configProvider';
import ProfileAvatarView from './profile-avatar.view';

const ProfileAvatarController: React.FC<ProfileAvatarControllerProps> = ({
  userId,
  bigAvatar,
}) => {
  /**
   * @function getUrl
   * @returns {string}
   */
  const getUrl = (): string => {
    const apiUrl = getValue(API_URL);
    const apiName = getValue(API_NAME);
    const apiVersion = getValue(API_VERSION);
    const companyId = getValue(USER_COMPANY_ID);

    return `${apiUrl}/${apiVersion}/account/${apiName}/profile/image?userId=${userId}&companyId=${companyId}`;
  };

  return <ProfileAvatarView bigAvatar={bigAvatar} getUrl={getUrl} />;
};

export default memo(ProfileAvatarController);
