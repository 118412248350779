import { useCallback, useState } from 'react';
import ChatUserListView from './chat-user-list.view';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
  USER_ID,
} from '../../../core/configProvider';

import type { ReactElement, MouseEvent } from 'react';
import type { ChatUserListPropsInterfaceController } from './chat-user-list.type';
import type { ChatUsersInterface } from '../chat-dialogs/add-members-dialog/add-members-dialog.type';
import { isEmptyObject } from '../../../helper/data-helper';
import { isAdminLoggedUser } from '../chat-section.helper';

const ChatUserListController = (
  props: ChatUserListPropsInterfaceController,
): ReactElement => {
  const { handleRemoveUser, handleClick, users, selectedUsers, usersWithSeenDate } =
    props;

  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);
  const userId = getValue(USER_ID);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const shouldShowRemoveIcon = useCallback(
    (selectedUser: ChatUsersInterface): boolean => {
      //just admin and owners can remove users
      //owner and admin can't remove himself
      if (isAdminLoggedUser(users) && selectedUser.personinfo_id !== userId) {
        return true;
      }

      return false;
    },
    [],
  );

  /**
   * @function openContextMenu
   * @param {MouseEvent<HTMLDivElement>} event
   * @returns { void } void
   */
  const openContextMenu = useCallback((event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  /**
   * @function handleClose
   * @returns { void } void
   */
  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  /**
   * @function getPersonAvatarURL
   * @param {ChatUsersInterface} user
   * @returns { void } void
   */
  const getPersonAvatarURL = useCallback((user: ChatUsersInterface): string => {
    return `${apiUrl}/oauth2/${sessionId}/${apiVersion}${
      user.personimage ?? user.personprofile
    }`;
  }, []);

  /**
   * @function isUserSelected
   * @param { ChatUsersInterface } user
   * @returns { void } void
   */
  const isUserSelected = useCallback(
    (user: ChatUsersInterface): boolean => {
      return !!(
        selectedUsers &&
        !!selectedUsers.find(
          (item: ChatUsersInterface) => item.personinfo_id === user.personinfo_id,
        )
      );
    },
    [selectedUsers],
  );

  return (
    <ChatUserListView
      shouldShowRemoveIcon={shouldShowRemoveIcon}
      getPersonAvatarURL={getPersonAvatarURL}
      handleRemoveUser={handleRemoveUser}
      openContextMenu={openContextMenu}
      isUserSelected={isUserSelected}
      handleClick={handleClick}
      handleClose={handleClose}
      usersWithSeenDate={usersWithSeenDate}
      anchorEl={anchorEl}
      users={users}
    />
  );
};

export default ChatUserListController;
