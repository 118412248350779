import GadgetSettingsTabView from './gadget-settings-tab.view';
import { MainCharts } from '../../../../../dynamic-chart';

import { type FC } from 'react';
import type { GadgetSettingsTabControllerProps } from './gadget-settings-tab.type';
import {
  announcementReportId,
  isChartNeededReportId,
} from '../../gadget-design-dialog.helper';
import { GadgetInformation } from '../../../gadget-input.type';

const GadgetSettingsTabController: FC<GadgetSettingsTabControllerProps> = props => {
  const { reports, gadgetValues, gadgetInternalChangeHandler } = props;

  /**
   * trigger `gadgetInternalChangeHandler` function for reportAddress
   * @function onReportSelect
   * @param {React.ChangeEvent<{name?: string | undefined ;value: unknown}>} event
   * @returns {void}
   */
  const onReportSelect = async (event, value): Promise<void> => {
    if (value) {
      gadgetInternalChangeHandler(
        {
          reportAddress: value.reportId as string,
          filterValues: [],
        },
        { replaceAll: true },
      );
    }
  };

  /**
   * trigger `gadgetInternalChangeHandler` function for chartType
   * @function onChartTypeSelect
   * @param {unknown} event
   * @returns {void}
   */
  const onChartTypeSelect = (event, value) => {
    const additionalKeys: Partial<GadgetInformation> = {};

    if (value === MainCharts.Announcements) {
      additionalKeys.reportAddress = announcementReportId;
    } else if (value === MainCharts.Calendar) {
      additionalKeys.reportAddress = '';
    }

    if (value) {
      gadgetInternalChangeHandler(
        {
          chartType: value as MainCharts,
          ...additionalKeys,
        },
        { replaceAll: true },
      );
    }
  };

  /**
   * @function getReportInputValue
   * @returns {void}
   */
  const getReportInputValue = ():
    | { label: string; reportId: string }
    | undefined => {
    return reports?.find(report => report.reportId === gadgetValues.reportAddress);
  };

  return (
    <GadgetSettingsTabView
      reports={reports}
      showReportDropdown={isChartNeededReportId(gadgetValues.chartType)}
      gadgetValues={gadgetValues}
      onReportSelect={onReportSelect}
      onChartTypeSelect={onChartTypeSelect}
      gadgetInternalChangeHandler={gadgetInternalChangeHandler}
      reportInputValue={getReportInputValue()}
    />
  );
};

export default GadgetSettingsTabController;
