import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<
  CustomTheme,
  { hasSelectedItems: boolean; hasAccessPath: boolean }
>(theme => ({
  container: {
    width: '100%',
    padding: 0,
    zIndex: 2,
    display: 'flex',
    justifyContent: props =>
      props.hasSelectedItems || props.hasAccessPath ? 'space-between' : 'flex-end',
    flexWrap: 'wrap',
  },

  actionsContainer: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
  },

  refreshButton: {
    display: 'flex',
    justifyContent: 'center',
  },

  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  filterButton: {
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  filterList: {
    cursor: 'pointer',
  },
}));
