import { GET_META } from '../core/data-Provider.helper';
import { WMS } from '../core/configRouteConstant';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  type ResourcesInterface,
} from '../type/actor-setup';
import dataProvider from '../core/dataProvider';

import type { WMSFetchMetaResult } from '../component/wms';

interface GetMetaDataCatchError {
  response: { data: { userMessage: string } };
}

/**
 * It gets `metaData` and return it based on `wms` structure
 * @function getMetaData
 * @param { string } resource
 * @returns { Promise<WMSFetchMetaResult> } A promise include `wms` data structure
 */
export const getMetaData = async (resource: string): Promise<WMSFetchMetaResult> => {
  try {
    const data = await dataProvider(GET_META, resource);

    const receivedMetaData = data[0]; // `data` is an array: [metaData, null]

    return {
      data: receivedMetaData,
      error: null,
    };
  } catch (error) {
    const _error =
      (error as GetMetaDataCatchError)?.response?.data?.userMessage ??
      (error as GetMetaDataCatchError).toString();

    return {
      data: null,
      error: _error,
    };
  }
};

/**
 * It gets `metaData` based on `wms` structure and dispatch it
 * @function changeResourcesHandler
 * @param { ResourcesInterface } resources
 * @returns { Promise<void> } a promise of void
 */
const changeResourcesHandler = async (
  resources: ResourcesInterface,
): Promise<void> => {
  if (
    resources?.current == null ||
    resources.current.value === '/' ||
    !resources.current.value.includes(WMS)
  ) {
    return;
  }

  const rootResource = resources.stack[0];
  let metaData = actorGetActionValue(
    'metaData',
    rootResource.value,
  ) as WMSFetchMetaResult | null;

  if (metaData?.data == null) {
    metaData = await getMetaData(rootResource.value);
  }

  actorDispatch(
    'metaData',
    {
      data: metaData.data,
      error: metaData.error,
    },
    {
      path: rootResource.value,
    },
  );

  actorDispatch('showLoading', false);
};

actorOnDispatch('resources', changeResourcesHandler);
