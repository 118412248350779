import { FC, useState, memo, useEffect } from 'react';
import { debounce } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import starkString from 'starkstring';

import Dropdown from '@web/dropdown';
import { FormActions } from '../../form';
import { emptyFunction } from '../../form/form.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';

import type { DropdownMeta } from '../dropdown-input';
import type { DelegationDropdownInterface } from './delegation-dropdown.type';

const DelegationDropdownController: FC<DelegationDropdownInterface> = memo(props => {
  const { formActionsHandler, field, label, inputMessage, value, hint } = props;

  const translate = useTranslate();

  const delegationData = actorGetActionValue('delegationData');

  const [selectedItem, setSelectedItem] = useState<Record<string, unknown>>({});
  const [dropdownData, setDropdownData] = useState<{
    items: Record<string, unknown>[];
    totalItemsCount: number;
  }>({ items: [], totalItemsCount: 0 });

  const { required, name, dropdown: dropdownMeta, disabled } = field;
  const { valueMember, displayMember } = dropdownMeta;

  useEffect(() => {
    const onDispatchId = actorOnDispatch('delegationData', _delegationData => {
      updateDropdownData({ DATA: _delegationData, TOTAL: _delegationData?.length });
    });

    updateDropdownData({ DATA: delegationData, TOTAL: delegationData?.length });

    return () => {
      actorRemoveAction({
        actionName: 'delegationData',
        listenerId: onDispatchId,
      });
    };
  }, []);

  useEffect(() => {
    if (delegationData?.length) {
      const item = {
        [valueMember as string]: value,
        [displayMember as string]:
          delegationData?.find(item => item.path === value)?.[
            displayMember as string
          ] ?? value,
      };
      setSelectedItem(item);
    }
  }, [value]);

  /**
   * it will trigger on input change in form controller and send request to path
   * @function dropdownOnChange
   * @param {Record<string, unknown>}
   * @returns {void} void
   */
  const dropdownOnChange = (value: Record<string, unknown> | null): void => {
    // use `isEmpty` function to handle dropdown items with value `0` or ''
    const computedValue = (value as Record<string, unknown>)?.['path'] ?? null;

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: computedValue,
    });

    actorDispatch('putPathDelegation', {
      resource: computedValue,
      successCallback: emptyFunction,
    });
  };

  /**
   * update dropdown data state
   * @function updateDropdownData
   * @param {object} data
   * @returns {void} void
   */
  const updateDropdownData = (data: {
    DATA: Record<string, unknown>[] | null;
    TOTAL?: number | undefined;
  }): void => {
    const { DATA = [], TOTAL = null } = data;

    setDropdownData({
      items: DATA ?? [],
      totalItemsCount: Number(TOTAL),
    });
  };
  /**
   * this function is locally search in dropdown data by debounce
   * @function handleOnSearch
   * @param {string} value
   * @return {void}
   */
  const handleOnSearch = debounce((value: string): void => {
    if (value.length > 2) {
      const searchedData =
        delegationData?.filter(item =>
          starkString(item.caption)
            .persianChar()
            .toString()
            .includes(starkString(value).persianChar().toString()),
        ) ?? [];
      updateDropdownData({ DATA: searchedData, TOTAL: searchedData?.length });
    } else {
      fetchDropdownDataHandler();
    }
  }, 700);

  /**
   * this function get dropdown data by request
   * @function fetchDropdownData
   * @return {void}
   */
  const fetchDropdownDataHandler = (): void => {
    actorDispatch('getDelegationData', null);
  };

  return (
    <Dropdown
      dropdownMeta={dropdownMeta as DropdownMeta}
      dropdownData={dropdownData.items}
      value={selectedItem}
      onChange={dropdownOnChange}
      fetchDropdownData={fetchDropdownDataHandler}
      fetchMoreDropdownData={() => {}}
      onSearch={handleOnSearch}
      isLoading={false}
      label={label}
      hint={hint}
      inputMessage={inputMessage}
      searchPlaceholder={translate('dropdown.searchPlaceholder')}
      noneLabel={translate('dropdown.noneLabel')}
      required={required}
      disabled={disabled}
      name={name}
      noDataFoundLabel={translate('dropdown.noOptionsMessage')}
      hasMore={false}
      className={''}
    />
  );
});

export default DelegationDropdownController;
