import { type FC, memo, useEffect, useState, useCallback } from 'react';
import { useTranslate } from 'react-admin';

import {
  MailActions,
  MailInterface,
  MailLabelType,
  OnChangeCheckedMailsParams,
  OnShowDetailParams,
  OnSubmitLabelsParams,
} from '../../mail-section.type';
import MailListItemView from './mail-list-item.view';
import { actorDispatch } from '../../../../type/actor-setup';
import { parseJSON } from '../../../../core/configProvider';

import type { MailListItemInterface } from './mail-list-item.type';

const MailListItemController: FC<MailListItemInterface> = memo(props => {
  const {
    mailActionsHandler,
    mail,
    onStarClick,
    onImportantClick,
    checkedMails,
    isEven,
  } = props;
  const translate = useTranslate();
  const [labelList, setLabelList] = useState<MailLabelType[]>([]);

  useEffect(() => {
    setLabelList(parseJSON<MailLabelType[]>(mail.labelsarray) ?? []);
  }, [mail]);

  /**
   * @function onChangeCheckedMails
   * @param { MailInterface } mail
   * @returns { void }
   */
  const onChangeCheckedMails = (mail: MailInterface): void => {
    mailActionsHandler(MailActions.onChangeCheckedMails, {
      mail,
    } as OnChangeCheckedMailsParams);
  };

  /**
   * @function onShowDetail
   * @param { MailInterface } mail
   * @returns { void }
   */
  const onShowDetail = (
    mail: MailInterface,
    event?: React.MouseEvent<HTMLElement>,
  ): void => {
    mailActionsHandler(MailActions.onShowDetail, {
      mail,
      event,
    } as OnShowDetailParams);
  };

  /**
   * @function onSubmitConfirmRemoveLabel
   * @param { MailLabelType } label
   * @returns { void }
   */
  const onSubmitConfirmRemoveLabel = useCallback(
    (label: MailLabelType): void => {
      const toLabeledMailData = [
        {
          doctype_id: mail.doctype_id,
          docid: mail.doc_id,
          refrences_id: mail.refrences_id,
        },
      ];

      const removeSelectLabel = labelList.filter(
        item => item.lablestitle !== label.lablestitle,
      );

      setLabelList(removeSelectLabel ?? []);
      actorDispatch('closeCurrentDialog', true);

      mailActionsHandler(MailActions.onSubmitLabels, {
        mailsData: toLabeledMailData,
        LabelsArray: removeSelectLabel,
      } as OnSubmitLabelsParams);
    },
    [labelList],
  );

  /**
   * @function removeCurrentLabel
   * @returns { void }
   */
  const removeCurrentLabel = useCallback((label): void => {
    actorDispatch('quickDialog', {
      confirmationIsOpen: true,
      data: {
        content: translate('ra.message.are_you_sure'),
        onConfirm: (): void => {
          onSubmitConfirmRemoveLabel(label);
        },
      },
    });
  }, []);

  return (
    <MailListItemView
      mailActionsHandler={mailActionsHandler}
      mail={mail}
      isRead={Boolean(!mail.isunread)}
      isEven={isEven}
      onStarClick={onStarClick}
      onImportantClick={onImportantClick}
      onShowDetail={onShowDetail}
      checkedMails={checkedMails}
      onChangeCheckedMails={onChangeCheckedMails}
      removeCurrentLabel={removeCurrentLabel}
      labelList={labelList}
    />
  );
});

export default MailListItemController;
