import type { RangeInputMode } from './range-picker-input.type';

// prettier-ignore
export const dateTimeRegex = /^(13|14|19|20)\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;
// prettier-ignore
export const dateRegex = /^(13|14|19|20)\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
const lastPeriodRegex = /^now - \d+[hmd]$/;
const nowRegex = /^now$/;

// prettier-ignore
const rangeRegex = /^(-\d+y\s*)?(-?\d+M\s*)?(-?\d+w\s*)?(-?\d+d\s*)?(-?\d+h\s*)?(-?\d+m\s*)?(-?\d+s\s*)?$/

/**
 * test text with different regex to find out which type the text is
 * @function getRangeInputMode
 * @param {string} text
 * @returns {RangeInputMode}
 */
export const getRangeInputMode = (text: string): RangeInputMode => {
  if (text === '') {
    return 'empty';
  } else if (nowRegex.test(text)) {
    return 'now';
  } else if (lastPeriodRegex.test(text)) {
    return 'lastPeriod';
  } else if (dateTimeRegex.test(text)) {
    return 'dateTime';
  } else if (dateRegex.test(text)) {
    return 'date';
  } else if (rangeRegex.test(text)) {
    return 'range';
  } else {
    return 'invalid';
  }
};
