import { ReactElement } from 'react';
import { Box, Button, Checkbox, IconButton, Tooltip } from '@material-ui/core';
import ImportantIcon from '@material-ui/icons/LabelImportant';
import PrintIcon from '@material-ui/icons/Print';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import classNames from 'classnames';
import { useLocale, useTranslate } from 'react-admin';

import { MultipleMailDetailBodyViewInterface } from './multiple-mail-detail-body.type';
import { useStyles } from './multiple-mail-detail-body.style';
import { CustomAccordion } from '../../../custom-accordion';
import { MailDetailBody } from '../mail-detail-body';
import { SelectedMailInterface } from '../..';
import { MailDetailSkeleton } from '../../../skeleton/mail-detail-skeleton.view';
import { MailSummary } from './mail-summary';

const MultipleMailDetailBodyView = (
  props: MultipleMailDetailBodyViewInterface,
): ReactElement => {
  const {
    mailActionsHandler,
    selectedMail,
    selectedMailLoading,
    onImportantClick,
    onRecallClick,
    onForwardMailClick,
    onPrintMail,
    printComponentRef,
    onStarClick,
    mailCountToShow,
    handleShowMoreClick,
  } = props;

  const classes = useStyles();

  const locale = useLocale();

  const translate = useTranslate();

  const mainTitle = selectedMail?.[0]?.subject;

  if (!selectedMail || selectedMailLoading) {
    return <MailDetailSkeleton />;
  }

  return (
    <div
      dir={locale === 'fa' || locale === 'ar' ? 'rtl' : ''}
      ref={printComponentRef}
    >
      <Box p={1} data-test="mail-detail-item-container">
        <Box m={{ xs: 5, md: 1 }}>
          <Box
            component="span"
            fontSize={{ xs: 12, md: 17 }}
            fontWeight="bold"
            data-test="mail-detail-item-title"
          >
            {mainTitle}
          </Box>
          <Box className="dontShowInPrint" component="span" color="text.disabled">
            <IconButton
              onClick={event =>
                onStarClick(
                  event,
                  selectedMail?.[0]?.isstared ? false : true,
                  selectedMail?.[0],
                )
              }
              data-test="mail-summary-check-box"
            >
              {selectedMail?.[0]?.isstared ? (
                <StarIcon
                  data-test="mail-summary-star-icon"
                  style={{ color: '#D4AF37', fontSize: '18px' }}
                />
              ) : (
                <StarBorderIcon
                  data-test="mail-summary-star-border-icon"
                  style={{ fontSize: '18px' }}
                />
              )}
            </IconButton>
          </Box>
          <Box className="dontShowInPrint" component="span" color="text.disabled">
            <Checkbox
              checked={Boolean(selectedMail?.[0]?.isimportant)}
              onChange={() =>
                onImportantClick(
                  // eslint-disable-next-line no-extra-boolean-cast
                  Boolean(selectedMail?.[0]?.isimportant) ? false : true,
                )
              }
              icon={<ImportantIcon className={classes.iconImportant} />}
              checkedIcon={
                <ImportantIcon className={classes.iconImportantChecked} />
              }
            />
          </Box>

          <Box className="dontShowInPrint" component="span" color="text.disabled">
            <IconButton
              onClick={onPrintMail}
              data-test="mail-detail-header-archive-icon"
            >
              <PrintIcon />
            </IconButton>
          </Box>
        </Box>

        <Box className={classes.detailContainer}>
          {selectedMail
            ?.slice(0, mailCountToShow)
            .map((mail: SelectedMailInterface, index: number) => (
              <CustomAccordion
                id={`${mail.uniqueid}`}
                data-test="mail-detail-item"
                defaultExpanded={true}
                summary={
                  <MailSummary
                    mailActionsHandler={mailActionsHandler}
                    mail={mail}
                    isMainMail={index === selectedMail?.length - 1}
                    onRecallClick={onRecallClick}
                  />
                }
                customContainerClass={classes.accordionContainer}
                customDetailClass={classes.accordionDetailContainer}
                customSummaryContainerClass={classNames(
                  'dontShowInPrint',
                  classes.summaryContainer,
                )}
              >
                <MailDetailBody
                  mailActionsHandler={mailActionsHandler}
                  selectedMail={mail}
                  selectedMailLoading={selectedMailLoading}
                  isMainMail={index === selectedMail?.length - 1}
                  onRecallClick={onRecallClick}
                  onForwardMailClick={onForwardMailClick}
                  isFromMultipleMailList
                />
              </CustomAccordion>
            ))}
          {selectedMail?.length > mailCountToShow && (
            <Box className={classes.showMoreButtonContainer}>
              <Button
                onClick={handleShowMoreClick}
                size="large"
                variant="outlined"
                color="primary"
              >
                {translate('form.showMore')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default MultipleMailDetailBodyView;
