import type { FC } from 'react';
import { useTranslate } from 'react-admin';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Tabs } from 'antd';
import { useTheme } from '@material-ui/core';

import { SimpleGrid } from '../../show-permissions/simple-grid';
import { permissionReasonColumns } from '../../show-permissions/show-permissions.helper';
import { useStyles } from './permission-show-reason-dialog.style';

import {
  defaultTabKey,
  type PermissionShowReasonDialogViewInterface,
} from './permission-show-reason-dialog.type';

const PermissionShowReasonDialogView: FC<
  PermissionShowReasonDialogViewInterface
> = props => {
  const {
    closeDialogHandler,
    permissionReason,
    deleteUserPermission,
    addUser,
    dialogTitle,
    handleTabChange,
    selectPersonForFilter,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const { TabPane } = Tabs;
  const theme = useTheme();

  return (
    <>
      <form>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Tabs
            onChange={handleTabChange}
            direction={theme.direction}
            className={classes.tabParent}
            data-style-tab-relation="tabsRelation"
          >
            <TabPane
              tab={<p>{`(${selectPersonForFilter})`}</p>}
              key={defaultTabKey.activeTabOne}
              destroyInactiveTabPane
            >
              <SimpleGrid
                heigh={30}
                useDynamicHeight={true}
                filterLocally={false}
                groping={false}
                columns={permissionReasonColumns}
                rows={permissionReason}
                hasDelete={true}
                onDeleteRow={deleteUserPermission}
              />
            </TabPane>
            <TabPane
              tab={<p>{translate('grid.total')}</p>}
              key={defaultTabKey.activeTabTwo}
            >
              <SimpleGrid
                heigh={30}
                useDynamicHeight={true}
                filterLocally={false}
                groping={false}
                columns={permissionReasonColumns}
                rows={permissionReason}
                hasDelete={true}
                onDeleteRow={deleteUserPermission}
              />
            </TabPane>
          </Tabs>
        </DialogContent>

        <DialogActions className={classes.containerAction}>
          <div>
            <Button className={classes.addUserButton} onClick={addUser}>
              {translate('ra.action.create')}
            </Button>
          </div>
          <div>
            <Button onClick={closeDialogHandler}>
              {translate('ra.action.cancel')}
            </Button>
          </div>
        </DialogActions>
      </form>
    </>
  );
};

export default PermissionShowReasonDialogView;
