import {
  memo,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocale, useTranslate } from 'react-admin';

import { NewMetaContext } from '../../container/NewMetaContext';
import { DialogContainer } from './dialog-container.view';
import { QuickCreateDropdownDialogView } from './quick-create-dropdown-dialog.view';
import { QuickCreateDialogView } from './quick-create-dialog.view';
import { ServiceDialogView } from './service-dialog.view';
import { RelationEditDialogView } from './relation-edit-dialog.view';
import { ConfirmationDialogView } from './confirmation-dialog/confirmation-dialog.view';
import { checkDialogType } from './dialog-stack.helper';
import { showNotification } from '../../helper/general-function-helper';
import { GridColumnsSelectionDialog } from './grid-column-selection-dialog';
import { FilterSaveDialog } from './filter-save-dialog';
import { DialogDataInterface, DynamicDialog } from './dynamic-dialog';
import { UserDescriptionDialog } from './user-description-dialog';
import { PermissionsAddUser } from './permissions-add-user';
import { PermissionsDialog } from './permission-show-reason-dialog';
import { DialogDataReasonPermissionInterface } from './permission-show-reason-dialog/permission-show-reason-dialog.type';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  ResourceInterface,
} from '../../type/actor-setup';
import type {
  AddGroupMembersDialogDataItem,
  AdminsDialogDataItem,
  ChatInfoDialogDataItem,
  CreateGroupDialogDataItem,
  DashboardCardFormDialogItem,
  DialogData,
  DialogOption,
  DialogParams,
  DialogType,
  FilterSaveDialogDataItem,
  ManageGroupDialogDataItem,
  GadgetDesignDialogDataItem,
  TodoShareDialogDataItem,
  SimpleDialogOption,
  SendLocationDialogDataItem,
  MessageInfoDialogDataItem,
  ServiceDialogDataItem,
  TodoAssignTaskDialogDataItem,
} from './dialogs-stack.type';
import { TodoShareDialog } from './todo-share-dialog';
import { CreateGroupDialog } from '../chat-section/chat-dialogs/create-group-dialog';
import { AddMembersDialog } from '../chat-section/chat-dialogs/add-members-dialog';
import { GadgetDesignDialog } from '../dynamic-input/gadget-input/gadget-design-dialog';
import { ChatInfoDialog } from '../chat-section/chat-dialogs/chat-info-dialog';
import { ManageGroupDialog } from '../chat-section/chat-dialogs/edit-group-dialog';
import { ManageInfoDialog } from '../chat-section/chat-dialogs/message-info-dialog';
import { AdminsDialog } from '../chat-section/chat-dialogs/admins-dialog';
import { DashboardCardFormDialog } from '../dashboard/dashboard-card-form-dialog';
import { UserInfoDialog } from '../chat-section/chat-dialogs/user-info-dialog';
import { SendLocationDialog } from '../chat-section/chat-dialogs/send-location-dialog';
import AssignTaskDialogController from './assign-task-dialog/assign-task-dialog.controller';
import { LightBoxDialog } from './light-box-dialog';
import { LightBoxDialogInterface } from './light-box-dialog/light-box-dialog.type';
import { SortableList } from '../sortable-list';
import { DynamicData } from '../sortable-list/sortable-list.type';

let dialogData: Record<string, unknown> = {};
let dialogType: DialogType | undefined;
let currentResource: ResourceInterface | undefined;

/**
 * @function gatherDialogData
 * @param {DialogData} actorData
 * @param {Function} handleGetMetaData
 * @returns {void}
 */
const gatherDialogData = (
  actorData: DialogData,
  handleGetMetaData: (...args) => void,
): void => {
  dialogType = checkDialogType(actorData);

  if (
    dialogType &&
    dialogType !== 'serviceDialogForm' &&
    actorGetActionValue('resources')
  ) {
    currentResource = actorGetActionValue('resources')!.current;
    const metaData = handleGetMetaData(currentResource.value);
    dialogData.metaData = metaData;
  }

  dialogData = {
    ...dialogData,
    ...actorData?.data,
    onCloseDialogCallback: actorData?.onCloseDialogCallback,
    dropdownIsOpen: !!actorData?.dropdownIsOpen,
    serviceIsOpen: actorData?.serviceIsOpen,
    confirmationIsOpen: actorData?.confirmationIsOpen,
  };
};

const DialogsStackController = (): ReactElement => {
  const [dialogs, setDialogs] = useState<DialogParams[]>([]);
  const locale = useLocale();
  const translate = useTranslate();

  const { getMeta } = useContext(NewMetaContext);

  useEffect(() => {
    actorOnDispatch('quickDialog', detail => {
      gatherDialogData(detail, getMeta);
      if (detail == null) return;

      if (detail.dropdownIsOpen) {
        openQuickCreateDropdownDialog();
        return;
      }

      if (detail.createIsOpen) {
        openQuickCreateDialog();
        return;
      }

      if (detail.listSortDialog) {
        openListSortDialog();
        return;
      }

      if (detail.serviceIsOpen) {
        openServiceDialog();
        return;
      }

      if (detail.relationEditIsOpen) {
        openRelationEditDialog();
        return;
      }

      if (detail.lightBoxPreviewIsOpen) {
        openLightBoxPreviewDialog();
        return;
      }

      if (detail.confirmationIsOpen) {
        openConfirmationDialog();
        return;
      }

      if (detail.permissionAddUserIsOpen) {
        OpenPermissionsAddUser();
        return;
      }

      if (detail.reasonPermissionIsOpen) {
        openPermissionsDialog();
        return;
      }

      if (detail.gridColumnsSelectionDialogIsOpen) {
        openGridColumnsSelectionDialog();
        return;
      }

      if (detail.dynamicDialogIsOpen) {
        openDynamicDialog();
        return;
      }

      if (detail.filterSaveDialogIsOpen) {
        openFilterSaveDialog();
        return;
      }

      if (detail.todoShareDialogIsOpen) {
        openTodoShareDialog();
        return;
      }

      if (detail.todoAssignTask) {
        openAssignTaskDialog();
        return;
      }

      if (detail.chatCreateGroupDialogIsOpen) {
        openChatCreateGroupDialog();
        return;
      }

      if (detail.addMembersDialogIsOpen) {
        openAddMembersDialog();
        return;
      }

      if (detail.chatInfoDialogIsOpen) {
        openChatInfoDialog();
        return;
      }

      if (detail.userInfoDialogIsOpen) {
        openUserInfoDialog();
        return;
      }

      if (detail.manageGroupDialogIsOpen) {
        openManageGroupDialog();
        return;
      }

      if (detail.gadgetDesignDialogIsOpen) {
        openGadgetDesignDialog();
        return;
      }

      if (detail.adminsDialogIsOpen) {
        openAdminsDialog();
        return;
      }

      if (detail.isDashboardCardFormDialogOpen) {
        editDashboardCard();
        return;
      }

      if (detail.sendLocationDialogIsOpen) {
        openSendLocationDialog();
      }

      if (detail.userDescriptionDialogIsOpen) {
        openUserDescriptionDialog();
        return;
      }
      if (detail.messageInfoDialogIsOpen) {
        openMessageInfoDialog();
        return;
      }
    });

    actorOnDispatch('closeDialogs', () => {
      setDialogs([]);
    });

    actorOnDispatch('closeCurrentDialog', () => {
      if (
        dialogData?.onCloseDialogCallback &&
        typeof dialogData.onCloseDialogCallback === 'function'
      ) {
        dialogData.onCloseDialogCallback();
      }

      closeDialogHandler();
    });
  }, []);

  /**
   * @function createDialog
   * @param {DialogOption} option
   * @returns {void}
   */
  const createDialog = (option: DialogOption): void => {
    const dialog = { ...dialogData, ...option, open: true };
    setDialogs(dialogs => [...dialogs, dialog]);
  };

  /**
   * @function mainCloseDialog
   * @returns {void}
   */
  const mainCloseDialog = () => {
    setDialogs(dialogs => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs];
    });
  };

  /**
   * close dialog
   * @function closeDialogHandler
   * @returns {void}
   */
  const closeDialogHandler = () => {
    mainCloseDialog();

    if (dialogType === 'dropdownQuickCreateForm') {
      actorDispatch('remove', {
        resource: currentResource!.value,
        type: FormKeyMode.DROPDOWN,
      });

      return;
    }

    if (dialogType === 'serviceDialogForm') {
      const { isSending, service } = dialogData.data as ServiceDialogDataItem;

      if (isSending) {
        return;
      }

      actorDispatch('remove', {
        resource: `action/${(service as Record<string, unknown>)?.uniqueId}`,
        type: FormKeyMode.SERVICE,
      });
    }

    if (dialogType === 'relationEditDialogForm') {
      actorDispatch('remove', {
        resource: currentResource!.value,
        type: FormKeyMode.RELATION,
      });

      actorRemoveAction({
        actionName: 'record',
        path: `${currentResource!.value}.${FormKeyMode.ROOT}`,
      });
    }

    if (dialogType !== 'serviceDialogForm' && dialogData.relationMode) {
      actorDispatch('remove', {
        resource: currentResource!.value,
        type: FormKeyMode.RELATION,
      });
    }

    if (dialogType === 'quickCreateDialog') {
      actorDispatch('refreshView', 'record', {
        disableDebounce: true,
        callerScopeName: 'DialogsStackController => closeDialogHandler',
      });
    }

    actorSetActionValue('quickDialog', null, {
      replaceAll: true,
    });

    dialogData = {};
  };

  /**
   * @function openDynamicDialog
   * @returns {void}
   */
  const openDynamicDialog = (): void => {
    (dialogData as unknown as DialogDataInterface)?.response.actionOutput
      .additionalData.Question &&
      createDialog({
        children: (
          <DynamicDialog
            dialogData={dialogData as unknown as DialogDataInterface}
            closeDialogHandler={closeDialogHandler}
            setDialogs={setDialogs}
            openDynamicDialog={openDynamicDialog}
            gatherDialogData={gatherDialogData}
          />
        ),
      });
  };

  /**
   * @function openQuickCreateDialog
   * @returns {void}
   */
  const openQuickCreateDialog = () => {
    createDialog({
      fullscreen: true,
      children: (
        <QuickCreateDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'quickCreateDialog'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * @function openQuickCreateDropdownDialog
   * @returns {void}
   */
  const openQuickCreateDropdownDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <QuickCreateDropdownDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'dropdownQuickCreateForm'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * @function openPermissionsDialog
   * @returns {void}
   */
  const openPermissionsDialog = useCallback((): void => {
    createDialog({
      children: (
        <PermissionsDialog
          closeDialogHandler={closeDialogHandler}
          dialogData={dialogData as unknown as DialogDataReasonPermissionInterface}
        />
      ),
    });
  }, []);

  /**
   * @function OpenPermissionsAddUser
   * @returns {void}
   */
  const OpenPermissionsAddUser = useCallback((): void => {
    createDialog({
      children: (
        <PermissionsAddUser
          closeDialogHandler={closeDialogHandler}
          dialogData={dialogData}
        />
      ),
    });
  }, []);

  /**
   * open service dialog
   * @function openServiceDialog
   * @returns {void}
   */
  const openServiceDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <ServiceDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType="serviceDialogForm"
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open relation edit dialog
   * @function openRelationEditDialog
   * @returns {void}
   */
  const openRelationEditDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <RelationEditDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'relationEditDialogForm'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * @function openLightBoxPreviewDialog
   * @returns {void}
   */
  const openLightBoxPreviewDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <LightBoxDialog
          dialogData={dialogData as unknown as LightBoxDialogInterface}
          onClose={closeDialogHandler}
        />
      ),
    });
  };
  /**
   * open confirmation dialog
   * @function openRelationEditDialog
   * @returns {void} void
   */
  const openConfirmationDialog = (): void => {
    createDialog({
      children: (
        <ConfirmationDialogView
          currentResource={currentResource!}
          locale={locale}
          dialogType={'quickCreateDialog'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open `GridColumnsSelection` dialog
   * @function openGridColumnsSelectionDialog
   * @returns {void} void
   */
  const openGridColumnsSelectionDialog = (): void => {
    createDialog({
      children: (
        <GridColumnsSelectionDialog
          dialogType={'gridColumnsSelectionDialog'}
          dialogData={dialogData}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open `listSortDialog` dialog
   * @function openGridColumnsSelectionDialog
   * @returns {void} void
   */
  const openListSortDialog = (): void => {
    createDialog({
      children: (
        <SortableList
          dialogData={
            dialogData as {
              data: DynamicData[];
              saveChange: (data: DynamicData[]) => () => void;
            }
          }
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open filter save dialog
   * @function openFilterSaveDialog
   * @returns {void} void
   */
  const openFilterSaveDialog = (): void => {
    createDialog({
      children: (
        <FilterSaveDialog
          dialogData={dialogData as unknown as FilterSaveDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open TodoShare Dialog
   * @function openTodoShareDialog
   * @returns {void} void
   */
  const openTodoShareDialog = (): void => {
    createDialog({
      children: (
        <TodoShareDialog
          dialogData={dialogData as unknown as TodoShareDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open assign Dialog
   * @function openAssignTaskDialog
   * @returns {void} void
   */
  const openAssignTaskDialog = useCallback((): void => {
    createDialog({
      children: (
        <AssignTaskDialogController
          closeDialogHandler={closeDialogHandler}
          dialogData={dialogData as unknown as TodoAssignTaskDialogDataItem}
        />
      ),
    });
  }, []);

  /**
   * open gadget design Dialog
   * @function openGadgetDesignDialog
   * @returns {void} void
   */
  const openGadgetDesignDialog = (): void => {
    createDialog({
      fullscreen: true,
      children: (
        <GadgetDesignDialog
          dialogData={dialogData as unknown as GadgetDesignDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * open ChatCreateGroup Dialog
   * @function openChatCreateGroupDialog
   * @returns {void} void
   */
  const openChatCreateGroupDialog = (): void => {
    createDialog({
      children: (
        <CreateGroupDialog
          closeDialogHandler={closeDialogHandler}
          dialogData={dialogData as unknown as CreateGroupDialogDataItem}
        />
      ),
    });
  };

  /**
   * open ChatInfo Dialog
   * @function openChatInfoDialog
   * @returns {void} void
   */
  const openChatInfoDialog = (): void => {
    createDialog({
      children: (
        <ChatInfoDialog
          dialogData={dialogData as unknown as ChatInfoDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /*
   * open message info Dialog
   * @function openMessageInfoDialog
   * @returns {void} void
   */
  const openMessageInfoDialog = useCallback((): void => {
    createDialog({
      children: (
        <ManageInfoDialog
          dialogData={dialogData as unknown as MessageInfoDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  }, []);

  /**
   * open UserInfo Dialog
   * @function openUserInfoDialog
   * @returns {void} void
   */
  const openUserInfoDialog = useCallback((): void => {
    createDialog({
      children: (
        <UserInfoDialog
          dialogData={dialogData as unknown as ChatInfoDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  }, []);

  /**
   * open ManageGroup Dialog
   * @function openManageGroupDialog
   * @returns {void} void
   */
  const openManageGroupDialog = useCallback((): void => {
    createDialog({
      children: (
        <ManageGroupDialog
          dialogData={dialogData as unknown as ManageGroupDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  }, [dialogData]);

  /**
   * open ChatAddMembers Dialog
   * @function openAddMembersDialog
   * @returns {void} void
   */
  const openAddMembersDialog = (): void => {
    createDialog({
      children: (
        <AddMembersDialog
          closeDialogHandler={closeDialogHandler}
          dialogData={dialogData as unknown as AddGroupMembersDialogDataItem}
        />
      ),
    });
  };

  /**
   * open openAdminsDialog Dialog
   * @function openAdminsDialog
   * @returns {void} void
   */
  const openAdminsDialog = (): void => {
    createDialog({
      children: (
        <AdminsDialog
          closeDialogHandler={closeDialogHandler}
          dialogData={dialogData as unknown as AdminsDialogDataItem}
        />
      ),
    });
  };

  /**
   * open editDashboardCard Dialog
   * @function editDashboardCard
   * @returns {void} void
   */
  const editDashboardCard = (): void => {
    createDialog({
      children: (
        <DashboardCardFormDialog
          closeDialogHandler={closeDialogHandler}
          data={dialogData as unknown as DashboardCardFormDialogItem}
        />
      ),
    });
  };

  /**
   * open SendLocation Dialog
   * @function openSendLocationDialog
   * @returns {void} void
   */
  const openSendLocationDialog = (): void => {
    createDialog({
      children: (
        <SendLocationDialog
          dialogData={dialogData as unknown as SendLocationDialogDataItem}
          closeDialogHandler={closeDialogHandler}
        />
      ),
    });
  };

  /**
   * @function handleKill
   * @param { DialogParams } dialog
   * @returns {void}
   *
   */
  const handleKill = (dialog: DialogParams): void => {
    if (dialog.onExited) dialog.onExited();
    if (dialogs.filter(dialog => dialog.open).length > 0) return;

    setDialogs(dialogs => dialogs.slice(0, dialogs.length - 1));
  };

  /**
   * @function openUserDescriptionDialog
   * @returns {void}
   *
   */
  const openUserDescriptionDialog = (): void => {
    const props = dialogData as unknown as SimpleDialogOption;
    createDialog({
      children: <UserDescriptionDialog {...props} onClose={closeDialogHandler} />,
    });
  };

  return (
    <>
      {dialogs.map((dialog, index) => {
        return (
          <DialogContainer
            key={index}
            dialogIndex={index}
            onKill={() => handleKill(dialog)}
            {...dialog}
            onClose={closeDialogHandler} // this onClose should override on all other onCloses
          />
        );
      })}
    </>
  );
};

export default memo(DialogsStackController);
