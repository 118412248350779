import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    overflow: 'hidden',
  },
  title: {
    marginTop: theme.spacing(1) + 'px !important',
    marginBottom: theme.spacing(1) + 'px !important',
    textAlign: 'center',
  },

  chart: {
    '& text': {
      cursor: 'pointer',
    },
  },
  gridChart: {
    height: '90%',
  },
  containerPagination: {
    height: '10%',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    background: theme.palette.primary.appLightBackgroundColor,
  },
  dataGrid: {
    fontSize: 12,
  },
  listContainer: {},

  bottomToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
