import { Button, ListItemIcon, Tooltip, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import type { ReactElement } from 'react';

import type { ButtonMenuViewInterface } from './button-menu.type';

const ButtonMenuView = (props: ButtonMenuViewInterface): ReactElement => {
  const {
    tooltipTitle,
    anchorEl,
    options,
    selectedOptionIndex,
    selectedItem,
    locale,
    showAnchorDown,
    menuClickHandler,
    menuCloseHandler,
    menuButtonClickHandler,
    onContextMenu,
  } = props;

  const anchorPosition = locale === 'en' ? 'right' : 'left';

  let menuAnchorOriginProps;
  let menuTransformOriginProps;
  if (showAnchorDown) {
    menuAnchorOriginProps = {
      vertical: 'bottom',
      horizontal: anchorPosition,
    };

    menuTransformOriginProps = {
      vertical: 'top',
      horizontal: anchorPosition,
    };
  }

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={menuButtonClickHandler}
        >
          {selectedItem}
          <ArrowDropDownIcon />
        </Button>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={menuCloseHandler}
        onContextMenu={onContextMenu}
        variant="menu"
        anchorOrigin={menuAnchorOriginProps}
        transformOrigin={menuTransformOriginProps}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.text}
            selected={index === selectedOptionIndex}
            onClick={menuClickHandler(index)}
          >
            <ListItemIcon>{option.component}</ListItemIcon>
            <Typography variant="inherit">{option.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonMenuView;
