import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslate } from 'react-admin';

import { DynamicChartSettingsSelector } from './dynamic-chart-settings-selector';
import { CustomAccordion } from '../../../../../custom-accordion';
import { useStyles } from './gadget-settings-tab.style';
import { ChartTypes, MainCharts } from '../../../../../dynamic-chart'; // its a constant
import { replaceArabicCharacters } from '../../../../../../helper/TextHelper';

import type { FC } from 'react';
import type { GadgetSettingsTabViewProps } from './gadget-settings-tab.type';

const GadgetSettingsTabView: FC<GadgetSettingsTabViewProps> = props => {
  const {
    reports,
    gadgetValues,
    onReportSelect,
    onChartTypeSelect,
    gadgetInternalChangeHandler,
    reportInputValue,
    showReportDropdown,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <CustomAccordion
        id="gadgetDialogMainSettings"
        enableChild
        defaultExpanded={true}
        summary={
          <Typography className={classes.accordionSummeryText}>
            {translate('gadget.mainSettings')}
          </Typography>
        }
        customSummaryContainerClass={classes.accordionBackground}
      >
        <div className={classes.accordionView}>
          <Autocomplete
            onChange={onChartTypeSelect}
            //@ts-ignore
            options={ChartTypes.filter(item => item !== MainCharts.Area)}
            className={classes.autoCompleteStyle}
            renderInput={params => {
              return (
                <TextField {...params} label={translate('gadget.selectChartType')} />
              );
            }}
            value={gadgetValues.chartType}
            getOptionLabel={option => option}
          />

          {showReportDropdown && (
            <Autocomplete
              fullWidth
              onChange={onReportSelect}
              options={reports}
              className={classes.autoCompleteStyle}
              renderInput={params => {
                return (
                  <TextField {...params} label={translate('gadget.selectReport')} />
                );
              }}
              value={reportInputValue ?? null}
              getOptionLabel={option => replaceArabicCharacters(option.label)}
            />
          )}
        </div>
      </CustomAccordion>

      <CustomAccordion
        id="gadgetDialogSecondarySettings"
        enableChild
        defaultExpanded={true}
        summary={
          <Typography className={classes.accordionSummeryText}>
            {translate('gadget.chartSettings')}
          </Typography>
        }
        customStyles={{ marginTop: 20 }}
        customSummaryContainerClass={classes.accordionBackground}
      >
        <div className={classes.accordionView}>
          <DynamicChartSettingsSelector
            gadgetInternalChangeHandler={gadgetInternalChangeHandler}
            gadgetValues={gadgetValues}
          />
        </div>
      </CustomAccordion>
    </div>
  );
};

export default GadgetSettingsTabView;
