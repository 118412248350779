import type { ChangeEvent, MutableRefObject } from 'react';

export interface PermissionAssignedViewInterface {
  data: AssignedTableData[] | CompatibleTreeData[];
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  treeView: boolean;
  onSelectItem: (selectedRowId: number) => void;
  searchInputRef: MutableRefObject<HTMLInputElement>;
  selectedIds: number[];
  handleSelectAllClick: () => void;
  isLoading: boolean;
  className?: string;
}

export interface AssignedTableData {
  id: number;
  title: string;
}

export interface RawTreeData {
  currentlevel: number;
  id: number;
  parentid: number;
  title: string;
}
export interface CompatibleTreeData {
  items?: CompatibleTreeData[];
  currentlevel: number;
  id: number;
  isOk: boolean;
  parentid: number | null;
  title: string;
}

export interface TableRowProps {
  id: number;
  title: string;
  checked: boolean;
  classes: Record<string, string>;
  onSelectItem: (id: number) => void;
}

export interface TableHeaderProps {
  classes: Record<string, string>;
  selectAllChecked: boolean;
  handleSelectAllClick: () => void;
  selectAllDisabled: boolean;
}

export enum PermissionAssignType {
  User = 1,
  Role = 2,
  Chart = 3,
}
