import { alpha, makeStyles } from '@material-ui/core';
import { StyleProps } from '.';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  root: {
    fontWeight: ({ isRead }) => (!isRead ? 'bold' : 300),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: '1px solid',
    borderColor: '#fff !important',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20,
    background: ({ isEven }) =>
      !isEven ? alpha(theme.palette.primary.main, 0.13) : '#fff',
    cursor: 'pointer',
    transition: 'all .2s ease',
    transform: 'scale(1)',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      transform: 'translateY(-2px)',
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.common.black, 0.2)}`,
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  crMailTitle: {
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      width: '200px',
    },
  },
  crMailInfo: {
    width: '100%',
    padding: '5px 0 8px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 400px)',
      padding: 0,
      paddingLeft: 16,
    },
  },

  subjectContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  crMailTime: {
    width: '100px',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  appsStarredRoot: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  truncate: {
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    fontWeight: 400,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  whitespacePre: {
    whiteSpace: 'pre',
  },
  styleIConReplay: {
    width: 30,
  },
  containerReply: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
