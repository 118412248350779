import { ReactElement } from 'react';
import { Map as MapContainer, Marker, TileLayer } from 'react-leaflet';
import { icon } from 'leaflet';

import { LocationFieldViewPropsInterface } from './location-field.type';
import { centerLocation } from '../../dynamic-input/location-input/location-input.helper';

const LocationInputView = (props: LocationFieldViewPropsInterface): ReactElement => {
  const { location, handleMapClick } = props;
  return (
    <MapContainer
      center={location ?? centerLocation}
      zoom={16}
      style={{ width: '100%', minHeight: 100, height: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {location && (
        <Marker
          onClick={handleMapClick}
          draggable={false}
          position={location}
          icon={icon({
            iconUrl: require(`../../../images/icon/map/defaultMarkerMap.svg`)
              .default,
            iconSize: [32, 32],
            iconAnchor: [16, 32],
          })}
        ></Marker>
      )}
    </MapContainer>
  );
};

export default LocationInputView;
