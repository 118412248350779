import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { actorOnDispatch, actorRemoveAction } from '../../type/actor-setup';
import { customScrollTo } from '../relation-panel/note-relation/pinned-note/pinned-notes-helper';
import CustomAccordionView from './custom-accordion.view';

import type {
  CustomAccordionInterface,
  ToggleAccordionInterface,
} from './custom-accordion.type';

const CustomAccordionController: FC<CustomAccordionInterface> = memo(props => {
  const { defaultExpanded = false, onChangeCallback, id } = props;

  const [expand, setExpand] = useState<boolean>(defaultExpanded);
  const [showFullScreen, setShowFullScreen] = useState<boolean>();
  useEffect(() => {
    actorOnDispatch('fullScreenItem', detail => {
      setShowFullScreen(!!detail);
    });
  }, []);
  const defaultExpandedRef = useRef<boolean>(false);

  /**
   * @function onChange
   * @param {React.ChangeEvent<unknown> | null} event
   * @returns {void} void
   */
  const onChange = (
    event: React.ChangeEvent<unknown> | null,
    newValue: boolean,
  ): void => {
    defaultExpandedRef.current = false;
    setExpand(newValue);
    newValue && onChangeCallback?.();
  };

  useEffect(() => {
    setExpand(defaultExpanded);
    defaultExpandedRef.current = defaultExpanded;
  }, [defaultExpanded]);

  const checkPropagation = event => {
    if (event.currentTarget?.className?.includes('MuiTypography-root')) {
      return;
    }
    if (
      event.target &&
      event.target.id !== 'summary-target' &&
      event.target.tagName !== 'span' &&
      event.target.getAttribute('data-tag') !== 'accordion-summuary' &&
      event.target.getAttribute('data-tag') !== 'accordion-summuary-title'
    ) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    if (defaultExpandedRef.current) return;
  }, [expand]);

  /**
   * check id relation by id pinned note
   * @function toggleAccordion
   * @param {ToggleAccordionInterface} accordionData
   * @returns {void} void
   */
  const toggleAccordion = useCallback(
    (accordionData: ToggleAccordionInterface): void => {
      if (accordionData.accordionId && defaultExpandedRef.current) {
        customScrollTo(id);
        return;
      }

      if (id === accordionData.accordionId) {
        onChange(null, !expand);
      }
    },
    [expand, defaultExpandedRef.current],
  );

  useEffect(() => {
    const id = actorOnDispatch('toggleAccordion', toggleAccordion, {
      preserve: false,
    });

    return () => {
      actorRemoveAction({
        actionName: 'toggleAccordion',
        listenerId: id,
      });
    };
  }, []);

  return (
    <CustomAccordionView
      checkPropagation={checkPropagation}
      onChange={onChange}
      defaultExpanded={defaultExpandedRef.current}
      expand={expand}
      showFullScreen={showFullScreen}
      {...props}
    />
  );
});

export default CustomAccordionController;
