import { FC } from 'react';
import { useTranslate } from 'react-admin';
import TablePagination from '@material-ui/core/TablePagination';

import { useStyles } from './pagination.style';

import type { PaginationViewInterface } from './pagination.type';

const emptyArray = [];

const PaginationView: FC<PaginationViewInterface> = props => {
  const {
    isLoading,
    isCompactMode,
    total,
    perPage,
    page,
    handlePageChange,
    labelDisplayedRows,
    rowsPerPageOptions,
    handlePerPageChange,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  if (!isLoading && total === 0) {
    return <div />;
  }

  if (isCompactMode) {
    return (
      <TablePagination
        component="span"
        count={total}
        page={page - 1}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        id="tablePagination"
        className={classes.tablePaginationSmall}
        rowsPerPageOptions={emptyArray}
        labelDisplayedRows={labelDisplayedRows}
      />
    );
  }

  // has been changed in commit: f6c770fcd
  return (
    <TablePagination
      component="span"
      count={total}
      page={page - 1}
      onPageChange={handlePageChange}
      rowsPerPage={perPage}
      onChangeRowsPerPage={handlePerPageChange}
      id="tablePagination"
      className={classes.tablePaginationSmall}
      rowsPerPageOptions={rowsPerPageOptions}
      labelDisplayedRows={labelDisplayedRows}
      labelRowsPerPage={translate('ra.navigation.page_rows_per_page')}
      data-test-total-items={total}
    />
  );
};

export default PaginationView;
