import {
  type FC,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';

import lodashDebounce from 'lodash/debounce';

import { replaceArabicCharacters } from '../../../helper/TextHelper';
import { FilterButtonBaseInterface } from './filter-button.type';
import { clone, isEmpty, isEmptyObject } from '../../../helper/data-helper';
import FilterButtonView from './filter-button.view';
import { CONFIG_LIST_LAST_FILTER } from '../../../core/configProvider';
import { showNotification } from '../../../helper/general-function-helper';
import { setAppSettings } from '../../../helper/settings-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FilterFormFieldInterface,
} from '../../../type/actor-setup';

import {
  FilterValueStructureEnum,
  createMultiselectInputFilters,
  generateFilterKeyForAppSetting,
  generateFilterValueForAppSetting,
  getFinalSanitizedFilters,
  getRequiredFilters,
  updateFilterFieldDataByLatestFields,
} from '../filter-form.helper';
import { createFormattedFilterFields } from './filter-button.helper';

import type { GridDataInterface } from '../../../type/actor-setup';
import type { FieldType } from '../../../helper/Types';
import type { FilterItemBaseType } from '../filter-form.type';

const FilterButtonController: FC<FilterButtonBaseInterface> = props => {
  /**
   * @description:FilterButton By `saveSettings = true` we mean that we want to get data from `settings` and save data in `settings`,
   * Otherwise, shouldn't run any actions related to `settings`
   */

  // prettier-ignore
  const { resource, fieldListToFilter, locale, saveSettings } = props;

  const translate = useTranslate();

  const [searchFilterWord, setSearchFilterWord] = useState('');
  const [button, setButton] = useState<ReactElement | null>(null);
  /**
   * @description:2-FilterButton If `anchorEl` has a value it means the menu of this button is open
   */
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [fieldListToDisplay, setFieldListToDisplay] = useState<FieldType[]>([]);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const originalFieldListToFilterRef = useRef<FieldType[]>([]);
  const fieldsNotSelectedToFilterRef = useRef<FieldType[]>([]);
  const originalFiltersRef = useRef<Record<string, FilterFormFieldInterface>>({});
  const selectedFieldListToFilterRef = useRef<
    Record<string, FilterFormFieldInterface>
  >({});

  const onDispatchData = useRef<(keyof ActorActionList)[]>([]);

  useEffect(() => {
    const clonedFieldListToFilter = clone(fieldListToFilter);

    const formattedFields = createFormattedFilterFields({
      fields: clonedFieldListToFilter,
      locale,
      resource,
    });

    originalFieldListToFilterRef.current = [...formattedFields];
    fieldsNotSelectedToFilterRef.current = [...formattedFields];

    for (const field of originalFieldListToFilterRef.current) {
      originalFiltersRef.current[field.name] = {
        fieldData: field,
        value: [field.key ?? field.name, '', ''], // [fieldName(or filterKey in parameters), operator, value]
      };
    }
    setFieldListToDisplay(clonedFieldListToFilter);

    // @description:FilterButton
    if (!isEmpty(resource) && saveSettings) {
      updateFilterFieldListToDisplay();
    }

    actorOnDispatch(
      'filterFormFieldHiddenChanged',
      data => {
        const field = data[resource];
        if (field == null) return;

        if (field.name in selectedFieldListToFilterRef.current) {
          delete selectedFieldListToFilterRef.current[field.name];
        }

        fieldsNotSelectedToFilterRef.current.unshift(field);
        setFieldListToDisplay([...fieldsNotSelectedToFilterRef.current]);
      },
      {
        preserve: false,
        callerScopeName: 'FilterButtonController => useEffect[]',
      },
    );
    onDispatchData.current.push('filterFormFieldHiddenChanged');

    actorOnDispatch(
      'showAllFieldsToFilter',
      () => {
        actorDispatch(
          'filterFormFields',
          { [resource]: originalFiltersRef.current },
          {
            replaceAll: true,
            callerScopeName: 'FilterButtonController',
          },
        );

        if (!saveSettings) {
          setAppSettings({
            key: generateFilterKeyForAppSetting(resource, CONFIG_LIST_LAST_FILTER),
            value: generateFilterValueForAppSetting(originalFiltersRef.current),
            forUser: true,
            onFailure: (): void => {
              showNotification(translate('ra.updatingSettingsFailed'), 'error');
            },
          });
        }
      },
      {
        preserve: false,
        callerScopeName: 'FilterButtonController => useEffect[]',
      },
    );

    onDispatchData.current.push('showAllFieldsToFilter');

    actorOnDispatch(
      'resetOriginalFieldsToFilter',
      data => {
        if (!data[resource]) return;

        selectedFieldListToFilterRef.current = {};
        fieldsNotSelectedToFilterRef.current = [
          ...originalFieldListToFilterRef.current,
        ];

        /**
         * When we have some required fields, they will not remove when we reset fields,
         * Then always `filterFormFields` has those fields and we have to remove them form
         * The button filter to prevent selection again
         */
        const filterFormFields =
          actorGetActionValue('filterFormFields')?.[resource] ?? {};
        for (const filterKey of Object.keys(filterFormFields)) {
          const targetIndex = fieldsNotSelectedToFilterRef.current.findIndex(
            _field => _field.name === filterKey || _field.key === filterKey,
          );
          if (targetIndex === -1) continue;
          fieldsNotSelectedToFilterRef.current.splice(targetIndex, 1);
        }
      },
      {
        preserve: false,
        onDispatchDelay: true, // We want to run this `onDispatch` as the last
        callerScopeName: 'FilterButtonController => useEffect[]',
      },
    );

    onDispatchData.current.push('resetOriginalFieldsToFilter');

    actorOnDispatch(
      'filterFormFields',
      filterFormFields => {
        const resetOriginalFieldsToFilter = actorGetActionValue(
          'resetOriginalFieldsToFilter',
        );
        if (resetOriginalFieldsToFilter?.[resource]) return;

        const _filterFormFields = filterFormFields[resource] ?? {};
        const fieldsKeys = Object.keys(_filterFormFields);
        for (const fieldKey of fieldsKeys) {
          const index = fieldsNotSelectedToFilterRef.current.findIndex(
            field => fieldKey === field.key || fieldKey === field.name,
          );
          if (index > -1) {
            fieldsNotSelectedToFilterRef.current.splice(index, 1);
          }
        }
      },
      {
        preserve: false,
      },
    );

    onDispatchData.current.push('filterFormFields');

    return () => {
      for (const item of onDispatchData.current) {
        actorRemoveAction({
          actionName: item,
          prune: true, // We have to the action and all its listeners
        });
      }
    };
  }, [resource]);

  useEffect(() => {
    const isOpen = !!anchorEl;
    if (isOpen) {
      setFieldListToDisplay([...fieldsNotSelectedToFilterRef.current]);

      requestAnimationFrame(() => {
        searchInputRef.current!.focus();
      });
    }

    return () => {
      if (isOpen) {
        setSearchFilterWord('');
      }
    };
  }, [anchorEl]);

  /**
   * @function updateFilterFieldListToDisplay
   * @returns { void } void
   */
  const updateFilterFieldListToDisplay = useCallback((): void => {
    // prettier-ignore
    const gridData = actorGetActionValue('gridData', resource) as | GridDataInterface | undefined;
    if (isEmptyObject(gridData)) {
      console.warn(
        '`FilterButtonController` => `updateFilterFieldListToDisplay`: `gridData` not found, please check `initializeLastFilters` function',
      );
      return;
    }

    // prettier-ignore
    const gridDataFilters = (gridData!.requestParameters?.filter ?? []) as FilterItemBaseType[];
    const currentFiltersRecord = gridDataFilters.reduce((filtersRecord, filter) => {
      if (Array.isArray(filter)) {
        return {
          ...filtersRecord,
          [filter[FilterValueStructureEnum.KEY] as string]: filter,
        };
      }
      return filtersRecord;
    }, {});

    let finalFilterFormFields: Record<string, FilterFormFieldInterface> = {};

    const requiredFilterFields = getRequiredFilters(resource, locale);
    if (requiredFilterFields.fields.length) {
      const clonedNormalFields = clone(
        fieldListToFilter.filter(
          field =>
            requiredFilterFields.fields.findIndex(
              item => item.name === field.name,
            ) === -1,
        ),
      );

      clonedNormalFields.map(field => {
        field.disabled = false;
        field.readOnly = false;
        field.label = field['translatedCaption']?.[locale] ?? field.caption;
      });

      setFieldListToDisplay(clonedNormalFields);

      finalFilterFormFields = requiredFilterFields.formattedFields!;
    }

    finalFilterFormFields = {
      ...finalFilterFormFields,
      ...currentFiltersRecord,
    };

    finalFilterFormFields = updateFilterFieldDataByLatestFields(
      originalFieldListToFilterRef.current,
      finalFilterFormFields,
    );

    for (const key in finalFilterFormFields) {
      if (key in currentFiltersRecord === false) continue;

      if (currentFiltersRecord[key].length === 4) {
        finalFilterFormFields[key].value = [
          currentFiltersRecord[key][FilterValueStructureEnum.KEY],
          currentFiltersRecord[key][FilterValueStructureEnum.OPERATOR],
          `${currentFiltersRecord[key][2]},${currentFiltersRecord[key][3]}`,
        ];
      } else {
        finalFilterFormFields[key].value = currentFiltersRecord[key];
      }
    }

    selectedFieldListToFilterRef.current = { ...finalFilterFormFields };
    if (Object.keys(selectedFieldListToFilterRef.current).length === 0) {
      actorDispatch(
        'filterFormFields',
        { [resource]: {} },
        {
          replaceAll: true,
          callerScopeName:
            'FilterFormController 1 => updateFilterFieldListToDisplay',
        },
      );
      return;
    }

    actorDispatch(
      'filterFormFields',
      { [resource]: selectedFieldListToFilterRef.current },
      {
        replaceAll: true,
        callerScopeName: 'FilterFormController 2 => updateFilterFieldListToDisplay',
      },
    );

    const filters: ((string | FilterItemBaseType)[] | FilterItemBaseType)[] =
      Object.values(selectedFieldListToFilterRef.current).map(item => item.value);
    for (const key of Object.keys(finalFilterFormFields)) {
      if (finalFilterFormFields[key].fieldData?.dropdown == null) {
        const filter = Object.values(finalFilterFormFields[key]).map(
          item => item.value,
        ) as (FilterItemBaseType | FilterItemBaseType[])[];
        filters.concat(filter);
      } else {
        const multiselectFilters = createMultiselectInputFilters(
          finalFilterFormFields[key].value as [string, string, unknown],
        );
        filters.push(multiselectFilters);
      }
    }

    // We have to clone `filters` to keep original values
    actorSetActionValue('gridData', getFinalSanitizedFilters(clone(filters)), {
      path: `${resource}.requestParameters.filter`,
      callerScopeName: 'FilterFormController => useEffect(... , [resource])',
    });

    setFieldListToDisplay(prevFields => {
      for (const fieldName of Object.keys(selectedFieldListToFilterRef.current)) {
        const index = prevFields.findIndex(
          _field => fieldName === _field.key || fieldName === _field.name,
        );
        if (index === -1) continue;

        prevFields.splice(index, 1);
      }

      fieldsNotSelectedToFilterRef.current = [...prevFields];

      return prevFields;
    });
  }, [resource]);

  /**
   * @function handleClickButton
   * @param { React.MouseEvent<HTMLButtonElement> } event
   * @returns { void } void
   */
  const handleClickButton = useCallback((event: MouseEvent): void => {
    setFieldListToDisplay([]); // To prevent displaying previous `Popover`

    // This prevents ghost click.
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  /**
   * @function handleCloseFilterMenu
   * @returns { void } void
   */
  const handleCloseFilterMenu = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  /**
   * @function handleSearchInputChange
   * @param { React.ChangeEvent<HTMLInputElement> } event
   * @returns { void } void
   */
  const handleSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      setSearchFilterWord(value);

      if (isEmpty(value)) {
        setFieldListToDisplay(fieldsNotSelectedToFilterRef.current);
        return;
      }

      const filteredItems = fieldsNotSelectedToFilterRef.current.filter(field => {
        const isMatch1 =
          replaceArabicCharacters(field.label).toString().indexOf(value) > -1;
        const isMatch2 =
          replaceArabicCharacters(field.name).toString().indexOf(value) > -1;

        return (
          selectedFieldListToFilterRef.current[field.name] == null &&
          (isMatch1 || isMatch2)
        );
      });

      setFieldListToDisplay(filteredItems);
    },
    [],
  );

  /**
   * @function getButtonRef
   * @param { ReactElement } node
   * @returns { void } void
   */
  const getButtonRef = useCallback((node: ReactElement): void => {
    setButton(node);
  }, []);

  /**
   * @function debouncedSearch
   * @returns { void } void
   */
  const debouncedSearch = useCallback(
    lodashDebounce(handleSearchInputChange, 500),
    [],
  );

  /**
   * @function menuItemClickHandler
   * @param { FilterFormFieldInterface } selectedItem
   * @returns { Function } a function
   */
  const menuItemClickHandler = useCallback(
    (selectedItem: FilterFormFieldInterface) => () => {
      const currentFilterFormFields =
        actorGetActionValue('filterFormFields')?.[resource] ?? {};

      const { key, name } = selectedItem.fieldData;
      const filterKey = String(key ?? name);

      // Prevent to set a duplicate field
      if (filterKey in currentFilterFormFields) return;

      selectedFieldListToFilterRef.current[filterKey] = selectedItem;

      // `DynamicFilterInput` just work if the received value is an array
      if (!Array.isArray(selectedItem.value)) {
        selectedItem.value = [filterKey, '', ''];
      }

      actorDispatch(
        'filterFormFields',
        {
          [resource]: {
            ...currentFilterFormFields,
            [filterKey]: selectedItem,
          },
        },
        {
          replaceAll: true,
          callerScopeName: 'FilterButtonController => menuItemClickHandler',
        },
      );

      const targetIndex = fieldsNotSelectedToFilterRef.current.findIndex(
        field => field.name === selectedItem.fieldData.name,
      );
      if (targetIndex > -1) {
        fieldsNotSelectedToFilterRef.current.splice(targetIndex, 1);
      }

      setFieldListToDisplay([...fieldsNotSelectedToFilterRef.current]);

      if (anchorEl) {
        searchInputRef.current!.focus();
      }
    },
    [resource, fieldListToDisplay],
  );

  return (
    <FilterButtonView
      anchorEl={anchorEl} // @description:2-FilterButton
      searchFilterWord={searchFilterWord}
      getButtonRef={getButtonRef}
      button={button}
      fieldListToFilter={fieldListToDisplay}
      resource={resource}
      locale={locale}
      searchInputRef={searchInputRef}
      handleClickButton={handleClickButton}
      handleCloseFilterMenu={handleCloseFilterMenu}
      handleSearchInputChange={debouncedSearch}
      menuItemClickHandler={menuItemClickHandler}
    />
  );
};

export default FilterButtonController;
