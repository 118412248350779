import {
  useEffect,
  useState,
  memo,
  useRef,
  useCallback,
  useMemo,
  type FC,
} from 'react';
import { useTranslate } from 'react-admin';

import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
  waitForAction,
} from '../type/actor-setup';
import LoadingBox from '../component/LoadingBox';
import { ShowRecordWithRelation } from '../component/show-record-with-relation';
import NotFound from '../component/NotFound';

import type { GeneralMetaData, MetaData } from '../helper/Types';
import {
  customRefresh,
  prepareShowFieldsCharacteristics,
} from '../component/show-record-with-relation/show-record-with-relation.helper';
import { removeOnDispatches } from '../helper/general-function-helper';
import { prepareOverrideParams } from '../component/form/form.helper';
import {
  isEmptyObject,
  separateRecordAndRelationRecord,
} from '../helper/data-helper';

interface ShowRecordPageInterface {
  location: Location;
  match: Record<string, unknown> & {
    params: { id: string; moduleName: string; moduleTableName: string };
  };
}

const ShowRecordPage: FC<ShowRecordPageInterface> = props => {
  const { location, match } = props;

  const [firstLoading, setFirstLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  /**
   * There is a possible to get `loading` & `record` actions in different times
   * To get latest `record` & prevent to perform extra re-renders, we use the following `ref`
   */
  const updateRecordPendingRef = useRef(false);

  const currentMetaDataRef = useRef<GeneralMetaData | null>(null);
  const currentRecordRef = useRef<Record<string, unknown> | null>(null);
  const resourceRef = useRef('');

  const translate = useTranslate();

  const { current: currentResource } = actorGetActionValue('resources')!;

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    let listenerId = actorOnDispatch('loading', loadingRecord => {
      /**
       * When a `refreshData` is running, we set this `ref` to `true`
       * So after getting the updated `record` we have to change the state if `processing`
       */
      if (updateRecordPendingRef.current) return;

      setProcessing(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          loadingRecord[currentResource.value] ||
          false,
      );
    });

    onDispatches.push({
      actionName: 'loading',
      listenerId,
    });

    listenerId = actorOnDispatch(
      'record',
      async records => {
        await waitForAction(
          'showSettingsLoading',
          showSettingsLoading => showSettingsLoading === false,
        );

        await waitForAction('metaData', metaData => {
          if (metaData[resourceRef.current] as GeneralMetaData | undefined) {
            currentMetaDataRef.current = metaData[
              resourceRef.current
            ] as GeneralMetaData;
          }

          return currentMetaDataRef.current != null;
        });

        //in single record we have'nt show record page.and should redirect user to create edit form
        if (currentMetaDataRef.current?.config?.hasOneRow) {
          window.location.href = `#/single-record/${resourceRef.current}`;
          return;
        }

        const targetRecord =
          records[resourceRef.current]?.[FormKeyMode.ROOT]?.[RecordKeyMode.FULL];
        if (isEmptyObject(targetRecord)) return;
        currentRecordRef.current = targetRecord ?? null;

        // We got the updated `record`, so we have to change the `processing` state
        if (updateRecordPendingRef.current) {
          updateRecordPendingRef.current = false;

          if (
            currentMetaDataRef.current != null &&
            currentRecordRef.current != null
          ) {
            // compute inputsInitialAppearanceCharacteristics
            prepareShowFieldsCharacteristics(
              currentMetaDataRef.current,
              {
                type: FormKeyMode.ROOT,
                value: resourceRef.current,
              },
              currentRecordRef.current,
            );
          }

          disableLoading();
        }
      },
      { preserve: false },
    );

    onDispatches.push({
      actionName: 'record',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
      resetComponentData();
    };
  }, []);

  const resetComponentData = useCallback(() => {
    actorSetActionValue('gridData', {}, { replaceAll: true });

    currentRecordRef.current = null;
    currentMetaDataRef.current = null;
    updateRecordPendingRef.current = false;

    setFirstLoading(true);
    setProcessing(false);
  }, []);

  const disableLoading = useCallback(() => {
    setFirstLoading(false);
    setProcessing(false);
  }, []);

  const matchParams = useMemo(() => {
    if (match?.params == null) {
      resourceRef.current = '';
      return null;
    }

    resetComponentData();

    const { moduleName, moduleTableName } = match.params;
    resourceRef.current = `${moduleName}/${moduleTableName}`;
    return match.params;
  }, [match]);

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    refreshData(false);

    const listenerId = actorOnDispatch(
      'refreshView',
      viewName => {
        //just get parent record data from server
        if (viewName === 'record' || viewName === 'all') {
          refreshData(undefined, viewName === 'record');
        }
      },
      {
        preserve: false,
      },
    );

    onDispatches.push({
      actionName: 'refreshView',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, [matchParams]);

  /**
   * @function refreshData
   * @param {boolean} disableNotification
   * @returns {void}
   */
  const refreshData = useCallback(
    (disableNotification = true, justRefreshRecordInActor = false) => {
      if (matchParams == null) return;

      updateRecordPendingRef.current = true;
      //when justRefreshRecordInActor is true we dont need to render ShowRecordPage.In this case we handled refresh data in show-record-details
      if (!justRefreshRecordInActor) setProcessing(true);

      customRefresh({
        resource: resourceRef.current,
        recordId: matchParams.id,
        disableNotification,
        successCallback: response => {
          const computedOverrideParams = prepareOverrideParams();

          const { recordWithoutRelationData = {}, relationRecord = {} } =
            separateRecordAndRelationRecord(
              (response.data ?? {}) as Record<string, unknown>,
              currentMetaDataRef.current,
              computedOverrideParams,
            );

          actorDispatch(
            'record',
            {
              [FormKeyMode.ROOT]: {
                [RecordKeyMode.FULL]: {
                  ...(response.data ?? {}),
                  ...computedOverrideParams,
                },
                [RecordKeyMode.FORM]: recordWithoutRelationData,
                [RecordKeyMode.PARENT_RECORD]: relationRecord,
              },
            },
            {
              path: resourceRef.current,
              replaceAll: true,
              disableDebounce: true,
              callerScopeName: 'ShowRecordPage => refreshData',
            },
          );
        },
        failureCallback: (): void => {
          currentRecordRef.current = null;
          disableLoading();
        },
      });
    },
    [matchParams],
  );

  if (firstLoading) {
    return <LoadingBox />;
  }

  if (!currentRecordRef.current) {
    return <NotFound />;
  }

  if (currentMetaDataRef.current?.['error'] === 'networkError') {
    return <NotFound title={translate('ra.notification.http_error')} />;
  }

  const isReport = currentResource.value.indexOf('report') === 0;

  return (
    <ShowRecordWithRelation
      resource={resourceRef.current}
      location={location}
      match={match}
      isReport={isReport}
      record={currentRecordRef.current!}
      refreshData={refreshData}
      loading={processing}
    />
  );
};

export default memo(ShowRecordPage, (prevProps, nextProps) => {
  const prevResource = `${prevProps.match.params.moduleName}/${prevProps.match.params.moduleTableName}`;
  const nextResource = `${nextProps.match.params.moduleName}/${nextProps.match.params.moduleTableName}`;

  return (
    prevResource === nextResource &&
    prevProps.match.params.id === nextProps.match.params.id
  );
});
