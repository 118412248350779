import { ReactElement } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { ProfileAvatar } from '../../../profile-avatar';
import { useStyles } from './todo-item-note.style';

import type { NoteItemViewType } from './todo-item-note.type';

const TodoItemNoteView = (props: NoteItemViewType): ReactElement => {
  const {
    onNewNoteKeyPress,
    onChangeEdit,
    deleteNote,
    handleBlur,
    editValue,
    noteData,
    isEditMode,
    toggleEditMode,
    refTextArea,
    moveCursorToEndOfTextArea,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.noteContainer}>
      <div data-test="todo-note" className={classes.noteContainerUser}>
        <ProfileAvatar userId={noteData.createuserid as number} />
        <div className={classes.noteContent}>
          <div className={classes.noteContentHeader}>
            <div className={classes.author}>{noteData.createusername}</div>
          </div>
        </div>

        <DeleteOutlineIcon
          className={classes.iconActionNote}
          fontSize="medium"
          onClick={deleteNote(noteData?.id)}
        />
      </div>
      {isEditMode ? (
        <TextareaAutosize
          onFocus={moveCursorToEndOfTextArea}
          ref={refTextArea}
          value={editValue}
          onKeyPress={onNewNoteKeyPress(noteData.id)}
          onBlur={handleBlur(noteData.id)}
          onChange={onChangeEdit}
          className={classes.changeStyle}
        />
      ) : (
        <div onClick={toggleEditMode}>
          <span className={classes.textNote}>{editValue}</span>
        </div>
      )}
    </div>
  );
};

export default TodoItemNoteView;
