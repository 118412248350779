import { useEffect, useRef, useState, memo, type FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'react-admin';

import { CONFIG_LIST_COLUMN_CHOICE, DEFAULT } from '../../core/configProvider';
import { getRootResource } from '../../helper/ActorHelper';
import { apiRequestResultHandler } from '../../helper/crud-api.helper';
import { getAppSettings } from '../../helper/settings-helper';
import { isEmbeddedPage } from '../../helper/UrlHelper';
import { ListToolbar } from '../list-toolbar';
import NotFound from '../NotFound';
import LoadingBox from '../LoadingBox';
import ListView from './list.view';
import { SettingBar } from '../list-toolbar/setting-bar';
import { useStyles } from './list.style';
import { Pagination } from '../list-toolbar/pagination';
import { clone } from '../../helper/data-helper';
import {
  actorDispatch,
  actorOnDispatch,
  actorSetActionValue,
  ApiRequestResultInterface,
  GridDataInterface,
} from '../../type/actor-setup';
import {
  prepareActionBarProps,
  preparePaginationProps,
  prepareSettingBarProps,
  setListSort,
  setListPage,
  setListPerPage,
  requestListData,
  getFieldsForDisplay,
  isGadgetList,
} from './list.helper';

import type { GeneralMetaData } from '../../helper/Types';
import type { ListControllerProps } from './list.type';
import type { FinalFiltersType } from '../filter-form';
import type {
  GridSetSortCallback,
  GridSetPageCallback,
  GridSetPerPageCallback,
} from '../grid/grid.type';
import {
  arrayWithAnd,
  removeOnDispatches,
  showNotificationForUnknownError,
} from '../../helper/general-function-helper';

const ListController: FC<ListControllerProps> = props => {
  const { metaData, listType } = props;

  const [listData, setListData] = useState<GridDataInterface | null>(null);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);

  const translate = useTranslate();
  const classes = useStyles();

  const listRequestIdAccessRef = useRef<string | null>(null);

  const history = useHistory();

  const resource = getRootResource();
  const isReport = Boolean(resource?.indexOf('report') === 0);

  /**
   * it will change loading state and grid will show correct `noData` error message
   * @function onFetchDataFailure
   * @param { Record<string, any> } error
   * @returns { void } void
   */
  const onFetchDataFailure = (error: Record<string, any>): void => {
    const errorMessage = error?.GET_LIST?.list?.data;
    showNotificationForUnknownError(errorMessage, translate, true);

    setIsFetchingData(false);
    actorDispatch('loading', false, { path: `${resource}` });
  };

  /**
   * @function handleSuccessGetListData
   * @param {ApiRequestResultInterface} apiSignal
   * @returns {void} void
   */
  const handleSuccessGetListData = (apiSignal: ApiRequestResultInterface): void => {
    actorDispatch('loading', false, { path: `${resource}` });
    apiRequestResultHandler(apiSignal);
  };

  /**
   * get fresh list data and call success or failure handlers
   * @function refreshList
   * @param {boolean} triggerShowLoading if true, show loading box
   * @returns {void} void
   */
  const getListDataAndRefresh = (triggerShowLoading = false): void => {
    actorDispatch('loading', true, { path: `${resource}` });
    if (!resource) return;

    if (triggerShowLoading) {
      setIsFetchingData(true); // show loading
    }

    requestListData(
      resource,
      listType,
      null, // <= parentIdentifier
      null, // <= childFieldName
      null, // <= parentFieldName
      metaData as GeneralMetaData,
      undefined, // <= params
      handleSuccessGetListData, // <= onSuccess
      onFetchDataFailure, // <= onFailure
      true, // <= isListMode
    );
  };

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    getListDataAndRefresh(true); // get initial data

    let listenerId = actorOnDispatch(
      'gridData',
      gridData => {
        if (!resource) return;

        const _listData: GridDataInterface = gridData?.[resource];
        if (!_listData) return;

        const prevRequestId = listRequestIdAccessRef.current;
        const nextRequestId = _listData.lastRequestId;

        // to prevent extra re-renders, compare prev and next request id
        if (prevRequestId !== nextRequestId) {
          setIsFetchingData(false);

          setListData(prev => ({
            ...prev,
            ..._listData,
          }));

          listRequestIdAccessRef.current = _listData.lastRequestId ?? null;
        }
      },
      { callerScopeName: 'ListController' },
    );

    onDispatches.push({
      actionName: 'gridData',
      listenerId,
    });

    listenerId = actorOnDispatch(
      'filterDataIsChanged',
      (finalFilters: Record<string, FinalFiltersType>) => {
        const filter = finalFilters[resource!];

        if (filter) {
          actorSetActionValue('gridData', filter, {
            path: `${resource}.requestParameters.filter`,
            replaceAll: true,
            callerScopeName: 'FilterFormController => updateGridFilters',
          });

          // it will trigger when filters change
          getListDataAndRefresh();
        }
      },
      { preserve: false },
    );

    onDispatches.push({
      actionName: 'filterDataIsChanged',
      listenerId,
    });

    listenerId = actorOnDispatch(
      'refreshView',
      refreshResource => {
        if (refreshResource === 'all' || refreshResource === resource) {
          getListDataAndRefresh();
        }
      },
      { preserve: false },
    );

    onDispatches.push({
      actionName: 'refreshView',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, []);

  if (isFetchingData) {
    return (
      <div className={classes.loadingOrErrorBoxContainer}>
        <LoadingBox />
      </div>
    );
  }

  if (!resource) {
    return (
      <div className={classes.loadingOrErrorBoxContainer}>
        <NotFound title={translate('ra.navigation.no_results')} />
      </div>
    );
  }

  /**
   * redirect to show page when click on a grid row
   * @function onRowClickInTableGrids
   * @param {object} row
   * @returns {void} void
   */
  const onRowClickInTableGrids = (row: Record<string, unknown>): void => {
    if (!row) return;

    history.push(`/${resource}/${row.id}/show`);
  };

  /**
   * it will call global function to change sort
   * @function setSortAdopter
   * @param {string} fieldName
   * @returns {void} void
   */
  const setSortAdopter: GridSetSortCallback = fieldName => {
    setListSort(fieldName, resource, getListDataAndRefresh);
  };

  /**
   * it will call global function to change page
   * @function setPageAdopter
   * @param {number} page
   * @returns {void} void
   */
  const setPageAdopter: GridSetPageCallback = page => {
    setListPage(page, resource, getListDataAndRefresh);
  };

  /**
   * it will call global function to change sort
   * @function setPerPageAdopter
   * @param {number} perPage
   * @returns {void} void
   */
  const setPerPageAdopter: GridSetPerPageCallback = perPage => {
    setListPerPage(perPage, resource, getListDataAndRefresh);
  };

  const defaultSelected =
    getAppSettings<number[]>(
      DEFAULT + '_' + CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
    ).value ?? null;

  const userSelected = getAppSettings<number[]>(
    CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
    true,
  ).value;

  const fields = getFieldsForDisplay(
    defaultSelected,
    userSelected,
    metaData as GeneralMetaData,
    null, // <= disabledFieldList
    true, // <= isListMode
  );

  const actionBarProps = prepareActionBarProps(
    listType,
    resource,
    metaData as GeneralMetaData,
    getListDataAndRefresh,
  );
  const settingBarProps = prepareSettingBarProps(resource, metaData, fields);
  const paginationProps = preparePaginationProps(
    resource,
    setPageAdopter,
    setPerPageAdopter,
    isFetchingData,
    false, // isCompactMode
    false, // isRelation
  );

  /**
   * change selected ids in grid data object in actor a
   * @function onSelectCheckbox
   * @param {string} resource
   * @returns {function}
   */
  const onSelectCheckbox = (selectedIds: Array<string | number>): void => {
    const castedValues = selectedIds.map(id => id?.toString());

    actorDispatch('gridData', castedValues, {
      path: `${resource}.selectedIds`,
      replaceAll: true,
    });
  };

  /**
   * @function setFilters
   * @param {Record<string, unknown>} newFilters
   * @returns {void}void
   */
  const setFilters = (newFilters: Record<string, unknown>): void => {
    // top filter grid use activeResourceRef.current not root resource
    const filter = Object.values(newFilters);
    const filterArray = arrayWithAnd(filter);

    actorSetActionValue('gridData', filterArray, {
      path: `${resource}.requestParameters.searchFilters`,
      replaceAll: true,
    });

    requestListData(
      resource,
      listType,
      null, // <= parentIdentifier
      null, // <= childFieldName
      null, // <= parentFieldName
      metaData as GeneralMetaData,
      undefined, // <= params
      handleSuccessGetListData, // <= onSuccess
      onFetchDataFailure, // <= onFailure
      true, // <= isListMode
    );
  };

  const isEmbedMode = isEmbeddedPage();
  const isMap = (metaData as GeneralMetaData)?.defaultView == 'Map';

  return (
    <div className={classes.listContainer}>
      <ListToolbar actionBarProps={actionBarProps} />

      <ListView
        onRowClick={isReport ? undefined : onRowClickInTableGrids}
        resource={resource}
        metaData={metaData as GeneralMetaData}
        setSort={setSortAdopter}
        hasCreate={!isReport || isGadgetList(resource)}
        hasEdit={!isReport || isGadgetList(resource)}
        hasShow={!isReport || isGadgetList(resource)}
        fields={clone(fields)}
        data={listData?.data ?? []}
        onSelectCheckbox={onSelectCheckbox}
        setFilters={setFilters}
      />

      {isEmbedMode && isMap ? null : (
        <div className={classes.bottomToolbar}>
          <SettingBar {...settingBarProps} />
          <Pagination {...paginationProps} />
        </div>
      )}
    </div>
  );
};

export default memo(ListController, (prevProps, nextProps) => {
  return (
    prevProps.listType === nextProps.listType &&
    prevProps.metaData === nextProps.metaData
  );
});
