import { useRef, type FC, useEffect } from 'react';
import { DataGrid } from 'devextreme-react';

import SimpleGrid from './simple-grid.view';

import { replaceFarsiCharactersWithArabic } from '../../../helper/TextHelper';

import type {
  OnOptionChangeType,
  SimpleGridControllerInterface,
} from './simple-grid.type';
import { AdvancedBooleanCell } from './simple-grid.helper';
import { isEmptyObject } from '../../../helper/data-helper';

const SimpleGridController: FC<SimpleGridControllerInterface> = props => {
  const {
    rows,
    columns,
    groping,
    hasDelete,
    onDeleteRow,
    filterLocally,
    useDynamicHeight = true,
    heigh,
    refreshTabData,
    isAdvanced,
    selectItems,
  } = props;

  const gridRef = useRef<DataGrid>(null);

  useEffect(() => {
    //this is return checkbox with click
    if (isEmptyObject(selectItems)) {
      AdvancedBooleanCell({ selectItems });
    }
  }, [selectItems]);

  /**
   * @function onOptionChanged
   * @param { OnOptionChangeType } event
   * @returns {void}
   */
  const onOptionChanged = (event: OnOptionChangeType): void => {
    gridRef.current?.instance.columnOption(
      replaceFarsiCharactersWithArabic(event.value),
    );
  };

  return (
    <SimpleGrid
      rows={rows}
      columns={columns}
      groping={groping}
      useDynamicHeight={useDynamicHeight}
      hasDelete={hasDelete}
      filterLocally={filterLocally}
      refreshTabData={refreshTabData}
      onDeleteRow={onDeleteRow}
      onOptionChanged={onOptionChanged}
      gridRef={gridRef}
      heigh={heigh}
      isAdvanced={isAdvanced}
      selectItems={selectItems}
    />
  );
};
export default SimpleGridController;
