import { FC, memo, useEffect, useRef } from 'react';

import { SavedFabData } from '../../mail-section';
import { MailFabInterface } from './mail-fab.type';
import MailFabView from './mail-fab.view';
import { clone } from '../../../helper/data-helper';
import {
  FormKeyMode,
  actorDispatch,
  actorGetActionValue,
} from '../../../type/actor-setup';

const MailFabController: FC<MailFabInterface> = memo(props => {
  const { handleFabOpen, handleFabRemove, mail } = props;
  const translate = actorGetActionValue('reactAdminHelpers')?.translate;

  /**
   * @function onClickOpen
   * @param {SavedFabData} mail
   * @returns {void}
   */
  const onClickOpen =
    (mail: SavedFabData) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      handleFabOpen && handleFabOpen(mail);
    };

  /**
   * @function onClickRemove
   * @param {SavedFabData} mail
   * @param {MouseEvent<HTMLElement, MouseEvent>} event
   * @returns {void}
   */
  const onClickRemove =
    (mail: SavedFabData) =>
    (event: React.MouseEvent<HTMLElement>): void => {
      event.stopPropagation();
      actorDispatch('quickDialog', {
        confirmationIsOpen: true,
        data: {
          content: translate?.('ra.message.are_you_sure'),
          onConfirm: (confirmationCallback?: () => void) => {
            if (typeof handleFabRemove == 'function') {
              handleFabRemove(event, mail);
            }
            confirmationCallback?.();
          },
        },
      });
    };

  return (
    <MailFabView
      onClickOpen={onClickOpen}
      onClickRemove={onClickRemove}
      mail={mail}
    />
  );
});
export default MailFabController;
