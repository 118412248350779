import { type FilterFormFieldInterface } from '../../type/actor-setup';
import type { SimpleGridColumn } from './simple-grid';
import type { PermissionsTab } from './show-permissions.type';

export const urlPermissions = 'permission/SetPermission';
export const permissionReasonUrl = 'Permission/ReasonPermission';

export const showPermissionResources: Record<number, string> = {
  0: 'Permission/GetAllMenuPermission',
  1: 'Permission/GetAllTablePermission',
  2: 'Permission/GetAllProcessPermission',
  3: 'Permission/GetAllTableActionPermission',
  4: 'Permission/GetAllMacroPermission',
  5: 'Permission/GetAllReportPermission',
  6: 'Permission/GetAllReportActionPermission',
  7: 'Permission/GetAllPrintPermission',
  8: 'Permission/GetAllWMSPermission',
  9: 'Permission/GetAllApiServicePermission',
  10: 'Permission/GetAllIrregularPermission',
  11: 'Permission/GetAllAdminPermission',
};

export const permissionReasonColumns: SimpleGridColumn[] = [
  {
    name: 'attributionName',
    type: 'text',
    title: 'نوع',
  },
  {
    name: 'title',
    type: 'text',
    title: 'عنوان',
  },
  {
    name: 'departmentTitles',
    type: 'text',
    title: 'شعبه',
  },
];

export const tabColumns: Record<string, SimpleGridColumn[]> = {
  menuPermissions: [
    {
      name: 'allowView',
      type: 'checkbox',
      title: 'مشاهده',
    },
    {
      name: 'title',
      type: 'text',
      title: 'عنوان',
    },
  ],
  tablePermissions: [
    {
      name: 'moduleName',
      type: 'text',
      title: 'ماژول',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'tableTitle',
      type: 'text',
      title: 'جدول',
    },
    {
      name: 'fieldName',
      type: 'text',
      title: 'فیلد',
    },
    {
      name: 'allowView',
      type: 'checkbox',
      title: 'نمایش',
    },
    {
      name: 'allowEdit',
      type: 'checkbox',
      title: 'ویرایش',
    },
    {
      name: 'allowDelete',
      type: 'checkbox',
      title: 'حذف',
    },
    {
      name: 'allowAdd',
      type: 'checkbox',
      title: 'ایجاد',
    },
    {
      name: 'allowListView',
      type: 'checkbox',
      title: 'نمایش لیستی',
      permKey: 'allowListViewPermKey',
    },
    {
      name: 'allowView',
      type: 'checkbox',
      title: 'نمایش رکورد های همه کاربران',
      permKey: 'allowAdditionWhereByPassPermKey',
    },
    {
      name: 'allowExport',
      type: 'checkbox',
      title: 'خروجی گرفتن',
    },
    {
      name: 'allowMoveProcessTo',
      type: 'checkbox',
      title: 'انتقال فرایند',
      permKey: 'allowMoveProcessToPermKey',
    },
    {
      name: 'fieldHasView',
      type: 'checkbox',
      title: 'مشاهده کلیه ردیفها بدون شرط اضافه',
      permKey: 'allowViewAllUserRecordsPermKey',
    },
  ],
  processPermissions: [
    {
      name: 'moduleTitle',
      type: 'text',
      title: 'ماژول',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'tableTitle',
      type: 'text',
      title: 'جدول',
      groping: 1,
    },
    {
      name: 'processTitle',
      type: 'text',
      title: 'فرایند',
    },
    {
      name: 'moduleTitle',
      type: 'text',
      title: 'مرحله',
    },
    {
      name: 'departments',
      type: 'text',
      title: 'شعبی که مجوز تنظیم شده',
    },
    {
      name: 'taskTitle',
      type: 'text',
      title: 'دکمه',
    },
    {
      name: 'allowView',
      type: 'checkbox',
      title: 'نمایش',
    },
    {
      name: 'isLinePermission',
      type: 'checkbox',
      title: 'مجوز دکمه',
      permKey: 'allowButtonPermKey',
    },
  ],
  regularPermissions: [
    {
      name: 'allowExecutePermKey',
      type: 'text',
      title: 'کلید مجوز',
    },
    {
      name: 'title',
      type: 'text',
      title: 'عنوان مجوز',
    },
    {
      name: 'hasPermission',
      type: 'checkbox',
      title: 'مجوز',
      permKey: 'allowExecutePermKey',
    },
  ],
  reportActionPermissions: [
    {
      name: 'actionTitle',
      type: 'text',
      title: 'گزارش',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'reportTitle',
      type: 'text',
      title: 'عنوان سرویس',
    },
    {
      name: 'allowExecute',
      type: 'checkbox',
      title: 'مجوز اجرا',
    },
  ],
  reportPermissions: [
    {
      name: 'moduleName',
      type: 'text',
      title: 'ماژول',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'tableName',
      type: 'text',
      title: 'جدول',
      groping: 1,
    },
    {
      name: 'reportName',
      type: 'text',
      title: 'گزارش',
    },
    {
      name: 'parentReportName',
      type: 'text',
      title: 'گزارش اصلی',
    },
    {
      name: 'allowView',
      type: 'checkbox',
      title: 'نمایش',
    },
    {
      name: 'allowExport',
      type: 'checkbox',
      title: 'خروجی فایل',
      permKey: 'allowExportPermKey',
    },
  ],
  macroPermissions: [
    {
      name: 'moduleName',
      type: 'text',
      title: 'ماژول',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'tableName',
      type: 'text',
      title: 'جدول',
      groping: 1,
    },
    {
      name: 'macroTitle',
      type: 'text',
      title: 'عنوان ماکرو',
    },
    {
      name: 'allowExecute',
      type: 'checkbox',
      title: 'مجوز اجرا',
    },
  ],
  tableActionPermissions: [
    {
      name: 'moduleName',
      type: 'text',
      title: 'ماژول',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'tableName',
      type: 'text',
      title: 'جدول',
      groping: 1,
    },
    {
      name: 'actionTitle',
      type: 'text',
      title: 'عنوان سرویس',
    },
    {
      name: 'allowExecute',
      type: 'checkbox',
      title: 'مجوز اجرا',
    },
  ],
  wmsPermissions: [
    {
      name: 'stockControl',
      type: 'text',
      title: 'عنوان',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'stockItem',
      type: 'text',
      title: 'تب',
    },
    {
      name: 'layouItem',
      type: 'text',
      title: 'ایتم',
    },
    {
      name: 'hasViewPermission',
      type: 'checkbox',
      title: 'نمایش',
      permKey: 'allowViewPermKey',
    },
    {
      name: 'hasEditPermission',
      type: 'checkbox',
      title: 'ویرایش',
      permKey: 'allowEditPermKey',
    },
    {
      name: 'barcodeReaderEntryControl',
      type: 'checkbox',
      title: 'مجوز بارکد ریدر',
      permKey: 'barcodeReaderEntryControlPermKey',
    },
  ],
  printPermissions: [
    {
      name: 'moduleName',
      type: 'text',
      title: 'ماژول',
      groping: 0,
      fixedPosition: true,
    },
    {
      name: 'tableName',
      type: 'text',
      title: 'جدول',
      groping: 1,
    },
    {
      name: 'reportName',
      type: 'text',
      title: 'گزارش',
    },

    {
      name: 'printName',
      type: 'text',
      title: 'عنوان پرینت',
    },
    {
      name: 'allowExecute',
      type: 'checkbox',
      title: 'مجوز اجرا',
    },
  ],
  apiServicePermissions: [
    {
      name: 'actionTitle',
      type: 'text',
      title: 'عنوان سرویس',
    },
    {
      name: 'actionId',
      type: 'text',
      title: 'شناسه',
    },
    {
      name: 'uniqueId',
      type: 'text',
      title: 'شناسه یکتا',
    },
    {
      name: 'allowExecute',
      type: 'checkbox',
      title: 'مجوز یکتا',
    },
  ],
  adminPermissions: [
    {
      name: 'title',
      type: 'text',
      title: 'عنوان',
    },
    {
      name: 'allowViewPermKey',
      type: 'text',
      title: 'کلید مجوز',
    },
    {
      name: 'allowView',
      type: 'checkbox',
      title: 'مشاهده',
    },
  ],
};

export const permissionsTab: PermissionsTab[] = [
  {
    id: '0',
    title: 'مجوز های منو',
    columns: tabColumns.menuPermissions,
    viewType: 'tree',
  },
  {
    id: '1',
    title: 'مجوز های جداول',
    columns: tabColumns.tablePermissions,
    viewType: 'grid',
  },
  {
    id: '2',
    title: 'مجوز های فرآیند',
    columns: tabColumns.processPermissions,
    viewType: 'grid',
  },
  {
    id: '3',
    title: 'مجوز های سرویس جداول',
    columns: tabColumns.tableActionPermissions,
    viewType: 'grid',
  },
  {
    id: '4',
    title: 'مجوز های ماکرو',
    columns: tabColumns.macroPermissions,
    viewType: 'grid',
  },
  {
    id: '5',
    title: 'مجوز های گزارش',
    columns: tabColumns.reportPermissions,
    viewType: 'grid',
  },
  {
    id: '6',
    title: 'مجوز های سرویس گزارش',
    columns: tabColumns.reportActionPermissions,
    viewType: 'grid',
  },
  {
    id: '7',
    title: 'مجوز های چاپ',
    columns: tabColumns.printPermissions,
    viewType: 'grid',
  },
  {
    id: '8',
    title: 'مجوز های انبارداری',
    columns: tabColumns.wmsPermissions,
    viewType: 'grid',
  },
  {
    id: '9',
    title: 'مجوز های سرویس Api',
    columns: tabColumns.apiServicePermissions,
    viewType: 'grid',
  },
  {
    id: '10',
    title: 'سایر مجوز ها',
    columns: tabColumns.regularPermissions,
    viewType: 'grid',
  },
  {
    id: '11',
    title: 'مجوز های ادمین',
    columns: tabColumns.adminPermissions,
    viewType: 'grid',
  },
];

export const customSelectedFilterFields: FilterFormFieldInterface[] = [
  {
    fieldData: {
      caption: 'کاربران فعال',
      dataType: {
        erp: 'dropdown',
        sql: 'bigint',
        simple: 'number',
        defaultOperator: 'Equals',
      },
      disabled: false,

      javaScriptFormula: '',
      dropdown: {
        filterValueMember: 'personinfo_id',
        columns: [
          {
            isHidden: false,
            width: 100,
            priority: 0,
            title: 'personinfo_id',
            format: null,
            dataType: {
              erp: 'bigint',
              sql: 'bigint',
              simple: 'number',
              id: 127,
              defaultOperator: 'Equals',
            },
          },
          {
            isHidden: false,
            width: 100,
            priority: 0,
            title: 'fathername',
            format: null,
            dataType: {
              erp: 'string',
              sql: 'nvarchar(max)',
              simple: 'string',
              id: 128,
              defaultOperator: 'Equals',
            },
          },
          {
            isHidden: false,
            width: 100,
            priority: 0,
            title: 'fullname',
            format: null,
            dataType: {
              erp: 'string',
              sql: 'nvarchar(max)',
              simple: 'string',
              id: 129,
              defaultOperator: 'Equals',
            },
          },
        ],
        displayMember: 'fullname',
        displayMember2: null,
        forceTreeLevel: false,
        id: '5221',
        moduleName: 'task',
        parameters: [],
        remoteSearch: true,
        translatedComment: null,
        translatedTitle: {
          fa: 'کاربران فعال',
          en: 'activeUser',
          ar: 'activeUser',
        },
        type: 'Simple',
        uniqueId: '369a7a95-2b1d-4c26-8002-77f1ce53e368',
        valueMember: 'personinfo_id',
        name: 'personinfo_id',
        title: 'personinfo_id',
      },
      hidden: true,
      id: 1,
      name: 'personinfo_id',
      translatedCaption: {
        fa: 'کاربران فعال',
        en: 'activeUser',
        ar: 'activeUser',
      },
      editable: false,
      hasEditPermission: false,
      relatedName: '__permissions_user',
      required: true,
      values: null,
      resource: '',
    },
    value: ['personinfo_id', 'equals', ''],
  },
  {
    fieldData: {
      forceTreeLevel: true,
      caption: 'منو ها',
      dataType: {
        erp: 'dropdown',
        sql: 'bigint',
        simple: 'number',
        defaultOperator: 'Equals',
      },
      disabled: false,
      javaScriptFormula: 'menu_id',
      dropdown: {
        filterValueMember: 'menu_id',
        type: 'Tree',
        columns: [
          {
            isHidden: false,
            width: 100,
            priority: 0,
            title: 'caption',
            format: null,
            dataType: {
              erp: 'bigint',
              sql: 'bigint',
              simple: 'number',
              id: 128,
              defaultOperator: 'Equals',
            },
          },
        ],
        displayMember: 'caption',
        displayMember2: null,
        forceTreeLevel: true,
        id: '5220',
        moduleName: 'task',
        parameters: [],
        remoteSearch: false,
        translatedComment: null,
        treeLevel: 0,
        translatedTitle: {
          fa: 'منو ها',
          en: 'menus',
          ar: 'menus',
        },
        uniqueId: 'ca743173-0a5b-4b89-b9e5-25a7c0dbe3f1',
        valueMember: 'menu_id',
        name: 'menuId',
        title: 'menuId',
      },
      hidden: true,
      id: 0,
      name: 'menu_id',
      translatedCaption: {
        fa: 'منو ها',
        en: 'menus',
        ar: 'menus',
      },
      relatedName: 'menu_id',
      required: false,
      values: null,
      resource: '',
    },
    value: ['menu_id', 'equals', ''],
  },
];
export const customSelectedFilterFieldsForAdvancedPermissions: FilterFormFieldInterface[] =
  [
    {
      fieldData: {
        forceTreeLevel: true,
        caption: 'منو ها',
        dataType: {
          erp: 'dropdown',
          sql: 'bigint',
          simple: 'number',
          defaultOperator: 'Equals',
        },
        disabled: false,
        javaScriptFormula: 'menu_id',
        dropdown: {
          filterValueMember: 'menu_id',
          type: 'Tree',
          columns: [
            {
              isHidden: false,
              width: 100,
              priority: 0,
              title: 'caption',
              format: null,
              dataType: {
                erp: 'bigint',
                sql: 'bigint',
                simple: 'number',
                id: 128,
                defaultOperator: 'Equals',
              },
            },
          ],
          displayMember: 'caption',
          displayMember2: null,
          forceTreeLevel: true,
          id: '5220',
          moduleName: 'task',
          parameters: [],
          remoteSearch: false,
          translatedComment: null,
          treeLevel: 0,
          translatedTitle: {
            fa: 'منو ها',
            en: 'menus',
            ar: 'menus',
          },
          uniqueId: 'ca743173-0a5b-4b89-b9e5-25a7c0dbe3f1',
          valueMember: 'menu_id',
          name: 'menuId',
          title: 'menuId',
        },
        hidden: true,
        id: 0,
        name: 'menu_id',
        translatedCaption: {
          fa: 'منو ها',
          en: 'menus',
          ar: 'menus',
        },
        relatedName: 'menu_id',
        required: false,
        values: null,
        resource: '',
      },
      value: ['menu_id', 'equals', ''],
    },
  ];
