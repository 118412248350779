import { FC, useCallback, useEffect, useRef, useState } from 'react';
import lodashDebounce from 'lodash/debounce';
import { Icon, IconButton, makeStyles, Theme } from '@material-ui/core';
import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import { FieldType } from '../../helper/Types';
import { isEmpty } from '../../helper/data-helper';

// TODO: Make TextFilterInput && NumberFilterInput a single Component

interface TextFilterInputType {
  value: string | number | null;
  onChange?: Function;
  field?: FieldType;
  source?: string;
  disabled?: boolean;
  getRef?: (ref: HTMLElement | null) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    display: 'flex',
    flex: 1,
  },

  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
    background: 'inherit',
    fontFamily: theme.typography.fontFamily,
  },

  closeIcon: {
    marginRight: '2px',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 5,
    },
  },
}));

const TextFilterInput: FC<TextFilterInputType> = props => {
  const { value, onChange, disabled, source } = props;

  /**
   * TODO:
   * We need `getRef` in the future,
   *  to handle changing focus of inputs by pressing `enter`
   */

  const [inputValue, setInputValue] = useState<string | number>('');

  const classes = useStyles();

  useEffect(() => {
    const _value = String(value ?? '');
    setInputValue(_value);
    onChange?.(_value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = convertDigitsToEnglish(event.target.value);
    setInputValue(value);
    onChange?.(value);
  };

  const clearHandler = useCallback(() => {
    setInputValue('');
    onChange?.('');
  }, []);

  return (
    <div className={classes.inputContainer} data-test-field-name={source}>
      <input
        value={inputValue ?? ''}
        onChange={handleChange}
        className={classes.input}
        data-test-field-name={props.source}
        disabled={disabled}
      />
      {!disabled && !isEmpty(inputValue) && (
        <IconButton
          role={'clearable'}
          size="small"
          tabIndex={-1}
          onClick={clearHandler}
          disabled={disabled}
          data-test-input-name="auto-complete-search-button"
        >
          <Icon fontSize="small" className={classes.closeIcon}>
            close
          </Icon>
        </IconButton>
      )}
    </div>
  );
};

export default TextFilterInput;
