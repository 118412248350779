import React, { memo, type ReactElement, useEffect, useRef } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import {
  Route,
  Redirect,
  withRouter,
  type RouteComponentProps,
} from 'react-router-dom';

import '../api/global-api';
import '../api/wms-api';
import '../api/dropdown-api';
import '../api/profile-api';
import '../api/service-api';
import '../api/stream-file-api';
import '../api/visitorCalendar-api';
import '../api/mail-api';
import '../api/chat-api';
import '../api/pivot-api';
import '../api/crud-api';
import '../api/print-api';
import '../api/quick-access-menu-api';

import { getValue, USER_TOKEN } from '../core/configProvider';
import {
  actorDispatch,
  actorGetActionValue,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  ResourceInterface,
} from '../type/actor-setup';
import { isEmpty, isEmptyObject } from '../helper/data-helper';
import {
  ADVANCE_PERMISSION,
  MAIL,
  PERMISSION,
  TODO,
  VISITOR_CALENDAR,
  WMS,
} from '../core/configRouteConstant';
import { MailPageResource } from '../component/mail-section/mail-section.helper';
import { visitorCalenderResource } from '../page/VisitorCalendarPage';
import { getSessionIdInUrl } from '../helper/UrlHelper';
import { shouldUpdateProfile, getProfile } from '../helper/ProfileHelper';

interface PrivateRoutePropsInterface {
  component: any; // FIXME: Search and find a correct type
  computedMatch: {
    isExact: boolean;
    params: Record<string, string>;
    path: string;
    url: string;
  };
  exact: boolean;
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
  match: RouteComponentProps['match'];
  path: string;
  staticContext?: RouteComponentProps['staticContext'];
}

const PrivateRoute = (props: PrivateRoutePropsInterface): ReactElement => {
  const { component: Component, ...rest } = props;
  const { computedMatch, location: routerDomLocation, history } = rest;
  const { params } = computedMatch;
  let newResource: string | undefined;
  const currentResource = actorGetActionValue('resources');
  const reduxDispatch = useDispatch();
  const preventToShowLoadingInWMS = useRef(false);

  const translate = useTranslate();
  const locale = useLocale();

  const sessionIdInUrl = getSessionIdInUrl();

  const profileData = actorGetActionValue('profile');
  if (
    (!sessionIdInUrl && !profileData?.profileData && !profileData?.isLoading) ||
    (sessionIdInUrl && shouldUpdateProfile(sessionIdInUrl))
  ) {
    getProfile(translate, sessionIdInUrl ?? undefined);
  }

  useEffect(() => {
    if (!actorGetActionValue('reactAdminHelpers')) {
      actorSetActionValue('reactAdminHelpers', {
        translate,
        locale,
        reduxDispatch,
      });
    }
  }, []);

  //TODO: remove this line when dashbloardlist refactored and all actions moved to /dashboard/DASHBOARD_UNIQUE_ID
  if (computedMatch.url === '/report/dashinfo') {
    history.push('/dashboardlist');
  }
  actorDispatch('closeDialogs', true);

  actorDispatch('urlInfo', {
    params,
    location: {
      ...location,
      ...routerDomLocation,
      pathname: rest.location.pathname, // We need `pathname` key in `rest.location`
    },
  });

  if (params.moduleName && params.moduleTableName) {
    newResource = `${params.moduleName}/${params.moduleTableName}`.toLowerCase(); // to prevent multiple unnecessary requests
  } else if (location.href.includes(WMS)) {
    newResource = `${WMS}/${params.id}`;
    // In `wms`, the root resource is the main resource { e.g. currentResource => wms/13/tab-0, rootResource => wms/13 }
    const rootResource = actorGetActionValue('resources')?.stack[0];
    if (newResource === rootResource?.value) {
      preventToShowLoadingInWMS.current = true;
    }
  }
  // simulate resource (for InboxPage)
  else if (location.href.includes(MAIL) || location.href.includes(TODO)) {
    newResource = MailPageResource;
  } else if (location.href.includes('showpermissions')) {
    newResource = PERMISSION;
  } else if (location.href.includes('advancepermission')) {
    newResource = ADVANCE_PERMISSION;
  }
  // simulate resource (for visitor calender)
  else if (location.href.includes(VISITOR_CALENDAR)) {
    newResource = visitorCalenderResource;
  } else if (computedMatch?.path === '/multi-report/:reportId/:tabIndex?') {
    newResource = `report/${computedMatch.params.reportId.toLowerCase()}`;
  }
  if (newResource) {
    //FIXME: Think and write better codes here
    if (!isEmptyObject(currentResource)) {
      const { type, value } = currentResource!.current;
      actorDispatch(
        'formMessages',
        {},
        {
          path: `${value}.${type}`,
          replaceAll: true,
        },
      );

      actorRemoveAction({
        actionName: 'formData',
        path: `${value}.${type}`,
      });

      actorRemoveAction({
        actionName: 'record',
        path: `${value}.${type}`,
      });
    }

    const parentUrl = actorGetActionValue(
      'quickCreateSupplementaryData',
      `${newResource}.${FormKeyMode.RELATION}.parentUrl`,
    );

    let resourcesStack: ResourceInterface[] = [];

    if (!isEmpty(parentUrl)) {
      resourcesStack = [
        {
          type: FormKeyMode.ROOT,
          value: currentResource?.current?.value ?? '',
        },
        {
          type: FormKeyMode.RELATION,
          value: newResource,
        },
      ];
    } else {
      resourcesStack = [
        {
          type: FormKeyMode.ROOT,
          value: newResource,
        },
      ];
    }

    // In `wms`, after first loading and getting `metData` we don't need to show `loading`
    if (!preventToShowLoadingInWMS.current) {
      actorDispatch('showLoading', true);
    }

    actorDispatch(
      'resources',
      {
        stack: resourcesStack,
        current: {
          type: !isEmpty(parentUrl) ? FormKeyMode.RELATION : FormKeyMode.ROOT,
          value: newResource,
        },
      },
      {
        callerScopeName: 'PrivateRoute',
        replaceAll: true,
      },
    );
  }

  const currentUrl =
    actorGetActionValue('urlInfo')?.location?.href ?? window.location.href ?? '';

  if (
    getValue(USER_TOKEN) ||
    (currentUrl && currentUrl.toLowerCase().includes('sessionid')) ||
    getSessionIdInUrl()
  ) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  sessionStorage.setItem('redirectUrl', location.hash.slice(2)); // `slice` removes `#/`
  return <Route {...rest} render={() => <Redirect to="/login" />} />;
};

export default withRouter(
  memo(PrivateRoute, (prevProps, nextProps) => {
    return (
      prevProps.location.pathname === nextProps.location.pathname &&
      prevProps.location.search === nextProps.location.search
    );
  }),
);
