import './jalali-date-input.style.css';
import { ReactElement } from 'react';
import { useLocale } from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Cleave from 'cleave.js/react';
import { useStyles } from './jalali-date-input.style';
import DatePicker from '../../date-picker';
import {
  CleaveInputDateOptions,
  CleaveInputDateTimeOptions,
} from './jalali-date-input.helper';

// TODO: fix type DatePicker2-samian
const JalaliDateInputGridView = (props: any): ReactElement => {
  const {
    visibleClass,
    resource,
    disabled,
    inputMessage,
    DateInputInPuzzleForm,
    label,
    name,
    inputFormat,
    handleChange,
    handleInputMaskChange,
    handleInputMaskBlur,
    internalValue,
    maskInputValue,
    calendarConfig,
    options,
    onFocus,
    onBlur,
    datePickerRef,
    simpleType,
    datePickerType,
  } = props;
  const locale = useLocale();
  const classes = useStyles({ disabled });

  return (
    <fieldset
      data-test-field-name={`${resource}/${name}`}
      className={`${visibleClass} datePickerContainer ${classes.fieldset} ${classes.gridDateInput}`}
      data-error={!!inputMessage?.message}
      data-label={!!label || label !== ''}
      data-puzzle-form={!!DateInputInPuzzleForm}
      disabled={disabled}
    >
      <InputAdornment position="start" className={classes.InputAdornment}>
        <div
          data-test="date-picker-icon"
          onClick={onFocus}
          className={classes.datePickerIcon}
        >
          <DateRangeIcon style={{ fontSize: '18px' }} />
        </div>
      </InputAdornment>

      <Cleave
        options={
          datePickerType === 'dateTime'
            ? CleaveInputDateTimeOptions
            : CleaveInputDateOptions
        }
        value={maskInputValue}
        className={classes.inputMask}
        onChange={handleInputMaskChange}
        onBlur={handleInputMaskBlur}
        data-test="date-picker-input"
      />

      <DatePicker
        {...options}
        ref={datePickerRef}
        inputFormat={inputFormat}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={internalValue}
        disabled={disabled}
        isGregorian={calendarConfig === 'gregorian'}
        timePicker={simpleType === 'datetime'}
        showTodayButton={false}
        setTodayOnBlur={false}
        locale={locale}
      />
    </fieldset>
  );
};

export default JalaliDateInputGridView;
