import { type FC, memo } from 'react';
import { Checkbox, Typography } from '@material-ui/core';
import type { TableRowProps } from './permission-assigned.type';

const TableRow: FC<TableRowProps> = props => {
  const { id, title, checked, classes, onSelectItem } = props;

  return (
    <div className={classes.tableRow}>
      <Checkbox onClick={() => onSelectItem(id)} checked={checked} />
      <Typography variant="body2" className={classes.typography}>
        {id}
      </Typography>
      <Typography variant="body2" className={classes.typography}>
        {title}
      </Typography>
    </div>
  );
};

export default memo(TableRow, (prevProps, nextProps) => {
  return prevProps.id === nextProps.id && prevProps.checked === nextProps.checked;
});
