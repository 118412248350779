import { memo, useCallback, useEffect, useReducer, useRef, type FC } from 'react';
import { useTranslate } from 'react-admin';

import MessagesListView from './messages-list.view';
import {
  findUnseenMessagesFromCurrentMessages,
  getMessagesBoxElements,
  getSeenMessagesElementsInViewport,
  scrollToFirstUnSeenMessage,
  _scrollTo,
} from './messages-list-helper/messages-list-common';
import {
  messagesListInitialState,
  messagesListReducer,
} from './messages-list-helper/messages-list-reducer';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../type/actor-setup';
import {
  ChatMessageTypeEnum,
  chatSectionSignalRManager,
  getChatTotalUnSeen,
  getMessageSenderUser,
  getSignalRMessageType,
  getSignalRMessages,
  messageSendManager,
  handleDeleteMessage,
  createFormattedMessagesFromSignalRMessages,
} from '../chat-section.helper';
import { chatStatelessActions } from '../chat-section-helper/chat-section-stateless-actions';
import { highlightMessageAndScrollTo } from './message-item/message-item.helper';
import { syncSeenMessages } from '../chat-section-helper/chat-section-common';
import { getGroupUsers } from '../group-chats.helper';
import { getPinsReportId } from '../chat-pins/chat-pins.helper';

import { customDebounce } from '../../../helper/general-function-helper';
import { objectToLowerCaseProperties } from '../../../helper/data-helper';

import type { ChatTextJsonFormatInterface } from '../new-message';

import type {
  ChatDetailInterface,
  ChatMessageContentInterface,
  MessageInSignalRType,
  MessageItemInterface,
  MessagesDetailInterface,
} from '../chat-section.type';
import type { UtilsRefInterface } from './messages-list.type';
import type { ChatUsersInterface } from '../chat-dialogs/add-members-dialog/add-members-dialog.type';
import type { LightBoxDialogInterface } from '../../dialogs-stack/light-box-dialog/light-box-dialog.type';

const MessagesListController: FC = memo(() => {
  /**
   * @check:reducer
   */
  const [state, dispatch] = useReducer(
    messagesListReducer,
    messagesListInitialState,
  );

  const {
    loading,
    selectedMessage,
    showScrollDownBtn,
    updatedMessagesDetail,
    chatData,
    scrollBoundaryReached,
    isUserMentioned,
    groupUsers,
    pinMessages,
    sumNotSeen,
  } = state;

  const translate = useTranslate();

  const utilsRef = useRef<UtilsRefInterface>({
    countToGet: 20,
    canScrollDown: true,
    seenMessagesIds: [],
    unseenMessagesIds: [],
    firstUnseenMessage: null,
    isSendingMessageAllowed: false,
    checkMessagesToFindRepliedMessageId: null,
    messagesBubbleElements: [],
    scrollableDivRef: null,
    signalRMessagesReceived: false,
    senderUser: null,
    scrollingOnFirstUnseenMessageIsDone: false,
  });

  // auto scroll down while sending new messages or file
  useEffect(() => {
    getChatTotalUnSeen();
    getSignalRMessages(signalRGetMessageCallback);

    const onDispatches: { actionName: keyof ActorActionList; id: symbol }[] = [];
    let id = actorOnDispatch('selectedMessage', selectedMessage => {
      if (selectedMessage?.mode === 'pin') {
        dispatch({
          type: 'setNewPinMessage',
          payload: selectedMessage.data,
        });

        return;
      }

      dispatch({
        type: 'setSelectedMessage',
        payload: selectedMessage,
      });
    });

    onDispatches.push({
      id,
      actionName: 'selectedMessage',
    });

    id = actorOnDispatch('selectedChat', selectedChat => {
      if (selectedChat == null) {
        dispatch({
          type: 'setSelectedChat',
          payload: null,
        });

        dispatch({
          type: 'setLoading',
          payload: false,
        });

        return;
      }

      let newMessagesDetail: MessagesDetailInterface | null = {
        data: [],
        hasMore: false,
      };

      if (!selectedChat.messagesDetail) {
        utilsRef.current.scrollingOnFirstUnseenMessageIsDone = false;
        dispatch({ type: 'setSumNotSeen', payload: -1 });

        newMessagesDetail = null;

        dispatch({
          type: 'setPinMessages',
          payload: [],
        });

        if (selectedChat.info.groupuid) {
          getGroupUsers(selectedChat.info.groupuid, onSuccessGetGroupUsers);

          dispatch({
            type: 'setIsUserMentioned',
            payload: Boolean(selectedChat.info.mentionchatid),
          });
        } else {
          dispatch({
            type: 'setGroupUsers',
            payload: [],
          });
        }

        actorDispatch(
          'crudAction',
          {
            type: 'GET_LIST',
            resource: getPinsReportId,
            requestParameters: {
              filter: [['OtherPersonInfo_ID', '=', selectedChat.info.personinfo_id]],
            },
            onSuccess: (response: { data: MessageItemInterface[] }) => {
              dispatch({
                type: 'setPinMessages',
                payload: response.data,
              });
            },
          },
          { replaceAll: true },
        );

        dispatch({
          type: 'setLoading',
          payload: true,
        });
      } else {
        newMessagesDetail.data = selectedChat.messagesDetail.data;
        newMessagesDetail.hasMore = selectedChat.messagesDetail.hasMore;

        dispatch({
          type: 'setLoading',
          payload: false,
        });
      }

      /**
       * Change state by `reducer` @check:reducer
       */
      dispatch({
        type: 'setSelectedChat',
        payload: {
          info: selectedChat.info,
          messagesDetail: newMessagesDetail,
        },
      });
    });

    onDispatches.push({
      id,
      actionName: 'selectedChat',
    });

    id = actorOnDispatch('isNewContentSent', flag => {
      if (flag) {
        scrollDown();
      }
    });

    onDispatches.push({
      id,
      actionName: 'isNewContentSent',
    });

    id = actorOnDispatch('profile', profile => {
      utilsRef.current.senderUser = getMessageSenderUser(profile);
    });

    onDispatches.push({
      id,
      actionName: 'profile',
    });

    return () => {
      for (const { actionName } of onDispatches) {
        actorRemoveAction({
          prune: true,
          actionName,
        });
      }
    };
  }, []);

  useEffect(() => {
    utilsRef.current.scrollableDivRef = document.getElementById(
      'scrollableDiv',
    ) as HTMLDivElement | null;

    utilsRef.current.messagesBubbleElements = getMessagesBoxElements(
      utilsRef.current.scrollableDivRef,
    );

    /**
     * when there are NOT SEEN messages
     * we should move scroll bar to top of the first unseen message
     * then user can start reviewing messages
     * from top to the bottom
     */
    if (
      utilsRef.current.firstUnseenMessage &&
      !utilsRef.current.scrollingOnFirstUnseenMessageIsDone
    ) {
      // const showSeparatorElement =
      //   updatedMessagesDetail.data.length > 15 &&
      //   utilsRef.current.unseenMessagesIds.length > 10;

      utilsRef.current = scrollToFirstUnSeenMessage(
        utilsRef.current,
        utilsRef.current.scrollableDivRef,
        document.getElementById(
          `messageId-${utilsRef.current.firstUnseenMessage.chat_id}`,
        ),
        // showSeparatorElement,
      );

      utilsRef.current.scrollingOnFirstUnseenMessageIsDone = true;
      _syncSeenMessages();
    }

    if (utilsRef.current.canScrollDown) {
      scrollDown();
    }

    if (utilsRef.current.checkMessagesToFindRepliedMessageId) {
      moveToRepliedMessage(utilsRef.current.checkMessagesToFindRepliedMessageId);
    }
  }, [updatedMessagesDetail]);

  useEffect(() => {
    if (!selectedMessage) return;
    utilsRef.current.canScrollDown = selectedMessage.mode !== 'edit';
  }, [selectedMessage]);

  useEffect(() => {
    if (chatData != null) {
      if (
        chatData.messagesDetail &&
        chatData.messagesDetail.data.length > 0 &&
        !utilsRef.current.scrollingOnFirstUnseenMessageIsDone
      ) {
        processUnSeenMessages(chatData);
      }

      const { info: selectedUser } = chatData;
      utilsRef.current.isSendingMessageAllowed =
        (selectedUser.isgroupadmin && !!selectedUser.ischannel) ||
        !selectedUser.ischannel;
    }

    return () => {
      utilsRef.current.firstUnseenMessage = null;
      utilsRef.current.canScrollDown = false;
    };
  }, [chatData]);

  useEffect(() => {
    const selectedChat = actorGetActionValue('selectedChat');
    const _sumNotSeen = sumNotSeen >= 0 ? sumNotSeen : 0;

    if (selectedChat != null) {
      actorDispatch('unseenMessagesCount', {
        [selectedChat.info.personinfo_id]: _sumNotSeen,
      });

      actorSetActionValue('chatList', _sumNotSeen, {
        path: `data.${selectedChat.info.personinfo_id}.info.sumnotseen`,
        callerScopeName: 'MessagesListController => useEffect(..., [chatData])',
      });

      actorSetActionValue(
        'selectedChat',
        { ...selectedChat, info: { ...selectedChat.info, sumnotseen: _sumNotSeen } },
        {
          replaceAll: true,
          callerScopeName: 'MessagesListController => useEffect(..., [chatData])',
        },
      );

      dispatch({
        type: 'setSelectedChat',
        payload: {
          ...selectedChat,
          info: {
            ...selectedChat.info,
            sumnotseen: _sumNotSeen,
          },
        },
      });
    }

    if (_sumNotSeen === 0) {
      utilsRef.current.seenMessagesIds = [];
      utilsRef.current.unseenMessagesIds = [];
    }
  }, [sumNotSeen]);

  // FIXME: Why `useCallback` has an issue here?
  const _syncSeenMessages = () => {
    const result = getSeenMessagesElementsInViewport(
      utilsRef.current.scrollableDivRef,
      utilsRef.current.messagesBubbleElements,
      utilsRef.current.seenMessagesIds,
      utilsRef.current.unseenMessagesIds,
    );

    syncSeenMessages(result.updatedSeenMessagesIds);
    updateSeenMessagesInChatData(result.updatedSeenMessagesIds);

    utilsRef.current.seenMessagesIds = [];
    utilsRef.current.unseenMessagesIds = result.updatedUnseenMessagesIds;
  };

  // FIXME: Why `useCallback` has an issue here?
  const updateSeenMessagesInChatData = (seenMessagesIds: number[]) => {
    if (chatData?.messagesDetail == null || seenMessagesIds.length === 0) return;

    const messages = chatData.messagesDetail?.data ?? [];
    for (let index = 0; index < seenMessagesIds.length; index++) {
      const targetIndex = messages.findIndex(
        item => item.chat_id === messages[index].chat_id,
      );
      if (targetIndex === -1) continue;

      messages[index].isseen = true;
    }

    const _sumNotSeen = chatData.info.sumnotseen - seenMessagesIds.length;

    dispatch({
      type: 'setSumNotSeen',
      payload: _sumNotSeen,
    });

    dispatch({
      type: 'setSelectedChat',
      payload: {
        info: {
          ...chatData.info,
          sumnotseen: _sumNotSeen,
        },
        messagesDetail: {
          data: messages,
          hasMore: chatData.messagesDetail.hasMore,
        },
      },
    });

    utilsRef.current.canScrollDown = false;
  };

  /**
   * @function onSuccessGetGroupUsers
   * @param {ChatUsersInterface[]} response
   * @returns {void} void
   */
  const onSuccessGetGroupUsers = useCallback(
    (newUsers: ChatUsersInterface[]): void => {
      dispatch({
        type: 'setGroupUsers',
        payload: newUsers,
      });
    },
    [],
  );

  const signalRGetMessageCallback = useCallback(
    (signalRMessages: MessageInSignalRType[]) => {
      console.log('signalRGetMessageCallback: ', signalRMessages);

      const _signalRMessages: MessageInSignalRType[] = [];
      for (const signalRMessage of signalRMessages) {
        // prettier-ignore
        const _signalRMessage = objectToLowerCaseProperties(signalRMessage) as MessageInSignalRType;
        _signalRMessage.isseen = Boolean(_signalRMessage.isseen);
        _signalRMessages.push(_signalRMessage);
      }

      const signalRMessageType = getSignalRMessageType(_signalRMessages);

      if (signalRMessageType === 'INSERT' && !_signalRMessages[0].isseen) {
        utilsRef.current.scrollingOnFirstUnseenMessageIsDone = false;
        utilsRef.current.unseenMessagesIds.push(_signalRMessages[0].chat_id);

        const personInfoId = _signalRMessages[0].fromgroup
          ? _signalRMessages[0].topersoninfo_id
          : _signalRMessages[0].frompersoninfo_id;

        const selectedChat = actorGetActionValue('selectedChat')?.info;
        if (selectedChat?.personinfo_id === personInfoId) {
          // prettier-ignore
          utilsRef.current.firstUnseenMessage = createFormattedMessagesFromSignalRMessages(_signalRMessages)[0];
        } else {
          utilsRef.current.firstUnseenMessage = null;
        }
      }

      console.log(
        'signalRGetMessageCallback: formatted messages => ',
        signalRMessages,
      );

      // prettier-ignore
      chatSectionSignalRManager(_signalRMessages, utilsRef.current.senderUser!);
    },
    [],
  );

  const processUnSeenMessages = useCallback((chatData: ChatDetailInterface) => {
    const { messagesDetail } = chatData;
    if (!messagesDetail || messagesDetail.data.length === 0) return;

    const { firstUnseenMessage, unseenMessagesIds } =
      findUnseenMessagesFromCurrentMessages(messagesDetail.data);

    utilsRef.current.unseenMessagesIds = unseenMessagesIds;
    utilsRef.current.firstUnseenMessage = firstUnseenMessage;

    dispatch({
      type: 'setUpdatedMessagesDetail',
      payload: { ...messagesDetail },
    });

    const { info: selectedUser } = chatData;
    utilsRef.current.isSendingMessageAllowed =
      (selectedUser.isgroupadmin && !!selectedUser.ischannel) ||
      !selectedUser.ischannel;
  }, []);

  /**
   * FIXME: Every time a `highlightMessageAndScrollTo` is called, the following function will be called, find a solution
   *
   * @function handleOnScroll
   * @param {UIEvent & { target: Element }} event
   * @returns {void}
   */
  const handleOnScroll = useCallback(
    (event: UIEvent & { target: Element }): void => {
      dispatch({
        type: 'setShowScrollButton',
        payload: event.target.scrollTop < 0,
      });

      const { messagesBubbleElements, seenMessagesIds, unseenMessagesIds } =
        utilsRef.current;

      if (
        utilsRef.current.messagesBubbleElements.length === 0 ||
        chatData == null ||
        chatData.info.personinfo_id === utilsRef.current.senderUser?.personinfo_id
      ) {
        return;
      }

      const { updatedSeenMessagesIds, updatedUnseenMessagesIds } =
        getSeenMessagesElementsInViewport(
          utilsRef.current.scrollableDivRef,
          messagesBubbleElements,
          seenMessagesIds,
          unseenMessagesIds,
        );

      utilsRef.current.seenMessagesIds = updatedSeenMessagesIds;
      utilsRef.current.unseenMessagesIds = updatedUnseenMessagesIds;

      syncSeenMessages(utilsRef.current.seenMessagesIds);
      updateSeenMessagesInChatData(utilsRef.current.seenMessagesIds);

      // Empty array to update new seen messages based on scroll
      utilsRef.current.seenMessagesIds = [];
    },
    [showScrollDownBtn, chatData],
  );

  const debouncedHandleScroll = useCallback(customDebounce(handleOnScroll, 100), [
    showScrollDownBtn,
    chatData,
  ]);

  /**
   * @function sendNewMessage
   * @param {ChatTextJsonFormatInterface} message
   * @returns {void} void
   */
  const sendMessageAction = useCallback(
    <
      TMessageType extends ChatMessageTypeEnum,
      TMetaType extends Record<string, any> = Record<string, any>,
    >(
      message: ChatTextJsonFormatInterface<TMessageType, TMetaType>,
    ): void => {
      if (!chatData?.info || !utilsRef.current.senderUser) return;

      if (
        message.formattedText.length === 0 &&
        selectedMessage?.mode === 'edit' &&
        message.messageType === ChatMessageTypeEnum.TEXT
      ) {
        const message = selectedMessage.data;

        actorDispatch('quickDialog', {
          confirmationIsOpen: true,
          data: {
            content: translate('ra.message.delete_content'),
            onCancel: (): void => {
              actorDispatch('closeCurrentDialog', true);
            },
            onConfirm: (): void => {
              actorDispatch('closeCurrentDialog', true);

              chatStatelessActions.deleteMessage({
                successCallback: () => {
                  handleDeleteMessage([message]);
                },
                _params: {
                  ChatID: message.chat_id,
                  OtherPersonInfo_ID:
                    message.isfrommyself === 1 // is sender or not
                      ? message.topersoninfo_id
                      : message.frompersoninfo_id,
                },
              });
            },
          },
        });

        return;
      }

      // prettier-ignore
      const _messageType = message.messageType.toLowerCase() as keyof ChatMessageContentInterface;
      const updatedMessages = messageSendManager<typeof _messageType>({
        messageType: _messageType,
        content: message,
      });

      /**
       * When a message has been sent,
       * it should to scroll to bottom of the messages
       */
      utilsRef.current.canScrollDown = true;

      dispatch({
        type: 'setUpdatedMessagesDetail',
        payload: {
          ...updatedMessagesDetail,
          data: updatedMessages,
        },
      });
    },
    [chatData, updatedMessagesDetail, selectedMessage],
  );

  /**
   * @function fetchMoreOnTop
   * @returns {void} void
   */
  const fetchMoreOnTop = useCallback((): void => {
    chatStatelessActions.fetchMoreMessages({
      olderMessages: true,
      countToGet: utilsRef.current.countToGet,
    });
  }, []);

  /**
   * @function moveToLatestMessage
   * @returns {void} void
   */
  const moveToLatestMessage = useCallback((): void => {
    if (scrollBoundaryReached.bottom) {
      scrollDown();
      return;
    }

    chatStatelessActions.moveLast({
      successCallback: scrollDown,
    });
  }, [scrollBoundaryReached]);

  /**
   * @function scrollDown
   * @returns { void } void
   */
  const scrollDown = useCallback((): void => {
    _scrollTo(utilsRef.current.scrollableDivRef, {
      top: 0,
      left: 0,
    });
  }, []);

  /**
   * @function scrollTop
   * @returns { void } void
   */
  const scrollTop = useCallback((): void => {
    _scrollTo(utilsRef.current.scrollableDivRef, {
      top: -99999,
      left: 0,
    });
  }, []);

  /**
   * @function moveToRepliedMessage
   * @param {number} messageId - replied message's `id`
   * @returns {void} void
   */
  const moveToRepliedMessage = useCallback(
    (messageId: number) => {
      const index = updatedMessagesDetail.data.findIndex(
        item => item.chat_id === messageId,
      );
      if (index > -1) {
        utilsRef.current.checkMessagesToFindRepliedMessageId = null;
        requestAnimationFrame(() => {
          highlightMessageAndScrollTo(updatedMessagesDetail.data[index].chat_id);
        });
        return;
      }

      // We have to get the target `chatId` from the server
      utilsRef.current.checkMessagesToFindRepliedMessageId = messageId;
      chatStatelessActions.fetchMoreMessages({
        olderMessages: true,
        countToGet: utilsRef.current.countToGet,
      });
    },
    [updatedMessagesDetail],
  );

  /**
   * @function scrollToMentionMessage
   * @returns {void} void
   */
  const scrollToMentionMessage = useCallback((): void => {
    if (chatData?.info.mentionchatid == null) return;

    highlightMessageAndScrollTo(chatData.info.mentionchatid);
    dispatch({
      type: 'setIsUserMentioned',
      payload: false,
    });
  }, [chatData]);

  return (
    <MessagesListView
      selectedUser={chatData?.info ?? null}
      unseenMessagesCount={sumNotSeen > 0 ? sumNotSeen : 0}
      messagesDetail={updatedMessagesDetail}
      senderUser={utilsRef.current.senderUser}
      showScrollDownBtn={showScrollDownBtn}
      loading={loading}
      groupUsers={groupUsers}
      isSendingMessageAllowed={utilsRef.current.isSendingMessageAllowed}
      selectedMessage={selectedMessage}
      sendMessageAction={sendMessageAction}
      handleOnScroll={debouncedHandleScroll}
      onScrollDown={moveToLatestMessage}
      onScrollTop={scrollTop}
      fetchMoreOnTop={fetchMoreOnTop}
      firstItemNotSeen={utilsRef.current.firstUnseenMessage}
      moveToRepliedMessage={moveToRepliedMessage}
      scrollToMentionMessage={scrollToMentionMessage}
      isUserMentioned={isUserMentioned}
      pinMessagesList={pinMessages}
    />
  );
});

export default MessagesListController;
