import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { ConfirmDialogInterface } from './confirm-dialog.type';
import { AppDialog } from '../app-dialog';
import { Button, useTheme } from '@material-ui/core';

import { useStyles } from './confirm-dialog.style';

const ConfirmDialogView = (props: ConfirmDialogInterface): ReactElement => {
  const { open, title, content, color, handleClose, onConfirm } = props;
  const translate = useTranslate();
  const classes = useStyles({ confirmBgColor: color });

  return (
    <AppDialog
      open={open}
      handleClose={handleClose}
      dialogTitle={title}
      dialogContent={content}
      dialogActions={
        <>
          <Button
            onClick={handleClose}
            variant="outlined"
            data-test="confirm-dialog-decline-button"
          >
            {translate('confirm.no')}
          </Button>
          <Button
            onClick={onConfirm}
            className={classes.confirmButton}
            variant="contained"
            color="secondary"
            id="dialogButtonConfirmYes"
            data-test="confirm-dialog-apply-button"
          >
            {translate('confirm.yes')}
          </Button>
        </>
      }
      data-test="confirm-dialog-container"
    />
  );
};

export default ConfirmDialogView;
