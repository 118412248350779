import { FC } from 'react';
import { useTranslate } from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';
import { Card, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './permission-assigned.style';
import { TreeView } from 'devextreme-react';
import TableRow from './permission-assigned-table-row';
import TableHeader from './permission-assigned-table-header';

import type { PermissionAssignedViewInterface } from './permission-assigned.type';

const PermissionAssignedView: FC<PermissionAssignedViewInterface> = props => {
  const {
    data,
    onSearch,
    treeView,
    onSelectItem,
    selectedIds,
    handleSelectAllClick,
    searchInputRef,
    isLoading,
    className,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  if (treeView) {
    return (
      <Card className={`${classes.container} ${className}`}>
        <Typography className={classes.title}>
          {translate('permissions.assigned')}
        </Typography>

        <div className={classes.divider} />

        {isLoading ? (
          Array.from(new Array(20)).map(() => {
            return (
              <div className={`${classes.tableRow} ${classes.spaceBetween}`}>
                <Skeleton variant="rect" width={18} height={18}></Skeleton>
                <Skeleton variant="text" width={'80%'} height={25}></Skeleton>
              </div>
            );
          })
        ) : (
          <TreeView
            selectNodesRecursive={false}
            selectByClick={false}
            showCheckBoxesMode="normal"
            id="assignedTreeview"
            items={data}
            itemRender={menuItem => <Typography>{menuItem.title}</Typography>}
            searchMode="contains"
            searchEnabled={false}
            rtlEnabled={true}
            noDataText={translate('tree.noDataToDisplay')}
            selectionMode="multiple"
            searchEditorOptions={{ placeholder: translate('ra.action.search') }}
            onItemSelectionChanged={item => {
              onSelectItem(item.itemData?.['id']);
            }}
            className={classes.tree}
          />
        )}
      </Card>
    );
  }

  return (
    <Card className={`${classes.container} ${className}`}>
      <Typography className={classes.title}>
        {translate('permissions.assigned')}
      </Typography>

      <TableHeader
        classes={classes}
        handleSelectAllClick={handleSelectAllClick}
        selectAllChecked={!data.some(row => !selectedIds.includes(row.id))}
        selectAllDisabled={data.length == 0 || isLoading}
      />
      <TextField
        className={classes.searchInput}
        variant="outlined"
        placeholder={translate('general.search')}
        inputRef={searchInputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={onSearch}
      />

      <div className={classes.treeContainer}>
        {isLoading
          ? Array.from(new Array(20)).map(() => {
              return (
                <div className={`${classes.tableRow} ${classes.spaceBetween}`}>
                  <Skeleton variant="rect" width={18} height={18}></Skeleton>
                  <Skeleton variant="text" width={'35%'} height={25}></Skeleton>
                  <Skeleton variant="text" width={'35%'} height={25}></Skeleton>
                </div>
              );
            })
          : data.map(row => (
              <TableRow
                id={row.id}
                title={row.title}
                classes={classes}
                onSelectItem={onSelectItem}
                checked={selectedIds.includes(row.id)}
              />
            ))}
      </div>
    </Card>
  );
};

export default PermissionAssignedView;
