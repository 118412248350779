import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../core/themeProvider';
import type { StyleProps } from './leaflet-map.type';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  mapContainer: {
    width: '100%',
    height: ({ isFullScreen }) => (isFullScreen ? '100%' : '100%'),
    marginBottom: 2,
  },

  markerPin: {
    width: '16px',
    height: '16px',
    borderRadius: '50% 50% 50% 0',
    background: `${theme.palette.primary.backgroundMarker}`,
    position: 'absolute',
    transform: 'rotate(-45deg)',
    left: '50%',
    margin: '-15px 0 0 -15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.secondary.contrastText}`,
  },

  markerPinSelect: {
    border: `1px solid ${theme.palette.primary.borderSelectMarker}`,
    width: '16px',
    height: '16px',
    borderRadius: '50% 50% 50% 0',
    background: `${theme.palette.primary.backgroundMarker}`,
    position: 'absolute',
    transform: 'rotate(-45deg)',
    left: '50%',
    margin: '-15px 0 0 -15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  iconNumber: {
    fontSize: '10px',
    transform: 'rotate(45deg)',
  },
  customDivIcon: {
    color: theme.palette.secondary.contrastText,
    right: 0,
    left: 0,
    position: 'absolute',
    width: '15px',
    fontSize: '10px',
    margin: '10px auto',
    textAlign: 'center',
  },
}));
