import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { useStyles } from './login-form-style';
import type { LoginFormViewInterface } from './login-form-type';

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const LoginFormView = (props: LoginFormViewInterface) => {
  const { submit, validate, loading, isShowPassword, handleShowPassword } = props;

  const translate = useTranslate();
  const classes = useStyles(props);

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type={!isShowPassword ? 'password' : ''}
                disabled={loading}
                autoComplete="current-password"
              />
            </div>
            <div className={classes.formCheckBox}>
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" onClick={handleShowPassword} />}
                label={translate('ra.auth.showPassword')}
                labelPlacement="end"
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              className={`${classes.button} ${classes.root}`}
            >
              {loading && (
                <CircularProgress className={classes.icon} size={18} thickness={2} />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>

          {/* login with Google button */}

          {/* <div className={classes.loginWithGoogle}>
            <h4 className={classes.loginHeader}>
              <span className={classes.loginText}>or log in with</span>
            </h4>
            <Button className={classes.googleBtn}>
              <span className={classes.GoogleText}>google</span>
              <img
                className={classes.img2}
                src="assets/image/icon/icons8-google.svg"
                alt="sorry"
              />
            </Button>
          </div> */}
        </form>
      )}
    />
  );
};

export default LoginFormView;
