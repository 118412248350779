import { ChangeEvent, FC, memo } from 'react';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { parseJSON } from '../../../core/configProvider';
import { getColorById } from '../../../helper/RowColorHelper';
import { actorGetActionValue } from '../../../type/actor-setup';

import type {
  ColorSelectFilterInputInterface,
  TableRowColorInterface,
} from './color-select-filter-input.type';
import ColorSelectFilterInputView from './color-select-filter-input.view';
import RenderOptionView from './render-option.view';

const ColorSelectFilterInputController: FC<ColorSelectFilterInputInterface> = memo(
  props => {
    const { field, onChange, value } = props;

    /**
     * handle change input
     * @function _onChange
     * @param {ChangeEvent<{}>} _
     * @param {DropdownProps} newValue
     * @return {void}
     */
    const _onChange = (_: ChangeEvent<{}>, newValue: DropdownProps): void => {
      onChange?.(newValue?.value);
    };

    /**
     * @param tableRowColors
     * @param {TableRowColorInterface[]} tableRowColors
     * @returns {DropdownItemProps[]} an array of TableRowColorInterface
     */
    const createColorFilterOptions = (
      tableRowColors: TableRowColorInterface[],
    ): DropdownItemProps[] => {
      const { translate } = actorGetActionValue('reactAdminHelpers')!;

      const options: DropdownItemProps[] = [];
      for (const rowColor of tableRowColors) {
        const { ColorCode, Name } = rowColor;
        options.push({
          content: <RenderOptionView color={getColorById(ColorCode)} text={Name} />,
          text: Name,
          value: Number(rowColor.ColorCode),
        });
      }

      const translatedText = translate('general.nothing');
      options.unshift({
        content: <RenderOptionView color={''} text={translatedText} />,
        text: translatedText,
        value: undefined,
      });

      return options;
    };

    const values: TableRowColorInterface[] = field.values?.[0]
      ? parseJSON((field?.values?.[0] as string) ?? '') ?? []
      : [];
    const options = createColorFilterOptions(values);
    const _value = options.find(item => item.value === value) ?? {};

    return (
      <ColorSelectFilterInputView
        {...props}
        onChange={_onChange}
        options={options}
        text={_value.text as string}
      />
    );
  },
);

export default ColorSelectFilterInputController;
