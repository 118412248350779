import { type FC, memo, useEffect, useState, useReducer } from 'react';

import { RelationActionTopToolbarInterface } from './relation-action-top-toolbar.type';
import RelationActionTopToolbarView from './relation-action-top-toolbar.view';
import { isEmpty } from '../../helper/data-helper';
import { KEY_SCROLL_TO } from '../relation-panel/relation-panel.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
} from '../../type/actor-setup';
import { getAppSettings } from '../../helper/settings-helper';
import { getSubPanelSortKeySetting } from '../relation-panel/relation-panel-sort.helper';

const RelationActionTopToolbarController: FC<RelationActionTopToolbarInterface> =
  memo(props => {
    const { list: relationList, resource } = props;
    const rootResource = actorGetActionValue('resources');

    const [sortedList, setSortedList] =
      useState<{ title: string; id: string }[]>(relationList);
    const [render, setRender] = useState(false);

    /**
     * useful to prevent click bubbling in a data grid with rowClick
     * @function stopPropagations
     * @param {React.MouseEvent<HTMLButtonElement>} event
     * @returns {void}
     */
    const stopPropagation = (event: React.MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();

      const targetId = event.currentTarget?.id;
      if (!targetId) {
        console.warn('`targetId` to scroll not found');
        return;
      }

      /**
       * Getting relation data may take a while, so we scroll to the target `accordion` at first,
       *  and when data is received correct scroll will happen
       */
      const violation = document.getElementById(KEY_SCROLL_TO + targetId);
      if (violation) {
        violation.scrollIntoView({
          behavior: 'smooth',
        });
      }

      actorDispatch(
        'toggleAccordion',
        {
          accordionId: targetId,
        },
        { disableDebounce: true },
      );

      actorSetActionValue('scroll', {
        uniqIdToScroll: targetId,
      });
    };

    useEffect(() => {
      const element = document.getElementById('main-toolbar-samian');
      if (!isEmpty(relationList) && relationList.length > 0) {
        if (element) {
          element.style.marginBottom = '44px';
        }
      }
      return () => {
        if (element) {
          element.style.marginBottom = '0px';
        }
      };
    });
    useEffect(() => {
      actorOnDispatch('signal', page => {
        if (page == 'reloadTopToolbarRelations') {
          setRender(prev => !prev);
        }
      });
    }, []);

    useEffect(() => {
      const sortSetting =
        getAppSettings(
          getSubPanelSortKeySetting(resource ?? rootResource?.current.value ?? ''),
          true,
        ).value ?? [];

      if (!Array.isArray(sortSetting)) return;
      const sortedArray: ({ title: string; id: string } | null)[] = [];

      sortSetting.forEach(relationId => {
        if (relationId.indexOf('report/') > -1) {
          // its report
          sortedArray.push(
            relationList.find(relation => `report/${relation.id}` === relationId) ??
              null,
          );
        } else if (relationId === `${resource}_notes`) {
          // its note
          sortedArray.push(
            relationList.find(relation => relation.id === 'notes') as any,
          );
        } else {
          // its not report
          sortedArray.push(
            relationList.find(
              relation => relation.id === relationId.split('/')?.[1],
            ) ?? null,
          );
        }
      });

      setSortedList(
        sortedArray.filter(item => item) as {
          title: string;
          id: string;
        }[],
      );
    }, [relationList, render]);

    return (
      <RelationActionTopToolbarView
        stopPropagation={stopPropagation}
        list={sortedList.length > 0 ? sortedList : relationList}
      />
    );
  });

export default RelationActionTopToolbarController;
