import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  loadingOrErrorBoxContainer: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },

  mainContainer: {
    display: 'flex',
    overflow: 'auto',
    flexGrow: 1,
    flexDirection: 'column',
  },
  tabPane: {
    width: '100%',
    height: '100%',
  },
  tabListContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    height: 1,
  },

  tabsContainer: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    '& > div:first-child ': {
      height: 1,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '& > div:nth-child(2) ': {
        flexGrow: 1,
      },
    },
  },

  tabParent: {
    flex: 1,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',

    '& .ant-tabs-content-holder': {
      display: 'flex',
      flex: 1,
    },

    '& .ant-tabs-nav': {
      margin: 0,
    },
  },

  tabGroupsContainer: {
    display: 'flex',
  },

  gridContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
  },

  bottomToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
