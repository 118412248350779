import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_LIST } from 'react-admin';
import { useTranslate } from 'react-admin';

import { actorDispatch, actorGetActionValue } from '../../../../type/actor-setup';
import { showNotification } from '../../../../helper/general-function-helper';
import {
  defaultMailFormValue,
  mailActionListReportId,
} from '../../mail-section.helper';
import MailSplitButtonView from './mail-split-button.view';
import LoadingBox from '../../../LoadingBox';

import type { SplitButtonOptionsInterface } from './mail-split-button.type';
import { MailActions, OnNewMessageParams } from '../../mail-section.type';

const MailSplitButtonController = (): ReactElement => {
  const mailActionsHandler = actorGetActionValue('mailActionsHandler')!;

  const [splitButtonOptions, setSplitButtonOptions] = useState<
    SplitButtonOptionsInterface[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const translate = useTranslate();
  const history = useHistory();

  useEffect(() => {
    if (splitButtonOptions.length > 0) return;

    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        entity: 'splitButton',
        resource: `report/${mailActionListReportId}`,
        onSuccess: (response): void => {
          onDataReceived(response?.data);
        },
        onFailure: (error: unknown): void =>
          error ? showNotification(error, 'error') : undefined,
      },
      {
        disableDebounce: true,
      },
    );
  }, []);

  /**
   * @function onDataReceived
   * @returns { SplitButtonOptionsInterface } response
   * @returns { void }
   */
  const onDataReceived = (response: SplitButtonOptionsInterface[]): void => {
    setSplitButtonOptions(response);
    setLoading(false);
  };

  /**
   * @function getItemInOptionList
   * @returns { string[] }
   */
  const getItemInOptionList = (): string[] => {
    return splitButtonOptions.map(item => item.title);
  };

  /**
   * @function onClickSplitButton
   * @param { number } selectedIndex
   * @returns { void }
   */
  const onClickSplitButton = (selectedIndex: number): void => {
    const clickedItem = splitButtonOptions.map(item => item.url);

    if (clickedItem[selectedIndex] === 'Message') {
      mailActionsHandler(MailActions.onOpenNewMessageForm, {
        formData: defaultMailFormValue,
        formTitle: translate('mail.newMessage'),
      } as OnNewMessageParams);
    } else {
      //InMail
      history.push(clickedItem[selectedIndex]);
    }
  };

  if (loading) return <LoadingBox />;
  if (splitButtonOptions.length == 0) return <></>;

  return (
    <MailSplitButtonView
      splitButtonOptions={getItemInOptionList()}
      onClickItem={onClickSplitButton}
    />
  );
};

export default MailSplitButtonController;
