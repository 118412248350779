import { memo, type FC } from 'react';
import { Map as MapContainer, TileLayer } from 'react-leaflet';
import { useTranslate } from 'react-admin';
import {
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@material-ui/core';

import { LocationMarker } from '../../../dynamic-input/location-input/location-marker';
import { useStyles } from './send-location-dialog.style';

import type { SendLocationDialogViewProps } from './send-location-dialog.type';

const SendLocationDialogView: FC<SendLocationDialogViewProps> = memo(props => {
  const {
    onConfirmButtonClick,
    onCancelButtonClick,
    selectedLocationRef,
    onMoveMarker,
    currentLocation,
    geolocationStatus,
    isLoading,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.mapContainerParent} id="map_container">
        <MapContainer
          center={currentLocation}
          zoom={16}
          className={classes.mapContainer}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          <LocationMarker
            onChangeLocation={onMoveMarker}
            location={selectedLocationRef.current}
            isDraggable={true}
          />
        </MapContainer>
      </div>

      <div className={classes.informationLayer}>
        {geolocationStatus.isFinding && (
          <LinearProgress className={classes.linearProgress} />
        )}

        {!geolocationStatus.isFinding && geolocationStatus.errorMessage && (
          <Typography className={classes.message}>
            {geolocationStatus.errorMessage}
          </Typography>
        )}

        <div className={classes.actionsContainer}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onConfirmButtonClick}
            data-test-notification="confirm"
            disabled={isLoading}
            className={classes.confirmButton}
            size="medium"
          >
            {translate('ra.action.confirm')}
          </Button>
          <Button
            variant="contained"
            onClick={onCancelButtonClick}
            data-test-notification="cancel"
            disabled={isLoading}
            size="medium"
          >
            {translate('ra.action.cancel')}
          </Button>

          {isLoading && (
            <div className={classes.loadingTextContainer}>
              <Typography className={classes.loadingText}>
                {translate('chat.savingLocation')}
              </Typography>
              <CircularProgress
                color="secondary"
                size={18}
                style={{ marginInlineStart: '0.5rem' }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default SendLocationDialogView;
