import { type FC } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';

import type { RangeUnitPickerViewProps } from './range-unit-picker.type';

const RangeUnitPickerView: FC<RangeUnitPickerViewProps> = props => {
  const { onChange, defaultValue, disabled, rangeUnits } = props;

  return (
    <FormControl>
      <Select onChange={onChange} defaultValue={defaultValue} disabled={disabled}>
        {rangeUnits.map(unit => {
          return <MenuItem value={unit.value}>{unit.label}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

export default RangeUnitPickerView;
