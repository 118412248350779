import { ReactElement } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import { IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { useStyles } from './prev-next-record.style';

import type { PrevNextRecordViewInterface } from './prev-next-record.type';

const PrevNextRecordView = (props: PrevNextRecordViewInterface): ReactElement => {
  const {
    onNextButtonClick,
    onPrevButtonClick,
    isPrevButtonDisabled,
    isNextButtonDisabled,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <div className={locale === 'fa' ? classes.faContainer : classes.container}>
      <IconButton
        onClick={onNextButtonClick}
        aria-label={translate('navigation.next')}
        title={translate('navigation.next')}
        data-test="relation-action-top-toolbar-next-record"
        disabled={isNextButtonDisabled}
        disableRipple
      >
        <ArrowForwardIosIcon className={classes.icons} />
      </IconButton>

      <IconButton
        onClick={onPrevButtonClick}
        aria-label={translate('navigation.prev')}
        title={translate('navigation.prev')}
        data-test="relation-action-top-toolbar-prev-record"
        disabled={isPrevButtonDisabled}
        disableRipple
      >
        <ArrowBackIosIcon className={classes.icons} />
      </IconButton>
    </div>
  );
};

export default PrevNextRecordView;
