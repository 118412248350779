import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  simpleForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
  },
  inputContainer: {
    display: 'flex',
    height: 'inherit',
    boxSizing: 'inherit',
    flex: '1',
  },
}));
