import {
  ReactElement,
  Fragment,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useTranslate } from 'react-admin';
import {
  Button,
  DialogActions,
  Icon,
  DialogTitle,
  useTheme,
} from '@material-ui/core';
import classNames from 'classnames';

import { useStyles } from './confirmation-dialog.style';

import type {
  ConfirmationDialogDataItem,
  DialogViewProps,
} from '../dialogs-stack.type';

export const ConfirmationDialogView = (props: DialogViewProps): ReactElement => {
  const { dialogData, closeDialogHandler } = props;
  const { content, color, icon, enableOnlyOkButton, onConfirm, onCancel } =
    dialogData as unknown as ConfirmationDialogDataItem;

  const translate = useTranslate();
  const theme = useTheme();
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles({ showLightColor: !!color });

  /**
   * It runs `onCancel` callback from the requester and then closes the dialog
   * @function cancelActionHandler
   * @returns { void } void
   */
  const cancelActionHandler = (): void => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
    setIsLoading(false);
    closeDialogHandler();
  };

  useEffect(() => {
    setTimeout(() => {
      if (confirmButtonRef.current) {
        confirmButtonRef.current.focus();
      }
    }, 300);
  }, [dialogData]);

  /**
   * @function confirmationCallback
   * @returns {void} void
   */
  const confirmationCallback = useCallback((): void => {
    closeDialogHandler();
    setIsLoading(false);
  }, []);

  /**
   * It runs `onConfirm` callback from the requester and then closes the dialog
   * @function confirmActionHandler
   * @returns { void } void
   */
  const confirmActionHandler = (): void => {
    setIsLoading(true);
    onConfirm?.(confirmationCallback);
  };

  return (
    <>
      <DialogTitle className={classes.dialogTitle}>
        <Icon
          style={{ color: color || theme.palette.secondary.main }}
          className={classNames(classes.icon, icon || 'fa fa-question-circle')}
        />
        {content}
      </DialogTitle>
      <DialogActions
        className={classes.dialogActions}
        data-style-dialog-confirm="dialogConfirm"
      >
        <div>
          {enableOnlyOkButton ? (
            <Button
              onClick={confirmActionHandler}
              style={{
                backgroundColor: color || theme.palette.secondary.main,
                color: '#ffffff',
              }}
              variant="contained"
              id="dialogButtonConfirmOk"
            >
              {translate('confirm.ok')}
            </Button>
          ) : (
            <Fragment>
              <Button
                className={classes.cancelBtn}
                onClick={cancelActionHandler}
                variant="outlined"
              >
                {translate('confirm.no')}
              </Button>
              <Button
                ref={confirmButtonRef}
                onClick={confirmActionHandler}
                className={classes.confirmButton}
                variant="contained"
                color="secondary"
                id="dialogButtonConfirmYes"
                disabled={isLoading}
              >
                {translate('confirm.yes')}
              </Button>
            </Fragment>
          )}
        </div>
      </DialogActions>
    </>
  );
};
