import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import type { ValidationErrorMessageType } from '../../../helper/Types';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';

export const useStyles = makeStyles<
  CustomTheme,
  { messageType: ValidationErrorMessageType | undefined }
>(theme => ({
  root: {
    flexGrow: 1,
    margin: 0,

    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    },
  },

  notchedOutline: {
    top: 0,
    borderColor: props => getColorBaseOfStatus(props.messageType, theme),
  },

  outlineRoot: {
    height: '100%',
  },

  input: {
    zIndex: 1,
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
    },
  },

  disabled: {
    color: theme.palette.primary.disabledFieldColor,
    '& fieldset': {
      backgroundColor: theme.palette.grey[300],
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  codingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: '100%',
  },

  field: {
    // paddingLeft: 20,
  },

  fieldCaption: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  fieldValue: {
    fontSize: 12,
  },
}));
