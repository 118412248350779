import { useState, createContext } from 'react';
import { useTranslate } from 'react-admin';

import { GET_META } from '../core/data-Provider.helper';
import dataProvider from '../core/dataProvider';
import { isEmpty, isNetworkError } from '../helper/data-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../type/actor-setup';

const getReportChildrenMeta = reportMeta => {
  const result = [];

  Object.keys(reportMeta.tabsColumns).forEach((tab, index) => {
    const preparedMeta = {
      ...reportMeta,
      fields: reportMeta.tabsColumns[tab],
      columns: reportMeta.tabsColumns[tab],
      executable: true,
    };

    const preparedResource = `report/${String(
      reportMeta.id,
    ).toLowerCase()}/${index}`;

    result.push({
      name: preparedResource,
      meta: preparedMeta,
    });

    actorSetActionValue('metaData', preparedMeta, { path: preparedResource });
  });

  return result;
};

const resetContextData = () => {
  globalAccess = {};
  isLoadedOnceList = {};
  internalCounter = 0;
};

export const NewMetaContext = createContext({
  resourceList: [],
  version: 0,
  getMeta: (...arg) => ({}),
  resetContextData: resetContextData,
});

let globalAccess = {};
let isLoadedOnceList = {};
let internalCounter = 0;

export const getMetaFromApi = async resource => {
  try {
    const prevMetaForThisResource = actorGetActionValue('metaData', resource);
 
    const metaArray = prevMetaForThisResource
      ? [prevMetaForThisResource]
      : await dataProvider(GET_META, resource, {}).catch(error => {
          if (isNetworkError(error) || typeof error === 'string') {
            throw error;
          }
        });

    const preparedList = [];
    for (const meta of metaArray ?? []) {
      let isReportWithTabs = false;

      let name;
      // something went wrong in api!
      if (!meta) {
        console.log('empty meta received!', resource);
        continue;
      } else if (typeof meta === 'string') {
        name = resource;
      } else if (!meta.config && meta.id) {
        name = `report/${meta.id}`;
        isReportWithTabs = meta.tabsColumns && Object.keys(meta.tabsColumns).length;
      } else if (typeof meta.config !== 'undefined') {
        const { moduleName, moduleTableName } = meta.config;
        name = `${moduleName}/${moduleTableName}`;
      } else {
        console.log('bad meta', meta);
        throw new Error('bad meta');
      }

      // add main meta
      preparedList.push({
        name: name.toLowerCase(),
        meta: !isReportWithTabs ? meta : { ...meta, executable: false },
      });

      if (meta.reports && meta.reports.length) {
        for (const reportMeta of meta.reports) {
          const hasReportChildren =
            reportMeta.tabsColumns && Object.keys(reportMeta.tabsColumns).length;

          const preparedMeta = {
            ...reportMeta,
            executable: hasReportChildren ? false : true,
          };

          preparedList.push({
            name: `report/${String(reportMeta.id).toLowerCase()}`,
            meta: preparedMeta,
          });

          actorSetActionValue('metaData', preparedMeta, {
            path: `report/${String(reportMeta.id).toLowerCase()}`,
          });

          if (hasReportChildren) {
            getReportChildrenMeta(reportMeta).forEach(child => {
              preparedList.push(child);
            });
          }
        }
      }

      // see if any child reports exist
      if (isReportWithTabs) {
        getReportChildrenMeta(meta).forEach(child => {
          preparedList.push(child);
        });
      }
    }

    return preparedList;
  } catch (catchError) {
    console.log('catchError: ', catchError);
    throw catchError;
  }
};

export const NewMetaStore = ({ children }) => {
  const [version, setVersion] = useState(internalCounter);
  const translate = useTranslate();

  const getMeta = (resourceName, successCallback, failureCallback) => {
    /**
     * FIXME: If `undefined/undefined` or `null/null` is a server side issue, it's OK
     * But if we produced this issue so we have check it more and fix it basically
     */
    if (
      isEmpty(resourceName) ||
      resourceName === 'undefined/undefined' ||
      resourceName === 'null/null'
    ) {
      if (typeof failureCallback === 'function') {
        failureCallback(translate('meta.resourceIsNotDefined'));
      }
      return null;
    }

    const resource = String(resourceName.toLowerCase());

    if (globalAccess[resource]) {
      if (typeof failureCallback === 'function' && globalAccess[resource].error) {
        failureCallback(translate('meta.getMetaFailure'));
      } else if (typeof successCallback === 'function') {
        successCallback();
      }
      actorDispatch('metaData', globalAccess[resource], {
        path: resource,
      });
      return globalAccess[resource];
    }

    if (typeof isLoadedOnceList[resource] !== 'undefined') {
      if (typeof successCallback === 'function') successCallback();
      return null;
    }

    // mark that we know about this and is loading
    isLoadedOnceList[resource] = true;

    // request meta for this resource
    getMetaFromApi(resource)
      .then(newList => {
        // new meta list is an array
        newList.forEach(item => {
          if (!globalAccess[item.name]) {
            globalAccess[item.name] = item.meta;
            isLoadedOnceList[item.name] = false;
          }
        });

        // trigger a refresh
        internalCounter++;
        setVersion(internalCounter);

        actorDispatch('metaData', globalAccess[resource], {
          path: resource,
        });
        if (typeof successCallback === 'function') successCallback();
      })
      .catch(error => {
        console.log('NewMetaContext.js:98 err', error);
        globalAccess[resource] = { error };
        if (typeof failureCallback === 'function') {
          failureCallback(error);
        }
      });
  };

  return (
    <NewMetaContext.Provider
      value={{
        getMeta,
        resetContextData,
        version,
        resourceList: Object.keys(isLoadedOnceList),
      }}
    >
      {children}
    </NewMetaContext.Provider>
  );
};
