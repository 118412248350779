import { useCallback, useEffect, useState } from 'react';
import { GET_LIST } from 'react-admin';

import { defaultGadgetDialogValue } from './gadget-design-dialog.helper';
import { prepareDynamicChartPropsForGadgets } from '../../../dynamic-chart/dynamic-chart.helper';
import GadgetDesignDialogView from './gadget-design-dialog.view';
import { actorDispatch, actorGetActionValue } from '../../../../type/actor-setup';

import type { FC } from 'react';
import type { GadgetDesignDialogPropsInterface } from './gadget-design-dialog.type';
import type { GadgetInformation } from '../gadget-input.type';
import { type DynamicChartControllerProps } from '../../../dynamic-chart';
import type { GeneralMetaData } from '../../../../helper/Types';
import { GadgetInternalChangeHandler } from './setting-section';
import { getGadgetDefaultSort } from '../../../dashboard/dashboard-card-form-dialog/dashboard-card-form-dialog.helper';

const GadgetDesignDialogController: FC<GadgetDesignDialogPropsInterface> = props => {
  const { closeDialogHandler, dialogData } = props;
  const { handleChange } = dialogData;
  const defaultValue: GadgetInformation =
    actorGetActionValue('currentGadgetInformation') || defaultGadgetDialogValue;

  const [gadgetValues, setGadgetValues] = useState<GadgetInformation>(defaultValue);
  const [reportData, setReportData] = useState<Record<string, unknown>[]>([]);
  const [currentGadgetMetaData, setCurrentGadgetMetaData] =
    useState<Partial<GeneralMetaData>>();

  /**
   * @function getMetaDataFromActorAndSetInLocal
   * @returns { void }
   */
  const getMetaDataFromActorAndSetInLocal = (): void => {
    const reportUrl = `report/${gadgetValues.reportAddress}`;
    const reportMetaData = actorGetActionValue('metaData', reportUrl);

    if (reportMetaData) {
      setCurrentGadgetMetaData(reportMetaData);
    }
  };

  useEffect(() => {
    if (gadgetValues.reportAddress) {
      getMetaDataFromActorAndSetInLocal();
      getReportData();
    }
  }, [gadgetValues.filterValues, gadgetValues.reportAddress]);

  /**
   * @function getReportData
   * @returns {void}
   */
  const getReportData = useCallback((): void => {
    actorDispatch(
      'crudAction',
      {
        entity: 'gadget',
        type: GET_LIST,
        resource: `report/${gadgetValues.reportAddress}`,
        requestParameters: {
          pagination: {
            page: 1,
            perPage: 99999,
          },
          filter: gadgetValues.filterValues,
        },
        onSuccess: (reportData: { data: Record<string, unknown>[] }) => {
          if (reportData?.data?.length) {
            setReportData(reportData.data);
          }
        },
      },
      { disableDebounce: true },
    );
  }, [gadgetValues.reportAddress, gadgetValues.filterValues]);

  const gadgetDefaultSort = getGadgetDefaultSort(gadgetValues?.sortObject);

  const dynamicChartPreparedProps: DynamicChartControllerProps =
    prepareDynamicChartPropsForGadgets({
      gadgetValues,
      reportData,
      currentGadgetMetaData,
      filterValues: gadgetValues.filterValues,
      ...(!!gadgetDefaultSort?.isSortEnabled && {
        sortField: gadgetDefaultSort.sortField,
        sortOrder: gadgetDefaultSort.sortOrder,
      }),
      displayNumber: gadgetValues.displayNumber,
    });

  /**
   * change internal gadget state
   * @function gadgetInternalChangeHandler
   * @param {Partial<GadgetInformation>} newValue
   * @returns {void}
   */
  const gadgetInternalChangeHandler: GadgetInternalChangeHandler = useCallback(
    (newValue, options): void => {
      setGadgetValues(previousValue => {
        if (options?.replaceAll) {
          return {
            //set necessary value
            reportAddress: previousValue.reportAddress,
            chartType: previousValue.chartType,
            ...newValue,
          };
        }
        return { ...previousValue, ...newValue };
      });
    },
    [],
  );

  /**
   * will trigger save function in gadget input
   * @function saveGadget
   * @returns {void}
   */
  const saveGadget = (): void => {
    handleChange(gadgetValues);
    closeDialogHandler();
  };

  return (
    <GadgetDesignDialogView
      closeDialogHandler={closeDialogHandler}
      gadgetValues={gadgetValues}
      saveGadget={saveGadget}
      gadgetInternalChangeHandler={gadgetInternalChangeHandler}
      dynamicChartPreparedProps={dynamicChartPreparedProps}
    />
  );
};

export default GadgetDesignDialogController;
