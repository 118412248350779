import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import type { CustomTheme } from '../../../core/themeProvider';

const DRAWER_WIDTH = 300;

export const useStyles = makeStyles<CustomTheme>(theme => ({
  close: {
    width: 0,
    transition: 'width 0.6s ease-in-out',
    overflow: 'hidden',
  },

  open: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: DRAWER_WIDTH,
    overflow: 'hidden',
    transition: 'width 0.6s ease-in-out',
    overflowY: 'auto',
    padding: '0px 8px 0px 8px',
  },

  exitToAppIcon: {
    rotate: '-180deg',
    cursor: 'pointer',
    color: grey[500],
    background: theme.palette.primary.appPrimaryLightBackgroundColor,
  },

  deleteOutlineIcon: {
    cursor: 'pointer',
    color: grey[500],
    background: theme.palette.primary.appPrimaryLightBackgroundColor,
  },

  bottomActionsContainer: {
    borderTop: `1px solid ${theme.palette.todoLightGray}`,
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  contentList: {
    width: '100%',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  moduleContainer: {
    background: theme.palette.primary.appLightBackgroundColor,
    height: 'auto',
    width: 'auto',
    margin: '5px',
    padding: '5px',
    borderRadius: 5,
  },
}));
