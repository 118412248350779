import { memo, useState } from 'react';
import { ColorResult } from 'react-color';

import ColorPickerMenuPopupView from './color-picker-menu-popup.view';

import type { ColorPickerMenuPopupControllerProps } from './color-picker-menu-popup.type';

const ColorPickerMenuPopupController = memo(
  (props: ColorPickerMenuPopupControllerProps) => {
    const { onSelectColor } = props;
    const [color, setColor] = useState<string>('');

    /**
     * handle color select
     * @function onColorSelect
     * @param {ColorResult} event
     * @returns {void}
     */
    const onColorSelect = (event: ColorResult): void => {
      onSelectColor?.(event.hex);
    };

    return (
      <ColorPickerMenuPopupView
        onColorSelect={onColorSelect}
        color={color}
        setColor={setColor}
      />
    );
  },
);

export default ColorPickerMenuPopupController;
