import ThresholdColorRangeView from './threshold-color-range.view';

import { FC, useEffect, useState } from 'react';
import type {
  ThresholdColorRangeControllerProps,
  ThresholdColorRangeInterface,
} from './threshold-color-range.type';

export const defaultThresholdRow = [
  { id: 1, number: 33, color: '#00ad2e' },
  { id: 2, number: 66, color: '#ff9d00' },
  { id: 3, number: 100, color: '#ff0800' },
];
const ThresholdColorRangeController: FC<
  ThresholdColorRangeControllerProps
> = props => {
  const { onChange, value } = props;

  const [internalValue, setInternalValue] =
    useState<ThresholdColorRangeInterface[]>(defaultThresholdRow);

  useEffect(() => {
    if (value) setInternalValue(JSON.parse(value));
  }, []);

  useEffect(() => {
    onChange(JSON.stringify(internalValue));
  }, [internalValue]);

  /**
   * onChangeRangeItem
   * @param {'color' | 'number'} key
   * @param {number} id
   * @param {number | string} value
   */
  const onChangeRangeItem = (
    key: 'color' | 'number',
    id: number,
    value: number | string,
  ): void => {
    const finalValue = key === 'number' && +value > 100 ? 100 : value;
    const updatedList = internalValue?.map(row =>
      row.id === id ? { ...row, [key]: finalValue } : row,
    );
    setInternalValue(updatedList);
  };

  /**
   * handleDeleteItem
   * @param {number} id
   * @returns {void}
   */
  const handleDeleteItem = (id: number) => (): void => {
    const updatedList = internalValue?.filter(item => item.id != id);
    setInternalValue(updatedList);
  };

  /**
   * handleAddNewRow
   * @returns {void}
   */
  const handleAddNewRow = (): void => {
    const newItem: ThresholdColorRangeInterface = {
      id: Math.random(),
      number: 0,
      color: '',
    };
    setInternalValue(prev => [...prev, newItem]);
  };

  return (
    <ThresholdColorRangeView
      value={internalValue}
      onChange={onChangeRangeItem}
      handleDelete={handleDeleteItem}
      handleAddNewRow={handleAddNewRow}
    />
  );
};

export default ThresholdColorRangeController;
