import { FC, memo, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import { List } from '../component/list';
import { getRootResource } from '../helper/ActorHelper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  waitForAction,
} from '../type/actor-setup';

import { MultiTabList } from '../component/multi-tab-list';
import LoadingBox from '../component/LoadingBox';
import { Map } from '../component/map';
import { ReportCalendar } from '../component/report-calendar';
import { PivotTable } from '../component/pivot-table';
import { PrintReport } from '../component/print-report';
import { Title } from 'react-admin';

import type { GeneralMetaData, SimplePage } from '../helper/Types';
import NotFound from '../component/NotFound';
import { getTranslatedName } from '../helper/meta-helper';
import { Locale } from '../type/global-types';
import { initializeLastFilters } from '../component/list/list.helper';
import { isEmpty, isEmptyObject } from '../helper/data-helper';
import { removeOnDispatches } from '../helper/general-function-helper';

const listViews = {
  Calendar: ReportCalendar,
  Pivot: PivotTable,
  Map: Map,
  Print: PrintReport,
  simpleList: List, // table grids
  Grid: List, // reports
  multiReport: MultiTabList, // multi reports
};

const useStyles = makeStyles(() => ({
  listContainer: {
    minHeight: 'min-content',
    display: 'flex',
    position: 'relative',
    flexGrow: 1,
  },
}));

const ListPage: FC<SimplePage> = () => {
  const [loading, setLoading] = useState(true); //fixme it will not turn true when resource change

  const currentMetaData = useRef<GeneralMetaData | undefined | null>(null);
  const rootResource = useRef<string | null>(null);

  const classes = useStyles();
  const locale = actorGetActionValue('reactAdminHelpers')?.locale ?? Locale.FA;

  useEffect(() => {
    const onDispatches: Parameters<typeof removeOnDispatches>[0] = [];

    const listenerId = actorOnDispatch(
      'showLoading',
      async loading => {
        if (loading === true) {
          setLoading(true);
          return;
        }

        rootResource.current = getRootResource() ?? '';
        if (!rootResource.current) return;

        await waitForAction(
          'showSettingsLoading',
          showSettingsLoading => showSettingsLoading === false,
        );

        await waitForAction('metaData', metaData => {
          if (metaData[rootResource.current!] as GeneralMetaData | undefined) {
            currentMetaData.current = metaData[
              rootResource.current!
            ] as GeneralMetaData;
          } else {
            setLoading(false);
          }

          return currentMetaData.current != null;
        });

        initializeLastFilters(currentMetaData.current!);
        setLoading(false);
      },
      { preserve: false },
    );

    onDispatches.push({
      actionName: 'showLoading',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    if (loading) return;

    actorDispatch(
      'setDocumentTitle',
      {
        metaData: currentMetaData.current,
        locale,
      },
      { replaceAll: true, disableDebounce: true },
    );
  }, [loading]);

  if (loading) {
    return <LoadingBox />;
  }

  if (!currentMetaData.current) {
    return <NotFound />;
  }

  const metaData = currentMetaData.current as GeneralMetaData;

  //in single record we have'nt list page.and should redirect user to create edit form
  if (metaData.config?.hasOneRow) {
    window.location.href = `#/single-record/${rootResource.current}`;
    //should not run next functions
    return null;
  }

  const isMultiReport =
    metaData.reportType === 'ParentChild' || metaData.reportType === 'MultiResult';

  const metaDataViewType = metaData.defaultView ?? 'simpleList';

  let ViewComponent = listViews[metaDataViewType];

  if (isMultiReport) {
    if (metaDataViewType === 'Print') {
      ViewComponent = listViews['Print'];
    } else {
      ViewComponent = listViews['multiReport'];
    }
  }

  return (
    <div className={classes.listContainer}>
      <Title title={getTranslatedName(metaData, locale)} />
      <ViewComponent
        metaData={metaData}
        listType={metaDataViewType}
        resource={rootResource.current!} // When there is `metaData` it means there is `rootResource.current` with a valid value
      />
    </div>
  );
};

export default memo(ListPage, (prevProps, nextProps) => {
  const prevResource = `${prevProps.match.params.moduleName}/${prevProps.match.params.moduleTableName}`;
  const nextResource = `${nextProps.match.params.moduleName}/${nextProps.match.params.moduleTableName}`;

  return (
    prevResource === nextResource &&
    prevProps.match.params.id === nextProps.match.params.id
  );
});
