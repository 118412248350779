import { useTranslate } from 'react-admin';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { useStyles } from './assign-task-dialog.style';

import type { AssignTaskDialogViewInterface } from './assign-task-dialog.type';
import type { FC } from 'react';

const AssignTaskDialogView: FC<AssignTaskDialogViewInterface> = props => {
  const {
    sharingList,
    closeDialogHandler,
    handleListItemClick,
    handleAssign,
    selectedUserId,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.dialogMainContainer} data-test="shareDialog">
      <DialogTitle>{translate('todo.assign')}</DialogTitle>
      <DialogContent>
        {sharingList.map((item, index) => (
          <List component="nav" aria-label="contacts">
            <ListItem
              button
              selected={selectedUserId === item.personinfo_id}
              onClick={() => handleListItemClick(item.personinfo_id)}
              style={{
                color: item.personinfo_id == selectedUserId ? 'blue' : '',
              }}
            >
              <ListItemText
                inset
                primary={item.fullname}
                className={classes.personName}
              />
            </ListItem>
          </List>
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActionsContainer}>
        <Button
          onClick={closeDialogHandler}
          className={classes.cancelBtn}
          variant="outlined"
        >
          {translate('confirm.cancel')}
        </Button>
        <Button
          onClick={handleAssign}
          className={classes.confirmButton}
          variant="contained"
        >
          {translate('mail.send')}
        </Button>
      </DialogActions>
    </div>
  );
};
export default AssignTaskDialogView;
