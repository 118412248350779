import SearchIcon from '@material-ui/icons/Search';

import { type FC } from 'react';
import { useStyles } from '../chat-section.style';
import type { SearchInfoViewInterface } from './search-info.type';

const SearchInfoView: FC<SearchInfoViewInterface> = props => {
  const { handleSearchUser } = props;
  const classes = useStyles();

  return (
    <p onClick={handleSearchUser} className={classes.containerSearch}>
      <SearchIcon />
    </p>
  );
};
export default SearchInfoView;
