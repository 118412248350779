import { useTranslate, useLocale } from 'react-admin';
import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './add-members-dialog.style';

import LoadingBox from '../../../loading-box';
import { ChatUserList } from '../../chat-user-list';
import { EDIT_MODE, VIEW_MODE } from './add-members-dialog.helper';

import type { ReactElement } from 'react';
import type {
  AddMembersDialogViewPropsInterface,
  ChatUsersInterface,
} from './add-members-dialog.type';

const AddMembersDialogView = (
  props: AddMembersDialogViewPropsInterface,
): ReactElement => {
  const {
    handleCreateOrUpdateGroup,
    handleDeleteSelectedUser,
    addToSelectedUsers,
    closeDialogHandler,
    handleRemoveUser,
    handleSearch,
    getAvatarURL,
    actionButtonText,
    selectedUsers,
    dialogTitle,
    isFound,
    users,
    mode,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const locale = useLocale();

  return (
    <div className={classes.mainDialogContainer}>
      <DialogTitle>
        <Box className={classes.dialogTitleContainer}>
          <Box>{dialogTitle}</Box>
          <IconButton className={classes.icon} onClick={closeDialogHandler}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContentContainer} dividers={true}>
        <Box className={classes.contentHeader}>
          <Box className={classes.textFieldContainer}>
            <TextField
              focused
              fullWidth
              className={classes.chatSearch}
              placeholder={translate('chat.search')}
              onChange={handleSearch}
              InputProps={{
                classes: {
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            {selectedUsers.length > 0 &&
              selectedUsers.map((selectedUser: ChatUsersInterface): JSX.Element => {
                if (selectedUser.isAlreadyMember) return <></>;
                return (
                  <Chip
                    avatar={
                      <Avatar
                        src={getAvatarURL(
                          selectedUser.personimage ?? selectedUser.personprofile,
                        )}
                      />
                    }
                    label={selectedUser.personname ?? selectedUser.fullname}
                    onDelete={handleDeleteSelectedUser(selectedUser)}
                    className={classes.chip}
                  />
                );
              })}
          </Box>
        </Box>
        {users.length > 0 ? (
          <ChatUserList
            handleRemoveUser={handleRemoveUser}
            handleClick={addToSelectedUsers}
            selectedUsers={selectedUsers}
            users={users}
          />
        ) : isFound ? (
          <LoadingBox />
        ) : (
          <div>{translate('general.noRecordFound')}</div>
        )}
      </DialogContent>
      {mode !== VIEW_MODE && (
        <DialogActions
          className={
            locale === 'en'
              ? classes.dialogActionsContainerEnglishVersion
              : classes.dialogActionsContainer
          }
        >
          <Button onClick={closeDialogHandler}>{translate('confirm.cancel')}</Button>
          <Button onClick={handleCreateOrUpdateGroup}>{actionButtonText}</Button>
        </DialogActions>
      )}
    </div>
  );
};

export default AddMembersDialogView;
