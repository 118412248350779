import {
  useState,
  memo,
  ChangeEvent,
  useEffect,
  useCallback,
  useRef,
  type FC,
  type MouseEvent,
  useMemo,
} from 'react';
import { IconButton, Box, useTheme } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PinDropIcon from '@material-ui/icons/PinDrop';
import lodashDebounce from 'lodash/debounce';

import { isEnterPressed, isEscapePressed } from '../../../helper/FormHelper';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../type/actor-setup';
import NewMessageView from './new-message.view';
import { chatStatelessActions } from '../chat-section-helper/chat-section-stateless-actions';
import { isEmpty, checkJsonStringify } from '../../../helper/data-helper';
import {
  CHAT_VERSION,
  ChatMessageTypeEnum,
  searchForUsersInGroup,
  setUsersIsTyping,
} from '../chat-section.helper';
import { openLocationDialog } from './new-message.helper';

import { getCommutatingUsers } from '../chat-sidebar/chat-sidebar.helper';
import { fileUploadResource } from '../chat-section.helper';
import { decodeHtml } from '../../../helper/general-function-helper';

import type { ChatUsersInterface } from '../chat-dialogs/add-members-dialog/add-members-dialog.type';
import type { LocationInterface } from '../../dynamic-input/location-input/location-input.type';
import type { CustomTheme } from '../../../core/themeProvider';
import type {
  ChatTextJsonFormatInterface,
  ForwardMessageMetaInterface,
  LocationMessageMetaInterface,
  NewMessageInterface,
  SpeedActionNames,
  SpeedActionsInterface,
  VideoMessageMetaInterface,
} from './new-message.type';
import { ChatItemInterface } from '../chat-section.type';

const NewMessageController: FC<NewMessageInterface> = memo(props => {
  const { sendMessageAction, currentMessage, groupUsers, embeded } = props;

  const [message, setMessage] = useState<string>('');
  const groupUsersRef = useRef<ChatUsersInterface[]>(groupUsers ?? []);
  const [isMentionListOpen, setIsMentionListOpen] = useState<boolean>(false);
  const [openVideoChatDialog, setOpenVideoChatDialog] = useState<boolean>(false);
  const [isSpeedDialSelectOpen, setIsSpeedDialSelectOpen] = useState(false);
  const [showEmojis, setShowEmojis] = useState<boolean>(false);

  const chatInputRef = useRef<HTMLInputElement>(null);
  const emojiContainerRef = useRef<HTMLDivElement>(null);
  const uploadedVideoUrl = useRef<string | null>(null);
  const selectedUserRef = useRef<ChatItemInterface>();

  useOnClickOutside(emojiContainerRef, () => setShowEmojis(false));

  const theme: CustomTheme = useTheme();

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    groupUsersRef.current = groupUsers ?? [];
  }, [groupUsers]);

  useEffect(() => {
    if (currentMessage != null) {
      if (currentMessage.mode === 'edit') {
        setDefaultMessage();
      }

      const chatList = actorGetActionValue('chatList')!;

      const chatId =
        currentMessage.data.isfrommyself === 1
          ? currentMessage.data.topersoninfo_id
          : currentMessage.data.frompersoninfo_id;

      // prettier-ignore
      selectedUserRef.current = chatList.data[chatId].info;
    }

    chatInputRef.current!.focus();
  }, [currentMessage]);

  useEffect(() => {
    actorSetActionValue('mainChatInput', chatInputRef.current);
  }, [chatInputRef]);

  useEffect(() => {
    chatInputRef.current!.focus();
  }, [message]);

  /**
   * @function setDefaultMessage
   * @returns {void} void
   */
  const setDefaultMessage = (): void => {
    // prettier-ignore
    const parseChatTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(currentMessage?.data.chattext ?? '');
    if (parseChatTextResult === false) {
      const messageText = decodeHtml(currentMessage?.data.chattext ?? '');
      setMessage(messageText);
      return;
    }

    const locationMessageText = decodeHtml(parseChatTextResult.formattedText);
    setMessage(locationMessageText);
  };

  /**
   * @function toggleEmojisHandler
   * @returns { void }
   */
  const toggleEmojisHandler = useCallback((): void => {
    setShowEmojis(prev => !prev);
  }, []);

  /**
   * @function onAddEmoji
   * @param event
   * @returns { void }
   */
  const onAddEmoji = useCallback((event: any): void => {
    const sym = event.unified.split('-');
    const codesArray = [] as any[];
    sym.forEach((el: any) => codesArray.push('0x' + el));
    const emoji = String.fromCodePoint(...codesArray);
    setMessage(prevMessage => `${prevMessage}${emoji}`);
  }, []);

  /**
   * @function onSendMessage
   * @returns { void }
   */
  const onSendMessage = useCallback((): void => {
    let _rawChatTextInCurrentMessage = '';
    let _formattedChatTextInCurrentMessage = '';

    const _message: ChatTextJsonFormatInterface = {
      rawText: '',
      formattedText: '',
      version: CHAT_VERSION,
      messageType: ChatMessageTypeEnum.TEXT,
      meta: {},
    };

    let messageCanSend = message.length > 0;

    if (currentMessage != null) {
      // prettier-ignore
      const parseChatTextResult = checkJsonStringify<ChatTextJsonFormatInterface>(currentMessage.data.chattext);
      if (parseChatTextResult === false) {
        _rawChatTextInCurrentMessage = currentMessage.data.chattext.replace(
          /\n/g,
          ' ',
        );

        _formattedChatTextInCurrentMessage = currentMessage.data.chattext.replace(
          /\n/g,
          '<br>',
        );
        _message.messageType = ChatMessageTypeEnum.TEXT;
      } else {
        _rawChatTextInCurrentMessage = parseChatTextResult.rawText;
        // prettier-ignore
        _formattedChatTextInCurrentMessage = parseChatTextResult.formattedText.replace(/\n/g, '<br>');

        if (currentMessage.mode == 'forward') {
          /**
           * @description - forwardMessageNote
           * We have to send these message individually, because `sendMessageAction` works with `selectedMessage`,
           *  we empty `selectedMessage` and in the continue we'll set the correct value to send the forwarded message
           */
          actorSetActionValue('selectedMessage', null);
        } else if (currentMessage.mode === 'edit') {
          _message.messageType = parseChatTextResult.messageType;
          _message.meta = parseChatTextResult.meta;
          messageCanSend = true;
        }
      }
    }

    if (messageCanSend) {
      _message.rawText = message.replace(/\n/g, '<br>');
      _message.formattedText = checkInputForUrl(_message.rawText);

      if (!embeded) {
        sendMessageAction(_message);
      }
    }

    if (currentMessage?.mode == 'forward') {
      // @description - forwardMessageNote
      actorSetActionValue('selectedMessage', currentMessage);
      const forwardMessageText = _rawChatTextInCurrentMessage.replace(/\n/g, '<br>');

      sendMessageAction<ChatMessageTypeEnum.FORWARD, ForwardMessageMetaInterface>({
        messageType: ChatMessageTypeEnum.FORWARD,
        rawText: forwardMessageText,
        formattedText: checkInputForUrl(_formattedChatTextInCurrentMessage),
        version: CHAT_VERSION,
        meta: {
          forwardName: selectedUserRef.current?.personname ?? '',
        },
      });
    }

    /**
     * The following code will be run in `UploadFilesForm`(i.e. `embeded` is equal to `true`)
     */
    if (embeded) {
      sendMessageAction(_message);
    }

    resetMessageInputData();
  }, [message, sendMessageAction, currentMessage, embeded]);

  /**
   * @function onSendFile
   * @param { React.ChangeEvent<HTMLElement> } event
   * @returns { void }
   */
  const onSendFile = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      chatStatelessActions.sendFiles({
        files: event.target.files,
      });

      // reset the last values of the input after sending
      event.target.value = '';
    },
    [],
  );

  const resetMessageInputData = useCallback(() => {
    actorDispatch('selectedMessage', null);
    setMessage('');
    setIsMentionListOpen(false);
  }, []);

  /**
   * Close selectedMessage Box  by esc
   * @function escFunction
   * @param event
   */
  const escFunction = useCallback(event => {
    if (isEscapePressed(event)) {
      resetMessageInputData();
    }
  }, []);

  /**
   * @function checkInputForMention
   * @param {string} inputValue
   * @returns {void} void
   */
  const checkInputForMention = useCallback(
    (inputValue: string): void => {
      if (!groupUsers) return;

      if (inputValue.at(-1) === '@') {
        setIsMentionListOpen(true);
        groupUsersRef.current = groupUsers;
      } else if (inputValue.at(-1) === ' ' || inputValue === '') {
        setIsMentionListOpen(false);
      } else if (isMentionListOpen) {
        //get the text after the last @ and before space from input value like @amirreza => amirreza or @amirreza @mohammad => mohammad
        const targetUserName = inputValue.split('@').pop()?.split(' ').shift() ?? '';
        const result = searchForUsersInGroup(groupUsers, targetUserName);
        groupUsersRef.current = result;
      }
    },
    [isMentionListOpen, groupUsers],
  );

  /**
   * @function checkInputForUrl
   * @param {string} inputValue
   * @returns {string} formattedMessage
   */
  const checkInputForUrl = useCallback((inputValue: string): string => {
    return inputValue.replace(
      /((?:https?:\/\/)?(?:[\w-]+\.)+[a-zA-Z]{2,}(?::\d+)?|(?:\d{1,3}\.){3}\d{1,3}(?::\d+)?)/g,
      substring => {
        const formattedSubstring = substring.startsWith('http')
          ? substring
          : `http://${substring}`;

        return `<a href="${formattedSubstring}" target="_blank">${substring}</a>`;
      },
    );
  }, []);

  /**
   * handle user is typing
   * @function updateUserIsTyping
   * @returns {Promise<void>}
   */
  const updateUserIsTyping = lodashDebounce(async (): Promise<void> => {
    try {
      const chatData = actorGetActionValue('selectedChat');

      if (chatData) {
        const communicatingUsers =
          chatData.chatContacts || (await getCommutatingUsers(chatData.info));

        setUsersIsTyping(
          communicatingUsers ?? [],
          !isEmpty(chatData.info.groupuid) ? chatData.info.personinfo_id + '' : '',
        );
      }
    } catch (error) {
      console.error('updateUserIsTyping error: ', error);
    }
  }, 1500);

  /**
   * @function handleInputChange
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const inputValue = event.target.value;

      updateUserIsTyping();
      checkInputForMention(inputValue);

      if (inputValue !== '\n') {
        setMessage(inputValue);
      }
    },
    [isMentionListOpen, groupUsers],
  );

  /**
   * send message by Enter and go to next line by shift + enter
   * @function handleKeyPress
   * @param { React.KeyboardEvent<HTMLDivElement> } event
   * @returns { void }
   */
  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>): void => {
      if (event.ctrlKey) {
        setMessage(message + '\n');
      }

      if (
        isEnterPressed(event) &&
        !event.shiftKey &&
        !event.ctrlKey &&
        !isEmpty(message)
      ) {
        event.preventDefault();
        onSendMessage();
      }
    },
    [message],
  );

  /**
   * @function handleOnMentionClick
   * @param { ChatUsersInterface } user
   * @returns { void } void
   */
  const handleOnMentionClick =
    (user: ChatUsersInterface) =>
    (_event: MouseEvent<HTMLDivElement>): void => {
      const newMessageToSend =
        message +
        `<a style="pointer-events: none;" href="" title="[(${user.fullname})~${user.personinfo_id}~]">${user.fullname}</a>`;
      setMessage(newMessageToSend);
    };

  /**
   * @function openDialog
   * @returns { void }
   */
  const openDialog = (): void => {
    setOpenVideoChatDialog(true);
  };

  /**
   * @function sendVideoMessage
   * @returns { void }
   */
  const sendVideoMessage = (): void => {
    if (isEmpty(uploadedVideoUrl.current)) {
      setOpenVideoChatDialog(false);
      return;
    }

    sendMessageAction<ChatMessageTypeEnum.VIDEO, VideoMessageMetaInterface>({
      rawText: 'Video',
      formattedText: '',
      version: CHAT_VERSION,
      messageType: ChatMessageTypeEnum.VIDEO,
      meta: {
        link: uploadedVideoUrl.current,
      },
    });

    uploadedVideoUrl.current = null;
    setOpenVideoChatDialog(false);
  };

  /**
   * @function onVideoRecorded
   * @param { Blob | MediaSource } blob
   * @returns { void }
   */
  const onVideoRecorded = (blob: Blob | MediaSource): void => {
    actorDispatch('uploadStreamFile', {
      param: {
        resource: fileUploadResource,
        file: blob,
      },
      successCallback: (response: { filePath: string }): void => {
        uploadedVideoUrl.current = response.filePath;
      },
    });
  };

  /**
   * @function closeVideoChatDialog
   * @returns { void }
   */
  const closeVideoChatDialog = (): void => {
    setOpenVideoChatDialog(false);
  };

  /**
   * @const speedActions
   * @description you can add your buttons as feature to this object
   * you can also add any complicated components as icon with `ComponentAsIcon` key
   * but notice that it should look exact like a siple Icon
   */
  const speedActions = useMemo<SpeedActionsInterface[]>(
    () => [
      { ComponentAsIcon: <PinDropIcon />, name: 'location' },
      {
        name: 'file',
        ComponentAsIcon: (
          <Box>
            <input
              id="icon-button-file"
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={onSendFile}
            />
            <label htmlFor="icon-button-file">
              <IconButton color="primary" aria-label="upload file" component="span">
                <AttachFileIcon
                  style={{
                    color: theme.palette.grey[400],
                    fontSize: '1.8rem',
                    transform: 'rotate(30deg)',
                  }}
                />
              </IconButton>
            </label>
          </Box>
        ),
      },
    ],
    [],
  );

  /**
   * close speed dial select
   * @function closeSpeedDialSelect
   * @returns {void} void
   */
  const closeSpeedDialSelect = useCallback((): void => {
    setIsSpeedDialSelectOpen(false);
  }, []);

  /**
   * open speed dial select
   * @function openSpeedDialSelect
   * @returns {void} void
   */
  const openSpeedDialSelect = useCallback(() => {
    setIsSpeedDialSelectOpen(true);
  }, []);

  /**
   * convert location to a json with correct format
   * @function handleChooseLocation
   * @param { LocationInterface } location
   * @param { string } imageUrl
   * @returns { void }
   */
  const handleChooseLocation = useCallback(
    (location: LocationInterface, imageUrl: string): void => {
      sendMessageAction<ChatMessageTypeEnum.LOCATION, LocationMessageMetaInterface>({
        rawText: 'Location',
        formattedText: '',
        version: CHAT_VERSION,
        messageType: ChatMessageTypeEnum.LOCATION,
        meta: {
          coordinates: location,
          imageUrl,
        },
      });
    },
    [],
  );

  /**
   * handle on click for each button in speed dial select
   * @function speedDialItemSelect
   * @param {SpeedActionNames} actionName
   * @returns {function} - a function to handle an item selection
   */
  const speedDialItemSelect = useCallback(
    (actionName: SpeedActionNames) => (): void => {
      switch (actionName) {
        case 'location':
          openLocationDialog(handleChooseLocation);
          break;

        case 'file':
          // nothing should happen here. because its component as icon handle its own functionalities by itself
          // but you can add any addition logic here.
          break;

        default:
          console.warn(
            'an unknown action clicked in `SpeedDial` component. please make sure that you handled all af actions in `speedActions` variable in `new-message.controller.tsx`',
          );
          break;
      }
    },
    [],
  );

  return (
    <NewMessageView
      handleOnMentionClick={handleOnMentionClick}
      onCancelSelectedMessage={resetMessageInputData}
      closeSpeedDialSelect={closeSpeedDialSelect}
      openSpeedDialSelect={openSpeedDialSelect}
      speedDialItemSelect={speedDialItemSelect}
      toggleEmojisHandler={toggleEmojisHandler}
      handleInputChange={handleInputChange}
      onSendMessage={onSendMessage}
      onKeyPress={handleKeyPress}
      onAddEmoji={onAddEmoji}
      groupUsers={groupUsersRef.current}
      isMentionListOpen={isMentionListOpen}
      isSpeedDialSelectOpen={isSpeedDialSelectOpen}
      selectedMessageData={currentMessage}
      emojiContainerRef={emojiContainerRef}
      speedActions={speedActions}
      ref={chatInputRef}
      showEmojis={showEmojis}
      message={message}
      openDialog={openDialog}
      openVideoChatDialog={openVideoChatDialog}
      closeVideoChatDialog={closeVideoChatDialog}
      sendVideoMessage={sendVideoMessage}
      onVideoRecorded={onVideoRecorded}
      embeded={embeded}
      selectedUser={selectedUserRef.current}
    />
  );
});
export default NewMessageController;
