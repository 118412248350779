import { ReactElement } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import { TodoDateOptionsMenuViewInterface } from './todo-date-options-menu.type';
import { isEmpty } from '../../../../helper/data-helper';

const TodoSidebarContextMenuView = (
  props: TodoDateOptionsMenuViewInterface,
): ReactElement => {
  const { onClickItem, isContextMenuOpen, items } = props;

  return (
    <>
      {!isEmpty(isContextMenuOpen) && (
        <Menu
          PaperProps={{
            style: {
              width: '200px',
            },
          }}
          open={isContextMenuOpen !== null}
          anchorReference="anchorPosition"
          anchorPosition={
            isContextMenuOpen?.mouseY
              ? { top: isContextMenuOpen.mouseY, left: isContextMenuOpen.mouseX }
              : undefined
          }
        >
          {items?.map(menuItem => (
            <MenuItem
              /* @ts-ignore */
              onClick={onClickItem(menuItem)}
            >
              {menuItem.title}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default TodoSidebarContextMenuView;
