import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    height: '100%',
    maxHeight: '40px',

    '& legend': {
      display: 'none',
    },
    '& fieldset': {
      backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    },
  },

  notchedOutline: {
    top: 0,
  },

  outlineRoot: {
    height: '100%',
  },

  disabled: {
    color: theme.palette.primary.disabledFieldColor,
    '& fieldset': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  input: {
    zIndex: 1,
    '&:-webkit-autofill': {
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
    },
  },

  error: {
    '& + p': {
      position: 'absolute',
      right: 0,
    },
  },

  iconButton: {
    zIndex: 1,
  },
}));
