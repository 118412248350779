import { Icon } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { FC, memo } from 'react';
import GaugeChart from 'react-gauge-chart';

import { isEmpty } from '../../../helper/data-helper';

import { useStyles } from './gauge-chart.style';
import type { GaugeChartViewProps } from './gauge-chart.type';

const GaugeChartView: FC<GaugeChartViewProps> = props => {
  const {
    getFormatTextValue,
    getTextColor,
    arcNumberList,
    arcColorList,
    value,
    title,
    height,
    width,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  if (isEmpty(value) || isNaN(value))
    return (
      <div className={classes.container} style={{ height, width }}>
        {translate('general.noData')}
      </div>
    );

  return (
    <div
      className={classes.container}
      style={{ height, width, maxWidth: 2 * height }} //for unmoral widths
    >
      {!isEmpty(title) && <div className={classes.title}>{title}</div>}
      <div>
        <GaugeChart
          id="gauge-chart5"
          nrOfLevels={420}
          arcsLength={arcNumberList}
          colors={arcColorList}
          percent={value > 100 ? 1 : value / 100}
          cornerRadius={0}
          arcPadding={0}
          formatTextValue={getFormatTextValue}
          textColor={getTextColor}
          width={width}
        />
      </div>
    </div>
  );
};

export default memo(GaugeChartView);
