import { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import lodashGet from 'lodash/get';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { Icon, IconButton, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';

import { actorOnDispatch, actorSetActionValue, FormKeyMode } from '../type/actor-setup';
import { removeOnDispatches } from '../helper/general-function-helper';
import { isEmpty } from '../helper/data-helper';

const styles = theme => ({
  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  icon: {
    fontSize: 20,
  },

  menuItem: {
    fontSize: 12,
  },
});

const getEmptyState = () => ({
  anchorEl: null,
  isDialogOpen: false,
  selectedService: null,
  isSending: false,
  serviceParams: {},
  disabled: false,
});

/**
 * define wheter we have more than one process in list or not
 * @function shouldOpenMenu
 * @param { Object } list
 * @returns { boolean }
 */
const shouldOpenMenu = list => {
  return Array.isArray(list) && list.length > 1;
};

class CreateWithProcessButtonContainer extends PureComponent {
  state = getEmptyState();
  onDispatches = [];
  parentInfoQueryParams = '' ;

  /**
   * @function handleOpenMenu
   * @param { ChangeEvent } event
   * @returns { void }
   */
  handleOpenMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  /**
   * @function handleOpenProcess
   * @param { Object } processList
   * @returns { void }
   */
  handleOpenProcess = processList => {
    const { history, resource, relationMode, data } = this.props;
    const process = processList?.[0];

    let createResource = `/${resource}/create?__processuniqueid=${process.uniqueId}` +
    `&positionid=${lodashGet(process, 'firstTask.positionId')}` +
    `&stateid=${lodashGet(process, 'firstTask.stateId')}`

    if (relationMode) {
      actorSetActionValue(
        'quickCreateSupplementaryData',
        { ...data, parentUrl: history.location.pathname },
        {
          path: `${resource}.${FormKeyMode.RELATION}`,
          replaceAll: true,
        },
      );

      if(!isEmpty(this.parentInfoQueryParams)){
        createResource += this.parentInfoQueryParams
      }
    }

    history.push(createResource);
  };

  /**
   * @function handleCloseMenu
   * @param {ChangeEvent } event
   * @returns { void }
   */
  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  componentDidMount() {

    const {parentInfo } = this.props;
    if(!isEmpty(parentInfo?.parentId) && !isEmpty(parentInfo?.parentResource) ){
      this.parentInfoQueryParams = `&parentid=${parentInfo?.parentId}&parentresource=${parentInfo?.parentResource}`
    }
    
    const listenerId = actorOnDispatch('loading', loadingRecord => {
      const disabled = loadingRecord['service'] || loadingRecord['processChangeLineButtons'] || false;
      this.setState(prev => ({...prev, disabled}));
    });

    this.onDispatches.push({
      actionName: 'loading',
      listenerId,
    });
  }

  componentWillUnmount() {
    removeOnDispatches(this.onDispatches);
  }

  render() {
    const { anchorEl } = this.state;
    const { classes, locale, resource, translate, activeProcessList } = this.props;

    if (!activeProcessList?.length) {
      return <div />;
    }

    return (
      <Fragment>
        <Tooltip title={translate('ra.action.create')}>
          <IconButton
            className={`${classes.IconButton} list-create-button`}
            ref={node => {
              this.button = node;
            }}
            onClick={
              shouldOpenMenu(activeProcessList)
                ? this.handleOpenMenu
                : () => this.handleOpenProcess(activeProcessList)
            }
            color="primary"
            id="createProcessButton"
          >
            <Icon className={classNames(classes.icon)}>add</Icon>
          </IconButton>
        </Tooltip>
        <Menu
          id="create-with-process-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseMenu}
        >
          {activeProcessList.map(
            process =>
              process?.isActive && (
                <MenuItem
                  data-test-uniqueid={process.uniqueId.slice(0, 4)}
                  key={process.uniqueId}
                  component={Link}
                  className={`${classes.menuItem} list-create-with-process-link`}
                  to={
                    `/${resource}/create?__processuniqueid=${process.uniqueId}` +
                    `&positionid=${lodashGet(process, 'firstTask.positionId')}` +
                    `&stateid=${lodashGet(process, 'firstTask.stateId')}${this.parentInfoQueryParams}`
                  }
                >
                  {lodashGet(process, ['translatedTitle', locale], process.title)}
                </MenuItem>
              ),
          )}
        </Menu>
      </Fragment>
    );
  }
}

CreateWithProcessButtonContainer.propTypes = {
  locale: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  metaData: PropTypes.object.isRequired,
};

export default compose(
  translate,
  withStyles(styles, { withTheme: true }),
  withRouter,
)(CreateWithProcessButtonContainer);
