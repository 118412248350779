import { makeStyles, Typography } from '@material-ui/core';
import { useLocale } from 'react-admin';
import { useEffect, type FC } from 'react';

import { RelationActionBar } from '../../relation-panel/relation-action-bar';
import { findRowStateColorField } from '../../../helper/RowColorHelper';
import { parseJSON } from '../../../core/configProvider';

import {
  actorDispatch,
  actorGetActionValue,
  type GridDataInterface,
} from '../../../type/actor-setup';
import type { GeneralMetaData } from '../../../helper/Types';
import type {
  RelationInMetaData,
  RelationPermissions,
  RelationType,
} from '../../relation-panel';
import type { TableRowColorInterface } from '../../table-color-filter';
import { isEmptyObject } from '../../../helper/data-helper';

const useStyles = makeStyles(() => ({
  relationActionBarContainer: {
    display: 'flex',
  },
}));

interface RelationPanelSummaryViewProps {
  relationType: RelationType;
  relationItemInMetaData: RelationInMetaData;
  relationResource: string;
  parentResource: string;
  relationPermission: RelationPermissions;
  relationPath: string;
  currentUrl: string;
  relationMetaData: GeneralMetaData | null;
  relationData: GridDataInterface | null;
  parentInfo: {
    parentResource: string;
    parentId: string;
    parentProcessUniqueId: string | null;
    parentPositionId: string | null;
    parentStateId: string | null;
  };
  parentRecord: Record<string, unknown>;
  clearAllSelectedCheckboxes: () => void;
  refreshRelation: () => void;
  hideActions: boolean;
  expand?: boolean;
  handleFullScreen;
}

const RelationPanelSummaryView: FC<RelationPanelSummaryViewProps> = props => {
  const {
    relationType,
    relationItemInMetaData,
    relationResource,
    parentResource,
    relationPermission,
    relationPath,
    currentUrl,
    relationMetaData,
    relationData,
    parentInfo,
    parentRecord,
    clearAllSelectedCheckboxes,
    refreshRelation,
    hideActions,
    expand,
    handleFullScreen,
  } = props;

  const locale = useLocale();
  const classes = useStyles();
  const text =
    relationItemInMetaData?.['translatedTitle']?.[locale] ??
    relationItemInMetaData?.['title'] ??
    relationItemInMetaData?.['moduleTableTitle'];

  if (!relationMetaData) {
    return (
      <Typography data-tag="accordion-summuary-title" variant="body2">
        {text}
      </Typography>
    );
  }

  const {
    moduleTableTitle,
    translatedTitle,
    title,
    reportId,
    moduleName,
    moduleTableName,
    childFieldName,
    parentFieldName,
  } = relationItemInMetaData ?? {};

  const fullSCreenItem = actorGetActionValue('fullScreenItem');

  const withActionBar =
    (relationItemInMetaData &&
      relationType !== 'note' &&
      relationMetaData.defaultView !== 'Pivot' &&
      expand) ||
    !isEmptyObject(fullSCreenItem);

  const relationTitle = translatedTitle?.[locale] ?? moduleTableTitle ?? title;

  const rowStateColorField = findRowStateColorField(relationMetaData.fields);
  const tableRowColors =
    parseJSON<TableRowColorInterface[]>(rowStateColorField?.values?.[0] ?? '') ?? [];

  return (
    <>
      <Typography data-tag="accordion-summuary-title" variant="body2">
        {relationTitle}
      </Typography>
      {!hideActions && (
        <div
          id={'action-bar-accordion-' + reportId}
          className={classes.relationActionBarContainer}
        >
          {withActionBar && (
            <RelationActionBar
              relationType={relationType}
              relationResource={relationResource}
              parentResource={parentResource}
              mockedRelation={{
                moduleName: moduleName!,
                moduleTableName: moduleTableName!,
                childFieldName: childFieldName!,
                parentFieldName: parentFieldName!,
              }}
              relationPermission={relationPermission}
              relationPath={relationPath}
              currentUrl={currentUrl}
              relationMetaData={relationMetaData}
              relationData={relationData}
              parentInfo={parentInfo}
              parentRecord={parentRecord}
              clearAllSelectedCheckboxes={clearAllSelectedCheckboxes}
              refreshRelation={refreshRelation}
              additionalProps={{
                // TODO: remove this poison ASAP 🤦🏽‍♂️ 🤦🏽‍♂️
                originalRecord: parentRecord,
              }}
              isColumnChoice={!!expand}
              tableRowColors={tableRowColors}
              handleFullScreen={handleFullScreen}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RelationPanelSummaryView;
