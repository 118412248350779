import { actorGetActionValue } from '../../../type/actor-setup';
import { permissionsTab } from '../../show-permissions/show-permissions.helper';
import type {
  SelectedRowData,
  RowData,
  TitleDialogInterface,
} from './permission-show-reason-dialog.type';

/**
 * @function removeSelectedPermissions
 * @param {RowData} row
 * @param {string} permissionKey
 * @returns {SelectedRowData}
 */
export const removeSelectedPermissions = (
  row: RowData,
  permissionKey: string,
  titleDialog: string,
): SelectedRowData => {
  return {
    AttributionType: row?.attributionType,
    AttributionIds: [row?.attributionIds],
    PermKeys: [permissionKey],
    Departments: row?.departmentIds ? [+row?.departmentIds] : [],
    PermDescs: {
      [permissionKey as string]: titleDialog,
    },
  };
};

/**
 * @function getDialogTitle
 * @param {TitleDialogInterface} dialogData
 * @returns {string} string
 */
export const getDialogTitle = ({
  fieldName,
  columnTitle,
  activeTab,
  cellData,
  cellName,
  cellNameModule,
}: TitleDialogInterface): string => {
  const translate = actorGetActionValue('reactAdminHelpers')!.translate;

  let dialogTitle = '';
  if (cellData.column?.caption) {
    dialogTitle =
      permissionsTab[activeTab?.selectedTab ?? 0].title +
      ' _ ' +
      translate('ra.action.table') +
      cellName +
      ' ـ ' +
      translate('ra.action.module') +
      cellNameModule +
      (fieldName.length > 1
        ? ' _ ' + translate('ra.action.field') + fieldName
        : '') +
      ' _ ' +
      translate('ra.action.license') +
      columnTitle;
  } else {
    dialogTitle =
      permissionsTab[activeTab?.selectedTab ?? 0].title + ' _ ' + cellNameModule;
  }
  return dialogTitle;
};

export const computePermKey = (
  permKey: string,
  cellDataData: Record<string, unknown>,
  cellDataName: string,
  cellDataItemData: {
    label: string;
    paramKey: string;
  },
): string => {
  let permissionKey = '';
  if (permKey && cellDataData?.[permKey]) {
    permissionKey = cellDataData[permKey] as string;
  } else {
    permissionKey = `${
      cellDataData?.[cellDataName + 'PermKey'] ?? cellDataItemData?.paramKey ?? ''
    }`;
  }
  return permissionKey;
};
