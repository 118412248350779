import { useLocale, useTranslate } from 'react-admin';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './admins-dialog.style';
import LoadingBox from '../../../loading-box';
import { ChatUserList } from '../../chat-user-list';

import type { ReactElement } from 'react';
import type { AdminsDialogViewPropsInterface } from './admins-dialog.type';

const AdminsDialogView = (props: AdminsDialogViewPropsInterface): ReactElement => {
  const {
    openAddMembersDialog,
    closeDialogHandler,
    handleRemoveAdmin,
    handleSearch,
    groupAdmins,
    isFound,
  } = props;
  const locale = useLocale();
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.mainDialogContainer}>
      <DialogTitle>
        <Box className={classes.dialogTitleContainer}>
          <Box>{translate('chat.admins')}</Box>
          <IconButton className={classes.icon} onClick={closeDialogHandler}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContentContainer} dividers={true}>
        <Box className={classes.contentHeader}>
          <Box className={classes.textFieldContainer}>
            <TextField
              focused
              fullWidth
              className={classes.chatSearch}
              placeholder={translate('chat.search')}
              onChange={handleSearch}
              InputProps={{
                classes: {
                  input: classes.input,
                },
                startAdornment: (
                  <InputAdornment position={locale === 'en' ? 'end' : 'start'}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        {groupAdmins.length > 0 ? (
          <ChatUserList handleRemoveUser={handleRemoveAdmin} users={groupAdmins} />
        ) : isFound ? (
          <LoadingBox />
        ) : (
          <div>{translate('general.noRecordFound')}</div>
        )}
      </DialogContent>

      <DialogActions
        className={
          locale === 'en'
            ? classes.dialogActionsContainerEnglishVersion
            : classes.dialogActionsContainer
        }
      >
        <Button onClick={closeDialogHandler}>{translate('confirm.cancel')}</Button>
        <Button onClick={openAddMembersDialog}>{translate('chat.addAdmin')}</Button>
      </DialogActions>
    </div>
  );
};

export default AdminsDialogView;
