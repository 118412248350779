import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  createFolderBtn: {
    width: '100%',
    fontSize: '11px',
  },

  toggleBtn: {
    minWidth: '0 !important',
    background: '#1D85D6 !important',
  },
  listRoot: {
    marginBottom: 8,
    [theme.breakpoints.up('xl')]: {
      marginBottom: 20,
    },
  },
  shareFormContainer: {
    minWidth: '600px',
    maxWidth: '600px',
  },
}));
