import React, { ReactElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconButton, makeStyles, Theme } from '@material-ui/core';
import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import { FieldType } from '../../helper/Types';
import { isEmpty } from '../../helper/data-helper';

// TODO: Make TextFilterInput && NumberFilterInput a single Component

interface NumberFilterInputType {
  value: string | number | null;
  onChange?: Function;
  field: FieldType;
  source?: string;
  disabled?: boolean;
  getRef?: (ref: HTMLElement | null) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    display: 'flex',
    flex: 1,
  },

  input: {
    border: 'none',
    outline: 'none',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    '&::-webkit-inner-spin-button,&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },

  closeIcon: {
    marginRight: '2px',
    color: 'rgba(0, 0, 0, 0.54)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 5,
    },
  },
}));

const NumberFilterInput = (props: NumberFilterInputType): ReactElement => {
  const { value, source, getRef, onChange, disabled } = props;

  /**
   * TODO:
   * We need `getRef` in the future,
   *  to handle changing focus of inputs by pressing `enter`
   */

  const [inputValue, setInputValue] = useState<string | number>('');

  const classes = useStyles();

  useEffect(() => {
    const formattedValue = convertDigitsToEnglish(value ?? '');
    setInputValue(formattedValue);
    onChange?.(formattedValue);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const numberValue = convertDigitsToEnglish(event.target.value);
    setInputValue(numberValue);
    onChange?.(numberValue);
  };

  const clearHandler = () => {
    setInputValue('');
    onChange?.('');
  };

  return (
    <div className={classes.inputContainer} data-test-field-name={source}>
      <input
        ref={getRef}
        data-test-range-input
        value={inputValue}
        onChange={handleChange}
        className={classes.input}
        type="number"
        pattern="[0-9]+"
      />
      {!disabled && !isEmpty(inputValue) && (
        <IconButton
          role={'clearable'}
          size="small"
          tabIndex={-1}
          onClick={clearHandler}
          disabled={disabled}
          data-test-input-name="auto-complete-search-button"
        >
          <Icon fontSize="small" className={classes.closeIcon}>
            close
          </Icon>
        </IconButton>
      )}
    </div>
  );
};

NumberFilterInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NumberFilterInput;
