import { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@material-ui/core/Box';
import { UserListViewInterface } from './user-list.type';
import { UserItem } from './user-item';
import LoadingBox from '../../../loading-box';
import { ChatItem } from './chat-item';
import { ChatItemInterface, ContactInterface } from '../../chat-section.type';
import { useStyles } from './user-list.style';

const UserListView = (props: UserListViewInterface): ReactElement => {
  const {
    data,
    fetchMore,
    refreshFunction,
    loading,
    isChatMode,
    onUserSelect,
    selectedUser,
    isUserInfoDialog,
  } = props;

  const classes = useStyles();

  return (
    <Box className={classes.chatScroll}>
      {loading ? (
        <LoadingBox size={40} style={{ alignItems: 'flex-start' }} />
      ) : (
        <InfiniteScroll
          dataLength={data.list.length}
          next={fetchMore}
          hasMore={data.hasMore}
          loader={<LoadingBox size={20} />}
          refreshFunction={refreshFunction}
          pullDownToRefreshThreshold={50}
          height={716}
          pullDownToRefreshContent={
            <p style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</p>
          }
          releaseToRefreshContent={
            <p style={{ textAlign: 'center' }}>&#8593; Release to refresh</p>
          }
        >
          {data.list.map(
            (item: ChatItemInterface | ContactInterface, index: number) => (
              <Box key={'list-item-' + index}>
                {isChatMode || isUserInfoDialog ? (
                  <ChatItem
                    key={item.personinfo_id}
                    item={item as ChatItemInterface}
                    onChatItemSelect={onUserSelect}
                    selectedUser={selectedUser}
                    refreshFunction={refreshFunction}
                    isUserInfoDialog={isUserInfoDialog}
                  />
                ) : (
                  <UserItem
                    key={item.personinfo_id}
                    item={item}
                    onUserSelect={onUserSelect}
                    selectedUser={selectedUser}
                    refreshFunction={refreshFunction}
                  />
                )}
              </Box>
            ),
          )}
        </InfiniteScroll>
      )}
    </Box>
  );
};

export default UserListView;
