import { FC, memo } from 'react';
import clsx from 'classnames';

import { TableRelation } from './table-relation';
import { NoteRelation } from './note-relation';
import { MultiTabTableRelation } from './multi-tab-table-relation';
import { useStyles } from './relation-panel.style';

import type { RelationPanelViewInterface } from './relation-panel.type';
import { CustomAccordion } from '../custom-accordion';
import RelationPanelSummaryView from '../custom-accordion/custom-summary-views/relation-panel-summary-view';
import NotFound from '../NotFound';
import LoadingBox from '../loading-box';
import { KEY_SCROLL_TO } from './relation-panel.helper';
import { actorDispatch } from '../../type/actor-setup';

const relationViews = {
  table: TableRelation,
  oneToOneTable: TableRelation, // fixMe: complete this type in another card
  report: TableRelation,
  multiReport: MultiTabTableRelation,
  note: NoteRelation,
};

const RelationPanelView: FC<RelationPanelViewInterface> = props => {
  const {
    refreshRelation,
    clearAllSelectedCheckboxes,
    relationPanelBaseProps,
    idTarget,
    relationIndex,
    relationData,
    relationItemInMetaData,
    parentResource,
    relationPath,
    parentRecord,
    metaDataError,
    dragDropRef,
    key,
  } = props;

  const {
    relationType,
    relationResource,
    relationPermission,
    currentUrl,
    relationMetaData,
    parentInfo,
  } = relationPanelBaseProps;

  const ViewComponent = relationViews[relationType];

  const classes = useStyles();

  const handleFullScreen = (status: boolean) => {
    actorDispatch('fullScreenItem', status ? <View /> : undefined);
  };

  const View = () => {
    return (
      <div
        ref={dragDropRef}
        className={clsx(classes.relationPanelContainer, classes.isOver)}
        id={KEY_SCROLL_TO + idTarget}
      >
        <CustomAccordion
          id={idTarget}
          key={`key_${idTarget}`}
          enableChild
          defaultExpanded={relationIndex === 0}
          // if relation data has not lastRequestId, it means that this relation never requested data at all
          // its possible when the relations was not in first index and never has been opened
          onChangeCallback={
            relationData?.lastRequestId ? undefined : refreshRelation
          }
          summary={
            <RelationPanelSummaryView
              relationType={relationType}
              relationItemInMetaData={relationItemInMetaData}
              relationResource={relationResource}
              parentResource={parentResource}
              relationPermission={relationPermission}
              relationPath={relationPath}
              currentUrl={currentUrl}
              relationMetaData={relationMetaData}
              relationData={relationData}
              parentInfo={parentInfo}
              parentRecord={parentRecord}
              clearAllSelectedCheckboxes={clearAllSelectedCheckboxes}
              refreshRelation={refreshRelation}
              hideActions={!!relationData?.error}
              handleFullScreen={handleFullScreen}
            />
          }
        >
          {relationMetaData && relationData?.lastRequestId ? (
            <ViewComponent relationPanelBaseProps={relationPanelBaseProps} />
          ) : metaDataError ? (
            <NotFound title={metaDataError} hideBackButton />
          ) : relationData?.error ? (
            <NotFound
              title={relationData.error}
              hideBackButton
              style={{ marginBottom: 10 }}
            />
          ) : (
            <LoadingBox />
          )}
        </CustomAccordion>
      </div>
    );
  };

  return View();
};

export default memo(RelationPanelView, (prevProps, nextProps) => {
  return (
    prevProps.relationPanelBaseProps.relationType ===
      nextProps.relationPanelBaseProps.relationType &&
    prevProps.relationPanelBaseProps.basePropsVersion ===
      nextProps.relationPanelBaseProps.basePropsVersion &&
    prevProps.key === nextProps.key
  );
});
