import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(() => ({
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '-webkit-fill-available',
    maxWidth: '-moz-available', // firefox will ignore webkit, so it can be used for firefox
  },
  gridContainer: { padding: 10, width: '100%', margin: 'auto', height: 700 },
}));
