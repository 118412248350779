import { useCallback, useState } from 'react';

import SideBarSettingsView from './sidebar-settings.view';
import { actorDispatch } from '../../../../type/actor-setup';

import type { ReactElement, MouseEvent } from 'react';

const SideBarSettingsController = (): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  /**
   * @function handleClick
   * @param { MouseEvent<HTMLElement> } event
   * @returns { void } void
   */
  const handleClick = useCallback((event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  }, []);

  /**
   * @function handleClose
   * @param { MouseEvent<HTMLElement> } event
   * @returns { void } void
   */
  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  /**
   * @function openCreateGroupDialog
   * @param { MouseEvent<HTMLElement> } event
   * @param { number } IsChannel
   * @returns { () => void } void
   */
  const openCreateGroupDialog = useCallback(
    (IsChannel = 0) =>
      (): void => {
        actorDispatch('quickDialog', {
          chatCreateGroupDialogIsOpen: true,
          data: { IsChannel },
        });
        handleClose();
      },
    [],
  );

  return (
    <SideBarSettingsView
      openCreateGroupDialog={openCreateGroupDialog}
      handleClick={handleClick}
      handleClose={handleClose}
      anchorEl={anchorEl}
    />
  );
};

export default SideBarSettingsController;
