import { GET_LIST } from 'react-admin';
import dataProvider from '../core/dataProvider';
import { showNotification } from '../helper/general-function-helper';
import { getDefaultReportSort } from '../helper/MetaHelper';
import { actorOnDispatch } from './../type/actor-setup';

actorOnDispatch(
  'getPrintReportData',
  async action => {
    const { successCallback, failureCallback, metaData, reportId, filterValues } =
      action;

    const sort = getDefaultReportSort(metaData, reportId);
    try {
      const response = await dataProvider(GET_LIST, `report/${reportId}`, {
        filter: filterValues,
        sort: sort,
        pagination: { page: 1, perPage: 10000 },
        queryParams: {
          putCaptionInHeader: true,
        },
      });

      successCallback?.(response.data);
    } catch (error) {
      showNotification(new Error(error as string).message, 'error');
      failureCallback?.(error);
    }
  },
  {
    preserve: false,
  },
);
