import { ROW_STATE_COLOR_FILTER } from '../../core/configProvider';
import { clone } from '../../helper/data-helper';

import type { FilterItemBaseType } from '../filter-form';

/**
 * @function addColorFilterToFilters
 * @param {(string | string[])[]} currentFilters
 * @returns {void} void
 */
export const addColorFilterToFilters = (
  currentFilters: (string | FilterItemBaseType)[] | null | undefined,
  filterValue: string,
): (string | FilterItemBaseType)[] => {
  let updatedFilters: (string | FilterItemBaseType)[] = [];
  if (Array.isArray(currentFilters)) {
    updatedFilters = clone(currentFilters);
  }

  const finalFilterValue: FilterItemBaseType = [
    ROW_STATE_COLOR_FILTER.KEY,
    ROW_STATE_COLOR_FILTER.OPERATOR,
    filterValue,
  ];

  if (!Array.isArray(updatedFilters) || updatedFilters.length === 0) {
    updatedFilters = [finalFilterValue as FilterItemBaseType];
  } else {
    const targetIndex = updatedFilters.findIndex(
      item => Array.isArray(item) && item[0] === ROW_STATE_COLOR_FILTER.KEY,
    );

    if (targetIndex > -1) {
      // Replace the new value
      updatedFilters[targetIndex] = finalFilterValue;
    } else {
      updatedFilters.push('and', finalFilterValue);
    }
  }

  return updatedFilters;
};
