import { CSSProperties } from 'react';

/**
 * getDirectionStyle
 * @param value {number | string}
 * @returns {CSSProperties | undefined}
 */
export const getDirectionStyle = (
  value: number | string,
): CSSProperties | undefined => {
  //example "123456" or "123 456"
  if (typeof value === 'string' && !isNaN(Number(value.replace(/\s/g, '')))) {
    return { direction: 'ltr' } as CSSProperties;
  }
  return undefined;
};
