import type { DropdownOption } from '../component/filter/autocomplete-filter-input';

/**
 * this function find selected option
 *@function getTextSelectedOption
 * @param {DropdownOption[]} {preparedOptions}
 * @param {string} {key}
 * @returns {string | undefined}
 */
export const getTextSelectedOption = (
  preparedOptions: DropdownOption[],
  key: string,
): string | undefined => {
  const selectedOption = preparedOptions.find(ite => ite.value === key);
  if (selectedOption) {
    if (selectedOption.text) {
      return selectedOption.text;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

/**
 * Get value form new object in onChange AutocompleteInput
 * @function handleChange
 * @param {object[]} {array}
 * @returns {string}
 */
export const convertToStringValue = (array: object[]): string => {
  return array.map(item => (typeof item === 'object' ? item['value'] : item)).join();
};
