import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { useLocale } from 'react-admin';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import HeightIcon from '@material-ui/icons/Height';

import { useStyles } from './mail-fab.style';
import { MailFabViewInterface } from './mail-fab.type';

const MailFabView = (props: MailFabViewInterface): ReactElement => {
  const { mail, onClickRemove, onClickOpen } = props;

  const locale = useLocale();
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Fab
      data-test="mail-fab"
      className={classes.fab}
      aria-label="open"
      size="medium"
    >
      <Box
        onClick={onClickOpen(mail)}
        className={
          locale == 'fa' || locale == 'ar'
            ? classes.fabDetail
            : classes.fabDetailLeft
        }
      >
        <div className={classes.mainIcon}>
          <Box
            data-test="mail-fab-height-button"
            className={classes.fabIconContainer}
          >
            <HeightIcon className={classes.fabIconHeight} />
          </Box>
          <ClearIcon
            data-test="mail-fab-clear-icon"
            onClick={onClickRemove(mail)}
            className={classes.fabIcon}
          />
        </div>
        <div>
          <Typography
            noWrap
            className={
              locale == 'fa' || locale == 'ar'
                ? classes.textFabMail
                : classes.textFabMailLeft
            }
          >
            {mail.formData.subject ??
              mail.formData.LetterParaph ??
              translate('mail.newMessage')}
          </Typography>
        </div>
      </Box>
    </Fab>
  );
};
export default MailFabView;
