import { isEmpty } from '../../../../helper/data-helper';
import { getFractionDigitCountFromFormat } from '../../../../helper/NumberHelper';
import { FieldType } from '../../../../helper/Types';

/**
 * this helper function handle - char in input
 * @function checkNumberForMinus
 * @param input string | number
 * @returns string
 */
export const checkNumberForMinus = (input: string | number): string => {
  if (!input) {
    return input.toString();
  }
  if (/([a-z]|[A-z])+/g.test(input.toString())) {
    return '';
  }
  const amount = input.toString();
  const isNegative = amount.indexOf('-') === 0;
  if (amount.length === 1) {
    return amount;
  }
  let result = amount;
  if (isNegative && amount[1] !== '-') {
    result = '-' + amount.substring(1).split('-').join('000');
  } else if (!isNegative && amount.length >= 1) {
    result = amount.split('-').join('000');
  }
  return result;
};

/**
 * @function formatNumber
 * @param {number} value
 * @param {Intl.NumberFormatOptions} options
 * @returns {string}
 */
export const formatNumber = (
  value: number | string,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 20 },
): string => {
  return new Intl.NumberFormat('en-US', options).format(Number(value));
};

/**
 * this function convert negative number to Parentheses and check format value
 *@function wrapNegativeNumberWithParentheses
 *@param {string} value
 *@return {string}
 */
export const wrapNegativeNumberWithParentheses = (value: string): string => {
  const numberValue = Number(value?.toString().replaceAll(',', ''));
  const preparedValue: string | number = value.toString()?.replace('-', ''); //don't use Math.abs(RCT-3472);
  if (isNaN(Number(numberValue))) return '0';

  return numberValue >= 0 ? preparedValue : `(${preparedValue})`;
};

/**
 *@function applyFormatNumber
 *@param {FieldType} field
 *@param {number | string} value
 *@param {boolean} separateThousand
 *@return {string | number}
 */
export const applyFormatNumber = (
  field: FieldType,
  value: number | string,
  separateThousand = true,
): string | number => {
  if (value == null) {
    return '';
  }

  if (Number.isNaN(+value)) {
    return value;
  }

  let preparedValue: number | string = value;

  //get option from field
  const fractionDigitsCount = !isEmpty(field.precision)
    ? field.precision
    : getFractionDigitCountFromFormat(field.format) || 20;

  //format number with options
  const formatOptions = {
    maximumFractionDigits: fractionDigitsCount,
    useGrouping: separateThousand,
  };
  preparedValue = !isEmpty(field.format)
    ? formatNumber(preparedValue, formatOptions)
    : preparedValue.toString();

  //wrap parentheses
  preparedValue = wrapNegativeNumberWithParentheses(preparedValue);

  //return value
  return preparedValue;
};
