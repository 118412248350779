import {
  FC,
  memo,
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import lodashDebounce from 'lodash/debounce';
import lodashOrderBy from 'lodash/orderBy';

import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import StarOutlineOutlinedIcon from '@material-ui/icons/StarOutlineOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';

import TodoTasksView from './todo-tasks.view';
import NotFound from '../../NotFound';
import LoadingBox from '../../LoadingBox';
import {
  clone,
  findMaxNumberInArrayByKey,
  isEmpty,
} from '../../../helper/data-helper';
import { showNotificationForUnknownError } from '../../../helper/general-function-helper';

import {
  getTasksFromApi,
  getTodoTaskFolderId,
  getUniqueIdFromUrl,
} from '../todo-section.helper';
import { FolderName } from '../../mail-section/mail-section.helper';
import { getParamFromUrl } from '../../../helper/UrlHelper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  URLInfo,
} from '../../../type/actor-setup';

import { useStyles } from './todo-tasks.style';

import { shareListResource } from '../../dialogs-stack/todo-share-dialog/todo-share-dialog.helper';
import type { GetTasksFromApiVariants } from '../todo-section.type';
import type { TodoTaskItemInterface } from './todo-task-item/todo-task-item.type';
import type {
  LocalSortTaskListInterface,
  SelectedTaskInterface,
} from './todo-tasks.type';

const TodoTasksController: FC = () => {
  const translate = useTranslate();
  const classes = useStyles();

  const [, render] = useReducer(p => !p, false);

  const localSortTaskList: LocalSortTaskListInterface[] = [
    {
      name: 'isimportant',
      displayName: translate('todo.IsImportant'),
      icon: (
        <StarOutlineOutlinedIcon fontSize="small" className={classes.iconSortList} />
      ),
    },
    {
      name: 'detailtitle',
      displayName: translate('todo.nameTask'),
      icon: <DnsOutlinedIcon fontSize="small" className={classes.iconSortList} />,
    },
    {
      name: 'enddate',
      displayName: translate('todo.dueDate'),
      icon: (
        <TimelineOutlinedIcon fontSize="small" className={classes.iconSortList} />
      ),
    },
    {
      name: 'createdate',
      displayName: translate('todo.createDate'),
      icon: (
        <OpenInNewOutlinedIcon fontSize="small" className={classes.iconSortList} />
      ),
    },
  ];

  const [tasks, setTasks] = useState<TodoTaskItemInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTask, setSelectedTask] = useState<SelectedTaskInterface>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAscendingOrder, setIsAscendingOrder] = useState<boolean>(false);
  const [shareList, setShareList] = useState<Record<string, unknown>[]>([]);

  const location = useLocation();

  const displayNameRef = useRef<string>('');
  const sortNameRef = useRef<string>('');

  const actorActionsRef = useRef<
    { actionName: keyof ActorActionList; id: symbol }[]
  >([]);

  const hasErrorRef = useRef<boolean>(false);
  const tasksRef = useRef<{
    completedTasks: TodoTaskItemInterface[];
    unCompletedTasks: TodoTaskItemInterface[];
  }>({ completedTasks: [], unCompletedTasks: [] });

  const completedTasks = tasks?.filter(task => task.isdone);

  const unCompletedTasks = tasks?.filter(task => !task.isdone);

  tasksRef.current.completedTasks = sortNameRef.current
    ? completedTasks
    : completedTasks.sort(
        (prevTask, nextTask) =>
          Number(prevTask.roworder) - Number(nextTask.roworder),
      );

  tasksRef.current.unCompletedTasks = sortNameRef.current
    ? unCompletedTasks
    : unCompletedTasks.sort(
        (prevTask, nextTask) =>
          Number(prevTask.roworder) - Number(nextTask.roworder),
      );

  useEffect(() => {
    const activeTask = actorGetActionValue('selectedTodoTask');

    if (!activeTask && tasks.length > 0) {
      const url = location?.search;
      const getUniqueFromUrl = getUniqueIdFromUrl(url)?.[1];
      const selectedTaskInfo = tasks?.find(
        item => item.uniqueid == getUniqueFromUrl,
      );
      actorDispatch('selectedTodoTask', selectedTaskInfo);
    }
  }, [tasks]);

  useEffect(() => {
    actorOnDispatch('refreshView', detail => {
      if (detail == 'todo') {
        const url = actorGetActionValue('urlInfo')!;
        getNewTaskWithVariant(url);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedTask === undefined) return;

    const folderId = selectedTask?.folderId ?? getTodoTaskFolderId(location.hash);
    actorDispatch(
      'crudAction',
      {
        entity: 'todo',
        type: 'GET_LIST',
        resource: `report/${shareListResource}`,
        requestParameters: {
          filter: [['folder_id', '=', String(folderId ?? '')]],
        },
        onSuccess: response => {
          actorSetActionValue('todoShareList', response.data);
          setShareList(response.data ?? []);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'TodoTasksController => useEffect(..., [selectedTask])',
      },
    );
  }, [selectedTask]);

  useEffect(() => {
    let id = actorOnDispatch(
      'todoTaskList',
      (data: TodoTaskItemInterface[]) => {
        setTasks(data);
        hasErrorRef.current = false;
        setIsLoading(false);
      },
      { preserve: false },
    );
    actorActionsRef.current.push({ actionName: 'todoTaskList', id });

    id = actorOnDispatch(
      'offlineRefreshView',
      detail => {
        if (detail?.entity !== 'todoTasks') return;
        if (detail.mode === 'update') {
          offlineReplaceNewTaskToState(detail.newData as TodoTaskItemInterface);
        } else if (detail.mode === 'create') {
          offlineAddNewTaskToState(detail.newData as TodoTaskItemInterface);
        }
      },
      { preserve: false },
    );
    actorActionsRef.current.push({ actionName: 'offlineRefreshView', id });

    id = actorOnDispatch(
      'urlInfo',
      lodashDebounce((urlInfo: URLInfo): void => {
        const activeTask = actorGetActionValue('selectedTodoTask');

        if (!activeTask) {
          if (
            urlInfo.location.search.includes('uniqueid') &&
            // it cause update task list only when folder id change
            getTodoTaskFolderId(urlInfo?.location?.search) === // its current location
              getTodoTaskFolderId(window.location.href) // its prevues location
          ) {
            getNewTaskWithVariant(urlInfo);
          }
        }
        if (!urlInfo.location.search.includes('uniqueid')) {
          actorDispatch('selectedTodoTask', '', { replaceAll: true });
          getNewTaskWithVariant(urlInfo);
        }
      }, 1000),
    );
    actorActionsRef.current.push({ actionName: 'urlInfo', id });

    id = actorOnDispatch('selectedOaFolder', detail => {
      setSelectedTask(detail as unknown as SelectedTaskInterface);
    });
    actorActionsRef.current.push({ actionName: 'selectedOaFolder', id });

    return () => {
      for (const item of actorActionsRef.current) {
        const { actionName, id } = item;
        actorRemoveAction({
          actionName,
          listenerId: id,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(sortNameRef.current) && !isEmpty(displayNameRef.current)) {
      handleTodoTasksSort(sortNameRef.current, displayNameRef.current)();
    }
  }, [isAscendingOrder]);

  const getNewTaskWithVariant = (urlInfo?: URLInfo): void => {
    // setIsLoading(true);

    if (urlInfo?.location.search.includes('module=todo&id')) {
      getTasksFromApi({
        failureCallback: onGetTaskFailure,
      });
    } else {
      const folderName: string =
        getParamFromUrl(urlInfo?.location.search as string, FolderName) ?? '';

      getTasksFromApi({
        variant: folderName as GetTasksFromApiVariants,
        failureCallback: onGetTaskFailure,
      });
    }
  };
  /**
   * show notification for error
   * @function onGetTaskFailure
   * @param {unknown} error
   * @returns {void} void
   */
  const onGetTaskFailure = (error: unknown): void => {
    if (error) {
      showNotificationForUnknownError(error, translate);
      hasErrorRef.current = true;
    }
    setIsLoading(false);
  };

  /**
   * replace new item with prev item in state
   * @function offlineReplaceNewTaskToState
   * @param {TodoTaskItemInterface} newTask
   * @returns {void} void
   */
  const offlineReplaceNewTaskToState = (newTask: TodoTaskItemInterface): void => {
    const prepareTasks = actorGetActionValue('todoTaskList')?.map(task => {
      return task.uniqueid === newTask.uniqueid ? newTask : task;
    });

    actorSetActionValue('todoTaskList', prepareTasks);
    setTasks(prepareTasks ?? []);

    const currentSelectedTaskId = actorGetActionValue('selectedTodoTask')?.uniqueid;

    if (currentSelectedTaskId) {
      actorDispatch('selectedTodoTask', newTask, { replaceAll: true });
    }
  };

  /**
   * add new item to prev state
   * @function offlineAddNewTaskToState
   * @param {TodoTaskItemInterface} newTask
   * @returns {void} void
   */
  const offlineAddNewTaskToState = (newTask: TodoTaskItemInterface): void => {
    const todoTasks = actorGetActionValue('todoTaskList') ?? [];

    const newTasks = clone(todoTasks);
    newTasks.unshift(newTask);

    actorSetActionValue('todoTaskList', newTasks);
    setTasks(newTasks);
  };

  /**
   * @function handleOpenMenuListNameSort
   * @param {string} event
   * @returns {void} void
   */
  const handleOpenMenuListNameSort = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * @function handleMenuListSortClose
   * @returns { void } void
   */
  const handleMenuListSortClose = (): void => {
    setAnchorEl(null);
  };

  /**
   * @function handleTodoTasksSort
   * @param { string } name
   * @param {string} displayName
   * @returns { void } void
   */
  const handleTodoTasksSort = (name: string, displayName: string) => (): void => {
    displayNameRef.current = displayName;
    sortNameRef.current = name;

    const sortedTasks = lodashOrderBy(tasks, name, [
      isAscendingOrder ? 'asc' : 'desc',
    ]);

    actorDispatch('todoTaskList', sortedTasks);
    handleMenuListSortClose();
  };

  /**
   * @function toggleOrder
   * @returns { void } void
   */
  const toggleOrder = (): void => {
    setIsAscendingOrder(prev => !prev);
  };

  /**
   * @function handleRemoveSort
   * @returns { void } void
   */
  const handleRemoveSort = (): void => {
    displayNameRef.current = '';
    sortNameRef.current = '';
    render();
  };

  /**
   * @function openShareDialog
   * @returns { void } void
   */
  const openShareDialog = useCallback((): void => {
    actorDispatch('quickDialog', {
      todoShareDialogIsOpen: true,
      data: {
        folderId: selectedTask?.folderId,
      },
    });
  }, [selectedTask]);

  /**
   * @function openAssignTaskDialog
   * @returns { void } void
   */
  const openAssignTaskDialog = useCallback((): void => {
    actorDispatch('quickDialog', {
      todoAssignTask: true,
      data: {
        folderId: selectedTask?.folderId,
      },
    });
  }, [selectedTask]);

  if (hasErrorRef.current)
    return <NotFound title={translate('ra.notification.data_provider_error')} />;

  if (isLoading) return <LoadingBox />;

  return (
    <TodoTasksView
      handleOpenMenuListNameSort={handleOpenMenuListNameSort}
      handleMenuListSortClose={handleMenuListSortClose}
      handleTodoTasksSort={handleTodoTasksSort}
      handleRemoveSort={handleRemoveSort}
      openShareDialog={openShareDialog}
      toggleOrder={toggleOrder}
      localSortTaskList={localSortTaskList}
      isAscendingOrder={isAscendingOrder}
      displayNameRef={displayNameRef}
      selectedTask={selectedTask}
      tasks={tasksRef.current}
      anchorEl={anchorEl}
      shareList={shareList}
      openAssignTaskDialog={openAssignTaskDialog}
    />
  );
};

export default memo(TodoTasksController);
