import { ReactElement } from 'react';
import { AppContainerViewInterface } from './app-container.type';
import { useStyles } from './app-container.style';
import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { AppSidebar } from './app-sidebar';

const AppContainerView = (props: AppContainerViewInterface): ReactElement => {
  const {
    title = '',
    cardStyle,
    sidebarContent,
    isAppDrawerOpen,
    onToggleDrawer,
    onCloseDrawer,
    fullView = false,
    children,
    ...rest
  } = props;
  const classes = useStyles({ fullView });

  return (
    <Box
      {...rest}
      px={{ xs: 0, md: 2 }}
      pt={{ xs: 0, md: 4 }}
      flex={1}
      display="flex"
      flexDirection="column"
      style={{ maxHeight: '94%' }}
      data-style-box-container="boxContainer"
    >
      <Box position="absolute" right={0} top={40}>
        {fullView ? null : (
          // @ts-ignore
          <Hidden lgUp>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={onToggleDrawer}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Hidden>
        )}
        <Box component="h2" color="text.primary" fontWeight={500} fontSize={16}>
          {title}
        </Box>
      </Box>

      <Box className={classes.appsContainer}>
        {sidebarContent ? (
          <AppSidebar
            isAppDrawerOpen={isAppDrawerOpen}
            onCloseDrawer={onCloseDrawer}
            fullView={fullView}
            sidebarContent={sidebarContent}
            cardStyle={cardStyle}
          />
        ) : null}

        <Box className={classes.appsMainContent}>
          <Card
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              overflow: 'auto',
              ...cardStyle,
              background: 'unset',
            }}
          >
            {children}
          </Card>
        </Box>
      </Box>
    </Box>
  );
};
export default AppContainerView;
