import { ReactElement, cloneElement } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStyles } from './custom-accordion.style';
import type { CustomAccordionViewInterface } from './custom-accordion.type';

const CustomAccordionView = (props: CustomAccordionViewInterface): ReactElement => {
  const {
    checkPropagation,
    onChange,
    customSummaryContainerClass = '',
    customContainerClass = '',
    customSummaryClass = '',
    customDetailClass = '',
    defaultExpanded = true,
    enableChild = false,
    customStyles,
    children,
    summary,
    expand,
    showFullScreen,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.customAccordionView}
      data-style-custom-accordion="accordionView"
      style={customStyles}
    >
      <Accordion
        expanded={showFullScreen ? true : expand}
        className={customContainerClass}
        classes={{
          root: classes.accordionRoot,
        }}
        square
        defaultExpanded={defaultExpanded}
        onChange={onChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          data-style-accordion="summery"
          data-test-accordion="summery"
          className={customSummaryContainerClass}
          classes={{
            root: ` ${classes.contentRoot}`,
          }}
        >
          <div
            data-style-accordion-summery="div"
            data-tag="accordion-summuary"
            className={`${customSummaryClass} ${classes.content}`}
            onClick={checkPropagation}
          >
            {summary &&
              cloneElement(summary, {
                expand,
              })}
          </div>
        </AccordionSummary>
        <AccordionDetails
          data-style-accordion-detail="detail"
          className={`${customDetailClass} ${
            showFullScreen
              ? classes.AccordionDetailsFullScreen
              : classes.AccordionDetails
          }`}
        >
          {enableChild || expand
            ? cloneElement(children, {
                expand,
              })
            : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordionView;
