import lodashSet from 'lodash/set';
import lodashGet from 'lodash/get';
import lodashFindIndex from 'lodash/findIndex';
import { isEmpty } from './data-helper';
import { replaceArabicCharacters } from './TextHelper';
import { clone, isEmptyObject } from './data-helper';
import { TreeRow } from './Types';

type CreateTreeDataListType = (
  sortedData: TreeRow[],
  selectedId: string | number | undefined,
  treeLevel: number,
  displayField: string,
  isDropdown?: boolean,
  idPattern?: string,
) => TreeRow[];

interface RowItems {
  title?: string;
  id: string | number;
  compositid: string;
}

/**
 * This function will receive an array of sorted data as flat, it items has not composite id
 * they are parent else should split their composite id by "#" and put the items that belong
 * to their parents to "items" property of them in the format of Array.
 * @function createTreeDataList
 * @param {Array<object>} sortedData
 * @param {string | number | undefined} selectedId
 * @param {number | null} treeLevel
 * @param {string} displayField
 * @param {boolean} isDropdown
 * @param {string} idPattern
 * @returns {Array<object>} tree
 */
export const createTreeDataList: CreateTreeDataListType = (
  sortedData,
  selectedId,
  treeLevel,
  displayField,
  isDropdown = false,
  idPattern = '',
): TreeRow[] => {
  const tree: TreeRow[] = [];
  let customTreeLevel = treeLevel;

  if (customTreeLevel === 0) {
    const RowsCurrentLevel = sortedData.map(row => row.currentlevel);
    customTreeLevel = Math.max(...RowsCurrentLevel);
  }

  if (!sortedData.length) return tree;

  for (let i = 0; i <= customTreeLevel; i++) {
    const rowsInThisLevel: TreeRow[] = sortedData.filter(
      row => row.currentlevel === i,
    );

    if (rowsInThisLevel.length) {
      rowsInThisLevel.forEach(row => {
        const { id, compositid }: RowItems = row;
        const title = replaceArabicCharacters(lodashGet(row, displayField));

        if (i === 0) {
          if (isEmpty(compositid)) {
            tree.push({
              ...row,
              text: title,
              id: `${id}`,
              items: [],
              selected: isDropdown
                ? row[idPattern] == selectedId
                : row.id == selectedId,
              expanded: isDropdown
                ? row[idPattern] == selectedId
                : row.id == selectedId,
            });

            return;
          }
        } else {
          const indexPath: (string | number)[] = [];
          compositid?.split('#').forEach(id => {
            if (!id) {
              return;
            }

            const index = lodashFindIndex(
              indexPath.length ? lodashGet(tree, indexPath) : tree,
              {
                id,
              },
            );

            if (index === -1) {
              return;
            }

            indexPath.push(index);
            indexPath.push('items');
          });

          let items: TreeRow[] = lodashGet(tree, indexPath);

          if (!items) {
            items = [];
          }

          items.push({
            ...row,
            text: title,
            id: `${id}`,
            items: [],
            selected: isDropdown
              ? row[idPattern] == selectedId
              : row.id == selectedId,
            expanded: isDropdown
              ? row[idPattern] == selectedId
              : row.id == selectedId,
          });

          indexPath.length !== 0
            ? lodashSet(tree, indexPath, items)
            : tree.push(...items);
        }
      });
    }
  }

  return tree;
};

/**
 * Support search with arabic characters in tree
 * @function handleSpecialChars
 * @param { title: string } item A tree item contains `title` key with a string value
 * @returns {TreeRow}
 */
export const handleSpecialChars = (item: TreeRow): TreeRow => {
  if (isEmptyObject(item) || (!isEmptyObject(item) && !item.title)) {
    return item;
  }

  const mirror = clone(item);

  mirror.title = replaceArabicCharacters(item.title);

  return mirror;
};
