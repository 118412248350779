import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';

import { prepareAxisChartData } from './line-chart.helper';
import { isEmpty } from '../../../helper/data-helper';

import { useEffect, type FC, useState } from 'react';
import { getNumericFormat } from '../dynamic-chart.helper';
import LineChartView from './line-chart.view';
import type { LineChartControllerProps } from './line-chart.type';
import type { ItemChart } from '../bar-chart';

const LineChartController: FC<LineChartControllerProps> = props => {
  const {
    reportData,
    chartOptions,
    width,
    height,
    handleChartItemClick,
    reportColumns,
    metaData,
  } = props;
  const [numericFormat, setNumericFormat] = useState<string | null>(null);

  const axisBottomLabelFieldName = chartOptions.axisBottomLabelFieldName;
  const axisLeftLabelFieldName = chartOptions.axisLeftLabelFieldName;
  const translate = useTranslate();

  //in old dashboard version valueFieldName was array and maybe we have crash
  const finalValueFieldName = Array.isArray(chartOptions.valueFieldName)
    ? chartOptions.valueFieldName[0]
    : chartOptions.valueFieldName;

  const compatibleData = prepareAxisChartData({
    data: reportData,
    columns: metaData?.columns,
    ...chartOptions,
    valueFieldName: [finalValueFieldName],
  });

  useEffect(() => {
    if (metaData) {
      setNumericFormat(getNumericFormat(metaData, [finalValueFieldName]));
    }
  }, [finalValueFieldName, chartOptions?.idFieldName, metaData?.reportId]);

  /**
   * @function handleClickLineChartItem
   * @param {ItemChart} data
   * @returns {void} void
   */
  const handleClickLineChartItem = (data: ItemChart): void => {
    if (!isEmpty(data.point?.y)) {
      handleChartItemClick?.(chartOptions.axisFieldName, data.point?.y);
    }
  };

  if (
    !compatibleData?.x?.some(x => x) ||
    !compatibleData?.y?.[0]?.data?.some(i => i)
  ) {
    return (
      <Typography variant="h5" color="secondary">
        {translate('general.noData')}
      </Typography>
    );
  }

  return (
    <LineChartView
      compatibleData={compatibleData}
      width={width}
      height={height}
      bottomLegend={axisBottomLabelFieldName as string}
      leftLegend={axisLeftLabelFieldName as string}
      handleClickLineChartItem={handleClickLineChartItem}
      shouldThousandSeparate={!isEmpty(numericFormat)}
    />
  );
};

export default LineChartController;
