import { ReactElement } from 'react';
import { FileInputViewInterface } from './file-input.type';
import { useStyles } from './file-input.style';
import { default as InputBaseLabel } from '../../input-base';
import { isEmpty } from '../../../helper/data-helper';
import { apiUrl } from '../../../core/data-Provider.helper';
import { hasPrefix } from './file-input.helper';

const FileInputWmsView = (props: FileInputViewInterface): ReactElement => {
  const {
    dropRef,
    disabled,
    label,
    hint,
    resource,
    required,
    name,
    handleClick,
    imageSrc,
    fileName,
    inputMessage,
    calcHeight,
    visibleClass,
  } = props;
  const classes = useStyles({ calcHeight, messageType: inputMessage?.messageType });

  return (
    <InputBaseLabel
      className={`${visibleClass}`}
      label={label}
      hint={hint}
      required={required}
      inputMessage={inputMessage}
      fullWidth
    >
      <fieldset
        ref={dropRef}
        className={`${classes.root} ${
          inputMessage?.message ? classes.fieldsetMessage : ''
        } ${disabled && classes.disabled}`}
        data-test-field-name={`${resource}/${name}`}
        disabled={disabled}
      >
        <div className={classes.wrap}>
          <div className={classes.inputFileValueName} onClick={handleClick}>
            {!isEmpty(fileName) && (
              <img
                className={classes.previewWmsImage}
                src={
                  hasPrefix(String(fileName))
                    ? String(fileName)
                    : `${apiUrl}/${fileName}`
                }
              />
            )}
          </div>
        </div>
      </fieldset>
    </InputBaseLabel>
  );
};

export default FileInputWmsView;
