import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  itemContainer: {
    width: '100%',
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    paddingLeft: 11,

    '&:hover': {
      background: theme.palette.todoHoveredItem,
    },
  },

  body: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexGrow: 1,
  },

  value: {
    fontSize: '0.8rem',
  },

  titleAndValue: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 10px 0 10px',
    alignItems: 'center',
  },

  dayPicker: { width: '100%' },
  todoItemText: {
    fontSize: '1rem',
    color: theme.palette.todoLightGray,
    fontWeight: 400,
  },
  todoSidebarSelectedIcon: {
    color: theme.palette.todoLightGray,
  },
  sidebarTodoIcon: {
    color: theme.palette.secondary.main,
  },
  confirmButton: {
    background: theme.palette.primary.automationBackgroundConfirmButton,
  },

  inputCustomWeek: {
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    marginTop: 10,
  },
  changePadding: {
    padding: 10,
  },
  active: {
    minWidth: 56,

    borderBottom: `1px solid ${theme.palette.primary.activeButton}`,
    borderTop: `1px solid ${theme.palette.primary.activeButton}`,
    borderRadius: '0',
    background: theme.palette.primary.activeButton,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.activeButton,
    },
    padding: 5,
  },

  notActive: {
    padding: 5,
    borderRadius: '0',
    minWidth: 56,
    borderBottom: `1px solid ${theme.palette.primary.noneActiveButton}`,
    borderTop: `1px solid ${theme.palette.primary.noneActiveButton}`,

    background: theme.palette.secondary.contrastText,
    color: theme.palette.primary.noneActiveButton,
    '&:hover': {
      backgroundColor: theme.palette.secondary.contrastText,
    },
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.primary.noneActiveButton}`,
      borderBottomRightRadius: 4,
      borderTopRightRadius: 4,
    },
    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.primary.noneActiveButton}`,
      borderBottomLeftRadius: 4,
      borderTopLeftRadius: 4,
    },
  },
  defaultColor: {
    background: theme.palette.primary.activeButton,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.activeButton,
    },
  },
}));
