import moment, { MomentInput } from 'moment';
import jmoment, { Moment } from 'moment-jalaali';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import { isEmpty } from '../../../../helper/data-helper';
import { createDateWithType, parseDate } from '../../../../helper/DateHelper';
import { showNotificationForUnknownError } from '../../../../helper/general-function-helper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../../type/actor-setup';
import { editTodoTask } from '../../todo-section.helper';
import TodoDateChooserView from './todo-date-chooser.view';

import {
  CONFIG_CALENDAR,
  CONFIG_LOCALE,
  getValue,
} from '../../../../core/configProvider';
import type { TodoTaskItemInterface } from '../../todo-tasks/todo-task-item/todo-task-item.type';
import type {
  TodoContextMenuInterface,
  TodoDateChooserControllerInterface,
} from './todo-date-chooser.type';

const TodoDateChooserController: FC<TodoDateChooserControllerInterface> = memo(
  props => {
    const { iconName, title, taskFiledName, isMinimized, withTime } = props;
    const DATE_FORMAT = withTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
    const localeConfig = getValue<'fa' | 'en'>(CONFIG_LOCALE);
    const calendarConfig = getValue<'jalali' | 'gregorian'>(CONFIG_CALENDAR);
    const todoSelectedTaskInfoRef = useRef<TodoTaskItemInterface | null>(null);
    const [internalValue, setInternalValue] = useState<string | null>(null);
    const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
    const [contextMenuDetails, setContextMenuDetails] =
      useState<TodoContextMenuInterface>(null);

    const translate = useTranslate();

    useEffect(() => {
      const id = actorOnDispatch('selectedTodoTask', taskInfo => {
        //if task is in minimized status we don't need actor
        if (isMinimized) return;
        const dateValue = taskInfo?.[taskFiledName] ?? null;
        todoSelectedTaskInfoRef.current = taskInfo;
        setInternalValue(dateValue);
      });

      const offlineRefreshListenerId = actorOnDispatch(
        'offlineRefreshView',
        detail => {
          if (detail?.entity !== 'todoTasks') return;
          if (detail?.mode === 'create' && isMinimized) {
            setInternalValue(null);
          }
        },
      );

      return () => {
        actorRemoveAction({
          actionName: 'selectedTodoTask',
          listenerId: id,
        });
        actorRemoveAction({
          actionName: 'offlineRefreshView',
          listenerId: offlineRefreshListenerId,
        });
      };
    }, [taskFiledName]);

    useEffect(() => {
      saveChanges();
    }, [internalValue]);

    /**
     * @function getDefaultDateValue
     * @param {MomentInput} date
     * @returns string
     */
    const getDefaultDateValue = (date?: MomentInput) => {
      const _moment = calendarConfig === 'gregorian' ? moment : jmoment;
      if (date) {
        return _moment(date).locale(localeConfig).add(3, 'h');
      }
      return _moment().locale(localeConfig).add(3, 'h');
    };

    /**
     * @function saveChanges
     * @returns void
     */
    const saveChanges = (): void => {
      //if we are in isMinimized mode we should save changes in actor for sending it to api when user clicking on create button
      if (isMinimized) {
        const lastCreateData = actorGetActionValue('taskFormData');
        actorSetActionValue('taskFormData', {
          ...lastCreateData,
          [taskFiledName]: internalValue,
        });
        return;
      }
      if (
        isEmpty(todoSelectedTaskInfoRef.current) ||
        internalValue == todoSelectedTaskInfoRef.current?.[taskFiledName]
      ) {
        return;
      }
      editTodoTask({
        taskUID: String(todoSelectedTaskInfoRef.current?.uniqueid),
        newValues: {
          [taskFiledName]: internalValue,
        },
        failureCallback: (error: unknown) => {
          showNotificationForUnknownError(error, translate);
        },
        successCallback: result => {
          if (result.data) {
            todoSelectedTaskInfoRef.current = result.data;
          }
        },
      });
    };

    /**
     * @function handleOpenContextMenu
     * @param { React.MouseEvent } event
     * @returns { void }
     */
    const handleOpenContextMenu = (event: React.MouseEvent): void => {
      event.preventDefault();
      setContextMenuDetails(
        contextMenuDetails === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : null,
      );
    };

    /**
     * @function handleClickMenuItem
     * @param { string } selectedMenu
     * @returns { void }
     */
    const handleClickMenuItem = (selectedMenu: string): void => {
      if (selectedMenu == 'customDateTime') {
        setIsOpenCalendar(true);
      } else {
        const date = createDateWithType(selectedMenu, withTime);
        const defaultDate = getDefaultDateValue(date);
        setInternalValue(defaultDate.format(DATE_FORMAT));
      }
    };

    /**
     * @function handleChangeCalendar
     * @param { Moment | null } selectedDate
     * @returns { void }
     */
    const handleChangeCalendar = (selectedDate: Moment | null): void => {
      const date = moment(selectedDate).locale('en').format(DATE_FORMAT);
      setInternalValue(date);
    };

    /**
     * close datePicker onBlur
     * @function handleBlurCalendar
     * @returns { void }
     */
    const handleBlurCalendar = (): void => {
      setIsOpenCalendar(false);
    };

    /**
     * @function handleRemoveDate
     * @returns { void }
     */
    const handleRemoveDate = (): void => {
      setInternalValue(null);
    };

    /**
     * @function handleCloseCalendar
     * @returns { void }
     */
    const handleCloseCalendar = (): void => {
      setIsOpenCalendar(false);
    };

    /**
     * @function getFormattedValue
     * @returns { string | null }
     */
    const getFormattedValue = (): string | null => {
      return parseDate(internalValue, translate, withTime) ?? null;
    };

    const datePickerProps = {
      timePicker: !!withTime,
      defaultValue: getDefaultDateValue(),
      inputFormat: DATE_FORMAT,
      isGregorian: calendarConfig === 'gregorian',
      locale: localeConfig === 'en' ? 'en' : 'fa',
    };
    return (
      <TodoDateChooserView
        handleClickMenuItem={handleClickMenuItem}
        handleChangeCalendar={handleChangeCalendar}
        handleBlurCalendar={handleBlurCalendar}
        handleRemoveDate={handleRemoveDate}
        handleCloseCalendar={handleCloseCalendar}
        handleOpenContextMenu={handleOpenContextMenu}
        value={getFormattedValue()}
        contextMenuDetails={contextMenuDetails}
        isOpenCalendar={isOpenCalendar}
        isMinimized={isMinimized ?? false}
        iconName={iconName}
        title={title}
        datePickerProps={datePickerProps}
      />
    );
  },
);
export default TodoDateChooserController;
