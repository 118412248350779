import { alpha, makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  notifyItem: {
    padding: 0,
    '& .react-contexify__item__content': {
      padding: 0,
      margin: 0,
      borderRadius: 5,
      transition: 'all 200ms',
    },
    '&.react-contexify__item:hover > .react-contexify__item__content': {
      transition: 'all 200ms',
      backgroundColor: theme.palette.primary.appPrimaryLightBackgroundColor,
      color: 'unset',
    },
  },

  isSeen: {
    backgroundColor: theme.palette.primary.appNotificationItem,
  },

  notifytext: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.appPrimaryTextColor,
  },
  seenNotifyText: {
    fontWeight: 'bold',
  },
  createdate: {
    color: theme.palette.primary.appSecondaryTextColor,
    paddingTop: 5,
  },

  notificationCard: {
    width: 300,
    display: 'flex',
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  notificationCardBody: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    width: 1,
    alignItems: 'center',
  },

  notificationCardIcon: {
    transform: 'rotate(45deg)',
    color: theme.palette.primary.main,
  },
  avatar: {
    marginRight: 10,
  },
}));
