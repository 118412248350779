import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditButton,useTranslate } from 'react-admin';
import ChangeProcessButton from './ChangeProcessButton';
import { Icon, IconButton, withStyles, CardActions, Button, Typography } from '@material-ui/core';
import { useLocale } from 'react-admin';
import compose from 'recompose/compose';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, Popover,makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ServiceButtonContainer from '../container/ServiceButtonContainer';
import PrintButtonContainer from '../container/PrintButtonContainer';
import {
  getProcessLines,
  isRecordEditable,
  isMetaEditable,
  getSingleRecordReportRelationList,
  getRelationList,
} from '../helper/MetaHelper';
import { getProcessList, getVisibleServices } from '../helper/meta-helper';
import ShiftProcessButton from './ShiftProcessButton';
import { RelationActionTopToolbar } from './relation-action-top-toolbar';
import { PrevNextRecord } from './relation-action-top-toolbar/prev-next-record';
import { showImageDialog } from './list';
import { removeOnDispatches } from '../helper/general-function-helper';
import { actorOnDispatch } from '../type/actor-setup';


const useStyles = makeStyles(theme => ({
  cardActionStyle: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    position: 'sticky',
    zIndex: 2,
    top: (props) =>  props.hasRelation ? '94px' : '50px' , //header div height
    [theme.breakpoints.down('sm')]: {
      top: (props) =>  props.hasRelation ? '82px':'40px', //header div height
    },
    padding:0,
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.45)',
  },

  iconButton: {
    padding: 5,
    margin: '0 6px',
  },

  button: {
    border: 'none',
    margin: '0px',
    padding: '6px 5px 2px 5px',
    position: 'relative',
    top: '7px',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(38, 57, 72, 0.04)',
    },
  },

  text: {
    marginInlineStart: '5px',
    fontSize: '0.8125rem',
  },

  icon: {
    fontSize: '1rem',
  },

}));

const ShowActions = ({
  locale,
  basePath,
  data: record,
  metaData,
  resource,
  hasEdit,
  customRefresh,
  dashboardUniqueId,
  handleChangeSortMode,
  relations,
  ...rest
}) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onDispatches = [];
    const listenerId = actorOnDispatch('loading', loadingRecord => {
      setLoading(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          loadingRecord[resource] ||
          false,
      );
    });

    onDispatches.push({
      actionName: 'loading',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    }
  }, []);

  const processLineList = getProcessLines(
    metaData,
    record.__processuniqueid,
    record.positionid,
    record.stateid,
  );

  const localeLanguage = useLocale();
  const history = useHistory();
  const translate = useTranslate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

  const isEditEnabled = !loading && hasEdit && isRecordEditable(metaData, record) && isMetaEditable(metaData);

  const visibleServices = getVisibleServices(metaData, record);

  const processList = getProcessList(metaData).all;

  const isTransferEnable= !!(processList?.find?.(process => process?.uniqueId ===record?.__processuniqueid )?.tasks?.some?.(task=>task.isTransferable || task.istransferable));

  const processLineNamesTest =
    processLineList && processLineList.length
      ? Array.from(processLineList.map(processLine => processLine.title)).join(',')
      : '';

  /**
   * it will make image url and call showImageDialog with it.
   * @function getProcessSchematic
   * @returns {void}
   */
  const getProcessSchematic = () => {
    showImageDialog({
      url: `/${resource}/${record.id}/process/view`,
      isGetFileMode: true,
    });
  };

  const reportRelationList = getSingleRecordReportRelationList(metaData, {
    processuniqueid: record.__processuniqueid,
    positionid: record.positionid,
    stateid: record.stateid,
  });
  const notes = metaData.config.hasNote?[{title:metaData.config.hasNote.moduleTableTitle, id:'notes'}]: []

  const relationList = getRelationList(metaData, {
    processuniqueid: record.__processuniqueid,
    positionid: record.positionid,
    stateid: record.stateid,
  })?.map(item => {
    return {
      title: item.translatedTitle?.[localeLanguage] ?? item.moduleTableTitle,
      id: item.moduleTableName,
    };
  });
  const allSubPanelList = [...relationList, ...reportRelationList, ...notes] ;

  const classes = useStyles({hasRelation:allSubPanelList.length > 0});

  const redirectToDashboard = () => {
    history.push('/dashboard/' + dashboardUniqueId);
  };

  const handleMainMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSubMenuAnchorEl(null); // close any previously opened Submenu
  };

  const handleSubmenuClick = (event) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  return (
    <>
      <RelationActionTopToolbar
        basePath={basePath}
        record={record}
        isType="show"
        list={allSubPanelList}
        resource={resource}
      />
      <CardActions
        className={classes.cardActionStyle}
        data-test-process-lines={processLineNamesTest}
        data-style-process-lines="processLineNamesTest"
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <PrevNextRecord />
          <div>
            {record['iseditable'] &&
              processLineList?.map(line => (
                <ChangeProcessButton
                  {...rest}
                  key={line.id}
                  line={line}
                  record={record}
                  resource={resource}
                  metaData={metaData}
                />
              ))}
          </div>
        </div>
        <div>
          <>
            {resource === 'report/dashinfo' && (
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  onClick={redirectToDashboard}
                  id="showDashboard"
                >
                  <ShowChartIcon />
                </IconButton>
              )}

          {!!processList.length && (
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={getProcessSchematic}
              id="schematicViewButton"
              disabled={loading}
            >
              <Icon>map</Icon>
            </IconButton>
          )}
          {!!visibleServices && (
            <ServiceButtonContainer
              locale={locale}
              resource={resource}
              selectedIds={[record.id]}
              metaData={metaData}
              customRefresh={customRefresh}
              visibleServices={visibleServices}
            />
          )}

              <PrintButtonContainer
                locale={locale}
                resource={resource}
                selectedIds={[record.id]}
                metaData={metaData}
              />

              <EditButton
                basePath={basePath}
                record={record}
                disabled={!isEditEnabled}
                id="editActionButton"
              />

              <button className={classes.button} variant="h6" onClick={handleMainMenuClick}>
                <MoreVertIcon />
              </button>

              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem
                  className={classes.menuitem1}
                  onClick={() => {
                    handleChangeSortMode();
                    handleClose();
                  }}
                >
                  <Icon className={classes.icon} >sort</Icon>
                  <span className={classes.text}>
                    {translate('general.sortRelationPanel')}
                  </span>
                </MenuItem>

                {!!processList.length && isTransferEnable && (<MenuItem onClick={handleSubmenuClick}>
                  <Icon className={classes.icon} >perm_data_setting</Icon>
                  <span className={classes.text}>
                    {translate('grid.shiftProcess')}
                  </span>
                </MenuItem>)
                }
              </Popover>
              {subMenuAnchorEl && (<Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                anchorEl={subMenuAnchorEl}
                open={Boolean(subMenuAnchorEl)}
                onClose={handleClose}
              >
                <ShiftProcessButton
                  resource={resource}
                  metaData={metaData}
                  processList={processList}
                  recordId={record.id}
                  defaultProcessUniqueId={record.__processuniqueid}
                />

              </Menu>
            )}
          </>
        </div>
      </CardActions>
    </>
  );
};

ShowActions.defaultProps = {
  hasEdit: true,
};

ShowActions.propTypes = {
  record: PropTypes.object,
  metaData: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  hasEdit: PropTypes.bool.isRequired,
};

export default ShowActions;
