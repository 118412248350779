import { FC } from 'react';
import FilterForm from '../filter-form';
import { ActionBar } from './action-bar';

import type { ListToolbarControllerProps } from './list-toolbar.type';
import { useStyles } from './list-toolbar.style';

const ListToolbarController: FC<ListToolbarControllerProps> = props => {
  const { actionBarProps } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <ActionBar {...actionBarProps} />
      </div>
      <FilterForm
        className={classes.filterForm}
        resource={actionBarProps.rootResource ?? actionBarProps.resource}
        checkFiltersIsChangedBeforeSubmitting // To handle disable/enable of the submit button
        saveSettings
      />
    </>
  );
};

export default ListToolbarController;
