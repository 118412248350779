import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { ValidationErrorMessageType } from '../../../helper/Types';
import { getColorBaseOfStatus } from '../../input-base/input-message/input-message.helper';

export const useStyles = makeStyles<
  CustomTheme,
  { messageType: ValidationErrorMessageType | undefined; disabled?: boolean }
>(theme => ({
  error: {
    position: 'absolute',
    right: 2,
    color: props => getColorBaseOfStatus(props.messageType, theme),
  },

  booleanInputViewContainer: {
    border: props => `1px solid ${getColorBaseOfStatus(props.messageType, theme)}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    flexGrow: 1,
  },

  booleanInputViewContainerError: {
    border: props => `1px solid ${getColorBaseOfStatus(props.messageType, theme)}`,
  },

  disabled: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[700],
  },

  wmsBooleanStyle: {
    height: 'auto',
    backgroundColor: props =>
      props.disabled ? theme.palette.grey[300] : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: '0 3px',
    border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`, // Use the theme colors
    margin: '7px 3px 0',
    width: '100%',
    '& label': {
      height: '100%',
      margin: 0,
      '& span': {
        fontSize: 13,
      },
    },
    '&:hover': {
      borderColor: props =>
        props.disabled
          ? `${theme.palette.primary.appPrimaryBorderInputColor}`
          : 'unset',
    },
    '&[data-focus=true]': {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `inset 0px 0px 0px 1px ${theme.palette.primary.main}`,
    },
    '&[data-blur=true]': {
      boxShadow: 'none',
      border: `1px solid ${theme.palette.primary.appPrimaryBorderInputColor}`,
    },
    position: 'relative',
  },
}));
