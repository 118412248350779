import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  container: {
    flexDirection: 'column',
    alignItems: 'unset',
    padding: 5,
    flexShrink: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
  },

  [theme.breakpoints.up('xs')]: {
    filterForm: {
      '& > fieldset': {
        flexBasis: '48%',
        marginInlineEnd: 'unset',
      },
    },
  },
  [theme.breakpoints.up(1100)]: {
    filterForm: {
      '& > fieldset': {
        flexBasis: '47%',
      },
    },
  },
  [theme.breakpoints.up(1400)]: {
    filterForm: {
      '& > fieldset': {
        flexBasis: '24%',
      },
    },
  },
}));
