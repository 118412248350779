import { FC } from 'react';
import { Table as MaterialTable, TableBody } from '@material-ui/core';

import { TableRow } from './table-row';
import { TableRowGrid } from './table-row-grid';
import { actorGetActionValue } from '../../type/actor-setup';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../../helper/settings-helper';

import type { TableProps } from './table.type';
import type { InputAppearanceCharacteristics } from '../../helper/meta-helper.type';

export const Table: FC<TableProps> = props => {
  // prettier-ignore
  const { group, className, currentTabIndex, inputInPuzzleForm, type, record, tab, onInputStateChange } = props;
  const { layout } = group;

  const currentResource = actorGetActionValue('resources')!.current;
  const inputsInitialAppearanceCharacteristics = actorGetActionValue(
    'inputsInitialAppearanceCharacteristics',
    `${currentResource.value}.${currentResource.type}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}`,
  ) as unknown as Record<string, InputAppearanceCharacteristics> | undefined;

  return (
    <div>
      <MaterialTable className={className}>
        <TableBody>
          {layout.map((rowFields, index) => {
            return type === 'form' ? (
              <TableRow
                key={index}
                fields={rowFields}
                dynamicInputProps={{
                  currentTabIndex,
                  inputInPuzzleForm,
                  onInputStateChange,
                  groupId: String(group.id),
                }}
              />
            ) : (
              <TableRowGrid
                key={index}
                fields={rowFields}
                inputsInitialAppearanceCharacteristics={
                  inputsInitialAppearanceCharacteristics
                }
                dynamicFieldProps={{
                  record: record!,
                  tab: tab!,
                }}
                onInputStateChange={onInputStateChange as (...arge) => void}
                groupId={String(group.id)}
              />
            );
          })}
        </TableBody>
      </MaterialTable>
    </div>
  );
};
