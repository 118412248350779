import { type FC, useCallback, useEffect, useRef, useState } from 'react';

import RelationActionBarView from './relation-action-bar.view';
import { requestListData } from '../../list/list.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../type/actor-setup';
import { clone } from '../../../helper/data-helper';
import { apiRequestResultHandler } from '../../../helper/crud-api.helper';
import { prepareReportFilter } from '../../../helper/MetaHelper';
import { getRootTableId } from '../../../helper/meta-helper';
import { ROW_STATE_COLOR_FILTER } from '../../../core/configProvider';

import type { RelationActionBarProps } from './relation-action-bar.type';
import type { MenuOptionInterface } from '../../button-menu';
import type { FinalFiltersType } from '../../filter-form';

const RelationActionBar: FC<RelationActionBarProps> = props => {
  const {
    relationResource,
    relationMetaData,
    mockedRelation,
    parentRecord,
    parentInfo,
    relationType,
    relationData,
    handleFullScreen,
  } = props;
  const { parentFieldName, childFieldName } = mockedRelation;

  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const selectedIdsAccessRef = useRef<Array<string>>();

  const quickCreateData: {
    [key: string]: unknown;
  } = {
    [childFieldName]: parentRecord?.[parentFieldName],
  };

  useEffect(() => {
    selectedIdsAccessRef.current = selectedIds;
  }, [selectedIds]);

  useEffect(() => {
    actorOnDispatch(
      'gridData',
      gridData => {
        const prevSelectedIds = selectedIdsAccessRef.current ?? [];
        const newSelectedIds = gridData[relationResource]?.selectedIds ?? [];

        if (prevSelectedIds.length !== newSelectedIds.length) {
          setSelectedIds(clone(newSelectedIds));
        }
      },
      { callerScopeName: 'RelationActionBar' },
    );
  }, []);

  /**
   * reset relation  data
   * @function onDeleteSuccessCallback
   * @returns {void} void
   */
  const onDeleteSuccessCallback = (): void => {
    requestListData(
      relationResource,
      relationType,
      (parentRecord[parentFieldName] as string) ?? parentInfo.parentId,
      mockedRelation.childFieldName, // <= childFieldName
      mockedRelation.parentFieldName, // <= parentFieldName
      relationMetaData, // <= metaData
      undefined, // no custom request parameters
      apiRequestResultHandler,
    );
    actorDispatch('loading', false, { path: relationResource });
    actorDispatch('refreshView', 'record', {
      callerScopeName: 'RelationActionBar => onDeleteSuccessCallback',
    });
  };

  /**
   * reset relation  data
   * @function onColorFilterChange
   * @params onColorFilterChange
   * @returns {void} void
   */
  const onColorFilterChange = (selectedOption: MenuOptionInterface): void => {
    let filter: [string, string, string][] = [];
    if (selectedOption.value != null) {
      filter = [
        [
          ROW_STATE_COLOR_FILTER.KEY,
          ROW_STATE_COLOR_FILTER.OPERATOR,
          selectedOption.value + '',
        ],
      ];
    }

    requestListData(
      relationResource,
      relationType,
      parentInfo.parentId, // <= parentIdentifier
      mockedRelation.childFieldName, // <= childFieldName
      mockedRelation.parentFieldName, // <= parentFieldName
      relationMetaData, // <= metaData
      {
        filter,
      },
      apiRequestResultHandler,
    );
    actorDispatch('loading', false, { path: relationResource });
    actorDispatch('refreshView', 'showRecordPage');
  };

  // --------------- prepare filters ---------------
  /**
   * it will toggle `IsTopFilterOpen` state to decide show or dont show filters in each column in grid
   * and also it should call `clearFilters` function if it going to set false in state
   * @function toggleShowFilters
   * @returns {void} void
   */
  const toggleShowFilters = useCallback(() => {
    const isTopFilterOpen = actorGetActionValue('isTopFilterOpen', relationResource);

    actorDispatch('isTopFilterOpen', { [relationResource]: !isTopFilterOpen });
  }, []);

  /**
   * @function getFilterValues
   * @returns Record<string, unknown>[] | undefined
   */
  const getFilterValues = (): FinalFiltersType | undefined => {
    if (relationType === 'report' || relationType === 'multiReport') {
      return prepareReportFilter({
        meta: relationMetaData,
        record: parentRecord,
        formatByAnd: true,
      });
    } else {
      return [
        [mockedRelation.childFieldName, '=', parentInfo.parentId],
        'and',
        ['tableid', '=', String(getRootTableId())],
      ];
    }
  };

  const allowExport = !(
    (relationType === 'table' || relationType === 'oneToOneTable') &&
    (relationData?.totalCount === 0 || !relationMetaData?.config?.allowExport)
  );

  return (
    <RelationActionBarView
      {...props}
      onDeleteSuccessCallback={onDeleteSuccessCallback}
      quickCreateData={quickCreateData}
      selectedIds={selectedIds}
      toggleShowFilters={toggleShowFilters}
      allowExport={allowExport}
      getFilterValues={getFilterValues}
      onColorFilterChange={onColorFilterChange}
      handleFullScreen={handleFullScreen}
    />
  );
};
export default RelationActionBar;
