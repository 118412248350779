import { makeStyles } from '@material-ui/core/styles';
import type { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.palette.primary.zoomingBorderColor}`,
    margin: '0.5rem 0',
  },
  child: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.primary.zoomingBorderColor}`,
    '&:last-child ': {
      border: 'none',
    },
  },
  zoomBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: '0.25rem 0.5rem',
    cursor: 'pointer',
  },
}));
