import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  icon: {
    margin: 8,
    color: 'hsl(0, 0%, 60%)',
  },

  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  disableSave: {
    fontSize: 12,
    color: 'hsl(0, 0%, 60%)',
  },
  saveText: {
    fontSize: 12,
  },
}));
