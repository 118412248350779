import StatChartView from './stat-chart.view';
import { addThousandSeparator } from '../dynamic-chart.helper';

import { type FC } from 'react';
import type { StatChartControllerProps } from './stat-chart.type';

const StatChartController: FC<StatChartControllerProps> = props => {
  const { reportData, chartOptions, height, width } = props;

  //in old dashboard version valueFieldName was array and maybe we have crash
  const finalValueFieldName = Array.isArray(chartOptions.valueFieldName)
    ? chartOptions.valueFieldName[0]
    : chartOptions.valueFieldName;

  const preparedValue = addThousandSeparator(
    (reportData?.[0]?.[finalValueFieldName] as string) ?? '',
  );

  const finalData = {
    title: chartOptions.titleFieldName ?? '',
    value: preparedValue,
    icon: (reportData?.[0]?.[chartOptions?.iconFieldName] as string) ?? '',
  };
  return <StatChartView data={finalData} height={height} width={width} />;
};

export default StatChartController;
