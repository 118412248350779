import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { leftArrow, rightArrow } from '../utils/assets';
import { convertToGregorianPersian } from '../utils/moment-helper';

export default class Heading extends Component {
  static propTypes = {
    month: PropTypes.object.isRequired,
    isGregorian: PropTypes.bool,
    locale: PropTypes.string
  };

  static contextTypes = {
    styles: PropTypes.object,
    nextMonth: PropTypes.func.isRequired,
    prevMonth: PropTypes.func.isRequired,
    setCalendarMode: PropTypes.func.isRequired
  };

  handleMonthClick(event) {
    const { setCalendarMode } = this.context;
    event.preventDefault();
    setCalendarMode('monthSelector');
  }

  render() {
    const { nextMonth, prevMonth } = this.context;
    const { month, styles, isGregorian, locale } = this.props;
    const dayFormat = isGregorian ? 'MMMM YYYY' : 'jMMMM jYYYY';

    return (
      <div className={styles.heading}>
        <button className={styles.title} onClick={this.handleMonthClick.bind(this)}>
          {locale === 'fa'
            ? convertToGregorianPersian(month.locale(locale).format(dayFormat))
            : month.locale(locale).format(dayFormat)}
        </button>
        {this.props.timePicker}
        {locale !== 'en' && (
          <React.Fragment>
            <button
              type="button"
              title="ماه قبل"
              className={styles.prev}
              onClick={prevMonth}
              dangerouslySetInnerHTML={rightArrow}
            />
            <button
              type="button"
              title="ماه بعد"
              className={styles.next}
              onClick={nextMonth}
              dangerouslySetInnerHTML={leftArrow}
            />
          </React.Fragment>
        )}
        {locale === 'en' && (
          <React.Fragment>
            <button
              type="button"
              title="previous month"
              className={styles.next}
              onClick={prevMonth}
              dangerouslySetInnerHTML={leftArrow}
            />
            <button
              type="button"
              title="next month"
              className={styles.prev}
              onClick={nextMonth}
              dangerouslySetInnerHTML={rightArrow}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
