import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { MenuItem } from '@material-ui/core';

import type { TodoSidebarContextMenuViewInterface } from './todo-sidebar-context-menu.type';

const TodoSidebarContextMenuView = (
  props: TodoSidebarContextMenuViewInterface,
): ReactElement => {
  const { handleDeleteFolder, toggleShareForm } = props;

  const translate = useTranslate();

  return (
    <>
      <MenuItem
        onClick={handleDeleteFolder}
        data-test="mail-sidebar-item-delete-button"
      >
        {translate('general.delete')}
      </MenuItem>

      <MenuItem onClick={toggleShareForm} data-test="mail-sidebar-item-share-button">
        {translate('todo.shareList')}
      </MenuItem>
    </>
  );
};

export default TodoSidebarContextMenuView;
