import { FC, memo } from 'react';

import { convertDigitsToEnglish } from '../../../../helper/NumberHelper';
import NumberTextFieldWMSView from './number-text-field-wms.view';
import { checkNumberForMinus } from './number-text-field.helper';
import { NumberTextFieldInterface } from './number-text-field.type';
import NumberTextFieldView from './number-text-field.view';

const NumberTextFieldController: FC<NumberTextFieldInterface> = memo(props => {
  const {
    onChange,
    inputCustomProps,
    inputMode,
    type,
    value,
    customTestAttribute,
    getRef,
    ...rest
  } = props;

  /**
   * this function handle - char in input
   * @function customOnChange
   * @param {e} React.ChangeEvent<HTMLInputElement>
   */
  const customOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.target.value = checkNumberForMinus(convertDigitsToEnglish(e.target.value));
    e.target.focus = inputCustomProps.onFocusForTest;
    if (onChange) onChange(e);
  };

  const ViewComponent = inputCustomProps?.inputInPuzzleForm
    ? NumberTextFieldWMSView
    : NumberTextFieldView;

  return (
    <ViewComponent
      customOnChange={customOnChange}
      type={type}
      customTestAttribute={customTestAttribute}
      inputMode={inputMode}
      getRef={getRef}
      {...inputCustomProps}
      {...rest}
      value={value}
    />
  );
});

export default NumberTextFieldController;
