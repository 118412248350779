import {
  actorGetActionValue,
  RecordKeyMode,
  ResourceInterface,
} from '../type/actor-setup';
import { MetaData } from './Types';

/**
 * get current resource from actor
 * @function getCurrentResource
 * @returns {ResourceInterface | null}
 */
export const getCurrentResource = (): ResourceInterface | null => {
  return actorGetActionValue('resources')?.current ?? null;
};

/**
 * get current record from actor
 * @function getCurrentRecord
 * @param {String} recordKeyMode
 * @returns {Object | null}
 */
export const getCurrentRecord = (
  recordKeyMode: RecordKeyMode = RecordKeyMode.FULL,
): Record<string, unknown> | null => {
  const currentResource = getCurrentResource();
  if (!currentResource) return null;

  return (actorGetActionValue(
    'record',
    `${currentResource.value}.${currentResource.type}.${recordKeyMode}`,
  ) ?? null) as Record<string, unknown> | null;
};

/**
 * get root resource from actor
 * @function getRootResource
 * @returns {Object | null}
 */
export const getRootResource = (): string | null => {
  return actorGetActionValue('resources')?.stack?.[0]?.value ?? null;
};

/**
 * get current metaData from actor
 * @function getCurrentMetaData
 * @returns {Object | null}
 */
export const getCurrentMetaData = (): MetaData | null => {
  const currentResource = getCurrentResource();
  if (!currentResource) return null;

  return (
    (actorGetActionValue(
      'metaData',
      currentResource.value,
    ) as unknown as MetaData) ?? null
  );
};

/**
 * get root metaData from actor
 * @function getRootMetaData
 * @returns {MetaData | null}
 */
export const getRootMetaData = (): MetaData | null => {
  const rootResource = getRootResource();
  if (!rootResource) return null;

  return (
    (actorGetActionValue('metaData', rootResource) as unknown as
      | MetaData
      | undefined) ?? null
  );
};
