import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
  },

  inputContainer: {
    padding: 10,
    height: 50,
    width: '100%',
    display: 'flex',
    background: theme.palette.primary.appLightBackgroundColor,
  },

  input: {
    flex: 1,
  },

  inputIcon: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },

  inputFooterContainer: {
    display: 'flex',
    backgroundColor: 'var(--bg-primary-accent)',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  inputFooterButtonContainer: {
    margin: '0.5rem',
  },
}));
