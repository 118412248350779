import { type FC, useEffect, useState, useCallback, memo } from 'react';

import LoadingSkeleton from './loading-skeleton';
import { getPermissionTabData } from './show-permission-tab.helper';
import NotFound from '../../NotFound';
import { showNotification } from '../../../helper/general-function-helper';
import { PermissionMenu } from '../../permissions/permission-menu';
import { openPermissionsDialog } from '../simple-grid/simple-grid.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';
import { MenuItemParams } from '../../menu/sidebar-menu';
import { prepareMenu } from '../../menu/sidebar-menu/sidebar-menu.helper';
import { isEmptyObject } from '../../../helper/data-helper';

import type { MenuPermission } from '../show-permissions.type';
import type { MenuPermissionsInterface } from './dynamic-permission-data.type';
import { DevExpressCellData } from '../simple-grid';

const MenuPermissions: FC<MenuPermissionsInterface> = ({
  tabId,
  isAdvanced,
  selectItems,
}) => {
  const resource = 'permission/show';
  const filter = actorGetActionValue('filterDataIsChanged')?.[resource];

  const [data, setData] = useState<MenuPermission[]>([]);
  const [loading, setLoading] = useState(!!filter);
  const [error, setError] = useState(false);

  /**
   * @function refreshTabData
   * @param { string } error
   * @returns {void} void
   */
  const refreshTabData = useCallback((customFilter: any = undefined): void => {
    const defaultFilter = actorGetActionValue('filterDataIsChanged')?.[resource];

    getPermissionTabData(tabId, onSuccess, onFailure, {
      filter: customFilter ?? defaultFilter,
    });
  }, []);

  /**
   * @function openMenu
   * @param {any} row
   * @returns {void} void
   */
  const openMenu = (row): void => {
    if (row.itemData.paramKey && !isAdvanced) {
      openPermissionsDialog(row, refreshTabData); // todo: handle this too
    }
  };

  /**
   * @function handleClickAdvancedPermission
   * @param {any} row
   * @returns {void} void
   */
  const handleClickAdvancedPermission = (row: {
    itemData: { paramKey: string; selected: boolean };
  }): void => {
    if (row.itemData.paramKey) {
      actorDispatch(
        'saveCellInPermissionsAdvance',
        {
          [row.itemData.paramKey]: {
            permissionKey: row.itemData.paramKey,
            checked: row.itemData.selected,
            cellData: row,
          },
        },
        { disableDebounce: true },
      );
    }
  };

  /**
   * @function onSuccess
   * @param {MenuPermission[]} response
   * @returns {void} void
   */
  const onSuccess = useCallback((response: unknown[]): void => {
    setData(response as MenuPermission[]);
    setLoading(false);
  }, []);

  /**
   * @function onFailure
   * @param {string} error
   * @returns {void} void
   */
  const onFailure = useCallback((error: string): void => {
    showNotification(error, 'error');
    setError(true);
    setLoading(false);
  }, []);

  useEffect(() => {
    const id = actorOnDispatch('filterDataIsChanged', filter => {
      refreshTabData(filter[resource]);
    });

    return () => {
      actorRemoveAction({
        actionName: 'filterDataIsChanged',
        listenerId: id,
      });
    };
  }, []);

  if (loading) {
    return <LoadingSkeleton />;
  }
  if (error) {
    return <NotFound />;
  }

  return (
    <PermissionMenu
      onRowClick={openMenu}
      data={prepareMenu(data as unknown as MenuItemParams[])}
      isAdvanced={isAdvanced}
      handleClickPermissionAdvance={handleClickAdvancedPermission}
      selectItems={selectItems}
    />
  );
};

export default memo(
  MenuPermissions,
  (prevProps: MenuPermissionsInterface, nextProps: MenuPermissionsInterface) => {
    return !isEmptyObject(nextProps.selectItems);
  },
);
