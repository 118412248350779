import dataProvider from '../core/dataProvider';
import { actorDispatch, actorOnDispatch } from '../type/actor-setup';
import { GET_LIST } from 'react-admin';
import { getMetaFromApi } from '../container/NewMetaContext';
import { quickAccessMenuResource } from '../component/quick-access-menu';
import { setLoading } from '../helper/general-function-helper';
import { arrayResultToObjectWithLowerCase } from '../helper/data-helper';

/**
 * @function successGetAppCoreDataCallback
 * @param { unknown } response
 * @returns { void }
 */
const successGetAppCoreDataCallback = (response): void => {
  if (response) {
    actorDispatch('quickAccessMenuData', response);

    setLoading(quickAccessMenuResource, false);
  }
};

actorOnDispatch('getQuickAccessMenuData', async () => {
  setLoading(quickAccessMenuResource, true);

  try {
    const allMetaData = await getMetaFromApi(quickAccessMenuResource);
    const quickAccessMenuMetaData = allMetaData.find(
      item => item.name === quickAccessMenuResource,
    )?.meta;

    if (quickAccessMenuMetaData) {
      actorDispatch('metaData', quickAccessMenuMetaData, {
        path: quickAccessMenuResource,
      });
    }

    try {
      const response = await dataProvider(GET_LIST, quickAccessMenuResource, {
        pagination: { page: 1, perPage: 10000 },
      });

      const data = arrayResultToObjectWithLowerCase(response?.data, {
        perPage: 10000,
      });

      successGetAppCoreDataCallback(data);
    } catch (error) {
      setLoading(quickAccessMenuResource, false);
    }
  } catch (error) {
    setLoading(quickAccessMenuResource, false);
  }
});
