import { type ReactElement, useEffect, useState } from 'react';

import { parseJSON } from '../../../core/configProvider';
import { prepareDynamicChartPropsForGadgets } from '../../dynamic-chart/dynamic-chart.helper';
import { CUSTOM_GET } from '../../../core/data-Provider.helper';
import { actorDispatch } from '../../../type/actor-setup';
import GadgetFieldView from './gadget-field.view';

import type { GadgetInformation } from '../../dynamic-input/gadget-input/gadget-input.type';
import type { GadgetFieldControllerProps } from './gadget-field.type';
import { getGadgetDefaultSort } from '../../dashboard/dashboard-card-form-dialog/dashboard-card-form-dialog.helper';

const GadgetFieldController = (props: GadgetFieldControllerProps): ReactElement => {
  const { gadgetValue } = props;
  const [reportData, setReportData] = useState<Record<string, unknown>[] | null>(
    null,
  );
  const [parsedValue, setParsedValue] = useState<null | GadgetInformation>(
    parseJSON<GadgetInformation>(gadgetValue),
  );

  useEffect(() => {
    setParsedValue(parseJSON<GadgetInformation>(gadgetValue));
  }, [gadgetValue]);

  useEffect(() => {
    if (!parsedValue) return;
    if (parsedValue?.reportAddress) {
      actorDispatch(
        'crudAction',
        {
          type: CUSTOM_GET,
          entity: 'gadget',
          resource: `v2/report/${parsedValue.reportAddress}?skip=0&takeCount=9999999&sort=id&sortType=DESC`,
          onSuccess: reportData => {
            if (reportData?.data?.length) {
              setReportData(reportData.data);
            } else {
              setReportData([]);
              console.error('report data has not length'); // // show notification later if needed
            }
          },
          onFailure: reportDataError => {
            setReportData([]);
            console.error('reportDataError: ', reportDataError); // // show notification later if needed
          },
        },
        { disableDebounce: true },
      );
    } else {
      setReportData([]);
    }
  }, [parsedValue?.reportAddress]);

  const gadgetDefaultSort = getGadgetDefaultSort(parsedValue?.sortObject);
  const dynamicChartPreparedProps =
    parsedValue && reportData
      ? prepareDynamicChartPropsForGadgets({
          gadgetValues: parsedValue,
          reportData,
          displayNumber: parsedValue.displayNumber,
          ...(!!gadgetDefaultSort?.isSortEnabled && {
            sortField: gadgetDefaultSort.sortField,
            sortOrder: gadgetDefaultSort.sortOrder,
          }),
        })
      : null;

  return (
    <GadgetFieldView
      parsedValue={parsedValue}
      dynamicChartPreparedProps={dynamicChartPreparedProps}
    />
  );
};

export default GadgetFieldController;
