import { useTranslate } from 'react-admin';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Divider,
  Button,
  TextField,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import GradeIcon from '@material-ui/icons/Grade';

import { ChatAvatarInput } from '../../chat-avatar-input';
import { useStyles } from './manage-group-dialog.style';

import type { ReactElement } from 'react';
import type { ManageGroupDialogViewPropsInterface } from './manage-group-dialog.type';

const ManageGroupDialogView = (
  props: ManageGroupDialogViewPropsInterface,
): ReactElement => {
  const {
    openAddMembersDialog,
    successFileCallback,
    closeDialogHandler,
    handleInputChange,
    openAdminsDialog,
    handleSave,
    selectedUser,
  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <>
      <DialogTitle>{translate('chat.editGroup')}</DialogTitle>
      <DialogContent>
        <Box>
          <Box className={classes.contentHeaderContainer}>
            <Box className={classes.groupImageContainer}>
              <ChatAvatarInput
                successFileCallback={successFileCallback}
                personImage={selectedUser.personimage}
              />
            </Box>
            <TextField
              className={classes.input}
              label={translate('chat.groupName')}
              focused
              defaultValue={selectedUser.personname}
              onChange={handleInputChange}
              variant="standard"
            ></TextField>
          </Box>
          <Box className={classes.listContainer}>
            <Divider />
            <Box onClick={openAddMembersDialog} className={classes.listItem}>
              <GroupIcon className={classes.icon} />
              <Box>{translate('chat.members')}</Box>
            </Box>
            <Box onClick={openAdminsDialog} className={classes.listItem}>
              <GradeIcon className={classes.icon} />
              <Box>{translate('chat.admins')}</Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>{translate('css.save')}</Button>
        <Button onClick={closeDialogHandler}>{translate('confirm.cancel')}</Button>
      </DialogActions>
    </>
  );
};

export default ManageGroupDialogView;
