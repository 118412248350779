import { useCallback, useEffect, useReducer, type ReactElement } from 'react';
import momentJalaali from 'moment-jalaali';
import { useLocale } from 'react-admin';

import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../type/actor-setup';
import {
  broadcastUserIsOnline,
  getChats,
  getDifferenceBetweenCustomTimeToNow,
  getUsersOnlineStatus,
  IS_TYPING_REFRESH_RATE,
  ONLINE_STATUS_REFRESH_RATE,
  setUpUsersIsTypingListener,
  validateUsersIsTyping,
} from './chat-section.helper';

import ChatSectionView from './chat-section.view';
import {
  chatSectionReducer,
  chatSectionInitialState,
} from './chat-section-helper/chat-section-reducer';
import { chatStatelessActions } from './chat-section-helper/chat-section-stateless-actions';
import {
  gregorianDateTimeFormatWithSlash,
  jalaliDateTimeFormatWithSlash,
} from '../../helper/CalendarMetaHelper';

import type { UsersOnlineStatusResponseInterface } from './chat-section.type';

/**
 * @ChatDescription
 * Hi my dear developers!!
 * Some comments will be needed here
 * We have two concepts: `chat` & `message`
 * based on API, every message has a `chat_id`!! and every chat has a `personinfo_id`!!, Ok?
 * then in `actor`, `chats` saves the chats based on `personinfo_id`, so a selected chat is actually a selected user
 * example:
 *  chatList: {
 *    total: 1,
 *    data: {
 *      287765: {
          info: {...}, => is actually sender user info
          messagesDetail: {
            data: [...],
            hasMore: true
          }
 *      }
 *    }
 *  }
 *
 * selectedChat: {
 *  info: {...},
    messagesDetail: {
      data: [...],
      hasMore: true
    }
 * }
 */

const ChatSectionController = (): ReactElement => {
  const [state, dispatch] = useReducer(chatSectionReducer, chatSectionInitialState);
  const { chatPage, isFileUploadDialogOpen, uploadedFiles } = state;

  const locale = useLocale();

  const onUsersStatusReceive = (
    usersStatus: UsersOnlineStatusResponseInterface[],
  ) => {
    const onlineDatesList = {};

    if (Array.isArray(usersStatus)) {
      usersStatus.forEach(userLastSeenInfoObject => {
        let lastSeenText = '';

        const deferenceTime = getDifferenceBetweenCustomTimeToNow(
          userLastSeenInfoObject.lastSeen,
        );

        if (deferenceTime < ONLINE_STATUS_REFRESH_RATE) {
          lastSeenText = 'online';
        } else if (deferenceTime > 360000000) {
          // its equal with 100 days
          lastSeenText = 'long time ago';
        } else {
          lastSeenText = momentJalaali(userLastSeenInfoObject.lastSeen).format(
            locale === 'en'
              ? gregorianDateTimeFormatWithSlash
              : jalaliDateTimeFormatWithSlash,
          );
        }

        onlineDatesList[String(userLastSeenInfoObject.client)] = lastSeenText;
      });

      actorDispatch('usersLastSeen', onlineDatesList, {
        disableDebounce: true,
        replaceAll: true,
      });
    }
  };

  /**
   * @function onUsersIsTypingReceive
   * @param {string} usersIsTyping
   * @returns {void} void
   */
  const onUsersIsTypingReceive = (
    isTypingUserId: string,
    groupId: string,
    isTypingPersonName: string,
  ): void => {
    actorSetActionValue(
      'isTypingUsers',
      {
        [groupId || isTypingUserId]: {
          time: new Date(),
          personName: isTypingPersonName,
          groupId,
        },
      },
      { disableDebounce: true },
    );
  };

  useEffect(() => {
    getUsersOnlineStatus(onUsersStatusReceive);
    setUpUsersIsTypingListener(onUsersIsTypingReceive);

    const broadcastUserIsOnlineInterval = setInterval(() => {
      broadcastUserIsOnline();
    }, ONLINE_STATUS_REFRESH_RATE);

    const removeExpiredIsTypings = setInterval(() => {
      validateUsersIsTyping();
    }, IS_TYPING_REFRESH_RATE);

    actorSetActionValue('chatReducer', { state, dispatch });

    getChats({
      shouldLoading: false,
      page: chatPage,
    });

    return () => {
      broadcastUserIsOnlineInterval && clearInterval(broadcastUserIsOnlineInterval);
      removeExpiredIsTypings && clearInterval(removeExpiredIsTypings);
    };
  }, []);

  const handleCloseFileUploadDialog = useCallback(() => {
    dispatch({
      type: 'openUploadFileDialog',
      payload: {
        show: false,
        uploadedFiles: [],
      },
    });
  }, []);

  /**
   * you can copy some files from everywhere
   * then ctrl + v on the messages list
   * it will trigger onSendFile
   * @function handlePasteFromClipboard
   * @param { React.ClipboardEvent<HTMLTextAreaElement> } event
   * @returns { void }
   */
  const handlePasteFromClipboard = useCallback(
    (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      event.persist();

      const currentSelectedUser = actorGetActionValue('selectedChat')?.info;
      if (currentSelectedUser) return;

      // FIXME: How we can use `currentSelectedUser` in `sendFile`?
      chatStatelessActions.sendFiles({
        files: event.clipboardData.files,
      });
    },
    [],
  );

  return (
    <ChatSectionView
      uploadedFiles={uploadedFiles}
      isFileUploadDialogOpen={isFileUploadDialogOpen}
      handleCloseFileUploadDialog={handleCloseFileUploadDialog}
      handlePasteFromClipboard={handlePasteFromClipboard}
    />
  );
};

export default ChatSectionController;
