import { ReactElement, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  showNotification,
  showNotificationForUnknownError,
} from '../../../helper/general-function-helper';
import { editTodoTask } from '../todo-section.helper';
import AddInMyDayView from './add-in-my-day-button.view';

import type { AddInMyDayControllerInterface } from './add-in-my-day-button.type';

const AddInMyDayController = (
  props: AddInMyDayControllerInterface,
): ReactElement => {
  const { todoSelectedTaskInfo } = props;
  const translate = useTranslate();

  const isAddedToMyDayInRecord = !!todoSelectedTaskInfo?.isaddedtomyday;

  const [isAddedToMyDayLocal, setIsAddedToMyDayLocal] = useState<boolean>(
    isAddedToMyDayInRecord,
  );

  /**
   * change status add to my day and send changes
   * @function onChangeIsAddedToMyDayButtonClick
   * @returns {void} void
   */
  const onChangeIsAddedToMyDay = (): void => {
    //For an optimistic eternity we change the color and spoil it
    setIsAddedToMyDayLocal(!isAddedToMyDayInRecord);

    // Edit the task
    editTodoTask({
      taskUID: String(todoSelectedTaskInfo?.uniqueid),
      newValues: {
        isaddedtomyday: !todoSelectedTaskInfo?.isaddedtomyday as boolean,
      },
      failureCallback: (error: unknown) => {
        showNotificationForUnknownError(error, translate);
      },
    });
  };

  useEffect(() => {
    setIsAddedToMyDayLocal(isAddedToMyDayInRecord);
  }, [todoSelectedTaskInfo]);

  return (
    <AddInMyDayView
      onChangeIsAddedToMyDay={onChangeIsAddedToMyDay}
      isAddedToMyDayLocal={isAddedToMyDayLocal}
    />
  );
};

export default AddInMyDayController;
