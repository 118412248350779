import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@material-ui/core/Box';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { useTranslate } from 'react-admin';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import { Badge, Fab } from '@material-ui/core';
import clsx from 'classnames';

import LoadingBox from '../../loading-box';
import { MessageItem } from './message-item';
import { useStyles } from './messages-list.style';
import { AppFooter } from '../../app-container/app-footer';
import { NewMessage } from '../new-message';
import { ChatPins } from '../chat-pins';

import { type FC } from 'react';
import { type MessageItemInterface } from '../chat-section.type';
import { type MessagesListViewInterface } from './messages-list.type';

const MessagesListView: FC<MessagesListViewInterface> = props => {
  const {
    selectedUser,
    fetchMoreOnTop,
    senderUser,
    onScrollDown,
    onScrollTop,
    showScrollDownBtn,
    handleOnScroll,
    loading,
    isSendingMessageAllowed,
    sendMessageAction,
    selectedMessage,
    messagesDetail,
    moveToRepliedMessage,
    unseenMessagesCount,
    isUserMentioned,
    scrollToMentionMessage,
    groupUsers,
    pinMessagesList,
  } = props;

  const classes = useStyles({
    paddingTop: ['edit', 'reply'].indexOf(selectedMessage?.mode ?? '') > -1 ? 0 : 8,
  });
  const translate = useTranslate();

  if (loading) {
    return (
      <Box className={classes.container}>
        <Box className={classes.loadingContainer} id="messageLoading">
          <LoadingBox />
        </Box>
      </Box>
    );
  }

  if (!selectedUser || !senderUser) {
    return (
      <Box
        p={4}
        className={clsx(classes.scrollChatNoUser, 'scroll-chat-nouser')}
        id="boxNoneUser"
      >
        <Box id="noneUser">
          <ChatBubbleOutlineIcon />
          <Box component="p">{translate('chat.startChat')}</Box>
        </Box>
      </Box>
    );
  }

  const { data: messages, hasMore } = messagesDetail;
  if (!messages.length) {
    return (
      <>
        <Box className={classes.container}>
          <Box component="span" className={classes.noMessage}>
            {translate('chat.sayHi')} {selectedUser.personname}
          </Box>
        </Box>

        <AppFooter className={classes.messagesListFooter}>
          {isSendingMessageAllowed && (
            <NewMessage
              groupUsers={groupUsers}
              selectedUser={selectedUser}
              sendMessageAction={sendMessageAction}
              currentMessage={selectedMessage}
            />
          )}
        </AppFooter>
      </>
    );
  }

  return (
    <>
      <Box className={classes.container}>
        <ChatPins pinMessagesList={pinMessagesList} />

        <div id="scrollableDiv" className={classes.scrollDiv}>
          <InfiniteScroll
            data-style-list-message="listMessage"
            dataLength={messages.length}
            scrollableTarget="scrollableDiv"
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
            inverse={true}
            next={fetchMoreOnTop}
            hasMore={hasMore}
            loader={<LoadingBox size={20} style={{ height: '4rem' }} />}
            className={classes.infiniteScroll}
            onScroll={handleOnScroll}
          >
            {messages.map((item: MessageItemInterface) => {
              const isSender = item.frompersoninfo_id === senderUser.personinfo_id;
              return (
                <MessageItem
                  key={item.frompersoninfo_id + item.chat_id}
                  user={
                    isSender
                      ? { ...senderUser, groupuid: selectedUser.groupuid }
                      : selectedUser
                  }
                  item={item}
                  message={item.chattext}
                  replyMessageText={item.replychattext}
                  messageId={item.chat_id}
                  isSender={isSender}
                  isSeen={item.isseen}
                  onScrollTop={onScrollTop}
                  data-style-item-message-from="itemMessageFrom"
                  moveToRepliedMessage={moveToRepliedMessage}
                />
              );
            })}
          </InfiniteScroll>

          {showScrollDownBtn && (
            <Fab
              onClick={onScrollDown}
              className={classes.scrollDownBtn}
              aria-label="scroll-down"
              size="small"
            >
              <Badge
                max={10000}
                color="secondary"
                classes={{ badge: classes.customBadge }}
                badgeContent={unseenMessagesCount}
              />
              <ArrowDownIcon />
            </Fab>
          )}
        </div>
      </Box>
      {isUserMentioned && (
        <Fab
          onClick={scrollToMentionMessage}
          className={classes.mentionIcon}
          size="small"
        >
          @
        </Fab>
      )}
      <AppFooter className={classes.messagesListFooter}>
        {isSendingMessageAllowed && (
          <NewMessage
            groupUsers={groupUsers}
            selectedUser={selectedUser}
            sendMessageAction={sendMessageAction}
            currentMessage={selectedMessage}
          />
        )}
      </AppFooter>
    </>
  );
};

export default MessagesListView;
