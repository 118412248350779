import { ReactElement, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import lodashGet from 'lodash/get';
import { CardContentInner, useLocale } from 'react-admin';

import { actorGetActionValue, actorOnDispatch } from '../../../type/actor-setup';
import { DynamicInput } from '../../dynamic-input/dynamic-input.controller';
import { InputRefContent } from '../../form';
import { updateInputsUiSpecs } from '../../form/form-with-tabs/form-with-tabs.helper';
import { useStyles } from './quick-edit-dialog-form.style';

import type { QuickEditDialogFormViewInterface } from './quick-edit-dialog-form.type';
import type { MetaDataBase } from '../../../helper/Types';

const QuickEditDialogFormView = (
  props: QuickEditDialogFormViewInterface,
): ReactElement => {
  const { fields } = props;
  const classes = useStyles();
  const locale = useLocale();

  useEffect(() => {
    const currentResource = actorGetActionValue('resources')!.current;

    actorOnDispatch('metaData', metaData => {
      const inputsRef =
        (actorGetActionValue(
          'inputsRef',
          `${currentResource.value}.${currentResource.type}`,
        ) as Record<string, InputRefContent> | null) ?? {};

      const _metaData = metaData[currentResource.value]! as unknown as MetaDataBase;
      if (_metaData == null) return;

      const allRawFields = Object.values(_metaData.fields ?? {});
      updateInputsUiSpecs(inputsRef, allRawFields);
    });
  }, []);

  const value = useMemo(() => {
    const currentResource = actorGetActionValue('resources')!.current;
    const formData = actorGetActionValue(
      'formData',
      `${currentResource.value}.${currentResource.type}`,
    )!;

    return formData[fields![0].name] ?? null;
  }, [fields]);

  return (
    <div className={classnames('simple-form', classes.simpleForm)}>
      <CardContentInner
        className={classnames('fieldContainer', classes.inputContainer)}
      >
        {fields &&
          fields.map(field => (
            <DynamicInput
              key={field.id}
              field={field}
              currentTabIndex={0}
              defaultValue={value}
              label={lodashGet(field, ['translatedCaption', locale], field.name)}
            />
          ))}
      </CardContentInner>
    </div>
  );
};

export default QuickEditDialogFormView;
