import { cloneElement, ReactElement } from 'react';
import { ListItem, ListItemText, Menu } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'classnames';

import { useStyles } from './nav-item.style';
import { NavLink } from '../nav-link';
import { isEmpty } from '../../../../helper/data-helper';

import type { NavItemViewInterface } from './nav-item.type';

const NavItemView = (props: NavItemViewInterface): ReactElement => {
  const {
    setSelectedOAFolderInActor,
    handleCloseContextMenu,
    onContextMenu,
    isActiveItem,
    onItemClick,
    onClickMenu,
    item,
    level,
    isContextMenuOpen,
    contextMenuNode,
    isActive,
    dragDropRef,
    canDragDrop,
    isOver,
  } = props;

  const classes = useStyles({ level, isActive, isOver });

  return (
    <div onClick={onItemClick?.(item)} ref={canDragDrop ? dragDropRef : null}>
      <ListItem
        data-style-navigation-item="navItem"
        onContextMenu={onContextMenu}
        button
        component={item?.onClick ? 'div' : NavLink}
        to={item.url}
        activeClassName={classes.active}
        className={clsx(classes.navItem, classes.isOver)}
        data-test={item?.dataTest}
        isActive={isActiveItem}
        onClick={setSelectedOAFolderInActor(item)}
      >
        {item.icon && (
          <Box height={20} className="nav-item-icon" component="span" mr={1}>
            {item.icon}
          </Box>
        )}

        <ListItemText primary={item.title} classes={{ primary: 'nav-item-text' }} />

        {item?.count && item.count > 0 ? (
          <Box className={classes.countContainer} mr={1} component="span">
            {item?.count}
          </Box>
        ) : null}

        {!isEmpty(isContextMenuOpen) && (
          <Menu
            onClick={onClickMenu}
            open={isContextMenuOpen !== null}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              isContextMenuOpen !== null &&
              isContextMenuOpen?.mouseX &&
              isContextMenuOpen?.mouseY
                ? { top: isContextMenuOpen.mouseY, left: isContextMenuOpen.mouseX }
                : undefined
            }
          >
            {contextMenuNode &&
              cloneElement(
                contextMenuNode as React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >,
                { ...item },
              )}
          </Menu>
        )}
      </ListItem>
    </div>
  );
};

export default NavItemView;
