import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';
import type { StyleProps } from './todo-label.type';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  chipContainer: {
    margin: 5,
    display: 'flex',
    flexWrap: 'wrap',
  },
  chipItem: {
    color: '#ffff',
    borderRadius: 7,
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${props => props.color}`,
    backgroundColor: props => props.color,
    filter: `brightness(1.5)`,
  },
  itemText: {
    width: 'auto',
    margin: '4px 4px',
  },
  menuContainer: {
    top: 800,
    overflowY: 'auto',
  },
  titleAndValue: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 10px 0 10px',
    fontSize: '1rem',
    fontWeight: 400,
  },

  itemContainer: {
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    padding: 11,
  },
  chipContainerItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 3,
  },
  todoItemText: {
    fontSize: '1rem',
    color: theme.palette.todoLightGray,
    fontWeight: 400,
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexGrow: 1,
  },
  closeIconColor: {
    color: theme.palette.primary.appLightBackgroundColor,
  },

  CheckIcon: {
    color: ({ color }) => color,
  },
}));
