import { useTranslate } from 'react-admin';
import { memo, type FC, useCallback } from 'react';

import { urlPermissions } from '../../show-permissions/show-permissions.helper';
import {
  showNotification,
  showNotificationForUnknownError,
} from '../../../helper/general-function-helper';
import { setSelectedPermissions } from './permissions-add-user.helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../../type/actor-setup';
import PermissionsAddUserView from './permissions-add-user.view';

import type { PermissionsAddUserType } from './permissions-add-user.type';
import { CUSTOM_POST } from '../../../core/data-Provider.helper';
import {
  computePermKey,
  getDialogTitle,
} from '../permission-show-reason-dialog/permission-show-reason-dialog.helper';
import { PermissionAssignType } from '../../permissions/permission-assigned/permission-assigned.type';

const PermissionsAddUser: FC<PermissionsAddUserType> = memo(props => {
  const { closeDialogHandler, dialogData } = props;
  const translate = useTranslate();

  const permDescs = {};
  const permKeyForServer: string[] = [];

  if (dialogData.isAdvanced) {
    const activeTab = actorGetActionValue('userPermissionValue');

    const selectedItemForPermission: any = dialogData?.selectedItemForPermission;

    for (const key in selectedItemForPermission) {
      const {
        column: cellDataColumn,
        data: cellDataData,
        itemData: cellDataItemData,
        node: cellDataNode,
        values: cellDataValues,
      } = selectedItemForPermission[key].cellData ?? {};

      let fieldName = '';
      if (cellDataData?.fieldname) {
        fieldName = cellDataData?.fieldname as string;
      }
      const columnTitle =
        cellDataColumn?.caption ?? cellDataNode?.itemData?.label ?? '';
      const cellName = cellDataValues?.[0] ?? cellDataItemData?.label ?? '';
      const cellNameModule = cellDataValues?.[1] ?? cellDataItemData?.label ?? '';

      const dialogTitle = getDialogTitle({
        fieldName,
        columnTitle,
        activeTab,
        cellData: selectedItemForPermission[key].cellData ?? {},
        cellName,
        cellNameModule,
      });

      const permKey = selectedItemForPermission[key]?.column?.permKey;
      const cellDataName = cellDataColumn?.name;

      permKeyForServer.push(
        computePermKey(permKey, cellDataData, cellDataName, cellDataItemData),
      );

      permDescs[key] = dialogTitle;
    }
  }

  /**
   * @function onChangePermissionSuccess
   * @returns {void}
   */
  const onChangePermissionSuccess = useCallback(({ data }): void => {
    if (data.code !== 200) {
      showNotification(data.userMessage, 'success', {
        fromQuickCreateDialog: true,
        forceSnackbar: true,
      });
    } else {
      showNotification(
        translate('ra.notification.successfully_executed'),
        'success',
        {
          fromQuickCreateDialog: true,
          forceSnackbar: true,
        },
      );
    }
    actorDispatch(
      'saveCellInPermissionsAdvance',
      {},
      { replaceAll: true, disableDebounce: true },
    );

    closeDialog();
    actorDispatch('signal', 'showReasonPermission');
  }, []);

  /**
   * @function onChangePermissionFailure
   * @params {response: { data: { userMessage: string } } response
   * @returns {void}
   */
  const onChangePermissionFailure = useCallback(
    (response: { data: { userMessage: string } }): void => {
      showNotification(response.data?.userMessage, 'error', {
        forceShowInDialog: true,
      });
      actorDispatch('saveCellInPermissionsAdvance', {});
    },

    [],
  );

  /**
   * handle permission click
   * @function onChangePermissionClick
   * @returns {void}
   */
  const onChangePermissionClick = useCallback((): void => {
    try {
      const permissionsInActor = actorGetActionValue('userPermissionValue');

      actorDispatch(
        'crudAction',
        {
          type: CUSTOM_POST,
          resource: urlPermissions,
          data: setSelectedPermissions(
            permissionsInActor,
            dialogData,
            permDescs,
            permKeyForServer,
          ),
          onSuccess: onChangePermissionSuccess,
          onFailure: onChangePermissionFailure,
        },
        {
          disableDebounce: true,
          replaceAll: true,
          callerScopeName:
            'PermissionShowReasonDialogController => onChangePermissionClick',
        },
      );
    } catch {
      console.error('onChangePermissionClick failed');
    }
  }, []);

  /**
   * @function onSuccessDeleteUserPermission
   * @returns {void} void
   */
  const onSuccessDeleteUserPermission = useCallback(({ data }): void => {
    if (data.code !== 200) {
      showNotification(data.userMessage, 'success', {
        fromQuickCreateDialog: true,
        forceSnackbar: true,
      });
    } else {
      showNotification(
        translate('ra.notification.successfully_executed'),
        'success',
        {
          fromQuickCreateDialog: true,
          forceSnackbar: true,
        },
      );
    }
    actorDispatch('saveCellInPermissionsAdvance', {});
  }, []);

  /**
   * @function removePermissionClick
   * @param { RowData } row
   * @returns { void } void
   */
  const removePermissionClick = () => {
    const permissionsInActor = actorGetActionValue('userPermissionValue');

    const {
      selectedAssignType = PermissionAssignType.User,
      selectedBranches = [],
      selectedAssignees = [],
    } = permissionsInActor ?? {};

    const selectedPermissions = {
      AttributionType: selectedAssignType,
      AttributionIds: selectedAssignees,
      PermKeys: permKeyForServer,
      Departments: selectedBranches,
      PermDescs: permDescs,
    };

    actorDispatch(
      'crudAction',
      {
        type: CUSTOM_POST,
        resource: 'permission/RemovePermission',
        data: selectedPermissions,
        onSuccess: onSuccessDeleteUserPermission,
        onFailure: (error: { data: { userMessage: string } }) => {
          showNotificationForUnknownError(error.data.userMessage, translate, true);
        },
      },
      { disableDebounce: true },
    );
    closeDialog();
    actorDispatch('signal', 'showReasonPermission');
  };

  /**
   * @function closeDialog
   * @returns {void}
   */
  const closeDialog = useCallback((): void => {
    closeDialogHandler();
    actorSetActionValue('userPermissionValue', {}, { replaceAll: true });
  }, []);

  return (
    <PermissionsAddUserView
      onChangePermissionClick={onChangePermissionClick}
      closeDialogHandler={closeDialog}
      dialogData={dialogData}
      removePermissionClick={removePermissionClick}
    />
  );
});

export default PermissionsAddUser;
