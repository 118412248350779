import { GET_LIST } from 'react-admin';

import dataProvider from '../core/dataProvider';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../type/actor-setup';
import {
  localStorageGetItem,
  removeValue,
  setValue,
  WEB_SETTING_ITEMS,
  getValue,
  SESSION_ID,
} from '../core/configProvider';
import { arrayResultToObjectWithLowerCase, isEmpty } from '../helper/data-helper';
import { getSessionIdInUrl } from '../helper/UrlHelper';

const settingResource = 'appcore/websetting';

const changeResourcesHandler = async (): Promise<void> => {
  const userSessionId = getValue(SESSION_ID) ?? getSessionIdInUrl();
  if (isEmpty(userSessionId)) return;

  actorDispatch('showSettingsLoading', true, {
    callerScopeName: 'settings-api => changeResourcesHandler 1',
  });

  const appSettings = actorGetActionValue('appSettings');
  if (appSettings?.original != null) {
    actorDispatch('showSettingsLoading', false, {
      callerScopeName: 'settings-api => changeResourcesHandler 2',
    });
    return;
  }

  //get setting from localStorage
  const webSettingItems = localStorageGetItem(WEB_SETTING_ITEMS);
  if (Array.isArray(webSettingItems) && webSettingItems.length > 0) {
    actorDispatch(
      'appSettings',
      {
        original: webSettingItems,
        objectList: {},
      },
      {
        callerScopeName: 'settings-api 1 => changeResourcesHandler',
      },
    );
    actorDispatch('showSettingsLoading', false, {
      callerScopeName: 'settings-api => changeResourcesHandler 3',
    });
    return;
  }

  try {
    const getSettingsResult = await dataProvider(GET_LIST, settingResource, {
      pagination: {
        page: 1,
        perPage: 999999,
      },
      sort: {
        field: 'id',
        order: 'DESC',
      },
    });

    const compatibleData = arrayResultToObjectWithLowerCase(
      getSettingsResult?.data,
      {
        perPage: 999999,
      },
    );

    setValue(WEB_SETTING_ITEMS, compatibleData);
    actorDispatch(
      'appSettings',
      {
        original: compatibleData,
        objectList: {},
      },
      {
        callerScopeName: 'settings-api 2 => changeResourcesHandler',
      },
    );
  } catch (error) {
    console.error('Getting the app settings error ', error);

    removeValue(WEB_SETTING_ITEMS);
    actorDispatch(
      'appSettings',
      {
        original: [],
        objectList: {},
      },
      {
        callerScopeName: 'settings-api 3 => changeResourcesHandler',
      },
    );
  }

  actorDispatch('showSettingsLoading', false, {
    callerScopeName: 'settings-api => changeResourcesHandler 4',
  });
};

actorOnDispatch('urlInfo', changeResourcesHandler);
