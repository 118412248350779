import { FunctionComponent } from 'react';
import { useState } from 'react';
import { useLogin, useNotify, useSafeSetState } from 'ra-core';
import { useTranslate } from 'react-admin';
import LoginFormView from './login-form.view';

import type {
  FormDataInterface,
  LoginFormControllerInterface,
} from './login-form-type';

const LoginFormController: FunctionComponent<
  LoginFormControllerInterface
> = props => {
  const { redirectTo } = props;

  const [loading, setLoading] = useSafeSetState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();

  /**
   * @function validate
   * @param { FormDataInterface } values
   * @returns { Record<string, unknown> }  Record<string, unknown>
   */
  const validate = (values: FormDataInterface): Record<string, unknown> => {
    const errors: Record<string, unknown> = {
      username: undefined,
      password: undefined,
    };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  /**
   * @function submit
   * @param {FormDataInterface} values
   * @returns { void } void
   */
  const submit = (values: FormDataInterface): void => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
        );
      });
  };

  /**
   * @function handleShowPassword
   * @returns { void }
   */
  const handleShowPassword = (): void => {
    setIsShowPassword(prev => !prev);
  };

  return (
    <LoginFormView
      submit={submit}
      validate={validate}
      loading={loading}
      isShowPassword={isShowPassword}
      handleShowPassword={handleShowPassword}
    />
  );
};

export default LoginFormController;
