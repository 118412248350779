import { memo, ReactElement, useEffect, useState } from 'react';
import { GET_LIST, useTranslate } from 'react-admin';

import { showNotificationForUnknownError } from '../../../helper/general-function-helper';
import TodoNoteView from './todo-note.view';
import { actorDispatch } from '../../../type/actor-setup';
import { customRecordNotePin } from '../../relation-panel/note-relation/create-edit-note/constants';

import type { NoteItemInterface, TodoNoteTypeController } from './todo-note.type';
import { isEmpty } from '../../../helper/data-helper';

const TodoNoteController = memo((props: TodoNoteTypeController): ReactElement => {
  const { todoSelectedTaskInfo } = props;
  const [notesData, setNotesData] = useState<NoteItemInterface[]>([]);
  const translate = useTranslate();

  /**
   * get data result by request and set data to state
   * @function getNote
   * @returns {void} void
   */
  const getNote = (): void => {
    const detailTask = todoSelectedTaskInfo?.tasksdetails_id;
    if (isEmpty(detailTask)) return;

    const filter = [
      ['KeyID', '=', detailTask ?? ''],
      'and',
      ['IsPin', '=', 0],
      'and',
      ['tableid', '=', '310'],
    ];

    // FIXME: What is the resource to request?
    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        resource: `report/${customRecordNotePin}`,
        entity: 'note',
        disableNotification: true,
        requestParameters: {
          filter,
        },
        detailTask,
        onSuccess: response => {
          if (response.hasOwnProperty('data')) {
            setNotesData(response.data);
          }
        },
        onFailure: (error: unknown) => {
          showNotificationForUnknownError(error, translate);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'TodoNoteController => getNote',
      },
    );
  };

  useEffect(() => {
    getNote();
  }, [todoSelectedTaskInfo]);

  return (
    <TodoNoteView
      getNote={getNote}
      todoSelectedTaskInfo={todoSelectedTaskInfo}
      notesData={notesData}
    />
  );
});

export default TodoNoteController;
