import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  dialogMainContainer: {
    minWidth: '600px',
  },
  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  cancelBtn: {
    margin: '0 5px',
  },
  confirmButton: {
    color: theme.palette.primary.appLightBackgroundColor,
    background: theme.palette.primary.automationBackgroundConfirmButton,
  },
  personCommon: {
    padding: 5,
    paddingRight: 10,
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
  },
  personName: {
    marginLeft: 7,
    marginRight: 7,
    flex: 1,
  },
  iconDeleteSharePerson: {
    cursor: 'pointer',
  },
}));
