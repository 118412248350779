import { type ReactElement, useCallback, useEffect, useMemo, useRef } from 'react';

import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
  RecordKeyMode,
} from '../../../type/actor-setup';
import {
  FormActions,
  InitialData,
  InputRefContent,
  OnBlurParams,
} from '../form.type';
import { FormWithTabsProps } from './form-with-tab.type';
import { FormWithTabsView } from './form-with-tab.view';

//TODO: turn setting HOC to hook on https://jira.samiansoft.com/browse/RCT-1518
import { clone } from '../../../helper/data-helper';
import LoadingBoxView from '../../loading-box';
import { QuickFormWithTabsView } from './quick-form-with-tab.view';
import { useGetFormDefaultValues } from '../hooks/use-get-form-default-values';
import {
  checkTabErrors,
  getParentResource,
  prepareFormInputsCharacteristics,
  updateLayoutUiSpecs,
} from './form-with-tabs.helper';
import { useTranslate } from 'react-admin';
import { ParentInfo } from './form-with-tab.type';
import {
  prepareRelationList,
  prepareViewFields,
} from '../../show-record-with-relation/show-record-with-relation.helper';
import { RelationPanelControllerAllRelationInterface } from '../../relation-panel';
import { extractAllFieldsFromTabList, updateInputsState } from '../form.helper';
import { showNotification } from '../../../helper/general-function-helper';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../../../helper/settings-helper';
import NotFound from '../../NotFound';
import {
  getProcessTaskByMetaDataAndRecord,
  getRequiredFieldsFromDeactivateSubPanel,
} from '../../../helper/meta-helper';

import type {
  FieldType,
  GeneralMetaData,
  GlobalParametersInterface,
  ValidationError,
} from '../../../helper/Types';

const FormWithTabController = (props: FormWithTabsProps): ReactElement => {
  const {
    isQuickForm,
    isCreateMode,
    match,
    basePath,
    dialogData,
    loading,
    rootResource,
  } = props;

  const firstUpdatedRef = useRef<boolean>(false);
  const tabsTitlesRef = useRef<Record<number, HTMLParagraphElement | null>>({});
  const onDispatchRef = useRef<{ actionName: keyof ActorActionList; id: symbol }[]>(
    [],
  );

  // prettier-ignore
  const { current: currentResource } = actorGetActionValue('resources')!;

  const _resourceObj = useMemo(() => {
    return isQuickForm ? currentResource : rootResource ?? currentResource;
  }, [rootResource, currentResource, isQuickForm]);

  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const urlInfo = actorGetActionValue('urlInfo')!;
  const translate = useTranslate();

  const metaData = useMemo(
    () =>
      (clone(actorGetActionValue('metaData', _resourceObj.value)) ??
        {}) as unknown as GeneralMetaData,
    [_resourceObj],
  );

  const record = (actorGetActionValue(
    'record',
    `${_resourceObj.value}.${_resourceObj.type}`,
  )! ?? {}) as {
    FORM?: Record<string, unknown>;
    PARENT_RECORD?: Record<string, unknown>;
    FULL?: Record<string, unknown>;
  };

  const fullRecord = record[RecordKeyMode.FULL] ?? {};

  const finalTabList = useMemo(() => {
    if (metaData == null) return [];

    /**
     * It has been set the `process` data in `record`, already(i.e. in `global-api => changeResourcesHandler`)
     */
    return prepareViewFields(record?.FULL ?? {}, metaData);
  }, [_resourceObj]);

  let allFields: Array<FieldType> = extractAllFieldsFromTabList(finalTabList);

  actorSetActionValue('allFields', allFields, {
    path: `${_resourceObj.value}.${_resourceObj.type}`,
    replaceAll: true,
    callerScopeName: 'FormWithTabController',
  });

  const globalParameters = actorGetActionValue(
    'profile',
    'profileData.globalparameters',
  ) as GlobalParametersInterface;

  const { isLoading, setIsLoading } = useGetFormDefaultValues({
    allFields,
    globalParameters,
    isCreateMode,
    resource: _resourceObj,
    urlInfo,
    record,
    parentInfo: (dialogData?.parentInfo as ParentInfo) ?? {},
  });

  const parentResource = dialogData
    ? (dialogData.parentInfo as ParentInfo).parentResource
    : getParentResource(fullRecord);

  /**
   * @function getRelationRequiredFields
   * @returns { Record<string, FieldType> } Record<string, FieldType>
   */
  const getRelationRequiredFields = useCallback((): Record<string, FieldType> => {
    const parentMetaData = actorGetActionValue('metaData')?.[parentResource!];

    const parentRecord = actorGetActionValue(
      'record',
      `${parentResource}.${FormKeyMode.ROOT}.${RecordKeyMode.FULL}`,
    ) as Record<string, unknown>;

    return getRequiredFieldsFromDeactivateSubPanel({
      parentMetaData: parentMetaData as GeneralMetaData,
      currentMetaData: metaData,
      parentRecord: parentRecord,
    });
  }, [parentResource, _resourceObj]);

  allFields = useMemo(() => {
    const requiredFieldInDeactivateSubPanel = getRelationRequiredFields();
    for (const requiredFieldId of Object.keys(requiredFieldInDeactivateSubPanel)) {
      const targetIndex = allFields.findIndex(field => field.id == requiredFieldId);
      if (targetIndex === -1) continue;

      allFields[targetIndex].required = true;
    }

    return [...allFields];
  }, [_resourceObj]);

  const currentProcessInfo = useMemo(() => {
    return getProcessTaskByMetaDataAndRecord(
      metaData as GeneralMetaData,
      fullRecord,
    );
  }, [_resourceObj]);

  // compute `inputsInitialAppearanceCharacteristics`
  useMemo(() => {
    prepareFormInputsCharacteristics(
      parentResource,
      metaData,
      fullRecord,
      currentResource,
      currentProcessInfo,
    );
  }, [_resourceObj, parentResource]);

  useEffect(() => {
    /**
     * To run service validation
     * for parentField when create form in relation opens
     * immediately onBlur(runServiceValidation) should happens!
     */
    if (!firstUpdatedRef.current) {
      runServiceValidationOnParentField();
      firstUpdatedRef.current = true;
    }

    actorSetActionValue('checkTabErrors', () => {
      checkTabErrors(_resourceObj, tabsTitlesRef);
    });

    //TODO: convert to hook if possible
    let id = actorOnDispatch(
      'initialData',
      () => {
        const currentResource = actorGetActionValue('resources')!.current;
        /**
         * To detect the current active form, `isQuickForm` means a dialog form that opened now(i.e. we pushed a new resource that type of it, doesn't equal `ROOT`)
         * Otherwise when the `isQuickForm` is equal `false` & the type of pushed resource is equal `ROOT`, it's an active opened form
         */
        if (isQuickForm || currentResource.type === FormKeyMode.ROOT) {
          updateTabContents(true);
          return;
        }

        // When a dialog form is opened, if there is a form behind it, we have to empty data of that form and after the dialog was closed, will set the correct values based on it's process
        const inputsRef = actorGetActionValue('inputsRef', [
          _resourceObj.value,
          _resourceObj.type,
        ]) as Record<string, InputRefContent> | null;

        updateInputsState({
          inputsRef,
          formErrors: {},
          formData: {},
        });
      },
      { preserve: false },
    );

    onDispatchRef.current.push({ actionName: 'initialData', id });

    id = actorOnDispatch(
      'formMessages',
      () => {
        checkTabErrors?.(_resourceObj, tabsTitlesRef);
        updateTabContents();
      },
      { preserve: false },
    );

    onDispatchRef.current.push({ actionName: 'formMessages', id });

    id = actorOnDispatch(
      'resetForm',
      parameters => {
        if (!isQuickForm) {
          const currentResource = actorGetActionValue('resources')!.current;
          if (currentResource.type !== FormKeyMode.ROOT) return;
        }

        setIsLoading(true);
        if (parameters?.saveType === 'saveAndNew') {
          runServiceValidationOnParentField();
        }
      },
      {
        onDispatchDelay: true,
      },
    );

    onDispatchRef.current.push({ actionName: 'resetForm', id });

    id = actorOnDispatch(
      'refreshView',
      resource => {
        if (resource === 'all') {
          updateTabContents(false);
        }
      },
      {
        onDispatchDelay: true,
      },
    );

    onDispatchRef.current.push({ actionName: 'refreshView', id });

    return () => {
      actorSetActionValue('inputsRef', null, {
        path: `${_resourceObj.value}.${_resourceObj.type}`,
        replaceAll: true,
      });

      // remove inputsInitialAppearanceCharacteristics after unmount
      actorSetActionValue('inputsInitialAppearanceCharacteristics', null, {
        path: `${currentResource.value}.${currentResource.type}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}`,
      });

      for (const onDispatchData of onDispatchRef.current) {
        actorRemoveAction({
          actionName: onDispatchData.actionName,
          listenerId: onDispatchData.id,
        });
      }
    };
  }, [isQuickForm, _resourceObj]);

  useEffect(() => {
    if (isLoading) return;

    updateTabContents();
    updateLayoutUiSpecs(_resourceObj, currentProcessInfo);
  }, [isLoading, currentProcessInfo]);

  const runServiceValidationOnParentField = useCallback(() => {
    if (!isQuickForm) return;

    if (dialogData == null || _resourceObj.type !== FormKeyMode.RELATION) {
      return;
    }
    // We need to run the following code after other `resetForm` happens
    const childFieldName = dialogData.childFieldName as string;
    const childFieldNameValue = dialogData[childFieldName];

    formActionsHandler(FormActions.InputBlur, {
      fieldName: childFieldName,
      value: childFieldNameValue,
      runValidationSuccessCallback: (): void => {
        setIsLoading(false);
      },
      runValidationFailureCallback: (): void => {
        setIsLoading(false);
      },
    } as OnBlurParams);
  }, []);

  const tabChangeHandler = useCallback(() => {
    requestAnimationFrame(() => {
      updateTabContents(false);
      updateLayoutUiSpecs(_resourceObj, currentProcessInfo);
    });
  }, [_resourceObj]);

  const updateTabContents = useCallback(
    (withInitialData = false) => {
      const inputsRef = actorGetActionValue('inputsRef', [
        _resourceObj.value,
        _resourceObj.type,
      ]) as Record<string, InputRefContent> | null;

      let data = {};
      if (withInitialData) {
        data =
          (actorGetActionValue('initialData', [
            _resourceObj.value,
            _resourceObj.type,
          ]) as InitialData | null) ?? {};
      } else {
        data =
          (actorGetActionValue('formData', [
            _resourceObj.value,
            _resourceObj.type,
          ]) as FormData | null) ?? {};
      }

      const formErrors =
        (actorGetActionValue('formMessages', [
          _resourceObj.value,
          _resourceObj.type,
        ]) as ValidationError | null) ?? {};

      updateInputsState({
        inputsRef,
        formErrors,
        formData: data,
      });
    },
    [_resourceObj.value, isQuickForm],
  );

  const recordId = match.params.id;

  const processInfo = useMemo(() => {
    const { __processuniqueid, positionid, stateid } =
      record[RecordKeyMode.FULL] ?? {};

    return {
      processuniqueid: (__processuniqueid ?? null) as string | null,
      positionid: (positionid ?? null) as string | null,
      stateid: (stateid ?? null) as string | null,
    };
  }, [record]);

  const relations: RelationPanelControllerAllRelationInterface = useMemo(() => {
    if (isLoading) return { isCheckedRelations: [], unCheckedRelations: [] };

    return prepareRelationList(
      finalTabList,
      _resourceObj.value,
      recordId ?? '', // its ok because of create mode
      processInfo,
    );
  }, [recordId, finalTabList, _resourceObj, isLoading]);

  const hasRelationNote = useMemo(
    () =>
      relations.isCheckedRelations?.filter(rel => rel.relationType === 'note')
        .length > 0,
    [relations.isCheckedRelations],
  );

  if (isLoading) {
    if (!allFields.length) {
      return (
        <NotFound
          hideBackButton
          title={translate('form.noFieldsInLayout')}
          description={isCreateMode ? translate('form.willCreateRawRecord') : null}
        />
      );
    }

    return <LoadingBoxView />;
  }

  if (!isCreateMode && record == null) {
    showNotification(translate('general.noData'), 'error');
  }

  if (isQuickForm) {
    return (
      <QuickFormWithTabsView
        tabList={finalTabList} // final tab list
        basePath={basePath}
        metaData={metaData}
        record={record?.[RecordKeyMode.FORM]}
        tabChangeHandler={tabChangeHandler}
        tabsTitlesRef={tabsTitlesRef}
      />
    );
  }

  return (
    <FormWithTabsView
      tabList={finalTabList} // final tab list
      match={match}
      showRelations={!isCreateMode} // If `isCreateMode` is `true` then `showRelations` should be `false`
      metaData={metaData}
      basePath={basePath}
      record={record?.[RecordKeyMode.FORM]}
      relations={relations.isCheckedRelations}
      tabChangeHandler={tabChangeHandler}
      tabsTitlesRef={tabsTitlesRef}
      hasRelationNote={hasRelationNote}
      processInfo={processInfo}
      loading={loading}
      resource={rootResource?.value}
    />
  );
};

FormWithTabController.defaultProps = {
  metaData: {
    quickTabPages: [
      {
        quickGroups: [],
        name: '',
        tableName: '',
        moduleName: '',
        title: '',
        priority: 0,
        translatedTitle: {},
      },
    ],
  },
  isCreateMode: false,
  isQuickForm: false,
  location: { search: '' },
  match: {},
  basePath: '',
};

export default FormWithTabController;
