import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  imageContainer: {
    borderRadius: 5,
    padding: '10px 10px 5px',
  },

  downloadLink: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      textDecoration: 'none',
    },
  },

  icon: {
    margin: 7,
  },

  image: {
    width: '100%',
  },
}));
