import { CustomTheme } from '@web/dropdown';
import { ValidationErrorMessageType } from '../../../helper/Types';

/**
 * get color base of request status
 * @function getColorBaseOfStatus
 * @param { ValidationErrorMessageType | undefined } status
 * @param { CustomTheme } theme
 * @returns {string}
 */
export const getColorBaseOfStatus = (
  status: ValidationErrorMessageType | undefined,
  theme: CustomTheme,
): string => {
  switch (status) {
    case 'warning': {
      return theme.palette.warning.main;
    }
    case 'info': {
      return theme.palette.info.main;
    }
    case 'success': {
      return theme.palette.success.main;
    }
    case 'error': {
      return theme.palette.error.main;
    }

    default: {
      return theme.palette.primary.appPrimaryBorderInputColor;
    }
  }
};
