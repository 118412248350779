import { FormControl, TextField } from '@material-ui/core';
import { useStyles } from './dynamic-chart-settings-selector.style';
import { useTranslate } from 'react-admin';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SortPickerController from './sort-picker/sort-picker.controller';
import { Autocomplete } from '@material-ui/lab';
import { ThresholdColorRange } from './threshold-color-range';

import type { FC } from 'react';
import type { DynamicChartSettingsSelectorViewProps } from './dynamic-chart-settings-selector.type';

const DynamicChartSettingsSelectorView: FC<
  DynamicChartSettingsSelectorViewProps
> = props => {
  const { options, handleSelect, gadgetValues, menuItems } = props;
  const classes = useStyles();

  const translate = useTranslate();
  return (
    <div>
      {options?.length == 0 && <div>{translate('gadget.settingsNotFound')}</div>}
      {options.map(field => {
        let valueInGadgetValues = gadgetValues[field.keyInGadgetData];
        let compatibleValue;

        if (field.inputType === 'multiSelect') {
          if (valueInGadgetValues && !Array.isArray(valueInGadgetValues)) {
            valueInGadgetValues = [valueInGadgetValues];
          }
          compatibleValue = menuItems?.filter(menu =>
            valueInGadgetValues?.some(valueObject => valueObject == menu.name),
          );
        } else {
          compatibleValue = menuItems?.find(
            menu => menu.name == valueInGadgetValues ?? '',
          );
        }
        return (
          <FormControl className={classes.formControl} fullWidth>
            {(() => {
              switch (field.inputType) {
                case 'multiSelect':
                  return (
                    <Autocomplete
                      multiple
                      options={menuItems ?? []}
                      getOptionLabel={option => option.title?.toString()}
                      value={compatibleValue ?? []}
                      onChange={(e, newValue) => {
                        handleSelect(
                          field,
                          newValue.map(item => item.name).join(','),
                          field.inputType,
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={field.label}
                        />
                      )}
                    />
                  );

                case 'textField':
                  return (
                    <TextField
                      onChange={event =>
                        handleSelect(field, event.target.value as string)
                      }
                      value={valueInGadgetValues ?? ''}
                      label={field.label}
                    />
                  );

                case 'boolean':
                  return (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={valueInGadgetValues}
                          onChange={_event => {
                            handleSelect(field, _event.target.checked);
                          }}
                          name={field.keyInGadgetData}
                        />
                      }
                      label={field.label}
                    />
                  );
                case 'sortField':
                  return (
                    <SortPickerController
                      onChange={value => handleSelect(field, value)}
                      menuItems={menuItems}
                      value={valueInGadgetValues}
                    />
                  );
                case 'thresholdColorRange':
                  return (
                    <ThresholdColorRange
                      onChange={value => handleSelect(field, value)}
                      value={valueInGadgetValues}
                    />
                  );
                case 'displayNumber':
                  return (
                    <TextField
                      type="number"
                      onChange={event => handleSelect(field, event.target.value)}
                      value={valueInGadgetValues ?? 5}
                      label={field.label}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                    />
                  );

                default:
                  return (
                    <Autocomplete
                      options={menuItems}
                      value={
                        Array.isArray(compatibleValue)
                          ? compatibleValue[0] ?? null
                          : compatibleValue ?? null
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={field.label}
                        />
                      )}
                      getOptionLabel={option => option.title.toString()}
                      onChange={(event, value) => {
                        handleSelect(field, value?.name ?? '');
                      }}
                    />
                  );
              }
            })()}
          </FormControl>
        );
      })}
    </div>
  );
};

export default DynamicChartSettingsSelectorView;
