import { FC, useMemo } from 'react';
import { API_URL, getValue } from '../../../core/configProvider';
import { isJsonEncodedString } from '../../../helper/data-helper';
import { downloadFile } from './multi-file-stream-field.helper';
import { MultiFileStreamFieldControllerInterface } from './multi-file-stream-field.type';
import MultiFileStreamFieldView from './multi-file-stream-field.view';

const MultiFileStreamFieldController: FC<
  MultiFileStreamFieldControllerInterface
> = props => {
  const { record, field } = props;

  const value = record?.[field.name] ?? [];

  const items = useMemo(() => {
    return isJsonEncodedString(value)
      ? JSON.parse(value as string)
      : Array.isArray(value)
      ? value
      : [];
  }, [value]);

  /**
   * download file
   * @function handleDownloadFile
   * @param filePath
   * @returns {void} void
   */
  const handleDownloadFile = filePath => {
    const fullUrl = getValue(API_URL);
    downloadFile(filePath, fullUrl);
  };

  return (
    <MultiFileStreamFieldView
      items={items}
      disabled={false}
      onItemClick={handleDownloadFile}
    />
  );
};

export default MultiFileStreamFieldController;
