import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    width: '100% !important',
    height: '100% !important',
  },
  iframeContainer: {
    height: '100%',
    width: '100%',
  },
}));
