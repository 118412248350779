import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  itemMenu: {
    gap: 12,
  },
  root: {
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    height: 17,

    '&[disabled]': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[700],
    },

    '& .ui.fluid.dropdown': {
      border: 'none',
      borderRadius: 0,
      background: 'none',
      padding: '2px 0',
      minHeight: 'auto',
      boxShadow: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      flexBasis: '100%',
      height: 17,
    },

    '& .ui.selection.active.dropdown:hover': {
      border: 'none',
      boxShadow: 'none',
    },

    '& input': {
      fontFamily: theme.typography.fontFamily,
      padding: '2px 0 !important',
    },

    '& .dropdown.icon': {
      color: theme.palette.primary.appPrimaryDisableIconColor,
      padding: '2px 5px !important',
    },

    '& .ui.loading.selection.dropdown>i.icon': {
      padding: '8px 10px !important',
    },

    '& .ui.dropdown .menu': {
      top: '133%',
      [theme.breakpoints.down('sm')]: {
        top: '112%',
      },
    },

    '& .ui.selection.active.dropdown .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.active.dropdown:hover .menu': {
      border: `1px solid ${theme.palette.primary.appPrimaryDividerColor}`,
    },

    '& .ui.selection.dropdown .menu>.item': {
      fontSize: 13,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.search.dropdown>.text': {
      fontSize: 13,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      flexBasis: '90%',
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
    },

    '& .ui.selection.dropdown .menu': {
      width: 'max-content',
      minWidth: 'auto',
    },

    '& .ui.upward.dropdown>.menu': {
      bottom: 'auto',
    },

    '& .ui.fluid.dropdown>.dropdown.icon': {
      float: 'none',
    },
  },

  legend: {
    fontSize: 10,
    lineHeight: 0,
    color: theme.palette.primary.appSecondaryTextColor,
    display: 'block',
    padding: '0 3px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },

  fieldsetError: {
    border: `1px solid ${theme.palette.error.main}`,
  },

  legendError: {
    color: theme.palette.error.main,
  },

  dropdownContainer: {
    display: 'flex',
    height: 17,
    alignItems: 'center',
    '& span': {
      cursor: 'pointer',
    },

    margin: 0,
    justifyContent: 'space-between',
    flexBasis: '100%',

    position: 'absolute',
    width: '100%',
  },

  errorText: {
    position: 'absolute',
    right: 90,
    top: 10,
    width: 100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 8,
    [theme.breakpoints.down('lg')]: {
      right: 18,
    },
    [theme.breakpoints.down('sm')]: {
      top: 3,
    },
  },

  label: {
    fontSize: 13,
    color: theme.palette.primary.appSecondaryTextColor,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },

  dropdownInPuzzleForm: {
    margin: '7px 3px 0',
  },
}));
