import { FC, memo } from 'react';
import { useTranslate } from 'react-admin';

import { MailActions, MailInterface, OnOpenReferenceFormParams } from '../../..';
import { showNotification } from '../../../../../helper/general-function-helper';
import {
  callQuickAccess,
  openNewTab,
} from '../../../../../helper/QuickAccessHelper';
import { actorDispatch } from '../../../../../type/actor-setup';
import { MailSummaryInterface } from './mail-summary.type';
import MailSummaryView from './mail-summary.view';

const MailSummaryController: FC<MailSummaryInterface> = memo(props => {
  const { mailActionsHandler, mail, isMainMail, expand, onRecallClick } = props;

  const translate = useTranslate();

  /**
   * @function onShowDelegationHandler
   * @returns { Promise<void> }
   */
  const onShowDelegationHandler = async (): Promise<void> => {
    if (mail && mail.delegationlink) {
      try {
        const url = await callQuickAccess(mail.delegationlink.toString());
        openNewTab(url);
      } catch (error) {
        showNotification(error as string, 'warning');
      }
    }
  };

  /**
   * @function onRecallHandler
   * @param { React.MouseEvent<HTMLButtonElement, MouseEvent> } event
   * @returns { void }
   */
  const onRecallHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    if (mail) {
      onRecallClick &&
        onRecallClick({
          doctype_id: mail.doctype_id,
          doc_id: mail.doc_id,
          refrences_id: mail.refrences_id,
        } as MailInterface);
    }
  };

  /**
   * to reply mail
   * @function onReplyToMail
   * @param { React.MouseEvent<HTMLButtonElement, MouseEvent> } event
   * @returns { void }
   */
  const onReplyToMail = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    if (mail) {
      const replyFormData = {
        ccinputisactive: false,
        bccinputisactive: false,
        topersoninfo_id: mail.frompersoninfo_id,
        __topersoninfo_id_value: mail.fromperson,
      } as Record<string, unknown>;

      actorDispatch('selectedDoc', {
        doc_id: mail.doc_id,
        doctype_id: mail.doctype_id,
        refrences_id: mail.refrences_id,
      });

      mailActionsHandler &&
        mailActionsHandler(MailActions.onOpenReferenceForm, {
          formData: replyFormData,
          formTitle: translate('mail.reply'),
        } as OnOpenReferenceFormParams);
    }
  };

  /**
   * to reply all mail
   * @function onReplyAllToMail
   * @param { React.MouseEvent<HTMLButtonElement, MouseEvent> } event
   * @returns { void }
   */
  const onReplyAllMail = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    if (mail) {
      const replyAllFormData = {
        ccinputisactive: false,
        bccinputisactive: false,
        topersoninfo_id: mail.frompersoninfo_id,
        ccpersoninfo_id: mail.ccpersoninfo_id,
        bccpersoninfo_id: mail.bccpersoninfo_id,
        __bccpersoninfo_id_value: mail.__bccpersoninfo_id_value,
        __ccpersoninfo_id_value: mail.__ccpersoninfo_id_value,
        __topersoninfo_id_value: mail.fromperson,
      } as Record<string, unknown>;

      actorDispatch('selectedDoc', {
        doc_id: mail.doc_id,
        doctype_id: mail.doctype_id,
        refrences_id: mail.refrences_id,
      });

      mailActionsHandler &&
        mailActionsHandler(MailActions.onOpenReferenceForm, {
          formData: replyAllFormData,
          formTitle: translate('mail.replyAll'),
        } as OnOpenReferenceFormParams);
    }
  };

  return (
    <MailSummaryView
      mail={mail}
      isMainMail={isMainMail}
      expand={expand}
      onShowDelegationHandler={onShowDelegationHandler}
      onRecallHandler={onRecallHandler}
      onReplyToMail={onReplyToMail}
      onReplyAllMail={onReplyAllMail}
    />
  );
});

export default MailSummaryController;
