import { useEffect, type FC } from 'react';
import { useTranslate } from 'react-admin';

import ShowPermissionsView from './show-permissions.view';
import { actorGetActionValue, actorSetActionValue } from '../../type/actor-setup';
import { IS_ADMIN_USER, getValue } from '../../core/configProvider';
import NotFound from '../NotFound';
import { showNotificationForUnknownError } from '../../helper/general-function-helper';

const ShowPermissionsController: FC = () => {
  const rootResource = actorGetActionValue('resources')?.current?.value;
  const isAdmin = getValue(IS_ADMIN_USER);
  const translate = useTranslate();

  /**
   * @function onTabChange
   * @param {string} activeKey
   * @returns {void} void
   */
  const onTabChange = (activeKey: string): void => {
    actorSetActionValue('userPermissionValue', { selectedTab: activeKey });
  };
  useEffect(() => {
    if (!isAdmin) {
      showNotificationForUnknownError(
        translate('ra.auth.authError'),
        translate,
        true,
      );
    }
  }, []);

  if (!isAdmin) {
    return <NotFound />;
  }

  return (
    <ShowPermissionsView
      handleTabChange={onTabChange}
      rootResource={rootResource ?? ''}
    />
  );
};

export default ShowPermissionsController;
