import { ReactElement } from 'react';
import { RichTextEditorFieldViewInterface } from './rich-text-editor-field.type';
import { Icon, IconButton } from '@material-ui/core';

import { useStyles } from './rich-text-editor-field.style';

const RichTextEditorFieldView = (
  props: RichTextEditorFieldViewInterface,
): ReactElement => {
  const { value, config, isPrintMode, handlePrint } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {!isPrintMode && (
        <div className={classes.toolbarContainer}>
          <IconButton onClick={handlePrint} color="primary" id="printButton">
            <Icon>print</Icon>
          </IconButton>
        </div>
      )}
      <div className="ck ck-toolbar ck-toolbar_grouping" />
      <div className="ck-content" dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );
};

export default RichTextEditorFieldView;
