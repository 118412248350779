import { ChangeEvent, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

import {
  CREATE,
  VIEW,
  EDIT,
  createOrUpdateForm,
  getFormResourceId,
  getParamsFromURL,
  showCustomForm,
} from './form-builder.helper';
import FormBuilderView from './form-builder.view';
import { actorDispatch } from '../../type/actor-setup';
import { showNotificationForUnknownError } from '../../helper/general-function-helper';
import { parseJSON } from '../../core/configProvider';

import type {
  ComponentMode,
  CustomFormInterface,
  FormIOComponentsInterface,
  FormDetailInterface,
} from './form-builder.type';

const FormBuilderController: FC = () => {
  const location = useLocation();
  const translate = useTranslate();
  const { formuid, ver, mainaccountid, personinfoid } = getParamsFromURL(
    location.search,
  );
  const isEditable = location.pathname.includes('formbuilder');

  const isInViewMode = !!formuid;

  const [componentMode, setComponentMode] = useState<ComponentMode>(
    isInViewMode ? VIEW : CREATE,
  );
  const [formIOComponents, setFormIOComponents] =
    useState<FormIOComponentsInterface>({
      display: 'form',
      components: [
        {
          type: 'button',
          action: 'submit',
          label: translate('formBuilder.submitButton'),
          theme: 'primary',
        },
      ],
    });
  const formRef = useRef<FormDetailInterface>({
    formName: '',
  });

  /**
   * @function handleSuccessGetForm
   * @param {{data: CustomFormInterface []}} response
   * @returns { void }
   */
  const handleSuccessGetForm = (response: { data: CustomFormInterface[] }): void => {
    try {
      const customFormData = parseJSON<Record<string, unknown>[]>(
        response.data[0].formdesignjson,
      );
      if (!customFormData) return;
      setFormIOComponents({ display: 'form', components: customFormData });
      showCustomForm(customFormData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isInViewMode && componentMode !== EDIT) {
      actorDispatch('crudAction', {
        type: 'GET_LIST',
        resource: getFormResourceId,
        requestParameters: {
          filter: [
            ['formUID', '=', formuid],
            ['version', '=', ver],
          ],
        },
        onSuccess: handleSuccessGetForm,
        onFailure: (error: unknown) => {
          showNotificationForUnknownError(error, translate);
        },
      });
    }
  }, []);

  useEffect(() => {
    if (componentMode === VIEW) {
      showCustomForm(formIOComponents.components);
    }

    return () => {
      if (componentMode === VIEW) {
        //TODO: this should be handled by a better way
        //there was no other way!!!!!!!!!!!!!!!!!!! i had to do it !!!
        document.getElementsByClassName('formio-form')[0]?.remove();
      }
    };
  }, [componentMode]);

  /**
   * @description this function is for creating a custom form or editing it, it is using a function from its helper file for more detail check there
   * @function createOrUpdateCustomForm
   * @returns { void } void
   */
  const createOrUpdateCustomForm = (): void => {
    createOrUpdateForm({
      formName: formRef.current.formName,
      formDesignJson: JSON.stringify(formIOComponents.components),
      formuid,
    });

    showCustomForm(formIOComponents.components);
  };

  /**
   * @function onEditButtonClick
   * @return {void} void
   */
  const onEditButtonClick = (): void => {
    setComponentMode(EDIT);
  };

  /**
   * @function onCancelEditForm
   * @return {void} void
   */
  const onCancelEditForm = (): void => {
    setComponentMode(VIEW);
  };
  /**
   * @function handleInputChange
   * @param { ChangeEvent<HTMLInputElement> } event
   * @returns { void }
   */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    formRef.current.formName = event.target.value;
  };

  //https://formio.github.io/formio.js/app/examples/select.html
  //https://help.form.io/developers/form-builder
  //https://codesandbox.io/s/custom-formio-component-in-react-81qb6?from-embed=&file=/src/Custom/Toggle/Toggle.settingsForm.js
  // https://formio.github.io/formio.js/docs/
  // because in future we are going to have different translations here this should be in controller not in helper
  const customFormOptions = {
    template: 'bootstrap',
    language: 'fa',
    editForm: {
      textarea: [
        {
          key: 'api',
          ignore: true,
        },
        {
          key: 'validation',
          ignore: true,
        },
      ],
    },
    builder: {
      // for disabling side bars example below
      // premium: false,

      basic: {
        title: translate('formBuilder.basicTitle'),
        width: 10,
        default: false,
        weight: 0,
        components: {
          buttonCustomComponent: true,
          customButton: true,
          // example below for disabling a field
          // textfield: false,
          textarea: {
            title: 'samian Text Area',
            key: 'textarea',
            icon: 'terminal',
            schema: {
              label: 'samian Text Areaxxx',
              type: 'textarea',
              key: 'textarea',
            },
          },
        },
      },
      custom: {
        title: translate('formBuilder.customTitle'),
        width: 10,
        components: {
          firstName: {
            title: 'First Name',
            key: 'firstName',
            icon: 'terminal',
            schema: {
              label: 'First Name',
              type: 'textfield',
              key: 'firstName',
              input: true,
            },
          },
          samianbutton: {
            title: 'Samian Button',
            key: 'samianbutton',
            icon: 'terminal',
            schema: {
              label: 'Samian Button1zzz',
              type: 'button',
              key: 'samianbutton',
              input: true,
            },
          },
        },
      },
      advanced: {
        title: translate('formBuilder.advancedTitle'),
      },
      layout: {
        title: translate('formBuilder.layoutTitle'),
      },
      data: {
        title: translate('formBuilder.dataTitle'),
      },
      premium: {
        title: translate('formBuilder.premiumTitle'),
      },
    },
  };

  return (
    <FormBuilderView
      createOrUpdateCustomForm={createOrUpdateCustomForm}
      onEditButtonClick={onEditButtonClick}
      handleInputChange={handleInputChange}
      onCancelEditForm={onCancelEditForm}
      customFormOptions={customFormOptions}
      componentMode={componentMode}
      isEditable={isEditable}
      formIOComponents={formIOComponents}
    />
  );
};

export default FormBuilderController;
