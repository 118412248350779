import { RelationPanelControllerInterface } from '../../relation-panel';

/**
 * prepare relation resource base of relation type
 * @function getRelationResource
 * @param {RelationPanelControllerInterface} relation
 * @returns {string}
 */
export const getRelationResource = (
  relation: RelationPanelControllerInterface,
): string => {
  if (relation.relationType === 'report') {
    return `report/${relation.relationItemInMetaData.id}`;
  }

  if (relation.relationType === 'table') {
    return `${relation.relationItemInMetaData.moduleName}/${relation.relationItemInMetaData.moduleTableName}`;
  }

  // other types can implement here ...

  return '';
};
