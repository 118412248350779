import { GET_DROPDOWN } from '../../../core/data-Provider.helper';
import dataProvider from '../../../core/dataProvider';
import { getGadgetsDropId } from '../dashboard-page.helper';
import { GadgetsDropdownResponseInterface } from './dashboard-card-form-dialog.type';
import { isJsonEncodedString } from '../../../helper/data-helper';
import { parseJSON } from '../../../core/configProvider';

import type { GadgetInformation } from '../../dynamic-input/gadget-input';
import type { ValueFieldName } from '../../dynamic-chart';
import type {
  DashboardCardsDataInterface,
  DashBoardFormDataInterface,
} from '../dashboard-page.type';
import type { SortReportDataInterface } from '../../dynamic-input/gadget-input/gadget-design-dialog/setting-section/gadget-settings-tab/dynamic-chart-settings-selector/sort-picker/sort-picker.type';

export const COLOR_BOX_DEFAULT_VALUE = '#ffff';

/**
 * get all records from `report/gadgets` table trough dropdown
 * @function getPreDesignedGadgets
 * @param { string | undefined } specialGadgetUID
 * @returns {Promise<GadgetsDropdownResponseInterface[]>}
 */
export const getPreDesignedGadgets = async (
  specialGadgetUID?: string,
): Promise<GadgetsDropdownResponseInterface[]> => {
  const gadgetsDropdownResponse = await dataProvider(
    GET_DROPDOWN,
    getGadgetsDropId,
    {
      search: specialGadgetUID ? specialGadgetUID : '',
      pagination: {
        page: 1,
        perPage: 9999,
      },
    },
  );

  return gadgetsDropdownResponse?.data;
};

/**
 * @function getValueFieldNameFromJson
 * @param {string} gadgetJsonInformation
 * @returns {string | null}
 */
export const getValueFieldNameFromJson = (
  gadgetJsonInformation: string,
): ValueFieldName | null => {
  try {
    if (isJsonEncodedString(gadgetJsonInformation)) {
      return (
        parseJSON<GadgetInformation>(gadgetJsonInformation)?.valueFieldName ?? null
      );
    }
    return null;
  } catch {
    return null;
  }
};

/**
 * if value field name was a single string value then return it.
 * else check for if it was an array , choose first item of it
 * @function getFirstValueFieldNameFromArray
 * @param {unknown} valueFieldNameFinalValue
 * @returns {string | void}
 */
export const getFirstValueFieldNameFromArray = (
  valueFieldNameFinalValue: unknown,
): string | undefined => {
  try {
    if (typeof valueFieldNameFinalValue === 'string') {
      return valueFieldNameFinalValue.includes(',')
        ? valueFieldNameFinalValue.trim().split(',')[0]
        : valueFieldNameFinalValue;
    } else if (typeof valueFieldNameFinalValue === 'number') {
      return String(valueFieldNameFinalValue);
    } else if (Array.isArray(valueFieldNameFinalValue)) {
      return valueFieldNameFinalValue[0];
    }
    return undefined;
  } catch {
    return undefined;
  }
};

/**
 * getting the sorted list of json gadgets
 * @function getGadgetDefaultSort
 * @param {string} sortInformationInGadget
 * @returns {SortReportDataInterface}
 */
export const getGadgetDefaultSort = (
  sortInformationInGadget?: string,
): SortReportDataInterface => {
  const gadgetDefaultSort =
    sortInformationInGadget && isJsonEncodedString(sortInformationInGadget)
      ? parseJSON<SortReportDataInterface>(sortInformationInGadget)
      : null;

  return {
    isSortEnabled: gadgetDefaultSort?.isSortEnabled ?? false,
    sortField: gadgetDefaultSort?.sortField ?? '',
    sortOrder: gadgetDefaultSort?.sortOrder ?? 'desc',
  };
};

/**
 * compute dashboard card default form data
 * @param { DashboardCardsDataInterface | undefined } dashboardCardData
 * @returns { DashBoardFormDataInterface }
 */
export const getDashboardCardDefaultData = (
  dashboardCardData?: DashboardCardsDataInterface,
  valueFieldName?: ValueFieldName,
  sortInformationInGadget?: string,
): DashBoardFormDataInterface => {
  let computedSort;

  if (!dashboardCardData) {
    computedSort = getGadgetDefaultSort(sortInformationInGadget);
  } else {
    computedSort = {
      isSortEnabled: dashboardCardData?.isSortEnabled ?? false,
      sortOrder: dashboardCardData?.sortOrder ?? 'desc',
      sortField:
        dashboardCardData?.sortField ||
        getFirstValueFieldNameFromArray(valueFieldName?.[0]),
    };
  }
  return {
    color: dashboardCardData?.color || COLOR_BOX_DEFAULT_VALUE,
    title: dashboardCardData?.title || '',
    gadgetUniqueId: dashboardCardData?.gadgetUniqueId || '',
    customFilters: dashboardCardData?.customFilters || [],
    isFiltersConfirmed: dashboardCardData?.isFiltersConfirmed || false,
    reportDataRowNumbers: dashboardCardData?.reportDataRowNumbers || 25,
    autoRefreshReportData: dashboardCardData?.autoRefreshReportData || false,
    autoRefreshReportDataTime: dashboardCardData?.autoRefreshReportDataTime,
    ...computedSort,
  };
};
