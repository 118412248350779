import { AnnouncementPosts } from '../../announcement-posts';
import useStyles from './announcements-chart.style';

import type { FC } from 'react';
import type { AnnouncementsChartViewProps } from './announcements-chart.type';

const AnnouncementsChartView: FC<AnnouncementsChartViewProps> = props => {
  const { onChangePageHandler, announcementsData, paginationParams, height, width } =
    props;
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ width, height }}>
      <AnnouncementPosts
        onChangePageHandler={onChangePageHandler}
        paginationParams={paginationParams}
        data={announcementsData}
      />
    </div>
  );
};

export default AnnouncementsChartView;
