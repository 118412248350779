import { GET_LIST } from 'react-admin';
import { FieldType, MetaData } from '../../helper/Types';

import { actorDispatch } from '../../type/actor-setup';

import type { ServiceResultInterface } from '../dynamic-input/button-input';
import { DashboardInSidebarInterface } from '../menu/sidebar-menu';
import type {
  DashboardCardsCoordinatesInterface,
  GetDashboardApiInterface,
  GetDashboardEntries,
  SaveDashboardEntries,
} from './dashboard-page.type';

export const CONFIRMED = 'CONFIRMED';
export const NOT_CONFIRMED = 'NOT_CONFIRMED';
export const DASHBOARD_FILTER = 'dashboardFilter';

export const getGadgetsDropId = '9bf28d55-75c9-45b6-8e20-1901ec964292';
export const getUsersDashboardsReportId =
  'report/6f0f8a93-9e3e-4ddf-8c1c-48e615b3e409';
export const getDashboardInfoReportId =
  'report/b114da26-331b-403f-8303-5dad7efba7dc';
export const createOrEditDashboardService = '55497811-d380-467d-bae4-deed442e5433';
export const updateDashboardPinAndFav = '0e3fe890-de3d-4ce6-98e1-d2261037bdc8';

export const DashboardDefaultValue =
  '{"dashboardCardsCoordinates":[],"dashboardCardsData":[]}';

export const computeValidGridLayoutWidth = (): number => {
  return (window.innerWidth * 1000) / 1422;
};

/**
 * get dashboard information for special id
 * @function getDashboard
 * @param { GetDashboardEntries } entries
 * @returns { void } void
 */
export const getDashboard = (entries: GetDashboardEntries): void => {
  const { dashboardId, successCallback, failureCallback } = entries;

  actorDispatch(
    'crudAction',
    {
      type: GET_LIST,
      entity: 'gadget',
      resource: getDashboardInfoReportId,
      requestParameters: { filter: [['dashuid', '=', dashboardId]] },
      onSuccess: successCallback,
      onFailure: failureCallback,
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * save Dashboard
 * @function saveDashboard
 * @param { SaveDashboardEntries } entries
 * @returns { void } void
 */
export const saveDashboard = (entries: SaveDashboardEntries): void => {
  const {
    dashboardId,
    dashboardCardsCoordinates,
    dashboardCardsData,
    dashboardName,
    IsFav,
    isPin,
    successCallback,
    failureCallback,
  } = entries;

  const dashboardFullDataInJson = JSON.stringify({
    dashboardCardsCoordinates,
    dashboardCardsData: dashboardCardsData.map(item => {
      return {
        ...item,
        isFiltersConfirmed: true,
      };
    }),
  });

  actorDispatch(
    'crudAction',
    {
      type: 'RUN_SERVICE',
      actionUniqueId: createOrEditDashboardService,
      data: {
        params: {
          DashUID: dashboardId,
          DashJson: dashboardFullDataInJson,
          DashName: dashboardName,
          isfav: IsFav,
          ispin: isPin,
        },
      },
      onSuccess: (response: ServiceResultInterface = {}) => {
        successCallback?.(response);
      },
      onFailure: error => {
        failureCallback?.(error);
      },
    },
    {
      disableDebounce: true,
    },
  );
};

/**
 * get dashboard uniqueId field if exists
 * @function getDashboardUniqueIdField
 * @param { MetaData } metaData
 * @returns { FieldType | null }
 */
export const getDashboardUniqueIdField = (metaData: MetaData): FieldType | null => {
  if (
    Object.values(metaData?.['fields'] ?? {}).find(
      field => field?.['name'] === 'dashjson',
    )
  ) {
    return Object.values(metaData?.['fields'] ?? {}).find(
      field => field?.['name'] === 'uniqueid',
    ) as FieldType;
  }
  return null;
};

/**
 * @function getUsersDashboards
 * @return { void } void
 */
export const getUsersDashboards = (props: GetDashboardApiInterface): void => {
  const { successCallback, failureCallback } = props;

  actorDispatch(
    'crudAction',
    {
      type: 'GET_LIST',
      entity: 'user_dashboards',
      resource: getUsersDashboardsReportId,
      requestParameters: { filter: [] },
      onSuccess: (result: { data: DashboardInSidebarInterface[] }): void => {
        successCallback?.(result.data);
      },
      onFailure: failureCallback,
    },
    { disableDebounce: true, replaceAll: true },
  );
};

/**
 * add minW and minH to coordinates
 * @function limitSizeCard
 * @param {DashboardCardsCoordinatesInterface[]} coordinates
 * @param {number} limit
 * @returns {DashboardCardsCoordinatesInterface[]}
 */
export const limitSizeCard = (
  coordinates: DashboardCardsCoordinatesInterface[],
  limit = 4,
): DashboardCardsCoordinatesInterface[] => {
  return coordinates.map(item => ({ ...item, minH: limit, minW: limit }));
};
