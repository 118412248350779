import { CARTABLE_COLLAPSE_STATE } from '../../../core/configProvider';
import { showNotification } from '../../../helper/general-function-helper';
import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';

import { actorGetActionValue } from '../../../type/actor-setup';

import type { CartableCollapsingStateInSettingsType } from '../../../helper/Types';
import type {
  NavCollapsingChangeHandlerType,
  RoutesConfigInterface,
} from './navigation-container.type';

/**
 * @function navCollapsingChangeHandler
 * @param {boolean} isOpen
 * @param {SelectedNavItemDataInterface} selectedItem
 * @param {string} parentComponentName
 * @returns {void} void
 *
 * We want to save items like the following:
 *  {
 *    mail: {
 *      selectedNavItem: {
 *        id: '...',
 *        url: '...'
 *      },
 *      parents: [
 *        {
 *          id: 'folders'
 *        },
 *        {
 *          id: '...',
 *          folderId: '...'
 *        },
 *        ...
 *      ],
 *    }
 *    todo: {
 *      selectedNavItem: {
 *        id: '...',
 *        url: '...'
 *      },
 *      parents: [
 *        {
 *          id: 'folders'
 *        },
 *        {
 *          id: '...',
 *          folderId: '...'
 *        },
 *        ...
 *      ],
 *    },
 *    ...
 * }
 */
export const navCollapsingChangeHandler: NavCollapsingChangeHandlerType = (
  isOpen,
  selectedItem,
  parentComponentName,
) => {
  const { self: item } = selectedItem;
  const { translate } = actorGetActionValue('reactAdminHelpers')!;

  const lastCollapseState = getAppSettings<CartableCollapsingStateInSettingsType>(
    CARTABLE_COLLAPSE_STATE,
    true,
  ).value!;

  let finalCollapsingState = {};
  if (isOpen) {
    finalCollapsingState = navItemSelectOrOpenHandler({
      lastCollapseState,
      parentComponentName,
      selectedItem: item,
    });
  } else {
    finalCollapsingState = navItemDeSelectOrCloseHandler({
      lastCollapseState,
      parentComponentName,
      selectedItem: item,
    });
  }

  setAppSettings({
    key: CARTABLE_COLLAPSE_STATE,
    value: finalCollapsingState,
    forUser: true,
    onFailure: (): void => {
      //showNotification(translate('ra.updatingSettingsFailed'), 'error');
    },
  });
};

/**
 * @function navItemSelectOrOpenHandler
 * @param {
 *  selectedItem: RoutesConfigInterface;
 *  parentComponentName: string;
 *  lastCollapseState: CartableCollapsingStateInSettingsType | null;
 * } params
 * @returns {CartableCollapsingStateInSettingsType} an object
 */
function navItemSelectOrOpenHandler(params: {
  selectedItem: RoutesConfigInterface;
  parentComponentName: string;
  lastCollapseState: CartableCollapsingStateInSettingsType | null;
}): CartableCollapsingStateInSettingsType {
  const { selectedItem, parentComponentName, lastCollapseState } = params;
  const updatedCollapsingState = { ...lastCollapseState };

  if (!lastCollapseState || !(parentComponentName in lastCollapseState)) {
    updatedCollapsingState[parentComponentName] = {
      parents: [
        {
          id: selectedItem.id,
          folderId: selectedItem.folderId,
        },
      ],
    };
    return updatedCollapsingState;
  }

  if (selectedItem.type === 'item') {
    for (const key in updatedCollapsingState) {
      // Remove latest `selectedItem` to replace with the new
      if (updatedCollapsingState[key].selectedNavItem) {
        delete updatedCollapsingState[key].selectedNavItem;
        break;
      }
    }

    updatedCollapsingState[parentComponentName].selectedNavItem = {
      id: selectedItem.id,
      folderId: selectedItem.folderId,
      url: selectedItem.url,
    };
  } else {
    const targetIndex = updatedCollapsingState[
      parentComponentName
    ].parents.findIndex(option => option.id === selectedItem.id);
    if (targetIndex === -1) {
      updatedCollapsingState[parentComponentName].parents = [
        ...updatedCollapsingState[parentComponentName].parents,
        {
          id: selectedItem.id,
          folderId: selectedItem.folderId,
        },
      ];
    }
  }

  return updatedCollapsingState;
}

/**
 * @function navItemDeSelectOrCloseHandler
 * @param {
 *  selectedItem: RoutesConfigInterface;
 *  parentComponentName: string;
 *  lastCollapseState: CartableCollapsingStateInSettingsType | null;
 * } params
 * @returns {CartableCollapsingStateInSettingsType} an object
 */
function navItemDeSelectOrCloseHandler(params: {
  selectedItem: RoutesConfigInterface;
  parentComponentName: string;
  lastCollapseState: CartableCollapsingStateInSettingsType | null;
}): CartableCollapsingStateInSettingsType {
  const { selectedItem, parentComponentName, lastCollapseState } = params;
  const updatedCollapsingState = { ...lastCollapseState };

  if (selectedItem.id === 'folders' && selectedItem.type === 'collapse') {
    updatedCollapsingState[parentComponentName] = {
      parents: [],
    };

    return updatedCollapsingState;
  }

  /**
   * When `open` be equal `false`, We have to remove `id` of current selected item
   *  and if its children exist in the array, should be removed
   */
  const targetIndex =
    updatedCollapsingState[parentComponentName].parents?.findIndex(
      option => option.id === selectedItem.id,
    ) ?? -1;

  if (targetIndex > -1) {
    updatedCollapsingState[parentComponentName].parents.splice(targetIndex, 1);
    if (Array.isArray(selectedItem.children)) {
      for (const child of selectedItem.children) {
        const targetIndex = updatedCollapsingState[
          parentComponentName
        ].parents.findIndex(option => option.id === child.id);

        if (targetIndex > -1) {
          updatedCollapsingState[parentComponentName].parents.splice(targetIndex, 1);
        }
      }
    }
  }

  return updatedCollapsingState;
}
