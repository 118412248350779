import { FC, memo, useCallback, useRef, useState } from 'react';
import { useTranslate, useLocale } from 'react-admin';
import { useTheme } from '@material-ui/core';

import { showNotification } from '../../../../helper/general-function-helper';
import { ConfirmDialog } from '../../../confirm-dialog';
import {
  MultipleMailDetailBodyInterface,
  RecallConfirmDialogInterface,
} from './multiple-mail-detail-body.type';
import MultipleMailDetailBodyView from './multiple-mail-detail-body.view';
import { isEmpty } from '../../../../helper/data-helper';
import momentJalaali from 'moment-jalaali';
import {
  gregorianDateTimeFormat,
  jalaliDateTimeFormat,
} from '../../../../helper/CalendarMetaHelper';
import { actorDispatch } from '../../../../type/actor-setup';
import lodashIndexOf from 'lodash/indexOf';
import { cleanSubject, getSubjectTranslate } from '../../mail-section.helper';
import { useReactToPrint } from 'react-to-print';
import {
  MailActions,
  MailInterface,
  OnImportantMailParams,
  OnNewMessageParams,
  OnRecallParams,
  OnStarMailParams,
  SelectedMailInterface,
} from '../../mail-section.type';

const MultipleMailDetailBodyController: FC<MultipleMailDetailBodyInterface> = memo(
  props => {
    const { mailActionsHandler, selectedMail, selectedMailLoading } = props;
    const translate = useTranslate();
    const theme = useTheme();

    const locale = useLocale();
    const printComponentRef = useRef<HTMLDivElement>(null);
    const [mailCountToShow, setMailCountToShow] = useState<number>(10);

    momentJalaali.locale(locale);

    const [confirmDialogData, setConfirmDialogData] =
      useState<RecallConfirmDialogInterface>({
        isOpen: false,
        data: null,
      });

    /**
     * @function onRecallClick
     * @param { MailInterface } mail
     * @returns { void }
     */
    const onRecallClick = useCallback(
      (mail: MailInterface) => {
        setConfirmDialogData({
          isOpen: true,
          data: mail,
        });
      },
      [setConfirmDialogData],
    );

    /**
     * @function handleCloseRecallConfirmDialog
     * @returns { void }
     */
    const handleCloseRecallConfirmDialog = useCallback(() => {
      setConfirmDialogData({
        isOpen: false,
        data: null,
      });
    }, [setConfirmDialogData]);

    /**
     * @function onRecallSuccessCallback
     * @returns { void }
     */
    const onRecallSuccessCallback = (): void => {
      handleCloseRecallConfirmDialog();
      mailActionsHandler(MailActions.onRefreshMailDataDetail);
      showNotification(translate('mail.successRecall'), 'info');
    };

    /**
     * @function onRecallFailureCallback
     * @param { unknown } error
     * @returns { void }
     */
    const onRecallFailureCallback = (error: unknown): void => {
      showNotification(error as string, 'error');
      handleCloseRecallConfirmDialog();
    };

    /**
     * @function onConfirmRecallHandler
     * @returns { void }
     */
    const onConfirmRecallHandler = (): void => {
      mailActionsHandler(MailActions.onRecall, {
        mailsData: [confirmDialogData?.data],
        successCallback: onRecallSuccessCallback,
        failureCallback: onRecallFailureCallback,
      } as OnRecallParams);
    };

    /**
     * @function onImportantClick
     * @param {  MailInterface[] | null } mailsData
     * @param { boolean } isStarred
     * @returns { void }
     */
    const onImportantClick = (isImportant: boolean): void => {
      mailActionsHandler(MailActions.onImportantMail, {
        mailsData: [
          {
            doctype_id: selectedMail?.[0]?.doctype_id,
            doc_id: selectedMail?.[0]?.doc_id,
          } as MailInterface,
        ],
        isImportant,
      } as OnImportantMailParams);
    };

    /**
     * getMessageBodyForForwardMail
     * @param {SelectedMailInterface} selectedMailForForward
     * @returns string
     */
    const getMessageBodyForForwardMail = (
      selectedMailForForward: SelectedMailInterface,
    ): string => {
      if (!selectedMail) return '';

      const index = lodashIndexOf(selectedMail, selectedMailForForward);
      let messageBody = '';

      for (let i = index; i < selectedMail.length; ++i) {
        const mail: SelectedMailInterface = selectedMail[i];
        const date =
          locale === 'fa'
            ? momentJalaali(mail.referdate).format(jalaliDateTimeFormat)
            : momentJalaali(mail.referdate).format(gregorianDateTimeFormat);

        const toPersonInfo = !isEmpty(mail?.__refertopersoninfo_id_value)
          ? mail?.__refertopersoninfo_id_value
          : mail?.__topersoninfo_id_value;

        messageBody += `
          <br/>-------- ${translate('mail.forward')} --------<br/>
            ${translate('mail.from')}:  ${mail?.fromcontactinfo} <br/>
            ${translate('mail.to')}:  ${toPersonInfo} <br/>
            ${translate('mail.referenceListHeader.date')}:  ${date} <br/><br/><br/>
            ${
              !isEmpty(mail.messagebody) ? mail.messagebody : mail.messageparaph
            }<br/><br/>`;
      }
      return messageBody;
    };

    /**
     * forward mail
     * @function onForwardMail
     * @returns { void }
     */
    const onForwardMailClick = (selectedMail: SelectedMailInterface): void => {
      if (selectedMail) {
        const { sendText } = getSubjectTranslate(translate);

        let subject = !isEmpty(selectedMail?.messageparaph)
          ? selectedMail?.messageparaph
          : selectedMail.subject;

        subject = sendText + ' ' + cleanSubject(subject, translate);

        const messageBody = getMessageBodyForForwardMail(selectedMail);

        const forwardFormData = {
          attachfiles: JSON.parse(selectedMail.attachfiles),
          subject: subject,
          messagebody: messageBody,
        } as Record<string, unknown>;

        actorDispatch('selectedDoc', {
          doc_id: selectedMail.doc_id,
          doctype_id: selectedMail.doctype_id,
          refrences_id: selectedMail.refrences_id,
        });

        mailActionsHandler(MailActions.onOpenNewMessageForm, {
          formData: forwardFormData,
          formTitle: translate('mail.newMessage'),
        } as OnNewMessageParams);
      }
    };

    /**
     * print mail content
     * @const onPrintMail
     */
    const onPrintMail = useReactToPrint({
      content: () => printComponentRef.current,
    });

    /**
     * @function onStarClick
     * @param {  React.ChangeEvent<HTMLButtonElement> } event
     * @param { boolean } isStarred
     * @param {SelectedMailInterface} selectedMailForForward
     * @returns { void }
     */
    const onStarClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      isStarred: boolean,
      selectedMail: SelectedMailInterface,
    ): void => {
      event.stopPropagation();

      mailActionsHandler &&
        mailActionsHandler(MailActions.onStarMail, {
          mailsData: [
            {
              doctype_id: selectedMail.doctype_id,
              doc_id: selectedMail.doc_id,
              refrences_id: selectedMail.refrences_id,
            } as MailInterface,
          ],
          isStarred,
        } as OnStarMailParams);
    };

    /**
     * @function handleShowMoreClick
     * @returns {void}
     */
    const handleShowMoreClick = (): void => {
      setMailCountToShow(prev => prev + 10);
    };

    return (
      <>
        {confirmDialogData.isOpen && (
          <ConfirmDialog
            open={confirmDialogData.isOpen}
            title={translate('mail.recallConfirmMessage')}
            content=""
            handleClose={handleCloseRecallConfirmDialog}
            onConfirm={onConfirmRecallHandler}
            color={theme.palette.error.main}
          />
        )}
        <MultipleMailDetailBodyView
          mailActionsHandler={mailActionsHandler}
          selectedMail={selectedMail}
          selectedMailLoading={selectedMailLoading}
          onImportantClick={onImportantClick}
          onRecallClick={onRecallClick}
          onForwardMailClick={onForwardMailClick}
          onPrintMail={onPrintMail}
          printComponentRef={printComponentRef}
          onStarClick={onStarClick}
          mailCountToShow={mailCountToShow}
          handleShowMoreClick={handleShowMoreClick}
        />
      </>
    );
  },
);

export default MultipleMailDetailBodyController;
