import { Grid, IconButton, Menu } from '@material-ui/core';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { MouseEvent, ReactElement, useState } from 'react';
import { CirclePicker } from 'react-color';
import { ColorPickerMenuPopup } from './color-picker-menu-popup';
import { useStyles } from './color-picker.style';
import { ColorPickerViewInterface } from './color-picker.type';

const ColorPickerView = (props: ColorPickerViewInterface): ReactElement => {
  const {
    colors,
    onSelectColor,
    selectedColor,
    anchorEl,
    setAnchorEl,
    onCirclePickerChange,
  } = props;

  const classes = useStyles();
  const open = Boolean(anchorEl);

  /**
   * open color picker dialog
   * @function handleClick
   * @param {MouseEvent<HTMLButtonElement>} event
   * @return {void}
   */
  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * close color picker dialog
   * @function handleClose
   * @return {void}
   */
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Grid container className={classes.root}>
      {Array.isArray(colors) && colors.length > 0 && (
        <CirclePicker
          className={classes.color}
          color={selectedColor}
          circleSize={24}
          circleSpacing={7}
          colors={colors}
          onChange={onCirclePickerChange}
        />
      )}

      <IconButton size="small" onClick={handleClick}>
        <ColorizeIcon fontSize="small" />
      </IconButton>

      <Menu dir="ltr" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ColorPickerMenuPopup onSelectColor={onSelectColor} />
      </Menu>
    </Grid>
  );
};

export default ColorPickerView;
