import { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import BulkDeleteButton from './BulkDeleteButton';
import ServiceButtonContainer from './ServiceButtonContainer';
import PrintButtonContainer from './PrintButtonContainer';
import { isEmptyObject } from '../helper/data-helper';
import MetaProvider from './MetaProvider';
class ListBulkActions extends PureComponent {
  render() {
    const { translate, locale, resource, hasDelete, ...rest } = this.props;

    return (
      <Fragment>
        {isEmptyObject(this.props.metaData) ? (
          <MetaProvider resourceName={resource}>
            <PrintButtonContainer {...rest} locale={locale} resource={resource} />
          </MetaProvider>
        ) : (
          <PrintButtonContainer {...rest} locale={locale} resource={resource} />
        )}

        {isEmptyObject(this.props.metaData) ? (
          <MetaProvider resourceName={resource}>
            <ServiceButtonContainer {...rest} locale={locale} resource={resource} />
          </MetaProvider>
        ) : (
          <ServiceButtonContainer {...rest} locale={locale} resource={resource} />
        )}

        {hasDelete && <BulkDeleteButton {...rest} resource={resource} />}
      </Fragment>
    );
  }
}

ListBulkActions.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default translate(ListBulkActions);
