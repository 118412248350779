import ImportantDashboardView from './important-dashboard.view';
import { openNewTab } from '../../../helper/QuickAccessHelper';

import { type FC } from 'react';
import type { DataDashboardsInterface } from './important-dashboard.type';
import { isEmpty } from '../../../helper/data-helper';

const ImportantDashboard: FC<Omit<DataDashboardsInterface, 'showDashboard'>> = (
  props,
): JSX.Element => {
  const { title, id, url } = props;

  /**
   * @function showDashboard
   * @returns {void}
   */
  const showDashboard = (): void => {
    !isEmpty(url) ? openNewTab(url) : openNewTab(`dashboard/${id}`);
  };

  return (
    <ImportantDashboardView title={title} id={id} showDashboard={showDashboard} />
  );
};

export default ImportantDashboard;
