import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  formContainer: {
    minHeight: '550px',
    minWidth: '700px',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '0 1rem',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      minHeight: 'auto',
    },
  },
  btn: {
    paddingLeft: 32,
    paddingRight: 32,
    color: '#fff !important',
  },

  toggleBtn: {
    minWidth: '0 !important',
  },
  referenceFormContainer: {
    minHeight: '350px',
    minWidth: '700px',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '0 1rem',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      minHeight: 'auto',
    },
  },

  inputContainer: {
    flexGrow: 1,
    margin: '.5rem 0',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .ck.ck-editor__main': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    '& .ck-editor__editable': {
      minHeight: '200px !important',
      minWidth: '100%',
    },
  },

  newFolderFormContainer: {
    overflow: 'visible !important',
  },
  fabContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'fixed',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    bottom: '-5px',
  },
  mailSectionContainer: {
    background: theme.palette.primary.appLightBackgroundColor,
    width: '100%',
  },
  hiddenMailSection: {
    display: 'none',
  },
}));
