import { MutableRefObject, ReactElement } from 'react';
import {
  APIError,
  FieldType,
  Match,
  MetaData,
  MetaDataBase,
  RecordInterface,
  ShowNotification,
  Translate,
  ValidationError,
  ValidationErrorMessageType,
} from '../../helper/Types';
import { CrudGetOneAction, Identifier } from 'react-admin';
import { FormWithTabsProps } from './form-with-tabs';
import { DropdownDataKey, ResourceInterface } from '../../type/actor-setup';
import { DropdownMetaBase } from '../dynamic-input/dropdown-input';
import { Locale } from '../../type/global-types';
import { WMSFormProps } from '../wms/wms-layouts/wms-form';
import { TabInterface } from '../show-record-with-relation';

// ---------------------------- FORM & CONTROLLER ----------------------------

export type RelationMetaData = Record<string, unknown>;

export interface CustomFormInterface {
  toolbarProps: ToolbarProps;
  children: ReactElement<FormWithTabsProps | WMSFormProps | SimpleFormProps>;
  childProps: FormWithTabsProps | WMSFormProps | SimpleFormProps;
  isBottomToolbar: boolean;
  formName?: keyof FormActionProps;
  Toolbar?: ReactElement;
  loading?: boolean;
}

export interface UiVisibleInputActionInterface {
  inputNameToBeVisible: string;
  javaScriptUiVisible: string | null;
  dropColumnName?: unknown;
}

export interface UiEnableInputActionInterface {
  inputNameToBeEnable: string;
  javaScriptUiEnable: string | null;
  dropColumnName?: unknown;
}

export interface InputMessage {
  message: string;
  messageType: ValidationErrorMessageType;
}

export interface InputRefContent {
  [key: string]: unknown;

  inputValue: unknown;
  setInputValue?: (value: unknown) => void;
  setInputMessage?: (inputMessage?: {
    message: string;
    messageType: ValidationErrorMessageType;
  }) => void;
  setIsUiVisible: (value: boolean) => void;
  setIsUiEnabled: (value: boolean) => void;
  inputRef: MutableRefObject<HTMLInputElement | undefined>;
  field: FieldType;
  uiVisibleInputsAction: UiVisibleInputActionInterface[];
  uiEnableInputsAction: UiEnableInputActionInterface[];
}

export type FormActionsHandler = (type: string, payload?: unknown) => void; // `type` is the action type or name e.g. save, delete and etc... / `payload` are/is some data that may be needed to do an action

export type CustomChangeHandler = (
  mapFieldName: string,
  field: FieldType,
  recordPath: string,
  payload: CustomChangeHandlerPayloadType,
) => void;

export type CustomChangeHandlerPayloadType = {
  value: Record<string, unknown>;
  selectedRecord?: Record<string, unknown>;
  skipIfFormHasAlreadyValue?: boolean;
};

export interface FormBaseProps {
  formActionsHandler?: FormActionsHandler;
  inputsRef: MutableRefObject<Record<string, InputRefContent> | undefined>;
  resource: string;
}

export interface RawRelationInMetaData {
  relationResource: string;
  moduleName: string;
  moduleTableName: string;
  childFieldName: string;
}

export interface RelationAdditionalProps {
  relationPath: string;
  resource: string;
  originalRecord: Record<string, unknown> | undefined;
  allowUsePropsAfterCreateRelation: boolean;
}

export interface RelationPermissions {
  hasCreate: boolean;
  hasEdit: boolean;
  hasDelete: boolean;
  disabledFieldList: Record<number, boolean>;
}

export interface RelationsCommonProps {
  relationRecord: Record<string, unknown> | undefined;
  fullRecord: Record<string, unknown> | undefined;
  resource: string;
  basePath: string;
  match: Match;
}

export interface FormRelation {
  relationResource: string;
  relationPath: string;
  additionalProps: RelationAdditionalProps;
  defaultSelected: Record<string, unknown>;
  userSelected: Record<string, unknown>;
  relation: RawRelationInMetaData;
  relationsCommonProps: RelationsCommonProps;
  parentRecordIsEditable: boolean;
}

export interface SimpleFormProps {
  name: string;
}

export interface GridFormParamsInterface {
  id: number | null;
  isCreateMode: boolean;
  additionalFormData?: Record<string, unknown>;
  formName?: keyof FormActionProps;
  relationMode?: boolean;
  resource: string;
  onSuccess?: (...args: any) => void;
  onFailure?: (...args: any) => void;
}

export interface CreateEditFullFormSaveParams extends ExtraParamsInterface {
  id: number | null;
  validationParams: ValidateInputParams;
  isQuickForm: boolean;
}

export interface RelationEditDialogFormSaveParams
  extends CreateEditFullFormSaveParams {
  isRelationEditDialogOpen?: boolean;
}

export interface QuickEditDialogFormSaveParams extends ExtraParamsInterface {
  id: number | null;
  validationParams: ValidateInputParams;
  closeDialog: () => void;
  resourceType: string;
  target: string | null;
}

export interface ServiceDialogFormParams {
  selectedService: SelectedService | null;
  selectedIds: number[];
  customRefresh?: () => void;
  parentResource?: string;
  onFailure?: (...args: any) => void;
}

export interface SimpleFormParams {
  fields: FieldType[];
}

export interface ProfileFormParams {
  fields: FieldType[];
}

export interface VisitorFormParams {
  fields: FieldType[];
}

export interface GridColumnsSelectionDialogParams {
  filedList: FieldType[];
  resource: string;
  locale: string;
}

export interface inlineEditField {
  fields: FieldType[];
}
export interface ChangePasswordParams {
  closeDialog: () => void;
}

export interface SelectedService extends Object {
  uniqueId: string;
}

export interface DropdownQuickCreateFormSaveParams extends ExtraParamsInterface {
  isSaveAndNew: boolean;
  isSaveAndView: boolean;
  validationParams: ValidateInputParams;
  dropdownMeta: DropdownMetaBase;
}

// FIXME: Complete this type like others (e.g. DropdownQuickCreateFormSaveParams and etc...)
export type WMS = ExtraParamsInterface;

export interface FormActionProps {
  simpleForm: SimpleFormParams;
  createEditRecord: CreateEditFullFormSaveParams;
  quickEditDialog: QuickEditDialogFormSaveParams;
  createEditRecordPage: CreateEditFullFormSaveParams;
  dropdownQuickCreateForm: DropdownQuickCreateFormSaveParams;
  serviceDialogForm: ServiceDialogFormParams;
  profileForm: ProfileFormParams;
  relationEditDialogForm: RelationEditDialogFormSaveParams;
  quickCreateDialog: CreateEditFullFormSaveParams;
  changePassword: ProfileFormParams;
  gridForm: GridFormParamsInterface;
  VisitorForm: VisitorFormParams;
  wms: WMS; // FIXME: Complete this type like others (e.g. DropdownQuickCreateFormSaveParams and etc...)
  gridColumnSelectionDialog: GridColumnsSelectionDialogParams;
}

export interface FormControllerProps {
  children: ReactElement<CustomFormInterface>;
  isQuickForm: boolean;
  formName: keyof FormActionProps;
  disableValidationErrorNotification: boolean;
  additionalProps?: Record<string, unknown>;
  saveInlineEdit?: Function;
  loading?: boolean;
  metaData?: MetaData;
  rootResource?: ResourceInterface;
}

export interface FormControllerPropsFromRedux {
  id: number | null;
  record: Record<string, unknown>;
  relationRecord: Record<string, unknown>;
  originalRecord: Record<string, unknown>;
  parentRecord: Record<string, unknown>;
  parentId: string | unknown;
  parentResource: string;
  globalParameters: Record<string, unknown>;
  crudGetOne: (
    resource: string,
    id: Identifier,
    basePath: string,
    refresh?: boolean | undefined,
  ) => CrudGetOneAction;
  dispatchCrudUpdate: Function;
  dispatchCrudCreate: Function;
  redirectToPage: Function;
}

export type ChangeFormData = (fieldName: string, value: unknown) => void;

export interface OnBlurParams {
  fieldName: string;
  value: unknown;
  currentResource: ResourceInterface;
  runValidationSuccessCallback?: () => void;
  runValidationFailureCallback?: () => void;
}

export interface MapDropDataParams {
  params: {
    value: string | number;
    selectedRecord: CustomChangeHandlerPayloadType;
  };
  field: FieldType;
}

export interface GetDropDataParams {
  id: number | null;
  meta: DropdownMetaBase;
  params: Record<string, unknown>;
  uniqueId: string;
}

export interface GetFileParams {
  url: string;
  fieldName: string;
}

export interface GetCodingDefaultValueParams {
  rowId: number | null;
  record: RecordInterface;
  field: FieldType;
}

export type OnBlur = (
  fieldName: OnBlurParams['fieldName'],
  value: OnBlurParams['value'],
  currentResource: OnBlurParams['currentResource'],
  runValidationSuccessCallback?: () => void,
  runValidationFailureCallback?: () => void,
) => Promise<void>;

export interface ChangeFormValueParams {
  fieldName: string;
  value: unknown;
  changeWithoutValidate?: boolean;
  isHidden?: boolean;
  event?: React.KeyboardEvent<HTMLInputElement>;
}

export type ChangeFormValue = (params: {
  fieldName: ChangeFormValueParams['fieldName'];
  value: ChangeFormValueParams['value'];
  isHidden?: boolean;
  runValidationSuccessCallback?: () => void;
  runValidationFailureCallback?: () => void;
}) => void;

export interface UpdateDataWithReduxParams {
  resource: string;
  id: number | null;
  basePath: string;
}

export type UpdateDataWithRedux = (
  resource: UpdateDataWithReduxParams['resource'],
  id: UpdateDataWithReduxParams['id'],
  basePath: UpdateDataWithReduxParams['basePath'],
) => void;

export interface FindTagInputDataWithActorParams {
  resource: string;
  source: string;
  value: string;
}

export type FindTagInputDataWithActor = (
  resource: FindTagInputDataWithActorParams['resource'],
  source: FindTagInputDataWithActorParams['source'],
  value: FindTagInputDataWithActorParams['value'],
) => void;

// --------------------------- OTHERS ---------------------------

export interface ToolbarProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formActionsHandler: (...args: any) => void; // Because `args` possible be => ('mhf', 23, { key1: 'asdasd }, ['a', 'b'], ...)
  loading?: boolean;
}

export interface Field {
  value: string;
}

export interface TranslatedTitle {
  fa: string;
  en: string;
  ar: string;
}

export interface TabGroup {
  id?: number;
  translatedTitle?: TranslatedTitle;
  columnCount?: number;
  layout: Array<Array<FieldType | null | 'empty'>>;
  specialFields?: Array<FieldType>;
}

export type ExtractAllFieldsFromTabList = (
  tabs: Array<TabInterface>,
) => Array<FieldType>;

export type AfterValidationInputs = (
  validationResult: [boolean, Record<string, ValidationError>],
  fieldName: string,
  value: any,
) => void;

export interface ValidateInputParams {
  validationErrorsRef: React.MutableRefObject<
    Record<string, ValidationError> | undefined | null
  >;
  formDataRef: React.MutableRefObject<Record<string, unknown> | undefined | null>;
  metaData: MetaDataBase;
  allFields: Array<FieldType>;
  showNotification: ShowNotification;
  translate: Translate;
  locale: Locale;
  isQuickForm: boolean;
}

export type ValidateInput = (params: {
  fieldName: string;
  value: unknown;
  validationParams: ValidateInputParams;
  isHidden?: boolean;
}) => Promise<[boolean, Record<string, ValidationError>, string | null]>;

export interface ExtraParamsInterface {
  isSaveAndNew: boolean;
  isSaveAndView: boolean;
  dropdownMeta: DropdownMetaBase;
  formName?: keyof FormActionProps;
  mustRefresh?: boolean;
  relationMode?: boolean;
  dropdownId?: number;
  actionUniqueId?: string;
  onSuccess?: (...args: any) => void;
  onFailure?: (...args: any) => void;
  onCreate?: (...args: any) => void;
  closeDialog?: () => void;
  customRefresh?: () => void;
  customExternalResource?: string;
}

export type CheckValidationClientSide = (
  prevValidationErrors: Record<string, ValidationError>,
  formData: Record<string, unknown>,
  validationFields: Array<FieldType>,
  currentResource: ResourceInterface,
) => boolean;

export interface Action {
  error: { data: object; requestId: string };
  payload: { data: { id: number }; exceptions: object[]; userMessage?: string };
  data: Record<string, unknown>;
}

export interface ApiResponseWithValidationErrors {
  apiErrors: APIError;
  requestId: string;
}

export type CheckValidationErrorsInApiResponse = (
  formData: FormData,
  apiResponse: ApiResponseWithValidationErrors,
  validationParams: ValidateInputParams,
) => void;

export enum FormActions {
  Save = 'SAVE',
  InputBlur = 'INPUT_BLUR',
  InputChange = 'INPUT_CHANGE',
  GetDropData = 'GET_DROP_DATA',
  MapDropData = 'MAP_DROP_DATA',
  InputFocus = 'INPUT_FOCUS',
  InputKeyDown = 'INPUT_KEY_DOWN',
  GetFileInput = 'GET_FILE_INPUT',
  GetOrUpdateDataWithRedux = 'GET_OR_UPDATE_DATA_WITH_REDUX',
  GetCodingDefaultValue = 'GET_CODING_DEFAULT_VALUE',
  FindTagInputDataWithRedux = 'FIND_TAG_INPUT_DATA_WITH_REDUX',
  FetchDropdownData = 'FETCH_DROPDOWN_DATA',
  getDropdownData = 'GET_DROPDOWN_DATA',
  GetAuthorityDelegation = 'GET_AUTHORITY_DELEGATION',
  PutDelegation = 'PUT_DELEGATION',
  uploadFileStream = 'UPLOAD_FILE_STREAM',
}

export type ValidationErrors = Record<string, ValidationError>;
export type FormData = Record<string, unknown>;
export type InitialData = Record<string, unknown>;

export type CodingInputData = {
  isLoading: boolean;
  data: Object | null;
};

export type FileInputData = {
  isImage: boolean;
  src: string;
};

export type tagInputData = {
  isLoading: boolean;
  resultList: string[] | null;
  errorList: string | null;
};

interface GetDropdownDataParams {
  id: number | null;
  params: Record<string, unknown>;
  meta: DropdownMetaBase;
  uniqueId: string;
}

export interface OnFocusParams {
  fieldName: string;
  value: unknown;
  currentResource: ResourceInterface;
}

export type OnFocus = (
  fieldName: OnFocusParams['fieldName'],
  currentResource: OnFocusParams['currentResource'],
) => void;

export interface FetchDropdownDataPayload {
  option: Record<string, unknown>;
  dropdownMeta: DropdownMetaBase;
  resource: string;
  resourceType: string;
  searchValue?: string;
  hasMore?: boolean;
  isFilterInput?: boolean;
  exactRequestParams?: Record<string, unknown>;
  successCallback: (dropdownData: {
    [DropdownDataKey.ALL]?: Record<string, unknown>[];
    [DropdownDataKey.DATA]?: Record<string, unknown>[];
    [DropdownDataKey.TOTAL]?: number;
  }) => void;
  failureCallback?: (error?: unknown) => void;
}

export type GetDropdownData = (params: GetDropdownDataParams) => void;

export interface FormStyleProps {
  formName?: string;
  isBottomToolbar?: boolean;
}

export interface CheckFormInputsSpecsInterface {
  keepValueAfterSubmitFieldNameList: Array<string>;
  keepFocusAfterSubmitFieldNameList: Array<string>;
  ignoreToFocusFieldNameList: Array<string>;
  inputNameListSortedByPriority: Array<string>;
}
