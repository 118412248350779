import { makeStyles } from '@material-ui/core';
import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  field: {
    padding: 0,
  },
  dialogTitle: {
    padding: '5px',
    minWidth: '300px',
  },
  dialogContent: {
    padding: '5px',
  },
}));
