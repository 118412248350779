import moment from 'jalali-moment';
import { FC } from 'react';
import { Views, Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { BigCalendarViewInterface } from '.';
import jalaliLocalizer from '../../helper/localizers-jalaali-calendar/jalali-moment';
import ExtraParamsHoc from '../ExtraParamsHoc';
import { BigCalendarToolbarView, EventWrapper } from './custom-components-calendar';
import LoadingBox from '../loading-box';
import { useStyles } from './big-calendar.style';
import './big-calendar.style.css';
import { isEmpty } from '../../helper/data-helper';

const DragAndDropCalendar = withDragAndDrop(Calendar);

const BigCalendarView: FC<BigCalendarViewInterface> = props => {
  const classes = useStyles();
  const {
    moveEvent,
    onDropFromOutside,
    resizeEvent,
    onSelectSlot,
    onRangeChange,
    onSelectEvent,
    eventStyleGetter,
    onView,
    getColor,
    view,
    CustomToolbarView = BigCalendarToolbarView,
    currentDate,
    events,
    eventCustomProps,
  } = props;

  return (
    <div className={classes.calendar} data-style-main-calender="calender">
      <DragAndDropCalendar
        resizable
        selectable
        events={events}
        localizer={jalaliLocalizer}
        defaultDate={moment()}
        date={currentDate}
        defaultView={Views.MONTH}
        onEventDrop={moveEvent}
        onSelectSlot={onSelectSlot}
        components={{
          toolbar: CustomToolbarView,
          event: ExtraParamsHoc({
            eventCustomProps,
          })(EventWrapper),
          month: {
            dateHeader: ({ date, label }) => {
              const colorInfo = getColor(date);
              return (
                <>
                  <div
                    style={{ color: colorInfo.color }}
                    className={classes.containerCalendar}
                  >
                    <span className={classes.titleCalendar}>{label}</span>
                    <span className={classes.labelCalendar}>{colorInfo.title}</span>
                  </div>
                </>
              );
            },
          },
        }}
        onEventResize={resizeEvent}
        onDropFromOutside={onDropFromOutside}
        onSelectEvent={onSelectEvent}
        onNavigate={onRangeChange}
        eventPropGetter={eventStyleGetter}
        onView={onView}
        view={view}
        showAllEvents
        rtl
      />
    </div>
  );
};

export default BigCalendarView;
