import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  settingsMenu: {
    marginTop: '3rem',
    cursor: 'pointer',
  },

  settingsIcon: {
    marginInlineEnd: '0.25rem',
  },

  menuItemIcon: {
    marginInlineEnd: '0.5rem',
  },
}));
