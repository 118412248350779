import {
  POST_STREAM_FILE,
  POST_STREAM_MULTIPLE_FILE,
} from '../core/data-Provider.helper';
import dataProvider from '../core/dataProvider';
import { actorOnDispatch } from '../type/actor-setup';

actorOnDispatch(
  'uploadStreamFile',
  async action => {
    const { param, successCallback, failureCallback } = action;
    const { resource, file } = param;
    if (!resource) {
      return;
    }
    try {
      const response = await dataProvider(POST_STREAM_FILE, resource, {
        data: { file },
      });
      successCallback(response?.data ?? {});
    } catch (error) {
      if (error) failureCallback(error as string);
    }
  },
  {
    preserve: false,
  },
);

actorOnDispatch(
  'uploadStreamMultipleFile',
  async action => {
    const {
      param,
      successCallback,
      successAllFilesUploadedCallback,
      failureCallback,
    } = action;
    const { resource, files } = param;
    if (!resource) {
      return;
    }

    await dataProvider(POST_STREAM_MULTIPLE_FILE, resource, {
      data: { files },
      successAllFilesUploadedCallback,
      successCallback,
      failureCallback,
    });
  },
  {
    preserve: false,
  },
);
