// TODO: this file should refactor (ASAP) on  https://jira.samiansoft.com/browse/RCT-1482

import { FC, useState, useRef, MutableRefObject } from 'react';
import querystring from 'qs';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import { Toolbar, useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import {
  actorDispatch,
  actorGetActionValue,
  actorRemoveAction,
  actorSetActionValue,
  FormKeyMode,
} from '../type/actor-setup';

import { isEmpty, isEmptyObject } from '../helper/data-helper';
import { isSingleRecordTable } from '../helper/MetaHelper';
import CustomFormButton from './form-component-old/CustomFormButton';
import { ExtraParamsInterface, FormActions } from './form';
import { dummyFunc } from '../helper/InputHelper';
import { DropdownMetaBase } from './dynamic-input/dropdown-input';
import useKeyPress from '../hooks/useKeyPress';

import type { MetaData } from '../helper/Types';
import type { CustomTheme } from '../core/themeProvider';

interface DumbButtonInterface {
  children: React.ReactNode;
  id: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
  disabled?: boolean;
}

export type SaveType =
  | 'save'
  | 'saveAndNew'
  | 'saveAndView'
  | 'wmsSaveAfterServiceHasBeenRan';

interface QuickCreateButtonToolbarInterface {
  closeDialog: Function;
  metaData?: MetaData;
  disableFullFormButton: boolean;
  saving?: boolean;
  record?: { __processuniqueid: string | number };
  redirectToPage?: Function;
  resource?: string;
  redirect?: string;
  parentInfo?: Record<string, unknown>;
  handleSubmit?: Function;
  customSubmit?: (param) => void;
  customRefresh?: () => void;
  customClassName?: string;
  isFromDropdown?: boolean;
  justSaveAndClose?: boolean;
  formActionsHandler?: Function;
  dropdownId?: number;
  dropdownMeta?: DropdownMetaBase;
  onCreate?: Function;
  mustRefresh?: boolean;
  relationMode?: boolean;
  isLoading?: boolean;
  isEdit?: boolean;
}

const useStyles = makeStyles<CustomTheme>(theme => ({
  dumbButton: {
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      padding: '5px 0 !important',
    },
  },

  saveIconButton: {
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      padding: '5px 0 !important',
    },
  },

  spacer: {
    display: 'none',
  },

  mobileToolbar: {
    padding: 0,
  },
}));

// for render button with custom props
const DumbButton = (props: DumbButtonInterface) => {
  const { onClick, children, id, disabled, buttonRef } = props;
  const classes = useStyles();

  return (
    <Button
      className={classes.dumbButton}
      onClick={onClick}
      id={id}
      disabled={disabled}
      buttonRef={buttonRef}
      data-test-is-disabled={!!disabled}
    >
      {children}
    </Button>
  );
};

/**
 * this function will close dialog and redirect page into another page base of params
 * @function redirectWithData
 * @param {function} closeDialog
 * @param {function} redirectToPage
 * @param {string} resource
 * @param {object|null} formData
 * @param {string} redirect
 * @param {object} parentInfo
 * @returns {void}
 */
const redirectWithData = (
  closeDialog: Function,
  redirectToPage: Function,
  resource: string,
  formData: object = {},
  redirect: string,
  parentInfo: object = {},
  isFromDropdown = false,
) => {
  const parentResource = lodashGet(parentInfo, 'parentResource', null);
  const parentProcessUniqueId = lodashGet(parentInfo, 'parentProcessUniqueId', null);
  const parentPositionId = lodashGet(parentInfo, 'parentPositionId', null);
  const parentStateId = lodashGet(parentInfo, 'parentStateId', null);

  if (!isFromDropdown) {
    closeDialog();
  }

  if (parentInfo && !isEmptyObject(parentInfo)) {
    redirectToPage(
      `/${resource}/create?${querystring.stringify(formData, {
        strictNullHandling: true,
      })}` +
        (!isEmpty(parentResource) ? `&parentResource=${parentResource}` : '') +
        (!isEmpty(parentProcessUniqueId)
          ? `&parentProcessUniqueId=${parentProcessUniqueId}`
          : '') +
        (!isEmpty(parentPositionId) ? `&parentPositionId=${parentPositionId}` : '') +
        (!isEmpty(parentStateId) ? `&parentStateId=${parentStateId}` : '') +
        (!isEmpty(redirect) ? `&redirect=${redirect}` : ''),
    );
  } else {
    redirectToPage(
      `/${resource}/create?` + (!isEmpty(redirect) ? `&redirect=${redirect}` : ''),
    );
  }
};

const QuickCreateButtonToolbar: FC<QuickCreateButtonToolbarInterface> = props => {
  const {
    closeDialog,
    metaData,
    disableFullFormButton,
    redirectToPage = dummyFunc,
    resource = '',
    redirect = '',
    parentInfo,
    isFromDropdown = false,
    justSaveAndClose = false,
    dropdownId,
    dropdownMeta,
    onCreate,
    mustRefresh,
    relationMode,
    isLoading,
    customSubmit,
    customRefresh,
    customClassName,
    isEdit,
    ...rest
  } = props;

  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const classes = useStyles();
  const translate = useTranslate();
  const [isSaving, setIsSaving] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const saveMainButtonRef = useRef<HTMLButtonElement | null>(null);

  // it should not render save and new button if it was singleRecord
  const isSingleRecord = isSingleRecordTable(metaData);

  /**
   * it will call closeDialog function from props (its a redux action to close dialog)
   * @function handleCloseDialogClick
   * @returns {void}
   */
  const handleCloseDialogClick = (): void => {
    closeDialog();

    if (!isFromDropdown && !relationMode) {
      const currentResource = actorGetActionValue('resources')!.current; // We need latest resource that now is exist
      actorRemoveAction({
        actionName: 'formData',
        path: currentResource.value,
      });
    }
  };

  /**
   * Handle 3 save type.
   * @function handleCustomSave
   * @param {SaveType} type
   * @returns {void}
   */
  const handleCustomSave = (type: SaveType) => (): void => {
    const params = {
      dropdownId,
      dropdownMeta,
      onCreate,
      mustRefresh,
      relationMode,
      isSaveAndNew: false,
      isSaveAndView: false,
      closeDialog,
      customRefresh,
      customExternalResource: resource,
    };

    setIsSaving(true);

    switch (type) {
      case 'save':
        if (typeof customSubmit === 'function') {
          customSubmit(params);
        } else {
          formActionsHandler?.(FormActions.Save, {
            ...params,
            // formName: 'quickCreateDialog',
            onSuccess: () => {
              setIsSaving(false);
            },
            onFailure: () => {
              requestAnimationFrame(() => {
                setIsSaving(false);
              });
            },
          } as ExtraParamsInterface);
        }

        break;

      case 'saveAndNew':
        formActionsHandler?.(FormActions.Save, {
          ...params,
          isSaveAndNew: true,
          // formName: 'quickCreateDialog',
          onSuccess: () => {
            setIsSaving(false);
          },
          onFailure: () => {
            requestAnimationFrame(() => {
              setIsSaving(false);
            });
          },
        } as ExtraParamsInterface);

        break;

      case 'saveAndView':
        formActionsHandler?.(FormActions.Save, {
          ...params,
          isSaveAndView: true,
          // formName: 'quickCreateDialog',
          onSuccess: () => {
            setIsSaving(false);
          },
          onFailure: () => {
            requestAnimationFrame(() => {
              setIsSaving(false);
            });
          },
        } as ExtraParamsInterface);

        break;

      default:
        console.log(`This type ${type} is not support`);
        break;
    }
  };

  const handleFullFormButtonClick = () => {
    actorDispatch('closeDialogs', true);

    if (isFromDropdown) {
      const currentResource = actorGetActionValue('resources')!.current;
      const dropFormData = actorGetActionValue(
        'formData',
        `${currentResource.value}.${currentResource.type}`,
      );

      actorSetActionValue('formData', dropFormData, {
        path: `${currentResource.value}.${FormKeyMode.ROOT}`,
        replaceAll: true,
        callerScopeName: 'QuickCreateButtonToolbar.tsx handleFullFormButtonClick',
      });
    }

    redirectWithData(
      closeDialog,
      redirectToPage,
      resource,
      {},
      redirect,
      parentInfo,
      isFromDropdown,
    );
  };

  /**
   * @function handleKeyPressed
   * @returns { void } void
   */
  const handleKeyPressed = (): void => {
    saveMainButtonRef.current?.focus();
    saveMainButtonRef.current?.click();
  };

  useKeyPress(['s'], handleKeyPressed, true);

  return (
    <Toolbar
      {...rest}
      className={customClassName}
      classes={{
        spacer: classes.spacer,
        mobileToolbar: classes.mobileToolbar,
      }}
    >
      <CustomFormButton
        id={isFromDropdown ? 'dropdownQuickCreateFormSave' : 'quickCreateFormSave'}
        onClick={handleCustomSave('save')}
        disabled={!!isLoading || isSaving}
        label={translate('ra.action.save')}
        buttonRef={saveMainButtonRef}
      />

      {!isSingleRecord && !justSaveAndClose && (
        <CustomFormButton
          id={
            isFromDropdown
              ? 'dropdownFormSaveAndViewButton'
              : 'formSaveAndViewButton'
          }
          onClick={handleCustomSave('saveAndView')}
          disabled={isSaving}
          variant="text"
          label={translate('form.createAndView')}
          buttonRef={buttonRef}
        />
      )}

      {!isSingleRecord && !justSaveAndClose && !(relationMode && isEdit) && (
        <CustomFormButton
          id={
            isFromDropdown ? 'dropdownFormSaveAndNewButton' : 'formSaveAndNewButton'
          }
          onClick={handleCustomSave('saveAndNew')}
          disabled={isSaving}
          label={translate('form.createAndNew')}
          buttonRef={buttonRef}
          variant="text"
        />
      )}

      {!disableFullFormButton && (
        <DumbButton
          id={
            isFromDropdown
              ? 'dropdownQuickCreateFormFullInsertButton'
              : 'quickCreateFormFullInsertButton'
          }
          onClick={handleFullFormButtonClick}
          buttonRef={buttonRef}
          disabled={isSaving}
        >
          {translate('quickCreate.fullForm')}
        </DumbButton>
      )}

      <DumbButton
        onClick={handleCloseDialogClick}
        disabled={isSaving}
        id={isFromDropdown ? 'dropdownCancelButton' : 'cancelButton'}
        buttonRef={buttonRef}
      >
        {translate('ra.action.cancel')}
      </DumbButton>
    </Toolbar>
  );
};

const mapDispatchToProps = {
  redirectToPage: push,
};

export default connect(null, mapDispatchToProps)(QuickCreateButtonToolbar);
