import { FC, memo, useEffect, useMemo, useRef } from 'react';
import { useLocale } from 'react-admin';
import { Moment } from 'moment';
import moment from 'moment-jalaali';
import Cleave from 'cleave.js/react';
import { useTranslate } from 'react-admin';

import {
  SERVER_DATE_FORMAT,
  SERVER_DATE_TIME_FORMAT,
} from '../../../core/configProvider';
import { getCalendarType, getInputFormat } from '../../../helper/DateHelper';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
} from '../../form/form.type';
import {
  CleaveRawValueLength,
  JalaliDateInputInterface,
} from './jalali-date-input.type';
import JalaliDateInputView from './jalali-date-input.view';
import JalaliDateInputWMSView from './jalali-date-input-wms.view';
import lodashGet from 'lodash/get';
import { gregorianDateFormat } from '../../../helper/CalendarMetaHelper';
import {
  convertJalaliToGregorian,
  formatInvalidDateForNotLeapYear,
  getDatePickerType,
  isInvalidDateForNotLeapYear,
  isLeapYear,
} from './jalali-date-input.helper';
import JalaliDateInputGridView from './jalali-date-input-grid.view';
import lodashDebounce from 'lodash/debounce';
import { isEmpty } from '../../../helper/data-helper';
import { actorDispatch } from '../../../type/actor-setup';
import { showNotification } from '../../../helper/general-function-helper';

const JalaliDateInputController: FC<JalaliDateInputInterface> = memo(props => {
  const {
    value,
    formActionsHandler,
    inputMessage,
    DateInputInPuzzleForm,
    DateInputInGrid,
    resource,
    field,
    label,
    hint,
    getRef,
    disabled,
    visibleClass,
    options,
    defaultValue,
  } = props;
  const locale = useLocale();

  const { name, required, fixCalendar, customOption } = field;
  const calendarConfig = getCalendarType(fixCalendar);
  const inputFormat = getInputFormat(fixCalendar);
  const datePickerType = getDatePickerType(field);
  const translate = useTranslate();

  const simpleType = lodashGet(field, 'dataType.simple');
  const formatDate =
    simpleType === 'datetime' ? SERVER_DATE_TIME_FORMAT : SERVER_DATE_FORMAT;
  const datePickerRef = useRef<any>(null);

  useEffect(() => {
    if (typeof getRef === 'function' && datePickerRef?.current != null) {
      getRef(datePickerRef.current);

      // FIXME: Use `DataPicker` types, in this time I couldn't find it
      (datePickerRef.current!['input'] as HTMLInputElement).onfocus = handleFocus;
    }
  }, [value]);

  /**
   * value for date picker
   */

  const internalValue = useMemo(() => {
    if (!value) {
      return null;
    }
    if (typeof value !== 'object') {
      return moment(value, formatDate);
    } else {
      const checkDate = new Date(value);
      if (moment.isDate(checkDate)) {
        return value;
      }
    }
    return moment(value, formatDate);
  }, [value]);

  /**
   * value for mask input
   */
  const maskInputValue = useMemo(() => {
    if (!value) {
      return '';
    }

    let format = 'YYYY/MM/DD';
    if (locale === 'fa' && calendarConfig !== 'gregorian') {
      format = 'jYYYY/jMM/jDD';
    }

    if (!isEmpty(customOption?.additionalDataFieldAlt)) {
      customOption.additionalDataFieldAlt = moment(
        customOption.additionalDataFieldAlt,
      ).format(format);
    }

    return moment(value).format(`${format} HH:mm`);
  }, [value]);

  /**
   *check date by formatDate form and  handle input change
   * @function handleChange
   * @param {Moment} changedDate
   * @returns {void}
   */
  const handleChange = (changedDate: Moment): void => {
    const newValue = changedDate
      ? changedDate.locale('en').format(formatDate)
      : null;

    if (newValue === value) {
      return;
    }

    formActionsHandler(FormActions.InputChange, {
      fieldName: name,
      value: newValue,
      isHidden: visibleClass?.includes('displayNone'),
    } as ChangeFormValueParams);

    if (DateInputInPuzzleForm) {
      /**
       * In `DatePicker` we checked if `timePicker` is equal `true` we don't have to close the selection section until the `time` part to be selected
       * So we don't close it manually, In `wms` forms we can do it
       */
      (datePickerRef.current?.['setOpen'] as (...args) => void)?.(false);

      const kbEvent = new KeyboardEvent('keyup', {
        bubbles: true,
        cancelable: true,
        key: 'Enter',
      });

      document.body.dispatchEvent(kbEvent);
    }

    handleBlur(newValue ?? '');
  };

  /**
   * @function handleInputMaskChange
   * @param { React.ChangeEvent<typeof Cleave> } event
   * @returns { void }
   */
  const handleInputMaskChange = (event: React.ChangeEvent<typeof Cleave>): void => {
    const { rawValue, value } = event.target;
    const date = value.substring(0, 2);

    if (calendarConfig === 'gregorian' && value.length == 2) {
      if (date != '20' && date != '19') {
        showNotification(translate('ra.action.date'), 'error');
      }
    } else if (calendarConfig !== 'gregorian' && value.length == 2) {
      if (date != '13' && date != '14') {
        showNotification(translate('ra.action.date'), 'error');
      }
    }

    const year = +value.split('/')[0];

    /**
     * when we have complete date
     */
    if (rawValue?.length === CleaveRawValueLength[datePickerType]) {
      if (calendarConfig !== 'gregorian') {
        if (!isLeapYear(year) && isInvalidDateForNotLeapYear(value)) {
          formActionsHandler(FormActions.InputChange, {
            fieldName: name,
            value: convertJalaliToGregorian(
              formatInvalidDateForNotLeapYear(value),
              datePickerType,
            ),
            isHidden: visibleClass?.includes('displayNone'),
          } as ChangeFormValueParams);
        } else {
          formActionsHandler(FormActions.InputChange, {
            fieldName: name,
            value: convertJalaliToGregorian(value, datePickerType),
            isHidden: visibleClass?.includes('displayNone'),
          } as ChangeFormValueParams);
        }
      } else {
        formActionsHandler(FormActions.InputChange, {
          fieldName: name,
          value: moment(value).format(gregorianDateFormat),
          isHidden: visibleClass?.includes('displayNone'),
        } as ChangeFormValueParams);
      }
    }
    if (year == 0) {
      formActionsHandler(FormActions.InputChange, {
        fieldName: name,
        value: '',
        isHidden: visibleClass?.includes('displayNone'),
      } as ChangeFormValueParams);
    }
  };

  /**
   * for dateTime field Only
   * when user forget to type time
   * and just type date
   * on blur we automatically
   * set 00:00 time
   *
   * @function handleInputMaskBlur
   * @param { React.ChangeEvent<typeof Cleave> } event
   * @returns { void }
   */
  const handleInputMaskBlur = (event: React.ChangeEvent<typeof Cleave>) => {
    if (datePickerType === 'dateTime') {
      const { rawValue, value } = event.target;
      const year = +value.split('/')[0];

      const newValue = `${value} 00:00`;

      if (rawValue.length === 8) {
        if (locale !== 'en') {
          if (!isLeapYear(year) && isInvalidDateForNotLeapYear(value)) {
            formActionsHandler(FormActions.InputChange, {
              fieldName: name,
              value: convertJalaliToGregorian(
                formatInvalidDateForNotLeapYear(newValue),
                datePickerType,
              ),
              isHidden: visibleClass?.includes('displayNone'),
            } as ChangeFormValueParams);
          } else {
            formActionsHandler(FormActions.InputChange, {
              fieldName: name,
              value: convertJalaliToGregorian(newValue, datePickerType),
              isHidden: visibleClass?.includes('displayNone'),
            } as ChangeFormValueParams);
          }
        } else {
          formActionsHandler(FormActions.InputChange, {
            fieldName: name,
            value: moment(newValue).format(gregorianDateFormat),
            isHidden: visibleClass?.includes('displayNone'),
          } as ChangeFormValueParams);
        }
      }
    }
  };

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = (inputValue: string) => {
    if (datePickerRef.current == null) return;

    // FIXME: Use `DataPicker` types, in this time I couldn't find it
    if (datePickerRef.current['state']['isOpen']) {
      return;
    }

    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value: inputValue,
    } as OnBlurParams);
  };

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = (): void => {
    (datePickerRef.current!['setOpen'] as (...args) => void)(true);
    formActionsHandler(FormActions.InputFocus, {
      fieldName: name,
      value,
    } as ChangeFormValueParams);
  };

  /**
   * it will set null as input value
   * @function clearInput
   * @returns {void} void
   */
  const clearInput = (): void => {
    datePickerRef.current.removeDate();

    // when click the input for clear value it the default value was empty
    actorDispatch('signal', 'isInputClearedOnce');
  };

  const ViewComponent = DateInputInGrid
    ? JalaliDateInputGridView
    : DateInputInPuzzleForm
    ? JalaliDateInputWMSView
    : JalaliDateInputView;

  return (
    <ViewComponent
      visibleClass={visibleClass}
      resource={resource}
      inputMessage={inputMessage}
      disabled={disabled}
      DateInputInPuzzleForm={DateInputInPuzzleForm}
      label={label}
      hint={hint}
      required={required}
      name={name}
      inputFormat={inputFormat}
      handleChange={handleChange}
      handleInputMaskChange={handleInputMaskChange}
      handleInputMaskBlur={handleInputMaskBlur}
      internalValue={internalValue ?? defaultValue}
      maskInputValue={maskInputValue}
      calendarConfig={calendarConfig}
      getRef={getRef}
      datePickerRef={datePickerRef}
      options={options}
      onFocus={handleFocus}
      onBlur={handleBlur}
      clearInput={clearInput}
      simpleType={simpleType}
      field={field}
      datePickerType={datePickerType}
    />
  );
});

export default JalaliDateInputController;
