import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { PermissionAssigned } from '../../permissions/permission-assigned';
import { PermissionsAssignType } from '../../permissions/permission-assign-type';
import { actorGetActionValue } from '../../../type/actor-setup';
import { useStyles } from './permissions-add-user.style';

import type { FC } from 'react';
import type { DevExpressCellData } from '../../show-permissions/simple-grid';
import type { PermissionsAddUserType } from './permissions-add-user.type';

const PermissionsAddUserView: FC<PermissionsAddUserType> = props => {
  const {
    closeDialogHandler,
    onChangePermissionClick,
    dialogData,
    removePermissionClick,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  const selectedForAddPermissions =
    dialogData?.isAdvanced &&
    Object.values(
      dialogData?.selectedItemForPermission as {
        [key: string]: {
          checked: boolean;
          cellData: DevExpressCellData;
          permissionKey: string;
        };
      },
    )?.length;

  const titleDialog = dialogData?.idDelete
    ? translate('permissions.removePermissions', {
        selectedForAddPermissions: selectedForAddPermissions,
      })
    : dialogData?.isAdvanced && !dialogData?.idDelete
    ? translate('permissions.selectPermissions', {
        selectedForAddPermissions: selectedForAddPermissions,
      })
    : actorGetActionValue('userPermissionValue')?.selectedName ?? '';

  return (
    <>
      <DialogTitle>{`${
        !dialogData?.isAdvanced ? translate('ra.action.create') : ''
      } ${titleDialog}`}</DialogTitle>

      <DialogContent>
        <div>
          <div>
            <PermissionsAssignType className={classes.containerTypeAssign} />
          </div>

          <div>
            <PermissionAssigned className={classes.containerAddUser} />
          </div>
        </div>
      </DialogContent>

      <Divider />

      {!dialogData?.idDelete && (
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={onChangePermissionClick}
          >
            {translate('permissions.applyPermission')}
          </Button>
          <Button onClick={closeDialogHandler}>
            {translate('ra.action.cancel')}
          </Button>
        </DialogActions>
      )}

      {dialogData?.idDelete && (
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={removePermissionClick}
          >
            {translate('permissions.removePermission')}
          </Button>
          <Button onClick={closeDialogHandler}>
            {translate('ra.action.cancel')}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export default PermissionsAddUserView;
