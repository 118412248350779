import { useEffect, useRef, useState } from 'react';
import {
  complete,
  translate,
  undo,
  userLogout as userLogoutAction,
} from 'react-admin';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import lodashGet from 'lodash/get';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import WarningIcon from '@material-ui/icons/Warning';
import {
  isEmpty,
  isEmptyObject,
  isNetworkError,
  isSystemError,
  clone
} from '../helper/data-helper';
import { hiddenNotifications } from '../helper/general-function-helper';
import { actorOnDispatch } from '../type/actor-setup';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

const styles = theme =>
  createStyles({
    confirm: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      zIndex: 5000,
      '&:hover': {
        backgroundColor: theme.palette.info.main,
      },
    },

    warning: {
      color: theme.palette.warning.contrastText,
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark,
      },
      zIndex: 5000,
    },

    error: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.light,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
      zIndex: 5000,
    },

    undo: {
      color: theme.palette.primary.light,
    },
    contentText: {
      whiteSpace: 'pre-wrap',
      marginRight: 5,
      marginLeft: 5,
    },
    addIconInModal: {
      display: 'flex',
    },

    changeColorIconWarning: {
      color: theme.palette.warning.main,
    },

    changeColorIconError: {
      color: theme.palette.error.light,
    },
    requestId: {
      textAlign: 'center',
      fontSize: '0.7em',
      borderTop: `1px solid ${theme.palette.divider}`,
      lineHeight: 2,
      cursor: 'pointer',
    },

  });

const Notification = props => {
  const {
    undo,
    complete,
    classes,
    className,
    type,
    translate,
    userLogout,
    ...rest
  } = props;

  const {
    undo: undoClass, // Rename classes.undo to undoClass in this scope to avoid name conflicts
    ...snackbarClasses
  } = classes;

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [notification, setNotification] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const prevMessage = useRef(null);
  const {
    fromQuickCreateDialog = false,
    forceSnackbar = false,
    logout = false,
    messageArgs = {},
    undoable = false,
    autoHideDuration = 4000,
    codeNumber,
    requestId = '',
  } = notification?.options ?? {};

  useEffect(() => {
    actorOnDispatch(
      'notification',
      notify => {
        if (notify == null) {
          setIsOpenSnackbar(false);
          setNotification({});
          return;
        }

        //if popup notifications have been opening,we shouldn't show toast message.
        if(!Boolean(notify?.options?.forceShowInDialog) && !isEmpty(prevMessage.current)){  
          return
        }

        if(Boolean(notify?.options?.forceShowInDialog)){
          prevMessage.current = isEmpty(prevMessage.current) ? notify.message : `${prevMessage.current}<br/>${notify.message}`
          notify.message = clone(prevMessage.current)
        }

        setNotification({...notify});
      },
      {
        preserve: false,
      },
    );
  }, []);


    /**
       * Copy Request Id to Clipboard
       * @function copyToClipboard
       * @returns {void}
       */
    const copyToClipboard = () => {
      navigator.clipboard
        .writeText(requestId)
        .then(() => {
          console.log('copied');
          handleTooltipOpen();
        })
        .catch(() => {
          console.log('failed');
        });
    };
  /**
   * Change `tooltipOpen` state to false
   * @function handleTooltipOpen
   * @returns {void}
   */
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  /**
   * Change `tooltipOpen` state to true
   * @function handleTooltipOpen
   * @returns {void}
   */
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const shouldConfirm =
    notification &&
    (notification.type === 'error' || notification.type === 'warning') &&
    (!forceSnackbar || forceSnackbar !== true);

  useEffect(() => {
    setIsOpenSnackbar(!!notification);

    //when the focus is lost,autoHideDuration doesn't work. we had to force close snack bar
    setTimeout(() => {
      if(!shouldConfirm && !notification?.options?.forceShowInDialog){
        handleRequestClose()
      }
    }, 4000);
  }, [notification]);

  const handleRequestClose = () => {
    if (isOpenSnackbar) {
      setIsOpenSnackbar(false);
    }
  };

  /**
   * @function isInvalidUser
   * @param { string } message
   * @returns { boolean }
   */
  const isInvalidUser = codeNumber => {
    if (codeNumber) {
      if (codeNumber == 401) {
        return true;
      }
    }
    return false;
  };

  const handleExited = () => {
    prevMessage.current = null;

    if ((notification && logout) || isInvalidUser(codeNumber)) {
      userLogout();
    }

    if (notification && undoable) {
      complete();
    }

    hiddenNotifications();
  };

  /**
   * create correct dialog error message
   * @function prepareMessage
   * @returns {string}
   */
  const prepareMessage = () => {
    try {
      const notificationMessage = lodashGet(notification, 'message');

      let message = notificationMessage
        ? String(translate(notificationMessage, messageArgs))
        : null;

      if (isNetworkError(message)) {
        return translate('ra.notification.http_error');
      }

      if (isSystemError(message)) {
        return null;
      }

      return message;
    } catch (error) {
      return null;
    }
  };

  const messageText = prepareMessage();

  if (isEmpty(messageText)) return null;
  

  if (shouldConfirm || !!notification?.options?.forceShowInDialog) {
    return (
      <Dialog open={true} data-test-notification-dialog>
        <DialogContent>
          {notification.type === 'warning' ? (
            <div className={classes.addIconInModal}>
              <WarningIcon className={classes.changeColorIconWarning} />
              <DialogContentText
                className={classes.contentText}
                data-test-notification="content"
              >
                <div dangerouslySetInnerHTML={{ __html: messageText }} />
              </DialogContentText>
            </div>
          ) : notification.type === 'error' ? (
            <div className={classes.addIconInModal}>
              <ErrorIcon className={classes.changeColorIconError} />
              <DialogContentText
                className={classes.contentText}
                data-test-notification="content"
              >
                <div dangerouslySetInnerHTML={{ __html: messageText }} />
              </DialogContentText>
            </div>
          ) : (
            <DialogContentText
              className={classes.contentText}
              data-test-notification="content"
            >
              <div dangerouslySetInnerHTML={{ __html: messageText }} />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className={classes[notification.type]}
            onClick={handleExited}
            data-test-notification="confirm"
          >
            {translate('ra.action.confirm')}
          </Button>
        </DialogActions>
        {requestId !== '' && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={translate('general.copied')}
            >
              <div className={classes.requestId} onClick={copyToClipboard}>
                {translate('general.requestId')}: <span>{requestId}</span>
              </div>
            </Tooltip>
          </ClickAwayListener>
        )}
      </Dialog>
    );
  }

  const anchorOrigin = { vertical:'bottom',horizontal:'center' }
  // hold CustomSnackbar in a variable to prevent rewrite it three times!
  const CustomSnackbar = (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={isOpenSnackbar}
      message={
        notification &&
        notification.message &&
        translate(lodashGet(notification, 'message'), messageArgs)
      }
      autoHideDuration={notification && autoHideDuration}
      disableWindowBlurListener={true}
      onExited={handleExited}
      onClose={handleRequestClose}
      ContentProps={{
        className: classNames(
          classes[(notification && notification.type) || type],
          classes.snackBarMarginTop,
        ),
      }}
      action={
        notification && undoable ? (
          <Button color="primary" className={undoClass} size="small" onClick={undo}>
            {translate('ra.action.undo')}
          </Button>
        ) : null
      }
      classes={snackbarClasses}
      {...rest}
    />
  );

  if (notification && !isEmptyObject(notification) && notification !== []) {
    if (fromQuickCreateDialog === true) {
      let SnackbarContainer = null;

      if (typeof document !== 'undefined') {
        SnackbarContainer = document.getElementById('customSnackContainer');
      }

      if (!SnackbarContainer) {
        return CustomSnackbar;
      } else {
        return createPortal(CustomSnackbar, SnackbarContainer);
      }
    } else {
      return CustomSnackbar;
    }
  } else {
    return null;
  }
};

Notification.propTypes = {
  complete: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  notification: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
    autoHideDuration: PropTypes.number,
    messageArgs: PropTypes.object,
  }),
  type: PropTypes.string,
  autoHideDuration: PropTypes.number,
  translate: PropTypes.func.isRequired,
  undo: PropTypes.func,
};

Notification.defaultProps = {
  type: 'info',
  autoHideDuration: 4000,
};

const mapDispatchToProps = {
  complete,
  undo,
  userLogout: userLogoutAction,
};

export default compose(
  translate,
  withStyles(styles),
  connect(null, mapDispatchToProps),
)(Notification);
