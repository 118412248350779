import { memo, type FC, useEffect } from 'react';

import AutomationView from './automation.view';

const AutomationController: FC = memo(() => {
  //redirect old Url to new Url
  if (location.href.includes('/mail')) {
    const queryParams = location.href.split('?');
    location.href = `#/oa?module=mail&folder-name=inbox&${queryParams[1] ?? ''}`;
    return <></>;
  } else {
    return <AutomationView />;
  }
});
export default AutomationController;
