import { FormRowsAndFieldsData, WMSLayoutRow, WMSTabData } from './wms.type';
import { FieldType } from '../../helper/Types';
import { clone, isEmptyObject } from '../../helper/data-helper';
import { FormData, InputRefContent } from '../form';
import {
  actorDispatch,
  actorGetActionValue,
  actorSetActionValue,
} from '../../type/actor-setup';

/**
 * Sorts the form fields by the order of `leftPriorityInRow` for each rows
 * @function sortByPriorityInRow
 * @param { WMSLayoutRow } a
 * @param { WMSLayoutRow } b
 * @returns { number }
 */
const sortByPriorityInRow = (a: WMSLayoutRow, b: WMSLayoutRow): number => {
  return a.leftPriorityInRow - b.leftPriorityInRow;
};

/**
 * It receives some section of `wms` metadata and calculates and categories information about layout fields
 * @function prepareRowListWMSForm
 * @param { WMSTabData } tabData
 * @returns { FormRowsAndFieldsData } an object
 */
export const prepareRowListWMSForm = (
  tabData: WMSTabData,
): FormRowsAndFieldsData => {
  const { id: tabId, title: tabTitle, item: tabItem } = tabData;

  const newLayoutRows: Array<Array<FieldType | null | 'empty'>> = [];
  const tempFields = {};
  const tempPreparedFieldList: Array<FieldType> = [];

  const layoutRows = tabItem.layoutRows ?? {};

  for (const row in layoutRows) {
    layoutRows[row].sort(sortByPriorityInRow);

    for (const field of layoutRows[row]) {
      const newField = { ...field, tabTitle, tabId } as unknown as FieldType;
      if (!newLayoutRows[row]) {
        newLayoutRows[row] = [];
      }
      newLayoutRows[row].push(newField);
    }
  }

  for (const row of newLayoutRows) {
    if (row) {
      for (const field of row) {
        if (!(field && typeof field === 'object' && Object.keys(field).length > 0)) {
          continue;
        }

        tempFields[field.id] = field;
        tempPreparedFieldList.push(field);
      }
    }
  }

  return {
    rowList: newLayoutRows,
    fields: tempFields,
    fieldList: tempPreparedFieldList,
  };
};

/**
 * It resets values of each input in the form based on meta data of each field
 * @function updateValuesOfInputsAfterSubmit
 * @param { Record<string, InputRefContent> | null | undefined } inputsRef
 * @param { Array<FieldType> } fieldNameList
 * @param { FormData | null } formData
 * @param { boolean } forceEmptyFields
 * @returns { void }
 */
export const updateValuesOfInputsAndFormDataAfterSubmit = (params: {
  inputsRef: Record<string, InputRefContent> | null | undefined;
  fieldNameList: string[];
  newFormData?: FormData | null; // `null` means we don't have to set incoming data.
  forceEmptyFields?: boolean;
}): void => {
  const { inputsRef, fieldNameList, newFormData, forceEmptyFields } = params;
  if (isEmptyObject(inputsRef)) return;

  const currentResource = actorGetActionValue('resources')!.current;
  const currentFormData = actorGetActionValue(
    'formData',
    `${currentResource.value}.${currentResource.type}`,
  )! as FormData;

  const updatedFormData = {};
  for (const fieldName of fieldNameList) {
    if (inputsRef?.[fieldName] == null) continue;

    const { keepValueAfterSubmit, defaultValue } = inputsRef![fieldName].field;

    inputsRef![fieldName]!.setInputValue?.(''); // At first, we have to empty value of the field
    let finalInputValue = defaultValue ?? '';

    if (forceEmptyFields) {
      inputsRef![fieldName]!.setInputValue?.(finalInputValue);
      updatedFormData[fieldName] = finalInputValue;

      continue;
    }

    //‍‍ `newFormData` != ‍‍‍‍`null` means we have to set incoming data.
    if (!isEmptyObject(newFormData)) {
      if (!(fieldName in newFormData!) && keepValueAfterSubmit) {
        finalInputValue = currentFormData[fieldName] ?? '';
      } else {
        /**
         * When there isn't a key in new form data while it exists in the current form,
         * It means we have to set the default value
         */
        finalInputValue = String(newFormData![fieldName] || finalInputValue);
      }
    } else if (keepValueAfterSubmit) {
      finalInputValue = currentFormData[fieldName] ?? '';
    }

    updatedFormData[fieldName] = finalInputValue;
    inputsRef![fieldName]!.setInputValue?.(finalInputValue);
  }

  actorSetActionValue('formData', updatedFormData, {
    path: `${currentResource.value}.${currentResource.type}`,
    replaceAll: true,
    callerScopeName: 'wms.helper => updateValuesOfInputsAndFormDataAfterSubmit',
  });

  const rootResource = actorGetActionValue('resources')!.stack[0];
  actorSetActionValue('formData', updatedFormData, {
    path: `${rootResource.value}.${rootResource.type}`,
    replaceAll: true,
    callerScopeName: 'WMSActionController => saveData',
  });

  const { focusOnFirstInputAfterSubmit } =
    actorGetActionValue('formGlobalProps')!.formFocusManagementFunctions;
  focusOnFirstInputAfterSubmit();
};

/**
 * It sets the value of each `field` after changes in `search` input based on data that received from `API`
 * @function updateValuesOfInputsAfterSearchInputChanges
 * @param {FormData} formData
 * @param {Record<string, InputRefContent> | null} inputsRef
 * @param {boolean} prevTabIsAction
 * @param {boolean} refreshGrid
 * @returns {void} void
 */
export const updateValuesOfInputsAfterSearchInputChanges = (
  formData: FormData | undefined,
  inputsRef: Record<string, InputRefContent> | null,
  isActionTab: boolean,
  refreshGrid?: boolean,
): void => {
  if (isEmptyObject(formData) || isEmptyObject(inputsRef)) return;

  const currentResource = actorGetActionValue('resources')!.current;
  const rootResource = actorGetActionValue('resources')!.stack[0];

  const fieldNameList =
    actorGetActionValue('formGlobalProps')!.inputNameListSortedByPriority;

  const updatedData = clone(formData)!;
  for (const fieldName of fieldNameList) {
    /**
     * 1) We have to remove `null` value from incoming data and keep the last corresponding values in `formData`
     *
     * 2) Because of any reason if there is a `fieldName` in `formData` that not exists in current `inputsRef`,
     * It has to delete
     */
    if (formData![fieldName] == null || fieldName in inputsRef! === false) {
      delete updatedData[fieldName];
      continue;
    }

    inputsRef![fieldName].setInputValue?.(formData![fieldName]);
  }

  actorSetActionValue('formData', updatedData, {
    path: `${currentResource.value}.${currentResource.type}`,
    callerScopeName: 'wms.helper 1 => updateValuesOfInputsAfterSearchInputChanges',
  });

  /**
   * We want to update the root data just in `action` type layouts
   */
  if (isActionTab) {
    actorSetActionValue('formData', updatedData, {
      path: `${rootResource.value}.${rootResource.type}`,
      callerScopeName: 'wms.helper 2 => updateValuesOfInputsAfterSearchInputChanges',
    });
  }

  if (refreshGrid) {
    actorDispatch('refreshView', null);
  }
};
