import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(() => ({
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    margin: '10px',
    flexFlow: 'wrap',
  },
  actionButton: {
    borderRadius: 4,
    background: '#d0d0d0',
    color: '#000000',
    padding: '10px',
    margin: '5px',
  },
}));
