import { useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import lodashFilter from 'lodash/filter';

import AddMembersDialogView from './add-members-dialog.view';
import { actorDispatch } from '../../../../type/actor-setup';
import { createGroup } from '../create-group-dialog/create-group-dialog-helper';
import {
  ADMINS_MODE,
  CREATE_MODE,
  EDIT_MODE,
  getMembersResourceId,
  VIEW_MODE,
} from './add-members-dialog.helper';
import {
  prepareSelectedUsersBeforeCreateGroup,
  updateGroup,
} from '../../group-chats.helper';
import { replaceArabicCharacters } from '../../../../helper/TextHelper';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../core/configProvider';
import { getChats } from '../../chat-section-helper/chat-section-common';

import type { ReactElement, MouseEvent } from 'react';
import type {
  AddMembersDialogPropsInterface,
  ChatUsersInterface,
} from './add-members-dialog.type';
import { clone } from '../../../../helper/data-helper';

/**
 * we have different modes for this dialog
 * 1- is default mode which is in creating group mode and is being called from create group dialog component and the main function that we are using is getPossibleMembersToAdd function
 * 2- is in view mode which is being called from chat info dialog component and the main function that we are using is getGroupUsers function
 * 3- is in Add members mode (edit mode)
 * 3- is in Add admins mode (admins mode)
 */
const AddMembersDialogController = (
  props: AddMembersDialogPropsInterface,
): ReactElement => {
  const { closeDialogHandler, dialogData } = props;
  const {
    GroupName,
    GroupProfileImage,
    groupUsers,
    GroupUID,
    mode = CREATE_MODE,
    IsChannel,
  } = dialogData;

  const translate = useTranslate();

  const dialogTitleText = {
    [CREATE_MODE]: translate('chat.addMembers'),
    [VIEW_MODE]: translate('chat.members'),
    [EDIT_MODE]: translate('chat.addMembers'),
    [ADMINS_MODE]: translate('chat.addAdmins'),
  };

  const actionButtonText = {
    [CREATE_MODE]: translate('chat.create'),
    [EDIT_MODE]: translate('chat.addMembers'),
    [ADMINS_MODE]: translate('chat.addAdmins'),
  };

  const isInViewMode = mode === VIEW_MODE && GroupUID && groupUsers;
  const isInEditMode = mode === EDIT_MODE && GroupUID && groupUsers;
  const isInAdminsMode = mode === ADMINS_MODE && GroupUID && groupUsers;

  const allUsersRef = useRef<ChatUsersInterface[]>(isInViewMode ? groupUsers : []);
  const [users, setUsers] = useState<ChatUsersInterface[]>(
    isInViewMode || isInAdminsMode ? groupUsers : [],
  );
  const [selectedUsers, setSelectedUsers] = useState<ChatUsersInterface[]>(
    isInEditMode ? groupUsers.map(user => ({ ...user, isAlreadyMember: true })) : [],
  );

  const groupAdmins = groupUsers?.filter(user => user.isadmin) ?? [];

  const [isFound, setIsFound] = useState<boolean>(true);

  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  useEffect(() => {
    if (isInViewMode || isInAdminsMode) return;
    //create mode
    getPossibleMembersToAdd();
  }, []);

  /**
   * @function getPossibleMembersToAdd
   * @returns { void } void
   */
  const getPossibleMembersToAdd = (): void => {
    actorDispatch(
      'crudAction',
      {
        type: 'GET_LIST',
        resource: getMembersResourceId,
        requestParameters: {
          filter: [['membersonly', '=', 1]],
          pagination: {
            page: 1,
            perPage: 999999,
          },
        },
        onSuccess: (response: { data: ChatUsersInterface[] }) => {
          if (response.data) {
            allUsersRef.current = response.data;
            if (response.data.length > 100) {
              setUsers(response.data.slice(0, 100));
            } else {
              setUsers(response.data);
            }
          }
        },
      },
      {
        replaceAll: true,
        disableDebounce: true,
        callerScopeName: 'getPossibleMembersToAdd',
      },
    );
  };

  /**
   * @function getAvatarURL
   * @param {string} personImage
   * @returns {string} string
   */
  const getAvatarURL = (personImage: string): string => {
    return `${apiUrl}/oauth2/${sessionId}/${apiVersion}${personImage}`;
  };

  /**
   * @function addToSelectedUsers
   * @param {ChatUsersInterface} user
   * @returns {() => void} a function
   */
  const addToSelectedUsers = (user: ChatUsersInterface) => (): void => {
    if (isInViewMode) return;

    let targetUser: ChatUsersInterface | null = null;

    //if selected user is already in the list then remove it
    const targetUserIndex = selectedUsers.findIndex(
      (selectedUser: ChatUsersInterface) =>
        selectedUser.personinfo_id === user.personinfo_id,
    );
    if (targetUserIndex > -1) {
      targetUser = selectedUsers[targetUserIndex];
    }

    const isAlreadyAdmin = groupAdmins.find(
      (admin: ChatUsersInterface) => admin.personinfo_id === user.personinfo_id,
    );
    if (isAlreadyAdmin) return;

    if (!targetUser) {
      setSelectedUsers([...selectedUsers, user]);
      return;
    }

    if (targetUser.isAlreadyMember) return;

    // User exists then we have to deselect it
    selectedUsers.splice(targetUserIndex, 1);
    setSelectedUsers([...selectedUsers]);
  };

  /**
   * @function handleDeleteSelectedUser
   * @param {ChatUsersInterface} user
   * @returns {(event: MouseEvent<HTMLInputElement>) => void}
   */
  const handleDeleteSelectedUser =
    (user: ChatUsersInterface) =>
    (_event: MouseEvent<HTMLInputElement>): void => {
      setSelectedUsers(
        selectedUsers.filter(
          (selectedUser: ChatUsersInterface) =>
            selectedUser.personinfo_id !== user.personinfo_id,
        ),
      );
    };

  const handleUpdateGroupInfoSuccess = (): void => {
    actorDispatch('refreshView', 'refreshChatInfoDialog');
    actorDispatch('closeCurrentDialog', true);

    getChats({
      page: 1,
      shouldLoading: true,
      dataShouldBeReplaced: true,
    });

    if (isInViewMode) return;
    closeDialogHandler();
  };

  /**
   * @function createGroup
   * @param {ChatUsersInterface[] | undefined} newUsers
   * @returns {void} void
   */
  const handleCreateOrUpdateGroup = (
    newUsers?: ChatUsersInterface[],
    successCallback: () => void = handleUpdateGroupInfoSuccess,
    failureCallback?: () => void,
  ): void => {
    const selectedUsersIds = isInAdminsMode
      ? prepareSelectedUsersBeforeCreateGroup(users)
      : prepareSelectedUsersBeforeCreateGroup(
          isInViewMode && newUsers != null ? newUsers : selectedUsers,
        );
    const selectedAdminsIds = prepareSelectedUsersBeforeCreateGroup(
      isInAdminsMode ? groupAdmins.concat(selectedUsers) : groupAdmins,
    );

    if (isInViewMode || isInEditMode || isInAdminsMode) {
      updateGroup(
        {
          GroupName: GroupName,
          MemberPersonInfoIDs: selectedUsersIds,
          IsUseInChat: 1,
          GroupProfileImage: JSON.stringify(GroupProfileImage ?? ''),
          IsChannel,
          GroupUID: GroupUID,
          AdminPersonInfo_IDs: selectedAdminsIds,
        },
        successCallback,
        failureCallback ??
          (() => {
            actorDispatch('closeCurrentDialog', true);
          }),
      );
    } else {
      createGroup({
        GroupName: GroupName,
        MemberPersonInfoIDs: selectedUsersIds,
        IsUseInChat: 1,
        GroupProfileImage: JSON.stringify(GroupProfileImage ?? ''),
        IsChannel,
      });
    }
  };

  /**
   * @function handleSearch
   * @param {React.ChangeEvent<HTMLInputElement>}event
   * @returns {void} void
   */
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      const result = lodashFilter(allUsersRef.current, user => {
        if (
          replaceArabicCharacters(user.personname ?? user.fullname).includes(
            replaceArabicCharacters(value),
          )
        ) {
          return user;
        }
      });
      if (result.length === 0) {
        setUsers([]);
        setIsFound(false);
        return;
      }
      setIsFound(true);
      setUsers(result.slice(0, 50));
      return;
    }
    setIsFound(true);
    //if value is empty than show all users
    if (allUsersRef.current.length > 100) {
      setUsers(allUsersRef.current.slice(0, 100));
    } else {
      setUsers(allUsersRef.current);
    }
  };

  /**
   * @function onRemoveUserConfirmation
   * @param { ChatUsersInterface } user
   * @returns { void } void
   */
  const onRemoveUserConfirmation = (targetUser: ChatUsersInterface): void => {
    const allUsers = clone(allUsersRef.current);
    const userThatShouldBeRemoved = allUsers.find(
      (user: ChatUsersInterface) => user.personinfo_id === targetUser.personinfo_id,
    );
    if (!userThatShouldBeRemoved) return;

    const index = allUsers.indexOf(userThatShouldBeRemoved);
    if (index == -1) {
      return;
    }
    allUsers.splice(index, 1);
    handleCreateOrUpdateGroup(allUsers, () => {
      setUsers([...allUsers]);

      handleUpdateGroupInfoSuccess();
    });
  };

  /**
   * @function handleRemoveUser
   * @param { ChatUsersInterface } user
   * @returns { (event: MouseEvent<HTMLElement>) => void } void
   */
  const handleRemoveUser =
    (user: ChatUsersInterface) =>
    (event: MouseEvent<HTMLElement>): void => {
      actorDispatch('quickDialog', {
        confirmationIsOpen: true,
        data: {
          content: translate('ra.message.are_you_sure'),
          onConfirm: () => onRemoveUserConfirmation(user),
        },
      });
    };

  return (
    <AddMembersDialogView
      handleCreateOrUpdateGroup={handleCreateOrUpdateGroup}
      handleDeleteSelectedUser={handleDeleteSelectedUser}
      addToSelectedUsers={addToSelectedUsers}
      closeDialogHandler={closeDialogHandler}
      handleRemoveUser={handleRemoveUser}
      handleSearch={handleSearch}
      getAvatarURL={getAvatarURL}
      dialogTitle={dialogTitleText[mode]}
      actionButtonText={actionButtonText[mode]}
      selectedUsers={selectedUsers}
      isFound={isFound}
      users={users}
      mode={mode}
    />
  );
};

export default AddMembersDialogController;
