import { UserPermissionValueInterface } from '../../../type/actor-setup';
import type { PermissionResponseType } from '../../show-permissions/permission-tabs/dynamic-permission-data.type';

export interface DialogDataReasonPermissionInterface {
  column: { permKey: string };
  cellData: {
    column: { name: string; caption: string };
    data: Record<string, unknown>;
    itemData: { label: string; paramKey: string };
    values: string[];
    caption: string | null;
    node: { itemData: { label: string } };
  };
  refreshTabData?: () => void;
}
export interface PermissionShowReasonDialogControllerInterface {
  dialogData: DialogDataReasonPermissionInterface;
  closeDialogHandler: () => void;
}
export interface PermissionShowReasonDialogViewInterface {
  closeDialogHandler: (...args) => void;
  dialogData: DialogDataReasonPermissionInterface;
  permissionReason: PermissionResponseType[];
  deleteUserPermission: (row: RowData) => () => void;
  addUser: () => void;
  dialogTitle: string;
  handleTabChange: (activeKey: string) => void;
  selectPersonForFilter?: unknown;
}

export interface SelectedRowData {
  AttributionType: number;
  AttributionIds: number[];
  PermKeys: string[];
  Departments: number[];
  PermDescs?: any;
}

export interface RowData {
  attributionType: number;
  attributionIds: number;
  departmentIds?: string;
}

export interface TitleDialogInterface {
  fieldName: string;
  columnTitle: string;
  activeTab: Partial<UserPermissionValueInterface> | null;
  cellName: string;
  cellNameModule: string;
  cellData: { column: { caption: string } };
}

export enum defaultTabKey {
  activeTabOne = '1',
  activeTabTwo = '2',
}
