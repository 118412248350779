import { ReactElement } from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { QuickAccessMenuViewInterface } from './quick-access-menu.type';
import { useStyles } from './quick-access-menu.style';
import { QuickAccessMenuDataInterface } from '.';
import LoadingBox from '../loading-box';

const QuickAccessMenuView = (props: QuickAccessMenuViewInterface): ReactElement => {
  const { data, isLoading, onClick } = props;
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:800px)');

  if (isLoading) return <LoadingBox size={12} />;
  return (
    <>
      {isSmallScreen ? (
        <FormControl>
          <Select
            disableUnderline
            className={classes.selectToolbar}
            inputProps={{
              classes: {
                icon: classes.selectBoxIcon,
              },
            }}
            IconComponent={props => <MoreVertIcon {...props} />}
          >
            {data?.map((item: QuickAccessMenuDataInterface) => (
              <MenuItem onClick={() => onClick(item)} value={item?.title ?? ''}>
                <Tooltip key={item.id} title={item?.title ?? ''}>
                  <i
                    data-test-quick-access-menu-icon={item.id}
                    className={`fa fa-${item?.icon} ${classes.selectBoxIcon}`}
                  ></i>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={1}
          data-test="quick-access-menu-container"
          id="accessMenu"
        >
          {data?.map((item: QuickAccessMenuDataInterface) => (
            <Tooltip key={item.id} title={item?.title ?? ''}>
              <i
                onClick={() => onClick(item)}
                data-test-quick-access-menu-icon={item.id}
                className={`fa fa-${item?.icon} ${classes.icon}`}
              ></i>
            </Tooltip>
          ))}
        </Box>
      )}
    </>
  );
};
export default QuickAccessMenuView;
