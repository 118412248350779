import { useCallback, Fragment } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { linkToRecord, useTranslate } from 'react-admin';
import { IconButton, TableCell, Icon, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import lodashGet from 'lodash/get';

import { isMetaEditable, isRecordEditable } from '../../helper/MetaHelper';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { RelationEditButton } from '../relation-edit-button';

const useStyles = makeStyles(theme => ({
  actionEditColumnContainer: {
    padding: 0,
    textAlign: 'center',
  },

  iconButton: {
    margin: '0 2px',
    padding: 7,
  },

  icon: {
    color: theme.palette.primary.main,
    margin: '0 !important',
    fontSize: 18,
  },

  disabledIcon: {
    color: theme.palette.grey[400],
    margin: '0 !important',
    fontSize: 18,
  },

  // silence warnings
  container: {},
  numberField: {},
}));

const stopPropagation = e => e.stopPropagation();

const ActionEditTableCell = props => {
  const {
    row,
    children,
    quickEditButton,
    basePath,
    currentUrl,
    metaData,
    relationMode,
    parentInfo = {},
    relationResource,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();

  const parentResource = lodashGet(parentInfo, 'parentResource', null);
  const parentProcessUniqueId = lodashGet(parentInfo, 'parentProcessUniqueId', null);
  const parentPositionId = lodashGet(parentInfo, 'parentPositionId', null);
  const parentStateId = lodashGet(parentInfo, 'parentStateId', null);
  const parentId = lodashGet(parentInfo, 'parentId', null);

  // don't show edit button, when "quick edit" is enabled
  let showEditButton = true;

  const isThisRecordEditable = // it will use for buttons not fields
    isRecordEditable(metaData, row) && isMetaEditable(metaData);

  const computeLink = useCallback(() => {
    if (parentInfo && !isEmptyObject(parentInfo)) {
      return (
        linkToRecord(basePath, row.id) +
        (!isEmpty(parentResource) ? `?parentResource=${parentResource}` : '') +
        (!isEmpty(parentId) ? `&parentId=${parentId}` : '') +
        (!isEmpty(parentProcessUniqueId)
          ? `&parentProcessUniqueId=${parentProcessUniqueId}`
          : '') +
        (!isEmpty(parentPositionId) ? `&parentPositionId=${parentPositionId}` : '') +
        (!isEmpty(parentStateId) ? `&parentStateId=${parentStateId}` : '') +
        (!isEmpty(currentUrl) ? `&redirect=${currentUrl}` : '')
      );
    } else {
      return (
        linkToRecord(basePath, row.id) +
        (!isEmpty(currentUrl) ? `?redirect=${currentUrl}` : '')
      );
    }
  }, [
    currentUrl,
    parentInfo,
    parentResource,
    parentProcessUniqueId,
    parentPositionId,
    parentStateId,
  ]);

  return (
    <Fragment>
      {quickEditButton &&
        children &&
        children.map(child => {
          if (!child) {
            return null;
          }

          const { props: buttonProps } = child;

          switch (buttonProps.id) {
            case 'edit':
              return (
                <Tooltip title={translate('grid.quickEdit')} key={buttonProps.id}>
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    onClick={buttonProps.onExecute}
                  >
                    <Icon
                      fontSize="small"
                      className={classNames(classes.icon, 'fa fa-edit')}
                    />
                  </IconButton>
                </Tooltip>
              );

            case 'commit':
              showEditButton = false;
              return (
                <Tooltip title={translate('ra.action.save')} key={buttonProps.id}>
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    onClick={buttonProps.onExecute}
                  >
                    <Icon fontSize="small" className={classes.icon}>
                      save
                    </Icon>
                  </IconButton>
                </Tooltip>
              );

            case 'cancel':
              showEditButton = false;
              return (
                <Tooltip title={translate('ra.action.cancel')} key={buttonProps.id}>
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    onClick={buttonProps.onExecute}
                  >
                    <Icon fontSize="small" className={classes.icon}>
                      undo
                    </Icon>
                  </IconButton>
                </Tooltip>
              );

            default:
              return null;
          }
        })}

      {showEditButton && (
        <Tooltip title={translate('ra.action.edit')}>
          {!relationMode ? (
            <IconButton
              color="primary"
              className={classes.iconButton}
              component={Link}
              to={computeLink()}
              label={translate('ra.action.edit')}
              onClick={stopPropagation}
              disabled={!isThisRecordEditable}
            >
              <Icon
                fontSize="small"
                className={
                  isThisRecordEditable ? classes.icon : classes.disabledIcon
                }
              >
                create
              </Icon>
            </IconButton>
          ) : (
            <RelationEditButton
              disabled={!isThisRecordEditable}
              iconClassName={
                isThisRecordEditable ? classes.icon : classes.disabledIcon
              }
              parentResource={parentResource}
              resource={relationResource}
              parentId={parentId}
              id={row.id}
            />
          )}
        </Tooltip>
      )}
    </Fragment>
  );
};

ActionEditTableCell.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  basePath: PropTypes.string,
  currentUrl: PropTypes.string,
  quickEditButton: PropTypes.bool,
  parentInfo: PropTypes.object,
  metaData: PropTypes.object,
  row: PropTypes.object,
  relationMode: PropTypes.bool,
  relationResource: PropTypes.string,
};

export default ActionEditTableCell;
