import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'space-between',
    flexDirection: 'column',
  },

  minimizedMainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'space-between',
    flexDirection: 'row',
  },

  itemContainer: {
    width: '100%',
    height: '35px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 200ms',
    paddingLeft: 11,
    '&:hover': {
      background: theme.palette.todoHoveredItem,
    },
  },

  body: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexGrow: 1,
  },

  icon: {
    margin: 12,
    fontSize: 20,
    color: theme.palette.primary.appPrimaryIconColor,
  },

  menuTitle: {
    textAlign: 'center',
    padding: '15px 30px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  value: {
    fontSize: '0.8rem',
    paddingTop: 4,
  },

  withValue: {
    color: theme.palette.secondary.main,
  },

  deleteFileIcon: {
    fontSize: '1rem',
    margin: 10,
    color: theme.palette.primary.appPrimaryIconColor,
    transition: 'all 200ms',
  },

  titleAndValue: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 10px 0 10px',
  },

  hasSubMenu: {
    borderTop: '1px solid #999',
  },

  subMenu: {
    backgroundColor: 'rgba(200, 200, 200, 0.5)',
    zIndex: 1350,
  },

  /** used in todo-remind-me */
  calendarContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  todoItemText: {
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.todoLightGray,
  },
  todoSidebarSelectedIcon: {
    color: theme.palette.todoLightGray,
  },
  sidebarTodoIcon: {
    color: theme.palette.secondary.main,
  },
}));
