import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import { type FC, useRef, useMemo, useEffect, useState } from 'react';

import BarChartView from './bar-chart.view';
import { addThousandSeparator, getNumericFormat } from '../dynamic-chart.helper';
import { clone, isEmpty } from '../../../helper/data-helper';

import {
  getHightChartDrillDownSeries,
  getFirstLevelSeries,
} from './bar-chart.helper';
import type {
  BarChartControllerProps,
  ChartLabel,
  ItemChart,
} from './bar-chart.type';

const BarChartController: FC<BarChartControllerProps> = props => {
  const {
    reportData,
    chartOptions,
    width,
    height,
    handleChartItemClick,
    reportColumns,
    metaData,
  } = props;

  const fieldNamesRef = useRef<Record<string, unknown>[]>([]);
  const {
    displayNumber = 5,
    valueFieldName,
    axisFieldName,
    axisBottomLabelFieldName,
    axisLeftLabelFieldName,
  } = chartOptions;

  let numericFormat: string | null = null;

  const compatibleData = getFirstLevelSeries({
    data: reportData,
    columns: metaData?.columns,
    rowCountToShow: displayNumber,
    valueFieldName,
    axisFieldName,
  });

  useEffect(() => {
    if (metaData) {
      numericFormat = getNumericFormat(metaData, valueFieldName) as string;
    }
  }, [valueFieldName, axisFieldName, metaData?.reportId]);

  /**
   * get new report data
   * @returns {void} void
   */
  (function (): void {
    if (reportColumns == null) return;

    valueFieldName?.forEach(value => {
      fieldNamesRef.current = {
        ...fieldNamesRef.current,
        [value]: reportColumns[value],
      };
    });
  })();

  /**
   * @function handleClickBarChartItem
   * @param { ItemChart } data
   * @returns {void} void
   */
  const handleClickBarChartItem = (data: ItemChart): void => {
    if (!isEmpty(data.point?.y)) {
      // handleChartItemClick?.(chartOptions.axisFieldName, data.point?.y);
    }
  };

  const shouldThousandSeparate = useMemo(() => {
    if (Array.isArray(valueFieldName)) {
      return !!numericFormat;
    }
    return false;
  }, [valueFieldName.length, numericFormat]);

  /**
   * format chart value if necessary
   * @function formatter
   * @param {number | string} value
   * @returns {string}
   */
  const formatter = (value: number | string): string => {
    if (shouldThousandSeparate) {
      return addThousandSeparator(value);
    }

    return String(value);
  };

  /**
   * @function getLabel
   * @param { ChartLabel } value
   * @returns {string}
   */
  const getLabel = ({ label, id, value }: ChartLabel): string => {
    const title =
      label.split('-')[1] !== ' undefined' ? label.split('-')[1] : 'other';

    const fieldName = fieldNamesRef?.current?.[id];
    const thousandLabel = shouldThousandSeparate
      ? addThousandSeparator(value)
      : value;

    const currentLabel = title + ' - ' + fieldName + ' - ' + thousandLabel;

    return currentLabel;
  };

  const drillDownSeries = getHightChartDrillDownSeries({
    allRawData: clone(reportData),
    firstLevelSeries: clone(compatibleData),
    axisFieldName: axisFieldName.slice(1), //we processed first level in advance
    valueFieldName: valueFieldName,
    columns: metaData?.columns,
    rowCountToShow: displayNumber,
  });

  return (
    <BarChartView
      compatibleData={compatibleData}
      axisBottomLegend={axisBottomLabelFieldName}
      axisLeftLegend={axisLeftLabelFieldName}
      width={width}
      height={height}
      handleClickBarChartItem={handleClickBarChartItem}
      fieldNamesRef={fieldNamesRef?.current}
      valueFormat={formatter}
      shouldThousandSeparate={shouldThousandSeparate}
      getLabel={getLabel}
      drillDownSeries={drillDownSeries}
    />
  );
};

export default BarChartController;
