import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  styleTextArea: {
    width: '100%',
    margin: 'auto',
    marginTop: 10,
    outline: 'none',
  },
  container: {
    direction: 'rtl',
    padding: 2,
    width: '100%',
    overflow: 'hidden',
  },
  btnCss: { float: 'left' },
}));
