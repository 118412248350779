import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  containerAddUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 5,
    height: 300,
    maxHeight: 300,
    overflowY: 'auto',
  },
  containerTypeAssign: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    margin: 5,
  },
}));
