import { MouseEvent } from 'react';

import { ExtraParamsInterface, FormActions } from '../../../form';
import { newLabelId } from '../../../mail-section/mail-section.helper';
import TodoNewLabelView from './todo-new-label.view';

import { actorGetActionValue } from '../../../../type/actor-setup';
import type { TodoNewLabelControllerInterface } from '../todo-label.type';
import type { ReactElement } from 'react';

const TodoNewLabelController = (
  props: TodoNewLabelControllerInterface,
): ReactElement => {
  const { handleToggleNewLabelDialog, isNewLabelDialogOpen, getUserLabels } = props;

  /**
   * @function onCreateNewLabel
   * @param { MouseEvent<HTMLElement> } event
   * @returns { void }
   */
  const onCreateNewLabel = (event: MouseEvent<HTMLElement>): void => {
    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
    event.preventDefault();

    formActionsHandler(FormActions.Save, {
      actionUniqueId: newLabelId,
      onSuccess: getUserLabels,
    } as ExtraParamsInterface);

    handleToggleNewLabelDialog();
  };

  return (
    <TodoNewLabelView
      handleToggleNewLabelDialog={handleToggleNewLabelDialog}
      onCreateNewLabel={onCreateNewLabel}
      isNewLabelDialogOpen={isNewLabelDialogOpen}
    />
  );
};

export default TodoNewLabelController;
