import { memo, ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import type { HomeTitleViewInterface } from './home-title.type';

const HomeTitleView = (props: HomeTitleViewInterface): ReactElement => {
  const { resource, customText } = props;
  const translate = useTranslate();

  if (customText) {
    return (
      <Typography component="span" variant="button" color="inherit" id="headerTitle">
        {customText}
      </Typography>
    );
  }

  if (resource === '/') {
    return (
      <Typography component="span" variant="button" color="inherit" id="headerTitle">
        {translate('menu.home')}
      </Typography>
    );
  }
  if (resource === 'permission/show') {
    return (
      <Typography component="span" variant="button" color="inherit" id="headerTitle">
        {translate('permissions.permission')}
      </Typography>
    );
  }
  if (resource === 'advance/permission/show') {
    return (
      <Typography component="span" variant="button" color="inherit" id="headerTitle">
        {translate('permissions.advancePermission')}
      </Typography>
    );
  }
  return <></>;
};

export default memo(
  HomeTitleView,
  (
    prevProps: Readonly<HomeTitleViewInterface>,
    nextProps: Readonly<HomeTitleViewInterface>,
  ) => {
    return (
      prevProps.customText === nextProps.customText &&
      prevProps.resource === nextProps.resource
    );
  },
);
