import AreaChartView from './area-chart.view';

import type { FC } from 'react';
import type { AreaChartControllerProps } from './area-chart.type';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import { prepareHighChartData } from '../dynamic-chart.helper';

const AreaChartController: FC<AreaChartControllerProps> = props => {
  const { reportData, chartOptions, width, height, reportColumns } = props;
  const translate = useTranslate();

  const axisBottomLabelFieldName = reportColumns?.[
    chartOptions.axisFieldName
  ] as string;

  const axisLeftLabelFieldName = reportColumns?.[
    chartOptions.valueFieldName?.[0]
  ] as string;

  const compatibleData = prepareHighChartData({
    data: reportData,
    ...chartOptions,
  });

  if (!compatibleData?.length) {
    return (
      <Typography variant="h5" color="secondary">
        {translate('general.noData')}
      </Typography>
    );
  }

  return (
    <AreaChartView
      compatibleData={compatibleData}
      width={width}
      height={height}
      bottomLegend={axisBottomLabelFieldName}
      topLegend={axisLeftLabelFieldName}
    />
  );
};

export default AreaChartController;
