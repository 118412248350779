import { useTranslate } from 'react-admin';
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';

import { SettingSection } from './setting-section';
import { DynamicChart } from '../../../dynamic-chart';
import { useStyles } from './gadget-design-dialog.style';

import type { ReactElement } from 'react';

import type { GadgetDesignDialogPropsViewInterface } from './gadget-design-dialog.type';
import { isChartNeededReportId } from './gadget-design-dialog.helper';

const GadgetDesignDialogView = (
  props: GadgetDesignDialogPropsViewInterface,
): ReactElement => {
  const {
    gadgetInternalChangeHandler,
    dynamicChartPreparedProps,
    closeDialogHandler,
    saveGadget,
    gadgetValues,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.dialogMainContainer} data-test="GadgetDesignDialog">
      <DialogTitle>{translate('dynamicInput.gadgetDesign')}</DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentSectionContainer}>
          <div className={classes.settingSection}>
            <SettingSection
              gadgetValues={gadgetValues}
              gadgetInternalChangeHandler={gadgetInternalChangeHandler}
            />
          </div>
          <div className={classes.previewSection}>
            {!isChartNeededReportId(gadgetValues.chartType) ||
            gadgetValues.reportAddress ? (
              <DynamicChart {...dynamicChartPreparedProps} />
            ) : (
              <div className={classes.messageSection}>
                <Typography variant="h5" color="error">
                  {translate('gadget.pleaseChooseReport')}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActionsContainer}>
        <Button onClick={closeDialogHandler}>{translate('confirm.cancel')}</Button>
        <Button onClick={saveGadget} color="primary">
          {translate('ra.action.save')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default GadgetDesignDialogView;
