import { FC, memo } from 'react';
import { clone } from '../../../../../helper/data-helper';

import DiagramContainerView from './diagram-container.view';

import type { ReferencesDataInterface } from '../../../mail-section.type';
import type { DiagramContainerInterface } from './diagram-container.type';

const DiagramContainerController: FC<DiagramContainerInterface> = memo(props => {
  const { data } = props;

  /**
   * @function createTree
   * @returns {ReferencesDataInterface[]}
   */
  const createTree = (): ReferencesDataInterface[] => {
    const tree = {};

    // Group messages by ID
    const messagesById = data.reduce((map, message) => {
      map[message.messagereferences_id] = message;
      return map;
    }, {});

    data.forEach(message => {
      const parentId = message.fromreference_id;
      if (parentId === null) {
        // If fromreference_id is null, it is a root record
        tree[message.messagereferences_id] = message;
      } else {
        // If fromreference_id exists, it is a child record
        const parentMessage = messagesById[parentId];
        if (parentMessage) {
          parentMessage.children = parentMessage.children || [];
          parentMessage.children.push(message);
        }
      }
    });

    return Object.values(tree);
  };

  return <DiagramContainerView data={createTree()} />;
});

export default DiagramContainerController;
