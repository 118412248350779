import { useEffect, useState, useRef } from 'react';
import { GET_LIST } from 'react-admin';

import TodoShareDialogView from './todo-share-dialog.view';
import { actorDispatch } from '../../../type/actor-setup';
import { apiUrl, apiVersion } from '../../../core/data-Provider.helper';
import {
  dropdownShareResource,
  shareListResource,
  shareServiceUniqueId,
} from './todo-share-dialog.helper';
import { showNotification } from '../../../helper/general-function-helper';
import { getValue, SESSION_ID } from '../../../core/configProvider';

import type { ReactElement, ChangeEvent } from 'react';
import type {
  AutoCompleteOptionsAPIResponseInterface,
  SharingListInterface,
  TodoShareDialogPropsInterface,
  UserOptionInterface,
} from './todo-share-dialog.type';
import { CUSTOM_GET, RUN_SERVICE } from '../../../core/data-Provider.helper';

const TodoShareDialogController = (
  props: TodoShareDialogPropsInterface,
): ReactElement => {
  const { closeDialogHandler, dialogData } = props;
  const folderId = dialogData?.folderId;

  const [users, setUsers] = useState<UserOptionInterface[]>([]);
  const selectedOptionsRef = useRef<string>('');
  const sharingListRef = useRef<string>('');
  const [sharingList, setSharingList] = useState<SharingListInterface[]>([]);
  const [loading, setLoading] = useState(true);

  const sessionId = getValue(SESSION_ID);

  useEffect(() => {
    const separatePersonInfoId = sharingList
      ?.map(item => item.personinfo_id)
      .toString();
    sharingListRef.current = separatePersonInfoId;
  }, [sharingList]);

  /**
   * @function getAvatarURL
   * @param {string} personImage
   * @returns {string} string
   */
  const getAvatarURL = (personImage: string): string => {
    return `${apiUrl}/oauth2/${sessionId}/${apiVersion}${personImage}`;
  };

  /**
   * @function getUsers
   * @description get users from api
   * @returns {void} void
   */
  const getUsers = (): void => {
    actorDispatch(
      'crudAction',
      {
        type: GET_LIST,
        entity: 'todo_get_users',
        resource: dropdownShareResource,
        requestParameters: {
          pagination: {
            page: 1,
            perPage: 9999,
          },
          sort: {
            field: 'personinfo_id',
            order: 'desc',
          },
        },
        onSuccess: (result: AutoCompleteOptionsAPIResponseInterface): void => {
          if (result?.data) {
            setUsers(result.data);
          }
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'TodoShareDialogController => getUsers',
      },
    );
  };

  /**
   * @function handleShare
   * @description handle share button click
   * @returns {void} void
   */
  const handleShare = (): void => {
    actorDispatch(
      'crudAction',
      {
        type: RUN_SERVICE,
        entity: 'todo_share',
        actionUniqueId: shareServiceUniqueId,
        data: {
          params: {
            ShareWithPersonInfo_IDs:
              selectedOptionsRef.current + sharingListRef.current,
            Folders_ID: folderId,
          },
        },
        onSuccess: (response): void => {
          showNotification(response.userMessage, response.messageType);
          getSharingList();
          closeDialogHandler();
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'TodoShareDialogController => handleShare',
      },
    );
  };

  /**
   * @function getSharingList
   * @returns {void} void
   */
  const getSharingList = (): void => {
    actorDispatch(
      'crudAction',
      {
        entity: 'todo',
        type: GET_LIST,
        resource: `report/${shareListResource}`,
        requestParameters: {
          filter: [['folder_id', '=', String(folderId ?? '')]],
        },
        onSuccess: response => {
          actorDispatch('todoShareList', response.data);
          setSharingList(response.data);
          setLoading(false);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'TodoShareDialogController => getSharingList',
      },
    );
  };

  /**
   * @function handleChange
   * @description handle change in auto complete
   * @returns {void} void
   */
  const handleChange = (
    _event: ChangeEvent<{}>,
    value: UserOptionInterface[],
  ): void => {
    selectedOptionsRef.current = '';
    for (const user of value) {
      // add user personinfo_id to selectedOptionsRef.current like this 1,2,3,4,5
      selectedOptionsRef.current += `${user.personinfo_id},`;
    }
  };

  /**
   * @function deletePersonFromShareList
   * @param {number} id
   * @returns {void} void
   */
  const deletePersonFromShareList = (id: number) => (): void => {
    const separatePersonInfoId = sharingList.filter(
      item => item.personinfo_id !== id,
    );
    setSharingList(separatePersonInfoId);
  };

  useEffect(() => {
    getUsers();
    getSharingList();
  }, []);

  return (
    <TodoShareDialogView
      loading={loading}
      sharingList={sharingList}
      closeDialogHandler={closeDialogHandler}
      handleChange={handleChange}
      handleShare={handleShare}
      userOptions={users}
      getAvatarURL={getAvatarURL}
      deletePersonFromShareList={deletePersonFromShareList}
    />
  );
};

export default TodoShareDialogController;
