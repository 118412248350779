import { useCallback, useEffect, useRef, useState } from 'react';

import CreateGroupDialogView from './create-group-dialog.view';
import { actorDispatch } from '../../../../type/actor-setup';
import { isEmpty } from '../../../../helper/data-helper';

import type { ReactElement } from 'react';
import type {
  CreateGroupDialogPropsInterface,
  FileStreamInterface,
} from './create-group-dialog.type';

const CreateGroupDialogController = (
  props: CreateGroupDialogPropsInterface,
): ReactElement => {
  const { closeDialogHandler, dialogData } = props;
  const { IsChannel } = dialogData;

  const [hasError, setHasError] = useState<boolean>(false);

  const groupNameInputRef = useRef<HTMLInputElement>();
  const groupImageFileStream = useRef<FileStreamInterface>();

  useEffect(() => {
    groupNameInputRef.current?.focus();
  }, []);

  /**
   * @function successFileCallback
   * @param {FileStreamInterface} response
   * @returns
   */
  const successFileCallback = useCallback((response: FileStreamInterface) => {
    groupImageFileStream.current = response;
  }, []);

  /**
   * @function handleOpenAddMembersDialog
   * @returns {void} void
   */
  const handleOpenAddMembersDialog = useCallback((): void => {
    if (isEmpty(groupNameInputRef.current!.value)) {
      groupNameInputRef.current!.focus();
      setHasError(true);
      return;
    }

    actorDispatch('quickDialog', {
      addMembersDialogIsOpen: true,
      chatCreateGroupDialogIsOpen: false,
      data: {
        GroupName: groupNameInputRef.current!.value,
        GroupProfileImage: groupImageFileStream.current,
        groupUsers: null,
        GroupUID: null,
        IsChannel,
      },
    });
  }, []);

  /**
   * @function handleChange
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const handleChange = (): void => {
    if (hasError) {
      setHasError(false);
    }
  };

  return (
    <CreateGroupDialogView
      handleOpenAddMembersDialog={handleOpenAddMembersDialog}
      successFileCallback={successFileCallback}
      closeDialogHandler={closeDialogHandler}
      handleChange={handleChange}
      IsChannel={IsChannel}
      hasError={hasError}
      groupNameInputRef={groupNameInputRef}
    />
  );
};

export default CreateGroupDialogController;
