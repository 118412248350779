import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate, CREATE, FileInput, FileField, SimpleForm } from 'react-admin';
import {
  megaByteToByte,
  isEmpty,
  objectToLowerCaseProperties,
} from '../helper/data-helper';
import Typography from '@material-ui/core/Typography';
import dataProvider from '../core/dataProvider';
import { withStyles } from '@material-ui/core';
import LoadingBox from '../component/LoadingBox';
import { showNotification } from '../helper/general-function-helper';

// const FORM_NAME = 'image-select-modal';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  simpleForm: {
    flexGrow: 1,
    '& > div': {
      padding: '0px !important',
    },
  },

  fileInput: {
    margin: 0,
    marginTop: 15,
    '& > div': {
      padding: 0,
    },
  },

  dropZone: {
    padding: '15px 25px',
  },

  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },

  progressText: {
    margin: '0 5px',
  },
});

class UploadFileContainer extends Component {
  constructor(params) {
    super(params);

    this.state = {
      isSending: false,
      uploadedFile: null,
    };
  }

  render() {
    const { classes } = this.props;
    const { uploadedFile, isSending } = this.state;

    return (
      <div className={classes.container}>
        <SimpleForm className={classes.simpleForm} toolbar={null}>
          <FileInput
            className={classes.fileInput}
            disableClick={isSending}
            label=""
            source="temp_file"
            input={{
              onChange: this.onFileChange,
              onBlur: this.onFileChange,
              value: uploadedFile,
            }}
            meta={{}}
            classes={{ dropZone: classes.dropZone }}
          >
            <FileField source="temp_file_path" title="title" />
          </FileInput>
        </SimpleForm>
        {isSending && (
          <div className={classes.progressContainer}>
            <LoadingBox absolute />
            <Typography variant="caption" className={classes.progressText}>
              {this.props.translate('form.sendingData')}
            </Typography>
          </div>
        )}
      </div>
    );
  }

  onFileChange = file => {
    const { fileUploadLimitMB, translate } = this.props;
    if (
      !isEmpty(fileUploadLimitMB) &&
      megaByteToByte(+fileUploadLimitMB) < file?.['size']
    ) {
      showNotification(
        translate('file.fileUploadLimitMB', {
          fileUploadLimitMB,
        }),
        'error',
      );
      return;
    }
    this.setState({ uploadedFile: file });
    this.sendFile(file);
  };

  sendFile = file => {
    const { resource, onChange, defaultValue = {} } = this.props;

    // Dispatch an action letting react-admin know a API call is ongoing
    this.setState({ isSending: true });

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    dataProvider(CREATE, resource, { data: { file, ...defaultValue } })
      .then(({ data }) => {
        const _data = objectToLowerCaseProperties(data);
        // // react-admin/packages/ra-core/lib/actions/dataActions.js
        onChange({
          ..._data,
          originalFileName: file.name,
        });
      })
      .catch(error => {
        showNotification(error.message || error.toString(), 'error');
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        this.setState({ isSending: false, uploadedFile: null });
      });
  };
}

UploadFileContainer.propTypes = {
  onChange: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  translate(UploadFileContainer),
);
