import { type ReactElement, memo } from 'react';

import { RecordShowDetailsView } from './record-edit-details.view';

import type { RecordDetailsPropsInterface } from './record-edit-details.type';

const RecordShowDetailsController = (
  props: RecordDetailsPropsInterface,
): ReactElement => <RecordShowDetailsView {...props} />;

export default memo(RecordShowDetailsController, () => true);
