import { ReactElement, cloneElement, Fragment } from 'react';
import { Menu, useTheme } from '@material-ui/core';
import {
  Collapse,
  Icon,
  IconButton,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { useStyles } from './nav-collapse.style';
import { NavCollapse } from './index';
import clsx from 'classnames';
import { NavItem } from '../nav-item';
import { RoutesConfigInterface } from '../navigation-container.type';
import { isEmpty } from '../../../../helper/data-helper';

import type { NavCollapseViewInterface } from './nav-collapse.type';

const NavCollapseView = (props: NavCollapseViewInterface): ReactElement => {
  const {
    handleCloseContextMenu,
    contextMenuNode,
    onToggle,
    collapsingToggle,
    onContextMenu,
    openMenu,
    item,
    level,
    open,
    baseUrl,
    isContextMenuOpen,
    folderId,
    canDragDrop,
    parentComponentName,
    dragDropRef,
    isOver,
    parents,
    lastExpandedItems,
    collapseChangingCallback,
  } = props;
  const classes = useStyles({ level, isOver });
  const theme = useTheme();

  return (
    <div data-test="parentFolder">
      <ListItem
        button
        component="li"
        className={clsx(classes.navItem, classes.isOver, open && 'open')}
        onContextMenu={onContextMenu}
        data-test={item?.dataTest}
        data-style-navigation-collaps="navCollaps"
        ref={canDragDrop ? dragDropRef : null}
        onClick={collapsingToggle}
      >
        <Box p={0} clone mr={1}>
          <IconButton disableRipple>
            <Icon className="nav-item-icon-arrow" color="inherit">
              {open
                ? 'expand_more'
                : theme.direction === 'ltr'
                ? 'chevron_right'
                : 'chevron_left'}
            </Icon>
          </IconButton>
        </Box>

        {item.icon && (
          <Box component="span" mr={3}>
            <Icon color="action" className={classes.listIcon}>
              {item.icon}
            </Icon>
          </Box>
        )}
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary={`${item.title}`}
          onClick={onToggle}
        />
        {item?.count && item.count > 0 ? (
          <Box
            className={classes.countContainer}
            mr={1}
            component="span"
            onClick={onToggle}
          >
            {item.count}
          </Box>
        ) : null}

        {!isEmpty(isContextMenuOpen) && (
          <Menu
            open={isContextMenuOpen !== null}
            onClose={handleCloseContextMenu}
            anchorReference="anchorPosition"
            anchorPosition={
              isContextMenuOpen !== null
                ? { top: isContextMenuOpen.mouseY, left: isContextMenuOpen.mouseX }
                : undefined
            }
          >
            {contextMenuNode &&
              cloneElement(
                contextMenuNode as React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >,
                { ...item },
              )}
          </Menu>
        )}
      </ListItem>

      {item.children && Array.isArray(item.children) && (
        <Collapse in={open} className="collapse-children">
          {item.children.map((child: RoutesConfigInterface) => (
            <Fragment key={child.id}>
              {child.type === 'collapse' && (
                <NavCollapse
                  parents={[...(parents ?? []), item]}
                  item={child}
                  level={level + 1}
                  baseUrl={baseUrl}
                  canDragDrop={canDragDrop}
                  parentComponentName={parentComponentName}
                  setParentOpen={openMenu}
                  collapseChangingCallback={collapseChangingCallback}
                  lastExpandedItems={lastExpandedItems}
                  open={
                    Array.isArray(lastExpandedItems!.parents) &&
                    lastExpandedItems!.parents.findIndex(
                      option => option.id === child.id,
                    ) > -1
                  }
                />
              )}

              {child.type === 'item' && (
                <NavItem
                  parents={[...(parents ?? []), item]}
                  item={child}
                  level={level + 1}
                  baseUrl={baseUrl}
                  isContextMenuOpen={isContextMenuOpen}
                  setParentOpen={openMenu}
                  onContextMenu={onContextMenu}
                  handleCloseContextMenu={handleCloseContextMenu}
                  contextMenuNode={child?.contextMenuNode}
                  parentId={folderId}
                  canDragDrop={canDragDrop}
                  parentComponentName={parentComponentName}
                  isPreSelected={
                    lastExpandedItems!.selectedNavItem?.url === child.url ||
                    undefined
                  }
                />
              )}
            </Fragment>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default NavCollapseView;
