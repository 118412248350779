import { makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  btnRoot: {
    '& .MuiIconButton-label': {
      width: 24,
      height: 24,
      transform: 'rotate(180deg)',
      color: blue[300],
    },
    height: 40,
    width: 40,
    marginRight: 10,
  },
  replySection: {
    borderRadius: 8,
  },
  replayMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5px',
    marginRight: '5px',
  },
  container: { display: 'flex' },
  chatInput: {
    '& input': {
      padding: 0,
    },
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '10px 14px',
      border: 'none',
    },
  },
  videoDialog: {
    '& .MuiDialogContent-root': {
      padding: 0,
    },
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  },

  rtlDIrectionIcon: { transform: 'rotate(-180deg)' },
  speedDial: {
    height: 35,

    '& .MuiFab-primary': {
      backgroundColor: theme.palette.primary.appLightBackgroundColor,
      color: theme.palette.grey[400],
    },

    '& .MuiFab-root': {
      boxShadow: 'unset',
    },
  },
  styleTypography: {
    marginRight: '1rem',
  },
}));
