import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));
