import { FC, memo } from 'react';
import { useTranslate } from 'react-admin';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Checkbox, Icon } from '@material-ui/core';

import { useStyles } from './todo-steps.style';
import { TodoStepItems } from './todo-step-items';

import type { TodoStepsViewInterface } from './todo-steps.type';

const TodoStepsView: FC<TodoStepsViewInterface> = memo(props => {
  const {
    handleChangeIsImportantTask,
    handleChangeDoneStatusTask,
    handleChangeTaskTitle,
    updateStep,
    removeStep,
    addStep,
    isDoneTaskImportant,
    newStepInputRef,
    isImportantTask,
    taskTitle,
    isLoading,
    stepList,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerContainer}>
        <Checkbox
          value={isDoneTaskImportant}
          checked={isDoneTaskImportant}
          onChange={handleChangeDoneStatusTask}
          color="secondary"
          icon={<Icon className={classes.defaultIcon}>radio_button_unchecked</Icon>}
          checkedIcon={<Icon className={classes.defaultIcon}>check_circle</Icon>}
        />
        <input
          className={classes.taskTitleInput}
          onChange={handleChangeTaskTitle}
          defaultValue={taskTitle}
        />
        <Checkbox
          value={isImportantTask}
          checked={isImportantTask}
          onChange={handleChangeIsImportantTask}
          color="secondary"
          icon={<Icon className={classes.defaultIcon}>star_border</Icon>}
          checkedIcon={<Icon className={classes.defaultIcon}>star</Icon>}
          disableRipple
        />
      </div>
      <div className={classes.stepListContainer}>
        <DndProvider backend={HTML5Backend}>
          {stepList.map(stepItem => {
            return (
              <TodoStepItems
                stepItem={stepItem}
                updateStep={updateStep}
                removeStep={removeStep}
                isLoading={isLoading}
              />
            );
          })}
        </DndProvider>
      </div>
      <div className={classes.inputContainer}>
        <Icon color="secondary" className={classes.addIcon} fontSize="small">
          add
        </Icon>
        <input
          className={classes.taskTitle}
          placeholder={
            isLoading ? translate('form.sendingData') : translate('todo.newStep')
          }
          ref={newStepInputRef}
          onKeyUp={addStep}
          disabled={isLoading}
          autoFocus={true}
        />
      </div>
    </div>
  );
});
export default TodoStepsView;
