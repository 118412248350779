import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    overflow: 'hidden',
    fontSize: '30px',
    fontWeight: 'bold',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    borderBottom: '1px solid' + theme.palette.primary.appBorderInput,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '5px 0',
    fontSize: '20px',
  },
}));
