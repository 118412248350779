import { FC, memo, ReactChildren } from 'react';
import { makeStyles, Link } from '@material-ui/core';
import { linkToRecord } from 'react-admin';
import { actorGetActionValue } from '../../type/actor-setup';
import { FieldType, MetaData } from '../../helper/Types';
import { isRelationCellEditableWithoutTypeChecking } from '../relation-panel/relation-panel.helper';

interface GridColumnLinkInterfaceProps {
  row: {
    id: string;
  };
  children: ReactChildren | React.ReactChild | Element;
  basePath: string;
  hasShow: boolean;
  source: string;
  relationMode?: boolean;
  field: FieldType;
  metaData: MetaData;
  hasEdit: boolean;
}

const styles = makeStyles(theme => ({
  link: {
    display: 'block',
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
    minHeight: 10,
  },
}));

const GridColumnLink: FC<GridColumnLinkInterfaceProps> = props => {
  const {
    children,
    basePath,
    row,
    hasShow,
    source,
    relationMode,
    field,
    metaData,
    hasEdit,
  } = props;
  const classes = styles();

  let computedPath = basePath;
  const currentUrl = actorGetActionValue('urlInfo');

  if (currentUrl?.params && relationMode) {
    const { moduleName, moduleTableName, id } = currentUrl.params;

    computedPath = relationMode
      ? `/${moduleName}/${moduleTableName}/${id}${computedPath}`
      : computedPath;
  }

  return !isRelationCellEditableWithoutTypeChecking(field, metaData, hasEdit) &&
    hasShow &&
    row ? (
    <Link
      href={`#${linkToRecord(computedPath, row.id)}/show`}
      className={classes.link}
      data-test-grid-column-link-name={source}
      data-style-grid-column-link-name="gridLink"
    >
      {/* @ts-ignore */}
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export default GridColumnLink;
