import { ReactElement } from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import { getTranslatedName } from '../../helper/MetaHelper';
import { isCleanStringMatch } from '../../helper/TextHelper';
import { MetaData } from '../../helper/Types';
import { CustomForm, FormActionProps, FormController, FormWithTabs } from '../form';
import QuickCreateButtonToolbar from '../QuickCreateButtonToolbar';
import { DialogViewProps } from './dialogs-stack.type';
import { useStyles } from './dialogs-stack.style';

export const QuickCreateDialogView = (props: DialogViewProps): ReactElement => {
  // prettier-ignore
  const { dialogData, locale, currentResource, closeDialogHandler, dialogType } = props;

  const classes = useStyles();

  return (
    <>
      <DialogTitle id="quickCreateDialogTitle">
        {isCleanStringMatch(getTranslatedName(dialogData.metaData, locale), 'ي')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormController formName={dialogType as keyof FormActionProps} isQuickForm>
          <CustomForm
            isBottomToolbar
            Toolbar={
              <QuickCreateButtonToolbar
                closeDialog={closeDialogHandler}
                redirect={''} // FIXME: What is it?!!!
                parentInfo={dialogData.parentInfo as Record<string, unknown>}
                metaData={dialogData.metaData as MetaData}
                disableFullFormButton={dialogData.relationMode as boolean}
                resource={currentResource!.value}
                relationMode={dialogData.relationMode as boolean | undefined}
                mustRefresh={dialogData.mustRefresh as boolean | undefined}
              />
            }
          >
            <FormWithTabs dialogData={dialogData} />
          </CustomForm>
        </FormController>
      </DialogContent>
    </>
  );
};
