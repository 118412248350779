import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import lodashDebounce from 'lodash/debounce';

import { convertDigitsToEnglish } from '../../helper/NumberHelper';
import {
  ChangeFormValueParams,
  FormActions,
  OnBlurParams,
  OnFocusParams,
} from '../form/form.type';
import { TextInputProps } from './text-input.type';
import { TextInputView } from './text-input.view';
import { TextInputWMSView } from './text-input-wms.view';
import { validUrlPattern } from '../../helper/data-helper';
import { openNewTab } from '../../helper/QuickAccessHelper';

const TextInputController = (props: TextInputProps): ReactElement => {
  const {
    value,
    formActionsHandler,
    inputMessage,
    resource,
    field,
    label,
    getRef,
    hint,
    className,
    disabled,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
    inputInPuzzleForm,
    isUrlInput,
  } = props;

  const { name } = field;

  const [passwordShown, setPasswordShown] = useState(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const [inputValueIsAValidUrl, setInputValueIsAValidUrl] = useState<boolean>(false);

  const hashedInputValueIsChanged = useRef<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null | undefined>();

  useEffect(() => {
    if (!field.isHashed) {
      setInputValue(value ?? '');
      return;
    }

    /**
     * @description
     * When we have a password field, its value is a `hash` value
     * that has been got from the `api`, if we show that value and the user changes some of chars,
     * the password will be a complex chars, to prevent it we show an empty input,
     * so if the value changes we send it otherwise the password doesn't change
     */
    if (!hashedInputValueIsChanged.current) {
      setInputValue('');
    }
  }, [value]);

  useEffect(() => {
    if (isUrlInput) {
      checkValueIsAValidUrl(inputValue);
    }
  }, [inputValue]);

  /**
   * Handle Blur event
   * @function handleBlur
   * @returns {void} void
   */
  const handleBlur = useCallback((): void => {
    formActionsHandler(FormActions.InputBlur, {
      fieldName: name,
      value: inputValue,
    } as OnBlurParams);
  }, [inputValue]);

  /**
   * Handle focus event
   * @function handleFocus
   * @returns {void} void
   */
  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>): void => {
      if (inputInPuzzleForm) {
        event.target.select();
      }

      formActionsHandler(FormActions.InputFocus, {
        fieldName: name,
        value: inputValue,
      } as OnFocusParams);
    },
    [inputValue, inputInPuzzleForm],
  );

  /**
   * Handles internal changes
   * @function handleChange
   * @param {ChangeEvent} event
   * @returns void
   */
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const formattedValue = convertDigitsToEnglish(event.target.value);

      setInputValue(formattedValue);

      //@description
      if (field.isHashed) {
        hashedInputValueIsChanged.current = true;
      }

      formActionsHandler(FormActions.InputChange, {
        fieldName: name,
        value: formattedValue,
        isHidden: visibleClass.includes('displayNone'),
      } as ChangeFormValueParams);
    },
    [],
  );

  /**
   * @function checkValueIsAValidUrl
   * @param { string } value
   * @returns { boolean } a boolean
   */
  const checkValueIsAValidUrl = useCallback(
    lodashDebounce((value: string): void => {
      // We have to run `setState` by a `debounce`deb
      setInputValueIsAValidUrl(validUrlPattern.test(value));
    }, 300),
    [],
  );

  /**
   * @function handleUrlForOpenNewTab
   * @returns {void} void
   */
  const handleUrlForOpenNewTab = useCallback((): void => {
    openNewTab(inputValue, 'new');
  }, [inputValue]);

  /**
   * @function ClickedButtonPassword
   * @returns {void} void
   */
  const changeShowPassword = useCallback((): void => {
    setPasswordShown(perv => !perv);
  }, []);

  const ViewComponent = inputInPuzzleForm ? TextInputWMSView : TextInputView;

  return (
    <ViewComponent
      getRef={getRef}
      field={field}
      value={inputValue}
      inputMessage={inputMessage}
      className={className}
      label={label}
      hint={hint}
      disabled={disabled}
      resource={resource}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleChange={handleChange}
      visibleClass={visibleClass}
      inputContainerClass={inputContainerClass}
      customTestAttribute={customTestAttribute}
      passwordShown={passwordShown}
      changeShowPassword={changeShowPassword}
      inputValueIsAValidUrl={inputValueIsAValidUrl}
      handleUrlForOpenNewTab={handleUrlForOpenNewTab}
      inputRef={inputRef}
    />
  );
};

TextInputController.defaultProps = {
  className: '',
  disabled: false,
  errorMessage: '',
  field: undefined,
  formActionsHandler: undefined,
  inputRef: undefined,
  source: '',
  value: '',
  label: '',
};

export default TextInputController;
