import { createPieChartData } from './pie-chart.helper';
import PieChartView from './pie-chart.view';

import { DefaultRawDatum } from '@nivo/pie';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import { type FC } from 'react';
import type { PieChartControllerProps } from './pie-chart.type';

const PieChartController: FC<PieChartControllerProps> = props => {
  const { reportData, chartOptions, height, width, handleChartItemClick, metaData } =
    props;

  const {
    valueFieldName,
    labelFieldName,
    idFieldName,
    colorFieldName,
    displayNumber,
  } = chartOptions;

  const translate = useTranslate();

  const compatibleData = createPieChartData({
    data: reportData,
    valueFieldName,
    labelFieldName,
    idFieldName,
    colorFieldName,
    displayNumber,
  });

  /**
   * @function onClickPieItem
   * @param {DefaultRawDatum} data
   * @returns {void} void
   */
  const onClickPieItem = (data: DefaultRawDatum): void => {
    if (data.id) {
      handleChartItemClick?.(data?.id);
    }
  };

  if (!compatibleData.length) {
    return (
      <Typography variant="h5" color="secondary">
        {translate('general.noData')}
      </Typography>
    );
  }
  return (
    <PieChartView
      compatibleData={compatibleData}
      height={height}
      width={width}
      handleChartItemClick={onClickPieItem}
    />
  );
};

export default PieChartController;
