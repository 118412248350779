import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 16,
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  right: {
    flex: 1,
    margin: 8,
    position: 'relative',
    overflow: 'unset',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      maxWidth: '30%',
      zIndex: '10',
      height: '98%',
      animation: `$closeSideMenu 2000ms ${theme.transitions.easing.easeInOut}`,
      transform: 'translateX(-104%)',
    },
    [theme.breakpoints.up('xs')]: {
      maxWidth: '50% !important',
    },
  },
  rightClose: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      maxWidth: '30%',
      zIndex: '10',
      height: '98%',
      animation: `$closeSideMenu 2000ms ${theme.transitions.easing.easeInOut}`,
      transform: 'translateX(-104%)',
    },
  },
  rightOpen: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      maxWidth: '30%',
      zIndex: '10',
      height: '98%',
      animation: `$openSideMenu 2000ms ${theme.transitions.easing.easeInOut}`,
      transform: 'translateX(0)',
    },
  },
  left: {
    flex: 4,
    position: 'relative',
    contain: 'strict',
  },
  top: { padding: 8 },
  calendar: {
    padding: 4,
    '& .heading .prev,.heading  .next': {
      boxShadow: '0px 1px 4px rgb(0 0 0 / 25%)',
      borderRadius: 4,
      width: 25,
      height: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .heading,.daysOfWeek': {
      marginBottom: 0,
    },
    '& .dayPickerContainer .dayWrapper': {
      marginTop: 0,
    },
    '& .heading .title': {
      padding: 0,
    },
    '& .dayPickerContainer .dayWrapper button': {
      width: 35,
      height: 35,
    },
  },
  containerCalender: {
    '& .selectToday': {
      display: 'none',
    },
    width: '100%',
    padding: 8,
    boxShadow:
      '-4px -4px 8px 3px rgba(242, 242, 245, 0.8), 4px 4px 8px 3px rgba(242, 242, 245, 0.8)',
  },
  closeArrow: {
    minWidth: 30,
    height: 45,
    borderRadius: '0 110px 110px 0 ',
    top: '50%',
    right: 0,
    position: 'absolute',
    background: '#C7E1F2',
    zIndex: 10,
    transform: 'translateX(100%)',
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  rootRight: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  '@keyframes closeSideMenu': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(-104%)',
    },
  },
  '@keyframes openSideMenu': {
    '0%': {
      transform: 'translateX(-104%)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  filter: {
    display: 'flex',
    '& > fieldset': {
      flexBasis: '40%',
    },
  },
}));
