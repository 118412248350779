import { FC, memo, useContext, useEffect, useRef, useState } from 'react';

import { NewMetaContext } from '../../container/NewMetaContext';

import { getPrintResource } from '../../helper/PrintMetaHelper';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
} from '../../type/actor-setup';
import { initializeLastFilters, prepareActionBarProps } from '../list/list.helper';
import { updateUrlQueryParams } from '../../helper/general-function-helper';
import PrintReportView from './print-report.view';

import type { PrintReportInterface } from './print-report.type';
import type { GeneralMetaData } from '../../helper/Types';
import type { FilterItemFinalFormatType, FinalFiltersType } from '../filter-form';

const PrintReportController: FC<PrintReportInterface> = memo(props => {
  const { listType, metaData, resource, isRelation } = props;

  const [iframeKey, setIframeKey] = useState(0);

  const { getMeta } = useContext(NewMetaContext);
  // prettier-ignore
  const printFilters = useRef<(FilterItemFinalFormatType | FilterItemFinalFormatType[])[]>(
    actorGetActionValue('gridData')?.[resource]?.requestParameters?.filter ?? []
  );
  const printResource = getPrintResource(
    (metaData?.prints[0] as Record<string, unknown>)?.id,
  );

  useEffect(() => {
    getMeta(printResource);
    initializeLastFilters(metaData as GeneralMetaData);

    updateUrlQueryParams({ filter: printFilters.current });

    actorOnDispatch(
      'filterDataIsChanged',
      (finalFilters: Record<string, FinalFiltersType>) => {
        const filter = finalFilters?.[resource!];
        if (filter) {
          actorSetActionValue('gridData', filter, {
            path: `${resource}.requestParameters.filter`,
            replaceAll: true,
            callerScopeName: 'FilterFormController => updateGridFilters',
          });

          updateUrlQueryParams({ filter: finalFilters });
          printFilters.current = filter;

          setIframeKey(Math.random()); // can replace with `window.frames['report-print'].location.reload();`  ?
        }
      },
      { preserve: false },
    );
  }, []);

  if (!printResource) {
    // TODO: Show a properly view in this situation if need
    return null;
  }

  const actionBarProps = prepareActionBarProps(
    listType,
    resource,
    metaData as GeneralMetaData,
  );

  // In `print`s `rootResource` equals `resource`
  actionBarProps.rootResource = resource;

  return (
    <PrintReportView
      iframeKey={iframeKey}
      actionBarProps={actionBarProps}
      filterValues={JSON.stringify(printFilters.current)}
      printResource={printResource}
      isRelation={isRelation}
    />
  );
});

export default PrintReportController;
