import range from 'lodash/range';
import momentJalaali from 'moment-jalaali';
import { toPersianDigits } from '../utils/moment-helper';
import { persianNumber } from '../utils/persian';

// List of months
const monthsJalaaliPersian = [
  'فروردین',
  'اردیبهشت',
  'خرداد',
  'تیر',
  'مرداد',
  'شهریور',
  'مهر',
  'آبان',
  'آذر',
  'دی',
  'بهمن',
  'اسفند'
];
const monthsJalaaliEnglish = [
  'Farvardin',
  'Ordibhesht',
  'Khordad',
  'Tir',
  'Mordad',
  'Shahrivar',
  'Mehr',
  'Aban',
  'Azar',
  'Dey',
  'Bahman',
  'Esfand'
];

const monthsGregorianEnglish = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const monthsGregorianPersian = [
  'ژانویه',
  'فوریه',
  'مارس',
  'آوریل',
  'مه',
  'جوئن',
  'ژوئیه',
  'اوت',
  'سپتامبر',
  'اکتبر',
  'نوامبر',
  'دسامبر'
];

// List of months
const yearsJalaaliEnglish = range(momentJalaali(new Date()).jYear() + 100, 1300);
const yearsJalaaliPersian = range(momentJalaali(new Date()).jYear() + 100, 1300).map(item =>
  toPersianDigits(item.toString())
);

const yearsGregorianEnglish = range(momentJalaali(new Date()).year() + 100, 1920);
const yearsGregorianPersian = range(momentJalaali(new Date()).year() + 100, 1920).map(item =>
  toPersianDigits(item.toString())
);

// Day of week names for use in date-picker heading
const dayOfWeekNamesJalaali = ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج'];
const dayOfWeekNamesJalaaliEnglish = ['Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr'];
const dayOfWeekNamesGregorian = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const dayOfWeekNamesGregorianPersian = ['ش', 'ج', 'پ', 'چ', 'س', 'د', 'ی'];

export {
  monthsGregorianPersian,
  monthsGregorianEnglish,
  monthsJalaaliPersian,
  monthsJalaaliEnglish,
  yearsJalaaliEnglish,
  yearsJalaaliPersian,
  yearsGregorianEnglish,
  yearsGregorianPersian,
  dayOfWeekNamesJalaali,
  dayOfWeekNamesJalaaliEnglish,
  dayOfWeekNamesGregorian,
  dayOfWeekNamesGregorianPersian
};
