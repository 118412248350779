import {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { useTranslate } from 'react-admin';

import PaginationView from './pagination.view';

import type { PaginationControllerInterface } from './pagination.type';
import { LabelDisplayedRowsArgs } from '@material-ui/core';

const PaginationController: FC<PaginationControllerInterface> = props => {
  const translate = useTranslate();

  const {
    total,
    perPage,
    page,
    setPage,
    setPerPage,
    isLoading,
    rowsPerPageOptions = [
      10,
      25,
      100,
      1000,
      //per page -1 = total rows
      { value: -1, label: translate('grid.total') },
    ],
    isCompactMode,
    isRelation,
  } = props;

  /**
   * Calculate how many page exist
   * @function getNbPages
   * @returns {number}
   */
  const getNbPages = useCallback(
    (): number => Math.ceil(total / perPage) || 1,
    [total, perPage],
  );

  /**
   * `Warning`: material-ui's page is 0-based
   * @function handlePageChange
   * @param {Event} event
   * @param {number} page page number
   * @returns {void}
   */
  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ): void => {
    event && event.stopPropagation();
    if (page < 0 || page > getNbPages() - 1) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', {
          page: page + 1,
        }),
      );
    }
    setPage(page + 1);
  };

  /**
   * Update how many rows are in each page
   * @function handlePerPageChange
   * @param {Event} event
   * @returns {void}
   */
  const handlePerPageChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    if (event && event.target) {
      if (!isRelation) {
        setPage(1);
      }
      setPerPage(Number(event.target.value));
    }
  };

  /**
   * prepare pagination labels
   * @function labelDisplayedRows
   * @param {object} param
   * @returns {object}
   */
  const labelDisplayedRows = ({ from, to }: LabelDisplayedRowsArgs): ReactNode => {
    return translate('ra.navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: perPage === -1 ? total : to,
    });
  };

  useEffect(() => {
    if (page < 1 || isNaN(page)) {
      setPage(1);
    }
  }, [page]);

  return (
    <PaginationView
      isLoading={!!isLoading}
      isCompactMode={!!isCompactMode}
      total={total}
      perPage={perPage}
      page={page}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={rowsPerPageOptions}
      handlePageChange={handlePageChange}
      handlePerPageChange={handlePerPageChange}
    />
  );
};

export default PaginationController;
