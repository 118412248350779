import { FC } from 'react';

import { InboxSection } from '../../mail-section';
import { TodoSection } from '../../todo-section';
import { MAIL, TODO } from '../../../core/configRouteConstant';
import { TodoActionSideBar } from '../../todo-section/task-action-side-bar';

const AutomationListPanelController: FC = () => {
  if (location.href.includes(MAIL)) {
    return <InboxSection visible />;
  }
  if (location.href.includes(TODO)) {
    return (
      <>
        <TodoSection />
        <TodoActionSideBar />
      </>
    );
  }

  return null;
};
export default AutomationListPanelController;
