//  fixme: remove this folder after refactor multi report

import { UPDATE_REPORT_DATA } from './constant';

export const updateReportWithCallbackAction = (resource, params, callback) => ({
  type: UPDATE_REPORT_DATA,
  resource,
  params,
  callback,
});
