import { useTheme } from '@material-ui/core';
import {
  FC,
  memo,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Identifier, GET_LIST } from 'react-admin';
import { ItemParams, TriggerEvent, useContextMenu } from 'react-contexify';
import { getValue, USER_ID, WEB_SOCKET_API_URL } from '../../core/configProvider';
import { isEmpty, isEmptyObject } from '../../helper/data-helper';
import { showNotification } from '../../helper/general-function-helper';
import {
  getNotificationItemPatternInfo,
  getNotificationPatternInfo,
} from '../../helper/PatternMetaHelper';
import {
  NotificationItemInterface,
  NotificationListInterface,
} from '../../helper/Types';

import {
  getEventValue,
  NOTIFICATION_SIGNAL,
} from '../../hooks/useSignalRConnection';
import { actorDispatch, actorOnDispatch } from '../../type/actor-setup';
import NotificationPanelView from './notification-panel.view';
import { onClickNotificationItem } from './notification-panel.helper';
import { FinalFiltersType } from '../filter-form';

const unSeenCondition = ['and', ['isseen', 'equal', 'false']];
const NotificationPanelController: FC = memo(() => {
  const theme = useTheme();

  const [notificationList, setNotificationList] = useState<
    NotificationItemInterface[]
  >([]);
  const [showAllNotification, setShowAllNotification] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [unSeenCounter, setUnSeenCounter] = useState<number>(0);

  const [webNotificationItem, setWebNotificationItem] =
    useState<NotificationItemInterface | null>(null);

  const notificationIconTriggerRef: RefObject<HTMLButtonElement> = useRef(null);
  const { notificationItemPatternName, actionSeenId } =
    getNotificationPatternInfo('notification');

  const { id: notificationId } = getNotificationItemPatternInfo(
    notificationItemPatternName,
  );
  const lastPage = useRef<number>(1);
  const currentUserId = getValue(USER_ID);

  const { show } = useContextMenu({
    id: 'notificationPanel',
  });

  /**
   * @function onSignalRNotificationEventCallback
   * @param message
   * @returns { void }
   */
  const onSignalRNotificationEventCallback = (message): void => {
    console.log('signalR Notification: ', message);
    actorDispatch('signalRNotification', message, { disableDebounce: true });
  };

  /**
   * @function getSignalRNotification
   * @returns { Promise<void> }
   */
  const getSignalRNotification = (): void => {
    //TODO: fix bug in multiple connection in quick way.We should handle synchronicity in handleConnectToSignalR
    setTimeout(() => {
      getEventValue({
        connectionType: NOTIFICATION_SIGNAL,
        connectionUrl: 'hub/Notification?app=5&clientid=' + currentUserId,
        userId: currentUserId,
        signalREvent: 'RefreshNotification',
        onEventCallback: onSignalRNotificationEventCallback,
      });
    }, 500);
  };

  useEffect(() => {
    getNotificationList([], onSuccessResetNotificationList);
  }, [showAllNotification]);

  useEffect(() => {
    //show signalR notification
    !isEmpty(currentUserId) && getSignalRNotification();
    actorOnDispatch('signalRNotification', message => {
      showNotificationItem(message);
    });
  }, []);

  /**
   * @function getNotification
   * @param {Record<string, unknown>} filter
   * @param {(data: NotificationListInterface) => void} onSuccess
   * @returns {void}
   */
  const getNotificationList = (
    filter: FinalFiltersType,
    onSuccess: (data: NotificationListInterface) => void,
  ): void => {
    const { reportId } = getNotificationPatternInfo('notification');
    const finalFilter = !showAllNotification
      ? [...filter, ...unSeenCondition]
      : filter;

    actorDispatch(
      'crudAction',
      {
        entity: 'notification',
        type: GET_LIST,
        resource: `report/${reportId}`,
        requestParameters: {
          pagination: { page: lastPage.current, perPage: 10 },
          sort: { field: 'id', order: 'DESC' },
          filter: finalFilter,
          addPaginationToFilter: true,
        },
        onSuccess: response => {
          if (!isEmptyObject(response?.data)) {
            setUnSeenCounter(Number(response.data[0]?.notseencount ?? 0));
            setHasMore(true);
          } else {
            setUnSeenCounter(0);
            setHasMore(false);
          }
          onSuccess?.(response);
        },
        onFailure: () => {
          // actorDispatch('notification', {
          //   message: 'Getting report data failed',
          //   type: 'error',
          // });
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'NotificationPanelController => getNotificationList',
      },
    );
  };

  /**
   * @function fetchMoreNotification
   * @returns {void}
   */
  const fetchMoreNotification = (): void => {
    lastPage.current++;
    getNotificationList([], onSuccessFetchMoreNotification);
  };

  /**
   * Show notification item based on web sockets.
   * @function onSuccessFetchMoreNotification
   * @param {NotificationListInterface} response
   * @returns {void}
   */
  const onSuccessFetchMoreNotification = (
    response: NotificationListInterface,
  ): void => {
    setNotificationList(prevData => [...prevData, ...response?.data]);
  };

  /**
   * @function showWebNotification
   * @param {NotificationListInterface} response
   * @returns {void}
   */
  const showWebNotification = (response: NotificationListInterface): void => {
    if (!isEmptyObject(response)) {
      const notification = response?.data[0];

      if (!isEmptyObject(notification)) {
        setWebNotificationItem(notification);
        if (notification?.type == 'popup') {
          showNotification(notification.notifytext, 'confirm', {
            forceShowInDialog: true,
          });
        }
      }
    }
  };

  /**
   * Show notification item based on web sockets.
   * @function showNotificationItem
   * @param {Identifier} id
   * @returns {void}
   */
  const showNotificationItem = (id: Identifier): void => {
    actorDispatch('refreshView', `RefreshMailbox`);
    getNotificationList([['notification_id', 'equal', id]], showWebNotification);
  };

  /**
   * Get notification icon position in app header. ذ
   * @function getMenuPosition
   * @returns {{x: number; y: number} | null | undefined}
   */
  const getMenuPosition = useCallback(():
    | { x: number; y: number }
    | null
    | undefined => {
    if (notificationIconTriggerRef && notificationIconTriggerRef.current) {
      const { left, right, bottom } =
        notificationIconTriggerRef.current.getBoundingClientRect();
      if (theme.direction === 'rtl') {
        return { y: bottom + 10, x: left - 20 };
      } else {
        return { y: bottom + 10, x: right - 285 };
      }
    } else {
      return { y: 0, x: 0 };
    }
  }, [notificationIconTriggerRef]);

  /**
   * Show menu contextify and run seen notification service and get `notificationList`.
   * @function handleNotificationIconClick
   * @param {TriggerEvent} event
   * @returns {Promise<void>}
   */
  const handleNotificationIconClick = async (event: TriggerEvent): Promise<void> => {
    show(event, {
      id: 'notificationPanel',
      position: getMenuPosition(),
    });

    lastPage.current = 1;
    getNotificationList([], onSuccessResetNotificationList);
  };

  /**
   * Show notification item based on web sockets.
   * @function onSuccessResetNotificationList
   * @param {NotificationListInterface} response
   * @returns {void}
   */
  const onSuccessResetNotificationList = (
    response: NotificationListInterface,
  ): void => {
    setNotificationList(response?.data);
  };

  /**
   * this function send action api for seen messages
   * @function handleSeenMessages
   * @param {string} ids
   */
  const handleSeenMessages = (ids: string) => {
    actorDispatch(
      'crudAction',
      {
        type: 'RUN_SERVICE',
        data: {
          params: {
            notifyid: ids,
          },
        },
        actionUniqueId: actionSeenId,
        onSuccess: () => {
          lastPage.current = 1;
          getNotificationList([], onSuccessResetNotificationList);
        },
      },
      {
        disableDebounce: true,
        replaceAll: true,
        callerScopeName: 'NotificationPanelController => handleSeenMessages',
      },
    );
  };

  /**
   * @function seenAllMessages
   * @param {MouseEvent<HTMLAnchorElement>} event
   * @returns {void}
   */
  const seenAllMessages = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.stopPropagation(); //dont close menu
    if (notificationList?.length == 0) {
      return;
    }
    const unSeenIds = notificationList
      .filter(item => !item.isseen)
      .map(item => item.notification_id)
      .join(',');

    if (!isEmpty(unSeenIds)) {
      handleSeenMessages(unSeenIds);
    }
  };

  /**
   * @function toggleNotificationListDialog
   * @param {MouseEvent<HTMLAnchorElement>} event
   * @returns {void}
   */
  const toggleShowAllNotification = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.stopPropagation(); //dont close menu
    setShowAllNotification(flag => !flag);
    lastPage.current = 1;
  };

  /**
   * @function handleClickNotificationItem
   * @param {NotificationItemInterface} item
   * @param {ItemParams} event
   * @returns {void}
   */
  /** */
  const handleClickNotificationItem =
    (item: NotificationItemInterface) => (event: ItemParams) => {
      handleSeenMessages(item.notification_id);
      onClickNotificationItem(item);
    };

  return (
    <NotificationPanelView
      handleNotificationIconClick={handleNotificationIconClick}
      notificationIconTriggerRef={notificationIconTriggerRef}
      unSeenCounter={unSeenCounter}
      notificationList={notificationList}
      fetchMoreData={fetchMoreNotification}
      hasMore={hasMore}
      webNotificationItem={webNotificationItem}
      showAllNotification={showAllNotification}
      toggleShowAllNotification={toggleShowAllNotification}
      seenAllMessages={seenAllMessages}
      handleClickNotificationItem={handleClickNotificationItem}
    />
  );
});

export default NotificationPanelController;
