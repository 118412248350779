import { makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { CustomTheme } from '../../../core/themeProvider';
import { StyleProps } from './sidebar-menu.type';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  maincontainer: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  starIcon: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.7)',
    display: 'none',
    opacity: 0,
    fontSize: 15,
    transition: 'all .3s ease',
    '&:hover': {
      fontSize: 16,
      color: 'rgba(0, 0, 0, 1)',
    },
  },

  samianLogo: {
    width: '100%',
  },

  container: {
    width: '100px',
    marginTop: '10px',
    margin: 'auto',
    textAlign: 'center',
  },

  spinner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },

  innerparent: {
    position: 'relative',
    width: '100%',
    flexGrow: 1,
    height: 1,
  },

  menulist: {
    listStyleType: 'none',
    padding: '10px 10px 0 10px',
    margin: 0,
    overflowY: 'auto',
    height: '100%',
    transition: 'all 1s ease',
    '&::-webkit-scrollbar': {
      width: ({ isMenuScrolling }) => (!isMenuScrolling ? 0 : '3px'),
    },
  },
  menucontent: {
    opecity: ({ isDrawerOpen }) => (isDrawerOpen ? 1 : 0),
    display: ({ isDrawerOpen }) => (isDrawerOpen ? '' : 'none'),
    color: '#000',
    fontSize: 11,
    margin: '0 1rem',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  childrenIcon: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: 20,
    opecity: ({ isDrawerOpen }) => (isDrawerOpen ? 1 : 0),
    display: ({ isDrawerOpen }) => (isDrawerOpen ? '' : 'none'),
  },

  accordionlistLiContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ isDrawerOpen }) =>
      isDrawerOpen ? 'space-between' : 'center',
    alignItems: 'center',
    margin: '.2rem 0',
    padding: ({ isDrawerOpen }) => (isDrawerOpen ? '0 15px' : '.8rem'),
    borderRadius: 8,
    lineHeight: 3.6,
    maxHeight: 40,
    '&:hover': {
      backgroundColor: grey[400],
    },
    '&.active': {
      backgroundColor: grey[400],
    },
    cursor: 'pointer',
    transition: 'all 0.3s linear !important',
  },
  modulelistLiContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ isDrawerOpen }) => (isDrawerOpen ? 'flex-start' : 'center'),
    alignItems: 'center',
    margin: '.2rem 0',
    padding: ({ isDrawerOpen }) => (isDrawerOpen ? '0 15px' : '.8rem'),
    maxHeight: 40,
    borderRadius: 8,
    lineHeight: 3.6,
    '&:hover': {
      backgroundColor: grey[400],
    },
    '&.active': {
      backgroundColor: grey[400],
    },
    cursor: 'pointer',
    transition: 'all 0.3s linear !important',
  },

  menulistLiContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: ({ isDrawerOpen }) =>
      isDrawerOpen ? 'space-between' : 'center',
    alignItems: 'center',
    margin: '.2rem 0',
    padding: ({ isDrawerOpen }) => (isDrawerOpen ? '0 15px' : '.8rem'),
    maxHeight: 40,
    borderRadius: 8,
    lineHeight: 3.6,
    '&:hover': {
      backgroundColor: grey[400],
      '& $starIcon': {
        display: 'block',
        opacity: 1,
      },
    },
    '&.active': {
      backgroundColor: grey[400],
    },
    cursor: 'pointer',
    transition: 'all 0.3s linear !important',
  },

  menuIcon: {
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 19,
    },
  },

  linkTitle: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0)',
    textDecoration: 'none',
    paddingRight: '0.2em',
    flexGrow: 1,
    fontSize: 11,
  },

  subMenu: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: grey[50],
    position: 'absolute',
    top: 0,
    left: 44,
    right: 0,
    bottom: 0,
  },

  appVersion: {
    fontSize: 11,
    textAlign: 'center',
    padding: 10,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  menucart: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    transition: 'all 300ms ease-in-out',
  },

  appVersionWithChangeApi: {
    background: theme.palette.primary.borderSelectMarker,
    fontSize: 11,
    textAlign: 'center',
    padding: 10,
    color: theme.palette.primary.appPrimaryLightBackgroundColor,
  },

  layerInMenu: {
    padding: '0.5rem 3rem',
  },
}));
