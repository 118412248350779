import { FC, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Notification from 'react-web-notification';

import samianLogo from '../../../images/ic_launcher.png';
import { callQuickAccess, openNewTab } from '../../../helper/QuickAccessHelper';
import { isEmptyObject, isEmpty } from '../../../helper/data-helper';
import { showNotification } from '../../../helper/general-function-helper';
import {
  WebNotificationEventInterface,
  WebNotificationInterfaceProps,
} from './web-notification.type';

const WebNotification: FC<WebNotificationInterfaceProps> = props => {
  const { webNotificationItem } = props;

  const [ignore, setIgnore] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [options, setOptions] = useState<object>();

  /**
   * Handle notification permission granted.
   * @function handlePermissionGranted
   * @returns {void}
   */
  const handlePermissionGranted = useCallback((): void => {
    setIgnore(false);
  }, [ignore]);

  /**
   * Handle notification permission denied.
   * @function handlePermissionDenied
   * @returns {void}
   */
  const handlePermissionDenied = useCallback((): void => {
    console.log('**PERMISSION DENIED**');
    setIgnore(true);
  }, [ignore]);

  /**
   * Handle notification not supported.
   * @function handleNotSupported
   * @returns {void}
   */
  const handleNotSupported = useCallback((): void => {
    console.log('**WEB NOTIFICATION NOT SUPPORTED**');
    setIgnore(true);
  }, [ignore]);

  /**
   * Handle web notification `onClick`.
   * @function handleWebNotificationOnClick
   * @param {WebNotificationEventInterface} event
   * @returns {Promise<void>}
   */
  const handleWebNotificationOnClick = async (
    event: WebNotificationEventInterface,
  ): Promise<void> => {
    try {
      if (!isEmpty(event.target.data.link)) {
        const url: string = await callQuickAccess(event.target.data.link);
        openNewTab(url);
      }
    } catch (error) {
      showNotification(error, 'warning');
    }
  };

  useEffect(() => {
    if (ignore || !webNotificationItem || isEmptyObject(webNotificationItem)) {
      return;
    }

    const title = webNotificationItem.notifytext;
    const tag = Date.now();
    const icon = samianLogo;

    const options = {
      tag,
      data: webNotificationItem,
      icon,
      lang: 'en',
      dir: 'ltr',
    };

    setTitle(title);
    setOptions(options);
  }, [webNotificationItem]);

  return (
    <Notification
      ignore={ignore}
      notSupported={handleNotSupported}
      onPermissionGranted={handlePermissionGranted}
      onPermissionDenied={handlePermissionDenied}
      onClick={handleWebNotificationOnClick}
      timeout={0} //ignore timeout
      title={title}
      options={options}
    />
  );
};

export default WebNotification;
