import { useTranslate, useLocale } from 'react-admin';
import {
  Box,
  IconButton,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import TuneIcon from '@material-ui/icons/Tune';
import PeopleIcon from '@material-ui/icons/People';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import { useStyles } from './chat-info-dialog.style';
import { ChatAvatarInput } from '../../chat-avatar-input';
import { ChatUserList } from '../../chat-user-list';
import {
  EDIT_MODE,
  VIEW_MODE,
} from '../add-members-dialog/add-members-dialog.helper';

import type { ReactElement } from 'react';
import type { ChatInfoDialogViewPropsInterface } from './chat-info-dialog.type';
import { isAdminLoggedUser } from '../../chat-section.helper';

const ChatInfoDialogView = (
  props: ChatInfoDialogViewPropsInterface,
): ReactElement => {
  const {
    openManageGroupDialog,
    openAddMembersDialog,
    closeDialogHandler,
    successFileCallback,
    showUserInfo,
    handleClose,
    openMenu,
    selectedUser,
    groupUsers,
    anchorEl,
    open,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const locale = useLocale();

  return (
    <Box className={classes.mainContainer}>
      <DialogTitle>
        <Box className={classes.dialogTitleContainer}>
          <Box>{selectedUser.groupuid ? translate('chat.groupInfo') : ''}</Box>
          {/*className style does not work here => */}
          <Box style={{ marginRight: '5rem' }}>
            <IconButton className={classes.icon} onClick={openMenu}>
              <MoreVertIcon />
            </IconButton>
            <IconButton className={classes.icon} onClick={closeDialogHandler}>
              <CloseIcon />
            </IconButton>
            <Menu
              className={classes.menu}
              elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: locale === 'en' ? 'left' : 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: locale === 'en' ? 'left' : 'right',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {!!isAdminLoggedUser(groupUsers) && (
                <MenuItem onClick={openManageGroupDialog} disableRipple>
                  <TuneIcon className={classes.menuIcon} />
                  {translate('chat.manageGroup')}
                </MenuItem>
              )}
              <MenuItem onClick={openAddMembersDialog(EDIT_MODE)} disableRipple>
                <GroupAddIcon className={classes.menuIcon} />
                {translate('chat.addMembers')}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box className={classes.contentHeaderContainer}>
            <Box className={classes.groupImageContainer}>
              <ChatAvatarInput
                successFileCallback={successFileCallback}
                personImage={selectedUser.personimage}
              />
            </Box>
            <Box className={classes.groupNameContainer}>
              {selectedUser.personname}
              <Typography variant="caption">
                {`${groupUsers.length} ${translate('chat.member')}`}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box className={classes.membersListItem}>
              <Box
                onClick={openAddMembersDialog(VIEW_MODE)}
                className={classes.membersTextContainer}
              >
                <PeopleIcon />
                <Typography variant="overline" className={classes.membersText}>
                  {`${groupUsers.length} ${translate('chat.member')}`}
                </Typography>
              </Box>
              <Box onClick={openAddMembersDialog(EDIT_MODE)}>
                <GroupAddIcon />
              </Box>
            </Box>
            <ChatUserList handleClick={showUserInfo} users={groupUsers} />
          </Box>
        </Box>
      </DialogContent>
    </Box>
  );
};

export default ChatInfoDialogView;
