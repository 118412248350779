import { useState, useEffect, type FC } from 'react';
import withWidth from '@material-ui/core/withWidth';

import ShowImageDialogView from './show-image-dialog.view';
import { downloadBase64File, getBase64File } from '../../helper/FileHelper';
import { showNotification } from '../../helper/general-function-helper';
import { isEmpty } from '../../helper/data-helper';
import {
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../type/actor-setup';

import type { ShowImageDialogControllerInterface } from './show-image-dialog.type';

const initialState: ShowImageDialogControllerInterface = {
  url: null,
  fullUrl: null,
  title: null,
  info: null,
  isGetFileMode: false,
  fileName: null,
};

const ShowImageDialogController: FC = () => {
  const [imageDialogInActor, setImageDialogInActor] =
    useState<ShowImageDialogControllerInterface>(initialState);

  const { isOpenImageDialog, title, url, info, fullUrl, fileName } =
    imageDialogInActor;

  const [finalImageUrl, setFinalImageUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const id = actorOnDispatch('imageDialog', setImageDialogInActor);

    return () => {
      actorRemoveAction({
        actionName: 'imageDialog',
        listenerId: id,
      });
    };
  }, []);

  const closeImageDialog = () => {
    actorDispatch('imageDialog', initialState, { replaceAll: true });
  };

  /**
   * Get File from API and set data to specified variables
   * @function getFile
   * @return {Promise<void>}
   */
  const getFileFromServer = async (): Promise<void> => {
    try {
      const { src, isImage } = await getBase64File({ link: url });
      if (isImage) {
        setFinalImageUrl(src);
      } else {
        downloadBase64File(src, fileName ?? '');
        closeImageDialog();
      }
      setIsLoading(false); //wait for image to be loaded
    } catch (error: any) {
      closeImageDialog();
      const errorMessage = error?.errorMessage ?? 'imageDialog.dataNotFound';
      showNotification(errorMessage, 'error');
    }
  };

  useEffect(() => {
    if (!isOpenImageDialog || (!url && !fullUrl)) {
      return;
    }

    if (!isEmpty(fullUrl)) {
      setFinalImageUrl(fullUrl);
      setIsLoading(false);
    } else {
      getFileFromServer();
    }

    return () => {
      setFinalImageUrl('');
      setIsLoading(true);
    };
  }, [fullUrl, url, isOpenImageDialog]);

  return (
    <ShowImageDialogView
      isLoading={isLoading}
      isOpenImageDialog={isOpenImageDialog}
      imageUrl={finalImageUrl}
      imageTitle={title ?? ''}
      imageInfo={info ?? ''}
      handleCloseDialog={closeImageDialog}
    />
  );
};

export default withWidth()(ShowImageDialogController);
