import { ReactElement, useState, useRef, useEffect } from 'react';
import { useTranslate } from 'react-admin';

import { showNotificationForUnknownError } from '../../../../helper/general-function-helper';
import { isCtrlEnterPressed } from '../../../../helper/FormHelper';
import { actorDispatch } from '../../../../type/actor-setup';
import { resourceForNote } from '../../../relation-panel/note-relation/create-edit-note/constants';
import AddNoteView from './add-note.view';

import type { ChangeEvent } from 'react';
import type { NoteAddControllerProps } from './add-todo-note.type';

const AddNoteController = (props: NoteAddControllerProps): ReactElement => {
  const { getNote, todoSelectedTaskInfo } = props;

  const [noteText, setNoteText] = useState<string>('');

  const invalidNoteRef = useRef<boolean>(false);
  const translate = useTranslate();

  useEffect(() => {
    setNoteText('');
  }, [todoSelectedTaskInfo?.uniqueid]);

  /** result after send data
   * @function onCreateEditSuccess
   * @returns { void }
   */
  const onCreateEditSuccess = (): void => {
    getNote();
    setNoteText('');
  };

  /** send data
   * @function onSaveNewNote
   * @returns { void }
   */
  const onSaveNewNote = (): void => {
    const data = {
      NoteText: JSON.stringify({
        NoteText: noteText,
      }),
      tableid: 310,
      KeyID: todoSelectedTaskInfo?.tasksdetails_id,
    };

    actorDispatch('crudAction', {
      type: 'RUN_SERVICE',
      data: {
        params: data,
      },
      actionUniqueId: resourceForNote,

      onSuccess: onCreateEditSuccess,
      onFailure: (error: unknown) => {
        showNotificationForUnknownError(error, translate);
      },
    });
  };

  /** check valid input and get value in input
   * @function onNewNoteChange
   * @params {ChangeEvent<HTMLInputElement>} event
   * @returns { void } void
   */
  const onNewNoteChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const text = event.target.value;
    if (!text.includes(' ') && text.length >= 50) {
      invalidNoteRef.current = true;
    } else {
      invalidNoteRef.current = false;
    }
    setNoteText(text);
  };

  /**
   * for handle ctrl + enter
   * @function onNewNoteKeyPress
   * @param {KeyboardEvent<HTMLInputElement>} event
   * @returns {void} void
   */
  const onNewNoteKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (noteText && isCtrlEnterPressed(event)) {
      onSaveNewNote();
      event.preventDefault();
    }
  };

  /**
   * for add note
   * @function onAddWithIcon
   * @returns {void} void
   */
  const handleClickAddIcon = (): void => {
    if (noteText) {
      onSaveNewNote();
    }
  };
  return (
    <AddNoteView
      onNewNoteKeyPress={onNewNoteKeyPress}
      onNewNoteChange={onNewNoteChange}
      invalidNote={invalidNoteRef.current}
      noteText={noteText}
      handleClickAddIcon={handleClickAddIcon}
    />
  );
};
export default AddNoteController;
