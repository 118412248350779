import { memo, type FC } from 'react';
import { useTranslate } from 'react-admin';
import { Checkbox, Typography } from '@material-ui/core';

import type { TableHeaderProps } from './permission-assigned.type';

const TableHeader: FC<TableHeaderProps> = props => {
  const { classes, handleSelectAllClick, selectAllChecked, selectAllDisabled } =
    props;

  const translate = useTranslate();

  return (
    <>
      <div className={classes.tableRow}>
        <Checkbox
          onClick={handleSelectAllClick}
          checked={selectAllChecked}
          disabled={selectAllDisabled}
        />
        <Typography variant="button" className={classes.typography}>
          {translate('permissions.id')}
        </Typography>
        <Typography variant="button" className={classes.typography}>
          {translate('permissions.title')}
        </Typography>
      </div>

      <div className={classes.divider} />
    </>
  );
};

export default memo(TableHeader, (prevProps, nextProps) => {
  return prevProps.selectAllChecked === nextProps.selectAllChecked;
});
