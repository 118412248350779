import {
  Avatar,
  ClickAwayListener,
  Grow,
  Link,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons//DateRange';
import lodashGet from 'lodash/get';
import moment from 'moment-jalaali';
import React, { Children, createRef, FC, MouseEventHandler, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import {
  CustomToolbarInterface,
  CustomTooltipInterface,
  EventWrapperInterface,
} from '.';
import { API_VERSION, getValue } from '../../core/configProvider';
import { apiUrl } from '../../core/data-Provider.helper';
import { showNotification } from '../../helper/general-function-helper';
import { callQuickAccess, openNewTab } from '../../helper/QuickAccessHelper';
import { useOutsideAlerter } from '../../hooks/useAutocompeletHandler';
import { useStyles } from './big-calendar.style';
import { actorGetActionValue } from '../../type/actor-setup';
import { findDOMNode } from 'react-dom';

export const BigCalendarToolbarView: FC<CustomToolbarInterface> = props => {
  const translate = useTranslate();
  const { onNavigate, onView } = props;
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onNavigate('TODAY')}>
          {translate('calendar.today')}
        </button>
        <button type="button" onClick={() => onNavigate('PREV')}>
          {translate('calendar.back')}
        </button>
        <button type="button" onClick={() => onNavigate('NEXT')}>
          {translate('calendar.next')}
        </button>
      </span>
      <span className="rbc-toolbar-label">{props.label}</span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => onView('month')}>
          {translate('calendar.month')}
        </button>
        <button type="button" onClick={() => onView('week')}>
          {translate('calendar.week')}
        </button>
        <button type="button" onClick={() => onView('day')}>
          {translate('calendar.day')}
        </button>
      </span>
    </div>
  );
};

export const EventWrapper: FC<EventWrapperInterface> = props => {
  const { eventCustomProps = {}, title, event } = props;
  const { summaryCalendarEvent, onContextMenuEvent } = eventCustomProps;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const locale = useLocale();
  const ref = createRef<HTMLElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  useOutsideAlerter(ref, () => setOpen(false));

  const summaryList = summaryCalendarEvent?.map(summaryEvent => [
    lodashGet(summaryEvent, ['translatedCaption', locale]) as string,
    event[lodashGet(summaryEvent, 'name')] as string,
  ]);
  const apiVersion = getValue(API_VERSION);
  const srcImage = `${apiUrl}/${apiVersion}${event?.image}`;

  /**
   * this function for open popup events calender
   * @function onClick
   * @param  _event
   * @returns {void}
   */
  const onClick: MouseEventHandler<HTMLDivElement> = _event => {
    setAnchorEl(_event.currentTarget);
    setOpen(!open);
  };

  return summaryCalendarEvent?.length ? (
    <div
      className="rbc-day-bg"
      onClick={onClick}
      onContextMenu={_event => onContextMenuEvent?.(_event, event)}
      data-style-day-calender="dayCalender"
    >
      <div className={classes.rootToolbar}>
        <Avatar
          className={classes.picAvatar}
          alt="event-calendar"
          src={srcImage}
        ></Avatar>
        &nbsp;
        <div className="rbc-day-bg">{props.title}</div>
      </div>
      <Popper
        open={open}
        placement="right"
        anchorEl={anchorEl}
        transition
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Paper>
                <CustomTooltip
                  label={title}
                  eventDate={event.start}
                  summaryList={summaryList}
                  srcImage={srcImage}
                  link={event?.link}
                />
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  ) : (
    <div className="rbc-day-bg" onContextMenu={e => onContextMenuEvent?.(e, event)}>
      {props.title}
    </div>
  );
};

export const CustomTooltip: FC<CustomTooltipInterface> = props => {
  const { label, eventDate, summaryList, srcImage, link } = props;
  const classes = useStyles();
  const translate = useTranslate();
  /**
   * Handle `onClick`.
   * @function onClickLink
   * @returns {Promise<void>}
   */
  const onClickLink = async (): Promise<void> => {
    try {
      if (link) {
        const url: string = await callQuickAccess(link);
        openNewTab(url, '_new');
      }
    } catch (error) {
      showNotification(error, 'warning');
    }
  };

  return (
    <div className={classes.rootCustomToolbar}>
      <div className={classes.header}>
        <Avatar src={srcImage} className={classes.picAvatar}></Avatar>
        <Typography className={classes.headerToolbar} variant="subtitle1">
          {label}
        </Typography>
      </div>
      <div className={classes.bodyToolbar}>
        <Typography variant="caption" className={classes.dateToolbar}>
          <DateRangeIcon fontSize="small" />
          &nbsp;
          {moment(eventDate).format('ddd، jDD jMMMM، jYYYY')}
        </Typography>
        {summaryList?.map(item => {
          return (
            <div>
              <Typography>{`${item[0]}: `}</Typography>
              <div dangerouslySetInnerHTML={{ __html: item[1] }}></div>
            </div>
          );
        })}
      </div>
      <Link className={classes.fieldLink} onClick={onClickLink}>
        {translate('todo.link')}
      </Link>
    </div>
  );
};
