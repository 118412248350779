import type { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';

import type { CustomCssViewType } from './custom-css.type';
import { useStyles } from './style-custom-style';

const textDefault =
  'html {\n     font-size: 14px;\n}\n @media (min-width: 768px) {\n     html {\n         font-size: 16px;\n    }\n}\n';

const CustomCssContentView = (props: CustomCssViewType): ReactElement => {
  const { onChangeDataStyle, value, sendData } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <Button onClick={sendData} variant="contained" className={classes.btnCss}>
        {translate('css.save')}
      </Button>
      <textarea
        placeholder={textDefault}
        rows={42}
        className={classes.styleTextArea}
        onChange={onChangeDataStyle}
        value={value}
      />
    </div>
  );
};

export default CustomCssContentView;
