import { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { translate } from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  withStyles,
  Icon,
  DialogTitle,
} from '@material-ui/core';
import classNames from 'classnames';

const styles = theme => ({
  dialogActions: {
    margin: 0,
    padding: '24px 24px',
  },

  icon: {
    margin: '0 5px',
  },

  dialogTitle: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  cancelBtn: {
    margin: '0 5px',
  },
});

const getEmptyState = () => ({
  maxWidth: null,
  dialogOpen: false,
  content: '',
  icon: '',
  color: '',
  enableOnlyOkButton: false,
  onConfirm: () => {},
  onCancel: () => {},
});

class RawConfirmDialogHOC extends PureComponent {
  state = getEmptyState();

  openConfirmDialog = params => {
    const { onConfirm = () => {}, onCancel = () => {} } = params;

    this.setState({
      ...params,
      dialogOpen: true,
      onConfirm,
      onCancel,
    });
  };

  handleConfirm = () => {
    const { onConfirm } = this.state;
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
    this.setState({ dialogOpen: false });
  };

  handleCancel = () => {
    const { onCancel } = this.state;
    if (typeof onCancel === 'function') {
      onCancel();
    }
    this.setState({ dialogOpen: false });
  };

  render() {
    const {
      component: ChildComponent,
      classes,
      translate,
      theme,
      ...rest
    } = this.props;
    const { dialogOpen, content, maxWidth, icon, color, enableOnlyOkButton } =
      this.state;
    return (
      <Fragment>
        <ChildComponent {...rest} openConfirmDialog={this.openConfirmDialog} />
        <Dialog
          open={dialogOpen}
          maxWidth={maxWidth}
          onClose={this.handleCancel}
          aria-labelledby="show-image-dialog-title"
        >
          <DialogTitle className={classes.dialogTitle}>
            <Icon
              style={{ color: color || theme.palette.secondary.main }}
              className={classNames(classes.icon, icon || 'fa fa-question-circle')}
            />
            {content}
          </DialogTitle>
          <DialogActions className={classes.dialogActions}>
            <div className={classes.btnContainer}>
              {enableOnlyOkButton ? (
                <Button
                  onClick={this.handleConfirm}
                  style={{
                    backgroundColor: color || theme.palette.secondary.main,
                    color: '#ffffff',
                  }}
                  variant="contained"
                  id="dialogButtonConfirmOk"
                >
                  {translate('confirm.ok')}
                </Button>
              ) : (
                <Fragment>
                  <Button
                    className={classes.cancelBtn}
                    onClick={this.handleCancel}
                    variant="outlined"
                  >
                    {translate('confirm.no')}
                  </Button>
                  <Button
                    onClick={this.handleConfirm}
                    style={{
                      backgroundColor: color || theme.palette.secondary.main,
                      color: '#ffffff',
                    }}
                    variant="contained"
                    id="dialogButtonConfirmYes"
                  >
                    {translate('confirm.yes')}
                  </Button>
                </Fragment>
              )}
            </div>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const ConfirmDialogHOC = compose(
  translate,
  withStyles(styles, { withTheme: true }),
)(RawConfirmDialogHOC);

ConfirmDialogHOC.propTypes = {
  component: PropTypes.any.isRequired,
};

export default HocComponent => {
  return class extends PureComponent {
    render() {
      return <ConfirmDialogHOC {...this.props} component={HocComponent} />;
    }
  };
};
