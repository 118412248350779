import { type FC, type ReactElement, useState, memo, useEffect } from 'react';

import { AppContainerInterface } from './app-container.type';
import AppContainerView from './app-container.view';
import {
  actorDispatch,
  actorRemoveAction,
  actorOnDispatch,
} from '../../type/actor-setup';

const AppContainerController: FC<AppContainerInterface> = memo(props => {
  const {
    title = '',
    cardStyle,
    sidebarContent,
    fullView = false,
    children,
    ...rest
  } = props;

  const [isAppDrawerOpen, setIsAppDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    if ((children as ReactElement).props.id === 'chatContent') {
      actorDispatch('isChatDrawerOpen', isAppDrawerOpen);
    }
  }, [isAppDrawerOpen]);

  useEffect(() => {
    const onDispatchId = actorOnDispatch('signal', signal => {
      if (signal === 'closeAppSidebar') {
        setIsAppDrawerOpen(false);
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'formData',
        listenerId: onDispatchId,
      });
    };
  }, []);

  /**
   * @function onToggleDrawer
   * @returns { void }
   */
  const onToggleDrawer = (): void => {
    setIsAppDrawerOpen(!isAppDrawerOpen);
  };

  /**
   * @function onCloseDrawer
   * @returns { void }
   */
  const onCloseDrawer = (): void => {
    setIsAppDrawerOpen(false);
  };

  return (
    <AppContainerView
      {...rest}
      title={title}
      isAppDrawerOpen={isAppDrawerOpen}
      onToggleDrawer={onToggleDrawer}
      onCloseDrawer={onCloseDrawer}
      cardStyle={cardStyle}
      sidebarContent={sidebarContent}
      fullView={fullView}
      children={children}
    />
  );
});

export default AppContainerController;
