import { makeStyles } from '@material-ui/core';
import { CustomTheme } from './../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    overflow: 'hidden',
  },
  title: {
    marginTop: theme.spacing(2) + 'px !important',
    textAlign: 'center',
  },
  chart: {
    width: '50vh',
    '& text': {
      cursor: 'pointer',
    },
  },
}));
