import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme, { paddingTop: number }>(theme => ({
  container: {
    display: 'flex',
    backgroundImage: ` url("/assets/image/telegram-bg.jpg")`,
    minHeight: '80%',
    height: '-webkit-fill-available',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },

  scrollDiv: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    '&::-webkit-scrollbar': {
      width: 3,
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-track-piece': {
        backgroundColor: 'initial',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0, .3)',
      },
    },
    height: 740,
    [theme.breakpoints.up('xl')]: {
      height: '98%',
    },
    [theme.breakpoints.down('lg')]: {
      height: '98%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  infiniteScroll: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  scrollDownBtn: {
    position: 'absolute',
    bottom: 24,
    right: 16,
    background: theme.palette.grey[400],
    color: theme.palette.primary.appLightBackgroundColor,

    '&:hover': {
      background: theme.palette.grey[600],
    },
  },
  customBadge: {
    top: '-20px',
    left: '-10px',
    color: theme.palette.primary.appLightBackgroundColor,
  },

  loadingContainer: {
    backgroundImage: ` url("/assets/image/telegram-bg.jpg")`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  noMessage: {
    fontSize: 18,
    color: theme.palette.grey[500],
  },

  scrollChatNoUser: {
    width: '100%',
    backgroundImage: ` url("/assets/image/telegram-bg.jpg")`,
    fontSize: 18,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    height: 'calc(100vh - 169px) !important',
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '3rem',
      color: '#BDBDBD',
      [theme.breakpoints.up('lg')]: {
        fontSize: '5rem',
      },
    },
  },

  messagesListFooter: {
    background: '#fff !important',
    zIndex: 2,
    paddingTop: ({ paddingTop }) => `${paddingTop}px !important`,
  },

  mentionIcon: {
    position: 'absolute',
    bottom: 140,
    fontSize: 15,
    color: theme.palette.primary.blueLink,
    right: '8%',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
