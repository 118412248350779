import { FC } from 'react';

import { DevExpressGrid } from '../../grid';

import type { TableRelationViewInterface } from './table-relation.type';

const TableRelationView: FC<TableRelationViewInterface> = props => {
  const {
    addToFilterRequestList,
    quickEditRowCallback,
    onSelectCheckbox,
    quickEditButton,
    parentRecord,
    currentUrl,
    parentInfo,
    onRowClick,
    hasCreate,
    resource,
    metaData,
    relation,
    setSort,
    hasEdit,
    fields,
    data,
    isReport,
    sort,
    summaryData,
    setFilters,
  } = props;

  return (
    <DevExpressGrid
      addToFilterRequestList={addToFilterRequestList}
      quickEditRowCallback={quickEditRowCallback}
      onSelectCheckbox={onSelectCheckbox}
      quickEditButton={quickEditButton}
      parentRecord={parentRecord}
      onRowClick={onRowClick}
      parentInfo={parentInfo}
      hasCreate={hasCreate}
      redirect={currentUrl}
      metaData={metaData}
      hasEdit={hasEdit}
      setSort={setSort}
      isReport={isReport}
      fields={fields}
      hasShow={!isReport}
      data={data}
      setFilters={setFilters}
      sort={sort}
      // TODO: check here
      relationMode={true}
      relation={relation} // why? 😑
      // TODO: check here
      relationResource={resource} // why? 😑
      basePath={`/${resource}`} // why? 😑
      resource={resource}
      summaryData={summaryData}
    />
  );
};

export default TableRelationView;
