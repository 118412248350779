import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import StartIcon from '@material-ui/icons/PlayArrow';
import ResetIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/Pause';

import { TimerInputViewInterface } from './timer-input.type';
import { useStyles } from './timer-input.style';
import InputBase from '../../input-base';

const TimerInputView = (props: TimerInputViewInterface): ReactElement => {
  const {
    getRef,
    field,
    value,
    disabled,
    inputMessage,
    label,
    resource,
    visibleClass,
    inputContainerClass,
    customTestAttribute,
    handleFocus,
    isPaused,
    onStart,
    onPause,
    onReset,
  } = props;

  const classes = useStyles({ messageType: inputMessage?.messageType });
  const translate = useTranslate();
  const theme = useTheme();

  const { name, required, maxLength, priority, customOption } = field;

  return (
    <InputBase
      label={label}
      inputMessage={inputMessage}
      required={required}
      field={field}
      className={`${visibleClass} ${inputContainerClass}`}
    >
      <Tooltip
        title={customOption.additionalDataFieldAlt}
        placement={theme.direction === 'rtl' ? 'left' : 'right'}
      >
        <TextField
          {...customTestAttribute}
          ref={getRef}
          value={value ?? ''}
          name={name}
          required={required}
          classes={{
            root: classes.root,
          }}
          variant="outlined"
          InputProps={{
            disabled,
            readOnly: true,
            classes: {
              root: classes.outlineRoot,
              notchedOutline: classes.notchedOutline,
              disabled: classes.disabled,
            },

            startAdornment: (
              <InputAdornment position="start" className={classes.InputAdornment}>
                <Tooltip title={translate('general.reset')}>
                  <IconButton onClick={onReset}>
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
                {!isPaused ? (
                  <Tooltip title={translate('general.pause')}>
                    <IconButton onClick={onPause}>
                      <PauseIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={translate('general.start')}>
                    <IconButton onClick={onStart}>
                      <StartIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: customOption.customStyleAdditionalDataField,
            'data-test-input-name': `${resource}/${name}`,
            'data-test-input-field-priority': priority,
            maxLength: maxLength,
          }}
          disabled={disabled}
          data-test="inputContainerTextField"
          data-test-has-error={inputMessage?.message}
          size="small"
          onFocus={handleFocus}
        />
      </Tooltip>
    </InputBase>
  );
};

export default TimerInputView;
