import { FC, memo } from 'react';
import { Divider } from 'semantic-ui-react';

import { useStyles } from './todo-date-options.style';
import { TodoDateChooser } from './todo-date-chooser';
import { useTranslate } from 'react-admin';
import { TodoRepeatTask } from './todo-repeat-task';
import type { TodoDateOptionsPropsInterface } from './todo-date-options.type';

const TodoDateOptionsView: FC<TodoDateOptionsPropsInterface> = memo(props => {
  const { isMinimized } = props;

  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div
      className={
        isMinimized ? classes.minimizedMainContainer : classes.mainContainer
      }
    >
      <TodoDateChooser
        title={translate('todo.remindMe')}
        iconName={'add_alert'}
        taskFiledName={'remindmedate'}
        isMinimized={isMinimized}
        withTime
      />
      {!isMinimized && <Divider />}
      <TodoDateChooser
        title={translate('todo.dueDate')}
        iconName={'event_available'}
        taskFiledName={'enddate'}
        isMinimized={isMinimized}
        withTime
      />
      {!isMinimized && <Divider />}
      <TodoRepeatTask isMinimized={isMinimized} />
    </div>
  );
});
export default TodoDateOptionsView;
