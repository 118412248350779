import { GET_FILE } from '../core/data-Provider.helper';
import dataProvider from '../core/dataProvider';
import { showNotification } from './general-function-helper';
import { EncodedFileType } from './Types';

/**
 * check download file response
 * @param response
 * @returns boolean
 */
const isDownloadFileHasError = (response: Record<string, any>): boolean => {
  if (/json$/gi.test(response.headers['content-type'])) {
    if (
      response.request.responseType === 'arraybuffer' &&
      response.data.toString() === '[object ArrayBuffer]'
    ) {
      return true;
    }
  }
  return false;
};

/**
 * Send a request to API and return a Blob
 * @function getBlobFile
 * @param {object} param file url
 * @returns {Promise<void | Blob>}
 */
export const getBlobFile = async (param: object): Promise<void | Blob> => {
  try {
    const response = await dataProvider(GET_FILE, null, param);

    if (!response.data) {
      return;
    }

    //if request has error
    if (isDownloadFileHasError(response)) {
      const res = JSON.parse(Buffer.from(response.data).toString('utf8'));
      showNotification(res?.errorMessage ?? 'imageDialog.dataNotFound', 'error');
      return;
    }

    return new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  } catch (error) {
    console.log('Cannot get blob file: ', error);
  }
};

/**
 * Send a request to API and return a `base64` file with its type as an object
 * @function getBase64File
 * @param {object} param an object with file URL
 * @returns {Promise<EncodedFileType>} an object with a base64 file with its type as an object
 */
export const getBase64File = (param: object): Promise<EncodedFileType> => {
  return new Promise((resolve, reject) => {
    dataProvider(GET_FILE, null, param)
      .then(response => {
        if (!response.data) {
          reject({
            src: '',
            isImage: false,
          });
        }

        //if request has error
        if (isDownloadFileHasError(response)) {
          const res = JSON.parse(Buffer.from(response.data).toString('utf8'));
          reject({
            src: '',
            isImage: false,
            errorMessage: res?.userMessage ?? '',
          });
        }

        const image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        const src = `data:${response.headers[
          'content-type'
        ].toLowerCase()};base64,${image}`;

        resolve({
          src,
          isImage: response.headers['content-type'].indexOf('image') !== -1,
        });
      })
      .catch(error => {
        console.log('Cannot get base64 file: ', error);
        reject({
          src: '',
          isImage: false,
          errorMessage: error?.userMessage ?? '',
        });
      });
  });
};

/**
 * check file is image or not
 * @function isFileTypeImage
 * @param {string} fileName
 * @returns {boolean} void
 */
export const isFileTypeImage = (fileName: string): boolean => {
  if (!fileName) {
    return false;
  }
  return fileName?.match(/\.(jpeg|jpg|gif|png|svg|JPG|PNG|JPEG|GIF|SVG)$/) != null;
};

/**
 * Get a `Blob` and a file `name`, create a HTML `<a>` tag and fire `click` event to download the file.
 * @function downloadFile
 * @param {Blob} file The Blob
 * @param {string} name The download file name
 * @param {string} customFormat The download file format
 * @returns {void}
 */
export const downloadFile = (
  file: Blob | void,
  name: string,
  customFormat = 'xlsx',
): void => {
  if (!file) {
    return;
  }
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}.${customFormat}`;
  a.click();
};

/**
 * Convert Input file to base 64
 * @function toBase64
 * @param file Input file
 * @returns {Promise<string | ArrayBuffer | null>} a Promise of `string | ArrayBuffer | null`
 */
export const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

/**
 * save base64 file content
 * @function downloadBase64File
 * @param {string} contentBase64
 * @param {string} fileName
 * @returns {void} void
 */
export const downloadBase64File = (
  contentBase64: string,
  fileName: string,
): void => {
  const linkSource = contentBase64;
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click();

  downloadLink.remove(); // to remove an extra link element
};

/**
 * convert base64 file to blob
 * @function base64ToBlob
 * @param {string} base64File
 * @returns {Blob} file in blob
 */
export const base64ToBlob = (base64File: string): Blob | null => {
  const fileData = base64File.split(',')[1];
  if (!fileData) {
    return null;
  }

  const byteCharacters = atob(fileData);
  const byteArrays: number[] = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  return new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });
};
