import { FC, memo } from 'react';
import TodoDateOptionsView from './todo-date-options.view';

import type { TodoDateOptionsPropsInterface } from './todo-date-options.type';

const TodoDateOptionsController: FC<TodoDateOptionsPropsInterface> = memo(props => {
  const { isMinimized } = props;
  return <TodoDateOptionsView isMinimized={isMinimized} />;
});
export default TodoDateOptionsController;
