import { useRef } from 'react';
import { useTranslate } from 'react-admin';
// https://github.com/clauderic/react-sortable-hoc/blob/master/examples/drag-handle.js
import classNames from 'classnames';
import { Icon, IconButton, makeStyles, Tooltip } from '@material-ui/core';

import { getGridColumns, getFieldsById } from '../helper/MetaHelper';
import { clone, isEmptyObject } from '../helper/data-helper';
import {
  DEFAULT,
  CONFIG_LIST_COLUMN_CHOICE,
  CONFIG_LIST_COLUMN_WIDTH,
} from '../core/configProvider';
import { getAppSettings } from '../helper/settings-helper';
import { actorDispatch, actorGetActionValue } from '../type/actor-setup';
import { FieldType } from '../helper/Types';
import { checkGridColumnHasDynamic } from '../component/grid/grid.helper';

const useStyles = makeStyles(theme => ({
  sortableItem: {
    margin: 0,
    padding: 0,
  },

  searchColumn: {
    display: 'flex',
    margin: '30px 0',
    '&:focus': {
      outline: 'none',
    },
  },

  icon: {
    fontSize: 20,
  },

  IconButton: {
    padding: 7,
    margin: '2px 5px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
      margin: 0,
    },
  },

  information: {
    display: 'flex',
    padding: '15px 0 0',
    alignItems: 'center',
    '& span': {
      color: theme.palette.text.hint,
    },
  },
}));

const ListColumnSelectDialogButton = props => {
  const { metaData, resource, locale, relationMode, fields } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const fieldsWidth = useRef<Record<string, unknown>>({});

  const prepareStateAndOpenDialog = () => {
    const defaultSelected =
      getAppSettings<Array<string | number>>(
        DEFAULT + '_' + CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
      ).value ?? [];
    const userSelected =
      getAppSettings<Array<string | number>>(
        CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
        true,
      ).value ?? [];

    const defaultColumnWidth = getAppSettings<number>(
      DEFAULT + '_' + CONFIG_LIST_COLUMN_WIDTH + '_' + resource,
    ).value;
    const userColumnWidth = getAppSettings<number>(
      CONFIG_LIST_COLUMN_WIDTH + '_' + resource,
      true,
    ).value;

    let tempFieldList: FieldType[] =
      getGridColumns({
        metaData,
        resource,
        filterFirstFive: false,
        isRelation: relationMode,
        defaultSelected,
        userSelected,
      }) ?? [];

    // if user has selected, or something default is set calculate fieldList again
    if (
      (userSelected && userSelected.length) ||
      (defaultSelected && defaultSelected.length)
    ) {
      const selectedIds =
        userSelected && userSelected.length ? userSelected : defaultSelected;
      const tempSelectedFields = clone(getFieldsById(metaData, selectedIds));
      const selectedFields: FieldType[] = [];

      if (tempSelectedFields && tempSelectedFields.length) {
        const tempSelectedFieldsLength = tempSelectedFields.length;
        for (let index = 0; index < tempSelectedFieldsLength; index++) {
          const _field = tempSelectedFields[index];
          if (isEmptyObject(_field)) continue;

          _field.isChecked = true;
          selectedFields.push(_field);
        }
      }

      if (!isEmptyObject(userColumnWidth) || !isEmptyObject(defaultColumnWidth)) {
        tempSelectedFields?.forEach(field => {
          field.width =
            userColumnWidth?.[field.id] ??
            defaultColumnWidth?.[field.id] ??
            field.width;

          fieldsWidth.current = {
            ...fieldsWidth.current,
            [field.id]: field.width,
          };
        });
      }

      const restOfFields = tempFieldList.filter(
        field => field && selectedIds.indexOf(field.id ?? field.name) === -1,
      );

      tempFieldList = [...selectedFields, ...restOfFields];
    }

    const gridDataInActor = actorGetActionValue('gridData');
    if (
      checkGridColumnHasDynamic(gridDataInActor?.[resource]?.data ?? [], metaData)
    ) {
      actorDispatch('showColumnChooser', { [resource]: true });
    } else {
      actorDispatch('quickDialog', {
        gridColumnsSelectionDialogIsOpen: true,
        data: {
          data: {
            fieldList: clone(tempFieldList),
            resource,
            locale,
            settings: {
              defaultSelected,
              userSelected,
              defaultColumnWidth,
              userColumnWidth,
            },
          },
        },
      });
    }
  };

  return (
    <div>
      <Tooltip title={translate('listColumnChoice.selectColumns')}>
        <IconButton
          className={classes.IconButton}
          onClick={prepareStateAndOpenDialog}
          color="primary"
          id="listColumnSelectDialogButton"
        >
          <Icon className={classNames(classes.icon, 'fa fa-columns')} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ListColumnSelectDialogButton;
