import { actorDispatch, actorOnDispatch } from '../../../type/actor-setup';
import MessagesHeaderView from './messages-header.view';

import { FC, memo, useCallback, useEffect, useState } from 'react';
import { SelectedChatType } from '../chat-section.type';

const MessagesHeaderController: FC = memo(() => {
  const [selectedUser, setSelectedUser] = useState<SelectedChatType | null>(null);

  useEffect(() => {
    actorOnDispatch('selectedChat', selectedChat => {
      if (selectedChat == null) {
        setSelectedUser(null);
        return;
      }

      // else
      setSelectedUser({ ...selectedChat.info });
    });
  }, []);

  /**
   * @function openChatInfoDialog
   * @returns {void} void
   * @description open chat info dialog
   */
  const openChatInfoDialog = useCallback((): void => {
    const dispatchData = {
      addMembersDialogIsOpen: false,
      userInfoDialogIsOpen: false,
      chatInfoDialogIsOpen: false,
      data: { selectedUser },
    };

    if (selectedUser?.groupuid) {
      dispatchData.chatInfoDialogIsOpen = true;
    } else {
      dispatchData.userInfoDialogIsOpen = true;
    }

    actorDispatch('quickDialog', dispatchData);
  }, [selectedUser]);

  return (
    selectedUser && (
      <MessagesHeaderView
        openChatInfoDialog={openChatInfoDialog}
        selectedUser={selectedUser}
      />
    )
  );
});

export default MessagesHeaderController;
