import { Formio } from 'react-formio';
import { RUN_SERVICE } from '../../core/data-Provider.helper';

import {
  showNotification,
  showNotificationForUnknownError,
} from '../../helper/general-function-helper';
import { actorDispatch } from '../../type/actor-setup';

import type { createOrUpdateFormParamsInterface } from './form-builder.type';

const createFormUniqueId = '4ea1a05c-27c4-428f-9737-dffdfa4a9709';
export const getFormResourceId = 'report/c1fef349-9fe2-47b2-a064-802f6e85ad43';
export const VIEW = 'view';
export const EDIT = 'edit';
export const CREATE = 'create';

/**
 * @function showCustomForm
 * @param {Record<string, unknown>[]} components
 * @returns {void} void
 */
export const showCustomForm = (components: Record<string, unknown>[]): void => {
  Formio.createForm(document.getElementById('formio-result'), {
    components,
  }).then(form => {
    form.on('submit', data => console.log('submit', data));
  });
};

/**
 * @description this function is sending a request to api and is updating form components or creating a new form
 * @function createOrUpdateForm
 * @param {createOrUpdateFormParamsInterface} params
 * @returns {void} void
 */
export const createOrUpdateForm = (
  params: createOrUpdateFormParamsInterface,
): void => {
  actorDispatch('crudAction', {
    type: RUN_SERVICE,
    entity: 'formbuilder',
    data: {
      params,
    },
    actionUniqueId: createFormUniqueId,
    onSuccess: (response: {
      data: Record<string, unknown>[];
      userMessage: string;
    }): void => {
      showNotification(response.userMessage, 'success');
    },
    onFailure: (error: unknown): void => {
      showNotificationForUnknownError(error);
    },
  });
};

/**
 * @function getParamsFromURL
 * @param {string} searchUrl
 * @returns {Record<string, string>}  Record<string, string>
 */
export const getParamsFromURL = (searchUrl: string): Record<string, string> => {
  const params = new URLSearchParams(searchUrl);
  const paramsObj: Record<string, string> = {};
  params.forEach((value, key) => {
    paramsObj[key] = value;
  });
  return paramsObj;
};
