import { type FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';

const LoadingSkeleton: FC = () => {
  return (
    <div style={{ padding: 1 }}>
      <Grid container spacing={1} xs={12}>
        {[...Array(12)].map(() => {
          return (
            <Grid item xs={12} md={1}>
              <Skeleton variant="rect" height={40} animation="wave" />
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Skeleton variant="rect" height={'80vh'} animation="wave" />
        </Grid>
      </Grid>
    </div>
  );
};

export default LoadingSkeleton;
