import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import type { FC } from 'react';
import type { LineChartViewProps } from './line-chart.type';
import { addThousandSeparator } from '../dynamic-chart.helper';

const LineChartView: FC<LineChartViewProps> = props => {
  const {
    compatibleData,
    width,
    height,
    handleClickLineChartItem,
    bottomLegend = '',
    leftLegend = '',
  } = props;

  const highChartOptions = {
    chart: {
      type: 'line',
      zoomType: 'x',
      style: { width, height },
      width: width,
      height: height,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: compatibleData?.x,
      title: {
        text: bottomLegend,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        borderRadius: '25%',
      },
      series: {
        cursor: 'pointer',
        events: {
          click: handleClickLineChartItem,
        },
      },
    },
    series: compatibleData?.y,

    yAxis: {
      title: {
        text: leftLegend,
      },
      labels: {
        formatter: ({ value }) => addThousandSeparator(value),
      },
    },
  };

  Highcharts.setOptions({
    lang: {
      decimalPoint: '.',
      thousandsSep: ',',
    },
  });

  return (
    <div
      style={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height,
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={highChartOptions}
        width={width}
        height={height}
        key={Math.random()}
      />
    </div>
  );
};

export default LineChartView;
