import { ReactElement, useEffect, useRef, useState } from 'react';

import { Typography } from '@material-ui/core';
import {
  localStorageGetItem,
  USER_WAREHOUSE_TITLE,
} from '../../core/configProvider';
import LoadingBox from '../LoadingBox';
import { actorOnDispatch } from '../../type/actor-setup';

const ProfileSettingItemView = ({ className }): ReactElement => {
  const [profileTitle, setProfileTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const dropdownDataRef = useRef<Array<Record<string, unknown>> | undefined>([]);

  useEffect(() => {
    actorOnDispatch('dropDownData', dropDownData => {
      dropdownDataRef.current =
        dropDownData?.['46a93036-0de8-4f4e-bc71-b8b9bab0f0e9-0']?.DATA; // '0' is a field if and comes from profileSettingFields in HeaderProfileContainer.tsx

      setProfileTitle(
        localStorageGetItem<string | null>(USER_WAREHOUSE_TITLE) ?? '',
      );

      setIsLoading(false);
    });

    actorOnDispatch('profile', profileData => {
      setProfileTitle(
        localStorageGetItem<string | null>(USER_WAREHOUSE_TITLE) ??
          profileData?.warehouseTitle ??
          '',
      );
    });
  }, []);

  if (isLoading) {
    return <LoadingBox size={20} />;
  }

  return (
    <Typography
      className={className}
      noWrap
      variant="caption"
      data-test-setting-item-view
    >
      {profileTitle}
    </Typography>
  );
};

export default ProfileSettingItemView;
