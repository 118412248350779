import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { translate, setListSelectedIds } from 'react-admin';
import LoadingBox from './LoadingBox';
import { DevExpressGrid } from './grid';
import { Component } from 'react';
import { addToFilterRequestListAction } from '../redux/listFilter/action';

class DevExGrid extends Component {
  render() {
    const {
      basePath,
      relationMode,
      currentUrl,
      hasEdit,
      isLoading,
      hasShow,
      metaData,
      parentInfo,
      relationResource,
      resource,
      quickEditRowCallback,
      isReport,
      isMultiReport,
      onRowClick,
      hideLoading,
      relation,
      parentRecord,
      setSort,
      fields,
      addToFilterRequestList,
      hasCreate,
      ids,
      data,
      sort,
      quickEditButton,
      setFilters,
      isTopFilterOpen,
      idDropDown = null,
      enableSelection,
      filterValues,
      selectedIds,
      enableClientExportExcel = false,
      isGroupingOpen,
      hasColumnChooser,
      simpleGridData,
      isMap
    } = this.props;

    if (isReport || isMultiReport) {
      //convert column name from int to string in report grid.RCT-2625
      metaData?.columns?.forEach(column => {
        column.name = String(column.name);
        column.relatedName = String(column.relatedName);
      });
    }

    // NOTE RCT-644: because grid will not understand when resource is changed, will not use
    // the provided columns for so the workaround is to remove the grid from showing and put it again after loading
    if (!hideLoading && isLoading) {
      return <LoadingBox />;
    }

    return (
      <DevExpressGrid
        idDropDown={idDropDown}
        enableSelection={enableSelection}
        ids={ids}
        data={data}
        fields={fields}
        basePath={basePath}
        redirect={currentUrl}
        hasShow={hasShow}
        hasEdit={hasEdit}
        hasCreate={hasCreate}
        relationMode={relationMode}
        relationResource={relationResource}
        resource={resource}
        quickEditRowCallback={quickEditRowCallback}
        setListSelectedIds={this.props?.setListSelectedIds}
        onSelectCheckbox={this.props?.onSelect}
        onRowClick={onRowClick}
        parentInfo={parentInfo}
        isReport={isReport || isMultiReport}
        relation={relation}
        parentRecord={parentRecord}
        setSort={setSort}
        sort={sort}
        metaData={metaData}
        addToFilterRequestList={addToFilterRequestList}
        quickEditButton={quickEditButton}
        setFilters={setFilters}
        isTopFilterOpen={isTopFilterOpen}
        filterValues={filterValues}
        selectedRows={selectedIds}
        enableClientExportExcel={enableClientExportExcel}
        isGroupingOpen={isGroupingOpen}
        hasColumnChooser={hasColumnChooser}
        isMap={isMap}
        simpleGridData
      />
    );
  }
}

const mapDispatchTopProps = {
  addToFilterRequestList: addToFilterRequestListAction,
  setListSelectedIds,
};

export default compose(connect(null, mapDispatchTopProps), translate)(DevExGrid);
