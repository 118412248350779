import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  mainContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
  },

  tasksContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto !important',
  },

  addTaskInput: {
    borderBottom: `3px solid ${theme.palette.todoTaskBackground}`,
    display: 'flex',
  },

  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    marginBottom: 5,
  },

  headerIcon: {
    margin: '4px 4px',
    display: 'flex',
    rotate: '-180deg',
  },

  sortItemContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  sortContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },

  iconSort: {
    cursor: 'pointer',
    fontSize: 17,
    margin: '2px 2px',
    color: theme.palette.todoLightGray,
  },

  iconMenuSort: {
    cursor: 'pointer',
    margin: '2px 2px',
  },

  listSortName: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  iconSortList: {
    margin: '0px 5px',
    marginLeft: 20,
  },

  headerRightSideContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.3rem',
    marginTop: 5,
  },

  sortSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },

  shareSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
    marginLeft: '1rem',
    marginRight: '1rem',
  },

  shareIcon: {
    cursor: 'pointer',
    margin: '0px 5px',
  },

  iconShare: {
    marginLeft: 5,
    marginRight: 5,
    cursor: 'pointer',
  },
}));
