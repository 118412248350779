import RemindTomorrowIcon from '../../../icon/RemindTomorrowIcon';
import RemindNextWeekIcon from '../../../icon/RemindNextWeekIcon';
import LaterTodayIcon from '../../../icon/LaterTodayIcon';
import WeekDaysIcon from '../../../icon/WeekDaysIcon';
import { contextMenuOptionInterface } from './todo-date-options-menu';
import { WeekdaysListInterface } from './todo-repeat-task/todo-repeat-task.type';

export const todoDateChooserContextMenuOption = (
  translate: (arg: string) => string,
): contextMenuOptionInterface[] => {
  return [
    {
      title: translate('todo.today'),
      icon: <LaterTodayIcon />,
      value: 'today',
    },
    {
      title: translate('todo.tomorrow'),
      icon: <RemindTomorrowIcon />,
      value: 'tomorrow',
    },
    {
      title: translate('todo.nextWeek'),
      icon: <RemindNextWeekIcon />,
      value: 'nextWeek',
    },
    {
      title: translate('todo.customDateTime'),
      icon: <WeekDaysIcon />,
      value: 'customDateTime',
    },
  ];
};

export const todoDateRepeatContextMenuOption = (
  translate: (arg: string) => string,
): contextMenuOptionInterface[] => {
  return [
    {
      title: translate('todo.daily'),
      icon: <LaterTodayIcon />,
      value: repeatTypeDropDown.daily,
    },
    {
      title: translate('todo.weekly'),
      icon: <RemindTomorrowIcon />,
      value: repeatTypeDropDown.weekly,
    },
    {
      title: translate('todo.monthly'),
      icon: <RemindNextWeekIcon />,
      value: repeatTypeDropDown.monthly,
    },
    {
      title: translate('todo.yearly'),
      icon: <WeekDaysIcon />,
      value: repeatTypeDropDown.yearly,
    },
    {
      title: translate('todo.options'),
      icon: <WeekDaysIcon />,
      value: 'options',
    },
  ];
};

export const todoWeekDayList = (
  translate: (arg: string) => string,
): WeekdaysListInterface[] => {
  return [
    {
      title: translate('calendar.saturday'),
      value: 'repeatweekdayssa',
    },
    {
      title: translate('calendar.sunday'),
      value: 'repeatweekdayssu',
    },
    {
      title: translate('calendar.monday'),
      value: 'repeatweekdaysmo',
    },
    {
      title: translate('calendar.tuesday'),
      value: 'repeatweekdaystu',
    },
    {
      title: translate('calendar.wednesday'),
      value: 'repeatweekdayswe',
    },
    {
      title: translate('calendar.thursday'),
      value: 'repeatweekdaysth',
    },
    {
      title: translate('calendar.friday'),
      value: 'repeatweekdaysfr',
    },
  ];
};

/**
 * @function getTranslateForRepeatType
 * @param { number | null } repeatType
 * @param { (arg: string) => string } translate
 * @returns { string }
 */
export const getTranslateForRepeatType = (
  repeatType: number | null,
  translate: (arg: string) => string,
): string => {
  switch (repeatType?.toString()) {
    case repeatTypeDropDown.daily:
      return translate('todo.daily');
    case repeatTypeDropDown.weekly:
      return translate('todo.weekly');
    case repeatTypeDropDown.monthly:
      return translate('todo.monthly');
    case repeatTypeDropDown.yearly:
      return translate('todo.yearly');
    default:
      return '';
  }
};

export const repeatTypeDropDown = {
  daily: '1',
  weekly: '2',
  monthly: '3',
  yearly: '4',
};
