import { useTranslate } from 'react-admin';
import type { ReactElement } from 'react';
import Box from '@material-ui/core/Box';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'classnames';

import type { UserItemViewInterface } from './user-item.type';
import { useStyles } from './user-item.style';
import { Menu, MenuItem } from '@material-ui/core';

const UserItemView = (props: UserItemViewInterface): ReactElement => {
  const {
    handleOnUserSelect,
    handleDeleteChat,
    openContextMenu,
    getAvatarURL,
    handleClose,
    selectedUser,
    anchorEl,
    item,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.mainContainer}>
      <ListItem
        button
        className={clsx(classes.listItemRoot, 'item-hover', {
          active: selectedUser && selectedUser.personinfo_id === item.personinfo_id,
        })}
        onClick={handleOnUserSelect}
        onContextMenu={openContextMenu}
      >
        <Box>
          <ListItemAvatar className={classes.avatarRoot}>
            <Avatar className={classes.avatar} src={getAvatarURL()} />
          </ListItemAvatar>
        </Box>
        <Box className={classes.userInfoRoot}>
          <Box
            component="span"
            fontWeight={300}
            display="block"
            mb={0.5}
            fontSize={14}
          >
            {item.personname}
          </Box>
        </Box>
      </ListItem>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteChat}>
          {translate('chat.deleteGroup')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserItemView;
