import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    minHeight: 300,
    '&:hover': {
      overflow: 'auto',
    },
  },
}));
export default useStyles;
