import { parseJSON } from '../../../../core/configProvider';

import type {
  MessageItemInterface,
  UploadedFileInterface,
} from '../../chat-section.type';
import type { UtilsRefInterface } from '../messages-list.type';

/**
 * @function getFileAddress
 * @param { string | null } fileUrl
 * @returns { string | null }
 */
export const getFileAddress = (
  fileUrl: string | null,
): UploadedFileInterface | null => {
  if (!fileUrl) {
    return null;
  }

  const fileAddress = parseJSON<
    UploadedFileInterface | { data: UploadedFileInterface }
  >(fileUrl);

  if (fileAddress) {
    if (typeof fileAddress === 'object' && 'data' in fileAddress) {
      return fileAddress.data;
    }

    return fileAddress as UploadedFileInterface;
  }

  return null;
};

/**
 * @function _scrollTo
 * @param {HTMLElement | null | undefined} element Target element to scroll
 * @param {ScrollToOptions} options some options such as `top`, etc...
 * @returns {void} void
 */
export const _scrollTo = (
  element: HTMLElement | null | undefined,
  options?: ScrollToOptions,
): void => {
  if (!element) {
    console.warn('`scrollTo`: Invalid target element');
    return;
  }

  element.scrollTo(options);
};

/**
 * @function isVisibleInViewport
 * @param {HTMLElement | null | undefined} container - Container of the target element
 * @param {HTMLElement | null | undefined} element - target element
 * @returns {boolean} a boolean
 */
export const isVisibleInViewport = (
  containerElement: Element | null | undefined,
  element: Element | null | undefined,
): boolean => {
  if (!element || !containerElement) return false;

  const { bottom, height, top } = element.getBoundingClientRect();
  const containerRect = containerElement.getBoundingClientRect();

  return top <= containerRect.top
    ? containerRect.top - top <= height && bottom >= containerRect.top * 1.3 // 1.3 => A relative confidence coefficient to sure the element is visible wholly
    : (bottom - containerRect.bottom) * 3.3 <= height; // 3.3 => A relative confidence coefficient to sure the element is visible wholly;
};

/**
 * @function getSeenMessagesIdInViewport
 * @param {Element | null | undefined} containerElement The container element of messages boxes
 * @param {Element[]} messagesBubbleElements The current messages boxes
 * @param {number[]} seenMessagesIds All `id`s of seen messages
 * @param {number[]} unseenMessagesIds All `id`s of unseen messages
 * @returns {updatedSeenMessagesIds: number[] updatedUnseenMessagesIds: number[]} An object of updated arrays of `id`s(new seen and new unseen messages)
 */
export const getSeenMessagesElementsInViewport = (
  containerElement: Element | null | undefined,
  messagesBubbleElements: Element[],
  seenMessagesIds: number[],
  unseenMessagesIds: number[],
): {
  updatedSeenMessagesIds: number[];
  updatedUnseenMessagesIds: number[];
} => {
  if (!containerElement || unseenMessagesIds.length === 0) {
    return {
      updatedSeenMessagesIds: [],
      updatedUnseenMessagesIds: [],
    };
  }

  const updatedSeenMessagesIds: number[] = [...seenMessagesIds];
  const updatedUnseenMessagesIds: number[] = [...unseenMessagesIds];
  for (let index = 0; index < messagesBubbleElements.length; index++) {
    if (!isVisibleInViewport(containerElement, messagesBubbleElements[index])) {
      continue;
    }

    const elementId = messagesBubbleElements[index].getAttribute('id');
    const id = elementId?.split('-')?.[1] ?? null;
    if (!id) continue;

    const _id = +id;
    const targetUnseenMessageIdIndex = updatedUnseenMessagesIds.indexOf(_id);
    if (targetUnseenMessageIdIndex > -1) {
      updatedUnseenMessagesIds.splice(targetUnseenMessageIdIndex, 1);

      if (updatedSeenMessagesIds.indexOf(_id) === -1) {
        updatedSeenMessagesIds.push(_id);
      }
    }
  }

  return {
    updatedSeenMessagesIds,
    updatedUnseenMessagesIds,
  };
};

/**
 * @function scrollToFirstUnSeenMessage
 * @param utilsRef A ref that contains some data of `message-list.controller`
 * @param requiredElementsRef Some element refs in `message-list.view`
 * @returns {UtilsRefInterface} an object
 */
export const scrollToFirstUnSeenMessage = (
  utilsRef: UtilsRefInterface,
  scrollableDivRef: HTMLDivElement | null | undefined,
  unseenMessageElement: HTMLElement | null,
  showSeparatorElement = false,
): UtilsRefInterface => {
  if (!scrollableDivRef || !unseenMessageElement) {
    return utilsRef;
  }

  const updatedUtilsRef = { ...utilsRef };
  updatedUtilsRef.canScrollDown = false;

  if (showSeparatorElement) {
    const divElement = document.createElement('div');
    divElement.innerText = 'پیام های خوانده نشده 👇🏻👇🏻';
    unseenMessageElement.parentNode?.insertBefore(divElement, unseenMessageElement);
  }

  const firstOfUnSeenDivRefAspect = unseenMessageElement.getBoundingClientRect();

  _scrollTo(scrollableDivRef, {
    top: firstOfUnSeenDivRefAspect.top - firstOfUnSeenDivRefAspect.height - 56, // `-56` is a little extra distance from top
  });

  return updatedUtilsRef;
};

/**
 * @function findUnseenMessagesFromCurrentMessages
 * @param {MessageItemInterface[]} currentMessages
 * @returns {number[]} An array of unseen messages ids
 */
export const findUnseenMessagesFromCurrentMessages = (
  currentMessages: MessageItemInterface[],
): {
  firstUnseenMessage: MessageItemInterface | null;
  unseenMessagesIds: number[];
} => {
  const unseenMessagesIds: number[] = [];
  for (let index = 0; index < currentMessages.length; index++) {
    if (currentMessages[index].isfrommyself || currentMessages[index].isseen) {
      continue; // I have seen it already
    }

    unseenMessagesIds.unshift(currentMessages[index].chat_id);
  }

  return {
    unseenMessagesIds,
    firstUnseenMessage:
      currentMessages.find(item => item.chat_id === unseenMessagesIds[0]) ?? null,
  };
};

/**
 * @function getMessagesBoxElements
 * @param {HTMLDivElement | null} scrollableDivRef A div element(container of messages boxes)
 * @returns {Element[]} An array of `div` elements
 */
export const getMessagesBoxElements = (
  scrollableDivRef: HTMLDivElement | null,
): Element[] => {
  if (!scrollableDivRef) return [];
  return Array.from(
    scrollableDivRef.firstElementChild?.firstElementChild?.children ?? [],
  );
};
