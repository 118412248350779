import type { ChangeEvent } from 'react';
import type { FieldType } from '../../helper/Types';

export interface DynamicInputControllerProps {
  field: FieldType;
  inputContainerClass?: string;
  currentTabIndex?: number;
  noLabel?: boolean;
  label?: string;
  defaultValue?: string;
  isServiceMode?: boolean;
  inputInPuzzleForm?: boolean;
  isProfile?: boolean;
  disableDropdownSearchPopup?: boolean;
  onInputStateChange?: (...args) => void;
  groupId?: string;
}

export interface StateType {
  admin: {
    ui: { viewVersion: number };
    profile: { globalParameters: object | null };
  };
}

export interface ExtraProps {
  globalParameters: object;
  dropdownState: [{ id?: string | number }] | null | any[];
  viewVersion: number;
  isCreateMode?: boolean;
}

export enum ui { // Example: { field.uiVisible : [ ["customer_parentbarcodemain_id", "customer_id", "value=2991942"] ] }
  fieldName,
  column,
  value,
}

export type TextFieldChangeEvent = ChangeEvent<
  HTMLTextAreaElement | HTMLInputElement
>;
export type SelectChangeEvent = ChangeEvent<{
  name?: string | undefined;
  value: unknown;
}>;
export type CheckboxChangeEvent = ChangeEvent<HTMLInputElement>;
