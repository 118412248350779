import { type FC } from 'react';
import { useTranslate } from 'react-admin';
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';

import { useStyles } from './permission-type.style';

import type {
  PermissionTypeViewInterface,
  ValidCheckboxOnChangeType,
} from './permission-type.type';

const PermissionTypeView: FC<PermissionTypeViewInterface> = props => {
  const { formState, onTypeSelect, permissionTypes, isSelectAll } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card className={classes.container}>
      <Typography className={classes.title}>
        {translate('permissions.permissionType')}
      </Typography>

      <div className={classes.divider} />

      <FormControl component="fieldset" className={classes.formControl}>
        <FormControlLabel
          className={`${classes.formControlLabel} ${classes.selectAll}`}
          control={<Checkbox />}
          label={translate('permissions.selectAll')}
          checked={isSelectAll}
          onChange={onTypeSelect as unknown as ValidCheckboxOnChangeType}
          name={'selectAll'}
        />

        <FormGroup className={classes.formGroup}>
          {permissionTypes.map(item => {
            return (
              <FormControlLabel
                className={classes.formControlLabel}
                control={<Checkbox />}
                label={item.title}
                checked={!!formState[item.value]}
                onChange={onTypeSelect as unknown as ValidCheckboxOnChangeType}
                name={item.value}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Card>
  );
};

export default PermissionTypeView;
