import querystring from 'qs';
import lodashGet from 'lodash/get';
import { isEmpty, validUrlPattern } from './data-helper';
import { actorGetActionValue } from '../type/actor-setup';
import { CreateNewUrlBaseOfSessionIdInUrl } from './Types';
import { getValue, USER_ID } from '../core/configProvider';

/**
 * Get param from url.
 * @function getParamFromUrl
 * @param {string} url
 * @param {string} name
 * @returns {null | string}
 */
export const getParamFromUrl = (url: string, name: string): null | string => {
  if (!url) {
    return null;
  }

  const params = querystring.parse(url.replace('?', '&'), {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;

  return params[name];
};

/**
 * Add param to url.
 * @function addParamToUrl
 * @param {string | null} url
 * @param {string} name
 * @param {number|string} value
 * @returns {null |string}
 */
export const addParamToUrl = (
  url: string | null,
  name: string,
  value: number | string,
): null | string => {
  if (!url) {
    return null;
  }

  const indexOfQ = url.indexOf('?');

  // if doesn't have extra params
  if (indexOfQ === -1) {
    return `${url}?${name}=${value}`;
  }

  const [baseUrl, rest] = url.split('?');

  const params = querystring.parse(rest, { ignoreQueryPrefix: true }) as Record<
    string,
    unknown
  >;
  params[name] = value;

  return baseUrl + '?' + querystring.stringify(params);
};

/**
 * Find parent id from url in edit relation record.
 * @function findIdFromUrlInEditRelationRecord
 * @param {string} location
 * @returns {void | number} id
 */
export const findIdFromUrlInEditRelationRecord = (url: string): void | number => {
  if (!isEmpty(url)) {
    const redirect = getParamFromUrl(url, 'redirect');
    if (!isEmpty(redirect)) {
      const parentUrlSplited = redirect!.split('/');
      if (parentUrlSplited && parentUrlSplited.length > 1) {
        const id = lodashGet(parentUrlSplited, 3);
        return id ? +id : undefined;
      }
    }
  }
};

/**
 * Check for a valid url
 * @function validUrl
 * @param {string} url
 * @returns{boolean}
 */
export const isValidUrl = (url: string): boolean => {
  return validUrlPattern.test(url);
};

/**
 * it look for session id in url and return it and return null if session id not find.
 * @function getSessionIdInUrl
 * @returns {string | null}
 */
export const getSessionIdInUrl = (): string | null => {
  let sessionid = sessionStorage.getItem('sessionid');
  if (!isEmpty(sessionid)) return sessionid;

  const currentUrl = window.parent.location.href ?? '';

  const lowerCaseUrl = currentUrl.toLowerCase();

  if (currentUrl && !lowerCaseUrl.includes('sessionid')) {
    return null;
  }

  const urlParameters = querystring.parse(
    lowerCaseUrl.includes('?') ? lowerCaseUrl.split('?')[1] : lowerCaseUrl,
  ) as { sessionid?: string };

  sessionid = urlParameters?.['sessionid'] ?? null;
  if (!isEmpty(sessionid)) {
    sessionStorage.setItem('sessionid', sessionid!);
  }
  return sessionid;
};

/**
 * it create new url with session-id-base authentication
 * @function createNewUrlBaseOfSessionIdInUrl
 * @param {string} url
 * @param {string} sessionId
 * @returns {string} new url
 */
export const createNewUrlBaseOfSessionIdInUrl: CreateNewUrlBaseOfSessionIdInUrl = (
  url,
  sessionId,
  apiAddress,
) => {
  const finalUrl = addAdditionalParamsToUrl(url);
  try {
    if (!sessionId) return finalUrl;

    const apiPrefixLength = apiAddress.length;

    return `${finalUrl.substr(
      0,
      apiPrefixLength,
    )}/oauth2/${sessionId}${finalUrl.substr(apiPrefixLength)}`;
  } catch (error) {
    return finalUrl;
  }
};

/**
 * Open a link in a new tab
 * @function openLinkNewTab
 * @param {string} url
 * @param {boolean} forceOpenIncomingUrl
 * @returns {void}
 */
export const openLinkNewTab = (url: string, forceOpenIncomingUrl = false): void => {
  let newUrl = '';
  if (!isValidUrl(url) && !forceOpenIncomingUrl) {
    newUrl = newUrl.concat('http://', url);
  } else {
    newUrl = url;
  }

  window.open(newUrl, '_blank');
};

/**
 * check if there is `embed` parameter in url or not
 * @function isEmbeddedPage
 * @returns {boolean}
 */
export const isEmbeddedPage = (): boolean => {
  const currentUrl = (window.location.href || window.parent.location.href) ?? '';
  const lowerCaseUrl = currentUrl.toLowerCase();

  if (currentUrl && !lowerCaseUrl.includes('embed')) {
    return false;
  }

  const urlParameters = querystring.parse(
    lowerCaseUrl.includes('?') ? lowerCaseUrl.split('?')[1] : lowerCaseUrl,
  );

  return Boolean(urlParameters?.embed);
};

/**
 * add optional params to url
 * @function addAdditionalParamsToUrl
 * @param {string} url
 * @returns {string}
 */
export const addAdditionalParamsToUrl = (url: string): string => {
  if (!url) return url;
  const userId = getValue(USER_ID);
  const departmentId = actorGetActionValue(
    'profile',
    'profileData.globalparameters.departmentinfoid',
  ) as unknown as string;

  let uniqueCacheId = userId ?? '';
  uniqueCacheId = departmentId ? `U${uniqueCacheId}D${departmentId}` : uniqueCacheId;

  return addParamToUrl(url, 'clientCacheKey', uniqueCacheId) ?? url;
};

/**
 * @function getUrlSubdomain
 * @returns {string | null}
 */
export const getUrlSubdomain = (): string | null => {
  const domain = /:\/\/([^/]+)/.exec(window.location.href)?.[1].split('.');
  return Array.isArray(domain) ? domain[0] : null;
};

/**
 * @function getUrlSubdomain
 * @param {boolean} withSubdomain
 * @returns {string}
 */
export const getDomain = (withSubdomain = true): string => {
  let url = window.location.href;

  url = url.replace(/(https?:\/\/)?(www.)?/i, '');

  if (!withSubdomain) {
    const domainSplitUrl = url.split('.');
    url = domainSplitUrl.slice(domainSplitUrl.length - 2).join('.');
  }

  url = url.replace(/:(\d+)/, '');
  if (url.indexOf('/') !== -1) {
    url = url.split('/')[0];
  }
  return url;
};
