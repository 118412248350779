import type {
  GadgetReport,
  GetChartReportsDropResponse,
} from './setting-section.type';

export const prepareReportsForGadgetInput = (
  reports: GetChartReportsDropResponse[],
): GadgetReport[] => {
  return reports.map((report: GetChartReportsDropResponse) => ({
    label: report?.reporttitle ?? '',
    reportId: report?.uniqueid ?? '',
    isActiveCalendar: report?.isactivecalendar ?? false,
  }));
};
