import momentJalaali from 'moment-jalaali';

import { getDashboardFilterFromUrl } from '../dashboard/dashboard-card/dashboard-card.helper';
import { isEmpty } from '../../helper/data-helper';
import {
  dateRegex,
  dateTimeRegex,
} from './range-picker-input/range-picker-input.helper';

import type { FilterItemFinalFormatType, FinalFiltersType } from '../filter-form';
import type { Translate } from '../../helper/Types';
import type {
  ConvertFormDataToFilterFormat,
  RangePickerFormData,
  RangeUnit,
} from './range-picker.type';

const RangePickerSigns = ['y', 'M', 'w', 'd', 'h', 'm', 's'];

/**
 * return translated constant range units
 * @function getRangeUnits
 * @param {Translate} translate
 * @returns {Array<{ value: RangeUnit; label: string }> }
 */
export const getRangeUnits = (
  translate: Translate,
): Array<{ value: RangeUnit; label: string }> => {
  return [
    { value: 'second', label: translate('rangePickerJira.second') },
    { value: 'minute', label: translate('rangePickerJira.minute') },
    { value: 'hour', label: translate('rangePickerJira.hour') },
    { value: 'day', label: translate('rangePickerJira.day') },
    { value: 'week', label: translate('rangePickerJira.week') },
    { value: 'month', label: translate('rangePickerJira.month') },
    { value: 'year', label: translate('rangePickerJira.year') },
  ];
};

/**
 * receive Range input sentence and cast it to teal date time and return it with this format : 'yyyy-MM-dd HH:mm:ss'
 * ex. `-4w 2d` => `1405/03/11 12:32:45`
 * @function calculateDateTimeByRange
 * @param {string} sentence
 * @param {Date} currentDate
 * @returns {string}
 */
export const calculateDateTimeByRange = (
  sentence: string,
  currentDate: Date = new Date(),
): string => {
  // Split the sentence into individual components
  const components = sentence.split(/\s+/);

  // Initialize variables to store the parsed values
  let years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0;

  // Parse each component and update the corresponding variable
  components.forEach(component => {
    if (component.includes('y')) {
      years = parseInt(component) || 0;
    } else if (component.includes('M')) {
      months = parseInt(component) || 0;
    } else if (component.includes('w')) {
      weeks = parseInt(component) || 0;
    } else if (component.includes('d')) {
      days = parseInt(component) || 0;
    } else if (component.includes('h')) {
      hours = parseInt(component) || 0;
    } else if (component.includes('m')) {
      minutes = parseInt(component) || 0;
    } else if (component.includes('s')) {
      seconds = parseInt(component) || 0;
    }
  });

  // Calculate the new date and time
  const newDate = new Date(
    currentDate.getFullYear() + years,
    currentDate.getMonth() + months,
    currentDate.getDate() + weeks * 7 + days,
    currentDate.getHours() + hours,
    currentDate.getMinutes() + minutes,
    currentDate.getSeconds() + seconds,
  );

  // Format the new date and time as 'yyyy-MM-dd HH:mm:ss'
  const formattedDate = newDate.toISOString().replace('T', ' ').slice(0, 19);

  return formattedDate;
};

/**
 * add default time to a simple date base of inputs mode to find out it should be beginning of day or end of it
 * @function convertDateToDateTime
 * @param {string} date
 * @param {'from' | 'to'} mode
 * @returns {string}
 */
export const convertDateToDateTime = (date = '', mode: 'from' | 'to'): string => {
  if (dateRegex.test(date)) {
    return `${date} ${mode === 'from' ? '00:00:00' : '23:59:59'}`;
  }

  return date;
};

/**
 * separate nested characters and numbers
 *
 * ex. `123four56seven` => ['123', 'four', '56', 'seven']
 *
 * @function splitNumbersAndCharacters
 * @param {string} inputString
 * @returns {string[]}
 */
export const splitNumbersAndCharacters = (inputString: string): string[] => {
  const regex = /(\d+)([a-zA-Z]+)/g;
  const matches = inputString.match(regex);

  if (!matches) {
    return [inputString]; // If there are no numbers followed by characters, return the original string in an array.
  }

  return matches
    .map(match => match.match(/(\d+)([a-zA-Z]+)/)?.slice(1))
    .flat() as string[];
};

/**
 * convert form data to filter format
 * @function convertFormDataToFilterFormat
 * @param {string} fieldName
 * @param {RangePickerFormMode} formMode
 * @param {MutableRefObject<Record<string, unknown>>} selectedNumbers
 * @param {MutableRefObject<Record<string, unknown>>} selectedUnits
 * @param {MutableRefObject<RangePickerRefProperties | undefined>} fromDateInputRef
 * @param {MutableRefObject<RangePickerRefProperties | undefined>} toDateInputRef
 * @param {MutableRefObject<RangePickerRefProperties | undefined>} fromRangeInputRef
 * @param {MutableRefObject<RangePickerRefProperties | undefined>} toRangeInputRef
 * @returns {FinalFiltersType | null}
 */
export const convertFormDataToFilterFormat: ConvertFormDataToFilterFormat =
  entries => {
    const {
      fieldName,
      formMode,
      selectedNumbers,
      selectedUnits,
      fromDateInputRef,
      toDateInputRef,
      fromRangeInputRef,
      toRangeInputRef,
    } = entries;

    const filter = [fieldName];

    if (formMode === 'allPast') {
      filter.push('<=');
      filter.push('now');
    } else if (formMode === 'lastPeriod') {
      if (isEmpty(selectedNumbers.current[formMode])) {
        // validation error
        return null;
      }
      const value = `${selectedNumbers.current[formMode]}${selectedUnits.current[formMode]}`;
      filter.push('between');
      filter.push(value);
      filter.push('now');
    } else if (formMode === 'nextPeriod') {
      if (isEmpty(selectedNumbers.current[formMode])) {
        // validation error
        return null;
      }
      const value = `${selectedNumbers.current[formMode]}${selectedUnits.current[formMode]}`;
      filter.push('between');
      filter.push('now');
      filter.push(value);
    } else if (formMode === 'between') {
      // eslint-disable-next-line prettier/prettier
    const fromDate = convertDateToDateTime(fromDateInputRef.current?.inputValue, 'from');
      const toDate = convertDateToDateTime(toDateInputRef.current?.inputValue, 'to');

      if (fromDate && toDate) {
        filter.push('between');
        filter.push(fromDate);
        filter.push(toDate);
      } else if (fromDate) {
        filter.push('>=');
        filter.push(fromDate);
      } else if (toDate) {
        filter.push('<=');
        filter.push(toDate);
      } else {
        return null;
        // validation error
      }
    } else if (formMode === 'inRange') {
      const fromRange = fromRangeInputRef.current?.inputValue ?? '';
      const toRange = toRangeInputRef.current?.inputValue ?? '';

      if (fromRange && toRange) {
        filter.push('between');
        filter.push(fromRange);
        filter.push(toRange);
      } else if (fromRange) {
        filter.push('>=');
        filter.push(fromRange);
      } else if (toRange) {
        filter.push('<=');
        filter.push(toRange);
      } else {
        return null;
        // validation error
      }
    }

    return filter;
  };

/**
 * its opposite of `convertFormDataToFilterFormat` function
 * will receive filter and convert it to formData
 * @function findFormDataBaseOfFilter
 * @param {FilterItemFinalFormatType} filter
 * @returns {RangePickerFormData}
 */
export const findFormDataBaseOfFilter = (
  filter: FilterItemFinalFormatType,
): RangePickerFormData => {
  const [, operator, first, second] = filter;
  const formData: RangePickerFormData = {};

  // ----------------------- allPast -----------------------
  if (operator === '<=' && first === 'now') {
    formData.formMode = 'allPast';
  } else if (operator === 'between' && second === 'now') {
    // ----------------------- lastPeriod -----------------------
    formData.formMode = 'lastPeriod';
    const [selectedNumbers, selectedUnits] = splitNumbersAndCharacters(
      first as string,
    );
    formData.selectedNumbers = selectedNumbers;
    formData.selectedUnits = selectedUnits as RangeUnit;
  } else if (operator === 'between' && first === 'now') {
    // ----------------------- nextPeriod -----------------------
    formData.formMode = 'nextPeriod';
    const [selectedNumbers, selectedUnits] = splitNumbersAndCharacters(
      second as string,
    );
    formData.selectedNumbers = selectedNumbers;
    formData.selectedUnits = selectedUnits as RangeUnit;
  } else if (
    operator === 'between' &&
    dateTimeRegex.test(first as string) &&
    dateTimeRegex.test(second as string)
  ) {
    // ----------------------- between -----------------------
    formData.formMode = 'between';
    formData.fromDateInput = first as string;
    formData.toDateInput = second as string;
  } else if (operator === '>=' && dateTimeRegex.test(first as string)) {
    formData.formMode = 'between';
    formData.fromDateInput = first as string;
  } else if (operator === '<=' && dateTimeRegex.test(first as string)) {
    formData.formMode = 'between';
    formData.toDateInput = first as string;
  } else if (
    operator === 'between' &&
    RangePickerSigns.indexOf(first as string) &&
    RangePickerSigns.indexOf(second as string)
  ) {
    // ----------------------- inRange -----------------------
    formData.formMode = 'inRange';
    formData.fromRangeInput = first as string;
    formData.toRangeInput = second as string;
  } else if (operator === '>=' && RangePickerSigns.indexOf(first as string)) {
    formData.formMode = 'inRange';
    formData.fromRangeInput = first as string;
  } else if (operator === '<=' && RangePickerSigns.indexOf(first as string)) {
    formData.formMode = 'inRange';
    formData.toRangeInput = first as string;
  } else {
    //validation error
  }

  return formData;
};

/**
 * receive period sentence and cast it to teal date time and return it with this format : 'yyyy-MM-dd HH:mm:ss'
 * ex. `15day` => `1405/03/11 12:32:45`
 * @function parsePeriodTimeSentenceToDateTime
 * @param {string} input
 * @param {boolean} subtract
 * @returns {string}
 */
export const parsePeriodTimeSentenceToDateTime = (
  input: string,
  subtract = false,
): string => {
  // Define the time units and their corresponding multipliers in milliseconds
  const timeUnits: Record<string, number> = {
    second: 1000,
    minute: 60000,
    hour: 3600000,
    day: 86400000,
    week: 604800000,
    month: 2629800000,
    year: 31557600000,
  };

  // Parse the input string to extract the number and time unit
  const matches = input.match(/^(\d+)([a-zA-Z]+)$/);

  if (!matches) {
    return 'Invalid input format';
  }

  const number = parseInt(matches[1], 10);
  const unit = matches[2].toLowerCase();

  if (!timeUnits[unit]) {
    return 'Invalid time unit';
  }

  // Get the current date
  const currentDate = new Date();

  // Calculate the time difference based on the number and time unit
  const timeDifference = subtract
    ? currentDate.getTime() - number * timeUnits[unit]
    : currentDate.getTime() + number * timeUnits[unit];

  // Create a new Date object with the modified time
  const newDate = new Date(timeDifference);

  // Format the date as 'yyyy-MM-dd HH:mm:ss'
  const formattedDate = `${newDate.getFullYear()}-${String(
    newDate.getMonth() + 1,
  ).padStart(2, '0')}-${String(newDate.getDate()).padStart(2, '0')} ${String(
    newDate.getHours(),
  ).padStart(2, '0')}:${String(newDate.getMinutes()).padStart(2, '0')}:${String(
    newDate.getSeconds(),
  ).padStart(2, '0')}`;

  return formattedDate;
};

/**
 * @function getCurrentDateTime
 * @returns {string}
 */
export const getCurrentDateTime = (): string => {
  momentJalaali.locale('fa');
  return momentJalaali().format('YYYY-MM-DD HH:mm:ss');
};

/**
 * read url filters , convert it to real date times and return it
 * @function getCalculatedUrlFilters
 * @returns {FinalFiltersType}
 */
export const getCalculatedUrlFilters = (): FinalFiltersType => {
  const currentFiltersInUrl = getDashboardFilterFromUrl();
  const compatibleFilters: FinalFiltersType = [];

  currentFiltersInUrl.forEach(filter => {
    if (!Array.isArray(filter)) {
      compatibleFilters.push(filter);
    } else {
      const formData = findFormDataBaseOfFilter(filter);

      if (!formData.formMode || formData.formMode === 'between') {
        compatibleFilters.push(filter);
      } else {
        const [key, operator, value1, value2] = filter;

        if (formData.formMode === 'allPast') {
          compatibleFilters.push([key, operator, getCurrentDateTime()]);
        } else if (formData.formMode === 'lastPeriod') {
          compatibleFilters.push([
            key,
            operator,
            parsePeriodTimeSentenceToDateTime(value1 as string, true),
            getCurrentDateTime(),
          ]);
        } else if (formData.formMode === 'nextPeriod') {
          compatibleFilters.push([
            key,
            operator,
            getCurrentDateTime(),
            parsePeriodTimeSentenceToDateTime(value2 as string),
          ]);
        } else if (formData.formMode === 'inRange') {
          compatibleFilters.push(
            !value2
              ? [key, operator, calculateDateTimeByRange(value1 as string)]
              : [
                  key,
                  operator,
                  calculateDateTimeByRange(value1 as string),
                  calculateDateTimeByRange(value2 as string),
                ],
          );
        }
      }
    }
  });

  return compatibleFilters;
};
