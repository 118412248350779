import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import { getAppSettings, setAppSettings } from '../../../helper/settings-helper';
import {
  showNotification,
  waitForBrowserPaint,
} from '../../../helper/general-function-helper';
import {
  CONFIG_LIST_COLUMN_CHOICE,
  CONFIG_LIST_LAST_FILTER,
  CONFIG_SAVED_FILTER,
} from '../../../core/configProvider';
import SavedFiltersButtonView from './saved-filters-button.view';
import useWidth from '../../useWidth';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
} from '../../../type/actor-setup';
import { getCurrentMetaData } from '../../../helper/ActorHelper';
import { getFilterColumns } from '../../../helper/MetaHelper';
import {
  generateFilterKeyForAppSetting,
  MAX_SAFE_FILTERS_COUNT,
  generateFilterValueForAppSetting,
} from '../filter-form.helper';

import type {
  SavedFilterItemInterface,
  SavedFiltersButtonPropsInterface,
} from './saved-filters-button.type';
import type { FC, SyntheticEvent } from 'react';
import { isEmptyObject } from '../../../helper/data-helper';

const SavedFiltersButtonController: FC<SavedFiltersButtonPropsInterface> = props => {
  const { resource, fields, isRelationMode } = props;

  const translate = useTranslate();
  const dialogWidth = useWidth();

  const [defaultItemsDisabled, setDefaultItemsDisabled] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [savedFilters, setSavedFilters] = useState<
    Record<string | number, SavedFilterItemInterface>
  >({});
  const initialTopOfFilter = actorGetActionValue('isTopFilterOpen', resource);
  const [isTopOfFilter, setIsTopOfFilter] = useState<boolean>(
    initialTopOfFilter ?? false,
  );

  const onDispatchData = useRef<{ actionName: keyof ActorActionList; id: symbol }[]>(
    [],
  );

  const currentMetaData = getCurrentMetaData();

  const isShowAllFiltersButtonDisabled = useMemo(() => {
    if (!currentMetaData) return false;

    const filterColumns = getFilterColumns(currentMetaData) ?? [];
    return filterColumns.length > MAX_SAFE_FILTERS_COUNT ? true : false;
  }, [
    currentMetaData?.['reportId'],
    currentMetaData?.['config']?.['moduleName'],
    currentMetaData?.['config']?.['moduleTableName'],
  ]);

  useEffect(() => {
    updateSavedFiltersFromSettings();

    let id = actorOnDispatch(
      'filterFormFields',
      filterFormFields => {
        const filter = filterFormFields?.[resource];
        if (filter) {
          setDefaultItemsDisabled(Object.keys(filter).length === 0);
        }
      },
      {
        preserve: false,
      },
    );

    onDispatchData.current.push({ actionName: 'filterFormFields', id });

    id = actorOnDispatch(
      'filterFormFieldHiddenChanged',
      () => {
        const filterFormFields = actorGetActionValue('filterFormFields')?.[resource];
        setDefaultItemsDisabled(Object.keys(filterFormFields ?? {}).length === 0);
      },
      {
        callerScopeName: 'SavedFiltersButtonController',
      },
    );

    onDispatchData.current.push({ actionName: 'filterFormFieldHiddenChanged', id });

    id = actorOnDispatch(
      'savedFilters',
      filterSavedData => {
        if (isEmptyObject(filterSavedData[resource])) return;
        setSavedFilters({ ...filterSavedData[resource] });
      },
      {
        preserve: false,
      },
    );

    id = actorOnDispatch(
      'filterDataIsChanged',
      filters => {
        setDefaultItemsDisabled(Object.keys(filters ?? {}).length === 0);
      },
      {
        preserve: false,
      },
    );

    return () => {
      for (const { actionName, id: listenerId } of onDispatchData.current) {
        actorRemoveAction({
          actionName,
          listenerId,
        });
      }
    };
  }, [resource]);

  const updateSavedFiltersFromSettings = useCallback((): void => {
    const savedFiltersFromTheAppSettings = getAppSettings<
      Record<string | number, SavedFilterItemInterface>
    >(generateFilterKeyForAppSetting(resource, CONFIG_SAVED_FILTER), true).value;
    if (savedFiltersFromTheAppSettings == null) return;

    const activeTabIndex = actorGetActionValue('activeTab')?.[resource];

    if (activeTabIndex != null) {
      const filteredSavedFiltersBasedOnTabIndex: Record<
        string | number,
        SavedFilterItemInterface
      > = {};

      const savedFiltersKeys = Object.keys(savedFiltersFromTheAppSettings);
      for (const id of savedFiltersKeys) {
        if (savedFiltersFromTheAppSettings[id].tabIndex !== activeTabIndex) {
          continue;
        }

        filteredSavedFiltersBasedOnTabIndex[id] = savedFiltersFromTheAppSettings[id];
      }

      setSavedFilters({ ...filteredSavedFiltersBasedOnTabIndex });
      return;
    }

    setSavedFilters({ ...savedFiltersFromTheAppSettings });
  }, [resource]);

  /**
   * @function handleClick
   * @param { SyntheticEvent<HTMLElement> } event
   * @returns { void } void
   */
  const handleClick = useCallback((event: SyntheticEvent<HTMLElement>): void => {
    updateSavedFiltersFromSettings();
    setAnchorEl(event.currentTarget);
  }, []);

  /**
   * @function closeSelectMenu
   * @returns { void } void
   */
  const closeSelectMenu = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  /**
   * @function removeFilterHandler
   * @param { string } filterId
   * @returns { void } void
   */
  const removeFilterHandler = useCallback(
    (filterId: string) => (): void => {
      actorDispatch('quickDialog', {
        confirmationIsOpen: true,
        data: {
          content: translate('ra.message.are_you_sure'),
          onConfirm: (confirmationCallback?: () => void): void => {
            const savedFiltersFromTheAppSettings =
              getAppSettings<Record<string | number, SavedFilterItemInterface>>(
                generateFilterKeyForAppSetting(resource, CONFIG_SAVED_FILTER),
                true,
              ).value ?? {};

            if (savedFiltersFromTheAppSettings[filterId] == null) {
              closeSelectMenu();
              return;
            }

            delete savedFiltersFromTheAppSettings[filterId];
            setSavedFilters({ ...savedFiltersFromTheAppSettings });

            setAppSettings({
              key: generateFilterKeyForAppSetting(resource, CONFIG_SAVED_FILTER),
              value: savedFiltersFromTheAppSettings,
              forUser: true,
              onSuccess: (): void => {
                confirmationCallback?.();
                showNotification(
                  translate('ra.filterWasDeletedSuccessfully'),
                  'info',
                );
                closeSelectMenu();
              },
              onFailure: (): void => {
                confirmationCallback?.();
              },
            });
          },
        },
      });
    },
    [resource],
  );

  /**
   * @function clearAllFilters
   * @returns { void } void
   */
  const clearAllFilters = useCallback((): void => {
    setDefaultItemsDisabled(true);
    closeSelectMenu();

    actorDispatch(
      'resetOriginalFieldsToFilter',
      { [resource]: true },
      {
        replaceAll: true,
        callerScopeName: 'SavedFiltersButtonController => clearAllFilters',
      },
    );

    // Reset to `false` after its job finished, for other components if they want to check its latest value
    requestAnimationFrame(() => {
      actorSetActionValue(
        'resetOriginalFieldsToFilter',
        { [resource]: false },
        {
          callerScopeName: 'SavedFiltersButtonController => setNewListFilter',
        },
      );
    });
  }, [resource]);

  /**
   * @function clearAllFilterValues
   * @returns { void } void
   */
  const clearAllFilterValues = useCallback((): void => {
    closeSelectMenu();
    actorDispatch(
      'clearAllFilterValues',
      { [resource]: true },
      {
        replaceAll: true,
        callerScopeName: 'SavedFiltersButtonController => clearAllFilterValues',
      },
    );
  }, [resource]);

  /**
   * @function setNewListFilter
   * @param { SavedFilterItemInterface } item
   * @returns { Function } a function
   */
  const setNewListFilter = useCallback(
    (item: SavedFilterItemInterface) => async (): Promise<void> => {
      if (item.data == null) return;

      actorDispatch(
        'filterFormFields',
        { [resource]: {} }, // empty `filterFormFields`
        {
          replaceAll: true,
          callerScopeName: 'SavedFiltersButtonController => 1-setNewListFilter',
        },
      );

      await waitForBrowserPaint();

      const filterDataKeys = Object.keys(item.data);
      const filterDataKeysLength = filterDataKeys.length;
      for (let index = 0; index < filterDataKeysLength; index++) {
        const filterData = item.data![filterDataKeys[index]];
        const filterKey = filterData.fieldData.key ?? filterData.fieldData.name;
        const targetIndex = fields.findIndex(
          _field => _field.key === filterKey || _field.name === filterKey,
        );
        if (targetIndex === -1) continue;

        filterData.fieldData = fields[targetIndex];
      }

      setAppSettings({
        key: generateFilterKeyForAppSetting(resource, CONFIG_LIST_LAST_FILTER),
        value: generateFilterValueForAppSetting(item.data),
        forUser: true,
        onFailure: (): void => {
          showNotification(translate('ra.updatingSettingsFailed'), 'error');
        },
      });

      actorDispatch(
        'filterFormFields',
        { [resource]: item.data },
        {
          callerScopeName: 'SavedFiltersButtonController => 2-setNewListFilter',
          replaceAll: true,
        },
      );

      if (item.gridColumns != null && !isRelationMode) {
        setAppSettings({
          key: CONFIG_LIST_COLUMN_CHOICE + '_' + resource,
          value: item.gridColumns,
          forUser: true,
          // onSuccess: (): void => {
          //   actorDispatch('refreshView', resource);
          // },
        });
      }

      closeSelectMenu();
    },
    [],
  );

  /**
   * @function showAllFilters
   * @returns { void } void
   */
  const showAllFilters = useCallback((): void => {
    actorDispatch(
      'showAllFieldsToFilter',
      { [resource]: true },
      {
        callerScopeName: 'SavedFiltersButtonController => showAllFilters',
        replaceAll: true,
      },
    );

    closeSelectMenu();
  }, []);

  /**
   * it will toggle `IsTopFilterOpen` state to decide show or dont show filters in each column in grid
   * and also it should call `clearFilters` function if it going to set false in state
   * @function toggleShowFilters
   * @returns {void} void
   */
  const toggleShowFilters = useCallback(() => {
    setIsTopOfFilter(prev => !prev);
    actorDispatch('isTopFilterOpen', { [resource]: !initialTopOfFilter });
  }, []);

  return (
    <SavedFiltersButtonView
      savedFilters={savedFilters}
      anchorEl={anchorEl}
      dialogIsOpen={!!anchorEl}
      disableItem={defaultItemsDisabled}
      dialogWidth={dialogWidth}
      translate={translate}
      closeSelectMenu={closeSelectMenu}
      handleClick={handleClick}
      setNewListFilter={setNewListFilter}
      showAllFilters={showAllFilters}
      removeFilterHandler={removeFilterHandler}
      clearAllFilters={clearAllFilters}
      clearAllFilterValues={clearAllFilterValues}
      isShowAllFiltersButtonDisabled={isShowAllFiltersButtonDisabled}
      toggleShowFilters={toggleShowFilters}
      isTopOfFilter={isTopOfFilter}
      resource={resource}
    />
  );
};

export default SavedFiltersButtonController;
