import { SortableContainer as reactSortableContainer } from 'react-sortable-hoc';
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  Icon,
  TextField,
  Typography,
} from '@material-ui/core';
import SortableItemController from '../sortable-item/sortable-item.controller';
import { useStyles } from './style';

import type { DynamicData, SortableViewInterface } from './sortable-list.type';
import { cloneElement, type FC } from 'react';

const SortableContainer = reactSortableContainer(
  ({ classes, children, onSortEnd }) => (
    <ul
      className={classes.sortableItem}
      data-style-content-sortable="contentSortable"
    >
      {children(onSortEnd)}
    </ul>
  ),
);

const SortableView: FC<SortableViewInterface> = ({
  translate,
  checkedFieldsRef,
  checkedFieldsInSearchRef,
  onSortEnd,
  dynamicData,
  finalFieldList,
  handleChangeAll,
  closeDialogHandler,
  handleChangeSearch,
  searchColumnWord,
  changeHandler,
  ViewButtonDialog,
  title,
}) => {
  const classes = useStyles();

  return (
    <>
      <DialogContent id="dialog_content">
        <Typography>{title}</Typography>
        <div>
          <Icon fontSize="small">info</Icon>
        </div>
        <TextField
          className={classes.searchColumn}
          placeholder={translate('ra.action.search')}
          autoFocus
          onChange={handleChangeSearch}
          defaultValue={searchColumnWord}
          id="searchTextField"
        />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  (checkedFieldsRef.current.length > 0 &&
                    checkedFieldsRef.current.length === dynamicData.length) ||
                  (checkedFieldsInSearchRef.current.length > 0 &&
                    checkedFieldsInSearchRef.current.length ===
                      finalFieldList.length)
                }
                onChange={event =>
                  handleChangeAll(event.target.checked, finalFieldList)
                }
                id="changeAllCheckbox"
              />
            }
            label={translate('listColumnChoice.changeAll')}
          />
        </div>
        {/* @ts-ignore */}
        <SortableContainer onSortEnd={onSortEnd} classes={classes}>
          {sortableProps =>
            finalFieldList.map((field, index) => (
              <SortableItemController
                key={`item-${index}`}
                field={field as DynamicData}
                locale={'fa'}
                checked={!!field.isChecked}
                index={index}
                onChange={changeHandler(field)}
                {...sortableProps}
              />
            ))
          }
        </SortableContainer>
      </DialogContent>
      {ViewButtonDialog
        ? cloneElement(ViewButtonDialog, { dynamicData, closeDialogHandler })
        : null}
    </>
  );
};

export default SortableView;
