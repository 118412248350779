import {
  type FC,
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
} from 'react';
import { useLocale } from 'react-admin';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import moment from 'moment';

import DynamicInputView from './dynamic-input.view';
import { SINGLE_RECORD_CONSTANT_UNIQUE_ID } from '../../helper/settings-helper';
import { getDropDownListFromState } from '../../helper/MetaHelper';
import { ui } from './dynamic-input.type';
import {
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  actorSetActionValue,
  RecordKeyMode,
} from '../../type/actor-setup';
import {
  getFieldAdditionalData,
  getTypeByField,
  inputTypes,
} from '../../helper/InputHelper';
import { isEmpty, isJsonEncodedString } from '../../helper/data-helper';
import { sanitizeFileStreamValue } from './multi-file-stream-input/multi-file-stream-input.helper';

import type { InputAppearanceCharacteristics } from '../../helper/meta-helper.type';
import type { InputMessage } from '../form';
import type {
  DynamicInputControllerProps,
  ExtraProps,
  StateType,
} from './dynamic-input.type';

const DynamicInputController: FC<DynamicInputControllerProps> = props => {
  const {
    defaultValue,
    field,
    noLabel,
    label,
    isServiceMode,
    isProfile,
    inputContainerClass,
    inputInPuzzleForm,
    onInputStateChange,
    groupId,
  } = props;

  const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;
  const currentResourceRef = useRef(actorGetActionValue('resources')!.current);

  const fieldType = getTypeByField(field);

  const isCreateMode = actorGetActionValue('formGlobalProps')?.isCreateMode;

  const record = actorGetActionValue('record', [
    currentResourceRef.current.value,
    currentResourceRef.current.type,
    RecordKeyMode.FORM,
  ]) as Record<string, unknown>;

  const formData = actorGetActionValue('formData', [
    currentResourceRef.current.value,
    currentResourceRef.current.type,
  ]) as Record<string, unknown>;

  //always use "relatedParameterName" in service form (RCT-2348)
  if (isServiceMode && field.relatedParameterName) {
    field.name = field.relatedParameterName as string;
  }

  const { name: fieldName, caption, translatedCaption, translatedComment } = field;

  // TODO: implement javaScriptUI features and complete javaScriptUI.test.js

  const [isUiVisible, setIsUiVisible] = useState(); // This value will change by form change handler, so the initial value should be `false`
  const [isUiEnabled, setIsUiEnabled] = useState(); // This value will change by form change handler, so the initial value should be `false`
  const [inputValue, setInputValue] = useState<unknown>();
  const [inputMessage, setInputMessage] = useState<InputMessage>();
  const lastIsHidden = useRef<boolean>();

  const inputRef = useRef<HTMLInputElement | null>();
  const isInputClearedOnce = useRef<boolean>(false);

  const locale = useLocale();

  const getRef = (ref: HTMLElement) => {
    if (['searchDialog', 'dropdown'].includes(field?.dataType?.erp)) {
      inputRef.current = ref as HTMLInputElement;
      return;
    }

    if (ref?.['input'] != null) {
      // DatePicker component
      inputRef.current = ref['input'];
    } else if (ref?.['open']) {
      // Dropdown component
      inputRef.current = ref['open'];
    } else {
      inputRef.current = ref?.querySelector?.('input');
    }
  };

  useEffect(() => {
    const id = actorOnDispatch('signal', signalKey => {
      if (signalKey === 'isInputClearedOnce') {
        isInputClearedOnce.current = true;
      }
    });

    return () => {
      actorRemoveAction({
        actionName: 'signal',
        listenerId: id,
      });
    };
  }, []);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  useLayoutEffect(() => {
    actorSetActionValue(
      'inputsRef',
      {
        [fieldName]: {
          setInputValue,
          setInputMessage,
          setIsUiVisible,
          setIsUiEnabled,
          inputRef,
          field,
          inputValue,
          isFirstLoad: true,
          uiVisibleInputsAction: [],
          uiEnableInputsAction: [],
        },
      },
      {
        path: [currentResourceRef.current.value, currentResourceRef.current.type],
        callerScopeName: 'DynamicInputController => useLayoutEffect',
      },
    );
  }, []);

  /**
   * @const inputsInitialAppearanceCharacteristics
   * includes the following permissions:
   *
   *  1- isRecordEditable
   *  2- check is report
   *  3- isFieldIsNotDisabledByParentDeactiveSubpanels
   *  4- isFieldEnableInItOwnProcessStep
   *  5- ui enable/visible
   *  6- exist in metadata but not exist in process task
   *  7- disable table primary field
   *  8- read only fields in `WMS`
   */
  const inputsInitialAppearanceCharacteristics = actorGetActionValue(
    'inputsInitialAppearanceCharacteristics',
    `${currentResourceRef.current.value}.${currentResourceRef.current.type}.${SINGLE_RECORD_CONSTANT_UNIQUE_ID}.${fieldName}`,
  ) as unknown as InputAppearanceCharacteristics | undefined;

  /**
   * add display non class to hidden fields
   * @function prepareVisibleClass
   * @returns {string} class name
   */
  const prepareVisibleClass = (): string => {
    let isHidden =
      inputsInitialAppearanceCharacteristics &&
      !inputsInitialAppearanceCharacteristics.visible;

    if (inputInPuzzleForm) {
      isHidden = field.hidden;
    }

    /**
     * Just when the state changed we have to check its value
     * That's why the new value of this state come from `setState` after a value is changed
     */
    if (isUiVisible !== undefined) {
      isHidden = !isUiVisible;
    }

    if (lastIsHidden.current !== isHidden) {
      onInputStateChange?.(groupId, field.name, !isHidden);
    }
    lastIsHidden.current = isHidden;

    return isHidden ? 'displayNone' : '';
  };

  let isDisabled =
    inputsInitialAppearanceCharacteristics &&
    !inputsInitialAppearanceCharacteristics.enable;

  if (inputInPuzzleForm) {
    isDisabled = field.readOnly;
  }

  /**
   * Just when the state changed we have to check its value
   * That's why the new value of this state come from `setState` after a value is changed
   */
  if (isUiEnabled !== undefined) {
    isDisabled = !isUiEnabled;
  }

  const value = useMemo(() => {
    let computedValue: unknown | null = null;
    if (fieldType === inputTypes.FILE_STREAM_FIELD) {
      if (inputValue !== null && typeof inputValue == 'object') {
        computedValue = [inputValue];
      } else {
        computedValue = [
          {
            filePath: record?.[field.linkName],
            realFileName: inputValue,
          },
        ];
      }
    } else if (fieldType !== inputTypes.MULTI_FILE_STREAM_FIELD) {
      computedValue = inputValue;
    } else {
      computedValue = sanitizeFileStreamValue(inputValue as string);
    }
    return computedValue;
  }, [inputValue]);

  field.customOption = {
    additionalDataFieldAlt: '',
    customStyleAdditionalDataField: {},
  };

  if (!isEmpty(record?.[`__${fieldName}_additionaldata`] as string)) {
    const fieldAdditionalData = getFieldAdditionalData(
      record?.[`__${fieldName}_additionaldata`] as string,
    );
    field.customOption.additionalDataFieldAlt =
      fieldAdditionalData.additionalDataFieldAlt;

    field.customOption.customStyleAdditionalDataField =
      fieldAdditionalData.customStyleAdditionalDataField;
  }

  if (!isEmpty(formData?.[`__${fieldName}_additionaldata`] as string)) {
    const fieldAdditionalData = getFieldAdditionalData(
      formData?.[`__${fieldName}_additionaldata`] as string,
    );
    field.customOption.additionalDataFieldAlt =
      fieldAdditionalData.additionalDataFieldAlt;

    field.customOption.customStyleAdditionalDataField =
      fieldAdditionalData.customStyleAdditionalDataField;
  }

  const inputProps = {
    value,
    formActionsHandler,
    inputMessage,
    resource: currentResourceRef.current.value,
    resourceType: currentResourceRef.current.type,
    field,
    label: !noLabel ? label ?? translatedCaption?.[locale] ?? caption : '',
    hint: lodashGet(translatedComment, locale, ''),
    inputRef,
    getRef,
    visibleClass: prepareVisibleClass(),
    inputContainerClass: inputContainerClass,
    disabled: isDisabled,
    isServiceMode,
    isProfile,
    inputInPuzzleForm,
  };

  if (field.showCurrentDate && !isInputClearedOnce.current) {
    inputProps['defaultValue'] = moment();
  }

  const customTestAttribute = {
    'data-test-field-name': field.name || '',
    'data-test-value': value,
    'data-test-quick-button-icon': 'checkbox',
    'data-test-max-value': field.maxValue,
    'data-test-min-value': field.minValue,
    'data-test-max-length': field.maxLength ? field.maxLength : 'dosent_matter',
    'data-test-field-type': fieldType,
    'data-test-field-hidden': field.hidden ? field.hidden : null,
    'data-style-dynamic-input': fieldType,
  };

  return (
    <DynamicInputView
      inputProps={inputProps}
      customTestAttribute={customTestAttribute}
      fieldType={fieldType}
      disableDropdownSearchPopup={props.disableDropdownSearchPopup}
      isCreateMode={isCreateMode}
    />
  );
};

const mapStateToInputProps = (state: StateType, props) => {
  const { metaData } = actorGetActionValue('formGlobalProps')!;

  const extraProps: ExtraProps = {
    globalParameters: lodashGet(state, 'profile.globalParameters', {}),
    dropdownState: [],
    viewVersion: state.admin.ui.viewVersion,
  };
  const { field } = props;

  if (
    field.uiVisible &&
    field.uiVisible.length &&
    field.uiVisible[0].length === 3 &&
    field.uiVisible[0][ui.column] !== ''
  ) {
    extraProps.dropdownState = getDropDownListFromState(
      field,
      'uiVisible',
      state,
      metaData,
    );
  } else if (
    field.uiEnable &&
    field.uiEnable.length &&
    field.uiEnable[0].length === 3 &&
    field.uiEnable[0][ui.column] !== ''
  ) {
    extraProps.dropdownState = getDropDownListFromState(
      field,
      'uiEnable',
      state,
      metaData,
    );
  }

  return extraProps;
};

export const DynamicInput = connect(
  mapStateToInputProps,
  null,
)(DynamicInputController);
