import { FC } from 'react';
import { refreshView as refreshViewAction } from 'react-admin';
import { useDispatch } from 'react-redux';

import NoteRelationView from './note-relation.view';
import { actorDispatch } from '../../../type/actor-setup';
import { apiRequestResultHandler } from '../../../helper/crud-api.helper';
import { requestListData } from '../../list/list.helper';

import type { NoteRelationControllerInterface } from './note-relation.type';

const NoteRelationController: FC<NoteRelationControllerInterface> = props => {
  const { relationPanelBaseProps } = props;
  const {
    relationMetaData,
    relationResource,
    parentFieldName,
    childFieldName,
    relationData,
    relationType,
    parentInfo,
  } = relationPanelBaseProps;
  const { parentResource, parentId } = parentInfo;

  const reduxDispatch = useDispatch();

  /**
   * save note
   * @function onSubmitNote
   * @returns {void} void
   */
  const onSubmitNote = (): void => {
    actorDispatch('isNoteCreateEditDialogOpen', false);
    requestListData(
      relationResource,
      relationType,
      parentId,
      childFieldName,
      parentFieldName,
      relationMetaData,
      undefined, // no custom request parameters
      apiRequestResultHandler,
    );
  };

  /**
   * refresh parent record
   * @function refreshView
   * @returns {void} void
   */
  const refreshView = (): void => {
    // todo: change reduxDispatch to actor dispatch when main grid has been refactored
    reduxDispatch(refreshViewAction());
  };

  return (
    <div>
      <NoteRelationView
        relationResource={relationResource}
        relationMetaData={relationMetaData}
        parentFieldName={parentFieldName}
        parentResource={parentResource}
        childFieldName={childFieldName}
        onSubmitNote={onSubmitNote}
        parentRecordId={parentId}
        refreshView={refreshView}
        data={relationData}
      />
    </div>
  );
};

export default NoteRelationController;
