import { useState, type FC, useRef, ForwardedRef } from 'react';

import { convertFormDataToFilterFormat } from './range-picker.helper';
import RangePickerView from './range-picker.view';
import { isEmpty } from '../../helper/data-helper';

import type { RangePickerRefProperties } from './range-picker-input/range-picker-input.type';
import type {
  OnUnitSelect,
  RangePickerControllerProps,
  RangePickerFormMode,
  OnChangeFormMode,
  OnInputChange,
  PeriodsFormData,
  ErrorsObject,
} from './range-picker.type';

const RangePickerController: FC<RangePickerControllerProps> = props => {
  const { fieldName, onChange, defaultValue } = props;

  const {
    formMode: defaultFormMode = 'allPast',
    selectedNumbers: defaultSelectedNumbers,
    selectedUnits: defaultSelectedUnits,
  } = defaultValue ?? {};

  const [formMode, setFormMod] = useState<RangePickerFormMode>(defaultFormMode);
  const [errors, setErrors] = useState<ErrorsObject>({
    lastPeriod: false,
    nextPeriod: false,
  });

  const preparedDefaultSelectedNumbers: PeriodsFormData = {
    lastPeriod: '',
    nextPeriod: '',
  };

  // apply default value from prop to local form data
  if (defaultSelectedNumbers) {
    preparedDefaultSelectedNumbers[formMode] = defaultSelectedNumbers;
  }

  const preparedDefaultSelectedUnits: PeriodsFormData = {
    lastPeriod: 'day',
    nextPeriod: 'day',
  };

  // apply default value from prop to local form data
  if (defaultSelectedUnits) {
    preparedDefaultSelectedUnits[formMode] = defaultSelectedUnits;
  }

  const fromDateInputRef = useRef<RangePickerRefProperties>();
  const toDateInputRef = useRef<RangePickerRefProperties>();
  const fromRangeInputRef = useRef<RangePickerRefProperties>();
  const toRangeInputRef = useRef<RangePickerRefProperties>();
  const selectedNumbers = useRef<PeriodsFormData>(preparedDefaultSelectedNumbers);
  const selectedUnits = useRef<PeriodsFormData>(preparedDefaultSelectedUnits);

  /**
   * set form mode to form data
   * @function onChangeFormMode
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void}
   */
  const onChangeFormMode: OnChangeFormMode = event => {
    setErrors({ lastPeriod: false, nextPeriod: false });
    setFormMod((event.target as HTMLInputElement).value as RangePickerFormMode);
  };

  /**
   * set units value to form data
   * @function onUnitSelect
   * @param {RangeUnit} unit
   * @returns {void}
   */
  const onUnitSelect: OnUnitSelect = unit => {
    selectedUnits.current = {
      ...selectedUnits.current,
      [formMode]: unit,
    };
  };

  /**
   * set inputs value to form data
   * @function onInputChange
   * @param {ChangeEvent<HTMLInputElement>} event
   * @returns {void}
   */
  const onInputChange: OnInputChange = event => {
    if (formMode === 'lastPeriod') {
      setErrors(prevErrors => ({ ...prevErrors, lastPeriod: false }));
    }
    if (formMode === 'nextPeriod') {
      setErrors(prevErrors => ({ ...prevErrors, nextPeriod: false }));
    }

    selectedNumbers.current = {
      ...selectedNumbers.current,
      [formMode]: event.target.value,
    };
  };

  /**
   * call onChange function with filter format of form data
   * @function onApplyClick
   * @returns {void}
   */
  const onApplyClick = (): void => {
    if (formMode === 'lastPeriod' && isEmpty(selectedNumbers.current.lastPeriod)) {
      setErrors(prevErrors => ({ ...prevErrors, lastPeriod: true }));
    }
    if (formMode === 'nextPeriod' && isEmpty(selectedNumbers.current.nextPeriod)) {
      setErrors(prevErrors => ({ ...prevErrors, nextPeriod: true }));
    }

    const filter = convertFormDataToFilterFormat({
      fieldName,
      formMode,
      selectedNumbers,
      selectedUnits,
      fromDateInputRef,
      toDateInputRef,
      fromRangeInputRef,
      toRangeInputRef,
    });

    if (filter) onChange(filter);
  };

  return (
    <RangePickerView
      onUnitSelect={onUnitSelect}
      onChangeFormMode={onChangeFormMode}
      onInputChange={onInputChange}
      onApplyClick={onApplyClick}
      formMode={formMode}
      fromDateInputRef={fromDateInputRef as ForwardedRef<RangePickerRefProperties>}
      toDateInputRef={toDateInputRef as ForwardedRef<RangePickerRefProperties>}
      fromRangeInputRef={fromRangeInputRef as ForwardedRef<RangePickerRefProperties>}
      toRangeInputRef={toRangeInputRef as ForwardedRef<RangePickerRefProperties>}
      defaultValue={defaultValue}
      errors={errors}
    />
  );
};

export default RangePickerController;
