import { Box, Tooltip } from '@material-ui/core';
import PaletteIcon from '@material-ui/icons/Palette';
import type { FC } from 'react';

import { getColorById } from '../../helper/RowColorHelper';
import { actorGetActionValue } from '../../type/actor-setup';
import { ButtonMenu, MenuOptionInterface } from '../button-menu';
import type {
  TableColorFilterViewPropsInterface,
  TableRowColorInterface,
} from './table-color-filter.type';

export const ColorFilterComponent: FC<TableRowColorInterface> = props => {
  const { ColorCode, Name } = props;

  return (
    <Tooltip title={Name} placement="top">
      <Box
        height={12}
        width={12}
        component="span"
        borderRadius="50%"
        display="block"
        bgcolor={ColorCode}
      />
    </Tooltip>
  );
};

/**
 * @param tableRowColors
 * @param {TableRowColorInterface[]} tableRowColors
 * @returns {MenuOptionInterface[]} an array of TableRowColorInterface
 */
const createColorFilterOptions = (
  tableRowColors: TableRowColorInterface[],
): MenuOptionInterface[] => {
  const { translate } = actorGetActionValue('reactAdminHelpers')!;

  const options: MenuOptionInterface[] = [];
  for (const rowColor of tableRowColors) {
    const { ColorCode, Name } = rowColor;
    options.push({
      component: (
        <ColorFilterComponent ColorCode={getColorById(ColorCode)} Name={Name} />
      ),
      text: rowColor.Name,
      value: Number(rowColor.ColorCode),
    });
  }

  const translatedText = translate('general.nothing');
  options.unshift({
    component: <ColorFilterComponent ColorCode={null} Name={translatedText} />,
    text: translatedText,
    value: null,
  });

  return options;
};

export const TableColorFilterView: FC<
  TableColorFilterViewPropsInterface
> = props => {
  const { tableRowColors, tooltipText, defaultText, colorChangeHandler } = props;

  return (
    <ButtonMenu
      tooltipTitle={tooltipText}
      options={createColorFilterOptions(tableRowColors)}
      optionSelectionHandler={colorChangeHandler}
      defaultText={defaultText}
      initialDisplayComponent={<PaletteIcon />}
      showAnchorDown
    />
  );
};
