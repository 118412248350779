import { ReactElement } from 'react';
import { IconButton, Tooltip, Icon } from '@material-ui/core';

import { SearchPopupButtonViewProps } from './search-popup-button.type';
import { useStyles } from './search-popup-button.style';

const SearchPopupButtonView = (props: SearchPopupButtonViewProps): ReactElement => {
  const { label, ref, style, openSearchDialog, disabled, className } = props;

  const classes = useStyles();

  return (
    <>
      {label ? (
        <Tooltip title={label}>
          <IconButton
            color="primary"
            ref={ref}
            size="small"
            className={`${classes.IconButton} ${className}`}
            onClick={openSearchDialog}
            disabled={disabled}
            data-test-input-name="auto-complete-search-button"
            style={style}
          >
            <Icon fontSize="small">search</Icon>
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          color="primary"
          ref={ref}
          size="small"
          className={className}
          onClick={openSearchDialog}
          disabled={disabled}
          data-test-input-name="auto-complete-search-button"
          style={style}
        >
          <Icon fontSize="small">search</Icon>
        </IconButton>
      )}
    </>
  );
};

export default SearchPopupButtonView;
