import { useStyles } from './important-dashboard.style';

import type { FC } from 'react';
import type { DataDashboardsInterface } from './important-dashboard.type';

const ImportantDashboardView: FC<DataDashboardsInterface> = (props): JSX.Element => {
  const { title, id, showDashboard } = props;
  const classes = useStyles();

  return (
    <div className={classes.container} key={id}>
      <div className={classes.containerDashboard}>
        <div>
          <span onClick={showDashboard} className={classes.textMenu}>
            {title}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImportantDashboardView;
