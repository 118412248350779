import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import WbSunny from '@material-ui/icons/WbSunny';
import StarBorder from '@material-ui/icons/StarBorder';
import DateRange from '@material-ui/icons/DateRange';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import AddIcon from '@material-ui/icons/Add';

import TodoSidebarView from './todo-sidebar.view';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorRemoveAction,
  FormKeyMode,
} from '../../../type/actor-setup';
import { showNotification } from '../../../helper/general-function-helper';
import { ExtraParamsInterface, FormActions } from '../../form';
import {
  deleteFolderServiceId,
  FolderName,
  newFolderId,
  shareFolderServiceId,
} from '../../mail-section/mail-section.helper';
import {
  getTodoFolders,
  updateFolderInfo,
  generateTodoFolders,
} from './todo-sidebar.helper';
import { OnDeleteFolderParams } from '../../mail-section';

import type { RoutesConfigInterface } from '../../app-drawer/navigation-container';
import type { TodoSidebarActionHandlerInterface } from './todo-sidebar.type';

const TodoSidebarController: FC = memo(() => {
  const translate = useTranslate();

  const [userCreatedFolders, setUserCreatedFolders] = useState<
    RoutesConfigInterface[]
  >([]);
  const [isNewFolderFormOpen, setIsNewFolderFormOpen] = useState<boolean>(false);
  const [isShareFormOpen, setIsShareFormOpen] = useState<boolean>(false);
  const selectedFolderId = useRef<null | number>(null);

  const onDispatchRefs = useRef<{ [name: string]: symbol }>({});

  useEffect(() => {
    onDispatchRefs.current['todoFolders'] = actorOnDispatch(
      'todoFolders',
      todoFolders => {
        if (!Array.isArray(todoFolders)) return;

        const userNewFolders = todoFolders.filter(folder => folder.folders_id > 0);
        setUserCreatedFolders(
          generateTodoFolders(userNewFolders, todoSideBarActionsHandler),
        );
      },
    );

    onDispatchRefs.current['dragDropAction'] = actorOnDispatch(
      'dragDropAction',
      data => {
        if (data?.parentComponentName === 'todo') {
          if (data.draggedItem.folderId != data.droppedItem.folderId) {
            updateFolderInfo(
              {
                Folders_ID: data.draggedItem.folderId as number,
                ParentFolders_ID: (data.droppedItem.folderId as number) ?? null,
              },
              refreshTodoFolders,
            );
          }
        }
      },
    );

    refreshTodoFolders();

    return () => {
      Object.keys(onDispatchRefs.current).forEach(key => {
        actorRemoveAction({
          actionName: key as keyof ActorActionList,
          listenerId: onDispatchRefs.current[key],
        });
      });
    };
  }, []);

  /**
   * @function refreshTodoFolders
   * @returns { void }
   */
  const refreshTodoFolders = (): void => {
    getTodoFolders();
  };

  /**
   * @function toggleNewFolderForm
   * @returns { void }
   */
  const toggleNewFolderForm = (): void => {
    setIsNewFolderFormOpen(prev => !prev);
  };

  /**
   * @function onCreateNewFolder
   * @returns { void }
   */
  const onCreateNewFolder = (): void => {
    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

    const currentFormData = actorGetActionValue(
      'formData',
      `automation/messages.${FormKeyMode.ROOT}`,
    );
    actorDispatch(
      'formData',
      { ...currentFormData, isfortask: true },
      {
        path: `automation/messages.${FormKeyMode.ROOT}`,
      },
    );
    formActionsHandler(FormActions.Save, {
      actionUniqueId: newFolderId,
      onSuccess: onSuccessCreateNewFolder,
    } as ExtraParamsInterface);
  };

  /**
   * @function toggleNewFolderForm
   * @param {number} folderId
   * @returns { void }
   */
  const toggleShareForm = (folderId: number | null): void => {
    selectedFolderId.current = folderId;
    setIsShareFormOpen(prev => !prev);
  };

  /**
   * @function handleCloseShareForm
   * @returns { void }
   */
  const handleCloseShareForm = (): void => {
    toggleShareForm(null);
  };

  /**
   * @function onSuccessCreateNewFolder
   * @returns { void }
   */
  const onSuccessShareFolder = (): void => {
    actorRemoveAction({
      actionName: 'formData',
      path: `automation/messages.${FormKeyMode.ROOT}`,
    });
    toggleShareForm(null);
    refreshTodoFolders();
  };

  /**
   * @function onCreateNewFolder
   * @returns { void }
   */
  const handleShareFolder = (): void => {
    const { formActionsHandler } = actorGetActionValue('formGlobalProps')!;

    const currentFormData = actorGetActionValue(
      'formData',
      `automation/messages.${FormKeyMode.ROOT}`,
    );

    actorDispatch(
      'formData',
      { ...currentFormData, Folders_ID: selectedFolderId.current },
      {
        path: `automation/messages.${FormKeyMode.ROOT}`,
      },
    );
    formActionsHandler(FormActions.Save, {
      actionUniqueId: shareFolderServiceId,
      onSuccess: onSuccessShareFolder,
    } as ExtraParamsInterface);
  };

  /**
   * @function onSuccessCreateNewFolder
   * @returns { void } void
   */
  const onSuccessCreateNewFolder = (): void => {
    actorRemoveAction({
      actionName: 'formData',
      path: `automation/messages.${FormKeyMode.ROOT}`,
    });
    refreshTodoFolders();
    toggleNewFolderForm();
  };

  /**
   * @function onDeleteFolderHandler
   * @param { number } Folders_ID
   * @param { Function } successCallback
   * @returns { void } void
   */
  const deleteFolderHandler = (params: OnDeleteFolderParams): void => {
    const { Folders_ID, successCallback } = params;

    actorDispatch('crudAction', {
      type: 'RUN_SERVICE',
      data: {
        params: { Folders_ID },
      },
      actionUniqueId: deleteFolderServiceId,
      onSuccess: res => {
        successCallback(res);
      },
      onFailure: (error: unknown): void => showNotification(error, 'error'),
    });
  };

  /**
   * @const todoSideBarActionsHandler
   * created an object from functions that need to pass the child component
   */
  const todoSideBarActionsHandler: TodoSidebarActionHandlerInterface = {
    deleteFolder: deleteFolderHandler,
    refreshTodoFolders: refreshTodoFolders,
    toggleShareForm: toggleShareForm,
  };

  /**
   * @const todoSidebarRoutesConfig
   * create sidebar menu for todo
   */
  const todoSidebarRoutesConfig: RoutesConfigInterface[] = [
    {
      id: 'folders',
      title: `${translate('todo.tasks')}`,
      type: 'collapse',
      dataTest: 'static-folders',
      children: [
        {
          id: 'inbox',
          title: `${translate('todo.IsAddedToMyDay')}`,
          type: 'item',
          icon: <WbSunny />,
          url: `/oa?module=todo&${FolderName}=isAddedToMyDay`,
          dataTest: 'static-folder-inbox',
        },
        {
          id: 'inbox',
          title: `${translate('todo.IsImportant')}`,
          type: 'item',
          icon: <StarBorder />,
          url: `/oa?module=todo&${FolderName}=isImportant`,
          dataTest: 'static-folder-inbox',
        },
        {
          id: 'inbox',
          title: `${translate('todo.DueDate')}`,
          type: 'item',
          icon: <DateRange />,
          url: `/oa?module=todo&${FolderName}=dueDate`,
          dataTest: 'static-folder-inbox',
        },
        {
          id: 'inbox',
          title: `${translate('todo.assignedtome')}`,
          type: 'item',
          icon: <AssignmentInd />,
          url: `/oa?module=todo&${FolderName}=assignedToMe`,
          dataTest: 'static-folder-inbox',
        },

        {
          id: 'create-new-folder',
          title: translate('todo.newList'),
          type: 'item',
          onClick: toggleNewFolderForm,
          icon: <AddIcon />,
          url: '#',
          dataTest: 'static-folder-drafts',
        },
        ...userCreatedFolders,
      ],
    },
  ];

  return (
    <TodoSidebarView
      todoSidebarRoutesConfig={todoSidebarRoutesConfig}
      isNewFolderFormOpen={isNewFolderFormOpen}
      isShareFormOpen={isShareFormOpen}
      handleCloseNewFolderForm={toggleNewFolderForm}
      handleCloseShareForm={handleCloseShareForm}
      onCreateNewFolder={onCreateNewFolder}
      handleShareFolder={handleShareFolder}
    />
  );
});

export default TodoSidebarController;
