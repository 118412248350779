import { ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import { Icon, IconButton, Tooltip } from '@material-ui/core';

import LoadingBox from '../../LoadingBox';
import { useStyles } from './wms-button-toolbar.style';

import type { WMSButtonToolbarViewProps } from './wms-button-toolbar.type';

const WMSButtonToolbarView = (props: WMSButtonToolbarViewProps): ReactElement => {
  const {
    onDeleteClick,
    onSaveClick,
    isDeleteAvailable,
    saveMainButtonRef,
    deleteButtonRef,
    saveActionId,
    isLoading,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {isLoading && <LoadingBox size={25} />}

      {isDeleteAvailable && (
        <Tooltip title={translate('ra.action.delete')}>
          <IconButton
            className={classes.iconButton}
            onClick={onDeleteClick}
            disabled={isLoading}
            ref={deleteButtonRef}
            data-test-delete-button
          >
            <Icon>delete</Icon>
          </IconButton>
        </Tooltip>
      )}

      {saveActionId && (
        <Tooltip title={translate('ra.action.save')}>
          <IconButton
            className={classes.iconButton}
            onClick={onSaveClick}
            disabled={isLoading}
            ref={saveMainButtonRef}
            data-test-save-button
          >
            <Icon>save</Icon>
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default WMSButtonToolbarView;
