import { useTranslate } from 'react-admin';
import {
  Button,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContent,
  Avatar,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useStyles } from './todo-share-dialog.style';

import type { ReactElement } from 'react';
import type { TodoShareDialogPropsViewInterface } from './todo-share-dialog.type';
import LoadingBox from '../../LoadingBox';
import { replaceArabicCharacters } from '../../../helper/TextHelper';

const TodoShareDialogView = (
  props: TodoShareDialogPropsViewInterface,
): ReactElement => {
  const {
    closeDialogHandler,
    handleShare,
    handleChange,
    userOptions,
    sharingList,
    getAvatarURL,
    loading,
    deletePersonFromShareList,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.dialogMainContainer} data-test="shareDialog">
      <DialogTitle>{translate('todo.share')}</DialogTitle>
      {loading ? (
        <LoadingBox />
      ) : (
        sharingList?.map(item => (
          <div className={classes.personCommon}>
            <Avatar src={getAvatarURL(item.personprofile)} />

            <p className={classes.personName}>{item.fullname}</p>
            <div>
              <span>
                <HighlightOffIcon
                  fontSize="small"
                  className={classes.iconDeleteSharePerson}
                  onClick={deletePersonFromShareList(item.personinfo_id)}
                />
              </span>
            </div>
          </div>
        ))
      )}
      <DialogContent>
        <Autocomplete
          multiple
          options={userOptions}
          getOptionLabel={option => replaceArabicCharacters(option.fullname)}
          onChange={handleChange}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              autoFocus
              margin="dense"
              fullWidth
              variant="standard"
            />
          )}
        />
      </DialogContent>

      <DialogActions className={classes.dialogActionsContainer}>
        <Button
          onClick={closeDialogHandler}
          className={classes.cancelBtn}
          variant="outlined"
        >
          {translate('confirm.cancel')}
        </Button>
        <Button
          onClick={handleShare}
          className={classes.confirmButton}
          color="secondary"
          variant="contained"
        >
          {translate('mail.send')}
        </Button>
      </DialogActions>
    </div>
  );
};

export default TodoShareDialogView;
