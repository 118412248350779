import type { ChatUsersInterface } from '../../chat-dialogs/add-members-dialog/add-members-dialog.type';
import type {
  ChatDetailInterface,
  MessageItemInterface,
  MessagesDetailInterface,
} from '../../chat-section.type';
import type { SelectedMessageInterface } from '../../new-message';
import type {
  ScrollBoundaryInterface,
  MessagesListStateInterface,
} from '../messages-list.type';

const setShowScrollButton = (
  state: MessagesListStateInterface,
  show: boolean,
): MessagesListStateInterface => {
  return {
    ...state,
    showScrollDownBtn: show,
  };
};

const setSelectedMessage = (
  state: MessagesListStateInterface,
  selectedMessage: SelectedMessageInterface | null,
): MessagesListStateInterface => {
  return {
    ...state,
    selectedMessage,
  };
};

const setUpdatedUserMessages = (
  state: MessagesListStateInterface,
  messagesDetail: MessagesDetailInterface,
): MessagesListStateInterface => {
  return {
    ...state,
    updatedMessagesDetail: {
      data: [...messagesDetail.data],
      hasMore: messagesDetail.hasMore,
    },
  };
};

// const onMessageSelect = (
//   state: MessagesListStateInterface,
//   selectedMessage: SelectedMessageInterface | null,
// ): MessagesListStateInterface => {
//   if (selectedMessage == null) {
//     return setSelectedMessage(state, null);
//   }

//   if (selectedMessage.mode === 'edit') {
//     // FIXME: We have to update UI after server response? we have some delays, how we can fix it?
//     chatStatelessActions.sendContent({
//       senderId: selectedMessage.data.frompersoninfo_id,
//       content: {
//         chattext: selectedMessage.data.chattext,
//       },
//     });

//     const targetMessageInUserMessages = state.updatedMessagesDetail?.data.find(
//       message => message.chat_id === selectedMessage.data.chat_id,
//     );
//     if (targetMessageInUserMessages) {
//       targetMessageInUserMessages.isdeleted = true;
//     }
//   } else if (selectedMessage.mode === 'reply') {
//     // TODO:
//   }

//   return setSelectedMessage(state, selectedMessage);
// };

const setLoading = (
  state: MessagesListStateInterface,
  loading: boolean,
): MessagesListStateInterface => {
  return {
    ...state,
    loading,
  };
};

const setSelectedChat = (
  state: MessagesListStateInterface,
  chatData: ChatDetailInterface | null,
): MessagesListStateInterface => {
  let _chatData: Partial<ChatDetailInterface> | null = {};
  let _newMessagesDetail: MessagesDetailInterface = {
    data: [],
    hasMore: false,
  };

  if (!chatData) {
    _chatData = null;
  } else {
    _chatData.info = { ...chatData.info };
    _chatData.messagesDetail = _newMessagesDetail;

    if (chatData.messagesDetail) {
      const { data, hasMore } = chatData.messagesDetail;
      _newMessagesDetail = {
        data: [...data],
        hasMore,
      };
      _chatData.messagesDetail = _newMessagesDetail;
    }
  }

  return {
    ...state,
    chatData: _chatData as ChatDetailInterface | null,
    updatedMessagesDetail: _newMessagesDetail,
  };
};

/**
 * @function reachBottomOfScroll
 * @returns { void }
 */
const scrollToBottomOfMessages = (
  state: MessagesListStateInterface,
): MessagesListStateInterface => {
  if (state.scrollBoundaryReached.bottom)
    return {
      ...state,
      showScrollDownBtn: false,
    };

  // fetchMoreMessages();

  return {
    ...state,
    showScrollDownBtn: false,
    scrollBoundaryReached: {
      bottom: true,
      top: false,
    },
  };
};

/**
 * @function scrollToTopOfMessages
 * @returns { void }
 */
const scrollToTopOfMessages = (
  state: MessagesListStateInterface,
): MessagesListStateInterface => {
  if (!state.chatData?.messagesDetail?.data) {
    return {
      ...state,
      scrollBoundaryReached: {
        bottom: false,
        top: true,
      },
    };
  }

  // fetchMoreMessages({});

  return { ...state };
};

const setScrollBoundary = (
  state: MessagesListStateInterface,
  scrollBoundary: ScrollBoundaryInterface,
): MessagesListStateInterface => {
  return {
    ...state,
    scrollBoundaryReached: scrollBoundary,
  };
};

const setIsUserMentioned = (
  state: MessagesListStateInterface,
  isUserMentioned: boolean,
): MessagesListStateInterface => {
  return {
    ...state,
    isUserMentioned,
  };
};

const setGroupUsers = (
  state: MessagesListStateInterface,
  groupUsers: ChatUsersInterface[],
): MessagesListStateInterface => {
  return {
    ...state,
    groupUsers: [...groupUsers],
  };
};

const setPinMessages = (
  state: MessagesListStateInterface,
  pinMessages: MessageItemInterface[],
): MessagesListStateInterface => {
  return {
    ...state,
    pinMessages: [...pinMessages],
  };
};

const setNewPinMessage = (
  state: MessagesListStateInterface,
  newPinMessage: MessageItemInterface,
): MessagesListStateInterface => {
  const _pinMessages = [...state.pinMessages];

  if (newPinMessage.ispin) {
    _pinMessages.push(newPinMessage);
  } else {
    const targetIndex = _pinMessages.findIndex(
      message => message.chat_id === newPinMessage.chat_id,
    );
    if (targetIndex > -1) {
      _pinMessages.splice(targetIndex, 1);
    }
  }

  // prettier-ignore
  const targetIndexInMessageList = state.updatedMessagesDetail.data.findIndex(message => message.chat_id === newPinMessage.chat_id);
  if (targetIndexInMessageList > -1) {
    // prettier-ignore
    state.updatedMessagesDetail.data[targetIndexInMessageList].ispin = newPinMessage.ispin;
  }

  return {
    ...state,
    updatedMessagesDetail: {
      data: [...state.updatedMessagesDetail.data],
      hasMore: state.updatedMessagesDetail.hasMore,
    },
    pinMessages: [..._pinMessages],
  };
};

const setSumNotSeen = (
  state: MessagesListStateInterface,
  count: number,
): MessagesListStateInterface => {
  return {
    ...state,
    sumNotSeen: count,
  };
};

// const sendMessage = <
//   T extends keyof ChatMessageContentInterface = keyof ChatMessageContentInterface,
// >(
//   state: MessagesListStateInterface,
//   params: MessageSendManagerParamInterface<T>,
// ): MessagesListStateInterface => {
//   // const updateMessages = messageSendManager<T>(params);

//   // messageSendManager({
//   //   type:
//   // });

//   return {
//     ...state,
//     updatedMessagesDetail: {
//       ...state.updatedMessagesDetail,
//       data: [],
//     },
//   };
// };

export const messagesListStatefulActions = {
  setSelectedMessage,
  setUpdatedUserMessages,
  setShowScrollButton,
  setLoading,
  setSelectedChat,
  scrollToBottomOfMessages,
  scrollToTopOfMessages,
  setScrollBoundary,
  setIsUserMentioned,
  setGroupUsers,
  setPinMessages,
  setNewPinMessage,
  setSumNotSeen,
};
