import {
  ChangeEvent,
  FocusEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';

import { resourceForNote } from '../../../relation-panel/note-relation/create-edit-note/constants';
import { showNotificationForUnknownError } from '../../../../helper/general-function-helper';
import { isCtrlEnterPressed } from '../../../../helper/FormHelper';
import { actorDispatch } from '../../../../type/actor-setup';
import { parseJSON } from '../../../../core/configProvider';
import TodoItemNoteView from './todo-item-note.view';

import type { NoteItemTypeController } from './todo-item-note.type';

const TodoItemNoteController = (props: NoteItemTypeController): ReactElement => {
  const { noteData, todoSelectedTaskInfo, getNote } = props;
  const translate = useTranslate();

  const [editValue, setEditValue] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const refTextArea = useRef<HTMLTextAreaElement | null>(null);

  /**
   *  get json note and convert to string
   * @function parseJSONForNote
   * @param {string} str
   * @returns {string | null} string | null
   */
  const parseJSONForNote = (str: string): string | null => {
    const parsedNote = parseJSON<{ NoteText?: string; text: string }>(str);
    if (parsedNote == null) {
      console.warn('parsedJSONNote: parsedNote is null');
      return '';
    }
    return parsedNote.NoteText ?? parsedNote.text ?? '';
  };

  useEffect(() => {
    if (noteData.notejson === null) {
      setEditValue(noteData.note);
    } else {
      setEditValue(parseJSONForNote(noteData.notejson as string) ?? '');
    }
  }, [todoSelectedTaskInfo, getNote]);

  const noteDataParams = {
    IsPin: 0,
    tableid: 310,
    KeyID: todoSelectedTaskInfo?.tasksdetails_id,
    NoteText: String(editValue),
  };

  /**
   * @function successEditTextItem
   * @returns { void } void
   */
  const successEditTextItem = (): void => {
    setIsEditMode(false);
    getNote();
  };

  useEffect(() => {
    refTextArea.current?.focus();
  }, [isEditMode]);

  /**
   * it moves the cursor to last possible position
   * @function moveCursorToEndOfTextArea
   * @param { FocusEvent<HTMLTextAreaElement> } event
   * @returns { void } void
   */
  const moveCursorToEndOfTextArea = (
    event: FocusEvent<HTMLTextAreaElement>,
  ): void => {
    const lastPosition = event.currentTarget.value.length;
    event.currentTarget.setSelectionRange(lastPosition, lastPosition);
  };

  /**
   * get data result by request and set data to state
   * @function onEditTextItemNoteTodo
   * @param { number } id
   * @returns { void } void
   */
  const onEditTextItemNoteTodo = (id: number) => (): void => {
    actorDispatch('crudAction', {
      type: 'RUN_SERVICE',
      data: {
        params: { ...noteDataParams, NoteID: id },
      },
      actionUniqueId: resourceForNote,
      onSuccess: successEditTextItem,
      onFailure: (error: unknown) => {
        showNotificationForUnknownError(error, translate, true);
      },
    });
  };

  /**
   * change value when textfield is edit
   * @function onChangeEdit
   * @param { ChangeEvent<HTMLTextAreaElement> } event
   * @returns { void } void
   */
  const onChangeEdit = (event: ChangeEvent<HTMLTextAreaElement>): void =>
    setEditValue(event.target.value);

  /**
   * delete item note todo
   * @function deleteNote
   * @param { number } id
   * @returns { void } void
   */
  const deleteNote = (id: number) => (): void => {
    actorDispatch('crudAction', {
      type: 'RUN_SERVICE',
      data: {
        params: { ...noteDataParams, isdeleted: 1, NoteID: id },
      },
      actionUniqueId: resourceForNote,
      onSuccess: getNote,
      onFailure: (error: unknown) => {
        showNotificationForUnknownError(error, translate);
      },
    });
  };

  /**
   * for handle ctrl + enter
   * @function onNewNoteKeyPress
   * @param { React.KeyboardEvent<HTMLTextAreaElement> } event
   * @param { number } id
   * @returns { void } void
   */
  const onNewNoteKeyPress =
    (id: number) =>
    (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
      if (editValue && isCtrlEnterPressed(event)) {
        event.preventDefault();
        onEditTextItemNoteTodo(id)();
      }
    };

  /**
   * for handle ctrl + enter
   * @function handleBlur
   * @param { FocusEvent<HTMLTextAreaElement> } event
   * @param { number } id
   * @returns { void } void
   */
  const handleBlur =
    (id: number) =>
    (_event: FocusEvent<HTMLTextAreaElement>): void => {
      if (editValue) {
        onEditTextItemNoteTodo(id)();
      }
    };

  /**
   * @function toggleEditMode
   * @returns { void } void
   */
  const toggleEditMode = (): void => {
    setIsEditMode(prev => !prev);
  };

  return (
    <TodoItemNoteView
      onNewNoteKeyPress={onNewNoteKeyPress}
      onChangeEdit={onChangeEdit}
      deleteNote={deleteNote}
      handleBlur={handleBlur}
      editValue={editValue}
      noteData={noteData}
      isEditMode={isEditMode}
      toggleEditMode={toggleEditMode}
      refTextArea={refTextArea}
      moveCursorToEndOfTextArea={moveCursorToEndOfTextArea}
    />
  );
};

export default TodoItemNoteController;
