import { API_URL, API_VERSION, getValue } from '../../core/configProvider';

export const getProfileImageUrl = (
  profileData?: Record<string, unknown>,
): string => {
  if (profileData?.globalParameters == null) {
    return '';
  }

  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);
  const random = Math.floor(Math.random() * 100);

  const globalParameters = profileData.globalParameters as Record<string, unknown>;

  return `${apiUrl}/${apiVersion}${
    globalParameters.currentCompanyLogo as string
  }?&dummyCounter=${random}`;
};
