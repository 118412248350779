import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  container: {},
  fieldsetError: {},

  form: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '7px',
    zIndex: 999, // Prevent to display on the `backdrop`s
    backgroundColor: theme.palette.primary.appSecondaryBackgroundColor,
    '& > fieldset': {
      flexBasis: '100%',
    },
  },

  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.appPrimaryBackgroundColor,
    alignSelf: 'center',
    marginTop: 5,
    marginBottom: 5,
    marginInlineEnd: 7,
    marginInlineStart: 7,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
