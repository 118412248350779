import { FC, MutableRefObject, useLayoutEffect } from 'react';
import { Button, makeStyles, Icon } from '@material-ui/core';

import { CustomTheme } from '../../core/themeProvider';
import { actorGetActionValue, actorSetActionValue } from '../../type/actor-setup';

type Variant = 'contained' | 'text' | 'outlined';

interface CustomFormButtonPropsInterface {
  id: string;
  onClick: (...args) => void;
  disabled: boolean;
  label: string;
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
  variant?: Variant;
  icon?: string;
}

const useStyles = makeStyles<CustomTheme>(theme => ({
  dumbButton: {
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      padding: '5px 0 !important',
    },
    '&.MuiButton-contained': {
      boxShadow: 'unset',
    },
  },

  saveIconButton: {
    margin: '0 5px',
  },
}));

const SaveIconButton = ({ className, icon }) => (
  <Icon className={className} fontSize="small">
    {icon}
  </Icon>
);

const CustomFormButton: FC<CustomFormButtonPropsInterface> = props => {
  const {
    id,
    onClick,
    disabled,
    variant = 'contained',
    label,
    icon = 'save',
    buttonRef,
  } = props;

  const currentResource = actorGetActionValue('resources')!.current;
  const classes = useStyles();

  useLayoutEffect(() => {
    actorSetActionValue(
      'quickCreateButtonToolbar',
      {
        [id]: buttonRef.current,
      },
      {
        path: [currentResource.value, currentResource.type],
      },
    );
  }, []);

  /**
   * Submit and redirect to specified page
   * @function handleClick
   * @returns {void}
   */
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (): void => {
    if (disabled) return;

    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <Button
      variant={variant}
      id={id}
      color="primary"
      onClick={handleClick}
      disabled={disabled}
      className={classes.dumbButton}
      ref={buttonRef}
      data-test-is-disabled={!!disabled}
    >
      <SaveIconButton className={classes.saveIconButton} icon={icon} />
      {label}
    </Button>
  );
};

export default CustomFormButton;
