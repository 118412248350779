import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  gaugeSettingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addButton: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '5px 0',
    padding: '5px 0',
    border: '1px solid ' + theme.palette.primary.appBorderInput,
    backgroundColor: theme.palette.primary.lightBlueBackgroundButton,
    color: theme.palette.primary.appLightBackgroundColor,
    cursor: 'pointer',
  },
  textInput: {
    height: '30px',
    width: '100%',
    borderWidth: 0,
    marginTop: '3px',
  },
  removeIcon: {
    margin: '0 10px',
  },
  deleteIcon: {
    cursor: 'pointer',
  },
}));
