import { useEffect, useState, type MouseEvent, type FC, useRef, memo } from 'react';
import { GET_LIST, useLocale } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

import DashboardCardView from './dashboard-card.view';
import LoadingBox from '../../loading-box';
import { addParamToUrl } from '../../../helper/UrlHelper';
import { prepareDynamicChartPropsForGadgets } from '../../dynamic-chart/dynamic-chart.helper';
import { getPreDesignedGadgets } from '../dashboard-card-form-dialog/dashboard-card-form-dialog.helper';
import { parseJSON } from '../../../core/configProvider';
import { getFilterFieldsFromGadgetValues } from '../../dynamic-input/gadget-input/gadget-design-dialog/setting-section/gadget-default-values-tab/gadget-default-values-tab.helper';
import { DASHBOARD_FILTER, NOT_CONFIRMED } from '../dashboard-page.helper';
import {
  FilterFormFieldInterface,
  actorDispatch,
  actorOnDispatch,
  actorRemoveAction,
} from '../../../type/actor-setup';

import type { GadgetInformation } from '../../dynamic-input/gadget-input';
import type { FieldType } from '../../../helper/Types';
import type { FinalFiltersType } from '../../filter-form';
import type {
  EditPropertyOfDashboardCardType,
  DashboardCardControllerProps,
} from './dashboard-card.type';
import {
  DynamicChartControllerProps,
  ChartType,
  MainCharts,
  HandleChartItemClickType,
} from '../../dynamic-chart';
import { isEmpty, isEmptyObject } from '../../../helper/data-helper';
import {
  FilterValueStructureEnum,
  customizeMergingRequestParameters,
} from '../../filter-form/filter-form.helper';
import { getDashboardFilterFromUrl } from './dashboard-card.helper';
import { getMetaDataFromActorOrNetwork } from '../../../helper/meta-helper';
import { getFilterColumns } from '../../../helper/MetaHelper';

/**
 * don't get report data if report type exist in the bellow list
 */
const ignoreGetReportChartTypeList: ChartType[] = [MainCharts.List];

const DashboardCardController: FC<DashboardCardControllerProps> = memo(
  props => {
    const {
      deleteDashboardCard,
      openEditDashboardCardDialog,
      dashboardCardData,
      editDashboardCard,
      dashboardGadgetsFilterStatus,
      isEditing,
    } = props;
    const { customFilters, isSortEnabled, sortField, sortOrder } = dashboardCardData;

    const [reportData, setReportData] = useState<Record<string, unknown>[] | null>(
      null,
    );
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [preDesignedGadget, setPreDesignedGadget] =
      useState<GadgetInformation | null>(null);
    const [showMore, setShowMore] = useState(false);
    const [hasGadgetsError, setHasGadgetsError] = useState(false);
    const reportResourceRef = useRef<string>('');
    const reportParameters = useRef<FieldType[]>([]);
    const locale = useLocale();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
      getPreDesignedGadgets(dashboardCardData.gadgetUniqueId)
        .then(_preDesignedGadgets => {
          const targetGadgetDesign = _preDesignedGadgets?.[0]?.gadgetdesign;

          if (targetGadgetDesign) {
            //prettier-ignore
            const parsedGadgetDesign =parseJSON<GadgetInformation>(targetGadgetDesign);
            //prettier-ignore

            reportResourceRef.current =
              `report/${parsedGadgetDesign?.reportAddress}` ?? '';

            setPreDesignedGadget(parsedGadgetDesign);
            setHasGadgetsError(false);
          } else {
            setHasGadgetsError(true);
          }
        })
        .catch(error => {
          setHasGadgetsError(true);
        });
    }, [dashboardCardData.gadgetUniqueId]);

    useEffect(() => {
      if (preDesignedGadget?.reportAddress) {
        getMetaDataFromActorOrNetwork(
          `report/${preDesignedGadget?.reportAddress}` ?? '',
        ).then(response => {
          const metaData =
            response[`report/${preDesignedGadget?.reportAddress}` ?? ''];

          reportParameters.current = getFilterColumns(metaData);
        });
      }
    }, [preDesignedGadget?.reportAddress]);

    /**
     * update gadget filters
     * @function onFiltersChange
     * @param {FinalFiltersType} newFilters
     * @returns {void} void
     */
    const onFiltersChange = (newFilters: FinalFiltersType): void => {
      if (!newFilters) return;

      editDashboardCard(dashboardCardData.id, {
        ...dashboardCardData,
        customFilters: newFilters,
        isFiltersConfirmed: true,
      });
    };

    useEffect(() => {
      let interval: NodeJS.Timer | null = null;

      if (
        dashboardCardData.autoRefreshReportData &&
        dashboardCardData.autoRefreshReportDataTime
      ) {
        interval = setInterval(() => {
          updateReportData();
        }, dashboardCardData.autoRefreshReportDataTime * 1000);
      }

      updateReportData();
      return () => {
        interval && clearInterval(interval);
      };
    }, [dashboardCardData.isFiltersConfirmed, preDesignedGadget]);

    useEffect(() => {
      const id = actorOnDispatch('urlInfo', updateReportIfFilterUrlChanged);
      return () => {
        actorRemoveAction({
          actionName: 'urlInfo',
          listenerId: id,
        });
      };
    }, []);

    /**
     * if filters thats sets in url exist in report parameter,we should send it in report request
     * @function updateReportIfFilterUrlChanged
     * @returns {void} void
     */
    const updateReportIfFilterUrlChanged = (): void => {
      const urlFilter = getDashboardFilterFromUrl();
      if (urlFilter && preDesignedGadget?.reportParameters) {
        let shouldReportUpdate = false;

        //search url filter in report parameter
        preDesignedGadget?.reportParameters?.forEach(parameter => {
          const isFilterExistInReportParameter = urlFilter.findIndex(
            filter =>
              filter[FilterValueStructureEnum.KEY] === parameter?.name ||
              filter[FilterValueStructureEnum.KEY] === parameter?.key,
          );
          if (isFilterExistInReportParameter > -1) {
            shouldReportUpdate = true;
          }
        });

        if (shouldReportUpdate) {
          updateReportData();
        }
      }
    };
    /**
     * get new report data
     * @function updateReportData
     * @returns {void} void
     */
    const updateReportData = (): void => {
      if (preDesignedGadget && dashboardCardData.isFiltersConfirmed) {
        //in list chart we dont need get data from api
        if (ignoreGetReportChartTypeList.includes(preDesignedGadget?.chartType)) {
          setReportData([]);
          return;
        }

        let preparedFilters =
          Array.isArray(customFilters) && customFilters.length > 0
            ? customFilters
            : preDesignedGadget.filterValues;

        const urlFilter = getDashboardFilterFromUrl();
        if (urlFilter) {
          //prettier-ignore
          preparedFilters = customizeMergingRequestParameters(preparedFilters, urlFilter) as FinalFiltersType;
        }

        actorDispatch(
          'crudAction',
          {
            entity: 'gadget',
            type: GET_LIST,
            resource: reportResourceRef.current,
            requestParameters: {
              pagination: {
                page: 1,
                perPage: 9999,
              },
              filter: preparedFilters,
            },
            onSuccess: reportData => {
              //if reportData unlike [GET_LIST]: {[entity]: {
              if (typeof reportData.data !== 'undefined') {
                setReportData(reportData.data ?? []);
              }
            },
            onFailure: (error: unknown) => {
              console.log('error: ', error);
              setReportData([]);
            },

            filter: preparedFilters,
          },
          { disableDebounce: true },
        );
      }
    };

    /**
     * delete card from dashboard
     * @function handleDelete
     * @returns {void} void
     */
    const handleDelete = (): void => {
      deleteDashboardCard(dashboardCardData.id);
    };

    /**
     * edit dashboard card
     * @function handleEdit
     * @returns {void} void
     */
    const handleEdit = (): void => {
      openEditDashboardCardDialog(
        dashboardCardData,
        preDesignedGadget?.valueFieldName, // we need this to fill the `sortField` default value
      );
    };

    /**
     * @function onChooseColor
     * @params {color} string
     * @returns {void} void
     */
    const onChooseColor = (color: string): void => {
      editDashboardCard(dashboardCardData.id, {
        ...dashboardCardData,
        color,
      });
    };

    /**
     * edit dashboard card and make it unconfirmed
     * @function goToFilterMode
     * @returns {void} void
     */
    const goToFilterMode = (): void => {
      closeOptionsMenu();
      editDashboardCard(dashboardCardData.id, {
        ...dashboardCardData,
        isFiltersConfirmed: false,
      });
    };

    /**
     * editPropertyOfDashboardCard :EditPropertyOfDashboardCardType
     */
    const editPropertyOfDashboardCard: EditPropertyOfDashboardCardType = (
      customProperty,
    ): void => {
      editDashboardCard(dashboardCardData.id, {
        ...dashboardCardData,
        ...customProperty,
      });
    };

    /**
     * Open options menu
     * @function openOptionsMenu
     * @param { MouseEvent<HTMLButtonElement> } event
     * @returns { void }
     */
    const openOptionsMenu = (event: MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    };

    /**
     * close option menu
     * @function closeOptionsMenu
     * @returns {void} void
     */
    const closeOptionsMenu = () => {
      setAnchorEl(null);
    };

    /**
     * on edit button click
     * @function onEditButtonClick
     * @returns {void} void
     */
    const onEditButtonClick = (): void => {
      closeOptionsMenu();
      handleEdit();
    };

    /**
     * on delete button click
     * @function onDeleteButtonClick
     * @returns {void} void
     */
    const onDeleteButtonClick = (): void => {
      closeOptionsMenu();
      handleDelete();
    };

    /**
     * add filter to url when user clicked on chart items
     * @function {HandleChartItemClickType} onDeleteButtonClick
     */
    const handleChartItemClick: HandleChartItemClickType = (
      value,
      fieldName,
      operator = 'equal',
    ) => {
      //if fieldName doesn't exist
      if (isEmpty(preDesignedGadget?.idFieldName) && isEmpty(fieldName)) return;

      let filterFieldKey = fieldName ?? preDesignedGadget?.idFieldName; //"fieldName" has more priority than "preDesignedGadget?.idFieldName"

      const filterFieldInParams = preDesignedGadget?.reportParameters?.filter(
        field => field.name == filterFieldKey || field.key == filterFieldKey,
      );
      if (Array.isArray(filterFieldInParams) && filterFieldInParams.length > 0) {
        filterFieldKey = (filterFieldInParams[0].key ??
          filterFieldInParams[0].name) as string;
      }

      const newFilter = [filterFieldKey, operator, value];

      const { pathname } = location;
      const currentFilter = getDashboardFilterFromUrl();
      //prettier-ignore
      const mergedFilter = customizeMergingRequestParameters(currentFilter, [newFilter]);
      const newPathAddress = addParamToUrl(
        pathname,
        DASHBOARD_FILTER,
        JSON.stringify(mergedFilter),
      );
      if (!isEmpty(newPathAddress)) history.push(newPathAddress);
    };

    let dynamicChartPreparedProps: DynamicChartControllerProps | null = null;
    let filterFields: FilterFormFieldInterface[] | null = null;
    if (!isEmptyObject(preDesignedGadget) && reportData) {
      dynamicChartPreparedProps = prepareDynamicChartPropsForGadgets({
        gadgetValues: preDesignedGadget,
        reportData,
        filterValues: customFilters,
        dashboardCardData,
        editPropertyOfDashboardCard,
        ...(!!isSortEnabled && { sortField, sortOrder }),
        handleChartItemClick,
        displayNumber: preDesignedGadget.displayNumber,
      });

      filterFields = getFilterFieldsFromGadgetValues(
        (reportParameters.current as FieldType[]) ?? [],
        locale,
        preDesignedGadget.filterValues ?? null,
        customFilters ?? null,
      );
    }

    const isFilterFormOpenInDashboard = Object.values(
      dashboardGadgetsFilterStatus,
    ).some(item => item === NOT_CONFIRMED);

    /**
     * handle show more
     * @function handleShowMore
     * @returns {void} void
     */
    const handleShowMore = (): void => {
      setShowMore(prev => !prev);
    };

    return (
      <DashboardCardView
        dashboardCardData={dashboardCardData}
        dynamicChartPreparedProps={dynamicChartPreparedProps}
        onChooseColor={onChooseColor}
        goToFilterMode={goToFilterMode}
        isFilterFormOpenInDashboard={isFilterFormOpenInDashboard}
        anchorEl={anchorEl}
        openOptionsMenu={openOptionsMenu}
        closeOptionsMenu={closeOptionsMenu}
        onEditButtonClick={onEditButtonClick}
        onDeleteButtonClick={onDeleteButtonClick}
        onFiltersChange={onFiltersChange}
        filterFields={filterFields}
        reportResource={reportResourceRef.current}
        showMore={showMore}
        handleShowMore={handleShowMore}
        isEditing={isEditing}
        hasGadgetsError={hasGadgetsError}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.dashboardCardData) ===
        JSON.stringify(nextProps.dashboardCardData) &&
      prevProps.dashboardGadgetsFilterStatus[prevProps.dashboardCardData.id] ===
        nextProps.dashboardGadgetsFilterStatus[nextProps.dashboardCardData.id] &&
      prevProps.isEditing === nextProps.isEditing
    );
  },
);

export default DashboardCardController;
