import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import type { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  attachmentContainer: {
    display: 'flex',
    marginTop: 10,
  },

  noteContainer: {
    padding: 5,
    borderTop: `1px solid ${grey[300]}`,
    wordBreak: 'break-word',
    background: theme.palette.primary.appSecondaryBackgroundColor,
    overflow: 'hidden',
  },

  noteContainerUser: {
    flexDirection: 'row',
    display: 'flex',
    wordBreak: 'break-word',
    background: theme.palette.primary.appSecondaryBackgroundColor,
  },

  noteContent: {
    marginLeft: '0.5em',
    flexGrow: 1,
  },

  noteContentHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '0.5em',
  },

  author: {
    fontSize: 13,
    fontWeight: 400,
    paddingBottom: '0.3em',
  },

  iconActionNote: {
    cursor: 'pointer',
    color: grey[500],
  },

  mainAction: {
    display: 'flex',
    alignItems: 'center',
  },

  todoItemText: {
    fontSize: '0.5rem',
    color: theme.palette.todoLightGray,
  },

  changeStyle: {
    width: '100%',
    border: 'none',
    padding: 0,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 12,
    outline: 0,
    resize: 'none',
  },
  textNote: {
    whiteSpace: 'break-spaces',
  },
}));
