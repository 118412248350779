import { ChartType, MainCharts } from '../../../dynamic-chart';
import lodashGet from 'lodash/get';

import type { ColumnsInterface } from '../../../grid/grid.type';
import type { GadgetInformation } from '../gadget-input.type';
import type { GeneralMetaData } from '../../../../helper/Types';

export const getReportsDropdownId = 'df5e405b-9cfb-4004-a6b8-27de4c0a3e54';
export const announcementReportId = '63443850-5823-4A41-8A78-1E4D47EB0295';

export const defaultGadgetDialogValue: GadgetInformation = {
  reportAddress: null,
  chartType: MainCharts.Pie,
};

export const fakeReportData = [
  {
    id: 'hack',
    label: 'hack',
    value: 171,
    color: 'hsl(41, 70%, 50%)',
  },
  {
    id: 'rust',
    label: 'rust',
    value: 154,
    color: 'hsl(237, 70%, 50%)',
  },
  {
    id: 'haskell',
    label: 'haskell',
    value: 444,
    color: 'hsl(243, 70%, 50%)',
  },
  {
    id: 'php',
    label: 'php',
    value: 468,
  },
  {
    id: 'stylus',
    label: 'stylus',
    value: 469,
  },
];

/**
 * @function getReportColumns
 * @params { string } local
 * @params { undefined | GadgetInformation } metaData
 * @returns { ColumnsInterface[] } ColumnsInterface[]
 */
export const getReportColumns = (
  metaData: GeneralMetaData | undefined,
  locale: string,
): ColumnsInterface[] => {
  if (!Array.isArray(metaData?.columns)) return [];

  const preparedColumns: ColumnsInterface[] = [];
  metaData!.columns.forEach(field => {
    preparedColumns.push({
      name: field.relatedName,
      title: lodashGet(field, ['translatedCaption', locale], field.caption),
    });
  });
  return preparedColumns;
};

/**
 * @function chartNeededReport
 * @params { chartType } ChartType
 * @returns { boolean } boolean
 */
export const isChartNeededReportId = (chartType: ChartType): boolean => {
  const dontShowReportDropdownGadgetTypes = [
    MainCharts.Announcements,
    MainCharts.Iframe,
  ];

  return dontShowReportDropdownGadgetTypes.indexOf(chartType) === -1;
};
