import { Icon, IconButton, Tooltip, withStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { removeOnDispatches } from '../helper/general-function-helper';

import { setResource } from '../helper/resource-helper';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
  actorSetActionValue,
  FormKeyMode,
} from '../type/actor-setup';

const styles = theme => ({
  icon: {
    color: 'hsl(0, 0%, 60%)',
    margin: 4,
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 5,
    },
  },

  iconWithLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      margin: 5,
    },
  },

  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
});

const QuickCreateButton = props => {
  const {
    resource,
    data = {},
    dropdownId,
    source,
    mustRefresh = false,
    redirect,
    classes,
    label,
    disabled,
    onCreate,
    disabledFieldList,
    parentInfo = {}, // FIXME:
    dropdownMeta,
    additionalProps,
    relationMode,
    childFieldName,
    parentFieldName,
    id,
    ...rest
  } = props;

  const [isDisabled, setIsDisabled] = useState(disabled ?? false);

  useEffect(() => {
    const onDispatches = [];

    const listenerId = actorOnDispatch('loading', loadingRecord => {
      setIsDisabled(
        loadingRecord['service'] ||
          loadingRecord['processChangeLineButtons'] ||
          false,
      );
    });

    onDispatches.push({
      actionName: 'loading',
      listenerId,
    });

    return () => {
      removeOnDispatches(onDispatches);
    };
  }, []);

  /**
   * it should get dropdown default values then open dialog
   * @function fetchDropdownDefaultValuesAndOpenDialog
   * @returns {Promise<void>} Promise<void>
   */
  const fetchDropdownDefaultValuesAndOpenDialog = () => {
    if (relationMode) {
      setResource(resource, FormKeyMode.RELATION);
      actorSetActionValue('quickCreateSupplementaryData', data, {
        path: `${resource}.${FormKeyMode.RELATION}`,
        replaceAll: true,
      });
    } else {
      actorSetActionValue('quickCreateSupplementaryData', data, {
        path: `${resource}.${FormKeyMode.ROOT}`,
        replaceAll: true,
      });
    }

    actorDispatch(
      'quickDialog',
      {
        createIsOpen: true,
        onCloseDialogCallback: !mustRefresh ? refreshRelation : undefined,
        data: {
          resource,
          dropdownId,
          source,
          mustRefresh,
          redirect,
          onCreate,
          disabledFieldList,
          parentInfo,
          dropdownMeta,
          additionalProps,
          relationMode,
          childFieldName,
          parentFieldName,
          ...data,
        },
      },
      {
        replaceAll: true,
      },
    );
  };

  /**
   * it should check if dropdownMeta exist (it means it called from plus button in dropdown input) it should call a redux action
   * that will open QuickCreateDropdownDialog else call another action that will open main QuickCreateDialog.
   * @function openDialog
   * @returns {void}
   */
  const openDialog = () => {
    if (dropdownMeta) {
      setResource(resource, FormKeyMode.DROPDOWN);
      const formGlobalProps = actorGetActionValue('formGlobalProps');
      actorDispatch(
        'quickDialog',
        {
          dropdownIsOpen: true,
          data: {
            resource,
            dropdownId,
            source,
            mustRefresh,
            redirect,
            onCreate: formGlobalProps.dropdownCreateSuccess,
            disabledFieldList,
            parentInfo,
            dropdownMeta,
            childFieldName,
          },
        },
        {
          replaceAll: true,
        },
      );
    } else {
      fetchDropdownDefaultValuesAndOpenDialog();
    }
  };

  return label ? (
    <Tooltip title={label}>
      <IconButton
        {...rest}
        id={id || 'quickCreateButton'}
        color="primary"
        size="small"
        className={`${classes.IconButton} list-create-button`}
        onClick={openDialog}
        onTouchStart={openDialog}
        data-test-info={resource}
        disabled={isDisabled}
      >
        <Icon fontSize="small">add</Icon>
      </IconButton>
    </Tooltip>
  ) : (
    <Icon
      {...rest}
      id={id || 'quickCreateButton'}
      className={classes.icon}
      color="primary"
      fontSize="small"
      onClick={openDialog}
      onTouchStart={openDialog}
      disabled={isDisabled}
      data-test-info={resource}
    >
      add
    </Icon>
  );
};

export default withStyles(styles, { withTheme: true })(QuickCreateButton);
