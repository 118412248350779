import clsx from 'classnames';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import DisplayDate from '../../DisplayDate';
import { TodoNote } from '../todo-note';
import { AddInMyDayButton } from '../add-in-my-day-button';
import { TodoSteps } from '../todo-steps';
import { TodoDateOptions } from '../todo-date-options';
import { TodoLabel } from '../todo-label';
import { useStyles } from './task-action-side-bar.style';
import { TodoFile } from '../todo-file';

import type { ReactElement } from 'react';
import type { TodoActionSideBarThemeInterface } from './task-action-side-bar.type';
import AssignTaskDialogController from '../../dialogs-stack/assign-task-dialog/assign-task-dialog.controller';
import TodoAssignTaskController from '../todo-assign-task/todo-assign-task.controller';

const TodoActionSideBarView = (
  props: TodoActionSideBarThemeInterface,
): ReactElement => {
  const {
    isOpen,
    handleCloseSideBar,
    createDate,
    todoSelectedTaskInfo,
    handleDeleteTask,
  } = props;
  const classes = useStyles();

  return (
    <div
      data-test="action-test"
      className={clsx(classes.close, isOpen && classes.open)}
    >
      <div>
        <div data-test="head" className={classes.moduleContainer}>
          <TodoSteps />
        </div>

        <div className={classes.moduleContainer}>
          <AddInMyDayButton todoSelectedTaskInfo={todoSelectedTaskInfo} />
        </div>
        <div className={classes.moduleContainer}>
          <TodoAssignTaskController todoSelectedTaskInfo={todoSelectedTaskInfo!} />
        </div>
        <div data-test="todoDateOptions" className={classes.moduleContainer}>
          <TodoDateOptions />
        </div>

        <div className={classes.moduleContainer}>
          <TodoFile todoSelectedTaskInfo={todoSelectedTaskInfo} />
        </div>

        <div className={classes.moduleContainer}>
          <TodoNote todoSelectedTaskInfo={todoSelectedTaskInfo} />
        </div>
        <div className={classes.moduleContainer}>
          <TodoLabel />
        </div>
      </div>

      <div className={classes.bottomActionsContainer}>
        <div className={classes.contentList}>
          <div>
            <ExitToAppIcon
              onClick={handleCloseSideBar}
              className={classes.exitToAppIcon}
              fontSize="medium"
            />
          </div>
          <div>
            <DisplayDate value={createDate as string} />
          </div>
          <div>
            <DeleteOutlineIcon
              onClick={handleDeleteTask}
              className={classes.deleteOutlineIcon}
              fontSize="medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodoActionSideBarView;
