import { GET_LIST } from 'react-admin';
import {
  RequestParametersInterface,
  actorDispatch,
} from '../../../type/actor-setup';
import {
  permissionReasonUrl,
  showPermissionResources,
} from '../show-permissions.helper';

import type { PermissionTabId } from '../show-permissions.type';
import type { PermissionResponseType } from './dynamic-permission-data.type';
import { FinalFiltersType } from '../../filter-form';

/**
 * @function getPermissionTabData
 * @param { string } tabId
 * @param { (response: unknown[]) => void } onSuccess
 * @param { (error: string) => void } onFailure
 * @returns {void} void
 */
export const getPermissionTabData = (
  tabId: PermissionTabId,
  onSuccess: (response: PermissionResponseType[]) => void,
  onFailure: (error: string) => void,
  params: {
    filter?: FinalFiltersType;
  },
): void => {
  actorDispatch(
    'crudAction',
    {
      type: GET_LIST,
      entity: 'permissions',
      resource: `${(showPermissionResources[tabId] as string)?.toLowerCase()}`,
      requestParameters: {
        filter: params.filter,
        pagination: { page: 1, perPage: 999999 },
      },
      onSuccess: response => {
        onSuccess(response.data);
      },
      onFailure: error => {
        onFailure(error);
      },
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'getPermissionTabData',
    },
  );
};

/**
 * @function getPermissionReason
 * @param { string } tabIndex
 * @param { (response: T) => void } onSuccess
 * @param { (error: string) => void } onFailure
 * @returns {void} void
 */
export const getPermissionReason = <T extends unknown>(
  onSuccess: (response: T) => void,
  onFailure: (error: string) => void,
  params: {
    filter?: FinalFiltersType;
  },
): void => {
  actorDispatch(
    'crudAction',
    {
      type: GET_LIST,
      entity: 'permissions',
      resource: permissionReasonUrl?.toLowerCase(),
      requestParameters: {
        filter: params.filter,
        pagination: { page: 1, perPage: 999999 },
      },
      onSuccess: response => {
        onSuccess(response.data);
      },
      onFailure: error => {
        onFailure(error);
      },
    },
    {
      disableDebounce: true,
      replaceAll: true,
      callerScopeName: 'getPermissionReason',
    },
  );
};
