import AddNoteController from './add-note/add-note.controller';
import TodoItemNoteController from './todo-item-note/todo-item-note.controller';
import { useStyles } from './todo-note.style';

import type { TodoNoteView } from './todo-note.type';

const NoteStreamCardView = (props: TodoNoteView): JSX.Element => {
  const { getNote, notesData, todoSelectedTaskInfo } = props;
  const classes = useStyles();

  return (
    <div className={classes.noteTodoMain}>
      <AddNoteController
        getNote={getNote}
        todoSelectedTaskInfo={todoSelectedTaskInfo}
      />
      <div className={classes.noteItemInSidebar}>
        {notesData?.map(note => {
          return (
            <TodoItemNoteController
              getNote={getNote}
              todoSelectedTaskInfo={todoSelectedTaskInfo}
              noteData={note}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NoteStreamCardView;
