import { useLocale } from 'react-admin';
import { useEffect, useState, type FC, useMemo, useRef } from 'react';
import { useTranslate } from 'react-admin';

import DashboardCardFormDialogView from './dashboard-card-form-dialog.view';
import { showNotification } from '../../../helper/general-function-helper';
import { parseJSON } from '../../../core/configProvider';
import { isJsonEncodedString } from '../../../helper/data-helper';
import {
  getDashboardCardDefaultData,
  getGadgetDefaultSort,
  getPreDesignedGadgets,
} from './dashboard-card-form-dialog.helper';

import type { DashBoardFormDataInterface } from '../dashboard-page.type';
import type { GadgetInformation } from '../../dynamic-input/gadget-input';
import type { ColumnsInterface } from '../../grid/grid.type';
import type {
  DashboardCardFormDialogControllerPropsInterface,
  GadgetsDropdownResponseInterface,
} from './dashboard-card-form-dialog.type';
import type {
  CheckboxChangeEvent,
  TextFieldChangeEvent,
} from '../../dynamic-input/dynamic-input.type';
import { getReportColumns } from '../../dynamic-input/gadget-input/gadget-design-dialog/gadget-design-dialog.helper';
import { getMetaDataFromActorOrNetwork } from '../../../helper/meta-helper';

const DashboardCardFormDialogController: FC<
  DashboardCardFormDialogControllerPropsInterface
> = props => {
  const { closeDialogHandler, data } = props;
  const { editDashboardCard, createNewDashboardCard, dashboardCardData } = data;

  // states
  const [reportColumns, setReportColumns] = useState<ColumnsInterface[]>([]);
  const [preDesignedGadgets, setPreDesignedGadgets] = useState<
    GadgetsDropdownResponseInterface[]
  >([]);

  const gadgetInformation = useRef<GadgetInformation | null>(null);
  const dashboardDefaultData = getDashboardCardDefaultData(
    dashboardCardData,
    undefined,
    gadgetInformation.current?.sortObject,
  );
  const [formData, setFormData] =
    useState<DashBoardFormDataInterface>(dashboardDefaultData);

  const translate = useTranslate();
  const locale = useLocale();

  const isInAddMode = !dashboardCardData;

  useEffect(() => {
    const selectedGadgetUniqueId = formData.gadgetUniqueId;
    const selectedGadgetJsonInformation = preDesignedGadgets?.find(
      gadget => gadget.gadgetuid === selectedGadgetUniqueId,
    )?.gadgetdesign;

    if (
      selectedGadgetJsonInformation &&
      isJsonEncodedString(selectedGadgetJsonInformation)
    ) {
      gadgetInformation.current = parseJSON(
        selectedGadgetJsonInformation,
      ) as GadgetInformation;
    }

    const gadgetDefaultSort = getGadgetDefaultSort(
      gadgetInformation.current?.sortObject,
    );

    if (isInAddMode) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ...gadgetDefaultSort,
      }));
    } else {
      const { isSortEnabled, sortField, sortOrder, gadgetUniqueId } =
        dashboardCardData;

      if (formData.gadgetUniqueId === gadgetUniqueId) {
        // still in same gadget
        setFormData(prevFormData => ({
          ...prevFormData,
          isSortEnabled: isSortEnabled ?? gadgetDefaultSort?.isSortEnabled ?? false,
          sortField: sortField ?? gadgetDefaultSort?.sortField ?? '',
          sortOrder: sortOrder ?? gadgetDefaultSort?.sortOrder ?? 'desc',
        }));
      } else {
        // gadget changed
        setFormData(prevFormData => ({
          ...prevFormData,
          ...gadgetDefaultSort,
        }));
      }
    }

    if (gadgetInformation.current?.reportAddress) {
      getMetaDataFromActorOrNetwork(
        `report/${gadgetInformation.current?.reportAddress}` ?? '',
      ).then(response => {
        setReportColumns(
          getReportColumns(
            response[`report/${gadgetInformation.current?.reportAddress}` ?? ''],
            locale,
          ),
        );
      });
    }
  }, [preDesignedGadgets, formData.gadgetUniqueId, dashboardCardData]);

  useEffect(() => {
    getPreDesignedGadgets().then(_preDesignedGadgets => {
      setPreDesignedGadgets(_preDesignedGadgets);
    });
  }, []);

  /**
   * validate form data
   * @function isValidFormData
   * @returns {boolean} is valid
   */
  const isValidFormData = (): boolean => {
    if (!formData.gadgetUniqueId) {
      showNotification(translate('gadget.pleaseFillAllRequiredFields'), 'error', {
        fromQuickCreateDialog: true,
      });

      return false;
    }

    return true;
  };

  /**
   * handle `x | y | w | h` change
   * @function addLayoutToGrid
   * @returns {void} void
   */
  const addLayoutToGrid = (): void => {
    if (!isValidFormData()) return;

    if (createNewDashboardCard) {
      createNewDashboardCard(formData);
      closeDialogHandler();
    }
  };

  /**
   * replace new properties with old properties
   * @function editGridLayout
   * @returns {void} void
   */
  const editGridLayout = (): void => {
    if (!isValidFormData()) return;

    if (editDashboardCard && dashboardCardData?.id) {
      editDashboardCard(dashboardCardData.id, {
        ...formData,
        id: dashboardCardData.id,
      });
      closeDialogHandler();
    }
  };

  /**
   * handle onChanges
   * @function onDashboardFormDataChange
   * @param { keyof DashBoardFormDataInterface } key
   * @returns { (event: unknown): void } change handler
   */
  const onDashboardFormDataChange =
    (key: keyof DashBoardFormDataInterface) =>
    (event: unknown, value?: GadgetsDropdownResponseInterface): void => {
      switch (key) {
        case 'title': {
          const value = (event as TextFieldChangeEvent).target.value;
          setFormData(prevFormData => ({
            ...prevFormData,
            title: value,
          }));
          break;
        }

        case 'gadgetUniqueId': {
          const _value = value?.gadgetuid ?? '';

          setFormData(prevFormData => ({
            ...prevFormData,
            gadgetUniqueId: _value as string,
          }));
          break;
        }

        case 'autoRefreshReportData': {
          const checkBoxNAme = (event as CheckboxChangeEvent).target.name;
          if (checkBoxNAme === 'autoRefreshReportData') {
            setFormData(prevFormData => ({
              ...prevFormData,
              autoRefreshReportData: !prevFormData.autoRefreshReportData,
            }));
          }
          break;
        }

        case 'autoRefreshReportDataTime':
          {
            const value = (event as TextFieldChangeEvent).target.value;
            setFormData(prevFormData => ({
              ...prevFormData,
              autoRefreshReportDataTime: Number(value),
            }));
          }
          break;

        case 'isSortEnabled': {
          const checkBoxNAme = (event as CheckboxChangeEvent).target.name;
          if (checkBoxNAme === 'isSortEnabled') {
            setFormData(prevFormData => ({
              ...prevFormData,
              isSortEnabled: !prevFormData.isSortEnabled,
            }));
          }
          break;
        }

        case 'sortField': {
          setFormData(prevFormData => ({
            ...prevFormData,
            sortField: value?.name,
          }));
          break;
        }

        case 'sortOrder': {
          setFormData(prevFormData => ({
            ...prevFormData,
            sortOrder: prevFormData.sortOrder === 'asc' ? 'desc' : 'asc',
          }));
          break;
        }

        default:
          console.warn('unknown key changed !');
          break;
      }
    };

  /**
   * @function getGadgetListInputValue
   * @returns {GadgetsDropdownResponseInterface} GadgetsDropdownResponseInterface
   */
  const getGadgetListInputValue = ():
    | GadgetsDropdownResponseInterface
    | undefined => {
    return preDesignedGadgets?.find(
      item => item.gadgetuid == formData.gadgetUniqueId,
    );
  };

  return (
    <DashboardCardFormDialogView
      addLayoutToGrid={addLayoutToGrid}
      editGridLayout={editGridLayout}
      closeDialogHandler={closeDialogHandler}
      isInAddMode={isInAddMode}
      preDesignedGadgets={preDesignedGadgets}
      formData={formData}
      onDashboardFormDataChange={onDashboardFormDataChange}
      gadgetListInputValue={getGadgetListInputValue()}
      reportColumns={reportColumns}
    />
  );
};
export default DashboardCardFormDialogController;
