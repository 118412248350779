import { FC, memo, useEffect, useRef } from 'react';
import { MailActions } from '..';
import { OnSendNewMessageParams } from '..';
import { MailFormToolbarInterface } from './mail-form-toolbar.type';
import MailFormToolbarView from './mail-form-toolbar.view';
import {
  FormKeyMode,
  actorDispatch,
  actorGetActionValue,
} from '../../../type/actor-setup';
import { clone } from '../../../helper/data-helper';
import { isMailFormDataChanged } from '../mail-section.helper';

const MailFormToolbarController: FC<MailFormToolbarInterface> = memo(props => {
  const { mailActionsHandler, title, onClose, isFullScreen } = props;

  const { translate } = actorGetActionValue('reactAdminHelpers')!;
  const defaultMailFormDataRef = useRef<Record<string, unknown> | null>({});

  useEffect(() => {
    setTimeout(() => {
      defaultMailFormDataRef.current = clone(
        actorGetActionValue('formData', `automation/messages.${FormKeyMode.ROOT}`),
      );
    }, 500);
  }, []);

  /**
   * @function handleToggleFullScreen
   * @returns { void }
   */
  const handleToggleFullScreen = (): void => {
    mailActionsHandler && mailActionsHandler(MailActions.onToggleMailFormFullScreen);
  };

  /**
   * show confirm dialog when form data changed
   * @function handleClose
   * @returns { void }
   */
  const handleClose = (): void => {
    if (isMailFormDataChanged(defaultMailFormDataRef.current)) {
      actorDispatch('quickDialog', {
        confirmationIsOpen: true,
        data: {
          content: translate?.('ra.message.are_you_sure'),
          onConfirm: (confirmationCallback?: () => void) => {
            mailActionsHandler?.(MailActions.onSendNewMessage, {
              successCallback: onClose,
              failureCallback: onClose,
              isSaveAsDraft: true,
            } as OnSendNewMessageParams);
            confirmationCallback?.();
          },
        },
      });
    } else {
      mailActionsHandler?.(MailActions.onSendNewMessage, {
        successCallback: onClose,
        failureCallback: onClose,
        isSaveAsDraft: true,
      } as OnSendNewMessageParams);
    }
  };

  /**
   * @function handleMinimize
   * @returns { void }
   */
  const handleMinimize = (): void => {
    mailActionsHandler?.(MailActions.onSetLocalStorage);
    onClose();
  };

  return (
    <MailFormToolbarView
      title={title}
      onClose={handleClose}
      handleToggleFullScreen={handleToggleFullScreen}
      isFullScreen={isFullScreen}
      onMinimize={handleMinimize}
    />
  );
});

export default MailFormToolbarController;
