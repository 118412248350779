import { makeStyles } from '@material-ui/core';

import type { CustomTheme } from './../../../../core/themeProvider';
import type { StyleProps } from './todo-step-items.type';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  stepList: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',

    borderTop: ({ isOver }) =>
      isOver ? `1px solid ${theme.palette.primary.appPrimaryTextColor}` : 'unset',
  },

  taskTitle: {
    flexGrow: 1,
    fontWeight: 100,
    outline: 'none',
    border: 'none',
    padding: 5,
    height: 30,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    fontFamily: theme.typography.fontFamily,
  },

  stepDoneIcon: {
    fontSize: 15,
    margin: 2.5,
  },
}));
