import { makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../core/themeProvider';
import { StyleProps } from './confirmation-dialog.type';

export const useStyles = makeStyles<CustomTheme, StyleProps>(theme => ({
  dialogActions: {
    margin: 0,
    padding: '24px 24px',
  },

  icon: {
    margin: '0 5px',
  },

  dialogTitle: {
    '& > *': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  cancelBtn: {
    margin: '0 5px',
  },
  confirmButton: {
    color: theme.palette.primary.appLightBackgroundColor,
    background: ({ showLightColor }) =>
      showLightColor
        ? theme.palette.primary.lightBlueBackgroundButton
        : theme.palette.primary.automationBackgroundConfirmButton,
  },
}));
