import { GET_MENU } from '../../core/data-Provider.helper';
import {
  CONFIG_FIXED_MENU,
  API_NAME,
  getValue,
  USER_TOKEN,
  setValue,
  CONFIG_CACHED_MENU,
} from '../../core/configProvider';
import { arrayResultToObjectWithLowerCase, clone } from '../../helper/data-helper';
import dataProvider from '../../core/dataProvider';
import { MenuItemParams } from './sidebar-menu';
import { actorDispatch } from '../../type/actor-setup';
import { getSessionIdInUrl } from '../../helper/UrlHelper';

const getMenuParams = {
  pagination: {
    page: 1,
    perPage: 1000,
  },
  sort: {
    field: 'id',
    order: 'ASC',
  },
};

const getMenuItems = async (): Promise<MenuItemParams[]> => {
  if (!getSessionIdInUrl() && !getValue(USER_TOKEN)) {
    throw new Error(`user token is not valid`);
  }

  const fixedMenu = getValue(CONFIG_FIXED_MENU) as MenuItemParams[];

  if (fixedMenu && fixedMenu.length > 0) {
    return fixedMenu;
  }

  const apiName = getValue(API_NAME);

  try {
    const data = await dataProvider(
      GET_MENU,
      `account/${apiName}/menu`,
      getMenuParams,
    );

    const compatibleData = arrayResultToObjectWithLowerCase(
      data?.data,
    ) as unknown as MenuItemParams[];

    if (!compatibleData || compatibleData.length === 0) {
      throw new Error(`menu not found`);
    }

    return compatibleData;
  } catch (error) {
    if (typeof error === 'string') {
      throw new Error(error);
    } else if (typeof error === 'object') {
      throw new Error(error?.['message']);
    } else {
      throw new Error('getting menu failed');
    }
  }
};

export const handleGetMenuItemsForSidebar = async (): Promise<void> => {
  try {
    actorDispatch('menuData', {
      isLoading: true,
    });

    const menuItems = await getMenuItems();

    actorDispatch(
      'menuData',
      {
        items: menuItems,
        isLoadedOnce: true,
        isLoading: false,
        error: null,
      },
      { replaceAll: true, disableDebounce: true },
    );

    setValue(CONFIG_CACHED_MENU, menuItems);
  } catch (error) {
    const errorMessage = (error as { message: string })?.message;

    actorDispatch(
      'menuData',
      {
        isLoading: false,
        error: errorMessage,
      },
      { disableDebounce: true },
    );

    console.log('=>> menu errorMessage: ', errorMessage);

    if (errorMessage === 'user token is not valid') {
      //
    } else if (errorMessage === 'menu not found') {
      //
    } else if (errorMessage === 'getting menu failed') {
      //
    } else {
      //
    }
  }
};
