import { isEmptyObject } from '../../../../helper/data-helper';

import type { WMSLayoutRow } from '../../wms.type';

/**
 * Check filter gird for show data.
 * @function showGridWithoutSearch
 * @param {Record<number, Array<WMSLayoutRow>>} layoutRows
 * @param {Record<string, unknown>} formData
 * @returns {boolean}
 */
export const showGridWithoutSearch = (
  layoutRows: Record<number, Array<WMSLayoutRow>>,
  formData: Record<string, unknown>,
): boolean => {
  if (isEmptyObject(formData)) return true;

  let shouldShowGrid = true;

  // check all rows for required fields
  for (const key in layoutRows) {
    // -1 is hidden fields
    if (parseInt(key) === -1) {
      continue;
    }

    layoutRows[key].forEach(field => {
      if (field.required === true && field.name in formData === false) {
        shouldShowGrid = false;
      }
    });
  }

  return shouldShowGrid;
};
