import { FC } from 'react';
import { LogoutButtonViewInterface } from './logout-button.type';
import { useTranslate } from 'react-admin';
import { MenuItem } from '@material-ui/core';
import { useStyles } from './logout-button.style'

const LogoutButtonView: FC<LogoutButtonViewInterface> = props => {
  const { userLogoutHandler } = props;
  const translate = useTranslate();

  const classes = useStyles();

  return (
    <MenuItem button className={classes.logout} onClick={userLogoutHandler}>
      {translate('general.logout')}
    </MenuItem>
  );
};

export default LogoutButtonView;
