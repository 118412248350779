import lodashFind from 'lodash/find';
import { isEmptyObject } from './data-helper';

const colorTable = {
  0: '#ef5350',
  1: '#ba68c8',
  2: '#42a5f5',
  3: '#29b6f6',
  4: '#66bb6a',
  5: '#9ccc65',
  6: '#ffee58',
  7: '#ffa762',
  8: '#bdbdbd',
  9: '#ffffff',
};

export const getColorById = id => {
  return colorTable[id];
};

/**
 * gets all fields and finds row state color `relatedName`.
 * @function findRowStateColorField
 * @param {Record<string, FieldType>} fields
 * @returns {FieldType} row state color key
 */
export const findRowStateColorField = fields => {
  if (isEmptyObject(fields)) {
    return null;
  }

  return lodashFind(
    fields,
    field => field.name === 'rowstatecolor' || field.title === 'rowstatecolor',
  );
};
