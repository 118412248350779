import { type ReactElement } from 'react';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'classnames';
import { Tooltip, Typography } from '@material-ui/core';

import { UserInfoViewInterface } from './user-info.type';
import { useStyles } from './user-info.style';
import { getUserAvatar } from './user-info.helper';
import { TextWithEffect } from '../text-with-effect';
import {
  API_URL,
  API_VERSION,
  getValue,
  SESSION_ID,
} from '../../../../core/configProvider';
import { isEmptyObject } from '../../../../helper/data-helper';

const UserInfoView = (props: UserInfoViewInterface): ReactElement => {
  const {
    user,
    showStatus = false,
    isOnline = false,
    userLastSeen,
    isTyping,
    usersIsTypingInGroup,
  } = props;

  const translate = useTranslate();

  const classes = useStyles();
  const sessionId = getValue(SESSION_ID);
  const apiUrl = getValue(API_URL);
  const apiVersion = getValue(API_VERSION);

  let isTypingText = translate('chat.isTyping');
  if (user.inGroup && usersIsTypingInGroup.length > 0) {
    isTypingText = usersIsTypingInGroup.join(',') + ' ' + isTypingText;
  }

  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.avatarRoot}>
        {user.photoURL ? (
          <Tooltip title={user.displayName ?? ''}>
            <Avatar
              className={classes.profilePic}
              src={`${apiUrl}/oauth2/${sessionId}/${apiVersion}${user.photoURL}`}
            />
          </Tooltip>
        ) : (
          <Tooltip title={user.displayName ?? ''}>
            <Avatar className={classes.profilePic}>{user.displayName ?? ''}</Avatar>
          </Tooltip>
        )}
        {showStatus && (
          <Box
            className={classes.userStatus}
            bgcolor={isOnline ? green[600] : red[600]}
          />
        )}
      </Box>
      <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Tooltip title={user.displayName ?? ''}>
            <Box className={clsx(classes.userName)}>
              {user.displayName ?? 'Chat User'}
            </Box>
          </Tooltip>

          {isTyping ? (
            <TextWithEffect text={isTypingText} effect="pending" />
          ) : (
            !user.inGroup && (
              <Typography variant="subtitle2" className={classes.lastSeenText}>
                {userLastSeen}
              </Typography>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfoView;
