import { FC, memo } from 'react';

import { AppSidebarInterface } from './app-sidebar.type';
import AppSidebarView from './app-sidebar.view';

const AppSidebarController: FC<AppSidebarInterface> = memo(props => {
  // prettier-ignore
  const { isAppDrawerOpen, onCloseDrawer, fullView, sidebarContent, cardStyle } = props;

  return (
    <AppSidebarView
      isAppDrawerOpen={isAppDrawerOpen}
      fullView={fullView}
      sidebarContent={sidebarContent}
      onCloseDrawer={onCloseDrawer}
      cardStyle={cardStyle}
    />
  );
});

export default AppSidebarController;
