import { FC, memo, useEffect } from 'react';
import { InputRefContent } from '..';
import { isEmptyObject } from '../../../helper/data-helper';
import { ValidationError } from '../../../helper/Types';
import {
  actorDispatch,
  actorGetActionValue,
  actorOnDispatch,
} from '../../../type/actor-setup';
import { FormData } from '../form.type';

import { ChangePasswordFormInterface } from './change-password-form.type';
import ChangePasswordFormView from './change-password-form.view';

const ChangePasswordFormController: FC<ChangePasswordFormInterface> = memo(props => {
  const { fields } = props;
  const { current: currentResource } = actorGetActionValue('resources')!;

  /**
   * Finding input values from `formData`, if corresponding input name found in `formErrors`, replace it
   * @function updateInputsState
   * @param {object} inputsRef
   * @param {object} formErrors
   * @param {object} formData
   * @returns {void} void
   */
  const updateInputsState = (
    inputsRef: Record<string, InputRefContent> | null | undefined,
    formErrors: Record<string, ValidationError> | undefined,
    formData: FormData,
  ): void => {
    if (isEmptyObject(inputsRef)) return;

    //TODO: move to main parent component
    for (const inputName in inputsRef) {
      if (formErrors?.[inputName]) {
        inputsRef[inputName].setInputMessage?.({
          message: formErrors?.[inputName].message ?? '',
          messageType: 'error',
        });

        // FIXME: Because we have some many re-renders, without this `delay`, the correct value will be removed
        setTimeout(() => {
          inputsRef[inputName].setInputValue?.(formErrors?.[inputName].value ?? '');
        }, 0);

        continue;
      }

      inputsRef[inputName].setInputMessage?.(undefined);
      inputsRef[inputName].setInputValue?.(formData?.[inputName] ?? '');
    }
  };

  useEffect(() => {
    actorDispatch('allFields', fields, {
      path: `${currentResource.value}.${currentResource.type}`,
    });
  }, []);

  useEffect(() => {
    actorOnDispatch('formMessages', formErrors => {
      const formData =
        (actorGetActionValue('formData', [
          currentResource.value,
          currentResource.type,
        ]) as FormData | null) ?? {};
      const inputsRef = actorGetActionValue('inputsRef');
      updateInputsState(
        inputsRef?.[currentResource.value]?.[currentResource.type],
        formErrors?.[currentResource.value]?.[currentResource.type],
        formData,
      );
    });

    //TODO: convert to hook if possible
    actorOnDispatch(
      'formData',
      formData => {
        const inputsRef = actorGetActionValue(
          'inputsRef',
          `${currentResource.value}.${currentResource.type}`,
        ) as Record<string, InputRefContent> | undefined;

        for (const inputName in inputsRef) {
          inputsRef?.[inputName]?.setInputValue?.(
            formData?.[currentResource.value]?.[currentResource.type]?.[inputName],
          );
        }
      },
      {
        onDispatchOnce: true,
      },
    );
  }, []);

  return <ChangePasswordFormView fields={fields} />;
});

export default ChangePasswordFormController;
