import { makeStyles } from '@material-ui/core';

import { CustomTheme } from '../../../core/themeProvider';

export const useStyles = makeStyles<CustomTheme>(theme => ({
  IconButton: {
    padding: 7,
    margin: '0 5px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px',
      margin: 0,
    },
  },

  settingToolbar: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    minHeight: 'auto',
  },
}));
