import type {
  ChatItemInterface,
  ContactInterface,
  MessageInSearchResultInterface,
  SelectedChatType,
} from '../../chat-section.type';
import type {
  ChatsDataInterface,
  ChatSidebarStateInterface,
} from '../chat-sidebar.type';

const setLoading = (
  state: ChatSidebarStateInterface,
  loading: boolean,
): ChatSidebarStateInterface => {
  return {
    ...state,
    loading,
  };
};

const setSearchValue = (
  state: ChatSidebarStateInterface,
  string: string,
): ChatSidebarStateInterface => {
  return {
    ...state,
    searchValue: string,
  };
};

const setSelectedUser = (
  state: ChatSidebarStateInterface,
  params: {
    chat: SelectedChatType | null;
    shouldUpdateChatsData?: boolean;
  },
): ChatSidebarStateInterface => {
  const { chat, shouldUpdateChatsData } = params;

  if (shouldUpdateChatsData === true) {
    const targetIndex = state.chatsData.list.findIndex(
      item => item.personinfo_id === chat?.personinfo_id,
    );

    if (targetIndex === -1) {
      state.chatsData.list.unshift(chat as ChatItemInterface);
    } else {
      state.chatsData.list[0] = chat as ChatItemInterface;
    }

    return {
      ...state,
      chatsData: {
        ...state.chatsData,
        list: [...state.chatsData.list],
      },
      selectedUser: chat != null ? { ...chat } : null,
    };
  }

  return {
    ...state,
    selectedUser: chat != null ? { ...chat } : null,
  };
};

const setContactsFoundBySearch = (
  state: ChatSidebarStateInterface,
  contacts: ContactInterface[],
): ChatSidebarStateInterface => {
  return {
    ...state,
    contactsFoundBySearch: [...contacts],
  };
};

const setMessagesFoundBySearch = (
  state: ChatSidebarStateInterface,
  messages: MessageInSearchResultInterface[],
): ChatSidebarStateInterface => {
  return {
    ...state,
    messagesFoundBySearch: [...messages],
  };
};

const setChatsData = (
  state: ChatSidebarStateInterface,
  chatsData: ChatsDataInterface,
): ChatSidebarStateInterface => {
  return {
    ...state,
    chatsData: {
      list: [...chatsData.list],
      hasMore: chatsData.hasMore,
    },
  };
};

export const chatSidebarStatefulActions = {
  setLoading,
  setChatsData,
  setSearchValue,
  setSelectedUser,
  setContactsFoundBySearch,
  setMessagesFoundBySearch,
};
