import { useTranslate } from 'react-admin';
import { Button, Icon, TextField } from '@material-ui/core';

import UploadFileContainer from '../../../../container/UploadFileContainer';
import { useStyles } from './create-edit-note.style';
import { ProfileAvatar } from '../../../profile-avatar';
import PreviewFile from '../../../PreviewFile';
import Pin from '../../../../images/pin.png';

import type { CreateEditNoteViewInterface } from './create-edit-note.type';
import type { FC } from 'react';

const CreateEditNoteView: FC<CreateEditNoteViewInterface> = props => {
  const {
    handleDeleteAttachment,
    handleNewAttachment,
    onNewNoteKeyPress,
    onNewNoteChange,
    saveNote,
    parentResource,
    currentUserId,
    invalidNote,
    attachments,
    isSending,
    noteText,
    parentId,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.noteItem}>
      <ProfileAvatar userId={currentUserId} />

      <div className={classes.noteContent}>
        <TextField
          label={translate('noteStream.newNote')}
          fullWidth
          variant="outlined"
          rowsMax={7}
          className={classes.textField}
          InputProps={{
            classes: {
              error: classes.error,
              input: classes.textFieldInput,
            },
          }}
          multiline
          value={noteText}
          onChange={onNewNoteChange}
          onKeyPress={onNewNoteKeyPress}
          error={invalidNote}
          helperText={invalidNote ? translate('noteStream.invalidNote') : undefined}
        />

        <div>
          <UploadFileContainer
            onChange={handleNewAttachment}
            resource={`${parentResource}/${parentId}`}
          />
          <div
            className={classes.previewFileContainer}
            data-test-previw-file-length={attachments.length}
          >
            {attachments.map((attachment, index) => (
              <PreviewFile
                index={index}
                key={attachment.fileName}
                file={attachment}
                onDelete={handleDeleteAttachment}
              />
            ))}
          </div>

          <div className={classes.saveBtnContainer}>
            <Button
              className={classes.noteButton}
              disabled={
                isSending || invalidNote || (!noteText && attachments.length === 0)
              }
              variant="contained"
              color="primary"
              size="small"
              onClick={saveNote(false)}
            >
              <Icon className={classes.icon} fontSize="small">
                save
              </Icon>

              {translate('ra.action.save')}
            </Button>
            <Button
              className={classes.noteButton}
              variant="outlined"
              color="primary"
              size="small"
              disabled={
                isSending || invalidNote || (!noteText && attachments.length === 0)
              }
              onClick={saveNote(true)}
            >
              <img
                src={Pin}
                className={
                  isSending || invalidNote || (!noteText && attachments.length === 0)
                    ? classes.iconPinDisable
                    : classes.iconPin
                }
              />
              {translate('ra.action.pin')}
            </Button>
          </div>
        </div>
        <div className={classes.saveBtnContainer}></div>
      </div>
    </div>
  );
};

export default CreateEditNoteView;
