import { alpha, makeStyles } from '@material-ui/core';
import { CustomTheme } from '../../../../core/themeProvider';

export const useStyles = makeStyles((theme: CustomTheme) => ({
  mainDialogContainer: {
    height: '38rem',
    maxWidth: '500px',
    overflow: 'hidden',
    minWidth: '20rem',
  },

  input: {
    height: '20px',
  },

  avatar: {
    width: 40,
    height: 40,
  },

  avatarRoot: {
    minWidth: 0,
    position: 'relative',
    marginRight: '2rem',
  },

  activeAvatar: {
    //blue border for active user
    border: `1px solid ${theme.palette.primary.main}`,
  },

  listContainer: {
    maxHeight: '20rem',
    marginTop: '1rem',
    overflow: 'auto',
    //hide scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  chip: {
    marginTop: '0.5rem',
    marginLeft: '0.2rem',
  },

  textFieldContainer: {
    width: '100%',
  },

  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    width: '100%',
    height: '5rem',
    overflow: 'auto',
    //hide scrollbar
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    //auto scroll to bottom
    '&:last-child': {
      paddingBottom: '1rem',
    },
  },

  listItem: {
    cursor: 'pointer',
    //hover
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      boxShadow: `0 3px 10px 0 ${alpha(theme.palette.primary.main, 0.2)}`,
    },
  },

  dialogContentContainer: {
    minHeight: '28rem',
    maxHeight: '34rem',
    overflow: 'hidden',
  },

  chatSearch: {
    position: 'relative',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    border: 'none',
    outline: 'none',

    '& .MuiOutlinedInput-input': {
      padding: '6px 14px',
      paddingLeft: 0,
      [theme.breakpoints.up('xl')]: {
        padding: '10px 14px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.secondary,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },

  dialogActionsContainer: {
    display: 'flex',
    direction: 'rtl',
    position: 'relative',
    minHeight: '4rem',
    marginBottom: '1rem',
    alignItems: 'center',
  },

  dialogActionsContainerEnglishVersion: {
    display: 'flex',
    direction: 'ltr',
    position: 'relative',
    minHeight: '4rem',
    marginBottom: '1rem',
    alignItems: 'center',
  },

  dialogTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  icon: {
    cursor: 'pointer',
    marginLeft: '0.5rem',
    width: '3px',
  },
}));
