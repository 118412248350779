import { FC } from 'react';
import { TopToolbar, useLocale, useTranslate } from 'react-admin';
import { Tooltip, IconButton, Icon } from '@material-ui/core';

import ListColumnSelectDialogButton from '../../../container/ListColumnSelectDialogButton';
import ExportButton from '../../ExportButton';
import ImportButton from '../../ImportButton';
import { useStyles } from './setting-bar.style';

import type { SettingBarViewProps } from './setting-bar.type';
import { isEmpty } from '../../../helper/data-helper';

const SettingBarView: FC<SettingBarViewProps> = props => {
  const {
    resource,
    isGroupingEnable,
    metaData,
    toggleDevExGrouping,
    isColumnChoiceEnable,
    fields,
    isTree,
    isExportButtonDisabled,
    filterExcel,
    sortExcel,
    isExportButtonEnable,
  } = props;

  const classes = useStyles();
  const locale = useLocale();
  const translate = useTranslate();

  return (
    <TopToolbar className={classes.settingToolbar}>
      {isExportButtonEnable && (
        <ExportButton
          disabled={isExportButtonDisabled}
          resource={resource}
          fields={fields
            ?.filter(field => !isEmpty(field?.name))
            .map(field => field.name)
            .join(',')}
          filter={filterExcel}
          sort={sortExcel}
          metaData={metaData}
        />
      )}

      {!isTree && isExportButtonEnable && (
        <ImportButton resource={resource} metaData={metaData} />
      )}

      {isColumnChoiceEnable && metaData && resource && (
        <ListColumnSelectDialogButton
          metaData={metaData}
          locale={locale}
          resource={resource}
          fields={fields}
        />
      )}

      {isGroupingEnable && (
        <Tooltip title={translate('grid.grouping')}>
          <IconButton
            color="primary"
            className={classes.IconButton}
            onClick={toggleDevExGrouping}
            id="grouping_button"
          >
            <Icon fontSize="small">view_compact</Icon>
          </IconButton>
        </Tooltip>
      )}
    </TopToolbar>
  );
};

export default SettingBarView;
